import { ColumnedCardLayout, ColumnedCardLayoutProps } from '../index'
import { DashboardDeviceSelectionGallery } from '../../components'
import { DeviceSelection } from '@/app/components/DeviceSelectionGallery/DeviceSelectionGallery.types'

export interface Props extends ColumnedCardLayoutProps {
  selectedDevice: DeviceSelection | null
  setSelectedDevice: (device: DeviceSelection) => void
  excludeDevices?: React.ComponentProps<
    typeof DashboardDeviceSelectionGallery
  >['exclude']
}

export default function DashboardLayout({
  children,
  setSelectedDevice,
  selectedDevice,
  ...props
}: Props) {
  return (
    <ColumnedCardLayout {...props}>
      <div style={{ overflow: 'hidden', maxWidth: '100%' }}>
        <DashboardDeviceSelectionGallery
          selectedDevice={selectedDevice}
          setSelectedDevice={setSelectedDevice}
          exclude={props.excludeDevices}
        />
      </div>
      {children}
    </ColumnedCardLayout>
  )
}
