import { createResourceCollection } from '@/request'
import { TeslaFleetAuthentication } from '@/types'

export const storeKey = 'teslaFleetAuthentication'

export const teslaFleetAuthenticationCollection =
  createResourceCollection<TeslaFleetAuthentication>({
    name: 'teslaFleetAuthentication',
    apiConfig: {
      path: 'tesla-fleet-authentication',
    },
    selector: (state) => state[storeKey],
  })

export default teslaFleetAuthenticationCollection.slice.reducer
