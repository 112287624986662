// @ts-expect-error we need to upgrade this package to get its types
import jwtDecode from 'jwt-decode'
import * as auth from '../actions/auth'
import { getApiErrorMessage } from '../utils/errorUtilities'
import { PayloadAction } from '@reduxjs/toolkit'
import { Token } from '@/types'
import { sendTokensToMobile } from '@/utils/messageUtils'
import { resetIdentity } from '@/logging/mixpanel'

type State = {
  access?: Token
  refresh?: Token
  fromToken?: boolean
  errors?: Record<string, unknown>
  passwordResetErrors?: Record<string, unknown>
  validateResetErrors?: Record<string, unknown>
  changePasswordErrors?: Record<string, unknown>
  authLoading?: boolean
  passwordResetLoading?: boolean
  passwordResetComplete?: boolean
  validateResetLoading?: boolean
  validateResetComplete?: boolean
  changePasswordComplete?: boolean
  changePasswordLoading?: boolean
}

const initialState: State = {
  access: undefined,
  refresh: undefined,
  errors: {},
  passwordResetErrors: {},
  validateResetErrors: {},
  changePasswordErrors: {},
  authLoading: false,
  passwordResetLoading: false,
  passwordResetComplete: false,
  validateResetLoading: false,
  validateResetComplete: false,
  changePasswordComplete: false,
  changePasswordLoading: false,
}

export default (state = initialState, action: PayloadAction<any>): State => {
  switch (action.type) {
    case auth.LOGIN_SUCCESS:
      sendTokensToMobile({
        access: action.payload.access,
        refresh: action.payload.refresh,
      })
      resetIdentity()
      return {
        access: {
          token: action.payload.access,
          ...jwtDecode(action.payload.access),
        },
        refresh: {
          token: action.payload.refresh,
          ...jwtDecode(action.payload.refresh),
        },
        errors: {},
        authLoading: false,
        fromToken: false,
      }
    case auth.LOGIN_TOKEN_SUCCESS:
      sendTokensToMobile({
        access: action.payload.access_token,
        refresh: action.payload.refresh_token,
      })
      resetIdentity()
      return {
        access: {
          token: action.payload.access_token,
          ...jwtDecode(action.payload.access_token),
        },
        refresh: {
          token: action.payload.refresh_token,
          ...jwtDecode(action.payload.refresh_token),
        },
        errors: {},
        authLoading: false,
        fromToken: true,
      }
    case auth.LOGIN_REQUEST:
      return {
        authLoading: true,
      }
    case auth.LOGIN_FAILURE:
      return {
        access: undefined,
        refresh: undefined,
        errors: getApiErrorMessage(action.payload),
        authLoading: false,
      }
    case auth.LOGIN_TOKEN_FAILURE:
      return {
        access: undefined,
        refresh: undefined,
        errors: getApiErrorMessage(action.payload),
        authLoading: false,
        fromToken: true,
      }
    case auth.TOKEN_SUCCESS:
      sendTokensToMobile({
        access: action.payload.access,
        refresh: state.refresh?.token as string,
      })
      return {
        ...state,
        access: {
          token: action.payload.access,
          ...jwtDecode(action.payload.access),
        },
      }
    case 'MANUAL_EXPIRE_TOKEN':
      if (state.access) {
        return {
          ...state,
          access: {
            ...state.access,
            exp: 0,
          },
        }
      }
      return state
    case auth.TOKEN_FAILURE:
      return {
        access: undefined,
        refresh: undefined,
        errors: getApiErrorMessage(action.payload),
      }
    case auth.SIGNUP_SUCCESS:
      sendTokensToMobile({
        access: action.payload.access,
        refresh: action.payload.refresh,
      })
      return {
        access: {
          token: action.payload.access,
          ...jwtDecode(action.payload.access),
        },
        refresh: {
          token: action.payload.refresh,
          ...jwtDecode(action.payload.refresh),
        },
        errors: {},
        authLoading: false,
      }
    case auth.SIGNUP_REQUEST:
      return {
        authLoading: true,
      }
    case auth.SIGNUP_FAILURE:
      return {
        access: undefined,
        refresh: undefined,
        errors: getApiErrorMessage(action.payload),
        authLoading: false,
      }
    case auth.LOGOUT_REQUEST:
      localStorage.removeItem('persist:polls')
      return {
        access: undefined,
        refresh: undefined,
      }
    case auth.PASSWORD_RESET_SUCCESS:
      return {
        passwordResetLoading: false,
        passwordResetErrors: {},
        passwordResetComplete: true,
      }
    case auth.PASSWORD_RESET_REQUEST:
      return {
        passwordResetLoading: true,
        passwordResetErrors: {},
        passwordResetComplete: false,
      }
    case auth.PASSWORD_RESET_FAILURE:
      return {
        passwordResetLoading: false,
        passwordResetErrors: getApiErrorMessage(action.payload),
        passwordResetComplete: false,
      }
    case auth.RESET_VALIDATE_SUCCESS:
      return {
        validateResetLoading: false,
        validateResetErrors: {},
        validateResetComplete: true,
      }
    case auth.RESET_VALIDATE_REQUEST:
      return {
        validateResetLoading: true,
        validateResetErrors: {},
        validateResetComplete: false,
      }
    case auth.RESET_VALIDATE_FAILURE:
      return {
        validateResetLoading: false,
        validateResetErrors: getApiErrorMessage(action.payload),
        validateResetComplete: false,
      }
    case auth.CHANGE_PASSWORD_SUCCESS:
      return {
        changePasswordLoading: false,
        changePasswordErrors: {},
        changePasswordComplete: true,
      }
    case auth.CHANGE_PASSWORD_REQUEST:
      return {
        changePasswordLoading: true,
        changePasswordErrors: {},
        changePasswordComplete: false,
      }
    case auth.CHANGE_PASSWORD_FAILURE:
      return {
        changePasswordLoading: false,
        changePasswordErrors: getApiErrorMessage(action.payload),
        changePasswordComplete: false,
      }
    default:
      return state
  }
}

export const authLoading = (state: State) => state.authLoading
export const passwordResetLoading = (state: State) => state.passwordResetLoading
export const validateResetLoading = (state: State) => state.validateResetLoading
export const changePasswordLoading = (state: State) =>
  state.changePasswordLoading
export const changePasswordComplete = (state: State) =>
  state.changePasswordComplete
export const passwordResetComplete = (state: State) =>
  state.passwordResetComplete
export const validateResetComplete = (state: State) =>
  state.validateResetComplete

export function accessToken(state: State) {
  if (state.access) {
    return state.access.token
  }
}

export function isAccessTokenExpired(state: State) {
  if (state.access && state.access.exp) {
    return 1000 * state.access.exp - new Date().getTime() < 5000
  }
  return true
}

export function refreshToken(state: State) {
  if (state.refresh) {
    return state.refresh.token
  }
}

export function isRefreshTokenExpired(state: State) {
  if (state.refresh && state.refresh.exp) {
    return 1000 * state.refresh.exp - new Date().getTime() < 5000
  }
  return true
}

export function isAuthenticated(state: State) {
  return !isRefreshTokenExpired(state)
}

export function errors(state: State) {
  return state.errors
}

export function passwordResetErrors(state: State) {
  return state.passwordResetErrors
}

export function validateResetErrors(state: State) {
  return state.validateResetErrors
}

export function changePasswordErrors(state: State) {
  return state.changePasswordErrors
}
