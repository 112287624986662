import { useEnrolledUtilityViewConfig } from '@/reducers/enrolledUtilityPrograms'
import { DeviceType } from '@/app/components/DeviceSelectionGallery/DeviceSelectionGallery.types'
import useWhiteLabelProgramId from '@/hooks/whitelabel/useWhiteLabelProgramId'
import useRemoteConfig from '@/hooks/useRemoteConfig'

export function useProgramDisabledDevices() {
  const { viewConfig: enrolledViewConfig, isLoading } =
    useEnrolledUtilityViewConfig()
  const { appRemoteConfig, isLoading: remoteConfigLoading } = useRemoteConfig()
  const disabledDevices: DeviceType[] = []

  // AVild - For Ava :( -- if we're Ava white labeled, we want to just never show Thermos
  const whitelabelId = useWhiteLabelProgramId()
  const isAva = Boolean(
    whitelabelId && whitelabelId === appRemoteConfig?.getWhitelabelId('ava'),
  )

  if (enrolledViewConfig?.disabledDevices?.vehicles) {
    disabledDevices.push('vehicle')
  }
  if (enrolledViewConfig?.disabledDevices?.thermostats || isAva) {
    disabledDevices.push('thermostat')
  }
  if (enrolledViewConfig?.disabledDevices?.vehicleChargers) {
    disabledDevices.push('vehicleCharger')
  }
  if (enrolledViewConfig?.disabledDevices?.homeMeters) {
    disabledDevices.push('home')
  }

  // AVild - This fills me with disdain, but I can't reproduce Ava's messaging
  // problem and frankly can not be bothered for something so incredibly minute
  const defaultMessage = isAva
    ? 'Ava SmartHome Charging limits connected devices to EVs, PHEVs, and EV chargers. You may still manage your smart thermostat from its app or the device itself.'
    : "A program you're enrolled in is preventing Optiwatt from controlling this device."

  return {
    disabledDevices,
    isLoading: isLoading && remoteConfigLoading,
    deviceIsDisabled: (device: DeviceType) => disabledDevices.includes(device),
    disabledMessage:
      enrolledViewConfig?.disabledDevices?.disabledMessage ?? defaultMessage,
  }
}
