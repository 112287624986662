export default function toTitleCase(string: string) {
  if (!string) return ''

  return string
    .trim()
    .toLowerCase()
    .split(/[\s_]+/) // space or underscore
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(' ')
}
