import { postMessagetoMobileIfPresent } from '@/utils/messageUtils'
import { AnimatePresence, motion } from 'framer-motion'
import { useCallback, useEffect, useRef, useState } from 'react'

const delay = 0

const chargeLimitAnims = {
  initial: { pathLength: 0 },
  animate: { pathLength: 1 },
  transition: { duration: 0.6, ease: [0, 0, 0.19, 1], delay },
}
const currentChargeAnims = {
  initial: { pathLength: 0 },
  animate: { pathLength: 1 },
  transition: { duration: 0.4, ease: [0, 0, 0.19, 1], delay },
}

export const BigText = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: {
    duration: 0.2,
    delay: 0.5,
    ease: [0.31, 0.04, 0.19, 1],
  }, // Delay to start after track animations
}

export const SmallText = {
  initial: { opacity: 0, y: 10 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.2, delay: delay + 0.5 }, // Delay to start after track animations
}

type CircularSliderProps = {
  loading: boolean
  size: number
  strokeWidth: number
  min: number
  max: number
  limit: number
  currentCharge: number
  chargeLimitColor?: string
  currentChargeColor?: string
  onChange: (newValue: number) => void
  onStartDragging?: () => void
  onStoppedDragging?: () => void
  children: React.ReactNode
  thumbText?: string
  step?: number
}

export const CircularSlider: React.FC<CircularSliderProps> = ({
  loading,
  size,
  strokeWidth,
  min,
  max,
  limit,
  currentCharge,
  chargeLimitColor,
  currentChargeColor,
  onStartDragging,
  onStoppedDragging,
  onChange,
  children,
  thumbText,
  step,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(loading)
  const [thumbMounted, setThumbMounted] = useState<boolean>(false)
  const [isDragging, setIsDragging] = useState<boolean>(false)
  const [previousValue, setPreviousValue] = useState<number>(limit)
  const svgRef = useRef<SVGSVGElement>(null)
  const loadingRef = useRef<SVGPathElement>(null)
  const radius = size / 2 - strokeWidth * 2
  const thumbSize = strokeWidth * 1.777

  const thumbAnims = {
    initial: { opacity: 0, scale: 0.7 },
    animate: {
      opacity: 1,
      scale: 1,
    },
    transition: {
      duration: 0.15,
      delay: thumbMounted ? 0 : 0.5,
    },
  }

  const svgAnims = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    transition: { duration: 0.2, delay: isLoading ? 0 : delay },
  }

  const loadingAnims = {
    initial: { rotate: 0, pathLength: 1 },
    animate: { rotate: 360, pathLength: 1 },
    exit: {
      rotate: 90,
      pathLength: 0,
      transition: {
        rotate: { duration: 0.2, ease: 'linear' },
        pathLength: { duration: 0.35, ease: 'linear', delay: 0.1 },
      },
    },
    transition: {
      duration: 1,
      ease: 'linear',
      repeat: Infinity,
    },
  }

  const calculateThumbPosition = (value: number): { x: number; y: number } => {
    const angle = ((value - min) / (max - min)) * 2 * Math.PI - Math.PI / 2
    return {
      x: radius * Math.cos(angle) + size / 2,
      y: radius * Math.sin(angle) + size / 2,
    }
  }

  const thumbPosition = calculateThumbPosition(limit)

  const updateValue = useCallback(
    (moveEvent: MouseEvent): void => {
      if (!isDragging) return
      if (!svgRef.current) return

      const svgCenter = svgRef.current.getBoundingClientRect()
      const x = moveEvent.clientX - svgCenter.left - size / 2
      const y = moveEvent.clientY - svgCenter.top - size / 2

      // Calculate the new angle based on the dampened mouse position
      const angle = Math.atan2(y, x) + Math.PI / 2

      let newValue =
        (((angle + Math.PI * 2) % (2 * Math.PI)) / (2 * Math.PI)) * (max - min)

      const valueDifference = newValue - limit
      const isCrossingBoundary = Math.abs(valueDifference) > (max - min) / 2
      if (isCrossingBoundary) {
        if (valueDifference > 0) {
          newValue = min
        } else {
          newValue = max
        }
      }
      // Normalize value to min-max
      newValue = Math.min(Math.max(newValue, min), max)

      // Round newValue to the nearest integer according to the step
      newValue = Math.round(newValue / (step ?? 1)) * (step ?? 1)

      if (newValue !== previousValue) {
        if (newValue % 5 === 0 && previousValue % 5 !== 0) {
          // Fire bigger haptic event
          postMessagetoMobileIfPresent('rigid', 'haptic')
        } else {
          postMessagetoMobileIfPresent('impactLight', 'haptic')
        }

        setPreviousValue(newValue)
      }

      onChange(newValue)
    },
    [isDragging, size, max, min, limit, onChange, radius],
  )

  const thumbRef = useRef<HTMLDivElement>(null)
  const startDragging = (event: any): (() => void) | void => {
    const thumb = thumbRef.current
    if (!thumb) {
      return
    }

    const thumbBox = thumb.getBoundingClientRect()
    const thumbCenter = {
      x: thumbBox.left + thumbBox.width / 2,
      y: thumbBox.top + thumbBox.height / 2,
    }

    const clickX = event.type.includes('mouse')
      ? event.clientX
      : event.touches[0].clientX
    const clickY = event.type.includes('mouse')
      ? event.clientY
      : event.touches[0].clientY

    const dx = clickX - thumbCenter.x
    const dy = clickY - thumbCenter.y
    const distance = Math.sqrt(dx * dx + dy * dy)

    if (distance > 20) {
      return
    }
    if (event.cancelable) event.preventDefault()

    setIsDragging(true)
    postMessagetoMobileIfPresent('impactHeavy', 'haptic')
    onStartDragging?.()

    const handleTouchMove = (e: TouchEvent) => {
      if (e.cancelable && isDragging) {
        e.preventDefault() // Prevent window scrolling on touch devices
      }
    }

    window.addEventListener('touchmove', handleTouchMove, { passive: false })

    const clientX = event.type.includes('mouse')
      ? event.clientX
      : event.touches[0].clientX
    const clientY = event.type.includes('mouse')
      ? event.clientY
      : event.touches[0].clientY
    updateValue({ clientX, clientY } as MouseEvent)
  }

  const stopDragging = () => {
    setIsDragging(false)
    postMessagetoMobileIfPresent('impactHeavy', 'haptic')
    onStoppedDragging?.()
  }

  useEffect(() => {
    if (loading) {
      return setIsLoading(true)
    }

    if (!loading && !loadingRef.current) {
      return
    }

    /**
     * We need to fire the animation when the loading spinner is at
     * a certain rotation angle. This allows us to queue up the animations
     * so they don't vary from differing load times
     */
    const checkRotation = () => {
      if (loadingRef.current) {
        const loadingElement = loadingRef.current
        const transformValue =
          getComputedStyle(loadingElement).getPropertyValue('transform')
        const matrix = new DOMMatrixReadOnly(transformValue)
        const rotation = Math.atan2(matrix.b, matrix.a) * (180 / Math.PI)

        if (rotation >= 20 && rotation <= 30) {
          setIsLoading(false)
        } else {
          requestAnimationFrame(checkRotation)
        }
      }
    }

    requestAnimationFrame(checkRotation)
  }, [loading, loadingRef])

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => setThumbMounted(true), 600)
    }

    return () => setThumbMounted(false)
  }, [isLoading])

  useEffect(() => {
    const handleMove = (event: any) => {
      if (event.cancelable) event.preventDefault()
      const clientX =
        event instanceof MouseEvent ? event.clientX : event.touches[0].clientX
      const clientY =
        event instanceof MouseEvent ? event.clientY : event.touches[0].clientY
      updateValue({ clientX, clientY } as MouseEvent)
    }
    const handleEnd = () => {
      stopDragging()
    }

    if (isDragging) {
      window.addEventListener('mousedown', handleMove)
      window.addEventListener('mousemove', handleMove)
      window.addEventListener('mouseup', handleEnd, { once: true })
      window.addEventListener('touchstart', handleMove)
      window.addEventListener('touchmove', handleMove, { passive: false })
      window.addEventListener('touchend', handleEnd, {
        passive: false,
        once: true,
      })
    }
    return () => {
      window.removeEventListener('mousedown', handleMove)
      window.removeEventListener('mousemove', handleMove)
      window.removeEventListener('mouseup', handleEnd)
      window.removeEventListener('touchstart', handleMove)
      window.removeEventListener('touchmove', handleMove)
      window.removeEventListener('touchend', handleEnd)
    }
  }, [isDragging, updateValue, stopDragging])

  return (
    <motion.svg
      initial={svgAnims.initial}
      animate={svgAnims.animate}
      transition={svgAnims.transition}
      height={size}
      width={size}
      style={{ cursor: 'pointer', userSelect: 'none' }}
      className="overflow-visible"
      ref={svgRef}
      onMouseDown={startDragging}
      onTouchStart={startDragging}
    >
      <AnimatePresence mode="wait">
        {isLoading ? (
          <motion.path
            ref={loadingRef}
            key="loading-animation"
            initial={loadingAnims.initial}
            animate={loadingAnims.animate}
            exit={loadingAnims.exit}
            transition={loadingAnims.transition}
            d={`
            M ${size / 2 - radius} ${size / 2}
            A ${radius} ${radius} 0 1 0 ${
              (size / 2) * Math.sin((-80 / 100) * 2 * Math.PI)
            } ${(size / 2) * Math.cos((-80 / 100) * 2 * Math.PI)}
            `}
            strokeWidth={strokeWidth}
            fill="transparent"
            stroke="#ccc"
            strokeLinecap="round"
            strokeDasharray={radius * Math.PI * 2 * 0.8}
            style={{ opacity: isLoading ? 1 : 0 }}
          />
        ) : (
          <>
            {/* Charge Limit progress */}
            <motion.path
              key="charge-limit"
              initial={chargeLimitAnims.initial}
              animate={chargeLimitAnims.animate}
              transition={chargeLimitAnims.transition}
              d={
                limit === max
                  ? `
                M ${size / 2} ${size / 2 - radius}
              A ${radius} ${radius} 0 1 1 ${size / 2} ${size / 2 + radius}
              A ${radius} ${radius} 0 1 1 ${size / 2} ${size / 2 - radius}
              `
                  : `
              M ${size / 2} ${size / 2 - radius}
              A ${radius} ${radius} 0 ${limit / max > 0.5 ? '1' : '0'} 1 ${
                      thumbPosition.x
                    } ${thumbPosition.y}
              `
              }
              strokeWidth={strokeWidth}
              fill="transparent"
              stroke={chargeLimitColor ?? '#80EA9F'}
              strokeLinecap="round"
              transform={`rotate(${size / 2} ${size / 2})`}
            />
            {/* Current Charge track */}
            <motion.path
              key="current-charge"
              initial={currentChargeAnims.initial}
              animate={currentChargeAnims.animate}
              transition={currentChargeAnims.transition}
              d={`
            M ${size / 2} ${size / 2 - radius}
            A ${radius} ${radius} 0 ${
                (currentCharge / 100) * 2 > 1 ? '1' : '0'
              } 1 ${
                size / 2 +
                radius * Math.sin((currentCharge / 100) * 2 * Math.PI)
              } ${
                size / 2 -
                radius * Math.cos((currentCharge / 100) * 2 * Math.PI)
              }
            `}
              strokeWidth={strokeWidth}
              fill="transparent"
              stroke={currentChargeColor ?? '#00D73F'}
              strokeLinecap="round"
            />

            {/* Center Component */}
            <foreignObject
              x={size / 2 - radius}
              y={size / 2 - radius}
              width={radius * 2}
              height={radius * 2}
            >
              <div className="relative h-full">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                  }}
                >
                  {children}
                </div>
              </div>
            </foreignObject>
            <foreignObject width={size} height={size} className="relative">
              {/* Thumb */}
              <motion.div
                initial={thumbAnims.initial}
                animate={thumbAnims.animate}
                transition={thumbAnims.transition}
                whileTap={{ scale: 1.2, transition: { duration: 0.1 } }}
                ref={thumbRef}
                style={{
                  position: 'relative',
                  left: thumbPosition.x - thumbSize / 2,
                  top: thumbPosition.y - thumbSize / 2,
                  width: thumbSize,
                  height: thumbSize,
                  boxShadow: '0 0 10px rgba(0,0,0,0.2)',
                }}
                className="bg-white rounded-full select-none"
              />
            </foreignObject>
            <foreignObject
              x={thumbPosition.x - 75}
              y={thumbPosition.y - 40}
              width={50}
              height={35}
              className="relative z-10"
            >
              <AnimatePresence>
                {isDragging && thumbText && (
                  <motion.div
                    initial={{ opacity: 0, y: 15, x: 20, scale: 0.8 }}
                    animate={{
                      opacity: 1,
                      y: 0,
                      x: 0,
                      scale: 1,
                    }}
                    exit={{ opacity: 0, y: 15, x: 20, scale: 0.8 }}
                    className="z-10 px-2 font-bold text-center text-gray-600 bg-gray-100 border border-gray-300 rounded-lg"
                  >
                    {thumbText}
                  </motion.div>
                )}
              </AnimatePresence>
            </foreignObject>
          </>
        )}
      </AnimatePresence>
    </motion.svg>
  )
}
