import {
  ConfigurableButton,
  ConfigurableText,
  FlowStep,
  Link,
} from '@/app/components'
import CircleCheckbox from '@/authenticated/components/CircleCheckbox'
import {
  BulletListItem,
  CardAction,
  CardActionButton,
  CardActions,
  CardNavigation,
  Grid,
  Icon,
  List,
  LoadingAnimation,
  Text,
} from '@/components'
import { ID } from '@/types/model'
import { EnrollErrorMessage } from '../UtilityProgramEnrollmentTogglePrompt/EnrollErrorMessage'
import UtilityProgramEnrollmentConfirmationModal from '../UtilityProgramEnrollmentConfirmationModal'
import ViewModel from './useViewModel'
import UtilityProgramAccountNumberEntry from '../UtilityProgramAccountNumberEntry'
import EnrollmentConfirmationPage from './EnrollmentConfirmationPage'
import { useEffect } from 'react'
import UtilityProgramHVACAttestation from '../UtilityProgramHVACAttestation'
import InnerCard from '@/components/InnerCard/InnerCard'

export type DeviceDescription = {
  description: string
  name: string
  id: ID
}

export type Props = ReturnType<typeof ViewModel> & {
  alreadyEnrolledVehicles?: DeviceDescription[]
  alreadyEnrolledThermostats?: DeviceDescription[]
  alreadyEnrolledChargers?: DeviceDescription[]
  possiblyEigibleVehicles?: DeviceDescription[]
  possiblyEligibleVehiclesText?: string
  titleText?: string
  descriptionText?: string
  hideTitle?: boolean
}

function UtilityProgramEligibleDeviceEnrollmentFlowStep(props: Props) {
  useEffect(() => {
    if (props.enrollError) {
      props.closeModal()
    }
  }, [props.enrollError])

  const noEligibleDevices =
    props.eligibleVehicles.length === 0 &&
    props.eligibleThermostats.length === 0

  return (
    <FlowStep id="eligible-device-enrollment-flow-step">
      <FlowStep.Content>
        <CardNavigation
          onCloseClick={props.onClose}
          id="program-enrollment-device-select-navigation"
        />
        <Grid flow="row" gap="30px">
          <EnrollErrorMessage error={props.enrollError} />
          <Grid flow="row" gap="16px">
            <ConfigurableText
              config={props.titleText ?? props.viewConfig?.title}
              variant="h3"
            >
              'Congratulations, your spot is available!'
            </ConfigurableText>
            {noEligibleDevices ? (
              'Unfortunately, none of your vehicles qualify for enrollment in the program. Please add an eligible vehicle to enroll.'
            ) : (
              <ConfigurableText
                config={props.descriptionText ?? props.viewConfig?.description}
                variant="body4"
              >
                Select which devices you want to enroll in the program.
              </ConfigurableText>
            )}
          </Grid>

          <Grid flow="row" gap="24px">
            <Text variant="subheader">
              {props.eligibleVehicles.length > 0
                ? 'Eligible vehicles'
                : 'No eligible vehicles'}
            </Text>
            <div className="flex flex-col gap-5">
              {props.eligibleVehicles.map((vehicle) => (
                <InnerCard>
                  <BulletListItem
                    key={vehicle.id}
                    icon={() => (
                      <CircleCheckbox
                        activeColor="#00D73F"
                        checked={props.selectedEligibleVehicleIDs.includes(
                          vehicle.id,
                        )}
                        style={{ padding: '0px' }}
                        onClick={() =>
                          props.toggleVehicleEligibility(vehicle.id)
                        }
                      />
                    )}
                  >
                    <Text variant="body1">{vehicle.description}</Text>
                    <Text variant="body4">{vehicle.name}</Text>
                  </BulletListItem>
                </InnerCard>
              ))}
            </div>
          </Grid>

          {props.eligibleThermostats.length > 0 && (
            <Grid flow="row" gap="24px">
              <Text variant="subheader">Eligible Thermostats</Text>
              <List>
                {props.eligibleThermostats.map((thermostat) => (
                  <BulletListItem
                    key={thermostat.id}
                    icon={() => (
                      <CircleCheckbox
                        activeColor="#00D73F"
                        checked={props.selectedEligibleThermostatIDs.includes(
                          thermostat.id,
                        )}
                        style={{ padding: '0px' }}
                        onClick={() =>
                          props.toggleThermostatEligibility(thermostat.id)
                        }
                      />
                    )}
                  >
                    <div style={{ paddingTop: '3px' }}>
                      {thermostat.description}
                      <br />
                      {thermostat.name}
                    </div>
                  </BulletListItem>
                ))}
              </List>
            </Grid>
          )}

          {props.eligibleChargers.length > 0 && (
            <Grid flow="row" gap="24px">
              <Text variant="subheader">Eligible chargers</Text>
              <List>
                {props.eligibleChargers.map((charger) => (
                  <BulletListItem
                    key={charger.id}
                    icon={() => (
                      <CircleCheckbox
                        activeColor="#00D73F"
                        checked={props.selectedEligibleChargerIDs.includes(
                          charger.id,
                        )}
                        style={{ padding: '0px' }}
                        onClick={() =>
                          props.toggleChargerEligibility(charger.id)
                        }
                      />
                    )}
                  >
                    <div style={{ paddingTop: '3px' }}>
                      {charger.description}
                      <br />
                      {charger.name}
                    </div>
                  </BulletListItem>
                ))}
              </List>
            </Grid>
          )}

          {props.ineligibleChargers.length > 0 && (
            <Grid flow="row" gap="24px">
              <Text variant="subheader">Ineligible chargers</Text>
              <List>
                {props.ineligibleChargers.map((charger) => (
                  <BulletListItem
                    key={charger.id}
                    icon={() => <Text variant="body2">•</Text>}
                  >
                    {charger.description}
                    <br />
                    {charger.name}
                  </BulletListItem>
                ))}
              </List>
            </Grid>
          )}

          {(props.alreadyEnrolledVehicles?.length ?? 0) > 0 && (
            <Grid flow="row" gap="24px">
              <Text variant="subheader">Enrolled vehicles</Text>
              <List>
                {props.alreadyEnrolledVehicles?.map((vehicle) => (
                  <BulletListItem
                    key={vehicle.id}
                    icon={() => <Text variant="body2">•</Text>}
                  >
                    {vehicle.description}
                    <br />
                    {vehicle.name}
                  </BulletListItem>
                ))}
              </List>
            </Grid>
          )}

          {(props.alreadyEnrolledThermostats?.length ?? 0) > 0 && (
            <Grid flow="row" gap="24px">
              <Text variant="subheader">Enrolled thermostats</Text>
              <List>
                {props.alreadyEnrolledThermostats?.map((thermostat) => (
                  <BulletListItem
                    key={thermostat.id}
                    icon={() => <Text variant="body2">•</Text>}
                  >
                    {thermostat.description}
                    <br />
                    {thermostat.name}
                  </BulletListItem>
                ))}
              </List>
            </Grid>
          )}

          {(props.possiblyEigibleVehicles?.length ?? 0) > 0 && (
            <Grid flow="row" gap="24px">
              <Text variant="subheader">Ineligible - Missing Permissions</Text>
              <List>
                {props.possiblyEigibleVehicles?.map((vehicle) => (
                  <BulletListItem
                    key={vehicle.id}
                    icon={() => <Text variant="body2">•</Text>}
                  >
                    {vehicle.description}
                    <br />
                    {vehicle.name}
                  </BulletListItem>
                ))}
              </List>
              <Text variant="body4">{props.possiblyEligibleVehiclesText}</Text>
              <Link to="/tesla-authentication/edit-permissions">
                Edit Permissions
              </Link>
            </Grid>
          )}

          {props.ineligibleVehicles.length > 0 && (
            <Grid flow="row" gap="24px">
              <Text variant="subheader">Ineligible - vehicle limitations</Text>
              <List>
                {props.ineligibleVehicles.map((vehicle) => (
                  <BulletListItem
                    key={vehicle.id}
                    icon={() => <Text variant="body2">•</Text>}
                  >
                    {vehicle.description}
                    <br />
                    {vehicle.name}
                  </BulletListItem>
                ))}
              </List>
            </Grid>
          )}
        </Grid>
      </FlowStep.Content>

      <FlowStep.Actions>
        <CardActions style={{ marginTop: '30px' }}>
          {props.onBack && (
            <CardActionButton
              type="navigation"
              onClick={props.onBack}
              id="program-enrollment-device-select-back-button"
            >
              <Icon name="ChevronLeft" color="grey-500" size={20} />
            </CardActionButton>
          )}
          <CardAction type="primary">
            {!props.enrollError ? (
              <ConfigurableButton
                id="program-enrollment-device-select-enroll-button"
                config={props.viewConfig?.enrollButton}
                onClick={props.onEnrollClickAfterSelection}
                disabled={noEligibleDevices ? false : props.disabled}
              >
                {noEligibleDevices ? 'Continue' : 'Enroll'}
              </ConfigurableButton>
            ) : (
              <ConfigurableButton
                id="program-enrollment-device-select-skip-button"
                onClick={props.afterEnrollErrorNextAction}
              >
                Next
              </ConfigurableButton>
            )}
          </CardAction>
        </CardActions>
      </FlowStep.Actions>
    </FlowStep>
  )
}

export default function UtilityProgramEligibleDeviceEnrollmentFlowStepStates(
  props: Props,
) {
  if (props.isLoading) {
    return <LoadingAnimation type="falling" centered />
  }

  return (
    <>
      {(() => {
        switch (props.enrollmentStep) {
          case 'deviceSelect':
            return <UtilityProgramEligibleDeviceEnrollmentFlowStep {...props} />
          case 'electricInHVAC':
            return <UtilityProgramHVACAttestation {...props} />
          case 'accountNumber':
            return (
              <UtilityProgramAccountNumberEntry
                onAccountNumberEntryComplete={
                  props.onAccountNumberEntryComplete
                }
                enrollError={props.enrollError}
                closeModal={props.closeModal}
                onSkip={props.onEnrollmentCompleteClick}
                existingAccountNumber={props.existingAccountNumber}
              />
            )
          case 'enrollmentComplete':
            return (
              <EnrollmentConfirmationPage
                onClick={props.onEnrollmentCompleteClick}
              />
            )
        }
      })()}
      <div id="modal-holder">
        <UtilityProgramEnrollmentConfirmationModal
          id="utility-program-enrollment-confirmation-modal"
          open={props.confirmationOpen}
          onClose={() => props.closeModal()}
          onConfirmationClick={() => {
            props.onEnroll()
          }}
        />
      </div>
    </>
  )
}
