import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { unsubscribe } from '../../actions/unsubscribe'
import Loader from '../../authenticated/components/layout/Loader'

const Body = (props) => {
  if (props.success) {
    return (
      <div className="text-center text-white">
        <h1 className="text-white" style={{ fontSize: 80 }}>
          Unsubscribed!
        </h1>
        <h5 className="text-white" mt={8}>
          “Sorry we annoyed you with our friendship”
        </h5>
        <p className="text-white">- Andy Bernard, The Office</p>
      </div>
    )
  } else {
    return (
      <div className="text-center text-white">
        <h1 className="text-white" style={{ fontSize: 80 }}>
          Oh no!
        </h1>
        <h5 className="text-white" mt={8}>
          Looks like we had a problem unsubscribing you, please contact
          support@getoptiwatt.com.
        </h5>
      </div>
    )
  }
}

const Unsubscribe = (props) => {
  const token = props.match.params.id
  const dispatch = useDispatch()
  const { success, isLoading } = useSelector((state) => state.unsubscribe)

  useEffect(() => {
    dispatch(unsubscribe(token))
  }, [isLoading])

  const header = success ? 'Unsubscribed!' : 'Oh no!'
  const body = success
    ? 'Sorry we annoyed you with our friendship'
    : 'Looks like we had a problem unsubscribing you.'

  return (
    <React.Fragment>
      <div className="main _use-deprecated-bootstrap _use-deprecated-style">
        <section
          className="hero-section ptb-100 background-img full-screen"
          style={{
            backgroundImage: "url('/img/app-hero-bg.webp')",
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
          }}
        >
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-9 col-lg-7">
                {isLoading ? <Loader /> : <Body success={success} />}
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  )
}

export default Unsubscribe
