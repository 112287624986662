import { thermostatsCollection } from '../reducers/thermostats'
import { RootState } from '../store'
import { ID } from '../types/model'

export default function selectLastMeasurementForThermostat(
  state: RootState,
  id: ID,
) {
  return thermostatsCollection.selectors.selectById(state, id)?.last_measurement
}
