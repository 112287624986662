import { DeviceAuthAlertMessage } from '@/app/components'
import useNavigateToTeslaKeyPairing from '@/app/hooks/useNavigateToTeslaKeyPairing'
import { useVehicleActiveEnrollments } from '@/hooks/programEnrollment/useVehicleEnrollment'
import { Vehicle } from '@/types/vehicle'

export default function TeslaVirtualKeyBanner(props: { vehicle: Vehicle }) {
  const { navigate: navigateToTeslaKeyPairing } = useNavigateToTeslaKeyPairing()
  const { isLoading, error, activeEnrollments } = useVehicleActiveEnrollments(
    props.vehicle.id,
  )
  const firstActiveEnrollment = activeEnrollments[0]
  // todo get message from cms field for program

  let message =
    "You still haven't paired your virtual key so some features may be unavailable."
  let linkText = 'Pair your virtual key'
  if (firstActiveEnrollment) {
    message =
      'Connection issue detected. This is affecting your participation and rewards. Pair your virtual key to stay connected.'
    linkText = 'Fix connection'
  }

  return (
    <DeviceAuthAlertMessage
      mode="warning"
      deviceName="vehicle"
      linkText={linkText}
      reconnectAction={() => navigateToTeslaKeyPairing(props.vehicle)}
      show={!(isLoading || error)}
    >
      {message}
    </DeviceAuthAlertMessage>
  )
}
