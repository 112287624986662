import { NavigationPage } from 'app-components'
import { useWhiteLabelData } from '@/hooks/whitelabel/useWhiteLabelData'
import { ButtonProps, CardAction, CardActions } from '@/components'
import BrandedHeader from '@/app/components/NavigationPage/WhitelabeledNavHeader/BrandedHeader'

type Props = {
  children: React.ReactNode
  id: string
  title?: string
  subtitle?: string
  navigationOptions?: ('back' | 'nothing')[]
}

/**
 * Used for easily spinning up branded pages. Could be refactored to
 * "BrandedNavigationPage" in the future
 * @param children
 * @param id
 * @param title
 * @param subtitle
 * @constructor
 */
export default function BrandedPage({
  children,
  id,
  title,
  subtitle,
  navigationOptions,
}: Props) {
  return (
    <NavigationPage
      id={id}
      title={title}
      subtitle={subtitle}
      navigationOptions={navigationOptions}
      headerContent={<BrandedHeader containerClassName="mb-4" />}
    >
      {children}
    </NavigationPage>
  )
}

type ContentProps = {
  children: React.ReactNode
  showButton?: boolean
  buttonText?: string
  buttonVariant?: ButtonProps['variant']
  buttonId?: string
  onClick?: () => void
}

/**
 * A wrapper around branded content that will allow for buttons that float to bottom
 * of page
 * @param children
 * @constructor
 */
export function Content({ children }: ContentProps) {
  return (
    <NavigationPage.SingleCardContent>
      {children}
    </NavigationPage.SingleCardContent>
  )
}

Content.displayName = 'Content'
BrandedPage.Content = Content

/**
 * To be placed inside BrandedPage.Content just to simplify TSX a bit -- wraps buttons
 * in a container that will be placed at the bottom of the page
 * @param children
 * @constructor
 */
export function ButtonContainer({ children }: ContentProps) {
  return (
    <CardActions>
      <CardAction type="primary" className="md:mt-5 flex flex-col gap-2.5">
        {children}
      </CardAction>
    </CardActions>
  )
}

ButtonContainer.displayName = 'ButtonContainer'
BrandedPage.ButtonContainer = ButtonContainer
