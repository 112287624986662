import UtilityProgramReferralLearnMoreCardRoot from './UtilityProgramReferralLearnMoreCard'
import useViewModel from './useViewModel'

type Props = {
  onClose: () => void
}

export default function UtilityProgramReferralLearnMoreCard(props: Props) {
  const viewModel = useViewModel()

  if (!viewModel.viewConfig) {
    return null
  }

  return (
    <UtilityProgramReferralLearnMoreCardRoot
      {...viewModel.viewConfig}
      {...props}
    />
  )
}
