import { createResourceCollection } from '@/request'
import { VehicleConnectivity } from '@/types/vehicle/vehicleConnectivity'

//url for the request should be api/vehicle/<int:vehicle_id>/connectivity/
export const vehicleConnectivityCollection =
  createResourceCollection<VehicleConnectivity>({
    name: 'vehicleConnectivity',
    selector: (state) => state.vehicleConnectivity,
  })

export default vehicleConnectivityCollection.slice.reducer
