import React from 'react'
import { motion } from 'framer-motion'
import { Helmet } from 'react-helmet'
import Header from '@/unauthenticated/components/Header/header'
import HeroSection from '@/unauthenticated/components/HeroSection/HeroSectionFaq'
import FooterSection from '@/unauthenticated/components/Footer'
import { sanitizeHtmlContent } from '@/utils/components'
import { DocumentRenderer } from '@keystone-6/document-renderer'
import ContactUs from '@/app/components/ContactUs/ContactUs'

type Answer =
  | string
  | {
      document: any
    }

type Props = {
  name: string
  loading?: boolean
  title?: string
  subtitle?: {
    document: any
  }
  questions: {
    question: string
    answer: Answer
    html?: boolean
  }[]
}

export default function Faq(props: Props) {
  return (
    <React.Fragment>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Header />
      <div className="main _use-deprecated-bootstrap _use-deprecated-style">
        <HeroSection />
        <Body {...props} />
      </div>
      <motion.div
        layout
        className="_use-deprecated-bootstrap _use-deprecated-style"
      >
        <FooterSection noSubscription={true} />
      </motion.div>
    </React.Fragment>
  )
}

type FaqColumnProps = {
  questions: Props['questions']
  columnNumber: number
}

function FaqColumn({ columnNumber, questions }: FaqColumnProps) {
  const isAnswerDocument = (answer: Answer): answer is { document: any } => {
    return typeof answer === 'object' && 'document' in answer
  }

  return (
    <div className="col-lg-6">
      <div id={`accordion-${columnNumber}`} className="accordion accordion-faq">
        {questions.map(({ question, answer, html: answerIsHtml }, index) => (
          <div className="card" key={index}>
            <div
              className="card-header py-4"
              id={`heading-${columnNumber}-${index + 1}`}
              data-toggle="collapse"
              role="button"
              data-target={`#collapse-${columnNumber}-${index + 1}`}
              aria-expanded="false"
              aria-controls={`collapse-${columnNumber}-${index + 1}`}
            >
              <h6 className="mb-0" style={{ paddingRight: '10px' }}>
                {question}
              </h6>
            </div>
            <div
              id={`collapse-${columnNumber}-${index + 1}`}
              className="collapse"
              aria-labelledby={`heading-${columnNumber}-${index + 1}`}
              data-parent={`#accordion-${columnNumber}`}
            >
              {isAnswerDocument(answer) ? (
                <div className="card-body">
                  <DocumentRenderer
                    renderers={{
                      block: {
                        list: (props) => {
                          if (props.type === 'ordered') {
                            return (
                              <ol
                                style={{
                                  listStyle: 'decimal inside',
                                }}
                              >
                                {props.children.map((child, index) => (
                                  <li key={index}>{child}</li>
                                ))}
                              </ol>
                            )
                          }
                          return (
                            <ul
                              style={{
                                listStyle: 'disc inside',
                              }}
                            >
                              {props.children.map((child, index) => (
                                <li key={index}>{child}</li>
                              ))}
                            </ul>
                          )
                        },
                      },
                    }}
                    document={answer.document}
                  />
                </div>
              ) : answerIsHtml ? (
                <div
                  className="card-body"
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtmlContent(answer),
                  }}
                />
              ) : (
                <div className="card-body">
                  <p style={{ whiteSpace: 'pre-line' }}>{answer}</p>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

function Body(props: Props) {
  // This is what it looks like when a document is empty in keystone
  const subtitleIsEmpty =
    props.subtitle === undefined ||
    props.subtitle?.document === undefined ||
    (Object.keys(props.subtitle?.document || {}).length === 1 &&
      Object.keys(props.subtitle?.document?.[0].children).length === 1 &&
      props?.subtitle?.document?.[0]?.children?.[0]?.text === '')

  return (
    <div>
      <section className="promo-section ptb-100">
        <div className="container">
          {!props.loading ? (
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <div className="row">
                <div className="col-lg-8 col-md-9">
                  <div className="section-heading mb-5">
                    {props.title ? (
                      <h2>{props.title}</h2>
                    ) : (
                      <h2>
                        {props.name}
                        <br />
                        Managed EV Charging Pilot
                      </h2>
                    )}
                    {!subtitleIsEmpty ? (
                      <p className="lead">
                        <DocumentRenderer document={props.subtitle?.document} />
                      </p>
                    ) : (
                      <p className="lead">
                        Don't see the answer you're looking for? Please{' '}
                        <ContactUs /> and we'll get back to you right away.
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                {/* split questions in half and put in one row */}
                <FaqColumn
                  columnNumber={1}
                  questions={props.questions.slice(
                    0,
                    Math.ceil(props.questions.length / 2),
                  )}
                />
                <FaqColumn
                  columnNumber={2}
                  questions={props.questions.slice(
                    Math.ceil(props.questions.length / 2),
                  )}
                />
              </div>
            </motion.div>
          ) : null}
        </div>
      </section>
    </div>
  )
}
