import { Divider, LoadingAnimation, Text } from '@/components'
import React from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import useGroupedUsageData from '@/app/pages/home-insights/current-rate/plan-details/plan-usage-graph/useGroupedUsageData'
import HorizontalBar from '../../../../../../components/HorizontalBar'

type UsageProps = {
  percent: number
  kwh: number
  label: string
  costPerKwh: number
}

function UsageGroup(props: UsageProps) {
  return (
    <div className="pt-5">
      <Text variant="body4">{props.label}</Text>
      <Text variant="body1" className="mt-0.5">
        {props.costPerKwh.toFixed(3).toLocaleString()}&cent; per kWh
      </Text>
      <HorizontalBar
        height={6}
        percentWidth={props.percent}
        className="bg-themed-blue-500"
        rounded
      >
        <Text variant="body4" className="whitespace-nowrap">
          {props.percent}% • {props.kwh} kWh
        </Text>
      </HorizontalBar>
    </div>
  )
}

export default function PlanUsageGraph() {
  const usageGroups = useGroupedUsageData(30)

  return (
    <div className="mt-5">
      <Divider />
      <AnimatePresence mode="wait">
        {usageGroups === undefined && (
          <motion.div
            key="loading"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <LoadingAnimation type="falling" centered className="mt-5" />
          </motion.div>
        )}
        {usageGroups && usageGroups.length === 0 && (
          <motion.div
            key="error"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <Text variant="body4" className="mt-5">
              We don't yet have usage data for your selected plan. Please come
              back later.
            </Text>
          </motion.div>
        )}
        {usageGroups && usageGroups.length > 0 && (
          <motion.div
            key="groups"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            {usageGroups.map((group) => {
              return (
                <UsageGroup
                  percent={group.percent_of_total}
                  kwh={group.kwh}
                  label={group.label ?? 'Unknown Rate'}
                  costPerKwh={group.rate_usd}
                />
              )
            })}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
