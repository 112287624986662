import { useState } from 'react'
import styled, { withTheme } from 'styled-components'
import {
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Checkbox,
  FormControl as MuiFormControl,
  FormControlLabel,
  FormGroup,
  Paper,
  Typography as MuiTypography,
} from '@material-ui/core'
import { spacing } from '@material-ui/system'
import { Layers } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { submitQuestions } from '../../../../actions/manualPlans'

const Typography = styled(MuiTypography)(spacing)
const Button = styled(MuiButton)(spacing)

const FormControlSpacing = styled(MuiFormControl)(spacing)

const FormControl = styled(FormControlSpacing)`
  width: 300px;
`

const Card = styled(MuiCard)(spacing)

const BigLayers = styled(Layers)`
  width: 45px;
  height: 45px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(5)}px;
`

function Questions({ onCancel, errorMessage, ...restProps }) {
  const { form } = useSelector((state) => state.manualPlans)
  const [weekdays, setWeekdays] = useState(form.changingWeekdays)
  const [months, setMonths] = useState(form.changingMonths)
  const dispatch = useDispatch()

  function handleSubmit(event) {
    event.preventDefault()
    dispatch(
      submitQuestions(
        event.target.weekdays.checked,
        event.target.months.checked,
      ),
    )
  }

  return (
    <Card mb={6} {...restProps} elevation={0}>
      <CardContent justifyContent="center" align="center">
        <BigLayers />
        <Typography variant="h6" gutterBottom>
          New Plan
        </Typography>
        <form onSubmit={handleSubmit}>
          <Paper mt={3} style={{ marginBottom: '10px' }}>
            <FormControl margin="normal" component="fieldset" align="left">
              <FormGroup>
                <FormControlLabel
                  style={{ marginTop: '20px' }}
                  control={
                    <Checkbox
                      checked={weekdays}
                      value="weekdays"
                      name="weekdays"
                      onChange={(e) => setWeekdays(e.target.checked)}
                    />
                  }
                  label="Check box if your rates on weekdays are different than on weekends"
                  labelPlacement="start"
                />
                <FormControlLabel
                  style={{ marginTop: '20px' }}
                  control={
                    <Checkbox
                      checked={months}
                      value="months"
                      name="months"
                      onChange={(e) => setMonths(e.target.checked)}
                    />
                  }
                  label="Check box if you have different rates for different months"
                  labelPlacement="start"
                />
              </FormGroup>
            </FormControl>
          </Paper>
          <Button onClick={onCancel} variant="contained" m={2}>
            Cancel
          </Button>
          <Button
            data-ga-event-label="select-plan"
            type="submit"
            variant="contained"
            color="primary"
          >
            Continue
          </Button>
          {errorMessage && (
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: '#f44336' }}
            >
              {errorMessage}
            </Typography>
          )}
        </form>
      </CardContent>
    </Card>
  )
}

export default withTheme(Questions)
