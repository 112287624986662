import { fetchViewConfigFromCMS } from '@/graphql/utilties/fetchViewConfigFromCMS'
import { createResourceCollection } from '@/request'
import { ID, RequestTypes, ResourceMiddlewareFunction } from '@/request/types'
import {
  UtilityProgram,
  UtilityProgramViewConfig,
} from '@/types/utilityProgram'
import { Model } from '@/types/model'

export type UtilityProgramModel = Model &
  UtilityProgram & {
    id: ID
    // view config is populated from the cms
    view_config_json?: UtilityProgramViewConfig
  }

async function populateViewConfigFromCMSMiddleware<
  UtilityProgram extends UtilityProgramModel,
>(
  requestData: { requestType: RequestTypes },
  data: UtilityProgram | UtilityProgram[],
): ReturnType<ResourceMiddlewareFunction<UtilityProgram>> {
  const type = requestData.requestType
  const middlewareInput: UtilityProgram[] = Array.isArray(data) ? data : [data]

  if (
    middlewareInput &&
    (type === RequestTypes.Fetch ||
      type === RequestTypes.Create ||
      type === RequestTypes.Update)
  ) {
    return await Promise.all(
      middlewareInput.map((utilityProgram) =>
        populateViewConfigFromCMS(utilityProgram),
      ),
    )
  }

  return data
}

async function populateViewConfigFromCMS<UtilityProgram>(
  utilityProgram: UtilityProgram,
) {
  const cmsViewConfig = await fetchViewConfigFromCMS({
    // @ts-expect-error no idea why this type is empty here
    utilityProgramId: utilityProgram.id,
  })

  return {
    ...utilityProgram,
    view_config_json: cmsViewConfig,
  }
}

type EligibleUtilityPrograms = UtilityProgramModel & {
  utilityPrograms: UtilityProgram[]
}
export const eligibleUtilityProgramsCollection =
  createResourceCollection<EligibleUtilityPrograms>({
    name: 'eligibleUtilityProgramsCollection',
    apiConfig: {
      path: 'programs/eligible',
      middlewares: [populateViewConfigFromCMSMiddleware],
    },
    selector: (state) => state.eligibleUtilityPrograms,
  })
const reducer = eligibleUtilityProgramsCollection.slice.reducer

export default reducer
