import { RootState } from '@/reducers'
import { teslaFleetAuthenticationCollection } from '@/reducers/teslaFleetAuthentication'

export function selectUrls(state: RootState) {
  const auth = teslaFleetAuthenticationCollection.selectors
    .selectAll(state)
    .find((x) => x.authorize_url || x.public_key_url)

  if (!auth) {
    return null
  }

  return {
    authorize_url: auth?.authorize_url as string,
    public_key_url: auth?.public_key_url as string,
  }
}

export function selectMissingScopes(state: RootState) {
  const auth = teslaFleetAuthenticationCollection.selectors
    .selectAll(state)
    .find((x) => Boolean(x.scopes?.missing?.length))

  if (!auth) {
    return null
  }

  return auth.scopes?.missing
}
