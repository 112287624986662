import { createResourceCollection } from '@/request'
import { ResourceModel } from '@/request/types'
import { Vehicle } from '@/types'

export type FordAuthentication = {
  vehicles: Vehicle[]
} & ResourceModel

export const fordAuthentication = createResourceCollection<FordAuthentication>({
  name: 'fordAuthentication',
  apiConfig: {
    path: 'authenticate-ford',
  },
  selector: (state) => state.fordAuth,
})

const fordAuthReducer = fordAuthentication.slice.reducer

export default fordAuthReducer
