import './styles.scss'
import {
  Button,
  Card,
  CardNavigation,
  Flex,
  Grid,
  Sheet,
  Text,
} from '../../../../components'
import formatClasses from '../../../../utils/classes/formatClasses'
import { useContext } from 'react'
import { BreakpointContext } from '@/context'
import clsx from 'clsx'
import { ConfigurableText } from '@/app/components'

export interface Props {
  backgroundImageUrl?: string
  onClose: () => void
  programLogoUrl: string
  tagText?: string
  showTag?: boolean
  title: string
  subtitle: string
  description: string
}

const defaultProps: Partial<Props> = {
  backgroundImageUrl:
    'https://optiwatt-static-assets.s3.amazonaws.com/images/san_diego_skyline.png',
}

export default function UtilityProgramReferralLearnMoreCard(props: Props) {
  props = { ...defaultProps, ...props }

  const breakpoint = useContext(BreakpointContext)
  const classes = {
    card: formatClasses(['utility-program-referral-learn-more-card']),
    navigation: formatClasses(['learn-more-card-navigation']),
    content: formatClasses(['card-content']),
    learnMoreButton: formatClasses(['learn-more-button']),
    logo: formatClasses(['program-logo']),
    logoText: formatClasses(['program-logo-text']),
  }

  return (
    <Card
      className={classes.card}
      style={{
        backgroundImage: `linear-gradient(360deg, rgba(75, 24, 114, 0.9) 31.05%, rgba(75, 24, 114, 0.2) 100%), url(${props.backgroundImageUrl})`,
      }}
    >
      <CardNavigation
        className={classes.navigation}
        onCloseClick={props.onClose}
        color="white"
        id="utility-program-referral-navigation"
      >
        <Grid
          flow="column"
          gap="10px"
          templateRows={breakpoint.xsOnly ? 'repeat(2, auto)' : undefined}
          placeItems={breakpoint.xsOnly ? 'start' : 'center'}
        >
          <Grid flow="column" gap="6px" placeItems="center">
            <img
              src={props.programLogoUrl}
              alt="Utility Program Logo"
              className={classes.logo}
            />
            <ConfigurableText
              config={props.title}
              className={clsx(classes.logoText, 'text-themed-white')}
              variant="h2"
            />
          </Grid>
          {props.showTag && (
            <Sheet color="purple-100">
              <Text className="text-themed-purple-500">
                {props.tagText === '' ? 'LIMITED SPOTS' : props.tagText}
              </Text>
            </Sheet>
          )}
        </Grid>
      </CardNavigation>
      <Flex
        className={classes.content}
        container
        direction="row"
        columnSpacing="16px"
        rowSpacing="16px"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Flex item>
          <Grid gap="4px" flow="row" templateColumns="minmax(0, 500px)">
            <ConfigurableText
              config={props.subtitle}
              className="text-themed-white"
              variant="subheader"
            />
            <ConfigurableText
              config={props.description}
              className="text-themed-white"
              variant="body2"
            />
          </Grid>
        </Flex>
        <Flex item fullWidth={breakpoint.smAndDown}>
          <Button
            className={classes.learnMoreButton}
            variant="primary"
            href="signup"
            style={{
              width: breakpoint.smAndDown ? '100%' : '300px',
            }}
            id="utility-program-referral-join-now-button"
          >
            Join Now
          </Button>
        </Flex>
      </Flex>
    </Card>
  )
}
