import { useAppSelector } from '../../../../../../hooks'
import useMockableViewModel from '../../../../../../hooks/useMockableViewModel'
import { selectVehicleById } from '../../../../../../selectors'
import mockVehicles from '../../../../../../types/vehicle/mock'
import useHideDashboardConfig from '../../../../../../authenticated/hooks/useHideDashboardConfig'
import { ID } from '../../../../../../types/model'

export interface Props {
  vehicleId: ID
}

function useViewModel(props: Props) {
  const vehicle = useAppSelector((state) =>
    selectVehicleById(state, props.vehicleId),
  )
  const shouldHideActionableWinterWeatherAlert = useHideDashboardConfig(
    'ACTIONABLE_WINTER_WEATHER_ALERT',
    true,
  )
  const shouldHideInfoWinterWeatherAlert = useHideDashboardConfig(
    'INFO_WINTER_WEATHER_ALERT',
    true,
  )

  return {
    vehicle,
    shouldHideActionableWinterWeatherAlert,
    shouldHideInfoWinterWeatherAlert,
  }
}

function useMockViewModel(props: Props) {
  return {
    vehicle: mockVehicles[0],
    shouldHideActionableWinterWeatherAlert: true,
    shouldHideInfoWinterWeatherAlert: true,
  }
}

export default useMockableViewModel({ useViewModel, useMockViewModel })
