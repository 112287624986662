import {
  combine,
  awaitResponse,
  succeed,
  fail,
  Action,
  RequestState,
} from './utils'
import {
  thermostatAuthActions,
  nestAuth,
  ecobeeAuth,
  honeywellAuth,
} from '../actions/thermostatAuth'
import { ThermostatBrandName } from '../types/thermostatBrands'

interface State extends RequestState {
  success: boolean | null
}

export function thermostatAuthReducer({
  types,
}: ReturnType<typeof thermostatAuthActions>) {
  const { Post, Custom } = types

  const initialState: State = {
    success: null,
    isLoading: false,
    errors: {},
  }

  function save(state: State, action: Action) {
    return {
      ...state,
      success: action.payload,
    }
  }

  function saveSuccess(state: State) {
    return {
      ...state,
      success: true,
    }
  }

  function saveFailure(state: State) {
    return {
      ...state,
      success: false,
    }
  }

  return (state = initialState, action: Action) => {
    switch (action.type) {
      case Post.Success:
        return combine(state, action, succeed, saveSuccess)
      case Post.Request:
        return combine(state, action, awaitResponse)
      case Post.Failure:
        return combine(state, action, saveFailure, fail)
      case Custom.Set:
        return combine(state, action, save)
      default:
        return state
    }
  }
}

export const nestAuthReducer = thermostatAuthReducer(nestAuth)
export const ecobeeAuthReducer = thermostatAuthReducer(ecobeeAuth)
export const honeywellAuthReducer = thermostatAuthReducer(honeywellAuth)

export function thermostatAuthSelector(brand: ThermostatBrandName) {
  const authIndex = {
    [ThermostatBrandName.Nest]: 'nestAuth',
    [ThermostatBrandName.Ecobee]: 'ecobeeAuth',
    [ThermostatBrandName.Honeywell]: 'honeywellAuth',
  }
  return (state: any) => state[authIndex[brand]] as State | undefined
}
