import { AlertMessage, NavigationPage } from '@/app/components'
import useViewModel from './useViewModel'
import PageLoader from '@/authenticated/components/layout/PageLoader'
import { ButtonContainer } from '@/app/pages/ava/BrandedPage'
import { Button } from '@/components'

function NotOwnerPageLoaded(
  viewModel: Extract<ReturnType<typeof useViewModel>, { isLoading: false }>,
) {
  const { onNoKeycard, onHasKeycard, error } = viewModel
  const subtitle =
    "We noticed you're listed as a driver, and the vehicle owner must share\n" +
    "          their virtual key with Optiwatt to complete the connection. Here's\n" +
    '          what you can do next.'
  return (
    <NavigationPage
      title="Pair Virtual Key"
      id="not-owner-page"
      subtitle={subtitle}
    >
      <NavigationPage.SingleCardContent>
        <div className="flex flex-col gap-4">
          <AlertMessage variant="info" show={!error}>
            Ensure you’re close to the vehicle before selecting Option 1. Follow
            the following steps to sync your car with Optiwatt.
          </AlertMessage>
          <AlertMessage variant="error" show={Boolean(error)}>
            {error}
          </AlertMessage>
        </div>
        <ButtonContainer>
          <Button id="btn-keycard" onClick={onHasKeycard}>
            I have a keycard to pair
          </Button>
          <Button id="btn-keycard" variant="secondary" onClick={onNoKeycard}>
            I don't have a keycard
          </Button>
        </ButtonContainer>
      </NavigationPage.SingleCardContent>
    </NavigationPage>
  )
}

export default function NotLoadedPage() {
  const viewModel = useViewModel()

  if (viewModel.isLoading) {
    return <PageLoader />
  }

  return <NotOwnerPageLoaded {...viewModel} />
}
