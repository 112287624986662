import React, {
  useState,
  ReactNode,
  HTMLAttributes,
  createContext,
  useContext,
  isValidElement,
  useEffect,
} from 'react'
import Icon from '../Icon'

interface CollapsibleListContextProps {
  activeIndex: number[]
  setActiveIndex: (index: number[] | ((index: number[]) => number[])) => void
  onlyOpenOne: boolean
}

interface InternalCollapsibleListItemProps
  extends HTMLAttributes<HTMLDivElement> {
  header: React.ReactNode
  children: ReactNode
  isOpen?: boolean
  index: number // This prop will be injected internally, not passed by the user
}

interface PublicCollapsibleListItemProps
  extends Omit<InternalCollapsibleListItemProps, 'index'> {}

const CollapsibleListContext = createContext<
  CollapsibleListContextProps | undefined
>(undefined)

export const useCollapsibleListContext = () => {
  return useContext(CollapsibleListContext)
}

const CollapsibleList: React.FC<
  {
    onlyOpenOne?: boolean
    children: React.ReactNode
  } & React.ReactHTMLElement<HTMLDivElement>['props']
> = ({ onlyOpenOne = false, children, ...props }) => {
  const [activeIndex, setActiveIndex] = useState<number[]>([])
  return (
    <CollapsibleListContext.Provider
      value={{ activeIndex, setActiveIndex, onlyOpenOne }}
    >
      <div
        {...props}
        className={`border rounded-lg border-themed-base-300 border-solid px-4 divide-y divide-themed-base-300 divide-solid bg-themed-white ${props.className}`}
      >
        {React.Children.map(children, (child, index) => {
          if (isValidElement(child)) {
            return React.cloneElement(child, {
              ...child.props,
              children: child.props.children ?? null,
              key: index,
              index, // Inject the index prop here
            })
          }
          return child
        })}
      </div>
    </CollapsibleListContext.Provider>
  )
}

const CollapsibleListItem: React.FC<PublicCollapsibleListItemProps> = ({
  header,
  children,
  isOpen = false,
  ...props
}) => {
  const { index } = (props as InternalCollapsibleListItemProps) ?? {}
  const context = useCollapsibleListContext()
  const [isNotCollapsed, setIsNotCollapsed] = useState(isOpen)
  useEffect(() => {
    if (!context) return
    if (isOpen) {
      if (context.onlyOpenOne) {
        context.setActiveIndex([index])
      } else {
        context.setActiveIndex((prev) => [...new Set([...prev, index])])
      }
    }
  }, [isOpen])
  useEffect(() => {
    if (context?.onlyOpenOne && context.activeIndex !== null) {
      setIsNotCollapsed(context.activeIndex.includes(index))
    }
  }, [context?.activeIndex])

  const toggleCollapse = () => {
    const newIsOpen = !isNotCollapsed
    setIsNotCollapsed(newIsOpen)
    if (!context) return
    if (context.onlyOpenOne) {
      context.setActiveIndex(newIsOpen ? [index] : [])
    } else {
      context.setActiveIndex((prev) =>
        newIsOpen
          ? [...new Set([...prev, index])]
          : prev.filter((i) => i !== index),
      )
    }
  }

  const open = context ? context.activeIndex.includes(index) : isNotCollapsed
  const hasValidChildren = !React.Children.toArray(children).every(
    (child) => child === null || child === undefined,
  )
  return (
    <div {...props} className={`w-full py-4 ${props.className}`}>
      {/* Header */}
      <div
        className={`${
          hasValidChildren && 'cursor-pointer'
        } flex justify-between items-center`}
        onClick={() => {
          if (!hasValidChildren) return
          toggleCollapse()
        }}
      >
        <span className="font-semibold">{header}</span>
        {hasValidChildren && (
          <Icon
            name="ChevronDown"
            className={`w-6 h-6 text-themed-base-400 flex-none transform ${
              open ? 'rotate-180' : 'rotate-0'
            } transition-transform duration-300 ease-in-out`}
          />
        )}
      </div>

      {/* Collapsible content */}
      <div
        className={`overflow-hidden grid duration-300 ease-in-out`}
        style={{
          transitionProperty: 'grid-template-rows',
          gridTemplateRows: open ? '1fr' : '0fr',
        }}
      >
        <div className={`transition-all overflow-hidden`}>
          <div className="mt-2">{children}</div>
        </div>
      </div>
    </div>
  )
}

const Collapsible = {
  List: CollapsibleList,
  ListItem: CollapsibleListItem,
}

export default Collapsible
