import { useEffect } from 'react'

const HubSpotForm = () => {
  useEffect(() => {
    if (window.hbspt) {
      window.hbspt.forms.create({
        region: 'na1',
        portalId: '8502002',
        formId: 'ae6c580b-42c9-44a2-844f-27c3b6160cc1',
        target: '#hubspotForm',
      })
    }
  }, [window.hbspt])

  return <div id="hubspotForm" />
}

export default HubSpotForm
