import { Home, Thermostat, Vehicle, VehicleCharger } from '@/types'
import {
  AlertState,
  Device,
  DeviceSelection,
} from '../DeviceSelectionGallery.types'
import { Resource } from '@/authenticated/components/ResourceSelectionGallery/types'
import { findBrandByPortal } from '@/types/thermostatBrands'
import { toTitleCase } from '@/utils/string'
import { getVehicleDisplayModel } from '@/utils/CarUtils'
import { useEnrolledUtilityViewConfig } from '@/reducers/enrolledUtilityPrograms'
import { useProgramDisabledDevices } from '@/hooks/useProgramDisabledDevices'
import { useEffect } from 'react'

type Props = {
  devices: (Device & { alertState: AlertState })[]
  selectedDevice: DeviceSelection | null
  setSelectedDevice: (device: DeviceSelection) => void
}

// Export the function for testing
export function vehicleToResource(
  vehicle: Vehicle,
  selected: boolean,
  selectDevice: (device: DeviceSelection) => void,
): Resource {
  const { car } = vehicle
  const { car_model } = car || {}

  const displayName = car?.display_name || ''
  const make = car_model?.make || 'Unset Make'
  const friendlyName = car_model?.friendly_name || ''
  const year = car_model?.year || ''
  let displayModel = getVehicleDisplayModel(vehicle)
  // AVild -- Ridiculous lil' fix to backend slurp bug
  if (displayModel === 'modely') {
    displayModel = 'Model Y'
  }

  const name = displayName !== '' ? displayName : make
  const details = `${year} ${
    displayName !== '' ? friendlyName : displayModel || ''
  }`
  const image = car?.image_url
    ? `/dashboard/img/car_models${car.image_url}`
    : '/dashboard/img/car_models/covered-car.webp'

  return {
    name,
    id: `device-selection-gallery-vehicle-selection-${make}-${vehicle.id}`,
    details,
    image,
    action: () => selectDevice({ id: vehicle.id, type: 'vehicle' }),
    selected,
  }
}

function homeToResource(
  home: Home,
  selected: boolean,
  selectDevice: (device: DeviceSelection) => void,
): Resource {
  return {
    name: 'Home',
    id: `device-selection-gallery-home-selection-${home.id}`,
    details:
      home.city && home.state
        ? `${toTitleCase(home.city)}, ${home.state.state_code.toUpperCase()}`
        : 'Your home',
    image: '/img/house.webp',
    action: () => selectDevice({ id: home.id, type: 'home' }),
    selected,
  }
}

function thermostatToResource(
  thermostat: Thermostat,
  selected: boolean,
  selectDevice: (device: DeviceSelection) => void,
): Resource {
  return {
    name: thermostat.name ?? thermostat.portal,
    id: `device-selection-gallery-thermostat-selection-${thermostat.portal}-${thermostat.id}`,
    details: 'Thermostat',
    image:
      findBrandByPortal(thermostat.portal)?.deviceImage ?? '/img/nest.webp',
    action: () => selectDevice({ id: thermostat.id, type: 'thermostat' }),
    mode: thermostat.authentication_inactive ? 'error' : 'normal',
    selected,
  }
}

function vehicleChargerToResource(
  vehicleCharger: VehicleCharger,
  selected: boolean,
  selectDevice: (device: DeviceSelection) => void,
): Resource {
  return {
    name: vehicleCharger?.name ?? 'Chargepoint',
    id: `device-selection-gallery-vehicle-charger-selection-${vehicleCharger.id}`,
    details: 'Charger',
    image: '/img/chargepoint-charger.webp',
    action: () =>
      selectDevice({ id: vehicleCharger.id, type: 'vehicleCharger' }),
    selected,
  }
}

function addModeToResource(
  resource: Resource,
  alertState: AlertState,
): Resource {
  return {
    ...resource,
    mode:
      alertState.mode === 'none' || alertState.mode === 'info'
        ? 'normal'
        : alertState.mode,
  }
}

function deviceToResource(
  device: Props['devices'][number],
  selected: boolean,
  selectDevice: (device: DeviceSelection) => void,
): Resource {
  let resource: Resource

  if (device.type === 'vehicle') {
    resource = vehicleToResource(device.model, selected, selectDevice)
  } else if (device.type === 'home') {
    resource = homeToResource(device.model, selected, selectDevice)
  } else if (device.type === 'vehicleCharger') {
    resource = vehicleChargerToResource(device.model, selected, selectDevice)
  } else if (device.type === 'thermostat') {
    resource = thermostatToResource(device.model, selected, selectDevice)
  } else {
    throw new Error(
      'Unknown device type encountered while trying to map devices to gallery resources',
    )
  }

  const resourceWithMode = addModeToResource(resource, device.alertState)
  return resourceWithMode
}

type DevicesWithResourcesAdded = (Props['devices'][number] & {
  resource: Resource
})[]

export function useSelectionGalleryResources(
  props: Props,
): DevicesWithResourcesAdded {
  const { devices, selectedDevice, setSelectedDevice } = props
  const { deviceIsDisabled } = useProgramDisabledDevices()

  return devices.map((device) => {
    const resource = deviceToResource(
      device,
      selectedDevice?.id === device.id && selectedDevice.type === device.type,
      setSelectedDevice,
    )

    resource.mode = deviceIsDisabled(device.type) ? 'disabled' : resource.mode

    return {
      ...device,
      resource,
    }
  })
}
