import React from 'react'
import { Button, Grid, Select } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import {
  getDeviceEligibility,
  getProgramEligibility,
  getProgramRequirements,
} from '@/actions/admin'
import { useSelector } from 'react-redux'
import Loader from '../../components/layout/Loader'
import { adminUtilityProgramCollection } from '@/actions/adminUtiltyProgramsCollections'

export default function UtilityEligibility({ profileID = '' }) {
  const dispatch = useDispatch()

  const programEligibility = useSelector(
    (state) => state.admin.utilityEligibility,
  )
  const programRequirements = useSelector(
    (state) => state.admin.programRequirements,
  )
  const [utilityProgram, setUtilityProgram] = React.useState(-1)
  const isLoading = useSelector((state) => state.admin.isLoading)

  const { data: utilityPrograms } = adminUtilityProgramCollection.useFetch()

  function fetchProgramRequirements() {
    if (utilityProgram === -1) return
    dispatch(getProgramRequirements(profileID, utilityProgram))
  }
  function fetchProgramEligibility() {
    if (utilityProgram === -1) return
    dispatch(getProgramEligibility(profileID, utilityProgram))
  }

  return (
    <div>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <label>Utility Program: </label>
          <Select
            native
            required
            label="Select a program"
            value={utilityProgram}
            onChange={(e) => {
              setUtilityProgram(parseInt(e.target.value))
              if (programRequirements) fetchProgramRequirements()
              if (programEligibility) fetchProgramEligibility()
            }}
          >
            <option value="-1" key="-1">
              Select A Program
            </option>
            {utilityPrograms &&
              utilityPrograms.map((utilityProgram) => (
                <option value={utilityProgram.id} key={utilityProgram.id}>
                  {utilityProgram.name}
                </option>
              ))}
          </Select>
        </Grid>
        <Grid item xs={12}>
          <Button
            disabled={isLoading || utilityProgram === -1}
            onClick={fetchProgramRequirements}
            variant="outlined"
            color="primary"
          >
            Get Program Requirements
          </Button>
          <Button
            disabled={isLoading || utilityProgram === -1}
            onClick={fetchProgramEligibility}
            variant="outlined"
            color="primary"
          >
            Check Program Eligibility
          </Button>
        </Grid>
      </Grid>

      {/* Program Requirements */}
      <div>
        {programRequirements && (
          <div>
            Program Requirement(s):
            {Object.keys(programRequirements).map((requirementType, idx) => (
              <ul key={idx}>
                <strong>{requirementType}</strong>
                {programRequirements[requirementType] &&
                  programRequirements[requirementType].requirements.map(
                    (requirement, idx) => (
                      <li key={idx}>
                        &emsp; {requirement.name} - {requirement.description}
                      </li>
                    ),
                  )}
              </ul>
            ))}
          </div>
        )}
      </div>
      <br />
      {/* Profile Level Checks */}
      <div>
        {programEligibility && (
          <div>
            <div>
              Profile Level Eligibility:{' '}
              <strong>
                {programEligibility.profile.is_eligible ? 'YES' : 'NO'}
              </strong>
            </div>
            <div>
              <ul>
                {programEligibility.profile.requirements_result.map(
                  (requirement, idx) => (
                    <li key={idx}>
                      {requirement.name}:
                      <br />
                      &emsp;
                      <strong>
                        {requirement.is_eligible
                          ? 'MEETS!'
                          : requirement.ineligible_reasons.join(', ')}
                      </strong>
                    </li>
                  ),
                )}
              </ul>
            </div>
          </div>
        )}
      </div>
      <br />
      {/* Device Level */}
      <div>
        {programEligibility && programEligibility.devices && (
          <div>
            Device Level Eligibility Checks:
            {Object.keys(programEligibility.devices).map((deviceType, idx) => (
              <ul key={idx}>
                {deviceType}:
                {!programEligibility.devices[deviceType] && (
                  <li>&emsp;Not allowed in program!</li>
                )}
                {programEligibility.devices[deviceType] &&
                  programEligibility.devices[deviceType].map((device, idx) => (
                    <li key={idx}>
                      &emsp;ID: <strong>{device.id}</strong> - Eligibility:{' '}
                      <strong>{device.is_eligible ? 'YES' : 'NO'}</strong>
                      {device.requirements_result.map((requirement, idx) => (
                        <div key={idx}>
                          &emsp;&emsp; {requirement.name} -{' '}
                          <strong>
                            {requirement.is_eligible
                              ? 'MEETS!'
                              : 'NO! - ' +
                                requirement.ineligible_reasons.join(', ')}
                          </strong>
                        </div>
                      ))}
                    </li>
                  ))}
              </ul>
            ))}
          </div>
        )}
      </div>

      {isLoading && <Loader />}
    </div>
  )
}
