import React, { Component } from 'react'

import Header from '../components/Header/header'
import PrivacyPolicy from '../components/PrivacyPolicy'
import FooterSection from '../components/Footer'
import PrivacyHeader from '../components/PrivacyPolicy/PrivacyHeader'

class Privacy extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <div className="main _use-deprecated-bootstrap _use-deprecated-style">
          <PrivacyHeader />
          <PrivacyPolicy />
        </div>
        <FooterSection />
      </React.Fragment>
    )
  }
}

export default Privacy
