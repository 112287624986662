import React, { useCallback, useState } from 'react'
import { Button, Modal, Text } from '@/components'
import { Link } from 'app-components'
import WizardChoices from '@/components/Wizard/WizardStep/WizardChoice/WizardChoices'
import WizardInput from '@/components/Wizard/WizardStep/WizardInput/WizardInput'
import { useMockableViewModel } from '@/hooks'
import { InputQuestion, WizardPage } from '@/components/Wizard/Wizard'

function isInputQuestion(obj: any): obj is InputQuestion {
  return typeof obj === 'object' && obj != null && 'label' in obj
}

const useViewModel = useMockableViewModel({
  useViewModel() {
    const [helpModalOpen, setHelpModalOpen] = useState(false)

    const openModal = useCallback(() => {
      setHelpModalOpen(true)
    }, [])

    const closeModal = useCallback(() => {
      setHelpModalOpen(false)
    }, [])

    return {
      helpModalOpen,
      openModal,
      closeModal,
    }
  },
  useMockViewModel() {
    return {
      helpModalOpen: false,
      openModal: () => alert('Open modal!'),
      closeModal: () => alert('Open modal!'),
    }
  },
})

function WizardForm(props: WizardPage) {
  if (isInputQuestion(props.question)) {
    return <WizardInput {...props} />
  }

  return <WizardChoices {...props} />
}

export default function WizardStep(props: WizardPage) {
  const { helpModalOpen, openModal, closeModal } = useViewModel()

  if (!props.question) {
    return <></>
  }

  return (
    <div className="flex flex-col h-full">
      <Text variant="subheader">
        {props.question.prompt}
        {props.question.helpText && (
          <span className="whitespace-nowrap">
            &nbsp;
            <Link id={`${props.id}-help-link`} to="" onClick={openModal}>
              Learn more
            </Link>
          </span>
        )}
      </Text>
      <WizardForm {...props} />
      <Modal
        id={`${props.id}-help-modal`}
        open={helpModalOpen}
        title={props.question.helpTitle}
        hideCloseButton
      >
        <Text variant="body4">{props.question.helpText}</Text>
        {props.question.helpSubtitle && (
          <div className="mt-6">
            <Text variant="subheader">{props.question.helpSubtitle}</Text>
            <Text variant="body4" className="mt-2">
              {props.question.helpSubtext}
            </Text>
          </div>
        )}
        <Button
          id="close-modal-button"
          className="mt-6"
          variant="secondary"
          onClick={closeModal}
        >
          Close
        </Button>
      </Modal>
    </div>
  )
}
