import {
  ScreenFillingPage,
  TeslaFleetPartnerSheet,
  ConfigurableText,
  AlertMessage,
} from '@/app/components'
import Logo from '@/app/img/logo-color.webp'
import RedClock from '@/app/img/clock-red.webp'
import TeslaLogo from '@/authenticated/images/tesla.webp'
import OptiwattIconLogo from '@/app/img/logo-icon-colored-filled.webp'
import { Button, Card } from '@/components'
import LinkDiagram from '@/app/features/logos/LinkDiagram'
import { useUrlSearchParam } from '@/hooks'
import Page404 from '@/authenticated/pages/Page404'
import { logException } from '@/logging/sentry'
import { useState } from 'react'
import { match } from 'ts-pattern'

function getCheckTokenAfterExpirationUrl(expiredToken: string) {
  const apiRoot = process.env.REACT_APP_LOCAL_API_GATEWAY

  if (!apiRoot) {
    throw new Error('TeslaFleetConnectionTestExpiredPage: API_ROOT is not set')
  }

  const encodedParams = new URLSearchParams({
    token: expiredToken,
  })

  return `${apiRoot}/api/tesla_check_key_after_token_expiration?${encodedParams.toString()}`
}

function EmailSentStatusMessage(props: {
  status: 'success' | 'error' | 'sending' | 'idle'
}) {
  return (
    <AlertMessage
      show={['success', 'error'].includes(props.status)}
      variant={props.status === 'success' ? 'success' : 'error'}
    >
      {match(props.status)
        .with('success', () => 'A new link has been sent to your email.')
        .with('error', () => 'An error occurred while sending the new link.')
        .otherwise(() => '')}
    </AlertMessage>
  )
}

function TeslaFleetConnectionTestExpiredPage(props: { expiredToken: string }) {
  const [emailSentStatus, setEmailSentStatus] = useState<
    'success' | 'error' | 'sending' | 'idle'
  >('idle')
  const checkTokenAfterExpirationUrl = getCheckTokenAfterExpirationUrl(
    props.expiredToken,
  )

  const handleSendNewLink = async () => {
    setEmailSentStatus('sending')
    try {
      const res = await fetch(checkTokenAfterExpirationUrl, {
        method: 'GET',
      })

      if (res.ok) {
        setEmailSentStatus('success')
      } else {
        setEmailSentStatus('error')
      }
    } catch (error) {
      logException(error)
      setEmailSentStatus('error')
    }
  }

  return (
    <ScreenFillingPage>
      <div className="flex w-full justify-center">
        <div className="flex flex-col items-center max-w-[400px] px-4">
          <img src={Logo} alt="Optiwatt Logo" className="h-9 my-6" />
          <Card className="bg-white px-16">
            <div className="flex flex-col items-center">
              <div className="flex flex-col items-center">
                <div className="mb-4">
                  <LinkDiagram
                    logos={[OptiwattIconLogo, TeslaLogo]}
                    widthOverride="tall"
                  />
                </div>
                <TeslaFleetPartnerSheet className="mb-8" />
              </div>
              <ConfigurableText className="mb-8 text-center" variant="h2">
                The connection test link has expired
              </ConfigurableText>
              <img src={RedClock} alt="Red Clock" className="h-[50px] mb-8" />
              <EmailSentStatusMessage status={emailSentStatus} />
              <Button
                id="tesla-fleet-connection-test-expired-button"
                variant="primary"
                className="mt-8"
                onClick={handleSendNewLink}
                disabled={
                  emailSentStatus === 'sending' || emailSentStatus === 'success'
                }
              >
                Email me a new link
              </Button>
            </div>
          </Card>
        </div>
      </div>
    </ScreenFillingPage>
  )
}

export default function TeslaFleetConnectionTestExpiredPageGuard() {
  const expiredToken = useUrlSearchParam('token')

  if (!expiredToken) {
    return <Page404 />
  }

  return <TeslaFleetConnectionTestExpiredPage expiredToken={expiredToken} />
}
