export const preloadImage = (src: string) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = src
    img.onload = resolve
    img.onerror = reject
  })
}

export const preloadImages = (imagesArray: string[]) => {
  if (imagesArray.length === 0) {
    return Promise.reject('Invalid image sources')
  }

  const promises = imagesArray.map(preloadImage)
  return Promise.all(promises)
}

const ImageUtils = { preloadImage, preloadImages }

export default ImageUtils
