import { useAppSelector } from '../../hooks'
import { remoteConfig } from '../../firebase'

const ALLOWED_STAGING_SERVER = 'optiwatt-test.com'

function isAllowedTestUser(email: string) {
  if (process.env.REACT_APP_ENVIRONMENT !== 'staging') {
    return false
  }
  const emailParts = email.split('@')
  if (emailParts.length !== 2) {
    return false
  }
  const mailServer = email.split('@')[1]
  return mailServer === ALLOWED_STAGING_SERVER
}

export function useIsExperimentalUser() {
  if (process.env.REACT_APP_ENVIRONMENT === 'development') {
    return true
  }

  const email = useAppSelector((state) => state.user.user?.username)
  if (!email || !remoteConfig) {
    return false
  }
  if (isAllowedTestUser(email)) {
    return true
  }
  const experimental_users_string = remoteConfig.getString('experimental_users')
  if (!experimental_users_string) {
    return false
  }
  const experimental_users = JSON.parse(experimental_users_string) as string[]
  return experimental_users.includes(email)
}
