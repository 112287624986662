import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type InProgressUtilityProgramEnrollments =
  InProgressUtilityProgramEnrollment[]
export type InProgressUtilityProgramEnrollment = {
  utilityProgramId: number
  selectedDevices: {
    vehicleIds: number[]
    chargerIds: number[]
    thermostatIds: number[]
  }
  tosAccepted: boolean
}

type SetProgramPayload = Pick<
  InProgressUtilityProgramEnrollment,
  'utilityProgramId' | 'selectedDevices'
>
const inProgressUtilityProgramEnrollmentsSlice = createSlice({
  name: 'inProgressUtilityProgramEnrollments',
  initialState: [] as InProgressUtilityProgramEnrollments,
  reducers: {
    setSelectedDevices: (
      state: InProgressUtilityProgramEnrollments,
      action: PayloadAction<SetProgramPayload>,
    ) => {
      if (
        !state.find(
          (p) => p.utilityProgramId === action.payload.utilityProgramId,
        )
      ) {
        return [...state, { ...action.payload, tosAccepted: false }]
      }
      state = state.map((p) =>
        p.utilityProgramId === action.payload.utilityProgramId
          ? { ...p, ...action.payload }
          : p,
      )
      return state
    },
    setTosAccepted: (
      state: InProgressUtilityProgramEnrollments,
      action: PayloadAction<{ utilityProgramId: number; accepted: boolean }>,
    ) => {
      if (
        !state.find(
          (p) => p.utilityProgramId === action.payload.utilityProgramId,
        )
      ) {
        return [
          ...state,
          {
            utilityProgramId: action.payload.utilityProgramId,
            selectedDevices: {
              chargerIds: [],
              thermostatIds: [],
              vehicleIds: [],
            },
            tosAccepted: action.payload.accepted,
          },
        ]
      }
      return state.map((p) =>
        p.utilityProgramId === action.payload.utilityProgramId
          ? { ...p, tosAccepted: action.payload.accepted }
          : p,
      )
    },
    completeEnrollment: (
      state: InProgressUtilityProgramEnrollments,
      action: PayloadAction<number>,
    ) => {
      return state.filter((p) => p.utilityProgramId !== action.payload)
    },
  },
})

export const { setSelectedDevices, setTosAccepted, completeEnrollment } =
  inProgressUtilityProgramEnrollmentsSlice.actions

export const selectInProgressEnrollment =
  (utilityProgramId: number) =>
  (state: {
    inProgressUtilityProgramEnrollments: InProgressUtilityProgramEnrollments
  }) =>
    state.inProgressUtilityProgramEnrollments.find(
      (p) => p.utilityProgramId === utilityProgramId,
    )

export default inProgressUtilityProgramEnrollmentsSlice.reducer
