// Converts a military time string to a short format (e.g., 12a)
export default function toShortTimeString(time: string) {
  const [hours, minutes] = time.split(':').map((item) => Number(item))
  const output = []

  if (hours === 0 || hours === 12) output.push(12)
  else if (hours < 12) output.push(hours)
  else if (hours > 12) output.push(hours - 12)

  if (minutes) output.push(minutes)

  const meridiem = hours >= 12 ? 'p' : 'a'

  return `${output.join(':')}${meridiem}`
}
