import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import data from './resources/data.json'
import {
  Box,
  Button as MuiButton,
  Dialog,
  DialogContent,
  Grid as MuiGrid,
  Typography as MuiTypography,
} from '@material-ui/core'
import DialogTitleCloseable from '../DialogTitleCloseable'

const Grid = styled(MuiGrid)(spacing)
const Button = styled(MuiButton)(spacing)

const Typography = styled(MuiTypography)(spacing)

const useStyles = makeStyles((theme) =>
  createStyles({
    dialog: {
      margin: theme.spacing(4),
    },
    stepBox: {
      borderRadius: '8px',
      width: '30px',
      height: '30px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      userSelect: 'none',
      backgroundColor: theme.palette.blue?.background,
      marginRight: theme.spacing(4),
    },
    stepText: {
      textAlign: 'center',
      fontWeight: 700,
      color: theme.palette.blue?.main,
    },
    labelText: {
      fontWeight: 700,
    },
    button: {
      width: '100%',
      backgroundColor: '#F2F2F2',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      '&:hover': {
        backgroundColor: theme.palette.blue?.background,
      },
    },
    header: {
      fontSize: '16px',
      marginTop: theme.spacing(2),
      fontWeight: 700,
    },
    listItemContainer: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  }),
)

export interface ListItemProps {
  label: string
  step: number
}

const ListItem = ({ step, label }: ListItemProps) => {
  const classes = useStyles()

  return (
    <Grid container alignItems="center" className={classes.listItemContainer}>
      <Grid item>
        <Box className={classes.stepBox}>
          <Typography className={classes.stepText}>{step}</Typography>
        </Box>
      </Grid>
      <Grid item xs>
        <Typography variant="body1" className={classes.labelText}>
          {label}
        </Typography>
      </Grid>
    </Grid>
  )
}

export interface Props {
  open: boolean
  onSubmit?: () => void
  onClose?: () => void
  eventIsOccurringNow: boolean
}

export default function BaselineShiftingEventDialog({
  open,
  onClose = () => {},
  eventIsOccurringNow,
}: Props) {
  const classes = useStyles()
  const history = useHistory()

  const navigateToChargeSettings = () => {
    history.push({
      pathname: '/devices',
      search: '?show-basline-settings=true',
    })
  }

  return (
    <Dialog
      classes={{ root: classes.dialog }}
      onClose={onClose}
      maxWidth={'xs'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      open={open}
    >
      <DialogTitleCloseable onClose={onClose}>
        <Typography variant="h5" className={classes.header}>
          {data.dialog.title}
        </Typography>
      </DialogTitleCloseable>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <ListItem step={1} label={data.dialog.step1} />
          </Grid>
          <Grid item xs={12}>
            <ListItem
              step={2}
              label={
                eventIsOccurringNow
                  ? data.dialog.step2OccurringNow
                  : data.dialog.step2OccurringSoon
              }
            />
          </Grid>
          <Grid item xs={12}>
            <ListItem step={3} label={data.dialog.step3} />
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.button}
              onClick={navigateToChargeSettings}
            >
              Change Settings
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
