import { useEffect } from 'react'
import {
  AnnotationFetchActionCreator,
  AnnotationFetchActionDispatcher,
  AnnotationMeta,
  DataStatusSliceState,
  FetchArgs,
  FetchOptions,
  ID,
  IdentifyingMeta,
  PaginatedResourcePayload,
  QuerySpec,
  QuerySpecMeta,
  QueryState,
  RequestActionTypes,
  RequestArgs,
  RequestDataSelector,
  RequestRootSelector,
  RequestState,
  RequestStatus,
  RequestTypes,
  ResourceApiConfig,
  ResourceFetchActionCreator,
  ResourceFetchActionDispatcher,
  ResourceModel,
  ResourceModelQueryResult,
  UpdateArgs,
  UpdateMeta,
} from './types'
import {
  createAction,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityAdapter,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit'
import {
  ApiError,
  createAction as createAPIMiddlewareAction,
} from 'redux-api-middleware'
import { getApiErrorMessage } from '../utils/errorUtilities'
import {
  canRefreshResource,
  createResourceSelectors,
  selectHeadersWithConfig,
  shouldPopulateResource,
} from './selectors'
import {
  buildRequestUrl,
  createQuerySpecApiMiddlewareTypes,
  createQuerySpecFromCreateArgs,
  createQuerySpecFromFetchArgs,
  extractResponseError,
  generateApiPath,
  getBaseUrl,
  getFetchOptions,
  getIdForResource,
  querySpecToIDString,
  removeTrailingAndLeadingSlashes,
  resourceWithIdAddedIfNeeded,
  useAppDispatch,
  useAppSelector,
} from './utils'
import { createFetchHook } from './hooks/createFetchHook'
import { createCreationHook } from './hooks/createCreationHook'
import { v4 as uuidv4 } from 'uuid'
import createExternalConfig from './createExternalConfig'

// @todo figure out how to make RootState work without introducing a circular dependency
type RootState = any

const MIDDLEWARE_RESOLVED_SUFFIX = 'MIDDLEWARE_RESOLVED'

function FetchActionTypes(name: string) {
  return {
    Request: `@@${name}/GET_REQUEST`,
    Success: `@@${name}/GET_SUCCESS`,
    Failure: `@@${name}/GET_FAILURE`,
  }
}

function FetchAnnotationActionTypes(name: string) {
  return {
    Request: `@@${name}/GET_ANNOTATION_REQUEST`,
    Success: `@@${name}/GET_ANNOTATION_SUCCESS`,
    Failure: `@@${name}/GET_ANNOTATION_FAILURE`,
  }
}

function CreateAnnotationActionTypes(name: string) {
  return {
    Request: `@@${name}/CREATE_ANNOTATION_REQUEST`,
    Success: `@@${name}/CREATE_ANNOTATION_SUCCESS`,
    Failure: `@@${name}/CREATE_ANNOTATION_FAILURE`,
  }
}

function CreationActionTypes(name: string) {
  return {
    Request: `@@${name}/CREATE_REQUEST`,
    Success: `@@${name}/CREATE_SUCCESS`,
    Failure: `@@${name}/CREATE_FAILURE`,
  }
}

function UpdateActionTypes(name: string) {
  return {
    Request: `@@${name}/UPDATE_REQUEST`,
    Success: `@@${name}/UPDATE_SUCCESS`,
    Failure: `@@${name}/UPDATE_FAILURE`,
  }
}

function DeletionActionTypes(name: string) {
  return {
    Request: `@@${name}/DELETE_REQUEST`,
    Success: `@@${name}/DELETE_SUCCESS`,
    Failure: `@@${name}/DELETE_FAILURE`,
  }
}

function createInvalidateAction(name: string) {
  return createAction(`@@${name}/INVALIDATE`)
}

function getActionTypes(name: string) {
  return {
    fetch: {
      ...FetchActionTypes(name),
      SuccessResolved: `${
        FetchActionTypes(name).Success
      }_${MIDDLEWARE_RESOLVED_SUFFIX}`,
    },
    update: {
      ...UpdateActionTypes(name),
      SuccessResolved: `${
        UpdateActionTypes(name).Success
      }_${MIDDLEWARE_RESOLVED_SUFFIX}`,
    },
    create: {
      ...CreationActionTypes(name),
      SuccessResolved: `${
        CreationActionTypes(name).Success
      }_${MIDDLEWARE_RESOLVED_SUFFIX}`,
    },
    delete: DeletionActionTypes(name),
    invalidate: createInvalidateAction(name),
  } as const
}

function createResourceSlice<
  Resource extends ResourceModel,
  Annotation = unknown,
>(args: {
  name: string
  paginationKey: string
  adapter: EntityAdapter<ResourceModelQueryResult<Resource>>
  queryStateAdapter: EntityAdapter<QueryState>
  scratchPadAdapter: EntityAdapter<ResourceModelQueryResult<Resource>>
  apiConfig: ResourceApiConfig<Resource>
  computedId?: (resource: Resource) => string
}) {
  const {
    name,
    paginationKey,
    adapter,
    queryStateAdapter,
    scratchPadAdapter,
    apiConfig,
    computedId,
  } = args

  type PaginatedResourcePayload<Resource> = {
    [key: string]: Resource[]
  }
  /**
   * The payload of a successful fetch request. This can be a single resource, an array of resources, or a paginated response.
   */
  type GetResourceResponsePayload<Resource> =
    | Resource[]
    | PaginatedResourcePayload<Resource>
    | Resource

  /**
   * The payload of a successful create request. This can be a single resource or an array of resources.
   */
  type CreateResourceResponsePayload<Resource> = Resource | Resource[]

  type AnnotationResult = Annotation | null

  type PaginatedAnnotationPayload = {
    [key: string]: AnnotationResult[]
  }
  type GetAnnotationPayload = PaginatedAnnotationPayload | AnnotationResult

  const getResourceListFromGetPayload = (
    payload: GetResourceResponsePayload<Resource>,
  ): Resource[] => {
    if (Array.isArray(payload)) {
      return payload
    } else if (paginationKey in payload) {
      return (payload as PaginatedResourcePayload<Resource>)[paginationKey]
    }
    return [payload as Resource]
  }

  const initialState: DataStatusSliceState<Resource> = {
    data: adapter.getInitialState({
      status: RequestStatus.Idle,
      annotationStatus: {},
      errors: null,
      annotationErrors: {},
    }),
    status: queryStateAdapter.getInitialState(),
    scratchPad: scratchPadAdapter.getInitialState(),
  }

  const updateQueryStateStatus = <
    WritableState extends { status: EntityState<QueryState> },
  >(
    writableState: WritableState,
    queryState: QueryState,
  ) => {
    const id = querySpecToIDString(queryState)
    // Here we use set instead of upsert because we want to replace the entire
    // query state object so that subscribers to queryState.selectors.selectAll
    // will be notified of the change
    // Note: Immer should pick up the changes from upsert, but it doesn't seem to
    // be working (as expected). This is a workaround.
    queryStateAdapter.setOne(writableState.status, {
      ...(writableState.status.entities[id] ?? {}),
      ...queryState,
    })
  }

  /**
   * A function used to fetch the current state of a resource from the store.
   * This is useful for making updates that depend on the current state of a resource.
   * @returns A copy of the current state of the resource in the store that matches the query spec.
   */
  const getCurrentStateResourceCopyForSpec = <
    State extends EntityState<ResourceModelQueryResult<Resource>>,
  >(
    state: State,
    id: ID | undefined,
  ) => {
    if (!id || !state.entities[id]) {
      return
    }
    return {
      ...state.entities[id],
    } as ResourceModelQueryResult<Resource>
  }

  /**
   * @param state
   * @param resource - the resource to add the querySpecHash to
   * @param querySpec - the query spec used to request the resource that we want to add to the resource as a querySpecHash
   * @param id - the id of the resource required for finding existing query spec hashes
   *
   * Internally, resources have query spec hashes attached to them. each query spec is a hash of
   * a query that provided the resource. These hashes are used for caching. If we see two instances of the
   * same query at different points in the app, we can use the cached resource instead of making a new request.
   * @returns the resource with the hash of the provided query spec added to its querySpecHashes array
   */
  const resourceWithQuerySpecHashAdded = <ResponseResource>(
    state: EntityState<ResourceModelQueryResult<Resource>>,
    resource: ResponseResource,
    querySpec: QuerySpec,
    id: ID | undefined,
  ) => {
    const currentStateResource = getCurrentStateResourceCopyForSpec(state, id)
    const querySpecHash = querySpecToIDString(querySpec)
    return {
      ...resource,
      querySpecHashes: [
        ...(currentStateResource?.querySpecHashes ?? []),
        querySpecHash,
      ],
    }
  }

  /**
   * Takes resources returned from the API and adds internal properties required for caching/tracking/etc.
   * Use this on an API response payload before adding it to the store.
   * @param rawResource - the resources returned from the API
   * @param querySpec - the query spec used to request the resource
   * @param state - the current state of the resource slice
   * @returns - the resources with internal properties required for caching/tracking/etc. added.
   */

  function transformRawResourceResponseToResourceModelQueryResults<
    ResponseResource extends Partial<ResourceModel>,
  >(
    rawResources: ResponseResource[],
    querySpec: QuerySpec,
    computedId: ((resource: ResponseResource) => string) | undefined,
    state: EntityState<ResourceModelQueryResult<Resource>>,
  ): ResourceModelQueryResult<Resource>[] {
    const resourcesWithIdsAndHashes = rawResources
      // Add IDs to the resources, which will be needed for subsequent requests like updates
      .map((resource) =>
        resourceWithIdAddedIfNeeded(
          resource,
          computedId ? computedId(resource) : uuidv4(),
        ),
      )
      // Add query spec hashes to the resources so we can use them for caching
      .map((resource) =>
        resourceWithQuerySpecHashAdded(state, resource, querySpec, resource.id),
      )

    return resourcesWithIdsAndHashes as ResourceModelQueryResult<Resource>[]
  }

  return createSlice({
    name: name,
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder
        .addMatcher(
          (action) =>
            [
              FetchActionTypes(name).Request,
              CreationActionTypes(name).Request,
              UpdateActionTypes(name).Request,
              DeletionActionTypes(name).Request,
            ].includes(action.type),
          (state, action: PayloadAction<unknown, string, QuerySpecMeta>) => {
            state.data.status = RequestStatus.Loading
            updateQueryStateStatus(state, {
              status: RequestStatus.Loading,
              ...action.meta.querySpec,
            })
          },
        )
        .addMatcher(
          (action) => action.type === UpdateActionTypes(name).Request,
          (
            state,
            action: PayloadAction<
              unknown,
              string,
              UpdateMeta<Resource> & QuerySpecMeta
            >,
          ) => {
            const querySpec = action.meta.querySpec
            updateQueryStateStatus(state, {
              status: RequestStatus.Loading,
              ...querySpec,
            })

            if (!action.meta.optimisticUpdate) {
              return
            }

            // Perform optimistic update
            let idsToUpdate = action.meta.updateIds

            if (!idsToUpdate && querySpec.id) {
              idsToUpdate = [querySpec.id]
            }
            if (!idsToUpdate) {
              console.error(
                'Cannot perform optimistic update without either an id or updateIds',
              )
              return
            }

            for (const updateId of idsToUpdate) {
              // If the resource with the spec if exists, copy it. Otherwise, throw an error
              const currentStateResource = getCurrentStateResourceCopyForSpec(
                state.data as EntityState<ResourceModelQueryResult<Resource>>,
                updateId,
              )
              if (!currentStateResource) {
                throw new Error(
                  `Cannot find ${name} resource with id ${updateId} in state during optimistic update`,
                )
              }
              adapter.updateOne(
                state.data as EntityState<ResourceModelQueryResult<Resource>>,
                {
                  id: updateId,
                  changes: resourceWithQuerySpecHashAdded(
                    state.data as EntityState<
                      ResourceModelQueryResult<Resource>
                    >,
                    action.meta.optimisticUpdate,
                    querySpec,
                    updateId,
                  ),
                },
              )
              // If the optimistic update fails, we want to revert to the previous state, which is stored in the scratch pad
              scratchPadAdapter.upsertOne(
                state.scratchPad as EntityState<
                  ResourceModelQueryResult<Resource>
                >,
                currentStateResource,
              )
            }
          },
        )
        // On a successful creation, adds or replaces the returned resource or resources
        // Note: multiple returned resources is supported
        .addMatcher(
          (action) =>
            action.type ===
            `${
              CreationActionTypes(name).Success
            }_${MIDDLEWARE_RESOLVED_SUFFIX}`,
          (
            state,
            action: PayloadAction<
              CreateResourceResponsePayload<Resource>,
              string,
              QuerySpecMeta
            >,
          ) => {
            const querySpec = action.meta.querySpec
            state.data.status = RequestStatus.Succeeded
            updateQueryStateStatus(state, {
              status: RequestStatus.Succeeded,
              error: null,
              ...querySpec,
            })
            const processedResources =
              transformRawResourceResponseToResourceModelQueryResults(
                Array.isArray(action.payload)
                  ? action.payload
                  : [action.payload],
                querySpec,
                computedId,
                state.data as EntityState<ResourceModelQueryResult<Resource>>,
              )
            adapter.upsertMany(
              state.data as EntityState<ResourceModelQueryResult<Resource>> &
                RequestState,
              processedResources,
            )
          },
        )
        // On a successful update, adds or replaces the returned resource
        .addMatcher(
          (action) =>
            action.type ===
            `${UpdateActionTypes(name).Success}_${MIDDLEWARE_RESOLVED_SUFFIX}`,
          (
            state,
            action: PayloadAction<Resource | Resource[], string, QuerySpecMeta>,
          ) => {
            const querySpec = action.meta.querySpec
            state.data.status = RequestStatus.Succeeded
            updateQueryStateStatus(state, {
              status: RequestStatus.Succeeded,
              error: null,
              ...querySpec,
            })

            const processedResources =
              transformRawResourceResponseToResourceModelQueryResults(
                Array.isArray(action.payload)
                  ? action.payload
                  : [action.payload],
                querySpec,
                computedId,
                state.data as EntityState<ResourceModelQueryResult<Resource>>,
              )

            adapter.upsertMany(
              state.data as EntityState<ResourceModelQueryResult<Resource>> &
                RequestState,
              processedResources,
            )
          },
        )
        // On a successful fetch, adds or replaces the returned resources
        .addMatcher(
          (action) =>
            action.type ===
            `${FetchActionTypes(name).Success}_${MIDDLEWARE_RESOLVED_SUFFIX}`,
          (
            state,
            action: PayloadAction<
              GetResourceResponsePayload<Resource>,
              string,
              QuerySpecMeta
            >,
          ) => {
            const querySpec = action.meta.querySpec
            state.data.status = RequestStatus.Succeeded
            updateQueryStateStatus(state, {
              status: RequestStatus.Succeeded,
              error: null,
              ...querySpec,
            })
            const processedResources =
              transformRawResourceResponseToResourceModelQueryResults(
                getResourceListFromGetPayload(action.payload),
                querySpec,
                computedId,
                state.data as EntityState<ResourceModelQueryResult<Resource>>,
              )
            const updateFunction = apiConfig.invalidateOnRequest
              ? adapter.setAll
              : adapter.upsertMany
            updateFunction(
              state.data as EntityState<ResourceModelQueryResult<Resource>> &
                RequestState,
              processedResources,
            )
          },
        )
        // On a successful delete, removes the returned resource
        .addMatcher(
          (action) => action.type === DeletionActionTypes(name).Success,
          (
            state,
            action: PayloadAction<
              unknown,
              string,
              IdentifyingMeta & QuerySpecMeta
            >,
          ) => {
            const querySpec = action.meta.querySpec
            state.data.status = RequestStatus.Succeeded
            updateQueryStateStatus(state, {
              status: RequestStatus.Succeeded,
              error: null,
              ...querySpec,
            })
            adapter.removeOne(
              state.data as EntityState<ResourceModelQueryResult<Resource>> &
                RequestState,
              action.meta.id,
            )
          },
        )
        .addMatcher(
          (action) =>
            [
              FetchActionTypes(name).Failure,
              CreationActionTypes(name).Failure,
              DeletionActionTypes(name).Failure,
            ].includes(action.type),
          (state, action: PayloadAction<ApiError, string, QuerySpecMeta>) => {
            const querySpec = action.meta.querySpec
            state.data.status = RequestStatus.Failed
            state.data.errors = getApiErrorMessage(action.payload)
            updateQueryStateStatus(state, {
              status: RequestStatus.Failed,
              error: extractResponseError(action.payload),
              ...querySpec,
            })
          },
        )
        .addMatcher(
          (action) => action.type === UpdateActionTypes(name).Failure,
          (
            state,
            action: PayloadAction<
              ApiError,
              string,
              UpdateMeta<Resource> & QuerySpecMeta
            >,
          ) => {
            const querySpec = action.meta.querySpec
            state.data.status = RequestStatus.Failed
            state.data.errors = getApiErrorMessage(action.payload)
            updateQueryStateStatus(state, {
              status: RequestStatus.Failed,
              error: extractResponseError(action.payload),
              ...querySpec,
            })

            if (!action.meta.optimisticUpdate) {
              return
            }

            // If the optimistic update fails, we want to revert to the previous state
            // Perform optimistic update
            let idsToUpdate = action.meta.updateIds

            if (!idsToUpdate && querySpec.id) {
              idsToUpdate = [querySpec.id]
            }
            if (!idsToUpdate) {
              console.error(
                'Cannot perform optimistic update without either an id or updateIds',
              )
              return
            }

            for (const updateId of idsToUpdate) {
              const scratchPadResource = {
                ...state.scratchPad.entities[updateId],
              }
              if (!scratchPadResource) {
                throw new Error(
                  `Cannot find ${name} resource with id ${querySpec.id} in state during optimistic update rollback`,
                )
              }
              adapter.updateOne(
                state.data as EntityState<ResourceModelQueryResult<Resource>> &
                  RequestState,
                {
                  id: updateId,
                  changes:
                    scratchPadResource as ResourceModelQueryResult<Resource>,
                },
              )
            }
          },
        )
        .addMatcher(
          (action) => action.type === createInvalidateAction(name).type,
          (state) => {
            state.data.status = RequestStatus.Invalidated
            adapter.removeAll(
              state.data as EntityState<ResourceModelQueryResult<Resource>> &
                RequestState,
            )
          },
        )
        // Annotation fetchers
        .addMatcher(
          (action) =>
            [
              FetchAnnotationActionTypes(name).Request,
              CreateAnnotationActionTypes(name).Request,
            ].includes(action.type),
          (
            state,
            action: PayloadAction<
              unknown,
              string,
              AnnotationMeta & QuerySpecMeta
            >,
          ) => {
            const { annotation, paginated, querySpec } = action.meta
            state.data.annotationStatus[annotation] = RequestStatus.Loading
            updateQueryStateStatus(state, {
              status: RequestStatus.Loading,
              ...querySpec,
            })
          },
        )
        .addMatcher(
          (action) =>
            action.type ===
            `${
              CreateAnnotationActionTypes(name).Success
            }_${MIDDLEWARE_RESOLVED_SUFFIX}`,
          (
            state,
            action: PayloadAction<
              Annotation,
              string,
              AnnotationMeta & QuerySpecMeta
            >,
          ) => {
            state.data.annotationStatus[action.meta.annotation] =
              RequestStatus.Succeeded
            const { id, annotation, paginated, querySpec } = action.meta
            updateQueryStateStatus(state, {
              status: RequestStatus.Succeeded,
              error: null,
              ...querySpec,
            })
            let annotationData: Annotation | Annotation[] = action.payload
            if (paginated) {
              const existingAnnotationData = state.data.entities[id]?.[
                annotation
              ] as Annotation | undefined
              annotationData = [annotationData].concat(
                existingAnnotationData ?? [],
              )
            }
            adapter.upsertOne(
              state.data as EntityState<ResourceModelQueryResult<Resource>> &
                RequestState,
              // todo - this is a hack to get the type to work
              {
                id: querySpec.id,
                [annotation]: annotationData,
              } as ResourceModelQueryResult<Resource>,
            )
          },
        )
        // On a successful annotation fetch, adds or replaces the returned annotation(s)
        .addMatcher(
          (action) =>
            action.type ===
            `${
              FetchAnnotationActionTypes(name).Success
            }_${MIDDLEWARE_RESOLVED_SUFFIX}`,
          (
            state,
            action: PayloadAction<
              GetAnnotationPayload,
              string,
              AnnotationMeta & QuerySpecMeta
            >,
          ) => {
            // Add the fetched annotation data to the resource identified by id
            state.data.annotationStatus[action.meta.annotation] =
              RequestStatus.Succeeded
            const { annotation, paginated, querySpec } = action.meta
            updateQueryStateStatus(state, {
              status: RequestStatus.Succeeded,
              error: null,
              ...querySpec,
            })
            const annotationData = paginated
              ? (action.payload as PaginatedAnnotationPayload)[paginationKey]
              : action.payload
            adapter.upsertOne(
              state.data as EntityState<ResourceModelQueryResult<Resource>> &
                RequestState,
              // todo - this is a hack to get the type to work
              {
                id: querySpec.id,
                [annotation]: annotationData ?? null,
              } as ResourceModelQueryResult<Resource>,
            )
          },
        )
        .addMatcher(
          (action) =>
            [
              FetchAnnotationActionTypes(name).Failure,
              CreateAnnotationActionTypes(name).Failure,
            ].includes(action.type),
          (
            state,
            action: PayloadAction<
              ApiError,
              string,
              AnnotationMeta & QuerySpecMeta
            >,
          ) => {
            const { annotation, paginated, querySpec } = action.meta
            state.data.annotationStatus[annotation] = RequestStatus.Failed
            state.data.annotationErrors[annotation] = {
              ...getApiErrorMessage(action.payload),
              id: action.meta.id,
            }
            updateQueryStateStatus(state, {
              status: RequestStatus.Failed,
              error: extractResponseError(action.payload),
              ...querySpec,
            })
          },
        )
    },
  })
}

function getAnnotationConfig<Resource extends ResourceModel>(
  apiConfig: ResourceApiConfig<Resource>,
  annotation: string,
  resourceName: string,
) {
  const annotationConfig = apiConfig.annotations?.[annotation]
  if (!annotationConfig) {
    throw new Error(
      `No annotation name "${annotation}" found for resource "${resourceName}"`,
    )
  }
  return annotationConfig
}

const resolveMiddlewares = async <Resource extends ResourceModel>(
  args: Parameters<
    typeof createAPIMiddlewareActionWithMiddlewareResolution<Resource>
  >[0],
  payload: any,
  getState: () => RootState,
) => {
  const { apiConfig, paginationKey } = args
  let resolvedPayload = payload
  for (const middleware of apiConfig.middlewares ?? []) {
    if (payload) {
      if (paginationKey in resolvedPayload) {
        resolvedPayload = await middleware(
          args,
          (resolvedPayload as PaginatedResourcePayload<Resource>)[
            paginationKey
          ],
          getState,
        )
      } else {
        resolvedPayload = await middleware(
          args,
          resolvedPayload as Resource | Resource[],
          getState,
        )
      }
    }
  }
  return resolvedPayload
}

function createAPIMiddlewareActionWithMiddlewareResolution<
  Resource extends ResourceModel,
>(
  args: Omit<Parameters<typeof createAPIMiddlewareAction>[0], 'headers'> & {
    headers:
      | Record<string, string>
      | ((state: RootState) => Record<string, string>)
    apiConfig: ResourceApiConfig<Resource>
    paginationKey: string
    requestType: RequestTypes
  },
) {
  const { apiConfig, paginationKey, requestType, ...apiMiddlewareActionArgs } =
    args

  return async (dispatch: any, getState: () => RootState) => {
    const actionResponse = await dispatch(
      createAPIMiddlewareAction(apiMiddlewareActionArgs),
    )

    if (!actionResponse || actionResponse.error) {
      let errorString: string
      try {
        errorString = JSON.stringify(actionResponse)
      } catch {
        errorString = 'unparsed error response'
      }
      console.error(
        'Error in createAPIMiddlewareActionWithMiddlewareResolution',
        errorString,
      )

      return actionResponse
    }

    const middlewareResponse = await resolveMiddlewares(
      args,
      actionResponse.payload,
      getState,
    )

    dispatch({
      type: `${actionResponse.type}_${MIDDLEWARE_RESOLVED_SUFFIX}`,
      payload: middlewareResponse,
      meta: {
        ...actionResponse.meta,
      },
    })

    return actionResponse
  }
}

function createFetchActions<Resource extends ResourceModel>(args: {
  name: string
  apiConfig: ResourceApiConfig<Resource>
  paginationKey: string
  inFlightFetchSet: Set<string>
}) {
  const { name, apiConfig, paginationKey, inFlightFetchSet } = args

  function createFetchAction<RequestPayload, RequestMeta>(
    args: FetchArgs = {},
    actionTypes: RequestActionTypes<RequestPayload, RequestMeta>,
  ) {
    const querySpec = createQuerySpecFromFetchArgs(args)
    const pathSegments = querySpec.pathSegments
    const endpoint = buildRequestUrl(apiConfig, { ...args, pathSegments })

    const actionTypesWithQueryMeta = createQuerySpecApiMiddlewareTypes(
      actionTypes,
      querySpec,
    )

    const fetchActionWithMiddlewareResolution =
      createAPIMiddlewareActionWithMiddlewareResolution({
        method: querySpec.method,
        headers: selectHeadersWithConfig(
          { 'Content-Type': 'application/json' },
          apiConfig,
        ),
        types: [
          actionTypesWithQueryMeta.Request,
          actionTypesWithQueryMeta.Success,
          actionTypesWithQueryMeta.Failure,
        ],
        endpoint: endpoint,
        ...(querySpec.params ? { params: querySpec.params } : {}),
        apiConfig,
        paginationKey,
        requestType: RequestTypes.Fetch,
      })

    return async (dispatch: any, getState: () => RootState) => {
      if (inFlightFetchSet.has(querySpecToIDString(querySpec))) {
        return
      }
      inFlightFetchSet.add(querySpecToIDString(querySpec))
      let response = null
      try {
        response = await dispatch(fetchActionWithMiddlewareResolution)
      } finally {
        // if the dispatch fails, we still want to remove the query spec from the inFlightFetchSet
        // but we don't want to consume the error as it is undefined behavior
        inFlightFetchSet.delete(querySpecToIDString(querySpec))
      }

      return response
    }
  }

  // This fetch action is used to fetch a resource of type `Resource`.
  // The slice we create below will match the types produced by this action.
  const fetch: ResourceFetchActionCreator = (args) =>
    createFetchAction(args, FetchActionTypes(name))

  const fetchAnnotation: AnnotationFetchActionCreator = (
    id,
    annotation,
    params,
  ) => {
    const { paginated, path } = getAnnotationConfig(apiConfig, annotation, name)

    const fetchAnnotationActionTypesWithMeta: RequestActionTypes<
      never,
      AnnotationMeta
    > = {
      Request: {
        type: FetchAnnotationActionTypes(name).Request,
        meta: { annotation, id, paginated },
      },
      Success: {
        type: FetchAnnotationActionTypes(name).Success,
        meta: { annotation, id, paginated },
      },
      Failure: {
        type: FetchAnnotationActionTypes(name).Failure,
        meta: { annotation, id, paginated },
      },
    }

    const pathSegments = [path]
    return createFetchAction(
      { pathSegments, params, id },
      fetchAnnotationActionTypesWithMeta,
    )
  }

  return { fetch, fetchAnnotation }
}

function createCreationAction<Resource extends ResourceModel>(args: {
  name: string
  apiConfig: ResourceApiConfig<Resource>
}) {
  const { name, apiConfig } = args

  const creationActionTypes = CreationActionTypes(name)

  return (resource: Partial<Resource>, args?: RequestArgs) => {
    const endpoint = buildRequestUrl(apiConfig, args ?? {})

    const method = 'POST'
    const body = JSON.stringify(resource)
    const querySpec = createQuerySpecFromCreateArgs(resource, args)

    const creationActionTypesWithQueryMeta = createQuerySpecApiMiddlewareTypes(
      creationActionTypes,
      querySpec,
    )

    return createAPIMiddlewareActionWithMiddlewareResolution({
      method,
      headers: selectHeadersWithConfig(
        { 'Content-Type': 'application/json' },
        apiConfig,
      ),
      types: [
        creationActionTypesWithQueryMeta.Request,
        creationActionTypesWithQueryMeta.Success,
        creationActionTypesWithQueryMeta.Failure,
      ],
      endpoint: endpoint,
      body,
      apiConfig,
      paginationKey: '',
      requestType: RequestTypes.Create,
    })
  }
}

function createDeletionAction<Resource extends ResourceModel>(args: {
  name: string
  apiConfig: ResourceApiConfig<Resource>
}) {
  const { name, apiConfig } = args

  const deletionActionTypeNames = DeletionActionTypes(name)

  return (path: string | number, args?: RequestArgs) => {
    const pathSegments = [...(args?.pathSegments ?? []), path.toString()]
    const endpoint = buildRequestUrl(apiConfig, { pathSegments })

    const id = getIdForResource({ path, id: args?.id })

    const method = 'DELETE'
    const deletionActionTypes = {
      Request: { type: deletionActionTypeNames.Request, meta: { id } },
      Success: { type: deletionActionTypeNames.Success, meta: { id } },
      Failure: { type: deletionActionTypeNames.Failure, meta: { id } },
    }
    const deletionActionTypesWitQueryMeta = createQuerySpecApiMiddlewareTypes(
      deletionActionTypes,
      {
        ...(args ?? {}),
        id,
        method,
        pathSegments,
      },
    )

    return createAPIMiddlewareAction({
      method,
      headers: selectHeadersWithConfig(
        { 'Content-Type': 'application/json' },
        apiConfig,
      ),
      types: [
        deletionActionTypesWitQueryMeta.Request,
        deletionActionTypesWitQueryMeta.Success,
        deletionActionTypesWitQueryMeta.Failure,
      ],
      endpoint: endpoint,
    })
  }
}

function createAnnotationCreationAction<Resource extends ResourceModel>(args: {
  name: string
  apiConfig: ResourceApiConfig<Resource>
  paginationKey: string
}) {
  const { name, apiConfig } = args

  return <ResourceAnnotation>(
    path: string | number,
    annotation: string,
    annotationData: ResourceAnnotation,
  ) => {
    const id = getIdForResource({ path: String(path) })
    const { paginated } = getAnnotationConfig(apiConfig, annotation, name)

    const creationActionTypes = {
      Request: {
        type: CreateAnnotationActionTypes(name).Request,
        meta: { annotation, id, paginated },
      },
      Success: {
        type: CreateAnnotationActionTypes(name).Success,
        meta: { annotation, id, paginated },
      },
      Failure: {
        type: CreateAnnotationActionTypes(name).Failure,
        meta: { annotation, id, paginated },
      },
    }

    const pathSegments = [path.toString(), annotation]
    const endpointPath = generateApiPath(apiConfig, { pathSegments })

    const method = 'POST'
    const body = JSON.stringify(annotationData)
    const creationActionTypesWithQueryMeta = createQuerySpecApiMiddlewareTypes(
      creationActionTypes,
      {
        id,
        pathSegments,
        method,
        body,
      },
    )

    return createAPIMiddlewareActionWithMiddlewareResolution({
      method,
      headers: selectHeadersWithConfig(
        { 'Content-Type': 'application/json' },
        apiConfig,
      ),
      types: [
        creationActionTypesWithQueryMeta.Request,
        creationActionTypesWithQueryMeta.Success,
        creationActionTypesWithQueryMeta.Failure,
      ],
      endpoint: `${getBaseUrl(apiConfig)}/api/${removeTrailingAndLeadingSlashes(
        endpointPath,
      )}/`,
      body,
      apiConfig,
      paginationKey: '',
      requestType: RequestTypes.Create,
    })
  }
}

function createUpdateManyAction<Resource extends ResourceModel>(args: {
  name: string
  apiConfig: ResourceApiConfig<Resource>
}) {
  const { name, apiConfig } = args

  return (
    path: string | number,
    ids: ID[],
    resource: Partial<Resource>,
    args?: UpdateArgs,
  ) => {
    const pathSegments = [...(args?.pathSegments ?? []), path.toString()]
    const endpoint = buildRequestUrl(apiConfig, { pathSegments })

    const meta = {
      optimisticUpdate: args?.optimistic ? resource : undefined,
      updateIds: ids,
    }
    const updateActionTypes = {
      Request: {
        type: UpdateActionTypes(name).Request,
        meta,
      },
      Success: {
        type: UpdateActionTypes(name).Success,
        meta,
      },
      Failure: {
        type: UpdateActionTypes(name).Failure,
        meta,
      },
    }
    const method = 'PATCH'
    const body = JSON.stringify(resource)
    const updateActionTypesWithQueryMeta = createQuerySpecApiMiddlewareTypes(
      updateActionTypes,
      {
        ...(args ?? {}),
        method,
        body,
        pathSegments,
      },
    )

    return createAPIMiddlewareActionWithMiddlewareResolution({
      method,
      headers: selectHeadersWithConfig(
        { 'Content-Type': 'application/json' },
        apiConfig,
      ),
      types: [
        updateActionTypesWithQueryMeta.Request,
        updateActionTypesWithQueryMeta.Success,
        updateActionTypesWithQueryMeta.Failure,
      ],
      endpoint: endpoint,
      body,
      apiConfig,
      paginationKey: '',
      requestType: RequestTypes.Update,
    })
  }
}

function createUpdateAction<Resource extends ResourceModel>(args: {
  name: string
  apiConfig: ResourceApiConfig<Resource>
  computedId?: (resource: Partial<Resource>) => string
}) {
  const { name, apiConfig, computedId } = args

  /**
   * Updates the resource at the given path with the provided resource object. Note if you don't
   * explicitly pass an id or a path that contains an id, this function will try and use the computedId
   * if it is defined on the resource
   * @param path - the path to the resource to update
   * @param resource - a partial resource object with the fields to update
   * @param args - additional arguments to control the behavior of the update action (e.g. optimistic updates)
   */
  return (
    path: string | number,
    resource: Partial<Resource>,
    args?: UpdateArgs,
  ) => {
    const maybeComputedId = computedId?.(resource)

    const id = getIdForResource({ path, id: args?.id ?? maybeComputedId })
    return createUpdateManyAction({ name, apiConfig })(
      path,
      [id],
      resource,
      args,
    )
  }
}

function createResourceFetcherHook<Resource extends ResourceModel>(args: {
  rootSelector: RequestDataSelector<Resource>
  selectors: ReturnType<typeof createResourceSelectors<Resource>>
  fetch: ResourceFetchActionCreator
  fetchAnnotation: AnnotationFetchActionCreator
}) {
  const { rootSelector, selectors, fetch, fetchAnnotation } = args
  /**
   * @param fetchArgs - the arguments to pass to the fetch action that define the structure and content of the request
   * @param fetchOptions - options to control the behavior of the fetcher hook. see `getFetchOptions` for defaults.
   */
  return (fetchArgs?: FetchArgs, fetchOptions?: FetchOptions) => {
    const options = getFetchOptions(fetchOptions)
    const dispatch = useAppDispatch()

    const selectQueryState = (state: RootState) => {
      const querySpec = createQuerySpecFromFetchArgs(fetchArgs)
      const queryStates = selectors.queryState.select(state, querySpec, {
        includePartialMatches: false,
      })
      // queryStates could be empty if the query hasnt been initiated yet
      if (!queryStates?.length) {
        return {
          status: 'idle' as RequestStatus,
          error: null,
        }
      } else if (queryStates.length !== 1) {
        console.warn(
          'resourceFetcherHook unexpectedly found more than one query state for a resource fetcher',
        )
      }
      return queryStates[0]
    }
    const shouldPopulate = useAppSelector((state) => {
      const queryState = selectQueryState(state)
      const invalidated = rootSelector(state).status === 'invalidated'
      return shouldPopulateResource(queryState) || invalidated
    })
    const shouldRefetch = useAppSelector((state) => {
      if (!options?.refetchOnChange) {
        return false
      }
      const { status, error } = selectQueryState(state)
      const canRefresh = canRefreshResource({
        status,
        errorsPresent: Boolean(error),
      })
      return canRefresh && !shouldPopulate
    })

    // Populate the resource if it has not been populated yet
    useEffect(() => {
      if (shouldPopulate && (options.require ?? true)) {
        dispatch(fetch(fetchArgs))
      }
    }, [shouldPopulate, options?.require])
    // Refetch when the fetch args change if the refetchOnChange option is set.
    useEffect(() => {
      if (shouldRefetch && (options.require ?? true)) {
        dispatch(fetch(fetchArgs))
      }
    }, [JSON.stringify(fetchArgs)])

    const status = createSelector(
      [selectQueryState],
      (queryState) => queryState.status,
    )
    const errors = createSelector(
      [selectQueryState],
      (queryState) => queryState.error,
    )

    const fetchDispatcher: ResourceFetchActionDispatcher = (args) =>
      dispatch(fetch(args))
    const fetchAnnotationDispatcher: AnnotationFetchActionDispatcher = (
      id,
      annotation,
      params?,
    ) => dispatch(fetchAnnotation(id, annotation, params))

    return {
      selectors: { status, errors },
      shouldPopulate: shouldPopulate,
      refetch: () => dispatch(fetch(fetchArgs)),
      fetch: fetchDispatcher,
      fetchAnnotation: fetchAnnotationDispatcher,
    }
  }
}

function createResourceAnnotationFetcherHook<
  Resource extends ResourceModel,
>(args: {
  rootSelector: RequestDataSelector<Resource>
  selectors: ReturnType<typeof createResourceSelectors<Resource>>
  selectById: (state: RootState, id: ID) => Resource | undefined
  fetchAnnotation: AnnotationFetchActionCreator
}) {
  const { rootSelector, selectors, selectById, fetchAnnotation } = args
  return (
    id: string | number | undefined,
    annotation: string,
    params?: { [key: string]: unknown },
    fetchOptions?: FetchOptions,
  ) => {
    const options = getFetchOptions(fetchOptions)
    const dispatch = useAppDispatch()

    // checks if any requests are in progress or errored out for this annotation
    // attached to resource with id
    const selectCanRefresh = (state: RootState) => {
      if (!id) {
        return false
      }
      const queryStates = selectors.queryState.selectByResourceId(state, id)
      return queryStates.every((queryState) =>
        canRefreshResource({
          status: queryState.status,
          errorsPresent: Boolean(queryState.error),
        }),
      )
    }
    const selectResourceAnnotationIsntPopulated = (state: RootState) => {
      if (!id) {
        return true
      }
      const resource = selectById(state, id)
      if (!resource) {
        return true
      }
      return resource[annotation] === undefined
    }
    const resourceAnnotationShouldPopulate = useAppSelector((state) => {
      const invalidated = rootSelector(state).status === 'invalidated'
      return (
        selectCanRefresh(state) &&
        (selectResourceAnnotationIsntPopulated(state) || invalidated)
      )
    })
    const shouldFetchOnChange = useAppSelector(
      (state) =>
        // fetch on a change if the option says we can, we are okay to refresh, and the annotation is populated
        // if it isnt populated, we will let the populate hook handle it
        options.refetchOnChange &&
        selectCanRefresh(state) &&
        !selectResourceAnnotationIsntPopulated(state),
    )

    const fetch = () => {
      if (id) {
        return dispatch(fetchAnnotation(id, annotation, params))
      }
    }
    const queryState = (state: RootState) => {
      // select all queryStates for resource with our id for this annotation
      const queryStates = selectors.queryState.select(state, {
        id: id,
        pathSegments: (s?: string[]) => Boolean(s?.includes(annotation)),
        params,
      })
      // queryStates could be empty if the query hasnt been initiated yet
      if (!queryStates?.length) {
        return {
          status: 'idle',
          error: null,
        }
      } else if (queryStates.length !== 1) {
        console.warn(
          'resourceAnnotationFetcherHook unexpectedly found more than one query state for annotation fetcher',
        )
      }
      return queryStates[0]
    }
    const status = createSelector(
      [queryState],
      (queryState) => queryState.status,
    )
    const errors = createSelector(
      [queryState],
      (queryState) => queryState.error,
    )

    // Populate the annotation if it is not populated
    useEffect(() => {
      if (resourceAnnotationShouldPopulate && (options.require ?? true)) {
        fetch()
      }
    }, [resourceAnnotationShouldPopulate, options.require])
    // Refetch the data when the params change
    useEffect(() => {
      if (shouldFetchOnChange && (options.require ?? true)) {
        fetch()
      }
    }, [JSON.stringify(params)])

    const annotationFetchDispatcher: AnnotationFetchActionDispatcher = (
      id,
      annotation,
      params?,
    ) => dispatch(fetchAnnotation(id, annotation, params))

    return {
      selectors: { status, errors },
      refetch: fetch,
      fetch: annotationFetchDispatcher,
    }
  }
}

/**
 *
 * @returns {
 *  adapter: EntityAdapter<Resource> - The entity adapter that manages the client side state for this resource.
 *           For example if youre fetching a list of users, this adapter will manage the list of users objects.
 * queryStateAdapter: EntityAdapter<QueryState> - The entity adapter that manages the query state for this resource.
 *          For example if youre fetching a list of users, this adapter will manage the state of the query that
 *          fetched the list of users.
 * }
 */
function createAdapters<Resource extends ResourceModel>() {
  const adapter = createEntityAdapter<ResourceModelQueryResult<Resource>>({
    // use string sort comparator to compare a.id and b.id
    sortComparer: (a, b) => String(a.id).localeCompare(String(b.id)),
  })
  // The scratch pad adapter is used to keep track of old resources for things like optimistic updates.
  const scratchPadAdapter = createEntityAdapter<
    ResourceModelQueryResult<Resource>
  >({
    sortComparer: (a, b) => String(a.id).localeCompare(String(b.id)),
  })
  const queryStateAdapter = createEntityAdapter<QueryState>({
    selectId: (queryState) => querySpecToIDString(queryState),
    sortComparer: (a, b) =>
      querySpecToIDString(a).localeCompare(querySpecToIDString(b)),
  })
  return { adapter, scratchPadAdapter, queryStateAdapter }
}

type CreateResourceCollectionOptions<Resource extends ResourceModel> = {
  name: string
  apiConfig?: ResourceApiConfig<Resource>
  selector: RequestRootSelector<ResourceModelQueryResult<Resource>>
  paginationKey?: string
  computedId?: (resource: Partial<Resource>) => string
}

/**
 *
 * @param name A string name for this resource. Generated action type constants will use this as a prefix
 * @param apiConfig Defines the API endpoint for this resource.
 * @param selector The selector for the root of this resource.
 * @param paginationKey If the resource is paginated, this key will be used to select the pagination state.
 * @returns {
 *  slice: A redux-toolkit slice that will handle the request state for this resource.
 *  rootSelector: The selector for the root of this resource.
 *  actions: { fetch, fetchAnnotation, invalidate } - redux actions
 *  type: { Request, Success, Failure } - redux action types for request state.
 *        Can be used to check request status when actions are dispatched as thunks.
 *  useResourceFetcher: A hook that will fetch the resource if it is not populated,
 *       and will invalidate the resource if any of the invalidation flags change.
 *       It returns the state of the fetching as well as controls for fetching.
 *  useResourceAnnotationFetcher: A hook that will fetch an annotation for a resource if it is not populated,
 *       It returns the state of the fetching as well as controls for fetching.
 * }
 */
export default function createResourceCollection<
  Resource extends ResourceModel,
>(options: CreateResourceCollectionOptions<Resource>) {
  const IN_FLIGHT_FETCHES = new Set<string>()

  const {
    name,
    apiConfig = {},
    selector,
    paginationKey = 'results',
    computedId,
  } = options

  const dataSelector = createSelector([selector], (resourceRequestState) => {
    if (!resourceRequestState) {
      console.error(`Collection missing root selector: ${name}`)
    }
    return resourceRequestState.data
  })
  const queryStateSelector = createSelector(
    [selector],
    (resourceRequestState) => resourceRequestState.status,
  )
  const { adapter, scratchPadAdapter, queryStateAdapter } =
    createAdapters<Resource>()

  const slice = createResourceSlice({
    name,
    paginationKey,
    adapter,
    queryStateAdapter,
    scratchPadAdapter,
    apiConfig,
    computedId,
  })

  const selectors = createResourceSelectors<Resource>(
    adapter,
    queryStateAdapter,
    dataSelector,
    queryStateSelector,
  )

  const create = createCreationAction<Resource>({ name, apiConfig })

  const useCreate = createCreationHook<Resource>({
    selectors,
    create,
  })

  const createAnnotation = createAnnotationCreationAction({
    name,
    apiConfig,
    paginationKey,
  })

  const update = createUpdateAction<Resource>({ name, apiConfig, computedId })
  const updateMany = createUpdateManyAction<Resource>({ name, apiConfig })

  const remove = createDeletionAction({ name, apiConfig })

  const { fetch, fetchAnnotation } = createFetchActions({
    name,
    apiConfig,
    paginationKey,
    inFlightFetchSet: IN_FLIGHT_FETCHES,
  })

  const useResourceFetcher = createResourceFetcherHook({
    rootSelector: dataSelector,
    selectors,
    fetch,
    fetchAnnotation,
  })

  const useFetch = createFetchHook({
    rootSelector: dataSelector,
    selectors,
    fetch,
  })

  const useResourceAnnotationFetcher = createResourceAnnotationFetcherHook({
    rootSelector: dataSelector,
    selectors,
    selectById: selectors.selectById,
    fetchAnnotation,
  })

  return {
    slice,
    rootSelector: selector,
    selectors,
    actions: {
      create,
      createAnnotation,
      update,
      updateMany,
      remove,
      fetch,
      fetchAnnotation,
      invalidate: createInvalidateAction(name),
    },
    types: FetchActionTypes(name),
    actionTypes: getActionTypes(name),
    /**
     * @deprecated use useFetch instead
     */
    useResourceFetcher,
    useFetch,
    /**
     * @deprecated use useFetchAnnotation instead
     */
    useResourceAnnotationFetcher,
    useFetchAnnotation: useResourceAnnotationFetcher,
    useCreate,
    config: createExternalConfig(apiConfig),
  }
}

export type ResourceCollection<T extends ResourceModel> = ReturnType<
  typeof createResourceCollection<T>
>
