import './styles.scss'
import formatClasses from '../../utils/classes/formatClasses'
import React, {
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
  useContext,
} from 'react'
import { BreakpointContext } from '../../context'
import { TestingProps } from '@/types/testingProps'

export interface Props extends HTMLAttributes<HTMLDivElement>, TestingProps {
  autoColumns?: string
  autoRows?: string
  columns?: string | number
  fillHeight?: boolean
  flow?: 'column' | 'row'
  fluid?: boolean
  gap?: string
  placeContent?: string
  placeItems?: string
  alignItems?: string
  templateColumns?: string
  templateRows?: string
}

const defaultProps: Partial<Props> = {
  flow: 'column',
}

export type Ref = ForwardedRef<HTMLDivElement>

function Grid(props: Props, ref?: ForwardedRef<HTMLDivElement>) {
  const breakpoint = useContext(BreakpointContext)

  props = { ...defaultProps, ...props }

  const classes = formatClasses([
    'grid',
    props.flow === 'column' ? 'column' : undefined,
    props.fluid ? 'fluid' : undefined,
    props.className,
  ])

  const styles = {
    height: props.fillHeight ? '100%' : undefined,
    gap: props?.gap,
    gridAutoColumns: props?.autoColumns,
    gridAutoRows: props?.autoRows,
    gridTemplateColumns: !props.autoColumns ? templateColumns() : undefined,
    gridTemplateRows: props?.templateRows,
    placeContent: props?.placeContent,
    placeItems: props?.placeItems,
    ...props.style,
  }

  function columnCount() {
    switch (breakpoint.current) {
      case 'xs':
        return Math.min(
          Number(props.columns) || React.Children.count(props.children),
          4,
        )
      case 'sm':
        return Math.min(
          Number(props.columns) || React.Children.count(props.children),
          8,
        )
      default:
        return Math.min(
          Number(props.columns) || React.Children.count(props.children),
          12,
        )
    }
  }

  function templateColumns() {
    if (props.templateRows && !props.templateColumns) return

    return props.templateColumns
      ? props.templateColumns
      : props.flow === 'column'
      ? `repeat(${columnCount()}, auto)`
      : undefined
  }

  return (
    <div
      className={classes}
      data-testing-id={props['data-testing-id']}
      ref={ref}
      style={styles}
    >
      {props.children}
    </div>
  )
}

export default forwardRef(Grid)
