import { NavigationPage } from 'app-components'
import React from 'react'
import RecommendedPlan from '@/app/pages/home-insights/current-rate/recommended-plan'
import PlanDetails from '@/app/pages/home-insights/current-rate/plan-details'
import SurveyObserver from '@/app/components/SurveyObserver'

export default function HomeCurrentRatePage() {
  return (
    <NavigationPage
      id="home-current-rate"
      title="Current Rate"
      subtitle="Here's your usage breakdown for the last 30 days"
    >
      <SurveyObserver observedSurveys={['GexaCompleteSwitch']} />
      <div className="bg-themed-white rounded-[8px] border border-solid border-themed-base-200 p-5">
        <PlanDetails />
      </div>
      <RecommendedPlan />
    </NavigationPage>
  )
}
