import ToggleButtonRoot, {
  ToggleButtonGroupProps,
  ToggleButtonProps,
} from './ToggleButton'

function ToggleButtonGroup(props: ToggleButtonGroupProps) {
  return <ToggleButtonRoot.ToggleButtonGroup {...props} />
}

function ToggleButton(props: ToggleButtonProps) {
  return <ToggleButtonRoot.ToggleButton {...props} />
}

export default {
  ToggleButtonGroup,
  ToggleButton,
}

export type { ToggleButtonGroupProps, ToggleButtonProps } from './ToggleButton'
