import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

export default function OdometerDialog(props) {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="charging-efficiency-dialog"
    >
      <DialogTitle id="charging-efficiency-dialog">
        What is this cost?
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ marginBottom: '15px' }}>
          {`This is the average cost per kWh for this charge, which is calculated from your electricity rates.`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
