import { ChargeForecast } from '@/types'
import {
  getEndChargeDatetime,
  getStartChargeDatetime,
  getUpcomingDREventTimeline,
} from '../utils'
import { Text } from '@/components'
import { Mode } from '.'
import {
  getChargeStartPeriodMetric,
  getChargeEndPeriodMetric,
  getRelativeDay,
  isPast,
  getAbsoluteDiff,
} from '@/types/chargeForecast/utils'
import { useDistanceUnit } from '@/authenticated/hooks/conversions'
import dayjs from 'dayjs'

type ChargeScheduleTimeProps = {
  mode: Exclude<Mode, 'NoCharge'>
  chargeForecast: ChargeForecast
}

function useViewModel({ mode, chargeForecast }: ChargeScheduleTimeProps) {
  const [userPreferredDistanceUnit] = useDistanceUnit()
  const drEventTimeline = getUpcomingDREventTimeline(chargeForecast)

  const chargeStartDatetime =
    mode === 'DREventScheduled'
      ? drEventTimeline?.start
      : getStartChargeDatetime(chargeForecast)
  const chargeEndDatetime =
    mode === 'DREventScheduled'
      ? drEventTimeline?.end
      : getEndChargeDatetime(chargeForecast)

  const startTimeLabel =
    mode === 'DREventScheduled'
      ? 'Event begin'
      : `Start${isPast(chargeStartDatetime) ? 'ed' : 's'} ${getRelativeDay(
          chargeStartDatetime,
        )}`
  const endTimeLabel =
    mode === 'DREventScheduled'
      ? 'Event end'
      : `End${isPast(chargeEndDatetime) ? 'ed' : 's'} ${getRelativeDay(
          chargeEndDatetime,
        )}`
  const chargeDateTimeDiff = getAbsoluteDiff(chargeEndDatetime, dayjs())

  const chargeStartPeriodMetric = getChargeStartPeriodMetric(chargeForecast)
  const chargeEndPeriodMetric = getChargeEndPeriodMetric(chargeForecast)

  const startPercent = chargeStartPeriodMetric?.battery_percent
  const endPercent = chargeEndPeriodMetric?.battery_percent

  const startDistance =
    chargeStartPeriodMetric?.[`${userPreferredDistanceUnit}_range`]
  const endDistance =
    chargeEndPeriodMetric?.[`${userPreferredDistanceUnit}_range`]

  const distanceSuffix = userPreferredDistanceUnit === 'miles' ? 'mi' : 'km'

  return {
    chargeStartDatetime,
    chargeEndDatetime,
    chargeDateTimeDiff,
    startTimeLabel,
    endTimeLabel,
    startPercent,
    endPercent,
    startDistance,
    endDistance,
    distanceSuffix,
  }
}

export default function ChargeScheduleTime(props: ChargeScheduleTimeProps) {
  const {
    chargeStartDatetime,
    chargeEndDatetime,
    chargeDateTimeDiff,
    startTimeLabel,
    endTimeLabel,
    startPercent,
    endPercent,
    startDistance,
    endDistance,
    distanceSuffix,
  } = useViewModel(props)

  return (
    <>
      {chargeStartDatetime && (
        <div className="flex flex-col flex-1">
          <Text variant="body2">{startTimeLabel}</Text>
          <div className="flex items-end">
            <Text variant="h1">{chargeStartDatetime.format('h:mm')}</Text>
            <Text variant="body1" className="pb-1 pl-1">
              {chargeStartDatetime.format('A')}
            </Text>
          </div>
          <div className="flex">
            {startPercent && startDistance && (
              <Text className="font-medium" variant="body2">
                {Math.round(startPercent)}% • {Math.round(startDistance)}{' '}
                {distanceSuffix}
              </Text>
            )}
          </div>
        </div>
      )}
      {chargeEndDatetime && (
        <div className="flex flex-col flex-1">
          <Text variant="body2">{endTimeLabel}</Text>
          <div className="flex items-end">
            <Text variant="h1">{chargeEndDatetime.format('h:mm')}</Text>
            <Text variant="body1" className="pb-1 pl-1">
              {chargeEndDatetime.format('A')}
            </Text>
            {chargeDateTimeDiff !== 0 && (
              <Text
                className="mb-auto pt-1 pl-1"
                variant="body1"
              >{`+${chargeDateTimeDiff}`}</Text>
            )}
          </div>
          <div className="flex">
            {endPercent && endDistance && (
              <Text className="font-medium" variant="body2">
                {Math.round(endPercent)}% • {Math.round(endDistance)}{' '}
                {distanceSuffix}
              </Text>
            )}
          </div>
        </div>
      )}
    </>
  )
}
