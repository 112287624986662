import useVehicleChargeSchedulesFetcher from '@/authenticated/hooks/useVehicleChargeSchedulesFetcher'
import { VehicleChargeSchedule } from '@/types'

export function useVehicleChargeSchedule(
  vehicleId: number | undefined,
): VehicleChargeSchedule | undefined {
  if (!vehicleId) {
    return undefined
  }
  const chargeSchedules = useVehicleChargeSchedulesFetcher()
  return (chargeSchedules ?? []).find(
    (schedule) => schedule.vehicle_id === vehicleId,
  ) as VehicleChargeSchedule | undefined
}
