import useMockableViewModel from '../../../../hooks/useMockableViewModel'
import { useAppSelector } from '../../../../hooks'
import { centsToDollars } from '../../../../utils/currency'
import { ID } from '../../../../types/model'
import { selectLatestAdjustmentForThermostat } from '../../../../selectors'
import { thermostatsCollection } from '../../../../reducers/thermostats'
import { useUsersPreferredTemperature } from '../../../../authenticated/hooks/useUsersPreferredTemperature'
import { convertFahrenheitToCelsius } from '../../../../utils/conversionUtilities'
import { DateRepresentation } from '../../../../types/dates'
import {
  ThermostatMode,
  ThermostatState,
} from '../../../../types/thermostatMeasurement'

interface Props {
  thermostatId: ID
}

function useViewModel(props: Props) {
  const lastAdjustment = useAppSelector((state) =>
    selectLatestAdjustmentForThermostat(state, props.thermostatId),
  )
  const lastAdjustmentStatus = useAppSelector((state) =>
    thermostatsCollection.selectors.annotationStatus(state, 'adjustments'),
  )
  const temperatureUnit = useUsersPreferredTemperature(0).unit

  // whichever setpoint is not null was the active setpoint
  const previousMode = lastAdjustment?.heat_setpoint === null ? 'cool' : 'heat'
  const previousSetpoint =
    lastAdjustment?.[`previous_${previousMode}_setpoint`] ?? 0
  const currentMode = lastAdjustment?.heat_setpoint ? 'heat' : 'cool'
  const currentSetpoint = lastAdjustment?.[`${currentMode}_setpoint`] ?? 0

  return {
    ...props,
    ...lastAdjustment,
    cool_setpoint:
      temperatureUnit === 'C'
        ? convertFahrenheitToCelsius(lastAdjustment?.cool_setpoint ?? 0)
        : lastAdjustment?.cool_setpoint ?? 0,
    heat_setpoint:
      temperatureUnit === 'C'
        ? convertFahrenheitToCelsius(lastAdjustment?.heat_setpoint ?? 0)
        : lastAdjustment?.heat_setpoint ?? 0,
    hidden: !lastAdjustment,
    loading:
      lastAdjustmentStatus === 'loading' || lastAdjustmentStatus === 'idle',
    estimated_cost_cents: lastAdjustment?.estimated_cost_cents,
    outdoor_temperature:
      temperatureUnit === 'C'
        ? convertFahrenheitToCelsius(lastAdjustment?.outdoor_temperature ?? 0)
        : lastAdjustment?.outdoor_temperature ?? 0,
    previousSetpoint:
      temperatureUnit === 'C'
        ? convertFahrenheitToCelsius(previousSetpoint)
        : previousSetpoint,
    currentSetpoint:
      temperatureUnit === 'C'
        ? convertFahrenheitToCelsius(currentSetpoint)
        : currentSetpoint,
    thermostat_measurement: {
      cool_setpoint:
        temperatureUnit === 'C'
          ? convertFahrenheitToCelsius(
              lastAdjustment?.thermostat_measurement.cool_setpoint ?? 0,
            )
          : lastAdjustment?.thermostat_measurement.cool_setpoint ?? 0,
      heat_setpoint:
        temperatureUnit === 'C'
          ? convertFahrenheitToCelsius(
              lastAdjustment?.thermostat_measurement.heat_setpoint ?? 0,
            )
          : lastAdjustment?.thermostat_measurement.heat_setpoint ?? 0,
      indoor_temperature:
        temperatureUnit === 'C'
          ? convertFahrenheitToCelsius(
              lastAdjustment?.thermostat_measurement.indoor_temperature ?? 0,
            )
          : lastAdjustment?.thermostat_measurement.indoor_temperature ?? 0,
      id: lastAdjustment?.thermostat_measurement.id ?? (0 as ID),
      api_event_timestamp:
        lastAdjustment?.thermostat_measurement.api_event_timestamp ??
        ('' as DateRepresentation),
      hvac_mode:
        lastAdjustment?.thermostat_measurement.hvac_mode ??
        ('' as ThermostatMode),
      hvac_state:
        lastAdjustment?.thermostat_measurement.hvac_state ??
        ('' as ThermostatState),
    },
  }
}

function useMockViewModel(props: Props) {
  return {
    ...props,
    hidden: false,
    loading: false,
    previousSetpoint: 60,
    currentSetpoint: 80,
    id: 27,
    thermostat_measurement: {
      id: 404,
      indoor_temperature: 81,
      cool_setpoint: 60,
      heat_setpoint: 60,
      hvac_mode: 'heat',
      hvac_state: 'off',
      api_event_timestamp: '2022-09-16T20:50:31.289599Z',
      created: '2022-09-22T18:01:09.945410Z',
      modified: '2022-09-22T18:01:09.945425Z',
    },
    created: '2022-09-22T18:01:09.945410Z',
    modified: '2022-09-22T18:01:09.945425Z',
    heat_setpoint: 0,
    cool_setpoint: 80,
    outdoor_temperature: 0,
    estimated_cost_cents: centsToDollars(50),
    actual_cost_cents: 0,
    hold_until_time: '2022-09-22T22:00:00Z',
    thermostat: 1,
  } as const
}

export default useMockableViewModel({ useViewModel, useMockViewModel })
