import { useAppSelector } from '../../../hooks'
import { selectUserVehicles, selectUserSelectedHome } from '../../../selectors'
import { thermostatsCollection } from '../../../reducers/thermostats'
import useMockableViewModel from '../../../hooks/useMockableViewModel'
import DeviceSelectionGallery, {
  Props as DeviceSelectionGalleryProps,
} from '../DeviceSelectionGallery'
import {
  DeviceType,
  DeviceTypesWithModel,
} from '../DeviceSelectionGallery/DeviceSelectionGallery.types'
import { VehicleCharger } from '@/types'
import { vehicleChargersCollection } from '@/reducers/vehicleChargers'

type DeviceExclusionPredicates = {
  [K in DeviceType]: (device: DeviceTypesWithModel[K]) => boolean
}

type Props = Omit<
  DeviceSelectionGalleryProps,
  'vehicles' | 'homes' | 'thermostats' | 'vehicleChargers'
> & {
  // Exclude certain device types from the gallery, e.g. ['vehicle', 'thermostat']
  exclude?: DeviceType[] | DeviceExclusionPredicates
}

function isDeviceExclusionPredicates(
  exclude?: DeviceType[] | DeviceExclusionPredicates,
): exclude is DeviceExclusionPredicates {
  return typeof exclude === 'object'
}

function getDeviceExclusionPredicates(
  exclude?: DeviceType[] | DeviceExclusionPredicates,
): DeviceExclusionPredicates {
  if (!exclude) {
    return {
      vehicle: () => false,
      thermostat: () => false,
      home: () => false,
      vehicleCharger: () => false,
    }
  }
  if (isDeviceExclusionPredicates(exclude)) {
    return {
      vehicle: exclude.vehicle ?? (() => false),
      thermostat: exclude.thermostat ?? (() => false),
      home: exclude.home ?? (() => false),
      vehicleCharger: exclude.vehicleCharger ?? (() => false),
    }
  }
  return {
    vehicle: () => exclude.includes('vehicle'),
    thermostat: () => exclude.includes('thermostat'),
    home: () => exclude.includes('home'),
    vehicleCharger: () => exclude.includes('vehicleCharger'),
  }
}

function theViewModel(props: Props) {
  const { exclude, ...deviceSelectionGalleryProps } = props
  const deviceExclusionPredicates = getDeviceExclusionPredicates(exclude)
  const vehicles = useAppSelector((state) => {
    return selectUserVehicles(state).filter(
      (vehicle) => !deviceExclusionPredicates.vehicle(vehicle),
    )
  })
  // Only show the home if the user can show the utility usage (otherwise theres nothing to show in the home tab)
  const home = useAppSelector((state) => {
    const selectedHome = selectUserSelectedHome(state)
    if (!selectedHome) {
      return undefined
    }
    return deviceExclusionPredicates.home(selectedHome)
      ? undefined
      : selectedHome
  })
  const homes = home ? [home] : []
  const thermostats = useAppSelector((state) => {
    return thermostatsCollection.selectors
      .selectAll(state)
      .filter((thermostat) => !deviceExclusionPredicates.thermostat(thermostat))
  })
  const vehicleChargers: VehicleCharger[] = useAppSelector((state) => {
    return vehicleChargersCollection.selectors
      .selectAll(state)
      .filter(
        (vehicleCharger) =>
          !deviceExclusionPredicates.vehicleCharger(vehicleCharger),
      )
  })

  return {
    vehicles,
    homes,
    thermostats,
    vehicleChargers,
    ...deviceSelectionGalleryProps,
  }
}

const useViewModel = useMockableViewModel({
  useViewModel: theViewModel,
  useMockViewModel: (props: Props) => ({
    vehicles: [],
    homes: [],
    thermostats: [],
    vehicleChargers: [],
    ...props,
  }),
})

export default function (props: Props) {
  const viewModel = useViewModel(props)
  return <DeviceSelectionGallery {...viewModel} {...props} />
}
