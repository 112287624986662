import { Link, NavigationPage } from '@/app/components'
import LinkDiagram from '@/app/features/logos/LinkDiagram'
import TeslaLogo from '@/authenticated/images/tesla.webp'
import OptiwattIconLogo from '@/app/img/logo-icon-colored-filled.webp'
import { Button, Text } from '@/components'
import {
  useAppDispatch,
  useAppSelector,
  useMockableViewModel,
  useUrlSearchParam,
} from '@/hooks'
import { useHistory, useParams } from 'react-router-dom'
import KeyConfirmationStatusModal, { StatusType, statusValues } from './status'
import { ComponentProps, useState } from 'react'
import {
  GET_VEHICLE_UPDATE_TESLA_PUBLIC_KEY_SUCCESS,
  getVehicleWithUpdatedTeslaPublicKey,
} from '@/actions/vehicles'
import { Vehicle } from '@/types'
import { teslaFleetAuthenticationCollection } from '@/reducers/teslaFleetAuthentication'
import { useNavigateToFleetPublicKey } from '../utils/useNavigateToFleetPublicKey'
import { logException } from '@/logging/sentry'
import { AlertMessage } from 'app-components'
import { ButtonContainer } from '@/app/pages/ava/BrandedPage'

type ConnectionStatus = ComponentProps<
  typeof KeyConfirmationStatusModal
>['mode']
const useViewModel = useMockableViewModel({
  useViewModel() {
    const dispatch = useAppDispatch()
    const history = useHistory()
    const { data: fleetAuthentications, status: fleetAuthenticationsStatus } =
      teslaFleetAuthenticationCollection.useFetch()
    const { vehicleId } = useParams<{ vehicleId: string | undefined }>()
    const allVehicles = useAppSelector((state) => state.vehicles.vehicles ?? [])
    const [checkedVehicles, setCheckedVehicles] = useState<Vehicle[]>([])
    const statusParam = useUrlSearchParam('status')
    const status = statusValues.includes(statusParam ?? '') ? statusParam : null
    let vehicles =
      (useAppSelector((state) => state.vehicleAuth.mfaData ?? []) as
        | Vehicle[]
        | undefined) ?? []

    if (vehicleId) {
      vehicles = allVehicles.filter((v) => v.id === Number.parseInt(vehicleId))
    }

    const updateStatus = (newStatus: StatusType) => {
      history.push({ search: `?status=${newStatus}` })
    }
    const clearStatus = () => {
      history.push({ search: '' })
    }
    const onFail = () => {
      updateStatus('failed')
    }

    const retryFetch = async (vehicleId: number, retries = 1): Promise<any> => {
      try {
        const response = await dispatch(
          getVehicleWithUpdatedTeslaPublicKey(vehicleId),
        )
        if (response.type === GET_VEHICLE_UPDATE_TESLA_PUBLIC_KEY_SUCCESS) {
          return response
        } else {
          if (retries > 0) {
            return await retryFetch(vehicleId, retries - 1)
          } else {
            throw new Error('Max retries reached')
          }
        }
      } catch (error) {
        if (retries > 0) {
          return await retryFetch(vehicleId, retries - 1)
        } else {
          throw error
        }
      }
    }

    const onConfirmClick = async () => {
      updateStatus('connecting')
      try {
        const responses = await Promise.all(
          vehicles?.map((v) => retryFetch(v.id)),
        )
        const vehiclesPayload = responses.map((r) => r.payload) as
          | Vehicle[]
          | undefined
        setCheckedVehicles(vehiclesPayload ?? [])

        updateStatus('connected')
      } catch (error) {
        logException(error, { logToConsole: true })
        updateStatus('failed')
      }
    }

    const retryKeyConnection = useNavigateToFleetPublicKey({
      fleetPublicKeyUrl: fleetAuthentications[0]?.public_key_url,
    })

    return {
      onConfirmClick: onConfirmClick,
      onClose: clearStatus,
      vehicles: checkedVehicles,
      retryKeyConnection: () => retryKeyConnection(vehicleId ?? ''),
      // mark loading when fleet auth loading because its needed to pass retry
      // action to the status modal
      isLoading: ['loading', 'idle'].includes(fleetAuthenticationsStatus),
      status: status as null | ConnectionStatus,
      failed: status === 'failed',
      onFail,
    }
  },
  useMockViewModel() {
    return {
      onConfirmClick: () => {},
      onClose: () => {},
      vehicles: [] as Vehicle[],
      retryKeyConnection: () => {},
      isLoading: false,
      status: null as null | StatusType,
      failed: false,
      onFail: () => {},
    }
  },
})

export default function KeyConfirmationPage() {
  const {
    onConfirmClick,
    onClose,
    status,
    vehicles,
    retryKeyConnection,
    isLoading,
    failed,
    onFail,
  } = useViewModel()

  return (
    <NavigationPage id="onboarding-key-confirmation-nav-page">
      <NavigationPage.SingleCardContent>
        <div>
          <LinkDiagram logos={[OptiwattIconLogo, TeslaLogo]} />
        </div>
        <Text variant="h2" className="!mt-16">
          Connection Test{failed && ' Failed'}
        </Text>
        <div className="flex flex-col gap-3 !mt-4">
          <Text variant="body2">
            Click the button below to begin the connection test. The test is
            very quick and will check your Tesla’s connection with Optiwatt in
            just a few seconds.
          </Text>
          <AlertMessage show={failed} variant="error">
            Oh no! Something went wrong. You can retry the connection test or
            contact support if it doesn’t work.
          </AlertMessage>
          {failed && (
            <div className="flex flex-col">
              <Link
                href="mailto:support@optiwatt.com"
                className="!no-underline"
              >
                Need Support?
              </Link>
              <Link
                href="mailto:support@optiwatt.com"
                className="!no-underline"
              >
                Email support@optiwatt.com
              </Link>
            </div>
          )}
        </div>
        <ButtonContainer>
          <Button
            id="tesla-fleet-auth-survey-continue-button"
            variant="primary"
            // waiting on one short request, so opt to disable button instead
            // of flashing loader
            disabled={isLoading}
            onClick={onConfirmClick}
          >
            {!failed ? 'Start Connection Test' : 'Retry Connection Test'}
          </Button>
        </ButtonContainer>
        <KeyConfirmationStatusModal
          id="key-confirmation-status-modal"
          open={status !== null && status !== 'failed'}
          mode={status ?? 'connecting'}
          onClose={onClose}
          onRetry={retryKeyConnection}
          vehicles={vehicles}
          onFailure={onFail}
        />
      </NavigationPage.SingleCardContent>
    </NavigationPage>
  )
}
