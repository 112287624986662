import { createResourceCollection } from '@/request'
import { ResourceModel } from '@/request/types'

export type Pkce = {
  code_challenge: string
  code_verifier: string
  code_challenge_method: string
} & ResourceModel

export const pkceCollection = createResourceCollection<Pkce>({
  name: 'pkce',
  apiConfig: {
    path: 'oauth/pkce',
  },
  selector: (state) => state.pkce,
})

const pkceReducer = pkceCollection.slice.reducer

export default pkceReducer
