import { RSAA } from 'redux-api-middleware'
import config from '../config'

export const ENERGY_STATES_SUCCESS = '@@energy/ENERGY_STATES_SUCCESS'
export const ENERGY_STATES_REQUEST = '@@energy/ENERGY_STATES_REQUEST'
export const ENERGY_STATES_FAILURE = '@@energy/ENERGY_STATES_FAILURE'

export const getAvgEnergyCostPerState = () => ({
  [RSAA]: {
    endpoint: `${config.apiGateway.URL}/api/avg_energy_costs/`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    types: [
      ENERGY_STATES_REQUEST,
      ENERGY_STATES_SUCCESS,
      ENERGY_STATES_FAILURE,
    ],
  },
})
