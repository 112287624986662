import { VehicleChargingDelayEvent } from '@/types'
import * as actions from '../actions/vehicleChargingDelayEvents'
import { getApiErrorMessage } from '../utils/errorUtilities'
import { PayloadAction } from '@reduxjs/toolkit'

type State = {
  delayEvents: VehicleChargingDelayEvent[] | null
  isLoading: boolean
  errors: Record<string, unknown>
}

const initialState: State = {
  delayEvents: null,
  isLoading: false,
  errors: {},
}

export default (state = initialState, action: PayloadAction<any>): State => {
  switch (action.type) {
    case actions.VEHICLE_DELAY_EVENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        delayEvents: action.payload,
        errors: {},
      }
    case actions.CHANGE_VEHICLE_DELAY_EVENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        delayEvents:
          state.delayEvents?.map((delayEvent) =>
            delayEvent.id === action.payload.id ? action.payload : delayEvent,
          ) ?? null,
        errors: {},
      }
    case actions.CHANGE_VEHICLE_DELAY_EVENT_REQUEST:
    case actions.VEHICLE_DELAY_EVENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case actions.CHANGE_VEHICLE_DELAY_EVENT_FAILURE:
    case actions.VEHICLE_DELAY_EVENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: getApiErrorMessage(action.payload),
      }
    default:
      return state
  }
}
