import { surveyResponsesCollection } from '@/reducers/surveyResponses'
import { SurveyResponse } from '@/types'

type SurveyNames =
  | 'NPS Survey 1'
  | 'Onboarding Survey 1'
  | 'SMUD MEO Survey 1'
  | 'Tesla Fleet Auth Survey 1'
  | 'Missing Brand Survey 1'
  | 'Autogrid Existing User Survey 1'
  | 'Plan Switch Approval 1'
  | 'Clark EVSE 1'
  | 'Feedback Survey'
  | 'Profile Deletion 1'
  | 'Insights Dismissal 1'
  | 'Rate Watch 1'
  | 'Program Unenrollment 1'
  | 'Live Chat 1'
  | 'Heard From 1'
  | 'Gexa Navigate To Switch 1'
  | 'Gexa Complete Switch 1'

export function useUserSurveyOf(
  surveyName: SurveyNames,
): SurveyResponse | undefined {
  const nonPendingResponses = surveyResponsesCollection.useFetch({
    params: {
      pending_response: false,
    },
  })?.data

  const pendingResponses = surveyResponsesCollection.useFetch({
    params: {
      pending_response: true,
    },
  })?.data

  return [...nonPendingResponses, ...pendingResponses]?.find(
    (survey) => survey.survey.name.toLowerCase() === surveyName.toLowerCase(),
  )
}
