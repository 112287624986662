import { useState } from 'react'
import styled from 'styled-components'

import '../../vendor/roundedBarCharts'

import {
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography as MuiTypography,
} from '@material-ui/core'

import { Skeleton } from '@material-ui/lab'
import { spacing } from '@material-ui/system'
import { getCharges } from '../../../actions/metric'
import { useDispatch, useSelector } from 'react-redux'
import { green } from '@material-ui/core/colors'
import { HelpCircle } from 'react-feather'
import GasSavingsDialog from '../GasSavingsDialog'
import DashboardChargesFilterGroup from '../DashboardChargesFilterGroup'

import theme from '../../../v2/theme'
import { ThemeProvider } from '@material-ui/core/styles'

const Card = styled(styled(MuiCard)(spacing))`
  border-radius: 20px;
`

const Typography = styled(MuiTypography)(spacing)

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`

const TypographyStyled = styled(MuiTypography)`
  color: ${(props) => props.theme.palette.grey[600]};

  span {
    color: ${(props) => props.percentagecolor};
    font-weight: ${(props) => props.theme.typography.fontWeightBold};
  }
`

const SmallInfo = styled(HelpCircle)`
    width: 14px;
    height: 14px;
    color: ${(props) => props.theme.palette.primary.main}
    margin-bottom: 2px;
`

const ChipPositionedView = styled.div`
  position: absolute;
  top: 8px;
  right: 16px;
`

const useDataFromExternalSource = () => {
  /* This hook will get the charges and vehicle data from the Redux store.
   */
  const dispatch = useDispatch()
  const charges = useSelector((state) => state.metric.charges)
  const isLoading = useSelector((state) => state.metric.isFetchingCharges)
  const chargesFailed = useSelector((state) => state.metric.chargesFailed)
  const numberOfVehicles = useSelector(
    (state) => state.vehicles.vehicles?.length ?? 0,
  )

  return [charges, numberOfVehicles, isLoading]
}

const TotalCost = ({ useData = useDataFromExternalSource, vehicleId }) => {
  const dispatch = useDispatch()
  const [charges, numberOfVehicles, isLoading] = useData()
  const [isOpen, setIsOpen] = useState(false)
  const reloadCharges = () =>
    dispatch(
      getCharges(1, charges.start_date, charges.end_date, charges.vehicle_id),
    )

  const handleClickOpen = (event) => {
    event.preventDefault()
    setIsOpen(true)
  }

  const handleGasConversionClose = () => {
    setIsOpen(false)
  }

  const handleGasConversionSubmitPressed = () => {
    if (numberOfVehicles === 1) {
      handleGasConversionClose()
    }
  }

  const handleGasConversionSubmit = () => {
    reloadCharges()
  }

  const currency = charges?.currency ?? 'USD'

  return (
    <ThemeProvider theme={theme}>
      <Box position="relative">
        <Card pt={2}>
          <CardContent>
            <Typography variant="h6" mb={4}>
              Total Spent
            </Typography>
            <Typography variant="h3" mb={3}>
              <Box fontWeight="fontWeightRegular">
                {isLoading ? (
                  <Skeleton animation="wave" />
                ) : (
                  new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: currency,
                  }).format(charges?.total_electricity_amount)
                )}
              </Box>
            </Typography>
            {currency === 'USD' && (
              <TypographyStyled
                variant="subtitle1"
                mb={4}
                percentagecolor={green[500]}
              >
                {isLoading ? (
                  <Skeleton animation="wave" />
                ) : (
                  vehicleId && (
                    <>
                      <span>
                        {`${new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: currency,
                        }).format(charges?.savings_amount)} in gas savings `}
                      </span>
                      <a href="true" onClick={handleClickOpen}>
                        <SmallInfo />
                      </a>
                    </>
                  )
                )}
                <GasSavingsDialog
                  open={isOpen}
                  onSubmit={handleGasConversionSubmit}
                  onSubmitPressed={handleGasConversionSubmitPressed}
                  onClose={handleGasConversionClose}
                  initialVehicleId={vehicleId}
                />
              </TypographyStyled>
            )}
            <ChipPositionedView>
              <DashboardChargesFilterGroup
                filters={['date']}
                vehicleId={vehicleId}
              />
            </ChipPositionedView>
          </CardContent>
        </Card>
      </Box>
    </ThemeProvider>
  )
}

export default TotalCost
