import { AlertMessage, NavigationPage } from '@/app/components'
import { Button, CardAction, CardActions, Grid, Text } from '@/components'
import check from '@/app/img/check.svg'
import { useAppSelector } from '@/hooks'
import { selectUtilityProgramForUserUtility } from '@/reducers/utilityPrograms'

function isBrowserLocaleInCanada(): boolean {
  const locale =
    navigator.language || (navigator.languages && navigator.languages[0])
  if (!locale) {
    return false
  }

  return locale.startsWith('en-CA') || locale.startsWith('fr-CA')
}

export default function EnrollmentConfirmationPage(props: {
  onClick: () => void
}) {
  const isCanada = isBrowserLocaleInCanada()
  // BC Hydro is utility program ID 13
  const isBCHydro =
    useAppSelector((state) => selectUtilityProgramForUserUtility(state))?.id ===
    13

  let content = (
    <Text className="max-w-[250px]" variant="h3" align="center">
      Great! You successfully {isCanada ? 'enroled' : 'enrolled'} in the program
    </Text>
  )

  if (isBCHydro) {
    content = (
      <>
        <Text className="max-w-[250px]" variant="h3" align="center">
          Great! We've received your submission and it's currently under review.
        </Text>
        <div>
          <AlertMessage variant="info">
            No further action is required. We'll notify you once verified. Stay
            tuned!
          </AlertMessage>
        </div>
      </>
    )
  }

  return (
    <NavigationPage
      id="enrollment-confirmation-page"
      className="p-0"
      navigationOptions={['nothing']}
    >
      <div className="h-full flex flex-col">
        <Grid
          className="min-h-[400px]"
          flow="row"
          gap="24px"
          placeItems="center"
          placeContent="center"
        >
          <img alt="checkmark" src={check} />
          {content}
        </Grid>
        <CardActions className="mt-12">
          <CardAction type="primary">
            <Button
              id="enrollment-confiirmation-continue-button"
              onClick={props.onClick}
            >
              Got it
            </Button>
          </CardAction>
        </CardActions>
      </div>
    </NavigationPage>
  )
}
