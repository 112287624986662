import React from 'react'
import { Sheet, Text } from '@/components'
import { Check } from 'react-feather'

type IconItemProps = {
  iconComponent: React.ReactNode
  title?: string
  subtitle?: string
  centerIcon?: boolean
  className?: string
  displayNew?: boolean
}

export default function IconItem(props: IconItemProps) {
  return (
    <div
      className={`flex ${props.centerIcon ? 'items-center' : ''} gap-5 ${
        props.className
      }`}
    >
      <div>{props.iconComponent}</div>
      <div className="flex-col">
        <div className="flex gap-1 items-center">
          {props.title && <Text variant="body1">{props.title}</Text>}
          {props.displayNew && (
            <Sheet color="blue-100">
              <Text variant="label" className="text-themed-blue-500">
                NEW
              </Text>
            </Sheet>
          )}
        </div>
        {props.subtitle && <Text variant="body4">{props.subtitle}</Text>}
      </div>
    </div>
  )
}
