import {
  CardActionButton,
  CardActions,
  Grid,
  GridItem,
  Icon,
} from '@/components'
import { Identifiable } from '@/types'
import formatClasses from '@/utils/classes/formatClasses'
import { getChildrenOnDisplayName } from '@/utils/components'
import './styles.scss'

type ActionWithPrecondition = {
  action: () => void
  precondition: boolean
}

export type GuardableAction = (() => void) | ActionWithPrecondition

interface ActionFunctionProps extends Identifiable {
  onNext?: GuardableAction
  onBack?: GuardableAction
  onSkip?: GuardableAction
}

export interface Props extends ActionFunctionProps {
  children: React.ReactNode
  gap?: string
  className?: string
  gridClassName?: string
}

function getConditionForAction(action: GuardableAction) {
  if (typeof action === 'function') {
    return action
  }
  return action.precondition
}

function getActionForAction(action: GuardableAction) {
  if (typeof action === 'function') {
    return action
  }
  return action.action
}

function ActionFunctions(props: ActionFunctionProps) {
  return (
    <CardActions>
      {props.onBack && (
        <CardActionButton
          type="navigation"
          onClick={getActionForAction(props.onBack)}
          disabled={!getConditionForAction(props.onBack)}
          id={`${props.id}-back-button`}
        >
          <Icon name="ChevronLeft" color="grey-500" size={20} />
        </CardActionButton>
      )}
      {props.onSkip && (
        <CardActionButton
          type="primary"
          onClick={getActionForAction(props.onSkip)}
          disabled={!getConditionForAction(props.onSkip)}
          id={`${props.id}-skip-button`}
        >
          Skip
        </CardActionButton>
      )}
      {props.onNext && (
        <CardActionButton
          type="primary"
          onClick={getActionForAction(props.onNext)}
          disabled={!getConditionForAction(props.onNext)}
          id={`${props.id}-next-button`}
        >
          Next
        </CardActionButton>
      )}
    </CardActions>
  )
}

function FlowStep({ className, ...props }: Props) {
  const classes = formatClasses(['flow-step', className])

  const content = getChildrenOnDisplayName(props.children, 'Content')
  const actions = getChildrenOnDisplayName(props.children, 'Actions')

  const actionFunctionsPresent = [
    props.onNext,
    props.onBack,
    props.onSkip,
  ].some(Boolean)

  return (
    <div className={classes}>
      <Grid
        flow="row"
        fillHeight
        templateRows="auto 1fr"
        gap={props.gap ?? '48px'}
        className={props.gridClassName}
      >
        <GridItem>{content}</GridItem>
        {actionFunctionsPresent ? <ActionFunctions {...props} /> : actions}
      </Grid>
    </div>
  )
}

function Content(props: { children: React.ReactNode }) {
  return <>{props.children}</>
}
Content.displayName = 'Content'
FlowStep.Content = Content

function Actions(props: { children: React.ReactNode }) {
  return <>{props.children}</>
}
Actions.displayName = 'Actions'
FlowStep.Actions = Actions

export default FlowStep
