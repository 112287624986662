import { Button, Divider, LoadingAnimation, Text } from '@/components'
import HorizontalBar from '@/components/HorizontalBar'
import { AnimatePresence, motion } from 'framer-motion'
import useViewModel, {
  RecommendedPlanProps,
} from '@/app/pages/home-insights/current-rate/recommended-plan/useViewModel'
import { useEffect, useState } from 'react'
import { logEvent } from '@/logging'
import SurveyObserver from '@/app/components/SurveyObserver'
import PlanSurveyNotification from '@/app/pages/home-insights/current-rate/recommended-plan/plan-survey-notification'
import RateWatch from '@/app/pages/home-insights/current-rate/rate-watch'
import GexaCompleteSwitchSurvey from '@/app/components/SurveyObserver/GexaCompleteSwitchSurvey'
import { surveyResponsesCollection } from '@/reducers/surveyResponses'
import dayjs from 'dayjs'
import { useUserSurveyOf } from '@/app/hooks/useUserSurveyOf'
import { useAppDispatch } from '@/hooks'

export default function RecommendedPlan() {
  const dispatch = useAppDispatch()
  const didYouSwitchSurvey = useUserSurveyOf('Gexa Complete Switch 1')
  const [displaySwitchSurvey, setDisplaySwitchSurvey] = useState(false)

  const didYouSwitchSubmission = async (res: {
    plan_id: number | null
    switched: boolean
  }) => {
    if (!didYouSwitchSurvey) {
      return
    }
    await dispatch(
      surveyResponsesCollection.actions.update(+didYouSwitchSurvey.id, {
        completed: dayjs().toISOString(),
        last_seen: dayjs().toISOString(),
        response: {
          plan_id: res.plan_id,
          switched: res.switched,
          version: didYouSwitchSurvey.survey.version,
        },
      }),
    )
  }

  const dismissSwitchSurvey = () => {
    if (!didYouSwitchSurvey) {
      return
    }

    surveyResponsesCollection.actions.update(+didYouSwitchSurvey.id, {
      dismissed: dayjs().toISOString(),
    })
  }

  return (
    <SurveyObserver observedSurveys={['GexaNavigateToSwitch']}>
      <>
        <RecommendedPlanComponent
          onLearnMore={() => setDisplaySwitchSurvey(true)}
        />
        <GexaCompleteSwitchSurvey
          onSubmit={didYouSwitchSubmission}
          onDismiss={dismissSwitchSurvey}
          surveyVersion={didYouSwitchSurvey?.survey?.version ?? '1.0'}
          utilityId={+(didYouSwitchSurvey?.survey?.metadata?.utility_id ?? 0)}
          surveyOpen={displaySwitchSurvey}
          onClose={() => setDisplaySwitchSurvey(false)}
        />
      </>
    </SurveyObserver>
  )
}

function RecommendedPlanComponent(props: RecommendedPlanProps) {
  const {
    comparison,
    planIsPending,
    fetchingPlans,
    planName,
    isLoading,
    newCosts,
    savings,
    currentCosts,
    percentDiff,
    learnMore,
    displayRecommendation,
    displayApprovalSurvey,
  } = useViewModel(props)

  useEffect(() => {
    if (!comparison) {
      return
    }

    logEvent('currentRateViewed', {
      metadata: { projected_savings: comparison.current_plan_difference },
    })
  }, [comparison])

  const loadingContainer = (
    <div className="flex flex-col justify-around items-center mt-8">
      <LoadingAnimation type="falling" />
      <Text variant="body1" className="pt-2">
        Checking for better plans...
      </Text>
    </div>
  )

  const rateWatch = (
    <div className="mt-6">
      <RateWatch />
    </div>
  )

  // Prioritize survey loading so user doesn't have to wait for long /compare results
  if (displayApprovalSurvey && !fetchingPlans) {
    return <PlanSurveyNotification />
  }

  // Without a plan, there are going to be no recommendations, so don't bother showing
  // the user we're looking until we have a plan
  if (fetchingPlans) {
    return null
  }

  if (planIsPending) {
    return rateWatch
  }

  const suggestedPlan = (
    <div className="mt-8">
      <Text variant="h3">Rate For You</Text>
      <Text variant="body4" className="mt-2">
        Here's a plan offered by {planName} that could potentially help you save
        based off your{' '}
        {comparison?.source === 'vehicle' ? 'charge ' : 'energy '}
        usage
      </Text>
      <div className="mt-7 bg-themed-white border rounded-[20px] shadow-spread py-4 px-5">
        <img alt="Plan logo" src="/img/gexa_energy.jpeg" className="h-[30px]" />
        <Text variant="h3" className="mt-1">
          Save with the {planName} Plan
        </Text>
        <div className="mt-6">
          <HorizontalBar
            percentWidth={Math.min(percentDiff, 95)}
            height={30}
            className={'bg-gradient-to-r from-[#8158f2] to-[#4d63F2]'}
            rounded={true}
          >
            <Text variant="h3">${newCosts}</Text>
            <Text variant="body4">with EV plan</Text>
          </HorizontalBar>
        </div>
        <div className="mt-4">
          <HorizontalBar
            percentWidth={100}
            height={15}
            className={
              'bg-gradient-to-r from-[#8158f2] to-[#4d63F2] opacity-50'
            }
            rounded={true}
          >
            <Text variant="body4" className="whitespace-nowrap">
              Current plan
            </Text>
          </HorizontalBar>
        </div>
        <Divider className={'mt-6'} />
        <div className="grid grid-cols-2 mt-6">
          <div
            className="pr-4 text-right"
            style={{ borderRight: 'solid 2px #EDEDED' }}
          >
            <Text variant="body1" className="text-themed-grey-500">
              Your current plan
            </Text>
            <Text variant="h3" className="mt-1">
              ${Math.round(currentCosts)}
            </Text>
          </div>
          <div className="pl-4 text-left">
            <Text variant="body1" className="text-themed-grey-500">
              Potential savings*
            </Text>
            <Text variant="h3" className="mt-1 text-themed-green-900">
              ${savings}/month
            </Text>
          </div>
        </div>
        <Button
          id="learn-more-button"
          variant="primary"
          className="mt-8 mb-2"
          onClick={learnMore}
        >
          Learn More
        </Button>
      </div>
    </div>
  )

  return (
    <AnimatePresence mode="wait">
      {!isLoading && !displayRecommendation && (
        <motion.div
          key="suggested-plan"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {rateWatch}
        </motion.div>
      )}
      {!isLoading && comparison && displayRecommendation && (
        <motion.div
          key="suggested-plan"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {suggestedPlan}
        </motion.div>
      )}
      {isLoading && (
        <motion.div
          key="loading-container"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {loadingContainer}
        </motion.div>
      )}
    </AnimatePresence>
  )
}
