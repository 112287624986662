import * as SentryLib from '@sentry/browser'
import Env from '@/utils/env'

type LogOptions = {
  logToConsole?: boolean
}

export function logMessage(message: string, options: LogOptions = {}) {
  if (Env.isLocal) {
    console.log(`Sentry message: ${message}`)
    return
  }
  if (options.logToConsole) {
    console.log(message)
  }
  SentryLib.captureMessage(message)
}

// eslint-disable-next-line
export function logException(exception: any, options: LogOptions = {}) {
  if (Env.isLocal) {
    console.log(`Sentry exception: ${exception}`)
    return
  }
  if (options.logToConsole) {
    console.error(exception)
  }
  SentryLib.captureException(exception)
}

const Sentry = {
  logMessage,
  logException,
}

export default Sentry
