import { createSelector } from '@reduxjs/toolkit'
import selectLastMeasurementForThermostat from './selectLastMeasurementForThermostat'
import selectLatestAdjustmentForThermostat from './selectLatestAdjustmentForThermostat'
import dayjs from 'dayjs'

const selectLatestMeasurementForThermostat = createSelector(
  [
    (state, id) => selectLatestAdjustmentForThermostat(state, id),
    (state, id) => selectLastMeasurementForThermostat(state, id),
  ],
  (latestAdjustment, lastMeasurement) => {
    if (
      latestAdjustment &&
      lastMeasurement &&
      dayjs(latestAdjustment.created).isAfter(
        dayjs(String(lastMeasurement.api_event_timestamp)),
      )
    ) {
      return {
        ...latestAdjustment.thermostat_measurement,
        // return the adjustment setpoints if they exist, becauase in this case they are newer than the last measurement
        heat_setpoint:
          latestAdjustment.heat_setpoint ?? lastMeasurement.heat_setpoint,
        cool_setpoint:
          latestAdjustment.cool_setpoint ?? lastMeasurement.cool_setpoint,
      }
    }
    return lastMeasurement
  },
)

export default selectLatestMeasurementForThermostat
