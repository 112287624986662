import { Thermostat } from '../types/thermostat'
import { createResourceCollection } from '../request'

export const thermostatsCollection = createResourceCollection<Thermostat>({
  name: 'thermostats',
  apiConfig: {
    path: 'thermostats',
    annotations: {
      adjustments: { path: 'adjustments', paginated: true },
      cost_history: { path: 'cost_history' },
      'cost_history/summary': { path: 'cost_history/summary' },
      schedules: { path: 'schedules' },
    },
  },
  selector: (state) => state.thermostats,
})

const thermostatsReducer = thermostatsCollection.slice.reducer

export default thermostatsReducer
