import { useState } from 'react'
import styled from 'styled-components'

import {
  Grid as MuiGrid,
  Box as MuiBox,
  Button as MuiButton,
  Collapse,
} from '@material-ui/core'
import { ChevronDown, ChevronUp } from 'react-feather'

import { spacing } from '@material-ui/system'
import { makeStyles } from '@material-ui/styles'
import DashboardCard, {
  DashboardDivider,
  DashboardSections,
} from '../../DashboardCard'
import { UsageSectionSubtitleTypography } from '../UsageSectionTypography'
import HomeComparisonExample from '../UsageOverview/HomeComparison/Example'
import NotificationBadge from '../../NotificationBadge'
import ColorBoxedInfoBox from '../../ColorBoxedInfoBox'

const Grid = styled(MuiGrid)(spacing)
const Box = styled(MuiBox)(spacing)
const Button = styled(MuiButton)(spacing)

const useStyles = makeStyles((theme) => ({
  seeExampleText: {
    textAlign: 'center',
    textDecoration: 'underline',
  },
  chevron: {
    marginLeft: '0.25rem',
  },
  headerContainer: {
    width: '100%',
  },
}))

const UtilitySetupCard = ({
  children,
  header,
  subHeader,
  Icon,
  notificationNumber = 1,
}) => {
  const classes = useStyles()

  const [showExample, setShowExample] = useState(false)

  return (
    <DashboardCard>
      <DashboardSections>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <NotificationBadge
              className={classes.headerContainer}
              badgeContent={notificationNumber}
            >
              <ColorBoxedInfoBox header={header} info={subHeader} Icon={Icon} />
            </NotificationBadge>
          </Grid>
        </Grid>
        {children}
        <Collapse in={showExample}>
          <Box>
            <DashboardDivider py={4} />
            <HomeComparisonExample />
          </Box>
        </Collapse>
        <DashboardDivider />
        <Grid container justifyContent="center">
          <Grid item>
            <Button p={0} onClick={() => setShowExample(!showExample)}>
              <UsageSectionSubtitleTypography
                className={classes.seeExampleText}
              >
                {!showExample ? 'See' : 'Hide'} Example Breakdown
                {!showExample ? (
                  <ChevronDown className={classes.chevron} size={20} />
                ) : (
                  <ChevronUp className={classes.chevron} size={20} />
                )}
              </UsageSectionSubtitleTypography>
            </Button>
          </Grid>
        </Grid>
      </DashboardSections>
    </DashboardCard>
  )
}

export default UtilitySetupCard
