import styled from 'styled-components'
import {
  TextField,
  Box as MuiBox,
  makeStyles,
  useTheme,
  Menu,
  MenuItem,
} from '@material-ui/core'
import { spacing } from '@material-ui/system'
import { ChevronDown } from 'react-feather'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { useRef, useState } from 'react'
import DesktopTimePicker from '@/app/components/DesktopTimePicker/DesktopTimePicker'
import useIsMobile from '../hooks/useIsMobile'

const Box = styled(MuiBox)(spacing)

const useStyles = makeStyles((theme) => ({
  mobileTimeBox: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.blue.background,
    borderRadius: '10px',
    margin: '0px !important',
  },
  endAdornment: {
    marginRight: '8px',
    marginTop: '2px',
  },
  inputText: {
    fontWeight: '700',
    fontSize: '13px',
    color: theme.palette.blue.main,
    backgroundColor: theme.palette.blue.background,
    paddingLeft: '4px',
    display: 'inline-block',

    '&::-webkit-calendar-picker-indicator': {
      background: 'transparent',
      bottom: 0,
      color: 'transparent',
      cursor: 'pointer',
      height: 'auto',
      left: '0',
      position: 'absolute',
      right: 0,
      top: 0,
      width: 'auto',
    },
  },
}))

const DEFAULT_HOUR = 9
const DEFAULT_MINUTE = 0

const isMobile = useIsMobile()

const SmartTimePicker = ({ onChange: onChange, hour, minute }) => {
  const [desktopTimePickerOpen, setDesktopTimePickerOpen] = useState(false)
  const ref = useRef(null)
  const classes = useStyles()
  const theme = useTheme()

  const value = dayjs()
    .set('hours', hour ?? DEFAULT_HOUR)
    .set('minutes', minute ?? DEFAULT_MINUTE)

  const desktopOnChange = (date) => {
    if (!onChange) {
      return
    }
    onChange({ hour: date.hour(), minute: date.minute() })
  }

  const mobileOnChange = ({ currentTarget }) => {
    let hour = DEFAULT_HOUR
    let minute = DEFAULT_MINUTE
    if (currentTarget?.value && currentTarget.value.includes(':')) {
      hour = parseInt(currentTarget.value.split(':')[0])
      minute = parseInt(currentTarget.value.split(':')[1])
    }
    if (onChange) {
      onChange({ hour, minute })
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box className={classes.mobileTimeBox}>
        <TextField
          id="time"
          type="time"
          value={value.format('HH:mm')}
          ref={ref}
          onClick={(e) => {
            if (!isMobile) {
              e.preventDefault()
              setDesktopTimePickerOpen(true)
            }
          }}
          onChange={mobileOnChange}
          InputProps={{
            endAdornment: (
              <ChevronDown
                className={classes.endAdornment}
                color={theme.palette.blue.main}
                size={18}
              />
            ),
            disableUnderline: true,
            classes: {
              input: classes.inputText,
            },
          }}
          inputProps={{
            step: 900,
          }}
        />
        <Menu
          open={desktopTimePickerOpen}
          anchorEl={ref.current}
          onClose={() => setDesktopTimePickerOpen(false)}
        >
          <MenuItem className="!bg-white hover:cursor-default">
            <DesktopTimePicker
              defaultValue={value}
              onChange={(value) => {
                desktopOnChange(value)
                setDesktopTimePickerOpen(false)
              }}
            />
          </MenuItem>
        </Menu>
      </Box>
    </LocalizationProvider>
  )
}

export default SmartTimePicker
