import { RSAA } from 'redux-api-middleware'
import { withAuth } from '../reducers'
import config from '../config'

export var VEHICLE_TIMELINE_REQUEST = '@@admin/VEHICLE_TIMELINE_REQUEST'
export var VEHICLE_TIMELINE_SUCCESS = '@@admin/VEHICLE_TIMELINE_SUCCESS'
export var VEHICLE_TIMELINE_FAILURE = '@@admin/VEHICLE_TIMELINE_FAILURE'

export var USER_PLAN_UPDATE_REQUEST = '@@admin/USER_PLAN_UPDATE_REQUEST'
export var USER_PLAN_UPDATE_SUCCESS = '@@admin/USER_PLAN_UPDATE_SUCCESS'
export var USER_PLAN_UPDATE_FAILURE = '@@admin/USER_PLAN_UPDATE_FAILURE'

export var CHARGE_FORECAST_ADMIN_REQUEST =
  '@@admin/CHARGE_FORECAST_ADMIN_REQUEST'
export var CHARGE_FORECAST_ADMIN_SUCCESS =
  '@@admin/CHARGE_FORECAST_ADMIN_SUCCESS'
export var CHARGE_FORECAST_ADMIN_FAILURE =
  '@@admin/CHARGE_FORECAST_ADMIN_FAILURE'

export var ELIGIBILITY_ADMIN_REQUEST = '@@admin/ELIGIBILITY_ADMIN_REQUEST'
export var ELIGIBILITY_ADMIN_SUCCESS = '@@admin/ELIGIBILITY_ADMIN_SUCCESS'
export var ELIGIBILITY_ADMIN_FAILURE = '@@admin/ELIGIBILITY_ADMIN_FAILURE'
export var PROGRAM_REQUIREMENTS_ADMIN_REQUEST =
  '@@admin/PROGRAM_REQUIREMENTS_ADMIN_REQUEST'
export var PROGRAM_REQUIREMENTS_ADMIN_SUCCESS =
  '@@admin/PROGRAM_REQUIREMENTS_ADMIN_SUCCESS'
export var PROGRAM_REQUIREMENTS_ADMIN_FAILURE =
  '@@admin/PROGRAM_REQUIREMENTS_ADMIN_FAILURE'

export const ADMIN_USER_INFORMATION_REQUEST =
  '@@admin/ADMIN_USER_INFORMATION_REQUEST'
export const ADMIN_USER_INFORMATION_SUCCESS =
  '@@admin/ADMIN_USER_INFORMATION_SUCCESS'
export const ADMIN_USER_INFORMATION_FAILURE =
  '@@admin/ADMIN_USER_INFORMATION_FAILURE'

// Device requests
export const ADMIN_USER_VEHICLE_REQUEST = '@@admin/ADMIN_USER_VEHICLE_REQUEST'
export const ADMIN_USER_VEHICLE_SUCCESS = '@@admin/ADMIN_USER_VEHICLE_SUCCESS'
export const ADMIN_USER_VEHICLE_FAILURE = '@@admin/ADMIN_USER_VEHICLE_FAILURE'
export const ADMIN_USER_CHARGER_REQUEST = '@@admin/ADMIN_USER_CHARGER_REQUEST'
export const ADMIN_USER_CHARGER_SUCCESS = '@@admin/ADMIN_USER_CHARGER_SUCCESS'
export const ADMIN_USER_CHARGER_FAILURE = '@@admin/ADMIN_USER_CHARGER_FAILURE'
export const ADMIN_USER_THERMOSTAT_REQUEST =
  '@@admin/ADMIN_USER_THERMOSTAT_REQUEST'
export const ADMIN_USER_THERMOSTAT_SUCCESS =
  '@@admin/ADMIN_USER_THERMOSTAT_SUCCESS'
export const ADMIN_USER_THERMOSTAT_FAILURE =
  '@@admin/ADMIN_USER_THERMOSTAT_FAILURE'

export const getVehicleTimeline = (data) => ({
  [RSAA]: {
    endpoint: config.apiGateway.URL + '/api/admin/timelines/',
    method: 'POST',
    body: JSON.stringify(data),
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      VEHICLE_TIMELINE_REQUEST,
      VEHICLE_TIMELINE_SUCCESS,
      VEHICLE_TIMELINE_FAILURE,
    ],
  },
})

export const updateUserPlan = (data) => ({
  [RSAA]: {
    endpoint: config.apiGateway.URL + '/api/admin/update_plan/',
    method: 'POST',
    body: JSON.stringify(data),
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      USER_PLAN_UPDATE_REQUEST,
      USER_PLAN_UPDATE_SUCCESS,
      USER_PLAN_UPDATE_FAILURE,
    ],
  },
})

export const getChargeForecast = (data) => ({
  [RSAA]: {
    endpoint: config.apiGateway.URL + '/api/admin/charge_forecast/',
    method: 'POST',
    body: JSON.stringify(data),
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      CHARGE_FORECAST_ADMIN_REQUEST,
      CHARGE_FORECAST_ADMIN_SUCCESS,
      CHARGE_FORECAST_ADMIN_FAILURE,
    ],
  },
})

export const getUserInformation = (email, profileID) => ({
  [RSAA]: {
    endpoint: `${config.apiGateway.URL}/api/admin/users/?${
      email ? `email=${email}` : `profile_id=${profileID}`
    }`,
    method: 'GET',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      ADMIN_USER_INFORMATION_REQUEST,
      ADMIN_USER_INFORMATION_SUCCESS,
      ADMIN_USER_INFORMATION_FAILURE,
    ],
  },
})

export const getUserVehicleInformation = (email, profileID) => ({
  [RSAA]: {
    endpoint: `${config.apiGateway.URL}/api/admin/vehicles/?${
      email ? `email=${email}` : `profile_id=${profileID}`
    }`,
    method: 'GET',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      ADMIN_USER_VEHICLE_REQUEST,
      ADMIN_USER_VEHICLE_SUCCESS,
      ADMIN_USER_VEHICLE_FAILURE,
    ],
  },
})
export const getUserChargerInformation = (email, profileID) => ({
  [RSAA]: {
    endpoint: `${config.apiGateway.URL}/api/admin/chargers/?${
      email ? `email=${email}` : `profile_id=${profileID}`
    }`,
    method: 'GET',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      ADMIN_USER_CHARGER_REQUEST,
      ADMIN_USER_CHARGER_SUCCESS,
      ADMIN_USER_CHARGER_FAILURE,
    ],
  },
})
export const getUserThermostatInformation = (email, profileID) => ({
  [RSAA]: {
    endpoint: `${config.apiGateway.URL}/api/admin/thermostats/?${
      email ? `email=${email}` : `profile_id=${profileID}`
    }`,
    method: 'GET',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      ADMIN_USER_THERMOSTAT_REQUEST,
      ADMIN_USER_THERMOSTAT_SUCCESS,
      ADMIN_USER_THERMOSTAT_FAILURE,
    ],
  },
})

export const getProgramEligibility = (profileID, programId) => ({
  [RSAA]: {
    endpoint: `${config.apiGateway.URL}/api/admin/utility_program_eligibility/program?profile_id=${profileID}&program_id=${programId}`,
    method: 'GET',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      ELIGIBILITY_ADMIN_REQUEST,
      ELIGIBILITY_ADMIN_SUCCESS,
      ELIGIBILITY_ADMIN_FAILURE,
    ],
  },
})

export const getDeviceEligibility = (
  profileID,
  programId,
  deviceType,
  deviceId,
) => ({
  [RSAA]: {
    endpoint: `${config.apiGateway.URL}/api/admin/utility_program_eligibility/device?profile_id=${profileID}&program_id=${programId}&device_type=${deviceType}&device_id=${deviceId}`,
    method: 'GET',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      DEVICE_ELIGIBILITY_ADMIN_REQUEST,
      DEVICE_ELIGIBILITY_ADMIN_SUCCESS,
      DEVICE_ELIGIBILITY_ADMIN_FAILURE,
    ],
  },
})

export const getProgramRequirements = (profileID, programId) => ({
  [RSAA]: {
    endpoint: `${config.apiGateway.URL}/api/admin/utility_program_eligibility/requirements?profile_id=${profileID}&program_id=${programId}`,
    method: 'GET',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      PROGRAM_REQUIREMENTS_ADMIN_REQUEST,
      PROGRAM_REQUIREMENTS_ADMIN_SUCCESS,
      PROGRAM_REQUIREMENTS_ADMIN_FAILURE,
    ],
  },
})
