import { Box as MuiBox } from '@material-ui/core'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { Circle } from 'react-feather'
import SegmentedCapsule from '../../../visualization/SegmentedCapsule'
import IconLegend from '../../../visualization/IconLegend'
import {
  LegendLabelTypography,
  LegendValueTypography,
} from '../../UsageSectionTypography/LegendTypography'
import dayjs from 'dayjs'

const Box = styled(MuiBox)(spacing)

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    position: 'relative',
  },
  segmentedCapsuleContainer: {
    position: 'relative',
    zIndex: 2,
  },
  legendContainer: {
    position: 'relative',
    zIndex: 1,
  },
  segmentTextContainer: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  segmentTextCoverBackground: {
    background: 'white',
  },
  segmentText: {
    textAlign: 'center',
    overflow: 'visible',
    whiteSpace: 'nowrap',
  },
  boundText: {
    color: '#5D5D5D',
  },
  segmentTextLarge: {
    fontSize: '2.4px',
  },
  segmentTextNormal: {
    fontSize: '3.8px',
  },
  segmentTextSmall: {
    fontSize: '4.4px',
  },
  legendLabel: {
    fontWeight: theme.typography.fontWeightBold,
  },
  unselected: {
    opacity: 0.3,
  },
  selected: {
    opacity: 1,
  },
}))

const SegmentCapsuleLabel = ({
  lowerHourBound,
  upperHourBound,
  hidden,
  className = '',
  segmentBBox,
  ...restProps
}) => {
  const classes = useStyles()
  const lower = dayjs().hour(lowerHourBound).minute(0).format('h:mma')
  const upper = dayjs().hour(upperHourBound).minute(0).format('h:mma')

  return (
    <Box
      className={classes.segmentTextContainer}
      style={{
        left: segmentBBox.left,
        top: segmentBBox.top - segmentBBox.height,
        width: segmentBBox.width,
        height: segmentBBox.height,
      }}
    >
      <LegendLabelTypography
        className={clsx(classes.segmentText, className)}
        hidden={hidden}
        {...restProps}
      >
        {lower} - {upper}
      </LegendLabelTypography>
    </Box>
  )
}

const SegmentedCapsuleBound = ({
  className = '',
  segmentBBox,
  isMinimum,
  ...restProps
}) => {
  const classes = useStyles()
  return (
    <Box
      className={classes.segmentTextContainer}
      style={{
        left: segmentBBox.left,
        top: segmentBBox.top - segmentBBox.height,
        width: segmentBBox.width,
        height: segmentBBox.height,
        justifyContent: isMinimum ? 'flex-start' : 'flex-end',
      }}
    >
      <LegendValueTypography
        className={clsx(classes.segmentText, className)}
        {...restProps}
      >
        12am
      </LegendValueTypography>
    </Box>
  )
}

// @todo pass currency
const DailyTOURateViz = ({
  coloredStops,
  selectedColorStop,
  setSelectedColorStop,
  defaultHour,
}) => {
  const classes = useStyles()

  const selectedColorStopDefault = ({ start, stop }) =>
    start <= defaultHour && stop > defaultHour

  const segmentedCapsuleHeightPercentage = 5
  const borderRadius = segmentedCapsuleHeightPercentage / 2 / 1.75

  return (
    <Box className={classes.container}>
      {/* We use an invisible segmned capsule to create space for the absolutely placed segment labels */}
      <SegmentedCapsule
        segmentStops={[]}
        heightPercentage={segmentedCapsuleHeightPercentage / 2}
        backgroundFill="rgba(255,255,255,0.0)"
      />
      <SegmentedCapsule
        className={classes.segmentedCapsuleContainer}
        segmentStops={coloredStops}
        selectedSegmentDefault={selectedColorStopDefault}
        heightPercentage={segmentedCapsuleHeightPercentage}
        borderRadius={borderRadius}
        segmentPaddingPercentage={0.5}
        backgroundFill="#ECF1FF"
        pb={2}
        segmentClassName={classes.unselected}
        segmentHoverClassName={classes.selected}
        onHover={(colorStop) => {
          setSelectedColorStop(colorStop)
        }}
        boundsRender={(isMinimum, bbox) => (
          <SegmentedCapsuleBound segmentBBox={bbox} isMinimum={isMinimum} />
        )}
        labelRender={(l, u, hovered, bbox) => (
          <SegmentCapsuleLabel
            lowerHourBound={l}
            upperHourBound={u}
            hidden={!hovered}
            segmentBBox={bbox}
          />
        )}
      />
      <IconLegend
        className={classes.legendContainer}
        data={coloredStops.map(({ fill, label, value, rate }) => ({
          color: fill,
          label,
          value,
          sortKey: rate?.centered_rate_index,
        }))}
        itemClassName={({ color, label, value }) => {
          return color === selectedColorStop?.fill
            ? classes.selected
            : classes.unselected
        }}
        iconRender={(color) => <Circle size={10} fill={color} color={color} />}
        labelRender={(label) => (
          <LegendLabelTypography>{label}</LegendLabelTypography>
        )}
        valueRender={(value) => (
          <LegendValueTypography>{value}</LegendValueTypography>
        )}
        spacing={6}
      />
    </Box>
  )
}

export default DailyTOURateViz
