import { selectPlan } from '@/actions/plans'
import { useState } from 'react'
import { useAppDispatch, useAppSelector } from '@/hooks'
import useMockableViewModel from '@/hooks/useMockableViewModel'

interface Props {
  onComplete?: () => void
}

function useViewModel(props: Props) {
  const dispatch = useAppDispatch()
  const [selectedPlanId, setSelectedPlanId] = useState<
    string | undefined | null
  >()
  const isLoading = useAppSelector(
    (state) => state.plans.isLoading || state.utilities.isLoading,
  )

  const onChange = (planId: number) => {
    setSelectedPlanId(planId.toString())
  }

  const onFinish = () => {
    dispatch(selectPlan({ plan_id: selectedPlanId })).then(() => {
      props.onComplete?.()
    })
  }

  const disabled = isLoading || !selectedPlanId

  return { selectedPlanId, onChange, onFinish, disabled }
}

function useMockViewModel(props: Props) {
  return {
    selectedPlanId: undefined as string | undefined | null,
    onChange: (planId: number) => console.log(planId),
    onFinish: () => props.onComplete?.(),
    disabled: false,
  }
}

export default useMockableViewModel({ useViewModel, useMockViewModel })
