import { FullPageCard } from '@/app/components'
import UtilityProgramEnrollmentFlow from '@/app/features/utility-programs/UtilityProgramEnrollmentFlow'
import { useNavigation } from '@/app/hooks'

export default function UtilityEnrollment() {
  const navigation = useNavigation()
  const onFinish = () => {
    navigation.push('/app')
  }

  return (
    <FullPageCard>
      <UtilityProgramEnrollmentFlow flat onConnect={onFinish} />
    </FullPageCard>
  )
}
