import { SurveyType } from '@/types'

export interface SurveyProperties {
  type: SurveyViewType
  title?: string
  prompt?: string
  submitText?: string
  reasons?: SurveyReason[]
}

export interface SurveyReason {
  display: string
  value: string
}

export enum SurveyViewType {
  List,
  Dialog,
}

export default function surveyBuilder(
  surveyType: SurveyType,
): SurveyProperties[] {
  let reasons: SurveyReason[] = []
  let reasonSurvey: SurveyProperties
  let feedbackSurvey: SurveyProperties
  let title = 'User Survey'

  switch (surveyType) {
    case 'ProfileDeletion':
      title = 'Profile Deletion'
      reasons = [
        { display: 'Savings were not as expected', value: 'low_savings' },
        { display: "I'm not seeing the value", value: 'low_value' },
        { display: 'Security concerns', value: 'security_concerns' },
        { display: 'Charge scheduling issues', value: 'charge_scheduling' },
        {
          display: 'The app is too hard to navigate',
          value: 'navigation_issue',
        },
        { display: 'Compatibility issue', value: 'compatibility_issue' },
        { display: "I don't own a vehicle", value: 'no_vehicle' },
        { display: "I don't own a thermostat", value: 'no_thermostat' },
        { display: "I don't own any devices", value: 'no_devices' },
        { display: 'Other (please specify)', value: 'other' },
      ]

      reasonSurvey = {
        reasons,
        type: SurveyViewType.List,
        title,
        prompt: 'Can you tell us why you want to delete your profile?',
      }

      feedbackSurvey = {
        type: SurveyViewType.Dialog,
        title,
        prompt:
          'We will delete your profile. Let us know how we can better support your needs in the future.',
        submitText: 'Submit and Delete Profile',
      }

      return [reasonSurvey, feedbackSurvey]

    case 'ProgramUnenrollment':
      title = 'Program Unenrollment'
      reasons = [
        { display: 'Savings were less than expected', value: 'low_savings' },
        { display: 'Did not meet household needs', value: 'needs_not_met' },
        { display: 'Program too restrictive', value: 'too_restrictive' },
        { display: 'Customer service issues', value: 'customer_service' },
        {
          display: 'Complicated enrollment process',
          value: 'enrollment',
        },
        { display: 'Moving/no longer needed', value: 'moving' },
        { display: 'Other (please specify)', value: 'other' },
      ]

      reasonSurvey = {
        reasons,
        type: SurveyViewType.List,
        title,
        prompt:
          'Can you tell us why you chose to unenroll from this utility plan?',
      }

      feedbackSurvey = {
        type: SurveyViewType.Dialog,
        title,
        prompt:
          "You have been unenrolled in this utility program. Is there additional feedback you'd like to give?",
      }

      return [reasonSurvey, feedbackSurvey]

    default:
      throw new Error('Invalid survey type passed to survey builder')
  }
}
