import { Check } from 'react-feather'
import React from 'react'
import IconItem from '@/app/components/IconItem/IconItem'

type CheckItemProps = {
  size?: number
  children: string
  className?: string
}

export default function CheckItem(props: CheckItemProps) {
  return (
    <IconItem
      className={props.className}
      iconComponent={
        <Check width={props.size ?? 24} height={props.size ?? 24} />
      }
      subtitle={props.children}
    />
  )
}
