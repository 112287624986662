import './styles.scss'
import {
  RefObject,
  useCallback,
  useEffect,
  useState,
  useRef,
  HTMLAttributes,
} from 'react'
import { ReactNodeProps } from '../../types/reactNodeProps'
import { getChildrenOnDisplayName } from '../../utils/components'
import formatClasses from '../../utils/classes/formatClasses'
import List from '../List'
import Flex from '../Flex'
import Text from '../Text'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  open?: boolean
  onClick?: () => void
}

function useOnOutsideClickHandler(
  ref: RefObject<HTMLDivElement>,
  callback: () => void,
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (!(event.target instanceof Node)) return
      if (ref.current && !ref.current.contains(event.target)) {
        callback()
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, callback])
}

function Menu({ children, open: externalOpen, onClick, ...props }: Props) {
  const [interalOpen, setInteralOpen] = useState(externalOpen)
  const open = externalOpen ?? interalOpen
  const classes = {
    container: formatClasses(['menu-container']),
    menu: formatClasses(['menu', open ? 'open' : 'closed']),
    anchor: formatClasses(['anchor']),
  }

  const anchor = getChildrenOnDisplayName(children, 'Activator')
  const list = getChildrenOnDisplayName(children, 'List')

  const onMenuClick = () => {
    setInteralOpen((prev) => !prev)
    onClick?.()
  }

  const menuRef = useRef<HTMLDivElement>(null)
  const onOutsideMenuClick = useCallback(() => {
    if (open) {
      setInteralOpen(false)
    }
  }, [open])
  useOnOutsideClickHandler(menuRef, onOutsideMenuClick)

  return (
    <div className={classes.container} onClick={onMenuClick} {...props}>
      <div className={classes.anchor}>{anchor}</div>
      <div className={classes.menu} ref={menuRef}>
        {list}
      </div>
    </div>
  )
}

function Activator({ children }: ReactNodeProps) {
  return <>{children}</>
}
Activator.displayName = 'Activator'
Menu.Activator = Activator

interface MenuListItemProps extends ReactNodeProps {
  onClick?: () => void
}
function MenuListItem({ children, ...props }: MenuListItemProps) {
  const classes = formatClasses([
    'list-item',
    'menu-list-item',
    props.className,
  ])
  return (
    <Flex item className={classes} onClick={props.onClick}>
      <Text variant="body2">{children}</Text>
    </Flex>
  )
}
MenuListItem.displayName = 'ListItem'
Menu.ListItem = MenuListItem

function MenuList({ children }: ReactNodeProps) {
  return <List bordered>{children}</List>
}
MenuList.displayName = 'List'
Menu.List = MenuList

export default Menu
