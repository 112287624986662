import * as trips from '../actions/calendar.js'
import * as vehicleActions from '../actions/vehicles'
import { getApiErrorMessage } from '../utils/errorUtilities'
import { findIndexByPropertyValue } from '../utils/arrayUtilities'

const initialState = {
  trips: null,
  hasTriedFetching: false,
  isLoading: false,
  errors: {},
}

export default (state = initialState, action) => {
  let currentTrips
  switch (action.type) {
    case trips.LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasTriedFetching: true,
      }
    case trips.LIST_SUCCESS: {
      const existingTripsThatAreNotInNewTrips = (state.trips ?? []).filter(
        (trip) => !action.payload.some((newTrip) => newTrip.id === trip.id),
      )
      return {
        ...state,
        isLoading: false,
        // add or replace trips
        trips: [...existingTripsThatAreNotInNewTrips, ...action.payload],
      }
    }
    case trips.CREATE_SUCCESS:
      return {
        ...state,
        trips: [...state.trips, action.payload],
        isLoading: false,
      }
    case vehicleActions.CHANGE_VEHICLE_SUCCESS:
    case vehicleActions.DISABLE_VEHICLE_ACTIONS_SUCCESS:
      return {
        trips: null,
        hasTriedFetching: false,
        isLoading: false,
        errors: {},
      }
    case trips.LIST_FAILURE:
    case trips.CREATE_FAILURE:
    case trips.UPDATE_FAILURE:
    case trips.DELETE_FAILURE:
    case trips.DELETE_ALL_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: getApiErrorMessage(action.payload),
      }
    case trips.UPDATE_REQUEST:
    case trips.CREATE_REQUEST:
    case trips.DELETE_REQUEST:
    case trips.DELETE_ALL_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case trips.UPDATE_SUCCESS:
      let updatedTrip = action.payload
      currentTrips = [...state.trips]
      for (let i = 0; i < currentTrips.length; i++) {
        if (currentTrips[i].id === updatedTrip.id) {
          currentTrips[i] = updatedTrip
        }
      }

      return {
        ...state,
        trips: currentTrips,
        isLoading: false,
      }
    case trips.DELETE_SUCCESS:
      currentTrips = [...state.trips]
      let index = findIndexByPropertyValue(
        currentTrips,
        'id',
        action.payload.id,
      )
      if (index !== -1) {
        currentTrips.splice(index, 1)
      }

      return {
        ...state,
        trips: currentTrips,
        isLoading: false,
      }
    case trips.DELETE_ALL_SUCCESS:
      return {
        ...state,
        trips: null,
        isLoading: false,
      }
    default:
      return state
  }
}
