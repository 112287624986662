import { createReferral } from '@/actions/referrals'
import { useAppDispatch, useAppSelector, useUrlSearchParam } from '@/hooks'
import { logEvent } from '@/logging'
import {
  searchableUtilityProgramsCollection,
  selectSearchableProgramForReferralCode,
} from '@/reducers/utilityPrograms'
import { useEffect, useState } from 'react'
import Landing, { path as LandingPath, Props as LandingProps } from './Landing'
import { useLocation } from 'react-router-dom'
export { LandingPath }

const activeReferralMap = {
  bee: ['buzzz'],
}

const getActiveReferral = (
  referralCode: string | null,
  utilityProgramReferralActive: boolean,
) => {
  if (utilityProgramReferralActive) {
    return 'utilityProgram'
  }
  if (!referralCode) {
    return null
  }
  const activeReferralLabel = Object.entries(activeReferralMap).find(
    ([_, codes]) => codes.includes(referralCode),
  )?.[0]
  return (activeReferralLabel ?? null) as LandingProps['activeReferral']
}

function useViewModel() {
  const dispatch = useAppDispatch()
  const referralCode = useUrlSearchParam('referral_code')
  const urlPath = useLocation().pathname
  const [referralDismissed, setReferralDismissed] = useState(false)

  searchableUtilityProgramsCollection.useResourceFetcher(
    {
      pathSegments: ['search_config'],
      params: { referral_code: referralCode },
    },
    { require: Boolean(referralCode) },
  )
  const utilityProgramReferralActive = useAppSelector((state) =>
    Boolean(selectSearchableProgramForReferralCode(state, referralCode)),
  )

  useEffect(() => {
    if (referralCode) {
      dispatch(createReferral(referralCode))
      logEvent('opened_referral')
    }
  }, [referralCode])

  const isAva = urlPath.includes('/ava')

  const signUpPath = `${isAva ? '/ava' : ''}/signup${
    referralCode ? `?referral_code=${referralCode}` : ''
  }`

  return {
    activeReferral: referralDismissed
      ? null
      : getActiveReferral(referralCode, utilityProgramReferralActive),
    dismissReferral: () => setReferralDismissed(true),
    signUpPath,
  }
}

export default function Root() {
  const viewModel = useViewModel()
  return <Landing {...viewModel} />
}
