import { createResourceCollection } from '@/request'
import { ThermostatSavingsSummary } from '@/types'
import { addThermostatIdFromPath } from './collectionMiddleware'
import { ThermostatPrecoolSavingsSummary } from '@/types/thermostatSavingsSummary'

export const thermostatSavingsSummariesCollection =
  createResourceCollection<ThermostatSavingsSummary>({
    name: 'thermostatSavingsSummaries',
    selector: (state) => state.thermostatSavingsSummaries,
    apiConfig: {
      middlewares: [addThermostatIdFromPath<ThermostatSavingsSummary>()],
    },
  })

export const thermostatPrecoolSavingsSummariesCollection =
  createResourceCollection<ThermostatPrecoolSavingsSummary>({
    name: 'thermostatPrecoolSavingsSummaries',
    selector: (state) => state.thermostatPrecoolSavingsSummaries,
    apiConfig: {
      middlewares: [addThermostatIdFromPath<ThermostatPrecoolSavingsSummary>()],
    },
  })

export const thermostatPrecoolSavingsSummaries =
  thermostatPrecoolSavingsSummariesCollection.slice.reducer

export const thermostatSavingsSummaries =
  thermostatSavingsSummariesCollection.slice.reducer
