import { RootState } from '@/reducers'
import { chargeNowEventCollection } from '@/reducers/chargeNowEvents'
import * as Sentry from '@sentry/browser'

export function selectChargeNowEvent(state: RootState) {
  const events = chargeNowEventCollection.selectors.selectAll(state)

  if (events.length > 1) {
    Sentry.captureMessage('More than one charge now event found')
  }

  if (!events.length) {
    return null
  }

  const event = events[0]

  if (event.complete) {
    return null
  }

  return event
}
