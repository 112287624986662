import NavigationPage from '@/app/components/NavigationPage'
import { Button, Card, Text } from '@/components'
import ChargeLimitSlider from '@/app/components/ChargeLimitSlider/ChargeLimitSlider'
import { getVehicleDisplayName } from '@/utils/CarUtils'
import VehiclePage from '@/app/components/VehiclePage/VehiclePage'
import useVehicleSettingsManager from '@/hooks/useVehicleSettingsManager'
import { VehicleChargeSchedule } from '@/types'
import { useState } from 'react'
import { cloneDeep } from 'lodash'
import { AlertMessage } from 'app-components'

function VehicleLimits() {
  const { vehicle, debouncedSave } = useVehicleSettingsManager()
  const [localChargeSchedule, setLocalChargeSchedule] = useState<
    VehicleChargeSchedule | undefined
  >()

  const onSliderChange = (updatedChargeSchedule: VehicleChargeSchedule) => {
    const clonedSchedule = cloneDeep(updatedChargeSchedule)
    setLocalChargeSchedule({ ...clonedSchedule })
  }

  const onSubmit = () => {
    if (!localChargeSchedule) {
      return
    }

    debouncedSave(localChargeSchedule)
    setLocalChargeSchedule(undefined)
  }

  return (
    <Card>
      <Text variant={'h3'} className="mt-1">
        Adjust Charge Limits
      </Text>
      <Text variant={'body3'} className="mt-1.5">
        {getVehicleDisplayName(vehicle)}
      </Text>
      <div className="mt-5">
        <ChargeLimitSlider onChange={onSliderChange} />
      </div>
      <AlertMessage variant="info">
        Your car will reach your Charge Minimum upon plug-in, and reach your
        Charge Limit by departure
      </AlertMessage>
      <Button
        id={'submit-charge-schedule'}
        onClick={onSubmit}
        type="submit"
        variant="primary"
        disabled={!localChargeSchedule}
        savable={true}
        className="mt-6"
      >
        Submit
      </Button>
    </Card>
  )
}

export default function VehicleLimitsPage() {
  return (
    <NavigationPage
      id="charge-limits-page"
      title="Limits"
      subtitle="Use this to adjust your car's charge limit"
    >
      <VehiclePage>
        <VehicleLimits />
      </VehiclePage>
    </NavigationPage>
  )
}
