import { UtilityProgramEnrollment } from '../types/utilityProgramEnrollment'
import { createResourceCollection } from '../request'
import { createSelector } from '@reduxjs/toolkit'
import {
  cmsMiddleware,
  programWithEvaluatedConfigTemplateFromUtility,
} from './utilityPrograms'
import { Utility } from '@/types'
import { ResourceMiddlewareFunction } from '@/request/types'

export const utilityProgramEnrollmentCollection =
  createResourceCollection<UtilityProgramEnrollment>({
    name: 'utilityProgramEnrollments',
    apiConfig: {
      path: 'utility_program_enrollments',
      middlewares: [
        cmsMiddleware as ResourceMiddlewareFunction<UtilityProgramEnrollment>,
      ],
    },
    selector: (state) => state.utilityProgramEnrollments,
  })

export const selectUtilityProgramEnrollmentForUser = createSelector(
  [
    utilityProgramEnrollmentCollection.selectors.selectAll,
    (state) => state.user.user?.profile?.utility as Utility | undefined,
  ],
  (utilityProgramEnrollments, utility) => {
    if (!utility) {
      return undefined
    }
    // assumes there is only one utility program enrollment per user
    const utilityProgramEnrollment = utilityProgramEnrollments.find(
      (program) => {
        /** local grid programs (MA program) are utility programs that do not have a view config.
        They are not used the same as other utility programs and need to be filtered out. */
        // todo this is a hack that needs to be fixed - we should have a better way to identify local grid programs
        const isNotLocalGridProgram = !!Object.keys(
          program.utility_program.view_config_json,
        ).length

        return isNotLocalGridProgram
      },
    )

    if (!utilityProgramEnrollment) {
      return undefined
    }

    const utilityProgram = !utility
      ? utilityProgramEnrollment.utility_program
      : programWithEvaluatedConfigTemplateFromUtility(
          utilityProgramEnrollment.utility_program,
          {
            utility_name: utility.name,
          },
        )

    return {
      ...utilityProgramEnrollment,
      utility_program: utilityProgram,
    } as UtilityProgramEnrollment
  },
)

const reducer = utilityProgramEnrollmentCollection.slice.reducer

export default reducer
