import ThermostatSectionRoot, {
  Props as ComponentProps,
} from './ThermostatSection'
import useViewModel from './useViewModel'
import { ViewModelComponentProps } from '../../../../../../utils/typeUtilities'
import { ID } from '@/types/model'
import ErrorBoundary, {
  LocationTag,
} from '@/authenticated/components/ErrorBoundary'
import ThermostatDREventAlertAndDetails from '@/app/features/smart-thermostats/ThermostatDREventAlertAndDetails'

export type Props = ViewModelComponentProps<ComponentProps, typeof useViewModel>

export default function ThermostatSection(props: Props) {
  const viewModel = useViewModel(props)

  return <ThermostatSectionRoot {...viewModel} />
}

export function ThermostatSectionHeader({
  thermostatId,
}: {
  thermostatId: ID
}) {
  return (
    <ErrorBoundary
      location={LocationTag.Dashboard}
      functionalityDescription={
        'Thermostat Demand Response Event Alert and Details'
      }
      severity="critical"
      fallbackOnCard
    >
      <ThermostatDREventAlertAndDetails thermostatId={thermostatId} />
    </ErrorBoundary>
  )
}
