import {
  convertCelsiusToFahrenheit,
  convertFahrenheitToCelsius,
} from '../../utils/conversionUtilities'
import { useAppSelector } from '@/hooks'

export const useUsersPreferredTemperature = (
  tempValue: number,
  unit: 'F' | 'C' = 'F',
) => {
  const preferredUnit = useAppSelector(
    (state) => state.user.user?.profile?.temperature_preference,
  )

  if (preferredUnit === unit || !preferredUnit) {
    return {
      value: tempValue,
      unit: unit,
    }
  } else {
    const newValue =
      preferredUnit === 'C'
        ? convertFahrenheitToCelsius(tempValue)
        : convertCelsiusToFahrenheit(tempValue)
    return {
      value: newValue,
      unit: preferredUnit,
    }
  }
}
