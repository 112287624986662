import { useEffect, useState } from 'react'
import NewChargerData from './data'
import {
  Grid as MuiGrid,
  Typography as MuiTypography,
  Button,
  Hidden,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
} from '@material-ui/core'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { spacing } from '@material-ui/system'
import { makeStyles, styled } from '@material-ui/styles'
import { ArrowRight, ArrowDown, Activity } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { getCarModels } from '../../../../../actions/carModels'
import {
  getChargers,
  getChargerSavings,
  resetChargerSavings,
} from '../../../../../actions/chargers'
import { getAvgEnergyCostPerState } from '../../../../../actions/energy'
import Loader from '../../../../../authenticated/components/layout/Loader'
import Answer from './answer'
import { Link } from 'react-feather'
import theme from '../../../../../v2/theme'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { logEvent } from '../../../../../logging'

const Grid = styled(MuiGrid)(spacing)
const Typography = styled(MuiTypography)(spacing)

const excludedChargers = [18, 19, 20, 21]

const useStyles = makeStyles((theme) => ({
  poweredBy: {
    fontSize: 11,
    textTransform: 'uppercase',
  },
  subtitle: {
    fontWeight: 500,
  },
  dropdownControl: {
    width: '100%',
  },
  disclosure: {
    color: '#BCBCBC',
    fontSize: '12px',
  },
  taxLink: {
    fontSize: '12px',
    fontWeight: 'bold',
    textDecoration: 'underline',
    paddingLeft: '8px',
    color: '#BCBCBC',
  },
  form: {
    width: '100%',
  },
  share: {
    position: 'absolute',
    right: 0,
    top: 0,
    '&:focus': {
      outline: 0,
      border: 0,
    },
  },
  sharexs: {
    '&:focus': {
      outline: 0,
      border: 0,
    },
  },
}))

const validationSchema = yup.object({
  old: yup.number().required(),
  new: yup.number().required(),
  miles: yup.number().required(),
  model: yup.number().required(),
  state: yup.number().required(),
})

const NewCharger = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      old: '',
      new: '',
      miles: '',
      year: '',
      model: '',
      state: '',
    },
    validationSchema: validationSchema,
    onSubmit: (data) => {
      dispatch(getChargerSavings(data))
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
        window.postMessage('updateFrameHeight', '*')
      }, 1500)
    },
  })

  const [copied, setCopied] = useState(false)

  const states = useSelector((state) => state.energy.states)
  const chargers = useSelector((state) => state.chargers.chargers)
  const filteredChargers = (chargers || []).filter(
    (charger) => !excludedChargers.includes(charger.id),
  )

  const models = useSelector((state) => state.carModels.carModels)
  const carModels = (models || []).filter(
    (model) =>
      formik.values.year === '' || String(model.year) === formik.values.year,
  )
  if (carModels) {
    carModels.sort((a, b) => {
      const yearComparator = b.year - a.year
      if (yearComparator !== 0) {
        return yearComparator
      }
      return a.friendly_name.localeCompare(b.friendly_name)
    })
  }
  const years = (models || []).reduce((acc, i) => {
    if (!acc.includes(i.year)) {
      acc.push(i.year)
    }
    return acc
  }, [])

  const chargerSavings = useSelector((state) => state.chargers.savings)

  const resetForm = () => {
    dispatch(resetChargerSavings())
    window.postMessage('updateFrameHeight', '*')
  }

  useEffect(() => {
    dispatch(getCarModels())
    dispatch(getChargers())
    dispatch(getAvgEnergyCostPerState())
  }, [dispatch])

  const styles = useStyles()

  return (
    <Grid container p={1} spacing={2}>
      <Hidden only={['xs']}>
        <CopyToClipboard text="https://optiwatt.com/news/is-the-tesla-gen-3-wall-connector-worth-it#interactive">
          <Button
            variant="text"
            color="primary"
            className={styles.share}
            startIcon={<Link color={theme.palette.primary.main} size={16} />}
            onClick={() => setCopied(true)}
          >
            {!copied ? 'Copy Link' : 'Copied!'}
          </Button>
        </CopyToClipboard>
      </Hidden>
      <form
        noValidate
        autoComplete="off"
        onSubmit={formik.handleSubmit}
        className={styles.form}
      >
        <Grid item>
          <Typography variant="h3">
            Is a Fast Charger Worth the Money?
          </Typography>
          <Typography variant="h6" className={styles.subtitle} mt={1}>
            Let us tell you
          </Typography>
        </Grid>
        {!chargerSavings || loading ? (
          <>
            <Grid
              item
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              mt={4}
              spacing={4}
            >
              <Grid item xs={12} md={5}>
                <FormControl
                  variant="filled"
                  className={styles.dropdownControl}
                >
                  <InputLabel id="current-charger-label">
                    Existing Charger
                  </InputLabel>
                  <Select
                    labelId="current-charger-label"
                    name="old"
                    value={formik.values.old}
                    required={true}
                    error={formik.touched.old && Boolean(formik.errors.old)}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value="" />
                    {filteredChargers.map((charger, i) => (
                      <MenuItem value={charger.id}>{charger.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Hidden only={['xs', 'sm']}>
                <Grid item container md={2} justifyContent="center">
                  <Grid item>
                    <ArrowRight color="#5D5D5D" size={24} />
                  </Grid>
                </Grid>
              </Hidden>
              <Hidden only={['md', 'lg', 'xl']}>
                <Grid item container xs={12} justifyContent="center">
                  <Grid item>
                    <ArrowDown color="#5D5D5D" size={24} />
                  </Grid>
                </Grid>
              </Hidden>
              <Grid item xs={12} md={5}>
                <FormControl
                  variant="filled"
                  className={styles.dropdownControl}
                >
                  <InputLabel id="new-charger-label">New Charger</InputLabel>
                  <Select
                    name="new"
                    value={formik.values.new}
                    required={true}
                    error={formik.touched.new && Boolean(formik.errors.new)}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value="" />
                    {filteredChargers.map((charger, i) => (
                      <MenuItem value={charger.id}>{charger.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              mt={2}
              spacing={4}
            >
              <Grid item xs={12} md={2}>
                <FormControl
                  variant="filled"
                  className={styles.dropdownControl}
                >
                  <InputLabel id="year-label">Year</InputLabel>
                  <Select
                    name="year"
                    value={formik.values.year}
                    required={true}
                    error={formik.touched.year && Boolean(formik.errors.year)}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value="" />
                    {(years || []).map((year, i) => (
                      <MenuItem value={year}>{year}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl
                  variant="filled"
                  className={styles.dropdownControl}
                >
                  <InputLabel id="model-label">Model</InputLabel>
                  <Select
                    name="model"
                    value={formik.values.model}
                    required={true}
                    error={formik.touched.model && Boolean(formik.errors.model)}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value="" />
                    {(carModels || []).map((model, i) => (
                      <MenuItem value={model.id}>
                        {model.friendly_name} {model.trim}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} md={3}>
                <FormControl
                  variant="filled"
                  className={styles.dropdownControl}
                >
                  <InputLabel id="state-label">State</InputLabel>
                  <Select
                    name="state"
                    value={formik.values.state}
                    required={true}
                    error={formik.touched.state && Boolean(formik.errors.state)}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value="" />
                    {(states || []).map((model, i) => (
                      <MenuItem value={model.id}>{model.state.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} md={3}>
                <FormControl
                  variant="filled"
                  className={styles.dropdownControl}
                >
                  <InputLabel id="miles-label">Daily Miles</InputLabel>
                  <Select
                    name="miles"
                    value={formik.values.miles}
                    required={true}
                    error={formik.touched.miles && Boolean(formik.errors.miles)}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value="" />
                    {NewChargerData.miles.map((miles, i) => (
                      <MenuItem value={miles.key}>{miles.value}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} mt={6}>
              {loading && <Loader color="primary" />}
              {!loading && (
                <Button
                  onClick={() => logEvent('blogChargerClick', false)}
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<Activity size={20} color="#FFF" />}
                >
                  Calculate
                </Button>
              )}
            </Grid>
            <Grid item xs={12} mt={2}>
              <Typography variant="body1" pl={2} className={styles.disclosure}>
                * Cost of wall connector + installation estimated to be $700
                based on national average with tax credit
                <br />
                <a
                  className={styles.taxLink}
                  href="https://www.irs.gov/pub/irs-pdf/i8911.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  File your Tax Form 8911 here
                </a>
              </Typography>
            </Grid>
          </>
        ) : (
          <Answer
            chargerSavings={chargerSavings}
            resetForm={resetForm}
            copied={copied}
            setCopied={setCopied}
          />
        )}
      </form>
    </Grid>
  )
}

export default NewCharger
