import useViewModel from './useViewModel'

const DownloadAppRedirect = () => {
  useViewModel()

  // Nothing to display
  return null
}

export default DownloadAppRedirect
