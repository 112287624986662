import { Grid as MuiGrid, Box as MuiBox } from '@material-ui/core'

import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { unique } from '../../../utils/arrayUtilities'

const Grid = styled(MuiGrid)(spacing)
const Box = styled(MuiBox)(spacing)

const useStyles = makeStyles((theme) => ({
  rowContainer: {
    lineHeight: '0.75rem',
  },
  inlineItem: {
    display: 'inline-block',
  },
  icon: {
    paddingRight: theme.spacing(1),
  },
  invis: {
    visibility: 'hidden',
  },
}))

/**
 * A capsule that has colored segments with some padding in between.
 * Fits width to container.
 * @param {[{color: string, label: string, value: string, sortKey: number}]} data
 * @param {(color: string) => React.Component} iconRender
 * @param {(label: string) => React.Component} labelRender
 * @param {(value: string) => React.Component} valueRender
 */
const IconLegend = ({
  className = '',
  data,
  itemClassName = ({ color, label, value }) => '',
  iconRender,
  labelRender,
  valueRender,
  spacing,
  ...restProps
}) => {
  const classes = useStyles()
  const uniqueLabeledData = unique(data, (d) => d.label).sort(
    (a, b) => (a.sortKey ?? 0) - (b.sortKey ?? 0),
  )

  return (
    <Grid className={className} container spacing={spacing} {...restProps}>
      {uniqueLabeledData.map(({ color, label, value }) =>
        !(label && value && color) ? null : (
          <Grid
            item
            key={label}
            className={itemClassName({ color, label, value })}
          >
            <Box className={classes.rowContainer}>
              <Box
                className={clsx(classes.inlineItem, classes.icon)}
                component="span"
              >
                {' '}
                {iconRender(color)}{' '}
              </Box>
              <Box className={classes.inlineItem} component="span">
                {' '}
                {labelRender(label)}{' '}
              </Box>
            </Box>
            <Box className={classes.rowContainer}>
              <Box
                className={clsx(
                  classes.inlineItem,
                  classes.icon,
                  classes.invis,
                )}
                component="span"
              >
                {' '}
                {iconRender(color)}{' '}
              </Box>
              <Box className={classes.inlineItem} component="span">
                {' '}
                {valueRender(value)}{' '}
              </Box>
            </Box>
          </Grid>
        ),
      )}
    </Grid>
  )
}

export default IconLegend
