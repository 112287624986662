import React from 'react'
import './styles.scss'
import { Divider, ExpansionPanel } from '@/components'
import { canCharge } from '@/utils/vehicles/permissions'
import { ConfirmationContentProps } from './ConnectVehicleConfirmation.types'
import {
  ConfirmationContentLabel,
  ConfirmationExpansionContentItem,
} from '@/app/components/DeviceConfirmationPanel'
import { getVehicleDisplayModel } from '@/utils/CarUtils'

export const ConfirmationContent: React.FC<ConfirmationContentProps> = ({
  vehicle,
  isTesla,
}) => {
  const canControlCharge = canCharge(vehicle)
  let model: string | undefined = getVehicleDisplayModel(vehicle)

  if (model === '') {
    model =
      vehicle.car?.car_model?.friendly_name !== ''
        ? vehicle.car?.car_model?.friendly_name
        : 'Unknown'
  }

  const friendlyName = vehicle.car?.car_model?.friendly_name
  const make = vehicle.car?.car_model?.make
  const carDescriptor = friendlyName
    ? friendlyName
    : make
    ? `${make} ${model}`
    : 'Unknown'

  const year = vehicle.car.car_model?.year
  const carName =
    vehicle.car.display_name !== ''
      ? vehicle.car.display_name
      : model
      ? model
      : 'Unknown'
  const carFriendlyName = `${year} ${carDescriptor}`

  return (
    <div className="confirmation-content">
      <ExpansionPanel
        initiallyExpanded={!canControlCharge}
        label={
          <ConfirmationContentLabel
            type={canControlCharge ? 'success' : 'error'}
            name={carName}
            description={carFriendlyName}
          />
        }
      >
        {canControlCharge ? (
          <ConfirmationExpansionContentItem title="Charge Control" />
        ) : (
          <ConfirmationExpansionContentItem
            type="error"
            title="Charge Control"
            subtitle="This vehicle does not support:"
          >
            <ul className="unordered-list">
              <li>Save Money or Save The Planet</li>
              <li>Scheduled Departure</li>
              <li>Trips</li>
            </ul>
          </ConfirmationExpansionContentItem>
        )}
        {isTesla && (
          <>
            <Divider orientation="horizontal" />
            <ConfirmationExpansionContentItem title="Account Data" />
          </>
        )}
        <Divider orientation="horizontal" />
        <ConfirmationExpansionContentItem title="Vehicle Data" />
        <Divider orientation="horizontal" />
        <ConfirmationExpansionContentItem title="Charge Data" />
      </ExpansionPanel>
    </div>
  )
}
