import { useState } from 'react'
import { Menu, MenuItem, Box } from '@material-ui/core'

import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  inline: {
    display: 'inline-block',
  },
}))

export default function DayOfWeekSelector({
  dayOfWeek,
  onSelect,
  children,
  ...restProps
}) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const pickedDay = dayOfWeek

  const daysOfWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ].map((day, i) => ({ pretty: day, day: i }))

  const handleClickAnchor = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuItemClick = (event) => {
    onSelect(parseInt(event.target.getAttribute('value')))
    setAnchorEl(null)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box className={classes.inline} {...restProps}>
      <Box className={classes.inline} onClick={handleClickAnchor}>
        {children}
      </Box>
      <Menu
        className={classes.menu}
        id="day-of-week-menu-selector"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {daysOfWeek.map(({ pretty, day }) => (
          <MenuItem
            key={day}
            value={day}
            onClick={handleMenuItemClick}
            selected={day === pickedDay}
          >
            {pretty}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}
