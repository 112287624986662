import { SELECT_UTILITY_FAILURE, selectUtility } from '@/actions/utilities'
import { useAppDispatch } from '@/hooks'
import { Plan } from '@/types'
import { useState } from 'react'
import { RSAAResultAction } from 'redux-api-middleware'
import * as Sentry from '@sentry/browser'
import { SELECT_PLAN_FAILURE, selectPlan } from '@/actions/plans'
import { Button, Icon } from '@/components'
import AlertMessage from '../../AlertMessage'

type Props = {
  plans: Plan[]
  onPlanSubmit: (planId: number | null) => void
  utilityId: number
}

export default function WhichPlanBody(props: Props) {
  const {
    selectedPlan,
    submitEnabled,
    submitError,
    handleSelectChange,
    onSubmit,
  } = useViewModel(props)
  return (
    <div className="flex flex-col justify-between h-full">
      <div>
        <div className=" bg-white border-[#E5E5E5] relative rounded-md border-[1px] border-solid h-14 w-full p-2 [&>*]:transition-all">
          <select
            name="gexa-plan-select"
            className="absolute peer bg-opacity-0 placeholder-shown:!top-0 h-full w-[95%] !border-none !outline-none self-end text-sm bg-transparent placeholder:opacity-0 bg-none appearance-none"
            value={selectedPlan ?? undefined}
            onChange={handleSelectChange}
          >
            {props.plans.map((plan) => (
              <option key={`gexa-plan-select-${plan.id}`} value={plan.id}>
                {plan.name}
              </option>
            ))}
            <option value="__other">I don't see my plan</option>
          </select>
          <Icon name="ChevronDown" className="absolute right-4 top-4" />
          <label
            htmlFor="gexa-plan-select"
            className="absolute text-[#5D5D5D] font-bold left-2 peer-placeholder-shown:select-none peer-placeholder-shown:pointer-events-none peer-placeholder-shown:left-[10px] bg-transparent top-2 text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:!top-[17px]"
          >
            <div className="flex flex-row">Utility plan</div>
          </label>
        </div>
        <AlertMessage
          variant="error"
          show={Boolean(submitError)}
          className="mt-2"
        >
          {submitError ?? 'There was an issue.'}
        </AlertMessage>
      </div>
      <Button
        id="gexa-complete-switch-plan-submit-button"
        variant="primary"
        className="w-full"
        onClick={onSubmit}
        disabled={!submitEnabled}
      >
        Submit
      </Button>
    </div>
  )
}

function useViewModel(props: Props) {
  const dispatch = useAppDispatch()
  const [selectedPlan, setSelectedPlan] = useState<'__other' | string>(
    String(props.plans[0]?.id) ?? null,
  )
  const [submitEnabled, setSubmitEnabled] = useState(true)
  const [submitError, setSubmitError] = useState<null | string>(null)

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedPlan(event.target.value)
  }

  const onSubmit = async () => {
    if (selectedPlan === null) {
      return
    }

    if (selectedPlan === '__other') {
      // todo navigate to manual plan entry
      props.onPlanSubmit(null)
      return
    }

    setSubmitEnabled(false)
    // Try and select the utility first. Changing utility
    // resets the user's plan, so select utility before plan.
    const utilRes: RSAAResultAction = await dispatch(
      selectUtility({ utility_id: props.utilityId }),
    )
    if (utilRes.type === SELECT_UTILITY_FAILURE) {
      const errorMessage =
        'There was an issue updating your utility in Optiwatt. Please try again or contact support if the issue persists'
      Sentry.captureMessage(errorMessage)
      setSubmitError(errorMessage)
      setSubmitEnabled(true)
      return
    }
    const planRes: RSAAResultAction = dispatch(
      selectPlan({
        plan_id: selectedPlan,
      }),
    )
    if (planRes.type === SELECT_PLAN_FAILURE) {
      const errorMessage =
        'There was an issue updating your plan in Optiwatt. Please try again or contact support if the issue persists'
      Sentry.captureMessage(errorMessage)
      setSubmitError(errorMessage)
      setSubmitEnabled(true)
      return
    }
    // If both utility and plan selection are successful, submit the survey
    setSubmitEnabled(true)
    props.onPlanSubmit(Number(selectedPlan))
  }

  return {
    selectedPlan,
    submitEnabled,
    submitError,
    handleSelectChange,
    onSubmit,
  }
}
