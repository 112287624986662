import Flex from '../Flex'
import Grid from '../Grid'
import Text from '../Text'

export type Props = {
  // icon render prop
  icon: () => JSX.Element
  title: string
  description: string
}

function IconDetailListItem(props: Props) {
  return (
    <Grid gap="16px" templateColumns={'auto 1fr'}>
      {props.icon()}
      <Flex container direction="column" gap="4px">
        <Text variant="body1">{props.title}</Text>
        <Text variant="body4">{props.description}</Text>
      </Flex>
    </Grid>
  )
}

export default IconDetailListItem
