import { VehicleInfo } from './ControlledVehiclesList'

const useViewModel = () => {
  const mockVehicles: VehicleInfo[] = [
    {
      name: 'Tez',
      model: '2018 Model 3',
      image:
        'dashboard/img/car_models/tesla/model3/Midnight_Silver_Metallic.webp',
      controllable: true,
    },
    {
      name: 'Chevorlet',
      model: '2020 Bolt',
      image: 'dashboard/img/car_models/chevrolet/boltev/Bright_Blue.webp',
      controllable: true,
    },
    {
      name: 'Chevorlet',
      model: '2017 Bolt',
      image: 'dashboard/img/car_models/chevrolet/boltev/Bright_Blue.webp',
      controllable: false,
    },
    {
      name: 'Other vehicles',
      link: 'Learn more',
      image: 'dashboard/img/car_models/covered-car.webp',
      controllable: true,
    },
  ]

  const handleToggleChange = (checked: boolean, vehicleId: number) => {}

  return {
    vehicles: mockVehicles,
    numVehicles: 13,
    handleToggleChange,
  }
}

export default useViewModel
