import { Button, ButtonProps, Icon } from '../../../components/index'
import { RequestStatus } from '../../../request/types'
import formatClasses from '../../../utils/classes/formatClasses'
import './styles.scss'

export interface Props extends ButtonProps {
  status: RequestStatus
}

export default function StatusButton({
  className,
  children,
  status,
  ...props
}: Props) {
  const content = {
    [RequestStatus.Idle]: children,
    [RequestStatus.Invalidated]: children,
    [RequestStatus.Loading]: 'Loading...',
    [RequestStatus.Succeeded]: (
      <>
        <Icon name="Check" color="white" size={17} /> &nbsp; Success
      </>
    ),
    [RequestStatus.Failed]: 'Error',
  }[status]

  const classes = formatClasses(['status-button', className])

  const disabled =
    status === RequestStatus.Loading || status === RequestStatus.Succeeded

  return (
    <Button
      className={classes}
      disabled={disabled}
      style={{
        height: 58,
      }}
      {...props}
    >
      {content}
    </Button>
  )
}
