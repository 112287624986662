import { ViewModelComponentProps } from '@/utils/typeUtilities'
import AddUtilityPlanOptionFlowStepRoot, {
  Props as ComponentProps,
} from './AddUtilityPlanOptionFlowStep'
import useViewModel from './useViewModel'

export type Props = ViewModelComponentProps<ComponentProps, typeof useViewModel>

export default function AddUtilityPlanOptionFlowStep(props: Props) {
  const viewModel = useViewModel(props)
  return <AddUtilityPlanOptionFlowStepRoot {...viewModel} {...props} />
}
