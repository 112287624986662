import MaintenanceGraphic from '@/app/img/maintenance-graphic.svg'
import { Text } from '@/components'
import useMaintenanceMode from '@/hooks/useMaintenanceMode'

const MaintenancePage = () => {
  const payload = useMaintenanceMode()

  return (
    <div className="flex flex-col items-center justify-center text-center gap-2 h-full px-8">
      <img src={MaintenanceGraphic} className="pb-8" alt="Under Maintenance" />
      <Text variant="h2" className="text-xl">
        {payload?.title ? payload.title : 'Under Maintenance'}
      </Text>
      <Text variant="body4" className="font-semibold max-w-md">
        {payload?.content
          ? payload.content
          : 'Optiwatt is currently down for scheduled maintenance. Please check back later.'}
      </Text>
    </div>
  )
}

export default MaintenancePage
