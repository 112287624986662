import styled from 'styled-components'
import {
  Grid as MuiGrid,
  makeStyles,
  Typography as MuiTypography,
  createStyles,
} from '@material-ui/core'
import { spacing } from '@material-ui/system'
import DashboardCard from '../DashboardCard'
import IconToggleLabel from '../IconToggleLabel'
import ConnectUtilitySection from './ConnectUtilitySection'
import useShowBaselineSettings from '../../hooks/useShowBaselineSettings'
import UtilityProgramEnrollmentTogglePrompt from '../../../app/features/utility-programs/UtilityProgramEnrollmentTogglePrompt'
import FlashingComponent from '../FlashingComponent'
import { ConfigurableFunctionality, ConfigurableText } from '@/app/components'
import { useAppSelector, useUrlSearchParam } from '@/hooks'
import { ChargeSchedule } from '@/types'
import { ID } from '@/types/model'
import { ButtonVariant } from '@/components'
import { useHistory } from 'react-router-dom'
import { localGridDetails } from '@/app/pages/schedule/vehicle/local-grid-support-details/localGridDetails'
import { UtilityProgram } from '@/types/utilityProgram'
import { utilityProgramCollection } from '@/reducers/utilityPrograms'
import { utilityProgramEnrollmentCollection } from '@/reducers/utilityProgramEnrollments'
import { logEvent } from '@/logging'

const Grid = styled(MuiGrid)(spacing)
const Typography = styled(MuiTypography)(spacing)

const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      fontWeight: 'bold',
      textAlign: 'left',
    },
    emoji: {
      marginLeft: theme.spacing(1),
    },
    programEnrollmentToggleSection: {
      paddingLeft: '6px',
    },
  }),
)

const EnvironmentalChargingOptionsCard = (props: {
  children: React.ReactNode
}) => {
  return (
    <DashboardCard pb={6} pt={6} mb={0}>
      <Grid container alignItems="center" justifyContent="center" spacing={6}>
        {props.children}
      </Grid>
    </DashboardCard>
  )
}

type EnrollmentToggleHeaderProps =
  | {
      vehicleId: ID
    }
  | {
      vehicleChargerId: ID
    }

const PlainHeader = () => {
  const classes = useStyles()
  return (
    <div className="flex space-x-2 w-full p-3">
      <img src="/img/zap-icon.svg" />
      <Typography variant="h6" m={1} className={classes.header}>
        Be a Watt Hero
      </Typography>
    </div>
  )
}
PlainHeader.displayName = 'PlainHeader'
EnvironmentalChargingOptionsCard.PlainHeader = PlainHeader

const EnrollmentToggleHeader = (props: EnrollmentToggleHeaderProps) => {
  const classes = useStyles()
  const flashUtilityEnrollment = useUrlSearchParam('show-utility-enrollment')

  const vehicleId = 'vehicleId' in props ? props.vehicleId : undefined
  const vehicleChargerId =
    'vehicleChargerId' in props ? props.vehicleChargerId : undefined

  const deviceType = vehicleChargerId ? 'vehicle_charger' : 'vehicle'

  return (
    <Grid item xs={12}>
      <FlashingComponent
        active={Boolean(flashUtilityEnrollment)}
        scrollToElement
      >
        <UtilityProgramEnrollmentTogglePrompt
          className={classes.programEnrollmentToggleSection}
          vehicleId={vehicleId}
          chargerId={vehicleChargerId}
          deviceType={deviceType}
          includeHeader
        />
      </FlashingComponent>
    </Grid>
  )
}
EnrollmentToggleHeader.displayName = 'EnrollmentToggleHeader'
EnvironmentalChargingOptionsCard.EnrollmentToggleHeader = EnrollmentToggleHeader

type AirQualityToggleProps<Schedule extends ChargeSchedule> = {
  chargeSchedule?: Schedule
  onChange: (schedule: Schedule) => void
}
const AirQualityToggle = <Schedule extends ChargeSchedule>({
  chargeSchedule,
  onChange,
}: AirQualityToggleProps<Schedule>) => {
  const showBaselineSettings = useShowBaselineSettings()
  return (
    <ConfigurableFunctionality feature="airQualityControl">
      <Grid item xs={12}>
        <FlashingComponent
          active={
            showBaselineSettings && chargeSchedule?.environment_support_enabled
          }
          scrollToElement={true}
        >
          <IconToggleLabel
            id="environment-support-toggle"
            title="Improve Your Air Quality"
            subtitle="Help your local environment by charging when electricity is generated by renewable energy methods."
            shouldDebounce={false}
            model={chargeSchedule}
            field="environment_support_enabled"
            onChange={onChange}
          />
        </FlashingComponent>
      </Grid>
    </ConfigurableFunctionality>
  )
}
AirQualityToggle.displayName = 'AirQualityToggle'
EnvironmentalChargingOptionsCard.AirQualityToggle = AirQualityToggle

const ProgramEnrollmentUpsell = ({
  vehicleId,
  chargerId,
  buttonVariant,
  controlMode,
  deviceType,
}: {
  vehicleId?: ID
  chargerId?: ID
  buttonVariant?: ButtonVariant
  controlMode?: 'toggle' | 'button'
  deviceType?: 'vehicle' | 'thermostat' | 'vehicle_charger'
}) => {
  const classes = useStyles()
  const mode = controlMode ?? 'button'
  const type = deviceType ?? 'vehicle'
  return (
    <ConfigurableFunctionality feature="supportLocalGrid">
      <Grid item xs={12}>
        <UtilityProgramEnrollmentTogglePrompt
          buttonVariant={buttonVariant}
          className={classes.programEnrollmentToggleSection}
          vehicleId={vehicleId}
          chargerId={chargerId}
          deviceType={type}
          controlMode={mode}
        />
      </Grid>
    </ConfigurableFunctionality>
  )
}
ProgramEnrollmentUpsell.displayName = 'ProgramEnrollmentUpsell'
EnvironmentalChargingOptionsCard.ProgramEnrollmentUpsell =
  ProgramEnrollmentUpsell

type UtilityIntegrationUpsellProps<Schedule extends ChargeSchedule> = {
  chargeSchedule?: Schedule
  onChange: (schedule: Schedule) => void
}

const UtilityIntegrationUpsell = <Schedule extends ChargeSchedule>({
  chargeSchedule,
  onChange,
}: UtilityIntegrationUpsellProps<Schedule>) => {
  const onChangeAllowUtilityIntegration = (gridSupportEnabled: boolean) => {
    if (!chargeSchedule) {
      return
    }
    onChange({ ...chargeSchedule, grid_support_enabled: gridSupportEnabled })
  }
  return (
    <ConfigurableFunctionality feature="supportLocalGrid">
      <Grid item xs={12}>
        <ConnectUtilitySection
          title="Support Your Local Grid"
          subtitle="Help reduce grid blackouts by delaying charging during short periods of high electricity demand."
          buttonText="Get Started"
          onSubmit={onChangeAllowUtilityIntegration}
        />
      </Grid>
    </ConfigurableFunctionality>
  )
}
UtilityIntegrationUpsell.displayName = 'UtilityIntegrationUpsell'
EnvironmentalChargingOptionsCard.UtilityIntegrationUpsell =
  UtilityIntegrationUpsell

type GridSuportToggleProps<Schedule extends ChargeSchedule> = {
  chargeSchedule?: Schedule
  onChange: (schedule: Schedule) => void
}
const GridSuportToggle = <Schedule extends ChargeSchedule>({
  chargeSchedule,
  onChange,
}: GridSuportToggleProps<Schedule>) => {
  const history = useHistory()
  // TODO: check the profile to see if the user is in mass
  const { localGridDetail, program } = useAppSelector((state) => {
    let program: UtilityProgram | undefined
    const localGridDetail = localGridDetails.find((localGridProgram) => {
      program = utilityProgramCollection.selectors
        .selectAll(state)
        .find?.((program) => program?.name === localGridProgram.name)
      if (!program) {
        program = utilityProgramEnrollmentCollection.selectors
          .selectAll(state)
          .find?.(
            (enrollment) =>
              enrollment.utility_program.name === localGridProgram.name,
          )?.utility_program
      }
      return program
    })
    return { localGridDetail, program }
  })

  const subtitle =
    'In extreme circumstances, help reduce CO₂ and grid blackouts by delaying charging during short periods of high electricity demand.'

  const viewDetails = (localGridID: string) => {
    if (!localGridID) return
    const currentPath = history.location.pathname
    history.push(`${currentPath}/local-grid-details/${localGridID}`)
  }

  const localGridOnChange = (schedule: Schedule) => {
    logEvent('local_grid_program_support_toggle', {
      metadata: {
        grid_support_enabled: schedule.grid_support_enabled,
        utility_program: program?.name || '',
      },
    })
    onChange(schedule)
  }

  return (
    <ConfigurableFunctionality feature="supportLocalGrid">
      <Grid item xs={12}>
        <IconToggleLabel
          id="grid-support-toggle"
          title="Support Your Local Grid"
          subtitle={localGridDetail?.subtitle ?? subtitle}
          shouldDebounce={false}
          model={chargeSchedule}
          field="grid_support_enabled"
          onChange={localGridDetail ? localGridOnChange : onChange}
          newHighlight={!!localGridDetail}
          overrideNewHighlightText={localGridDetail ? 'UPDATED' : ''}
        />
        {localGridDetail && (
          <ConfigurableText
            variant="link"
            id="grid-support-toggle-link"
            className="pl-2"
            onClick={() => viewDetails(String(program?.id))}
          >
            View Details
          </ConfigurableText>
        )}
      </Grid>
    </ConfigurableFunctionality>
  )
}
GridSuportToggle.displayName = 'GridSuportToggle'
EnvironmentalChargingOptionsCard.GridSuportToggle = GridSuportToggle

export default EnvironmentalChargingOptionsCard
