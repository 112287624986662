import { createResourceCollection } from '../request'
import { ResourceModel } from '@/request/types'

export type AvaAddressesResponse = {
  addresses: string[] | string
} & ResourceModel

export type AvaVerificationResponse = {
  is_covered: boolean
  is_customer: boolean
  is_verified: boolean
} & ResourceModel

export const avaAddressesCollection = createResourceCollection<
  AvaAddressesResponse | AvaVerificationResponse
>({
  name: 'avaAddressesCollection',
  apiConfig: {
    path: 'ava_addresses',
  },
  selector: (state) => state.avaAddresses,
})

const avaAddressesReducer = avaAddressesCollection.slice.reducer

export default avaAddressesReducer
