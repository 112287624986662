import { NavigationPage, TeslaFleetPartnerSheet } from '@/app/components'
import TeslaLogo from '@/authenticated/images/tesla.webp'
import LinkDiagram from '@/app/features/logos/LinkDiagram'
import OptiwattIconLogo from '@/app/img/logo-icon-colored-filled.webp'
import { Button } from '@/components'
import { useNavigation } from '@/app/hooks'
import { useAppSelector, useUrlSearchParam } from '@/hooks'
import { selectUrls } from '@/selectors/teslaFleetAuthentication'
import { ReviewingPermissions } from '@/app/components/SurveyObserver/TeslaFleetAuthSurvey'
import { AnimatePresence, easeInOut, motion } from 'framer-motion'
import VehicleConnectionPitch from '@/app/components/VehicleConnectionPitch/VehicleConnectionPitch'
import { useWhiteLabelData } from '@/hooks/whitelabel/useWhiteLabelData'
import { useIsAvaBasePath } from '@/app/hooks/ava/useAvaVerification'

const MockVehicleResource = {
  id: 1,
  car: {
    display_name: 'Your Tesla',
    car_model: {
      make: 'Tesla',
      model: 'Review Permissions',
      friendly_model: null,
    },
  },
}

function useViewModel() {
  // prefer history to navigation because we are just
  // swapping out the content of the page and not navigating
  const navigation = useNavigation()
  const isAva = useIsAvaBasePath()
  const isOnboarding = useUrlSearchParam('onboarding')
  const teslaFleetAuthUrl = useAppSelector(
    (state) => selectUrls(state)?.authorize_url,
  )

  const onContinueClick = () => {
    if (!teslaFleetAuthUrl) {
      return
    }
    if (isAva) {
      const teslaAuthUrl = new URL(teslaFleetAuthUrl ?? '')
      const state = teslaAuthUrl.searchParams.get('state')
      const base64State = btoa(
        JSON.stringify({ utility: 'ava', onboarding: isOnboarding, state }),
      )
      teslaAuthUrl.searchParams.set('state', base64State)
      window.location.href = teslaAuthUrl.toString()
      return
    }

    window.location.href = teslaFleetAuthUrl
  }
  const onReviewPermissionsClick = () => {
    navigation.pushAddParam('review-permissions', 'true')
  }
  return { onContinueClick, onReviewPermissionsClick }
}

export default function ConnectTeslaStartPage() {
  const { onContinueClick, onReviewPermissionsClick } = useViewModel()
  const reviewingPermissions =
    useUrlSearchParam('review-permissions') === 'true'

  const { whiteLabelData } = useWhiteLabelData()

  const logos = [OptiwattIconLogo, TeslaLogo]

  if (whiteLabelData?.largeBrandLogo) {
    logos.unshift(whiteLabelData.largeBrandLogo)
  }

  return (
    <NavigationPage id="connect-tesla-start-page">
      <NavigationPage.SingleCardContent>
        <div>
          <LinkDiagram widthOverride="tall" logos={logos} />
        </div>
        <div className="flex items-center justify-center w-full mt-4 mb-10">
          <TeslaFleetPartnerSheet />
        </div>
        <AnimatePresence mode="wait">
          {reviewingPermissions ? (
            <motion.div
              className="h-full"
              key="review-permissions"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ ease: easeInOut, duration: 0.15 }}
            >
              <ReviewingPermissions
                vehicles={[MockVehicleResource]}
                onContinueClick={onContinueClick}
              />
            </motion.div>
          ) : (
            <motion.div
              className="h-full"
              key="tesla-connect-prompt"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ ease: easeInOut, duration: 0.15 }}
            >
              <TeslaConnectPrompt
                onGetStartedClick={onContinueClick}
                onReviewPermissionsClick={onReviewPermissionsClick}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </NavigationPage.SingleCardContent>
    </NavigationPage>
  )
}

function TeslaConnectPrompt({
  onGetStartedClick,
  onReviewPermissionsClick,
}: {
  onGetStartedClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void
  onReviewPermissionsClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void
}) {
  return (
    <>
      <VehicleConnectionPitch make="Tesla" />
      <div className="flex flex-col gap-4 mt-10">
        <Button
          id="tesla-fleet-auth-survey-review-permissions-button"
          variant="primary-subtle"
          onClick={onReviewPermissionsClick}
        >
          Review Permissions
        </Button>
        <Button
          id="tesla-fleet-auth-survey-get-started-button"
          variant="primary"
          onClick={onGetStartedClick}
        >
          Get Started
        </Button>
      </div>
    </>
  )
}
