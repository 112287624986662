import React from 'react'
import DeviceConnectionRedirect, {
  Props as DeviceConnectionRedirectProps,
} from '../DeviceConnectionRedirect'

export interface Props extends DeviceConnectionRedirectProps {}

export default function ThermostatRedirect(props: Props) {
  return <DeviceConnectionRedirect {...props} />
}
