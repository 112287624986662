import { RefObject, ChangeEvent } from 'react'
import { CardProps, CardNavigation } from '@/components'
import {
  FlowStep,
  Question,
  SelectInput,
  SelectOption,
} from '../../../components'
import { Utility } from '@/types/utility'
import data from './resources/data'

export interface Props extends CardProps {
  utility?: Utility
  fileInputRef: RefObject<HTMLInputElement>
  handleFilesUploaded: (event: ChangeEvent<HTMLInputElement>) => void
  options: SelectOption[]
  handleSelectChange: (value: number | null) => void
  onClose?: () => void
}

export default function AddUtilityPlanOptionFlowStep({
  utility,
  fileInputRef,
  handleFilesUploaded,
  options,
  handleSelectChange,
  onClose,
}: Props) {
  return (
    <FlowStep id="add-utility-plan-flow">
      <FlowStep.Content>
        <CardNavigation
          id="add-utility-plan-card-navigation"
          onCloseClick={onClose}
        />
        <Question
          title={data.question}
          subtitle={
            utility?.login_url ? data.subtitle(utility?.login_url) : undefined
          }
        >
          <SelectInput
            direction="column"
            options={options}
            onChange={handleSelectChange}
            variant="buttons"
          />
          <input
            onChange={handleFilesUploaded}
            style={{ display: 'none' }}
            multiple
            type="file"
            ref={fileInputRef}
          />
        </Question>
      </FlowStep.Content>
    </FlowStep>
  )
}
