export enum ActionType {
  SetDialogOpen = '@@TeslaGiveawayDashboardView/SET_DIALOG_OPEN',
  SetDialogConfirmationPending = '@@TeslaGiveawayDashboardView/SET_DIALOG_CONFIRMATION_PENDING',
}

export const setDialogOpen = (dialogOpen: boolean) => ({
  type: ActionType.SetDialogOpen,
  payload: dialogOpen,
})

export const setDialogConfirmationPending = (
  dialogConfirmationPending: boolean,
) => ({
  type: ActionType.SetDialogConfirmationPending,
  payload: dialogConfirmationPending,
})
