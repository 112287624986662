import { ThermostatBrand } from '@/types/brand'

export enum ThermostatBrandName {
  Ecobee = 'ecobee',
  Nest = 'nest',
  Honeywell = 'honeywell',
}

export const findBrandByPortal = (portal: string) =>
  brands.find((brand) => brand.name === portal.toLowerCase())

export const brands = [
  {
    name: ThermostatBrandName.Ecobee,
    logo: '/dashboard/img/brands/ecobee.webp',
    deviceImage: '/img/ecobee.webp',
  },
  {
    name: ThermostatBrandName.Nest,
    logo: '/dashboard/img/brands/nest.webp',
    deviceImage: '/img/nest.webp',
  },
  {
    name: ThermostatBrandName.Honeywell,
    logo: '/dashboard/img/brands/honeywell.webp',
    deviceImage: '/img/honeywell.webp',
  },
] as ThermostatBrand[]

export default brands
