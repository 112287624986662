import { RootState } from '@/reducers'
import { selectChargeForecast } from './selectChargeForecast'
import { getBatteryTargetETA } from '@/types/chargeForecast/utils'

export function selectBatteryTargetETA(state: RootState) {
  const chargeForecast = selectChargeForecast(state)

  if (!chargeForecast) {
    return null
  }

  return getBatteryTargetETA(chargeForecast)
}
