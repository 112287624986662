export const localGridDetails = [
  {
    id: 5,
    name: 'Massachusetts Leap Payout Test',
    subtitle:
      'Earn $0.01 for every kWh of at-home charging during off-peak hours. As a Massachusetts resident, join our Clean Peak Program to support the local grid and get rewarded.',
    title: 'Massachusetts Clean Peak Program Rebate',
    description:
      'You’re enrolled! Get $0.01 for every kWh when you charge off-peak. Thank you for supporting a greener grid!',
    earnings: 47,
    referralText: `People who join Optiwatt using your referral link will get get
                  up to $5 gift - and you’ll earn a forever +10% bonus. Earn up
                  to $50 if they join an active utility program!`,
  },
]
