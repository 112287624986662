import { ExpansionPanel, Icon } from '@/components'
import { logEvent } from '@/logging'
import { TeslaFleetAuthenticationScopeName } from '@/types/teslaFleetAuthentication'
import { ID } from '@/types/model'
import { useState } from 'react'
import { capitalizeFirstLetter } from '@/utils/stringUtilities'
import { getVehicleDisplayModel } from '@/utils/CarUtils'

export type VehicleResource = {
  id: ID
  car: {
    display_name: string
    car_model: {
      make: string
      model: string
      friendly_model: string | null
    } | null
  }
}

export default function TeslaVehiclePermissions({
  vehicles,
  missingScopes,
}: {
  vehicles: VehicleResource[]
  missingScopes?: TeslaFleetAuthenticationScopeName[]
}) {
  const [expandCount, setExpandCount] = useState(0)
  const onExpand = (expanded: boolean, label: string) => {
    setExpandCount((count) => (expanded ? count + 1 : count - 1))
    logEvent('ExpandScopeToggle', { metadata: { scope: label } })
  }
  const iconType = () => {
    if (missingScopes) {
      return missingScopes.length > 0 ? 'missingPermissions' : 'connected'
    }
    return 'notConnected'
  }
  const missingScopeNames = [...Object.values(missingScopes ?? [])] as string[]

  return (
    <>
      {vehicles?.map((vehicle) => (
        <ExpansionPanel
          label={
            <VehicleLabel
              name={
                vehicle.car.display_name !== ''
                  ? vehicle.car.display_name
                  : capitalizeFirstLetter(vehicle.car.car_model?.make ?? '')
              }
              model={getVehicleDisplayModel(vehicle)}
              iconType={iconType()}
            />
          }
          initiallyExpanded={iconType() === 'connected'}
          expandedCount={expandCount}
          key={vehicle.id}
        >
          <div className="my-2">
            {permissions.map((permission, idx) => (
              <ExpansionPanel
                label={
                  missingScopes ? (
                    <ExpansionPanelTitle
                      title={permission.title}
                      isSelected={!missingScopeNames.includes(permission.title)}
                    />
                  ) : (
                    permission.title
                  )
                }
                onExpand={(e) => onExpand(e, permission.title)}
                nested={true}
                last={idx === permissions.length - 1}
                key={permission.title}
              >
                <div className="flex flex-col gap-4 p-4 text-sm">
                  <div>{permission.description}</div>
                  <ul className="!gap-0 pl-5">
                    {permission.features.map((feature, i) => (
                      <li className="list-disc" key={i}>
                        {feature}
                      </li>
                    ))}
                  </ul>
                  {permission.comingSoon && <ComingSoonLabel />}
                </div>
              </ExpansionPanel>
            ))}
          </div>
        </ExpansionPanel>
      ))}
    </>
  )
}

function ComingSoonLabel() {
  return (
    <div>
      <span className="p-1 text-xs font-bold uppercase rounded-lg text-themed-accent bg-themed-blue-100">
        Coming Soon
      </span>
    </div>
  )
}
type VehicleLabelIcon = 'notConnected' | 'missingPermissions' | 'connected'
enum VehicleLabelIconColor {
  notConnected = 'bg-themed-base-400',
  missingPermissions = 'bg-themed-warning',
  connected = 'bg-themed-success',
}
export function VehicleLabel({
  name,
  model,
  iconType,
}: {
  name: string
  model: string
  iconType: VehicleLabelIcon
}) {
  const iconBgClass = VehicleLabelIconColor[iconType]
  const icon = (iconType: VehicleLabelIcon) => {
    switch (iconType) {
      case 'connected':
        return <Icon name="Check" size={16} color="white" />
      case 'missingPermissions':
        return <Icon name="AlertCircle" size={22} color="white" />
      case 'notConnected':
        return (
          <>
            {[...Array(3)].map((_, i) => (
              <Icon
                name="Circle"
                color="white"
                key={i}
                className="bg-white rounded-full"
                size={4}
              />
            ))}
          </>
        )
    }
  }
  return (
    <div className="flex flex-row items-center gap-4">
      <div
        className={`flex justify-center items-center w-8 h-8 rounded-lg gap-1 ${iconBgClass}`}
      >
        {icon(iconType)}
      </div>
      <div className="flex flex-col">
        <div className="text-lg font-bold text-themed-neutral">{name}</div>
        <div className="font-base">{model}</div>
      </div>
    </div>
  )
}

function ExpansionPanelTitle({
  title,
  isSelected,
}: {
  title: string
  isSelected: boolean
}) {
  const bgClass = () => {
    return isSelected ? 'bg-themed-success' : 'bg-themed-error'
  }

  return (
    <div className="flex flex-row items-center gap-2">
      <div
        className={`${bgClass()} rounded-full h-4 w-4 flex items-center justify-center`}
      >
        <Icon name={isSelected ? 'Check' : 'X'} size={12} color="white" />
      </div>
      <div className="text-sm font-bold text-gray-900">{title}</div>
    </div>
  )
}

const permissions = [
  {
    title: 'Profile Information',
    description: 'Share it to access features including:',
    features: ['Personalized communication', 'Offers'],
    comingSoon: false,
  },
  {
    title: 'Vehicle Information',
    description: 'Required to access features including:',
    features: ['Save Money or Save The Planet', 'Scheduled Departure', 'Trips'],
    comingSoon: false,
  },
  {
    title: 'Vehicle Location',
    description: 'Required to access features including:',
    features: [
      'Manage Charging',
      'Save Money or Save The Planet',
      'Scheduled Departure',
      'Trips',
    ],
    comingSoon: false,
  },
  {
    title: 'Vehicle Charging Management',
    description: 'Required to access features including:',
    features: [
      'Manage Charging',
      'Save Money or Save The Planet',
      'Scheduled Departure',
      'Trips',
    ],
    comingSoon: false,
  },
  {
    title: 'Vehicle Commands',
    description: 'Required to access features including:',
    features: ['Climate Preconditioning'],
    comingSoon: false,
  },
  {
    title: 'Energy Product Information',
    description: 'Required to support future features including:',
    features: [
      'Home Energy Insights',
      'Home Energy Management',
      'Import Costs or Export Revenue',
      'Toggle Operation Mode',
    ],
    comingSoon: true,
  },
  {
    title: 'Energy Product Settings',
    description: 'Required to support future features including:',
    features: [
      'Home Energy Insights',
      'Home Energy Management',
      'Import Costs or Export Revenue',
      'Toggle Operation Mode',
    ],
    comingSoon: true,
  },
]
