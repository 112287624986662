import {
  Card,
  CardProps,
  CardActions,
  CardAction,
  CardActionButton,
  CardNavigation,
  Icon,
  LoadingAnimation,
} from '@/components'
import { ConfigurableButton } from '@/app/components'
import UtilityProgramEnrollmentPrompt from '../UtilityProgramEnrollmentPrompt'
import { UtilityProgram, ViewConfigButton } from '@/types/utilityProgram'

export interface Props extends Omit<CardProps, 'children'> {
  program?: UtilityProgram
  logoUrl: string | null
  onBackClick?: () => void
  onCloseClick?: () => void
  onSkipClick?: () => void
  onPrimaryClick: () => void
  buttonConfig?: ViewConfigButton
}

export default function UtilityProgramEnrollmentPromptCard({
  program,
  logoUrl,
  onBackClick,
  onSkipClick,
  onCloseClick,
  onPrimaryClick,
  className,
  buttonConfig,
  ...props
}: Props) {
  const includeNavigation = onBackClick || onCloseClick

  return (
    <Card
      className={className}
      style={!includeNavigation ? { paddingTop: 32 } : {}}
      {...props}
    >
      {includeNavigation && (
        <CardNavigation
          onCloseClick={onCloseClick}
          id="utility-program-enrollment-prompt-navigation"
        />
      )}
      {!program ? (
        <div className={'h-[50vh] flex justify-center'}>
          <LoadingAnimation type="falling" centered />
        </div>
      ) : (
        <UtilityProgramEnrollmentPrompt
          utilityProgram={program}
          logoUrl={logoUrl}
          excludeHeaderAnimation={true}
        />
      )}
      <CardActions>
        {onBackClick && (
          <CardActionButton
            type="navigation"
            onClick={onBackClick}
            id="utility-program-enrollment-prompt-back-button"
          >
            <Icon name="ChevronLeft" color="grey-500" size={20} />
          </CardActionButton>
        )}
        {onSkipClick && (
          <CardActionButton
            type="secondary"
            onClick={onSkipClick}
            id="utility-program-enrollment-prompt-skip-button"
          >
            Skip
          </CardActionButton>
        )}
        <CardAction type="primary">
          {program && (
            <ConfigurableButton
              id="utility-program-enrollment-prompt-primary-button"
              config={buttonConfig}
              onClick={onPrimaryClick}
            >
              Next
            </ConfigurableButton>
          )}
        </CardAction>
      </CardActions>
    </Card>
  )
}
