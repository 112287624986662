export default function toCommaSeparatedString(
  value: string | number,
  locales?: Intl.LocalesArgument,
) {
  let internalValue = value

  if (typeof value === 'string') {
    internalValue = Number(value)

    if (Number.isNaN(internalValue)) {
      console.error(
        `Could not convert string with value "${value}" to a number, received ${internalValue}. "${value}" will be returned instead.`,
      )
      return value ?? 0
    }
  }

  return internalValue.toLocaleString(locales, { useGrouping: true })
}
