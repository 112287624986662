import {
  Button,
  Card,
  CardProps,
  ContainerAnimation,
  Flex,
  Grid,
  Icon,
  Text,
} from '@/components'
import { BreakpointContext } from '@/context'
import formatClasses from '@/utils/classes/formatClasses'
import { useContext } from 'react'
import frown from '@/app/img/frown.svg'
import './styles.scss'
import { ConfigurableText } from '@/app/components'

export interface Props extends CardProps {
  variant: 'success' | 'error'
  title: string
  description: string
  faqUrl?: string
  tosUrl?: string | null
}

function StatusIcon(props: { variant: 'success' | 'error' }) {
  return (
    {
      success: <Icon name="Gift" color="purple-500" size={64} />,
      error: <img src={frown} alt="Frowny face" />,
    }[props.variant] ?? null
  )
}

export default function UtilityProgramConnectStatusCard(passedProps: Props) {
  const { className, description, variant, title, ...props } = passedProps
  const breakpoint = useContext(BreakpointContext)

  const classes = {
    card: formatClasses(['utility-program-connect-status-card', className]),
    backgroundAnimation: formatClasses(['background-animation']),
    button: formatClasses(['dashboard-button']),
  }

  return (
    <Card className={classes.card} {...props}>
      {variant === 'success' && (
        <ContainerAnimation
          className={classes.backgroundAnimation}
          variant="snow"
          width="100%"
          height="300px"
        />
      )}
      <Grid
        flow="row"
        gap="32px"
        placeItems="center"
        placeContent="center"
        templateColumns="minmax(0, 400px)"
      >
        <ConfigurableText
          config={title}
          align="center"
          variant="h1"
          className="text-themed-purple-500"
        />
        <StatusIcon variant={variant} />
        <ConfigurableText config={description} variant="body2" align="center" />
        {variant === 'success' && (
          <Flex
            container
            direction={breakpoint.xsOnly ? 'column' : 'row'}
            columnSpacing="48px"
            rowSpacing="24px"
            justifyContent="center"
            alignItems="center"
          >
            {props.faqUrl && (
              <Flex item>
                <a href={props.faqUrl}>
                  <Text variant="body2" className="text-themed-blue-500">
                    Program FAQ
                  </Text>
                </a>
              </Flex>
            )}
            {props.tosUrl && (
              <Flex item>
                <a href={props.tosUrl}>
                  <Text variant="body2" className="text-themed-blue-500">
                    Terms of Service
                  </Text>
                </a>
              </Flex>
            )}
          </Flex>
        )}

        <Button
          className={classes.button}
          variant="primary-subtle"
          id="utility-program-connect-dashboard-button"
          href="/app"
        >
          Go to Home
        </Button>
      </Grid>
    </Card>
  )
}
