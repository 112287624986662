import React from 'react'
import { useSelector } from 'react-redux'
import { Button, Grid, IconButton, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { RefreshCw } from 'react-feather'
import config from '../../../config'

function CommandButton(props) {
  const [loading, setLoading] = React.useState(false)

  return (
    <Button onClick={props.sendCommand} variant="outlined">
      {props.command}
    </Button>
  )
}

export default function VehicleCommands() {
  const [vehicle, setVehicle] = React.useState('')
  const [commandResponse, setCommandResponse] = React.useState('')
  const [commandResponseOpen, setCommandResponseOpen] = React.useState(false)
  const [dataLoading, setDataLoading] = React.useState(false)
  const [vehicleData, setVehicleData] = React.useState({})
  const accessToken = useSelector((state) => state.auth.access)

  if (!accessToken) {
    return null
  }

  function getVehicleData() {
    setDataLoading(true)
    fetch(`${config.apiGateway.URL}/api/tesla-data/${vehicle}/`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken.token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => setVehicleData(res))
      .catch((err) => alert(err))
      .finally(() => setDataLoading(false))
  }

  function sendCommand(commandData) {
    fetch(`${config.apiGateway.URL}/api/tesla-commands/${vehicle}/`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(commandData),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken.token}`,
      },
    })
      .then((res) => res.json())
      .finally((res) => {
        setCommandResponse(JSON.stringify(res))
        setCommandResponseOpen(true)
      })
      .catch((err) => alert(err))
  }

  return (
    <>
      <Grid container spacing={0} justifyContent="center">
        <Grid item xs={12}>
          <label>Optiwatt Vehicle ID:</label>
          <input
            type="number"
            value={vehicle}
            onChange={(e) => setVehicle(e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        justifyContent="space-evenly"
        alignItems="stretch"
      >
        <Grid item xl={4} lg={4}>
          <h1>Commands</h1>
          <Grid container>
            <Grid item xs={12}>
              <h2>Charging Controls</h2>
            </Grid>
            <Grid item xs={6}>
              <CommandButton
                command={'start'}
                sendCommand={() => sendCommand({ command: 'charge_start' })}
              />
            </Grid>
            <Grid item xs={6}>
              <CommandButton
                command={'stop'}
                sendCommand={() => sendCommand({ command: 'charge_stop' })}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <h2>Climate Controls</h2>
            </Grid>
            <Grid item xs={6}>
              <CommandButton
                command={'start'}
                sendCommand={() =>
                  sendCommand({ command: 'auto_conditioning_start' })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <CommandButton
                command={'stop'}
                sendCommand={() =>
                  sendCommand({ command: 'auto_conditioning_stop' })
                }
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <h2>Climate Preconditioning</h2>
            </Grid>
            <Grid item xs={6}>
              <CommandButton
                command={'on'}
                sendCommand={() =>
                  sendCommand({ command: 'set_preconditioning_max', on: true })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <CommandButton
                command={'off'}
                sendCommand={() =>
                  sendCommand({ command: 'set_preconditioning_max', on: false })
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={6} lg={6}>
          <h1>
            Vehicle Data
            <IconButton onClick={getVehicleData}>
              <RefreshCw />
            </IconButton>
          </h1>
          {dataLoading ? (
            'Loading...'
          ) : (
            <div>
              <pre>{JSON.stringify(vehicleData, null, 2)}</pre>
            </div>
          )}
        </Grid>
      </Grid>
      <Snackbar
        open={commandResponseOpen}
        autoHideDuration={6000}
        onClose={() => setCommandResponseOpen(false)}
      >
        <Alert onClose={() => setCommandResponseOpen(false)} severity="success">
          {commandResponse}
        </Alert>
      </Snackbar>
    </>
  )
}
