import storage from 'redux-persist/es/storage'
import { applyMiddleware, createStore } from 'redux'
import { createFilter } from 'redux-persist-transform-filter'
import { persistReducer } from 'redux-persist'
import thunk from 'redux-thunk'
import createRootReducer from '../reducers'
import paramsMiddleware from '@tshio/redux-api-params-middleware'
import { createBrowserHistory } from 'history'
import { composeWithDevTools } from 'redux-devtools-extension'
import { routerMiddleware } from 'connected-react-router'
import {
  apiMiddleware,
  loggingMiddleware,
  requestLoggingMiddleware,
  tokenLoggingMiddleware,
} from './middleware'
import * as devtoolActions from './syntheticDevtoolActions'

import * as Sentry from '@sentry/react'
import { browserTracingIntegration } from '@sentry/browser'

export const history = createBrowserHistory()
const NOT_DEV = process.env.NODE_ENV !== 'development'

if (NOT_DEV) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    environment: process.env.NODE_ENV,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      browserTracingIntegration({
        // Can also use reactRouterV4Instrumentation
        routingInstrumentation:
          Sentry.reactRouterV5BrowserTracingIntegration(history),
      }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 0.01,
    normalizeDepth: 5,
    // beforeBreadcrumb: (breadcrumb) => (breadcrumb.category !== "console" ? breadcrumb : null),
  })
}

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // @todo now make sure we dont send vehicle auth
  actionTransformer: (action) => {
    if (typeof action?.type !== 'string') {
      return action
    }

    const blacklistedActionSubstrings = [
      'thermostatAuth/POST_SUCCESS',
      'CONNECT_VEHICLE_SUCCESS',
      '@@teslaFleetAuthentication/CREATE_SUCCESS_MIDDLEWARE_RESOLVED',
      '@@jwt/TOKEN_SUCCESS',
      '@@jwt/SIGNUP_SUCCESS',
      '@@jwt/LOGIN_SUCCESS',
      '@@utilities/ALL_LIST_SUCCESS',
    ]

    if (blacklistedActionSubstrings.some((s) => action.type.includes(s))) {
      // just send the action type; no payload
      return { action: action?.type ?? '[unknown redacted action type]' }
    }
    return action
  },
  stateTransformer: (state) => {
    const transformedState = {
      ...state,
      auth: {
        access: '[redacted]',
        refresh: '[redacted]',
      },
      utilities: {
        ...state.utilities,
        allUtilities: '[removed for size]',
      },
    }
    return transformedState
  },
})

export default function configureStore(preloadedState) {
  const authFilter = createFilter('auth', [
    'access',
    'refresh',
    'username',
    'fromToken',
  ])

  const reducer = persistReducer(
    {
      key: 'polls',
      storage: storage,
      whitelist: ['auth', 'logging', 'inProgressUtilityProgramEnrollments'],
      transforms: [authFilter],
    },
    createRootReducer(history),
  )

  // This is used for setup here: https://github.com/zalmoxisus/redux-devtools-extension#usage
  // This was copied from ds-rebuild folder.
  const composeEnhancers = composeWithDevTools({
    trace: true,
    traceLimit: 25,
    actionCreators: devtoolActions,
  })

  return createStore(
    reducer, // root reducer with router state
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        thunk,
        routerMiddleware(history), // for dispatching history actions
        paramsMiddleware,
        apiMiddleware,
        tokenLoggingMiddleware,
        loggingMiddleware,
        requestLoggingMiddleware,
      ),
      sentryReduxEnhancer,
    ),
  )
}
