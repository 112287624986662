import React from 'react'
import {
  CardActionButton,
  CardActions,
  Divider,
  Flex,
  Grid,
  Modal,
  Sheet,
  Text,
} from '@/components'
import {
  useEventExplanation,
  mockEventExplanation,
  useEnrolledEventDetails,
} from './useEventExplanation'
import { ID } from '@/types/model'
import { useAppDispatch, useAppSelector, useMockableViewModel } from '@/hooks'
import { selectNextWithThermostatId } from '@/selectors/thermostatDemandResponseEvents'
import {
  takeActionOnThermostatDemandResponseEvent,
  thermostatDemandResponseEventsCollection,
} from '@/reducers/thermostatDemandResponseEvents'
import { centsToDollars } from '@/utils/currency'
import { motion } from 'framer-motion'
import { StatusCircle } from '@/app/components'
import { QueryState } from '@/request/types'

type Props = {
  thermostatId: ID
  open: React.ComponentProps<typeof Modal>['open']
  close: () => void
  variant: 'opt-in-status' | 'opt-out'
}

function useLiveViewModel(props: Props) {
  const dispatch = useAppDispatch()
  const message = useEventExplanation(props.thermostatId)
  const savingsDollars = useAppSelector((state) => {
    const cents =
      selectNextWithThermostatId(state, props.thermostatId)
        ?.estimated_savings_cents ?? 0
    return centsToDollars(cents)
  })
  const nextEventId = useAppSelector(
    (state) => selectNextWithThermostatId(state, props.thermostatId)?.id,
  )
  const optOutAndClose = () => {
    if (!nextEventId) {
      return
    }
    dispatch(
      takeActionOnThermostatDemandResponseEvent(nextEventId, 'opt-out', {
        optimistic: true,
      }),
    )
    props.close()
  }

  const enrolledEventDetails = useEnrolledEventDetails(props.thermostatId)

  const optInStatus = useAppSelector((state) => {
    const optInQueryState =
      thermostatDemandResponseEventsCollection.selectors.queryState.select(
        state,
        {
          method: 'PATCH',
          body: (body) => {
            try {
              return JSON.parse(body ?? '')?.enrolled === true
            } catch (e) {
              return false
            }
          },
        },
      )[0] as QueryState | undefined
    return optInQueryState?.status
  })

  return {
    open: props.open,
    close: props.close,
    message,
    savingsDollars,
    optOutAndClose,
    enrolledEventDetails,
    modalVariant: props.variant,
    enrolledEventStatus:
      optInStatus === 'idle'
        ? 'loading'
        : ((optInStatus ?? 'loading') as 'loading' | 'failed' | 'succeeded'),
  }
}

function ThermostatDREnrollmentStatusView(props: {
  status: 'loading' | 'failed' | 'succeeded'
  enrolledEventDetails: ReturnType<typeof useEnrolledEventDetails>
}) {
  const content = {
    loading: {
      title: 'Loading...',
      body: '',
    },
    succeeded: {
      title: props.enrolledEventDetails.title,
      body: props.enrolledEventDetails.details,
    },
    failed: {
      title: 'There was an issue fulfilling your request.',
      body: 'Try again later or contact support if the issue persists. We apologize for the inconvenience.',
    },
  }

  return (
    <motion.div layout>
      <Grid flow="row" gap="16px" placeItems="center">
        <StatusCircle status={props.status} />
        <Text variant="subheader" align="center" style={{ padding: '0 20%' }}>
          {content[props.status].title}
        </Text>
        <Text variant="body2" align="center" style={{ padding: '0 5%' }}>
          {content[props.status].body}
        </Text>
      </Grid>
    </motion.div>
  )
}

function ThermostatDREventDetailsModalView({
  open,
  close,
  message,
  savingsDollars,
  optOutAndClose,
  modalVariant,
  enrolledEventStatus,
  enrolledEventDetails,
}: ReturnType<typeof useViewModel>) {
  return (
    <Modal
      id="thermostat-dr-event-details-modal"
      open={open}
      onClose={close}
      title={modalVariant === 'opt-out' ? 'Manage Event' : undefined}
    >
      {modalVariant === 'opt-in-status' ? (
        <ThermostatDREnrollmentStatusView
          status={enrolledEventStatus}
          enrolledEventDetails={enrolledEventDetails}
        />
      ) : (
        <Text variant="body3" style={{ marginBottom: '12px' }}>
          {message}
        </Text>
      )}
      <Grid flow="row" gap="16px">
        <Divider />
        <Flex container justifyContent="space-between" alignItems="center">
          <Flex item>
            <Text variant="body1">Estimated Event Savings</Text>
          </Flex>
          <Flex item>
            <Sheet color="green-100">
              <Text className="text-themed-green-900">
                +${savingsDollars.toFixed(2)}
              </Text>
            </Sheet>
          </Flex>
        </Flex>
        <Divider />
        <CardActions>
          {modalVariant === 'opt-out' ? (
            <CardActionButton
              id="thermostat-dr-event-details-opt-out-button"
              type="secondary"
              onClick={optOutAndClose}
            >
              Opt Out
            </CardActionButton>
          ) : (
            <CardActionButton
              id="thermostat-dr-event-details-opt-in-button"
              type="primary"
              onClick={close}
            >
              Done
            </CardActionButton>
          )}
        </CardActions>
      </Grid>
    </Modal>
  )
}

function useMockViewModel(props: Props) {
  const message = mockEventExplanation
  const savingsDollars = 0.45785
  const optOutAndClose = close
  const enrolledEventDetails = {
    title: 'Great! Thanks for participating in this event.',
    details:
      'This event lasts until 9:30 PM tonight. Your temperature may shift up to 3° outside your normal settings.',
  }
  const modalVariant: 'opt-in' | 'opt-out' = 'opt-out'
  return {
    open: props.open,
    close: props.close,
    message,
    savingsDollars,
    optOutAndClose,
    enrolledEventDetails,
    modalVariant,
    enrolledEventStatus: 'loading' as 'loading' | 'failed' | 'succeeded',
  }
}

const useViewModel = useMockableViewModel({
  useViewModel: useLiveViewModel,
  useMockViewModel,
})

export default function ThermostatDREventDetailsModal(props: Props) {
  const viewModel = useViewModel(props)
  return <ThermostatDREventDetailsModalView {...viewModel} />
}
