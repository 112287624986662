import { RSAA, createAction } from 'redux-api-middleware'
import { withAuth } from '../reducers'
import config from '../config'
import * as Sentry from '@sentry/react'
import { setLoggingUserId } from '@/reducers/logging'
import { identify as identifyWithMixpanel } from '@/logging/mixpanel'

export var USER_REQUEST = '@@user/USER_REQUEST'
export var USER_SUCCESS = '@@user/USER_SUCCESS'
export var USER_FAILURE = '@@user/USER_FAILURE'

export var SET_USER = '@@user/SET_USER'

export var SET_USER_METADATA = '@@user/SET_USER_METADATA'

export var USER_PATCH_BILLING_START_REQUEST =
  '@@user/USER_PATCH_BILLING_START_REQUEST'
export var USER_PATCH_BILLING_START_SUCCESS =
  '@@user/USER_PATCH_BILLING_START_SUCCESS'
export var USER_PATCH_BILLING_START_FAILURE =
  '@@user/USER_PATCH_BILLING_START_FAILURE'

export const getCurrentUser = () => {
  return async (dispatch, getState) => {
    try {
      const response = await dispatch(
        createAction({
          endpoint: config.apiGateway.URL + '/api/current_user/',
          method: 'GET',
          headers: withAuth({ 'Content-Type': 'application/json' }),
          types: [USER_REQUEST, USER_SUCCESS, USER_FAILURE],
        }),
      )
      if (response?.type === USER_SUCCESS) {
        Sentry.setTag('profile', response.payload.profile.id)
        if (response.payload.id) {
          identifyWithMixpanel(
            response.payload.id,
            response.payload.username,
            response.payload.full_name,
          )
        }
        const auth = getState().auth
        dispatch(setLoggingUserId(auth.access?.user_id))
      }
      return response
    } catch (e) {
      Sentry.captureException(e)
    }

    return { type: USER_FAILURE }
  }
}

const PatchUser = {
  Request: '@@user/USER_PATCH_REQUEST',
  Success: '@@user/USER_PATCH_SUCCESS',
  Failure: '@@user/USER_PATCH_FAILURE',
}

export const patchCurrentUser = (data, RequestTypeEnum = PatchUser) => ({
  [RSAA]: {
    endpoint: config.apiGateway.URL + '/api/current_user/',
    method: 'PATCH',
    body: JSON.stringify(data),
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      RequestTypeEnum.Request,
      RequestTypeEnum.Success,
      RequestTypeEnum.Failure,
    ],
  },
})

export const patchCurrentUserBillingCycleStart = (data) => ({
  [RSAA]: {
    endpoint: config.apiGateway.URL + '/api/current_user/',
    method: 'PATCH',
    body: JSON.stringify(data),
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      USER_PATCH_BILLING_START_REQUEST,
      USER_PATCH_BILLING_START_SUCCESS,
      USER_PATCH_BILLING_START_FAILURE,
    ],
  },
})

export const PatchUserGridSupport = {
  Request: '@@user/USER_PATCH_GRID_SUPPORT_REQUEST',
  Success: '@@user/USER_PATCH_GRID_SUPPORT_SUCCESS',
  Failure: '@@user/USER_PATCH_GRID_SUPPORT_FAILURE',
}

export const markStartedGridSupportAuthorize = (utilityId) => {
  return patchCurrentUser(
    { has_started_grid_support_authorize: true, utility_id: utilityId },
    PatchUserGridSupport,
  )
}

export const setCurrentUserBillingCycleStart = (billingCycleStart) => {
  return patchCurrentUserBillingCycleStart({
    billing_cycle_start_day: billingCycleStart,
  })
}

export const setUserMetadata = (metadata) => ({
  type: SET_USER_METADATA,
  payload: metadata,
})
