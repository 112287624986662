import React, { useCallback } from 'react'
import { FlowStep } from 'app-components'
import { Button, CardAction, CardActions, Flex, TextField } from '@/components'
import { useMockableViewModel } from '@/hooks'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { InputQuestion, WizardPage } from '@/components/Wizard/Wizard'

const useViewModel = useMockableViewModel({
  useViewModel(props: WizardPage) {
    const onSubmit = (val: { input: string }) => {
      props.callback(val.input)
      props.onNext?.()
    }

    const inputValidator =
      (props.question as InputQuestion)?.type === 'number'
        ? yup
            .number()
            .min((props.question as InputQuestion).minInput ?? Number.MIN_VALUE)
            .max((props.question as InputQuestion).maxInput ?? Number.MAX_VALUE)
            .required()
            .label('This')
        : yup
            .string()
            .min((props.question as InputQuestion).minInput ?? 0)
            .max((props.question as InputQuestion).maxInput ?? Number.MAX_VALUE)
            .required()
            .label('This')

    const formik = useFormik({
      initialValues: {
        input: '',
      },
      validationSchema: yup.object({
        input: inputValidator,
      }),
      onSubmit,
    })

    const onUnknown = useCallback(() => {
      props.callback(
        props.question.skipOverride ? props.question.skipOverride : null,
      )

      props.onNext?.()
    }, [props.callback, props.onNext, props.question])

    return {
      id: props.id,
      onNext: props.onNext,
      callback: props.callback,
      handleSubmit: formik.handleSubmit,
      isValid: formik.isValid && formik.dirty,
      formValues: formik.values,
      onValueChange: formik.handleChange,
      question: props.question as InputQuestion,
      inputErrors: {
        input: formik.dirty ? formik.errors.input : undefined,
      },
      onUnknown,
    }
  },
  useMockViewModel() {
    return {
      id: 'id',
      onNext: () => alert('On next'),
      callback: () => alert('Callback!'),
      question: {
        prompt: 'prompt',
        label: 'something',
        type: 'text' as const,
      },
      handleSubmit: () => alert('Form submitted!'),
      isValid: false,
      formValues: { input: '' },
      onValueChange: () => alert('Form field changed'),
      inputErrors: { input: undefined },
      onUnknown: () => alert('Unknown clicked!'),
    }
  },
})

export default function WizardInput(props: WizardPage) {
  const viewModel = useViewModel(props)

  return (
    <form className="h-full" onSubmit={viewModel.handleSubmit}>
      <FlowStep id={`${viewModel.id}-input-flow`}>
        <FlowStep.Content>
          <TextField
            name="input"
            label={viewModel.question.label}
            id={`${viewModel.id}-input-field`}
            type={viewModel.question.type}
            value={viewModel.formValues.input}
            onChange={viewModel.onValueChange}
            error={Boolean(viewModel.inputErrors.input)}
            helperText={viewModel.inputErrors.input}
          ></TextField>
          {viewModel.question.skipEnabled && (
            <Flex container>
              <Flex item>
                <Button
                  className="mt-4"
                  id="hvac-model-num-idk-btn"
                  variant="chip"
                  onClick={viewModel.onUnknown}
                >
                  I don't know
                </Button>
              </Flex>
            </Flex>
          )}
        </FlowStep.Content>
        <FlowStep.Actions>
          <CardActions>
            <CardAction type="primary">
              <Button
                type="submit"
                id="hvac-success-finish"
                disabled={!viewModel.isValid}
              >
                Submit
              </Button>
            </CardAction>
          </CardActions>
        </FlowStep.Actions>
      </FlowStep>
    </form>
  )
}
