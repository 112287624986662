import React, { Component } from 'react'
import './styles.scss'

class TermsOfService extends Component {
  constructor(props) {
    super(props)
    this.state = {
      testimonial: {},
    }
  }

  render() {
    return (
      <React.Fragment>
        <section
          className={
            'testimonial-section ptb-100 ' +
            (this.props.bgColor && this.props.bgColor === 'gray'
              ? 'gray-light-bg'
              : '')
          }
        >
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-12">
                <div className="section-heading mb-5">
                  <h2>Terms of Service</h2>
                  <p className="paragraph-lead">
                    Please review these Terms of Service ("Terms") carefully,{' '}
                    <b>
                      including the assignment of your Environmental Attributes
                      (as defined below) in Section 2.3 below, and a binding
                      arbitration clause and class action waiver in Section 9.6
                      below
                    </b>
                    . Your acceptance of these Terms is required in order for
                    you to access and use the Company Services (as defined
                    below). By registering with us, purchasing any goods or
                    services from us, or by using the Company Services in whole
                    or in part (whether as a shopper, browser/viewer, vendor,
                    user, customer, merchant, and/ or contributor of content),
                    you are entering into a legally binding agreement with us.
                    If you are agreeing to these Terms on behalf of or for the
                    benefit of an entity or a third party, then you represent
                    and warrant that you have the necessary authority to agree
                    to these Terms on such entity or third party’s behalf. If
                    you do not agree to these Terms, you cannot use the Company
                    Services. We reserve the right at our discretion to make
                    modifications to these Terms periodically. We will notify
                    you of material changes via an email sent to the email
                    address we have on file for you or via other notification
                    mechanisms. Your continued use of and access to the Company
                    Services, or your purchase of any goods or services from us,
                    after such modifications, indicates your acceptance of and
                    agreement to the modified Terms.
                  </p>
                  <p className="underline">Contents</p>
                  <ol className="list-decimal pl-8 space-y-1 mb-4">
                    <li>Defined Terms</li>
                    <li>Access to Company Services</li>
                    <li>Use of the Company Services</li>
                    <li>Access Information & Content</li>
                    <li>Third Party Applications & Co-Branded Services</li>
                    <li>Restrictions</li>
                    <li>
                      Disclaimer of Warranties, Limitation of Liability &
                      Indemnity
                    </li>
                    <li>Copyright Policy</li>
                    <li>General Provisions</li>
                  </ol>
                  <h3>1. DEFINED TERMS</h3>
                  <p className="paragraph-lead">
                    <u>"Access Information"</u>: your user name, password, and
                    other log-in information used by you for access to the
                    Company Services or to conduct transactions.
                  </p>
                  <p className="paragraph-lead">
                    <u>"Company Services"</u>: the features and other products
                    and services make available to you, including but not
                    limited to when you visit or shop at our website, located at
                    https://www.optiwatt.com, or access and use our iOS or
                    Android applications made available on the Apple AppStore
                    and Google Play store, respectively, as such features,
                    products, services, or applications may be offered,
                    withdrawn, updated or modified by us in our sole discretion
                    from time to time.
                  </p>
                  <p className="paragraph-lead">
                    <u>"Connected Device"</u>: an electronic vehicle,
                    thermostat, charger, or other device that can be monitored
                    and/or controlled by, and/or otherwise used in connection
                    with the Company Services.
                  </p>
                  <p className="paragraph-lead">
                    <u>"Content"</u>: text, images, graphics, photos, video,
                    audio, and any other content, information or data (including
                    transaction data), created, derived from or accessible via
                    use of the Company Services. The term "your Content"
                    includes Content originating or derived from your use of the
                    Company Services and/or Third Party Applications.
                  </p>
                  <p className="paragraph-lead">
                    <u>"Environmental Attributes"</u>: any and all emissions and
                    renewable energy credits, energy conservation credits,
                    benefits, reductions, offsets and allowances, emission
                    reduction credits or words of similar import or regulatory
                    effect (including emissions reduction credits or allowances
                    under all applicable emission trading, compliance or budget
                    programs, or any other federal, state or regional emission,
                    renewable energy or energy conservation trading or budget
                    program, including but not limited to, for residents of
                    Massachusetts, Clean Peak Energy Certificates generated
                    through participation in the Clean Peak Energy Standard
                    Program) resulting from the avoidance of the emission of any
                    gas, chemical or other substance into the air, soil or water
                    that are related or attributable to your ownership or
                    operation of an electric or hybrid-powered vehicle.
                  </p>
                  <p className="paragraph-lead">
                    <u>"Energy Services Vendors"</u>: our vendors used in
                    connection with certain demand response or other electric or
                    gas utility programs, including but not limited to Voltus,
                    Inc. and any other utility partner or other vendor we may
                    use from time to time.
                  </p>
                  <p className="paragraph-lead">
                    <u>“Market Administrator”</u>: the electricity market
                    administrator in your location of use of the Company
                    Services.
                  </p>
                  <p className="paragraph-lead">
                    <u>"Privacy Policy"</u>: our privacy policy available at
                    <a href="https://www.optiwatt.com/privacy">
                      {' '}
                      https://www.optiwatt.com/privacy
                    </a>
                    , as may be amended from time to time.
                  </p>
                  <p className="paragraph-lead">
                    <u>"Qualified End User"</u>: means an eligible customer of
                    the Company who has submitted an application that has been
                    accepted for the Company Services by the Market
                    Administrator and/or the applicable Energy Services Vendor
                    and whose participation has not been withdrawn or
                    terminated.
                  </p>
                  <p className="paragraph-lead">
                    <u>"Qualified End User Data"</u>: means your name, service
                    address, account number, activation date, Connected Device
                    account status, run time data, set points, and related
                    details, and operational data including peak load
                    contribution, Connected Device or whole-home interval energy
                    consumption data, and annual energy consumption, and may
                    include some of your Access Information.
                  </p>
                  <p className="paragraph-lead">
                    <u>"Subject Matter"</u>: these Terms, Company Services,
                    Content, Third Party Applications and any good, item,
                    product or service made available via any of the foregoing.
                  </p>
                  <p className="paragraph-lead">
                    <u>"Third Party Applications"</u>: online or offline
                    software, products, services, platforms, APIs, e-commerce
                    sites, functionality, hardware, networks or Content not
                    developed or provided by Company, including any of the
                    foregoing that is owned or licensed by you or a third party,
                    or that is processed, made available, or enabled for use and
                    display, via the Company Services.
                  </p>
                  <p className="paragraph-lead">
                    For purposes of these Terms, "we", "us", "our", the
                    "Company" or "Optiwatt" refers to Optiwatt, Inc. "You" or
                    "your" refers to you as the individual using the Company
                    Services, the company or other legal entity represented by
                    such individual, and all affiliates thereto.
                  </p>
                  <h3>2. ACCESS TO COMPANY SERVICES</h3>
                  <p className="paragraph-lead">
                    2.1 <u>Availability</u>. Subject to your compliance with
                    these Terms, including your payment obligations, if any, we
                    will use commercially reasonable efforts to make available
                    the Company Services. You acknowledge that your decision to
                    use the Company Services is not reliant or dependent on the
                    availability of any current or future functionality or
                    features, or on any oral or written public or private
                    comments or representations made by us.
                  </p>
                  <p className="paragraph-lead">
                    2.2 <u>Eligibility</u>. You must be at least 16 years old to
                    use the Company Services. By accepting these Terms or using
                    the Company Services, you represent, warrant, and covenant
                    to us that: (a) you are at least 16 years old; (b) if you
                    are under age 18 (or are otherwise a minor in your
                    jurisdiction), you have received permission from your parent
                    or legal guardian to use the Company Services and you agree
                    that you will only use the Company Services under the
                    supervision of a parent or legal guardian who agrees to be
                    bound by these Terms on your behalf; and (c) your acceptance
                    of these Terms and use of the Company Services is in
                    compliance with all applicable local, state/provincial,
                    national and international laws, rules and regulations. Any
                    use or access to the Company Services by individuals under
                    the age of 16 (or, if higher, the minimum age to legally
                    drive an automobile in your jurisdiction) is strictly
                    prohibited and is a violation of these Terms. You may not
                    use the Company Services if you are barred from doing so
                    under the laws of the United States or other countries
                    including the country in which you are resident or from
                    which you use or access the Company Services.
                  </p>
                  <p className="paragraph-lead">
                    2.3 <u>Transfer of Ownership of Environmental Attributes</u>
                    . You acknowledge and agree that Environmental Attributes
                    may have monetary value, and that, in at least some cases,
                    you may have the option of applying to the applicable
                    program, including but not limited to, for Massachusetts
                    residents, the Clean Peak Energy Standard Program, directly
                    on your own or working with a third-party resource provider
                    (or, in the case of the Clean Peak Energy Standard Program,
                    a Clean Peak Resource Provider), such as the Company or an
                    Energy Services Vendor, who may apply on your behalf. As
                    partial consideration for your use of the Company Services,
                    you agree to forgo your rights to monetize the value of the
                    Environmental Attributes directly by participating in such
                    program through the Company or an Energy Services Vendor,
                    and hereby transfer and assign to the Company and/or, if
                    applicable, such Energy Services Vendor, all right, title
                    and interest in and to the Environmental Attributes. You
                    further hereby represent and warrant that (i) you have been
                    provided sufficient information that you are releasing your
                    rights to Environmental Attributes through participation in
                    such program to the Company and/or such Energy Services
                    Vendor and that the consideration you are receiving is
                    appropriate, and (ii) the Environmental Attributes are free
                    and clear of any other claims or encumbrances of any kind
                    whatsoever, and you agree to provide the Company and/or such
                    Energy Services Vendor your best efforts with respect to any
                    act reasonably necessary for the Company and/or such Energy
                    Services Vendor to perfect title in and to the Environmental
                    Attributes.
                  </p>
                  <p className="paragraph-lead">
                    2.4 <u>Consent to Emails</u>. You agree and consent to
                    receive email messages from us, which may be transactional,
                    for account management purposes, or for communications
                    relating to or provided as part of the Company Services,
                    including administrative notices and service announcements
                    or changes.
                  </p>
                  <p className="paragraph-lead">
                    2.5 <u>Reservation of Rights</u>. Company, Energy Services
                    Vendors, and our licensors, as applicable, retain all right,
                    title and interest in and to the Company Services and our
                    Content, and all associated intellectual property rights.
                    Company, Energy Services Vendors, and our licensors grant no
                    licensed rights to you in our copyrights, trademarks,
                    patents, or any other form of our respective intellectual
                    property. The user interface, user experience, icons,
                    presentation layer and elements, reports, layouts, and
                    screen displays of or generated by the Company Services are
                    the copyrightable content, trade dress, trademarks and
                    servicemarks of Company, Energy Services Vendors, or our
                    licensors, as applicable. You will not use, reproduce,
                    distribute, or deploy the Company Services or our Content,
                    except for your own personal or business use, and solely in
                    accordance with these Terms.
                  </p>
                  <h3>3. USE OF THE COMPANY SERVICES</h3>
                  <p className="paragraph-lead">
                    3.1 <u>Necessary Systems</u>. Your access to and ability to
                    effectively use the Company Services is conditioned on your
                    procurement of all necessary system, hardware, software,
                    operating environment, Third Party Applications,
                    connectivity, and network access. You acknowledge that use
                    of the Company Services requires connection to, and data
                    transfers over, the network and therefore may impact your
                    data usage charges imposed by your wireless operator or
                    other service provider, and may impact usage limitations
                    imposed by Third Party Applications.
                  </p>
                  <p className="paragraph-lead">
                    3.2 <u>Feedback</u>. You agree that we may freely use and
                    exploit in perpetuity any feedback, requirements,
                    recommendations, ideas, bug fixes, reviews, ratings,
                    comments, suggestions, or improvements, that you, or any
                    employee or agent thereof, may at any time disclose or
                    submit to us relating to the Company Services for our
                    business purposes, including for product, service or
                    solution sales, support and development, without any
                    obligation or payment to you.
                  </p>
                  <p className="paragraph-lead">
                    3.3 <u>Data</u>. You hereby consent to collection and use by
                    Company and our Energy Services Vendors of data (including
                    Qualified End User Data, meta-data, analytical, diagnostic
                    and technical data, and usage statistics) concerning or
                    arising from your use of the Company Services in order to
                    provide the functionality of and improve the Company
                    Services, apply to and participate in demand response or
                    other electric, gas, or other utility programs, for product
                    development and/or marketing purposes, to protect against
                    spam and malware, and/or for verifying compliance with these
                    Terms.
                  </p>
                  <p className="paragraph-lead">
                    3.4 <u>Energy Services Venders.</u> You hereby consent to
                    and give us and our Energy Services Vendors, as applicable,
                    permission to do the following:
                  </p>
                  <p className="pl-5">
                    a. collect or permit our Energy Services Vendors to collect
                    your Qualified End User Data for the purpose of determining
                    your eligibility for the Company Services and operating the
                    Company Services
                  </p>
                  <p className="pl-5">
                    b. share with and permit our Energy Services Vendors to
                    access your Qualified End User Data for purpose of
                    registration and settlement with the Market Administrator;
                  </p>
                  <p className="pl-5">
                    c. share and permit our Energy Services Vendors to share the
                    required portions of your Qualified End User Data with the
                    Market Administrator as part of the Market Administrator’s
                    registration process;{' '}
                  </p>
                  <p className="pl-5">
                    d. share with and permit our Energy Services Vendors to
                    access energy demand data recorded through the use of the
                    Company Services, and share this data with the Market
                    Administrator for purposes of registration and settlement
                    with the Market Administrator;{' '}
                  </p>
                  <p className="pl-5">
                    e. automatically adjust your Connected Device, as required
                    by the Company Services or for general system testing
                    purposes;
                  </p>
                  <p className="pl-5">
                    f. review and analyze all of your Qualified End User Data
                    for the purposes of fulfilling our obligations in connection
                    with the Company Services and under applicable law,
                    improving the Company Services, and as otherwise permitted
                    by applicable law in accordance with our privacy policy and
                    the privacy policies of our Energy Services Vendors; and{' '}
                  </p>
                  <p className="pl-5">
                    g. summarize in publicly available studies or press releases
                    Qualified End User Data that has been anonymized such that
                    you are not individually identifiable.
                  </p>
                  <p className="paragraph-lead">
                    3.5 <u>Usage Restrictions</u>. You will not use the Company
                    Services or participate in any activities via the Company
                    Services in a manner that is likely to be prohibited by law
                    or these Terms or violative of third party rights in any
                    applicable jurisdiction, including intellectual property
                    rights. Your use of the Company Services must be in full
                    compliance with applicable law. You are solely responsible
                    for the accuracy, quality and legality of the Third Party
                    Applications. You will not use, or enable or permit the use
                    of the Company Services to store or transmit illegal,
                    infringing, libelous, offensive or otherwise unlawful or
                    tortious material or data or in violation of privacy rights,
                    or to transmit malicious code, viruses, time bombs, Trojan
                    horses, or similar mechanisms, scripts, agents, bots or
                    programs. You will not use or access the Company Services:
                    (a) if you are a competitor of us or operating on behalf of
                    such a competitor; or (b) for purposes of monitoring
                    availability, performance or functionality, or for any other
                    benchmarking or competitive purposes.
                  </p>
                  <p className="paragraph-lead">
                    3.6 <u>Notifications to You</u>. For purposes of service
                    messages and notices about the Company Services to you, we
                    may place a banner notice across site pages to alert you to
                    certain changes such as modifications to these Terms.
                    Alternatively, notice may consist of an email from us to an
                    email address associated with your account, even if we have
                    other contact information. You also agree that we may
                    communicate with you in relation to your account and these
                    Terms through your account or through other contact
                    information that you have provided to us, including email,
                    mobile number, telephone, or delivery services.
                  </p>
                  <p className="paragraph-lead">
                    3.7 <u>Notifications to Us</u>. If you believe that you are
                    entitled or obligated to act contrary to these Terms under
                    any mandatory or applicable law, you agree to provide us
                    with detailed and substantiated explanation of your reasons
                    in writing at least thirty days before you act, to allow us
                    to assess whether we may, at our sole discretion, provide an
                    alternative remedy for the situation, though we are under no
                    obligation to do so. To be effective, notices to us must be
                    sent to casey@getoptiwatt.com.
                  </p>
                  <p className="paragraph-lead">
                    3.8 <u>Payment Terms</u>. Your interactions with the Company
                    Services may earn you rewards or payments from certain
                    Energy Services Vendors, Market Administrators, or other
                    applicable program operators. You expressly authorize
                    Company’s service provider, Tremendous, LLC and its
                    affiliates (the “Processor”), to originate credit and other
                    rewards transfers to you and/or your financial institution
                    account. You must have at least USD $5.00 (or the equivalent
                    in “points”) to redeem certain rewards and/or payments. Any
                    rewards or earned payment amounts that you do not redeem
                    within 5 years of earning such rewards or payments may be
                    forfeited and become no longer redeemable. Your triggering
                    of redemption of rewards or payments cannot be reversed, and
                    once we transfer such rewards or payment to the Processor
                    for purposes of transferring the same to you, we will not be
                    liable for any loss, damage, or impairment thereto arising
                    from the actions or inactions of the Processor.
                  </p>
                  <h3>4. ACCESS INFORMATION &amp; CONTENT</h3>
                  <p className="paragraph-lead">
                    4.1 <u>Access Information</u>. You are wholly responsible
                    for maintaining the confidentiality of Access Information
                    and wholly liable for all activities occurring under such
                    Access Information. You will not transfer to any party
                    Access Information, or use access information of another,
                    without our prior written consent. You will immediately
                    notify us of any unauthorized use of Access Information or
                    any other breach of security via email sent to
                    casey@getoptiwatt.com. We will not be liable for any loss or
                    damage arising from lost or forgotten Access Information
                    (including associated loss of Content), from failure to
                    comply with this section or from unauthorized use of Access
                    Information.
                  </p>
                  <p className="paragraph-lead">
                    4.2 <u>Responsibility for Content</u>. Your Content is your
                    sole responsibility and the responsibility of the user from
                    which such Content originated. We will have no
                    responsibility or liability for the deletion or failure to
                    store any Content or user data. We reserve the right to mark
                    as "inactive" and archive accounts that are inactive for an
                    extended period of time. It is your sole responsibility to
                    back up your Content and end user data. We may preserve and
                    disclose any Content if required to do so by law or judicial
                    or governmental mandate or as reasonably necessary to
                    protect the rights, property or safety of us, users and/or
                    the public. We may terminate Company Services access in
                    response to a violation or suspected violation of these
                    Terms.{' '}
                    <b>
                      You agree to only provide information through the Company
                      Services that you are allowed to provide without violating
                      any obligations you might have towards a third party,
                      including any confidentiality obligations. You agree not
                      to provide any information that you are not allowed to
                      share with others, including by contract or law. Any
                      information you provide will be accessible by other users
                      of the Company Services.
                    </b>
                  </p>
                  <p className="paragraph-lead">
                    4.3 <u>Use of Content</u>. You will bear all risks
                    associated with the use of any Content, including any
                    reliance on the quality, integrity, accuracy, completeness,
                    or usefulness of such Content. We may refuse or delete any
                    Content, including Content of which we become aware that
                    fails to fulfill the purpose of the Company Services, is in
                    breach of these Terms, is otherwise contrary to law, or is
                    otherwise inappropriate in our discretion.{' '}
                    <b>
                      We do not guarantee, and are not responsible for, the
                      accuracy, integrity or quality of any Content. Under no
                      circumstances will we be liable in any way for any
                      Content, including, but not limited to, liability for any
                      errors, inaccuracies, or omissions in any Content, or for
                      any loss or damage of any kind incurred as a result of the
                      use of any Content. We will have no obligation or
                      liability to maintain, store, or license Content, protect
                      and maintain Content owners' intellectual property rights,
                      or to enforce these Terms. You hereby waive and release
                      any claims you may have against us arising or resulting
                      from use or misuse of Content or your inability to
                      effectively use Content, your failure to comply with these
                      Terms, or for any act, omission, or conduct of any Company
                      Services user.
                    </b>
                  </p>
                  <p className="paragraph-lead">
                    4.4 <u>Account Data</u> While we will endeavor to back up
                    data, we have no responsibility or liability for the
                    deletion or failure to store any Content, including but not
                    limited to your account data. You acknowledge and agree that
                    we are under no obligation to compile and return to you your
                    Content, including if you elect to deactivate your account,
                    except as Company may otherwise agree in writing.
                  </p>
                  <p className="paragraph-lead">
                    4.5 <u>License to Your Content</u>. You hereby grant to us
                    the perpetual, irrevocable, worldwide, royalty-free, fully
                    paid-up, sublicensable, non-exclusive right and license to
                    use, reproduce, distribute, create derivative works of,
                    display and perform your Content solely for the purposes of
                    developing, implementing, maintaining, improving and
                    providing the Company Services.
                  </p>
                  <h3>5. THIRD PARTY APPLICATIONS & CO-BRANDED SERVICES</h3>
                  <p className="paragraph-lead">
                    5.1 <u>Third Party Applications and Co-Branded Services</u>.
                    The Company Services interoperate with Third Party
                    Applications and may depend on continuing availability of
                    and access to such Third Party Applications for full
                    functionality of the Company Services. If you install,
                    enable, or otherwise use a Third Party Application for use
                    with or via the Company Services, you grant us and/or the
                    provider of such Third Party Application, which may or may
                    not be an Energy Services Vendor (such provider of such
                    Third Party Application, a “Third Party Provider”)
                    permission to access and collect your Content as required
                    for the interoperation of the Company Services with such
                    Third Party Application. The Company Services may also
                    include Third Party Provider-branded or co-branded sites or
                    services (collectively, “Third Party or Co-Branded
                    Services”). These Third Party or Co-Branded Services provide
                    services to you by us and/or such Third Party Providers. WE
                    DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY
                    FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD
                    PARTY PROVIDER THROUGH THE COMPANY SERVICES, AND SUCH THIRD
                    PARTY PROVIDERS DO NOT WARRANT, ENDORSE, GUARANTEE, OR
                    ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED
                    OR OFFERED BY US, INCLUDING BUT NOT LIMITED TO THE COMPANY
                    SERVICES.
                  </p>
                  <p className="paragraph-lead">
                    5.2 <u>Responsibility and Use of Your Content</u>. You are
                    solely responsible for the Third Party Applications. Under
                    no circumstances will we be liable in any way for Third
                    Party Applications, including, but not limited to, liability
                    for any errors or omissions in any Third Party Applications,
                    or for any errors, loss or damage of any kind incurred as a
                    result of the use or unavailability of the Third Party
                    Applications. You acknowledge and agree that any exchange of
                    your Content between you and any Third Party Application or
                    Third Party Provider, shall solely be between you and such
                    Third Party Application or Third Party Provider. We do not
                    warrant or make any representation, endorse, guarantee,
                    monitor, assume responsibility for, or support, and shall
                    have no liability whatsoever in connection with, any Third
                    Party Applications or Third Party Providers, and you hereby
                    waive and release any claims you may have against us arising
                    or resulting from use, misuse, alteration, loss, or
                    disclosure of your Content resulting from access or
                    collection by Third Party Applications. If Third Party
                    Providers of cease to make the Third Party Applications
                    available for interoperation with the corresponding features
                    of the Company Services and on reasonable terms or
                    otherwise, we may cease providing Company Services or such
                    features, without entitling you to any refund, credit or
                    other compensation.
                  </p>
                  <p className="paragraph-lead">
                    5.3 <u>Third Party Application Use of Your Content</u>. We
                    will not be responsible for any disclosure, modification or
                    deletion of your Content resulting from (a) any access or
                    use of such Content by Third Party Applications or (b)
                    enabling Thid Party Applications to interoperate with the
                    Company Services.
                  </p>
                  <p className="paragraph-lead">
                    5.4 <u>Third Party Applications Terms</u>. You may be
                    subject to additional terms and conditions and privacy
                    policies that may apply when you use Third Party
                    Applications or Third Party or Co-Branded Services, or
                    affiliate or third party content or services, and you are
                    responsible for complying with such additional terms and
                    conditions.
                  </p>
                  <h3>6. RESTRICTIONS</h3>
                  <p className="paragraph-lead">
                    6.1 <u>Prohibited Acts</u>. You are prohibited from
                    breaching or attempting to breach any security features of
                    the Company Services, including, without limitation: (a)
                    accessing content, data, information or materials not
                    intended for you, or logging onto a server or account that
                    you are not authorized to access; (b) attempting to probe,
                    scan, or test the vulnerability of the Company Services, or
                    any associated system or network, or to breach security or
                    authentication measures without proper authorization; (c)
                    interfering or attempting to interfere with use of the
                    Company Services by any user, host, or network, including,
                    without limitation, by means of submitting a virus,
                    overloading, flooding, spamming, mail bombing, or crashing;
                    (d) publishing or linking to malicious content intended to
                    damage or disrupt another user’s browser or computer or to
                    compromise a user’s privacy or anonymity; (e) forging any
                    TCP/IP packet header or any part of the header information;
                    (f) accessing or tampering with non-public areas of the
                    Company Services, our computer systems, or the technical
                    delivery systems of us or our providers; (g) publish, post,
                    upload or otherwise transmit any data, material, information
                    or content that contains any viruses, trojan horses, worms,
                    time bombs, corrupted files or programming routines or
                    mechanisms that are intended to damage, interfere with,
                    monitor, intercept or expropriate any systems, data,
                    information or property; (h) accessing or attempting to
                    access the Company Services by any means (automated or
                    otherwise) other than through the currently available,
                    published or enabled interfaces that are provided by us,
                    unless you have been specifically allowed to do so in a
                    separate agreement with us; or (i) attempting to modify,
                    reverse-engineer, decompile, disassemble, or otherwise
                    reduce or attempt to reduce to a human-perceivable form any
                    of the source code or other information used by us in
                    providing the Company Services.
                  </p>
                  <p className="paragraph-lead">
                    6.2 <u>Illicit Access</u>. You will not obtain or attempt to
                    gain unauthorized access to other accounts, computer systems
                    or networks connected to any of our servers, through
                    hacking, password mining or any other means. You will not
                    obtain or attempt to obtain any materials or information
                    through any means not intentionally made available through
                    the Company Services, which is for your personal/internal
                    and individualized use only. Without limiting the generality
                    of the foregoing, you will not publish, distribute or
                    transmit to the general public via any medium the Company
                    Services, except through and as otherwise authorized by us,
                    and you will not engage in framing, mirroring, or otherwise
                    reproducing or simulating the appearance or function of the
                    Company Services. You will not remove any copyright,
                    trademark or other proprietary rights notices associated
                    with or visible via use of the Company Services.
                  </p>
                  <h3>
                    7. DISCLAIMER OF WARRANTIES, LIMITATION OF LIABILITY &amp;
                    INDEMNITY
                  </h3>
                  <p className="paragraph-lead">
                    7.1 <u>Disclaimer</u>.{' '}
                    <b>
                      TO THE MAXIMUM EXTENT POSSIBLE UNDER APPLICABLE LAW, WE
                      PROVIDE THE COMPANY SERVICES ON AN AS-IS, AS-AVAILABLE
                      BASIS WITH ALL FAULTS, AND WE DISCLAIM ALL WARRANTIES OF
                      ANY KIND WITH RESPECT TO THE COMPANY SERVICES, WHETHER
                      EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE
                      IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                      PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
                    </b>
                    Specifically, we make no warranty that (a) the Company
                    Services will meet your requirements, goals or needs, (b)
                    Company Services access will be uninterrupted, timely,
                    secure or error-free, or (c) any errors or deficiencies in
                    the Company Services will be corrected. We have no
                    responsibility or liability for the deletion of or failure
                    to store your Content or to ensure that your Content is
                    accurate or complete.{' '}
                    <b>
                      It is your sole responsibility to back up and maintain the
                      accuracy and completeness of your Content.
                    </b>{' '}
                    Because no online system is perfectly secure or reliable,
                    the internet is an inherently insecure medium, and the
                    reliability and security of hosting services, internet
                    intermediaries, your internet service provider, and other
                    application or service providers cannot be assured, you
                    accept such inherent security risks associated with your use
                    of the Company Services. Some jurisdictions do not allow the
                    exclusion of implied warranties, so the above exclusion of
                    implied warranties may not apply to you.
                  </p>
                  <p className="paragraph-lead">
                    7.2 <u>Limitation of Liability</u>.{' '}
                    <b>
                      IN NO EVENT SHALL COMPANY, ITS OFFICERS, DIRECTORS,
                      EMPLOYEES OR AGENTS, BE LIABLE TO YOU FOR ANY DIRECT,
                      INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL
                      DAMAGES WHATSOEVER RESULTING OR ARISING FROM OR RELATED TO
                      THE SUBJECT MATTER OR ANY (A) ERRORS, MISTAKES, OR
                      INACCURACIES OF CONTENT, COMPANY SERVICES OR THIRD PARTY
                      APPLICATIONS, (B) DAMAGES OF ANY NATURE WHATSOEVER
                      (INCLUDING PERSONAL INJURY, PROPERTY DAMAGE OR LOSS, OR
                      RELIANCE DAMAGES), RESULTING FROM YOUR ACCESS TO AND USE
                      OF, OR INABILITY TO ACCESS AND USE COMPANY SERVICES, THIRD
                      PARTY APPLICATIONS, OR CONTENT, (C) ANY UNAUTHORIZED
                      ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL
                      PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED
                      THEREIN, (D) ANY TERMINATION, INTERRUPTION OR CESSATION OF
                      COMPANY SERVICES, CONTENT OR THIRD PARTY APPLICATIONS, (E)
                      ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE, WHICH MAY
                      BE TRANSMITTED TO OR THROUGH COMPANY SERVICES OR THIRD
                      PARTY APPLICATIONS BY ANY THIRD PARTY, AND/OR (F) ANY
                      ERRORS OR OMISSIONS IN ANY CONTENT, COMPANY SERVICES OR
                      THIRD PARTY APPLICATIONS, OR FOR ANY LOSS OR DAMAGE OF ANY
                      KIND INCURRED AS A RESULT OF YOUR USE OF CONTENT, COMPANY
                      SERVICES OR THIRD PARTY APPLICATIONS, OR ANY CONTENT,
                      PRODUCT, ITEM OR SERVICE POSTED, EMAILED, TRANSMITTED, OR
                      OTHERWISE MADE AVAILABLE VIA THE COMPANY SERVICES. YOU
                      AGREE NOT TO RELY ON THE COMPANY SERVICES FOR ANY LIFE
                      SAFETY OR TIME-CRITICAL PURPOSES, AND TO THE FULLEST
                      EXTENT PERMITTED BY APPLICABLE LAW, YOU AGREE TO RELEASE
                      AND HOLD US, OUR ENERGY SERVICES VENDORS, AND OTHER THIRD
                      PARTY PROVIDERS HARMLESS FROM ALL LIABILITY, DAMAGES, OR
                      LOSSES OF ANY KIND OR SORT, PERSONAL INJURY, OR LOSS OF
                      LIFE ARISING FROM YOUR USE OF THE COMPANY SERVICES OR
                      THIRD PARTY APPLICATIONS. LIABILITY IS SO LIMITED AND
                      EXCLUDED, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR
                      ANY OTHER LEGAL THEORY, AND WHETHER OR NOT WE ARE ADVISED
                      OF THE POSSIBILITY OF SUCH DAMAGES. LIABILITY FOR DAMAGES
                      WILL BE SO LIMITED AND EXCLUDED, REGARDLESS OF THE
                      VALIDITY OR EFFICACY OF ANY REMEDY PROVIDED HEREIN AND
                      EVEN IF ANY REMEDY FAILS OF ITS ESSENTIAL PURPOSE. THE
                      PROVISIONS OF THIS SECTION ALLOCATE THE RISKS UNDER THESE
                      TERMS BETWEEN THE PARTIES AND EACH PARTY HAS RELIED UPON
                      THE LIMITATIONS SET FORTH HEREIN IN DETERMINING WHETHER TO
                      ENTER INTO THIS RELATIONSHIP. THE PARTIES HAVE VOLUNTARILY
                      AGREED TO DEFINE THE PARTIES’ RIGHTS, LIABILITIES AND
                      OBLIGATIONS RESPECTING THE SUBJECT MATTER EXCLUSIVELY IN
                      CONTRACT PURSUANT TO THESE TERMS, AND YOU EXPRESSLY
                      DISCLAIM THAT YOU ARE OWED ANY DUTIES OR ARE ENTITLED TO
                      ANY REMEDIES NOT EXPRESSLY SET FORTH IN THESE TERMS. THE
                      FOREGOING LIMITATIONS AND EXCLUSIONS APPLY TO THE MAXIMUM
                      EXTENT PERMITTED BY APPLICABLE LAW.
                    </b>
                    Some jurisdictions do not allow the limitation or exclusion
                    of liability for incidental or consequential damages, so the
                    above limitation or exclusion may not apply to you and you
                    may also have other legal rights that vary from jurisdiction
                    to jurisdiction.
                  </p>
                  <p className="paragraph-lead">
                    7.3 <u>Indemnification</u>. To the extent permitted under
                    applicable laws, you hereby agree to defend, indemnify and
                    hold us and our affiliates, officers, directors, employees
                    and agents harmless from and against any claims,
                    liabilities, damages, losses, and expenses, including
                    without limitation, reasonable attorney's fees and costs,
                    arising out of or in any way connected with extent permitted
                    under applicable laws, you agree to defend, indemnify and
                    hold us and our affiliates, officers, directors, employees
                    and agents harmless from and against any claims,
                    liabilities, damages, losses, and expenses, including
                    without limitation, reasonable attorney's fees and costs,
                    arising out of or in any way connected with your use of the
                    Company Services or any Company service or product, your
                    failure to comply with these Terms, and/or your violation of
                    any rights of another. You will cooperate as reasonably
                    required by us in the defense of any claim. We reserve the
                    right to assume the exclusive defense and control of any
                    matter subject to indemnification by you, and you will not
                    in any event settle any claim without our prior written
                    consent.
                  </p>
                  <h3>8. COPYRIGHT POLICY</h3>
                  <p className="paragraph-lead">
                    We respect the intellectual property of others and ask that
                    users of the Company Services do the same. In connection
                    with the Company Services, we have adopted and implemented a
                    policy respecting copyright law that provides for the
                    removal of any infringing materials and for the termination,
                    in appropriate circumstances, of users of the Company
                    Services who are repeat infringers of intellectual property
                    rights, including copyrights. If you believe that one of our
                    users is, through the use of the Company Services,
                    unlawfully infringing the copyright(s) in a work, and wish
                    to have the allegedly infringing material removed, the
                    following information in the form of a written notification
                    (pursuant to 17 U.S.C. 512(c)) must be provided to our
                    designated Copyright Agent:
                  </p>
                  <p className="paragraph-lead">
                    a. Your physical or electronic signature;
                  </p>
                  <p className="paragraph-lead">
                    b. Identification of the copyrighted work(s) that you claim
                    to have been infringed;
                  </p>
                  <p className="paragraph-lead">
                    c. Identification of the material on the Company Services
                    that you claim is infringing and that you request us to
                    remove;
                  </p>
                  <p className="paragraph-lead">
                    d. Sufficient information to permit us to locate such
                    material;
                  </p>
                  <p className="paragraph-lead">
                    e. Your address, telephone number, and e-mail address;
                  </p>
                  <p className="paragraph-lead">
                    f. A statement that you have a good faith belief that use of
                    the objectionable material is not authorized by the
                    copyright owner, its agent, or under the law; and
                  </p>
                  <p className="paragraph-lead">
                    g. A statement that the information in the notification is
                    accurate, and under penalty of perjury, that you are either
                    the owner of the copyright that has allegedly been infringed
                    or that you are authorized to act on behalf of the copyright
                    owner.
                  </p>
                  <p className="paragraph-lead">
                    Please note that, pursuant to 17 U.S.C. 512(f), any
                    misrepresentation or omission of a material fact (falsities)
                    in a written notification automatically subjects the
                    complaining party to liability for any damages, costs and
                    attorney's fees incurred by us in connection with the
                    written notification and allegation of copyright
                    infringement.
                  </p>
                  <p className="paragraph-lead">
                    Our designated Copyright Agent is: Copyright Agent, c/o
                    Optiwatt, Inc., 180 Sansome Street, San Francisco CA 94104
                    USA;{' '}
                    <a href="mailto:zach@getoptiwatt.com">
                      zach@getoptiwatt.com
                    </a>
                    .
                  </p>
                  <h3>9. GENERAL PROVISIONS</h3>
                  <p className="paragraph-lead">
                    9.1 <u>Service Discontinuance/Modification</u>. We may from
                    time to time modify or discontinue access to, temporarily or
                    permanently, any part, feature, or functionality of the
                    Company Services. We will not be liable for any such
                    modification, suspension or discontinuance, even if certain
                    features or functions, your settings, and/or any Content you
                    have contributed or have come to rely on, are permanently
                    lost.
                  </p>
                  <p className="paragraph-lead">
                    9.2 <u>Account Termination</u>. We may terminate your
                    account or access for cause, including without limitation
                    for: (a) violation of these Terms; (b) abuse of our
                    resources or any attempt to gain unauthorized entry to the
                    Company Services; (c) use of the Company Services in a
                    manner inconsistent with its purpose; (d) any authorized
                    user's request for such termination; or (e) requirements of
                    or for failure to comply with applicable law, regulation,
                    court or governing agency order, or ethical requirements. We
                    may in addition terminate the availability of the Company
                    Services for our own business reasons, including if we elect
                    to cease being in the business of providing it or if the
                    owners of Third Party Applications make continued operation
                    commercially impracticable or unreasonable. After account
                    termination, you will not attempt to register a new account
                    without our permission.
                  </p>
                  <p className="paragraph-lead">
                    9.3 <u>Trademarks; Media</u>. You are granted no right,
                    title or license to any third-party trademarks by these
                    Terms, or to any of our trademarks or servicemarks. We
                    reserve all right, title and interest in and to our
                    trademarks, servicemarks, trade names, domain names, and
                    similar identifiers. All trademarks not owned by us that
                    appear in the Company Services are the property of their
                    respective owners, who may or may not be affiliated with,
                    connected to, or sponsored by us. You shall not purchase
                    search engine or other pay per click keywords (such as
                    Google AdWords), or domain names that use our trademarks or
                    servicemarks and/or variations and misspellings thereof.
                  </p>
                  <p className="paragraph-lead">
                    9.4 <u>California-Based</u>. The Company Services is
                    controlled by us from our offices in California, USA. We
                    make no representation that the Company Services is
                    appropriate for use in other jurisdictions. Your use of or
                    access to the Company Services will not be construed as our
                    purposefully availing ourselves of the benefits or
                    privileges of doing business in any other state or
                    jurisdiction other than California, USA.
                  </p>
                  <p className="paragraph-lead">
                    9.5 <u>Governing Law; Choice of Forum</u>. The Subject
                    Matter and any disputes between us and related to or
                    concerning any of the Subject Matter (including tort as well
                    as contract claims, and whether pre-contractual or
                    extra-contractual) will be governed by the laws of the State
                    of California, without regard to conflicts of laws rules,{' '}
                    <b>
                      and to the maximum extent permitted by applicable law, the
                      parties hereby waive the right to a jury trial for any
                      such disputes.
                    </b>{' '}
                    The United Nations Convention on Contracts for the
                    International Sale of Goods will not apply and is hereby
                    expressly excluded. Subject to section 9.6, the state and
                    federal courts located in San Francisco County, California,
                    will have exclusive jurisdiction and venue over any disputes
                    between the parties arising out of or related to the Subject
                    Matter, and both parties hereby consent and submit to the
                    jurisdiction and venue of such courts for such disputes and
                    waive any objections to the exercise of such jurisdiction or
                    venue.
                  </p>
                  <p className="paragraph-lead">
                    9.6 <u>Arbitration</u>. Please read this Arbitration
                    Agreement carefully. It is part of your contract with the
                    Company and affects your rights. It contains procedures for
                    mandatory binding arbitration and a class action waiver.
                  </p>
                  <p className="pl-5">
                    a. <u>Applicability of Arbitration Agreement</u>. All claims
                    and disputes (excluding claims for injunctive or other
                    equitable relief as set forth below) in connection with the
                    Terms, the Company Services or the use of any products or
                    services provided by the Company that cannot be resolved
                    informally or in small claims court shall be resolved by
                    binding arbitration on an individual basis under the terms
                    of this Arbitration Agreement. Unless otherwise agreed, all
                    arbitration proceedings shall be held in English. This
                    Arbitration Agreement applies to you and the Company.
                  </p>
                  <p className="pl-5">
                    b. <u>Notice Requirement and Informal Dispute Resolution</u>
                    . Before either party may seek arbitration, the party must
                    first send to the other party a written Notice of Dispute
                    (“Notice”) describing the nature and basis of the claim or
                    dispute, and the requested relief. A Notice to the Company
                    should be sent to: casey@getoptiwatt.com. After the Notice
                    is received, you and the Company may attempt to resolve the
                    claim or dispute informally. If you and the Company do not
                    resolve the claim or dispute within thirty (30) days after
                    the Notice is received, either party may begin an
                    arbitration proceeding. The amount of any settlement offer
                    made by any party may not be disclosed to the arbitrator
                    until after the arbitrator has determined the amount of the
                    award, if any, to which either party is entitled.
                  </p>
                  <p className="pl-5">
                    c. <u>Arbitration Rules</u>. Arbitration shall be initiated
                    through JAMS (www.jamsadr.com), an established alternative
                    dispute resolution provider that offers arbitration as set
                    forth in this section, or if JAMS is not available to
                    arbitrate, the parties shall agree to select an alternative
                    provider (“ADR Provider”). This Agreement and the rules of
                    the ADR Provider shall govern all aspects of the
                    arbitration, including but not limited to the method of
                    initiating and/or demanding arbitration, except to the
                    extent such rules are in conflict with the Agreement
                    (“Arbitration Rules”). The arbitration shall be conducted by
                    a single, neutral arbitrator. Any claims or disputes where
                    the total amount of the award sought is less than Ten
                    Thousand U.S. Dollars (US $10,000.00) may be resolved
                    through binding non-appearance-based arbitration, at the
                    option of the party seeking relief. For claims or disputes
                    where the total amount of the award sought is Ten Thousand
                    U.S. Dollars (US $10,000.00) or more, the right to a hearing
                    will be determined by the Arbitration Rules. Any hearing
                    will be held in a location within 100 miles of your
                    residence, unless you reside outside of the United States,
                    and unless the parties agree otherwise. If you reside
                    outside of the U.S., the arbitrator shall give the parties
                    reasonable notice of the date, time and place of any oral
                    hearing. Any judgment on the award rendered by the
                    arbitrator may be entered in any court of competent
                    jurisdiction. If the arbitrator grants you an award that is
                    greater than the last settlement offer that the Company made
                    to you prior to the initiation of arbitration, the Company
                    will pay you the greater of the award or 130% of the
                    settlement offer. Each party shall bear its own costs
                    (including reasonable attorney’s fees) and disbursements
                    arising out of the arbitration and shall each pay an equal
                    share of the fees and costs of the ADR Provider.
                  </p>
                  <p className="pl-5">
                    d.{' '}
                    <u>Additional Rules for Non-Appearance Based Arbitration</u>
                    . If non-appearance based arbitration is elected pursuant to
                    Section “(c)” immediately above, the arbitration shall be
                    conducted by telephone, online and/or based solely on
                    written submissions; the specific manner shall be chosen by
                    the party initiating the arbitration. The arbitration shall
                    not involve any personal appearance by the parties or
                    witnesses unless otherwise agreed by the parties.
                  </p>
                  <p className="pl-5">
                    e. <u>Time Limits</u>. If you or the Company pursues
                    arbitration, the arbitration action must be initiated and/or
                    demanded within the statute of limitations (i.e., the legal
                    deadline for filing a claim) and within any deadline imposed
                    under the Arbitration Rules for the pertinent claim.
                  </p>
                  <p className="pl-5">
                    f. <u>Authority of Arbitrator</u>. If arbitration is
                    initiated, the arbitrator will decide the rights and
                    liabilities, if any, of you and the Company, and the dispute
                    will not be consolidated with any other matters or joined
                    with any other cases or parties. The arbitrator shall have
                    the authority to grant motions dispositive of all or part of
                    any claim. The arbitrator shall have the authority to award
                    monetary damages, and to grant any non-monetary remedy or
                    relief available to an individual under applicable law, the
                    ADR Provider’s rules, and the Agreement. The arbitrator
                    shall issue a written award and statement of decision
                    describing the essential findings and conclusions on which
                    the award is based, including the calculation of any damages
                    awarded. The arbitrator has the same authority to award
                    relief on an individual basis that a judge in a court of law
                    would have. The award of the arbitrator is final and binding
                    upon you and the Company.
                  </p>
                  <p className="pl-5">
                    g. <u>Waiver of Jury Trial</u>. The parties hereby waive
                    their constitutional and statutory rights to go to court and
                    have a trial in front of a judge or a jury, instead electing
                    that all claims and disputes shall be resolved by
                    arbitration under this Arbitration Agreement. Arbitration
                    procedures are typically more limited, more efficient and
                    less costly than rules applicable in a court and are subject
                    to very limited review by a court. In the event any
                    litigation should arise between you and the Company in any
                    state or federal court in a suit to vacate or enforce an
                    arbitration award or otherwise, you and the Company waive
                    all rights to a jury trial, instead electing that the
                    dispute be resolved by a judge.
                  </p>
                  <p className="pl-5">
                    h. <u> Waiver of Class or Consolidated Actions</u>
                    .All claims and disputes within the scope of this
                    Arbitration Agreement must be arbitrated or litigated on an
                    individual basis and not on a class basis, and claims of
                    more than one User cannot be arbitrated or litigated jointly
                    or consolidated with those of any other User.{' '}
                  </p>
                  <p className="pl-5">
                    i. <u>Confidentiality</u>. All aspects of the arbitration
                    proceeding, including but not limited to the award of the
                    arbitrator and compliance therewith, shall be strictly
                    confidential. The parties agree to maintain confidentiality
                    unless otherwise required by law. This paragraph shall not
                    prevent a party from submitting to a court of law any
                    information necessary to enforce this Agreement, to enforce
                    an arbitration award, or to seek injunctive or equitable
                    relief.
                  </p>
                  <p className="pl-5">
                    j. <u>Severability</u>. If any part or parts of this
                    Arbitration Agreement are found under the law to be invalid
                    or unenforceable by a court of competent jurisdiction, then
                    such specific part or parts shall be of no force and effect
                    and shall be severed and the remainder of the Agreement
                    shall continue in full force and effect.
                  </p>
                  <p className="pl-5">
                    k. <u>Right to Waive</u>. Any or all of the rights and
                    limitations set forth in this Arbitration Agreement may be
                    waived by the party against whom the claim is asserted. Such
                    waiver shall not waive or affect any other portion of this
                    Arbitration Agreement.
                  </p>
                  <p className="pl-5">
                    l. <u>Survival of Agreement</u>. This Arbitration Agreement
                    will survive the termination of your relationship with the
                    Company.{' '}
                  </p>
                  <p className="pl-5">
                    m. <u>Small Claims Court.</u>. Notwithstanding the
                    foregoing, either you or the Company may bring an individual
                    action in small claims court.
                  </p>
                  <p className="pl-5">
                    n. <u>Emergency Equitable Relief</u>. Notwithstanding the
                    foregoing, either party may seek emergency equitable relief
                    before a state or federal court in order to maintain the
                    status quo pending arbitration. A request for interim
                    measures shall not be deemed a waiver of any other rights or
                    obligations under this Arbitration Agreement.
                  </p>
                  <p className="pl-5">
                    o. <u>Claims Not Subject to Arbitration</u>. Notwithstanding
                    the foregoing, claims of defamation, violation of the
                    Computer Fraud and Abuse Act, and infringement or
                    misappropriation of the other party’s patent, copyright,
                    trademark or trade secrets shall not be subject to this
                    Arbitration Agreement.
                  </p>
                  <p className="pl-5">
                    p. <u>Courts</u>. In any circumstances where the foregoing
                    Arbitration Agreement permits the parties to litigate in
                    court, the parties hereby agree to submit to the personal
                    jurisdiction and venue of the courts located within the City
                    and County San Francisco, California, USA for such purpose.
                  </p>
                  <p className="paragraph-lead">
                    9.7 <u>Limitation</u>. You agree that regardless of any
                    statute of limitations to the contrary, any claim or cause
                    of action arising out of or related to the Subject Matter
                    must be filed within one year after such claim or cause of
                    action arose, or be forever barred. If applicable law
                    prohibits a one-year limitation period for asserting claims,
                    any claim must be asserted within the shortest time period
                    established by applicable law.
                  </p>
                  <p className="paragraph-lead">
                    9.8 <u>Assignment</u>. These Terms will not be assigned,
                    delegated, or transferred by you, in whole or in part,
                    whether voluntarily, involuntarily, by merger,
                    consolidation, dissolution, sale of assets, or otherwise,
                    without our prior written consent. Any such purported
                    assignment, delegation or transfer without such written
                    consent will be void. We may at any time assign these Terms
                    without prior consent or notice. These Terms will be binding
                    on, and inure to the benefit of, the parties and their
                    respective and permitted successors and assigns.
                  </p>
                  <p className="paragraph-lead">
                    9.9 <u>Injunctive Relief</u>. You acknowledge and agree that
                    breach of these Terms, or any unauthorized use, disclosure
                    or distribution of the Company Services, may cause
                    irreparable harm to us, the extent of which would be
                    difficult to ascertain, and that we will be entitled to seek
                    immediate injunctive relief (in addition to any other
                    available remedies), in any court of competent jurisdiction
                    under the applicable laws thereto.
                  </p>
                  <p className="paragraph-lead">
                    9.10 <u>Miscellaneous</u>. The Terms constitute the entire
                    agreement between you and us and govern your use of the
                    Company Services, superseding any prior agreements,
                    understandings, communications or proposals. If any
                    provision of the Terms is found by a court of competent
                    jurisdiction to be invalid, the parties nevertheless agree
                    that the court should endeavor to give effect to the
                    parties' intentions as reflected in the provision, and the
                    other provisions of the Terms will remain in full force and
                    effect. No waiver of any provision of these Terms will be
                    deemed a further waiver or continuing waiver or such
                    provision or any other provision, and our failure to assert
                    any right or provision under these Terms will not constitute
                    a waiver of such right or provision. Nothing herein will be
                    deemed to create an agency, partnership, joint venture,
                    employee-employer or franchisor-franchisee relationship of
                    any kind between us and any user or other person or entity,
                    nor do these terms extend rights to any third party.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

export default TermsOfService
