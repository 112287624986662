import { EntityState } from '@reduxjs/toolkit'
import { RSAAAction, RSAACall } from 'redux-api-middleware'

export type AnnotationFetchActionCreator = (
  id: ID,
  annotation: string,
  params?: { [key: string]: unknown },
) => (
  dispatch: any,
  getState: () => RootState,
) => Promise<RSAAAction<unknown, unknown, AnnotationMeta & QuerySpec>>

export type AnnotationFetchActionDispatcher = (
  id: ID,
  annotation: string,
  params?: { [key: string]: unknown },
) => FetchDispatchResult

export type AnnotationMeta = IdentifyingMeta & {
  annotation: string
  paginated?: boolean
}

export type AnnotationRequestState = {
  annotationStatus: { [key: string]: RequestStatus }
  annotationErrors: { [key: string]: Errors }
}

export type ResponseError<T = unknown> = {
  status: number
  response: T
  message: string
}

// @deprecated
export type Errors = { [key: string]: unknown } | null

export type FetchMeta = {
  postFetch?: (data: unknown) => Promise<unknown>
}

export type FetchArgs = RequestArgs & {
  path?: string
  params?: { [key: string]: unknown }
}

export type FetchDispatchResult<Payload = unknown, Meta = unknown> = Promise<
  RequestActionTypeSpecification<Payload, Meta>
>

export type FetchOptions = {
  require?: boolean
  refetchOnChange?: boolean
}

export type ID = string | number

export type IdentifyingMeta = {
  id: ID
}

export type QueryMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'

export type QuerySpec = Partial<FetchArgs> & {
  method: QueryMethod
  body?: string
  middlewareResolved?: boolean
}

export type QuerySpecMeta = {
  querySpec: QuerySpec
}

export type QueryState = Partial<QuerySpec> & {
  status: RequestStatus
  error?: ResponseError | null
}

export type RequestActionStatus = 'Request' | 'Success' | 'Failure'

type RequestActionType<Payload = unknown, Meta = unknown> =
  | string
  | RequestActionTypeSpecification<Payload, Meta>

type RequestActionTypeSpecification<Payload = unknown, Meta = unknown> = {
  type: string
  payload?: Payload
  meta?: Meta
}

export type RequestActionTypes<Payload = unknown, Meta = unknown> = {
  [key in RequestActionStatus]: RequestActionType<Payload, Meta>
}

export type RequestArgs = {
  id?: ID
  path?: string
  pathSegments?: string[]
}

export enum RequestStatus {
  Idle = 'idle',
  Loading = 'loading',
  Succeeded = 'succeeded',
  Failed = 'failed',
  Invalidated = 'invalidated',
}

export enum RequestTypes {
  Fetch = 'fetch',
  Update = 'update',
  Create = 'create',
  Delete = 'delete',
}

export type RequestState = {
  status: RequestStatus
  errors: Errors
}

export type ResourceCreateActionCreator<Resource extends ResourceModel> = (
  resource: Partial<Resource>,
  args?: RequestArgs,
) => (dispatch: any, getState: () => RootState) => Promise<RSAAAction>

export type ResourceFetchActionCreator = (
  args?: FetchArgs,
) => (dispatch: any, getState: () => RootState) => Promise<RSAAAction>

export type ResourceFetchActionDispatcher = (
  args?: FetchArgs,
) => FetchDispatchResult

export type ResourceRequestState<ResourceType> = RequestState & {
  resource: ResourceType
}

export type ResourceModel = {
  id: number | string
  [key: string]: any
}

export type RootState = any

export type ResourceModelQueryResult<Resource extends ResourceModel> =
  Resource & {
    /**
     * Internally, resources have query spec hashes attached to them. each query spec is a hash of
     * a query that provided the resource. These hashes are used for caching. If we see two instances of the
     * same query at different points in the app, we can use the cached resource instead of making a new request.
     */
    querySpecHashes: string[]
  }

export type PaginatedResourcePayload<T> = {
  [key: string]: T[]
}
export type GetResourcePayload<T> = T[] | PaginatedResourcePayload<T> | T

export type ResourceMiddlewareFunction<Resource extends ResourceModel> = (
  requestData: Omit<RSAACall<unknown, unknown, unknown>, 'headers'> & {
    headers:
      | Record<string, string>
      | ((state: RootState) => Record<string, string>)
    apiConfig: ResourceApiConfig<Resource>
    paginationKey: string
    requestType: RequestTypes
  },
  data: Resource | Resource[],
  getState: () => RootState,
) => Promise<GetResourcePayload<Resource>>

export type ResourceApiConfig<Resource extends ResourceModel> = {
  baseUrl?: string
  path?: string | ((...args: string[]) => string)
  annotations?: {
    [key: string]: Omit<ResourceApiConfig<Resource>, 'annotations' | 'path'> & {
      path: string
      paginated?: boolean
    }
  }
  middlewares?: ResourceMiddlewareFunction<Resource>[]
  // Indicates that the resource is public and requests should not be authenticated
  public?: boolean
  // If true, the local resources will always be replaced when a fetch is successful
  invalidateOnRequest?: boolean
}

export type UpdateArgs = RequestArgs & {
  optimistic?: boolean
  params?: { [key: string]: unknown }
}

export type UpdateMeta<PartialResource> = {
  optimisticUpdate?: PartialResource
  updateIds?: ID[]
}

/** SLICE STATE */

export type DataStatusSliceState<Resource extends ResourceModel> = {
  data: EntityState<ResourceModelQueryResult<Resource>> &
    RequestState &
    AnnotationRequestState
  status: EntityState<QueryState>
  // scratchPad used for storing data that is needed for things like optimistic updates
  scratchPad: EntityState<ResourceModelQueryResult<Resource>>
}

export type RequestRootSelector<Resource extends ResourceModel> = (
  state: RootState,
) => DataStatusSliceState<Resource>

export type RequestDataSelector<Resource> = (
  state: RootState,
) => EntityState<Resource> & RequestState & AnnotationRequestState

/** END SLICE STATE */
