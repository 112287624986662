import { RSAA } from 'redux-api-middleware'
import { withAuth } from '../reducers'
import config from '../config'

export var CHARGES_REQUEST = '@@metric/CHARGES_REQUEST'
export var CHARGES_SUCCESS = '@@metric/CHARGES_SUCCESS'
export var CHARGES_FAILURE = '@@metric/CHARGES_FAILURE'

export var RATES_REQUEST = '@@metric/RATES_REQUEST'
export var RATES_SUCCESS = '@@metric/RATES_SUCCESS'
export var RATES_FAILURE = '@@metric/RATES_FAILURE'

export var CHARGE_FORECAST_REQUEST = '@@metric/CHARGE_FORECAST_REQUEST'
export var CHARGE_FORECAST_SUCCESS = '@@metric/CHARGE_FORECAST_SUCCESS'
export var CHARGE_FORECAST_FAILURE = '@@metric/CHARGE_FORECAST_FAILURE'

export const getCharges = (
  page,
  start_time_after,
  start_time_before,
  vehicle_id,
  vehicle_charger_id,
) => ({
  [RSAA]: {
    endpoint: config.apiGateway.URL + '/api/charges/',
    method: 'GET',
    params: {
      page,
      ...(start_time_after ? { start_time_after } : {}),
      ...(start_time_before ? { start_time_before } : {}),
      ...(vehicle_id ? { vehicle_id } : { charger_id: vehicle_charger_id }),
    },
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [CHARGES_REQUEST, CHARGES_SUCCESS, CHARGES_FAILURE],
  },
})

export const getRates = () => ({
  [RSAA]: {
    endpoint: config.apiGateway.URL + '/api/rates/',
    method: 'GET',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [RATES_REQUEST, RATES_SUCCESS, RATES_FAILURE],
  },
})

export const getChargeForecast = (vehicle_id) => ({
  [RSAA]: {
    endpoint: config.apiGateway.URL + '/api/charge_forecast/',
    method: 'GET',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    params: vehicle_id ? { vehicle_id } : {},
    types: [
      CHARGE_FORECAST_REQUEST,
      CHARGE_FORECAST_SUCCESS,
      CHARGE_FORECAST_FAILURE,
    ],
  },
})
