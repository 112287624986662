import { useTheme } from '@material-ui/core'

import { makeStyles } from '@material-ui/styles'
import { UsageSectionHeaderTypography } from '../../UsageSectionTypography'

const useStyles = makeStyles((theme) => ({}))

const Header = ({ days = 16 }) => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <UsageSectionHeaderTypography>
      Home Comparison
      {/* <ColorBoxedTypography
        variant="subtitle1"
        hexColor={theme.palette.blue.main}
        py={1}
        px={1}
        ml={2}
      >
        <UsageSectionBodyTypography>
          {days} days
        </UsageSectionBodyTypography>
      </ColorBoxedTypography> */}
    </UsageSectionHeaderTypography>
  )
}

export default Header
