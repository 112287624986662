export default function toExternalLink(href: string) {
  try {
    const url = new URL(href)
    if (url.hostname === 'external.optiwatt.com') {
      return href
    }
  } catch {
    throw new Error(`Invalid external URL: ${href}`)
  }
  return `https://external.optiwatt.com/${href}`
}
