import { Button } from '@/components'
import BrandedPage from '@/app/pages/ava/BrandedPage'
import { useNavigation } from '@/app/hooks'
import ComboBox from '@/components/forms/combo-box'
import { useForm } from 'react-hook-form'
import { useSelectedUtility } from '@/hooks/useSelectedUtility'
import { RequestStatus } from '@/request/types'
import PageLoader from '@/authenticated/components/layout/PageLoader'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { SELECT_PLAN_SUCCESS, selectPlan } from '@/actions/plans'
import { PayloadAction } from '@reduxjs/toolkit'
import { User } from '@/types'
import { AlertMessage } from '@/app/components'
import { useState } from 'react'
import useEnrolledDeviceSelections from '@/app/hooks/useEnrolledDeviceSelections'

type FormValues = {
  plan: { id: number; name: string }
}

const formSchema = yup.object({
  plan: yup
    .object({
      id: yup.number().required(),
      name: yup.string().required(),
    })
    .required(),
})

function useSubmitPlanSelection() {
  const dispatch = useAppDispatch()
  const isLoading = useAppSelector((state) => state.plans.isLoading)

  const submit = (planId: number): Promise<PayloadAction<User>> => {
    return dispatch(selectPlan({ plan_id: planId.toString() }))
  }

  return { submit, isLoading }
}

function useViewModel() {
  const navigation = useNavigation()
  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { isDirty },
  } = useForm<FormValues>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      plan: undefined,
    },
  })
  const [submissionError, setSubmissionError] = useState<string | null>(null)
  const { data: utility, status: utilityStatus } = useSelectedUtility()
  const { submit: submitPlanSelection, isLoading: planSelectionLoading } =
    useSubmitPlanSelection()
  const { getEnrolledDeviceRoute } = useEnrolledDeviceSelections()

  const planOptions = utility?.plan_options ?? []

  const isLoading = [RequestStatus.Loading, RequestStatus.Idle].includes(
    utilityStatus,
  )

  const onSubmit = handleSubmit(async ({ plan }: FormValues) => {
    setSubmissionError(null)
    const res = await submitPlanSelection(plan.id)
    if (res.type !== SELECT_PLAN_SUCCESS) {
      setSubmissionError(
        'There was an error submitting your plan. Please try again and contact support if the issue persists.',
      )
    } else {
      navigation.push(getEnrolledDeviceRoute())
    }
  })

  return {
    planOptions,
    isLoading,
    onSubmit,
    comboBoxProps: { setValue, getValues },
    submitDisabled: !isDirty || planSelectionLoading,
    submissionError,
  }
}

export default function PlanSelectOptionSelectPage() {
  const {
    planOptions,
    isLoading,
    onSubmit,
    comboBoxProps,
    submitDisabled,
    submissionError,
  } = useViewModel()

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <BrandedPage
      title="What is your utility plan?"
      subtitle="Find your plan in the list below"
      id="plan-select-option-select-page"
    >
      <BrandedPage.Content>
        <ComboBox
          {...comboBoxProps}
          label="Utility Plan"
          name="plan"
          options={planOptions}
        />
        <AlertMessage
          variant="error"
          className="mt-4"
          show={Boolean(submissionError)}
        >
          {submissionError}
        </AlertMessage>
        <BrandedPage.ButtonContainer>
          <Button
            id="next-button"
            variant="primary"
            disabled={submitDisabled}
            onClick={onSubmit}
          >
            Next
          </Button>
        </BrandedPage.ButtonContainer>
      </BrandedPage.Content>
    </BrandedPage>
  )
}
