import { Thermostat } from '.'
const mock: Thermostat[] = [
  {
    id: 1,
    portal: 'Ecobee',
    last_measurement: {
      id: 404,
      indoor_temperature: 81,
      cool_setpoint: 60,
      heat_setpoint: 60,
      hvac_mode: 'heat',
      hvac_state: 'off',
      api_event_timestamp: '2022-09-16T20:50:31.289599Z',
      created: '2022-09-22T18:01:09.945410Z',
      modified: '2022-09-22T18:01:09.945425Z',
    },
    authentication_inactive: false,
    name: 'My Ecobee',
  },
]

export default mock
