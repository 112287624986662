import { GasCarConversion } from '../types/vehicle/'

export function friendlyName(gasCarConversion: GasCarConversion | null) {
  if (!gasCarConversion) {
    return null
  }
  return `${gasCarConversion.year} ${gasCarConversion.make} ${gasCarConversion.model}`
}

export function trimName(gasCarConversion: GasCarConversion | null) {
  if (!gasCarConversion) {
    return null
  }
  return `${Number(gasCarConversion.displacement).toFixed(1)} L, ${
    gasCarConversion.cylinders
  } cyl`
}
