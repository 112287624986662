import { useEffect } from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import { X as Close } from 'react-feather'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { patchConfig } from '../../../actions/dashboardConfigs'
import { useHistory } from 'react-router-dom'

import '../../vendor/roundedBarCharts'

import {
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography as MuiTypography,
  IconButton,
} from '@material-ui/core'

import { spacing } from '@material-ui/system'
import { makeStyles, withStyles } from '@material-ui/styles'
import {
  getChargeSchedules,
  updateChargeSchedule,
} from '../../../actions/schedule'
import { useGetDashboardConfig } from '../../hooks/useHideDashboardConfig'

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.secondary.main,
  },
  cardContent: {
    position: 'relative',
    backgroundColor: theme.palette.secondary.main,
    '&:last-child': {
      paddingBottom: `${theme.spacing(4)}px`,
    },
  },
  header: {
    fontWeight: 900,
    color: theme.palette.common.white,
    fontSize: 16,
  },
  body: {
    color: theme.palette.common.white,
    lineHeight: 1.35,
    fontSize: 14,
  },
  iconWrapper: {
    position: 'absolute',
    zIndex: 100,
    right: 4,
    top: 4,
  },
  icon: {
    color: theme.palette.common.white,
    height: 16,
    width: 16,
  },
}))

const WhiteButton = withStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.common.white,
  },
}))(Button)
const MuiButton = styled(WhiteButton)(spacing)

const Card = styled(MuiCard)(spacing)
const Typography = styled(MuiTypography)(spacing)

const ActionableWinterWeatherAlertCard = (_props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const profile = useSelector((state) => state.user?.user?.profile)
  const chargeSchedules = useSelector(
    (state) => state.schedule?.chargeSchedules,
  )
  const isLoading = useSelector((state) => state.schedule.isLoading)
  const home = useSelector((state) => state.homes.selectedHome)

  const actionableWinterWeatherAlertConfig = useGetDashboardConfig(
    'ACTIONABLE_WINTER_WEATHER_ALERT',
  )

  const [updatedChargeLimit, setUpdatedChargeLimit] = useState(false)

  const chargeSchedule =
    !!chargeSchedules && chargeSchedules.length > 0 ? chargeSchedules[0] : null
  const chargeScheduleEnabled = chargeSchedule
    ? chargeSchedule.allow_charging_control
    : false

  const onClick = () => {
    if (chargeScheduleEnabled) {
      setUpdatedChargeLimit(true)

      chargeSchedule.battery_target_max = 95
      chargeSchedule.battery_target_unit = 'percent'
      if (chargeSchedule) {
        dispatch(updateChargeSchedule(chargeSchedule))
      }
    } else {
      history.push('/schedule')
    }
  }
  // Hide this alert from this profile
  const onDismiss = () => {
    dispatch(
      patchConfig(
        actionableWinterWeatherAlertConfig.id,
        'ACTIONABLE_WINTER_WEATHER_ALERT',
        profile.id,
      ),
    )
  }

  // Load charge schedules if not already loaded
  useEffect(() => {
    if (!chargeSchedules && !isLoading) {
      dispatch(getChargeSchedules())
    }
  }, [chargeSchedules, dispatch, isLoading])

  useEffect(() => {
    // After charge limit is updated and loading is done, dismiss after 2 seconds
    if (updatedChargeLimit && !isLoading) {
      setTimeout(() => {
        onDismiss()
      }, 2000)
    }
  })

  return (
    <Box position="relative">
      <Card mb={6} pt={2} className={classes.card} px={4}>
        <IconButton onClick={onDismiss} className={classes.iconWrapper}>
          <Close className={classes.icon} />
        </IconButton>
        <MuiCardContent className={classes.cardContent}>
          <Typography variant="h6" mb={2} className={classes.header}>
            Prepare for {home?.state?.name} Winter Storm{' '}
            <span role="img" aria-label="warning">
              ⚠️
            </span>
            <span role="img" aria-label="snow">
              ❄️
            </span>
          </Typography>
          <Typography variant="body1" mb={3} className={classes.body}>
            <Box fontWeight="fontWeightRegular">
              {chargeScheduleEnabled
                ? 'Boost your charge limit to 95% of capacity in preparation for the upcoming winter storm! We will restore your settings once the storm is over.'
                : 'We recommend setting your charge limit to 95% in preparation for the upcoming winter storm! Enable Optiwatt charging to raise your limit now.'}
            </Box>
          </Typography>
          {updatedChargeLimit && (
            <MuiButton variant="contained" mt={3} px={8} onClick={onClick}>
              <span
                role="img"
                aria-label="checkmark"
                style={{ paddingRight: '4px' }}
              >
                ✔️
              </span>{' '}
              Success
            </MuiButton>
          )}
          {!isLoading && !updatedChargeLimit && (
            <MuiButton variant="contained" mt={3} px={8} onClick={onClick}>
              {chargeScheduleEnabled ? 'Boost to 95%' : 'Set Up'}
            </MuiButton>
          )}
        </MuiCardContent>
      </Card>
    </Box>
  )
}

export default ActionableWinterWeatherAlertCard
