import { useEffect, useState, useRef, useCallback } from 'react'

import { Box as MuiBox } from '@material-ui/core'

import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import { makeStyles } from '@material-ui/styles'

import clsx from 'clsx'
import zip from 'lodash/zip'
import { RoundedRect } from '../../../utils/svgUtilities'
import { v4 as uuidv4 } from 'uuid'

const Box = styled(MuiBox)(spacing)

const useStyles = makeStyles((theme) => ({
  capsuleContainer: {
    width: '100%',
    lineHeight: '0',
  },
  svg: {
    borderRadius: '10px',
  },
}))

const Segment = ({
  stopWindow,
  index,
  totalRectsSize,
  maxStop,
  segmentPaddingPercentage,
  heightPercentage,
  segmentClassName,
  segmentHoverClassName,
  labelRender,
  labelWidthPercentage = 25,
  clipPathId,
  hovered,
  borderRadiusRight = 0,
  onEnter = (idx) => {},
  onLeave = (idx) => {},
  bboxWasSet = () => {},
}) => {
  const classes = useStyles()
  const segmentRef = useRef()

  const rectWidth =
    totalRectsSize * ((stopWindow[1].stop - stopWindow[0].stop) / maxStop)
  const width = labelWidthPercentage
  const widthDiff = rectWidth - width
  const xPos =
    totalRectsSize * (stopWindow[0].stop / maxStop) +
    segmentPaddingPercentage * index

  const labelAlignment =
    stopWindow[0].stop === 0 && width > rectWidth
      ? 'left'
      : stopWindow[1].stop === maxStop && width > rectWidth
      ? 'right'
      : 'center'

  useEffect(() => {
    bboxWasSet(segmentRef.current.getBoundingClientRect(), index)
  }, [xPos, rectWidth, heightPercentage, bboxWasSet])

  return (
    <RoundedRect
      ref={segmentRef}
      key={index}
      x={xPos}
      y={0}
      width={rectWidth}
      height={heightPercentage}
      borderRadiusRight={borderRadiusRight}
      fill={stopWindow[1].fill || '#00000000'}
      clipPath={`url(#${clipPathId})`}
      className={clsx(segmentClassName, hovered && segmentHoverClassName)}
      onMouseEnter={() => onEnter(index)}
      onMouseLeave={() => onLeave(index)}
      onTouchStart={() => onEnter(index)}
    />
  )
}

/**
 * A capsule that has colored segments with some padding in between.
 * Fits width to container.
 * @param {[{stop: int, fill: string}]} segmentStops
 * @param {({stop: int, fill: string}) => void} onHover
 * @param {number} heightPercentage - height as a percentage of width between 1 and 100
 * @param {number} segmentPaddingPercentage - between 1 and 100
 * @param {string} backgroundFill - color of background capsule
 */
const SegmentedCapsule = ({
  className = '',
  segmentStops,
  onHover = (_) => {},
  selectedSegmentDefault = ({ stop, fill }) => false,
  heightPercentage,
  segmentPaddingPercentage,
  backgroundFill,
  labelRender = false,
  boundsRender = false,
  boundsWidthPercentage = 11,
  segmentClassName = '',
  segmentHoverClassName = '',
  clippingId = uuidv4(),
  borderRadius = heightPercentage / 2,
  ...restProps
}) => {
  const classes = useStyles()

  const sortedSegmentStops = segmentStops.sort((a, b) => a.stop - b.stop)

  const defaultSegmentIndex = sortedSegmentStops.findIndex(
    selectedSegmentDefault,
  )
  const [_hoveredSegmentIndex, _setHoveredSegmentIndex] = useState(null)
  const setHoveredSegmentIndex = (index) => {
    _setHoveredSegmentIndex(index || defaultSegmentIndex)
    onHover(sortedSegmentStops[index])
  }
  const hoveredSegmentIndex = _hoveredSegmentIndex || defaultSegmentIndex

  const stopWindows = zip(
    sortedSegmentStops.slice(0, -1),
    sortedSegmentStops.slice(1),
  )

  const maxStop = Math.max(...sortedSegmentStops.map((stop) => stop.stop))
  const minStop = Math.min(...sortedSegmentStops.map((stop) => stop.stop))
  const gaps = stopWindows.length - 1
  const totalPaddingSize = gaps * segmentPaddingPercentage
  const totalRectsSize = 100 - totalPaddingSize

  const clipPathId = `roundedClip-${clippingId}`

  const boundsRenderResolved = boundsRender || (() => {})
  const labelRenderResolved = labelRender || (() => {})

  const segmentShouldHaveRightBorderRadius = (stopWindows, index) => {
    if (index === stopWindows.length - 1) {
      return true
    }
    const currentWindow = stopWindows[index]
    const nextWindowHasFill = !!stopWindows[index + 1][1].fill
    const nextWindowIsSame =
      currentWindow[1].stop === stopWindows[index + 1][1].stop
    return (
      !nextWindowHasFill ||
      (nextWindowIsSame &&
        segmentShouldHaveRightBorderRadius(stopWindows, index + 1))
    )
  }

  const containerRef = useRef()
  const [segmentBBoxes, setSegmentBBoxes] = useState({})

  const segmentBBoxSetter = useCallback(
    (bbox, segmentIdx) => {
      const parentBBox = containerRef.current.getBoundingClientRect()
      setSegmentBBoxes((prevSegmentBBoxes) => ({
        ...prevSegmentBBoxes,
        [segmentIdx]: {
          top: bbox.top - parentBBox.top,
          left: bbox.left - parentBBox.left,
          height: bbox.height,
          width: bbox.width,
        },
      }))
    },
    [containerRef],
  )

  const hoveredSegmentIsOnRightHalf = () => {
    const hoveredSegmentLeft = segmentBBoxes[hoveredSegmentIndex - 1]?.left
    const midwayPoint = containerRef.current.getBoundingClientRect().width / 2
    return hoveredSegmentLeft > midwayPoint
  }

  return (
    <Box
      ref={containerRef}
      className={clsx(classes.capsuleContainer, className)}
      {...restProps}
    >
      <svg
        className={classes.svg}
        width="100%"
        height="100%"
        viewBox={`0 0 100 ${heightPercentage}`}
      >
        {/* Background */}
        <clipPath id={`${clipPathId}`}>
          <rect
            x="0"
            y="0"
            width="100"
            height={heightPercentage}
            rx={borderRadius}
          />
        </clipPath>
        <rect
          x="0"
          y="0"
          width="100"
          height={heightPercentage}
          fill={backgroundFill}
          clipPath={`url(#${clipPathId})`}
        />
        {/* Segments */}
        {stopWindows.map((stopWindow, index) => (
          <Segment
            key={index}
            stopWindow={stopWindow}
            index={index}
            totalRectsSize={totalRectsSize}
            maxStop={maxStop}
            segmentPaddingPercentage={segmentPaddingPercentage}
            heightPercentage={heightPercentage}
            labelRender={labelRenderResolved}
            clipPathId={clipPathId}
            segmentClassName={segmentClassName}
            segmentHoverClassName={segmentHoverClassName}
            hovered={hoveredSegmentIndex === index + 1}
            onEnter={(idx) => setHoveredSegmentIndex(idx + 1)}
            onLeave={(idx) => setHoveredSegmentIndex(null)}
            borderRadiusRight={
              segmentShouldHaveRightBorderRadius(stopWindows, index)
                ? borderRadius
                : 0
            }
            bboxWasSet={segmentBBoxSetter}
          />
        ))}
      </svg>
      {/* @todo create new component for segmented capsule with labels and bounds instead of all in one */}
      {/* Labels */}
      {stopWindows.map((stopWindow, index) => {
        if (segmentBBoxes[index]) {
          return (
            <Box key={index}>
              {labelRenderResolved(
                stopWindow[0].stop,
                stopWindow[1].stop,
                hoveredSegmentIndex === index + 1,
                segmentBBoxes[index],
              )}
            </Box>
          )
        }
      })}
      {/* Bounds */}
      {segmentBBoxes[0] &&
        (stopWindows.length === 1 || hoveredSegmentIsOnRightHalf()) &&
        boundsRenderResolved(true, segmentBBoxes[0])}
      {segmentBBoxes[stopWindows.length - 1] &&
        (stopWindows.length === 1 || !hoveredSegmentIsOnRightHalf()) &&
        boundsRenderResolved(false, segmentBBoxes[stopWindows.length - 1])}
    </Box>
  )
}

export default SegmentedCapsule
