const scrollToTop = async (resolveEarlyMs = 0) => {
  return new Promise<void>((resolve) => {
    // Estimate the scroll duration or use a default duration
    const scrollDurationEstimate = 300 // Adjust this based on your smooth scroll behavior

    // If resolveEarlyMs is specified and valid, calculate the early resolve time
    const earlyResolveTime =
      resolveEarlyMs > 0 && resolveEarlyMs < scrollDurationEstimate
        ? scrollDurationEstimate - resolveEarlyMs
        : scrollDurationEstimate

    // Set a timeout to resolve the promise early if specified
    setTimeout(() => {
      resolve()
    }, earlyResolveTime)

    // Perform the scroll
    window.scrollTo({ top: 0, behavior: 'smooth' })
  })
}

export default scrollToTop
