import { State } from '../home'
import { Model } from '../model'
import { Plan } from '../plan'

export enum ApiProvider {
  Leap = 'LEAP',
  UtilityApi = 'UTIL',
}

export interface Utility extends Model {
  state: State | null
  find_bill_steps: any[]
  has_api_provider_link: boolean
  plan_options: Plan[]
  name: string
  place: string
  website: string | null
  login_url: string | null
  logo_url: string | null
  api_provider: ApiProvider | string
  api_provider_url: string
  api_provider_active: boolean
  last_datetime_rates_updated: string | null
  is_searchable: boolean
  openei_utility: number
}

export interface UtilitySearchResult {
  id: number
  state?: State | null
  name: string
  logo_url: string | null
  api_provider_url: string | null
  api_provider_active: boolean
  api_provider: ApiProvider | string | null
}

export function asSearchResult(utility: Utility): UtilitySearchResult {
  return {
    id: utility.id,
    state: utility.state,
    name: utility.name,
    logo_url: utility.logo_url,
    api_provider_url: utility.api_provider_url,
    api_provider_active: utility.api_provider_active,
    api_provider: utility.api_provider,
  }
}
