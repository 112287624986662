import { Button, Modal, Text } from '@/components'
import check from '@/app/img/check.svg'
import React, { useState } from 'react'
import { RateWatchOptions } from '@/app/pages/home-insights/current-rate/rate-watch'
import Multiselect, { MultiselectOption } from '@/app/components/Multiselect'
import { useSurveyResponder } from '@/app/components/SurveyObserver'
import { logEvent } from '@/logging'
import { RSAAResultAction } from 'redux-api-middleware'
import { AlertMessage } from 'app-components'
import { surveyResponsesCollection } from '@/reducers/surveyResponses'

type ModalProps = {
  showModal: boolean
  onClose: () => void
  onSubmit: () => void
  value: RateWatchOptions
}

const responses: MultiselectOption[] = [
  { text: 'Better rates', value: 'better_rates' },
  { text: 'Easy to use', value: 'easy_to_use' },
  { text: 'Automatically helps me save', value: 'helps_me_save' },
]

export default function RateWatchSurvey(props: ModalProps) {
  const [surveyAnswers, setSurveyAnswers] = useState<string[]>([])
  const [other, setOther] = useState<string>()
  const surveyResponder = useSurveyResponder('RateWatch')
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  const onChange = (vals: MultiselectOption[]) => {
    setSurveyAnswers(vals.filter((val) => val.selected).map((val) => val.value))
  }

  const submit = async () => {
    logEvent('rateToolFindSavings')
    setIsLoading(true)
    setIsError(false)
    const response = (await surveyResponder.submitResponse({
      watch_type: props.value,
      response: surveyAnswers,
      other_text: other,
    })) as RSAAResultAction

    setIsLoading(false)

    if (
      response.type === surveyResponsesCollection.actionTypes.update.Failure
    ) {
      setIsError(true)
    } else {
      props.onSubmit()
    }
  }

  const invalidSurvey = surveyAnswers.length === 0 && !other

  return (
    <Modal
      id="rate-watch-survey-modal"
      open={props.showModal}
      onClose={props.onClose}
    >
      {isError && (
        <AlertMessage className="mb-4 mt-2" variant="error">
          There was an error processing your response. Please try again.
        </AlertMessage>
      )}
      <div className="flex items-start gap-3 justify-items-start">
        <img src={check} className="w-[20px]" />
        <div>
          <Text variant="h3">Thanks for your interest</Text>
          <Text variant="body4" className="mt-2">
            We’ll notify you when this feature is ready
          </Text>
        </div>
      </div>
      <Text variant="body1" className="mt-6">
        What are you looking for most from Rate Watch?
      </Text>
      <div className="flex flex-wrap gap-2.5 mt-2.5">
        <Multiselect
          options={responses}
          onChange={onChange}
          includeOther
          onOtherChange={setOther}
        />
      </div>
      <Button
        id="submit-btn"
        type="submit"
        variant="primary-subtle"
        className="mt-6"
        onClick={submit}
        disabled={isLoading || invalidSurvey}
      >
        Submit Feedback
      </Button>
    </Modal>
  )
}
