export default function meanOrUndefined(
  collection: (number | undefined | null)[],
): number | undefined {
  if (!collection || collection.length == 0) {
    return 0
  }

  let undefinedValues = 0
  let sum = 0
  for (const val of collection) {
    if (val === undefined || val === null) {
      undefinedValues++
      continue
    }

    sum += val
  }

  if (undefinedValues > collection.length / 2) {
    return undefined
  }

  return sum / collection.length
}
