import {
  CardActions,
  CardActionButton,
  CardNavigation,
  Icon,
  Grid,
} from '@/components'
import { BooleanInput, FlowStep, Question } from '@/app/components'
import data from './resources/data.json'

export interface Props {
  onWeekdaysChange: (value: boolean | null) => void
  onMonthsChange: (value: boolean | null) => void
  onClose?: () => void
  onBack?: () => void
  onSubmit: () => void
}

export default function SelectManualUtilityPlanOptionsFlowStep(props: Props) {
  return (
    <FlowStep id="select-manual-utility-plan-options-flow">
      <FlowStep.Content>
        <CardNavigation
          id="select-manual-utility-plan-options-card-navigation"
          onCloseClick={props.onClose}
        />
        <Grid flow="row" gap="32px">
          <Question
            title={data.questions[0].title}
            subtitle={data.questions[0].subtitle}
          >
            <BooleanInput onChange={props.onWeekdaysChange} />
          </Question>
          <Question subtitle={data.questions[1].subtitle}>
            <BooleanInput onChange={props.onMonthsChange} />
          </Question>
        </Grid>
      </FlowStep.Content>

      <FlowStep.Actions>
        <CardActions>
          {props.onBack && (
            <CardActionButton
              type="navigation"
              onClick={props.onBack}
              id="select-manual-utility-plan-options-card-back-button"
            >
              <Icon name="ChevronLeft" color="grey-500" size={20} />
            </CardActionButton>
          )}

          <CardActionButton
            type="primary"
            onClick={props.onSubmit}
            id="select-manual-utility-plan-options-card-select-button"
          >
            Next
          </CardActionButton>
        </CardActions>
      </FlowStep.Actions>
    </FlowStep>
  )
}
