import ThermostatRoot, { Props as ComponentProps } from './Thermostat'
import useViewModel from './useViewModel'
import { ViewModelComponentProps } from '../../../../utils/typeUtilities'

export type Props = ViewModelComponentProps<ComponentProps, typeof useViewModel>

export default function Thermostat(props: Props) {
  const viewModel = useViewModel(props)

  return <ThermostatRoot {...viewModel} />
}
