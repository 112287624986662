import React, { useCallback, useState } from 'react'
import { useSelectedPlanFetcher } from '@/authenticated/hooks/plans'
import { LoadingAnimation, Text } from '@/components'
import { AnimatePresence, motion } from 'framer-motion'
import Icon from '../../../../../components/Icon'
import PlanUsageGraph from './plan-usage-graph'

export default function PlanDetails() {
  const [isOpened, setIsOpened] = useState(false)
  const { selectedPlan, planIsPending, isLoading } = useSelectedPlanFetcher()

  const onClick = useCallback(() => {
    setIsOpened((prevState) => !prevState)
  }, [])

  if (isLoading) {
    return <LoadingAnimation type="falling" centered />
  }

  if (!selectedPlan || planIsPending) {
    return (
      <AnimatePresence>
        <motion.div
          key="plan"
          className="flex"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <div className="flex-1">
            <Text variant="body4" className="mt-0.5">
              You do not currently have a utility plan setup, or your plan is
              currently pending. Usage insights are unavailable.
            </Text>
          </div>
        </motion.div>
      </AnimatePresence>
    )
  }

  return (
    <AnimatePresence>
      <motion.div
        key="plan"
        className="flex cursor-pointer"
        onMouseDown={onClick}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <div className="flex-1">
          <Text variant="body4">Your current plan</Text>
          <Text variant="body1" className="mt-0.5">
            {selectedPlan.name}
          </Text>
        </div>
        <div className="flex-4 mt-2">
          <Icon color="#5d5d5d" name={isOpened ? 'ChevronUp' : 'ChevronDown'} />
        </div>
      </motion.div>
      {isOpened && selectedPlan && <PlanUsageGraph />}
    </AnimatePresence>
  )
}
