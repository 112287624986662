import { match } from 'ts-pattern'
export enum BehavioralEligibilityRequirements {
  HasDevices = 'HAS_DEVICES',
  Allowed = 'ALLOWED',
  NotAllowed = 'NOT_ALLOWED',
  AvaKnownEv = 'AVA_KNOWN_EV',
}

export function behavioralIneligibilityReasonContent(
  key: BehavioralEligibilityRequirements,
) {
  return match(key)
    .returnType<string>()
    .with(
      BehavioralEligibilityRequirements.HasDevices,
      () => 'You must have eligible devices to enroll in this program.',
    )
    .with(
      BehavioralEligibilityRequirements.Allowed,
      () => 'You are not on the allowed list for this program.',
    )
    .with(
      BehavioralEligibilityRequirements.NotAllowed,
      () => 'You are specifically excluded from this program.',
    )
    .with(
      BehavioralEligibilityRequirements.AvaKnownEv,
      () => 'You must have a known EV to enroll in this program.',
    )
    .exhaustive()
}
