import { useLocation, useHistory } from 'react-router-dom'

export function useUrlSearchParamFlagControls(flagParam: string) {
  const location = useLocation()
  const history = useHistory()
  const onLocation: ReturnType<typeof useLocation> = {
    ...location,
    search: `${location.search}&${flagParam}=true`,
  }
  const offLocation: ReturnType<typeof useLocation> = {
    ...location,
    search: location.search.replace(`&${flagParam}=true`, ''),
  }
  const setFlag = (isOn: boolean) => {
    const newLocation = isOn ? onLocation : offLocation
    history.push(newLocation)
  }

  const isOn = location.search.includes(`${flagParam}=true`)
  return {
    isOn,
    onLocation,
    offLocation,
    setFlag,
  }
}
