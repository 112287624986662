import { uploadPlans } from '@/actions/plans'
import { FlowStep } from '@/app/components'
import { getValidPlans } from '@/authenticated/components/Plan/ManualPlan/Plan'
import { getBlankPlan } from '@/authenticated/components/Plan/ManualPlan/PlansGraph'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { Plan } from '@/types'
import { useEffect, useState } from 'react'
import SetMonthlyPlanCard from '@/authenticated/components/Plan/ManualPlan/MonthPlan'
import SetWeeklyPlan from '@/authenticated/components/Plan/ManualPlan/WeekPlan'
import { clearForm, hideEditPlans } from '@/actions/manualPlans'

export interface Props {
  onBack?: () => void
  onComplete?: () => void
}

interface ContentProps {
  handleSuccess: (plans: Plan[]) => void
  onBack?: () => void
  error?: string
}

function DefineManualPlanFlowStepContent(props: ContentProps) {
  const { editNewWeekRates, form, plans } = useAppSelector(
    (state) => state.manualPlans,
  )
  const dispatch = useAppDispatch()

  useEffect(() => {
    return () => {
      dispatch(hideEditPlans())
      dispatch(clearForm())
    }
  }, [])

  if (editNewWeekRates || !form.changingMonths) {
    return (
      <SetWeeklyPlan
        plan={getBlankPlan()}
        handleSuccess={props.handleSuccess}
        errorMessage={props.error}
        elevation={0}
      />
    )
  }

  for (let i = 0; i < plans.length; i++) {
    const plan = plans[i]
    if (plan.editRates) {
      return (
        <SetWeeklyPlan
          plan={plan}
          handleSuccess={props.handleSuccess}
          errorMessage={props.error}
          elevation={0}
        />
      )
    }
  }

  return (
    <SetMonthlyPlanCard
      handleSuccess={props.handleSuccess}
      errorMessage={props.error}
    />
  )
}

export default function DefineManualPlanFlowStep(props: Props) {
  const dispatch = useAppDispatch()
  const { form } = useAppSelector((state) => state.manualPlans)
  const [validationError, setValidationError] = useState<string | undefined>()
  const error = validationError

  function handleSuccess(plans: Plan[]) {
    const validPlans = getValidPlans(plans, form.changingWeekdays)
    if (validPlans) {
      dispatch(uploadPlans(validPlans)).then(() => {
        props.onComplete?.()
      })
    } else {
      setValidationError(
        'Missing rates or months. Please try again or contact us',
      )
    }
  }

  return (
    <FlowStep id="define-manual-plan-flow" gap="0">
      <FlowStep.Content>
        <DefineManualPlanFlowStepContent
          handleSuccess={handleSuccess}
          error={error}
        />
      </FlowStep.Content>
    </FlowStep>
  )
}
