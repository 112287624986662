export function synthetic_connectTesla() {
  return {
    type: 'CONNECT_VEHICLE_SUCCESS',
    payload: [
      {
        id: 147,
        car: {
          id: 34,
          car_model: {
            id: 206,
            gas_car_conversion: null,
            created: '2024-02-12T22:33:53.358911Z',
            modified: '2024-02-12T22:33:53.363754Z',
            make: 'tesla',
            model: 'modely',
            year: '2023',
            friendly_name: 'Model Y',
            trim: 'Standard Range',
            is_default_trim: true,
            watts_per_mile: null,
            battery_capacity_kwh: null,
            drivetrain: 'bev',
          },
          image_url: '/tesla/model3/Red_Multi-Coat.webp',
          fleet_scopes: {
            selected: [
              'offline_access',
              'openid',
              'user_data',
              'vehicle_device_data',
              'vehicle_cmds',
              'vehicle_charging_cmds',
              'energy_device_data',
              'energy_cmds',
            ],
            missing: [],
          },
          created: '2023-11-29T15:53:45.554111Z',
          modified: '2024-03-04T14:18:54.282363Z',
          is_active: true,
          average_gas_price_cents: 340,
          odometer: 10503.087471,
          color: 'RedMulticoat',
          display_name: 'Firebird',
          vin: '7SAYGDEE6PF760250',
          raw_json: {
            response: {
              plg: true,
              pws: true,
              rhd: false,
              car_type: 'modely',
              seat_type: null,
              timestamp: 1709561926569,
              eu_vehicle: false,
              roof_color: 'RoofColorGlass',
              utc_offset: -18000,
              wheel_type: 'Induction20Black',
              key_version: 2,
              spoiler_type: 'None',
              trim_badging: '74d',
              badge_version: 1,
              driver_assist: 'TeslaAP3',
              exterior_trim: 'Black',
              headlamp_type: 'Global',
              aux_park_lamps: 'NaPremium',
              exterior_color: 'RedMulticoat',
              rear_seat_type: 0,
              rear_drive_unit: 'PM216MOSFET',
              third_row_seats: 'None',
              car_special_type: 'base',
              charge_port_type: 'US',
              ece_restrictions: false,
              has_seat_cooling: false,
              webcam_supported: true,
              rear_seat_heaters: 1,
              use_range_badging: true,
              can_actuate_trunks: true,
              efficiency_package: 'MY2021',
              has_air_suspension: false,
              has_ludicrous_mode: false,
              interior_trim_type: 'White2',
              sun_roof_installed: null,
              performance_package: 'Base',
              supports_qr_pairing: false,
              paint_color_override: '',
              default_charge_to_max: false,
              motorized_charge_port: true,
              exterior_trim_override: '',
              webcam_selfie_supported: true,
              cop_user_set_temp_supported: true,
              dashcam_clip_save_supported: true,
              can_accept_navigation_requests: true,
            },
          },
          permissions: [
            'R_BAT',
            'R_ODO',
            'R_CHARGE',
            'R_VIN',
            'R_INFO',
            'R_GEO',
            'W_CHARGE',
            'W_LOCK',
          ],
          tesla_id: '3744445264449383',
          tesla_vehicle_id: '2252203495412838',
          option_codes: null,
          tokens: "['cca37db0137b7e95', '7235e09d18cbaa2e']",
          in_service: false,
          id_s: '3744445264449383',
          calendar_enabled: true,
          api_version: '73',
          backseat_token: null,
          backseat_token_updated_at: null,
          account_full_name: null,
          account_phone_number: null,
          access_type: 'OWNER',
          exterior_color: 'RedMulticoat',
          public_key_not_attached_at: null,
          supports_command_signing: true,
          account_home: null,
        },
        environmental_impact: {
          odometer: 10503,
          gasoline_gallons_saved: 350,
          gas_gallon_conversion: 350,
          total_metric_impact: 51.45,
          description: 'tree seedlings grown for 10 years',
          image_file: 'seedling.png',
          source:
            'https://www.epa.gov/energy/greenhouse-gases-equivalencies-calculator-calculations-and-references#seedlings',
        },
        average_gas_price_dollars: 3.4,
        gas_price_zipcode: '98506',
        charge_limit_percent: 80,
        battery_level_percent: 79,
        miles_per_battery_percent: 3.225,
        is_charge_control_enabled: true,
        selected_gas_car_conversion: {
          id: 314,
          make: 'mini',
          model: 'Cooper S Hardtop 2 Door',
          year: '2021',
          gas_car_gas_type: 'Premium',
          gas_car_mpg: 30,
          source: 'https://www.fueleconomy.gov/feg/noframes/42565.shtml',
          cylinders: null,
          displacement: null,
        },
        authentication_inactive: false,
        requires_tesla_fleet_migration: false,
        is_optiwatt_ghost_vehicle: false,
        inactive: false,
        created: '2023-11-29T15:53:45.589023Z',
        modified: '2024-03-04T14:19:10.280896Z',
        is_selected_vehicle: true,
        last_drive_time: null,
        last_charge_time: '2023-11-29T15:53:46.809000Z',
        last_sleep_time: '2024-03-04T14:18:39.926205Z',
        not_found_date: null,
        has_hit_battery_limit_and_draining: false,
        user_deleted: false,
        requires_subscription: false,
        model_object_id: 34,
        authentication: 194,
        profile: 87,
        gas_car_conversion: null,
        model_content_type: 1,
      },
    ],
  }
}

export function synthetic_chargeForecastSuccess() {
  return {
    payload: {
      plan: {
        id: 80727,
        types: ['Flat'],
        current_season: null,
        next_season: null,
        tiers: null,
        created: '2024-03-06T18:32:14.614869Z',
        modified: '2024-03-06T18:32:16.414118Z',
        name: 'Manual Plan',
        start_date: null,
        end_date: null,
        sector: 'Residential',
        is_default: false,
        source: null,
        is_time_of_use: false,
        is_tiered: false,
        is_real_time: false,
        is_custom_plan: true,
        utility: 89071,
        openei_rate: null,
      },
      charge_forecast_periods: [
        {
          start_datetime: '2024-04-10T20:33:28.927323Z',
          end_datetime: '2024-04-10T21:00:00Z',
          electricity_price_per_kwh: 0.17,
          low_emissions: false,
          emissions_moer: 1244.62,
          percent_time_at_home: 100,
          start_standard_metric: null,
          end_standard_metric: null,
          start_cheapest_rate_metric: {
            type: 'minimized',
            miles: 0,
            kilometers: 0,
            miles_range: 147.2653385860042,
            kilometers_range: 236.99999999999997,
            battery_percent: 73,
            charge_cost_cents: 0,
            carbon_lbs: 0,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          end_cheapest_rate_metric: {
            type: 'minimized',
            miles: 0,
            kilometers: 0,
            miles_range: 147.2653385860042,
            kilometers_range: 236.99999999999997,
            battery_percent: 73,
            charge_cost_cents: 0,
            carbon_lbs: 0,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          start_smart_charge_metric: null,
          end_smart_charge_metric: null,
        },
        {
          start_datetime: '2024-04-10T21:00:00Z',
          end_datetime: '2024-04-10T22:00:00Z',
          electricity_price_per_kwh: 0.17,
          low_emissions: false,
          emissions_moer: 1250.4583333333333,
          percent_time_at_home: 100,
          start_standard_metric: null,
          end_standard_metric: null,
          start_cheapest_rate_metric: {
            type: 'minimized',
            miles: 0,
            kilometers: 0,
            miles_range: 147.2653385860042,
            kilometers_range: 236.99999999999997,
            battery_percent: 73,
            charge_cost_cents: 0,
            carbon_lbs: 0,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          end_cheapest_rate_metric: {
            type: 'minimized',
            miles: 0,
            kilometers: 0,
            miles_range: 147.2653385860042,
            kilometers_range: 236.99999999999997,
            battery_percent: 73,
            charge_cost_cents: 0,
            carbon_lbs: 0,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          start_smart_charge_metric: null,
          end_smart_charge_metric: null,
        },
        {
          start_datetime: '2024-04-10T22:00:00Z',
          end_datetime: '2024-04-10T23:00:00Z',
          electricity_price_per_kwh: 0.17,
          low_emissions: false,
          emissions_moer: 1255.3916666666667,
          percent_time_at_home: 100,
          start_standard_metric: null,
          end_standard_metric: null,
          start_cheapest_rate_metric: {
            type: 'minimized',
            miles: 0,
            kilometers: 0,
            miles_range: 147.2653385860042,
            kilometers_range: 236.99999999999997,
            battery_percent: 73,
            charge_cost_cents: 0,
            carbon_lbs: 0,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          end_cheapest_rate_metric: {
            type: 'minimized',
            miles: 0,
            kilometers: 0,
            miles_range: 147.2653385860042,
            kilometers_range: 236.99999999999997,
            battery_percent: 73,
            charge_cost_cents: 0,
            carbon_lbs: 0,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          start_smart_charge_metric: null,
          end_smart_charge_metric: null,
        },
        {
          start_datetime: '2024-04-10T23:00:00Z',
          end_datetime: '2024-04-11T00:00:00Z',
          electricity_price_per_kwh: 0.17,
          low_emissions: false,
          emissions_moer: 1242.5416666666667,
          percent_time_at_home: 100,
          start_standard_metric: null,
          end_standard_metric: null,
          start_cheapest_rate_metric: {
            type: 'minimized',
            miles: 0,
            kilometers: 0,
            miles_range: 147.2653385860042,
            kilometers_range: 236.99999999999997,
            battery_percent: 73,
            charge_cost_cents: 0,
            carbon_lbs: 0,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          end_cheapest_rate_metric: {
            type: 'minimized',
            miles: 0,
            kilometers: 0,
            miles_range: 147.2653385860042,
            kilometers_range: 236.99999999999997,
            battery_percent: 73,
            charge_cost_cents: 0,
            carbon_lbs: 0,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          start_smart_charge_metric: null,
          end_smart_charge_metric: null,
        },
        {
          start_datetime: '2024-04-11T00:00:00Z',
          end_datetime: '2024-04-11T01:00:00Z',
          electricity_price_per_kwh: 0.17,
          low_emissions: false,
          emissions_moer: 1258.5333333333333,
          percent_time_at_home: 100,
          start_standard_metric: null,
          end_standard_metric: null,
          start_cheapest_rate_metric: {
            type: 'minimized',
            miles: 0,
            kilometers: 0,
            miles_range: 147.2653385860042,
            kilometers_range: 236.99999999999997,
            battery_percent: 73,
            charge_cost_cents: 0,
            carbon_lbs: 0,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          end_cheapest_rate_metric: {
            type: 'minimized',
            miles: 0,
            kilometers: 0,
            miles_range: 147.2653385860042,
            kilometers_range: 236.99999999999997,
            battery_percent: 73,
            charge_cost_cents: 0,
            carbon_lbs: 0,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          start_smart_charge_metric: null,
          end_smart_charge_metric: null,
        },
        {
          start_datetime: '2024-04-11T01:00:00Z',
          end_datetime: '2024-04-11T02:00:00Z',
          electricity_price_per_kwh: 0.17,
          low_emissions: false,
          emissions_moer: 1235.4833333333333,
          percent_time_at_home: 100,
          start_standard_metric: null,
          end_standard_metric: null,
          start_cheapest_rate_metric: {
            type: 'minimized',
            miles: 0,
            kilometers: 0,
            miles_range: 147.2653385860042,
            kilometers_range: 236.99999999999997,
            battery_percent: 73,
            charge_cost_cents: 0,
            carbon_lbs: 0,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          end_cheapest_rate_metric: {
            type: 'minimized',
            miles: 0,
            kilometers: 0,
            miles_range: 147.2653385860042,
            kilometers_range: 236.99999999999997,
            battery_percent: 73,
            charge_cost_cents: 0,
            carbon_lbs: 0,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          start_smart_charge_metric: null,
          end_smart_charge_metric: null,
        },
        {
          start_datetime: '2024-04-11T02:00:00Z',
          end_datetime: '2024-04-11T03:00:00Z',
          electricity_price_per_kwh: 0.17,
          low_emissions: true,
          emissions_moer: 1225.5416666666667,
          percent_time_at_home: 100,
          start_standard_metric: null,
          end_standard_metric: null,
          start_cheapest_rate_metric: {
            type: 'minimized',
            miles: 0,
            kilometers: 0,
            miles_range: 147.2653385860042,
            kilometers_range: 236.99999999999997,
            battery_percent: 73,
            charge_cost_cents: 0,
            carbon_lbs: 0,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          end_cheapest_rate_metric: {
            type: 'minimized',
            miles: 0,
            kilometers: 0,
            miles_range: 147.2653385860042,
            kilometers_range: 236.99999999999997,
            battery_percent: 73,
            charge_cost_cents: 0,
            carbon_lbs: 0,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          start_smart_charge_metric: null,
          end_smart_charge_metric: null,
        },
        {
          start_datetime: '2024-04-11T03:00:00Z',
          end_datetime: '2024-04-11T04:00:00Z',
          electricity_price_per_kwh: 0.17,
          low_emissions: true,
          emissions_moer: 1207.475,
          percent_time_at_home: 100,
          start_standard_metric: null,
          end_standard_metric: null,
          start_cheapest_rate_metric: {
            type: 'minimized',
            miles: 0,
            kilometers: 0,
            miles_range: 147.2653385860042,
            kilometers_range: 236.99999999999997,
            battery_percent: 73,
            charge_cost_cents: 0,
            carbon_lbs: 0,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          end_cheapest_rate_metric: {
            type: 'minimized',
            miles: 0,
            kilometers: 0,
            miles_range: 147.2653385860042,
            kilometers_range: 236.99999999999997,
            battery_percent: 73,
            charge_cost_cents: 0,
            carbon_lbs: 0,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          start_smart_charge_metric: null,
          end_smart_charge_metric: null,
        },
        {
          start_datetime: '2024-04-11T04:00:00Z',
          end_datetime: '2024-04-11T05:00:00Z',
          electricity_price_per_kwh: 0.17,
          low_emissions: true,
          emissions_moer: 1200.0833333333333,
          percent_time_at_home: 100,
          start_standard_metric: null,
          end_standard_metric: null,
          start_cheapest_rate_metric: {
            type: 'minimized',
            miles: 0,
            kilometers: 0,
            miles_range: 147.2653385860042,
            kilometers_range: 236.99999999999997,
            battery_percent: 73,
            charge_cost_cents: 0,
            carbon_lbs: 0,
            should_charge: true,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          end_cheapest_rate_metric: {
            type: 'minimized',
            miles: 14.131109115914274,
            kilometers: 22.741759144605478,
            miles_range: 161.39644770191848,
            kilometers_range: 259.74175914460545,
            battery_percent: 79.92352404766226,
            charge_cost_cents: 90.40780052486419,
            carbon_lbs: 6.382170271365535,
            should_charge: true,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          start_smart_charge_metric: null,
          end_smart_charge_metric: null,
        },
        {
          start_datetime: '2024-04-11T05:00:00Z',
          end_datetime: '2024-04-11T06:00:00Z',
          electricity_price_per_kwh: 0.17,
          low_emissions: true,
          emissions_moer: 1207.4416666666666,
          percent_time_at_home: 100,
          start_standard_metric: null,
          end_standard_metric: null,
          start_cheapest_rate_metric: {
            type: 'minimized',
            miles: 14.131109115914274,
            kilometers: 22.741759144605478,
            miles_range: 161.39644770191848,
            kilometers_range: 259.74175914460545,
            battery_percent: 79.92352404766226,
            charge_cost_cents: 90.40780052486419,
            carbon_lbs: 6.382170271365535,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          end_cheapest_rate_metric: {
            type: 'minimized',
            miles: 14.131109115914274,
            kilometers: 22.741759144605478,
            miles_range: 161.39644770191848,
            kilometers_range: 259.74175914460545,
            battery_percent: 79.92352404766226,
            charge_cost_cents: 90.40780052486419,
            carbon_lbs: 6.382170271365535,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          start_smart_charge_metric: null,
          end_smart_charge_metric: null,
        },
        {
          start_datetime: '2024-04-11T06:00:00Z',
          end_datetime: '2024-04-11T07:00:00Z',
          electricity_price_per_kwh: 0.17,
          low_emissions: true,
          emissions_moer: 1205.55,
          percent_time_at_home: 100,
          start_standard_metric: null,
          end_standard_metric: null,
          start_cheapest_rate_metric: {
            type: 'minimized',
            miles: 14.131109115914274,
            kilometers: 22.741759144605478,
            miles_range: 161.39644770191848,
            kilometers_range: 259.74175914460545,
            battery_percent: 79.92352404766226,
            charge_cost_cents: 90.40780052486419,
            carbon_lbs: 6.382170271365535,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          end_cheapest_rate_metric: {
            type: 'minimized',
            miles: 14.131109115914274,
            kilometers: 22.741759144605478,
            miles_range: 161.39644770191848,
            kilometers_range: 259.74175914460545,
            battery_percent: 79.92352404766226,
            charge_cost_cents: 90.40780052486419,
            carbon_lbs: 6.382170271365535,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          start_smart_charge_metric: null,
          end_smart_charge_metric: null,
        },
        {
          start_datetime: '2024-04-11T07:00:00Z',
          end_datetime: '2024-04-11T08:00:00Z',
          electricity_price_per_kwh: 0.17,
          low_emissions: false,
          emissions_moer: 1204.1,
          percent_time_at_home: 100,
          start_standard_metric: null,
          end_standard_metric: null,
          start_cheapest_rate_metric: {
            type: 'minimized',
            miles: 14.131109115914274,
            kilometers: 22.741759144605478,
            miles_range: 161.39644770191848,
            kilometers_range: 259.74175914460545,
            battery_percent: 79.92352404766226,
            charge_cost_cents: 90.40780052486419,
            carbon_lbs: 6.382170271365535,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          end_cheapest_rate_metric: {
            type: 'minimized',
            miles: 14.131109115914274,
            kilometers: 22.741759144605478,
            miles_range: 161.39644770191848,
            kilometers_range: 259.74175914460545,
            battery_percent: 79.92352404766226,
            charge_cost_cents: 90.40780052486419,
            carbon_lbs: 6.382170271365535,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          start_smart_charge_metric: null,
          end_smart_charge_metric: null,
        },
        {
          start_datetime: '2024-04-11T08:00:00Z',
          end_datetime: '2024-04-11T09:00:00Z',
          electricity_price_per_kwh: 0.17,
          low_emissions: false,
          emissions_moer: 1212,
          percent_time_at_home: 100,
          start_standard_metric: null,
          end_standard_metric: null,
          start_cheapest_rate_metric: {
            type: 'minimized',
            miles: 14.131109115914274,
            kilometers: 22.741759144605478,
            miles_range: 161.39644770191848,
            kilometers_range: 259.74175914460545,
            battery_percent: 79.92352404766226,
            charge_cost_cents: 90.40780052486419,
            carbon_lbs: 6.382170271365535,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          end_cheapest_rate_metric: {
            type: 'minimized',
            miles: 14.131109115914274,
            kilometers: 22.741759144605478,
            miles_range: 161.39644770191848,
            kilometers_range: 259.74175914460545,
            battery_percent: 79.92352404766226,
            charge_cost_cents: 90.40780052486419,
            carbon_lbs: 6.382170271365535,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          start_smart_charge_metric: null,
          end_smart_charge_metric: null,
        },
        {
          start_datetime: '2024-04-11T09:00:00Z',
          end_datetime: '2024-04-11T10:00:00Z',
          electricity_price_per_kwh: 0.17,
          low_emissions: false,
          emissions_moer: 1218.9916666666666,
          percent_time_at_home: 100,
          start_standard_metric: null,
          end_standard_metric: null,
          start_cheapest_rate_metric: {
            type: 'minimized',
            miles: 14.131109115914274,
            kilometers: 22.741759144605478,
            miles_range: 161.39644770191848,
            kilometers_range: 259.74175914460545,
            battery_percent: 79.92352404766226,
            charge_cost_cents: 90.40780052486419,
            carbon_lbs: 6.382170271365535,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          end_cheapest_rate_metric: {
            type: 'minimized',
            miles: 14.131109115914274,
            kilometers: 22.741759144605478,
            miles_range: 161.39644770191848,
            kilometers_range: 259.74175914460545,
            battery_percent: 79.92352404766226,
            charge_cost_cents: 90.40780052486419,
            carbon_lbs: 6.382170271365535,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          start_smart_charge_metric: null,
          end_smart_charge_metric: null,
        },
        {
          start_datetime: '2024-04-11T10:00:00Z',
          end_datetime: '2024-04-11T11:00:00Z',
          electricity_price_per_kwh: 0.17,
          low_emissions: false,
          emissions_moer: 1229.0666666666666,
          percent_time_at_home: 100,
          start_standard_metric: null,
          end_standard_metric: null,
          start_cheapest_rate_metric: {
            type: 'minimized',
            miles: 14.131109115914274,
            kilometers: 22.741759144605478,
            miles_range: 161.39644770191848,
            kilometers_range: 259.74175914460545,
            battery_percent: 79.92352404766226,
            charge_cost_cents: 90.40780052486419,
            carbon_lbs: 6.382170271365535,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          end_cheapest_rate_metric: {
            type: 'minimized',
            miles: 14.131109115914274,
            kilometers: 22.741759144605478,
            miles_range: 161.39644770191848,
            kilometers_range: 259.74175914460545,
            battery_percent: 79.92352404766226,
            charge_cost_cents: 90.40780052486419,
            carbon_lbs: 6.382170271365535,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          start_smart_charge_metric: null,
          end_smart_charge_metric: null,
        },
        {
          start_datetime: '2024-04-11T11:00:00Z',
          end_datetime: '2024-04-11T12:00:00Z',
          electricity_price_per_kwh: 0.17,
          low_emissions: false,
          emissions_moer: 1225.9,
          percent_time_at_home: 100,
          start_standard_metric: null,
          end_standard_metric: null,
          start_cheapest_rate_metric: {
            type: 'minimized',
            miles: 14.131109115914274,
            kilometers: 22.741759144605478,
            miles_range: 161.39644770191848,
            kilometers_range: 259.74175914460545,
            battery_percent: 79.92352404766226,
            charge_cost_cents: 90.40780052486419,
            carbon_lbs: 6.382170271365535,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          end_cheapest_rate_metric: {
            type: 'minimized',
            miles: 14.131109115914274,
            kilometers: 22.741759144605478,
            miles_range: 161.39644770191848,
            kilometers_range: 259.74175914460545,
            battery_percent: 79.92352404766226,
            charge_cost_cents: 90.40780052486419,
            carbon_lbs: 6.382170271365535,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          start_smart_charge_metric: null,
          end_smart_charge_metric: null,
        },
        {
          start_datetime: '2024-04-11T12:00:00Z',
          end_datetime: '2024-04-11T12:15:00Z',
          electricity_price_per_kwh: 0.17,
          low_emissions: false,
          emissions_moer: 1224.6,
          percent_time_at_home: 100,
          start_standard_metric: null,
          end_standard_metric: null,
          start_cheapest_rate_metric: {
            type: 'minimized',
            miles: 14.131109115914274,
            kilometers: 22.741759144605478,
            miles_range: 161.39644770191848,
            kilometers_range: 259.74175914460545,
            battery_percent: 79.92352404766226,
            charge_cost_cents: 90.40780052486419,
            carbon_lbs: 6.382170271365535,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          end_cheapest_rate_metric: {
            type: 'minimized',
            miles: 14.131109115914274,
            kilometers: 22.741759144605478,
            miles_range: 161.39644770191848,
            kilometers_range: 259.74175914460545,
            battery_percent: 79.92352404766226,
            charge_cost_cents: 90.40780052486419,
            carbon_lbs: 6.382170271365535,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          start_smart_charge_metric: null,
          end_smart_charge_metric: null,
        },
        {
          start_datetime: '2024-04-11T12:15:00Z',
          end_datetime: '2024-04-11T13:00:00Z',
          electricity_price_per_kwh: 0.17,
          low_emissions: false,
          emissions_moer: 1227.7888888888888,
          percent_time_at_home: 100,
          start_standard_metric: null,
          end_standard_metric: null,
          start_cheapest_rate_metric: {
            type: 'minimized',
            miles: 14.131109115914274,
            kilometers: 22.741759144605478,
            miles_range: 161.39644770191848,
            kilometers_range: 259.74175914460545,
            battery_percent: 79.92352404766226,
            charge_cost_cents: 90.40780052486419,
            carbon_lbs: 6.382170271365535,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          end_cheapest_rate_metric: {
            type: 'minimized',
            miles: 14.131109115914274,
            kilometers: 22.741759144605478,
            miles_range: 161.39644770191848,
            kilometers_range: 259.74175914460545,
            battery_percent: 79.92352404766226,
            charge_cost_cents: 90.40780052486419,
            carbon_lbs: 6.382170271365535,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          start_smart_charge_metric: null,
          end_smart_charge_metric: null,
        },
        {
          start_datetime: '2024-04-11T13:00:00Z',
          end_datetime: '2024-04-11T14:00:00Z',
          electricity_price_per_kwh: 0.17,
          low_emissions: false,
          emissions_moer: 1235.3666666666666,
          percent_time_at_home: 100,
          start_standard_metric: null,
          end_standard_metric: null,
          start_cheapest_rate_metric: {
            type: 'minimized',
            miles: 14.131109115914274,
            kilometers: 22.741759144605478,
            miles_range: 161.39644770191848,
            kilometers_range: 259.74175914460545,
            battery_percent: 79.92352404766226,
            charge_cost_cents: 90.40780052486419,
            carbon_lbs: 6.382170271365535,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          end_cheapest_rate_metric: {
            type: 'minimized',
            miles: 14.131109115914274,
            kilometers: 22.741759144605478,
            miles_range: 161.39644770191848,
            kilometers_range: 259.74175914460545,
            battery_percent: 79.92352404766226,
            charge_cost_cents: 90.40780052486419,
            carbon_lbs: 6.382170271365535,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          start_smart_charge_metric: null,
          end_smart_charge_metric: null,
        },
        {
          start_datetime: '2024-04-11T14:00:00Z',
          end_datetime: '2024-04-11T15:00:00Z',
          electricity_price_per_kwh: 0.17,
          low_emissions: false,
          emissions_moer: 1255.7333333333333,
          percent_time_at_home: 100,
          start_standard_metric: null,
          end_standard_metric: null,
          start_cheapest_rate_metric: {
            type: 'minimized',
            miles: 14.131109115914274,
            kilometers: 22.741759144605478,
            miles_range: 161.39644770191848,
            kilometers_range: 259.74175914460545,
            battery_percent: 79.92352404766226,
            charge_cost_cents: 90.40780052486419,
            carbon_lbs: 6.382170271365535,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          end_cheapest_rate_metric: {
            type: 'minimized',
            miles: 14.131109115914274,
            kilometers: 22.741759144605478,
            miles_range: 161.39644770191848,
            kilometers_range: 259.74175914460545,
            battery_percent: 79.92352404766226,
            charge_cost_cents: 90.40780052486419,
            carbon_lbs: 6.382170271365535,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          start_smart_charge_metric: null,
          end_smart_charge_metric: null,
        },
        {
          start_datetime: '2024-04-11T15:00:00Z',
          end_datetime: '2024-04-11T16:00:00Z',
          electricity_price_per_kwh: 0.17,
          low_emissions: false,
          emissions_moer: 1266.6166666666666,
          percent_time_at_home: 100,
          start_standard_metric: null,
          end_standard_metric: null,
          start_cheapest_rate_metric: {
            type: 'minimized',
            miles: 14.131109115914274,
            kilometers: 22.741759144605478,
            miles_range: 161.39644770191848,
            kilometers_range: 259.74175914460545,
            battery_percent: 79.92352404766226,
            charge_cost_cents: 90.40780052486419,
            carbon_lbs: 6.382170271365535,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          end_cheapest_rate_metric: {
            type: 'minimized',
            miles: 14.131109115914274,
            kilometers: 22.741759144605478,
            miles_range: 161.39644770191848,
            kilometers_range: 259.74175914460545,
            battery_percent: 79.92352404766226,
            charge_cost_cents: 90.40780052486419,
            carbon_lbs: 6.382170271365535,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          start_smart_charge_metric: null,
          end_smart_charge_metric: null,
        },
        {
          start_datetime: '2024-04-11T16:00:00Z',
          end_datetime: '2024-04-11T17:00:00Z',
          electricity_price_per_kwh: 0.17,
          low_emissions: false,
          emissions_moer: 1276.2083333333333,
          percent_time_at_home: 100,
          start_standard_metric: null,
          end_standard_metric: null,
          start_cheapest_rate_metric: {
            type: 'minimized',
            miles: 14.131109115914274,
            kilometers: 22.741759144605478,
            miles_range: 161.39644770191848,
            kilometers_range: 259.74175914460545,
            battery_percent: 79.92352404766226,
            charge_cost_cents: 90.40780052486419,
            carbon_lbs: 6.382170271365535,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          end_cheapest_rate_metric: {
            type: 'minimized',
            miles: 14.131109115914274,
            kilometers: 22.741759144605478,
            miles_range: 161.39644770191848,
            kilometers_range: 259.74175914460545,
            battery_percent: 79.92352404766226,
            charge_cost_cents: 90.40780052486419,
            carbon_lbs: 6.382170271365535,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          start_smart_charge_metric: null,
          end_smart_charge_metric: null,
        },
        {
          start_datetime: '2024-04-11T17:00:00Z',
          end_datetime: '2024-04-11T18:00:00Z',
          electricity_price_per_kwh: 0.17,
          low_emissions: false,
          emissions_moer: 1272.45,
          percent_time_at_home: 100,
          start_standard_metric: null,
          end_standard_metric: null,
          start_cheapest_rate_metric: {
            type: 'minimized',
            miles: 14.131109115914274,
            kilometers: 22.741759144605478,
            miles_range: 161.39644770191848,
            kilometers_range: 259.74175914460545,
            battery_percent: 79.92352404766226,
            charge_cost_cents: 90.40780052486419,
            carbon_lbs: 6.382170271365535,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          end_cheapest_rate_metric: {
            type: 'minimized',
            miles: 14.131109115914274,
            kilometers: 22.741759144605478,
            miles_range: 161.39644770191848,
            kilometers_range: 259.74175914460545,
            battery_percent: 79.92352404766226,
            charge_cost_cents: 90.40780052486419,
            carbon_lbs: 6.382170271365535,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          start_smart_charge_metric: null,
          end_smart_charge_metric: null,
        },
        {
          start_datetime: '2024-04-11T18:00:00Z',
          end_datetime: '2024-04-11T19:00:00Z',
          electricity_price_per_kwh: 0.17,
          low_emissions: false,
          emissions_moer: 1282.2666666666667,
          percent_time_at_home: 100,
          start_standard_metric: null,
          end_standard_metric: null,
          start_cheapest_rate_metric: {
            type: 'minimized',
            miles: 14.131109115914274,
            kilometers: 22.741759144605478,
            miles_range: 161.39644770191848,
            kilometers_range: 259.74175914460545,
            battery_percent: 79.92352404766226,
            charge_cost_cents: 90.40780052486419,
            carbon_lbs: 6.382170271365535,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          end_cheapest_rate_metric: {
            type: 'minimized',
            miles: 14.131109115914274,
            kilometers: 22.741759144605478,
            miles_range: 161.39644770191848,
            kilometers_range: 259.74175914460545,
            battery_percent: 79.92352404766226,
            charge_cost_cents: 90.40780052486419,
            carbon_lbs: 6.382170271365535,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          start_smart_charge_metric: null,
          end_smart_charge_metric: null,
        },
        {
          start_datetime: '2024-04-11T19:00:00Z',
          end_datetime: '2024-04-11T20:00:00Z',
          electricity_price_per_kwh: 0.17,
          low_emissions: false,
          emissions_moer: 1299.5,
          percent_time_at_home: 100,
          start_standard_metric: null,
          end_standard_metric: null,
          start_cheapest_rate_metric: {
            type: 'minimized',
            miles: 14.131109115914274,
            kilometers: 22.741759144605478,
            miles_range: 161.39644770191848,
            kilometers_range: 259.74175914460545,
            battery_percent: 79.92352404766226,
            charge_cost_cents: 90.40780052486419,
            carbon_lbs: 6.382170271365535,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          end_cheapest_rate_metric: {
            type: 'minimized',
            miles: 14.131109115914274,
            kilometers: 22.741759144605478,
            miles_range: 161.39644770191848,
            kilometers_range: 259.74175914460545,
            battery_percent: 79.92352404766226,
            charge_cost_cents: 90.40780052486419,
            carbon_lbs: 6.382170271365535,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          start_smart_charge_metric: null,
          end_smart_charge_metric: null,
        },
        {
          start_datetime: '2024-04-11T20:00:00Z',
          end_datetime: '2024-04-11T20:33:28.927323Z',
          electricity_price_per_kwh: 0.17,
          low_emissions: false,
          emissions_moer: 1285,
          percent_time_at_home: 100,
          start_standard_metric: null,
          end_standard_metric: null,
          start_cheapest_rate_metric: {
            type: 'minimized',
            miles: 14.131109115914274,
            kilometers: 22.741759144605478,
            miles_range: 161.39644770191848,
            kilometers_range: 259.74175914460545,
            battery_percent: 79.92352404766226,
            charge_cost_cents: 90.40780052486419,
            carbon_lbs: 6.382170271365535,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          end_cheapest_rate_metric: {
            type: 'minimized',
            miles: 14.131109115914274,
            kilometers: 22.741759144605478,
            miles_range: 161.39644770191848,
            kilometers_range: 259.74175914460545,
            battery_percent: 79.92352404766226,
            charge_cost_cents: 90.40780052486419,
            carbon_lbs: 6.382170271365535,
            should_charge: false,
            should_precondition: false,
            should_precondition_climate: false,
            emissions_moer: 0,
          },
          start_smart_charge_metric: null,
          end_smart_charge_metric: null,
        },
      ],
      max_battery_target: 80,
      min_battery_target: 20,
      battery_unit: 'percent',
      selected_plan: 'green',
      comparison_plan: null,
      schedule_start_time_utc: null,
      departure_time_utc: '2024-04-11T12:15:00Z',
      timezone: 'America/New_York',
      is_rates_only: true,
      is_trip: false,
      can_charge_now: false,
      charge_now_status: 'startable',
      vehicle_id: 1671119,
      plugged_in: true,
      next_enrolled_dr_event: null,
    },
    type: '@@metric/CHARGE_FORECAST_SUCCESS',
  }
}

export function synthetic_expireToken() {
  return {
    type: 'MANUAL_EXPIRE_TOKEN',
  }
}
