import isEqual from 'lodash/isEqual'
import { createSelectorCreator, defaultMemoize } from 'reselect'

type ConstInput<T> = { wrapped: T; type: 'constant' }

export const Const = <T>(value: T): ConstInput<T> => ({
  wrapped: value,
  type: 'constant',
})

function isConstInput<T>(
  value: unknown | ConstInput<T>,
): value is ConstInput<T> {
  return (
    typeof value === 'object' &&
    value !== null &&
    'wrapped' in value &&
    'type' in value
  )
}

export const createSelector = createSelectorCreator(defaultMemoize, (a, b) => {
  if (isConstInput(a) && isConstInput(b)) {
    return isEqual(a.wrapped, b.wrapped)
  }
  return a === b
})
