import { Link } from '@mui/material'
import { Toggle, Modal, Button } from '@/components'
import { useState } from 'react'
import twMerge from '@/utils/classes/twMerge'

export type VehicleInfo = {
  name: string
  model?: string
  image: string
  link?: string
  controllable: boolean
}

type Props = {
  vehicleList: VehicleInfo[]
  onToggleChange: (checked: boolean, id: number) => void
  className?: string
  itemStyle?: string
  hideLastDivider?: boolean
}

const ControlledVehiclesList = (props: Props) => {
  const [messageOpen, setMessageOpen] = useState(false)

  return (
    <div className={props.className ?? ''}>
      {props.vehicleList.map((vehicle, index) => (
        <div
          key={index}
          className={twMerge(
            'flex border-b-[1px] border-[#0000001f] border-solid h-16 items-center',
            props.hideLastDivider && index === props.vehicleList.length - 1
              ? 'border-none'
              : '',
            props.itemStyle,
          )}
        >
          <img src={vehicle.image} alt="car-logo" className="w-[100px]" />
          <div className="flex flex-col ml-2 mr-auto">
            <div className="text-[14px] text-[#343434] font-bold leading-4 mb-1">
              {vehicle.name}
            </div>
            {vehicle.link ? (
              <Link
                onClick={() => setMessageOpen(true)}
                className="!text-[14px] !text-[#426FFB] !leading-4 !font-bold border-b-[1px] 
                    border-[#426FFB] border-solid w-fit hover:cursor-pointer"
              >
                {vehicle.link}
              </Link>
            ) : (
              <div className="text-[14px] text-[#5D5D5D] font-medium leading-4">
                {vehicle.model}
              </div>
            )}
          </div>
          <Toggle
            id={`control-toggle-${index}`}
            defaultChecked={vehicle.controllable}
            onChange={(checked) => props.onToggleChange(checked, index)}
          />
        </div>
      ))}
      <Modal
        id="control-vehicle-charger-modal"
        open={messageOpen}
        className="pt-3"
        hideCloseButton
      >
        <div className="flex flex-col">
          <h5 className="text-[18px] text=[#343434] font-bold mb-3">
            Other vehicles
          </h5>
          <div className="text-[14px] text-[#5D5D5D] font-medium mb-8">
            Other vehicles are EVs that use this charger, but are not connected
            to your Optiwatt account.
          </div>
          <Button
            id="control-vehicle-charger-modal-close-btn"
            variant="secondary"
            onClick={() => setMessageOpen(false)}
          >
            Close
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default ControlledVehiclesList
