import { RSAA } from 'redux-api-middleware'
import { withAuth } from '../reducers'
import config from '../config'

export const VEHICLES_REQUEST = '@@vehicles/VEHICLES_REQUEST'
export const VEHICLES_SUCCESS = '@@vehicles/VEHICLES_SUCCESS'
export const VEHICLES_FAILURE = '@@vehicles/VEHICLES_FAILURE'

export const CHANGE_VEHICLE_REQUEST = '@@vehicles/CHANGE_VEHICLE_REQUEST'
export const CHANGE_VEHICLE_SUCCESS = '@@vehicles/CHANGE_VEHICLE_SUCCESS'
export const CHANGE_VEHICLE_FAILURE = '@@vehicles/CHANGE_VEHICLE_FAILURE'

export const DISABLE_VEHICLE_ACTIONS_REQUEST =
  '@@vehicles/DISABLE_VEHICLE_ACTIONS_REQUEST'
export const DISABLE_VEHICLE_ACTIONS_SUCCESS =
  '@@vehicles/DISABLE_VEHICLE_ACTIONS_SUCCESS'
export const DISABLE_VEHICLE_ACTIONS_FAILURE =
  '@@vehicles/DISABLE_VEHICLE_ACTIONS_FAILURE'

export const CLEAR_VEHICLE_ERRORS = '@@vehicles/CLEAR_VEHICLE_ERRORS'

export const GET_VEHICLE_UPDATE_TESLA_PUBLIC_KEY_REQUEST =
  '@@vehicles/GET_VEHICLE_UPDATE_TESLA_PUBLIC_KEY_REQUEST'
export const GET_VEHICLE_UPDATE_TESLA_PUBLIC_KEY_SUCCESS =
  '@@vehicles/GET_VEHICLE_UPDATE_TESLA_PUBLIC_KEY_SUCCESS'
export const GET_VEHICLE_UPDATE_TESLA_PUBLIC_KEY_FAILURE =
  '@@vehicles/GET_VEHICLE_UPDATE_TESLA_PUBLIC_KEY_FAILURE'

export const Patch = {
  Request: '@vehicles/PATCH_VEHICLE_REQUEST',
  Success: '@vehicles/PATCH_VEHICLE_SUCCESS',
  Failure: '@vehicles/PATCH_VEHICLE_FAILURE',
}

function patch(vehicleId, partialVehicle) {
  return {
    [RSAA]: {
      endpoint: `${config.apiGateway.URL}/api/vehicles/${vehicleId}/`,
      method: 'PATCH',
      headers: withAuth({ 'Content-Type': 'application/json' }),
      body: JSON.stringify(partialVehicle),
      types: [Patch.Request, Patch.Success, Patch.Failure],
    },
  }
}

export const getVehicles = () => ({
  [RSAA]: {
    endpoint: `${config.apiGateway.URL}/api/vehicles/`,
    method: 'GET',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [VEHICLES_REQUEST, VEHICLES_SUCCESS, VEHICLES_FAILURE],
  },
})

export const selectVehicle = ({ payload: vehicle, optimisticOptions }) => {
  const { revertTo } = optimisticOptions || {}
  const CHANGE_VEHICLE_REQUEST_TYPE = {
    type: CHANGE_VEHICLE_REQUEST,
    payload: vehicle,
  }
  const CHANGE_VEHICLE_FAILURE_TYPE = !Boolean(optimisticOptions)
    ? CHANGE_VEHICLE_FAILURE
    : {
        type: CHANGE_VEHICLE_FAILURE,
        payload: async (action, state, res) => {
          const response = await res.json()
          return { response, previouslySelectedVehicle: revertTo }
        },
      }

  return {
    [RSAA]: {
      endpoint: `${config.apiGateway.URL}/api/vehicles/${vehicle.id}/`,
      method: 'PATCH',
      body: JSON.stringify({ is_selected_vehicle: true }),
      headers: withAuth({ 'Content-Type': 'application/json' }),
      types: [
        CHANGE_VEHICLE_REQUEST_TYPE,
        CHANGE_VEHICLE_SUCCESS,
        CHANGE_VEHICLE_FAILURE_TYPE,
      ],
    },
  }
}

export const disableVehicleActions = (vehicle_id) => ({
  [RSAA]: {
    endpoint: `${config.apiGateway.URL}/api/disable-vehicle-actions/`,
    method: 'POST',
    body: vehicle_id ? JSON.stringify({ vehicle_id }) : null,
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      {
        type: DISABLE_VEHICLE_ACTIONS_REQUEST,
        payload: vehicle_id,
      },
      DISABLE_VEHICLE_ACTIONS_SUCCESS,
      DISABLE_VEHICLE_ACTIONS_FAILURE,
    ],
  },
})

export const clearVehicleErrors = () => ({ type: CLEAR_VEHICLE_ERRORS })

export const selectGasCarConversion = (vehicleId, gasCarConversionId) =>
  patch(vehicleId, { gas_car_conversion: gasCarConversionId })

export const getVehicleWithUpdatedTeslaPublicKey = (vehicleId) => ({
  [RSAA]: {
    endpoint: `${config.apiGateway.URL}/api/vehicle/${vehicleId}/car/public_key_not_attached`,
    method: 'GET',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      GET_VEHICLE_UPDATE_TESLA_PUBLIC_KEY_REQUEST,
      GET_VEHICLE_UPDATE_TESLA_PUBLIC_KEY_SUCCESS,
      GET_VEHICLE_UPDATE_TESLA_PUBLIC_KEY_FAILURE,
    ],
  },
})
