import React, { useEffect, useState } from 'react'
import { Button, Text } from '@/components'
import { ChevronLeft, ChevronRight } from 'react-feather'
import { motion } from 'framer-motion'
import useHasRendered from '@/hooks/useHasRendered'

export type CarouselSelectorOptions = {
  disableNavWrapping?: boolean
  hideHeader?: boolean
  minWidth?: number
}

interface CarouselSelectorProps<T> {
  items: T[]
  onChange?: (selectedItem: T) => void
  className?: string
  labelProperty?: keyof T
  children?: React.ReactNode
  options?: CarouselSelectorOptions
}

export default function CarouselSelector<T>({
  items,
  onChange,
  className: containerClassName,
  labelProperty,
  children,
  options,
}: CarouselSelectorProps<T>) {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [direction, setDirection] = useState(1)
  const hasRendered = useHasRendered()

  useEffect(() => {
    setCurrentIndex(0)
    onChange?.(items?.[0])
  }, [items])

  const handlePrev = () => {
    if (options?.disableNavWrapping && currentIndex === 0) {
      return
    }

    setDirection(-1)
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex === 0 ? items.length - 1 : prevIndex - 1
      onChange?.(items?.[newIndex])
      return newIndex
    })
  }

  const handleNext = () => {
    if (options?.disableNavWrapping && currentIndex === items.length - 1) {
      return
    }

    setDirection(1)

    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex === items.length - 1 ? 0 : prevIndex + 1
      onChange?.(items?.[newIndex])
      return newIndex
    })
  }

  const getLabel = (item: T) => {
    if (labelProperty && typeof item === 'object' && item !== null) {
      return item[labelProperty] as string
    }
    return String(item)
  }

  if (!items || items.length === 0) {
    return <></>
  }

  const disablePrevious = options?.disableNavWrapping && currentIndex === 0
  const disableNext =
    options?.disableNavWrapping && currentIndex === items?.length - 1

  return (
    <>
      {!options?.hideHeader && (
        <div className={`${containerClassName} flex gap-2.5`}>
          <Button
            id="prev-button"
            variant="click-wrapper"
            className="w-auto"
            onClick={handlePrev}
            disabled={disablePrevious}
          >
            <ChevronLeft size={20} className="text-base-grey-400" />
          </Button>
          <Text
            variant="body1"
            className="text-center relative top-[1px]"
            style={{ minWidth: `${options?.minWidth ?? 70}px` }}
          >
            {getLabel(items[currentIndex])}
          </Text>
          <Button
            id="next-button"
            variant="click-wrapper"
            onClick={handleNext}
            className="w-auto"
            disabled={disableNext}
          >
            <ChevronRight size={20} className="text-base-grey-400" />
          </Button>
        </div>
      )}
      {children && (
        <div className="overflow-hidden">
          <motion.div
            key={currentIndex}
            initial={hasRendered ? { x: direction * 150, opacity: 0 } : {}}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -direction * 150, opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            {children}
          </motion.div>
        </div>
      )}
    </>
  )
}
