import React from 'react'
import { IconNames } from '@/components/Icon/Icon'
import { Icon, Sheet, Text } from '@/components'

export default function IconList(props: { children: React.ReactNode }) {
  return <ul className="flex flex-col gap-7">{props.children}</ul>
}

type IconListSheetProps = {
  text: string
  color?: string
  textColor?: string
}

export function IconListItem(props: {
  featherIcon: IconNames
  title: string
  subtitle: string
  sheetProps?: IconListSheetProps
}) {
  return (
    <li className="flex flex-row gap-4 items-start">
      <div className="bg-themed-purple-200 align-items-center justify-around rounded-[10px] flex px-2 py-2">
        <Icon
          size={20}
          name={props.featherIcon}
          className="text-themed-primary"
        />
      </div>
      <div className="flex flex-col">
        {props.title && (
          <div className="flex flex-row items-start gap-4">
            <Text variant="h3" className="mb-2">
              {props.title}
            </Text>
            {props.sheetProps && (
              <Sheet
                size="xs"
                color={props.sheetProps.color ?? 'blue-100'}
                className={`${
                  props.sheetProps.textColor
                    ? `text-[#${props.sheetProps.textColor}]`
                    : 'text-themed-primary'
                }`}
              >
                <strong>{props.sheetProps.text}</strong>
              </Sheet>
            )}
          </div>
        )}
        {props.subtitle && <Text variant="body4">{props.subtitle}</Text>}
      </div>
    </li>
  )
}
