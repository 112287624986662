export function isObjectEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false
  }
  return true
}

export function getValidUrl(urlString) {
  if (urlString == null) {
    return urlString
  }

  if (urlString.startsWith('http://') || urlString.startsWith('https://')) {
    return urlString
  } else {
    return 'http://' + urlString
  }
}

export function getNested(obj, ...args) {
  return args.reduce((obj, level) => obj && obj[level], obj)
}
