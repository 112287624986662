export default {
  hero: {
    title: 'Charge your EV the smart way',
    // title: "Tesla charging just got smarter",
    description:
      'Optiwatt uses your exact electricity rates so you can track and save when charging',
    bgImage: 'img/app-hero-bg.webp',
  },
  promo: {
    title: 'How does it work?',
    description:
      'Optiwatt automatically schedules your EV to charge during the cheapest electricity rates and tracks your exact spending',
    items: [],
  },
  aboutUs: {
    title: {
      bold: '',
      normal: '',
    },
    description:
      'Following reasons show advantages of adding AgencyCo to your lead pages, demos and checkouts',
    items: [
      {
        title: 'Clean Design',
        description: 'Increase sales by showing true dynamics of your website',
        icon: 'ti-vector text-white',
      },
      {
        title: 'Secure Data',
        description:
          'Build your online store’s trust using Social Proof & Urgency',
        icon: 'ti-lock text-white',
      },
      {
        title: 'Retina Ready',
        description:
          'Realize importance of social proof in customer’s purchase decision',
        icon: 'ti-eye text-white',
      },
    ],
  },
  price: {
    title: 'Pricing Packages',
    description:
      "Uniquely maintain efficient expertise whereas cross functional deliverables. Continually leverage other's competitive mind share whereas enabled channels. ",
    packages: [
      {
        price: '$29',
        license: 'Basic',
        isFeatured: false,
        features: [
          'Push Notifications',
          'Data Transfer',
          'SQL Database',
          'Search & SEO Analytics',
          '24/7 Phone Support',
          '2 months technical support',
          '2+ profitable keyword',
        ],
      },
      {
        price: '$50',
        license: 'Popular',
        isFeatured: true,
        features: [
          'Push Notifications',
          'Data Transfer',
          'SQL Database',
          'Search & SEO Analytics',
          '24/7 Phone Support',
          '6 months technical support',
          '30+ profitable keyword',
        ],
      },
      {
        price: '$120',
        license: 'Extended',
        isFeatured: false,
        features: [
          'Push Notifications',
          'Data Transfer',
          'SQL Database',
          'Search & SEO Analytics',
          '24/7 Phone Support',
          '1 year technical support',
          '70+ profitable keyword',
        ],
      },
    ],
  },
  testimonial: {
    title: 'What customer say about us',
    description:
      'Rapidiously morph transparent internal or "organic" sources whereas resource sucking e-business. Conveniently innovate compelling internal.',
    comments: [
      {
        name: 'Dayne',
        company: '8/19/2020',
        image: 'img/google.webp',
        comment:
          "What a great app, I love it! This is my 2nd Tesla, and I'm glad to have what you provide detailed - I live at a condo, and eventually will need proof of how much in electricity I need to pay the association per month - this is EXCELLENT!",
      },
      {
        name: 'u/Nathan_3518',
        company: '9/8/2020',
        image: '/img/reddit.webp',
        comment:
          'Such an amazing idea, and thoughtful execution. The app looks elegant, and the UI looks simple-as-ever to use.',
      },
      {
        name: 'TeslaJoy',
        company: '8/12/2020',
        image: 'img/facebook.webp',
        comment:
          'I really like the app. Clean and intuitive interface and great features.',
      },
      {
        name: 'u/grumpyveteran',
        company: '9/8/2020',
        image: 'img/reddit.webp',
        comment:
          'Looks great, this is exactly what I’ve been wanting. I use 120v so it’s not always done charging before the higher electricity rates kick in and I don’t like using scheduled departure because I don’t always leave the house.',
      },
      {
        name: 'Vin',
        company: '8/19/2020',
        image: 'img/google.webp',
        comment:
          "Love the app as I'm a new Tesla owner and it gives me insight into my spending on recharging.",
      },
      {
        name: 'u/Crscynth',
        company: '9/8/2020',
        image: 'img/reddit.webp',
        comment: 'On behalf of a planet on fire - I say, "Thank you!"',
      },
      {
        name: 'u/LookmomImarocker',
        company: '9/8/2020',
        image: 'img/reddit.webp',
        comment:
          "First off, AMAZING! We just moved into our new home and I realized how frustrating the scheduling feature of Tesla's interface is!!",
      },
      {
        name: 'u/sound_byte',
        company: '9/8/2020',
        image: 'img/reddit.webp',
        comment:
          "Thanks! I've been wanting scheduled charging (start/stop) since I became a Tesla owner. Had it on my first EV (Chevy Spark) and have really missed it.",
      },
      {
        name: 'u/dammitLeb',
        company: '5/7/2020',
        image: 'img/reddit.webp',
        comment:
          'I love it, it shows a lifetime costs and can drill down to daily charge costs. It also shows the gas savings as compared to a similar ICE vehicle.',
      },
    ],
  },
  contact: {
    title: 'Contact us',
    description: 'Have a suggestion or feedback?',

    office: 'Head Office',
    address: 'Santa Barbara, California',
    email: 'support@getoptiwatt.com',
  },
  trustedCompany: [
    'img/client-1-color.webp',
    'img/client-6-color.webp',
    'img/client-3-color.webp',
    'img/client-4-color.webp',
    'img/client-5-color.webp',
  ],
  trustedCompanyGray: [
    'img/client-1-gray.webp',
    'img/client-2-gray.webp',
    'img/client-3-gray.webp',
    'img/client-4-gray.webp',
    'img/client-5-gray.webp',
  ],
  trustedCompanyAboutUs: {
    title: 'Trusted by companies',
    description:
      'Rapidiously morph transparent internal or "organic" sources whereas resource sucking e-business. Conveniently innovate compelling internal.',
    companies: [
      'img/client-5-color.webp',
      'img/client-1-color.webp',
      'img/client-6-color.webp',
      'img/client-3-color.webp',
      'img/client-4-color.webp',
      'img/client-5-color.webp',
      'img/client-7-color.webp',
      'img/client-2-color.webp',
      'img/client-1-color.webp',
      'img/client-3-color.webp',
    ],
  },
  teamMember: {
    members: [
      {
        name: 'Edna Mason',
        designation: 'Senior Designer',
        image: 'img/team-4.webp',
      },
      {
        name: 'Arabella Ora',
        designation: 'Senior Designer',
        image: 'img/team-1.webp',
      },
      {
        name: 'John Charles',
        designation: 'Senior Designer',
        image: 'img/team-2.webp',
      },
      {
        name: 'Jeremy Jere',
        designation: 'Senior Designer',
        image: 'img/team-3.webp',
      },
    ],
  },
  video: {
    link: 'https://www.youtube.com/watch?v=9No-FiEInLA',
  },
}
