const months = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
}

export function getMonthsRange(startMonth, endMonth) {
  if (!months.hasOwnProperty(startMonth) || !months.hasOwnProperty(endMonth)) {
    console.log(
      'Unknown startMonth: ' + startMonth + ' or endMonth: ' + endMonth,
    )
  }

  // Months are inclusive, add + 1
  return getRange(
    months[startMonth],
    months[endMonth] + 1,
    Object.keys(months).length,
  )
}

export function convertMonthToNumber(month) {
  return months[month]
}

export function convertNumberToMonth(monthNumber) {
  return Object.keys(months).find((key) => months[key] === monthNumber)
}

export function getHoursRange(startHour, endHour) {
  const hours = {
    '00:00': 0,
    '01:00': 1,
    '02:00': 2,
    '03:00': 3,
    '04:00': 4,
    '05:00': 5,
    '06:00': 6,
    '07:00': 7,
    '08:00': 8,
    '09:00': 9,
    '10:00': 10,
    '11:00': 11,
    '12:00': 12,
    '13:00': 13,
    '14:00': 14,
    '15:00': 15,
    '16:00': 16,
    '17:00': 17,
    '18:00': 18,
    '19:00': 19,
    '20:00': 20,
    '21:00': 21,
    '22:00': 22,
    '23:00': 23,
  }

  return getRange(hours[startHour], hours[endHour], Object.keys(hours).length)
}

export function getRange(start, end, length) {
  if (start === null || end === null) {
    return []
  }

  if (start < end) {
    return createRange(start, end - start)
  } else if (end === 0) {
    end = length
    return createRange(start, end - start)
  } else {
    // If endHour < startHour, i.e 2am and 11 pm, wrap around the day.
    return createRange(start, length - start).concat(createRange(0, end))
  }
}

function createRange(start, count) {
  return Array.apply(0, Array(count)).map((element, index) => index + start)
}
