import { useState } from 'react'
import dayjs from 'dayjs'

import { setCurrentUserBillingCycleStart } from '../../actions/user'
import { useDispatch, useSelector } from 'react-redux'

const useBillingStartDay = () => {
  const startDayStr = useSelector(
    (state) => state.user.user?.profile?.billing_cycle_start_day,
  )
  return startDayStr ? dayjs(startDayStr).date() : null
}

export default function useOptimisticBillingStartDay() {
  const dispatch = useDispatch()
  const fetchedBillingStartDay = useBillingStartDay()
  const [localBillingStartDay, setLocalBillingStartDay] = useState(
    fetchedBillingStartDay,
  )

  const [onBillingStartDayReturn, _setOnBillingStartDayReturn] = useState(
    () => () => {},
  )
  const setOnBillingStartDayReturn = (callback) => {
    _setOnBillingStartDayReturn(() => callback)
  }

  // setBillingStartDay assumes day is <= 28
  const setBillingStartDay = (day) => {
    setLocalBillingStartDay(day)

    // can pick any month other than 1 or 12 to avoid changing years.
    const date = dayjs().set('month', 5).set('date', day)
    const dayDate = date.startOf('day')
    dispatch(setCurrentUserBillingCycleStart(dayDate)).then(
      onBillingStartDayReturn,
    )
  }

  const billingStartDay =
    localBillingStartDay !== fetchedBillingStartDay
      ? localBillingStartDay
      : fetchedBillingStartDay
  const isLoading = localBillingStartDay !== fetchedBillingStartDay

  const lastBillingStart =
    billingStartDay > dayjs().date()
      ? dayjs().subtract(1, 'month').set('date', billingStartDay)
      : dayjs().set('date', billingStartDay)

  const billingCycleProgress = billingStartDay
    ? dayjs().diff(lastBillingStart, 'days')
    : 'an unknown amount of'

  return {
    billingStartDay,
    isLoading,
    setBillingStartDay,
    billingCycleProgress,
    setOnBillingStartDayReturn,
  }
}
