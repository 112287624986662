import { useEffect } from 'react'

/**
 * This component's full job is to make tesla testing on local more smooth
 * It assumes your .env on the backend has
 * TESLA_FLEET_REDIRECT_URI=https://staging.optiwatt.com/tesla-authentication-local
 */
export default function StagingToLocalTeslaRedirect() {
  // Redirect to localhost:3000/tesla-authentication with all the existing params
  useEffect(() => {
    const url = new URL(window.location.href)
    const redirectUrl = `https://localhost:3000/tesla-authentication${url.search}`
    window.location.href = redirectUrl
  }, [])

  return null
}
