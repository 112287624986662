export default {
  id: 1,
  created: '2020-01-01T00:00:00Z',
  modified: '2020-01-01T00:00:00Z',
  name: 'SMUD-Pilot',
  total_incentives_usd: 150,
  logo_url:
    'https://production-optiwatt-cms.s3.us-east-1.amazonaws.com/41e30bcb-eecd-44ef-8608-e82f3b98e651.png',
  tos_url: 's3-url/to/tos.pdf',
  faq_url: 's3-url/to/faq.pdf',
  unenrolled_survey_url: 'url/to/unenrolled/survey',
  utility_ids: [88811],
  view_config_json: {
    referral: {
      referralCodes: ['smud'],
      programLogoUrl: '/img/smud.svg',
      title: 'SMUD',
      subtitle: 'Save on EV Charging',
      description:
        'Optiwatt and SMUD are bringing you a new program to support the grid. Receive a $150 reward after enrollment and $20 per quarter during the pilot.',
    },
    learnMore: {
      title: 'A better EV plan just arrived',
      subtitle:
        'Optiwatt and FortisAlberta are bringing you a better way to save on EV charging',
      buttonTitle: 'Learn More',
    },
    scheduler: {
      title: 'Be a Watt Hero 🦸',
      description:
        'Enrol and receive a $50 payment now and a $100 payment after staying enrolled for three months',
      enrolledAlert:
        'We are working with your utility to improve EV charging, more features coming soon!',
      disenrollConfirmation: {
        title: 'Are you sure you want to disenrol?',
        description:
          'By disenroling from this program you will not receive your $100 incentive.',
        primaryButton: {
          text: 'Disennrol',
        },
      },
    },
    onboarding: {
      excludeHeaderAnimation: true,
      title: 'Save on EV charging',
      subtitle:
        'Optiwatt and FortisAlberta are bringing you a new program to support the grid',
      primaryButton: {
        backgroundColor: '#002855',
        color: 'white',
        text: 'Enrol',
      },
      enrollConfirmation: {
        title: 'Congratulations, your spot is available!',
        description:
          'By clicking Accept, you agree to the <a href="https://optiwatt.com/tos" target="_blank">Terms and Conditions</a> of the FortisAlberta EV Smart Charging Pilot.',
        primaryButton: {
          backgroundColor: '#002855',
          color: 'white',
          text: 'Accept',
        },
      },
      footer:
        'Please visit our <a href="https://optiwatt.com/faq/fortisalberta" target="_blank">FAQ</a> page if you would like to learn more about this program',
      programName: 'FortisAlberta',
      informationList: [
        {
          title: 'Help with grid readiness',
          subtitle:
            'Help with grid planning by delaying charging during short periods of high electricity demand',
          featherIcon: 'Heart',
          iconBackgroundColor: '#0085ca0d',
          iconColor: '#0085CA',
        },
        {
          title: 'Stay in control',
          subtitle:
            "Your rates won't change and you can charge your car anytime or opt out entirely with one click",
          featherIcon: 'Shield',
          iconBackgroundColor: '#0085ca0d',
          iconColor: '#0085CA',
        },
        {
          tag: 'LIMITED SPOTS',
          tagBackgroundColor: '#F2F9FC',
          tagColor: '#0085CA',
          title: 'Save $150',
          subtitle:
            'Receive a $50 reward after enrolment and a $100 reward at the end of the pilot after completing the final customer survey',
          featherIcon: 'Gift',
          iconBackgroundColor: '#0085ca0d',
          iconColor: '#0085CA',
        },
      ],
    },
    enrolledFunctionality: {
      charging: {
        schedule: {
          ALL: { hide: true },
        },
      },
      onboarding: {
        ALL: { hide: true },
      },
    },
  },
}
