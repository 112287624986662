import React from 'react'

import Bee from './Bee'
import VehicleLanding from '../VehicleLanding'
import { useUnsetBodyOverflow } from '@/app/hooks'

// Paths that will be directed to this component
// The following paths are used to track conversion on various platforms
const urlPaths = [
  '/',
  '/start',
  '/charge',
  '/tesla',
  '/reddit',
  '/register',
  '/twitter',
  '/save',
  '/ava',
]

export const path = `(${urlPaths.join('|')})`

export interface Props {
  activeReferral: 'utilityProgram' | 'bee' | null
  dismissReferral: () => void
  signUpPath: string
}

export default function Landing(props: Props) {
  useUnsetBodyOverflow('auto')

  return (
    <React.Fragment>
      {props.activeReferral === 'bee' && <Bee />}
      <VehicleLanding
        utilityProgramReferralActive={props.activeReferral === 'utilityProgram'}
        beeActive={props.activeReferral === 'bee'}
        dismissReferral={props.dismissReferral}
        signUpPath={props.signUpPath}
      />
    </React.Fragment>
  )
}
