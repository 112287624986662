import './styles.scss'
import { Bar, Grid, GridItem, Text } from '../../../../components'
import formatClasses from '../../../../utils/classes/formatClasses'
import { ThermostatDailySchedule } from '../../../../types/thermostatSchedule'
import { toShortTimeString } from '../../../../utils/date'
import { ThermostatComfortSetting } from '../../../../types/thermostatComfortSetting'
import dayjs from 'dayjs'

export interface Props {
  schedule: ThermostatDailySchedule[]
  settings: ThermostatComfortSetting[]
}

interface ScheduleItemProps {
  end: string
  index: number
  length: number
  name: string
  start: string
}

function ScheduleItem(props: ScheduleItemProps) {
  const classes = formatClasses(['schedule-item'])

  return (
    <Grid className={classes} flow="row" gap="24px">
      <GridItem placeSelf="center">
        <span>{props.name}</span>
      </GridItem>
      {props.index === 0 && (
        <Grid placeContent="space-between">
          <span>{props.start}</span>
          <span data-marker>{props.end}</span>
        </Grid>
      )}
      {props.index > 0 && (
        <GridItem placeSelf="end">
          <span
            data-marker={props.index + 1 !== props.length ? true : undefined}
          >
            {props.end}
          </span>
        </GridItem>
      )}
    </Grid>
  )
}

export default function HomeScheduleBar(props: Props) {
  const classes = formatClasses(['home-schedule-bar'])

  const label = () => {
    const days = [
      'Mondays',
      'Tuesdays',
      'Wednesdays',
      'Thursdays',
      'Fridays',
      'Saturdays',
      'Sundays',
    ]

    // Convert each `date` among days in the schedule to the corresponding day of the week
    const schedule = [
      ...new Set(
        props.schedule
          .map(({ date }) => {
            const day = dayjs(String(date)).day() - 1

            return day >= 0 ? day : 6
          })
          .sort()
          .map((index) => days[index]),
      ),
    ].join(', ')

    // Mondays ... Sundays --> Every day
    if (schedule === days.join(', ')) return 'Every day'
    // Saturdays, Sundays --> Weekends
    else if (schedule === days.slice(5).join(', ')) return 'Weekends'
    // Mondays ... Fridays --> Weekdays
    else if (schedule === days.slice(0, 5).join(', ')) return 'Weekdays'
    else return schedule
  }

  const name = (id: number) => {
    const setting = props.settings.find((item) => item.id === id)

    return setting?.name || ''
  }

  return (
    <Grid flow="row" gap="24px">
      <Text>{label()}</Text>
      {/*<Grid placeContent="space-around">*/}
      {/*  {*/}
      {/*    props.schedule[0].schedule.map((event, index) => {*/}
      {/*      return (*/}
      {/*        <Text variant="body2">*/}
      {/*          { name(event.comfort_setting) }*/}
      {/*        </Text>*/}
      {/*      )*/}
      {/*    })*/}
      {/*  }*/}
      {/*</Grid>*/}
      {/*<Grid placeContent="space-between">*/}
      {/*  {*/}
      {/*    props.schedule[0].schedule.map((event, index) => {*/}
      {/*      return (*/}
      {/*        <>*/}
      {/*          { index === 0 && (*/}
      {/*            <Text variant="body2">*/}
      {/*              {toShortTimeString(event.start)}*/}
      {/*            </Text>*/}
      {/*          )*/}
      {/*          }*/}

      {/*          <span data-marker>*/}
      {/*            {toShortTimeString(event.end)}*/}
      {/*          </span>*/}

      {/*        </>*/}
      {/*      )*/}
      {/*    })*/}
      {/*  }*/}
      {/*</Grid>*/}
      <Bar className={classes}>
        {props.schedule[0].schedule.map((event, index) => {
          return (
            <ScheduleItem
              end={toShortTimeString(event.end)}
              key={index}
              index={index}
              length={props.schedule[0].schedule.length}
              name={name(event.comfort_setting)}
              start={toShortTimeString(event.start)}
            />
          )
        })}
      </Bar>
    </Grid>
    // <Flex
    //   container
    //   direction="column"
    //   rowSpacing={6}
    // >
    //   <Flex item>
    //     <Text>
    //       { label() }
    //     </Text>
    //   </Flex>
    //   <Flex item>
    //     <Bar className={classes}>
    //       {
    //         props.schedule[0].schedule.map((event, index) => {
    //           return (
    //             <ScheduleItem
    //               end={toShortTimeString(event.end)}
    //               key={index}
    //               name={name(event.comfort_setting)}
    //               start={toShortTimeString(event.start)}
    //             />
    //           )
    //         })
    //       }
    //     </Bar>
    //   </Flex>
    // </Flex>
  )
}
