import FeedbackSelectDialog from '@/authenticated/components/FeedbackSelectDialog'
import FeedbackDialog from '@/authenticated/components/feedback'
import SurveyObserver from '@/app/components/SurveyObserver'
import { SurveyViewType } from '@/utils/survey-builder/surveyBuilder'
import useViewModel, {
  FeedbackMessageSurveyProps,
} from '@/components/FeedbackMessageSurvey/useViewModel'

export default (props: FeedbackMessageSurveyProps) => {
  const viewModel = useViewModel(props)

  if (!viewModel.currentSurvey) {
    return <></>
  }

  let survey = <></>
  switch (viewModel.currentSurvey.type) {
    case SurveyViewType.List:
      survey = (
        <FeedbackSelectDialog
          open={props.open}
          onClose={props.onClose}
          onSubmit={viewModel.selectReason}
          feedbackInquiry={
            viewModel.currentSurvey.prompt ?? 'What problem are you facing?'
          }
          reasons={viewModel.currentSurvey.reasons}
        />
      )
      break

    case SurveyViewType.Dialog:
      survey = (
        <FeedbackDialog
          id={'survey-dialog-' + viewModel.surveyIdx}
          isOpen={props.open}
          prompt={
            viewModel.currentSurvey.prompt ??
            'What other ways do you think we could improve?'
          }
          title={viewModel.currentSurvey.title ?? 'Submit Feedback'}
          submitText={viewModel.currentSurvey.submitText ?? 'Submit'}
          onChanges={viewModel.setMessage}
          onSubmit={viewModel.progressSurvey}
          onClose={viewModel.closeSurvey}
        />
      )
      break
  }

  return (
    <SurveyObserver observedSurveys={[props.surveyType]}>
      {survey}
    </SurveyObserver>
  )
}
