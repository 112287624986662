import { createSelector } from '@reduxjs/toolkit'
import { thermostatsCollection } from '../reducers/thermostats'
import { ThermostatAdjustment } from '../types/thermostatAdjustment'

const selectLatestAdjustmentForThermostat = createSelector(
  [
    (state, thermostatId) =>
      thermostatsCollection.selectors.selectById(state, thermostatId)
        ?.adjustments,
  ],
  (adjustments) => {
    if (Array.isArray(adjustments) && adjustments.length === 0) {
      // return null, signaling that adjustments have been fetched but there are none
      return null
    }

    const thermostatAdjustments = [...(adjustments ?? [])].sort(
      (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime(),
    )

    return thermostatAdjustments[0] as ThermostatAdjustment | undefined
  },
)

export default selectLatestAdjustmentForThermostat
