import { RSAA, createAction } from 'redux-api-middleware'
import { withAuth } from '../reducers'
import config from '../config'
import { logEvent } from '@/logging'
import { Vehicle } from '@/types'
import { vehicleIsNew } from '@/types/vehicle'
import { FordAuthentication } from '@/reducers/authenticateFord'

export const CONNECT_VEHICLE_REQUEST = 'CONNECT_VEHICLE_REQUEST'
export const CONNECT_VEHICLE_SUCCESS = 'CONNECT_VEHICLE_SUCCESS'
export const CONNECT_VEHICLE_FAILURE = 'CONNECT_VEHICLE_FAILURE'

export const SET_CONNECT_CONFIRMATION_PENDING =
  'SET_CONNECT_CONFIRMATION_PENDING'

export const MULTIFACTOR_VEHICLE_REQUEST = 'MULTIFACTOR_VEHICLE_REQUEST'
export const MULTIFACTOR_VEHICLE_SUCCESS = 'MULTIFACTOR_VEHICLE_SUCCESS'
export const MULTIFACTOR_VEHICLE_FAILURE = 'MULTIFACTOR_VEHICLE_FAILURE'

export const DELETE_VEHICLE_REQUEST = 'DELETE_VEHICLE_REQUEST'
export const DELETE_VEHICLE_SUCCESS = 'DELETE_VEHICLE_SUCCESS'
export const DELETE_VEHICLE_FAILURE = 'DELETE_VEHICLE_FAILURE'
export const DELETE_VEHICLE_CLEAR_ERRORS = 'DELETE_VEHICLE_CLEAR_ERRORS'

export const CAPTCHA_DATA_REQUEST = 'CAPTCHA_DATA_REQUEST'
export const CAPTCHA_DATA_SUCCESS = 'CAPTCHA_DATA_SUCCESS'
export const CAPTCHA_DATA_FAILURE = 'CAPTCHA_DATA_FAILURE'

export const RESET_VEHICLE_AUTH_ERRORS = 'RESET_VEHICLE_AUTH_ERRORS'

export const resetVehicleAuthErrors = () => ({
  type: RESET_VEHICLE_AUTH_ERRORS,
})

export const getTeslaCaptcha = () => ({
  [RSAA]: {
    endpoint: config.apiGateway.URL + '/api/vehicle_captcha/',
    method: 'GET',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [CAPTCHA_DATA_REQUEST, CAPTCHA_DATA_SUCCESS, CAPTCHA_DATA_FAILURE],
  },
})

export const submitConnectTeslaForm = (teslaData: {
  email: string
  password: string
}) => ({
  [RSAA]: {
    endpoint: config.apiGateway.URL + '/api/vehicle_authentication/',
    method: 'POST',
    body: JSON.stringify(teslaData),
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      CONNECT_VEHICLE_REQUEST,
      CONNECT_VEHICLE_SUCCESS,
      CONNECT_VEHICLE_FAILURE,
    ],
  },
})

export function submitConnectSmartcar(code: string | null) {
  return async (dispatch: any, getState: unknown) => {
    const actionResponse = await dispatch(
      createAction({
        endpoint: config.apiGateway.URL + '/api/vehicle_authentication/',
        method: 'POST',
        body: JSON.stringify({ smartcar_code: code }),
        headers: withAuth({ 'Content-Type': 'application/json' }),
        types: [
          CONNECT_VEHICLE_REQUEST,
          CONNECT_VEHICLE_SUCCESS,
          CONNECT_VEHICLE_FAILURE,
        ],
      }),
    )
    if (actionResponse.type === CONNECT_VEHICLE_SUCCESS) {
      const vehicles = (actionResponse.payload ?? []) as Vehicle[]
      const newVehicleCount = vehicles.filter((v) => vehicleIsNew(v)).length
      logEvent('addTesla', { metadata: { vehicle_count: newVehicleCount } })
      logEvent('add_vehicle', { count: newVehicleCount })
    }
    return actionResponse
  }
}

export const setVehicleConnectionConfirmationPending = (
  isPendingData: unknown,
) => ({
  type: SET_CONNECT_CONFIRMATION_PENDING,
  payload: isPendingData,
})

export const submitMultiFactorAuthenticationForm = (mfaData: unknown) => ({
  [RSAA]: {
    endpoint: config.apiGateway.URL + '/api/vehicle_authentication/',
    method: 'POST',
    body: JSON.stringify(mfaData),
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      MULTIFACTOR_VEHICLE_REQUEST,
      MULTIFACTOR_VEHICLE_SUCCESS,
      MULTIFACTOR_VEHICLE_FAILURE,
    ],
  },
})

export const submitDeleteVehicle = (vehicle: Vehicle) => ({
  [RSAA]: {
    endpoint: config.apiGateway.URL + `/api/vehicles/${vehicle.id}/`,
    method: 'DELETE',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      {
        type: DELETE_VEHICLE_REQUEST,
        payload: vehicle,
      },
      DELETE_VEHICLE_SUCCESS,
      {
        type: DELETE_VEHICLE_FAILURE,
        payload: async (action: unknown, state: unknown, res: any) => {
          const response = await res.json()
          return { response, vehicle: vehicle }
        },
      },
    ],
  },
})

export const clearDeleteVehicleErrors = () => ({
  type: DELETE_VEHICLE_CLEAR_ERRORS,
})

export const connectExternalVehicle = (payload: FordAuthentication) => {
  return { type: CONNECT_VEHICLE_SUCCESS, payload: payload.vehicles }
}
