import { useWizard, Wizard } from 'react-use-wizard'
import UtilityProgramEnrollmentPromptCard from '../UtilityProgramEnrollmentPromptCard'
import UtilityProgramEligibleDeviceEnrollmentFlowStep from '../UtilityProgramEligibleDeviceEnrollmentFlowStep'
import UtilityProgramSigninStep from '../UtilityProgramEnrollmentFlowSigninStep'
import ViewModel from './useViewModel'
import { Redirect } from 'react-router-dom'
import { remoteConfig } from '@/firebase'

export type Props = ReturnType<typeof ViewModel>

function EnrollmentPromptStep({ ...props }: Props) {
  const { nextStep } = useWizard()
  const { utility, onSkip, onClose, onBack, ...cardProps } = props
  return (
    <UtilityProgramEnrollmentPromptCard
      onCloseClick={onClose}
      onBackClick={onBack}
      onSkipClick={onSkip}
      onPrimaryClick={nextStep}
      style={{
        padding: 0,
      }}
      {...cardProps}
    />
  )
}

function EligibleDeviceEnrollmentStep(props: Props) {
  const { previousStep, nextStep } = useWizard()

  const avaId = remoteConfig?.getWhitelabelId('ava')

  // Ava-specific redirect to flow that accepts passive vehicles.
  // This is in-lieu of DEV-2979
  if (props.utilityProgram && props.utilityProgram.id === avaId) {
    return <Redirect to={`/ava/program-enrollment/${avaId}/enroll`} />
  }

  return (
    <UtilityProgramEligibleDeviceEnrollmentFlowStep
      {...props}
      onBack={previousStep}
      onRedirectUrl={nextStep}
    />
  )
}

export default function UtilityProgramEnrollmentFlow(props: Props) {
  return (
    <div style={{ height: '100%' }}>
      <Wizard>
        <EnrollmentPromptStep {...props} />
        <EligibleDeviceEnrollmentStep {...props} />
        <UtilityProgramSigninStep {...props} />
      </Wizard>
    </div>
  )
}
