import React, { useState } from 'react'
import { BaselineShiftingEvent } from '../../../types/baselineShiftingEvent'
import theme from '../../../v2/theme'
import { ThemeProvider } from '@material-ui/core/styles'
import BaselineShiftingEventDialog from './BaselineShiftingEventDialog'
import data from './resources/data.json'
import dayjs from 'dayjs'
import { AlertMessage } from '@/app/components'
import { Text } from '@/components'

export interface Props {
  events?: BaselineShiftingEvent[]
}

export default ({ events }: Props) => {
  const [open, setOpen] = useState(false)

  const eventIsOccurringNow = (() => {
    const now = dayjs()
    return events?.some((event) => {
      const start = dayjs(event.start_time as string)
      const end = dayjs(event.end_time as string)
      return now.isBetween(start, end)
    })
  })()

  return (
    <>
      <AlertMessage variant="info">
        <Text onClick={() => setOpen(true)} variant="link">
          {data.forecastLinkOcurringSoon}
        </Text>
      </AlertMessage>
      <ThemeProvider theme={theme}>
        <BaselineShiftingEventDialog
          eventIsOccurringNow={eventIsOccurringNow ?? false}
          open={open}
          onClose={() => setOpen(false)}
        />
      </ThemeProvider>
    </>
  )
}
