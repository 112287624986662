import { LearnMoreCard, LearnMoreCardProps } from '@/app/components'
import useViewModel from './useViewModel'
import { ViewModelComponentProps } from '../../../../utils/typeUtilities'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from '../../../../v2/theme'
import { UtilityProgram } from '@/types/utilityProgram'

type Props = ViewModelComponentProps<LearnMoreCardProps, typeof useViewModel>

export default function UtilityProgramLearnMoreCard(props: Props) {
  const viewModel = useViewModel({
    forcedUtilityProgram: props.utilityProgram,
  })
  return (
    <ThemeProvider theme={theme}>
      <LearnMoreCard {...viewModel} {...props} />
    </ThemeProvider>
  )
}
