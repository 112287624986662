import { UtilityProgramViewConfig } from '@/types/utilityProgram'
import useRemoteConfig from '@/hooks/useRemoteConfig'

const DEFAULT_PLAY_STORE =
  'https://play.google.com/store/apps/details?id=com.getoptiwatt.optiwatt'
const DEFAULT_APP_STORE =
  'https://apps.apple.com/us/app/optiwatt/id1536047033?mt=8'

export default function useStoreLinks(viewConfig?: UtilityProgramViewConfig) {
  const { isLoading, appRemoteConfig } = useRemoteConfig()
  const androidUrl =
    viewConfig?.customPlayStoreUrl ||
    appRemoteConfig?.getString('play_store_link') ||
    DEFAULT_PLAY_STORE
  const appleUrl =
    viewConfig?.customAppStoreUrl ||
    appRemoteConfig?.getString('app_store_link') ||
    DEFAULT_APP_STORE

  return { isLoading, androidUrl, appleUrl }
}
