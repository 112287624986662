import { useCallback } from 'react'
import { isNativeMobile } from '@/authenticated/hooks/useIsMobile'
import { HTMLAttributeAnchorTarget } from 'react'
import { isSafari } from 'react-device-detect'

export default function useExternalNavigation() {
  const externallyNavigate = useCallback(
    (url: string, target: HTMLAttributeAnchorTarget = '_blank') => {
      if (isNativeMobile() || isSafari) {
        window.location.href = url
      } else {
        window.open(url, target)
      }
    },
    [],
  )

  return { externallyNavigate }
}
