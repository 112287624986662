import { AlertState, Device } from '../DeviceSelectionGallery.types'

export type SortableDevice = {
  type: Device['type']
  alertState: {
    mode: AlertState['mode']
  }
}

export function sortDevices<D extends SortableDevice>(devices: D[]): D[] {
  // order by alertState.mode, then by device type within the same alertState.mode
  // where alertState.mode priority is 'error' > 'warning' > 'info' > 'none'
  // where device type priority is 'vehicle' > 'vehicleCharger' > 'thermostat' > 'home'
  return devices.sort((a, b) => {
    // Sort by alertState.mode
    const modePriority = {
      error: 4,
      warning: 3,
      info: 2,
      none: 1,
    }
    const modeA = a.alertState.mode
    const modeB = b.alertState.mode
    const modePriorityA = modePriority[modeA]
    const modePriorityB = modePriority[modeB]

    // Sort by device type
    const typePriority = {
      vehicle: 4,
      vehicleCharger: 3,
      thermostat: 2,
      home: 1,
    }
    const typeA = a.type
    const typeB = b.type
    const typePriorityA = typePriority[typeA]
    const typePriorityB = typePriority[typeB]

    if (modePriorityA === modePriorityB) {
      return typePriorityB - typePriorityA
    }
    return modePriorityB - modePriorityA
  })
}
