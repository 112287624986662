import { groupBy } from '../../../../utils/array/groupBy'
import {
  ThermostatDailySchedule,
  ThermostatSchedule,
  ThermostatScheduleEvent,
} from '../../../../types/thermostatSchedule'
import { thermostatsCollection } from '../../../../reducers/thermostats'
import { useAppSelector } from '../../../../hooks'
import { selectSchedulesForThermostat } from '../../../../selectors'
import dayjs from 'dayjs'
import {
  ThermostatComfortSetting,
  ThermostatComfortSettingSchedule,
} from '../../../../types/thermostatComfortSetting'
import { toDayRange, toDollars } from '../../../../utils/string'
import { useState } from 'react'
import { ID } from '../../../../types/model'
import { ThermostatDailySchedulesWithDisplayValues } from './HomeScheduleCard'
import { useUsersPreferredTemperature } from '../../../../authenticated/hooks/useUsersPreferredTemperature'
import { TemperatureUnit } from '../../../../types/temperatureUnit'
import { convertFahrenheitToCelsius } from '../../../../utils/conversionUtilities'
import mock from '../../../../types/thermostatSchedule/mock.json'
import useMockableViewModel from '../../../../hooks/useMockableViewModel'

interface Props {
  thermostatId: ID
}

function groupSchedules(days?: ThermostatDailySchedule[]): {
  [key: string]: ThermostatDailySchedule[]
} {
  return groupBy(days || [], (day: ThermostatDailySchedule) => {
    const { schedule } = day
    const startTime = schedule
      .map((period: ThermostatScheduleEvent) => period.start)
      .join('')
    const comfortSetting = schedule
      .map((period: ThermostatScheduleEvent) => period.comfort_setting)
      .join('')

    // Returns an identifier that correlates to the schedule for a given day
    return `${startTime}-${comfortSetting}`
  })
}

/**
 * Adds schedules and total cost to each comfort setting
 *
 * Each schedule includes a label, duration, and cost.
 * Total cost is the sum of costs for all schedules.
 */
function formatComfortSettings(
  comfortSettings: ThermostatComfortSetting[],
  schedules: ThermostatDailySchedule[][],
) {
  const formattedComfortSettings = comfortSettings.map((comfortSetting) => {
    return {
      ...comfortSetting,
      schedules: [] as ThermostatComfortSettingSchedule[],
      total_cost: 0,
    }
  })

  comfortSettings.forEach((comfortSetting, index) => {
    const allLabels = new Set<number>()
    schedules.forEach((schedule) => {
      const label = new Set<number>()
      const info = {
        label: '',
        duration: 0,
        cost: 0,
      }

      schedule.forEach((day) => {
        day.schedule.forEach((dailySchedule) => {
          if (comfortSetting.id === dailySchedule.comfort_setting) {
            // @ts-expect-error timeDiff does not exist on dayjs()
            info.duration += dayjs().timeDiff(
              dailySchedule.start,
              dailySchedule.end,
            )
            info.cost += dailySchedule.cost
            label.add(dayjs(<string>day.date).day())
            allLabels.add(dayjs(<string>day.date).day())
          }
        })
      })

      // Convert day indices to strings
      info.label = toDayRange(Array.from(label))

      formattedComfortSettings[index].total_cost += info.cost
      formattedComfortSettings[index].schedules.push(info)
    })
    // Fill in any days that were not found with the current comfort setting
    formattedComfortSettings[index].schedules.forEach((scheduleInfo) => {
      if (scheduleInfo.label === '') {
        scheduleInfo.label = toDayRange(Array.from(allLabels), {
          useMissingValuesInstead: true,
        })
      }
    })
  })

  return formattedComfortSettings
}

function formatCents(cents: number) {
  return toDollars(cents, { format: 'cents', numeric: true, decimals: 2 })
}

function comfortSettingsWithDisplayValues(
  comfortSettings: ThermostatComfortSetting[],
  temperatureUnit?: TemperatureUnit,
) {
  return comfortSettings.map((comfortSetting) => {
    if (temperatureUnit === 'C') {
      comfortSetting.heat_setpoint = convertFahrenheitToCelsius(
        comfortSetting.heat_setpoint,
      )
      comfortSetting.cool_setpoint = convertFahrenheitToCelsius(
        comfortSetting.cool_setpoint,
      )
    }

    const schedules = (comfortSetting.schedules ?? []).map((schedule) => {
      return {
        ...schedule,
        cost: Number(formatCents(schedule.cost)),
      }
    })

    const totalCost = schedules.reduce(
      (total, schedule) => total + schedule.cost,
      0,
    )

    return {
      ...comfortSetting,
      schedules,
      total_cost: totalCost,
    }
  })
}

function calculateSettingCostDollars(
  schedule: ThermostatDailySchedule[],
  id: number,
) {
  if (!schedule.length) {
    return 0
  }

  let sum = 0

  schedule.forEach((day) => {
    const { schedule } = day
    const events = schedule.filter((item) => item.comfort_setting === id)
    const eventsCost = events.reduce((total, event) => total + event.cost, 0)

    sum += eventsCost
  })

  return Number(formatCents(sum / schedule.length))
}

function scheduleWithDisplayValues(
  schedule: ThermostatDailySchedule[],
  comfortSettings: ThermostatComfortSetting[],
  temperatureUnit?: TemperatureUnit,
) {
  const comfortSettingCosts = comfortSettings.map((setting) => ({
    comfortSetting: setting,
    cost: calculateSettingCostDollars(schedule, setting.id),
  }))

  const totalCost = comfortSettingCosts.reduce(
    (total, { cost }) => total + cost,
    0,
  )

  const dailySchedules = [] as ThermostatDailySchedule[]

  if (temperatureUnit === 'C') {
    schedule.forEach((dailySchedule) => {
      const thermostatDailySchedule = {
        date: dailySchedule.date,
        schedule: [] as ThermostatScheduleEvent[],
      }
      dailySchedule.schedule.forEach((period) => {
        thermostatDailySchedule.schedule.push({
          start: period.start,
          end: period.end,
          comfort_setting: period.comfort_setting,
          cost: period.cost,
          historical: period.historical,
          outdoor_temp: convertFahrenheitToCelsius(period.outdoor_temp),
          energy: period.energy,
        })
      })

      dailySchedules.push(thermostatDailySchedule)
    })
  }

  return {
    dailySchedules: temperatureUnit === 'C' ? dailySchedules : schedule,
    comfortSettingCosts,
    totalCost,
  }
}

export function formatSchedule(
  schedule: ThermostatSchedule,
  temperatureUnit?: TemperatureUnit,
) {
  const { comfort_settings, days } = schedule
  const groupedDays = Object.values(groupSchedules(days || []))

  const comfortSettings = comfortSettingsWithDisplayValues(
    formatComfortSettings(comfort_settings, groupedDays),
    temperatureUnit,
  )

  const totalCost = comfortSettings.reduce(
    (total, comfortSetting) => total + comfortSetting.total_cost,
    0,
  )

  const schedules = groupedDays.map((schedule) =>
    scheduleWithDisplayValues(schedule, comfortSettings, temperatureUnit),
  )

  return {
    ...schedule,
    comfort_settings: comfortSettings,
    days,
    schedules,
    total_cost: totalCost,
  }
}

function useViewModel(props: Props) {
  const [startDate, setStartDate] = useState(dayjs().startOf('month').format())
  const [endDate, setEndDate] = useState(dayjs().endOf('month').format())
  const temperatureUnit = useUsersPreferredTemperature(0)
    .unit as TemperatureUnit

  function onChipGroupClick(index: number | null) {
    setStartDate(
      dayjs()
        .startOf('month')
        .add(index || 0, 'month')
        .format(),
    )
    setEndDate(
      dayjs()
        .endOf('month')
        .add(index || 0, 'month')
        .format(),
    )
  }

  const schedulesFetcher = thermostatsCollection.useResourceAnnotationFetcher(
    props.thermostatId,
    'schedules',
    {
      start_date: startDate,
      end_date: endDate,
      costs: true,
    },
  )

  const schedules = useAppSelector((state) =>
    selectSchedulesForThermostat(state, props.thermostatId),
  )
  const schedulesStatus = useAppSelector(schedulesFetcher.selectors.status)

  const formattedSchedule = schedules
    ? formatSchedule(schedules, temperatureUnit)
    : {
        comfort_settings: [] as ThermostatComfortSetting[],
        days: [] as ThermostatDailySchedule[],
        schedules: [] as ThermostatDailySchedulesWithDisplayValues[],
        total_cost: 0,
        avg_high_temp_f: 0,
        avg_low_temp_f: 0,
        max_temp_f: 0,
        min_temp_f: 0,
      }

  return {
    ...props,
    ...formattedSchedule,
    avg_high_temp_f: useUsersPreferredTemperature(
      schedules?.avg_high_temp_f ?? 0,
    ).value,
    avg_low_temp_f: useUsersPreferredTemperature(schedules?.avg_low_temp_f ?? 0)
      .value,
    max_temp_f: useUsersPreferredTemperature(schedules?.max_temp_f ?? 0).value,
    min_temp_f: useUsersPreferredTemperature(schedules?.min_temp_f ?? 0).value,
    loading: schedulesStatus === 'loading' || schedulesStatus === 'idle',
    onChipGroupClick,
  }
}

function useMockViewModel(props: Props) {
  const schedules = mock
  const formattedSchedule = formatSchedule(schedules)

  return {
    ...props,
    ...formattedSchedule,
    loading: false,
    onChipGroupClick: () => {},
  }
}

export default useMockableViewModel({ useViewModel, useMockViewModel })
