import { useCallback, useEffect, useState } from 'react'
import { MultilineSelectOption } from '@/app/components/MultilineDropdown/MultilineSelect'
import { useMockableViewModel } from '@/hooks'
import GoogleMapsUtils from '@/utils/google-maps/GoogleMapsUtils'
import { AddressSelectorProps } from './AddressSelector'
import { match, P } from 'ts-pattern'
import { uniqBy } from 'lodash'

export type GoogleLocationEvent = {
  description: string
  place_id: string
  location?: { lat?: number; lng?: number }
}

function useViewModel(props: AddressSelectorProps) {
  const [selectedAddress, setSelectedAddress] =
    useState<MultilineSelectOption>()
  const [localAddresses, setLocalAddresses] = useState<MultilineSelectOption[]>(
    [],
  )
  const [addressEntry, setAddressEntry] = useState<string>()
  const [manualEntry, setManualEntry] = useState(true)

  useEffect(
    function initializeAddressList() {
      if (!selectedAddress) {
        setManualEntry(!props.addresses || !props.addresses?.length)
      }

      const defaultOption = {
        label: 'Default Address',
        line1: props.initialAddress,
      } as MultilineSelectOption

      const defaultAddress = match({
        initialAddress: props.initialAddress,
        addresses: props.addresses,
      })
        .with({ initialAddress: P.nonNullable, addresses: [] }, () => {
          return defaultOption
        })
        .with(
          { initialAddress: P.nonNullable, addresses: P.array(P.nonNullable) },
          (initialValues) => {
            const foundAddress = initialValues.addresses.find(
              (add) =>
                add.line1.toLowerCase() ===
                initialValues.initialAddress.toLowerCase(),
            )
            return foundAddress ?? defaultOption
          },
        )
        .with(
          { initialAddress: P.nullish, addresses: P.array(P.nonNullable) },
          (initialValues) => {
            return initialValues.addresses[0]
          },
        )
        .otherwise(() => {
          return null
        })

      const allAddresses = uniqBy(
        [...props.addresses, defaultAddress].filter(
          (val): val is MultilineSelectOption => val !== null,
        ),
        'line1',
      ).filter((val) => !!val)

      setLocalAddresses((prev) =>
        JSON.stringify(prev) === JSON.stringify(allAddresses)
          ? prev
          : allAddresses,
      )

      if (defaultAddress && !selectedAddress) {
        setSelectedAddress((prev) =>
          prev?.line1 === defaultAddress.line1 ? prev : defaultAddress,
        )
      }
    },
    [props.addresses, props.initialAddress],
  )

  const manualEntryOption: MultilineSelectOption = {
    label: 'New Address',
    line1: 'Add an alternative home address',
  }

  const addressSelectionChange = (option: MultilineSelectOption) => {
    if (option === manualEntryOption) {
      setManualEntry(true)
      return
    }

    setSelectedAddress((prevState) => {
      if (prevState?.line1 === option.line1) {
        return prevState
      }
      const addr = localAddresses.find((addr) => addr.line1 === option.line1)
      return addr ?? prevState
    })
  }

  function handleMapChange(
    _event?: React.ChangeEvent<unknown>,
    locationObj?: GoogleLocationEvent,
  ) {
    if (!locationObj?.description) return

    setAddressEntry(locationObj.description)
    props.onAddressChange?.({
      description: locationObj?.description,
      place_id: locationObj?.place_id,
      location: locationObj?.location,
    })
  }

  function resetToSelection() {
    setManualEntry(false)
    if (!selectedAddress) {
      return
    }

    GoogleMapsUtils.geocode({ address: selectedAddress.line1 }, (results) => {
      if (!results.length) {
        return
      }
      setAddressEntry(selectedAddress.line1)
    })
  }

  return {
    manualEntry,
    selectedAddress,
    addressSelectionChange,
    handleMapChange,
    addressEntry,
    manualEntryOption,
    resetToSelection,
    addresses: localAddresses ?? [],
    hasAddressesAvailable: localAddresses?.length > 0,
  }
}

function useMockViewModel() {
  return {
    manualEntry: false,
    selectedAddress: {
      label: 'Address 1',
      line1: '828 Reading Way',
    } as MultilineSelectOption,
    addressSelectionChange: () => {
      alert('Address selection changed!')
    },
    handleMapChange: () => {
      alert('Map changed!')
    },
    addressEntry: 'Some Address',
    manualEntryOption: {} as MultilineSelectOption,
    resetToSelection: () => alert('Reset to selection!'),
    addresses: [{ label: 'Address 1', line1: '828 Reading Way' }],
    hasAddressesAvailable: true,
  }
}

export default useMockableViewModel({ useViewModel, useMockViewModel })
