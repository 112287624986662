import { dateIsBetween } from '@/utils/time'
import { DateRepresentation } from './dates'
import { ID } from './model'

export type DeviceDemandResponseEvent = {
  id: ID
  start_datetime: DateRepresentation
  end_datetime: DateRepresentation
  cancelled_reason?: string
  user_opt_choice?: 'opt-in' | 'opt-out'
  enrolled: boolean
}

export type VehicleChargingDelayEvent = DeviceDemandResponseEvent & {
  vehicle_id: ID
}

export type VehicleDemandResponseEvent = VehicleChargingDelayEvent

export function eventIsCurrentlyActive(
  event: DeviceDemandResponseEvent,
): boolean {
  const now = new Date()
  return dateIsBetween({
    date: now,
    start: event.start_datetime,
    end: event.end_datetime,
  })
}
