import './styles.scss'

export default function SandboxPage() {
  return (
    <div className="sandbox">
      {/**
       * A page for prototyping things in the app.
       *
       * This page is only available in development, and you should not commit changes to this file.
       */}
    </div>
  )
}
