import { RootState } from '@/store'
import { ID } from '@/types/model'
import { Vehicle } from '@/types/vehicle'
import { createSelector } from '@reduxjs/toolkit'
import { DeviceDescription } from './UtilityProgramEligibleDeviceEnrollmentFlowStep'
import { capitalizeFirstLetter } from '@/utils/stringUtilities'
import { useAppDispatch, useAppSelector, useUrlSearchParam } from '@/hooks'
import useMockableViewModel from '@/hooks/useMockableViewModel'
import { selectUtilityProgramForUserUtility } from '@/reducers/utilityPrograms'
import { abbreviatedString } from '@/utils/string'
import { thermostatsCollection } from '@/reducers/thermostats'
import {
  Thermostat,
  UtilityProgramEnrollment,
  UtilitySearchResult,
  VehicleCharger,
  VehicleEnrollment,
} from '@/types'
import { useState } from 'react'
import { RequestStatus } from '@/request/types'
import { utilityProgramEnrollmentCollection } from '@/reducers/utilityProgramEnrollments'
import {
  chargerEnrollmentCollection,
  thermostatEnrollmentCollection,
  vehicleEnrollmentCollection,
} from '@/reducers/deviceProgramEnrollments'
import * as Sentry from '@sentry/react'
import { makeGridSupportSelection } from '@/actions/utilities'
import { getChargeSchedules } from '@/actions/schedule'
import { UtilityProgram } from '@/types/utilityProgram'
import { selectUtilityProgramForUserConsideringEnrollments } from '@/selectors'
import { useNavigation } from '@/app/hooks'
import { utilityAccountsCollection } from '@/reducers/utilityAccounts'
import { vehicleChargersCollection } from '@/reducers/vehicleChargers'
import {
  ChargerEnrollment,
  ThermostatEnrollment,
} from '@/types/utilityProgramEnrollment'

export function vehicleDescriptionSelector(vehicleIDs: ID[]) {
  const vehicleIDSet = new Set(vehicleIDs)
  return createSelector(
    [(state: RootState) => state.vehicles.vehicles ?? []],
    (vehicles: Vehicle[]): DeviceDescription[] => {
      return vehicles
        .filter((vehicle) => vehicleIDSet.has(vehicle.id))
        .map((vehicle) => {
          const carModel = vehicle.car.car_model
          const abbreviatedVin = vehicle.car.vin
            ? `VIN ${abbreviatedString(vehicle.car.vin, {
                direction: 'fromBack',
              })}`
            : ''
          const name = (s: string) => (s ? `(${s})` : '')

          if (!carModel) {
            return {
              description: vehicle.car.display_name,
              name: name(abbreviatedVin),
              id: vehicle.id,
            }
          }

          return {
            description: `${carModel.year} ${capitalizeFirstLetter(
              carModel.friendly_name,
            )}`,
            name: name(
              vehicle.car.display_name
                ? vehicle.car.display_name
                : abbreviatedVin,
            ),
            id: vehicle.id,
          }
        })
    },
  )
}

export const useFetchingUtilityProgram = (utility?: UtilitySearchResult) => {
  const utilityProgram = useAppSelector(
    (state) =>
      selectUtilityProgramForUserUtility(state, utility) ??
      utilityProgramEnrollmentCollection.selectors.selectAll(state)[0]
        ?.utility_program,
  )

  const utilityProgramEnrollment = useAppSelector(
    (state) => utilityProgramEnrollmentCollection.selectors.selectAll(state)[0],
  )

  const isLoading = status === RequestStatus.Loading

  return { utilityProgram, utilityProgramEnrollment, isLoading }
}

export const fetchEligibleDevices = (utilityProgram?: UtilityProgram) => {
  const { data: thermostats } = thermostatsCollection.useFetch()

  const mapThermostat = (t: Thermostat): DeviceDescription => ({
    id: t.id,
    name: t.name ?? 'Thermostat',
    description: t.portal,
  })

  const deviceEligibility = utilityProgram?.device_eligibility

  const { data: chargers } = vehicleChargersCollection.useFetch()

  const mapCharger = (c: VehicleCharger): DeviceDescription => ({
    id: c.id,
    name: c.name ?? 'Charger',
    description: 'Chargepoint',
  })

  const eligibleChargers: DeviceDescription[] = chargers
    ?.filter((c) => deviceEligibility?.eligible_charger_ids.includes(c.id))
    ?.map(mapCharger)

  const ineligibleChargers: DeviceDescription[] = chargers
    ?.filter((c) => deviceEligibility?.ineligible_charger_ids.includes(c.id))
    ?.map(mapCharger)

  const eligibleThermostats: DeviceDescription[] = thermostats
    ?.filter((t) => deviceEligibility?.eligible_thermostat_ids.includes(t.id))
    ?.map(mapThermostat)

  const ineligibleThermostats: DeviceDescription[] = thermostats
    ?.filter((t) => deviceEligibility?.ineligible_thermostat_ids.includes(t.id))
    ?.map(mapThermostat)

  const eligibleVehicles = useAppSelector(
    vehicleDescriptionSelector(deviceEligibility?.eligible_vehicle_ids ?? []),
  )
  const ineligibleVehicles = useAppSelector(
    vehicleDescriptionSelector(deviceEligibility?.ineligible_vehicle_ids ?? []),
  )
  return {
    eligibleThermostats,
    ineligibleThermostats,
    eligibleChargers,
    ineligibleChargers,
    eligibleVehicles,
    ineligibleVehicles,
  }
}

function useEnrollmentStep() {
  const navigation = useNavigation()
  const accountNumberUrlParamPresent =
    useUrlSearchParam('enter-account-number') === 'true'
  const enrollmentCompleteUrlParamPresent =
    useUrlSearchParam('enrollment-complete') === 'true'
  const electricInHVACUrlParamPresent =
    useUrlSearchParam('electric-in-hvac') === 'true'

  const enrollmentStep = enrollmentCompleteUrlParamPresent
    ? ('enrollmentComplete' as const)
    : accountNumberUrlParamPresent
    ? ('accountNumber' as const)
    : electricInHVACUrlParamPresent
    ? ('electricInHVAC' as const)
    : ('deviceSelect' as const)

  const setEnrollmentStep = (
    step:
      | 'deviceSelect'
      | 'accountNumber'
      | 'electricInHVAC'
      | 'enrollmentComplete',
  ) => {
    if (step === 'enrollmentComplete') {
      navigation.pushAddParam('enrollment-complete', 'true')
    } else if (step === 'accountNumber') {
      navigation.pushAddParam('enter-account-number', 'true')
    } else if (step === 'electricInHVAC') {
      navigation.pushAddParam('electric-in-hvac', 'true')
    } else {
      navigation.pushRemoveParam('enter-account-number')
      navigation.pushRemoveParam('electric-in-hvac')
      navigation.pushRemoveParam('enrollment-complete')
    }
  }

  const clearEnrollmentStep = () => {
    navigation.pushRemoveParam('enter-account-number')
    navigation.pushRemoveParam('electric-in-hvac')
    navigation.pushRemoveParam('enrollment-complete')
  }

  return { enrollmentStep, setEnrollmentStep, clearEnrollmentStep }
}

function useShouldShowAccountNumberEntry() {
  const { data: utilityAccountEntry } = utilityAccountsCollection.useFetch(
    '/utilities/utility_account',
  )

  const shouldBeIncludedInEnrollment = useAppSelector((state) => {
    const configRoot =
      selectUtilityProgramForUserConsideringEnrollments(state)?.view_config_json
        .accountNumberEntry
    return Boolean(
      configRoot?.includeAccountNumberEntry ||
        configRoot?.accountNumberEntryRequired,
    )
  })

  const existingAccountNumber =
    (utilityAccountEntry?.[0]?.account_number as string | undefined) ?? null

  return {
    shouldShow: shouldBeIncludedInEnrollment,
    existingAccountNumber,
  }
}

export function useShouldShowHVACAttestation(
  eligibleThermostats: DeviceDescription[],
  excludedEligibleThermostatIDs: ID[],
) {
  const shouldBeIncludedInEnrollment = useAppSelector((state) => {
    const configRoot =
      selectUtilityProgramForUserConsideringEnrollments(state)?.view_config_json
        .hvacAttestation

    return Boolean(configRoot?.requireHvacAttestation)
  })

  const tryingToEnrollTstat = eligibleThermostats.some(
    (t) => !excludedEligibleThermostatIDs.includes(t.id),
  )

  return { shouldShow: shouldBeIncludedInEnrollment && tryingToEnrollTstat }
}

export type Props = {
  onConnect?: () => void
  onSkip?: () => void
  onBack?: () => void
  onClose?: () => void
  onRedirectUrl?: (url: string) => void
}

type EnrollmentStepStatus = {
  hvacAttestation?: boolean
}

function useViewModel(props: Props) {
  // Hooks
  const dispatch = useAppDispatch()
  const navigation = useNavigation()

  // State
  const [excludedEligibleVehicleIDs, setExcludedEligibleVehicleIDs] = useState<
    ID[]
  >([])
  const [excludedEligibleThermostatIDs, setExcludedEligibleThermostatIDs] =
    useState<ID[]>([])
  const [excludedEligibleChargerIDs, setExcludedEligibleChargerIDs] = useState<
    ID[]
  >([])
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [enrollError, setEnrollError] = useState<string | undefined>(undefined)
  const [enrollmentLoading, setEnrollmentLoading] = useState(false)
  const [isHvacAttested, setIsHvacAttested] = useState(false)

  // Selectors
  const viewConfig = useAppSelector(
    (state) =>
      selectUtilityProgramForUserConsideringEnrollments(state)?.view_config_json
        .eligibleDeviceEnrollment,
  )

  const utilitySelection = useAppSelector(
    (state) => state.utilities.selectedUtility,
  )

  const modalConfirmationRequired = useAppSelector((state) => {
    const configRoot =
      selectUtilityProgramForUserConsideringEnrollments(state)?.view_config_json
    return Boolean(
      configRoot?.enrollmentConfirmationModal ??
        configRoot?.onboarding?.enrollConfirmation,
    )
  })
  const { shouldShow: shouldShowAccountNumberEntry, existingAccountNumber } =
    useShouldShowAccountNumberEntry()
  const { enrollmentStep, setEnrollmentStep, clearEnrollmentStep } =
    useEnrollmentStep()

  // Fetching
  const { utilityProgram, utilityProgramEnrollment, isLoading } =
    useFetchingUtilityProgram(utilitySelection ?? undefined)

  const {
    eligibleThermostats,
    ineligibleThermostats,
    eligibleChargers,
    ineligibleChargers,
    eligibleVehicles,
    ineligibleVehicles,
  } = fetchEligibleDevices(utilityProgram)

  const { shouldShow: shouldShowHVACAttestatation } =
    useShouldShowHVACAttestation(
      eligibleThermostats,
      excludedEligibleThermostatIDs,
    )

  // Constants
  const disabled =
    enrollmentLoading ||
    (eligibleVehicles.length === excludedEligibleVehicleIDs.length &&
      eligibleThermostats.length === excludedEligibleThermostatIDs.length &&
      eligibleChargers.length === excludedEligibleChargerIDs.length)

  // Functions
  const setGridSupportSelection = (support: boolean) => {
    const promise = dispatch(
      makeGridSupportSelection(
        {
          has_made_grid_support_selection: true,
        },
        dispatch,
      ),
    )

    if (promise) {
      // refresh charges, because they will all be updated to reflect the new grid support adoption
      promise.then(() => dispatch(getChargeSchedules()))
    } else {
      Sentry.captureMessage('makeGridSupportSelection returning null promise')
    }
  }

  const selectedEligibleChargerIDs = eligibleChargers
    ?.filter((charger) => !excludedEligibleChargerIDs.includes(charger.id))
    .map((charger) => charger.id)

  const selectedEligibleVehicleIDs = eligibleVehicles
    .filter((vehicle) => !excludedEligibleVehicleIDs.includes(vehicle.id))
    .map((vehicle) => vehicle.id)

  const selectedEligibleThermostatIDs = eligibleThermostats
    ?.filter(
      (thermostat) => !excludedEligibleThermostatIDs.includes(thermostat.id),
    )
    .map((thermostat) => thermostat.id)

  const toggleEligibility = (
    id: ID,
    excludedIDs: ID[],
    setExcludedIDs: (ids: ID[]) => void,
  ) => {
    setExcludedIDs(
      excludedIDs.includes(id)
        ? excludedIDs.filter((excludedId) => excludedId !== id)
        : [...excludedIDs, id],
    )
  }

  const toggleChargerEligibility = (chargerId: ID) =>
    toggleEligibility(
      chargerId,
      excludedEligibleChargerIDs,
      setExcludedEligibleChargerIDs,
    )

  const toggleVehicleEligibility = (vehicleId: ID) =>
    toggleEligibility(
      vehicleId,
      excludedEligibleVehicleIDs,
      setExcludedEligibleVehicleIDs,
    )

  const toggleThermostatEligibility = (thermostatId: ID) =>
    toggleEligibility(
      thermostatId,
      excludedEligibleThermostatIDs,
      setExcludedEligibleThermostatIDs,
    )

  const onEnroll = async () => {
    setEnrollmentLoading(true)

    try {
      if (!utilityProgram) {
        throw new Error(
          `No utility program found when submitting program enrollment. Was loading: ${isLoading}`,
        )
      }

      setGridSupportSelection(true)

      // Ensure utility program enrollment exists
      const scopeLevelUtilityProgramEnrollment =
        await ensureUtilityProgramEnrollment()
      if (!scopeLevelUtilityProgramEnrollment) return

      // Enroll devices
      const enrollmentResults = await enrollDevices()

      // Handle enrollment results
      handleEnrollmentResults(
        enrollmentResults,
        scopeLevelUtilityProgramEnrollment,
      )
    } finally {
      setEnrollmentLoading(false)
    }
  }

  const ensureUtilityProgramEnrollment =
    async (): Promise<UtilityProgramEnrollment | null> => {
      if (utilityProgramEnrollment) return utilityProgramEnrollment

      const result = await dispatch(
        utilityProgramEnrollmentCollection.actions.create({
          utility_program_id: utilityProgram.id,
          vehicle_ids: [],
          thermostat_ids: [],
          charger_ids: [],
        }),
      )

      if (result.error) {
        setEnrollError(result.payload.response?.[0] || 'Unknown error')
        return null
      }

      return result.payload
    }

  const enrollDevices = async (): Promise<
    (VehicleEnrollment | ThermostatEnrollment | ChargerEnrollment)[]
  > => {
    const enrollmentPromises: Promise<
      VehicleEnrollment | ThermostatEnrollment | ChargerEnrollment
    >[] = []

    // Enroll eligible vehicles
    selectedEligibleVehicleIDs.forEach((id) => {
      enrollmentPromises.push(enrollDevice('vehicle', id))
    })

    // Enroll eligible thermostats based on HVAC attestation conditions
    if (shouldShowHVACAttestatation) {
      if (isHvacAttested) {
        selectedEligibleThermostatIDs.forEach((id) => {
          enrollmentPromises.push(enrollDevice('thermostat', id))
        })
      }
      // Don't enroll thermostats if attestation is required but not completed
    } else {
      selectedEligibleThermostatIDs.forEach((id) => {
        enrollmentPromises.push(enrollDevice('thermostat', id))
      })
    }

    // Enroll eligible chargers
    selectedEligibleChargerIDs.forEach((id) => {
      enrollmentPromises.push(enrollDevice('charger', id))
    })

    return Promise.all(enrollmentPromises)
  }

  const enrollDevice = (
    deviceType: 'vehicle' | 'thermostat' | 'charger',
    deviceId: ID,
  ): Promise<VehicleEnrollment | ThermostatEnrollment | ChargerEnrollment> => {
    const collectionMap = {
      vehicle: vehicleEnrollmentCollection,
      thermostat: thermostatEnrollmentCollection,
      charger: chargerEnrollmentCollection,
    }

    return dispatch(
      collectionMap[deviceType].actions.create({
        utility_program_id: utilityProgram.id,
        [`${deviceType}_id`]: deviceId,
      }),
    )
  }

  const handleEnrollmentResults = (
    results: (
      | VehicleEnrollment
      | ThermostatEnrollment
      | ChargerEnrollment
      | { error: true; payload: { response: string[] } }
    )[],
    enrollment: UtilityProgramEnrollment,
  ) => {
    const errors = results.filter(
      (result): result is { error: true; payload: { response: string[] } } =>
        'error' in result && result.error === true,
    )

    if (errors.length) {
      setEnrollError(errors[0].payload.response[0] || 'Unknown error')
      return
    }

    setEnrollError(undefined)
    invalidateCollections()

    if (enrollment.external_redirect_url) {
      props.onRedirectUrl?.(enrollment.external_redirect_url)
    } else {
      setConfirmationOpen(false)
      setEnrollmentStep('enrollmentComplete')
    }
  }

  const invalidateCollections = () =>
    [
      utilityProgramEnrollmentCollection,
      vehicleEnrollmentCollection,
      thermostatEnrollmentCollection,
      chargerEnrollmentCollection,
    ].forEach((collection) => dispatch(collection.actions.invalidate()))

  const proceedToEnrollment = () => {
    if (modalConfirmationRequired) {
      setConfirmationOpen(true)
    } else {
      setConfirmationOpen(false)
      onEnroll()
    }
  }

  const onEnrollClickAfterSelection = () => {
    if (shouldShowHVACAttestatation) {
      setEnrollmentStep('electricInHVAC')
    } else if (shouldShowAccountNumberEntry) {
      setEnrollmentStep('accountNumber')
    } else {
      proceedToEnrollment()
    }
  }

  const afterEnrollErrorNextAction = () => {
    if (utilityProgramEnrollment.external_redirect_url) {
      props.onRedirectUrl?.(utilityProgramEnrollment.external_redirect_url)
    } else if (props.onSkip) {
      props.onSkip()
    } else {
      navigation.push('/app')
    }
  }

  const onAccountNumberEntryComplete = () => {
    proceedToEnrollment()
  }

  const onHVACAttestationComplete = () => {
    if (shouldShowAccountNumberEntry) {
      setEnrollmentStep('accountNumber')
    } else {
      proceedToEnrollment()
    }
  }

  const onEnrollmentCompleteClick = () => {
    props.onConnect?.()
    clearEnrollmentStep()
  }

  const closeModal = () => {
    setConfirmationOpen(false)
  }

  return {
    ...props,
    eligibleVehicles,
    ineligibleVehicles,
    eligibleThermostats,
    ineligibleThermostats,
    eligibleChargers,
    ineligibleChargers,
    toggleVehicleEligibility,
    toggleThermostatEligibility,
    toggleChargerEligibility,
    onEnroll,
    closeModal,
    onEnrollClickAfterSelection,
    afterEnrollErrorNextAction,
    confirmationOpen,
    enrollError,
    viewConfig,
    selectedEligibleVehicleIDs,
    selectedEligibleThermostatIDs,
    selectedEligibleChargerIDs,
    disabled,
    enrollmentStep,
    onAccountNumberEntryComplete,
    onEnrollmentCompleteClick,
    existingAccountNumber,
    isLoading: isLoading,
    onHVACAttestationComplete,
    isHvacAttested,
    setIsHvacAttested,
  }
}

function useMockViewModel(props: Props) {
  const eligibleVehicles = [
    {
      description: '2019 Tesla Model 3',
      name: '(Sparky)',
      id: 1,
    },
    {
      description: '2020 Tesla Model X',
      name: '(Bolt)',
      id: 2,
    },
  ]

  const ineligibleVehicles = [
    {
      description: '2022 Toyota Prius Prime',
      name: '(VIN ...6843)',
      id: 3,
    },
  ]

  const eligibleChargers = [
    {
      id: 1,
      name: 'Garage Charger',
      description: 'Chargepoint',
    },
  ]

  const eligibleThermostats = [
    {
      id: 1,
      name: 'Living Room Thermostat',
      description: '',
    },
    {
      id: 2,
      name: 'Bedroom Thermostat',
      description: '',
    },
  ]

  const viewConfig = {
    title: {
      text: 'Congratulations, your spot is available!',
    },
    description: {
      text: 'Select which devices you want to enroll in the program. Each vehicle earns $150 upfront plus $20 per quarter through 2024.',
    },
    enrollButton: {
      text: 'Enroll',
    },
  }

  return {
    ...props,
    eligibleVehicles,
    ineligibleVehicles,
    eligibleThermostats,
    ineligibleThermostats: [],
    eligibleChargers,
    ineligibleChargers: [],
    toggleVehicleEligibility: (vehicleId: ID) => {},
    toggleThermostatEligibility: (thermostatId: ID) => {},
    toggleChargerEligibility: (chargerId: ID) => {},
    onEnroll: async () => {},
    closeModal: () => {},
    onEnrollClickAfterSelection: () => {},
    afterEnrollErrorNextAction: () => {},
    confirmationOpen: false,
    enrollError: undefined,
    viewConfig,
    selectedEligibleVehicleIDs: eligibleVehicles.map((vehicle) => vehicle.id),
    selectedEligibleThermostatIDs: eligibleThermostats.map(
      (thermostat) => thermostat.id,
    ),
    selectedEligibleChargerIDs: eligibleChargers.map((charger) => charger.id),
    disabled: false,
    enrollmentStep: 'deviceSelect' as
      | 'deviceSelect'
      | 'accountNumber'
      | 'enrollmentComplete',
    onAccountNumberEntryComplete: () => {},
    onEnrollmentCompleteClick: () => {},
    existingAccountNumber: null,
    isLoading: false,
    onHVACAttestationComplete: () => {},
    isHvacAttested: false,
    setIsHvacAttested: () => {},
  }
}

export default useMockableViewModel({ useViewModel, useMockViewModel })
