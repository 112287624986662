import { Modal, ModalProps } from '@/components'
import { BreakpointContext } from '@/context'
import twMerge from '@/utils/classes/twMerge'
import { useContext } from 'react'

export type AdaptiveModalProps = Omit<ModalProps, 'fullscreen'>

/**
 * A modal that expands to fullscreen on small screens.
 * Meant to imitate native modals on mobile devices.
 */
export default function AdaptiveModal(props: AdaptiveModalProps) {
  const { className, ...modalProps } = props
  const breakpoint = useContext(BreakpointContext)
  const classes = twMerge(
    className,
    breakpoint.mdAndUp ? 'max-h-[80%]' : undefined,
  )
  return (
    <Modal
      fullscreen={breakpoint.smAndDown}
      {...modalProps}
      className={classes}
    >
      {props.children}
    </Modal>
  )
}
