import { FirebaseApp, initializeApp } from 'firebase/app'
import {
  fetchAndActivate,
  getBoolean as _getBoolean,
  getNumber as _getNumber,
  getRemoteConfig,
  getString as _getString,
  getValue as _getValue,
  RemoteConfig as FirebaseRemoteConfig,
} from 'firebase/remote-config'
import * as Sentry from '@sentry/browser'
import { logEvent } from './logging'
import { logException, logMessage } from './logging/sentry'
import EventEmitter from 'events'
import Env from '@/utils/env'

export const DEFAULT_AVA_WHITELABEL_ID = 57
export const DEFAULT_AVA_PROD_WHITELABEL_ID = 25
export const DEFAULT_AVA_WHITELABEL_SLUG = 'ava'

export const defaultConfig = {
  experimental_users: '[]',
  tesla_force_mobile_redirect: false,
  coming_soon_thermostat_brands: '[]',
  back_soon_from_partner_only: true,
  back_soon_brands: '[]',
  tesla_oauth_enabled: false,
  thermostat_schedule_settings: false,
  nest_schedule_settings: false,
  tesla_force_unofficial_auth: false,
  tesla_force_fleet_auth: false,
  password_validation_min_length: 8,
  connect_charger: false,
  thermostat_pre_cool_settings: false,
  hide_nest_schedule_components: true,
  vehicle_chargeforecast: false,
  hide_signup_captcha: true,
  show_solar_plan: false,
  show_home_estimations: false,
  show_home_insights: false,
  pse_hyundai_kia_paused_data_collection_alert: false,
  gexa_link_url: 'https://www.gexaenergy.com/',
  gexa_reset_utility_id: 88139,
  gexa_reset_plan_id: 20403,
  ford_oauth: false,
  play_store_link:
    'https://play.google.com/store/apps/details?id=com.getoptiwatt.optiwatt&hl=en_US&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
  app_store_link:
    'https://apps.apple.com/us/app/optiwatt-tesla-ev-charging/id1536047033',
  whitelabeling_enabled: false,
  thermostat_enrollments: false,
  charger_enrollments: true,
  device_settings: false,
  prod_whitelabel_ids: `{"${DEFAULT_AVA_WHITELABEL_SLUG}": ${DEFAULT_AVA_PROD_WHITELABEL_ID}}`,
  staging_whitelabel_ids: `{"${DEFAULT_AVA_WHITELABEL_SLUG}": ${DEFAULT_AVA_WHITELABEL_ID}}`,
  evse_target_kwh: 48,
  trips_under_construction: false,
  paused_data_collection_alert: '[]',
}

export abstract class RemoteConfig {
  abstract getValue(key: string): any
  abstract getString(key: string): string
  abstract getNumber(key: string): number
  abstract getBoolean(key: string): boolean
  abstract getArray(key: string): string[]

  abstract eventEmitter: EventEmitter
  abstract ready: boolean

  getWhiteLabelIdMap(): Record<string, number> | null {
    let configVal: string | undefined

    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      configVal = this.getString('prod_whitelabel_ids')
    } else {
      configVal = this.getString('staging_whitelabel_ids')
    }

    if (!configVal) {
      return null
    }

    try {
      const val = JSON.parse(configVal)
      if (typeof val !== 'object') {
        logMessage(
          `REMOTE CONFIG: Failed to parse white label id map as object`,
        )
        return null
      }
      return val
    } catch (err) {
      logException(err)
      return null
    }
  }

  getWhitelabelId(key: string): number | null {
    const whiteLabelIdMap = this.getWhiteLabelIdMap()

    if (Env.isLocal) {
      return process.env.REACT_APP_LOCAL_AVA_PROGRAM_ID
        ? Number(process.env.REACT_APP_LOCAL_AVA_PROGRAM_ID)
        : null
    }

    if (!whiteLabelIdMap) {
      return null
    }

    try {
      return whiteLabelIdMap[key]
    } catch (Error) {
      logMessage(`REMOTE CONFIG: Failed to get white label id for ${key}`)
      return null
    }
  }

  getProgramSlugFromWhiteLabelId(whiteLabelId: number) {
    if (Env.isLocal) {
      const localWhitelabels = JSON.parse(defaultConfig.staging_whitelabel_ids)

      for (const [key, value] of Object.entries(localWhitelabels)) {
        if (value === whiteLabelId) {
          return key
        }
      }
      return null
    }

    const whiteLabelIdMap = this.getWhiteLabelIdMap()
    if (!whiteLabelIdMap) {
      return null
    }

    // find where the value of the map matches the whiteLabelId
    const programSlug = Object.keys(whiteLabelIdMap).find(
      (key) => whiteLabelIdMap[key] === whiteLabelId,
    )
    return programSlug ?? null
  }
}

class LiveRemoteConfig extends RemoteConfig {
  remoteConfig: FirebaseRemoteConfig
  ready = false
  eventEmitter = new EventEmitter()

  constructor() {
    super()
    this.remoteConfig = this.setupFirebaseConfig()
  }

  setupFirebaseConfig() {
    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    const firebaseConfig = {
      apiKey: 'AIzaSyBepLq2XUtkg6kX9gx4FMREr_8sRmZPE44',
      authDomain: 'optiwatt-b420f.firebaseapp.com',
      databaseURL: 'https://optiwatt-b420f.firebaseio.com',
      projectId: 'optiwatt-b420f',
      storageBucket: 'optiwatt-b420f.appspot.com',
      messagingSenderId: '398769848157',
      appId: '1:398769848157:web:7eda0f23893060c197f2b4',
      measurementId: 'G-9FX0BTGQTP',
    }

    // Initialize Firebase
    const app: FirebaseApp = initializeApp(firebaseConfig)

    // Initialize Remote Config and get a reference to the service
    const remoteConfig: FirebaseRemoteConfig = getRemoteConfig(app)

    // Set remote config cache to 5 minutes
    remoteConfig.settings.minimumFetchIntervalMillis = 300000

    // Sets default config if reference before loaded (failsafe)
    remoteConfig.defaultConfig = defaultConfig

    fetchAndActivate(remoteConfig)
      .then(() => {
        this.ready = true
        this.eventEmitter.emit('ready')
      })
      .catch((err) => {
        logException(err)
        logEvent('remote_config_fetch_failed')
        this.eventEmitter.emit('error', [err])
        console.log(`Error loading firebase remote config: ${err}`)
      })

    return remoteConfig
  }

  getValue(key: keyof typeof defaultConfig) {
    return _getValue(this.remoteConfig, key)
  }
  getString(key: keyof typeof defaultConfig) {
    const val = _getString(this.remoteConfig, key)
    return _getString(this.remoteConfig, key)
  }
  getNumber(key: keyof typeof defaultConfig) {
    return _getNumber(this.remoteConfig, key)
  }
  getBoolean(key: keyof typeof defaultConfig) {
    return _getBoolean(this.remoteConfig, key)
  }
  getArray(key: keyof typeof defaultConfig) {
    const value = this.getString(key)
    try {
      return JSON.parse(value) as string[]
    } catch (err) {
      Sentry.captureException(err)
      return []
    }
  }
}

class TestRemoteConfig extends RemoteConfig {
  ready = true
  eventEmitter = new EventEmitter()

  constructor() {
    super()
    this.eventEmitter.emit('ready')
  }

  getValue(key: keyof typeof defaultConfig) {
    return defaultConfig[key]
  }
  getString(key: keyof typeof defaultConfig) {
    return String(defaultConfig[key])
  }
  getNumber(key: keyof typeof defaultConfig) {
    return Number(defaultConfig[key])
  }
  getBoolean(key: keyof typeof defaultConfig) {
    return Boolean(defaultConfig[key])
  }
  getArray(key: keyof typeof defaultConfig) {
    return JSON.parse(String(defaultConfig[key]))
  }
}

let remoteConfig: RemoteConfig | null = null

if (process.env.NODE_ENV === 'test') {
  remoteConfig = new TestRemoteConfig()
} else {
  try {
    remoteConfig = new LiveRemoteConfig()
  } catch (err) {
    Sentry.captureException(err)
  }
}

export { remoteConfig }
