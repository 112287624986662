import { useEffect, useState, useRef } from 'react'
import styled, { withTheme } from 'styled-components'
import { Paper, Typography as MuiTypography } from '@material-ui/core'
import { spacing } from '@material-ui/system'
import { Bar } from 'react-chartjs-2'
import Rate from './Rate'
import Skeleton from '@material-ui/lab/Skeleton'
import { useDispatch, useSelector } from 'react-redux'
import { MISSING_HOURS_MESSAGE, useRatesGraph } from './RatesGraph'
import {
  setErrorHours,
  hideEditPlans,
  clearForm,
} from '../../../../actions/manualPlans'
import { capitalizeFirstLetter } from '../../../../utils/stringUtilities'
import { AlertMessage } from '@/app/components'
import { motion } from 'framer-motion'
import { Button, Icon } from '@/components'

const Typography = styled(MuiTypography)(spacing)

const Wrapper = styled(Paper)`
  display: grid;
  padding: ${(props) => props.theme.spacing(6)}px;
  height: 100%;

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`

const ChartWrapper = styled.div`
  height: 200px;
  width: 100%;
`

function WeekRates(props) {
  const { editingRates, plans } = useSelector((state) => state.manualPlans)
  const { changingWeekdays, changingMonths } = useSelector(
    (state) => state.manualPlans.form,
  )
  const rates = getRates()
  const [data, options, missingHours] = useRatesGraph(rates)
  const dispatch = useDispatch()
  const errorRef = useRef(null)
  let [showMissingError, setShowMissingError] = useState(false)

  function handleSubmit(event) {
    event.preventDefault()
    if (missingHours.length > 0) {
      setShowMissingError(true)
      dispatch(setErrorHours(missingHours))
    } else {
      setShowMissingError(false)
      props.handleSuccess()
    }
  }

  function getRates() {
    if (props.plan.planId) {
      return props.plan.rates[props.type]
    } else {
      return editingRates[props.type]
    }
  }

  const wrapperStyle = () => {
    if (props.disabled) {
      return { opacity: '.50' }
    }
  }

  // Get the end of the last rate safely
  const lastRateEnd = () => {
    if (rates.length > 0) {
      return rates[rates.length - 1].end
    } else {
      return null
    }
  }

  useEffect(() => {
    // Scroll to missing error if it exists
    if (showMissingError) {
      errorRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      })
    }
  }, [showMissingError, errorRef])

  return (
    <Wrapper justifyContent="center" style={wrapperStyle()} elevation={0}>
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        {changingWeekdays ? capitalizeFirstLetter(props.type) : 'Daily'} Rates
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        Enter your rates{' '}
        {changingMonths && `for ${props.plan.start} to ${props.plan.end}`}
      </Typography>
      <ChartWrapper style={{ marginTop: '20px' }}>
        {!props.disabled ? (
          <Bar data={data} options={options} />
        ) : (
          <Skeleton animation={false} variant="rect" height={200} />
        )}
      </ChartWrapper>
      <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
        Enter {changingWeekdays ? props.type : 'daily'} rates
        {changingMonths && ` for ${props.plan.start} to ${props.plan.end}`}
      </Typography>
      <Typography variant="body2" gutterBottom>
        Please add a new rate for each hour of the{' '}
        {changingWeekdays ? props.type : 'day'}
      </Typography>
      {showMissingError && (
        <motion.div ref={errorRef} layout="position">
          <AlertMessage variant="error" className="mt-2">
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: '#f44336' }}
            >
              {MISSING_HOURS_MESSAGE}
            </Typography>
          </AlertMessage>
        </motion.div>
      )}
      {rates.map((rate, index) => {
        return (
          <motion.div layout="position">
            <Rate
              key={index}
              index={index}
              planId={props.plan.planId}
              rates={rates}
              type={props.type}
              disabled={true}
              fabDisabled={props.disabled}
              rate={rate.rate}
              start={rate.start}
              end={rate.end}
              errors={rate.errors}
            />
          </motion.div>
        )
      })}
      {missingHours.length > 0 && (
        <motion.div layout="position">
          <Rate
            index={rates.length}
            planId={props.plan.planId}
            addButtonId="add-rate"
            rates={rates}
            type={props.type}
            disabled={props.disabled}
            fabDisabled={props.disabled}
            rate={null}
            start={lastRateEnd()}
            end={null}
            errors={{
              rate: null,
              start: null,
              end: null,
            }}
          />
        </motion.div>
      )}

      <div className="flex justify-center flex-grow w-full gap-4 mt-20">
        {props.isWeekend && (
          <Button
            variant="secondary"
            className="flex-[2]"
            m={1}
            disabled={props.disabled}
            onClick={props.handlePrevious}
          >
            Previous
          </Button>
        )}
        <Button
          data-ga-event-label="select-plan"
          className="flex-[4]"
          type="submit"
          variant="primary"
          color="primary"
          onClick={handleSubmit}
          m={1}
          disabled={props.disabled}
        >
          Submit
        </Button>
        {props.errorMessage && (
          <Typography variant="body2" gutterBottom style={{ color: '#f44336' }}>
            {props.errorMessage}
          </Typography>
        )}
      </div>
    </Wrapper>
  )
}

export default withTheme(WeekRates)
