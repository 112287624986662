import { PayloadAction } from '@reduxjs/toolkit'
import * as actions from '../actions/manualPlans'
import * as planActions from '../actions/plans'

type ManualPlan = {
  planId: number
  rates: { weekday: number[]; weekend: number[] }
  start?: any // replace `any` with the appropriate type
  end?: any // replace `any` with the appropriate type
  editRates?: boolean
}

type State = {
  editNewWeekRates: boolean
  plans: ManualPlan[]
  editingRates: { weekday: number[]; weekend: number[] }
  errorMonths: any[] // replace `any` with the appropriate type
  errorHours: any[] // replace `any` with the appropriate type
  form: {
    completed: boolean
    changingWeekdays: boolean
    changingMonths: boolean
  }
  isEditingPlan: boolean
  errors: Record<string, unknown> // replace `unknown` with the appropriate type if possible
}

const initialState: State = {
  editNewWeekRates: false,
  plans: [],
  editingRates: { weekday: [], weekend: [] },
  errorMonths: [],
  errorHours: [],
  form: {
    completed: false,
    changingWeekdays: false,
    changingMonths: false,
  },
  isEditingPlan: false,
  errors: {},
}

const initialEditingRates = () => {
  return { weekday: [], weekend: [] }
}

export default (state = initialState, action: PayloadAction<any>): State => {
  let plans
  let editingRates
  let form
  switch (action.type) {
    case actions.START_EDIT_PLAN:
      return {
        ...state,
        isEditingPlan: true,
      }
    case actions.END_EDIT_PLAN:
    case planActions.UPLOAD_PLAN_SUCCESS:
    case planActions.UPLOAD_UTILITY_BILL_SUCCESS:
    case planActions.SELECT_PLAN_SUCCESS:
      return {
        ...state,
        isEditingPlan: false,
      }
    case actions.ADD_PLAN: {
      plans = [...state.plans]
      const plan = action.payload.plan
      plan.planId = Math.max(...plans.map((o) => o.planId), 0) + 1
      return {
        ...state,
        plans: [...state.plans, plan],
        editingRates: initialEditingRates(),
      }
    }
    case actions.SUBMIT_RATES:
      plans = [...state.plans]
      // If it's a current plan, update the rates, otherwise, create a new plan.
      if (action.payload.planId) {
        const index = plans.findIndex(
          (plan) => plan.planId === action.payload.planId,
        )
        plans[index].rates = action.payload.rates
        editingRates = initialEditingRates()
      } else {
        editingRates = action.payload.rates
      }

      return {
        ...state,
        plans: plans,
        editNewWeekRates: false,
        editingRates: editingRates,
      }
    case actions.DELETE_PLAN:
      plans = [...state.plans]
      plans.splice(
        plans.findIndex((plan) => plan.planId === action.payload.planId),
        1,
      )
      return {
        ...state,
        plans: plans,
        editingRates: initialEditingRates(),
      }
    case actions.MODIFY_PLAN: {
      plans = [...state.plans]
      const index = plans.findIndex(
        (plan) => plan.planId === action.payload.planId,
      )
      plans[index].start = action.payload.start
      plans[index].end = action.payload.end
      return {
        ...state,
        plans: plans,
        editingRates: initialEditingRates(),
      }
    }
    case actions.ADD_RATE:
      if (action.payload.planId) {
        plans = [...state.plans]
        const index = plans.findIndex(
          (plan) => plan.planId === action.payload.planId,
        )
        plans[index].rates[
          action.payload.type as keyof ManualPlan['rates']
        ].push(action.payload.rate)
        return {
          ...state,
          plans: plans,
        }
      } else {
        editingRates = { ...state.editingRates }
        editingRates[action.payload.type as keyof ManualPlan['rates']].push(
          action.payload.rate,
        )
        return {
          ...state,
          editingRates: editingRates,
        }
      }
    case actions.DELETE_RATE: {
      const type = action.payload.type
      if (action.payload.planId) {
        plans = [...state.plans]
        const index = plans.findIndex(
          (plan) => plan.planId === action.payload.planId,
        )
        plans[index].rates[type as keyof ManualPlan['rates']].splice(
          action.payload.index,
          1,
        )
        return {
          ...state,
          plans: plans,
        }
      } else {
        editingRates = { ...state.editingRates }
        editingRates[type as keyof ManualPlan['rates']].splice(
          action.payload.index,
          1,
        )
        return {
          ...state,
          editingRates: editingRates,
        }
      }
    }
    case actions.SET_ERROR_MONTHS:
      return {
        ...state,
        errorMonths: action.payload.errorMonths,
      }
    case actions.SET_ERROR_HOURS:
      return {
        ...state,
        errorHours: action.payload.errorHours,
      }
    case actions.SUBMIT_QUESTIONS:
      form = { ...state.form }
      form.completed = true
      form.changingWeekdays = action.payload.weekdays
      form.changingMonths = action.payload.months
      return {
        ...state,
        form: form,
      }
    case actions.CLEAR_FORM:
      form = { ...state.form }
      form.completed = false
      return {
        ...initialState,
        editingRates: initialEditingRates(),
        form: form,
        isEditingPlan: state.isEditingPlan,
      }
    case actions.SHOW_NEW_PLAN:
      return {
        ...state,
        editNewWeekRates: true,
      }
    case actions.HIDE_NEW_PLAN:
      return {
        ...state,
        editNewWeekRates: false,
      }
    case actions.SHOW_EDIT_NEW_WEEK_RATES:
      plans = [...state.plans]
      for (let i = 0; i < plans.length; i++) {
        plans[i].editRates = plans[i].planId === action.payload.planId
      }
      return {
        ...state,
        plans: plans,
        editNewWeekRates: false,
      }
    case actions.HIDE_EDIT_NEW_WEEK_RATES:
      plans = [...state.plans]
      for (let i = 0; i < plans.length; i++) {
        plans[i].editRates = false
      }
      return {
        ...state,
        plans: plans,
        editNewWeekRates: false,
        editingRates: { weekday: [], weekend: [] },
      }
    default:
      return state
  }
}
