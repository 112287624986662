import { ViewModelComponentProps } from '@/utils/typeUtilities'
import QuestionnaireRoot, {
  Props as ComponentProps,
  QuestionnairePage,
  QuestionnairePageValue,
} from './Questionnaire'
import useViewModel from './useViewModel'

type Props = ViewModelComponentProps<ComponentProps, typeof useViewModel>
export default function Questionnaire({ ...props }: Props) {
  const viewModel = useViewModel(props)
  return <QuestionnaireRoot {...viewModel} version={props.version} />
}

export type { Props, QuestionnairePage, QuestionnairePageValue }
