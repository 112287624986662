import { RSAA } from 'redux-api-middleware'
import { withAuth } from '../reducers'
import config from '../config'
import { RequestTypeEnum } from '../types/requests'

export enum Get {
  Request = '@@baselineShiftingEvent/BASELINE_SHIFTING_EVENT_GET_REQUEST',
  Success = '@@baselineShiftingEvent/BASELINE_SHIFTING_EVENT_GET_SUCCESS',
  Failure = '@@baselineShiftingEvent/BASELINE_SHIFTING_EVENT_GET_FAILURE',
}

export function get(
  params: { [key: string]: any } = {},
  TypeEnum: RequestTypeEnum = Get,
) {
  return {
    [RSAA]: {
      endpoint: `${config.apiGateway.URL}/api/baseline_shifting_events/`,
      method: 'GET',
      params,
      headers: withAuth({ 'Content-Type': 'application/json' }),
      types: [TypeEnum.Request, TypeEnum.Success, TypeEnum.Failure],
    },
  }
}
