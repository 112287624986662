import { EyeIcon } from 'lucide-react'
import { Button, Icon, Sheet, Text, ToggleButton } from '@/components'
import React, { useCallback, useState } from 'react'
import { AlertMessage } from 'app-components'
import SurveyObserver from '@/app/components/SurveyObserver'
import RateWatchSurvey from '@/app/pages/home-insights/current-rate/rate-watch/rate-watch-survey'
import check from '@/app/img/check.svg'
import { logEvent } from '@/logging'

export type RateWatchOptions = 'any' | 'significant' | 'huge'

const detailMapping = {
  any: `<b>Any</b> is any savings Optiwatt can find you`,
  significant: `<b>Significant</b> is 10-20% savings compared to your current plan`,
  huge: '<b>Huge</b> is greater than 20% savings compared with your current plan',
}

export default function RateWatch() {
  const [selected, setSelected] = useState<RateWatchOptions>('any')
  const [showModal, setShowModal] = useState(false)
  const [showThankYou, setShowThankYou] = useState(false)

  const buttonClassName = useCallback((active: boolean) => {
    return active
      ? '!bg-[#F7EDFF] text-themed-purple-500 text-base !rounded-[20px] py-2 px-5 border-none'
      : 'py-2 px-5 text-base'
  }, [])

  const openModal = () => {
    logEvent('rateWatchGetEarlyAccessClicked', {
      metadata: { importance_level: selected },
    })
    setShowModal(true)
  }
  const closeModal = () => setShowModal(false)

  const onSubmit = () => {
    closeModal()
    setShowThankYou(true)
  }

  const closeThankYou = () => {
    setShowThankYou(false)
  }

  return (
    <div className="bg-themed-white rounded-[8px] border border-solid border-themed-base-200 p-5">
      <div id="header" className="flex gap-4 items-center">
        <EyeIcon />
        <Text variant="body1">Rate watch</Text>
        <Sheet color="blue-100">
          <Text variant="label" className="text-themed-blue-500">
            Coming soon
          </Text>
        </Sheet>
      </div>
      <Text variant="body4" id="subtitle" className="mt-1 pl-10">
        We'll let you know when we find a cheaper rate in your area
      </Text>
      <div
        id="selection-container"
        className="flex bg-themed-white border rounded-[24px] shadow-spread p-2 my-6"
      >
        <ToggleButton.ToggleButtonGroup
          value={selected}
          onChange={(val) => setSelected(val as RateWatchOptions)}
          className="flex w-full justify-around items-center"
          resetStyle={true}
          disallowDeselect
        >
          <ToggleButton.ToggleButton
            key="any"
            id="btn-any"
            value="any"
            resetStyle
            className={buttonClassName}
          >
            Any
          </ToggleButton.ToggleButton>
          <ToggleButton.ToggleButton
            key="significant"
            id="btn-sig"
            value="significant"
            resetStyle
            className={buttonClassName}
          >
            Significant
          </ToggleButton.ToggleButton>
          <ToggleButton.ToggleButton
            key="huge"
            id="btn-huge"
            value="huge"
            resetStyle
            className={buttonClassName}
          >
            Huge
          </ToggleButton.ToggleButton>
        </ToggleButton.ToggleButtonGroup>
      </div>
      <AlertMessage variant="info" className="!bg-white !p-0">
        <div
          className="text-black mt-[-1px]"
          dangerouslySetInnerHTML={{ __html: detailMapping[selected] }}
        />
      </AlertMessage>
      <SurveyObserver observedSurveys={['RateWatch']}>
        <Button
          id="btn-signup"
          variant="primary-subtle"
          className="mt-6"
          onClick={openModal}
        >
          Get Early Access
        </Button>
      </SurveyObserver>
      <RateWatchSurvey
        showModal={showModal}
        onClose={closeModal}
        onSubmit={onSubmit}
        value={selected}
      />
      {showThankYou && (
        <div className="bg-themed-white rounded-[8px] border border-solid border-themed-base-200 px-5 py-4 mt-6">
          <div className="flex items-start gap-3">
            <img src={check} className="w-[20px]" />
            <div>
              <Text variant="body1">Thanks for your feedback</Text>
              <Text variant="body4" className="mt-1">
                Your responses will be directly shared with our product
                development team
              </Text>
            </div>
            <Button
              className="!p-0"
              variant="icon"
              id="close-button"
              onClick={closeThankYou}
            >
              <Icon name="X" size={18} />
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
