import { combine, awaitResponse, succeed, fail, Action, Errors } from './utils'
import { Patch } from '../actions/deletedVehicles'
import { DeletedVehicle } from '@/types'

type State = {
  deletedVehicles: DeletedVehicle[] | null
  isLoading: boolean
  errors: Errors
}

const initialState: State = {
  deletedVehicles: null,
  isLoading: false,
  errors: {},
}

export default (state = initialState, action: Action): State => {
  switch (action.type) {
    case Patch.Success:
      return combine(state, action, succeed)
    case Patch.Request:
      return combine(state, action, awaitResponse)
    case Patch.Failure:
      return combine(state, action, fail)
    default:
      return state
  }
}
