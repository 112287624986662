import { Badge } from '@material-ui/core'

import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  badge: {
    color: 'white',
    background: '#FB5842',
    boxShadow: '0px 0px 10px 0px #FB584240',
  },
  badgeRoot: {
    maxWidth: '100%',
  },
}))

const NotificationBadge = ({ children, ...restProps }) => {
  const classes = useStyles()

  return (
    <Badge
      classes={{ root: classes.badgeRoot, badge: classes.badge }}
      {...restProps}
    >
      {children}
    </Badge>
  )
}

export default NotificationBadge
