import {
  BoxedSymbol,
  Button,
  CardActions,
  CardAction,
  Flex,
  IconDetailListItem,
  List,
  Text,
} from '@/components'
import { useSurveyResponder } from './reducer'
import { useEffect, useState } from 'react'
import { useAppSelector } from '@/hooks'
import { selectUrls } from '@/selectors/teslaFleetAuthentication'
import AdaptiveModal from '../AdaptiveModal'
import TeslaLogo from '@/authenticated/images/tesla.webp'
import OptiwattIconLogo from '@/app/img/logo-icon-colored-filled.webp'
import LinkDiagram from '@/app/features/logos/LinkDiagram'
import { IconNames } from '@/components/Icon/Icon'
import { logEvent } from '@/logging'
import TeslaVehiclePermissions, {
  VehicleResource,
} from '../TeslaVehiclePermissions/TeslaVehiclePermissions'
import TeslaFleetPartnerSheet from '@/app/components/TeslaFleetPartnerSheet'

const listContent = [
  {
    icon: 'Heart',
    title: 'Keep the features you love',
    description:
      'You’ll lose access to the control features you love if you don’t update this connection.',
  },
  {
    icon: 'Key',
    title: 'Get more control',
    description:
      'Review permissions before granting access and change them any time',
  },
  {
    icon: 'Shield',
    title: 'Improve your data security',
    description:
      'Get the latest encryption and manage Optiwatt directly in the Tesla app',
  },
]

function useViewModel() {
  const teslaFleetAuthUrl = useAppSelector(
    (state) => selectUrls(state)?.authorize_url,
  )

  const [locallyDismissed, setLocallyDismissed] = useState(false)
  const {
    dismissSurvey,
    addSurveyInteraction,
    markSurveySeen,
    activeSurveyResponse,
    activeSurveyResponseErrors,
  } = useSurveyResponder('TeslaFleetAuth')

  const onCloseClick = () => {
    dismissSurvey()
    setLocallyDismissed(true)
  }

  useEffect(() => {
    if (
      !activeSurveyResponse?.last_seen &&
      !activeSurveyResponseErrors?.length
    ) {
      markSurveySeen()
    }
  }, [activeSurveyResponse?.last_seen, activeSurveyResponseErrors?.length])

  const onContinueClick = () => {
    if (!teslaFleetAuthUrl) {
      return
    }
    window.location.href = teslaFleetAuthUrl
    addSurveyInteraction({ action: 'click' })
  }

  const open = !locallyDismissed && Boolean(teslaFleetAuthUrl)
  const [reviewingPermissions, setReviewingPermissions] = useState(false)
  const onReviewPermissionsClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    setReviewingPermissions(true)
    const isReviewButton =
      event.currentTarget.id ===
      'tesla-fleet-auth-survey-review-permissions-button'
    addSurveyInteraction({
      action: 'click',
      action_data: {
        button: isReviewButton ? 'review-permissions' : 'get-started',
      },
    })
    if (isReviewButton) {
      logEvent('TeslaAPI_Modal_ReviewPermissions_Click')
    } else {
      logEvent('TeslaAPI_Modal_GetStarted_Click')
    }
  }
  const vehicles = useAppSelector((state) => {
    return state.vehicles
  })
  const vehiclesRequiringAuth = vehicles.vehicles?.filter(
    (v) => v.requires_tesla_fleet_migration,
  )

  return {
    onCloseClick,
    onContinueClick,
    open,
    reviewingPermissions,
    onReviewPermissionsClick,
    vehiclesRequiringAuth,
  }
}

export default function TeslaFleetAuthSurvey() {
  const {
    onCloseClick,
    onContinueClick,
    open,
    onReviewPermissionsClick,
    reviewingPermissions,
    vehiclesRequiringAuth,
  } = useViewModel()
  return (
    <AdaptiveModal
      id="tesla-fleet-auth-survey-modal"
      open={open}
      onClose={onCloseClick}
    >
      <div className="mt-12">
        <LinkDiagram logos={[OptiwattIconLogo, TeslaLogo]} />
      </div>
      <div className="flex items-center justify-center w-full mt-4 mb-10">
        <TeslaFleetPartnerSheet />
      </div>
      {!reviewingPermissions ? (
        <TeslaFleetSurveyPrompt
          onGetStartedClick={onContinueClick}
          onReviewPermissionsClick={onReviewPermissionsClick}
        />
      ) : (
        <ReviewingPermissions
          vehicles={vehiclesRequiringAuth ?? []}
          onContinueClick={onContinueClick}
        />
      )}
    </AdaptiveModal>
  )
}

function TeslaFleetSurveyPrompt({
  onGetStartedClick,
  onReviewPermissionsClick,
}: {
  onGetStartedClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void
  onReviewPermissionsClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void
}) {
  return (
    <>
      <Text variant="h2">
        You need to update to the latest Tesla connection
      </Text>
      <Text variant="body2" className="!mt-4 !mb-8">
        Optiwatt is now an official Tesla Fleet partner, and a new connection is
        required to get the most out of our app. Here's what you get:
      </Text>
      <List gap="md">
        {listContent.map((item, index) => (
          <Flex key={index} item>
            <IconDetailListItem
              icon={() => (
                <BoxedSymbol variant="icon" iconName={item.icon as IconNames} />
              )}
              title={item.title}
              description={item.description}
            />
          </Flex>
        ))}
      </List>
      <div className="flex flex-col gap-4 mt-10">
        <Button
          id="tesla-fleet-auth-survey-review-permissions-button"
          variant="primary-subtle"
          onClick={onReviewPermissionsClick}
        >
          Review Permissions
        </Button>
        <Button
          id="tesla-fleet-auth-survey-get-started-button"
          variant="primary"
          onClick={onGetStartedClick}
        >
          Get Started
        </Button>
      </div>
    </>
  )
}

export function ReviewingPermissions({
  vehicles,
  onContinueClick,
}: {
  vehicles: VehicleResource[]
  onContinueClick: () => void
}) {
  return (
    <div className="flex flex-col gap-4 h-full">
      <Text variant="h2">
        Optiwatt functions best with all permissions enabled
      </Text>
      <div className="text-sm">
        On the next screen, we will request the following information from you.
        Select a dropdown below to see which features
      </div>
      <TeslaVehiclePermissions vehicles={vehicles} />
      <CardActions>
        <CardAction type="primary">
          <Button
            id="tesla-fleet-auth-continue-button"
            onClick={onContinueClick}
          >
            Continue
          </Button>
        </CardAction>
      </CardActions>
    </div>
  )
}
