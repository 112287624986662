import { useUrlSearchParam } from '@/hooks'
import { UtilityProgram } from '@/types/utilityProgram'
import { useProgramSpecificConfig } from '@/app/hooks/useProgramSpecificConfig'

export function useProgramSpecificConfigFromUrlParam(
  utilityProgramOverride?: UtilityProgram,
) {
  const externalUtilityProgramIdParam = useUrlSearchParam('program_id')
  return useProgramSpecificConfig(
    externalUtilityProgramIdParam,
    utilityProgramOverride,
  )
}
