import { capitalizeFirstLetter } from '@/utils/stringUtilities'

const DAYS = [
  'sundays',
  'mondays',
  'tuesdays',
  'wednesdays',
  'thursdays',
  'fridays',
  'saturdays',
]

const WEEKDAYS = [1, 2, 3, 4, 5]
const WEEKENDS = [0, 6]

const includesEvery = (arr: number[], subset: number[]) =>
  subset.every((value) => arr.includes(value))

const joinWithCommas = (arr: string[]) => {
  if (arr.length > 2) {
    return arr.join(', ').replace(/, ([^,]*)$/, ', and $1')
  }

  return arr.join(' and ')
}

const mapToCapitalizedDays = (indices: number[]) =>
  indices.map((idx) => `${capitalizeFirstLetter(DAYS[idx])}`)

export default function daysOfWeekToReadable(days: string[]): string {
  const idxArr = days
    .map((day) => `${day}s`)
    .map((day) => DAYS.indexOf(day))
    .filter((idx) => idx !== -1) // Filter invalid days
    .sort((a, b) => a - b)

  if (idxArr.length === 0) return 'No days'
  if (idxArr.length === 7) return 'Every day'

  const isWeekends = includesEvery(idxArr, WEEKENDS)
  const isWeekdays = includesEvery(idxArr, WEEKDAYS)

  const remainingDays = mapToCapitalizedDays(idxArr)

  if (isWeekends)
    return joinWithCommas([
      'Weekends',
      ...remainingDays.filter(
        (day) => !WEEKENDS.includes(DAYS.indexOf(day.toLowerCase())),
      ),
    ])
  if (isWeekdays)
    return joinWithCommas([
      'Weekdays',
      ...remainingDays.filter(
        (day) => !WEEKDAYS.includes(DAYS.indexOf(day.toLowerCase())),
      ),
    ])

  return joinWithCommas(remainingDays)
}
