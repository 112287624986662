import './styles.scss'
import formatClasses from '../../utils/classes/formatClasses'
import { Grid, Text } from '../index'
import getColor from '../../utils/color/getColor'
import React from 'react'
import { capitalize } from '../../utils/stringUtilities'
import { ReactNodeProps } from '../../types/reactNodeProps'

export type Props = (DefaultBarGraphProps | CustomBarGraphProps) &
  BarGraphLegendProps &
  BarGraphEventHandlers &
  BarGraphAxisProps

interface DefaultBarGraphProps extends Omit<ReactNodeProps, 'children'> {
  colors?: { color: string; type: string }[]
  items: { label?: string; value: number; type?: string }[]
}

interface CustomBarGraphProps extends ReactNodeProps {
  // No additional props provided
}

export interface BarGraphLegendProps {
  legend?: LegendItemProps[]
}

interface BarGraphAxisProps {
  xAxis: (string | number)[]
  yAxis: (string | number)[]
}

interface BarGraphEventHandlers {
  onPointerLeave?: (event?: React.PointerEvent<HTMLElement>) => void
}

export interface DataPointProps {
  label?: string // Text to display when hovering over a data point
  value: number
  type: string // Data type that corresponds to an item in the legend
}

export interface LegendItemProps {
  color: string
  label: string
}

function DataPoint(props: Omit<DataPointProps, 'type'> & { color: string }) {
  const classes = {
    bar: formatClasses(['default-bar-graph-bar']),
    dataPoint: formatClasses(['default-data-point']),
  }
  return (
    <div
      className={classes.dataPoint}
      data-key={props.label ? props.label : undefined}
    >
      <div
        className={classes.bar}
        data-value={Math.round(props.value * 10)}
        style={{
          background: getColor(props.color),
          height: `${Math.round(props.value * 10)}px`,
        }}
      />
    </div>
  )
}

function LegendItem(props: LegendItemProps) {
  const classes = {
    legendKey: formatClasses(['legend-key']),
  }

  return (
    <Grid gap="6px" placeItems="baseline">
      <span
        className={classes.legendKey}
        style={{
          background: getColor(props.color),
        }}
      />
      <Grid flow="row">
        <Text variant="body2">{props.label}</Text>
      </Grid>
    </Grid>
  )
}

function Legend(props: { items: LegendItemProps[] }) {
  return (
    <Grid className="bar-graph-legend" gap="32px">
      {props.items.map((child, index) => {
        return (
          <LegendItem
            color={child.color}
            label={capitalize(child.label.toLowerCase())}
            key={index}
          />
        )
      })}
    </Grid>
  )
}

function YAxis(props: { labels: (string | number)[] }) {
  return (
    <Grid
      className="bar-graph-y-axis"
      flow="row"
      placeContent="space-between"
      placeItems="normal end"
    >
      {props.labels.map((label, index) => {
        return (
          <Text key={index} variant="body2">
            {label}
          </Text>
        )
      })}
    </Grid>
  )
}

function XAxis(props: { labels: (string | number)[] }) {
  return (
    <Grid placeContent="space-between">
      {props.labels.map((label, index) => {
        return (
          <Text key={index} variant="body2">
            {label}
          </Text>
        )
      })}
    </Grid>
  )
}

export default function BarGraph(props: Props) {
  const classes = {
    barGraph: formatClasses(['bar-graph']),
    graph: formatClasses(['graph', props.className]),
  }

  function DataPoints() {
    // Default bar graph
    if ('items' in props) {
      return props.items.map((item, index) => {
        const color =
          props.colors?.find(({ type }) => item.type === type)?.color ||
          'blue-500'

        return (
          <DataPoint
            color={color}
            key={index}
            label={item.label}
            value={item.value}
          />
        )
      })
    }
    // Custom bar graph
    else {
      return props.children
    }
  }

  return (
    <div
      className={classes.barGraph}
      // Support for leave on Android/Chrome "mobile" emulation
      onPointerLeave={props.onPointerLeave}
    >
      <Grid flow="row" gap="16px">
        <Grid gap="16px" templateColumns="auto 1fr">
          <YAxis labels={props.yAxis} />
          <Grid gap="8px" flow="row">
            <Grid
              autoColumns="1fr"
              className={classes.graph}
              gap="4px"
              placeContent="normal start"
              placeItems="end"
            >
              <DataPoints />
            </Grid>
            <XAxis labels={props.xAxis} />
          </Grid>
        </Grid>
        {props.legend && <Legend items={props.legend} />}
      </Grid>
    </div>
  )
}
