type KeyPath = string[]

export default function selectNestedProperty(
  obj: Record<string, any>,
  ...keyPath: KeyPath
) {
  return keyPath.reduce((acc, key) => {
    if (acc === undefined) {
      return undefined
    }
    return acc[key]
  }, obj)
}
