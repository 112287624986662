import styled from 'styled-components'

import { Typography as MuiTypography } from '@material-ui/core'

import { spacing } from '@material-ui/system'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'

const Typography = styled(MuiTypography)(spacing)

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: '0.9rem',
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const UsageSectionSubtitleTypography = ({
  className = '',
  children,
  ...restProps
}) => {
  const classes = useStyles()

  return (
    <Typography
      className={clsx(classes.text, className)}
      variant="subtitle1"
      {...restProps}
    >
      {children}
    </Typography>
  )
}

export default UsageSectionSubtitleTypography
