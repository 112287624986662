import {
  Button,
  Flex,
  Grid,
  GridItem,
  Icon,
  LoadingAnimation,
} from '@/components'
import React, { useCallback, useEffect, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import useIsMobile from '@/authenticated/hooks/useIsMobile'
import ImageUtils from '@/utils/images/ImageUtils'

type Props = {
  srcs: string[]
  maxHeight?: number
}

type Direction = 'next' | 'previous'

const swipeConfidenceThreshold = 11000

const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity
}

const AnimatedImage = ({
  srcs,
  direction,
  imgIndex,
  onDrag,
}: {
  srcs: string[]
  direction: Direction
  imgIndex: number
  onDrag: (dir: Direction) => void
}) => {
  const [variants, setVariants] = useState({})

  useEffect(() => {
    setVariants({
      slideIn:
        direction === 'next' ? { opacity: 0, x: 500 } : { opacity: 0, x: -500 },
      slideOut:
        direction === 'next' ? { opacity: 0, x: 500 } : { opacity: 0, x: -500 },
      animate: { x: 0, opacity: 1 },
    })
  }, [direction])

  return (
    <AnimatePresence mode="wait">
      <motion.img
        key={imgIndex}
        src={srcs[imgIndex]}
        initial="slideIn"
        animate="animate"
        exit="slideOut"
        variants={variants}
        transition={{ duration: 0.35 }}
        className="h-full w-full"
        drag="x"
        dragConstraints={{ left: 0, right: 0 }}
        dragElastic={1}
        onDragEnd={(e, { offset, velocity }) => {
          const swipe = swipePower(offset.x, velocity.x)
          if (swipe < -swipeConfidenceThreshold) {
            onDrag('next')
          } else if (swipe > swipeConfidenceThreshold) {
            onDrag('previous')
          }
        }}
      />
    </AnimatePresence>
  )
}

const ImageCarousel = ({ srcs, maxHeight }: Props) => {
  const [imgIndex, setImgIndex] = useState(0)
  const [direction, setDirection] = useState<Direction>('next')
  const [isLoading, setIsLoading] = useState(true)

  const isMobile = useIsMobile()

  useEffect(() => {
    if (!srcs || srcs.length === 0) {
      setIsLoading(false)
      return
    }

    ImageUtils.preloadImages(srcs).then(() => {
      setIsLoading(false)
    })
  }, [srcs])

  const next = useCallback(() => {
    if (imgIndex <= srcs.length - 2) {
      setDirection('next')
      setImgIndex((prev) => prev + 1)
    }
  }, [imgIndex, srcs.length])

  const prev = useCallback(() => {
    if (imgIndex >= 1) {
      setDirection('previous')
      setImgIndex((prev) => prev - 1)
    }
  }, [imgIndex])

  const onDrag = useCallback(
    (dir: Direction) => {
      setDirection(dir)
      if (dir === 'next') {
        if (imgIndex <= srcs.length - 2) {
          setImgIndex((prev) => prev + 1)
        }
      } else {
        if (imgIndex >= 1) {
          setImgIndex((prev) => prev - 1)
        }
      }
    },
    [imgIndex, srcs.length],
  )

  if (isLoading) {
    return (
      <Flex container justifyContent="space-around" alignItems="center">
        <LoadingAnimation type="falling" />
      </Flex>
    )
  }

  return (
    <AnimatePresence mode="wait">
      <Grid flow="column" placeItems="center" gap="10px">
        <GridItem placeSelf="center start">
          {imgIndex >= 1 && (
            <Button id="prev-button" variant="circle-button" onClick={prev}>
              <Icon
                name="ChevronLeft"
                color="white"
                size={isMobile ? 18 : 30}
              />
            </Button>
          )}
        </GridItem>
        <GridItem placeSelf="center">
          <div className="overflow-hidden w-auto" style={{ maxHeight }}>
            <AnimatedImage
              srcs={srcs}
              imgIndex={imgIndex}
              direction={direction}
              onDrag={onDrag}
            />
          </div>
        </GridItem>
        <GridItem className="mr-5" placeSelf="center end">
          {imgIndex <= srcs.length - 2 && (
            <Button id="next-button" variant="circle-button" onClick={next}>
              <Icon
                name="ChevronRight"
                color="white"
                size={isMobile ? 18 : 30}
              />
            </Button>
          )}
        </GridItem>
      </Grid>
    </AnimatePresence>
  )
}

export default ImageCarousel
