import { Switch, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'

type Props = Omit<React.ComponentProps<typeof Switch>, 'location'>

export default function AnimatedNavigationSwitch(props: Props) {
  const location = useLocation()

  return (
    <AnimatePresence mode="wait">
      <Switch location={location} key={location.pathname} {...props} />
    </AnimatePresence>
  )
}
