import config from '@/config'
import {
  FetchArgs,
  FetchOptions,
  ID,
  QuerySpec,
  QuerySpecMeta,
  QueryState,
  RequestActionTypes,
  RequestArgs,
  RequestStatus,
  ResourceApiConfig,
  ResourceModel,
  ResponseError,
  RootState,
} from './types'
import { createResourceSelectors } from './selectors'
import { createSelector } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

export const useAppDispatch = () => useDispatch<any>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

// stackoverflow.com/a/50376498
function isNumber(value?: string | number): boolean {
  return value != null && value !== '' && !isNaN(Number(value.toString()))
}

export function extractResponseError<E extends ResponseError>(
  error: E,
): ResponseError {
  const { status, response, message } = error
  return { status, response, message }
}

export function createQuerySpecApiMiddlewareTypes<Meta>(
  types: RequestActionTypes<unknown, Meta>,
  querySpec: QuerySpec,
): RequestActionTypes<unknown, QuerySpecMeta & Meta> {
  const typeKeys = ['Request', 'Success', 'Failure'] as const

  // create middle ware action types where meta contains query data
  return typeKeys.reduce((acc, key) => {
    const actionType = types[key]
    // actionType can be either a string representing the action type, or
    // an object with a type and metadata about the query action
    const actionTypeObject =
      typeof actionType === 'string' ? { type: actionType } : actionType
    acc[key] = {
      ...actionTypeObject,
      meta: {
        ...(actionTypeObject.meta ?? {}),
        querySpec,
      } as QuerySpecMeta & Meta,
    }
    return acc
  }, {} as RequestActionTypes<unknown, QuerySpecMeta & Meta>)
}

export function querySpecToIDString(querySpec: Partial<QuerySpec>) {
  const { method, body, id, params, pathSegments } = querySpec
  const justTheSpec = { method, body, id, params, pathSegments }
  return JSON.stringify(justTheSpec)
}

export type QuerySpecWithPredicateProps = {
  [key in keyof QuerySpec]:
    | QuerySpec[key]
    | ((value: QuerySpec[key]) => boolean)
}
export type MatchQueryStateToQuerySpecOptions = {
  includePartialMatches?: boolean
}
export function queryStateMatchesQuerySpec(
  queryState: QueryState,
  querySpec: Partial<QuerySpecWithPredicateProps>,
  options?: MatchQueryStateToQuerySpecOptions,
) {
  if (options?.includePartialMatches) {
    return Object.keys(querySpec).every((key) => {
      const specKey = key as keyof QuerySpec
      const specValue = querySpec[specKey] as (value: unknown) => boolean
      // If the spec value is a function, call it with the query state value and use the return value
      // otherwise compare the values directly
      if (typeof specValue === 'function') {
        return specValue(queryState[specKey])
      }
      return (
        JSON.stringify(queryState[specKey]) ===
        JSON.stringify(querySpec[specKey])
      )
    })
  }

  Object.keys(querySpec).forEach((key) => {
    if (typeof querySpec[key as keyof QuerySpec] === 'function') {
      throw new Error(
        'Cannot use a predicate function when includePartialMatches is false',
      )
    }
  })
  return (
    querySpecToIDString(queryState) ===
    querySpecToIDString(querySpec as QuerySpec)
  )
}

/**
 * Given fetch args, create a full query spec.
 * This is useful for querying with selectors.queryState.select with includesPartialMatches = false,
 * as the result of this function should be a full query spec that matches what is stored in the state.
 * It should match because it is used in the createFetchAction function.
 */
export function createQuerySpecFromFetchArgs(args: FetchArgs = {}) {
  const pathSegments = (args.id ? [args.id.toString()] : []).concat(
    args.pathSegments || [],
  )
  const method = 'GET' as const
  return {
    method,
    ...args,
    pathSegments,
  }
}

export function createQuerySpecFromCreateArgs<Resource extends ResourceModel>(
  resource: Partial<Resource>,
  args?: RequestArgs,
) {
  const method = 'POST' as const
  const body = JSON.stringify(resource)
  return {
    ...(args ?? {}),
    method,
    body,
  }
}

export function getFetchOptions(options: FetchOptions = {}) {
  const { refetchOnChange, require } = options
  const defaultOptions = { refetchOnChange: true, require: undefined }
  return {
    refetchOnChange: refetchOnChange ?? defaultOptions.refetchOnChange,
    require: require ?? defaultOptions.require,
  }
}

export function extractIdFromPathOrId(path: string | number) {
  if (typeof path === 'number') {
    return path
  }
  const pathSegments = path.split('/')
  for (let i = pathSegments.length - 1; i >= 0; i--) {
    const segment = pathSegments[i]
    if (isNumber(segment)) {
      return segment
    }
  }
  throw new Error(`Could not extract numeric id from path: ${path}`)
}

export function getIdForResource(options: { id?: ID; path?: string | number }) {
  const { id, path } = options

  if (id) {
    return id
  }

  if (!path) {
    throw new Error(
      'Must provide either an id or a path with an id in it to get a resource id',
    )
  }

  const idFromPath = extractIdFromPathOrId(path)

  if (!isNumber(idFromPath)) {
    throw new Error(`Could not extract numeric id from path: ${path}`)
  }

  return idFromPath
}

export function removeTrailingAndLeadingSlashes(endpoint: string) {
  return endpoint.replace(/^\/|\/$/g, '')
}

export function getBaseUrl<Resource extends ResourceModel>(
  apiConfig: ResourceApiConfig<Resource>,
) {
  const { baseUrl } = apiConfig
  if (baseUrl) {
    return baseUrl
  }
  return config.apiGateway.URL
}

/**
 * If the path from request args is a static string, the endpoint is the segments appended to the path.
 * else if the path is defined in the apiConfig, it's segments appended to the api config path
 * else if the path is a function, the endpoint is the result of calling the function with the segments.
 *
 * @returns The path defined by the request using `args` and `apiConfig`.
 * Clarification: the path is the end of a url. e.g. if the url is https://google.com/my/search
 * then the path is /my/search
 */
export function generateApiPath<Resource extends ResourceModel>(
  apiConfig: ResourceApiConfig<Resource>,
  args: FetchArgs | RequestArgs,
) {
  const pathSegments = args.pathSegments ?? []

  if (args.path) {
    return [args.path, ...pathSegments].join('/')
  }

  if (!apiConfig.path) {
    return pathSegments.join('/')
  }

  if (typeof apiConfig.path === 'string') {
    return [apiConfig.path, ...pathSegments].join('/')
  }
  return apiConfig.path(...pathSegments)
}

/**
 * @returns The full url of the request defined by `apiConfig` and `requestArgs`
 */
export function buildRequestUrl<Resource extends ResourceModel>(
  apiConfig: ResourceApiConfig<Resource>,
  requestArgs: FetchArgs | RequestArgs,
) {
  const endpointPath = generateApiPath(apiConfig, requestArgs)
  return `${getBaseUrl(apiConfig)}/api/${removeTrailingAndLeadingSlashes(
    endpointPath,
  )}/`
}

export function resourceWithIdAddedIfNeeded(
  resource: Partial<ResourceModel>,
  // allows us to pass in an id to use in the case that the resource does not have one
  // if we dont pass the id, we'll just use a random uuid
  fallbackId?: string,
): ResourceModel {
  if (resource.id) {
    return resource as ResourceModel
  }
  return {
    ...resource,
    id: fallbackId ?? uuidv4(),
  }
}

/**
 * Creates a selector that returns the query state for a given requests query spec.
 * Note this is different than the queryState.select selector, which returns all query states
 * and does not handle the idle case before a query state is created.
 */
export const createQueryStateForRequestSpecSelector = <
  Resource extends ResourceModel,
>(
  selectors: ReturnType<typeof createResourceSelectors<Resource>>,
  querySpec: QuerySpec,
) => {
  return createSelector(
    [
      (state) =>
        selectors.queryState.select(state, querySpec, {
          includePartialMatches: false,
        }),
    ],
    (queryStates) => {
      if (!queryStates?.length) {
        return {
          status: 'idle' as RequestStatus,
          error: null,
        }
      } else if (queryStates.length !== 1) {
        console.warn(
          "Unexpectedly found more than one query state for a request's query spec. This should not happen.",
        )
      }
      return queryStates[0]
    },
  )
}
