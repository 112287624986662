import { match } from 'ts-pattern'

export enum ThermostatEligibilityRequirements {
  Active = 'ACTIVE',
  Data = 'DATA',
  Allowed = 'ALLOWED',
  NotAllowed = 'NOT_ALLOWED',
}
export function thermostatIneligibilityReasonContent(
  key: ThermostatEligibilityRequirements,
) {
  return match(key)
    .returnType<string>()
    .with(
      ThermostatEligibilityRequirements.Active,
      () => 'Your thermostat must be active to enroll in this program.',
    )
    .with(
      ThermostatEligibilityRequirements.Data,
      () =>
        'Your thermostat must be able to send data to enroll in this program.',
    )
    .with(
      ThermostatEligibilityRequirements.Allowed,
      () => 'Your thermostat is not on the allowed list for this program.',
    )
    .with(
      ThermostatEligibilityRequirements.NotAllowed,
      () => 'Your thermostat is specifically excluded from this program.',
    )
    .exhaustive()
}
