import React from 'react'
import styled from 'styled-components'

import {
  Box as MuiBox,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Button as MuiButton,
  IconButton as MuiIconButton,
} from '@material-ui/core'
import { X as Close } from 'react-feather'

import { spacing } from '@material-ui/system'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const Card = styled(MuiCard)(spacing)
const Box = styled(MuiBox)(spacing)
const CardContent = styled(MuiCardContent)(spacing)
const Button = styled(MuiButton)(spacing)
const IconButton = styled(MuiIconButton)(spacing)

const DEFAULT_PADDING = 4

const useStyles = makeStyles((theme) =>
  createStyles({
    cardContent: {
      position: 'relative',
      '&:last-child': {
        paddingBottom: (props: { pb?: number }) => {
          if (props.pb === undefined) {
            return theme.spacing(DEFAULT_PADDING)
          }
          return theme.spacing(props.pb)
        },
      },
    },
    cardRounded: {
      '&:last-child': {
        borderRadius: theme.spacing(6),
      },
    },
    buttonRounded: {
      borderRadius: theme.spacing(6),
    },
    buttonLabel: {
      padding: 0,
    },
    cardRootDefault: {
      marginBottom: theme.spacing(6),
      borderRadius: '20px',
    },
    cardRootDefaultNoShadow: {
      marginBottom: theme.spacing(6),
      borderRadius: '20px',
      boxShadow: 'none',
    },
    iconWrapper: {
      position: 'absolute',
      zIndex: 100,
      right: 4,
      top: 4,
    },
    icon: {
      color: theme.palette.secondary.contrastText,
      height: 16,
      width: 16,
    },
  }),
)

interface ConditionalButtonProps {
  wrapWithButton: boolean
  children: React.ReactElement
  rounded?: boolean
  onClick?: () => void
}

const ConditionalButton = ({
  wrapWithButton,
  children,
  rounded = false,
  onClick,
}: ConditionalButtonProps) => {
  const classes = useStyles({})
  return wrapWithButton ? (
    <Button
      onClick={onClick}
      classes={{ label: classes.buttonLabel }}
      className={rounded ? classes.buttonRounded : ''}
      p={0}
    >
      {children}
    </Button>
  ) : (
    children
  )
}

export interface Props {
  children?: React.ReactNode
  className?: string
  rounded?: boolean
  Header?: React.ElementType
  Media?: React.ElementType
  cardClasses?: { [key: string]: string }
  onClick?: () => void
  onClose?: () => void
  pb?: number
  mb?: number
  mx?: number
  pt?: number
  rest?: any[]
}

const DashboardCard = ({
  className,
  children,
  rounded,
  Header,
  Media,
  cardClasses: maybeCardClasses,
  onClick,
  onClose,
  mb,
  ...rest
}: Props) => {
  const classes = useStyles(rest)
  const defaultCardClasses = {
    root: classes.cardRootDefault,
  } as {
    [key: string]: string
  }
  const cardClasses = maybeCardClasses || defaultCardClasses

  return (
    <ConditionalButton
      wrapWithButton={Boolean(onClick)}
      rounded={rounded}
      onClick={onClick}
    >
      <Box className={className} position="relative">
        {onClose && (
          <IconButton onClick={onClose} className={classes.iconWrapper}>
            <Close className={classes.icon} />
          </IconButton>
        )}
        <Card
          className={rounded ? classes.cardRounded : ''}
          classes={cardClasses}
          mb={mb}
        >
          {Header && <Header />}
          {Media && <Media />}
          {children && (
            <CardContent
              className={clsx(classes.cardContent, cardClasses.cardContent)}
              {...rest}
            >
              {children}
            </CardContent>
          )}
        </Card>
      </Box>
    </ConditionalButton>
  )
}

export default DashboardCard
