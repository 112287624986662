import { NavigationPage } from 'app-components'
import HomeEstimation from '@/app/components/HomeEstimations/Prompt/HomeEstimation'
import { Button, Flex } from '@/components'
import useHomeEstimationNavigation, {
  HomeEstimationPages,
} from '@/app/pages/home-estimations/useHomeEstimationNavigation'
import { useCallback, useEffect } from 'react'
import { useHomeCollection } from '@/reducers/homeResource'
import LoadingAnimation from '@/components/LoadingAnimation/LoadingAnimation'
import useHomeEstimationCompletion from '@/app/components/HomeEstimations/useHomeEstimationCompletion'
import useIsMobile from '@/authenticated/hooks/useIsMobile'
import { logEvent } from '@/logging'

export default function HomeEstimationsPage() {
  const navigate = useHomeEstimationNavigation()

  const isMobile = useIsMobile()
  const home = useHomeCollection()
  const isEstimated = useHomeEstimationCompletion()

  useEffect(() => {
    logEvent('Improve_HVAC_Accuracy_Viewed')
  }, [])

  const onUpdateClick = useCallback(() => {
    logEvent('Update_Home_Estimations_Button_Clicked')
    navigate.push(HomeEstimationPages.Edit)
  }, [])

  return (
    <NavigationPage
      id="home-estimations-page"
      title="Home Estimations"
      subtitle="Below is information about your home to help us compute your energy usage"
    >
      {home && (
        <div className="h-full flex flex-col">
          <HomeEstimation variant={'menu'} forceShow={true}></HomeEstimation>
          {!isEstimated && (
            <Button
              className={isMobile ? 'mt-auto' : 'mt-6'}
              id="edit-button"
              variant="secondary"
              onClick={onUpdateClick}
            >
              Update Home Estimations
            </Button>
          )}
        </div>
      )}
      {!home && (
        <Flex container alignItems="center" justifyContent="space-around">
          <LoadingAnimation type="falling"></LoadingAnimation>
        </Flex>
      )}
    </NavigationPage>
  )
}
