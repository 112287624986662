import React from 'react'
import { connect } from 'react-redux'
import _data from '../../data'

class PrivacyHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hero: {},
    }
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

    this.setState({
      hero: _data.hero,
    })
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="hero-section pt-100 background-img"
          style={{
            backgroundImage: `url(${this.state.hero.bgImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
          }}
        >
          <div className="container">
            <div className="row align-items-center justify-content-between py-5">
              <div className="col-md-7 col-lg-6 pb-5">
                <div
                  className="hero-content-left text-white"
                  style={{ marginBottom: '150px' }}
                >
                  <h1 className="text-white">
                    <span>Privacy Policy</span>
                  </h1>
                  <p className="lead">Last updated May 7, 2024</p>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-img-absolute">
            <img src="img/hero-bg-shape-1.svg" className="img-fluid" />
          </div>
        </section>
      </React.Fragment>
    )
  }
}

export default connect()(PrivacyHeader)
