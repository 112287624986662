import { RootState } from '../store'
import selectUserCanAndHasntIntegratedUtility from './selectUserCanAndHasntIntegratedUtility'
import selectUserUtilityIntegration from './selectUserUtilityIntegration'
import { ApiProvider } from '../types/utility'

export default function selectCanShowUtilityUsage(state: RootState) {
  const apiProviderIsLeap =
    state.utilities.selectedUtility?.api_provider === ApiProvider.Leap
  const apiProviderIsValidUApi =
    state.utilities.selectedUtility?.api_provider === ApiProvider.UtilityApi &&
    state.utilities.selectedUtility?.website?.includes('coned')
  const canConnectUtility = selectUserCanAndHasntIntegratedUtility(state)
  const utilityIntegration = selectUserUtilityIntegration(state)
  const maybeCanShow =
    (apiProviderIsLeap || apiProviderIsValidUApi) &&
    (canConnectUtility || !!utilityIntegration)
  return maybeCanShow ?? false
}
