import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react'
import styled from 'styled-components'
import {
  Box as MuiBox,
  makeStyles,
  Typography as MuiTypography,
} from '@material-ui/core'
import { spacing } from '@material-ui/system'
import { Skeleton as MuiSkeleton } from '@material-ui/lab'
import { debounce } from 'lodash'
import { useDispatch } from 'react-redux'
import { Sheet, Text, Toggle } from '@/components'

const Typography = styled(MuiTypography)(spacing)
const Box = styled(MuiBox)(spacing)
const Skeleton = styled(MuiSkeleton)(spacing)

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    height: '22px',
    paddingRight: '6px',
  },
  icon: {
    height: '100%',
  },
  text: {
    fontWeight: 'bold',
    fontSize: 14,
    paddingTop: '2px',
    paddingBottom: '2px',
  },
  subtitle: {
    fontSize: 14,
    paddingTop: '2px',
    paddingBottom: '2px',
    marginRight: theme.spacing(4),
    marginTop: '2px',
  },
  textContainer: {
    flex: 1,
    paddingLeft: '6px',
    textAlign: 'left',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  textSwitchContainer: {
    width: '100%',
  },
  switchContainer: {
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}))

type TransformFunction = (model: object, field: string, checked: boolean) => any

const DEFAULT_TRANSFORM: TransformFunction = (_model, _field, checked) => {
  return checked
}

const DEFAULT_DEBOUNCE = 1000

type Props<M extends object> = {
  icon?: string
  iconComponent?: React.ReactNode
  title?: string
  subtitle?: string
  checked?: boolean | null
  disabled?: boolean
  onChange?: ((model: M) => void) | ((checked: boolean) => void)
  children?: React.ReactNode
  isLoading?: boolean
  model?: object
  field?: string
  action?: (model: object) => void
  transform?: (model: object, field: string, checked: boolean) => any
  shouldDebounce?: boolean
  debounceTime?: number
  id?: string
  newHighlight?: boolean
  overrideNewHighlightText?: string
  allowDisabledEnabled?: boolean
  badge?: ReactNode
}

/**
 * @param {String} icon - The icon source to display
 * @param {String} iconComponent - The icon source to display
 * @param {String} title - Text that is displayed as a header
 * @param {String} subtitle - Text that is displayed as a subtitle
 * @param {Boolean} checked - Default state of the switch
 * @param {Function} onChange - Function that is called when the switch is toggled
 * @param {ReactDOM} children - Any additional elements to display between icon and switch (if text style wants to be overridden)
 * @param {Boolean} disabled - Whether or not the switch is disabled
 * @param {Boolean} isLoading - Whether or not the switch is loading, a skeleton will be displayed if true
 * @param {Object} model - The model to update when the switch is toggled
 * @param {String} field - The field to update in the model
 * @param {Function} action - The action to dispatch when the switch is toggled and model / field are set
 * @param {Function} transform - A function that transforms the model field value before the action is dispatched
 * @param {Boolean} shouldDebounce - Whether or not to debounce the action
 * @param {Number} debounceTime - The time to debounce the action
 * @param {Boolean} newHighlight - Whether to display the "NEW" tag after the title
 * @param {Boolean} allowDisabledEnabled - Allows the toggle to be in "enabled" state even when disabled
 */
const IconToggleLabel = <M extends object>({
  icon,
  iconComponent,
  title,
  subtitle,
  checked = false,
  disabled = false,
  onChange,
  children,
  isLoading,
  model,
  field,
  action,
  transform = DEFAULT_TRANSFORM,
  shouldDebounce = true,
  debounceTime = DEFAULT_DEBOUNCE,
  id = '',
  newHighlight = false,
  overrideNewHighlightText = 'NEW',
  allowDisabledEnabled = false,
  badge = null,
}: Props<M>) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const typedField = field as keyof typeof model

  const shouldUseModelAction = !!field && (!disabled || allowDisabledEnabled)

  const [selected, setSelected] = useState(
    !!field && !!model ? model[typedField] : checked,
  )

  useEffect(() => {
    if (shouldUseModelAction && !!model) {
      setSelected(model[typedField])
    }
  }, [model])

  useEffect(() => {
    if (!disabled && model && field) {
      setSelected(model[typedField])
    }
  }, [field, model])

  useEffect(() => {
    if (checked !== null && !shouldUseModelAction) {
      setSelected(checked)
    }
  }, [checked])

  const debouncedModelSave = useCallback(
    debounce((model) => {
      if (action) {
        dispatch(action(model))
      }
    }, debounceTime),
    [],
  )

  const onModelChange = (checked: boolean) => {
    setSelected(checked)
    if (model) {
      const updatedModel = {
        ...model,
        [typedField]: transform(model, typedField, checked),
      }
      if (shouldDebounce) {
        debouncedModelSave(updatedModel)
      } else if (action) {
        dispatch(action(updatedModel))
      }
      if (onChange) {
        const changeFunc = onChange as (model: object) => void
        changeFunc(updatedModel)
      }
    } else if (onChange) {
      const changeFunc = onChange as (checked: boolean) => void
      changeFunc(checked)
    }
  }

  return (
    <Box className={classes.container}>
      {iconComponent}
      {icon && !iconComponent && (
        <Box className={classes.iconContainer}>
          <img src={icon} className={classes.icon} alt="Optiwatt" />
        </Box>
      )}
      <Box className={classes.textContainer}>
        {title && (
          <>
            <Text variant="body1" className={classes.text}>
              {title}{' '}
              {newHighlight && (
                <Sheet color="blue-100">
                  <Text variant="label" className="text-themed-blue-500">
                    {overrideNewHighlightText}
                  </Text>
                </Sheet>
              )}
              {badge && <div className={'inline-block'}>{badge}</div>}
            </Text>
          </>
        )}
        {subtitle && (
          <Text variant="body4" className={classes.subtitle}>
            {subtitle}
          </Text>
        )}
        {!title && !subtitle && children}
      </Box>
      <Box className={classes.switchContainer}>
        {isLoading || (shouldUseModelAction && !model) ? (
          <Skeleton animation="wave" width={48} height={32} mr={2} />
        ) : (
          <div className={'ml-4'}>
            <Toggle
              id={`${id}-switch`}
              disabled={disabled}
              checked={!!selected || false}
              onChange={onModelChange}
            />
          </div>
        )}
      </Box>
    </Box>
  )
}

export default IconToggleLabel
