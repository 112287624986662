import { RSAA } from 'redux-api-middleware'
import config from '../config'
import { withAuth } from '../reducers'

export const LIST_REQUEST = '@@plans/LIST_REQUEST'
export const LIST_SUCCESS = '@@plans/LIST_SUCCESS'
export const LIST_FAILURE = '@@plans/LIST_FAILURE'

export const SELECT_PLAN_REQUEST = '@@plans/SELECT_PLAN_REQUEST'
export const SELECT_PLAN_SUCCESS = '@@plans/SELECT_PLAN_SUCCESS'
export const SELECT_PLAN_FAILURE = '@@plans/SELECT_PLAN_FAILURE'

export const UPLOAD_PLAN_REQUEST = 'UPLOAD_PLAN_REQUEST'
export const UPLOAD_PLAN_SUCCESS = 'UPLOAD_PLAN_SUCCESS'
export const UPLOAD_PLAN_FAILURE = 'UPLOAD_PLAN_FAILURE'

export const UPLOAD_UTILITY_BILL_REQUEST = 'UPLOAD_UTILITY_BILL_REQUEST'
export const UPLOAD_UTILITY_BILL_SUCCESS = 'UPLOAD_UTILITY_BILL_SUCCESS'
export const UPLOAD_UTILITY_BILL_FAILURE = 'UPLOAD_UTILITY_BILL_FAILURE'

export const CLEAR_PLAN_ERRORS = '@@plans/CLEAR_PLAN_ERRORS'

export const getPlans = () => ({
  [RSAA]: {
    endpoint: `${config.apiGateway.URL}/api/plans/`,
    method: 'GET',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [LIST_REQUEST, LIST_SUCCESS, LIST_FAILURE],
  },
})

export const selectPlan = (planData) => ({
  [RSAA]: {
    endpoint: `${config.apiGateway.URL}/api/current_user/`,
    method: 'PATCH',
    body: JSON.stringify(planData),
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [SELECT_PLAN_REQUEST, SELECT_PLAN_SUCCESS, SELECT_PLAN_FAILURE],
  },
})

export const uploadPlans = (plans) => ({
  [RSAA]: {
    endpoint: `${config.apiGateway.URL}/api/manual_plan_upload/`,
    method: 'POST',
    body: JSON.stringify(plans),
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [UPLOAD_PLAN_REQUEST, UPLOAD_PLAN_SUCCESS, UPLOAD_PLAN_FAILURE],
  },
})

export const uploadUtilityBillForm = (data) => ({
  [RSAA]: {
    endpoint: `${config.apiGateway.URL}/api/utility_bill_upload/`,
    method: 'PUT',
    headers: withAuth({}),
    body: data,
    types: [
      UPLOAD_UTILITY_BILL_REQUEST,
      UPLOAD_UTILITY_BILL_SUCCESS,
      UPLOAD_UTILITY_BILL_FAILURE,
    ],
  },
})

export const clearPlanErrors = () => ({
  type: CLEAR_PLAN_ERRORS,
})
