import { useNavigation } from '@/app/hooks'
import { NavigationPage } from '@/app/components'
import AddUtilityPlanFlow from '@/app/features/connect-utility/AddUtilityPlanFlow'

export default function ManageUtilityPlan() {
  const navigation = useNavigation()

  const nextStep = () => {
    navigation.push('/settings')
  }

  return (
    <NavigationPage id="settings-update-plan">
      <NavigationPage.SingleCardContent>
        <AddUtilityPlanFlow onComplete={nextStep} />
      </NavigationPage.SingleCardContent>
    </NavigationPage>
  )
}
