import { combineReducers } from 'redux'
import { History } from 'history'
import auth, * as fromAuth from './auth'
import user, * as fromUser from './user'
import form, * as fromForm from './form.js'
import admin from './admin.js'
import feedback from './feedback.js'
import homes from './homes'
import manualPlans from './manualPlans.js'
import plans from './plans'
import vehicles from './vehicles'
import utilities from './utilities'
import metric, * as fromMetric from './metric.js'
import schedule from './schedule'
import calendar from './calendar.js'
import dashboardConfigs from './dashboardConfigs'
import vehicleAuth from './vehicleAuth.js'
import vehicleChargingDelayEvents from './vehicleChargingDelayEvents'
import carModels from './carModels'
import chargers from './chargers'
import energy from './energy'
import seasonalRateSummary from './seasonalRateSummary'
import usages from './usages'
import comparison from './usages/comparison'
import surveyInteractions from './surveyInteractions'
import surveyResponses from './surveyResponses'
import deletedVehicles from './deletedVehicles'
import gasCarConversion from './gasCarConversion'
import { gasPrice } from './gasPrice'
import {
  programReducer as utilityPrograms,
  searchableProgramReducer as searchableUtilityPrograms,
} from './utilityPrograms'
import utilityProgramEnrollments from './utilityProgramEnrollments'
import {
  ecobeeAuthReducer,
  honeywellAuthReducer,
  nestAuthReducer,
} from './thermostatAuth'
import thermostats from './thermostats'
import logging from './logging'
import {
  behavioralEnrollmentCollectionReducer,
  chargerEnrollmentCollectionReducer,
  thermostatEnrollmentCollectionReducer,
  vehicleEnrollmentCollectionReducer,
} from './deviceProgramEnrollments'
import chargeNowEvents from './chargeNowEvents'
import thermostatDemandResponseEvents from './thermostatDemandResponseEvents'
import thermostatComfortSettings from './thermostatComfortSettings'
import {
  thermostatPrecoolSavingsSummaries,
  thermostatSavingsSummaries,
} from './thermostatSavingsSummaries'
import thermostatSettings from './thermostatSettings'
import teslaFleetAuthentication from './teslaFleetAuthentication'
import vehicleChargers from './vehicleChargers'
import { chargeForecast, charges } from './chargeRelatedCollections'

import components from './components.js'

import themeReducer from './theme.js'
import { connectRouter } from 'connected-react-router'
import unsubscribe from './unsubscribe.js'
import baselineShiftingEvents from './baselineShiftingEvents'
import { adminUtilityPrograms } from '@/actions/adminUtiltyProgramsCollections'
import homesReducer from '@/reducers/homeResource'
import utilityAccounts from './utilityAccounts'
import homeUsages from '@/reducers/homeUsages'
import referralsReducer from './referrals'
import transactionsReducer from './transactions'
import transactionsRedemptionReducer from './transactionsRedemption'
import homeRateComparisons from '@/reducers/homeRateComparisons'
import plansCollectionReducer from './plansCollection'
import deletedThermostat from '@/reducers/deletedThermostat'
import deletedVehicleCharger from '@/reducers/deletedVehicleCharger'
import pkceReducer from '@/reducers/pkce'
import authenticateFord from '@/reducers/authenticateFord'
import tripsReducer from '@/reducers/trips'
import vehicleConnectivity from '@/reducers/vehicleConnectivity'
import avaAddressesReducer from './avaAddressesCollection'
import utilityProgramProfileEligibility from './utilityProgramProfileEligibility'
import avaSecurityQuestionReducer from './avaSecurityQuestionCollection'
import customUserReducer from './customUser'
import enrolledUtilityPrograms from '@/reducers/enrolledUtilityPrograms'
import inProgressUtilityProgramEnrollments from './inProgressUtilityProgramEnrollments'
import eligibleUtilityPrograms from '@/reducers/eligibleUtilityPrograms'
import programsNewReducer from '@/reducers/programsNew'
import { zendeskAuthCollection } from './zendeskAuth'

const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    auth: auth,
    user: user,
    customUsers: customUserReducer,
    feedback: feedback,
    form: form,
    homes: homes,
    manualPlans: manualPlans,
    plans: plans,
    vehicles: vehicles,
    utilities: utilities,
    metric: metric,
    calendar: calendar,
    schedule: schedule,
    vehicleAuth: vehicleAuth,
    delayEvents: vehicleChargingDelayEvents,
    unsubscribe: unsubscribe,
    dashboardConfigs: dashboardConfigs,
    carModels: carModels,
    chargers: chargers,
    energy: energy,
    themeReducer: themeReducer,
    admin: admin,
    seasonalRateSummary: seasonalRateSummary,
    usages: usages,
    comparison: comparison,
    surveyInteractions,
    surveyResponses: surveyResponses,
    deletedVehicles: deletedVehicles,
    gasCarConversion: gasCarConversion,
    gasPrice,
    baselineShiftingEvents: baselineShiftingEvents,
    nestAuth: nestAuthReducer,
    ecobeeAuth: ecobeeAuthReducer,
    honeywellAuth: honeywellAuthReducer,
    utilityPrograms,
    searchableUtilityPrograms,
    utilityProgramEnrollments,
    thermostats,
    components,
    logging,
    vehicleEnrollments: vehicleEnrollmentCollectionReducer,
    thermostatEnrollments: thermostatEnrollmentCollectionReducer,
    chargerEnrollments: chargerEnrollmentCollectionReducer,
    behavioralEnrollments: behavioralEnrollmentCollectionReducer,
    chargeNowEvents,
    thermostatDemandResponseEvents,
    thermostatComfortSettings,
    thermostatSavingsSummaries,
    thermostatPrecoolSavingsSummaries,
    thermostatSettings,
    teslaFleetAuthentication,
    vehicleChargers,
    chargeForecast,
    charges,
    adminUtilityPrograms,
    utilityAccounts,
    homeUsages,
    homeRateComparisons,
    referrals: referralsReducer,
    transactions: transactionsReducer,
    transactionsRedemption: transactionsRedemptionReducer,
    plansCollection: plansCollectionReducer,
    homeCollection: homesReducer,
    deletedThermostat,
    deletedVehicleCharger,
    pkce: pkceReducer,
    fordAuth: authenticateFord,
    trips: tripsReducer,
    vehicleConnectivity,
    avaAddresses: avaAddressesReducer,
    enrolledUtilityPrograms,
    utilityProgramProfileEligibility,
    avaSecurityQuestions: avaSecurityQuestionReducer,
    eligibleUtilityPrograms,
    inProgressUtilityProgramEnrollments: inProgressUtilityProgramEnrollments,
    programsNew: programsNewReducer,
    zendeskAuth: zendeskAuthCollection.slice.reducer,
  })

export type RootState = ReturnType<ReturnType<typeof createRootReducer>>

export default createRootReducer

export const refreshTokenIsValid = (state: RootState) =>
  fromAuth.isAuthenticated(state.auth)
export const accessToken = (state: RootState) =>
  fromAuth.accessToken(state.auth)
export const isAccessTokenExpired = (state: RootState) =>
  fromAuth.isAccessTokenExpired(state.auth)
export const refreshToken = (state: RootState) =>
  fromAuth.refreshToken(state.auth)
export const currentUser = (state: RootState) =>
  fromUser.currentUser(state.user)
export const isFetchingUser = (state: RootState) =>
  fromUser.isFetchingUser(state.user)
export const hasContactFormSucceeded = (state: RootState) =>
  fromForm.hasContactFormSucceeded(state.form)

export const authLoading = (state: RootState) =>
  fromAuth.authLoading(state.auth)
export const passwordResetLoading = (state: RootState) =>
  fromAuth.passwordResetLoading(state.auth)
export const validateResetLoading = (state: RootState) =>
  fromAuth.validateResetLoading(state.auth)
export const passwordResetComplete = (state: RootState) =>
  fromAuth.passwordResetComplete(state.auth)
export const authErrors = (state: RootState) => fromAuth.errors(state.auth)
export const passwordResetErrors = (state: RootState) =>
  fromAuth.passwordResetErrors(state.auth)
export const validateResetErrors = (state: RootState) =>
  fromAuth.validateResetErrors(state.auth)
export const changePasswordErrors = (state: RootState) =>
  fromAuth.changePasswordErrors(state.auth)
export const changePasswordLoading = (state: RootState) =>
  fromAuth.changePasswordLoading(state.auth)
export const changePasswordComplete = (state: RootState) =>
  fromAuth.changePasswordComplete(state.auth)

export const contactFormLoading = (state: RootState) =>
  fromForm.contactFormLoading(state.form)

export const formErrors = (state: RootState) => fromForm.errors(state.form)

export const rates = (state: RootState) => fromMetric.rates(state.metric)

export function withAuth(headers = {}) {
  return (state: RootState) => ({
    ...headers,
    Authorization: `Bearer ${accessToken(state)}`,
  })
}
