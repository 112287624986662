import { ThermostatAdjustment } from '.'

const mock: ThermostatAdjustment = {
  id: 27,
  thermostat_measurement: {
    id: 402,
    created: '2022-10-12T16:01:36.468076Z',
    modified: '2022-10-12T16:01:36.468089Z',
    indoor_temperature: 72,
    cool_setpoint: 73,
    heat_setpoint: 68,
    hvac_mode: 'cool',
    hvac_state: 'off',
    api_event_timestamp: '2022-09-15T21:03:22Z',
  },
  created: '2022-10-12T16:01:36.468076Z',
  modified: '2022-10-12T16:01:36.468089Z',
  heat_setpoint: 68,
  cool_setpoint: 70,
  outdoor_temperature: 80,
  estimated_cost_cents: 50,
  actual_cost_cents: 45,
  hold_until_time: '2022-10-13T02:00:00Z',
}

export default mock
