import { ViewModelComponentProps } from '@/utils/typeUtilities'
import UtilityProgramEnrollmentFlowRoot, {
  Props as ComponentProps,
} from './UtilityProgramEnrollment'
import useViewModel from './useViewModel'

export type Props = ViewModelComponentProps<ComponentProps, typeof useViewModel>

export default function UtilityProgramEnrollmentFlow(props: Props) {
  const viewModel = useViewModel(props)
  return <UtilityProgramEnrollmentFlowRoot {...viewModel} {...props} />
}
