import { RSAA } from 'redux-api-middleware'
import { withAuth } from '../reducers'
import config from '../config'
import { AuthCodeExchange } from '../types/authCodeExchange'
import { ThermostatBrandName } from '../types/thermostatBrands'

export function thermostatAuthActions(prefix: string, authPath: string) {
  const actionPrefix = `@@${prefix}-thermostatAuth`

  const Post = {
    Request: `@@${actionPrefix}/POST_REQUEST`,
    Success: `@@${actionPrefix}/POST_SUCCESS`,
    Failure: `@${actionPrefix}/POST_FAILURE`,
  }

  const Custom = {
    Set: `@${actionPrefix}/CUSTOM_SET`,
  }

  function post(authCodeExchange: AuthCodeExchange) {
    return {
      [RSAA]: {
        endpoint: `${config.apiGateway.URL}${authPath}`,
        method: 'POST',
        body: JSON.stringify(authCodeExchange),
        headers: withAuth({ 'Content-Type': 'application/json' }),
        types: [Post.Request, Post.Success, Post.Failure],
      },
    }
  }

  function authenticate(code: string, redirect_uri: string) {
    return post({ code, redirect_uri })
  }

  function setAuth(success: boolean) {
    return {
      type: Custom.Set,
      payload: success,
    }
  }

  return {
    actions: { authenticate, setAuth },
    types: { Post, Custom },
  }
}

export const nestAuth = thermostatAuthActions(
  'nestAuth',
  '/api/nest_authentication/',
)
export const ecobeeAuth = thermostatAuthActions(
  'ecobeeAuth',
  '/api/ecobee_authentication/',
)
export const honeywellAuth = thermostatAuthActions(
  'honeywellAuth',
  '/api/honeywell_authentication/',
)

export default function thermostatAuth(
  brand: ThermostatBrandName,
): ReturnType<typeof thermostatAuthActions> {
  return {
    [ThermostatBrandName.Nest]: nestAuth,
    [ThermostatBrandName.Ecobee]: ecobeeAuth,
    [ThermostatBrandName.Honeywell]: honeywellAuth,
  }[brand]
}
