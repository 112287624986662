import './styles.scss'
import formatClasses from '@/utils/classes/formatClasses'
import { ReactNodeProps } from '@/types/reactNodeProps'
import React, { createRef, RefObject, useRef, useState } from 'react'

export interface Props extends ReactNodeProps {
  direction?: 'row' | 'column'
  disabled?: boolean
  centered?: boolean
  initialValue?: number | null
  required?: boolean
  onClick?: (index: number | null) => void
  scrollable?: boolean
  toggleable?: boolean
  variant?: 'select' | 'buttons'
}

const defaultProps: Partial<Props> = {
  direction: 'row',
  toggleable: true,
  variant: 'select',
}

export default function ChipGroup(props: Props) {
  props = { ...defaultProps, ...props }

  const [activeChip, setActiveChip] = useState<number | null>(
    props.required ? props.initialValue ?? 0 : props.initialValue ?? null,
  )
  const chipGroupRef = useRef<HTMLDivElement>(null)
  const refs = useRef<RefObject<HTMLDivElement>[]>([])
  refs.current = React.Children.map(
    props.children,
    (_, i) => refs.current[i] ?? createRef(),
  )

  const classes = {
    chipGroup: formatClasses([
      'chip-group',
      props.onClick || props.required ? 'clickable' : undefined,
      props.centered ? 'centered' : undefined,
      props.disabled ? 'disabled' : undefined,
      props.scrollable ? 'scrollable' : undefined,
      props.variant === 'buttons' ? 'buttons' : undefined,
      props.direction === 'column' ? 'column' : undefined,
    ]),
    chip: (index: number) =>
      formatClasses([
        props.variant === 'select' && activeChip === index && !props.disabled
          ? 'active'
          : 'inactive',
      ]),
  }

  const onClick = (index: number) => {
    if (props.disabled) return

    const activeValue =
      index === activeChip && !props.required && props.toggleable ? null : index
    setActiveChip(activeValue)

    if (props.scrollable && activeValue) {
      const chipRef = refs.current[index]
      const offsetLeft =
        (chipRef.current?.offsetLeft || 0) +
        (chipRef.current?.offsetWidth || 0) / 2 -
        10
      const offsetWidth = chipGroupRef.current?.offsetWidth || 0

      chipGroupRef.current?.scrollTo({
        behavior: 'smooth',
        left: offsetLeft - offsetWidth / 2,
      })
    }

    props.onClick?.(activeValue)
  }

  return (
    <div
      className={`${classes.chipGroup} ${props.className}`}
      ref={chipGroupRef}
    >
      {React.Children.map(props.children, (child, index) => {
        return (
          <div
            className={classes.chip(index)}
            key={index}
            onClick={() => onClick(index)}
            ref={refs.current[index]}
          >
            {child}
          </div>
        )
      })}
    </div>
  )
}
