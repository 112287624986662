import { enrolledUtilityProgramsCollection } from '@/reducers/enrolledUtilityPrograms'
import { useMemo } from 'react'
import { UtilityProgram } from '@/types/utilityProgram'
import { eligibleUtilityProgramsCollection } from '@/reducers/eligibleUtilityPrograms'

/**
 * AVild - This hook exists to support the old single-program way of doing things.
 * The code Marti wrote for multi-programs and the endpoints it uses are the correct
 * way of doing things going forward - this hook and its usages exist because I
 * refactored old logic for the single-program flow and wanted a hook to re-use
 * logic between vehicle/charger/home screens.
 */
export default function useAvailableUtilityPrograms() {
  const { data: eligiblePrograms, isLoadingOrIdle: loadingPotential } =
    eligibleUtilityProgramsCollection.useFetch()
  const {
    data: enrolledUtilityWithPrograms,
    isLoadingOrIdle: loadingEnrolled,
  } = enrolledUtilityProgramsCollection.useFetch()
  const enrolledPrograms = useMemo(() => {
    if (!enrolledUtilityWithPrograms) return []
    return enrolledUtilityWithPrograms.map((program) => program.utility_program)
  }, [enrolledUtilityWithPrograms])

  const programsNotEnrolledIn = eligiblePrograms.filter(
    (program: UtilityProgram) =>
      !enrolledPrograms
        .map((enrolledProgram: UtilityProgram) => enrolledProgram.id)
        .includes(program.id),
  )

  const firstAvailableProgram =
    (programsNotEnrolledIn?.[0] as UtilityProgram) ?? null

  return {
    eligiblePrograms,
    enrolledPrograms,
    programsNotEnrolledIn,
    firstAvailableProgram,
    programsAreAvailable: !!programsNotEnrolledIn.length,
    isLoading: loadingPotential || loadingEnrolled,
  }
}
