import React from 'react'
import {
  Box as MuiBox,
  Typography as MuiTypography,
  Link,
} from '@material-ui/core'
import ConnectDeviceInformationCard from '../ConnectDeviceInformationCard'
import UtilityIntegrationLinkDiagram from '../UtilityIntegrationLinkDiagram'
import { Props as ListableInformationCardProps } from '../ConnectDeviceInformationCard/ListableInformationCard'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import data from './resources/data.json'
import { ApiProvider } from '../../../types/utility/'
import { CardActions, CardActionButton, Icon } from '../../../components'
import Lottie from 'lottie-react'
import connectUtilityAnimation from './resources/images/connect-utility-lottie.json'
import clsx from 'clsx'

const Box = styled(MuiBox)(spacing)
const Typography = styled(MuiTypography)(spacing)

const useStyles = makeStyles((theme) =>
  createStyles({
    informationCard: {
      borderRadius: '0px',
      maxHeight: 'none',
      overflow: 'visible',
    },
    informationCardHeader: {
      marginBottom: 0,
    },
    footText: {
      fontSize: '12px',
    },
    link: {
      color: theme.palette.blue?.main,
    },
    listIconContainer: {
      background: theme.palette.secondary.main,
    },
  }),
)

const ConnectUtilityAnimation = (
  <Lottie animationData={connectUtilityAnimation} loop />
)

interface Classes {
  container?: string
}

type ConnectUtilityActionButtonsProps = {
  onConnectClick: () => void
  onSkipClick?: () => void
  onBackClick?: () => void
}

function ConnectUtilityActionButtons({
  onConnectClick,
  onSkipClick,
  onBackClick,
}: ConnectUtilityActionButtonsProps) {
  return (
    <CardActions>
      {onBackClick && (
        <CardActionButton
          onClick={onBackClick}
          type="navigation"
          id="connect-utility-back-button"
        >
          <Icon name="ChevronLeft" size={20} color="grey-500" />
        </CardActionButton>
      )}
      {onSkipClick && (
        <CardActionButton
          onClick={onSkipClick}
          type="secondary"
          id="connect-utility-skip-button"
        >
          Skip
        </CardActionButton>
      )}
      <CardActionButton
        onClick={onConnectClick}
        type="primary"
        id="connect-utility-connect-button"
      >
        Connect
      </CardActionButton>
    </CardActions>
  )
}

export interface Props extends ConnectUtilityActionButtonsProps {
  utilityName: string
  utilityLogoUrl: string
  apiProviderName: string
  apiProvider: ApiProvider
  showGiveawayTerms: boolean
  informationCards: ListableInformationCardProps[]
  classes?: Classes
}

export default function ConnectUtility({
  utilityName,
  utilityLogoUrl,
  apiProviderName,
  apiProvider,
  showGiveawayTerms,
  informationCards,
  onConnectClick,
  onSkipClick,
  onBackClick,
  classes: providedClasses,
}: Props) {
  const classes = useStyles()

  return (
    <ConnectDeviceInformationCard
      id="connect-utility-information-card"
      className={clsx(providedClasses?.container, classes.informationCard)}
      padContent
      headerlessOnDesktop={false}
      media={ConnectUtilityAnimation}
      title={data.title}
      subtitle={data.subtitle}
      informationCards={informationCards}
      onClick={onConnectClick}
      classes={{
        header: classes.informationCardHeader,
        iconContainer: classes.listIconContainer,
      }}
      sticky={false}
      underTitleComponent={
        <Box py={8}>
          <UtilityIntegrationLinkDiagram
            utilityName={utilityName}
            utilityLogoUrl={utilityLogoUrl}
            apiProvider={apiProvider}
          />
        </Box>
      }
      footer={
        <Typography
          className={classes.footText}
          variant="subtitle2"
          pt={2}
          pb={6}
          align="center"
        >
          By clicking Connect, {apiProviderName}, our 3rd party provider, will
          connect to your utility company meter.
          {showGiveawayTerms && (
            <>
              <br />
              <br />
              You agree to the{' '}
              <Link
                className={classes.link}
                href="tesla-giveaway-tos"
                target="_blank"
              >
                terms
              </Link>{' '}
              of the Tesla giveaway.
            </>
          )}
        </Typography>
      }
      button={
        <ConnectUtilityActionButtons
          onConnectClick={onConnectClick}
          onSkipClick={onSkipClick}
          onBackClick={onBackClick}
        />
      }
    />
  )
}
