import { Button, Text } from '@/components'
import BrandedPage from '@/app/pages/ava/BrandedPage'
import { useNavigation } from '@/app/hooks'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import { SelectInput } from '@/app/components'
import { useSelectedUtility } from '@/hooks/useSelectedUtility'
import toExternalLink from '@/utils/to-external-link'
import { RequestStatus } from '@/request/types'
import PageLoader from '@/authenticated/components/layout/PageLoader'
import { getValidUrl } from '@/utils/objectUtilities'

type PathParams = { utility: string | undefined }

enum PlanSelectOption {
  Upload = 'Upload utility bill',
  Select = 'Select plan from list',
  Manual = 'Set rates manually',
}

function useViewModel() {
  const { data: utility, status } = useSelectedUtility()
  const utilityUrl = utility?.website
    ? toExternalLink(getValidUrl(utility?.website))
    : undefined
  const [selectedOption, setSelectedOption] = useState<PlanSelectOption | null>(
    null,
  )
  const planSelectOptions: PlanSelectOption[] = [
    PlanSelectOption.Upload,
    PlanSelectOption.Select,
    PlanSelectOption.Manual,
  ]

  const onSelectOptionChange = (optionIndex: number | null) => {
    if (optionIndex === null) {
      setSelectedOption(null)
    } else {
      setSelectedOption(planSelectOptions[optionIndex])
    }
  }

  const isLoading = [RequestStatus.Loading, RequestStatus.Idle].includes(status)

  return {
    isLoading,
    utilityUrl,
    selectedOption,
    planSelectOptions,
    onSelectOptionChange,
  }
}

export default function PlanSelectPage() {
  const {
    isLoading,
    utilityUrl,
    planSelectOptions,
    selectedOption,
    onSelectOptionChange,
  } = useViewModel()
  const navigation = useNavigation()
  const { utility } = useParams<PathParams>()

  const proceed = () => {
    const nextUrl =
      selectedOption === PlanSelectOption.Upload
        ? '/plan-select/upload'
        : selectedOption === PlanSelectOption.Select
        ? '/plan-select/select'
        : selectedOption === PlanSelectOption.Manual
        ? '/plan-select/manual-entry'
        : null

    if (!nextUrl) {
      return
    }

    if (!utility) {
      navigation.pushRetainingParams(nextUrl)
    } else {
      navigation.pushRetainingParams(`/${utility}${nextUrl}`)
    }
  }

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <BrandedPage
      title="How would you like to add your utility plan?"
      id="plan-select-page"
    >
      <BrandedPage.Content>
        {utilityUrl && (
          <Text variant="body2" className="font-medium mb-5">
            You can sign in to your utility’s website{' '}
            <a
              href={utilityUrl}
              target="_blank"
              className="text-blue-500 !underline"
              rel="noreferrer"
            >
              here
            </a>
          </Text>
        )}
        <SelectInput
          direction="column"
          options={planSelectOptions.map((o) => ({ value: o }))}
          onChange={onSelectOptionChange}
        />
        <BrandedPage.ButtonContainer>
          <Button
            id="next-button"
            variant="primary"
            disabled={false}
            onClick={proceed}
          >
            Next
          </Button>
        </BrandedPage.ButtonContainer>
      </BrandedPage.Content>
    </BrandedPage>
  )
}
