import { createSelector } from '@reduxjs/toolkit'
import { thermostatsCollection } from '../reducers/thermostats'

const selectCostHistoryForThermostat = createSelector(
  [thermostatsCollection.selectors.selectById],
  (thermostat) => {
    return thermostat?.cost_history
  },
)

export default selectCostHistoryForThermostat
