import './styles.scss'
import formatClasses from '../../utils/classes/formatClasses'
import React, { ButtonHTMLAttributes, useState } from 'react'
import { Flex } from '../index'
import { Identifiable } from '@/types'
import Check from '@/app/img/check_dark.svg'
import { motion } from 'framer-motion'
import useHasRendered from '@/hooks/useHasRendered'

const SAVE_TIME = 2000

export type ButtonVariant =
  | 'primary'
  | 'primary-subtle'
  | 'secondary'
  | 'confirmed'
  | 'accent'
  | 'accent-subtle'
  | 'icon'
  | 'navigation'
  | 'click-wrapper'
  | 'outlined'
  | 'chip'
  | 'circle-button'

export interface Props
  extends Identifiable<ButtonHTMLAttributes<HTMLButtonElement>> {
  variant?: ButtonVariant
  href?: string
  important?: boolean
  savable?: boolean
}

const defaultProps: Partial<Props> = {
  variant: 'primary',
}

export default function Button(props: Props) {
  props = { ...defaultProps, ...props }
  const { variant, children, className, onClick, ...buttonProps } = props
  const [isSaving, setIsSaving] = useState(false)

  const hasRendered = useHasRendered()

  const classes = formatClasses([
    'optiwatt-button',
    isSaving ? 'confirmed' : `${props.variant}`,
    props.className,
  ])

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (isSaving) {
      return
    }

    props.onClick?.(event)
    if (props.href) {
      window.location.href = props.href
    }
    if (props.savable) {
      setIsSaving(true)

      setTimeout(() => {
        setIsSaving(false)
      }, SAVE_TIME)
    }
  }

  const animate = props.savable && hasRendered

  return (
    <button
      className={`${classes} relative disabled:opacity-50`}
      style={props.style}
      onClick={handleClick}
      type="button"
      {...buttonProps}
    >
      <motion.div
        key={isSaving ? 'saved' : 'submit'}
        initial={animate ? { opacity: 0 } : undefined}
        animate={{ opacity: 1 }}
        exit={animate ? { opacity: 0 } : undefined}
        transition={{ duration: 0.3 }}
      >
        {isSaving ? (
          <div className="flex items-center gap-1">
            <img
              className="absolute w-[24px] h-[24px]"
              src={Check}
              alt="Check icon"
            />
            <span className="pl-[28px]">Submitted</span>{' '}
            {/* Add padding-left to make room for the icon */}
          </div>
        ) : (
          <>
            {React.Children.toArray(props.children).length > 1 && (
              <Flex container columnSpacing={1.5}>
                {React.Children.map(props.children, (child) => {
                  return <Flex item>{child}</Flex>
                })}
              </Flex>
            )}
            {React.Children.toArray(props.children).length <= 1 && (
              <>{children}</>
            )}
          </>
        )}
      </motion.div>
    </button>
  )
}
