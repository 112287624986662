import { RSAA } from 'redux-api-middleware'
import config from '../config'

export const CAR_MODEL_SUCCESS = '@@carModel/CAR_MODEL_SUCCESS'
export const CAR_MODEL_REQUEST = '@@carModel/CAR_MODEL_REQUEST'
export const CAR_MODEL_FAILURE = '@@carModel/CAR_MODEL_FAILURE'

export const getCarModels = () => ({
  [RSAA]: {
    endpoint: `${config.apiGateway.URL}/api/car_models/`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    types: [CAR_MODEL_REQUEST, CAR_MODEL_SUCCESS, CAR_MODEL_FAILURE],
  },
})
