const paths = (routes) =>
  routes.reduce((acc, route) => {
    acc.push(route)
    if (route.children) {
      acc.push(...paths(route.children))
    }
    return acc
  }, [])

export const getPathFromRoutes = (routes) => {
  const path = paths(routes)
    .filter((route) => Boolean(route.path))
    .map((route) => route.path)
    .flat()
  return path
}
