import './snow.scss'
import formatClasses from '@/utils/classes/formatClasses'
import { HTMLAttributes, useMemo } from 'react'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  variant: 'snow'
  width: string
  height: string
}

/**
 * Source: https://codepen.io/kyoyababa/pen/OWJaoB
 */
function SnowAnimation() {
  const classes = formatClasses(['g-snows'])

  return (
    <ul className={classes}>
      {Array.from({ length: 100 }).map((_, index) => {
        return <li key={index} />
      })}
    </ul>
  )
}

export default function ContainerAnimation(passedProps: Props) {
  const { width, height, variant, style, ...props } = passedProps

  const styles = {
    width: width,
    height: height,
    style,
  }

  const Animation = useMemo(
    () =>
      ({
        snow: SnowAnimation,
      }[variant]),
    [variant],
  )

  return (
    <div style={styles} {...props}>
      <Animation />
    </div>
  )
}
