import { useSelector } from 'react-redux'
import { getGraphData } from '../../Graph'
import {
  convertMonthToNumber,
  getMonthsRange,
} from '../../../../utils/rangeUtilities'
import { getMissingPeriods } from './Plan'

export const OVERLAP_MONTHS_MESSAGE = 'Months cannot overlap'
export const MISSING_MONTHS_MESSAGE =
  'Please fill in all months. Make sure you hit the + button after adding choosing a start and end month.'

export const blankPlanErrors = { planId: null, start: null, end: null }

export const getBlankPlan = () => {
  return getPlan(null, null, null)
}

export const getPlan = (planId, start, end, rates = null) => {
  if (!rates) {
    rates = { weekday: [], weekend: [] }
  }

  return {
    planId: planId,
    start: start,
    end: end,
    errors: blankPlanErrors,
    editRates: false,
    rates: rates,
  }
}

export function getOptions() {
  return {
    lineDashType: 'dash',
    maintainAspectRatio: false,
    legend: { display: false },
    scales: {
      yAxes: [
        {
          gridLines: { display: false },
          stacked: false,
          display: false,
          ticks: {
            stepSize: 1,
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          barPercentage: 0.95,
          gridLines: {
            display: false,
          },
        },
      ],
    },
  }
}

export function usePlansGraph() {
  const { plans, errorMonths: errorPeriods } = useSelector(
    (state) => state.manualPlans,
  )
  const labels = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const label = 'Months'
  const missingMonths = getMissingPeriods(labels.length, plans, getMonthsRange)
  const monthsData = getGraphData(
    getMonthsGraphData(),
    labels,
    label,
    plans,
    'planId',
    errorPeriods,
    getMonthsRange,
  )

  function getMonthsGraphData() {
    return Array(labels.length).fill(1)
  }

  function addErrors(plans) {
    let properties = ['start', 'end']
    for (let i = 0; i < plans.length; i++) {
      for (let j = 0; j < properties.length; j++) {
        let property = properties[j]
        if (errorPeriods.includes(convertMonthToNumber(plans[i][property]))) {
          plans[i]['errors'][property] = OVERLAP_MONTHS_MESSAGE
        } else {
          plans[i]['errors'][property] = null
        }
      }
    }

    return plans
  }

  return [addErrors(plans), monthsData, getOptions(), missingMonths]
}
