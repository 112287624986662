import { UtilityProgramViewConfig } from '.'
import { toTitleCase } from '@/utils/string'

export function getWhilelabeledExperienceData(
  viewConfig: UtilityProgramViewConfig,
) {
  return {
    colorScheme: viewConfig.colorScheme,
    brandLogo: viewConfig.bannerLogoUrl,
    addressSelection: viewConfig.addressSelection,
    residenceVerification: viewConfig.residenceVerification,
    externalContact: viewConfig.externalContact,
    largeBrandLogo: viewConfig.secondaryLogoUrl,
  }
}

export function sanitizeProgramName(name: string) {
  let n = name.toLowerCase()
  if (n.endsWith('program')) {
    n = n.replace('program', '')
  }
  return toTitleCase(n)
}
