import { RootState } from '@/reducers'
import { selectChargeForecast } from './selectChargeForecast'
import { getChargeStartPeriod } from '@/types/chargeForecast/utils'

/**
 * now: the charge forecast indicates charging is happening now
 * Date: the charge forecast indicates charging will start at the returned time
 * null: the charge forecast does not indicate charging will happen
 */
type ChargeStartTimeResult = 'now' | Date | null

export function selectChargeStartTime(state: RootState): ChargeStartTimeResult {
  const chargeForecast = selectChargeForecast(state)

  if (!chargeForecast) {
    return null
  }

  const firstPeriodWithShouldCharge = getChargeStartPeriod(chargeForecast)

  if (!firstPeriodWithShouldCharge) {
    return null
  }

  if (
    firstPeriodWithShouldCharge.start_datetime ===
    chargeForecast.charge_forecast_periods[0].start_datetime
  ) {
    return 'now'
  }

  return new Date(firstPeriodWithShouldCharge.start_datetime)
}
