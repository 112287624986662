import config from '@/config'
import { isNativeMobile } from '@/authenticated/hooks/useIsMobile'

export const redirectToGoogleOAuthURL = async () => {
  window.location.href = `${config.apiGateway.URL}/api/oauth/google/url${
    isNativeMobile() ? '?is_mobile=true' : ''
  }`
}
export const redirectToAppleOAuthURL = async () => {
  window.location.href = `${config.apiGateway.URL}/api/oauth/apple${
    isNativeMobile() ? '?is_mobile=true' : ''
  }`
}
