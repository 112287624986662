import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { Vehicle } from '../types/vehicle/'
import * as Sentry from '@sentry/browser'

const selectUserVehicles = createSelector(
  [(state: RootState) => state.vehicles.vehicles],
  (vehicles: Vehicle[] | null) => {
    try {
      return (vehicles ?? [])?.sort((a, b) => -a.is_selected_vehicle)
    } catch (err) {
      // Patch for issue where vehicles payload is corrupted because success type is dispatched
      // with an error payload
      Sentry.captureException(err)
      return []
    }
  },
)

export default selectUserVehicles
