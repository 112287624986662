import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import type { RootState, AppDispatch } from '../store'
import useUrlSearchParam, { useUrlSearchParams } from './useUrlSearchParam'
import { useUrlSearchParamFlagControls } from './useUrlSearchParamFlagControls'
import useCountry from './useCountry'
import useMockableViewModel from './useMockableViewModel'
import useValidatePassword from './useValidatePassword'
import useMobileVersionFlag from './useMobileVersionFlag'
import { useThermostatEnrollment } from './useThermostatEnrollment'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export {
  useCountry,
  useMockableViewModel,
  useUrlSearchParam,
  useUrlSearchParams,
  useUrlSearchParamFlagControls,
  useValidatePassword,
  useMobileVersionFlag,
  useThermostatEnrollment,
}
