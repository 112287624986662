import React from 'react'
import { UseFormRegister } from 'react-hook-form'
import './styles.css'
import { AsteriskIcon } from 'lucide-react'

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  name: string
  label: string
  register: UseFormRegister<any> // eslint-disable-line @typescript-eslint/no-explicit-any
  validationIconStatus?: boolean
  disabledColor?: string
}

export default function TextFormField({
  name,
  label,
  register,
  validationIconStatus,
  disabledColor,
  children,
  ...inputHtmlProps
}: Props) {
  return (
    <div
      className={`form-field-root !flex flex-row gap-1 ${
        inputHtmlProps.disabled
          ? `bg-base-${
              !disabledColor ? 'white' : disabledColor
            } cursor-not-allowed opacity-50`
          : ''
      }`}
    >
      <div className="flex flex-col gap-1 w-full">
        <label
          htmlFor={name}
          className="text-themed-grey-500 font-bold select-none pointer-events-none bg-transparent top-2 text-xs"
        >
          <div className="flex flex-row">
            {label}
            {inputHtmlProps.required && (
              <AsteriskIcon className="text-themed-error" size={12} />
            )}
          </div>
        </label>
        <input
          type="text"
          className="h-full w-full bg-opacity-0 border-none outline-none self-end text-sm bg-transparent text-themed-grey-500 placeholder:text-themed-grey-400"
          placeholder={inputHtmlProps.placeholder ?? ''}
          {...register(name)}
          {...inputHtmlProps}
        />
      </div>
      {children}
    </div>
  )
}

type FormIconProps = {
  children?: React.ReactNode
}

export function TextFormFieldIcon({ children }: FormIconProps) {
  return (
    <div className="flex justify-center select-none pointer-events-none items-center">
      {children}
    </div>
  )
}

TextFormFieldIcon.displayName = 'TextFormIcon'
