import { useUsersPreferredTemperature } from '@/authenticated/hooks/useUsersPreferredTemperature'
import { useAppSelector } from '@/hooks'
import { ID } from '@/types/model'
import { dateIsBetween } from '@/utils/time'
import { getDateFromTimestamp } from '@/utils/timeUtilities'
import { selectNextWithThermostatId } from '@/selectors/thermostatDemandResponseEvents'
import { ThermostatDemandResponseEvent } from '@/types'

export const mockEventExplanation =
  'You are currently participating in an event until 9:30 PM tonight. Your temperature may shift up to 3° outside your normal settings.'

function useEventDetails(event: ThermostatDemandResponseEvent | undefined) {
  if (!event) {
    return undefined
  }
  const formattedStartTime = getDateFromTimestamp(
    event.start_datetime,
    'h:mm A',
  )
  const formattedEndTime = getDateFromTimestamp(event.end_datetime, 'h:mm A')
  const endIsInToday =
    new Date(event.end_datetime).getDate() === new Date().getDate()
  const maxTempDelta = useUsersPreferredTemperature(
    event.max_temp_delta_f,
    'F',
  ).value

  const eventIsCurrentlyActive = dateIsBetween({
    date: new Date(),
    start: event?.start_datetime,
    end: event?.end_datetime,
  })

  return {
    formattedStartTime,
    formattedEndTime,
    endIsInToday,
    maxTempDelta,
    eventIsCurrentlyActive,
    event,
  }
}

export function useEventExplanation(thermostatId: ID) {
  const nextEvent = useAppSelector((state) =>
    selectNextWithThermostatId(state, thermostatId),
  )

  const eventDetails = useEventDetails(nextEvent)

  if (!eventDetails) {
    return 'No Upcoming Events'
  }

  const {
    formattedStartTime,
    formattedEndTime,
    endIsInToday,
    maxTempDelta,
    eventIsCurrentlyActive,
    event,
  } = eventDetails

  if (eventIsCurrentlyActive && event.enrolled) {
    return `You are currently participating in an event until ${formattedEndTime}.
      Your temperature may shift up to ${maxTempDelta}° outside your normal
      settings.`
  }

  if (eventIsCurrentlyActive && !event.enrolled) {
    return `There is currently an event until ${formattedEndTime}. Temperatures may shift up to ${maxTempDelta}° outside your normal settings if you would like to participate.`
  }

  if (!eventIsCurrentlyActive && !event.enrolled) {
    return `There is an upcoming event from
    ${formattedStartTime} to ${formattedEndTime}${
      endIsInToday ? '' : ' tomorrow'
    }.
    Temperatures may shift up to ${maxTempDelta}° outside your normal settings if you would like to participate.`
  }

  return `You have an upcoming event from
  ${formattedStartTime} to ${formattedEndTime}${
    endIsInToday ? '' : ' tomorrow'
  }.
  Your temperature may shift up to ${maxTempDelta}° outside your normal settings.`
}

export function useEnrolledEventDetails(thermostatId: ID) {
  const nextEvent = useAppSelector((state) =>
    selectNextWithThermostatId(state, thermostatId),
  )

  const eventDetails = useEventDetails(nextEvent)

  if (!eventDetails || !eventDetails?.event.enrolled) {
    return {
      title: 'There was an issue retrieving your event details.',
      details: 'Please try again later.',
    }
  }

  const {
    formattedStartTime,
    formattedEndTime,
    maxTempDelta,
    eventIsCurrentlyActive,
  } = eventDetails

  if (eventIsCurrentlyActive) {
    return {
      title: 'Great! Thanks for participating in this event.',
      details: `This event lasts until ${formattedEndTime}. Your temperature may shift up to ${maxTempDelta}° outside your normal settings.`,
    }
  }

  return {
    title: 'Great! Thanks for participating in the upcoming event.',
    details: `This event starts at ${formattedStartTime}. Your temperature may shift up to ${maxTempDelta}° outside your normal settings.`,
  }
}
