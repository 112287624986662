import { makeStyles } from '@material-ui/styles'
import { UsageSectionHeaderTypography } from '../../UsageSectionTypography'
import HomeComparison from './'

const useStyles = makeStyles((theme) => ({}))

const Header = () => {
  return (
    <UsageSectionHeaderTypography>
      Example Home Comparison
    </UsageSectionHeaderTypography>
  )
}

const HomeComparisonExample = () => {
  const classes = useStyles()

  const homeUsage = { usage: 359, cost: 58 }
  const carUsage = { usage: 129, cost: 16 }
  const lastHomeUsage = { usage: 416, cost: 68 }
  const lastCarUsage = { usage: 142, cost: 19 }

  return (
    <HomeComparison
      isLoading={false}
      headerRender={() => <Header />}
      {...{ homeUsage, carUsage, lastHomeUsage, lastCarUsage }}
    />
  )
}

export default HomeComparisonExample
