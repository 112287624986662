import { Card, CardProps, Grid, Icon, Text } from '@/components'
import useMockableViewModel from '@/hooks/useMockableViewModel'
import { ViewModelComponentProps } from '@/utils/typeUtilities'
import { useHistory } from 'react-router-dom'
import './styles.scss'
import { ConnectionRequirement } from '../types'

export interface ComponentProps extends CardProps {
  title: string
  featureDescription: string
  onLinkClick: () => void
  linkText: string
}

function LockedFeatureCardComponent(props: ComponentProps) {
  const { onLinkClick, linkText, featureDescription, title, ...cardProps } =
    props
  const classes = {
    textSection: 'locked-feature-card-text-section',
  }
  return (
    <Card {...cardProps}>
      <Grid
        className={classes.textSection}
        flow="column"
        templateColumns="min-content 1fr"
        gap="18px"
      >
        <Icon name="Lock" color="grey-900" size={26} />
        <Grid className={classes.textSection} flow="row" gap="18px">
          <Text variant="h3">{props.title}</Text>
          <Grid flow="row" gap="8px">
            <Text variant="body2">{props.featureDescription}</Text>
            <Text variant="link" onClick={props.onLinkClick}>
              {props.linkText}
            </Text>
          </Grid>
        </Grid>
        {/* TODO find a way to dismiss these locked features. Should require small backend rework of the dashboard config to
        be able to handle new types more easily. Right now, we need to add an option for every new card type */}
        {/* <CardNavigation
          id="locked-feature-card-navigation"
          onCloseClick={() => {}}
        /> */}
      </Grid>
    </Card>
  )
}

const LinkToSatisfyRequirement = {
  utility: '/connect-utility',
  vehicle: '/connect-vehicle',
  thermostat: '/connect-thermostat',
  meter: '/utility-program-enrollment',
  plan: '/connect-utility',
  unpendingPlan: '/settings',
  home: '/settings?action=home',
}

const TextDescribingRequirement = {
  utility: 'Select your utility',
  vehicle: 'Connect your electric vehicle',
  thermostat: 'Connect your thermostat',
  meter: 'Connect your utility',
  plan: 'Connect your utility',
  unpendingPlan: 'Plan Settings',
  home: 'Add Home',
}

const FeatureDescriptionRequirement = {
  utility: 'This feature is locked, to unlock, connect your utility.',
  vehicle: 'This feature is locked, to unlock, connect your vehicle.',
  thermostat: 'This feature is locked, to unlock, connect your thermostat.',
  meter: 'This feature is locked, to unlock, connect your meter.',
  plan: 'This feature is locked, to unlock, connect your utility and add a plan.',
  unpendingPlan:
    "Your plan is pending, please check back soon. If you can't wait, choose from an existing plan or create a new one in the Plan Settings.",
  home: 'This feature is locked, to unlock, connect your home.',
}

interface ViewModelProps {
  connectionRequirement: ConnectionRequirement
}

function useViewModel(props: ViewModelProps) {
  const history = useHistory()

  const onLinkClick = () => {
    history.push(LinkToSatisfyRequirement[props.connectionRequirement])
  }

  const featureDescription =
    FeatureDescriptionRequirement[props.connectionRequirement]

  const linkText = TextDescribingRequirement[props.connectionRequirement]

  return { onLinkClick, linkText, featureDescription }
}

function useMockViewModel(props: ViewModelProps) {
  const onLinkClick = () => {
    console.log('Link clicked')
  }
  const linkText = TextDescribingRequirement[props.connectionRequirement]
  const featureDescription =
    FeatureDescriptionRequirement[props.connectionRequirement]
  return { onLinkClick, linkText, featureDescription }
}

const mockableViewModel = useMockableViewModel({
  useViewModel,
  useMockViewModel,
})

type Props = ViewModelComponentProps<ComponentProps, typeof mockableViewModel>

export default function LockedFeatureCard(props: Props) {
  const viewModel = mockableViewModel(props)

  return <LockedFeatureCardComponent {...viewModel} {...props} />
}
