import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

export default (props) => {
  const { optIn, endTime, startTime } = props
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="demand-response-action-dialog"
    >
      <DialogTitle id="demand-response-action-dialog">
        {optIn ? 'Support the Grid' : 'Resume Vehicle Charging'}
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText style={{ marginBottom: '15px' }}>
          {optIn
            ? `Your local grid is currently experiencing high electricity demand. In order to help reduce grid stress, Optiwatt can temporarily postpone your vehicle’s charging from ${startTime} until ${endTime}.`
            : `Your local grid is currently experiencing high electricity demand. Because you have grid support enabled, Optiwatt will postpone your vehicle's charging from ${startTime} until ${endTime} to reduce grid stress.`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {optIn ? (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="brand-submit-button"
            onClick={() => props.handleAction(true)}
          >
            Opt In
          </Button>
        ) : (
          <Button onClick={() => props.handleAction(false)} color="primary">
            Opt Out
          </Button>
        )}
        {optIn ? null : (
          <Button onClick={props.handleClose} color="primary">
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
