import { useAppSelector } from '../../hooks'
import { Utility } from '../../types/utility/'

export default (
  utilitySelection: Utility | null = null,
  useSelection = false,
) =>
  useAppSelector((state) => {
    const utilityIntegration =
      state.user.user?.profile?.latest_utility_integration
    const utility = useSelection
      ? utilitySelection
      : state.utilities.selectedUtility
    return (
      !utilityIntegration &&
      utility !== null &&
      utility?.api_provider_url !== null &&
      utility?.api_provider_active === true
    )
  })
