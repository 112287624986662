import { createContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

export type NavigationContextValue = {
  /**
   * The direction of the most recent navigation. This will always be updated
   * before the navigation happens, so it can be used in determining which
   * direction to animate the navigation.
   */
  direction: 'forward' | 'backward'
  goBack: () => void
  go: (n: number) => void
  push: (url: string) => void
}

export const NavigationContext = createContext<
  NavigationContextValue | undefined
>(undefined)

type Props = {
  children: React.ReactNode
}

/**
 * Provides `goBack`, `go`, and `push` for navigating, and the direction of the navigation.
 * The provided functions navigate in a way that ensures the direction is
 * updated before the navigation happens. This allows for animating the
 * navigation based on the direction.
 */
export function NavigationContextProvider(props: Props) {
  const history = useHistory()
  const [navigationIntent, setNavigationIntent] = useState<{
    direction: 'forward' | 'backward'
    url?: string
    // number of pages to move forward when using the `go` function,
    // a negative number represents going backwards
    pagesForward?: number
  }>()

  function push(url: string) {
    setNavigationIntent({
      direction: 'forward',
      url,
    })
  }

  function goBack() {
    // set url to the previous url
    setNavigationIntent({ direction: 'backward' })
  }

  function go(n: number) {
    if (n === 0) return

    const direction = n > 0 ? 'forward' : 'backward'
    setNavigationIntent({ direction, pagesForward: n })
  }

  // When a history change happens, navigate after the navigation direction has been set
  useEffect(() => {
    if (navigationIntent?.direction === 'backward') {
      history.go(navigationIntent?.pagesForward ?? -1)
    } else if (navigationIntent?.direction === 'forward') {
      if (navigationIntent?.url) {
        history.push(navigationIntent.url)
      } else if (navigationIntent?.pagesForward) {
        history.go(navigationIntent.pagesForward)
      }
    }
  }, [navigationIntent])

  const value = useMemo(
    () => ({
      direction: navigationIntent?.direction ?? 'forward',
      push,
      goBack,
      go,
    }),
    [navigationIntent],
  )

  return (
    <NavigationContext.Provider value={value}>
      {props.children}
    </NavigationContext.Provider>
  )
}
