import './styles.scss'
import {
  CardActions,
  CardAction,
  Flex,
  Modal,
  Button,
  ButtonVariant,
  Toggle,
} from '../../../../components'
import formatClasses from '../../../../utils/classes/formatClasses'
import { ReactNodeProps } from '../../../../types/reactNodeProps'
import { ViewConfigButton, ViewConfigModal } from '@/types/utilityProgram'
import AlertMessage from '@/app/components/AlertMessage'
import { ConfigurableButton, ConfigurableText } from '@/app/components'
import FeedbackMessageSurvey from '@/components/FeedbackMessageSurvey'
import { EnrollErrorMessage } from './EnrollErrorMessage'

type ControlMode = 'toggle' | 'button'

export interface Props extends Omit<ReactNodeProps, 'children'> {
  title: string
  description: string
  programLogoUrl: string | null
  isEnrolled: boolean
  onEnrollToggleChange: (checked: boolean) => void
  onButtonClick: () => void
  buttonConfig?: ViewConfigButton
  buttonVariant?: ButtonVariant
  enrolledAlert?: string
  disenrollConfirmationConfig?: ViewConfigModal
  disenrollConfirmationOpen: boolean
  closeDisenrollConfirmation: () => void
  confirmDisenroll: () => void
  includeHeader?: boolean
  controlMode?: ControlMode
  enrollError?: string | { detail: string }
  unenrollSurveyOpen: boolean
  closeSurvey: () => void
}

export default function UtilityProgramEnrollmentTogglePrompt({
  title,
  description,
  programLogoUrl,
  isEnrolled,
  onEnrollToggleChange,
  buttonConfig,
  buttonVariant,
  onButtonClick,
  enrolledAlert,
  disenrollConfirmationConfig,
  disenrollConfirmationOpen,
  closeDisenrollConfirmation,
  confirmDisenroll,
  includeHeader,
  controlMode = 'toggle',
  unenrollSurveyOpen,
  closeSurvey,
  enrollError,
  ...restProps
}: Props) {
  const classes = {
    logoContainer: formatClasses(['enrollment-toggle-prompt-logo-container']),
    logo: formatClasses(['enrollment-toggle-prompt-logo']),
    description: formatClasses(['enrollment-toggle-prompt-description']),
  }

  return (
    <>
      <Flex
        id="utility-program-enrollment-toggle-prompt"
        container
        direction="column"
        rowSpacing={2}
        {...restProps}
      >
        <div className="[&:not(:empty)]:mt-4">
          <EnrollErrorMessage error={enrollError} />
        </div>
        {includeHeader && (
          <Flex item>
            <ConfigurableText config={title} variant="h3" />
          </Flex>
        )}
        <Flex item>
          <Flex
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex item className={classes.logoContainer}>
              {programLogoUrl && (
                <img
                  src={programLogoUrl}
                  alt="Utility Program Logo"
                  className={classes.logo}
                />
              )}
            </Flex>
            {controlMode === 'toggle' && (
              <Flex item>
                <Toggle
                  id={'utility-enrollment-toggle'}
                  checked={isEnrolled}
                  onChange={onEnrollToggleChange}
                />
              </Flex>
            )}
          </Flex>
        </Flex>
        <Flex item>
          <ConfigurableText
            config={description}
            className={classes.description}
            variant="body2"
          />
        </Flex>

        {controlMode === 'button' && (
          <Flex item>
            <ConfigurableButton
              id="charge-schedule-enrollment-prompt-button"
              variant={buttonVariant ?? 'primary'}
              config={buttonConfig}
              onClick={onButtonClick}
            >
              Get Started
            </ConfigurableButton>
          </Flex>
        )}

        <Flex item>
          <AlertMessage variant="info" show={Boolean(enrolledAlert)}>
            {enrolledAlert}
          </AlertMessage>
          {disenrollConfirmationConfig && (
            <Modal
              title={disenrollConfirmationConfig.title}
              open={disenrollConfirmationOpen}
              onClose={closeDisenrollConfirmation}
              id={'utility-program-disenroll-confirmation-modal'}
            >
              <ConfigurableText
                className="!mb-3"
                variant="body2"
                config={disenrollConfirmationConfig.description}
              >
                Are you sure you want to disenroll from this program?
              </ConfigurableText>
              <CardActions>
                <CardAction type="primary">
                  <Button
                    variant="primary"
                    onClick={closeDisenrollConfirmation}
                    id={'utility-program-disenroll-confirmation-back-button'}
                  >
                    Back
                  </Button>
                </CardAction>
                <CardAction type="primary">
                  <Button
                    variant="secondary"
                    onClick={confirmDisenroll}
                    style={
                      disenrollConfirmationConfig.primaryButton
                        ? {
                            backgroundColor:
                              disenrollConfirmationConfig.primaryButton
                                .backgroundColor,
                            color:
                              disenrollConfirmationConfig.primaryButton.color,
                          }
                        : {}
                    }
                    id={
                      'utility-program-disenroll-confirmation-disenroll-button'
                    }
                  >
                    {disenrollConfirmationConfig.primaryButton?.text ??
                      'Disenroll'}
                  </Button>
                </CardAction>
              </CardActions>
            </Modal>
          )}
        </Flex>
      </Flex>
      <FeedbackMessageSurvey
        onClose={closeSurvey}
        open={unenrollSurveyOpen}
        onSubmit={closeSurvey}
        surveyType={'ProgramUnenrollment'}
      />
    </>
  )
}
