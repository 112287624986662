import { Link, NavigationPage } from 'app-components'
import { Card, Divider, Text } from '@/components'
import React, { useState } from 'react'
import IconToggleLabel from '@/authenticated/components/IconToggleLabel'
import { Heart } from 'react-feather'
import Piggy from '@/authenticated/images/simple_piggy.svg?react'
import Solar from '@/authenticated/images/solar-icon.svg?react'
import { useFeatureFlag } from '@/hooks/useFeatureFlag'
import ComparePlansDialog from '@/authenticated/components/ChargeSchedule/ComparePlansDialog'
import useVehicleSettingsManager from '@/hooks/useVehicleSettingsManager'
import VehiclePage from '@/app/components/VehiclePage/VehiclePage'
import { VehicleChargeSchedule } from '@/types'
import { cloneDeep } from 'lodash'
import Target from '@/app/img/target.svg'

function VehicleGoals() {
  const { chargeSchedule, saveChargeSchedule } = useVehicleSettingsManager()
  const showSolar = useFeatureFlag({
    flag: 'show_solar_plan',
    experimentalValue: true,
  })

  const [dialogOpen, setDialogOpen] = useState(false)
  const [localChargeSchedule, setLocalChargeSchedule] = useState(
    cloneDeep(chargeSchedule),
  )

  const save = (chargeSchedule: VehicleChargeSchedule) => {
    setLocalChargeSchedule(chargeSchedule)
    saveChargeSchedule(chargeSchedule)
  }

  return (
    <>
      <Card>
        <div className="flex gap-2 mb-6">
          <img src={Target} alt="target-img" />
          <Text variant="h3">Goals</Text>
        </div>
        <IconToggleLabel
          id="save-the-planet-plan"
          iconComponent={
            <Heart style={{ width: 20, marginRight: 20 }} color="#FB42D2" />
          }
          title="Save the Planet"
          shouldDebounce={true}
          subtitle="Charge during times of the lowest CO₂ emissions"
          model={localChargeSchedule}
          field="save_the_planet_plan_enabled"
          checked={false}
          onChange={save}
        />
        <Divider className="my-4" />
        <IconToggleLabel
          id="save-money-plan"
          iconComponent={<Piggy style={{ width: 20, marginRight: 20 }} />}
          shouldDebounce={true}
          title="Save Money"
          subtitle="Charge during the cheapest rates"
          model={localChargeSchedule}
          field="money_saving_plan_enabled"
          checked={false}
          onChange={save}
        />
        {showSolar && (
          <>
            <Divider className="my-4" />
            <IconToggleLabel
              id="solar-surplus-plan"
              iconComponent={<Solar style={{ width: 20, marginRight: 20 }} />}
              shouldDebounce={true}
              title="Solar Surplus"
              subtitle="Charge when your solar is overproducing"
              model={localChargeSchedule}
              field="solar_surplus_plan_enabled"
              checked={false}
              onChange={save}
              newHighlight
            />
          </>
        )}
        <Divider className="mt-4 mb-6" />
        <div className="flex justify-center gap-4">
          <Text variant="body4">Which goal is for you?</Text>
          <Link to="" onClick={() => setDialogOpen(true)}>
            Compare Goals
          </Link>
        </div>
      </Card>
      <ComparePlansDialog
        id="compare-plans-dialog"
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        includeScheduleDeparture
      />
    </>
  )
}

export default function VehicleGoalsPage() {
  return (
    <NavigationPage
      title="Goals"
      subtitle="Set charging goals with Optiwatt. Tell us what matters most to you."
      id="vehicle-goals-page"
    >
      <VehiclePage>
        <VehicleGoals />
      </VehiclePage>
    </NavigationPage>
  )
}
