type ToDayRangeOptions = {
  multiDaySeparator?: string
  rangeSeparator?: string
  useMissingValuesInstead?: boolean
}

const defaultOptions = {
  multiDaySeparator: '–',
  rangeSeparator: ', ',
  useMissingValuesInstead: false,
}

export default function toDayRange(
  values: number[],
  options?: ToDayRangeOptions,
) {
  const { multiDaySeparator, rangeSeparator, useMissingValuesInstead } = {
    ...defaultOptions,
    ...options,
  }
  const missingValues = [...Array(7).keys()].filter(
    (value) => !values.includes(value),
  )
  const valuesOrMissingValues = useMissingValuesInstead ? missingValues : values
  // converts values from zero indexed on sunday to monday indexed
  const mondayIndexedValues = valuesOrMissingValues
    .map((value) => (value === 0 ? 6 : value - 1))
    .sort((a, b) => a - b)
  const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  const ranges = mondayIndexedValues.reduce(
    (dayRanges, dayNumber, idx, dayNumbers) => {
      if (idx > 0 && dayNumber - dayNumbers[idx - 1] === 1) {
        dayRanges[dayRanges.length - 1][1] = dayNumber
      } else {
        dayRanges.push([dayNumber])
      }
      return dayRanges
    },
    [] as number[][],
  )

  return ranges
    .map((range) => {
      // If there is only one number in the range, or the numbers are not consecutive, separate them with the rangeSeparator
      if (
        range.length === 1 ||
        !(range.length === 2 && Math.ceil((range[0] + range[1]) / 2) < range[1])
      ) {
        // Convert Saturday AND Sunday into 'Weekends'
        if (
          range.length === 2 &&
          range.every((dayIdx) => [5, 6].includes(dayIdx))
        ) {
          return 'Weekends'
        }
        return range
          .map((dayNumber) => daysOfWeek[dayNumber])
          .join(rangeSeparator)
      } else {
        if (range.every((dayIdx) => [0, 4].includes(dayIdx))) {
          return 'Weekdays'
        }
        return range
          .map((dayNumber) => daysOfWeek[dayNumber])
          .join(multiDaySeparator)
      }
    })
    .join(rangeSeparator)
}
