import dayjs, { Dayjs } from 'dayjs'
import {
  ChargeForecast,
  ChargeForecastPeriod,
  ChargeForecastPeriodMetric,
} from '.'

const SCHEDULE_DEPARTURE_TIME_BUFFER_MINUTES = 5
// Sometimes the charge forecast will have an end battery percent that  is
// almost the battery target, but not quite. To account for this, we add a
// padding to the battery target.
const MAX_BATTERY_PERCENT_PADDING = 2

export function allMetricsForPeriod(
  period: ChargeForecastPeriod,
  startOrEnd: 'start' | 'end',
) {
  return [
    period[`${startOrEnd}_standard_metric`],
    period[`${startOrEnd}_cheapest_rate_metric`],
    period[`${startOrEnd}_smart_charge_metric`],
  ]
}

/**
 * Check if the battery percent has reached the target.
 * @param batteryPercent
 * @param batteryTarget
 * @returns
 */
export function batteryPercentHasReachedTarget(
  batteryPercent: number,
  batteryTarget: number,
) {
  return batteryPercent >= batteryTarget - MAX_BATTERY_PERCENT_PADDING
}

/**
 * Check if the period is at the battery target or the scheduled Ready By time.
 * @param chargeForecast
 * @param period
 * @returns
 */
export function periodIsValidChargeEnd(
  chargeForecast: ChargeForecast,
  period: ChargeForecastPeriod,
) {
  const batteryTarget = chargeForecast.max_battery_target

  const metrics = allMetricsForPeriod(period, 'end')
  const batteryPercent = Math.max(
    ...metrics.map((metric) => metric?.battery_percent ?? 0),
  )

  const periodReachedTarget = batteryPercentHasReachedTarget(
    batteryPercent,
    batteryTarget,
  )

  if (periodReachedTarget) {
    return true
  }

  if (!chargeForecast.departure_time_utc) {
    return false
  }

  const scheduledDepartureTime = new Date(chargeForecast.departure_time_utc)

  const periodIsAtScheduledDepartureTime =
    new Date(period.end_datetime).getTime() >=
    scheduledDepartureTime.getTime() -
      SCHEDULE_DEPARTURE_TIME_BUFFER_MINUTES * 60 * 1000

  return periodIsAtScheduledDepartureTime
}

export function getBatteryTargetPeriod(chargeForecast: ChargeForecast) {
  const periods = chargeForecast.charge_forecast_periods
  const batteryTarget = chargeForecast.max_battery_target

  // Find the max battery percent reached for this forecast.
  const maxBatteryPercentEndsMetrics = periods.map((period) => {
    const metrics = allMetricsForPeriod(period, 'end')
    const maxBatteryPercent = Math.max(
      ...metrics.map((metric) => metric?.battery_percent ?? 0),
    )
    return maxBatteryPercent
  })
  const maxBatteryPercentEnd = Math.max(...maxBatteryPercentEndsMetrics)

  // Make sure the max battery percent reached is at or above the battery target.
  if (!batteryPercentHasReachedTarget(maxBatteryPercentEnd, batteryTarget)) {
    return null
  }

  const periodBatteryTargetReached = periods.find((period) =>
    allMetricsForPeriod(period, 'end').some(
      (metric) => metric?.battery_percent === maxBatteryPercentEnd,
    ),
  )

  return periodBatteryTargetReached ?? null
}

export function getChargeStartPeriod(chargeForecast: ChargeForecast) {
  const periods = chargeForecast.charge_forecast_periods
  const pluggedIn = chargeForecast.plugged_in

  if (!periods.length) {
    return null
  }

  if (!pluggedIn) {
    return null
  }

  const metricShouldCharge = (metric?: ChargeForecastPeriodMetric) => {
    return metric?.should_charge === true
  }

  const periodHasShouldChargeMetric = (period: ChargeForecastPeriod) =>
    allMetricsForPeriod(period, 'start').some(metricShouldCharge)

  const firstPeriodWithShouldCharge = periods.find(periodHasShouldChargeMetric)

  if (!firstPeriodWithShouldCharge) {
    return null
  }

  return firstPeriodWithShouldCharge
}

export function getLastChargingPeriod(chargeForecast: ChargeForecast) {
  const periods = chargeForecast.charge_forecast_periods
  const pluggedIn = chargeForecast.plugged_in

  if (!periods.length) {
    return null
  }

  if (!pluggedIn) {
    return null
  }

  const metricShouldCharge = (metric?: ChargeForecastPeriodMetric) => {
    return metric?.should_charge === true
  }

  const periodHasShouldChargeMetric = (period: ChargeForecastPeriod) =>
    allMetricsForPeriod(period, 'end').some(metricShouldCharge)

  // return last period with a metric with should_charge = true
  const lastPeriodWithShouldCharge = periods
    .slice()
    .reverse()
    .find(periodHasShouldChargeMetric)

  if (!lastPeriodWithShouldCharge) {
    return null
  }

  return lastPeriodWithShouldCharge
}

export function getChargeEndPeriod(chargeForecast: ChargeForecast | undefined) {
  if (!chargeForecast) {
    return null
  }
  const periods = chargeForecast.charge_forecast_periods
  const pluggedIn = chargeForecast.plugged_in

  if (!periods?.length) {
    return null
  }

  if (!pluggedIn) {
    return null
  }

  const lastChargingPeriod = getLastChargingPeriod(chargeForecast)

  if (!lastChargingPeriod) {
    return null
  }

  const lastChargingPeriodIsEnd = periodIsValidChargeEnd(
    chargeForecast,
    lastChargingPeriod,
  )

  return lastChargingPeriodIsEnd ? lastChargingPeriod : null
}

export function getChargeStartPeriodMetric(
  chargeForecast: ChargeForecast | undefined,
) {
  if (!chargeForecast) {
    return null
  }
  const period = getChargeStartPeriod(chargeForecast)

  if (!period) {
    return null
  }

  return (
    allMetricsForPeriod(period, 'start').find(
      (metric) => metric?.should_charge === true,
    ) ?? null
  )
}

export function getChargeEndPeriodMetric(
  chargeForecast: ChargeForecast | undefined,
) {
  if (!chargeForecast) {
    return null
  }
  const period = getChargeEndPeriod(chargeForecast)

  if (!period) {
    return null
  }

  return (
    allMetricsForPeriod(period, 'end').find(
      (metric) => metric?.should_charge === true,
    ) ?? null
  )
}

export function getBatteryTargetETA(
  chargeForecast: ChargeForecast | undefined,
) {
  if (!chargeForecast) {
    return null
  }
  const batteryTargetPeriod = getBatteryTargetPeriod(chargeForecast)

  if (!batteryTargetPeriod) {
    return null
  }

  return new Date(batteryTargetPeriod.end_datetime)
}

/**
 * Gets relative weekday in terms of the current day
 * @param day
 * @returns relative weekday of day
 * ex: today | tomorrow | yesterday | Sunday...
 */
export const getRelativeDay = (day: Dayjs | null | undefined) => {
  if (!day) return null

  const weekDayNames = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]

  const currentDate = dayjs()
    .set('hour', 0)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0)
  const timelessDay = day
    .set('hour', 0)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0)
  const diff = currentDate.diff(timelessDay, 'day')

  if (diff === 0) {
    return 'today'
  } else if (diff === -1) {
    return 'tomorrow'
  } else if (diff === 1) {
    return 'yesterday'
  }

  return weekDayNames[timelessDay.day()]
}

export const isPast = (day: Dayjs | null | undefined) => {
  return dayjs().isAfter(day)
}

/**
 * Finds the difference between day1 and day2
 * @param day1
 * @param day2
 * @returns the difference between dates as an int
 */
export const getAbsoluteDiff = (
  day1: Dayjs | null | undefined,
  day2: Dayjs | null | undefined,
) => {
  const timelessDay1 = day1
    ?.set('hour', 0)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0)

  const timelessDay2 = day2
    ?.set('hour', 0)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0)

  return timelessDay1?.diff(timelessDay2, 'day')
}
