import Faq from '../Faq'

export default function PowerSouthFaq() {
  const questions = [
    {
      question: 'What is PowerSouth’s EV Charging Research Pilot',
      answer:
        'PowerSouth is looking to understand the impacts of EV charging on the electrical grid through an EV charging research pilot.',
    },
    {
      question: 'Why is PowerSouth running the pilot?',
      answer:
        'PowerSouth is interested in learning how and when EV owners charge their EVs and what possibilities exist to manage the increased demands on the electrical grid. Throughout the pilot, opportunities may arise for EV owners to shift their charging schedules and earn rewards. More information will be shared with participants upon enrollment into the pilot through the Optiwatt app.',
    },
    {
      question: 'Who is PowerSouth Energy Cooperative?',
      answer:
        'PowerSouth Energy Cooperative is a generation and transmission (G&T) cooperative that provides reliable and affordable wholesale power to 16 distribution cooperatives and 4 municipals in Alabama and Florida.',
    },
    {
      question:
        'Which utilities are participating in the EV charging research pilot?',
      answer:
        '13 distribution cooperatives and 1 municipal who get their wholesale power from PowerSouth Energy Cooperative are participating. These systems are: CHELCO, City of Elba, Clarke Washington EMC, Coosa Valley EC, Covington EC, Dixie EC, Escambia River EC, Gulf Coast EC, Pioneer EC, South Alabama EC, Southern Pine EC, Tallapoosa River EC, West Florida EC and Wiregrass EC.',
    },
    {
      question: 'How long is the pilot?',
      answer:
        'The pilot will launch in July 2023 and last approximately 1 year, ending in July 2024',
    },
    {
      question: 'How many vehicles can participate in the pilot?',
      answer:
        'PowerSouth is looking for up to 250 EVs across its service territory to participate in the pilot.',
    },
    {
      question:
        'If I agree to participate in the PowerSouth pilot with Optiwatt, what incentive will I receive?',
      answer:
        'Each participating vehicle will receive $25 in rewards for enrolling and participating in the pilot ($10 at sign-up and $15 if you are enrolled through the duration of the pilot.)',
    },
    {
      question: 'When will I receive my incentive?',
      answer:
        'You will receive $10 per vehicle in Optiwatt rewards once you have been confirmed eligible for pilot participation. This can take up to a few weeks after initial enrollment. You will receive the remaining $15 per vehicle at the end of the pilot, in approximately July 2024, after completing the end-of-program survey.',
    },
    {
      question: 'How will this affect my charging schedule?',
      answer:
        'This is a research pilot program, with no effect on charging behavior. You are free to continue charging your vehicle(s) as you normally would.',
    },
    {
      question: 'Will this prevent me from charging my EV?',
      answer:
        "Not at all! If you choose to use Optiwatt's optional charging features, Optiwatt will prioritize your departure time and charge limit preference to ensure you always have the battery you need.",
    },
    {
      question: 'How do I participate in the program?',
      answer:
        'To participate, simply connect your EV to the Optiwatt mobile or desktop app and enable the program enrollment toggle in the “Charging” section of the app. No additional steps are required, Optiwatt will automatically handle the rest.',
    },
    {
      question: 'How do I stop participating in the program?',
      answer:
        'You are able to disenroll from the program easily with one-click in the “Charging” section of the mobile or desktop app. Alternatively, you may email <a href="mailto:support@getoptiwatt.com">support@optiwatt.com</a> to disenroll at any time.',
      html: true,
    },
    {
      question:
        'If I have more than one Electric Vehicle, am I eligible for two incentives?',
      answer:
        'Yes, you can receive the incentive for each battery electric vehicle in your household. To register additional vehicles, simply connect the vehicle to Optiwatt in the “Profile” section of the mobile app or desktop site and you will be automatically qualified.',
    },
    {
      question: 'If I have further questions, who should I contact?',
      answer:
        'Further questions should be directed to Optiwatt by emailing <a href="mailto:support@getoptiwatt.com">support@optiwatt.com</a> ',
      html: true,
    },
  ]

  return (
    <Faq
      name="PowerSouth"
      title="PowerSouth EV Research Pilot"
      questions={questions}
    />
  )
}
