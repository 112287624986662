import './styles.scss'
import { Button, Icon, Flex } from '../index'
import { ReactNodeProps } from '../../types/reactNodeProps'
import formatClasses from '../../utils/classes/formatClasses'
import { Identifiable } from '@/types'

export interface Props extends ReactNodeProps, Identifiable {
  onBackClick?: () => void
  onCloseClick?: () => void
  color?: string
}

export default function CardNavigation(props: Props) {
  const classes = {
    root: formatClasses([
      'card-navigation',
      props.children ? 'has-header' : undefined,
      props.className,
    ]),
    button: (direction: 'left' | 'right') =>
      formatClasses(['navigation-button', direction]),
    header: formatClasses(['navigation-header']),
  }

  const iconColor = props.color ?? 'grey-900'

  return (
    <Flex
      className={classes.root}
      container
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <Flex item className={classes.button('left')}>
        {props.onBackClick && (
          <Button
            variant="icon"
            onClick={props.onBackClick}
            id={`${props.id}-back-button`}
          >
            <Icon color={iconColor} name="ChevronLeft" size={20} />
          </Button>
        )}
        {props.children && (
          <div className={classes.header}>{props.children}</div>
        )}
      </Flex>
      <Flex item className={classes.button('right')}>
        {props.onCloseClick && (
          <Button
            variant="icon"
            onClick={props.onCloseClick}
            id={`${props.id}-close-button`}
          >
            <Icon color={iconColor} name="X" size={20} />
          </Button>
        )}
      </Flex>
    </Flex>
  )
}
