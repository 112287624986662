import { useAppDispatch, useMockableViewModel } from '@/hooks'
import useProfileId from '@/hooks/useProfileId'
import { useNavigation } from '@/app/hooks'
import {
  AvaSecurityQuestion,
  avaSecurityQuestionCollection,
  AvaSecurityQuestionValidation,
} from '@/reducers/avaSecurityQuestionCollection'
import { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { RequestStatus, ResourceModelQueryResult } from '@/request/types'
import { fetchAuthenticated } from '@/utils/fetch'
import { debounce } from 'lodash'
import { getCurrentUser } from '@/actions/user'
import { getUtilities } from '@/actions/utilities'
import { getPlans } from '@/actions/plans'

type FormFields = {
  name: string
  email: string
}

export type LabeledSecurityQuestion = {
  name: string
  email: string
  label: string
}

export enum AvaQuestionStatus {
  IDLE,
  LOADING,
  INVALID,
  VALID,
}

export function verificationResponseToLabeledQuestions(
  response: ResourceModelQueryResult<AvaSecurityQuestion>[],
) {
  const data = response?.[0]
  if (!data) {
    return []
  }

  const max = Math.max(data.name?.length, data.email?.length)

  return Array.from({ length: max }, (_, index) => ({
    name: data?.name?.[index],
    email: data?.email?.[index],
    label: `Primary ${index + 1}`,
  })) as LabeledSecurityQuestion[]
}

function onFieldChange(
  event: React.ChangeEvent<HTMLInputElement>,
  currentQuestion: LabeledSecurityQuestion | undefined,
  currentField: keyof LabeledSecurityQuestion,
  profileId: number | null,
  setStatus: (status: AvaQuestionStatus) => void,
  setError: (text: string) => void,
) {
  const value = event?.target?.value
  if (!value || !currentQuestion) {
    return
  }
  if (value.length !== currentQuestion?.[currentField].length) {
    setStatus(AvaQuestionStatus.INVALID)
    return
  }

  setStatus(AvaQuestionStatus.LOADING)
  fetchAuthenticated(`/ava_security_questions/${profileId}`, {
    method: 'POST',
    body: JSON.stringify({
      [currentField]: value,
      profile_id: profileId,
    }),
  })
    .then((response) => {
      if (response.status !== 200) {
        throw new Error()
      }
      return response.json()
    })
    .then((response: AvaSecurityQuestionValidation) => {
      setStatus(
        response.email || response.name
          ? AvaQuestionStatus.VALID
          : AvaQuestionStatus.INVALID,
      )
    })
    .catch(() => {
      setStatus(AvaQuestionStatus.INVALID)
      setError('An error occurred. Please try re-entering your answers.')
    })
}

function useViewModel() {
  const profileId = useProfileId()
  const { push: navigateTo } = useNavigation()
  const { data: questionFetchResponse, status: fetchQuestionStatus } =
    avaSecurityQuestionCollection.useFetch(profileId ?? -1, {
      require: profileId !== null,
    })
  const [currentQuestion, setCurrentQuestion] =
    useState<LabeledSecurityQuestion>()
  const [nameStatus, setNameStatus] = useState(AvaQuestionStatus.IDLE)
  const [emailStatus, setEmailStatus] = useState(AvaQuestionStatus.IDLE)
  const [error, setError] = useState<string>()
  const [helpModalOpen, setHelpModalOpen] = useState(false)
  const dispatch = useAppDispatch()
  const nextPage = '/ava/onboarding'

  const { register, reset: resetForm } = useForm<FormFields>()

  const labelledQuestions = useMemo(() => {
    return verificationResponseToLabeledQuestions(questionFetchResponse)
  }, [questionFetchResponse])

  const isLoading =
    fetchQuestionStatus === RequestStatus.Loading ||
    fetchQuestionStatus === RequestStatus.Idle

  const success =
    [nameStatus, emailStatus].includes(AvaQuestionStatus.VALID) &&
    ![nameStatus, emailStatus].includes(AvaQuestionStatus.LOADING)

  const showSkipMessage =
    (nameStatus === AvaQuestionStatus.INVALID ||
      emailStatus === AvaQuestionStatus.INVALID) &&
    !success

  function onCarouselChange(carouselItem: LabeledSecurityQuestion) {
    resetForm()
    setCurrentQuestion(carouselItem)
  }

  const onNext = async () => {
    if (success) {
      // Update the user -- if they were verified, they have had their
      // plan set, and we want to know that in the future
      await Promise.all([
        dispatch(getUtilities()),
        dispatch(getPlans()),
        dispatch(getCurrentUser()),
      ])
    }
    navigateTo(nextPage)
  }

  const debouncedNameChange = useMemo(
    () =>
      debounce(
        (event: React.ChangeEvent<HTMLInputElement>) =>
          onFieldChange(
            event,
            currentQuestion,
            'name',
            profileId,
            setNameStatus,
            setError,
          ),
        500,
      ),
    [currentQuestion, profileId],
  )

  const debouncedEmailChange = useMemo(
    () =>
      debounce(
        (event: React.ChangeEvent<HTMLInputElement>) =>
          onFieldChange(
            event,
            currentQuestion,
            'email',
            profileId,
            setEmailStatus,
            setError,
          ),
        500,
      ),
    [currentQuestion, profileId],
  )

  function onNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!currentQuestion) {
      return
    }
    debouncedNameChange(event)
  }

  function onEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!currentQuestion) {
      return
    }
    debouncedEmailChange(event)
  }

  const openModal = () => setHelpModalOpen(true)
  const closeModal = () => setHelpModalOpen(false)

  return {
    currentQuestion,
    labelledQuestions,
    formRegister: register,
    isLoading,
    onCarouselChange,
    onNext,
    onNameChange,
    onEmailChange,
    nameStatus,
    emailStatus,
    error,
    showSkipMessage,
    success,
    openModal,
    closeModal,
    helpModalOpen,
    nextPage,
  }
}

function useMockViewModel() {
  const { register } = useForm<FormFields>()
  return {
    currentQuestion: {
      name: 'test',
      email: 'test@test.com',
      label: 'Primary 1',
    } as LabeledSecurityQuestion,
    labelledQuestions: [
      {
        name: 'test',
        email: 'test@test.com',
        label: 'Primary 1',
      } as LabeledSecurityQuestion,
    ],
    formRegister: register,
    isLoading: false,
    onCarouselChange: () => alert('Carousel changed!'),
    onNext: async () => alert('Next clicked!'),
    onNameChange: () => alert('Name changed!'),
    onEmailChange: () => alert('Email changed!'),
    nameStatus: AvaQuestionStatus.IDLE,
    emailStatus: AvaQuestionStatus.IDLE,
    error: '',
    showSkipMessage: false,
    success: false,
    openModal: () => alert('Modal opened!'),
    closeModal: () => alert('Modal closed!'),
    helpModalOpen: false,
    nextPage: '/ava/onboarding',
  }
}

export default useMockableViewModel({
  useViewModel,
  useMockViewModel,
})
