import React from 'react'
import { Box as MuiBox, Grid as MuiGrid } from '@material-ui/core'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import optiwatt from '../../images/logo-colored.webp'

const Box = styled(MuiBox)(spacing)
const Grid = styled(MuiGrid)(spacing)

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: '100%',
    },
    logoContainer: {
      background: '#ffffff',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      borderRadius: '8px',
      width: '100px',
      height: '40px',
    },
    leapLogoContainer: {
      width: '66px',
      height: '66px',
    },
    logoImg: {
      maxHeight: '70%',
      maxWidth: '70%',
    },
    connector: {
      width: '20px',
      borderTop: '2px dashed',
      borderColor: theme.palette.blue?.main,
    },
  }),
)

export interface Props {
  utilityLogo: string
  apiProviderLogo: string
}

export default function UtilityIntegrationLinkDiagram({
  utilityLogo,
  apiProviderLogo,
}: Props) {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Grid container alignItems="center" justifyContent="center" wrap="nowrap">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.logoContainer}
        >
          <img className={classes.logoImg} src={optiwatt} alt="optiwatt logo" />
        </Grid>
        <Grid item>
          <hr className={classes.connector} />
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={clsx(classes.logoContainer, classes.leapLogoContainer)}
        >
          <img
            className={classes.logoImg}
            src={apiProviderLogo}
            alt="api provider logo"
          />
        </Grid>
        <Grid item>
          <hr className={classes.connector} />
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.logoContainer}
        >
          <img
            className={classes.logoImg}
            src={utilityLogo}
            alt="utility logo"
          />
        </Grid>
      </Grid>
    </Box>
  )
}
