import sanitizeHtml from 'sanitize-html'

export default function sanitizeHtmlContent(html: string) {
  return sanitizeHtml(html, {
    allowedAttributes: {
      ...sanitizeHtml.defaults.allowedAttributes,
      '*': [
        'padding',
        'padding-left',
        'padding-right',
        'padding-top',
        'padding-bottom',
        'style',
      ],
    },
  })
}
