import React from 'react'
import { ConfigurableButton } from '@/app/components'
import type { ButtonProps } from '@/components'
import { Text } from '@/components'

type ClassnameActiveFunction = (active: boolean) => string
type StyleActiveFunction = (active: boolean) => React.CSSProperties

export type ToggleButtonVariants = 'default' | 'rounded'

export type ToggleButtonProps = {
  label?: string
  icon?: React.JSX.Element
  value: string
  active?: boolean
  className?: string | ClassnameActiveFunction
  style?: React.CSSProperties | StyleActiveFunction
  resetStyle?: boolean
  content?: React.ReactNode
  variant?: ToggleButtonVariants
} & Omit<
  ButtonProps,
  | 'conent'
  | 'className'
  | 'style'
  | 'value'
  | 'active'
  | 'icon'
  | 'label'
  | 'resetStyle'
  | 'variant'
>

const ToggleButton = ({
  label,
  icon,
  value,
  style,
  active,
  className,
  resetStyle,
  variant,
  children,
  ...rest
}: ToggleButtonProps) => {
  let buttonClassName = `${
    active
      ? 'border-themed-accent bg-themed-accent bg-opacity-20'
      : 'border-themed-base-200 hover:bg-themed-accent hover:bg-opacity-10'
  } transition duration-800 border border-solid rounded-md p-3 h-full`
  if (resetStyle) buttonClassName = ''
  if (typeof className === 'function') {
    buttonClassName += className(active ?? false)
  } else if (typeof className === 'string') {
    buttonClassName += className
  }

  return (
    <ConfigurableButton
      variant={'click-wrapper'}
      {...rest}
      className={'!h-full !flex-1 !rounded-none'}
    >
      <div className={`${buttonClassName}`}>
        {children ? (
          children
        ) : (
          <div className="w-full h-full flex flex-col justify-around items-center">
            <div className="flex grow-1 w-full h-full items-center justify-center">
              {icon}
            </div>
            {label && (
              <div className="flex grow-0 justify-center items-center">
                <Text variant="body1" className="">
                  {label}
                </Text>
              </div>
            )}
          </div>
        )}
      </div>
    </ConfigurableButton>
  )
}

type AllowedChildren =
  | React.ReactElement<ToggleButtonProps>
  | React.ReactElement<ToggleButtonProps>[]

export type ToggleButtonGroupProps = {
  value: string | null
  onChange: (value: string | null) => void
  resetStyle?: boolean
  children?: AllowedChildren
  disallowDeselect?: boolean
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange' | 'value'>

const ToggleButtonGroup = ({
  value,
  onChange,
  children,
  resetStyle,
  disallowDeselect,
  ...rest
}: ToggleButtonGroupProps) => {
  if (!children) return null
  const childArray = React.Children.toArray(
    children,
  ) as React.ReactElement<ToggleButtonProps>[]
  let divClassName = ' w-full flex justify-around gap-4 '
  if (resetStyle) divClassName = ''
  if (rest.className) divClassName += rest.className
  return (
    <div {...rest} className={` ${divClassName}`}>
      {childArray.map((child) => {
        const { label, icon, ...childRest } = child.props
        return React.cloneElement(child, {
          key: childRest.value,
          label,
          icon,
          ...childRest,
          active: childRest.value === value,
          onClick: () => {
            if (childRest.value === value && disallowDeselect) {
              return
            }
            onChange(childRest.value === value ? null : childRest.value)
          },
        })
      })}
    </div>
  )
}

export default { ToggleButtonGroup, ToggleButton }
