import { createSelector } from '@reduxjs/toolkit'
import { Vehicle } from '../types/vehicle'
import { RootState } from '../store'
import { ID } from '../types/model'

const selectVehicleById = createSelector(
  [(state: RootState) => state.vehicles.vehicles, (_: RootState, id: ID) => id],
  (vehicles: Vehicle[] | null, id: ID) => vehicles?.find((v) => v.id === id),
)

export default selectVehicleById
