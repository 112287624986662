// eslint-disable-next-line @typescript-eslint/ban-types
export interface MockableViewModel<ViewModelFunc extends Function> {
  useViewModel: ViewModelFunc
  useMockViewModel: ViewModelFunc
}

// eslint-disable-next-line @typescript-eslint/ban-types
export default function useMockableViewModel<ViewModelFunc extends Function>({
  useViewModel,
  useMockViewModel,
}: MockableViewModel<ViewModelFunc>) {
  if (process.env.STORYBOOK_USE_MOCK_DATA === 'true') {
    return useMockViewModel
  }
  return useViewModel
}
