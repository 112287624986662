import { ReactNode } from 'react'
import { useIsExperimentalUser } from '../hooks/useIsExperimentalUser'

export interface Props {
  children: ReactNode | ReactNode[] // Accepts a single child or a list of children
}

export default function Experimental({ children }: Props) {
  const isExperimentalUser = useIsExperimentalUser()

  if (!isExperimentalUser) {
    return null
  }

  return (
    <>
      {Array.isArray(children)
        ? children.map((child, index) => <div key={index}>{child}</div>)
        : children}
    </>
  )
}
