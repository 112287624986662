import { RSAA } from 'redux-api-middleware'
import { withAuth } from '../reducers'
import config from '../config'

export const LIST_REQUEST = '@@usages/LIST_REQUEST'
export const LIST_SUCCESS = '@@usages/LIST_SUCCESS'
export const LIST_FAILURE = '@@usages/LIST_FAILURE'

export const USAGE_COMPARISON_REQUEST = '@@usages/USAGE_COMPARISON_REQUEST'
export const USAGE_COMPARISON_SUCCESS = '@@usages/USAGE_COMPARISON_SUCCESS'
export const USAGE_COMPARISON_FAILURE = '@@usages/USAGE_COMPARISON_FAILURE'

export var CHARGES_COMPARISON_REQUEST = '@@usages/CHARGES_COMPARISON_REQUEST'
export var CHARGES_COMPARISON_SUCCESS = '@@usages/CHARGES_COMPARISON_SUCCESS'
export var CHARGES_COMPARISON_FAILURE = '@@usages/CHARGES_COMPARISON_FAILURE'

export const getUsages = ({ usagesEndDatetime, usagesStartDatetime }) => ({
  [RSAA]: {
    endpoint: `${config.apiGateway.URL}/api/usages/`,
    method: 'GET',
    params: {
      end_datetime: usagesEndDatetime,
      ...(usagesStartDatetime ? { start_datetime: usagesStartDatetime } : {}),
    },
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [LIST_REQUEST, LIST_SUCCESS, LIST_FAILURE],
  },
})

export const getUsageComparison = () => ({
  [RSAA]: {
    endpoint: `${config.apiGateway.URL}/api/usages/comparison/`,
    method: 'GET',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      USAGE_COMPARISON_REQUEST,
      USAGE_COMPARISON_SUCCESS,
      USAGE_COMPARISON_FAILURE,
    ],
  },
})

export const getChargesComparison = (vehicle_id) => ({
  [RSAA]: {
    endpoint: config.apiGateway.URL + '/api/charges/comparison/',
    method: 'GET',
    ...(vehicle_id == null ? { params: { vehicle_id } } : {}),
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      CHARGES_COMPARISON_REQUEST,
      CHARGES_COMPARISON_SUCCESS,
      CHARGES_COMPARISON_FAILURE,
    ],
  },
})
