import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

export default function OdometerDialog(props) {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="charging-cost-dialog"
    >
      <DialogTitle id="charging-cost-dialog">
        How is this cost calculated?
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ marginBottom: '15px' }}>
          {`This is the total cost to charge using energy and your electricity rate. [kWh] x [cost per kWh]`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
