import React from 'react'

import { appPath } from './app'
import AuthenticatedRoute from './AuthenticatedRoute'
import { Route, Switch } from 'react-router'
import App from '@/authenticated/App'

import Landing, { LandingPath } from '@/app/pages/root'
import Login from '@/unauthenticated/pages/Login'
import SignUp from '@/unauthenticated/pages/SignUp'
import ResetPassword from '@/unauthenticated/pages/ResetPassword'
import updatePassword from '@/unauthenticated/pages/UpdatePassword'
import Terms from '@/unauthenticated/pages/Terms'
import TeslaGiveawayTerms from '@/unauthenticated/pages/TeslaGiveawayTerms'
import Privacy from '@/unauthenticated/pages/Privacy'
import NotFound from '@/unauthenticated/pages/404'
import UtilityConnectRedirect from '@/authenticated/components/UtilityConnectRedirect'
import Unsubscribe from '@/unauthenticated/pages/Unsubscribe'
import BlogTools from '@/unauthenticated/components/Blog/Tools'
import SmartCarRedirect from '@/authenticated/components/SmartCarRedirect'
import ThermostatRedirect from '@/authenticated/components/ThermostatRedirect'
import DownloadAppRedirect from '@/unauthenticated/pages/DownloadAppRedirect/DownloadAppRedirect'
import {
  AikenFaq,
  BerkeleyFaq,
  BroadRiverFaq,
  HorryFaq,
  LaurensFaq,
  MidCarolinaFaq,
  NewberryFaq,
  PalmettoFaq,
  SanteeFaq,
  YorkFaq,
} from '@/unauthenticated/pages/CepciFaq'
import FortisAlbertaFaq from '@/app/pages/faq/fortisalberta'
import SmudFaq from '@/app/pages/faq/smud'
import PowerSouthFaq from '@/app/pages/faq/powersouth'
import ODECFaq from '../pages/faq/odec'
import Sandbox from '@/app/pages/sandbox'
import UtilityProgramConnect from '../pages/utility-program-connect'
import FAQPage from '@/app/pages/faq'
import FaqSlugPage from '@/app/pages/faq/slug/FaqSlugPage'

import { LazyPage } from '@/app/components'
import TeslaFleetAuthenticationPage from '../pages/tesla-authentication'
import TeslaFleetConnectionTestConfirmationPage from '../pages/tesla-fleet-connection-test-confirmation'
import TeslaFleetConnectionTestExpiredPage from '../pages/tesla-fleet-connection-test-expired'
import PressReleasePage from '../pages/business/PressReleasePage'
import DeviceTrafficControl from '../pages/device-traffic-control'
import Google from '@/app/pages/oauth/google/google'
import Apple from '@/app/pages/oauth/apple/apple'
import { useWhitelabelTheme } from '@/hooks/whitelabel/useWhitelabelTheme'
import MaintenancePage from '../pages/maintenance'
import useMaintenanceMode from '@/hooks/useMaintenanceMode'
import { useHistory } from 'react-router-dom'
import useMobileMessage from '@/app/hooks/useMobileMessage'

const HomePage = React.lazy(() => import('@/app/pages/home'))
const BusinessPage = React.lazy(() => import('@/app/pages/business'))

type NavigationPayload = {
  location: string
  code_verifier: string
}

type NavigationMessage = {
  payload?: NavigationPayload
  close?: boolean
  error?: string
}

export default function RootRoutes() {
  useWhitelabelTheme()

  const history = useHistory()
  useMobileMessage<NavigationMessage>(
    async (data: NavigationMessage) => {
      if (data?.payload?.location) {
        history.replace(data.payload.location)
      }
    },
    ['navigation'],
  )

  const maintenanceMode = useMaintenanceMode()

  return (
    <Switch>
      <Route exact path={LandingPath} component={Landing} />
      <Route exact path="/home" component={LazyPage(HomePage)} />
      <Route exact path="/business" component={LazyPage(BusinessPage)} />
      <Route
        exact
        path="/business/press-release"
        component={LazyPage(PressReleasePage)}
      />
      <Route exact path="/tos" component={Terms} />
      <Route exact path="/tesla-giveaway-tos" component={TeslaGiveawayTerms} />
      <Route exact path="/privacy" component={Privacy} />
      <Route exact path="/faq/fortisalberta" component={FortisAlbertaFaq} />
      <Route exact path="/faq/dec" component={ODECFaq} />
      <Route exact path="/faq/smud" component={SmudFaq} />
      <Route exact path="/faq/powersouth" component={PowerSouthFaq} />
      <Route exact path="/faq" component={FAQPage} />
      {/* A /faq/:slug path */}
      <Route exact path="/faq/:slug" component={FaqSlugPage} />
      <Route exact path="/:utility?/download" component={DownloadAppRedirect} />

      <Route exact path="/aiken-faq" component={AikenFaq} />
      <Route exact path="/berkeley-faq" component={BerkeleyFaq} />
      <Route exact path="/broad-river-faq" component={BroadRiverFaq} />
      <Route exact path="/horry-faq" component={HorryFaq} />
      <Route exact path="/laurens-faq" component={LaurensFaq} />
      <Route exact path="/mid-carolina-faq" component={MidCarolinaFaq} />
      <Route exact path="/newberry-faq" component={NewberryFaq} />
      <Route exact path="/palmetto-faq" component={PalmettoFaq} />
      <Route exact path="/santee-faq" component={SanteeFaq} />
      <Route exact path="/york-faq" component={YorkFaq} />
      {!['production', 'staging'].includes(
        process.env.REACT_APP_ENVIRONMENT ?? '',
      ) && <Route exact path="/sandbox" component={Sandbox} />}

      {/* Display the maintenance page if maintenance mode is enabled */}
      {maintenanceMode && <Route component={MaintenancePage} />}

      <Route
        exact
        path="/utility-program-connect"
        component={UtilityProgramConnect}
      />

      <Route exact path="/login/:utility?" component={Login} />
      <Route exact path="/:utility?/signup" component={SignUp} />
      <Route exact path="/reset-password" component={ResetPassword} />
      <Route exact path="/update-password" component={updatePassword} />
      <Route exact path="/oauth/google" component={Google} />
      <Route exact path="/oauth/apple" component={Apple} />
      {/* Should match any route that starts with device-traffic-control and has an arbitrary app path following.
       * This app path should be used to deep link into the app. The device traffic control page will
       * handle sending that deep link to the mobile app or redirecting to the web app if the mobile app doesnt open
       */}
      <Route
        path="/:utility?/device-traffic-control/:appPath*"
        component={LazyPage(DeviceTrafficControl)}
      />
      <AuthenticatedRoute exact={true} path={appPath} component={App} />
      <Route
        exact
        path="/grid-support-complete"
        component={UtilityConnectRedirect}
      />
      <Route
        exact
        path="/smartcar-authentication"
        component={SmartCarRedirect}
      />
      <Route
        exact
        path={[
          '/tesla-authentication',
          '/ava/tesla-authentication',
          '/ava/onboarding/tesla-authentication',
        ]}
        component={TeslaFleetAuthenticationPage}
      />
      <Route
        exact
        path="/thermostat-authentication/:brand"
        component={ThermostatRedirect}
      />
      <Route
        exact
        path="/tesla-fleet-connection-test-confirmation"
        component={TeslaFleetConnectionTestConfirmationPage}
      />
      <Route
        exact
        path="/tesla-fleet-connection-test-expired"
        component={TeslaFleetConnectionTestExpiredPage}
      />

      <Route exact path="/unsubscribe/:id" component={Unsubscribe} />
      <Route exact path="/blog-tools/(new-charger)" component={BlogTools} />
      <Route component={NotFound} />
    </Switch>
  )
}
