import { useAppDispatch, useAppSelector } from '@/hooks'
import useProfileId from '@/hooks/useProfileId'
import {
  avaAddressesCollection,
  AvaVerificationResponse,
} from '@/reducers/avaAddressesCollection'
import { selectUserSelectedHome } from '@/selectors'
import { PayloadAction } from '@reduxjs/toolkit'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { avaSecurityQuestionCollection } from '@/reducers/avaSecurityQuestionCollection'
import { getCurrentUser } from '@/actions/user'
import useProfileWhiteLabelService from '@/app/hooks/useProfileWhiteLabelService'

type Params = {
  utility: string | undefined
}

export default function useViewModel() {
  const { utility } = useParams<Params>()
  const dispatch = useAppDispatch()
  const [destination, setDestination] = useState<string | null>(null)
  const profileId = useProfileId()
  const formattedAddress = useAppSelector(
    (state) => selectUserSelectedHome(state)?.formatted_address,
  )
  const { removeProfileWhiteLabel } = useProfileWhiteLabelService()
  const hasFetched = useRef(false)
  avaSecurityQuestionCollection.useFetch(profileId ?? -1, {
    require: profileId !== null,
  })

  const checkAvaVerification = async (
    profileId: number | null,
    formattedAddress: string | undefined,
  ) => {
    if (hasFetched.current) {
      return
    }

    if (!profileId || !formattedAddress) {
      return
    }

    hasFetched.current = true

    const avaResult: PayloadAction<AvaVerificationResponse> = await dispatch(
      avaAddressesCollection.actions.create(
        {
          address: formattedAddress.trim(),
          profile_id: profileId,
        },
        { path: `/ava_addresses/${profileId}` },
      ),
    )

    if (avaResult.type === avaAddressesCollection.actionTypes.create.Failure) {
      return
    }

    await dispatch(getCurrentUser())

    if (!avaResult.payload.is_covered) {
      await removeProfileWhiteLabel()

      // Navigate to /onboarding but _after_ the address selection??
      setDestination('/onboarding?questionsToSkip=homeAddress')
      return
    }
    if (!avaResult.payload.is_customer) {
      setDestination(`/ava/become-utility-customer?pageType=onboarding`)
      return
    }
    if (!avaResult.payload.is_verified) {
      setDestination(`/ava/onboarding/verify-residence`)
      return
    }

    setDestination(`/ava/onboarding`)
  }

  const determineDestination = () => {
    if (utility === 'ava') {
      checkAvaVerification(profileId, formattedAddress)
    } else {
      setDestination('/onboarding?questionsToSkip=homeAddress')
    }
  }

  useEffect(() => {
    determineDestination()
  }, [profileId, formattedAddress])

  return {
    destination,
  }
}
