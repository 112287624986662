import './styles.scss'
import formatClasses from '../../utils/classes/formatClasses'
import MuiTextField, { StandardTextFieldProps } from '@mui/material/TextField'

export interface Props extends StandardTextFieldProps {
  // No additional props provided
}

export default function TextField({ className, ...props }: Props) {
  const classes = {
    root: formatClasses(['text-field', className]),
  }
  return <MuiTextField className={classes.root} variant="standard" {...props} />
}
