import { useState } from 'react'
import styled from 'styled-components'
import {
  Box as MuiBox,
  Grid as MuiGrid,
  IconButton,
  makeStyles,
  Typography as MuiTypography,
  useTheme,
} from '@material-ui/core'
import { spacing } from '@material-ui/system'
import useIsMobileApp from '../hooks/useIsMobile'
import SmartTimePicker from './SmartTimePicker'
import DayOfWeekPicker from './DayOfWeekPicker'
import { X as Close } from 'react-feather'

const Grid = styled(MuiGrid)(spacing)
const Box = styled(MuiBox)(spacing)
const Typography = styled(MuiTypography)(spacing)

const useStyles = makeStyles((theme) => ({
  text: {
    fontWeight: '700',
    fontSize: '13px',
  },
  inputText: {
    marginTop: '2px',
    fontWeight: '700',
    fontSize: '13px',
    color: theme.palette.blue.main,
  },
  headerBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  weeklyPickerBox: {
    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(6)}px ${theme.spacing(
      6,
    )}px ${theme.spacing(6)}px`,
    borderRadius: '20px',
    backgroundColor: '#F2F2F2',
  },
  deleteButton: {
    position: 'absolute',
    right: '-8px',
    top: '-8px',
    backgroundColor: '#FAFAFA',
    //boxShadow: `0 0px 10px rgb(0, 0, 0, 0.1)`,
  },
  deleteButtonRoot: {
    '&:hover': {
      backgroundColor: theme.palette.blue.background,
    },
  },
}))

// Weekdays
const DEFAULT_DAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday']

const WeeklyTimePicker = ({
  onDelete,
  onChange,
  days: providedDays,
  hour: providedHour,
  minute: providedMinute,
  mode,
}) => {
  const classes = useStyles()
  const isApp = useIsMobileApp()
  const theme = useTheme()

  const [internalDays, setInternalDays] = useState(DEFAULT_DAYS)
  const [internalTime, setInternalTime] = useState({
    hour: mode === 'departure' ? 8 : 7,
    minute: mode === 'departure' ? 30 : 0,
  })
  const days = providedDays ?? internalDays
  const { hour, minute } = {
    hour: providedHour ?? internalTime.hour,
    minute: providedMinute ?? internalTime.minute,
  }

  const onTimeChange = ({ hour, minute }) => {
    setInternalTime({ hour, minute })
    if (onChange) {
      onChange({ hour, minute, days })
    }
  }

  const onDaysChange = (days) => {
    setInternalDays(days)
    if (onChange) {
      onChange({ hour, minute, days })
    }
  }

  return (
    <Box className={classes.weeklyPickerBox}>
      <IconButton
        className={classes.deleteButton}
        onClick={onDelete}
        classes={{ root: classes.deleteButtonRoot }}
      >
        <Close size={12} color={theme.palette.blue.main} />
      </IconButton>
      <Grid
        container
        spacing={4}
        justifyContent="center"
        alignItems="flex-start"
        direction="column"
      >
        <Grid
          container
          item
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          {
            <Grid item>
              <Typography variant="body1" className={classes.text}>
                {mode === 'departure' ? 'Ready by' : 'Charge begin'}:
              </Typography>
            </Grid>
          }
          <Grid item>
            <Box mb={!isApp ? 2 : 0}>
              <SmartTimePicker
                hour={hour}
                minute={minute}
                onChange={onTimeChange}
              />
            </Box>
          </Grid>
        </Grid>
        <Box ml={2}>
          <DayOfWeekPicker defaultDays={days} onChange={onDaysChange} />
        </Box>
      </Grid>
    </Box>
  )
}

export default WeeklyTimePicker
