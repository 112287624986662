import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Dialog, Grid, Box, IconButton, useMediaQuery } from '@material-ui/core'
import GridSupport from './GridSupport'
import { makeGridSupportSelection } from '../../../actions/utilities'
import { useDispatch } from 'react-redux'
import { makeStyles, useTheme } from '@material-ui/styles'
import { X as Close } from 'react-feather'
import { getChargeSchedules } from '../../../actions/schedule'
import theme from '../../../v1/theme'
import { ThemeProvider } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  iconWrapper: {
    position: 'absolute',
    zIndex: 100,
    right: 4,
    top: 4,
  },
  icon: {
    color: '#343434',
    height: 16,
    width: 16,
  },
  content: {
    padding: '32px 32px 24px 32px',
  },
}))

export default function GridSupportSelectDialog(props) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const styleTheme = useTheme()
  const shouldBeFullScreen = useMediaQuery(styleTheme.breakpoints.down('sm'))
  const { onClose, ...other } = props

  const selectedUtility = useSelector(
    (state) => state.utilities.selectedUtility,
  )

  const handleSubmit = useCallback(
    (supportGrid) => {
      const grid_support_enabled = supportGrid
      const has_made_grid_support_selection = true
      // @todo - add comment, why do we care about Cali here?
      if (!props.isCalifornia || (props.isCalifornia && supportGrid)) {
        props?.onSubmit?.({
          grid_support_enabled,
          has_made_grid_support_selection,
        })
        dispatch(
          makeGridSupportSelection(
            { has_made_grid_support_selection },
            dispatch,
          ),
        ).then(() =>
          // @todo - add comment, why do we fetch charge schedules here?
          dispatch(getChargeSchedules()),
        )
      }
    },
    [dispatch, props.isCalifornia],
  )

  const themeSelection = useSelector((state) => state.themeReducer)

  return (
    <ThemeProvider theme={theme[themeSelection.currentTheme]}>
      <Dialog
        fullScreen={shouldBeFullScreen}
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="grid-support-dialog"
        {...other}
      >
        <Box>
          <IconButton onClick={props.onClose} className={classes.iconWrapper}>
            <Close className={classes.icon} />
          </IconButton>
          <Grid>
            <Grid item>
              <GridSupport
                contentStyle={classes.content}
                utilitySelection={selectedUtility}
                handleClose={props.onClose}
                handleSubmit={handleSubmit}
                allowSkip={props.allowSkip}
                data={props.data}
                confirmationPending={props.confirmationPending}
                setConfirmationPending={props.setConfirmationPending}
              />
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </ThemeProvider>
  )
}
