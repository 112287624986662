function isNestedObject(obj: any): obj is Record<string, unknown> {
  return typeof obj === 'object' && obj !== null && !Array.isArray(obj)
}

function nestedObjectMap<Mapped extends Record<string, unknown>>(
  obj: Mapped,
  fn: (key: string, value: unknown) => unknown,
): Mapped {
  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key]
    if (isNestedObject(value)) {
      acc[key] = nestedObjectMap(value, fn)
    } else {
      acc[key] = fn(key, value)
    }

    return acc
  }, {} as Record<string, unknown>) as Mapped
}

export default nestedObjectMap
