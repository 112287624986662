import { StatusCircle } from '@/app/components'
import {
  CardActionButton,
  CardActions,
  Grid,
  Modal,
  ModalProps,
  Text,
} from '@/components'
import { useAppSelector } from '@/hooks'
import { chargeNowEventCollection } from '@/reducers/chargeNowEvents'
import { ID } from '@/types/model'
import {
  selectBatteryTargetETA,
  selectChargeForecast,
} from '@/selectors/chargeForecast'
import dayjs from 'dayjs'

type Props = {
  vehicleId: ID
  open: ModalProps['open']
  onClose: ModalProps['onClose']
}

function useViewModel(props: Props) {
  const { vehicleId, ...modalProps } = props
  const loading = useAppSelector((state) => {
    const forecastLoading = state.metric.isFetchingChargeForecast as boolean
    const chargeNowLoading =
      chargeNowEventCollection.selectors.queryState.someLoading(state)
    return forecastLoading || chargeNowLoading
  })
  const error = useAppSelector((state) => {
    const forecastFailed = state.metric.chargeForecastFailed as boolean
    const chargeNowFailed = chargeNowEventCollection.selectors.queryState
      .select(state, {
        method: 'POST',
      })
      .find((queryState) => Boolean(queryState.error))
    return chargeNowFailed || forecastFailed
  })
  const finishChargingTime = useAppSelector(selectBatteryTargetETA)
  const batteryTarget = useAppSelector(
    (state) => selectChargeForecast(state)?.max_battery_target,
  )

  const status: 'loading' | 'failed' | 'succeeded' = loading
    ? 'loading'
    : error
    ? 'failed'
    : 'succeeded'

  return {
    status,
    finishChargingTime,
    batteryTarget,
    ...modalProps,
  }
}

function ChargeNowStatusModalComponent(props: ReturnType<typeof useViewModel>) {
  const { status, finishChargingTime, batteryTarget, ...modalProps } = props

  const content = {
    loading: {
      title: 'Just a moment...',
      body: '',
    },
    succeeded: {
      title:
        batteryTarget && finishChargingTime
          ? `Your battery will reach ${Math.round(batteryTarget)}% by ${dayjs(
              finishChargingTime,
            ).format('h:mm a')}.`
          : 'Your car is now charging.',
      body: 'Smart charging has been temporarily disabled for this charging session.',
    },
    failed: {
      title: 'There was an issue fulfilling your request.',
      body: 'Try again later or contact support if the issue persists. We apologize for the inconvenience.',
    },
  }

  return (
    <Modal id="charge-now-modal" {...modalProps}>
      <div
        style={{
          // fix the height of the modal content
          height: '163px',
          marginTop: '40px',
          marginBottom: '64px',
        }}
      >
        <Grid flow="row" gap="16px" placeItems="center">
          <StatusCircle status={status} />
          <Text variant="subheader" align="center" style={{ padding: '0 20%' }}>
            {content[status].title}
          </Text>
          <Text variant="body2" align="center" style={{ padding: '0 5%' }}>
            {content[status].body}
          </Text>
        </Grid>
      </div>

      <CardActions>
        <CardActionButton
          id="charge-now-modal-done-button"
          type="primary"
          onClick={modalProps.onClose}
        >
          Done
        </CardActionButton>
      </CardActions>
    </Modal>
  )
}

export default function ChargeNowStatusModal(props: Props) {
  const viewModel = useViewModel(props)
  return <ChargeNowStatusModalComponent {...viewModel} />
}
