import { useUrlSearchParam } from '@/hooks'

export type NextDevice = {
  type: DeviceRoute
  id: number
}

type DeviceRoute = 'vehicle' | 'thermostat' | 'vehicle-charger' | 'home'

export default function useEnrolledDeviceSelections() {
  const enrolledDeviceType = useUrlSearchParam('deviceType')
  const enrolledDeviceId = useUrlSearchParam('deviceId')

  const getEnrolledDeviceRoute = () => {
    if (!enrolledDeviceType || !enrolledDeviceId) {
      return '/app'
    }

    return `/devices/${enrolledDeviceType}/${enrolledDeviceId}`
  }

  const createEnrolledDeviceParams = (nextDevice: NextDevice | null) => {
    if (nextDevice === null) {
      return ''
    }
    return `?deviceType=${nextDevice.type}&deviceId=${nextDevice.id}`
  }

  return { getEnrolledDeviceRoute, createEnrolledDeviceParams }
}
