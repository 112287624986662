import HomeScheduleCardRoot, {
  Props as ComponentProps,
} from './HomeScheduleCard'
import useViewModel from './useViewModel'
import { ViewModelComponentProps } from '../../../../utils/typeUtilities'

export type Props = ViewModelComponentProps<ComponentProps, typeof useViewModel>

export default function HomeScheduleCard(props: Props) {
  const viewModel = useViewModel(props)

  return <HomeScheduleCardRoot {...viewModel} />
}
