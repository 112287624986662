import { useSelector } from 'react-redux'
import { selectedVehicle as getSelectedVehicle } from '../../../../selectors/vehicles'

import useOptimisticBillingStartDay from '../../../hooks/useOptimisticBillingStartDay'
import BillingStartSetup from './BillingStartSetup'
import ConnectUtilitySetup from './ConnectUtilitySetup'

const UtilitySetup = ({
  useBillingStartDay = useOptimisticBillingStartDay,
  hasIntegratedUtility,
  setIsGridSupportOpen,
}) => {
  const { billingStartDay, isLoadingBillingStartDay, setBillingStartDay } =
    useBillingStartDay()

  const selectedVehicle = useSelector((state) =>
    getSelectedVehicle(state.vehicles.vehicles),
  )

  return billingStartDay ? (
    <ConnectUtilitySetup
      billingStartDay={billingStartDay}
      setBillingStartDay={setBillingStartDay}
      isLoading={isLoadingBillingStartDay}
      selectedVehicle={selectedVehicle}
      setIsGridSupportOpen={setIsGridSupportOpen}
    />
  ) : (
    <BillingStartSetup
      billingStartDay={billingStartDay}
      setBillingStartDay={setBillingStartDay}
      notificationNumber={hasIntegratedUtility ? 1 : 2}
      selectedVehicle={selectedVehicle}
    />
  )
}

export default UtilitySetup
