import {
  Card,
  Flex,
  List,
  DataListItem,
  Skeleton,
  Text,
  Icon,
  Grid,
} from '../../../../components'
import { CostSheet } from '../../../components'
import { ReactNodeProps } from '../../../../types/reactNodeProps'
import { ThermostatAdjustment } from '../../../../types/thermostatAdjustment'
import { toDegrees } from '../../../../utils/string'
import dayjs from 'dayjs'

export interface Props extends ReactNodeProps, Partial<ThermostatAdjustment> {
  // No additional props defined
  hidden: boolean
  loading: boolean
  previousSetpoint: number
  currentSetpoint: number
}

const SheetSkeleton = () => (
  <Skeleton animation="wave" variant="text" width={63} height={26} />
)

const TemperatureSkeleton = () => (
  <Skeleton
    animation="wave"
    variant="text"
    width={25}
    height={18}
    style={{
      display: 'inline-block',
      verticalAlign: 'middle',
    }}
  />
)

const TimeSkeleton = () => (
  <Skeleton animation="wave" variant="text" width={54} height={18} />
)

const SetPoint = (props: {
  previous: number
  current: number
  loading: boolean
}) => {
  const colorClassName = {
    previous:
      props.previous < props.current
        ? 'text-themed-blue-500'
        : 'text-themed-red-500',
    current:
      props.current > props.previous
        ? 'text-themed-red-500'
        : 'text-themed-blue-500',
  }

  return (
    <Grid gap="4px" placeItems="center">
      <Text className={colorClassName.previous}>
        {`was ${!props.loading ? toDegrees(props.previous) : ''}`}
      </Text>
      {props.loading && <TemperatureSkeleton />}
      <Icon color="grey-500" name="ChevronRight" size={16} />
      {props.loading ? (
        <TemperatureSkeleton />
      ) : (
        <Text className={colorClassName.current}>
          {toDegrees(props.current)}
        </Text>
      )}
    </Grid>
  )
}

export default function YourLastAdjustmentCard(props: Props) {
  const isExpired =
    props.hold_until_time && dayjs() > dayjs(String(props.hold_until_time))
  if (props.hidden || isExpired) {
    return null
  }

  return (
    <Card>
      <Flex container direction="column" rowSpacing={1}>
        <Flex item>
          <Text variant="h3">Your Last Adjustment</Text>
        </Flex>
        <Flex item>
          <List bordered>
            <DataListItem label="Difference">
              {props.loading ? (
                <SheetSkeleton />
              ) : (
                <CostSheet cost={props.estimated_cost_cents} size="sm" />
              )}
            </DataListItem>
            <DataListItem label="Set Point">
              <SetPoint
                loading={props.loading}
                previous={props.previousSetpoint}
                current={props.currentSetpoint}
              />
            </DataListItem>
            <DataListItem label="Schedule Resumes">
              {props.loading ? (
                <TimeSkeleton />
              ) : (
                <Text>
                  {props.hold_until_time
                    ? dayjs(String(props.hold_until_time)).format('h:mm A')
                    : 'N/A'}
                </Text>
              )}
            </DataListItem>
            <DataListItem label="Inside / Outside">
              <Text>
                {props.loading ? (
                  <TemperatureSkeleton />
                ) : (
                  toDegrees(
                    props.thermostat_measurement?.indoor_temperature ?? 0,
                  )
                )}{' '}
                /{' '}
                {props.loading ? (
                  <TemperatureSkeleton />
                ) : (
                  toDegrees(props.outdoor_temperature)
                )}
              </Text>
            </DataListItem>
          </List>
        </Flex>
      </Flex>
    </Card>
  )
}
