import { useNavigation } from '@/app/hooks'
import { teslaFleetAuthenticationCollection } from '@/reducers/teslaFleetAuthentication'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigateToFleetPublicKey } from '../../utils/useNavigateToFleetPublicKey'

type UrlParams = {
  vehicleId: string
}

type ViewModel =
  | {
      isLoading: true
    }
  | {
      isLoading: false
      onHasKeycard: () => void
      onNoKeycard: () => void
      error?: string
    }

function useLoadedViewModel(props: {
  vehicleId: string
  navigateToFleetPublicKey: (vehicleId: string) => void
  navigateToFleetPublicKeyError: boolean
}): ViewModel {
  const navigation = useNavigation()
  const [error, setError] = useState<string | undefined>()

  const onHasKeycard = () => {
    if (props.navigateToFleetPublicKeyError) {
      setError(
        'There was an error navigating to the Tesla key pairing page, please contact support',
      )
      return
    }
    props.navigateToFleetPublicKey(props.vehicleId)
  }

  const onNoKeycard = () => {
    navigation.pushToPath('/no-keycard')
  }

  return {
    isLoading: false,
    onHasKeycard,
    onNoKeycard,
    error,
  }
}

export default function useViewModel(): ViewModel {
  const { vehicleId: vehicleId } = useParams<UrlParams>()

  const {
    data: fleetAuthentications,
    error: fleetAuthenticationError,
    isLoadingOrIdle: isLoadingFleetAuthentication,
  } = teslaFleetAuthenticationCollection.useFetch()
  const fleetPublicKeyUrl =
    fleetAuthentications[0]?.public_key_url ?? 'https://optiwatt.com/app'
  const navigateToFleetPublicKey = useNavigateToFleetPublicKey({
    fleetPublicKeyUrl,
  })

  const loadedViewModel = useLoadedViewModel({
    vehicleId,
    navigateToFleetPublicKey,
    navigateToFleetPublicKeyError: Boolean(fleetAuthenticationError),
  })

  if (isLoadingFleetAuthentication) {
    return { isLoading: true }
  }

  return loadedViewModel
}
