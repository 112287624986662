import { useEffect } from 'react'
import styled from 'styled-components'
import {
  Grid as MuiGrid,
  Box as MuiBox,
  Button as MuiButton,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { spacing } from '@material-ui/system'

import { useSelector, useDispatch } from 'react-redux'
import { shouldLoadData } from '../../../hooks/useDataLoader'
import { useSelectedPlanFetcher } from '../../../hooks/plans'
import { getSeasonalRateSummary } from '../../../../actions/seasonalRateSummary'
import {
  getUsageComparison,
  getChargesComparison,
} from '../../../../actions/usages'
import DashboardCard, {
  DashboardSections,
  DashboardDivider,
} from '../../DashboardCard'
import TimeOfUseOverview from './TimeOfUseOverview'
import HomeComparison from './HomeComparison'
import HomeComparisonHeader from './HomeComparison/Header'
import BillingCycleSection from './BillingCycleSection'
import dayjs from 'dayjs'

const Grid = styled(MuiGrid)(spacing)
const Box = styled(MuiBox)(spacing)
const Button = styled(MuiButton)(spacing)

const useStyles = makeStyles((theme) => ({
  chevron: {
    marginLeft: '0.25rem',
  },
  seeExampleText: {
    textAlign: 'center',
    textDecoration: 'underline',
  },
}))

function usageEndDateToMonth(endDate) {
  if (!endDate) {
    return ''
  }
  const endMoment = dayjs(endDate)
  const midMonth = endMoment.daysInMonth() / 2
  if (endMoment.date() < midMonth) {
    return endMoment.subtract(1, 'months').format('MMMM')
  }
  return endMoment.format('MMMM')
}

export const useSeasonalRateSummaryFetcher = (dispatch) => {
  const {
    seasonalRateSummary: fetchedSeasonalRateSummary,
    isLoading,
    errors,
  } = useSelector((state) => state.seasonalRateSummary)

  useEffect(() => {
    if (shouldLoadData(fetchedSeasonalRateSummary, isLoading, errors)) {
      dispatch(getSeasonalRateSummary())
    }
  }, [fetchedSeasonalRateSummary, isLoading, errors, dispatch])

  return { seasonalRateSummary: fetchedSeasonalRateSummary || [], isLoading }
}

const getFormattedComparisonData = (comparisonData) => {
  return {
    current: comparisonData?.current
      ? {
          usage: comparisonData?.current?.total_usage_kwh || 0.0,
          cost: comparisonData?.current?.total_cost || 0.0,
          end: usageEndDateToMonth(comparisonData?.current?.end_date),
        }
      : null,
    past: comparisonData?.past
      ? {
          usage: comparisonData?.past?.total_usage_kwh || 0.0,
          cost: comparisonData?.past?.total_cost || 0.0,
          end: usageEndDateToMonth(comparisonData?.past?.end_date),
        }
      : null,
  }
}

const useHomeUsageComparisonFetcher = (dispatch) => {
  const {
    comparison: fetchedComparison,
    isLoadingComparison: isLoading,
    errors,
  } = useSelector((state) => ({
    comparison: state.comparison?.usageComparison,
    isLoadingComparison: state.comparison?.isLoadingUsageComparison,
    errors: state.comparison?.usageErrors,
  }))

  useEffect(() => {
    if (shouldLoadData(fetchedComparison, isLoading, errors)) {
      dispatch(getUsageComparison())
    }
  }, [fetchedComparison, isLoading, errors, dispatch])

  return {
    ...getFormattedComparisonData(fetchedComparison),
    isLoading,
  }
}

const useChargeUsageComparisonFetcher = (dispatch, maybeVehicleId) => {
  const {
    comparison: fetchedComparison,
    isLoadingComparison: isLoading,
    errors,
  } = useSelector((state) => ({
    comparison: state.comparison?.chargesComparison,
    isLoadingComparison: state.comparison?.isLoadingChargesComparison,
    errors: state.comparison?.chargesErrors,
  }))

  useEffect(() => {
    const shouldFetchInitially = shouldLoadData(
      fetchedComparison,
      isLoading,
      errors,
    )
    const shouldFetchOnVehicleChange =
      maybeVehicleId &&
      maybeVehicleId !== fetchedComparison?.vehicle_id &&
      shouldLoadData(false, isLoading, errors)
    const hasFetchedComparisonWithVehicle = !!fetchedComparison?.vehicle_id
    if (
      shouldFetchInitially ||
      (hasFetchedComparisonWithVehicle && shouldFetchOnVehicleChange)
    ) {
      dispatch(getChargesComparison(maybeVehicleId))
    }
  }, [fetchedComparison, isLoading, errors, dispatch, maybeVehicleId])

  return {
    ...getFormattedComparisonData(fetchedComparison),
    isLoading,
  }
}

const getComparisonData = (dispatch, maybeVehicleId) => {
  dispatch(getUsageComparison())
  dispatch(getChargesComparison(maybeVehicleId))
}

const UsageOverview = ({ useBillingStartDay, maybeVehicleId }) => {
  const dispatch = useDispatch()
  const { seasonalRateSummary } = useSeasonalRateSummaryFetcher(dispatch)
  const { selectedPlan, planIsPending } = useSelectedPlanFetcher()

  const {
    billingStartDay,
    isLoadingBillingStartDay,
    setBillingStartDay,
    billingCycleProgress,
    setOnBillingStartDayReturn,
  } = useBillingStartDay()
  const {
    current: homeUsage,
    past: lastHomeUsage,
    isLoading: homeUsageLoading,
  } = useHomeUsageComparisonFetcher(dispatch)
  const {
    current: carUsage,
    past: lastCarUsage,
    isLoading: carUsageLoading,
  } = useChargeUsageComparisonFetcher(dispatch, maybeVehicleId)

  const isLoadingComparison = homeUsageLoading || carUsageLoading

  useEffect(() => {
    setOnBillingStartDayReturn(() => {
      getComparisonData(dispatch, maybeVehicleId)
    })
  }, [])

  const isTou = selectedPlan?.types?.includes('Time-of-use')
  // const isTiered = selectedPlan?.types?.includes(PlanType.Tiered);

  const classes = useStyles()

  return (
    <DashboardCard p={6}>
      <DashboardSections>
        {isTou && (
          <TimeOfUseOverview seasonalRateSummary={seasonalRateSummary} />
        )}
        <Box>
          {isTou && <DashboardDivider mt={2} mb={2} />}
          <Box>
            <HomeComparison
              isLoading={isLoadingComparison}
              headerRender={() => (
                <HomeComparisonHeader days={billingCycleProgress} />
              )}
              planIsPending={planIsPending}
              {...{ homeUsage, carUsage, lastHomeUsage, lastCarUsage }}
            />
            <DashboardDivider my={4} />
            <BillingCycleSection
              billingStartDay={billingStartDay}
              setBillingStartDay={setBillingStartDay}
              isLoading={isLoadingBillingStartDay}
            />
          </Box>
        </Box>
      </DashboardSections>
    </DashboardCard>
  )
}

export default UsageOverview
