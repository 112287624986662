import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useOptimisticBillingStartDay from '../../hooks/useOptimisticBillingStartDay'
import useHasIntegratedUtility from '../../hooks/useHasIntegratedUtility'
import UtilitySetup from './UtilitySetup'
import UsageOverview from './UsageOverview'
import GridSupportSelectDialog from '../GridSupport/GridSupportSelectDialog'
import { makeGridSupportSelection } from '../../../actions/utilities'
import { setGridSupportConfirmationPending } from '../../../actions/utilities'
import useCanConnectUtility from '../../hooks/useCanConnectUtility'

const UtilityUsage = ({ maybeVehicleId }) => {
  const dispatch = useDispatch()
  const hasIntegratedUtility = !!useHasIntegratedUtility()
  const {
    billingStartDay,
    isLoading: isLoadingBillingStartDay,
    setBillingStartDay,
    billingCycleProgress,
    setOnBillingStartDayReturn,
  } = useOptimisticBillingStartDay()

  const showSetupUtil = false

  const setupRequired =
    !billingStartDay || !hasIntegratedUtility || showSetupUtil

  const useBillingStartDay = () => ({
    billingStartDay,
    isLoadingBillingStartDay,
    setBillingStartDay,
    billingCycleProgress,
    setOnBillingStartDayReturn,
  })

  // GRID SUPPORT
  const [isGridSupportOpen, setIsGridSupportOpen] = useState(false)
  const canConnectUtility = useCanConnectUtility()
  const gridSupportConfirmationPending = useSelector(
    (state) => state.utilities.gridSupportConfirmationPending,
  )

  function handleGridSupportClose() {
    let data = {
      has_made_grid_support_selection: true,
    }
    dispatch(makeGridSupportSelection(data, dispatch))
    setIsGridSupportOpen(false)
    dispatch({ type: SET_USER, payload: { profile: data } })
  }

  function handleGridSupportOnClose() {
    canConnectUtility ? setIsGridSupportOpen(false) : handleGridSupportClose()

    dispatch(setGridSupportConfirmationPending(false))
  }

  return (
    <>
      {setupRequired ? (
        <UtilitySetup
          useBillingStartDay={useBillingStartDay}
          hasIntegratedUtility={hasIntegratedUtility}
          setIsGridSupportOpen={setIsGridSupportOpen}
        />
      ) : (
        <UsageOverview
          useBillingStartDay={useBillingStartDay}
          maybeVehicleId={maybeVehicleId}
        />
      )}
      <GridSupportSelectDialog
        open={isGridSupportOpen}
        onClose={handleGridSupportOnClose}
        allowSkip={!canConnectUtility}
        isCalifornia={canConnectUtility}
        confirmationPending={gridSupportConfirmationPending}
      />
    </>
  )
}

export default UtilityUsage
