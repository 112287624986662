import {
  ScreenFillingPage,
  TeslaFleetPartnerSheet,
  ConfigurableText,
} from '@/app/components'
import Logo from '@/app/img/logo-color.webp'
import Check from '@/app/img/check.svg'
import TeslaLogo from '@/authenticated/images/tesla.webp'
import OptiwattIconLogo from '@/app/img/logo-icon-colored-filled.webp'
import { Card } from '@/components'
import LinkDiagram from '@/app/features/logos/LinkDiagram'

export default function TeslaFleetConnectionTestConfirmationPage() {
  return (
    <ScreenFillingPage>
      <div className="flex w-full justify-center">
        <div className="flex flex-col items-center max-w-[400px] px-4">
          <img src={Logo} alt="Optiwatt Logo" className="h-9 my-6" />
          <Card className="bg-white px-16">
            <div className="flex flex-col items-center">
              <div className="flex flex-col items-center">
                <div className="mb-4">
                  <LinkDiagram
                    logos={[OptiwattIconLogo, TeslaLogo]}
                    widthOverride="tall"
                  />
                </div>
                <TeslaFleetPartnerSheet className="mb-8" />
              </div>
              <ConfigurableText className="mb-8 text-center" variant="h2">
                Thanks for testing the connection, we will take it from here!
              </ConfigurableText>
              <img src={Check} alt="Check" className="h-[50px] mb-8" />
            </div>
          </Card>
        </div>
      </div>
    </ScreenFillingPage>
  )
}
