import { useAppSelector } from '@/hooks'
import ClarkVehicleChargerAlert from '../../VehicleChargerAlerts/ClarkVehicleChargerAlert'
import { useSurveyResponder } from '../reducer'

export type UseViewModelProps = ReturnType<typeof useViewModel>
function useViewModel() {
  const { dismissSurvey, submitResponse } = useSurveyResponder('ClarkEVSE')
  const logoUrl = useAppSelector(
    (state) => state.utilities.selectedUtility?.logo_url,
  )
  return { dismissSurvey, submitResponse, logoUrl }
}

export default function ClarkEVSE() {
  return <ClarkVehicleChargerAlert {...useViewModel()} />
}
