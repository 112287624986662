const RATE_LABELS = [
  'Ultra Off-Peak',
  'Mega Off-Peak',
  'Super Off-Peak',
  'Off-Peak',
  'Standard',
  'On-Peak',
  'Super On-Peak',
  'Mega On-Peak',
  'Ultra On-Peak',
]

export default function centeredRateIndexToLabel(centeredIdx?: number): string {
  if (centeredIdx === undefined || centeredIdx === null) {
    return 'Flat-Rate'
  }
  const idx = centeredIdx + Math.floor(RATE_LABELS.length / 2)
  return RATE_LABELS[idx]
}
