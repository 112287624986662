import ErrorBoundary, { LocationTag } from '../ErrorBoundary'
import TotalCostRoot from './TotalCost'

export default function TotalCost(props) {
  return (
    <ErrorBoundary
      location={LocationTag.TotalCost}
      functionalityDescription="Total Costs Overview"
      fallbackOnCard
    >
      <TotalCostRoot {...props} />
    </ErrorBoundary>
  )
}
