import { Button, Flex } from '@/components'
import React from 'react'
import { ChoicesQuestion, WizardPage } from '@/components/Wizard/Wizard'

export default function WizardChoices(props: WizardPage) {
  return (
    <Flex container gap="6px" style={{ paddingTop: '10px' }}>
      {(props.question as ChoicesQuestion).choices.map((answer, idx) => {
        return (
          <Flex item>
            <Button
              id={`${props.id}-selection-${idx}`}
              variant="chip"
              onClick={() => {
                props.callback(answer.value)
                props.onNext?.()
              }}
            >
              {answer.label}
            </Button>
          </Flex>
        )
      })}
      {props.question.skipEnabled && (
        <Flex item>
          <Button
            id={`${props.id}-selection-skip`}
            variant="chip"
            onClick={() => {
              props.callback(
                props.question.skipOverride ? props.question.skipOverride : '',
              )
              props.onNext?.()
            }}
          >
            I don't know
          </Button>
        </Flex>
      )}
    </Flex>
  )
}
