import BrandedPage from '@/app/pages/ava/BrandedPage'
import { useNavigation } from '@/app/hooks'
import SelectManualPlan from '@/authenticated/components/Plan/ManualPlan/SelectManualPlan'
import useEnrolledDeviceSelections from '@/app/hooks/useEnrolledDeviceSelections'

export default function PlanSelectManualEntryPage() {
  const navigation = useNavigation()
  const { getEnrolledDeviceRoute } = useEnrolledDeviceSelections()

  const proceed = () => {
    navigation.push(getEnrolledDeviceRoute())
  }

  return (
    <BrandedPage
      title="Add your utility plan manually"
      id="plan-select-manual-entry-page"
    >
      <BrandedPage.Content>
        <SelectManualPlan
          onCancel={() => navigation.goBack()}
          onComplete={proceed}
        />
      </BrandedPage.Content>
    </BrandedPage>
  )
}
