import { useState } from 'react'
import { useAppDispatch } from '../../../../hooks'
import { submitQuestions } from '../../../../actions/manualPlans'
import useMockableViewModel from '../../../../hooks/useMockableViewModel'

interface Props {
  onOptionSelect?: () => void
}

function useViewModel(props: Props) {
  const dispatch = useAppDispatch()
  const [weekdaysDiffer, setWeekdaysDiffer] = useState<boolean | null>()
  const [monthsDiffer, setMonthsDiffer] = useState<boolean | null>()

  const onWeekdaysChange = (differ: boolean | null) => {
    setWeekdaysDiffer(differ)
  }
  const onMonthsChange = (differ: boolean | null) => {
    setMonthsDiffer(differ)
  }

  const onSubmit = () => {
    dispatch(submitQuestions(weekdaysDiffer, monthsDiffer))
    props.onOptionSelect?.()
  }

  return {
    onWeekdaysChange,
    onMonthsChange,
    onSubmit,
  }
}

function useMockViewModel(props: Props) {
  return {
    onWeekdaysChange: (differ: boolean | null) => {
      console.log(differ)
    },
    onMonthsChange: (differ: boolean | null) => {
      console.log(differ)
    },
    onSubmit: () => console.log('submit'),
  }
}

export default useMockableViewModel({ useViewModel, useMockViewModel })
