import { Plan } from '@/types'
import { RateComparison } from '@/reducers/homeRateComparisons'

export default function shouldRecommendPlan(
  currentPlan: Plan | undefined | null,
  comparison: RateComparison | undefined | null,
): boolean {
  if (!currentPlan || !comparison) {
    return false
  }

  return (
    comparison?.current_plan_difference > 0 &&
    comparison?.comparison_plan?.name.toLowerCase().includes('gexa') &&
    !currentPlan?.name.toLowerCase().includes('gexa')
  )
}
