import { RSAA } from 'redux-api-middleware'
import { withAuth } from '../reducers'
import config from '../config'

export const CONTACT_REQUEST = 'CONTACT_REQUEST'
export const CONTACT_SUCCESS = 'CONTACT_SUCCESS'
export const CONTACT_FAILURE = 'CONTACT_FAILURE'

export const SELECT_PLAN_SUCCESS = 'SELECT_PLAN_SUCCESS'

export const RESET_CONTACT_FORM = 'RESET_FEEDBACK_FORM'

export const submitContactForm = (contactData) => ({
  [RSAA]: {
    endpoint: config.apiGateway.URL + '/api/forms/contact',
    method: 'POST',
    body: JSON.stringify(contactData),
    headers: { 'Content-Type': 'application/json' },
    types: [CONTACT_REQUEST, CONTACT_SUCCESS, CONTACT_FAILURE],
  },
})

export const submitContactFormWithAuth = (contactData) => ({
  [RSAA]: {
    endpoint: config.apiGateway.URL + '/api/forms/contact',
    method: 'POST',
    body: JSON.stringify(contactData),
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [CONTACT_REQUEST, CONTACT_SUCCESS, CONTACT_FAILURE],
  },
})

export const resetContactForm = () => {
  return { type: RESET_CONTACT_FORM }
}

// Example chaining: https://stackoverflow.com/questions/60343790/how-do-i-chain-actions-with-redux-api-middleware

// export const exampleChaining = (data) => dispatch => {
//     return dispatch(functionOne(data))
//         .then((res) => {
//             if (res.error) {
//                 debugger;
//             } else {
//                 debugger;
//                 return dispatch(functionTwo(data));
//             }
//         });
// };
