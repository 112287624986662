import { useRef, useState } from 'react'
import { withTheme } from 'styled-components'
import WeekRates from './WeekRates'
import { useDispatch, useSelector } from 'react-redux'
import { submitRates, addPlan } from '../../../../actions/manualPlans'
import { getPlan } from './PlansGraph'
import { hideEditPlans } from '../../../../actions/manualPlans'

const scrollToRef = (ref) =>
  window.scrollTo({
    top: ref.current.offsetTop,
    behavior: 'smooth',
  })

function WeekPlan({
  plan,
  handleSuccess: providedHandleSuccess,
  onComplete,
  onBack,
  errorMessage,
  ...restProps
}) {
  const [isWeekendSelected, setIsWeekendSelected] = useState(false)
  const { editingRates } = useSelector((state) => state.manualPlans)
  const { changingWeekdays, changingMonths } = useSelector(
    (state) => state.manualPlans.form,
  )
  const rates = getRates()
  const weekend = useRef(null)
  const weekday = useRef(null)
  const scrollToWeekday = () => scrollToRef(weekday)
  const scrollToWeekend = () => scrollToRef(weekend)
  const dispatch = useDispatch()

  function getRates() {
    if (plan.planId) {
      return plan.rates
    } else {
      return editingRates
    }
  }

  function handleSuccess() {
    if (changingWeekdays) {
      if (isWeekendSelected) {
        submit()
      } else {
        scrollToWeekend()
        setIsWeekendSelected(true)
      }
    } else {
      submit()
    }
  }

  function submit() {
    dispatch(submitRates(plan.planId, rates))
    if (!changingMonths) {
      let newPlan = getPlan(1, 'January', 'December', rates)
      dispatch(addPlan(newPlan))
      providedHandleSuccess([newPlan])
      onComplete?.()
    } else {
      dispatch(hideEditPlans())
    }
  }

  function handlePrevious() {
    scrollToWeekday()
    setIsWeekendSelected(false)
  }

  return (
    <div>
      <div ref={weekday} className="mb-4">
        <WeekRates
          plan={plan}
          onBack={onBack}
          type="weekday"
          disabled={isWeekendSelected}
          handleSuccess={handleSuccess}
          isWeekend={false}
          errorMessage={errorMessage}
          {...restProps}
        />
      </div>
      {changingWeekdays && (
        <div ref={weekend}>
          <WeekRates
            plan={plan}
            onBack={onBack}
            type="weekend"
            disabled={!isWeekendSelected}
            handleSuccess={handleSuccess}
            handlePrevious={handlePrevious}
            isWeekend={true}
            errorMessage={errorMessage}
            {...restProps}
          />
        </div>
      )}
    </div>
  )
}

export default withTheme(WeekPlan)
