import styled from 'styled-components'
import {
  Box as MuiBox,
  Collapse,
  Grid as MuiGrid,
  makeStyles,
  useTheme,
} from '@material-ui/core'
import { spacing } from '@material-ui/system'
import { Skeleton as MuiSkeleton } from '@material-ui/lab'
import IconToggleLabel from '../IconToggleLabel'
import { Clock } from 'react-feather'
import WeeklyTimePickerList, { DayTime } from '../WeeklyTimePickerList'
import { VehicleChargeSchedule } from '@/types'
import { ChargeScheduleDaysOfWeek } from '@/types/chargeSchedule'
import React from 'react'
import { Text } from '@/components'

const Grid = styled(MuiGrid)(spacing)
const Box = styled(MuiBox)(spacing)
const Skeleton = styled(MuiSkeleton)(spacing)

const useStyles = makeStyles((theme) => ({
  clockIcon: {
    alignSelf: 'flex-start',
    marginRight: theme.spacing(2),
  },
  grid: {
    paddingRight: '0px !important',
    paddingLeft: '0px !important',
  },
}))

const scheduleToDayTimes = (
  scheduleDaysOfWeeks: ChargeScheduleDaysOfWeek[],
  mode: 'departure' | 'charging',
) =>
  scheduleDaysOfWeeks
    .map((day) => {
      const time =
        mode === 'departure' ? day.departure_time : day.charging_start_time
      if (!time) {
        return null
      }
      const [hour, minute] = time.split(':')
      return {
        day: day.day_of_week,
        hour,
        minute,
      } as DayTime
    })
    .filter((dayTime) => dayTime !== null) as DayTime[]

// Map dayTimes to charge_schedule_days_of_week depending on the mode,
// filling in missing schedule days and returning the complete charge schedule
const dayTimesToChargeScheduleDaysOfWeek = (
  dayTimes: DayTime[],
  chargeSchedule: VehicleChargeSchedule,
  mode: 'departure' | 'charging',
) => {
  const isDeparture = mode === 'departure'
  const days = dayTimes.map((time) => ({
    day_of_week: time.day,
    departure_time: isDeparture
      ? `${time.hour}:${time.minute}:00`
      : chargeSchedule.charge_schedule_days_of_week.find(
          (d) => d.day_of_week === time.day,
        )?.departure_time ?? null,
    charging_start_time: !isDeparture
      ? `${time.hour}:${time.minute}:00`
      : chargeSchedule.charge_schedule_days_of_week.find(
          (d) => d.day_of_week === time.day,
        )?.charging_start_time ?? null,
  }))
  // append days in charge_schedule_days_of_week not found in dayTimes so they are not lost
  const missingDays = chargeSchedule.charge_schedule_days_of_week
    ?.filter((day) => !days.some((d) => d.day_of_week === day.day_of_week))
    .map((day) => ({
      day_of_week: day.day_of_week,
      charging_start_time: isDeparture ? day.charging_start_time : null,
      departure_time: !isDeparture ? day.departure_time : null,
    }))
    .filter((day) => day.charging_start_time || day.departure_time)
  return [...days, ...(missingDays ?? [])]
}

const ScheduledChargingSelector = ({
  chargeSchedule,
  onChange,
  mode,
}: {
  mode: 'departure' | 'charging'
  chargeSchedule: VehicleChargeSchedule
  onChange: (chargeSchedule: VehicleChargeSchedule) => void
}) => {
  const theme = useTheme()
  const classes = useStyles()

  const scheduledDepartureEnabled =
    mode === 'departure'
      ? chargeSchedule?.schedule_departure_enabled
      : chargeSchedule?.schedule_start_charge_enabled

  const onScheduledDepartureToggleChange = (checked: boolean) => {
    onChange({
      ...chargeSchedule,
      schedule_departure_enabled: checked,
    })
  }
  const onScheduledChargingToggleChange = (checked: boolean) => {
    onChange({
      ...chargeSchedule,
      schedule_start_charge_enabled: checked,
    })
  }

  const onScheduledDepartureTimeChange = (
    departures: DayTime[],
    chargeSchedule: VehicleChargeSchedule,
  ) => {
    onChange({
      ...chargeSchedule,
      charge_schedule_days_of_week: dayTimesToChargeScheduleDaysOfWeek(
        departures,
        chargeSchedule,
        mode,
      ) as unknown as ChargeScheduleDaysOfWeek[],
    })
  }
  const onScheduledChargingTimeChange = (
    chargingTimes: DayTime[],
    chargeSchedule: VehicleChargeSchedule,
  ) => {
    onChange({
      ...chargeSchedule,
      charge_schedule_days_of_week: dayTimesToChargeScheduleDaysOfWeek(
        chargingTimes,
        chargeSchedule,
        mode,
      ) as unknown as ChargeScheduleDaysOfWeek[],
    })
  }

  const onLastDelete = () => {
    if (mode === 'departure') {
      onChange({
        ...chargeSchedule,
        schedule_departure_enabled: false,
      })
    } else {
      onChange({
        ...chargeSchedule,
        schedule_start_charge_enabled: false,
      })
    }
  }
  const title = mode === 'departure' ? 'Ready By' : 'Start Charge'
  const subtitle =
    mode === 'departure'
      ? 'Ensures your vehicle is ready by your chosen time for maximum convenience. Ideal for most drivers.'
      : 'Starts charging at your chosen time. Use only if you need a specific start time. ‘Ready By’ is recommended.'
  const onChangeAction =
    mode === 'departure'
      ? onScheduledDepartureTimeChange
      : onScheduledChargingTimeChange
  const toggleAction =
    mode === 'departure'
      ? onScheduledDepartureToggleChange
      : onScheduledChargingToggleChange

  const recommendedBadge =
    mode === 'departure' ? (
      <Text
        variant={'label'}
        className={
          'rounded-full uppercase justify-center items-center bg-base-blue-100 text-base-blue-500 px-2 py-0.5'
        }
      >
        Recommended
      </Text>
    ) : undefined
  return (
    <Grid spacing={4} container justifyContent="center" alignItems="center">
      <Grid item xs={12} className={classes.grid}>
        <IconToggleLabel
          iconComponent={
            <Box className={classes.clockIcon}>
              <Clock color={theme.palette?.blue?.main} />
            </Box>
          }
          title={title}
          subtitle={subtitle}
          shouldDebounce={false}
          checked={scheduledDepartureEnabled}
          onChange={toggleAction}
          isLoading={!chargeSchedule}
          id={`scheduled-${mode}-toggle`}
          badge={recommendedBadge}
        />
      </Grid>
      <Collapse in={!!scheduledDepartureEnabled}>
        <Grid item xs={12} mt={4} py={4} px={7}>
          {chargeSchedule?.charge_schedule_days_of_week ? (
            <WeeklyTimePickerList
              mode={mode}
              dayTimes={scheduleToDayTimes(
                chargeSchedule.charge_schedule_days_of_week,
                mode,
              )}
              onChange={(dayTimes) => onChangeAction(dayTimes, chargeSchedule)}
              onLastDelete={onLastDelete}
            />
          ) : (
            <Skeleton variant="rect" width="100%" height={200} />
          )}
        </Grid>
      </Collapse>
    </Grid>
  )
}

export default ScheduledChargingSelector
