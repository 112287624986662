const allColors = [
  '#426BFB',
  '#42A2FB',
  '#42DAFB',
  '#FBD242',
  '#FFC555',
  '#FBB142',
  '#FB8542',
  '#FB5842',
  '#FB4242',
]

export default {
  diverging: {
    blueYellowRedBar(numColors) {
      if (numColors > allColors.length || numColors < 0) {
        throw new Error(
          `Number of colors must be between 0 and ${allColors.length}`,
        )
      }
      return {
        0: [],
        1: [2],
        2: [2, 7],
        3: [2, 5, 7],
        4: [2, 3, 5, 7],
        5: [1, 2, 3, 5, 7],
        6: [0, 1, 2, 3, 5, 7],
        7: [0, 1, 2, 3, 4, 5, 8],
        8: [0, 1, 2, 3, 4, 5, 7, 8],
        9: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      }[numColors].map((idx) => allColors[idx])
    },
    blueYellowRedLabel(numColors) {
      if (numColors > allColors.length || numColors < 0) {
        throw new Error(
          `Number of colors must be between 0 and ${allColors.length}`,
        )
      }
      return {
        0: [],
        1: [1],
        2: [1, 7],
        3: [1, 5, 7],
        4: [1, 3, 5, 7],
        5: [1, 2, 3, 5, 7],
        6: [0, 1, 2, 3, 5, 7],
        7: [0, 1, 2, 3, 4, 5, 8],
        8: [0, 1, 2, 3, 4, 5, 7, 8],
        9: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      }[numColors].map((idx) => allColors[idx])
    },
  },
}
