import './styles.scss'
import formatClasses from '../../../utils/classes/formatClasses'
import { Grid, Icon, Text } from '../../../components'
import { HTMLAttributes } from 'react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { Identifiable } from '@/types'

export interface Props extends Identifiable<HTMLAttributes<HTMLDivElement>> {
  completed: boolean
  label: string
  number: number
}

export default function TaskListItem(props: Props) {
  const classes = {
    listItem: formatClasses([
      'list-item',
      'task-list-item',
      props.completed ? 'complete' : 'incomplete',
    ]),
    taskBullet: formatClasses(['task-bullet']),
  }
  return (
    <div
      className={classes.listItem}
      onClick={!props.completed ? props.onClick : undefined}
      id={props.id}
    >
      <Grid
        gap="16px"
        placeItems="center start"
        alignItems="center"
        templateColumns={props.completed ? 'auto 1fr' : 'auto 1fr auto'}
      >
        <div className={classes.taskBullet}>
          <SwitchTransition mode="out-in">
            <CSSTransition
              classNames="task-bullet-content"
              in={props.completed}
              key={props.completed ? 'check' : 'task-number'}
              timeout={100}
              appear
            >
              {props.completed ? (
                <Icon name="Check" color="green-500" size={20} />
              ) : (
                <div>{props.number}</div>
              )}
            </CSSTransition>
          </SwitchTransition>
        </div>
        <Text>{props.label}</Text>
        {!props.completed && (
          <Icon color="grey-400" name="ChevronRight" size={20} />
        )}
      </Grid>
    </div>
  )
}
