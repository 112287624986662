import { RootState } from '@/reducers'
import { thermostatsCollection } from '@/reducers/thermostats'

export function selectDevicesHaveLoaded(state: RootState) {
  const vehiclesLoaded = state.vehicles.hasFetched
  // if a root thermostat GET request has succeeded, we can assume we have initially fetched thermostats
  const thermostatsLoaded = thermostatsCollection.selectors.queryState
    .select(state, {
      method: 'GET',
      pathSegments: (segments) => segments?.length === 0,
    })
    .some((state) => state.status === 'succeeded')

  return vehiclesLoaded && thermostatsLoaded
}
