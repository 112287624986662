import { isNativeMobile } from '@/authenticated/hooks/useIsMobile'

type Props = {
  className?: string
}

const Header = ({ className }: Props) => {
  const href = isNativeMobile() ? undefined : '/'
  return (
    <div className={className}>
      <a href={href}>
        <img src="img/logo-color.webp" width={144} alt="logo" />
      </a>
    </div>
  )
}

export default Header
