import { Button, CardAction, CardActions, Grid, Text } from '@/components'
import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { FlowStep, NavigationPage } from 'app-components'
import check from '@/app/img/check.svg'
import useHomeEstimationNavigation, {
  HomeEditReasons,
} from '@/app/pages/home-estimations/useHomeEstimationNavigation'

function routeToDisplay(route: HomeEditReasons) {
  switch (route) {
    case HomeEditReasons.ModelSubmission:
      return 'submitting your HVAC model number'

    case HomeEditReasons.Confirmation:
      return 'confirming your home information'

    case HomeEditReasons.TagSubmission:
      return 'submitting your HVAC tag'

    case HomeEditReasons.WizardSubmission:
      return 'answering questions about your home'

    default:
      return 'submitting your home information'
  }
}

type PathParams = {
  reason: HomeEditReasons
}

export default function HomeEstimationSuccess(props: any) {
  const navigate = useHomeEstimationNavigation()

  const { reason } = useParams<PathParams>()
  const displayReason = routeToDisplay(reason)

  const onClick = useCallback(() => {
    navigate.returnFromSuccess()
  }, [])

  return (
    <NavigationPage id="hvac-edit-success-page" navigationOptions={['nothing']}>
      <FlowStep id="hvac-edit-success-flow">
        <FlowStep.Content>
          <Grid
            className="min-h-[400px]"
            flow="row"
            gap="24px"
            placeItems="center"
            placeContent="center"
          >
            <img alt={props.mode} src={check} />
            <Text className="max-w-[275px]" variant="h3" align="center">
              Great! Thank you for {displayReason}
            </Text>
          </Grid>
        </FlowStep.Content>
        <FlowStep.Actions>
          <CardActions>
            <CardAction type="primary" onClick={onClick}>
              <Button id="hvac-success-finish">Got it</Button>
            </CardAction>
          </CardActions>
        </FlowStep.Actions>
      </FlowStep>
    </NavigationPage>
  )
}
