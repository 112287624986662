import mixpanel from 'mixpanel-browser'
import * as Sentry from '@sentry/browser'
import { isProduction } from '@/utils/appUtilities'
import { isNativeMobile } from '@/authenticated/hooks/useIsMobile'
import isMobile from 'is-mobile'
import { setUTMCookies } from './cookie'
import posthog from 'posthog-js'
import { logException, logMessage } from './sentry'

if (!process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN) {
  const errorMessage = 'Cannot initialize Mixpanel without a token'
  Sentry.captureMessage(errorMessage)
  console.error(errorMessage)
}

mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN as string, {
  debug: !isProduction,
  batch_requests: false,
})

if (
  process.env.REACT_APP_PUBLIC_POSTHOG_KEY &&
  process.env.REACT_APP_PUBLIC_POSTHOG_HOST
) {
  posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY ?? '', {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
    person_profiles: 'always',
  })
} else {
  const errorMessage = 'Cannot initialize Posthog without a key and host'
  logMessage(errorMessage)
  console.error(errorMessage)
}

export function identify(userId: string, email?: string, name?: string) {
  if (!isProduction) {
    console.log(`Identifying user: ${userId}`)
    return
  }
  if (!posthog._isIdentified()) {
    posthog.identify(userId, { email, name })
  }
  mixpanel.identify(userId)
}

function _resetIdentity() {
  if (!isProduction) {
    console.log(`Resetting identity`)
    return
  }
  posthog.reset()
  mixpanel.reset()
}

export function resetIdentity() {
  try {
    _resetIdentity()
  } catch (e) {
    logException(e)
  }
}

export function track(
  event: string,
  params: {
    [key: string]: any
  } | null = null,
) {
  if (!isProduction) {
    console.log(`Tracking event: ${event}`, params)
    return
  }
  posthog.capture(event, { eventParams: params })
  mixpanel.track(event, { eventParams: params })
}

export function trackPosthog(
  event: string,
  params: { [key: string]: any } | null = null,
) {
  if (!isProduction) {
    console.log(`Tracking posthog event: ${event}`, params)
    return
  }

  posthog.capture(event, { eventParams: params })
}

export function trackPageview() {
  const properties = {
    platform: isNativeMobile()
      ? 'mobile-native'
      : isMobile()
      ? 'mobile-web'
      : 'web',
    source: isMobile() ? 'mobile' : 'web',
  }
  if (!isProduction) {
    console.log(
      `Tracking Pageview: ${document.location} with properties:`,
      properties,
    )
    return
  }
  posthog.capture('$pageview', {
    $set: {
      ...properties,
    },
  })
  mixpanel.track_pageview(properties)
}

/**
 * This is a workaround to track pageviews in a single page application.
 */
history.pushState = ((f) =>
  function pushState(...args: unknown[]) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const ret = f.apply(this, args)
    window.dispatchEvent(new Event('locationchange'))
    return ret
  })(history.pushState)

history.replaceState = ((f) =>
  function replaceState(...args: unknown[]) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const ret = f.apply(this, args)
    window.dispatchEvent(new Event('locationchange'))
    return ret
  })(history.replaceState)

window.addEventListener('popstate', () => {
  window.dispatchEvent(new Event('locationchange'))
})

window.addEventListener('locationchange', () => {
  console.log(`location: ${document.location}`)
  setUTMCookies(location.search)
  trackPageview()
})
window.addEventListener('load', () => {
  console.log(`location: ${document.location}`)
  setUTMCookies(location.search)
  trackPageview()
})
