import { Shadows } from '@material-ui/core/styles/shadows'

function createShadow(px: number, percent: number) {
  return `0 0 ${px}px 0 rgba(53,64,82,${percent / 100})`
}

const shadows = ['none'].concat(
  Array(24)
    .fill(14)
    .map((px, index) => createShadow(px, (index + 1) * 4)),
) as Shadows

export default shadows
