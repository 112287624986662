import { AdaptiveModal, DeviceConfirmationPanel } from '@/app/components'
import DeviceConnectionRedirect, {
  ConnectionStatusMode,
} from '@/authenticated/components/DeviceConnectionRedirect'
import {
  Button,
  CardAction,
  CardActions,
  Icon,
  ModalProps,
  Text,
} from '@/components'
import { useAppSelector, useMockableViewModel } from '@/hooks'
import { useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Paper } from '@material-ui/core'
import { DeviceConfirmationPanelProps } from '@/app/components/DeviceConfirmationPanel'
import { Vehicle } from '@/types'
import '@/authenticated/components/Vehicle/ConnectVehicleConfirmation/styles.scss'
import { useNavigation } from '@/app/hooks'
import { canCharge } from '@/utils/vehicles/permissions'
import { getVehicleDisplayModel } from '@/utils/CarUtils'
import { useIsAvaBasePath } from '@/app/hooks/ava/useAvaVerification'
import { useLocation } from 'react-router-dom'
import { ButtonContainer } from '@/app/pages/ava/BrandedPage'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    paddingTop: theme.spacing(6.5),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(10),
      paddingTop: theme.spacing(4),
    },
  },
  connectButton: {
    backgroundColor: theme.palette.primary.main,
    marginTop: 20,
  },
}))
const statuses = ['connecting', 'connected', 'failed'] as const
export type StatusType = (typeof statuses)[number]
export const statusValues = statuses.map((s) => s.valueOf())
type Props = ModalProps & {
  mode: StatusType
  vehicles?: Vehicle[]
  /**
   * onRetry used in case of failed connection
   */
  onRetry: () => void
  onFailure: () => void
}

const useViewModel = useMockableViewModel({
  useViewModel(props: Props) {
    const { mode, onRetry, ...modalProps } = props
    const navigation = useNavigation()
    const isAva = useIsAvaBasePath()
    const isOnboarding = useLocation().pathname.includes('/onboarding/')
    const vehicles = props.vehicles
    // Get recently connected vehicles
    const recentVehicles = useAppSelector((state) =>
      Array.isArray(state.vehicleAuth.mfaData) ? state.vehicleAuth.mfaData : [],
    ) as Vehicle[]
    const canChargeAllVehicles =
      recentVehicles.length > 0 && recentVehicles.every((v) => canCharge(v))

    const connectionStatusMode = {
      connecting: ConnectionStatusMode.Connecting,
      failed: ConnectionStatusMode.Failed,
      connected: ConnectionStatusMode.Connected,
    }[props.mode]

    const onFinish = useRef(() => {
      if (isAva && isOnboarding) {
        return navigation.push('/ava/onboarding/add-another')
      }
      if (recentVehicles.length > 0) {
        return navigation.push('/connect-vehicle/configure')
      }

      return navigation.push('/app')
    })

    const buttonAction = {
      connecting: onFinish.current,
      // If we can't charge any of the vehicles, we let the user continue
      // so they don't get stuck in a loop since our key check is based
      // on changing the charge control
      failed: canChargeAllVehicles ? props.onRetry : onFinish.current,
      connected: onFinish.current,
    }[props.mode]

    const skipAction = onFinish.current

    return {
      connectionStatusMode,
      buttonAction,
      skipAction,
      vehicles,
      onRetry,
      ...modalProps,
    }
  },
  useMockViewModel(props: Props) {
    const { mode, onRetry, ...modalProps } = props
    return {
      connectionStatusMode: ConnectionStatusMode.Connected,
      buttonAction: () => {},
      skipAction: () => {},
      vehicles: [],
      onRetry,
      ...modalProps,
    }
  },
})

export default function KeyConfirmationStatusModal(props: Props) {
  const classes = useStyles()
  const {
    connectionStatusMode,
    buttonAction: defaultButtonAction,
    skipAction,
    vehicles,
    onRetry,
    ...modalProps
  } = useViewModel(props)
  const buttonAction = defaultButtonAction
  const deviceConnections: DeviceConfirmationPanelProps[] = (
    vehicles ?? []
  ).map((vehicle: Vehicle) => {
    const hasPublicKey = vehicle.car.public_key_not_attached_at === null
    const model = getVehicleDisplayModel(vehicle)
    const friendlyName = vehicle.car?.car_model?.friendly_name
    const make = vehicle.car?.car_model?.make
    const carDescriptor = friendlyName
      ? friendlyName
      : make
      ? `${make} ${model}`
      : 'Unknown'

    const year = vehicle.car.car_model?.year
    const carFriendlyName = `${year} ${carDescriptor}`

    const device: DeviceConfirmationPanelProps = {
      device: {
        name: vehicle.car.display_name,
        description: carFriendlyName,
      },
      permissions: [
        {
          name: 'Virtual Key Paired',
          allowed: hasPublicKey,
        },
      ],
    }

    if (!hasPublicKey) {
      device.permissions[0] = {
        name: 'Virtual Key Not Paired',
        description: 'Pair it to access features including:',
        associatedFeatures: [
          'Save Money or Save The Planet',
          'Scheduled Departure',
          'Trips',
        ],
        allowed: hasPublicKey,
      }
    }

    return device
  })

  if (connectionStatusMode === ConnectionStatusMode.Connected) {
    const anyVehicleHasAnIssue = deviceConnections.some(
      // @todo comment why do we only check the first permission?
      (device) => !device.permissions[0].allowed,
    )
    const title = `Great! We verified ${vehicles?.length} Tesla
    ${vehicles?.length === 1 ? 'vehicle' : 'vehicles'}`
    const description = `The test on your ${
      vehicles?.length === 1 ? 'car' : 'cars'
    } has returned valid results, confirming a seamless connection.`
    const buttonText = 'Continue'

    // If any vehicle has an issue, change the title
    if (anyVehicleHasAnIssue) {
      props.onFailure()
      return
    }

    return (
      <AdaptiveModal {...modalProps}>
        <Paper className={classes.wrapper} elevation={0}>
          <div className="confirmation-container">
            <div>
              <div className="confirmation-header">
                <div className="title-container">
                  <div className="icon-container">
                    <div className="check">
                      <Icon name="Check" color="white" size={12} />
                    </div>
                  </div>
                  <Text variant="h2">{title}</Text>
                </div>
                <Text variant="body4">{description}</Text>
              </div>
              {deviceConnections.map((deviceConnection) => (
                <DeviceConfirmationPanel
                  key={deviceConnection.device.name}
                  device={deviceConnection.device}
                  permissions={deviceConnection.permissions}
                />
              ))}
            </div>
            <ButtonContainer>
              <Button
                id="confirm-device-connection-button"
                onClick={buttonAction}
                variant="primary"
              >
                {buttonText}
              </Button>
            </ButtonContainer>
          </div>
        </Paper>
      </AdaptiveModal>
    )
  }

  const buttonText = {
    connected: 'Continue',
    failed: 'Continue',
    connecting: 'Cancel',
  }[props.mode]

  return (
    <AdaptiveModal {...modalProps}>
      <DeviceConnectionRedirect
        className="min-h-0"
        mode={connectionStatusMode}
        statusText={{
          connecting: "Hold tight...we're confirming your connection",
          connected: 'Successfully Verified',
          failed: 'Oops, Something went wrong',
        }}
        statusSubText={{
          connected: 'Thank you! Your account has been verified.',
          failed:
            'Please log in to your Tesla account and double-check that you have shared your virtual key, then try again.',
        }}
      />
      <CardActions>
        <CardAction type="secondary">
          <Button
            id="skip-button"
            onClick={skipAction}
            variant="secondary"
            className="mt-4"
          >
            Skip
          </Button>
        </CardAction>
        <CardAction type="primary">
          <Button
            id="confirm-device-connection-button"
            className={classes.connectButton}
            onClick={buttonAction}
            variant="primary"
          >
            {buttonText}
          </Button>
        </CardAction>
      </CardActions>
    </AdaptiveModal>
  )
}
