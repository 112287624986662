import * as actions from '../actions/vehicles'
import * as vehicleAuthActions from '../actions/vehicleAuth'
import { getApiErrorMessage } from '../utils/errorUtilities'
import { selectedVehicle as getSelectedVehicle } from '../selectors/vehicles'
import { TeslaFleetAuthentication, Vehicle } from '@/types'
import { PayloadAction } from '@reduxjs/toolkit'
import { teslaFleetAuthenticationCollection } from './teslaFleetAuthentication'

type State = {
  vehicles: Vehicle[] | null
  isLoading: boolean
  errors: Record<string, unknown>
  hasFetched: boolean
}

const initialState: State = {
  vehicles: null,
  isLoading: false,
  errors: {},
  hasFetched: false,
}

function upsertVehicles(
  existingVehicles: Vehicle[] | null,
  newVehicles: Vehicle[],
): Vehicle[] {
  const existingVehicleIds = existingVehicles?.map((vehicle) => vehicle.id)
  const newVehicleIds = newVehicles.map((vehicle) => vehicle.id)
  const vehicleIds = new Set(existingVehicleIds?.concat(newVehicleIds) ?? [])
  const vehicles = Array.from(vehicleIds)
    .map((vehicleId) => {
      const newVehicle = newVehicles.find((vehicle) => vehicle.id === vehicleId)
      if (newVehicle) {
        return newVehicle
      }
      return existingVehicles?.find((vehicle) => vehicle.id === vehicleId)
    })
    .filter((vehicle) => vehicle !== undefined) as Vehicle[]
  return vehicles
}

export default (state = initialState, action: PayloadAction<any>): State => {
  switch (action.type) {
    case vehicleAuthActions.MULTIFACTOR_VEHICLE_SUCCESS:
    case vehicleAuthActions.CONNECT_VEHICLE_SUCCESS: {
      const showMfa =
        Object.prototype.hasOwnProperty.call(
          action.payload,
          'should_show_mfa',
        ) && action.payload.should_show_mfa === true
      if (showMfa) {
        return {
          ...state,
          isLoading: false,
          hasFetched: true,
        }
      }
      return {
        ...state,
        vehicles: upsertVehicles(state.vehicles, action.payload ?? []),
        isLoading: false,
        hasFetched: true,
      }
    }
    case actions.VEHICLES_SUCCESS:
      return {
        ...state,
        vehicles: action.payload,
        isLoading: false,
        hasFetched: true,
      }
    case vehicleAuthActions.DELETE_VEHICLE_REQUEST: {
      // Optimistically remove the deleted vehicle from vehicles
      const vehicles =
        state.vehicles?.filter((vehicle) => vehicle.id !== action.payload.id) ??
        null
      return {
        ...state,
        vehicles,
      }
    }
    case vehicleAuthActions.DELETE_VEHICLE_FAILURE: {
      // Revert the optimistic deletion of the vehicle
      const vehicle = action.payload.vehicle
      const vehicles = state.vehicles?.concat(vehicle) ?? null
      return {
        ...state,
        vehicles,
      }
    }
    case actions.CHANGE_VEHICLE_REQUEST: {
      const optimisticSelectedVehicle = action.payload
      // If a selectedVehicle is passed, then we optimistically update the state
      if (!optimisticSelectedVehicle) {
        return {
          ...state,
          isLoading: true,
        }
      }
      return {
        ...state,
        vehicles:
          state.vehicles?.map((vehicle) => ({
            ...vehicle,
            is_selected_vehicle: vehicle.id === optimisticSelectedVehicle.id,
          })) ?? null,
        // do not indicate loading if we are optimistically changing the selected vehicle
        isLoading: false,
      }
    }
    case actions.CHANGE_VEHICLE_SUCCESS: {
      const selectedVehicle = action.payload
      // If the selected vehicle is the same as previously, don't update the selectedVehicle
      if (getSelectedVehicle(state.vehicles)?.id === selectedVehicle.id) {
        return {
          ...state,
          isLoading: false,
        }
      }
      return {
        ...state,
        vehicles:
          state.vehicles?.map((vehicle) => ({
            ...vehicle,
            is_selected_vehicle: vehicle.id === selectedVehicle.id,
          })) ?? null,
        isLoading: false,
      }
    }
    case actions.CHANGE_VEHICLE_FAILURE: {
      const previouslySelectedVehicle = action.payload.previouslySelectedVehicle
      // If the previouslySelectedVehicle is passed, then we revert the optimistic update
      if (!previouslySelectedVehicle) {
        return {
          ...state,
          isLoading: false,
          errors: getApiErrorMessage(action.payload),
        }
      }
      return {
        ...state,
        vehicles:
          state.vehicles?.map((vehicle) => ({
            ...vehicle,
            is_selected_vehicle: vehicle.id === previouslySelectedVehicle.id,
          })) ?? null,
        isLoading: false,
      }
    }
    case actions.DISABLE_VEHICLE_ACTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case actions.Patch.Request:
    case actions.VEHICLES_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case actions.DISABLE_VEHICLE_ACTIONS_REQUEST: {
      const vehicle_id = action.payload
      const vehicles =
        state.vehicles?.map((vehicle) => ({
          ...vehicle,
          is_charge_control_enabled:
            vehicle.id === vehicle_id
              ? false
              : vehicle.is_charge_control_enabled,
        })) ?? null
      return {
        ...state,
        vehicles,
        isLoading: true,
      }
    }
    case actions.Patch.Failure:
    case actions.DISABLE_VEHICLE_ACTIONS_FAILURE:
    case actions.VEHICLES_FAILURE:
    case vehicleAuthActions.CONNECT_VEHICLE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: getApiErrorMessage(action.payload),
        hasFetched: true,
      }
    case actions.CLEAR_VEHICLE_ERRORS:
      return {
        ...state,
        errors: {},
      }
    case actions.Patch.Success:
      return {
        ...state,
        isLoading: false,
        vehicles:
          state.vehicles?.map((vehicle) =>
            vehicle.id === action.payload.id ? action.payload : vehicle,
          ) ?? null,
      }
    case teslaFleetAuthenticationCollection.actionTypes.create
      .SuccessResolved: {
      const payload = action.payload as TeslaFleetAuthentication
      const newlyConnectedVehicles = payload.vehicles ?? []
      return {
        ...state,
        vehicles: (state.vehicles ?? []).concat(newlyConnectedVehicles),
      }
    }
    case actions.GET_VEHICLE_UPDATE_TESLA_PUBLIC_KEY_SUCCESS: {
      const payload = action.payload as Vehicle
      return {
        ...state,
        vehicles: upsertVehicles(
          state.vehicles,
          action.payload ? [payload] : [],
        ),
      }
    }
    default:
      return state
  }
}
