import React from 'react'
import {
  Grid as MuiGrid,
  Typography as MuiTypography,
  Button,
} from '@material-ui/core'
import DashboardCard from '../DashboardCard'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import { makeStyles, createStyles } from '@material-ui/core/styles'

const Grid = styled(MuiGrid)(spacing)
const Typography = styled(MuiTypography)(spacing)

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      maxWidth: '400px',
    },
    buttonContainer: {
      textAlign: 'center',
    },
    headerText: {
      marginLeft: theme.spacing(1),
    },
    subtitle: {
      fontSize: '12px',
    },
  }),
)

export interface Props {
  title: string
  subtitle: string
  buttonText: string
  onButtonClick: () => void
  onClose: () => void
  children?: React.ReactNode
}

export default function MeterConnectionCard({
  title,
  subtitle,
  buttonText,
  onButtonClick,
  onClose,
  children,
}: Props) {
  const classes = useStyles()

  return (
    <DashboardCard onClose={onClose}>
      <Grid container spacing={10}>
        <Grid className={classes.headerText} item xs={12}>
          <Typography variant="h6" mb={1}>
            {title}
          </Typography>
          <Typography className={classes.subtitle} variant="subtitle2">
            {subtitle}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          {children}
        </Grid>

        <Grid className={classes.buttonContainer} item xs={12}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            fullWidth
            onClick={onButtonClick}
          >
            {buttonText}
          </Button>
        </Grid>
      </Grid>
    </DashboardCard>
  )
}
