import React, { useState, useRef, useEffect } from 'react'
import { Icon, Text } from '@/components'
import { ChevronDown, ChevronUp } from 'react-feather'

export type MultilineSelectOption = {
  label: string
  line1: string
  line2?: string
  onSelect?: () => void
}

type MultilineSelectProps = {
  options: MultilineSelectOption[]
  selectedOption: MultilineSelectOption | undefined
  onChange: (value: MultilineSelectOption) => void
  hideCurrentSelection?: boolean
  className?: string
}

const MultilineSelect = ({
  options,
  selectedOption,
  onChange,
  hideCurrentSelection,
  className,
}: MultilineSelectProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null)
  const selectRef = useRef<HTMLDivElement>(null)

  const handleSelect = (option: MultilineSelectOption, index: number) => {
    onChange(option)
    option?.onSelect?.()
    setIsOpen(false)
    setFocusedIndex(index)
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (!isOpen && (event.key === 'Enter' || event.key === ' ')) {
      // If closed and Enter or Space is pressed, open the dropdown
      event.preventDefault()
      setIsOpen(true)
    } else if (isOpen) {
      if (event.key === 'ArrowDown') {
        event.preventDefault()
        setFocusedIndex((prev) =>
          prev === null || prev >= options.length - 1 ? 0 : prev + 1,
        )
      } else if (event.key === 'ArrowUp') {
        event.preventDefault()
        setFocusedIndex((prev) =>
          prev === null || prev <= 0 ? options.length - 1 : prev - 1,
        )
      } else if (event.key === 'Enter' && focusedIndex !== null) {
        event.preventDefault()
        handleSelect(options[focusedIndex], focusedIndex)
      } else if (event.key === 'Escape') {
        setIsOpen(false)
      }
    }
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (
      selectRef.current &&
      !selectRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div
      className={`relative inline-block w-full select-none ${className}`}
      ref={selectRef}
      aria-expanded={isOpen}
      aria-haspopup="listbox"
    >
      <div
        className={`border border-solid border-gray-200 rounded-[8px] ${
          isOpen ? 'rounded-b-none border-b-0 mb-[1px]' : ''
        }`}
      >
        <div
          className="py-2 px-3 cursor-pointer min-h-[54px]"
          onClick={() => setIsOpen(!isOpen)}
          role="combobox"
          aria-controls="multiline-select-listbox"
          aria-expanded={isOpen}
          aria-activedescendant={
            focusedIndex !== null
              ? `multiline-select-option-${focusedIndex}`
              : undefined
          }
          tabIndex={0}
          onKeyDown={handleKeyDown}
        >
          {selectedOption ? (
            <div className="flex flex-row justify-between items-center">
              <div className="flex-col">
                <Text variant="body1">{selectedOption.label}</Text>
                <Text variant="body4">{selectedOption.line1}</Text>
                {selectedOption.line2 && (
                  <Text variant="body4">{selectedOption.line2}</Text>
                )}
              </div>
              <div className="flex items-center ml-auto">
                {isOpen ? (
                  <Icon name="ChevronUp" color="grey-400" size={24} />
                ) : (
                  <Icon name="ChevronDown" color="grey-400" size={24} />
                )}
              </div>
            </div>
          ) : (
            <Text variant="body1">Select an address</Text>
          )}
        </div>
      </div>

      {isOpen && (
        <ul
          id="multiline-select-listbox"
          className="absolute z-[15] w-full bg-white max-h-60 overflow-auto border border-solid border-gray-200 border-t-0 rounded-b-[8px]"
          role="listbox"
          aria-labelledby="combobox"
        >
          {options.map((option, index) => {
            if (hideCurrentSelection && option === selectedOption) {
              return null
            }

            const isFocused = focusedIndex === index

            return (
              <li
                key={index}
                id={`multiline-select-option-${index}`}
                role="option"
                aria-selected={selectedOption === option}
                className={`px-3 py-2 hover:bg-gray-100 cursor-pointer ${
                  isFocused ? 'bg-gray-100' : ''
                }`}
                onClick={() => handleSelect(option, index)}
                onMouseEnter={() => setFocusedIndex(index)}
                tabIndex={-1}
              >
                <div className="flex flex-col">
                  <Text variant="body1">{option.label}</Text>
                  <Text variant="body4">{option.line1}</Text>
                  {option.line2 && <Text variant="body4">{option.line2}</Text>}
                </div>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default MultilineSelect
