import { createResourceCollection } from '@/request'
import { UtilityAccount } from '@/types'
import { Model } from '@/types/model'

export const utilityAccountsCollection = createResourceCollection<
  UtilityAccount & Model
>({
  name: 'utilityAccounts',
  selector: (state) => state.utilityAccounts,
  // user should only have one utility account number
  computedId: () => 'unique',
})

export default utilityAccountsCollection.slice.reducer
