import { Link, useLocation } from 'react-router-dom'
import AlertMessage from '../AlertMessage'

type Props = React.ComponentProps<typeof AlertMessage> & {
  linkTo: Partial<ReturnType<typeof useLocation>> | string
  linkText: string
  onLinkClick?: () => void
}

export default function AlertMessageLink(props: Props) {
  const { linkTo, linkText, onLinkClick, children, ...alertMessageProps } =
    props
  const location = useLocation()
  const to =
    typeof linkTo === 'string'
      ? linkTo
      : {
          ...location,
          ...linkTo,
        }
  return (
    <AlertMessage {...alertMessageProps}>
      {children}
      <Link
        to={to}
        onClick={onLinkClick}
        style={{ display: 'block', marginTop: '10px' }}
      >
        {linkText}
      </Link>
    </AlertMessage>
  )
}
