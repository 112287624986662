import Icon from '@/components/Icon/Icon'
import Text from '@/components/Text/Text'
import TeslaEditPermissions from '../TeslaEditPermissions/TeslaEditPermissions'
import { Button } from '@/components'

export default function TeslaMissingRequiredPermission() {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row gap-4 items-center">
        <div
          className={`flex justify-center items-center w-8 h-8 rounded-lg gap-1 bg-themed-warning`}
        >
          <Icon name="AlertCircle" size={22} color="white" />
        </div>
        <div className="font-bold text-xl text-themed-neutral">
          Missing Permissions
        </div>
      </div>
      <Text variant="body4">
        While selecting all permissions is recommended, the vehicle information
        and vehicle location permissions are necessary to continue. Please see
        the steps below to edit permissions.
      </Text>
      <TeslaEditPermissions />
      <Button
        id="tesla-missing-permissions-disabled-button"
        variant="primary"
        disabled={true}
      >
        Continue
      </Button>
    </div>
  )
}
