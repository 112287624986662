import React, { useCallback } from 'react'
import styled from 'styled-components'
import { darken, rgba } from 'polished'
import { spacing } from '@material-ui/system'
import { NavLink as RouterNavLink, withRouter } from 'react-router-dom'

import PerfectScrollbar from 'react-perfect-scrollbar'
import '../../vendor/perfect-scrollbar.css'
import { Edit } from 'react-feather'

import Experimental from '../Experimetal'
import theme from '../../../v2/theme'
import { ThemeProvider } from '@material-ui/core/styles'

import {
  Chip,
  Divider,
  Drawer as MuiDrawer,
  Grid as MuiGrid,
  Link as MuiLink,
  List as MuiList,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core'

import { tabbedRouteSpecs } from '../../../app/routes'
import getColor from '../../../utils/color/getColor'
import FeedbackDialogContactForm from '@/authenticated/components/feedback/FeedbackDialogContactForm'
import { useNavigation } from '@/app/hooks'
import { Text } from '@/components'
import { useMousePress } from '@/authenticated/hooks/useMousePress'

const Grid = styled(MuiGrid)(spacing)

const NavLink = React.forwardRef((props, ref) => (
  <RouterNavLink innerRef={ref} {...props} />
))

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`

const List = styled(MuiList)`
  background-color: ${(props) => props.theme.sidebar.background};
`

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)}px;
  padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
`

const Brand = styled(ListItem)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)}px;
  padding-right: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up('sm')} {
    min-height: 64px;
  }
`

const Category = styled(ListItem)`
  padding-top: ${(props) => props.theme.spacing(3)}px;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
  padding-left: ${(props) => props.theme.spacing(6)}px;
  padding-right: ${(props) => props.theme.spacing(5)}px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) =>
      darken(0.05, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    font-family: ${theme.typography.fontFamily};
    color: ${(props) => props.theme.sidebar.color};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    font-weight: ${theme.typography.fontWeightBold};
    padding: 0 ${(props) => props.theme.spacing(4)}px;
  }
`

const Link = styled(ListItem)`
  padding-left: ${(props) => props.theme.spacing(14)}px;
  padding-top: ${(props) => props.theme.spacing(2)}px;
  padding-bottom: ${(props) => props.theme.spacing(2)}px;
  span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
  }
  &:hover span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
  }
  &.${(props) => props.activeClassName} {
    background-color: ${(props) =>
      darken(0.06, props.theme.sidebar.background)};
    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`

const LinkText = styled(ListItemText)`
  color: ${(props) => props.theme.sidebar.color};
  padding-left: 4px;
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    font-weight: ${theme.typography.fontWeightMedium};
  }
  margin-top: 0;
  margin-bottom: 0;
`

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 12px;
  top: 8px;
  background: ${(props) => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`

const SidebarFooter = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: 23px ${(props) => props.theme.spacing(4)}px 20px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`

const SidebarFooterText = styled(Typography)`
  font-size: 14px;
  color: #bdbdbd;
  font-weight: ${theme.typography.fontWeightBold};
`

const FeedbackLink = styled(MuiLink)`
  color: #bdbdbd;
  &:hover {
    color: white;
  }
`

const FeedbackIcon = styled(Edit)`
  margin-right: 7px;
  margin-bottom: 3px;
  color: #bdbdbd;
  &:fill {
    color: red;
  }
`

const LogoLink = styled.a`
  display: grid;
  place-items: center;
`

function MaybeExperimental({ experimental, children }) {
  return experimental ? <Experimental>{children}</Experimental> : children
}

function SidebarCategory({
  name,
  icon: Icon,
  classes,
  isOpen,
  badge,
  ...rest
}) {
  const navigate = useNavigation()
  const isMousePress = useMousePress()

  const onClick = useCallback(
    (e) => {
      e.preventDefault()
      navigate.push(rest.to)
    },
    [rest.to],
  )

  return (
    <a
      onMouseDown={isMousePress ? onClick : undefined}
      onClick={!isMousePress ? onClick : undefined}
      className="cursor-pointer"
    >
      <div className="flex pl-5 py-2.5 hover:bg-[#19202D] transition-colors duration-200">
        <Icon
          className={`h-5 mt-0.5 ${
            isOpen ? 'text-themed-primary' : 'text-themed-base-400'
          }`}
        />
        <Text variant="body5" className="text-themed-base-100 px-4">
          {name}
        </Text>
      </div>
    </a>
  )
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showFeedback: false,
    }
    this.toggleFeedback = this.toggleFeedback.bind(this)
    this.toggleFeedbackAndPreventEvent =
      this.toggleFeedbackAndPreventEvent.bind(this)
  }

  toggleFeedback() {
    this.setState((state) => ({ showFeedback: !state.showFeedback }))
  }

  toggleFeedbackAndPreventEvent(e) {
    e.preventDefault()
    this.toggleFeedback()
  }

  toggle(index) {
    // Collapse all elements
    Object.keys(this.state).forEach(
      (item) =>
        this.state[index] ||
        this.setState(() => ({
          [item]: false,
        })),
    )

    // Toggle selected element
    this.setState((state) => ({
      [index]: !state[index],
    }))
  }

  UNSAFE_componentWillMount() {
    // Helper function to convert path to regex pattern
    const pathToRegex = (path) => {
      const pattern = path
        .replace(/:([A-Za-z]+)\?/g, '([^/]+)?') // Convert optional params
        .replace(/:([A-Za-z]+)/g, '([^/]+)') // Convert params
        .replace(/\//g, '\\/') // Escape slashes
      return new RegExp(`^${pattern}`)
    }
    /* Open collapse element that matches current url */
    const pathName = this.props.location.pathname

    tabbedRouteSpecs.forEach((route, index) => {
      // Ensure route.path is defined and is either a string or an array
      const paths = route.path
        ? Array.isArray(route.path)
          ? route.path
          : [route.path]
        : []
      // Check if pathName matches any pattern in the route.path array
      const isActive = paths.some((singlePath) =>
        pathToRegex(singlePath).test(pathName),
      )
      const isOpen = route.open
      const isHome = route.containsHome && pathName === '/' ? true : false
      this.setState(() => ({
        [index]: isActive || isOpen || isHome,
      }))
    })
  }

  render() {
    const { classes, staticContext, ...other } = this.props

    return (
      <ThemeProvider theme={theme}>
        <Drawer variant="permanent" {...other}>
          <Brand>
            <LogoLink href="/app">
              <img
                src="/img/logo-small.webp"
                width="120px"
                alt="app"
                className="img-fluid"
              />
            </LogoLink>
          </Brand>
          <Scrollbar>
            <List disablePadding>
              <Items>
                {tabbedRouteSpecs.map(
                  (category, index) =>
                    !category.hidden && (
                      <MaybeExperimental
                        experimental={category.experimental}
                        key={index}
                      >
                        <React.Fragment>
                          <SidebarCategory
                            className={category.className}
                            isOpen={this.state[index]}
                            name={category.id}
                            to={
                              category.linkPath ??
                              (category.path &&
                                (Array.isArray(category.path)
                                  ? category.path[0]
                                  : category.path))
                            }
                            component={category.path && NavLink}
                            icon={category.icon}
                            badge={category.badge}
                            button={true}
                          />
                        </React.Fragment>
                      </MaybeExperimental>
                    ),
                )}
              </Items>
            </List>
            <Divider />
          </Scrollbar>
          <SidebarFooter>
            <Grid container spacing={2}>
              <Grid item>
                <FeedbackLink
                  onClick={(e) => this.toggleFeedbackAndPreventEvent(e)}
                  href="#"
                >
                  <SidebarFooterText
                    variant="body2"
                    className="flex flex-row items-center"
                  >
                    <FeedbackIcon size={16} />
                    <div>Feedback?</div>
                  </SidebarFooterText>
                </FeedbackLink>
              </Grid>
            </Grid>
          </SidebarFooter>
          <FeedbackDialogContactForm
            id="sidebar-feedback-survey"
            isOpen={this.state.showFeedback}
            onClose={this.toggleFeedback}
            supportTitle="Feedback: How can we improve?"
          />
        </Drawer>
      </ThemeProvider>
    )
  }
}

export default withRouter(Sidebar)
