import { AnimatePresence, motion } from 'framer-motion'
import React, { useMemo } from 'react'
import { clamp } from '@/utils/limit'

type Props = {
  percentWidth: number
  height: number
  className: string
  rounded: boolean
  children: React.ReactNode
}
export default function HorizontalBar(props: Props) {
  const clampedPercent = useMemo(
    () => clamp(0, props.percentWidth, 100),
    [props.percentWidth],
  )

  return (
    <AnimatePresence mode="wait">
      <div className="flex items-center gap-2">
        <motion.div
          initial={{ width: '6px' }}
          animate={{ width: `${clampedPercent}%` }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className={props.className}
          style={{
            height: props.height,
            minWidth: props.height,
            borderRadius: props.rounded ? props.height : 0,
          }}
        ></motion.div>
        <motion.div
          initial={{ opacity: 0, x: '50px' }}
          animate={{ opacity: 1, x: '0px' }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          {props.children}
        </motion.div>
      </div>
    </AnimatePresence>
  )
}
