import { useState } from 'react'
import { Text } from '@/components'
import { MultiselectOption } from '@/app/components/Multiselect'

type Props = {
  option: MultiselectOption
  index: number
  onClick?: (index: number, selected: boolean) => void
}

export default function MultiselectButton(props: Props) {
  const [selected, setSelected] = useState(props.option.selected)

  const onClick = () => {
    setSelected((prev) => {
      props.onClick?.(props.index, !prev)
      return !prev
    })
  }

  return (
    <button
      className="inline-flex items-center justify-center bg-themed-grey-100 rounded-full px-4 py-2.5 cursor-pointer border border-2 border-solid hover:bg-gray-200 transition"
      style={{
        borderColor: selected ? '#426FFB' : 'rgba(0, 0, 0, 0)',
      }}
      onMouseDown={onClick}
    >
      <Text variant="body1">{props.option.text}</Text>
    </button>
  )
}
