import { ThermostatLandingLayout } from '@/app/layouts'
import ContactForm from '@/app/layouts/ThermostatLanding/ContactForm'
import './faq-accordion.scss'

type FAQAccordionProps = {
  questions: {
    question: string
    answer: string
  }[]
}

export function FAQAccordion({ questions }: FAQAccordionProps) {
  return (
    <div className="accordion faq-accordion" id="faq-accordion">
      {questions.map(({ question, answer }, index) => (
        <div className="accordion-item" key={index}>
          <h2 className="accordion-header" id={`heading-${index}`}>
            <button
              className={`accordion-button`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse-${index}`}
              aria-expanded="true"
              aria-controls={`collapse-${index}`}
            >
              {question}
            </button>
          </h2>
          <div
            id={`collapse-${index}`}
            className={`accordion-collapse collapse`}
            aria-labelledby={`heading-${index}`}
            data-bs-parent="#faq-accordion"
          >
            <div className="accordion-body">{answer}</div>
          </div>
        </div>
      ))}
    </div>
  )
}

export const GeneralQuestions = [
  {
    question: 'Is Optiwatt secure? Is my password safe?',
    answer:
      'Optiwatt never stores your credentials on our servers and your data is 100% safe. When you connect Optiwatt to your manufacturer’s account using your login credentials, we receive an authorization token that can be used to communicate directly with your device. Optiwatt then stores an encrypted version of that authentication token and discards any sensitive data.',
  },
  {
    question: 'Does this work outside of the USA?',
    answer: 'Yes, we support all countries and currencies.',
  },
  {
    question: 'How does Optiwatt make money?',
    answer:
      'Our goal is to work with utility companies and reduce peak-load demand by helping you schedule your energy consumption during off-peak hours. This will save utilities money by preventing additional "peaker" power plants from needing to be fired up.',
  },
  {
    question: 'How does this save earth?',
    answer:
      'Using Optiwatt helps to avoid the need for utility companies to use less efficient power plants during high-demand periods. By shifting your energy use to times when demand is lower, we reduce reliance on these polluting sources, leading to fewer emissions and a cleaner environment.',
  },
]

export const ThermostatQuestions = [
  {
    question: 'What thermostat makes and models does Optiwatt support?',
    answer:
      'Optiwatt currently supports all internet-connected smart thermostats manufactured by Google Nest, ecobee, and Honeywell. We add new makes and models on a regular basis, so stay tuned for updates!',
  },
  {
    question: 'Does Optiwatt control my thermostat’s schedule?',
    answer:
      'No. Optiwatt only adjusts your thermostat’s temperature manually, as if you were turning the dial on the wall. Your normal schedule will resume shortly thereafter. You must make changes to your thermostat’s schedule in your manufacturer’s app, using Optiwatt’s cost insights to guide you. We hope to support smart scheduling very soon, so stay tuned for updates!',
  },
  {
    question: 'Is this different from my thermostat manufacturer’s app?',
    answer:
      "Yes, Optiwatt's technology allows you to see how much your heating or air conditioning will cost you in real-time. You can tweak temperature within the app, just as you would on the wall, and Optiwatt will estimate what you will save or spend as a result. Optiwatt also estimates how much you are likely to spend throughout the year as the outside temperature changes, and provides historical spending breakdowns by time period or comfort setting so you can better understand your energy bill.",
  },
  {
    question: 'Do I have to change the temperature in the Optiwatt app?',
    answer:
      'No, you can always change the temperature on the wall or in your manufacturer’s app, and then check in with Optiwatt to see how the change impacted your wallet. Optiwatt will never change your temperature setpoint unless you do.',
  },
  {
    question: 'How does the cost estimation work?',
    answer:
      'Optiwatt uses your home address to find the size of your home, its age, the outside temperature, and other relevant data points. This is used to calculate the thermal efficiency of your insulation, the daily losses that occur as a result of heat exchange, and the output of your heater(s) and/or air conditioner(s) that keep you comfortable. If you feel your costs are inaccurate, you can always contact Optiwatt’s customer support to update your profile.',
  },
  {
    question:
      'Does Optiwatt estimate natural gas, heating oil, or other costs associated with keeping my home comfortable?',
    answer:
      'No, Optiwatt only estimates the electrical costs associated with heating and cooling. During winter months, if you use natural gas or other combustible fuels to heat your home, Optiwatt will only show you the electrical cost associated with running the fans in these systems.',
  },
]

export const VehicleQuestions = [
  {
    question: 'What vehicle makes and models does Optiwatt support?',
    answer:
      'Optiwatt currently supports all Tesla models, the BMW i3, i3 BEV, i3s, 330e, 530e, 745e xDrive, 740e xDrive, i8, X3 xDrive30e, X5 xDrive40e, and X5 xDrive45e, the Chevrolet Bolt EV, Spark EV, and Volt, the Ford Focus Electric, Mach E, C-MAX Energi Plug-In Hybrid, Escape PHEV, and Fusion Energi Plug-In Hybrid, the Jaguar I-Pace and I-PaceEV400, the VW e-Golf and ID.4, the Audi A7 PHEV, A8 PHEV, Q5 PHEV, e-tron, Q4 e-tron, e-tron GT, the Cadillac CT6 plug-in and ELR, the Chrysler Pacifica Hybrid, the Hyundai Ioniq Plug-In Hybrid, Kona Electric, Sonata Plug-In Hybrid, and Ioniq Electric, the Jeep Wrangler 4xe, the Land Rover Range Rover PHEV and Ranger Rover Sport PHEV, the Lincoln Aviator PHEV and Corsair PHEV, the Nissan Leaf, the Volvo S60 PHEV, S90 PHEV, V60 PHEV, XC60 PHEV, and XC90 PHEV. We add new makes and models on a regular basis, so stay tuned for updates!',
  },
  {
    question: 'Does Optiwatt keep my car awake?',
    answer:
      'No. Optiwatt will stop requesting data whenever your car is ready to naturally fall asleep (i.e sitting in your driveway). This ensures your car sleeps during its normal cycle, to avoid causing any additional phantom drain.',
  },
  {
    question: 'Is this different from my vehicle manufacturer’s app?',
    answer:
      "Yes, Optiwatt's charging schedule provides more control and flexibility. In addition to postponing charging until the cheapest rates begin, it also stops charging as soon as the more expensive rates return. Optiwatt’s charging schedule dynamically adjusts on days where you drive more than normal, to ensure you still have the range you need for the following day.",
  },
  {
    question: 'How does the charge schedule work?',
    answer:
      'Optiwatt uses your utility plan and exact electricity rates in order to determine the cheapest possible times to charge. Users who want more control can set their target Ready By time and battery level and Optiwatt will determine the best hours to charge to ensure these targets are met.',
  },
  {
    question: 'Do I have to use Optiwatt’s scheduler?',
    answer:
      'No, all scheduler settings can be disabled upon sign-up or log-in if desired.',
  },
  {
    question: "What is Optiwatt's Preconditioning?",
    answer:
      'If you enable Preconditioning for your charge schedule in Optiwatt, Optiwatt will automatically warm the battery pack for your departure to reduce some of the effects of cold weather on your EV ❄️. When the battery has been warmed up, your vehicle will have better regenerative braking, more power available from the motors, and longer range.',
  },
]

export default function FAQPage() {
  return (
    <ThermostatLandingLayout>
      <section className="banner-area faq section-padding">
        <div className="container">
          <h4 className="sub-heading">FAQ</h4>
          <h2 className="section-heading">We’re here to help!</h2>

          <ul className="nav nav-tabs justify-content-center">
            <li className="nav-item">
              <a className="active" data-bs-toggle="tab" href="#general-faq">
                <button className="nav-link tab-btn">General</button>
              </a>
            </li>
            <li className="nav-item">
              <a data-bs-toggle="tab" href="#vehicle-faq">
                <button className="nav-link tab-btn">Vehicle</button>
              </a>
            </li>
            <li className="nav-item">
              <a data-bs-toggle="tab" href="#thermostat-faq">
                <button className="nav-link tab-btn">Thermostat</button>
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div className="tab-pane fade show active" id="general-faq">
              <FAQAccordion questions={GeneralQuestions} />
            </div>
            <div className="tab-pane fade" id="vehicle-faq">
              <FAQAccordion questions={VehicleQuestions} />
            </div>
            <div className="tab-pane fade" id="thermostat-faq">
              <FAQAccordion questions={ThermostatQuestions} />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-3"></div>
            <div className="col-lg-4 col-md-6">
              <div className="faq-form">
                <h3 className="faq-form-heading">
                  Don’t see your question? Ask us here:
                </h3>
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </section>
    </ThermostatLandingLayout>
  )
}
