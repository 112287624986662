import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import {
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core'
import {
  getUserInformation,
  getUserChargerInformation,
  getUserThermostatInformation,
  getUserVehicleInformation,
} from '../../../actions/admin'
import UpdatePlan from './UpdatePlan'
import VehicleTimeline from './VehicleTimeline'
import VehicleCommands from './VehicleCommands'
import ChargeForecastAdmin from './ChargeForecastAdmin'
import AuthenticationToken from './AuthenticationToken'
import UtilityEligibility from './UtilityEligibility'
import { Alert } from '@material-ui/lab'
import Loader from '../../components/layout/Loader'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}))

const adminComponents = [
  {
    name: 'Vehicle Timeline',
    component: VehicleTimeline,
  },
  {
    name: 'Vehicle Commands',
    component: VehicleCommands,
  },
  {
    name: 'Update Plan',
    component: UpdatePlan,
  },
  {
    name: 'Charge Forecast',
    component: ChargeForecastAdmin,
  },
  {
    name: 'Authentication Token',
    component: AuthenticationToken,
  },
  {
    name: 'Utility Program Eligibility',
    component: UtilityEligibility,
  },
]

export default function Admin() {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { profileID: adminProfileID } = useParams()
  const [email, setEmail] = useState('')
  const [profileID, setProfileID] = useState(adminProfileID ?? '')
  const [adminComponentIndex, setAdminComponentIndex] = useState(null)
  const [validUser, setValidUser] = useState(true)
  const currentUser = useSelector((state) => state.user.user)
  const user = useSelector((state) => state.admin.user)
  const vehicles = useSelector((state) => state.admin.vehicles)
  const thermostats = useSelector((state) => state.admin.thermostats)
  const chargers = useSelector((state) => state.admin.chargers)

  useEffect(() => {
    setValidUser(!!user?.profile?.id)
    if (user?.profile?.id) {
      setEmail(user?.username)
      setProfileID(user?.profile?.id)
    } else {
      setEmail('')
      setProfileID('')
    }
  }, [user])

  useEffect(() => {
    if (adminProfileID) {
      getUserDetails()
    }
  }, [adminProfileID])

  function changeProfile(e) {
    setProfileID(e.target.value)
    setEmail('')
  }

  function changeEmail(e) {
    setEmail(e.target.value)
    setProfileID('')
  }

  function changeAdminComponent(componentIndex) {
    if (componentIndex === adminComponentIndex) {
      setAdminComponentIndex(null)
    } else {
      setAdminComponentIndex(componentIndex)
    }
  }

  function getUserDetails() {
    dispatch(getUserInformation(email, profileID))
    dispatch(getUserVehicleInformation(email, profileID))
    dispatch(getUserChargerInformation(email, profileID))
    dispatch(getUserThermostatInformation(email, profileID))
  }
  function get_model_content_type(content_type) {
    switch (content_type) {
      case 139:
        return 'Tesla'
      case 143:
        return 'Smartcar'
      case 270:
        return 'Ford'
      default:
        return 'Unknown'
    }
  }

  const profile = user?.profile
  const AdminComponent = adminComponents?.[adminComponentIndex]?.component

  if (!currentUser) {
    return <Loader />
  }

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Alert severity="info">
            You are currently signed in as:{' '}
            <strong>{currentUser?.username}</strong>
          </Alert>
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField label="Email" value={email} onChange={changeEmail} />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            label="Profile ID"
            value={profileID}
            onChange={changeProfile}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button color="primary" variant="contained" onClick={getUserDetails}>
            Get User Information
          </Button>
        </Grid>
        {!validUser && (
          <Grid item xs={12}>
            <Alert severity="Warning">
              Please enter a valid email or profile ID
            </Alert>
          </Grid>
        )}
      </Grid>
      <small>
        Dev usage - user_id:
        {user?.profile?.user} profile_id:
        {user?.profile?.id}
      </small>
      {/* Only show the rest of the UI if we have a valid user */}
      {user && profileID && (
        <div>
          <h6>User Information</h6>
          <Grid container mt={4}>
            <Grid item>
              <TableContainer component={Paper}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>First Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Home Address</TableCell>
                      <TableCell>Utility Name</TableCell>
                      <TableCell>Plan Name</TableCell>
                      <TableCell>Plan Type</TableCell>
                      <TableCell>Bill Url</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{user?.first_name}</TableCell>
                      <TableCell>{user?.username}</TableCell>
                      <TableCell>{profile?.home?.formatted_address}</TableCell>
                      <TableCell>{profile?.utility?.name}</TableCell>
                      <TableCell>{profile?.plan?.name}</TableCell>
                      <TableCell>{profile?.plan?.types}</TableCell>
                      <TableCell
                        component="a"
                        href={profile?.utility_bill_upload?.s3_url}
                        target="_blank"
                      >
                        {profile?.utility_bill_upload ? 'Bill' : null}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              {/* Vehicles info */}
            </Grid>
            {!!vehicles && !!vehicles.length && (
              <Grid container mt={4}>
                <Grid item>
                  <h6>Vehicles Info</h6>
                  <TableContainer component={Paper}>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell>id</TableCell>
                          <TableCell>Inactive</TableCell>
                          <TableCell>Is Selected Vehicle</TableCell>
                          <TableCell>Controllable</TableCell>
                          <TableCell>Model Content Type</TableCell>
                          <TableCell>Car id</TableCell>
                          <TableCell>Car Name</TableCell>
                          <TableCell>Car Permissions</TableCell>
                          <TableCell>Car Model Name</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {vehicles.map((vehicle) => (
                          <TableRow key={vehicle.id}>
                            <TableCell>{vehicle.id}</TableCell>
                            <TableCell>{String(vehicle.inactive)}</TableCell>
                            <TableCell>
                              {String(vehicle.is_selected_vehicle)}
                            </TableCell>
                            <TableCell>
                              {String(vehicle.controllable)}
                            </TableCell>
                            <TableCell>
                              {vehicle.model_content_type} -{' '}
                              {get_model_content_type(
                                vehicle.model_content_type,
                              )}
                            </TableCell>
                            <TableCell>{vehicle.car.id}</TableCell>
                            <TableCell>{vehicle?.car?.display_name}</TableCell>
                            <TableCell>
                              {vehicle?.car?.permissions.join(', ')}
                            </TableCell>
                            <TableCell>
                              {vehicle?.car?.car_model?.friendly_name}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            )}
            {/* Charger info */}
            {!!chargers && !!chargers.length && (
              <Grid container mt={4}>
                <Grid item>
                  <h6>Charger Info</h6>
                  <TableContainer component={Paper}>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell>id</TableCell>
                          <TableCell>name</TableCell>
                          <TableCell>Authentication Inactive</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {chargers.map((charger) => (
                          <TableRow key={charger.id}>
                            <TableCell>{charger.id}</TableCell>
                            <TableCell>{charger.name}</TableCell>
                            <TableCell>
                              {String(charger.authentication_inactive)}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            )}
            {/* Thermostat info */}
            {!!thermostats && !!thermostats.length && (
              <Grid container mt={4}>
                <Grid item>
                  <h6>Thermostat Info</h6>
                  <TableContainer component={Paper}>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell>id</TableCell>
                          <TableCell>portal</TableCell>
                          <TableCell>name</TableCell>
                          <TableCell>Authentication Inactive</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {thermostats.map((thermostat) => (
                          <TableRow key={thermostat.id}>
                            <TableCell>{thermostat.id}</TableCell>
                            <TableCell>{thermostat.portal}</TableCell>
                            <TableCell>{thermostat.name}</TableCell>
                            <TableCell>
                              {String(thermostat.authentication_inactive)}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid container>
            <Grid item>
              {adminComponents.map((elem, index) => (
                <Button
                  key={index}
                  variant="contained"
                  color="primary"
                  onClick={() => changeAdminComponent(index)}
                >
                  {elem.name}
                </Button>
              ))}
            </Grid>
          </Grid>
          <Grid container>
            {adminComponentIndex !== null ? (
              <AdminComponent userEmail={email} profileID={profileID} />
            ) : null}
          </Grid>
        </div>
      )}
    </div>
  )
}
