import React, { Component } from 'react'
import './styles.scss'
import { optiwattConsts } from '@/optiwattConsts'

class PrivacyPolicy extends Component {
  render() {
    return (
      <React.Fragment>
        <section
          className={
            'testimonial-section ptb-100 ' +
            (this.props.bgColor && this.props.bgColor === 'gray'
              ? 'gray-light-bg'
              : '')
          }
        >
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-12">
                <div className="section-heading mb-5">
                  <h2>
                    Optiwatt Privacy Policy <br />
                  </h2>
                  <h3>
                    Notice of Accessing, Collecting, Storing, Using, and
                    Disclosing Energy Usage Information
                  </h3>
                  <p className="paragraph-lead">
                    Thank you for choosing to be part of our community at
                    Compass Global, Inc., doing business as Optiwatt
                    (“Optiwatt”, “we”, “us”, or “our”). We are committed to
                    protecting your personal information and your right to
                    privacy. If you have any questions or concerns about our
                    notice, or our practices with regards to your personal
                    information, please contact us at{' '}
                    <a href="mailto:privacy@GetOptiwatt.com">
                      privacy@GetOptiwatt.com
                    </a>
                    .
                  </p>
                  <p className="paragraph-lead">
                    When you visit our website{' '}
                    <a
                      href="https://www.optiwatt.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      www.optiwatt.com
                    </a>{' '}
                    or mobile application, and use our services, you trust us
                    with your personal information. We take your privacy very
                    seriously. In this Privacy Policy, we seek to explain to you
                    what information we collect, how we use it and what rights
                    you have in relation to it. This is important information,
                    and you should take the time to read through it carefully.
                    If there are any terms in this Privacy Policy that you do
                    not agree with, you should immediately discontinue use of
                    our Websites, our Apps, and our Services.
                  </p>
                  <p className="paragraph-lead">
                    This Privacy Policy (the ‘Policy’) applies to all
                    information collected through our websites (including{' '}
                    <a
                      href="https://www.optiwatt.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      www.optiwatt.com
                    </a>
                    , and all of our websites collectively referred to as our
                    “Websites”), mobile applications ("Apps"), and/or any
                    related services, sales, marketing or events (we refer to
                    all of these collectively in this Privacy Policy as the
                    "Services").
                  </p>

                  <h3>
                    We collect the following categories of personal information:
                  </h3>

                  <table className="privacy-table">
                    <tr>
                      <th>Data you provide directly to us</th>
                      <th>Data we collect automatically</th>
                    </tr>
                    <tr>
                      <td>
                        <ul className="bulleted-list">
                          <li>
                            Contact details
                            <ul>
                              <li>
                                Name, email address, postal address, and phone
                                number
                              </li>
                            </ul>
                          </li>
                          <li>
                            Login credentials for your vehicle software or
                            electric utility provider
                          </li>
                          <li>
                            Rates and information related to your electric
                            utility provider and usage
                          </li>
                          <li>
                            Your feedback
                            <ul>
                              <li>
                                Online surveys and polls, product feedback, any
                                information you provide when contacting us, etc
                              </li>
                            </ul>
                          </li>
                          <li>
                            Information about your vehicle
                            <ul>
                              <li>
                                The make, model, and year of the electric
                                plug-in vehicle(s) you drive
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <ul className="bulleted-list">
                          <li>
                            Website analytics
                            <ul>
                              <li>
                                Including your IP address, pages viewed, the URL
                                from referral websites, links clicked on
                              </li>
                            </ul>
                          </li>
                          <li>
                            Browser and device information
                            <ul>
                              <li>
                                Type of Internet browser and operating system
                                you use, other device heuristics
                              </li>
                            </ul>
                          </li>
                          <li>Information about your use of Our Services</li>
                          <li>
                            During in app events, we collect date, timestamp,
                            mobile advertising identifiers, a proprietary user
                            ID, and charging session information
                          </li>
                          <li>
                            Information about your personal devices
                            <ul>
                              <li>
                                Your device type, operating system, network, and
                                other information
                              </li>
                            </ul>
                          </li>
                          <li>
                            Information directly from your vehicle software (if
                            you provide your login credentials), including
                            precise vehicle location (for certain vehicles), and
                            charging history.
                          </li>
                          <li>
                            Information directly from your utility provider (if
                            you provide your login credentials), including
                            charging rates, times, and history.
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </table>

                  <h3>When We Collect Personal Information</h3>
                  <p className="paragraph-lead">
                    We collect personal information when:
                  </p>

                  <table className="privacy-table">
                    <tr>
                      <th>Data you provide directly to us</th>
                      <th>Data we collect automatically</th>
                    </tr>
                    <tr>
                      <td>
                        <ul className="bulleted-list">
                          <li>
                            You email, call or otherwise communicate with us
                          </li>
                          <li>
                            You fill out surveys or other market research
                            activities
                          </li>
                          <li>You sign up for Our Services</li>
                        </ul>
                      </td>
                      <td>
                        <ul className="bulleted-list">
                          <li>You visit and interact with our Services</li>
                          <li>
                            You view our email communications or push
                            notifications
                          </li>
                          <li>You click-through a marketing promotion</li>
                          <li>
                            You provide us with your vehicle and/or utility
                            login credentials and charge your vehicle
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </table>

                  <h3>How We Use Collected Personal Information</h3>
                  <p className="paragraph-lead">
                    We use the personal information that you give and we collect
                    for the following purposes:
                  </p>
                  <ul className="bulleted-list">
                    <li>
                      To provide our products and services, notably
                      <ul>
                        <li>
                          Enabling electric utility synchronization with your
                          vehicle to optimize charging performance.
                        </li>
                        <li>
                          Monitoring app use, including charging and other
                          activities surrounding charging activity.
                        </li>
                        <li>
                          When you opt in to our utility rebate program, we use
                          your personal information to facilitate your utility
                          company paying you a commission for providing your
                          charging information to them.
                        </li>
                      </ul>
                    </li>
                    <li>
                      For customer support
                      <ul>
                        <li>
                          Respond to your issue reports, questions, or feedback
                        </li>
                      </ul>
                    </li>
                    <li>To improve our Services</li>
                    <li>
                      To troubleshoot any website, software, or charging
                      synchronization issues.
                    </li>
                    <li>
                      For advertising and marketing
                      <ul>
                        <li>
                          We use Personal information for advertising and
                          marketing purposes. This helps keep our Services low
                          cost or free of charge.
                        </li>
                        <li>
                          We may send you email updates about new features,
                          products, and services.
                        </li>
                        <li>
                          We may use collected information for online or mobile
                          display advertising.
                        </li>
                      </ul>
                    </li>
                  </ul>

                  <h3>How We May Share Personal Information</h3>
                  <ul className="bulleted-list">
                    <li>
                      We may share collected personal information with vendors
                      or service providers. Our contracted vendors or service
                      providers are prohibited from using Optiwatt -collected
                      personal information for any other independent marketing
                      purpose. Please see below how you can opt-out of our
                      cookie-specific data uses. If you opt in to our utility
                      rebate program, we may share your information with your
                      electric utility company to facilitate your utility
                      company paying you a commission in exchange for this
                      personal information.
                    </li>
                    <li>To resolve customer disputes or inquiries.</li>
                    <li>
                      For internal business purposes, including to analyze how
                      the Services and our charging optimization software are
                      used; forecast network utilization; design advertising
                      campaigns; measure the performance of advertising
                      campaigns; analyze electric vehicle charging trends;
                      design new services or products; conduct audits or
                      testing; perform internal research; and maintain business
                      records.
                    </li>
                    <li>
                      To protect the legal rights of Optiwatt or our website,
                      platform or service users.
                      <ul>
                        <li>
                          the legal rights of our company, our employees, our
                          agents, and our affiliates.
                        </li>
                        <li>
                          to protect the safety and security of our website
                          visitors.
                        </li>
                        <li>to detect and protect against fraud.</li>
                        <li>to comply with law or legal process.</li>
                      </ul>
                    </li>
                    <li>
                      Optiwatt may also share your personal information as part
                      of a business transition.
                      <ul>
                        <li>
                          Optiwatt may share personal information with
                          prospective purchasers to evaluate the proposed
                          transaction.
                        </li>
                        <li>
                          Optiwatt may also share your personal information with
                          another company that buys assets or stock of Optiwatt.
                          That company may use and disclose personal information
                          for purposes similar to those described in this
                          policy.
                        </li>
                      </ul>
                    </li>
                    <li>With your consent.</li>
                  </ul>

                  <p className="paragraph-lead">
                    In addition, we may share de-identified, aggregate, or
                    anonymous information about our website visitors and
                    Services users with advertising or marketing partners. See
                    below for more information about your choices with our use
                    of cookies and related technologies.
                  </p>

                  <h3>Your Rights And How To Exercise Them</h3>
                  <ul className="bulleted-list">
                    <li>
                      Access the personal information we maintain about you
                    </li>
                    <li>
                      Delete the personal information we maintain about you,
                      unless such information is necessary for compliance with
                      our legal or regulatory obligations.
                    </li>
                    <li>
                      Correct inaccurate personal information we maintain about
                      you.
                    </li>
                    <li>
                      Opt-Out of certain uses of your personal information. You
                      can opt out of:
                      <ul>
                        <li>
                          Email marketing - unsubscribe to our commercial email
                          list by following the instructions provided in the
                          email or calling (888) 264-2208.
                        </li>
                        <li>
                          Tailored advertising using third party cookies on our
                          website or through mobile software or services. Refer
                          to ‘Online Tracking Technologies and How To Opt Out’
                          below for more information.
                        </li>
                      </ul>
                    </li>
                    <li>
                      You can remove your vehicle and all future vehicle data
                      uses by Optiwatt by clicking on the three dots adjacent to
                      the vehicle image in your mobile app ‘Profile’ settings.
                    </li>
                  </ul>

                  <h3>Online Tracking Technologies and How To Opt Out</h3>
                  <p className="paragraph-lead">
                    Like many companies, we use cookies, pixels, web beacons,
                    SDKS, and other tracking technologies to collect information
                    about your browsing activities, your interactions with
                    websites and apps, and to serve tailored ads. There are a
                    number of ways to opt out of having your online activity and
                    device data collected through these Services, which we
                    summarize below:
                  </p>

                  <ul className="bulleted-list">
                    <li>Opting out of cookie use in our Cookie Preferences.</li>
                    <li>
                      {' '}
                      Blocking Cookies In Your Browser. Most browsers let you
                      remove or reject cookies, including cookies used for
                      interest-based advertising. To do this, follow the
                      instructions in your browser settings. Many browsers
                      accept cookies by default until you change your settings.
                      For more information about cookies, including how to see
                      what cookies have been set on your device and how to
                      manage and delete them, visit{' '}
                      <a
                        href="www.allaboutcookies.org."
                        target="_blank"
                        rel="noreferrer"
                      >
                        www.allaboutcookies.org
                      </a>
                      .
                    </li>
                    <li>
                      Blocking Advertising ID Use In Your Mobile Settings. Your
                      mobile device settings may provide functionality to limit
                      use of the advertising ID associated with your mobile
                      device for interest-based advertising purposes.
                    </li>
                    <li>
                      Advertising Industry Opt-Out Tools. You can also use these
                      opt-out options to limit use of your information for
                      interest-based advertising online or in apps by visiting{' '}
                      <a
                        href="http://optout.aboutads.info/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        http://optout.aboutads.info
                      </a>
                      .
                    </li>
                    <li>
                      With Each of Our Vendors Individually. The following
                      advertising partners offer opt-out features that let you
                      opt-out of use of your information for interest-based
                      advertising:
                    </li>
                  </ul>

                  <table className="privacy-table">
                    <tr>
                      <th>Vendor/Partner</th>
                      <th>Type of Data</th>
                      <th>Privacy Policy/Opt-Out</th>
                    </tr>
                    <tr>
                      <td>Facebook</td>
                      <td>Online and Mobile Advertising</td>
                      <td>
                        <a
                          href="https://www.facebook.com/help/568137493302217/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Facebook Advertising Preferences
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td>Google Ads and Analytics</td>
                      <td>Online and Mobile Advertising</td>
                      <td>
                        <a
                          href="https://support.google.com/googlepay/answer/10223752?co=GENIE.Platform%3DAndroid&hl=en#:~:text=How%20we%20protect%20your%20data,of%20Google%20for%20targeting%20ads."
                          target="_blank"
                          rel="noreferrer"
                        >
                          Google's Privacy Policy
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td>TikTok</td>
                      <td>Online and Mobile Advertising</td>
                      <td>
                        <a
                          href="https://www.tiktok.com/legal/page/us/privacy-policy/en"
                          target="_blank"
                          rel="noreferrer"
                        >
                          TikTok Privacy Policy
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td>Reddit</td>
                      <td>Online and Mobile Advertising</td>
                      <td>
                        <a
                          href="https://www.reddit.com/policies/privacy-policy"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Reddit Privacy Policy
                        </a>{' '}
                      </td>
                    </tr>
                  </table>

                  <h3>
                    Utility Program Privacy: Notice of Accessing, Collecting,
                    Storing, Using, and Disclosing Energy Usage Information
                  </h3>
                  <p class="paragraph-lead">
                    Compass Global, Inc. (DBA Optiwatt) is committed to
                    protecting your privacy and ensuring transparency in how we
                    handle your information as it relates to utility programs.
                    This notice explains our practices regarding the accessing,
                    collecting, storing, using, and disclosing of your covered
                    information. This notice applies only to users in the event
                    that Optiwatt partners with their local energy provider.
                  </p>
                  <p>
                    We collect or during certain programs receive the following
                    categories of covered information: Customer Identification
                    Information, Usage Information, Program Participation
                    Information, Device Information, Location Information,
                    Service Information.
                  </p>
                  <p>
                    We use the information to plan, implement, or evaluate
                    demand response and energy management or energy efficiency
                    programs. We do not use or disclose your information for
                    secondary purposes without your prior, express, written
                    authorization, except as required by law.
                  </p>
                  <p>
                    We will notify you of material changes to this notice via an
                    email sent to the email address we have on file for you or
                    through other notification mechanisms.
                  </p>
                  <p>
                    If you have any questions, concerns, or complaints regarding
                    the collection, storage, use, or disclosure of your
                    information, you can contact our User Success Manager and
                    our support team by email at{' '}
                    <a href={`mailto:${optiwattConsts.contact.email}`}>
                      {optiwattConsts.contact.email}
                    </a>
                    , phone at{' '}
                    <a href={`tel:${optiwattConsts.contact.phoneNumber}`}>
                      {optiwattConsts.contact.phoneNumber}
                    </a>
                    , or mail at 1212 Broadway Plaza, Suite 2100, Walnut Creek,
                    CA 94596.
                  </p>
                  <p>
                    You have the right to review or dispute your information by
                    contacting our support team. You also have the right to
                    remove your information by deleting your Optiwatt account.
                  </p>

                  <h3>Mobile Privacy</h3>
                  <p className="paragraph-lead">
                    Our app, ‘Optiwatt: Tesla & EV Charging’, uses software code
                    provided by our business partners (software development kits
                    or SDKs) that let our software interact with the Services
                    those partners provide. Sometimes these interactions will
                    involve that business partner collecting some information
                    from the device on which the software is run.
                  </p>

                  <h4>How to view our mobile privacy disclosures</h4>

                  <h5 className="paragraph-lead">On Apple Nutrition Labels:</h5>
                  <ul className="bulleted-list">
                    <li>
                      Go to the <strong>App Store</strong> on your iPhone or
                      iPad.
                    </li>
                    <li>
                      Choose the app you want to check the privacy labels of.
                    </li>
                    <li>
                      Scroll down on the app’s download page and tap on{' '}
                      <strong>See Details</strong> right next to{' '}
                      <strong>App Privacy.</strong>
                    </li>
                  </ul>

                  <h5 className="paragraph-lead">
                    On Google Play Data safety section:
                  </h5>
                  <ul className="bulleted-list">
                    <li>
                      Open <strong>Google Play</strong>
                    </li>
                    <li>Browse or use the search bar to find an app.</li>
                    <li>Tap an app.</li>
                    <li>
                      Under <strong>Data Safety</strong>, you'll find a summary
                      of the app's data safety practices.
                    </li>
                    <li>
                      For more detail, tap <strong>See details</strong>.
                    </li>
                  </ul>

                  <h4>How to opt out of tracking on mobile</h4>

                  <h5 className="paragraph-lead">On iOS:</h5>
                  <ul className="bulleted-list">
                    <li>
                      When you download and open a new app, you'll get a
                      notification that asks if you want to let the app track
                      your activity across other companies' apps and websites.
                      You'll also see information about what the app would
                      track. You can tap <strong>Ask App not to Track</strong>{' '}
                      to block that activity or Allow.
                    </li>
                    <li>
                      For apps that you've already downloaded and may have
                      tracking permissions set up for, you can opt out of
                      tracking under <strong>Settings</strong>, tap an app, and
                      then tap to turn off <strong>Allow Tracking</strong>.
                      Alternatively, you may go to{' '}
                      <strong>Settings &gt; Privacy &gt; Tracking</strong>.
                      You'll see in the list of apps that have requested
                      permission to track your activity. You can tap to turn on
                      or off tracking for each app.
                    </li>
                  </ul>

                  <h5 className="paragraph-lead">On Android:</h5>
                  <ul className="bulleted-list">
                    <li>
                      Go to the <strong>Settings</strong> app and navigate to{' '}
                      <strong>Privacy &gt; Ads</strong>. Tap{' '}
                      <strong>Delete advertising ID</strong>, then tap it again
                      on the next page to confirm. This will prevent any app on
                      your phone from accessing it in the future.
                    </li>
                    ß
                  </ul>

                  <h3>U.S. State-Specific Notice</h3>
                  <p className="paragraph-lead">
                    Some U.S. states have enacted comprehensive privacy laws.
                    The California Consumer Privacy Act, as amended by the
                    California Privacy Rights Act or “CPRA” (collectively, the
                    “CCPA”) and the Virginia Consumer Data Protection Act
                    (“VCDPA”) create additional privacy obligations for
                    businesses and provide Californians and Virginians with
                    additional privacy rights.
                  </p>

                  <h4 className="paragraph-lead">Additional Privacy Rights:</h4>

                  <p className="paragraph-lead">
                    In addition to the rights granted above, if you are a
                    California or Virginia resident, you have the right to:
                  </p>

                  <ul className="bulleted-list">
                    <li>
                      <strong>
                        Opt out of the “sale” or “share” of your personal
                        information.
                      </strong>
                      <ul>
                        <li>
                          Given the broad definitions of ‘sale’ and ‘share’
                          under CCPA, some third party cookies placed on our
                          website may be considered a sale or share. You may
                          opt-out of such cookies as detailed in ‘Online
                          Tracking and How To Opt Out.’ You can also exercise
                          this right by submitting an opt out on our ‘Your
                          Privacy Choices’ webform.
                        </li>
                        <li>
                          If you have opted into our utility rebate program, we
                          may disclose your personal information to your
                          participating utility company. You can revoke consent
                          for this disclosure by deleting your account or
                          contacting us at{' '}
                          <a href="mailto:privacy@GetOptiwatt.com">
                            privacy@GetOptiwatt.com
                          </a>{' '}
                          .
                        </li>
                      </ul>
                    </li>
                    <li>
                      <strong>Opt out of targeted advertising.</strong> Some
                      third party cookies on our Website are used for targeted
                      advertising purposes. You may opt out of such cookies as
                      detailed in ‘Online Tracking and How To Opt Out’ section
                      (above).
                    </li>
                    <li>
                      <strong>Use of location-based information</strong>
                      <ul>
                        <li>
                          Californians have the right to limit the use and
                          disclosure of their sensitive personal information and
                          Virginians have the right to opt in to the use of
                          their sensitive personal information. Optiwatt may
                          receive sensitive information from your vehicle
                          software (e.g., Tesla) such as geolocation data. If
                          you are providing precise geolocation information,
                          then you have provided consent at the point of
                          collection and can opt out at any time by disabling
                          location information sharing through your mobile
                          operating system settings.
                        </li>
                      </ul>
                    </li>
                  </ul>

                  <p className="paragraph-lead">
                    You can exercise these rights by contacting us at{' '}
                    <a href="mailto:privacy@GetOptiwatt.com">
                      privacy@GetOptiwatt.com
                    </a>
                    .
                  </p>

                  <h3>Canadian, UK, & European Specific Notice</h3>
                  <h4>Legal Basis</h4>
                  <p className="paragraph-lead">
                    Under the GDPR, we process Personal Data under the following
                    legal basis
                  </p>
                  <table className="privacy-table">
                    <tr>
                      <th>Processing Activity</th>
                      <th>Legal Basis under GDPR</th>
                    </tr>
                    <tr>
                      <td>
                        Collection and Processing of Customer or Customer
                        Personal Data to maintain and fulfill the Services
                      </td>
                      <td>Contract Fulfillment</td>
                    </tr>
                    <tr>
                      <td>Improving our Services</td>
                      <td>Legitimate Interest</td>
                    </tr>
                    <tr>
                      <td>
                        Product or Service-Related Communications to Customers
                      </td>
                      <td>Legitimate Interest</td>
                    </tr>
                    <tr>
                      <td>Cookie-Based Advertising</td>
                      <td>Consent</td>
                    </tr>
                    <tr>
                      <td>Email Marketing</td>
                      <td>Legitimate Interest</td>
                    </tr>
                  </table>

                  <h4>Controller/Processor Designation</h4>
                  <p className="paragraph-lead">
                    Under the GDPR, we are designated as a Controller.
                  </p>

                  <h4>Cross-Border Data Transfers</h4>
                  <p className="paragraph-lead">
                    We may transfer to, and store the data we collect about you,
                    in countries other than the country in which the data was
                    originally collected, including the United States, Canada or
                    other destinations outside the European Economic Area (“
                    <strong>EEA</strong>”) and the United Kingdom (“
                    <strong>UK</strong>”). Those countries may not have
                    equivalent data protection laws as the country in which you
                    provided the data. When we transfer your data to other
                    countries, we will protect the data as described in this
                    Privacy Policy and applicable supplemental product privacy
                    policy and comply with applicable legal requirements
                    providing adequate protection for the transfer of data to
                    countries outside Canada, the EEA, and the UK.
                  </p>

                  <p className="paragraph-lead">
                    If you are located in the EEA or UK, we will only transfer
                    your Personal Data if:
                  </p>

                  <ul className="bulleted-list">
                    <li>
                      The country to which the Personal Data will be transferred
                      has been granted an adequacy decision; or
                    </li>
                    <li>
                      We have put in place appropriate safeguards in respect of
                      the transfer, for example we have entered into standard
                      contractual clauses and required additional safeguards
                      with the recipient, or the recipient is a party to binding
                      corporate rules approved by an EU supervisory authority
                    </li>
                  </ul>

                  <p className="paragraph-lead">
                    For more information on cross-border transfers of Your
                    Personal Data or the appropriate safeguards in place, please
                    contact us at{' '}
                    <a href="mailto:privacy@GetOptiwatt.com">
                      privacy@GetOptiwatt.com
                    </a>{' '}
                    .
                  </p>

                  <h4>
                    Additional Rights for UK or European Economic Area
                    Residents:
                  </h4>
                  <p className="paragraph-lead">
                    In addition to the rights granted above, if you are a UK or
                    EEA resident, the General Data Protection Regulation (GDPR)
                    grants you the right to lodge a complaint against us with
                    your local data protection authority. You can find your data
                    protection authority at{' '}
                    <a href="https://edpb.europa.eu/about-edpb/about-edpb/members_en">
                      https://edpb.europa.eu/about-edpb/about-edpb/members_en
                    </a>
                    .
                  </p>

                  <h3>Security & Retention</h3>
                  <p className="paragraph-lead">
                    We have implemented reasonable security measures designed to
                    protect your personal information from unauthorized access
                    and disclosure. It is important that you understand,
                    however, that no website, Internet-connected device or
                    online platform is completely secure. We cannot anticipate
                    all potential misuse of your information, and as a result,
                    cannot guarantee the security of any information you
                    transmit to us.
                  </p>

                  <p className="paragraph-lead">
                    We generally retain consumer personal information for as
                    long as necessary to provide our Services. If you subscribe
                    to our newsletter, we will retain your information for as
                    long as you are subscribed plus one (1) year. With regards
                    to information collected from inquiries that do not result
                    in a subscription to our communications or associated
                    Services, we will delete this information after one (1)
                    year. In addition, we retain cookie and other similar
                    tracking data for one (1) year. We may also retain personal
                    information if required by law, or for our legitimate
                    interests, such as abuse detection and prevention, and
                    defending ourselves from legal claims. Residual copies of
                    personal data may be stored in backup systems for a limited
                    period as a security measure to protect against data loss.
                    For more information, please email us at{' '}
                    <a href="mailto:privacy@GetOptiwatt.com">
                      privacy@GetOptiwatt.com
                    </a>{' '}
                    .
                  </p>

                  <h3>Children’s Privacy</h3>
                  <p className="paragraph-lead">
                    We do not intentionally collect any personal information
                    from children under the age of 13. If you believe we have
                    obtained personal information associated with children under
                    the age of 13 (or 16 in California), please contact us at{' '}
                    <a href="mailto:privacy@GetOptiwatt.com">
                      privacy@GetOptiwatt.com
                    </a>{' '}
                    or (888) 264-2208 and we will delete it.
                  </p>

                  <h3>Links On Our Website</h3>
                  <p className="paragraph-lead">
                    This website may contain links to other websites or
                    services. We do not control what information you provide, or
                    is collected by these third-party websites. We encourage you
                    to read the privacy policies or statements of the other
                    websites you visit.
                  </p>

                  <h3>Changes To This Policy</h3>
                  <p className="paragraph-lead">
                    If you have any questions about our privacy or security
                    practices, or if you would like to request access to or
                    correction of your personal information, you can contact
                    Optiwatt by mail, telephone, or e-mail:
                  </p>

                  <h3>Contact Us</h3>
                  <p className="paragraph-lead">
                    If you have any questions about our privacy or security
                    practices, or if you would like to request access to or
                    correction of your personal information, you can contact
                    Optiwatt by mail, telephone, or e-mail:
                  </p>

                  <div>
                    <strong>Compass Global, Inc.</strong>
                  </div>
                  <div>
                    <strong>180 Sansome street</strong>
                  </div>
                  <div>
                    <strong>San Francisco, CA, 94104 USA</strong>
                  </div>
                  <div>
                    <strong>
                      <a href="mailto:privacy@GetOptiwatt.com">
                        privacy@GetOptiwatt.com
                      </a>{' '}
                    </strong>
                  </div>
                  <div>
                    <strong>(888) 264-2208</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

export default PrivacyPolicy
