import { useAppDispatch, useMockableViewModel } from '@/hooks'
import { useCallback, useState } from 'react'
import { useFeatureFlag } from '@/hooks/useFeatureFlag'
import useHomeEstimationNavigation, {
  HomeEstimationPages,
} from '@/app/pages/home-estimations/useHomeEstimationNavigation'
import useHomeEstimationCompletion from '@/app/components/HomeEstimations/useHomeEstimationCompletion'
import { homesCollection, useHomeCollection } from '@/reducers/homeResource'
import { Props } from '@/app/components/HomeEstimations/Prompt/HomeEstimation'

const useViewModel = useMockableViewModel({
  useViewModel(props: Props) {
    const [forceShow, setForceShow] = useState(props.forceShow ?? false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [error, setError] = useState(false)
    const home = useHomeCollection()

    const featureFlag = useFeatureFlag({
      flag: 'show_home_estimations',
      experimentalValue: true, // TODO AVild - probably off?
    })

    const dispatch = useAppDispatch()
    const navigate = useHomeEstimationNavigation()
    const isEstimated = useHomeEstimationCompletion()

    const onConfirm = useCallback(async () => {
      if (!home) {
        setError(true)
        return
      }

      setError(false)
      setShowSuccess(true)
      setForceShow(true)

      // Show the success page for a bit
      setTimeout(async () => {
        const response = await dispatch(
          homesCollection.actions.update(home.id, {
            user_defined_sq_footage: home.sq_footage,
            user_defined_heating_btu: home.heating_btu / home.sq_footage,
            user_defined_year_constructed: home.year_constructed,
            user_defined_heating_energy_source: home.heating_energy_source,
          }),
        )

        if (response.error) {
          setError(true)
        }

        setShowSuccess(false)
        setForceShow(props.forceShow ?? false)
      }, 3000)
    }, [home])

    const onIncorrect = useCallback(() => {
      navigate.push(HomeEstimationPages.Edit, undefined, props.from)
    }, [navigate])

    return {
      showSuccess,
      onConfirm,
      onIncorrect,
      isEstimated,
      home,
      error,
      forceShow,
      featureFlag,
    }
  },
  useMockViewModel() {
    return {
      showSuccess: false,
      onConfirm: async () => alert('Correct clicked!'),
      onIncorrect: () => alert('Incorrect clicked!'),
      isEstimated: false,
      home: undefined,
      error: false,
      forceShow: true,
      featureFlag: false,
    }
  },
})

export default useViewModel
