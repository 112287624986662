import { UtilityProgram } from '../types/utilityProgram'
import { UtilitySearchResult } from '../types/utility'
import { createResourceCollection } from '../request'
import { createSelector } from '@reduxjs/toolkit'
import { nestedObjectMap } from '../utils/object'
import { parseTemplate } from '../utils/string'
import { RequestTypes, ResourceMiddlewareFunction } from '@/request/types'
import * as Sentry from '@sentry/browser'
import { UtilityProgramEnrollment } from '@/types/utilityProgramEnrollment'
import { fetchViewConfigFromCMS } from '@/graphql/utilties/fetchViewConfigFromCMS'

// Type guard function to determine if the provided program is of type UtilityProgramEnrollment
function isUtilityProgramEnrollment(
  program: UtilityProgram | UtilityProgramEnrollment,
): program is UtilityProgramEnrollment {
  return 'utility_program' in program
}

// The processProgram function takes a UtilityProgram or a UtilityProgramEnrollment program
// and processes it based on the useCmsViewConfig flag.
function processProgram() {
  return async function (
    program: UtilityProgram | UtilityProgramEnrollment,
  ): Promise<UtilityProgram | UtilityProgramEnrollment> {
    // Get the utility program object based on the type of the input program
    const utilityProgram = isUtilityProgramEnrollment(program)
      ? program.utility_program
      : program

    try {
      const cmsViewConfig = await fetchViewConfigFromCMS({
        utilityProgramId: utilityProgram?.id,
      })

      // If the cmsViewConfig was returned, process the data and update the utility program properties
      if (cmsViewConfig) {
        const updatedUtilityProgram = {
          ...utilityProgram,
          logo_url: cmsViewConfig.logoUrl ?? null,
          tos_url: cmsViewConfig.tosUrl ?? '',
          faq_url: cmsViewConfig.faqUrl ?? '',
          view_config_json: cmsViewConfig,
        }

        // If the input program is a UtilityProgramEnrollment, update its utility_program property,
        // otherwise return the evaluated program directly
        return isUtilityProgramEnrollment(program)
          ? {
              ...program,
              utility_program: updatedUtilityProgram,
            }
          : updatedUtilityProgram
      } else {
        // If the input program is a UtilityProgramEnrollment, update its utility_program property,
        // otherwise return the fallback program directly
        return isUtilityProgramEnrollment(program)
          ? { ...program, utility_program: utilityProgram }
          : utilityProgram
      }
    } catch (e) {
      // If an exception occurs, capture it using Sentry
      Sentry.captureException(e)

      // If the input program is a UtilityProgramEnrollment, update its utility_program property,
      // otherwise return the fallback program directly
      return isUtilityProgramEnrollment(program)
        ? { ...program, utility_program: utilityProgram }
        : utilityProgram
    }
  }
}

export const cmsMiddleware: ResourceMiddlewareFunction<
  UtilityProgram | UtilityProgramEnrollment
> = async (requestData, data) => {
  const type = requestData.requestType
  const middlewareInputData: Array<UtilityProgram | UtilityProgramEnrollment> =
    Array.isArray(data) ? data : [data]

  if (
    middlewareInputData &&
    (type === RequestTypes.Fetch ||
      type === RequestTypes.Create ||
      type === RequestTypes.Update) &&
    Array.isArray(middlewareInputData)
  ) {
    const processedPrograms = await Promise.all(
      middlewareInputData.map(processProgram()),
    )

    // If the input data was an array, return an array, otherwise return the first element
    return Array.isArray(data) ? processedPrograms : processedPrograms[0]
  }

  return data
}

export const utilityProgramCollection =
  createResourceCollection<UtilityProgram>({
    name: 'utilityPrograms',
    apiConfig: {
      path: 'utility_programs',
      invalidateOnRequest: true,
      annotations: {
        device_eligibility: { path: 'eligible_vehicles' },
      },
      middlewares: [
        cmsMiddleware as ResourceMiddlewareFunction<UtilityProgram>,
      ],
    },
    selector: (state) => state.utilityPrograms,
  })

export const searchableUtilityProgramsCollection =
  createResourceCollection<UtilityProgram>({
    name: 'searchableUtilityPrograms',
    apiConfig: {
      path: 'utility_programs',
      public: true,
      middlewares: [
        cmsMiddleware as ResourceMiddlewareFunction<UtilityProgram>,
      ],
    },
    selector: (state) => state.searchableUtilityPrograms,
  })

export const selectSearchableProgramForReferralCode = createSelector(
  [
    (state) => searchableUtilityProgramsCollection.selectors.selectAll(state),
    (_, referralCode: string | null) => referralCode ?? null,
  ],
  (programs, referralCode) => {
    return referralCode
      ? programs.find((program) =>
          program.view_config_json.referral.referralCodes.includes(
            referralCode,
          ),
        )
      : undefined
  },
)

type TemplateVariables = {
  utility_name: string
}

export function evaluatedProgramViewConfigTemplate<
  T extends Record<string, unknown>,
>(viewConfig: T, templateVariables: TemplateVariables): T {
  return nestedObjectMap(viewConfig, (_key, value) => {
    if (typeof value === 'string') {
      return parseTemplate(value, templateVariables)
    }
    return value
  })
}

export function programWithEvaluatedConfigTemplateFromUtility(
  program: UtilityProgram,
  variables: TemplateVariables = { utility_name: '' },
) {
  const templateVariables = { utility_name: variables.utility_name }
  const evaluatedConfig = evaluatedProgramViewConfigTemplate(
    program.view_config_json,
    templateVariables,
  )
  return {
    ...program,
    view_config_json: evaluatedConfig,
  }
}

export const selectUtilityProgramForUserUtility = createSelector(
  [
    utilityProgramCollection.selectors.selectAll,
    (state, utility?: UtilitySearchResult) =>
      utility ?? state.user.user?.profile?.utility,
  ],
  (utilityPrograms, utility) => {
    if (!utility) {
      return undefined
    }
    const utilityProgram = utilityPrograms.find((program) => {
      /** local grid programs (MA program) are utility programs that do not have a view config.
        They are not used the same as other utility programs and need to be filtered out. */
      const isNotLocalGridProgram = !!Object.keys(program.view_config_json)
        .length

      return isNotLocalGridProgram
    })
    if (!utilityProgram) {
      return undefined
    }
    return programWithEvaluatedConfigTemplateFromUtility(utilityProgram, {
      utility_name: utility.name,
    })
  },
)

export const useProgram = () => {}

export const selectFirstUtilityProgram = createSelector(
  [
    utilityProgramCollection.selectors.selectAll,
    (state) => state.user.user?.profile?.utility,
  ],
  (utilityPrograms, utility) => {
    const utilityProgram = utilityPrograms[0]
    if (!utilityProgram) {
      return undefined
    }

    if (utility) {
      return programWithEvaluatedConfigTemplateFromUtility(utilityProgram, {
        utility_name: utility.name,
      })
    }

    return {
      ...utilityProgram,
      view_config_json: evaluatedProgramViewConfigTemplate(
        utilityProgram.view_config_json,
        { utility_name: utilityProgram.name },
      ),
    }
  },
)

export const selectUtilityProgramLogoUrl = createSelector(
  [
    (state, utility?: UtilitySearchResult) =>
      selectUtilityProgramForUserUtility(state, utility),
    (state) => state.user.user?.profile?.utility?.logo_url,
  ],
  (utilityProgram, utilityLogoUrl) =>
    utilityProgram?.logo_url ?? utilityLogoUrl,
)

const programReducer = utilityProgramCollection.slice.reducer
const searchableProgramReducer =
  searchableUtilityProgramsCollection.slice.reducer

export { programReducer, searchableProgramReducer }
