import React, {
  forwardRef,
  RefObject,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
} from 'react'
import { makeStyles } from '@material-ui/styles'
import { ResourceSelectionGalleryCard } from '../../../app/components'
import { Resource } from './types'
import { Flex } from '../../../components'
import clsx from 'clsx'
import useIsMobile from '../../hooks/useIsMobile'
import { useLocation } from 'react-router-dom'
import { throttle } from 'lodash'

const useStyles = makeStyles(() => ({
  edgeToEdgeContainer: {
    width: '100%',
    overflow: 'auto',
    paddingBottom: '20px',
  },
  noScrollbar: {
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  scrollbarOnHover: {
    '&::-webkit-scrollbar': {
      height: '8px',
      opacity: 0,
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(0, 0, 0, 0)',
      'border-radius': '4px',
    },
    '&:hover': {
      '&::-webkit-scrollbar-thumb': {
        background: 'rgba(0, 0, 0, .3)',
        cursor: 'pointer',
      },
    },
  },
}))

/**
 * TODO: Fix this ugly hack. It has to exist because the entire app re-renders on route change.
 * This causes the scroll position to reset when you navigate to a different device page.
 *
 * The fix should be refactoring tabs.jsx so that /app and /devices render with the device gallery
 * and the content is a separate router that renders the content. This way, the device gallery
 * doesn't unmount when you navigate to a different device from the gallery.
 */
const usePersistDeviceGalleryScroll = (
  scrollableRef: RefObject<HTMLDivElement>,
  location: ReturnType<typeof useLocation>,
) => {
  useLayoutEffect(() => {
    const baseURL = location.pathname.split('/').slice(0, 2).join('/')
    const lastBaseURL = window.baseURLStack?.slice(-1)[0]
    if (
      window.resourceSelectionGalleryScrollLeftValue === null ||
      window.resourceSelectionGalleryScrollLeftValue === undefined
    ) {
      window.resourceSelectionGalleryScrollLeftValue =
        scrollableRef.current?.scrollLeft ?? 0
    } else if (!lastBaseURL || baseURL !== lastBaseURL) {
      window.resourceSelectionGalleryScrollLeftValue = 0
    }
    // Restore the scroll position when the component mounts
    const savedScrollLeft = window.resourceSelectionGalleryScrollLeftValue
    if (savedScrollLeft !== null) {
      scrollableRef?.current?.scrollTo({
        left: savedScrollLeft,
        behavior: 'instant',
      })
    }
  })

  useEffect(() => {
    const baseURL = location.pathname.split('/').slice(0, 2).join('/')
    window.baseURLStack = [...(window.baseURLStack ?? []), baseURL]
  }, [location])

  const handleScroll = () => {
    if (scrollableRef.current) {
      /**
       * Because this component is rendered on different device pages, it unmount and remounts every
       * time you interact with it. To get around that, we store a temporary scroll left state
       * in the window object to persist the scroll position when the component is remounted.
       * This allows us to keep the scroll state as you go around the app, but it will reset
       * when you refresh the page.
       */
      window.resourceSelectionGalleryScrollLeftValue =
        scrollableRef.current.scrollLeft
    }
  }

  return handleScroll
}

export type GalleryRefHandle = {
  scrollToStart: () => void
}

type ResourceSelectionGalleryProps = {
  resources: Resource[]
}

const ResourceSelectionGallery = forwardRef<
  GalleryRefHandle,
  ResourceSelectionGalleryProps
>(({ resources }, ref) => {
  const classes = useStyles()
  const scrollableRef: React.Ref<HTMLDivElement> = useRef(null)
  const location = useLocation()
  const isMobile = useIsMobile()

  const handleInvertedScroll = (e: WheelEvent) => {
    e.preventDefault()
    invertScroll(e)
  }

  const invertScroll = throttle((e: WheelEvent) => {
    e.preventDefault()
    if ((e.deltaY === 0 && e.deltaX === 0) || !scrollableRef.current) return
    const multiplier = 50
    const scrollBy =
      multiplier *
      (Math.abs(e.deltaY) >= Math.abs(e.deltaX) ? e.deltaY : e.deltaX)
    scrollableRef.current.scrollLeft += scrollBy
  }, 25)

  useEffect(() => {
    if (!scrollableRef.current || isMobile) return
    scrollableRef.current.addEventListener('wheel', handleInvertedScroll, {
      passive: false,
    })
    return () => {
      scrollableRef.current?.removeEventListener('wheel', handleInvertedScroll)
    }
  }, [scrollableRef.current])

  const handleScroll = usePersistDeviceGalleryScroll(scrollableRef, location)

  useImperativeHandle(ref, () => ({
    scrollToStart() {
      scrollableRef?.current?.scroll({ left: 0, behavior: 'smooth' })
    },
  }))

  const galleryClasses = clsx(
    classes.edgeToEdgeContainer,
    isMobile ? classes.noScrollbar : classes.scrollbarOnHover,
  )
  return (
    <Flex
      container
      direction="row"
      columnSpacing={2.5}
      className={galleryClasses + ' scroll-smooth'}
      wrap="nowrap"
      ref={scrollableRef}
      onScroll={handleScroll}
    >
      {resources.map((resource) => (
        <Flex item key={resource.id}>
          <ResourceSelectionGalleryCard {...resource} />
        </Flex>
      ))}
    </Flex>
  )
})

export default ResourceSelectionGallery
