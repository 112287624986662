import FordLogo from '@/app/img/ford-logo.svg'
import ExternalVehicleRedirect from '@/app/pages/connect-vehicle/external-vehicle-redirect/ExternalVehicleRedirect'

export default function ConnectFordMobileRedirect() {
  return (
    <ExternalVehicleRedirect
      mobileMessageSent="showFordAuth"
      mobileMessageReceived="fordAuth"
      appIconSrc={FordLogo}
      pageTitle="Ford Authorization"
      id="ford-auth"
      appPath="ford"
      logEventBrandName="ford"
    />
  )
}
