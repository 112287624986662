import { Utility, UtilitySearchResult } from '.'

const utilities: (UtilitySearchResult | Utility)[] = [
  {
    id: 88194,
    state: null,
    find_bill_steps: [
      {
        step_number: 1,
        step_description: 'On the left-hand menu, click "My Account"',
      },
      {
        step_number: 2,
        step_description:
          'From the drop-down menu, select "Billing & Payments"',
      },
      {
        step_number: 3,
        step_description: 'Click "View Bill" to download',
      },
    ],
    has_api_provider_link: true,
    plan_options: [
      {
        id: 69631,
        types: ['Tiered'],
        current_season: null,
        next_season: null,
        tiers: null,
        created: '2022-08-19T22:46:33.104206Z',
        modified: '2022-09-06T20:39:40.778664Z',
        name: 'Domestic Service: D - Baseline Region 10',
        start_date: '2022-06-01T00:00:00Z',
        end_date: null,
        sector: 'Residential',
        is_default: false,
        source:
          'https://edisonintl.sharepoint.com/teams/Public/TM2/Shared%20Documents/Public/Regulatory/Tariff-SCE%20Tariff%20Books/Electric/Schedules/Residential%20Rates/ELECTRIC_SCHEDULES_D.pdf?CT=1658421072903&OR=ItemsView',
        is_time_of_use: false,
        is_tiered: true,
        is_real_time: false,
        is_custom_plan: false,
        utility: 88194,
        openei_rate: 12662,
      },
      {
        id: 69632,
        types: ['Tiered'],
        current_season: null,
        next_season: null,
        tiers: null,
        created: '2022-08-19T22:49:57.972660Z',
        modified: '2022-09-06T20:39:40.649213Z',
        name: 'Domestic Service: D - Baseline Region 13',
        start_date: '2022-06-01T00:00:00Z',
        end_date: null,
        sector: 'Residential',
        is_default: false,
        source:
          'https://edisonintl.sharepoint.com/teams/Public/TM2/Shared%20Documents/Public/Regulatory/Tariff-SCE%20Tariff%20Books/Electric/Schedules/Residential%20Rates/ELECTRIC_SCHEDULES_D.pdf?CT=1658421072903&OR=ItemsView',
        is_time_of_use: false,
        is_tiered: true,
        is_real_time: false,
        is_custom_plan: false,
        utility: 88194,
        openei_rate: 12663,
      },
      {
        id: 69633,
        types: ['Tiered'],
        current_season: null,
        next_season: null,
        tiers: null,
        created: '2022-08-19T22:53:22.698816Z',
        modified: '2022-09-06T20:39:40.621463Z',
        name: 'Domestic Service: D - Baseline Region 14',
        start_date: '2022-06-01T00:00:00Z',
        end_date: null,
        sector: 'Residential',
        is_default: false,
        source:
          'https://edisonintl.sharepoint.com/teams/Public/TM2/Shared%20Documents/Public/Regulatory/Tariff-SCE%20Tariff%20Books/Electric/Schedules/Residential%20Rates/ELECTRIC_SCHEDULES_D.pdf?CT=1658421072903&OR=ItemsView',
        is_time_of_use: false,
        is_tiered: true,
        is_real_time: false,
        is_custom_plan: false,
        utility: 88194,
        openei_rate: 12664,
      },
      {
        id: 69634,
        types: ['Tiered'],
        current_season: null,
        next_season: null,
        tiers: null,
        created: '2022-08-19T22:57:00.254235Z',
        modified: '2022-09-06T20:39:40.399568Z',
        name: 'Domestic Service: D - Baseline Region 15',
        start_date: '2022-06-01T00:00:00Z',
        end_date: null,
        sector: 'Residential',
        is_default: false,
        source:
          'https://edisonintl.sharepoint.com/teams/Public/TM2/Shared%20Documents/Public/Regulatory/Tariff-SCE%20Tariff%20Books/Electric/Schedules/Residential%20Rates/ELECTRIC_SCHEDULES_D.pdf?CT=1658421072903&OR=ItemsView',
        is_time_of_use: false,
        is_tiered: true,
        is_real_time: false,
        is_custom_plan: false,
        utility: 88194,
        openei_rate: 12665,
      },
      {
        id: 69635,
        types: ['Tiered'],
        current_season: null,
        next_season: null,
        tiers: null,
        created: '2022-08-19T23:00:27.836991Z',
        modified: '2022-09-06T20:39:40.162567Z',
        name: 'Domestic Service: D - Baseline Region 16',
        start_date: '2022-06-01T00:00:00Z',
        end_date: null,
        sector: 'Residential',
        is_default: false,
        source:
          'https://edisonintl.sharepoint.com/teams/Public/TM2/Shared%20Documents/Public/Regulatory/Tariff-SCE%20Tariff%20Books/Electric/Schedules/Residential%20Rates/ELECTRIC_SCHEDULES_D.pdf?CT=1658421072903&OR=ItemsView',
        is_time_of_use: false,
        is_tiered: true,
        is_real_time: false,
        is_custom_plan: false,
        utility: 88194,
        openei_rate: 12666,
      },
      {
        id: 69636,
        types: ['Tiered'],
        current_season: null,
        next_season: null,
        tiers: null,
        created: '2022-08-19T23:03:49.748117Z',
        modified: '2022-09-06T20:39:40.569900Z',
        name: 'Domestic Service: D - Baseline Region 5',
        start_date: '2022-06-01T00:00:00Z',
        end_date: null,
        sector: 'Residential',
        is_default: false,
        source:
          'https://edisonintl.sharepoint.com/teams/Public/TM2/Shared%20Documents/Forms/AllItems.aspx?ga=1&id=%2Fteams%2FPublic%2FTM2%2FShared%20Documents%2FPublic%2FRegulatory%2FTariff%2DSCE%20Tariff%20Books%2FElectric%2FPreliminary%20Statements%2FELECTRIC%5FPRELIM%5FH%2Epdf&parent=%2Fteams%2FPublic%2FTM2%2FShared%20Documents%2FPublic%2FRegulatory%2FTariff%2DSCE%20Tariff%20Books%2FElectric%2FPreliminary%20Statements',
        is_time_of_use: false,
        is_tiered: true,
        is_real_time: false,
        is_custom_plan: false,
        utility: 88194,
        openei_rate: 12667,
      },
      {
        id: 69629,
        types: ['Tiered'],
        current_season: null,
        next_season: null,
        tiers: null,
        created: '2022-08-19T22:39:43.713836Z',
        modified: '2022-09-06T20:39:40.990687Z',
        name: 'Domestic Service: D - Baseline Region 6',
        start_date: '2022-06-01T00:00:00Z',
        end_date: null,
        sector: 'Residential',
        is_default: false,
        source:
          'https://edisonintl.sharepoint.com/teams/Public/TM2/Shared%20Documents/Public/Regulatory/Tariff-SCE%20Tariff%20Books/Electric/Schedules/Residential%20Rates/ELECTRIC_SCHEDULES_D.pdf?CT=1658421072903&OR=ItemsView',
        is_time_of_use: false,
        is_tiered: true,
        is_real_time: false,
        is_custom_plan: false,
        utility: 88194,
        openei_rate: 12660,
      },
      {
        id: 69637,
        types: ['Tiered'],
        current_season: null,
        next_season: null,
        tiers: null,
        created: '2022-08-19T23:07:08.899132Z',
        modified: '2022-09-06T20:39:39.520235Z',
        name: 'Domestic Service: D - Baseline Region 8',
        start_date: '2022-06-01T00:00:00Z',
        end_date: null,
        sector: 'Residential',
        is_default: false,
        source:
          'https://edisonintl.sharepoint.com/teams/Public/TM2/Shared%20Documents/Public/Regulatory/Tariff-SCE%20Tariff%20Books/Electric/Schedules/Residential%20Rates/ELECTRIC_SCHEDULES_D.pdf?CT=1658421072903&OR=ItemsView',
        is_time_of_use: false,
        is_tiered: true,
        is_real_time: false,
        is_custom_plan: false,
        utility: 88194,
        openei_rate: 12668,
      },
      {
        id: 69630,
        types: ['Tiered'],
        current_season: null,
        next_season: null,
        tiers: null,
        created: '2022-08-19T22:43:06.021743Z',
        modified: '2022-09-06T20:39:40.944822Z',
        name: 'Domestic Service: D - Baseline Region 9',
        start_date: '2022-06-01T00:00:00Z',
        end_date: null,
        sector: 'Residential',
        is_default: false,
        source:
          'https://edisonintl.sharepoint.com/teams/Public/TM2/Shared%20Documents/Public/Regulatory/Tariff-SCE%20Tariff%20Books/Electric/Schedules/Residential%20Rates/ELECTRIC_SCHEDULES_D.pdf?CT=1658421072903&OR=ItemsView',
        is_time_of_use: false,
        is_tiered: true,
        is_real_time: false,
        is_custom_plan: false,
        utility: 88194,
        openei_rate: 12661,
      },
      {
        id: 69638,
        types: ['Flat'],
        current_season: null,
        next_season: null,
        tiers: null,
        created: '2022-08-19T23:10:32.028971Z',
        modified: '2022-09-06T20:39:38.898412Z',
        name: 'Domestic Time-of-Use Electric Vehicle Charging: TOU-EV-1',
        start_date: '2022-06-01T00:00:00Z',
        end_date: null,
        sector: 'Residential',
        is_default: false,
        source:
          'https://edisonintl.sharepoint.com/teams/Public/TM2/Shared%20Documents/Public/Regulatory/Tariff-SCE%20Tariff%20Books/Electric/Schedules/Residential%20Rates/ELECTRIC_SCHEDULES_TOU-EV-1.pdf?CT=1658430283147&OR=ItemsView',
        is_time_of_use: false,
        is_tiered: false,
        is_real_time: false,
        is_custom_plan: false,
        utility: 88194,
        openei_rate: 12669,
      },
      {
        id: 69626,
        types: ['Flat'],
        current_season: null,
        next_season: null,
        tiers: null,
        created: '2022-08-19T22:28:50.827128Z',
        modified: '2022-09-06T20:39:41.265488Z',
        name: 'TOU-D-4-9PM',
        start_date: '2022-06-01T00:00:00Z',
        end_date: null,
        sector: 'Residential',
        is_default: false,
        source:
          'https://edisonintl.sharepoint.com/teams/Public/TM2/Shared%20Documents/Forms/AllItems.aspx?ga=1&id=%2Fteams%2FPublic%2FTM2%2FShared%20Documents%2FPublic%2FRegulatory%2FTariff%2DSCE%20Tariff%20Books%2FElectric%2FSchedules%2FResidential%20Rates%2FELECTRIC%5FSCHEDULES%5FTOU%2DD%2Epdf&parent=%2Fteams%2FPublic%2FTM2%2FShared%20Documents%2FPublic%2FRegulatory%2FTariff%2DSCE%20Tariff%20Books%2FElectric%2FSchedules%2FResidential%20Rates',
        is_time_of_use: false,
        is_tiered: false,
        is_real_time: false,
        is_custom_plan: false,
        utility: 88194,
        openei_rate: 12657,
      },
      {
        id: 69628,
        types: ['Flat'],
        current_season: null,
        next_season: null,
        tiers: null,
        created: '2022-08-19T22:36:10.239192Z',
        modified: '2022-09-06T20:39:40.977584Z',
        name: 'TOU-D-5-8PM',
        start_date: '2022-06-01T00:00:00Z',
        end_date: null,
        sector: 'Residential',
        is_default: false,
        source:
          'https://edisonintl.sharepoint.com/teams/Public/TM2/Shared%20Documents/Forms/AllItems.aspx?ga=1&id=%2Fteams%2FPublic%2FTM2%2FShared%20Documents%2FPublic%2FRegulatory%2FTariff%2DSCE%20Tariff%20Books%2FElectric%2FSchedules%2FResidential%20Rates%2FELECTRIC%5FSCHEDULES%5FTOU%2DD%2Epdf&parent=%2Fteams%2FPublic%2FTM2%2FShared%20Documents%2FPublic%2FRegulatory%2FTariff%2DSCE%20Tariff%20Books%2FElectric%2FSchedules%2FResidential%20Rates',
        is_time_of_use: false,
        is_tiered: false,
        is_real_time: false,
        is_custom_plan: false,
        utility: 88194,
        openei_rate: 12659,
      },
      {
        id: 70385,
        types: ['Time-of-use'],
        current_season: null,
        next_season: null,
        tiers: null,
        created: '2022-09-15T00:38:08.151432Z',
        modified: '2022-09-15T00:38:11.066595Z',
        name: 'TOU-D-PRIME',
        start_date: null,
        end_date: null,
        sector: 'Residential',
        is_default: false,
        source: null,
        is_time_of_use: true,
        is_tiered: false,
        is_real_time: false,
        is_custom_plan: false,
        utility: 88194,
        openei_rate: null,
      },
      {
        id: 69624,
        types: ['Flat'],
        current_season: null,
        next_season: null,
        tiers: null,
        created: '2022-08-19T22:21:51.034056Z',
        modified: '2022-09-06T20:39:42.966175Z',
        name: 'TOU-D-PRIME',
        start_date: '2022-06-01T00:00:00Z',
        end_date: null,
        sector: 'Residential',
        is_default: false,
        source:
          'https://edisonintl.sharepoint.com/teams/Public/TM2/Shared%20Documents/Forms/AllItems.aspx?ga=1&id=%2Fteams%2FPublic%2FTM2%2FShared%20Documents%2FPublic%2FRegulatory%2FTariff%2DSCE%20Tariff%20Books%2FElectric%2FSchedules%2FResidential%20Rates%2FELECTRIC%5FSCHEDULES%5FTOU%2DD%2Epdf&parent=%2Fteams%2FPublic%2FTM2%2FShared%20Documents%2FPublic%2FRegulatory%2FTariff%2DSCE%20Tariff%20Books%2FElectric%2FSchedules%2FResidential%20Rates',
        is_time_of_use: false,
        is_tiered: false,
        is_real_time: false,
        is_custom_plan: false,
        utility: 88194,
        openei_rate: 12654,
      },
      {
        id: 51292,
        types: ['Time-of-use', 'Tiered'],
        current_season: {
          description: 'summer',
          days_until_start: 0,
          days_until_end: 9.0,
        },
        next_season: {
          description: 'winter',
          days_until_start: 10.0,
          days_until_end: null,
        },
        tiers: null,
        created: '2020-03-11T14:56:16.438539Z',
        modified: '2020-03-11T14:56:16.438562Z',
        name: 'Time-Of-Use: TOU-D-T',
        start_date: '2018-10-01T00:00:00Z',
        end_date: null,
        sector: 'Residential',
        is_default: false,
        source: null,
        is_time_of_use: true,
        is_tiered: true,
        is_real_time: false,
        is_custom_plan: false,
        utility: 88194,
        openei_rate: 11267,
      },
      {
        id: 41892,
        types: ['Time-of-use', 'Tiered'],
        current_season: {
          description: 'summer',
          days_until_start: 0,
          days_until_end: 9.0,
        },
        next_season: {
          description: 'winter',
          days_until_start: 10.0,
          days_until_end: null,
        },
        tiers: null,
        created: '2020-03-07T10:39:01.091471Z',
        modified: '2020-03-07T10:39:01.091471Z',
        name: 'Time-Of-Use: TOU-D-T (NEM 2.0)',
        start_date: '2017-06-29T23:00:00Z',
        end_date: null,
        sector: 'Residential',
        is_default: false,
        source:
          'https://www.sce.com/wps/portal/home/residential/rates/Time-Of-Use-Residential-Rate-Plans/!ut/p/b1/hc5BCsIwFATQs3iC_CS1icsvxvSX2lhTtGYjWZWCVhfi-Y3SnaizG3gDwwLrWBjjY-jjfbiO8fzqIT-VtEJuM0HO1wqQe7OxTSUNZgkcE4AvQfi3P7DwJlxbLMgD2e08AypVC0p5rtfqA-yUSGBvKrfkArSYwMKCKUqXQNtIINlA7RElQD6BHydvlw4G6mdPmzQ8Bw!!/dl4/d5/L2dBISEvZ0FBIS9nQSEh/',
        is_time_of_use: true,
        is_tiered: true,
        is_real_time: false,
        is_custom_plan: false,
        utility: 88194,
        openei_rate: 9921,
      },
      {
        id: 37798,
        types: ['Time-of-use', 'Tiered'],
        current_season: {
          description: 'summer',
          days_until_start: 0,
          days_until_end: 9.0,
        },
        next_season: {
          description: 'winter',
          days_until_start: 10.0,
          days_until_end: null,
        },
        tiers: null,
        created: '2020-03-07T10:39:01.091471Z',
        modified: '2020-03-07T10:39:01.091471Z',
        name: 'Time-Of-Use: TOU-D-TEV',
        start_date: '2016-01-01T00:00:00Z',
        end_date: null,
        sector: 'Residential',
        is_default: false,
        source: 'https://www.sce.com/NR/sc3/tm2/pdf/CE324.pdf',
        is_time_of_use: true,
        is_tiered: true,
        is_real_time: false,
        is_custom_plan: false,
        utility: 88194,
        openei_rate: 8712,
      },
      {
        id: 69639,
        types: ['Flat'],
        current_season: null,
        next_season: null,
        tiers: null,
        created: '2022-08-19T23:13:51.094362Z',
        modified: '2022-09-06T20:39:38.861684Z',
        name: 'Time-of-use Tiered Domestic (NEM 2.0): TOU-D-A',
        start_date: '2022-06-01T00:00:00Z',
        end_date: null,
        sector: 'Residential',
        is_default: false,
        source:
          'https://edisonintl.sharepoint.com/teams/Public/TM2/Shared%20Documents/Public/Regulatory/Tariff-SCE%20Tariff%20Books/Electric/Schedules/Residential%20Rates/ELECTRIC_SCHEDULES_TOU-D.pdf?CT=1658430799714&OR=ItemsView',
        is_time_of_use: false,
        is_tiered: false,
        is_real_time: false,
        is_custom_plan: false,
        utility: 88194,
        openei_rate: 12670,
      },
      {
        id: 68850,
        types: ['Flat'],
        current_season: null,
        next_season: null,
        tiers: null,
        created: '2022-08-10T21:22:00.393849Z',
        modified: '2022-09-06T20:41:54.550616Z',
        name: 'Time-of-use Tiered Domestic (NEM 2.0): TOU-D-B',
        start_date: '2022-06-01T00:00:00Z',
        end_date: null,
        sector: 'Residential',
        is_default: false,
        source:
          'https://edisonintl.sharepoint.com/teams/Public/TM2/Shared%20Documents/Forms/AllItems.aspx?ga=1&id=%2Fteams%2FPublic%2FTM2%2FShared%20Documents%2FPublic%2FRegulatory%2FTariff%2DSCE%20Tariff%20Books%2FElectric%2FSchedules%2FResidential%20Rates%2FELECTRIC%5FSCHEDULES%5FTOU%2DD%2Epdf&parent=%2Fteams%2FPublic%2FTM2%2FShared%20Documents%2FPublic%2FRegulatory%2FTariff%2DSCE%20Tariff%20Books%2FElectric%2FSchedules%2FResidential%20Rates',
        is_time_of_use: false,
        is_tiered: false,
        is_real_time: false,
        is_custom_plan: false,
        utility: 88194,
        openei_rate: 12655,
      },
    ],
    created: '2020-03-07T09:25:53.364192Z',
    modified: '2021-03-31T21:21:42.219939Z',
    name: 'Southern California Edison Co',
    place: 'California',
    website: 'www.sce.com',
    login_url: 'https://www.sce.com/',
    api_provider: 'LEAP',
    api_provider_url: 'https://www.addleap.com/Optiwatt/sce/getting-started',
    api_provider_active: true,
    last_datetime_rates_updated: null,
    is_searchable: true,
    openei_utility: 14399,
    logo_url:
      'https://optiwatt-static-assets.s3.amazonaws.com/utilities/logos/smud.svg',
  },
  {
    id: 87972,
    name: 'Pacific Gas & Electric Co',
    api_provider_url: 'https://www.addleap.com/Optiwatt/pge/getting-started',
    api_provider_active: true,
    api_provider: 'LEAP',
    logo_url:
      'https://optiwatt-static-assets.s3.amazonaws.com/utilities/logos/smud.svg',
  },
  {
    id: 88811,
    name: 'Duke Energy',
    api_provider_url: null,
    api_provider_active: false,
    api_provider: null,
    logo_url: null,
  },
  {
    id: 94144,
    name: 'Groton Utilities',
    api_provider_url: null,
    api_provider_active: false,
    api_provider: null,
    logo_url: null,
  },
  {
    id: 88894,
    name: 'Kitchener-Wilmot Hydro Inc.',
    api_provider_url: null,
    api_provider_active: false,
    api_provider: null,
    logo_url: null,
  },
  {
    id: 93957,
    name: 'Reamped Energy ',
    api_provider_url: null,
    api_provider_active: false,
    api_provider: null,
    logo_url: null,
  },
  {
    id: 93251,
    name: 'Synergy',
    api_provider_url: null,
    api_provider_active: false,
    api_provider: null,
    logo_url: null,
  },
]

export default utilities
