import { useState, useEffect } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import dayjs from 'dayjs'

export default function DateRangeModal(props) {
  const [startDate, setStartDate] = useState(dayjs().format())
  const [endDate, setEndDate] = useState(dayjs().format())
  const [startErrors, setStartErrors] = useState(null)
  const [endErrors, setEndErrors] = useState(null)

  useEffect(() => {
    let startErrors = [],
      endErrors = []

    if (dayjs(endDate).startOf('day').isAfter(dayjs())) {
      endErrors.push('End date cannot be in the future.')
    }
    if (dayjs(startDate).startOf('day').isAfter(endDate)) {
      startErrors.push('Start date cannot be after end date.')
    }
    startErrors.length
      ? setStartErrors(startErrors.join('\n'))
      : setStartErrors(null)
    endErrors.length ? setEndErrors(endErrors.join('\n')) : setEndErrors(null)
  }, [startDate, endDate])

  return (
    <Dialog
      open={props.open}
      onClose={() => props.closeModal(null, null)}
      aria-labelledby="date-range-modal-title"
      aria-describedby="date-range-modal-description"
    >
      <DialogTitle id="date-range-modal-title">
        Custom Date Range Picker
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ marginBottom: '15px' }}>
          Choose dates for a custom date range.
        </DialogContentText>
        <DialogContentText style={{ marginBottom: '15px' }}>
          <KeyboardDatePicker
            margin="normal"
            id="start-date-picker-dialog"
            label="Start Date Range Picker"
            format="MM/dd/yyyy"
            value={startDate}
            onChange={(date) =>
              setStartDate(dayjs(date).startOf('day').format())
            }
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            error={startErrors ? true : false}
            helperText={startErrors ? startErrors : null}
          />
        </DialogContentText>
        <DialogContentText style={{ marginBottom: '15px' }}>
          <KeyboardDatePicker
            margin="normal"
            id="end-date-picker-dialog"
            label="End Date Range Picker"
            format="MM/dd/yyyy"
            value={endDate}
            onChange={(date) => setEndDate(dayjs(date).endOf('day').format())}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            error={endErrors ? true : false}
            helperText={endErrors ? endErrors : null}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.closeModal(null, null)}>Cancel</Button>
        <Button
          disabled={startErrors || endErrors ? true : false}
          onClick={() => props.closeModal(startDate, endDate)}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
