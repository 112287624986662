import useMockableViewModel from '@/hooks/useMockableViewModel'
import useProgramFromParams from '@/app/hooks/useProgramFromParams'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { useNavigation } from '@/app/hooks'
import { useEffect, useMemo, useState } from 'react'
import { ID } from '@/types/model'
import { canCharge } from '@/utils/vehicles/permissions'
import { programWithEvaluatedConfigTemplateFromUtility } from '@/reducers/utilityPrograms'
import {
  UtilityProgramEnrollment,
  Vehicle,
  VehicleCharger,
  VehicleEnrollment,
} from '@/types'
import { utilityProgramEnrollmentCollection } from '@/reducers/utilityProgramEnrollments'
import { BehavioralEnrollment } from '@/types/utilityProgramEnrollment'
import {
  behavioralEnrollmentCollection,
  chargerEnrollmentCollection,
  vehicleEnrollmentCollection,
} from '@/reducers/deviceProgramEnrollments'
import { useProfile } from '@/hooks/useProfileId'
import Sentry from '@/logging/sentry'
import { vehicleChargersCollection } from '@/reducers/vehicleChargers'
import useEnrolledDeviceSelections, {
  NextDevice,
} from '@/app/hooks/useEnrolledDeviceSelections'
import { vehicleDescriptionSelector } from '@/app/features/utility-programs/UtilityProgramEligibleDeviceEnrollmentFlowStep/useViewModel'
import { DeviceDescription } from '@/app/features/utility-programs/UtilityProgramEligibleDeviceEnrollmentFlowStep/UtilityProgramEligibleDeviceEnrollmentFlowStep'
import { enrolledUtilityProgramsCollection } from '@/reducers/enrolledUtilityPrograms'

function useViewModel() {
  const { utility, utilityProgram, eligibility, programId, isLoading } =
    useProgramFromParams()
  const navigate = useNavigation()
  const vehicles = useAppSelector((state) => state?.vehicles?.vehicles)
  const chargers = useAppSelector(
    vehicleChargersCollection.selectors.selectAll,
  ) as VehicleCharger[]
  const [selectedVehicles, setSelectedVehicles] = useState<ID[]>([])
  const [selectedChargers, setSelectedChargers] = useState<ID[]>([])
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [isEnrolling, setIsEnrolling] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [selectedBehavioralEnrollment, setSelectedBehavioralEnrollment] =
    useState(true)
  const { data: enrolledProgram } =
    utilityProgramEnrollmentCollection.useFetch()
  const dispatch = useAppDispatch()
  const profile = useProfile()
  const { createEnrolledDeviceParams } = useEnrolledDeviceSelections()

  const eligibleChargers = useMemo(() => {
    if (!eligibility || !chargers) {
      return []
    }

    return (
      chargers?.filter((charger) => {
        return eligibility?.devices?.chargers?.eligible_ids?.includes(
          charger.id,
        )
      }) ?? []
    )
  }, [chargers, eligibility])

  const eligibleVehicles = useMemo(() => {
    if (!eligibility || !vehicles) {
      return []
    }

    return (
      vehicles?.filter((vehicle) => {
        return eligibility?.devices?.vehicles?.eligible_ids?.includes(
          vehicle.id,
        )
      }) ?? []
    )
  }, [vehicles, eligibility])

  const ineligibleVehicles = useAppSelector(
    vehicleDescriptionSelector(
      utilityProgram?.device_eligibility?.ineligible_vehicle_ids ?? [],
    ),
  )

  const eligibleForBehavioralEnrollment =
    eligibility?.devices?.behavioral?.ineligible_reasons?.every(
      (reason) => reason.is_eligible,
    ) ?? false

  useEffect(
    function initializeSelectedDevices() {
      const eligibleVehicleIds = eligibleVehicles.map((vehicle) => vehicle.id)
      setSelectedVehicles(eligibleVehicleIds)
    },
    [eligibleVehicles],
  )

  useEffect(
    function initializeSelectedChargers() {
      const eligibleChargerIds = eligibleChargers.map((charger) => charger.id)
      setSelectedChargers(eligibleChargerIds)
    },
    [eligibleChargers],
  )

  const controlledVehicles = eligibleVehicles.filter((vehicle) =>
    canCharge(vehicle),
  )
  const passiveVehicles = eligibleVehicles.filter(
    (vehicle) => !canCharge(vehicle),
  )

  // In the future, likely need to update this to be "no devices at all"
  const noEligibleVehiclesOrChargers =
    (!eligibleVehicles || eligibleVehicles.length === 0) &&
    (!eligibleChargers || eligibleChargers.length === 0)
  const address = profile?.home?.formatted_address ?? 'Address Not Available'

  const isSkippingEnrollment = () => {
    if (noEligibleVehiclesOrChargers) {
      return !eligibleForBehavioralEnrollment || !selectedBehavioralEnrollment
    }

    return selectedVehicles.length === 0 && selectedChargers.length === 0
  }

  const viewConfig = utilityProgram
    ? programWithEvaluatedConfigTemplateFromUtility(utilityProgram, {
        utility_name: utility?.name ?? '',
      }).view_config_json.eligibleDeviceEnrollment
    : null

  const tosConfig = utilityProgram
    ? programWithEvaluatedConfigTemplateFromUtility(utilityProgram, {
        utility_name: utility?.name ?? '',
      }).view_config_json.enrollmentConfirmationModal
    : null

  const ineligibleForEnrollment =
    noEligibleVehiclesOrChargers && !eligibleForBehavioralEnrollment

  const viewConfigTitle = viewConfig?.title?.text ?? 'Utility Program'
  const pageTitle = ineligibleForEnrollment
    ? 'No Eligible Devices Found'
    : viewConfigTitle

  const toggleVehicleEnroll = (vehicleId: ID) => {
    setSelectedVehicles((prevState) => {
      if (prevState.includes(vehicleId)) {
        return prevState.filter((id) => id !== vehicleId)
      }

      return [...prevState, vehicleId]
    })
  }

  const toggleChargerEnroll = (chargerId: ID) => {
    setSelectedChargers((prevState) => {
      if (prevState.includes(chargerId)) {
        return prevState.filter((id) => id !== chargerId)
      }

      return [...prevState, chargerId]
    })
  }

  const onEnroll = async () => {
    if (isSkippingEnrollment()) {
      navigate.push('/app')
      return
    }

    if (!tosConfig) {
      await acceptAndEnroll()
      return
    }

    setShowConfirmationModal(true)
  }

  const acceptAndEnroll = async () => {
    setShowConfirmationModal(false)
    setIsEnrolling(true)
    setError(null)

    // If previously enrolled in this program, skip the step of enrolling the
    // profile
    if (
      !enrolledProgram ||
      enrolledProgram[0]?.utility_program?.id?.toString() !== programId
    ) {
      const ensuredEnrollment = await ensureUtilityProgramEnrollment()
      if (!ensuredEnrollment) {
        setIsEnrolling(false)
        return
      }
    }

    if (!noEligibleVehiclesOrChargers) {
      const responses = await enrollSelectedDevices()
      setIsEnrolling(false)

      if (responses.length && responses.some((res) => res.error)) {
        setError('An error occurred. Please try enrolling again.')
        return
      }
    }

    invalidateEnrollmentCollections()

    let nextDevice: NextDevice | null = null
    if (selectedVehicles?.[0]) {
      nextDevice = { type: 'vehicle', id: selectedVehicles[0] }
    } else if (selectedChargers?.[0]) {
      nextDevice = { type: 'vehicle-charger', id: selectedChargers[0] }
    } else if (selectedBehavioralEnrollment && profile) {
      nextDevice = { type: 'home', id: profile.home.id }
    }

    navigate.push(`success${createEnrolledDeviceParams(nextDevice)}`)
  }

  const ensureUtilityProgramEnrollment =
    async (): Promise<UtilityProgramEnrollment | null> => {
      const result = await dispatch(
        utilityProgramEnrollmentCollection.actions.create({
          utility_program_id: utilityProgram.id,
          vehicle_ids: [],
          thermostat_ids: [],
          charger_ids: [],
          enroll_behaviorally: noEligibleVehiclesOrChargers,
        }),
      )

      if (result.error) {
        const errorMessage =
          result.payload.response?.[0] ||
          result.payload.response?.error_message ||
          'Enrollment error'

        Sentry.logMessage(errorMessage)
        setError(
          'There was an error while enrolling. You may not meet the eligibility requirements for this program.',
        )
        return null
      }

      return result.payload
    }

  const enrollSelectedDevices = async () => {
    const enrollmentPromises: Promise<
      VehicleEnrollment | BehavioralEnrollment
    >[] = []

    selectedVehicles.forEach((id) => {
      enrollmentPromises.push(enrollDevice('vehicle', id))
    })
    selectedChargers.forEach((id) => {
      enrollmentPromises.push(enrollDevice('charger', id))
    })

    return Promise.all(enrollmentPromises)
  }

  const enrollDevice = (
    deviceType: 'vehicle' | 'profile' | 'charger',
    deviceId: ID,
  ): Promise<VehicleEnrollment | BehavioralEnrollment> => {
    const collectionMap = {
      charger: chargerEnrollmentCollection,
      vehicle: vehicleEnrollmentCollection,
      profile: behavioralEnrollmentCollection,
    }

    return dispatch(
      collectionMap[deviceType].actions.create({
        utility_program_id: utilityProgram.id,
        [`${deviceType}_id`]: deviceId,
      }),
    )
  }
  const invalidateEnrollmentCollections = () =>
    [
      utilityProgramEnrollmentCollection,
      vehicleEnrollmentCollection,
      behavioralEnrollmentCollection,
      chargerEnrollmentCollection,
      enrolledUtilityProgramsCollection,
    ].forEach((collection) => dispatch(collection.actions.invalidate()))

  const toggleBehavioralEnrollment = () => {
    setSelectedBehavioralEnrollment((prev) => !prev)
  }

  const closeModal = () => {
    setShowConfirmationModal(false)
  }

  const pageSubtitle = () => {
    if (isLoading || ineligibleForEnrollment) {
      return ''
    }
    if (noEligibleVehiclesOrChargers) {
      // TODO DEV-2992 -- We need to update this to pull from a new CMS field
      return 'You are enrolling your address only, which earns $75 upfront plus $25 per year through 2026, no matter how many vehicles charge at the address.'
    }
    return (
      viewConfig?.description?.text ?? 'Select eligible devices for enrollment'
    )
  }

  return {
    error,
    isLoading,
    isEnrolling,
    showConfirmationModal,
    selectedVehicles,
    controlledVehicles,
    passiveVehicles,
    ineligibleVehicles,
    selectedChargers,
    eligibleChargers,
    tosConfig,
    viewConfig,
    toggleVehicleEnroll,
    toggleChargerEnroll,
    closeModal,
    onEnroll,
    acceptAndEnroll,
    behavioralEnrollment: noEligibleVehiclesOrChargers,
    ineligibleForEnrollment,
    address,
    pageTitle: isLoading ? 'Loading program information...' : pageTitle,
    pageSubtitle: pageSubtitle(),
    toggleBehavioralEnrollment,
    selectedBehavioralEnrollment,
    isSkippingEnrollment: isSkippingEnrollment(),
  }
}

function useMockViewModel() {
  return {
    error: null,
    isLoading: false,
    isEnrolling: false,
    showConfirmationModal: false,
    selectedVehicles: [] as ID[],
    controlledVehicles: [] as Vehicle[],
    passiveVehicles: [] as Vehicle[],
    ineligibleVehicles: [] as DeviceDescription[],
    selectedChargers: [] as ID[],
    eligibleChargers: [] as VehicleCharger[],
    tosConfig: null,
    viewConfig: null,
    toggleVehicleEnroll: () => alert('Toggle vehicle selection'),
    toggleChargerEnroll: () => alert('Toggle charger selection'),
    closeModal: () => alert('Modal closed!'),
    onEnroll: async () => alert('Enrolled!'),
    acceptAndEnroll: async () => alert('Accepted!'),
    behavioralEnrollment: false,
    address: '123 Reading Rd',
    ineligibleForEnrollment: false,
    pageTitle: 'Some Program is Available',
    pageSubtitle: 'Enroll some vehicles',
    toggleBehavioralEnrollment: () => alert('Toggle behavioral selection'),
    selectedBehavioralEnrollment: false,
    isSkippingEnrollment: false,
  }
}

export default useMockableViewModel({ useViewModel, useMockViewModel })
