import { logEvent } from '@/logging'

const googleGeocoder: { current?: google.maps.Geocoder } = {
  current: undefined,
}

const geocode = (
  input: google.maps.GeocoderRequest,
  callback: (
    results: google.maps.GeocoderResult[],
    status: google.maps.GeocoderStatus,
  ) => void,
) => {
  if (!window.google) return
  if (!googleGeocoder.current) {
    googleGeocoder.current = new window.google.maps.Geocoder()
  }
  googleGeocoder.current?.geocode(input, (results, status) => {
    if (status !== 'OK') {
      logEvent('GeocoderFailure')
      return
    }
    if (!results?.[0]) {
      logEvent('GoogleMapsSearchRateLimitExceeded')
      return
    }
    callback(results, status)
  })
}

export default { googleGeocoder, geocode }
