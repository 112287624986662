import * as actions from '../actions/unsubscribe'

const initialState = {
  isLoading: true,
  success: true,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.UNSUBSCRIBE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
      }
    case actions.UNSUBSCRIBE_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case actions.UNSUBSCRIBE_FAILURE:
      return {
        ...state,
        isLoading: false,
        success: false,
      }
    default:
      return state
  }
}
