import { selectUtilityProgramEnrollmentForUser } from '@/reducers/utilityProgramEnrollments'
import { RootState } from '@/store'
import { ID } from '@/types/model'
import { createSelector } from '@reduxjs/toolkit'

const selectUserVehicleIsEnrolledProgramEligible = createSelector(
  [
    (state: RootState) =>
      selectUtilityProgramEnrollmentForUser(state)?.utility_program
        ?.device_eligibility?.eligible_vehicle_ids,
    (state: RootState, vehicleId?: ID) => vehicleId,
  ],
  (eligibleVehicleIdsFromEnrollmentProgram, vehicleId) => {
    if (!vehicleId) {
      return false
    }

    return [...(eligibleVehicleIdsFromEnrollmentProgram ?? [])].includes(
      vehicleId,
    )
  },
)

export default selectUserVehicleIsEnrolledProgramEligible
