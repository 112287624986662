import { match } from 'ts-pattern'
export enum ChargerEligibilityRequirements {
  Active = 'ACTIVE',
  Data = 'DATA',
  Allowed = 'ALLOWED',
  NotAllowed = 'NOT_ALLOWED',
}

export function chargerIneligibilityReasonContent(
  key: ChargerEligibilityRequirements,
) {
  return match(key)
    .returnType<string>()
    .with(
      ChargerEligibilityRequirements.Active,
      () => 'Your charger must be active to enroll in this program.',
    )
    .with(
      ChargerEligibilityRequirements.Data,
      () => 'Your charger must be able to send data to enroll in this program.',
    )
    .with(
      ChargerEligibilityRequirements.Allowed,
      () => 'Your charger is not on the allowed list for this program.',
    )
    .with(
      ChargerEligibilityRequirements.NotAllowed,
      () => 'Your charger is specifically excluded from this program.',
    )
    .exhaustive()
}
