import { ThermostatMode } from '../../../types/thermostatMeasurement'
import dayjs from 'dayjs'

export default function getAutoAdjustedHvacMode(
  hvacMode: ThermostatMode,
  timestamp: string = dayjs().format(),
) {
  if (hvacMode === 'auto') {
    const month = dayjs(timestamp).month()
    return month > 3 && month < 9 ? 'cool' : 'heat'
  }
  return hvacMode
}
