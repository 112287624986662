import { NavigationPage } from '@/app/components'
import { AdaptiveCardRowLayout } from '@/app/layouts'
import { GridItem, Modal } from '@/components'
import connectUtilityAnimation from '@/app/img/connect-utility-lottie.json'
import Lottie from 'lottie-react'
import UtilityProgramEnrollmentTogglePrompt from '@/app/features/utility-programs/UtilityProgramEnrollmentTogglePrompt'
import PageLoader from '@/authenticated/components/layout/PageLoader'
import EventSettingsRow from '@/app/features/devices/thermostat/EventSettingsRow'
import { UtilityProgramHVACAttestation } from '@/app/features/utility-programs/UtilityProgramHVACAttestation'
import useViewModel from './useViewModel'

export default function ThermostatUtilityProgramEnrollment() {
  const {
    showHVACAttestation,
    setShowHVACAttestation,
    deviceId,
    thermostatId,
    isHvacAttested,
    setIsHvacAttested,
    brandedThermostatName,
    isEnrolledLocal,
    comfortSettings,
    comfortSettingsError,
    dataLoading,
    handleEnrollToggle,
    pageTitle,
  } = useViewModel()

  if (!deviceId || isNaN(thermostatId) || dataLoading) {
    return <PageLoader />
  }

  return (
    <NavigationPage
      id="thermostat-utility-enrollment-settings-page"
      title={pageTitle}
      subtitle={brandedThermostatName ?? 'Thermostat'}
      navigationOptions={['back']}
    >
      <Modal
        open={showHVACAttestation}
        onClose={() => setShowHVACAttestation(false)}
        id="hvac-attestation-modal"
      >
        <div className="mt-6">
          <UtilityProgramHVACAttestation
            isHvacAttested={isHvacAttested}
            setIsHvacAttested={setIsHvacAttested}
            onHVACAttestationComplete={() => {
              setShowHVACAttestation(false)

              if (isHvacAttested) {
                handleEnrollToggle(true, true)
              }
            }}
          />
        </div>
      </Modal>
      <AdaptiveCardRowLayout.PlainRow>
        <GridItem placeItems="center">
          <Lottie
            className="w-1/2"
            animationData={connectUtilityAnimation}
            loop
          />
        </GridItem>
      </AdaptiveCardRowLayout.PlainRow>
      <AdaptiveCardRowLayout.CardRow>
        <UtilityProgramEnrollmentTogglePrompt
          deviceType="thermostat"
          thermostatId={thermostatId}
          isEnrolledValue={isEnrolledLocal}
          onEnrollToggleValueChange={handleEnrollToggle}
        />
      </AdaptiveCardRowLayout.CardRow>
      <EventSettingsRow
        editingEnabled={isEnrolledLocal}
        comfortSettingsError={Boolean(comfortSettingsError)}
        thermostatId={thermostatId}
        comfortSettings={comfortSettings ?? []}
        onComfortSettingsChange={() => {}}
      />
    </NavigationPage>
  )
}
