import { LockedFeature } from '@/app/components'
import SurveyObserver from '@/app/components/SurveyObserver'
import ChargeForecastDashboardView from '@/authenticated/components/ChargeForecastDashboardView'
import ErrorBoundary, {
  LocationTag,
} from '@/authenticated/components/ErrorBoundary'
import VehicleChargerChargesDashboardView from '@/authenticated/components/VehicleChargerChargesDashboardView'
import { ID } from '@/types/model'
import UtilityProgramLearnMoreDashboardView from 'features/utility-programs/UtilityProgramLearnMoreDashboardView'
import useAvailableUtilityPrograms from '@/app/hooks/useAvailableUtilityPrograms'
import { remoteConfig } from '@/firebase'

export interface Props {
  vehicleChargerId: ID
}

function useViewModel(props: Props) {
  return {
    vehicleChargerId: props.vehicleChargerId,
  }
}

function VehicleChargerSectionRoot(props: ReturnType<typeof useViewModel>) {
  // TODO AVild - isAva nonsense
  const avaId = remoteConfig?.getWhitelabelId('ava')
  const { firstAvailableProgram, isLoading: isLoadingFirstAvailable } =
    useAvailableUtilityPrograms()
  const isAva = firstAvailableProgram && firstAvailableProgram.id === avaId

  return (
    <>
      <VehicleChargerAlerts />
      {isAva && <UtilityProgramLearnMoreDashboardView />}
      <ErrorBoundary
        location={LocationTag.VehicleChargerChargeForecastCard}
        functionalityDescription="Vehicle charger charge forecast card"
        fallbackOnCard
      >
        <LockedFeature
          title="Charge Forecast"
          connectionRequirements={['plan', 'unpendingPlan']}
        >
          <ChargeForecastDashboardView
            vehicleChargerId={props.vehicleChargerId}
          />
        </LockedFeature>
      </ErrorBoundary>
      <ErrorBoundary
        location={LocationTag.VehicleChargerChargesCard}
        functionalityDescription="Vehicle charger charge forecast card"
        fallbackOnCard
      >
        <VehicleChargerChargesDashboardView
          vehicleChargerId={props.vehicleChargerId}
        />
      </ErrorBoundary>
    </>
  )
}

export default function VehicleChargerSection(props: Props) {
  const viewModel = useViewModel(props)
  return <VehicleChargerSectionRoot {...viewModel} />
}

function VehicleChargerAlerts() {
  return (
    <div className="flex flex-col gap-4">
      <SurveyObserver observedSurveys={['ClarkEVSE']} />
    </div>
  )
}
