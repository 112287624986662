import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Grid,
  Input,
  InputAdornment,
  Snackbar,
} from '@material-ui/core'
import { updateUserPlan } from '../../../actions/admin'
import Loader from '../../components/layout/Loader'
import { Alert } from '@material-ui/lab'

function CommandButton(props) {
  const [loading, setLoading] = React.useState(false)

  return (
    <Button onClick={props.sendCommand} variant="outlined">
      {props.command}
    </Button>
  )
}

export default function UpdatePlan({ userEmail = '' }) {
  const dispatch = useDispatch()
  const isLoading = useSelector((state) => state.admin.isLoading)
  const errors = useSelector((state) => state.admin.errors)
  const [email, setEmail] = React.useState(userEmail)
  const [label, setLabel] = React.useState('')
  const [planName, setPlanName] = React.useState('')
  const [utilityName, setUtilityName] = React.useState('')
  const [rate, setRate] = React.useState('')
  const [commandResponse, setCommandResponse] = React.useState('')
  const accessToken = useSelector((state) => state.auth.access)

  useEffect(() => {
    if (!isLoading && Object.keys(errors).length === 0) {
      setCommandResponse('Success! Plan updated.')
    }
  }, [isLoading, errors])

  function handleClose() {
    setCommandResponse('')
  }

  function sendCommand() {
    dispatch(
      updateUserPlan({
        email: email,
        label: label,
        plan_name: planName,
        utility_name: utilityName,
        rate: rate,
      }),
    )
  }

  if (!accessToken) {
    return null
  }

  return (
    <>
      <Grid container spacing={0} justifyContent="center">
        <Grid item xs={12}>
          <label>User Email:</label>
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <label>Plan Label:</label>
          <Input
            value={label}
            onChange={(e) => setLabel(e.target.value)}
          /> OR <label>Utility Name:</label>
          <Input
            value={utilityName}
            onChange={(e) => setUtilityName(e.target.value)}
          />
          <label>Plan Name:</label>
          <Input
            value={planName}
            onChange={(e) => setPlanName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <label>Rate:</label>
          <Input
            value={rate}
            onChange={(e) => setRate(e.target.value)}
            endAdornment={<InputAdornment position="end">/kWh</InputAdornment>}
          />
        </Grid>
        <CommandButton command={'submit'} sendCommand={() => sendCommand()} />
      </Grid>
      {errors?.detail ? (
        <div className="alert alert-danger" role="alert">
          {errors.detail}
        </div>
      ) : (
        ''
      )}
      {isLoading && <Loader />}
      {commandResponse.length > 0 && (
        <Snackbar open={true} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            {commandResponse}
          </Alert>
        </Snackbar>
      )}
    </>
  )
}
