import { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  Box as MuiBox,
  Grid as MuiGrid,
  makeStyles,
  Typography as MuiTypography,
  useTheme,
} from '@material-ui/core'
import { spacing } from '@material-ui/system'
import { Skeleton as MuiSkeleton } from '@material-ui/lab'
import { alpha } from '@mui/material'
import { daysOfWeekToReadable } from '@/utils/date'
import { Text } from '@/components'

const Grid = styled(MuiGrid)(spacing)
const Box = styled(MuiBox)(spacing)
const Typography = styled(MuiTypography)(spacing)
const Skeleton = styled(MuiSkeleton)(spacing)

const useStyles = makeStyles((theme) => ({
  dayBox: {
    borderRadius: '8px',
    cursor: 'pointer',
    width: '30px',
    height: '30px',
    '&:hover': {
      backgroundColor: alpha(theme.palette.blue.main, 0.5),
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    userSelect: 'none',
  },
  dayText: {
    fontSize: '11px',
    cursor: 'pointer',
    textAlign: 'center',
    fontWeight: '700',
  },
  subtext: {
    fontSize: '11px',
    color: theme.palette.body.main,
    fontWeight: '700',
    justifySelf: 'flex-start',
    textAlign: 'left',
  },
  dayBoxContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  selfAlignLeft: {
    width: '100%',
    alignSelf: 'flex-start',
  },
}))

const DayOfWeekPicker = ({ onChange, defaultDays }) => {
  const theme = useTheme()
  const classes = useStyles()
  const DAYS = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ]
  const [days, setDays] = useState(defaultDays || [])
  const [readable, setReadable] = useState()

  const onDayClick = (day) => {
    const newDays = days.includes(day)
      ? days.filter((d) => d !== day)
      : [...days, day]
    setDays(newDays)
    if (onChange) {
      onChange(newDays)
    }
  }

  useEffect(() => {
    setDays(defaultDays || [])
  }, [defaultDays])

  useEffect(() => {
    const readableDays = daysOfWeekToReadable(days)
    setReadable(readableDays)
  }, [days])

  return (
    <Grid
      container
      spacing={4}
      justifyContent="center"
      alignItems="flex-start"
      direction="column"
    >
      <div className="my-2 flex gap-2">
        {DAYS.map((day, idx) => (
          <Grid item key={idx}>
            <Box
              className={classes.dayBox}
              style={{
                backgroundColor: days.includes(day)
                  ? theme.palette.blue.main
                  : '#FAFAFA',
              }}
              onClick={() => onDayClick(day)}
            >
              <Text
                style={{
                  color: days.includes(day)
                    ? theme.palette.blue.background
                    : theme.palette.body.main,
                }}
                variant="body3"
                data-testid={`day-${day}`}
              >
                {day.charAt(0).toUpperCase()}
              </Text>
            </Box>
          </Grid>
        ))}
      </div>
      <Grid item className="max-w-[280px]">
        <Text variant="body3">{readable}</Text>
      </Grid>
    </Grid>
  )
}

export default DayOfWeekPicker
