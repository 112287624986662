import { NavigationPage } from '@/app/components'
import ScheduledChargingSelector from '@/authenticated/components/ChargeSchedule/ScheduledChargingSelector'
import Tabs from '@/components/Tabs'
import { VehicleChargeSchedule } from '@/types'
import { useEffect, useState } from 'react'
import theme from '@/v2/theme'
import { ThemeProvider } from '@material-ui/styles'
import { Collapse, Grid as MuiGrid, styled } from '@material-ui/core'
import ClimateControl from '@/authenticated/components/ChargeSchedule/ClimateControl'
import {
  ChargeScheduleMessaging,
  ScheduleChargingProps,
  useViewModel,
} from '@/app/pages/schedule/vehicle/scheduling/schedule-charging-viewmodel'
import { DashboardDivider } from '@/authenticated/components/DashboardCard'
import { spacing } from '@material-ui/system'
import { Alert, Text } from '@/components'
import { toTitleCase } from '@/utils/string'
import { motion } from 'framer-motion'
import VehiclePage from '@/app/components/VehiclePage/VehiclePage'
import { track } from '@/logging/mixpanel'

const StyledDashboardDivider = styled(DashboardDivider)(spacing)
const Grid = styled(MuiGrid)(spacing)

export default function ScheduleCharging() {
  const viewModelData = useViewModel()
  const tabLabels = ['Ready By', 'Start Charge']
  useEffect(() => {
    track('Viewed Charging Schedule Screen')
  }, [])
  return (
    <ThemeProvider theme={theme}>
      <NavigationPage
        id="vehicle-scheduling"
        title={'Scheduling'}
        subtitle={
          'Customize your ready by and start charge times for each day of the week.'
        }
        navigationOptions={['back']}
      >
        <VehiclePage>
          <div className="optiwatt-card !p-0 rounded-3xl w-full mb-4">
            <Tabs.Tabs
              tabKey="vehicle-scheduling-tabs"
              labels={tabLabels}
              tabBarAttributes={{ className: 'max-w-none' }}
              initialTabIndex={0}
            />
          </div>
          {viewModelData.chargeScheduleMessaging.length > 0 && (
            <ChargeScheduleMessages
              chargeScheduleMessaging={viewModelData.chargeScheduleMessaging}
            />
          )}
          <Tabs.Content tabKey="vehicle-scheduling-tabs">
            <div className="optiwatt-card rounded-2xl w-full p-6">
              <ReadyByTab {...viewModelData} />
            </div>
            <div className="optiwatt-card rounded-2xl w-full p-6">
              <ChargingTab {...viewModelData} />
            </div>
          </Tabs.Content>
        </VehiclePage>
      </NavigationPage>
    </ThemeProvider>
  )
}

function ReadyByTab({
  localChargeSchedule: chargeSchedule,
  onChargeScheduleChange,
  canControlClimate,
}: ScheduleChargingProps) {
  const [scheduledDepartureEnabled, setScheduledDepartureEnabled] = useState(
    chargeSchedule?.schedule_departure_enabled,
  )

  useEffect(() => {
    if (!chargeSchedule) {
      return
    }
    setScheduledDepartureEnabled(chargeSchedule.schedule_departure_enabled)
    if (onChargeScheduleChange) {
      onChargeScheduleChange(chargeSchedule)
    }
  }, [chargeSchedule])

  const onScheduledDepartureChange = (
    updatedSchedule: VehicleChargeSchedule,
  ) => {
    setScheduledDepartureEnabled(updatedSchedule.schedule_departure_enabled)
    if (onChargeScheduleChange) {
      onChargeScheduleChange(updatedSchedule)
    }
  }

  if (!chargeSchedule) {
    return null
  }
  return (
    <>
      <ScheduledChargingSelector
        mode={'departure'}
        chargeSchedule={chargeSchedule}
        onChange={onScheduledDepartureChange}
      />
      <Collapse in={scheduledDepartureEnabled}>
        <StyledDashboardDivider my={7} mx={4} />
        <Grid item xs={12} mt={2}>
          <ClimateControl
            chargeSchedule={chargeSchedule}
            onChange={onChargeScheduleChange}
            canControlClimate={canControlClimate}
          />
        </Grid>
      </Collapse>
    </>
  )
}

function ChargingTab({
  localChargeSchedule: chargeSchedule,
  onChargeScheduleChange,
}: ScheduleChargingProps) {
  if (!chargeSchedule) {
    return null
  }

  useEffect(() => {
    if (!chargeSchedule) {
      return
    }
    if (onChargeScheduleChange) {
      onChargeScheduleChange(chargeSchedule)
    }
  }, [chargeSchedule])
  return (
    <ScheduledChargingSelector
      mode={'charging'}
      chargeSchedule={chargeSchedule}
      onChange={onChargeScheduleChange}
    />
  )
}

function ChargeScheduleMessages({
  chargeScheduleMessaging,
}: {
  chargeScheduleMessaging: ChargeScheduleMessaging
}) {
  const message = chargeScheduleMessaging[0]
  const startTime = message.startTime.format('h:mmA')
  const endTime = message.endTime.format('h:mmA')
  const sameDay =
    message.startChargeScheduleDay.day_of_week ===
    message.endChargeScheduleDay.day_of_week
  const startDay = toTitleCase(
    message.startChargeScheduleDay.day_of_week?.substring(0, 3) ?? '',
  )
  const endDay = toTitleCase(
    message.endChargeScheduleDay.day_of_week?.substring(0, 3) ?? '',
  )
  const formatMinutes = (minutes: number) => {
    const hours = Math.floor(minutes / 60)
    const remainingMinutes = minutes % 60
    if (remainingMinutes === 0) {
      return `${hours}h`
    }
    return `${hours}h ${remainingMinutes}m`
  }
  return (
    <motion.div
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={{
        hidden: { opacity: 0, y: -20 },
        visible: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: -20 },
      }}
      transition={{ duration: 0.5 }}
    >
      <Alert color={'#FEF6D9'} className={'mb-4'}>
        <Alert.Icon name={'AlertTriangle'} color={'#FBD242'} />
        <Alert.Text>
          <Text variant={'body1'}>Not enough time to fully charge</Text>
          <Text variant={'body2'} className={'mt-2'}>
            Set: {formatMinutes(message.minutesDiff)} ({startDay} {startTime} -{' '}
            {!sameDay ? endDay : ''} {endTime})
          </Text>
          <Text variant={'body2'}>
            Need: {formatMinutes(message.maxChargeTimeMinutes)} for 100% charge
          </Text>
          <Text variant={'body2'} className={'mt-4'}>
            Consider adjusting your start or ready by time.
          </Text>
        </Alert.Text>
      </Alert>
    </motion.div>
  )
}
