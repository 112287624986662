import { Identifiable } from '@/types'
import { Button, ButtonVariant, CardAction, CardActionProps } from '../index'
import { TestingProps } from '@/types/testingProps'

export interface Props extends Identifiable<CardActionProps>, TestingProps {
  onClick?: () => void
  disabled?: boolean
  buttonVariantOverride?: ButtonVariant
}

export default function CardActionButton(props: Props) {
  const actionToButtonVariantMap: { [key: string]: ButtonVariant } = {
    primary: 'primary',
    secondary: 'secondary',
    navigation: 'navigation',
  }

  return (
    <CardAction type={props.type}>
      <Button
        data-testing-id={props['data-testing-id']}
        disabled={props.disabled}
        variant={
          props.buttonVariantOverride ?? actionToButtonVariantMap[props.type]
        }
        onClick={props.onClick}
        id={props.id}
      >
        {props.children}
      </Button>
    </CardAction>
  )
}
