import useBasePath from '@/hooks/useBasePath'
import useRemoteConfig from '@/hooks/useRemoteConfig'

export default function useUtilityBasePath() {
  const basePath = useBasePath()
  const { isLoading, isFailed, appRemoteConfig } = useRemoteConfig()
  const whitelabelId = appRemoteConfig?.getWhitelabelId(basePath ?? '') ?? null

  if (isLoading) {
    return {
      isLoading: true,
      basePath: null,
    }
  } else if (isFailed) {
    return {
      isLoading: false,
      basePath: null,
    }
  } else if (basePath && whitelabelId !== null) {
    return {
      isLoading: false,
      basePath,
    }
  }

  return {
    isLoading: false,
    basePath: null,
  }
}

/**
 * @returns the url with a utility base path prefixed to it, if one exists
 */
export function useUrlWithUtilityBasePath(url: string) {
  const { basePath, isLoading } = useUtilityBasePath()
  if (isLoading) {
    return {
      isLoading: true,
      url: null,
    }
  }

  const urlWithOutLeadingSlash = url.startsWith('/') ? url.slice(1) : url

  if (basePath) {
    return {
      isLoading: false,
      url: `${basePath}/${urlWithOutLeadingSlash}`,
    }
  }

  return {
    isLoading: false,
    url: url,
  }
}

/**
 * Like useUrlWithUtilityBasePath, but returns a function to create a url from params.
 * Useful when you want to create a url based on some params but can't use a hook in a loop, for example.
 */
export function useUrlWithUtilityBasePathCreator<T>(
  urlMaker: (urlParams: T) => string,
) {
  const { basePath, isLoading } = useUtilityBasePath()
  if (isLoading) {
    return {
      isLoading: true,
      makeUrl: null,
    }
  }

  const createUrl = (urlParams: T) => {
    const url = urlMaker(urlParams)
    const urlWithOutLeadingSlash = url.startsWith('/') ? url.slice(1) : url

    if (basePath) {
      return `${basePath}/${urlWithOutLeadingSlash}`
    }

    return url
  }

  return {
    isLoading: false,
    createUrl,
  }
}
