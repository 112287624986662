import { useEffect, useState, useRef } from 'react'
import Eases from 'eases'
import './styles.scss'

type EasingFunction =
  | 'backInOut'
  | 'backIn'
  | 'backOut'
  | 'bounceInOut'
  | 'bounceIn'
  | 'bounceOut'
  | 'circInOut'
  | 'circIn'
  | 'circOut'
  | 'cubicInOut'
  | 'cubicIn'
  | 'cubicOut'
  | 'elasticInOut'
  | 'elasticIn'
  | 'elasticOut'
  | 'expoInOut'
  | 'expoIn'
  | 'expoOut'
  | 'linear'
  | 'quadInOut'
  | 'quadIn'
  | 'quadOut'
  | 'quartInOut'
  | 'quartIn'
  | 'quartOut'
  | 'quintInOut'
  | 'quintIn'
  | 'quintOut'
  | 'sineInOut'
  | 'sineIn'
  | 'sineOut'

interface Props {
  value: number
  speed?: number
  decimals?: number
  customFunctionRender?: (value: number, decimals: number) => any
  ease?: EasingFunction
}

function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef(callback)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

function defaultRender(value: number, decimals: number) {
  return Number(value).toFixed(decimals)
}

export default function NumberEasing(props: Props) {
  const [renderValue, renderValueSet] = useState(props.value)
  const [lastTarget, lastTargetSet] = useState(props.value)
  const [lastUpdateTime, lastUpdateTimeSet] = useState(new Date().getTime())

  useEffect(() => {
    lastUpdateTimeSet(new Date().getTime() - 16)
    lastTargetSet(renderValue)
  }, [props.value])

  useInterval(() => {
    const currentTime = new Date().getTime()
    const absoluteProgress =
      (currentTime - lastUpdateTime) / (props?.speed || 500)

    if (absoluteProgress >= 1) {
      renderValueSet(props.value)
    } else {
      const easedProgress = Eases[props.ease || 'quintInOut'](absoluteProgress)
      renderValueSet(lastTarget + (props.value - lastTarget) * easedProgress)
    }
  }, 16)

  const functionRender = props.customFunctionRender || defaultRender

  return (
    <span className="eased-value">
      {functionRender(renderValue, props.decimals || 0)}
    </span>
  )
}
