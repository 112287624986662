import { Vehicle } from '.'

export const vehicles: Vehicle[] = [
  {
    id: 1656804,
    authentication_inactive: false,
    requires_tesla_fleet_migration: false,
    controllable: true,
    car: {
      id: 43,
      car_model: {
        id: 199,
        gas_car_conversion: null,
        created: '2022-05-12T20:39:04.849177Z',
        modified: '2022-05-12T20:39:05.123978Z',
        make: 'chevrolet',
        model: 'Volt',
        year: '2018',
        friendly_name: 'Chevrolet Volt',
        friendly_model: null,
        trim: 'Standard Range',
        is_default_trim: true,
        watts_per_mile: null,
      },
      image_url: '/chevrolet/BoltEV/Cherry_Red.webp',
      created: '2022-05-12T20:38:50.273381Z',
      modified: '2022-10-24T18:17:31.831133Z',
      is_active: true,
      average_gas_price_cents: null,
      odometer: 60721.18947052829,
      color: null,
      display_name: '',
      vin: '0SCGMCT02A7D580C0',
      raw_json: [
        '4f30c1b4-d5a9-4fd5-abc2-3fff5373e41d',
        'CHEVROLET',
        'Volt',
        2018,
        ['5eb59198-ad84-4415-ad49-9ca28a1ffde6'],
      ],
      permissions: ['R_BAT', 'R_CHARGE', 'R_GEO', 'R_ODO', 'R_INFO', 'R_VIN'],
      smartcar_id: '4f30c1b4-d5a9-4fd5-abc2-3fff5373e41d',
    },
    environmental_impact: {
      odometer: 60721,
      gasoline_gallons_saved: 2429,
      gas_gallon_conversion: 2429,
      total_metric_impact: 882.4,
      description: 'propane cylinders used for home barbeques',
      image_file: 'propane.webp',
      source:
        'https://www.epa.gov/energy/greenhouse-gases-equivalencies-calculator-calculations-and-references#propane',
    },
    average_gas_price_dollars: null,
    gas_price_zipcode: '43207',
    charge_limit_percent: 95,
    battery_level_percent: 69,
    miles_per_battery_percent: 2.868,
    is_charge_control_enabled: true,
    selected_gas_car_conversion: {
      id: 377,
      make: 'Honda',
      model: 'Civic',
      year: '2012',
      gas_car_gas_type: 'Premium',
      gas_car_mpg: 25,
      source: 'https://www.fueleconomy.gov/feg/noframes/31188.shtml',
      cylinders: '4.0',
      displacement: '2.4',
    },
    created: '2022-05-12T20:38:50.993153Z',
    modified: '2022-10-24T18:17:31.826412Z',
    last_ping: '2022-10-24T18:17:31.826412Z',
    is_selected_vehicle: true,
    last_drive_time: null,
    last_charge_time: null,
    last_sleep_time: null,
    is_not_found: false,
    has_hit_battery_limit_and_draining: false,
    model_object_id: 43,
    model_content_type: 143,
    latest_set_data_job_log: null,
    latest_set_charge_schedule_job_log: null,
  },
  {
    id: 1656805,
    authentication_inactive: false,
    requires_tesla_fleet_migration: false,
    controllable: true,
    car: {
      id: 44,
      car_model: {
        id: 146,
        gas_car_conversion: {
          id: 329,
          make: 'Fiat',
          model: '500 X AWD',
          year: '2018',
          gas_car_gas_type: 'Regular',
          gas_car_mpg: 24,
          source: 'https://www.fueleconomy.gov/feg/noframes/39684.shtml',
          cylinders: '4',
          displacement: '2',
        },
        created: '2021-10-05T22:21:40.395000Z',
        modified: '2021-10-14T15:49:13.721000Z',
        make: 'chevrolet',
        model: 'Bolt EV',
        year: '2018',
        friendly_name: 'Bolt EV',
        friendly_model: null,
        trim: 'Standard Range',
        is_default_trim: true,
        watts_per_mile: 280,
      },
      image_url: '/chevrolet/boltev/Bright_Blue.webp',
      created: '2022-05-12T20:38:53.132700Z',
      modified: '2022-10-24T18:17:31.850640Z',
      is_active: true,
      average_gas_price_cents: null,
      odometer: null,
      color: null,
      display_name: '',
      vin: null,
      raw_json: [
        '61ed6edb-ba1a-4fad-8013-babf03e3bb46',
        'CHEVROLET',
        'Bolt',
        2018,
        ['e8805226-4f53-4af0-aa3c-5f9caddfd7f3'],
      ],
      permissions: ['R_INFO'],
      smartcar_id: '61ed6edb-ba1a-4fad-8013-babf03e3bb46',
    },
    environmental_impact: null,
    average_gas_price_dollars: null,
    gas_price_zipcode: '43207',
    charge_limit_percent: 80,
    battery_level_percent: 80,
    miles_per_battery_percent: null,
    is_charge_control_enabled: false,
    selected_gas_car_conversion: {
      id: 329,
      make: 'Fiat',
      model: '500 X AWD',
      year: '2018',
      gas_car_gas_type: 'Regular',
      gas_car_mpg: 24,
      source: 'https://www.fueleconomy.gov/feg/noframes/39684.shtml',
      cylinders: '4',
      displacement: '2',
    },
    created: '2022-05-12T20:38:53.878167Z',
    modified: '2022-10-24T18:17:30.703021Z',
    last_ping: '2022-10-24T18:17:30.703021Z',
    is_selected_vehicle: false,
    last_drive_time: null,
    last_charge_time: null,
    last_sleep_time: null,
    is_not_found: false,
    has_hit_battery_limit_and_draining: false,
    model_object_id: 44,
    model_content_type: 143,
    latest_set_data_job_log: null,
    latest_set_charge_schedule_job_log: null,
  },
  {
    id: 6588723,
    controllable: true,
    car: {
      id: 423496768,
      car_model: {
        id: 26793,
        gas_car_conversion: {
          id: 104,
          make: 'BMW',
          model: 'X3 xDrive30i',
          year: '2021',
          gas_car_gas_type: 'Premium',
          gas_car_mpg: 25,
          source: 'https://www.fueleconomy.gov/feg/noframes/42899.shtml',
          cylinders: '4',
          displacement: '2',
        },
        created: '2021-11-24T04:18:52.860000Z',
        modified: '2023-01-13T16:16:37.121484Z',
        make: 'tesla',
        model: 'modely',
        year: '2023',
        friendly_name: 'Tesla Model Y',
        friendly_model: null,
        trim: 'Long Range AWD',
        is_default_trim: true,
        watts_per_mile: 270,
      },
      image_url: '/tesla/modely/Red_Multi-Coat.webp',
      created: '2023-04-30T15:01:35.670742Z',
      modified: '2023-12-13T14:52:08.361490Z',
      is_active: true,
      average_gas_price_cents: 448.77777777777777,
      odometer: 7571.773703,
      color: 'RedMulticoat',
      display_name: 'Firebird',
      vin: '7SAYGDEE6PF760250',
      permissions: [
        'R_BAT',
        'R_ODO',
        'R_CHARGE',
        'R_VIN',
        'R_INFO',
        'R_GEO',
        'W_CHARGE',
        'W_LOCK',
      ],
      tesla_id: '3744445264449383',
      tesla_vehicle_id: '2252203495412838',
      tokens: "['37ad1e661df5aa54', '46fa36b3cbc031c7']",
      in_service: false,
      id_s: '3744445264449383',
      calendar_enabled: true,
      api_version: '67',
      backseat_token: null,
      backseat_token_updated_at: null,
      exterior_color: 'RedMulticoat',
      public_key_not_attached_at: null,
    },
    environmental_impact: {
      odometer: 7572.0,
      gasoline_gallons_saved: 303.0,
      gas_gallon_conversion: 303.0,
      total_metric_impact: 114.53,
      description: 'trash bags recycled instead of landfilled',
      image_file: 'trash-bag.png',
      source:
        'https://www.epa.gov/energy/greenhouse-gases-equivalencies-calculator-calculations-and-references#trash',
    },
    average_gas_price_dollars: 4.487777777777778,
    gas_price_zipcode: '44321',
    charge_limit_percent: 80,
    battery_level_percent: 79,
    miles_per_battery_percent: 3.232,
    is_charge_control_enabled: true,
    selected_gas_car_conversion: {
      id: 104,
      make: 'BMW',
      model: 'X3 xDrive30i',
      year: '2021',
      gas_car_gas_type: 'Premium',
      gas_car_mpg: 25,
      source: 'https://www.fueleconomy.gov/feg/noframes/42899.shtml',
      cylinders: '4',
      displacement: '2',
    },
    authentication_inactive: false,
    requires_tesla_fleet_migration: true,
    created: '2023-04-30T15:01:35.715676Z',
    modified: '2023-12-13T14:52:08.717823Z',
    last_ping: '2023-12-13T14:52:08.717823Z',
    is_selected_vehicle: true,
    last_drive_time: '2023-12-13T01:09:23.877000Z',
    last_charge_time: '2023-12-04T17:56:54.682000Z',
    last_sleep_time: '2023-12-13T14:52:08.712815Z',
    has_hit_battery_limit_and_draining: false,
    model_object_id: 423496768,
    model_content_type: 139,
  },
  {
    id: 6588724,
    controllable: true,
    car: {
      id: 423496768,
      car_model: {
        id: 26793,
        gas_car_conversion: {
          id: 104,
          make: 'BMW',
          model: 'X3 xDrive30i',
          year: '2021',
          gas_car_gas_type: 'Premium',
          gas_car_mpg: 25,
          source: 'https://www.fueleconomy.gov/feg/noframes/42899.shtml',
          cylinders: '4',
          displacement: '2',
        },
        created: '2021-11-24T04:18:52.860000Z',
        modified: '2023-01-13T16:16:37.121484Z',
        make: 'tesla',
        model: 'modely',
        year: '2023',
        friendly_name: 'Tesla Model Y',
        friendly_model: null,
        trim: 'Long Range AWD',
        is_default_trim: true,
        watts_per_mile: 270,
      },
      image_url: '/tesla/modely/Red_Multi-Coat.webp',
      created: '2023-04-30T15:01:35.670742Z',
      modified: '2023-12-13T14:52:08.361490Z',
      is_active: true,
      average_gas_price_cents: 448.77777777777777,
      odometer: 7571.773703,
      color: 'RedMulticoat',
      display_name: 'Firebird',
      vin: '7SAYGDEE6PF760250',
      permissions: [
        'R_BAT',
        'R_ODO',
        'R_CHARGE',
        'R_VIN',
        'R_INFO',
        'R_GEO',
        'W_CHARGE',
        'W_LOCK',
      ],
      tesla_id: '3744445264449383',
      tesla_vehicle_id: '2252203495412838',
      tokens: "['37ad1e661df5aa54', '46fa36b3cbc031c7']",
      in_service: false,
      id_s: '3744445264449383',
      calendar_enabled: true,
      api_version: '67',
      backseat_token: null,
      backseat_token_updated_at: null,
      exterior_color: 'RedMulticoat',
      public_key_not_attached_at: null,
    },
    environmental_impact: {
      odometer: 7572.0,
      gasoline_gallons_saved: 303.0,
      gas_gallon_conversion: 303.0,
      total_metric_impact: 114.53,
      description: 'trash bags recycled instead of landfilled',
      image_file: 'trash-bag.png',
      source:
        'https://www.epa.gov/energy/greenhouse-gases-equivalencies-calculator-calculations-and-references#trash',
    },
    average_gas_price_dollars: 4.487777777777778,
    gas_price_zipcode: '44321',
    charge_limit_percent: 80,
    battery_level_percent: 79,
    miles_per_battery_percent: 3.232,
    is_charge_control_enabled: true,
    selected_gas_car_conversion: {
      id: 104,
      make: 'BMW',
      model: 'X3 xDrive30i',
      year: '2021',
      gas_car_gas_type: 'Premium',
      gas_car_mpg: 25,
      source: 'https://www.fueleconomy.gov/feg/noframes/42899.shtml',
      cylinders: '4',
      displacement: '2',
    },
    authentication_inactive: false,
    requires_tesla_fleet_migration: false,
    created: '2023-04-30T15:01:35.715676Z',
    modified: '2023-12-13T14:52:08.717823Z',
    last_ping: '2023-12-13T14:52:08.717823Z',
    is_selected_vehicle: true,
    last_drive_time: '2023-12-13T01:09:23.877000Z',
    last_charge_time: '2023-12-04T17:56:54.682000Z',
    last_sleep_time: '2023-12-13T14:52:08.712815Z',
    has_hit_battery_limit_and_draining: false,
    model_object_id: 423496768,
    model_content_type: 139,
  },
  {
    id: 6588725,
    controllable: true,
    car: {
      id: 423496768,
      car_model: {
        id: 26793,
        gas_car_conversion: {
          id: 104,
          make: 'BMW',
          model: 'X3 xDrive30i',
          year: '2021',
          gas_car_gas_type: 'Premium',
          gas_car_mpg: 25,
          source: 'https://www.fueleconomy.gov/feg/noframes/42899.shtml',
          cylinders: '4',
          displacement: '2',
        },
        created: '2021-11-24T04:18:52.860000Z',
        modified: '2023-01-13T16:16:37.121484Z',
        make: 'tesla',
        model: 'modely',
        year: '2023',
        friendly_name: 'Tesla Model Y',
        friendly_model: null,
        trim: 'Long Range AWD',
        is_default_trim: true,
        watts_per_mile: 270,
      },
      image_url: '/tesla/modely/Red_Multi-Coat.webp',
      created: '2023-04-30T15:01:35.670742Z',
      modified: '2023-12-13T14:52:08.361490Z',
      is_active: true,
      average_gas_price_cents: 448.77777777777777,
      odometer: 7571.773703,
      color: 'RedMulticoat',
      display_name: 'Thunderbird',
      vin: '7SAYGDEE6PF760250',
      permissions: [
        'R_BAT',
        'R_ODO',
        'R_CHARGE',
        'R_VIN',
        'R_INFO',
        'R_GEO',
        'W_CHARGE',
        'W_LOCK',
      ],
      tesla_id: '3744445264449383',
      tesla_vehicle_id: '2252203495412838',
      tokens: "['37ad1e661df5aa54', '46fa36b3cbc031c7']",
      in_service: false,
      id_s: '3744445264449383',
      calendar_enabled: true,
      api_version: '67',
      backseat_token: null,
      backseat_token_updated_at: null,
      exterior_color: 'RedMulticoat',
      public_key_not_attached_at: '2023-04-30T15:01:35.715676Z',
    },
    environmental_impact: {
      odometer: 7572.0,
      gasoline_gallons_saved: 303.0,
      gas_gallon_conversion: 303.0,
      total_metric_impact: 114.53,
      description: 'trash bags recycled instead of landfilled',
      image_file: 'trash-bag.png',
      source:
        'https://www.epa.gov/energy/greenhouse-gases-equivalencies-calculator-calculations-and-references#trash',
    },
    average_gas_price_dollars: 4.487777777777778,
    gas_price_zipcode: '44321',
    charge_limit_percent: 80,
    battery_level_percent: 79,
    miles_per_battery_percent: 3.232,
    is_charge_control_enabled: true,
    selected_gas_car_conversion: {
      id: 104,
      make: 'BMW',
      model: 'X3 xDrive30i',
      year: '2021',
      gas_car_gas_type: 'Premium',
      gas_car_mpg: 25,
      source: 'https://www.fueleconomy.gov/feg/noframes/42899.shtml',
      cylinders: '4',
      displacement: '2',
    },
    authentication_inactive: false,
    requires_tesla_fleet_migration: false,
    created: '2023-04-30T15:01:35.715676Z',
    modified: '2023-12-13T14:52:08.717823Z',
    last_ping: '2023-12-13T14:52:08.717823Z',
    is_selected_vehicle: true,
    last_drive_time: '2023-12-13T01:09:23.877000Z',
    last_charge_time: '2023-12-04T17:56:54.682000Z',
    last_sleep_time: '2023-12-13T14:52:08.712815Z',
    has_hit_battery_limit_and_draining: false,
    model_object_id: 423496768,
    model_content_type: 139,
  },
]

export default vehicles
