import { createResourceCollection } from '@/request'
import { Model } from '@/types/model'

type GasPrice = {
  average_gas_cost_cents_per_gallon: number
  vehicle_id: number
  zipcode: string
}

export const gasPriceCollection = createResourceCollection<GasPrice & Model>({
  name: 'gasPrice',
  apiConfig: {
    path: 'gas_price_cost',
  },
  selector: (state) => state.gasPrice,
})

export const gasPrice = gasPriceCollection.slice.reducer
