import React, { useState } from 'react'
import { Redirect } from 'react-router'
import { authErrors, authLoading, refreshTokenIsValid } from '@/reducers'
import { login } from '@/actions/auth'
import {
  redirectToAppleOAuthURL,
  redirectToGoogleOAuthURL,
} from '@/utils/oauth/oauthUtils'
import { useAppDispatch, useAppSelector } from '@/hooks'
import {
  Button,
  CardAction,
  CardActions,
  FormInputWithError,
  Icon,
  Modal,
} from '@/components'
import { Link } from '@mui/material'
import { AnimatePresence, motion } from 'framer-motion'
import Header from '../../Header/HeaderV2'
import './styles.scss'
import { isNativeMobile } from '@/authenticated/hooks/useIsMobile'
import { overrideMobileUrls } from '@/utils/messageUtils'
import { remoteConfig } from '@/firebase'
import { useWhiteLabelData } from '@/hooks/whitelabel/useWhiteLabelData'
import useRemoteConfig from '@/hooks/useRemoteConfig'
import PageLoader from '@/authenticated/components/layout/PageLoader'
import useBasePath from '@/hooks/useBasePath'
import { useParams } from 'react-router-dom'

type Props = { location: { state: { from: Location } } }

const HeroSection = (props: Props) => {
  const dispatch = useAppDispatch()
  const [user, setUser] = useState({ username: '', password: '' })
  const [triedSubmit, setTriedSubmit] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  const { whiteLabelData, isLoading: loadingTheme } = useWhiteLabelData()
  const { isLoading: remoteConfigLoading } = useRemoteConfig()

  const errors = useAppSelector(authErrors)
  const isAuthenticated = useAppSelector(refreshTokenIsValid)
  const loading = useAppSelector(authLoading)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const loginRoute = props?.location?.state?.from?.pathname ?? '/app'

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setTriedSubmit(true)

    // user wants to login to staging -> open confirmation modal
    if (
      user.username.toLowerCase() === 'staging@optiwatt.com' &&
      user.password === 'optiwatt' &&
      isNativeMobile()
    ) {
      setModalOpen(true)
      return
    }

    if (user.username && user.username.includes('@') && user.password) {
      dispatch(login(user.username.toLowerCase(), user.password))
    }
  }

  const handleRedirectToStaging = () => {
    overrideMobileUrls()
    setModalOpen(false)
  }

  if (isAuthenticated) {
    return <Redirect to={loginRoute} />
  }

  const SignUpLink = (
    <p className="text-[#0A1C53] text-[16px] md:text-[20px] mb-0 font-medium">
      Not registered?{' '}
      <Link style={{ color: '#426FFB', fontWeight: 700 }} href="/signup">
        Create account
      </Link>
    </p>
  )

  const { username, password } = user
  const params = new URLSearchParams(window.location.search)
  const errorParam = params.get('error')
  const validErrors = errorParam ? { detail: errorParam } : errors ?? {}
  const validLoading = typeof loading !== 'undefined' && loading

  const usernameInvalid = triedSubmit && (!username || !username.includes('@'))
  const passwordInvalid = triedSubmit && !password
  let loginInvalid = false
  if (
    validErrors.detail === 'No active account found with the given credentials'
  ) {
    loginInvalid = true
    delete validErrors.detail
  }

  if (loadingTheme || remoteConfigLoading) {
    return <PageLoader />
  }

  return (
    <>
      <div className="login-root flex flex-col items-center bg-[#EEF2FB] min-h-[100vh]">
        <div className="flex flex-col justify-center w-[90%] max-w-5xl min-h-[100vh]">
          {whiteLabelData?.brandLogo && (
            <div className="flex justify-center">
              <img
                className="max-w-[70px] max-h-[70px] mt-4 mb-1"
                src={whiteLabelData.brandLogo}
              />
            </div>
          )}
          <div
            className="flex flex-wrap md:flex-nowrap justify-between pt-8 pb-6 px-5 md:px-12 md:pt-12 md:pb-8 my-3
          bg-white rounded-[20px] shadow-[0_20px_52px_0_rgba(1,50,107,0.16)]"
          >
            <div className="flex flex-col justify-between basis-full md:basis-[45%] mb-8 md:mb-0">
              <div>
                <Header className="mb-4" />
                <h1 className="text-themed-primary font-bold md:font-extrabold leading-10 md:leading-[3.625rem] mb-2 md:mb-0">
                  Login
                </h1>
                <p className="text-[#0a1c53] font-medium text-[16px] md:text-[20px] leading-5 md:leading-7 m-1">
                  Sign into your account to continue
                </p>
              </div>
              <img
                src="img/signup-image.webp"
                alt="Charging Vehicle"
                width={311}
                className="self-center hidden md:block my-auto"
              />
            </div>
            <div className="flex flex-col justify-between basis-full md:basis-[47%] md:ml-2">
              <AnimatePresence>
                {(validErrors?.detail as string) && (
                  <motion.div
                    initial={{ height: 0 }}
                    animate={{ height: 'auto' }}
                    exit={{ height: 0 }}
                    className="overflow-hidden text-themed-error bg-themed-error/20 p-3 pl-4 mb-6 font-semibold rounded-md max-sm:text-sm"
                  >
                    {validErrors.detail as string}
                  </motion.div>
                )}
              </AnimatePresence>
              <form action="post" noValidate onSubmit={handleSubmit}>
                <FormInputWithError
                  type="email"
                  name="username"
                  placeholder="Email"
                  data-testing-id="login-email"
                  onChange={handleChange}
                  value={username}
                  error={usernameInvalid || loginInvalid}
                  errorMessage="Please enter a valid email address."
                  showErrorMessage={!loginInvalid}
                />
                <FormInputWithError
                  type="password"
                  name="password"
                  placeholder="Password"
                  data-testing-id="login-password"
                  onChange={handleChange}
                  value={password}
                  error={passwordInvalid || loginInvalid}
                  errorMessage={
                    loginInvalid
                      ? 'Your email or password is incorrect. Please try again.'
                      : 'Please enter your password.'
                  }
                />
                <div className="pl-4 border-l border-solid border-[#c9d1e7] -mt-2 text-xs md:text-base">
                  <Link
                    style={{ color: '#426FFB', fontWeight: 700 }}
                    href="/reset-password"
                  >
                    Forgot password?
                  </Link>
                </div>
                <div className="flex mt-8">
                  <Button
                    className="!bg-themed-primary text-[18px] font-semibold py-3 px-5 rounded-lg w-[100%] font-[montserrat]"
                    id="optiwatt-login-button"
                    variant="primary"
                    type="submit"
                    disabled={validLoading}
                  >
                    Login
                  </Button>
                </div>
                <div className="flex flex-col gap-4 mt-4">
                  <div className="grid grid-cols-[1fr_50px_1fr]">
                    <div
                      className="border-solid border-b-themed-base-400 h-[13px] -mt-[5px]"
                      style={{ borderBottomWidth: '1px' }}
                    />
                    <div className="flex items-center justify-center font-bold text-xs text-[#424F76]">
                      Or
                    </div>
                    <div
                      className="border-solid border-b-themed-base-400 h-[13px] -mt-[5px]"
                      style={{ borderBottomWidth: '1px' }}
                    />
                  </div>
                  <ContinueWithButton
                    onClick={() => redirectToGoogleOAuthURL()}
                    icon={<Icon name="Google" size={22} />}
                    id="continue-with-google"
                  />
                  <ContinueWithButton
                    onClick={() => redirectToAppleOAuthURL()}
                    icon={<Icon name="Apple" size={22} />}
                    id="continue-with-apple"
                  />
                </div>
              </form>
              <div>
                <div className="hidden mt-6 md:block">{SignUpLink}</div>
              </div>
            </div>
          </div>
          <div className="mb-4 self-start ml-[6%] md:hidden">{SignUpLink}</div>
        </div>
      </div>
      <Modal
        id="staging-override-confirmation-modal"
        open={modalOpen}
        onClose={handleRedirectToStaging}
      >
        <Modal.Title>
          You have overridden the URLs for the app until your next logout.
        </Modal.Title>
        <CardActions>
          <CardAction type="primary">
            <Button
              id="staging-override-confirmation-button"
              onClick={handleRedirectToStaging}
            >
              Ok
            </Button>
          </CardAction>
        </CardActions>
      </Modal>
    </>
  )
}

export default HeroSection

const ContinueWithButton = ({
  onClick,
  icon,
  id,
}: {
  onClick: () => Promise<void>
  icon: React.ReactNode
  id: string
}) => {
  return (
    <div
      id={id}
      onClick={onClick}
      className="flex flex-row items-center justify-center gap-2 p-3 text-lg font-semibold rounded-lg
      cursor-pointer hover:bg-themed-grey-200 text-themed-grey-900 bg-themed-grey-100"
    >
      Continue with
      {icon}
    </div>
  )
}
