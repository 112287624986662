import { useAppSelector } from '../../../../hooks'
import useMockableViewModel from '../../../../hooks/useMockableViewModel'
import { selectCanShowUtilityUsage } from '../../../../selectors'

function useViewModel() {
  const canShowUtilityUsage = useAppSelector(selectCanShowUtilityUsage)

  return { shouldShow: canShowUtilityUsage }
}

function useMockViewModel() {
  return { shouldShow: true }
}

export default useMockableViewModel({ useViewModel, useMockViewModel })
