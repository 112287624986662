import { teslaFleetAuthenticationCollection } from '@/reducers/teslaFleetAuthentication'
import { useNavigateToFleetPublicKey } from '../pages/tesla-authentication/utils/useNavigateToFleetPublicKey'
import { Vehicle } from '@/types'
import { useNavigation } from './useNavigation'
import { logMessage } from '@/logging/sentry'
import { useUrlWithUtilityBasePathCreator } from './useUtilityBasePath'
import { logEvent } from '@/logging'

/**
 * If the user is an owner of the Tesla vehicle, navigate to the Tesla key pairing page.
 * If the user is not an owner, navigate to the Tesla key pairing instructions page for non-owners.
 * @returns a function to navigate to the Tesla key pairing page. takes a vehicle as input
 */
export default function useNavigateToTeslaKeyPairing() {
  const navigation = useNavigation()
  const {
    data: fleetAuthentications,
    status: fleetAuthenticationStatus,
    isLoadingOrIdle: isLoadingFleetAuthentication,
  } = teslaFleetAuthenticationCollection.useFetch()
  const fleetPublicKeyUrl =
    fleetAuthentications[0]?.public_key_url ?? 'https://optiwatt.com/app'
  const navigateToFleetPublicKey = useNavigateToFleetPublicKey({
    fleetPublicKeyUrl,
  })

  const { createUrl: makeNotOwnerUrl, isLoading: isLoadingNotOwnerUrl } =
    useUrlWithUtilityBasePathCreator(
      (urlParams: { vehicleId: number }) =>
        `/tesla-authentication/key-connection/${urlParams.vehicleId}/not-owner`,
    )

  const isLoading = isLoadingFleetAuthentication || isLoadingNotOwnerUrl

  const errorNavigate = (vehicle: Vehicle) => {
    logMessage(
      'useNavigateToTeslaKeyPairing: attempted to navigate in unready state',
    )
  }

  if (isLoading) {
    return {
      isLoading: true,
      error: null,
      navigate: errorNavigate,
    }
  }

  if (fleetAuthenticationStatus === 'failed') {
    return {
      isLoading: false,
      error: 'Failed to fetch fleet authentication',
      navigate: errorNavigate,
    }
  }

  const navigate = (vehicle: Vehicle) => {
    const isOwner = vehicle.car.access_type === 'OWNER'
    if (isOwner) {
      logEvent('tesla_key_pairing_opened_owner')
      navigateToFleetPublicKey(String(vehicle.id))
    } else if (makeNotOwnerUrl) {
      logEvent('tesla_key_pairing_opened_not_owner')
      navigation.push(makeNotOwnerUrl({ vehicleId: vehicle.id }))
    }
  }

  return {
    isLoading: false,
    error: null,
    navigate: navigate,
  }
}
