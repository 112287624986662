import Chart from 'react-google-charts'

function loadData(timeLines) {
  let chartRows = []
  for (let j = 0; j < timeLines.length; j++) {
    let modelTimeline = timeLines[j]
    for (let i = 0; i < modelTimeline.data.length; i++) {
      let row = modelTimeline.data[i]
      let tooltip
      if ('tooltip' in row) {
        tooltip = row['tooltip']
      } else {
        tooltip = null
      }

      let chartRow = [
        row['name'],
        row['description'],
        tooltip,
        new Date(row['timestamp_start']),
        new Date(row['timestamp_end']),
      ]
      chartRows.push(chartRow)
    }
  }

  return chartRows
}

function loadOptions(data) {
  let allDates = []
  for (let i = 0; i < data.length; i++) {
    let row = data[i]
    allDates.push(row[3])
    allDates.push(row[4])
  }
  const minDateStart = new Date(Math.min.apply(null, allDates))
  const maxDateEnd = new Date(Math.max.apply(null, allDates))
  return {
    // timeline: {colorByRowLabel: true, singleColor: "#8d8"},
    hAxis: {
      minValue: minDateStart,
      maxValue: maxDateEnd,
      format: 'M/d/yy HH:MM',
    },
  }
}

// Reference : https://developers.google.com/chart/interactive/docs/gallery/timeline
function Timeline(props) {
  const columns = [
    { type: 'string', id: 'Position' },
    { type: 'string', id: 'Name' },
    { type: 'string', role: 'tooltip' },
    { type: 'date', id: 'Start' },
    { type: 'date', id: 'End' },
  ]

  const { timeLines } = props
  const data = loadData(timeLines)
  const options = loadOptions(data)

  return (
    <Chart
      chartType="Timeline"
      data={[columns, ...data]}
      width="100%"
      height="400px"
      options={options}
    />
  )
}

export default Timeline
