import { getVehicles } from '@/actions/vehicles'
import { useAppDispatch, useUrlSearchParam } from '@/hooks'
import { logEvent } from '@/logging'
import { logMessage } from '@/logging/sentry'
import { fetchAuthenticated } from '@/utils/fetch'
import { useEffect, useState } from 'react'

export default function useRefreshPermissionsIfNeeded(
  vehicleId: number | undefined,
) {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useAppDispatch()
  const shouldRefresh = useUrlSearchParam('refresh_permissions') === 'true'
  const [error, setError] = useState(false)

  const sendRefreshRequest = async () => {
    setError(false)
    setIsLoading(true)
    // Remove the refresh_permissions param without a refresh
    const url = new URL(window.location.href)
    url.searchParams.delete('refresh_permissions')
    window.history.replaceState({}, '', url)
    // Request that permissions are refreshed
    const res = await fetchAuthenticated(
      `/tesla_permissions_refresh/${vehicleId}`,
      {
        method: 'POST',
      },
    )
    // refresh the vehicles so we have the most up to date permissions
    await dispatch(getVehicles())
    setIsLoading(false)

    if (res.status !== 200) {
      const errorBody = await res.text()
      logMessage(
        `CapabilitiesPage: Failed to refresh permissions. Status: ${res.status}, Body: ${errorBody}`,
      )
      logEvent(`capabilities_refreshed_permissions_failed`)
      setError(true)
    } else {
      logEvent(`capabilities_refreshed_permissions`)
    }
  }

  useEffect(() => {
    if (shouldRefresh && vehicleId) {
      sendRefreshRequest()
    }
  }, [shouldRefresh, vehicleId])

  return { isLoading, error }
}
