import './styles.scss'
import formatClasses from '../../utils/classes/formatClasses'
import { ForwardedRef, forwardRef, HTMLAttributes } from 'react'

export interface Props
  extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  dismissible?: boolean
  flat?: boolean
  fullHeight?: boolean
  fullWidth?: boolean
  onClick?: () => void
}

export type Ref = ForwardedRef<HTMLDivElement>

function Card(
  {
    children,
    className,
    dismissible,
    style,
    flat,
    fullHeight,
    fullWidth,
    onClick,
    ...rest
  }: Props,
  ref?: Ref,
) {
  const classes = {
    card: formatClasses([
      'optiwatt-card',
      flat ? 'flat' : undefined,
      fullHeight ? 'full-height' : undefined,
      fullWidth ? 'full-width' : undefined,
      className,
    ]),
    dismissButton: formatClasses(['dismiss-button']),
    content: formatClasses(['optiwatt-card-content']),
  }

  return (
    <div
      className={classes.card}
      style={style}
      onClick={onClick}
      {...rest}
      ref={ref}
    >
      <div className={classes.content}>{children}</div>
    </div>
  )
}

export default forwardRef(Card)
