import React, { useContext, useEffect } from 'react'
import { motion, Variants } from 'framer-motion'
import isMobile from 'is-mobile'
import {
  AdaptiveCardRowLayout,
  AdaptiveCardRowLayoutProps,
} from '@/app/layouts'
import { Button, Card, Icon } from '@/components'
import { BreakpointContext, NavigationContext } from '@/context'
import { merge } from 'lodash'
import { NavigationContextValue } from '@/context/NavigationContext'
import twMerge from '@/utils/classes/twMerge'
import ConfigurableText from '../ConfigurableText'
import XButton from '@/components/XButton/XButton'

export type Props = Omit<AdaptiveCardRowLayoutProps, 'title'> & {
  id: string
  title?: string
  subtitle?: React.ReactNode
  navigationOptions?: ('back' | 'close' | 'nothing')[]
  backOverride?: () => void
  onCloseClick?: () => void
  headerContent?: React.ReactNode
  backDisabled?: boolean
}

const defaultHeaderProps = {
  navigationOptions: ['back'] as Props['navigationOptions'],
}

function NavigationPageHeader({
  id,
  title,
  subtitle,
  navigationOptions,
  backOverride,
  headerContent,
  onCloseClick,
  backDisabled,
}: Props) {
  const navigation = useContext(NavigationContext)

  const onBackClick = () => {
    if (backOverride) {
      backOverride()
      return
    }
    history.length > 2 ? navigation?.goBack() : navigation?.push('/app')
  }

  const variants = navigation && createVariantsForHeader(navigation.direction)

  const buttonClass = '!h-[34px] !p-0 !bg-themed-base-200 !w-[34px] !m-0'
  const titleClass = subtitle ? 'mb-[8px]' : ''
  const subtitleClass = ''
  const textContainerClass = 'flex flex-col'
  const wrapperClass = title || subtitle ? 'mb-7' : 'mb-5'

  return (
    <div
      className={`flex flex-col ${
        title || subtitle ? 'gap-5' : ''
      } ${wrapperClass}`}
    >
      <div className="flex items-center justify-between w-full">
        {navigationOptions?.includes('back') ? (
          <Button
            id={`${id}-navigation-page-back`}
            variant="icon"
            onClick={onBackClick}
            className={buttonClass}
            disabled={backDisabled ?? false}
          >
            <div className="flex items-center justify-center">
              <Icon name="ArrowLeft" size={24} color="grey-900" />
            </div>
          </Button>
        ) : (
          <div className="h-[34px] w-[34px]" />
        )}
        {navigationOptions?.includes('close') && onCloseClick && (
          <XButton onClick={onCloseClick} />
        )}
        <div className="absolute left-1/2 transform -translate-x-1/2">
          {headerContent}
        </div>
      </div>
      <motion.div
        key={`${id}-motion-title`}
        variants={variants}
        initial="initial"
        animate="inFrame"
        exit="exit"
        style={{ overflow: 'hidden' }}
      >
        <div className={textContainerClass}>
          {title && (
            <ConfigurableText className={titleClass} variant="h3">
              {title}
            </ConfigurableText>
          )}
          {subtitle && (
            <ConfigurableText variant="body4" className={subtitleClass}>
              {subtitle}
            </ConfigurableText>
          )}
        </div>
      </motion.div>
    </div>
  )
}

function NavigationPage({
  id,
  title,
  subtitle,
  navigationOptions,
  backOverride,
  children,
  headerContent,
  onCloseClick,
  backDisabled,
  ...layoutProps
}: Props) {
  useEffect(() => {
    document.body.scrollTo({ top: 0, behavior: 'auto' })
  }, [])

  const headerProps = merge({}, defaultHeaderProps, {
    id,
    title,
    subtitle,
    navigationOptions,
    backOverride,
    headerContent,
    onCloseClick,
    backDisabled,
  })
  const navigationContext = useContext(NavigationContext)
  const variants =
    navigationContext &&
    createVariantsForPage(navigationContext.direction, isMobile())

  return (
    <AdaptiveCardRowLayout
      {...layoutProps}
      title={<NavigationPageHeader {...headerProps} />}
    >
      <motion.div
        key={`${id}-motion`}
        className="h-full"
        variants={variants}
        initial="initial"
        animate="inFrame"
        exit="exit"
      >
        {children}
      </motion.div>
    </AdaptiveCardRowLayout>
  )
}

type SingleCardContentProps = {
  children: React.ReactNode
  overrideFullscreen?: boolean
  className?: string
}

function SingleCardContent({
  children,
  overrideFullscreen,
  className,
}: SingleCardContentProps) {
  const breakpoint = useContext(BreakpointContext)
  const cardClass =
    breakpoint.smAndDown && !overrideFullscreen ? '!p-0' : undefined

  return (
    <Card
      fullHeight={breakpoint.smAndDown && !overrideFullscreen}
      flat={breakpoint.smAndDown && !overrideFullscreen}
      className={twMerge(cardClass, className)}
    >
      {children}
    </Card>
  )
}
SingleCardContent.displayName = 'SingleCardContent'
NavigationPage.SingleCardContent = SingleCardContent

function createVariantsForHeader(
  direction?: NavigationContextValue['direction'],
): Variants {
  return {
    initial: { opacity: 0, x: direction === 'forward' ? 50 : -50, y: 0 },
    inFrame: {
      opacity: 1,
      x: 0,
      transition: {
        ease: [0, 1, 0.75, 1],
        opacity: { duration: 0.2 },
        duration: 0.15,
      },
    },
    exit: { opacity: 0, transition: { ease: [0.25, 0, 1, 0], duration: 0.15 } },
  }
}

function createVariantsForPage(
  direction: NavigationContextValue['direction'],
  isMobile: boolean,
): Variants {
  const commonTransition = {
    transition: { ease: [0, 1, 0.75, 1], duration: 0.15 },
  }
  const xValue = isMobile
    ? direction === 'forward'
      ? '100vw'
      : '-100vw'
    : direction === 'forward'
    ? 80
    : -80

  return {
    initial: { opacity: isMobile ? undefined : 0, x: xValue, y: 0 },
    inFrame: {
      opacity: isMobile ? undefined : 1,
      x: 0,
      y: 0,
      ...commonTransition,
    },
    exit: {
      opacity: isMobile ? undefined : 0,
      x: isMobile ? (direction === 'forward' ? '-100vw' : '100vw') : -xValue,
      y: 0,
      ...commonTransition,
    },
  }
}

export default NavigationPage
