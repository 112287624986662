import { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useAppDispatch, useAppSelector, useUrlSearchParam } from '@/hooks'
import { ConnectionStatusMode } from '@/authenticated/components/DeviceConnectionRedirect'
import data from './resources/data'
import { getVehicles } from '@/actions/vehicles'
import { teslaFleetAuthenticationCollection } from '@/reducers/teslaFleetAuthentication'
import { RSAAResultAction } from 'redux-api-middleware'
import { logEvent } from '@/logging'
import { isNativeMobile } from '@/authenticated/hooks/useIsMobile'
import { TeslaFleetAuthentication } from '@/types'
import {
  selectMissingScopes,
  selectUrls,
} from '@/selectors/teslaFleetAuthentication'
import { TeslaFleetAuthenticationScopeName } from '@/types/teslaFleetAuthentication'
import { vehicleIsNew } from '@/types/vehicle'
import { useIsAvaBasePath } from '@/app/hooks/ava/useAvaVerification'

function useFetchFleetAuthorizeUrl() {
  const { data: fleetAuthentications } =
    teslaFleetAuthenticationCollection.useFetch()
  const fleetAuthorizeUrl = fleetAuthentications[0]?.authorize_url
  return fleetAuthorizeUrl
}

function useConnectionStatusMode(userCancelledTeslaLogin: boolean) {
  return useAppSelector((state) => {
    // if the user cancelled the request, we want to show an error page
    if (userCancelledTeslaLogin) {
      return ConnectionStatusMode.Failed
    }

    const loading = teslaFleetAuthenticationCollection.selectors.queryState
      .select(state, {
        method: 'POST',
      })
      .some((x) => x.status === 'loading')
    const errors = teslaFleetAuthenticationCollection.selectors.queryState
      .select(state, {
        method: 'POST',
      })
      .filter((x) => x.status === 'failed')
    const errorsFound = errors.length > 0
    const vehiclesWereReturned = teslaFleetAuthenticationCollection.selectors
      .selectAll(state)
      .some((x) => x.vehicles !== undefined)

    if (loading) {
      return ConnectionStatusMode.Connecting
    } else if (errorsFound) {
      return ConnectionStatusMode.Failed
    } else if (vehiclesWereReturned) {
      return ConnectionStatusMode.Connected
    }
    return ConnectionStatusMode.Connecting
  })
}

function useCreateFleetAuthenticationFromCodeInUrl() {
  const dispatch = useAppDispatch()
  const isMobile = isNativeMobile()
  useEffect(() => {
    // Because this is the entry point component for the connection flow on native mobile,
    // we need to ensure that we have fetched the vehicles before we attempt to connect
    // new vehicles. This is to populate our existing vehicles in the redux store. On
    // vehicle connect success, we concat our new vehicle to the existing vehicles.
    // By fetching now, we avoid having to refresh the page after connecting a vehicle
    // to see all of the vehicles.
    dispatch(getVehicles())
    const urlParams = new URLSearchParams(window.location.search)
    const code = urlParams.get('code')
    if (code) {
      // create authentication
      dispatch(
        teslaFleetAuthenticationCollection.actions.create({
          code,
        }),
      ).then((result: RSAAResultAction<TeslaFleetAuthentication>) => {
        // log event on success
        if (
          result?.type ===
          teslaFleetAuthenticationCollection.actionTypes.create.Success
        ) {
          const fleetAuthentication = result.payload as TeslaFleetAuthentication
          const authHasMissingScopes = Boolean(
            fleetAuthentication?.scopes?.missing?.length,
          )
          // log the event if the user has connected their vehicle
          // and they are not missing any permissions
          if (!authHasMissingScopes) {
            const numberNewlyCreatedVehicles =
              fleetAuthentication?.vehicles?.filter((v) => vehicleIsNew(v))
                .length ?? 0
            logEvent('addTesla', {
              metadata: {
                vehicle_count: numberNewlyCreatedVehicles,
              },
            })
            logEvent('add_vehicle', {
              count: numberNewlyCreatedVehicles,
            })
          }
          // On mobile, automatically redirect to the public key registration url after a successful connection.
          // Don't redirect if the user is missing scopes because we want them to interact with the instructions
          if (
            fleetAuthentication?.public_key_url &&
            isMobile &&
            !authHasMissingScopes
          ) {
            window.location.href = fleetAuthentication?.public_key_url
          }
        }
      })
    }
  }, [])
}

function getRedirectUrlState(): { utility?: string; onboarding?: boolean } {
  const urlParams = new URLSearchParams(window.location.search)
  const state = urlParams.get('state') ?? ''
  try {
    const decodedState = atob(state)
    const parsedState = JSON.parse(decodedState)
    return parsedState as { utility?: string; onboarding?: boolean }
  } catch {
    return {}
  }
}

function useViewModel() {
  const history = useHistory()
  const isMobile = isNativeMobile()
  const { utility, onboarding } = getRedirectUrlState()
  const isAvaBasePath = useIsAvaBasePath()
  if (utility?.toLowerCase() === 'ava' && onboarding && !isAvaBasePath) {
    history.replace(
      `/ava/onboarding${history.location.pathname}${history.location.search}`,
    )
  }
  const { path } = useRouteMatch()

  // This state is true when the user has clicked the continue button and we are waiting for the
  // public key registration url to open in a new tab. We need to track this state because we
  // need to disable actions to prevent the user from clicking the continue button multiple times
  const [currentlyRedirectingExternally, setCurrentlyRedirectingExternally] =
    useState(false)
  const userCancelledTeslaLogin =
    useUrlSearchParam('error') === 'login_cancelled'
  const missingScopes = useAppSelector(selectMissingScopes)?.map(
    (s) => TeslaFleetAuthenticationScopeName[s],
  )
  const connectionStatusMode = useConnectionStatusMode(userCancelledTeslaLogin)

  // fetch the fleet authorize url so that if the user cancels the login from the
  // tesla login page, we can redirect them back to the fleet authorize url
  const fleetAuthorizeUrl = useFetchFleetAuthorizeUrl()
  const publicKeyRegistrationUrl = useAppSelector((state) => {
    return selectUrls(state)?.public_key_url
  })

  // create the fleet authentication from url on page load
  useCreateFleetAuthenticationFromCodeInUrl()

  // select the vehicles from the fleet authentication we will use to redirect
  // the user to key confirmation page for a specific vehicle
  const authenticatedVehicles = useAppSelector((state) =>
    teslaFleetAuthenticationCollection.selectors
      .selectAll(state)
      .flatMap((a) => a.vehicles ?? []),
  )

  const buttonAction = () => {
    // If the user cancelled the login, redirect them back to the fleet authorize url to retry
    if (userCancelledTeslaLogin && fleetAuthorizeUrl) {
      window.location.href = fleetAuthorizeUrl
      return
    }

    // When in desktop, open the public key registration url in a new tab when the user clicks the continue
    // button to avoid the popup blocker.
    if (
      !isMobile &&
      connectionStatusMode === ConnectionStatusMode.Connected &&
      publicKeyRegistrationUrl
    ) {
      window.open(publicKeyRegistrationUrl, '_blank')
      setCurrentlyRedirectingExternally(true)
    }

    // if the user authenticated a vehicle, redirect them to the key confirmation page for that vehicle
    if (
      connectionStatusMode === ConnectionStatusMode.Connected &&
      authenticatedVehicles.length > 0
    ) {
      return history.push(`${path}/key-confirmation`)
    }
    history.push('/app')
  }

  const statusText = {
    ...data.statusText,
    failed: missingScopes ? data.permissionsErrorTitle : data.statusText.failed,
  }

  const alertText = {
    failed: userCancelledTeslaLogin
      ? data.cancelledLoginErrorText
      : missingScopes
      ? data.permissionsErrorText
      : undefined,
  }

  return {
    mode: connectionStatusMode,
    statusText,
    alertText,
    // if the user is missing scopes, we want them to interact with the instructions, so don't show any button that would close the window
    buttonAction,
    actionDisabled: currentlyRedirectingExternally,
    missingScopes,
  }
}

export default useViewModel
