import { Button, Flex, Text } from '@/components'
import { AnimatePresence, motion } from 'framer-motion'
import HomeEstimationConfirmation from '@/app/components/HomeEstimations/Success/HomeEstimationConfirmation'
import HomeEstimationData from '@/app/components/HomeEstimations/Data/HomeEstimationData'
import check from '@/app/img/check.svg'
import { AlertMessage } from 'app-components'
import dayjs from 'dayjs'
import { ReactNodeProps } from '@/types/reactNodeProps'
import useViewModel from './useViewModel'
import { Home } from '@/types'
import { logEvent } from '@/logging'

const variants = {
  open: { opacity: 1, transition: { duration: 0.25 } },
  closed: { opacity: 0, transition: { duration: 0.25 } },
}

export type HomeEstimationVariant = 'alert' | 'menu'

export type Props = {
  variant: HomeEstimationVariant
  forceShow?: boolean
  from?: string
}

function CommonMotion({ children }: ReactNodeProps) {
  return (
    <motion.div
      initial="closed"
      animate="open"
      exit="closed"
      variants={variants}
    >
      {children}
    </motion.div>
  )
}

function ConfirmationOfInfo({ home }: { home: Home }) {
  return (
    <div className="bg-themed-white border-solid border-2 border-themed-base-200 rounded-[8px] py-4 px-5">
      <Flex gap="8px" container direction="row">
        <Flex item>
          <img width="20" src={check} />
        </Flex>
        <Flex item>
          <Text variant="body1">Information Confirmed</Text>
          <Text variant="body4" className="pt-1">
            Confirmed on {dayjs(home?.modified).format('MMMM DD, YYYY')}.
          </Text>
        </Flex>
      </Flex>
    </div>
  )
}

function ErrorMessage({ message }: { message: string }) {
  return (
    <AlertMessage variant="error" className="mb-4">
      {message}
    </AlertMessage>
  )
}

function ConfirmationPrompt({
  variant,
  onConfirm,
  onIncorrect,
}: {
  variant: HomeEstimationVariant
  onConfirm: () => void
  onIncorrect: () => void
}) {
  const confirmInfo = () => {
    logEvent('Home_Estimations_Confirmed', { metadata: { confirmed: true } })
    onConfirm()
  }

  const incorrectInfo = () => {
    logEvent('Home_Estimations_Confirmed', { metadata: { confirmed: false } })
    onIncorrect()
  }

  return (
    <CommonMotion key="prompt">
      <Text variant="subheader" className="mt-8 leading-4">
        Does the information above look accurate?
      </Text>
      <Flex
        container
        gap="8px"
        className={variant === 'alert' ? 'mt-3' : 'mt-4'}
        direction="row"
      >
        <Flex item>
          <Button
            onClick={confirmInfo}
            id="correct-info-btn"
            className={`${
              variant === 'alert' ? 'bg-themed-white' : ''
            } desktop:bg-themed-white`}
            variant="chip"
          >
            Yes
          </Button>
        </Flex>
        <Flex item>
          <Button
            onClick={incorrectInfo}
            id="not-correct-info-btn"
            variant="chip"
            className={`${
              variant === 'alert' ? 'bg-themed-white' : ''
            } desktop:bg-themed-white`}
          >
            No
          </Button>
        </Flex>
      </Flex>
    </CommonMotion>
  )
}

export default function HomeEstimation({ variant, forceShow, from }: Props) {
  const {
    showSuccess,
    onConfirm,
    onIncorrect,
    isEstimated,
    home,
    error,
    featureFlag,
  } = useViewModel({ variant, forceShow, from })

  if (!featureFlag || !home) {
    return null
  }

  if (!forceShow && !isEstimated) {
    return null
  }

  const containerClasses = variant === 'alert' ? 'bg-themed-notice p-5' : ''
  const contentClasses =
    variant === 'alert'
      ? 'bg-themed-notice'
      : 'bg-themed-white border-solid border-2 border-themed-base-200 rounded-[8px] py-3 px-4'

  const showConfirmationPrompt =
    !isEstimated && home && !showSuccess && variant === 'menu'

  return (
    <div className={`${containerClasses} rounded-[8px] relative`}>
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full px-2">
        {showSuccess && (
          <CommonMotion key="confirmation">
            <HomeEstimationConfirmation />
          </CommonMotion>
        )}
      </div>
      <AnimatePresence mode="wait">
        <div className={contentClasses}>
          {error && (
            <ErrorMessage message="An error occurred. Please try again." />
          )}
          <CommonMotion key="data">
            <span className={showSuccess ? 'invisible' : ''}>
              <HomeEstimationData variant={variant} />
            </span>
          </CommonMotion>
        </div>
        {isEstimated && !showSuccess && variant === 'menu' && (
          <ConfirmationPrompt
            variant={variant}
            onConfirm={onConfirm}
            onIncorrect={onIncorrect}
          />
        )}
        {variant === 'alert' && (
          <span className={showSuccess ? 'invisible' : ''}>
            <ConfirmationPrompt
              variant={variant}
              onConfirm={onConfirm}
              onIncorrect={onIncorrect}
            />
          </span>
        )}
        {showConfirmationPrompt && (
          <CommonMotion key="confirmed-info">
            <div className="mt-6">
              <ConfirmationOfInfo home={home} />
            </div>
          </CommonMotion>
        )}
      </AnimatePresence>
    </div>
  )
}
