import {
  useFeatureFlagEnabled,
  useFeatureFlagPayload,
  useFeatureFlagVariantKey,
} from 'posthog-js/react'

/**
 * Use a PostHog feature flag to determine if a feature should be enabled.
 * @param flag The name of the feature flag.
 * @returns A boolean indicating if the feature flag is enabled.
 */
export const usePostHogBooleanFeatureFlag = (flag: string) => {
  return useFeatureFlagEnabled(flag) ?? false
}

/**
 * Use a PostHog feature flag to determine if a feature should be enabled.
 * @param flag The name of the feature flag.
 * @returns The payload of the feature flag.
 */
export const usePostHogFeatureFlag = (flag: string) => {
  return useFeatureFlagPayload(flag) ?? false
}

/**
 * Use a PostHog feature flag to determine the variant of a feature.
 * @param flag The name of the feature flag.
 * @returns The variant key of the feature flag or boolean indicating if the feature flag is enabled.
 */
export const usePostHogVariantFeatureFlag = (flag: string) => {
  return useFeatureFlagVariantKey(flag) ?? false
}
