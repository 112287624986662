import './styles.scss'
import {
  Button,
  CardActionButton,
  CardActions,
  Grid,
  GridItem,
  Icon,
  Text,
} from 'component-library'
import React, { HTMLAttributes, useState } from 'react'
import formatClasses from '@/utils/classes/formatClasses'
import { Identifiable } from '@/types'
import twMerge from '@/utils/classes/twMerge'

type QuestionnaireVersion = '1.0' | '2.0'

export interface Props
  extends Omit<Identifiable<HTMLAttributes<HTMLDivElement>>, 'onSubmit'> {
  onBackClick: () => void
  onNextClick: (setDisabled: (value: boolean) => void) => void
  onSkipClick: () => void
  onSubmit: (responses: QuestionnairePageValue[]) => void
  pages: QuestionnairePage[]
  pageNumber: number
  setPageNumber: (value: (value: number) => number) => void
  showSkipModalId: string | null
  setShowSkipModalId: (value: string | null) => void
  values: QuestionnairePageValue[]
  setValues: (values: QuestionnairePageValue[]) => void
  insertQuestionnaireResponse: (value: QuestionnairePageValue['value']) => void
  showBackButton?: boolean
  /**
   * The version of the component
   * changelog: 1.0 -> 2.0
   * - moved back button to upper left
   * - moved question title to inside QuestionnaireContainer
   * - added option to go to next page from onChange if no next button is present
   */
  version?: QuestionnaireVersion
}

export type QuestionnairePage = {
  buttons: QuestionnaireButtonType[]
  component: (props: any) => JSX.Element
  order: number
  key: string
  redirect?: string
  skipModal?: (
    onSkipClick: () => void,
    onSkipClose: () => void,
  ) => React.ReactNode
  /**
   * A predicate that determines whether or not the page should be shown.
   * Use this when you want to show a page conditionally based on the responses
   * to previous pages.
   */
  shouldShow?: (values: QuestionnairePageValue[]) => boolean
  /**
   * A predicate that determines which buttons to be shown.
   * Use this when you want to conditionally show buttons based on responses
   */
  showButtons?: (values: QuestionnairePageValue[]) => QuestionnaireButtonType[]
  title?: string
}

interface QuestionnairePageProps {
  component: any
  onChange: (value: QuestionnairePageValue['value']) => void
  showSkipModalId: string | null
  setShowSkipModalId: (value: string | null) => void
}

type QuestionnaireContainerProps = {
  className: string
  id: string
  version: QuestionnaireVersion
  onBack?: () => void
  title?: string
  children: React.ReactNode
}

export type QuestionnairePageValue = {
  order: number
  value: (string | number | boolean)[]
  key: string
}

export type QuestionnaireButtonType =
  | 'back'
  | 'next'
  | 'skip'
  | { custom: string }

interface QuestionnaireButtonProps extends Identifiable {
  disabled?: boolean
  onClick?: () => void
}

function BackButton(props: QuestionnaireButtonProps) {
  return (
    <CardActionButton
      data-testing-id="back-button"
      type="navigation"
      {...props}
    >
      <Icon name="ChevronLeft" color="grey-500" size={18} />
    </CardActionButton>
  )
}

function NextButton(props: QuestionnaireButtonProps) {
  return (
    <CardActionButton data-testing-id="next-button" type="primary" {...props}>
      Next
    </CardActionButton>
  )
}
function SkipButton(props: QuestionnaireButtonProps) {
  return (
    <CardActionButton data-testing-id="skip-button" type="secondary" {...props}>
      Skip
    </CardActionButton>
  )
}

function CustomButton(
  props: QuestionnaireButtonProps & { children: React.ReactNode },
) {
  return (
    <CardActionButton data-testing-id="next-button" type="primary" {...props}>
      {props.children}
    </CardActionButton>
  )
}

function QuestionnairePage(props: QuestionnairePageProps) {
  if (typeof props.component !== 'function')
    console.error(
      'The "component" property must receive a function, e.g., `component: FunctionComponent`',
    )

  const Component = props.component

  return <Component onChange={props.onChange} />
}

const QuestionnaireContainer = (props: QuestionnaireContainerProps) => {
  if (props.version === '2.0') {
    return (
      <div className={twMerge('flex flex-col', props.className)}>
        <div className="flex items-center space-x-1 mb-5 h-10">
          {props.onBack && (
            <Button
              id={`${props.id}-back-button`}
              variant="icon"
              onClick={props.onBack}
              className="-ml-2"
            >
              <Icon name="ArrowLeft" size={24} color="grey-900" />
            </Button>
          )}
          <Text variant="h2">{props.title}</Text>
        </div>
        {props.children}
      </div>
    )
  } else {
    return <div className={props.className}>{props.children}</div>
  }
}

export default function Questionnaire(props: Props) {
  const version = props.version ?? '1.0'
  const classes = {
    questionnaire: formatClasses([props.className]),
  }

  const [isDisabledFlag, setIsDisabledFlag] = useState(false)
  function isDisabled() {
    if (isDisabledFlag) {
      return true
    }
    // If the props.values list is not initialized or the page is not in the list yet
    if (!props.values.length || !props.values[props.pageNumber]) return true

    // If any of the responses on the current page are null
    return props.values[props.pageNumber]?.value?.some(
      (value: unknown) => value === null,
    )
  }

  function onChange(value: QuestionnairePageValue['value']) {
    props.insertQuestionnaireResponse(value)
  }

  function onSkipClose() {
    props.setShowSkipModalId(null)
  }
  function onSkipClick() {
    onChange([false])
    props.onSkipClick()
  }
  function getSkipModal(
    skipModal?: (
      onNextClick: () => void,
      onSkipClose: () => void,
    ) => React.ReactNode,
  ) {
    if (!skipModal) return
    return skipModal(onSkipClick, onSkipClose)
  }

  const page = props.pages?.[props.pageNumber]
  if (!page) {
    return null
  }

  return (
    <QuestionnaireContainer
      id={props.id}
      version={version}
      className={classes.questionnaire}
      onBack={
        page.buttons.includes('back') || props.showBackButton
          ? props.onBackClick
          : undefined
      }
      title={page.title ?? ''}
    >
      <Grid flow="row" fillHeight templateRows="auto 1fr" gap="40px">
        <GridItem>
          <QuestionnairePage
            component={page.component}
            onChange={onChange}
            setShowSkipModalId={props.setShowSkipModalId}
            showSkipModalId={props.showSkipModalId}
          />
        </GridItem>
        <CardActions>
          {page.buttons.map(
            (button: QuestionnaireButtonType, index: number) => {
              if (typeof button === 'object') {
                return (
                  <CustomButton
                    id={`${props.id}-${button.custom}-button`}
                    key={index}
                    onClick={() => props.onNextClick(setIsDisabledFlag)}
                    disabled={isDisabled()}
                  >
                    {button.custom}
                  </CustomButton>
                )
              }

              switch (button) {
                case 'back':
                  if (version == '1.0') {
                    return (
                      <BackButton
                        id={`${props.id}-back-button`}
                        key={index}
                        onClick={props.onBackClick}
                      />
                    )
                  } else {
                    return null
                  }
                case 'next':
                  return (
                    <NextButton
                      id={`${props.id}-next-button`}
                      disabled={isDisabled()}
                      key={index}
                      onClick={() => props.onNextClick(setIsDisabledFlag)}
                    />
                  )
                case 'skip':
                  return (
                    <>
                      <SkipButton
                        id={`${props.id}-skip-button`}
                        disabled={false}
                        key={index}
                        onClick={() =>
                          page.skipModal
                            ? props.setShowSkipModalId(props.id)
                            : props.onSkipClick()
                        }
                      />
                      {props.showSkipModalId === props.id &&
                        getSkipModal(page.skipModal)}
                    </>
                  )
                default:
                  return <></>
              }
            },
          )}
        </CardActions>
      </Grid>
    </QuestionnaireContainer>
  )
}
