import React from 'react'
import { CardMedia } from '@material-ui/core'
import MeterConnectionCard from '../MeterConnectionCard'
import data from './resources/data.json'
import winnableTeslaImage from './resources/images/win-tesla-red.webp'

export interface Props {
  onButtonClick: () => void
  onClose: () => void
}

export default function TeslaGiveawayMeterConnectionCard({
  onButtonClick,
  onClose,
}: Props) {
  return (
    <MeterConnectionCard
      title={data.title}
      subtitle={data.subtitle}
      buttonText={data.buttonText}
      onButtonClick={onButtonClick}
      onClose={onClose}
    >
      <CardMedia component="img" image={winnableTeslaImage} />
    </MeterConnectionCard>
  )
}
