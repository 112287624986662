import { Redirect } from 'react-router-dom'
import { remoteConfig } from '@/firebase'
import useWhiteLabelProgramId from '@/hooks/whitelabel/useWhiteLabelProgramId'

export default function OnboardingSurvey() {
  const whitelabelId = useWhiteLabelProgramId()
  const avaId = remoteConfig?.getWhitelabelId('ava')

  if (whitelabelId && avaId && whitelabelId === avaId) {
    return <Redirect to="/ava/onboarding/address-select" />
  }
  return <Redirect to="/onboarding" />
}
