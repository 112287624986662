import { Card, LoadingAnimation, Text } from '@/components'
import { Link } from 'app-components'
import React from 'react'
import useViewModel from '@/app/components/VehiclePage/useViewModel'

type Props = {
  children: React.ReactNode
}

/**
 * The purpose of this component is to take care of some validation
 * to ensure that any charge settings of a vehicle can be modified
 * with the vehicle's current charge/utility settings. It also provides
 * consistent failure messaging and loading indication
 * @param props - Contains react children and a vehicle ID
 */
export default function VehiclePage(props: Props) {
  const { isLoading, vehicleId, chargeable } = useViewModel()

  if (isLoading) {
    return <LoadingAnimation type="falling" centered></LoadingAnimation>
  }

  if (!chargeable) {
    return (
      <Card>
        <div className="flex flex-col">
          <Text variant="body4">
            Your vehicle is not set up to have its charging managed by Optiwatt.
          </Text>
          <div className="mt-4">
            <Link to={`/devices/vehicle/${vehicleId}?tab=settings`}>
              Enable charge settings.
            </Link>
          </div>
        </div>
      </Card>
    )
  }

  return <>{props.children}</>
}
