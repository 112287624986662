import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import DateFnsUtils from '@date-io/date-fns'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { StylesProvider } from '@material-ui/styles'
import { ThemeProvider } from 'styled-components'
import { pageWithErrorBoundary } from './components/ErrorBoundary'
import { client } from '../apolloClient'

import { ApolloProvider } from '@apollo/client'

import { getCurrentUser } from '../actions/user'
import { getVehicles } from '../actions/vehicles'
import { getHomes } from '../actions/homes'
import { getUtilities } from '../actions/utilities'
import { getPlans } from '../actions/plans'
import { getVehicleChargingDelayEvents } from '../actions/vehicleChargingDelayEvents'
import { thermostatsCollection } from '../reducers/thermostats'
import { utilityProgramCollection } from '../reducers/utilityPrograms'
import { utilityProgramEnrollmentCollection } from '../reducers/utilityProgramEnrollments'

import maTheme from '../v1/theme'
import { AppRoutes } from '../app/routes'
import { LOG_EVENT_TYPE } from '../utils/messageUtils'

import '../styles/colors.scss'
import '../styles/styles.scss'
import { logServerEvent } from '@/actions/event'
import useVehicleChargeSchedulesFetcher from './hooks/useVehicleChargeSchedulesFetcher'
import { surveyResponsesCollection } from '@/reducers/surveyResponses'
import {
  thermostatEnrollmentCollection,
  vehicleEnrollmentCollection,
  chargerEnrollmentCollection,
} from '@/reducers/deviceProgramEnrollments'
import { thermostatDemandResponseEventsCollection } from '@/reducers/thermostatDemandResponseEvents'
import { teslaFleetAuthenticationCollection } from '@/reducers/teslaFleetAuthentication'
import { vehicleChargersCollection } from '@/reducers/vehicleChargers'
import { v4 as uuidv4 } from 'uuid'
import { useAppSelector } from '@/hooks'
import ZendeskLiveChat from '@/app/components/ZendeskLiveChat'
export function useInitialDataLoader(dispatch, initialFetch = true) {
  const isThereAUser = useAppSelector((state) => !!state?.auth?.refresh?.token)
  const {
    actions: { fetch: vehicleEnrollmentFetch },
  } = vehicleEnrollmentCollection
  const {
    actions: { fetch: thermostatEnrollmentFetch },
  } = thermostatEnrollmentCollection
  const {
    actions: { fetch: chargerEnrollmentFetch },
  } = chargerEnrollmentCollection
  const {
    actions: { fetch: getThermoStats },
  } = thermostatsCollection
  const {
    actions: { fetch: getUtilityPrograms },
  } = utilityProgramCollection
  const {
    actions: { fetch: getUtilityProgramEnrollments },
  } = utilityProgramEnrollmentCollection
  const {
    actions: { fetch: getTeslaFleetAuthentication },
  } = teslaFleetAuthenticationCollection
  const {
    actions: { fetch: getSurveyResponses },
  } = surveyResponsesCollection
  const vehicleChargersFetcher = vehicleChargersCollection.useResourceFetcher()
  const thermostatDemandResponseFetcher =
    thermostatDemandResponseEventsCollection.useResourceFetcher({
      params: {
        start: new Date().toISOString(),
        // 24 hours from now
        end: new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString(),
      },
    })

  /**
   * We want to share the same logic for fetching data across multiple components.
   */
  const dispatches = [
    // When transpiled we need a way to identify these functions vs the action functions
    { fn: thermostatDemandResponseFetcher.fetch, name: 'fetchDispatcher' },
    { fn: vehicleChargersFetcher.fetch, name: 'fetchDispatcher' },
    vehicleEnrollmentFetch,
    thermostatEnrollmentFetch,
    chargerEnrollmentFetch,
    getThermoStats,
    getUtilityPrograms,
    getUtilityProgramEnrollments,
    getTeslaFleetAuthentication,
    getCurrentUser,
    getSurveyResponses,
    getVehicles,
    getHomes,
    getUtilities,
    getPlans,
    getVehicleChargingDelayEvents,
  ]

  async function callDispatch() {
    console.log(`!!!!!!!!!!!!!!! WE ARE INITIALIZING THE APP !!!!!!!!!!!!!!!`)
    await Promise.all(
      dispatches.map((action) => {
        // if async function call, otherwise dispatch the call
        if (action.name === 'fetchDispatcher' && action.fn) {
          return action.fn()
        } else {
          return dispatch(action())
        }
      }),
    )
  }

  useEffect(() => {
    if (initialFetch && isThereAUser) {
      if (isThereAUser) {
        callDispatch()
      }
    }
  }, [isThereAUser])

  useVehicleChargeSchedulesFetcher({ keepInSyncWithVehicles: true })

  useEffect(() => {
    window.addEventListener('message', (message) => {
      if (message?.data?.from === 'react-native' && message?.data?.event) {
        logEventIfNeeded(message.data.event)
      }
    })
  }, [])

  return callDispatch
}

const logEventIfNeeded = (event) => {
  if (typeof event === 'string' && event?.includes?.(LOG_EVENT_TYPE)) {
    const serializedEvent = event.split(`${LOG_EVENT_TYPE}|`)[1]
    const eventObject = JSON.parse(serializedEvent)
    logServerEvent(eventObject.event, true, uuidv4())
  }
}

function App() {
  const dispatch = useDispatch()
  const theme = useSelector((state) => state.themeReducer)

  useInitialDataLoader(dispatch)

  return (
    <ApolloProvider client={client}>
      <StylesProvider injectFirst>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiThemeProvider theme={maTheme[theme.currentTheme]}>
            <ThemeProvider theme={maTheme[theme.currentTheme]}>
              <ZendeskLiveChat />
              <AppRoutes />
            </ThemeProvider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ApolloProvider>
  )
}

export default pageWithErrorBoundary(App, {
  severity: 'critical',
})
