import { createResourceCollection } from '@/request'
import { ResourceModel } from '@/request/types'
import { ID } from '@/types/model'

export type DeletedVehicleCharger = {
  charger: ID
  profile: ID
  reason: string
} & ResourceModel

export const deletedVehicleChargerCollection =
  createResourceCollection<DeletedVehicleCharger>({
    name: 'deletedVehicleCharger',
    apiConfig: {
      path: 'deleted_vehicle_chargers',
    },
    selector: (state) => state.deletedVehicleCharger,
  })

export default deletedVehicleChargerCollection.slice.reducer
