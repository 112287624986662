import './styles.scss'
import formatClasses from '../../../../utils/classes/formatClasses'
import {
  Grid,
  GridItem,
  Icon,
  Menu,
  NumberEasing,
  Text,
} from '../../../../components'
import { BaseSyntheticEvent, useEffect, useRef, useState } from 'react'
import { ThermostatAdjustment } from '../../../../types/thermostatAdjustment'
import { percentToDegrees } from './useViewModel'
import { DateRepresentation } from '../../../../types/dates'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import dayjs from 'dayjs'
import { toDegrees, toTitleCase } from '../../../../utils/string'
import {
  ThermostatMeasurement,
  ThermostatMode,
  ThermostatState,
} from '../../../../types/thermostatMeasurement'
import { AlertMessage, CostSheet, Link } from '../../../components'
import useIsMobile from '../../../../authenticated/hooks/useIsMobile'

export type Props = {
  loading: boolean
  disabled: boolean
  adjustment: Partial<ThermostatAdjustment>
  measurement: Partial<ThermostatMeasurement>
  setValue: (temperature: number) => void
  value: number
  temperature: number
  previousModifiedTime?: DateRepresentation
  indoorTemperature?: number
  indoorTemperaturePercent: number
  // The mode the thermostat is in (can be auto)
  thermostatMode: ThermostatMode
  setHvacMode: (mode: 'heat' | 'cool' | 'off') => void
  // the mode the hvac is in (cannot be auto)
  hvacMode: 'heat' | 'cool' | 'off'
  hvacState: ThermostatState
  onPointerActionEnd: () => void
}

interface ThermostatSliderProps {
  setValue: (temperature: number) => void
  value: number
  indoorTemperaturePercent: number
  onPointerActionEnd: () => void
  disabled: boolean
}

interface ThermostatDetailsProps {
  estimatedCostCents?: number
  holdUntilTime?: DateRepresentation
  indoorTemperature?: number
  modified?: DateRepresentation
}

function ThermostatSlider(props: ThermostatSliderProps) {
  const [pointerDown, setPointerDown] = useState(false)
  const [gradientClass, setGradientClass] = useState(
    formatClasses(['thermostat-slider-gradient', 'slide']),
  )
  const [sliderThumbClass, setSliderThumbClass] = useState(
    formatClasses(['thermostat-slider-thumb', 'slide']),
  )
  const sliderRef = useRef<HTMLDivElement>(null)
  const rangeRef = useRef<HTMLInputElement>(null)
  const isMobile = useIsMobile()

  const classes = {
    gradient: gradientClass,
    mask: formatClasses(['thermostat-slider-mask']),
    slider: formatClasses(['thermostat-slider']),
    thumb: sliderThumbClass,
  }

  // Gradient is rotated to stay aligned with the slider thumb
  const gradientRotation = percentToDegrees(props.value, [85, 355])

  // Gradient disabled styling
  const disabledGradientStyle = !props.disabled
    ? {}
    : { opacity: 0.45, cursor: 'not-allowed' }

  const disabledCursorOverride = !props.disabled
    ? {}
    : { cursor: 'not-allowed' }

  // Removes the transition on the slider thumb after it has done an initial transition on mount
  // This prevents disparity between the gradient rotation and the thumb position
  useEffect(() => {
    setTimeout(() => {
      setGradientClass(formatClasses(['thermostat-slider-gradient']))

      setSliderThumbClass(
        formatClasses([
          !props.disabled
            ? 'thermostat-slider-thumb'
            : 'thermostat-slider-thumb-disabled',
        ]),
      )
    }, 500)
  }, [])

  // Prevents a weird selection error when switching between simulated mobile and desktop in Chrome
  useEffect(() => {
    setPointerDown(false)
  }, [isMobile])

  const calculateRadians = (x: number, y: number) => {
    const radians = Math.atan2(y, x)

    return radians <= 0 ? 2 * Math.PI + radians : radians
  }

  const radiansToDegrees = (radians: number) => {
    let degrees = (180 * radians) / Math.PI - 135

    // Prevents thumb from skipping to the end of the track unexpectedly
    if (degrees > -135 && degrees < -90) degrees = 225 + (135 + degrees)
    // Enforces an upper and lower bound and allows thumb to move to opposite side of the track
    else if (degrees < 0) {
      degrees = degrees < -45 ? 270 : 0
    }

    return degrees
  }

  const onPointerMove = (
    event: BaseSyntheticEvent<MouseEvent | TouchEvent>,
  ) => {
    if (
      props.disabled ||
      !sliderRef.current ||
      !rangeRef.current ||
      !pointerDown
    ) {
      return
    }

    const { max, min } = rangeRef.current
    let pointerX = 0
    let pointerY = 0

    if (event.nativeEvent instanceof MouseEvent) {
      pointerX = event.nativeEvent.clientX
      pointerY = event.nativeEvent.clientY
    } else if (event.nativeEvent instanceof TouchEvent) {
      const touchEvent =
        event.nativeEvent.touches[0] || event.nativeEvent.changedTouches[0]

      pointerX = touchEvent.clientX
      pointerY = touchEvent.clientY
    }

    const { height, left, top, width } =
      sliderRef.current.getBoundingClientRect()

    const radialSliderX = left + width / 2
    const radialSliderY = top + height / 2

    // Calculate the value of the `<input type="range">` based on the radial slider position
    const radians = calculateRadians(
      pointerX - radialSliderX,
      pointerY - radialSliderY,
    )
    const degrees = radiansToDegrees(radians)
    const percentage = Math.round(
      (degrees / 270) * (Number(max) - Number(min)) + Number(min),
    )

    props.setValue(percentage)
  }

  function onPointerUp() {
    setPointerDown(false)
    props.onPointerActionEnd()
  }

  function onPointerLeave() {
    if (pointerDown) {
      setPointerDown(false)
      props.onPointerActionEnd()
    }
  }

  return (
    <div
      className={classes.slider}
      style={{ ...disabledCursorOverride }}
      ref={sliderRef}
      onPointerDown={() => !isMobile && setPointerDown(true)}
      onPointerUp={() => !isMobile && onPointerUp()}
      onPointerMove={(event) => !isMobile && onPointerMove(event)}
      onPointerLeave={() => !isMobile && onPointerLeave()}
    >
      <input
        hidden
        min={0}
        max={100}
        ref={rangeRef}
        type="range"
        value={props.value}
        onChange={() => {}}
      />
      <div
        className="thermostat-indoor-temperature"
        style={{
          transform: `rotate(${percentToDegrees(
            props.indoorTemperaturePercent || 0,
            [-43, 227],
          )}deg)`,
        }}
      />
      <div
        className={classes.thumb}
        onPointerDown={() => isMobile && setPointerDown(true)}
        onPointerUp={() => isMobile && onPointerUp()}
        onPointerMove={(event) => isMobile && onPointerMove(event)}
        onPointerLeave={() => isMobile && onPointerLeave()}
        style={{
          transform: `rotate(${percentToDegrees(props.value, [-38, 230])}deg)`,
        }}
      />
      <div className={classes.mask}>
        <div
          className={classes.gradient}
          style={{
            background: `conic-gradient(from 140deg, var(--color-tier-300), var(--color-tier-200), var(--color-tier-100) ${
              400 - gradientRotation
            }deg, var(--color-tier-900) ${
              400 - gradientRotation
            }deg, var(--color-tier-800), var(--color-tier-700) , var(--color-tier-600), var(--color-tier-500), var(--color-tier-400) 360deg)`,
            transform: `rotate(${gradientRotation}deg)`,
            ...disabledGradientStyle,
          }}
        />
      </div>
    </div>
  )
}

function ThermostatDetails(props: ThermostatDetailsProps) {
  const updatedTime = dayjs(String(props.modified)).add(1, 'hour').format()
  const recentlyAdjusted = props.modified && updatedTime > dayjs().format()

  const AdjustmentDetails = () => {
    return (
      <Grid placeItems="center">
        <Grid gap="8px" placeItems="center">
          <Icon name="Clock" color="grey-500" size={16} />
          <Text>{dayjs(String(props.holdUntilTime)).format('h:mm A')}</Text>
        </Grid>
        <CostSheet
          className="thermostat-adjustment-cost"
          cost={props.estimatedCostCents}
          size="md"
        />
      </Grid>
    )
  }

  const IndoorTemperature = () => {
    return (
      <Text>
        {props.indoorTemperature
          ? `Indoor ${toDegrees(props.indoorTemperature)}`
          : '\u00A0'}
      </Text>
    )
  }

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        classNames="thermostat-details"
        key={recentlyAdjusted ? 'adjustment-details' : 'indoor-temperature'}
        timeout={200}
      >
        {recentlyAdjusted ? <AdjustmentDetails /> : <IndoorTemperature />}
      </CSSTransition>
    </SwitchTransition>
  )
}

type ThermostatModeLabelProps = {
  thermostatMode: ThermostatMode
  hvacMode: 'heat' | 'cool' | 'off'
  setHvacMode: (mode: 'heat' | 'cool') => void
  state: ThermostatState
}
function ThermostatModeLabel(props: ThermostatModeLabelProps) {
  const activatorClasses = formatClasses(['thermostat-mode-label-activator'])
  return props.thermostatMode !== 'auto' ? (
    <Text variant="body2">
      {props.hvacMode === 'off'
        ? 'Off'
        : props.state === 'on'
        ? `${toTitleCase(props.hvacMode)}ing to`
        : toTitleCase(props.hvacMode)}
    </Text>
  ) : (
    <Menu>
      <Menu.Activator>
        <div className={activatorClasses}>
          <Text variant="body2">
            {toTitleCase(props.hvacMode)}{' '}
            <Icon name="ChevronDown" color="grey-500" size={16} />
          </Text>
        </div>
      </Menu.Activator>
      <Menu.List>
        <Menu.ListItem onClick={() => props.setHvacMode('heat')}>
          Heat
        </Menu.ListItem>
        <Menu.ListItem onClick={() => props.setHvacMode('cool')}>
          Cool
        </Menu.ListItem>
      </Menu.List>
    </Menu>
  )
}

export default function Thermostat(props: Props) {
  const offMode = props.hvacMode === 'off'

  const classes = formatClasses([
    'thermostat',
    props.loading ? 'loading' : undefined,
    offMode ? 'off' : undefined,
  ])

  return (
    <>
      <Grid fluid={true} gap="20px" flow="row" placeItems="center">
        <GridItem style={{ width: '100%' }}>
          {props.disabled && (
            <AlertMessage variant={'info'}>
              Adjusting of the thermostat is currently locked. To unlock this
              feature, you must connect your utility.
              <br />
              <br />
              <Link to="/connect-utility">Connect your utility</Link>
            </AlertMessage>
          )}
        </GridItem>
        <GridItem>
          <div className={classes}>
            <ThermostatSlider
              indoorTemperaturePercent={props.indoorTemperaturePercent}
              setValue={props.setValue}
              value={offMode ? 0 : props.value}
              disabled={props.disabled}
              onPointerActionEnd={props.onPointerActionEnd}
            />
            <Grid
              className="thermostat-info"
              flow="row"
              gap="8px"
              placeItems="center"
            >
              <GridItem placeItems="center">
                <ThermostatModeLabel
                  thermostatMode={props.thermostatMode}
                  hvacMode={props.hvacMode}
                  setHvacMode={props.setHvacMode}
                  state={props.hvacState}
                />
                <Text className="thermostat-temperature" variant="title">
                  <NumberEasing
                    value={
                      offMode ? props.indoorTemperature ?? 0 : props.temperature
                    }
                    speed={400}
                    ease="quadOut"
                  />
                  &deg;
                </Text>
              </GridItem>
              <ThermostatDetails
                estimatedCostCents={props.adjustment?.estimated_cost_cents}
                holdUntilTime={props.adjustment?.hold_until_time}
                indoorTemperature={
                  offMode ? undefined : props.indoorTemperature
                }
                modified={props.adjustment?.modified}
              />
            </Grid>
          </div>
        </GridItem>
      </Grid>
    </>
  )
}
