import { createResourceCollection } from '@/request'

export const adminUtilityProgramCollection = createResourceCollection({
  name: 'adminUtilityPrograms',
  apiConfig: {
    path: `/admin/utility_programs`,
  },
  selector: (state) => state.adminUtilityPrograms,
})

export const adminUtilityPrograms = adminUtilityProgramCollection.slice.reducer
