import { useLocation } from 'react-router-dom'

export function useUrlSearchParams() {
  const location = useLocation()
  return new URLSearchParams(location.search)
}

export default function useUrlSearchParam(param: string) {
  const searchParams = useUrlSearchParams()
  return searchParams.get(param)
}
