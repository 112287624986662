import Faq from '../Faq'

export default function SmudFaq() {
  const questions = [
    {
      question: 'Why is SMUD offering this program?',
      answer:
        "SMUD is working with Optiwatt to test how it can help Sacramento-area customers charge their electric vehicles at times of day when energy demand is low. This puts less strain on the electricity grid and allows us to use more renewable energy. The Managed EV Charging pilot program supports SMUD's goal to eliminate carbon emissions from the power supply by 2030.",
    },
    {
      question: 'How does it work?',
      answer: `
        <ol style="list-style:auto; padding-left:16px;">
          <li>Plug in your EV and let Optiwatt know when you need your vehicle charged.</li>
          <li>Optiwatt will create an optimized charging schedule based on your preferences and information from SMUD about the best times to charge.</li>
          <li>This charging schedule will be sent to your car automatically. All you need to do is plug in your car to charge and earn rewards.</li>
        </ol>
        <br />
        <p>You're free to leave the program at any time without penalty. Please contact Optiwatt to unenroll at support@getoptiwatt.com or unenroll within the app.</p>
        <a href="https://www.smud.org/-/media/Documents/Going%20Green/EVs/MCP%20Terms%20and%20Conditions%20_%20Optiwatt.ashx" target="_blank">SMUD Managed Charging Pilot Terms & Conditions</a>
      `,
      html: true,
    },
    {
      question: 'What are the eligible car models',
      answer: `
        <p>SMUD customers who drive a battery, electric or plug-in hybrid EV from one of the following lists may apply to participate. </p>
        <p><span style="font-weight: 600;">Note:</span> This list is subject to change at any time at the discretion of Optiwatt and SMUD.</p>
        <span style="font-weight: 600;">Tesla vehicles</span>
        <ul style="list-style: circle; padding-left:16px;">
          <li>3</li>
          <li>S</li>
          <li>X</li>
          <li>Y</li>
        </ul>
        <br />
      `,
      html: true,
    },
    {
      question:
        'Is a smart thermostat required to participate in the Managed EV Charging pilot?',
      answer:
        'No, a smart thermostat is not required to participate in the pilot.',
    },
    {
      question: 'What rewards will I receive?',
      answer: `
        <p>Once your vehicle has been connected to the communication platform and SMUD has verified eligibility, you'll be eligible to receive participation rewards. Participants will receive a one-time, initial incentive and also a quarterly reward payment.</p>
        <table style="width:100%; font-size: 10px">
          <tr>
            <th style="font-weight: 600;">Charging Level</th>
            <th style="font-weight: 600;">Description</th>
            <th style="font-weight: 600;">One-time Initial Incentive</th>
            <th style="font-weight: 600;">Ongoing Quarterly Reward</th>
          </tr>
          <tr>
            <td>Level 1</td>
            <td>Uses a standard 120-volt household outlet.</td>
            <td>$150</td>
            <td>$20</td>
          </tr>
          <tr>
            <td>Level 2</td>
            <td>Uses a 208/240-volt  electrical plug and charging cord or installed EV charger unit.</td>
            <td>$150</td>
            <td>$20</td>
          </tr>
        </table>
      `,
      html: true,
    },
    {
      question:
        'Can I participate if I don’t have the ability to charge at home?',
      answer:
        'Yes, you can participate if you are unable to charge at home but are able to consistently charge at work or other locations.',
    },
    {
      question: `Will I be scheduled to charge outside of SMUD's discounted EV rate (midnight - 6 AM)?`,
      answer: `You may be scheduled to charge during a time when electricity rates are more expensive and outside of SMUD's discounted EV rate time period. The incentive structure of the Managed EV Charging program was designed to offset the possible increased costs that may be incurred by charging during times of day when electricity costs are higher.`,
    },
    {
      question:
        'What if I need the car to charge before its scheduled start time(s)?',
      answer: `The pilot program rules allow you to change your charging schedule whenever you need to, without affecting your program rewards or program status. To learn how to make changes to your charging schedule, visit Optiwatt’s website or app.`,
    },
    {
      question: 'What data are you collecting from my vehicle?',
      answer: `The pilot program gathers charging data by accessing data available from your vehicle. Once you provide permission to gather this charging data through the enrollment process, Optiwatt will provide charging information about your vehicle to SMUD. SMUD uses this information to evaluate the benefits of smart charging.`,
    },
    {
      question:
        'If I have multiple vehicles on my Optiwatt profile, where will the incentives be sent?',
      answer:
        'Incentives will be sent to the account holder that initially established the Optiwatt account and connected the vehicles.',
    },
    {
      question: 'When will I receive my reward payment?',
      answer: `
        <p>After receiving your one-time, initial $150 incentive, the following is the schedule for quarterly reward payments.</p>
        <table style="width:100%; font-size: 10px">
          <tr>
            <th style="font-weight: 600;">Enrollment Date*</th>
            <th style="font-weight: 600;">1st Reward Payment Date</th>
          </tr>
          <tr>
            <td>Jan 1 - Mar 31</td>
            <td>By April 30</td>
          </tr>
          <tr>
            <td>Apr 1 - Jun 30</td>
            <td>By July 31</td>
          </tr>
          <tr>
            <td>Jul 1 - Sept 30</td>
            <td>By Oct 31</td>
          </tr>
          <tr>
            <td>Oct 1 - Dec 31</td>
            <td>By Jan 31</td>
          </tr>
          <tr><td>*Enrollments any time during a quarter will qualify for quarterly payment that quarter and subsequent payments on a quarterly basis</td></tr>
        </table>
        <p></p>
      `,
      html: true,
    },
  ]
  return <Faq name="SMUD" questions={questions} />
}
