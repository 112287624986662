import styled from 'styled-components'

import { Typography as MuiTypography } from '@material-ui/core'

import { spacing } from '@material-ui/system'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'

const Typography = styled(MuiTypography)(spacing)

const useStyles = makeStyles((theme) => ({
  text: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const UsageSectionHeaderTypography = ({
  children,
  className,
  ...restProps
}) => {
  const classes = useStyles()

  return (
    <Typography
      variant="subtitle1"
      className={clsx(classes.text, className)}
      {...restProps}
    >
      {children}
    </Typography>
  )
}

export default UsageSectionHeaderTypography
