import { useNavigation } from '@/app/hooks'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

const HOME_ESTIMATION_ROUTE = '/home-estimations'
export const HOME_ESTIMATION_ROOT = `${HOME_ESTIMATION_ROUTE}/:from*`

export enum HomeEstimationPages {
  Home = '',
  Edit = '/edit',
  ModelNum = '/model-number',
  Wizard = '/wizard',
  Success = '/success',
}

export enum HomeEditReasons {
  Confirmation = 'confirmation',
  TagSubmission = 'tag-submission',
  ModelSubmission = 'model-submission',
  WizardSubmission = 'wizard-submission',
}

export default function useHomeEstimationNavigation(): {
  push: (
    page: HomeEstimationPages,
    reason?: HomeEditReasons,
    nextFrom?: string,
  ) => void
  returnFromSuccess: () => void
} {
  const navigate = useNavigation()
  const { from } = useParams() as { from: string }

  const push = useCallback(
    (
      page: HomeEstimationPages,
      reason?: HomeEditReasons,
      nextFrom?: string,
    ) => {
      let fromParam = from ? `/${from}` : ''
      if (nextFrom) {
        fromParam = `/${nextFrom}`
      }

      if (reason) {
        navigate.push(HOME_ESTIMATION_ROUTE + fromParam + page + `/${reason}`)
      } else {
        navigate.push(HOME_ESTIMATION_ROUTE + fromParam + page)
      }
    },
    [from],
  )

  const returnFromSuccess = useCallback(() => {
    if (!from) {
      navigate.push('/app')
      return
    }

    navigate.push(`/${from}`)
  }, [from])

  return {
    push,
    returnFromSuccess,
  }
}
