import { useEffect, useMemo, useSyncExternalStore } from 'react'

type ListenerType = () => void
type GlobalListenerStoreEntryType = Map<string, ListenerType[]>
type GlobalListenerStoreType = Map<string, GlobalListenerStoreEntryType>
let globalListenerStore: GlobalListenerStoreType = new Map()

export type GlobalDataStoreEntryType<T> = Map<string, T>
export type GlobalDataStoreType = Map<string, GlobalDataStoreEntryType<unknown>>
let globalDataStore: GlobalDataStoreType = new Map()

// Batching mechanism to handle rapid updates
const pendingUpdates = new Map<string, Map<string, unknown>>()
let isBatchingUpdates = false
let timeoutId: ReturnType<typeof setTimeout> | null = null

const flushUpdates = () => {
  if (pendingUpdates.size === 0) return

  isBatchingUpdates = true

  pendingUpdates.forEach((updates, nameSpace) => {
    updates.forEach((data, key) => {
      const externalDataStore = globalDataStore.get(nameSpace) || new Map()
      externalDataStore.set(key, data)
      globalDataStore.set(nameSpace, new Map(externalDataStore))
    })
  })

  globalDataStore = new Map(globalDataStore)

  // Call listeners after all updates are processed
  pendingUpdates.forEach((updates, nameSpace) => {
    updates.forEach((_, key) => {
      callListeners(nameSpace, key)
    })
  })

  pendingUpdates.clear()
  isBatchingUpdates = false
  timeoutId = null
}

const setListenerStore = (
  nameSpace: string,
  key: string,
  listener: ListenerType | ListenerType[],
) => {
  const externalListenerStore =
    globalListenerStore.get(nameSpace) ||
    (new Map() as GlobalListenerStoreEntryType)
  const listeners = externalListenerStore.get(key) || []
  listener = Array.isArray(listener) ? listener : [listener]
  externalListenerStore.set(key, [...listeners, ...listener])
  globalListenerStore.set(nameSpace, new Map(externalListenerStore))
  globalListenerStore = new Map(globalListenerStore)
}

const subscribe = (nameSpace: string, key: string, listener: ListenerType) => {
  setListenerStore(nameSpace, key, listener)
  return () => {
    let listenerStore = globalListenerStore.get(nameSpace)?.get(key) || []
    listenerStore = listenerStore.filter((l) => l !== listener)
    setListenerStore(nameSpace, key, listenerStore)
  }
}

const getSnapshot = <T>(nameSpace: string, key: string) => {
  // Check pending updates first
  if (isBatchingUpdates && pendingUpdates.has(nameSpace)) {
    const pendingValue = pendingUpdates.get(nameSpace)?.get(key)
    if (pendingValue !== undefined) {
      return pendingValue as T
    }
  }

  const externalDataStore = globalDataStore.get(nameSpace)
  if (!externalDataStore) {
    return undefined as unknown as T
  }
  const data = externalDataStore.get(key)
  return data as T
}

const callListeners = (nameSpace: string, key: string) => {
  const listeners = globalListenerStore.get(nameSpace)?.get(key)
  listeners?.forEach((listener) => listener())
}

const setDataStore = <T>(nameSpace: string, key: string, data: T) => {
  // Apply the initial value immediately if it doesn't exist yet
  const externalDataStore = globalDataStore.get(nameSpace) || new Map()
  const exists = externalDataStore.has(key)

  if (!exists) {
    externalDataStore.set(key, data as unknown)
    globalDataStore.set(nameSpace, new Map(externalDataStore))
    globalDataStore = new Map(globalDataStore)
    return
  }

  // Queue updates for existing values
  if (!pendingUpdates.has(nameSpace)) {
    pendingUpdates.set(nameSpace, new Map())
  }

  pendingUpdates.get(nameSpace)?.set(key, data as unknown)

  if (!timeoutId) {
    timeoutId = setTimeout(flushUpdates, 0) // Batch in next microtask
  }
}

/** Function to create a global store
 * @param nameSpace - The namespace of the store - should be unique
 * @returns {useStore, setDataStore} - The useStore hook and the setDataStore function
 */
const createStore = (nameSpace: string) => {
  const externalDataStore = globalDataStore.get(nameSpace) || new Map()
  if (!globalDataStore.has(nameSpace)) {
    globalDataStore.set(nameSpace, externalDataStore)
  }

  /** Function to set the store of this namespace
   * @param key - The key of the store to be updated
   * @param data - The new data to set the store to or function to update the store
   * @returns {void}
   * This function should be used if data needs to be updated outside of the useStore hook
   * This function will call all listeners related to the key to update the ui
   */
  const setStore = <T>(key: string, data: T | ((data: T) => T)): void => {
    if (data instanceof Function) {
      const currentData = getSnapshot<T>(nameSpace, key)
      const newData = data(currentData)
      setDataStore(nameSpace, key, newData)
      return
    }
    setDataStore(nameSpace, key, data)
  }

  /** Hook to use to create a usable store with a signal to update whenever the value is changed
   * @param key - The key of the store - should be unique
   * @param initStore - The initial value of the store
   * @returns {data, set} - The data and the set function
   */
  const useStore = <T>(key: string, initStore: T) => {
    // Initialize store immediately on first call
    useEffect(() => {
      const externalDataStore = globalDataStore.get(nameSpace) || new Map()
      if (!externalDataStore.has(key)) {
        const store = new Map()
        store.set(key, initStore)
        globalDataStore.set(nameSpace, store)
        globalDataStore = new Map(globalDataStore)
        callListeners(nameSpace, key)
      }
    }, [key, initStore])

    const memoizedSubscribe = useMemo(
      () => (listener: ListenerType) => subscribe(nameSpace, key, listener),
      [nameSpace, key],
    )

    const dataFromStore = useSyncExternalStore(
      memoizedSubscribe,
      () => getSnapshot<T>(nameSpace, key),
      () => initStore, // Provide fallback for server rendering
    )

    // Always return a valid value by falling back to initStore if undefined
    const data = dataFromStore !== undefined ? dataFromStore : initStore

    // Cleanup store
    useEffect(() => {
      return () => {
        globalDataStore.get(nameSpace)?.delete(key)
        globalListenerStore.get(nameSpace)?.delete(key)
        callListeners(nameSpace, key)
      }
    }, [nameSpace, key])

    /** Function to set the data of the store
     * @param data - The new data to set the store to or function to update the store
     * @returns {void}
     * Set the store and calls all listeners to update the ui
     */
    const set = (data: T | ((data: T) => T)) => {
      if (data instanceof Function) {
        const currentData = getSnapshot<T>(nameSpace, key) || initStore
        const newData = data(currentData)
        setDataStore(nameSpace, key, newData)
        return
      }
      setDataStore(nameSpace, key, data)
    }

    const store = {
      data,
      set,
    }

    return store
  }

  return { useStore, setStore }
}

export default createStore
