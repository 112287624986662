import { AlertMessageLink } from '@/app/components'
import { ID } from '@/types/model'
import { useLocation } from 'react-router-dom'
import {
  useEventExplanation,
  mockEventExplanation,
} from './useEventExplanation'
import { useAppDispatch, useAppSelector, useMockableViewModel } from '@/hooks'
import { selectNextWithThermostatId } from '@/selectors/thermostatDemandResponseEvents'
import { takeActionOnThermostatDemandResponseEvent } from '@/reducers/thermostatDemandResponseEvents'

type Props = {
  thermostatId: ID
  linkToOptOut: Partial<ReturnType<typeof useLocation>>
  linkToOptIn: Partial<ReturnType<typeof useLocation>>
}

function useLinkTo(props: Props, linkToOptInStatus?: boolean) {
  const location = useLocation()
  return {
    ...location,
    ...(linkToOptInStatus ? props.linkToOptIn : props.linkToOptOut),
  }
}

function useLiveViewModel(props: Props) {
  const dispatch = useAppDispatch()
  const upcomingUnenrolledEventId = useAppSelector((state) => {
    const nextEvent = selectNextWithThermostatId(state, props.thermostatId)
    return nextEvent?.enrolled ? undefined : nextEvent?.id
  })
  const linkTo = useLinkTo(props, upcomingUnenrolledEventId !== undefined)

  const onLinkClick = () => {
    // if the user IS enrolled in an event, we just want to navigate to the
    // event details page where they can opt out, so we only need linkTo.
    if (upcomingUnenrolledEventId === undefined) {
      return
    }
    // if the user ISNT enrolled, we enroll them when they click the link as a side effect.
    // linkTo will take them to a modal that confirms their enrollment.
    dispatch(
      takeActionOnThermostatDemandResponseEvent(
        upcomingUnenrolledEventId,
        'opt-in',
        { optimistic: false },
      ),
    )
  }

  const message = useEventExplanation(props.thermostatId)
  const linkText = upcomingUnenrolledEventId === undefined ? 'Manage' : 'Opt In'

  return {
    message,
    linkTo,
    onLinkClick,
    linkText,
  }
}

function ThermostatDREventAlertMessageView({
  message,
  linkTo,
  onLinkClick,
  linkText,
}: ReturnType<typeof useViewModel>) {
  return (
    <AlertMessageLink
      variant="info"
      linkTo={linkTo}
      onLinkClick={onLinkClick}
      linkText={linkText}
    >
      {message}
    </AlertMessageLink>
  )
}

function useMockViewModel(props: Props) {
  const linkTo = useLinkTo(props)
  const onLinkClick = () => console.log('link clicked')
  return {
    message: mockEventExplanation,
    linkTo,
    onLinkClick,
    linkText: 'Manage',
  }
}

const useViewModel = useMockableViewModel({
  useViewModel: useLiveViewModel,
  useMockViewModel,
})

export default function ThermostatDREventAlertMessage(props: Props) {
  return <ThermostatDREventAlertMessageView {...useViewModel(props)} />
}
