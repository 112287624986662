import { RSAA } from 'redux-api-middleware'
import config from '../config'
import { withAuth } from '../reducers'

export const DISTANCE_UNITS_REQUEST = '@@units/DISTANCE_UNITS_REQUEST'
export const DISTANCE_UNITS_SUCCESS = '@@units/DISTANCE_UNITS_SUCCESS'
export const DISTANCE_UNITS_FAILURE = '@@units/DISTANCE_UNITS_FAILURE'

export const TEMPERATURE_UNITS_REQUEST = '@@units/TEMPERATURE_UNITS_REQUEST'
export const TEMPERATURE_UNITS_SUCCESS = '@@units/TEMPERATURE_UNITS_SUCCESS'
export const TEMPERATURE_UNITS_FAILURE = '@@units/TEMPERATURE_UNITS_FAILURE'

export const updateDistanceUnit = (distanceUnit) => ({
  [RSAA]: {
    endpoint: `${config.apiGateway.URL}/api/current_user/`,
    method: 'PATCH',
    body: JSON.stringify({ distance_preference: distanceUnit }),
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      DISTANCE_UNITS_REQUEST,
      DISTANCE_UNITS_SUCCESS,
      DISTANCE_UNITS_FAILURE,
    ],
  },
})

export const updateTemperatureUnit = (temperatureUnit) => ({
  [RSAA]: {
    endpoint: `${config.apiGateway.URL}/api/current_user/`,
    method: 'PATCH',
    body: JSON.stringify({ temperature_preference: temperatureUnit }),
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      TEMPERATURE_UNITS_REQUEST,
      TEMPERATURE_UNITS_SUCCESS,
      TEMPERATURE_UNITS_FAILURE,
    ],
  },
})
