import { useEffect } from 'react'
import { ResourceRequestState } from '../reducers/utils'
import type { RootState } from '../store'
import { useAppSelector, useAppDispatch } from '.'
import {
  shouldPopulateResource,
  shouldRefreshResource,
} from '../selectors/requests'

export interface ResourceManager<ResourceType> {
  selector: (state: RootState) => ResourceRequestState<ResourceType>
  fetchAction: () => { [key: string]: any }
  onChange?: (resource: ResourceType) => void
  fetchDependencies?: any[]
}

export function useFetchingResourceSelector<ResourceType>({
  selector,
  fetchAction,
  onChange,
  fetchDependencies = [],
}: ResourceManager<ResourceType>): ResourceRequestState<ResourceType> {
  const dispatch = useAppDispatch()
  const resourceRequestState = useAppSelector(selector)

  useEffect(() => {
    if (shouldPopulateResource(resourceRequestState)) {
      dispatch(fetchAction())
    }
  }, [resourceRequestState])

  useEffect(() => {
    if (
      shouldRefreshResource(resourceRequestState) &&
      !shouldPopulateResource(resourceRequestState)
    ) {
      dispatch(fetchAction())
    }
  }, fetchDependencies)

  useEffect(() => {
    if (onChange) {
      onChange(resourceRequestState.resource)
    }
  }, [resourceRequestState.resource])

  return resourceRequestState
}
