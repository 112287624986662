import './styles.scss'
import { Button, Card, Grid, GridItem, Icon, Text } from 'component-library'
import { HTMLAttributes, useEffect, useRef, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import formatClasses from '@/utils/classes/formatClasses'
import React from 'react'
import { getChildrenOnDisplayName } from '@/utils/components'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { Identifiable } from '@/types'
import clsx from 'clsx'

export interface Props
  extends Identifiable<Omit<HTMLAttributes<HTMLDivElement>, 'onChange'>> {
  closeOnOutsideClick?: boolean
  closeOnChange?: boolean
  elevated?: boolean
  initialValue?: Dayjs | string | null
  onClickOutside?: () => void
  onChange: (date: string) => void
  onClose?: () => void
  onOpen?: () => void
  open?: boolean
  resetOnOpen?: boolean
}

interface DatepickerHeaderProps extends Identifiable {
  onLastMonthClick: () => void
  onMonthYearClick: () => void
  onNextMonthClick: () => void
  visibleDate: Dayjs
}

interface DatepickerMonthProps {
  onDateClick: (date: Dayjs) => void
  selectedDate: Dayjs
  visibleDate: Dayjs
}

interface DayOfWeekColumnProps {
  dayOfWeek: DayOfWeek
  month: Dayjs[]
  onDateClick: (date: Dayjs) => void
  selectedDate: Dayjs
  visibleDate: Dayjs
}

type DayOfWeek = 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat' | 'Sun'

function DatepickerHeader(props: DatepickerHeaderProps) {
  const classes = {
    monthYearSelector: formatClasses(['month-year-selector']),
  }

  return (
    <Grid placeContent="space-between" templateRows="34px">
      <button
        className={classes.monthYearSelector}
        data-testing-id="month-year-selector"
        onClick={props.onMonthYearClick}
      >
        {`${props.visibleDate.format('MMMM')} ${props.visibleDate.format(
          'YYYY',
        )}`}
        {/*&nbsp;*/}
        {/*<Icon*/}
        {/*  color="grey-500"*/}
        {/*  name="ChevronRight"*/}
        {/*  rotation={props.mode === 'month-year' ? 90 : 0}*/}
        {/*  size={18}*/}
        {/*/>*/}
      </button>
      <Grid>
        <Button
          data-testing-id="last-month-button"
          id={`${props.id}-last-month-button`}
          onClick={props.onLastMonthClick}
          variant="icon"
        >
          <Icon color="grey-500" name="ChevronLeft" size={18} />
        </Button>
        <Button
          data-testing-id="next-month-button"
          id={`${props.id}-next-month-button`}
          onClick={props.onNextMonthClick}
          variant="icon"
        >
          <Icon color="grey-500" name="ChevronRight" size={18} />
        </Button>
      </Grid>
    </Grid>
  )
}

function DatepickerMonth(props: DatepickerMonthProps) {
  const daysOfWeek = [
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat',
  ] as DayOfWeek[]
  const month = [] as Dayjs[]

  // Create an object for each day of the month -- it keeps track of the date and day of week
  for (let i = 1; i < props.visibleDate.daysInMonth() + 1; i++) {
    month.push(props.visibleDate.set('date', i))
  }

  return (
    <Grid placeContent="space-between">
      {daysOfWeek.map((day, index) => {
        return (
          <DayOfWeekColumn
            dayOfWeek={day}
            month={month}
            onDateClick={props.onDateClick}
            key={index}
            selectedDate={props.selectedDate}
            visibleDate={props.visibleDate}
          />
        )
      })}
    </Grid>
  )
}

function DayOfWeekColumn(props: DayOfWeekColumnProps) {
  const today = dayjs()
  const classes = {
    date: (index: number) =>
      formatClasses([
        'optiwatt-datepicker-day',
        props.visibleDate.year() === today.year() &&
        props.visibleDate.month() === today.month() &&
        index === today.date()
          ? 'current-day'
          : undefined,
        props.selectedDate.year() === props.visibleDate.year() &&
        props.selectedDate.month() === props.visibleDate.month() &&
        props.selectedDate.date() === index
          ? 'selected'
          : undefined,
      ]),
    dayOfWeek: formatClasses(['optiwatt-datepicker-day-of-week']),
  }

  return (
    <Grid
      autoColumns="32px"
      autoRows="32px"
      data-testing-id={`datepicker-${props.dayOfWeek}-column`.toLowerCase()}
      flow="row"
      placeItems="center"
    >
      <Text
        className={clsx(classes.dayOfWeek, 'text-themed-grey-400')}
        variant="body2"
      >
        {props.dayOfWeek}
      </Text>
      {props.month.map((day, index) => {
        // If a day is on Monday, display it in the 'Mon' column
        if (day.format('ddd') === props.dayOfWeek) {
          return (
            <React.Fragment key={index}>
              {
                /* Adds appropriate amount of "blank" days so that months that don't start on Sunday display properly */
                day.date() <= 7 && day.day() < props.month[0].day() ? (
                  <div></div>
                ) : (
                  <></>
                )
              }
              <GridItem
                className={classes.date(index + 1)}
                data-testing-id={`datepicker-${props.visibleDate.format(
                  'MMM',
                )}-${day.date()}-${props.visibleDate.year()}`.toLowerCase()}
                onClick={() => props.onDateClick(day)}
                placeContent="center"
              >
                {day.date()}
              </GridItem>
            </React.Fragment>
          )
        }
      })}
    </Grid>
  )
}

function Datepicker(props: Props) {
  const [internalOpen, setInternalOpen] = useState(props.open)
  const [visibleDate, setVisibleDate] = useState(
    dayjs(props.initialValue ?? undefined),
  )
  const [selectedDate, setSelectedDate] = useState(
    dayjs(props.initialValue ?? undefined),
  )
  const open = props.open ?? internalOpen
  const activatorRef = useRef<HTMLDivElement>(null)
  const datepickerRef = useRef<HTMLDivElement>(null)

  const classes = {
    datepicker: formatClasses([
      'optiwatt-datepicker',
      props.elevated ? 'elevated' : undefined,
    ]),
    doneButton: formatClasses(['done-button']),
    lastMonth: formatClasses(['last-month']),
    monthSelector: formatClasses(['month-selector']),
  }

  function onLastMonthClick() {
    setVisibleDate(visibleDate.subtract(1, 'month'))
  }

  function onNextMonthClick() {
    setVisibleDate(visibleDate.add(1, 'month'))
  }

  function onDateClick(newDate: Dayjs) {
    setSelectedDate(newDate)

    props.onChange(newDate.format('MM/DD/YYYY'))

    if (props.closeOnChange) setInternalOpen(false)
  }

  function onMonthYearClick() {
    // Leaving this for later, if it's needed
  }

  function onOpen() {
    if (internalOpen) {
      setInternalOpen(false)
      props.onClose?.()
    } else {
      setInternalOpen(true)
      props.onOpen?.()
      if (props.resetOnOpen) {
        setSelectedDate(dayjs(props.initialValue ?? undefined))
        setVisibleDate(dayjs(props.initialValue ?? undefined))
      }
      console.log(selectedDate)
    }
  }

  const activator = getChildrenOnDisplayName(props.children, 'Activator')

  useEffect(() => {
    function onOutsideClick(event: any) {
      if (
        !datepickerRef.current?.contains(event.target) &&
        !activatorRef.current?.contains(event.target)
      ) {
        props.onClickOutside?.()
        if (props.closeOnOutsideClick) setInternalOpen(false)
      }
    }

    document.addEventListener('click', onOutsideClick, true)

    return () => {
      document.removeEventListener('click', onOutsideClick, true)
    }
  }, [props.onClickOutside, props.closeOnOutsideClick])

  return (
    <>
      <div
        className="datepicker-activator"
        data-testing-id="datepicker-activator"
        onClick={onOpen}
        ref={activatorRef}
      >
        {activator}
      </div>
      <SwitchTransition mode="out-in">
        <CSSTransition
          appear
          classNames="datepicker"
          key={open ? 'open' : 'closed'}
          timeout={200}
          unmountOnExit
          mountOnEnter
        >
          {open ? (
            <Card
              className={classes.datepicker}
              data-testing-id="datepicker"
              style={props.style}
              ref={datepickerRef}
            >
              <Grid flow="row" gap="10px">
                <DatepickerHeader
                  id={props.id}
                  onLastMonthClick={onLastMonthClick}
                  onNextMonthClick={onNextMonthClick}
                  onMonthYearClick={onMonthYearClick}
                  visibleDate={visibleDate}
                />
                <DatepickerMonth
                  onDateClick={onDateClick}
                  selectedDate={selectedDate}
                  visibleDate={visibleDate}
                />
              </Grid>
            </Card>
          ) : (
            <></>
          )}
        </CSSTransition>
      </SwitchTransition>
    </>
  )
}

function DatepickerActivator(props: HTMLAttributes<HTMLElement>) {
  return <>{props.children}</>
}
DatepickerActivator.displayName = 'Activator'
Datepicker.Activator = DatepickerActivator

export default Datepicker
