import { Box as MuiBox, Grid as MuiGrid } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import { makeStyles } from '@material-ui/styles'
import { Zap, Home, CheckCircle, Info } from 'react-feather'
import HomeComparisonViz from './HomeComparisonViz'
import CircledContent from '../../../CircledContent'
import { UsageSectionBodyTypography } from '../../UsageSectionTypography'
import ColorBoxedInfoBox from '../../../ColorBoxedInfoBox'

const Box = styled(MuiBox)(spacing)
const Grid = styled(MuiGrid)(spacing)

const useStyles = makeStyles((theme) => ({
  collectingDataIcon: {
    color: theme.palette.blue.main,
    verticalAlign: 'middle',
  },
  circle: {
    backgroundColor: theme.palette.blue.background,
    padding: theme.spacing(3),
  },
  iconConnector: {
    width: theme.spacing(12),
    display: 'inline-block',
    verticalAlign: 'middle',
    borderTop: `3px dashed ${theme.palette.blue.main}`,
  },
  successCheckIcon: {
    color: theme.palette.green.main,
    verticalAlign: 'middle',
  },
  successText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  infoBoxText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  skelly: {
    borderRadius: theme.border.radius,
  },
}))

const HomeUsageState = {
  hasMultipleMonths: 'hasMultipleMonths',
  hasSingleMonth: 'hasSingleMonth',
  hasNoData: 'hasNoData',
  planIsPending: 'planIsPending',
}

const getHomeUsageState = (
  homeUsage,
  lastHomeUsage,
  isLoading,
  planIsPending,
) => {
  if (planIsPending) {
    return HomeUsageState.planIsPending
  }
  if (isLoading) {
    return HomeUsageState.hasNoData
  }
  return {
    [String([true, true])]: HomeUsageState.hasMultipleMonths,
    [String([true, false])]: HomeUsageState.hasSingleMonth,
    [String([false, true])]: HomeUsageState.hasNoData,
    [String([false, false])]: HomeUsageState.hasNoData,
  }[String([!!homeUsage, !!lastHomeUsage])]
}

const CollectingDataIndicator = () => {
  const classes = useStyles()

  return (
    <Box>
      <CircledContent className={classes.circle}>
        <Home className={classes.collectingDataIcon} />
      </CircledContent>
      <hr className={classes.iconConnector} />
      <CircledContent className={classes.circle}>
        <Zap className={classes.collectingDataIcon} />
      </CircledContent>
    </Box>
  )
}

const SuccessfullyConnectedIndicator = () => {
  const classes = useStyles()

  return (
    <Grid container justifyContent="center" align="center" spacing={4}>
      <Grid item xs={12}>
        <CollectingDataIndicator />
      </Grid>
      <Grid item xs={12}>
        <UsageSectionBodyTypography className={classes.successText}>
          <CheckCircle className={classes.successCheckIcon} size={'1.5rem'} />
          &nbsp; Great! You've sucessfully connected your utility
        </UsageSectionBodyTypography>
      </Grid>
    </Grid>
  )
}

const TopHomeComparisonView = ({
  homeUsageState,
  homeUsage,
  carUsage,
  maxCost,
  isLoading,
}) => {
  const classes = useStyles()

  if (isLoading) {
    return (
      <Skeleton
        className={classes.skelly}
        animation="wave"
        variant="rect"
        width={'100%'}
        height={40}
      />
    )
  }

  switch (homeUsageState) {
    case HomeUsageState.hasMultipleMonths:
    case HomeUsageState.hasSingleMonth:
      return (
        <HomeComparisonViz
          title={homeUsage.end}
          {...{ homeUsage, carUsage, maxCost, isLoading }}
        />
      )
    case HomeUsageState.hasNoData:
    case HomeUsageState.planIsPending:
      return <SuccessfullyConnectedIndicator />
    default:
      return null
  }
}

const BottomHomeComparisonView = ({
  homeUsageState,
  lastHomeUsage,
  lastCarUsage,
  maxCost,
  isLoading,
  planIsPending,
}) => {
  const classes = useStyles()

  if (isLoading) {
    return null
  }

  switch (homeUsageState) {
    case HomeUsageState.hasMultipleMonths:
      return (
        <HomeComparisonViz
          title={lastHomeUsage.end}
          {...{
            homeUsage: lastHomeUsage,
            carUsage: lastCarUsage,
            maxCost,
            isLoading,
          }}
        />
      )
    case HomeUsageState.hasSingleMonth:
      return (
        <ColorBoxedInfoBox
          info="You will see a comparison between two months once we have enough data."
          Icon={Info}
          classes={{ info: classes.infoBoxText }}
        />
      )
    case HomeUsageState.hasNoData:
      return (
        <ColorBoxedInfoBox
          info="Energy usage may take up to a month to populate, we will notify you when it’s ready."
          Icon={Info}
          classes={{ info: classes.infoBoxText }}
        />
      )
    case HomeUsageState.planIsPending:
      return (
        <ColorBoxedInfoBox
          info="Your home usage will appear here once your utility plan has been added."
          Icon={Info}
          classes={{ info: classes.infoBoxText }}
        />
      )
    default:
      return null
  }
}

const HomeComparison = ({
  homeUsage,
  carUsage,
  lastHomeUsage,
  lastCarUsage,
  isLoading,
  headerRender,
  planIsPending,
}) => {
  const classes = useStyles()

  const homeUsageState = getHomeUsageState(
    homeUsage,
    lastHomeUsage,
    isLoading,
    planIsPending,
  )

  const maxCost = Math.max(
    (lastCarUsage?.cost || 0) + (lastHomeUsage?.cost || 0),
    (carUsage?.cost || 0) + (homeUsage?.cost || 0),
  )

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12} mb={2}>
          {headerRender()}
        </Grid>
        <Grid item xs={12} mb={2}>
          <TopHomeComparisonView
            homeUsageState={homeUsageState}
            homeUsage={homeUsage}
            carUsage={carUsage}
            maxCost={maxCost}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <BottomHomeComparisonView
            homeUsageState={homeUsageState}
            lastHomeUsage={lastHomeUsage}
            lastCarUsage={lastCarUsage}
            maxCost={maxCost}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default HomeComparison
