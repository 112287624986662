import { AlertMessage } from '@/app/components'
import { Text } from '@/components'
import { useAppSelector } from '@/hooks'
import {
  selectBatteryTargetETA,
  selectChargeForecast,
} from '@/selectors/chargeForecast'
import dayjs from 'dayjs'

export default function NonStopChargeAvailable({
  requestChargeNowEvent,
}: {
  requestChargeNowEvent: () => void
}) {
  const batteryTargetETA = useAppSelector(selectBatteryTargetETA)
  const batteryTarget = useAppSelector(
    (state) => selectChargeForecast(state)?.max_battery_target,
  )

  return (
    <AlertMessage variant="info">
      Your car is currently smart charging.
      {batteryTargetETA &&
        batteryTarget &&
        ` Your battery will reach ${Math.round(batteryTarget)}% by ${dayjs(
          batteryTargetETA,
        ).format('h:mm A')} `}
      <div style={{ marginTop: '12px' }}>
        <Text variant="link" onClick={requestChargeNowEvent}>
          Charge nonstop
        </Text>
      </div>
    </AlertMessage>
  )
}
