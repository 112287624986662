import { Button } from '@/components'
import useExternalNavigation from '@/app/hooks/useExternalNavigation'
import playStore from '@/app/img/play-store.svg'
import appStore from '@/app/img/app-store.svg'
import useStoreLinks from '@/app/hooks/useStoreLinks/useStoreLinks'

type Props = {
  store: 'apple' | 'google'
  className?: string
}

export default function AppStoreButton(props: Props) {
  const { externallyNavigate } = useExternalNavigation()
  const isApple = props.store === 'apple'

  const { androidUrl, appleUrl } = useStoreLinks()

  const onClick = () => {
    const url = isApple ? appleUrl : androidUrl

    externallyNavigate(url)
  }

  const imgSrc = isApple ? appStore : playStore

  return (
    <Button
      className={props.className}
      id="app-store-button"
      variant="click-wrapper"
      onClick={onClick}
    >
      <img
        alt={`${isApple ? 'app' : 'play'}-store-button`}
        className="w-[100%]"
        src={imgSrc}
      />
    </Button>
  )
}
