import './styles.scss'
import formatClasses from '../../utils/classes/formatClasses'
import { ForwardedRef, forwardRef, HTMLAttributes } from 'react'
import { TestingProps } from '@/types/testingProps'

type GridItemColumnString =
  | 'auto'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
type GridItemColumnNumber = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
type GridItemColumnObject = {
  xs?: GridItemColumnString | GridItemColumnNumber
  sm?: GridItemColumnString | GridItemColumnNumber
  md?: GridItemColumnString | GridItemColumnNumber
  lg?: GridItemColumnString | GridItemColumnNumber
  xl?: GridItemColumnString | GridItemColumnNumber
}

export interface Props extends HTMLAttributes<HTMLDivElement>, TestingProps {
  columns?: GridItemColumnString | GridItemColumnNumber | GridItemColumnObject
  fluid?: boolean
  placeContent?: string
  placeItems?: string
  placeSelf?: string
}

export type Ref = ForwardedRef<HTMLDivElement>

function GridItem(props: Props, ref?: ForwardedRef<HTMLDivElement>) {
  const classes = () => {
    const computedClasses: string[] = []

    switch (typeof props.columns) {
      case 'string': {
        if (props.columns === 'auto') computedClasses.push('columns-auto')
        else computedClasses.push(`columns-${props.columns}`)
        break
      }
      case 'number':
        computedClasses.push(`columns-${props.columns}`)
        break
      case 'object': {
        const keys = Object.keys(props.columns)
        const values = Object.values(props.columns)

        for (let i = 0; i < keys.length; i++) {
          if (
            keys[i] === 'xs' &&
            typeof values[i] === 'number' &&
            Number(values[i]) > 4
          )
            values[i] = 4
          if (
            keys[i] === 'sm' &&
            typeof values[i] === 'number' &&
            Number(values[i]) > 8
          )
            values[i] = 8

          computedClasses.push(`columns-${keys[i]}-${values[i]}`)
        }
        break
      }
      case 'undefined':
        break
    }

    return formatClasses(['grid-item', ...computedClasses, props.className])
  }

  const styles = {
    placeContent: props?.placeContent,
    placeItems: props?.placeItems,
    placeSelf: props?.placeSelf,
    width: props.fluid ? '100%' : undefined,
    ...props.style,
  }

  return (
    <div
      className={classes()}
      data-testing-id={props['data-testing-id']}
      ref={ref}
      style={styles}
      onClick={props?.onClick}
    >
      {props.children}
    </div>
  )
}

export default forwardRef(GridItem)
