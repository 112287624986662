const PERMISSIONS_URL = process.env.REACT_APP_TESLA_FLEET_ACCOUNT_URL as string

export default {
  statusText: {
    connecting: 'Hold tight...we’re finishing up your connection',
    connected: 'Great! You successfully connected your vehicle',
    failed: 'Oh no! Something went wrong. Please go back and try again',
  },
  permissionsErrorTitle: 'Permissions Required!',
  permissionsErrorText: `Optiwatt requires the Vehicle Information, Vehicle Commands, and Vehicle Charging Management permissions to function. To enable them:
  <br /><br />
  Step 1. <a href="${PERMISSIONS_URL}" target="_blank">Click here</a> to revoke Optiwatt's access.
  <br/><br/>
  Step 2. After revoking your permissions, Tesla will redirect you to re-connect your vehicle. If you were not redirected, <a href="/connect-vehicle">click here</a>`,
  cancelledLoginErrorText:
    'Looks like you cancelled the login process. Please try again.',
}
