import { FordCar, SmartcarCar, TeslaCar } from '@/types/vehicle'
import { isTeslaCar, isFordCar } from '.'

const CORE_AVAILABLE_TESLA_SCOPES = [
  'vehicle_charging_cmds',
  'vehicle_device_data',
  'vehicle_location',
] as const

function teslaMissingCoreAvailablePermissions(car: TeslaCar) {
  return CORE_AVAILABLE_TESLA_SCOPES.some((p) =>
    car.fleet_scopes.missing.includes(p),
  )
}

/**
 * A core permission is considered available if it is a permission the user
 * can enable. Some are not, for example, smartcar connections do not
 * have the notion of permissions. It is all or nothing.
 * Tesla, on the other hand, has the notion of permissions that a user selects.
 *
 * @returns true if the car is missing any of the core available permissions.
 */
export function carMissingCoreAvailablePermissions(
  car: TeslaCar | FordCar | SmartcarCar,
) {
  if (isTeslaCar(car)) {
    return teslaMissingCoreAvailablePermissions(car)
  } else {
    // core permissions are not applicable to non-tesla cars at this time.
    // permissions are all or nothing on other platforms
    return false
  }
}

function teslaCanControlClimate(car: TeslaCar) {
  return !car.fleet_scopes.missing.includes('vehicle_cmds')
}

export function carCanControlClimate(car: TeslaCar | FordCar | SmartcarCar) {
  if (isTeslaCar(car)) {
    return teslaCanControlClimate(car)
  } else if (isFordCar(car)) {
    // ford can control climate and doesnt have granular permissions
    return true
  }
  return false
}
