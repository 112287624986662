export default {
  palette: {
    blue: {
      main: '#426FFB',
      contrastText: '#FFF',
      background: '#ECF1FF',
    },
    purple: {
      main: '#AF4BFB',
      dark: '#8d05f4',
      contrastText: '#FFF',
    },
    green: {
      main: '#00D73F',
      contrastText: '#FFF',
    },
  },
  border: {
    radius: '10px',
  },
}
