import { GasCarConversion } from '../types/vehicle/'
import {
  combine,
  awaitResponse,
  succeed,
  fail,
  savePage,
  Action,
  RequestState,
} from './utils'
import { Get } from '../actions/gasCarConversion'

interface State extends RequestState {
  searchResults: GasCarConversion[] | null
}

const initialState: State = {
  searchResults: null,
  isLoading: false,
  errors: {},
}

export default (state = initialState, action: Action): State => {
  switch (action.type) {
    case Get.Success:
      return combine(state, action, succeed, savePage('searchResults'))
    case Get.Request:
      return combine(state, action, awaitResponse)
    case Get.Failure:
      return combine(state, action, fail)
    default:
      return state
  }
}
