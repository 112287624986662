import { Referral } from '../types/referral'
import { createResourceCollection } from '../request'

export const referralsCollection = createResourceCollection<Referral>({
  name: 'referrals',
  apiConfig: {
    path: 'referrals',
  },
  selector: (state) => state.referrals,
})

const referralsReducer = referralsCollection.slice.reducer

export default referralsReducer
