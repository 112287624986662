import { Button } from '@/components'
import { useAppDispatch } from '@/hooks'
import { logEvent } from '@/logging'
import { chargeNowEventCollection } from '@/reducers/chargeNowEvents'
import {
  chargeForecastCollection,
  chargesCollection,
} from '@/reducers/chargeRelatedCollections'

type ChargeButtonViewModelProps = {
  vehicleChargerId?: number
  vehicleId?: number
  deviceType: 'vehicle' | 'vehicle_charger'
}

function useViewModel({
  vehicleChargerId,
  deviceType,
  vehicleId,
}: ChargeButtonViewModelProps) {
  const id = vehicleChargerId ?? vehicleId
  const { data } = chargeForecastCollection.useFetch({
    params: {
      charger_id: vehicleChargerId,
      vehicle_id: vehicleId,
    },
  })
  const chargeForecast = data.find(
    (item) => item.vehicle_id === id || item.charger_id === id,
  )

  const { refetch: refetchCharges } = chargesCollection.useFetch({
    params: {
      charger_id: vehicleChargerId,
      vehicle_id: vehicleId,
    },
  })

  const { data: chargeNowEvents, refetch: refetchChargeNowEvents } =
    chargeNowEventCollection.useFetch(`${deviceType}/${id}/charge_now`)

  const chargeNowEventStatus = chargeForecast?.charge_now_status
  const dispatch = useAppDispatch()

  const startChargeNow = () => {
    dispatch(
      chargeNowEventCollection.actions.create(
        {},
        {
          pathSegments: [`${deviceType}/${id}/charge_now`],
        },
      ),
    ).then(async () => {
      await dispatch(chargeForecastCollection.actions.invalidate())
      refetchChargeNowEvents()
      refetchCharges()
    })
  }

  const stopChargeNow = () => {
    const chargeNowEvent = chargeNowEvents?.[0]

    if (!chargeNowEvent) {
      return
    }

    dispatch(
      chargeNowEventCollection.actions.update(
        `${deviceType}/${id}/charge_now/${chargeNowEvent.id}/`,
        { complete: true },
      ),
    ).then(async () => {
      await dispatch(chargeForecastCollection.actions.invalidate())
      refetchChargeNowEvents()
      refetchCharges()
    })
  }

  const chargeNowButtonAction = () => {
    if (chargeNowEventStatus === 'startable') {
      logEvent('chargeforecast_chargenow_start')
      startChargeNow()
    } else if (chargeNowEventStatus === 'boostable') {
      logEvent('chargeforecast_chargenow_boost')
      startChargeNow()
    } else if (chargeNowEventStatus === 'stoppable') {
      logEvent('chargeforecast_chargenow_stop')
      stopChargeNow()
    }
  }

  let buttonText = ''
  if (chargeNowEventStatus === 'startable') {
    buttonText = 'Start Charging'
  } else if (chargeNowEventStatus === 'boostable') {
    buttonText = 'Charge non-stop'
  } else if (chargeNowEventStatus === 'stoppable') {
    buttonText = 'Stop Charging'
  }

  const show = ['startable', 'boostable', 'stoppable'].includes(
    chargeNowEventStatus ?? '',
  )

  return { chargeNowButtonAction, buttonText, show }
}

export function ChargeNowButton(props: ChargeButtonViewModelProps) {
  const { chargeNowButtonAction, buttonText, show } = useViewModel(props)

  if (!show) {
    return null
  }

  return (
    <Button
      id="charge-forecast-charge-now-button"
      variant="primary-subtle"
      onClick={chargeNowButtonAction}
    >
      {buttonText}
    </Button>
  )
}
