import { selectVehicleById } from '@/selectors'
import { useSelector } from 'react-redux'
import {
  convertGallonsToLiters,
  convertMilesToKilometers,
  convertMPGToLPK,
} from '../../utils/conversionUtilities'

const DEFAULT_DISTANCE_UNIT = 'mi'
const KILOMETERS = 'kilometers'
const MILES = 'miles'
const GALLONS = 'gallons'
const LITERS = 'liters'
const MPG = 'mpg'
const LPK = 'L/100km'

/**
 *
 * @returns {['miles' | 'kilometers']} distanceUnit
 */
export function useDistanceUnit() {
  const currentUser = useSelector((state) => state.user.user)
  const distanceUnit =
    (currentUser?.profile?.distance_preference ?? 'mi') ===
    DEFAULT_DISTANCE_UNIT
      ? MILES
      : KILOMETERS
  return [distanceUnit]
}

export function useOdometerWithUnits(vehicleId) {
  const [distanceUnit] = useDistanceUnit()
  const selectedVehicle = useSelector((state) =>
    selectVehicleById(state, vehicleId),
  )
  const distanceValue =
    distanceUnit === KILOMETERS
      ? Math.round(convertMilesToKilometers(selectedVehicle?.car?.odometer), 0)
      : Math.round(selectedVehicle?.car?.odometer, 0)

  return [distanceValue, distanceUnit]
}

export function useGasVolumeWithUnits(vehicleId) {
  const currentUser = useSelector((state) => state.user.user)
  const selectedVehicle = useSelector((state) =>
    selectVehicleById(state, vehicleId),
  )

  const volumeUnit =
    (currentUser?.profile?.distance_preference ?? 'mi') ===
    DEFAULT_DISTANCE_UNIT
      ? GALLONS
      : LITERS
  const volumeInGallons =
    selectedVehicle.environmental_impact?.gasoline_gallons_saved
  const volumeValue =
    volumeUnit === LITERS
      ? convertGallonsToLiters(volumeInGallons)
      : volumeInGallons

  return [volumeValue, volumeUnit]
}

export function useGasConversionEfficiencyWithUnits(vehicleId) {
  const currentUser = useSelector((state) => state.user.user)
  const selectedVehicle = useSelector((state) =>
    selectVehicleById(state, vehicleId),
  )

  const efficiencyUnit =
    (currentUser?.profile?.distance_preference ?? 'mi') ===
    DEFAULT_DISTANCE_UNIT
      ? MPG
      : LPK
  const efficiencyValue =
    efficiencyUnit === LPK
      ? convertMPGToLPK(
          selectedVehicle?.car?.car_model?.gas_car_conversion?.gas_car_mpg,
        )
      : selectedVehicle?.car?.car_model?.gas_car_conversion?.gas_car_mpg

  return [efficiencyValue, efficiencyUnit]
}
