import { createResourceCollection } from '../request'
import { ResourceModel } from '../request/types'

// Define the ZendeskAuth type
export interface ZendeskAuth extends ResourceModel {
  id: string
  jwt: string
}

// Create the Zendesk auth resource collection
export const zendeskAuthCollection = createResourceCollection<ZendeskAuth>({
  name: 'zendeskAuth',
  apiConfig: {
    path: 'zendesk/auth',
  },
  selector: (state) => state.zendeskAuth,
})
