import { useEffect, useState } from 'react'
import MonthPlan from './MonthPlan'
import WeekPlan from './WeekPlan'
import { getBlankPlan } from './PlansGraph'
import { useDispatch, useSelector } from 'react-redux'
import Questions from './Questions'
import { getValidPlans } from './Plan'
import { uploadPlans } from '../../../../actions/plans'

export default function SelectManualPlan({
  onCancel,
  onComplete = () => {},
  ...restProps
}) {
  const {
    editNewWeekRates,
    form,
    plans,
    errorMessage: error,
  } = useSelector((state) => state.manualPlans)
  const dispatch = useDispatch()
  const [errorMessage, setErrorMessage] = useState(error)

  useEffect(() => {
    setErrorMessage(error)
  }, [setErrorMessage, error])

  function handleSuccess(plans) {
    let validPlans = getValidPlans(plans, form.changingWeekdays)
    if (validPlans) {
      dispatch(uploadPlans(validPlans))
    } else {
      setErrorMessage('Missing rates or months. Please try again or contact us')
    }
  }

  if (!form.completed) {
    return (
      <Questions
        onCancel={onCancel}
        errorMessage={errorMessage}
        {...restProps}
      />
    )
  }

  if (editNewWeekRates || !form.changingMonths) {
    return (
      <WeekPlan
        plan={getBlankPlan()}
        handleSuccess={handleSuccess}
        errorMessage={errorMessage}
        onComplete={onComplete}
        {...restProps}
      />
    )
  }

  for (let i = 0; i < plans.length; i++) {
    let plan = plans[i]
    if (plan.editRates) {
      return (
        <WeekPlan
          plan={plan}
          handleSuccess={handleSuccess}
          errorMessage={errorMessage}
          onComplete={onComplete}
          {...restProps}
        />
      )
    }
  }

  return (
    <MonthPlan
      handleSuccess={handleSuccess}
      errorMessage={errorMessage}
      onComplete={onComplete}
    />
  )
}
