import React, { ReactNode } from 'react'

export default function getChildrenOnDisplayName(
  children: JSX.Element | ReactNode,
  displayName: string,
) {
  return React.Children.map(children, (child) => {
    // if child is not a react element, return null
    if (!React.isValidElement(child) || typeof child.type === 'string') {
      return null
    }
    const type = child.type as React.ComponentType
    return type.displayName === displayName ? child : null
  })
}

export function getChildOnDisplayName(
  children: JSX.Element | ReactNode,
  displayName: string,
) {
  const childrenOnDisplayName = getChildrenOnDisplayName(children, displayName)
  return childrenOnDisplayName && childrenOnDisplayName.length > 0
    ? childrenOnDisplayName?.[0]
    : null
}
