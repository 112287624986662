import Dashboard from './dashboard'
import { useAppSelector } from '../../../hooks'
import './styles.scss'
import useIsMobile from '../../../authenticated/hooks/useIsMobile'
import { useContext, useEffect } from 'react'
import { BreakpointContext } from '../../../context'
import PageLoader from '../../../authenticated/components/layout/PageLoader'
import { surveyResponsesCollection } from '@/reducers/surveyResponses'
import SurveyObserver from '@/app/components/SurveyObserver'
import { updateRouteOnMobile } from '@/utils/messageUtils'

function useMobileSpecificStyles() {
  const isMobile = useIsMobile()
  const breakpoint = useContext(BreakpointContext)
  // Add mobile-specific styles
  useEffect(() => {
    const root = document.querySelector('#root')

    if (isMobile && !root?.classList.contains('mobile'))
      return root?.classList.add('mobile')

    root?.classList.remove('mobile')
  }, [isMobile || breakpoint.mdAndDown])
}

export default function App() {
  useMobileSpecificStyles()

  const onboardingSurveyLoading = useAppSelector((state) => {
    const fetchQueryStates =
      surveyResponsesCollection.selectors.queryState.select(state, {
        method: 'GET',
      })
    return fetchQueryStates.every(
      (queryState) =>
        queryState.status === 'loading' || queryState.status === 'idle',
    )
  })

  // prevents screen flicker when switching to survey
  const surveyPending = useAppSelector((state) => {
    const surveyNames = [
      'Onboarding Survey 1',
      'Tesla Fleet Auth Survey 1',
      'Feedback Survey',
      'Heard From 1',
    ]
    const allResponses = surveyResponsesCollection.selectors.selectAll(state)
    const surveyResponses = allResponses.filter((res) =>
      surveyNames.includes(res.survey.name),
    )
    return surveyResponses.some((res) => res?.pending_response)
  })

  useEffect(() => {
    // needed for android since route events don't always get triggered correctly
    updateRouteOnMobile('/app')
  }, [])

  if (onboardingSurveyLoading) {
    return <PageLoader />
  }

  return (
    <>
      <SurveyObserver
        observedSurveys={[
          'Onboarding',
          'TeslaFleetAuth',
          'Feedback',
          'HeardFrom',
        ]}
      />
      {!surveyPending && <Dashboard />}
    </>
  )
}
