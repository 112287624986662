import { ChargeSchedule } from '../chargeSchedule'
import { ID } from '../model'

export type VehicleChargerChargeSchedule = ChargeSchedule & {
  charger_id: ID
}

export function isVehicleChargerChargeSchedule(
  schedule: ChargeSchedule & { charger_id?: ID | null },
): schedule is VehicleChargerChargeSchedule {
  return 'charger_id' in schedule && schedule.charger_id !== null
}
