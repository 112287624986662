import './styles.scss'
import {
  BooleanInput,
  NumberInput,
  Question,
  SelectInput,
} from 'app-components'
import { Text } from '../../../../components'
import { Chip, Datepicker, Grid } from 'component-library'
import { useContext, useState } from 'react'
import { Questionnaire } from 'app-components'
import {
  QuestionnaireButtonType,
  QuestionnairePageValue,
} from '@/app/components/Questionnaire/Questionnaire'
import { BreakpointContext } from '@/context'
import dayjs from 'dayjs'
import Modal from '@/components/Modal'
import Button from '@/components/Button/Button'
import { useAppSelector } from '@/hooks'
import AddressSelector from '@/app/components/AddressSelector/AddressSelector'
import { useOnboardingSurveyResponses } from './utils'

export interface Props {
  includeChargerQuestion: boolean
  onSubmit: (responses: QuestionnairePageValue[]) => void
  setShowSkipModalId: () => void
  showSkipModalId: string | null
  questionsToSkip: OnboardingQuestionnairePageKey[]
  isLoading: boolean
  isSubmitting: boolean
}

interface QuestionnairePageProps {
  onChange?: (event?: unknown) => void
}

export function HomeAddress(props: QuestionnairePageProps) {
  const home = useAppSelector((state) => state.homes.selectedHome)

  return (
    <Question title="Where do you consume energy?">
      <Text variant="body2">
        Enter your address or select your location on the map to find rebate
        programs available in your area, automatically control home charging,
        and much more.
      </Text>
      <AddressSelector
        addresses={[]}
        onAddressChange={(addr) => {
          props.onChange?.(addr.place_id)
        }}
        initialAddress={home?.formatted_address}
      />
    </Question>
  )
}

function NumberOfEVs(props: QuestionnairePageProps) {
  const { getResponseByKey } = useOnboardingSurveyResponses()
  const numberOfEVsResponse = (getResponseByKey('numberOfEVs') ?? 0) as number

  const [sentTeslaFlowRequest, setSentTeslaFlowRequest] =
    useState<boolean>(false)

  function onChange(event: unknown) {
    props.onChange?.(event)

    const counter = Number.parseInt(event as string, 0)
    if (counter > 0 && !sentTeslaFlowRequest) {
      setSentTeslaFlowRequest(true)
    }
  }

  return (
    <Question title="How many EVs do you own?">
      <NumberInput
        initialValue={numberOfEVsResponse}
        range={[0, 99]}
        onChange={onChange}
      />
    </Question>
  )
}

function AwaitingEVDelivery(props: QuestionnairePageProps) {
  const breakpoint = useContext(BreakpointContext)
  const { getResponseByKey } = useOnboardingSurveyResponses()
  const thisMonth = dayjs().endOf('month').format('MM/DD/YYYY')
  const nextMonth = dayjs().add(1, 'month').endOf('month').format('MM/DD/YYYY')
  const awaitingEVDeliveryResponse = getResponseByKey('awaitingEVDelivery') as
    | { deliveryDate: string | null; awaitingDelivery: boolean }
    | undefined
  const [awaitingDelivery, setAwaitingDelivery] = useState<boolean | null>(
    awaitingEVDeliveryResponse?.awaitingDelivery ?? null,
  )
  const deliveryDate = awaitingEVDeliveryResponse?.deliveryDate ?? null
  const [datePickerDeliveryDate, setDatePickerDeliveryDate] = useState<
    string | null
  >(
    deliveryDate &&
      deliveryDate !== thisMonth &&
      deliveryDate !== nextMonth &&
      deliveryDate !== 'Who knows?'
      ? deliveryDate
      : null,
  )
  const expectedDeliveryOptions = [
    { label: 'This month', date: thisMonth },
    {
      label: 'Next month',
      date: nextMonth,
    },
    {
      value: (
        <Datepicker
          id="expected-delivery-datepicker"
          closeOnChange
          closeOnOutsideClick
          elevated
          initialValue={datePickerDeliveryDate}
          onChange={onDatepickerChange}
          resetOnOpen={datePickerDeliveryDate === null}
          style={{
            transformOrigin: breakpoint.mdAndUp ? 'top right' : 'top left',
            right: breakpoint.mdAndUp ? 0 : -177,
          }}
        >
          <Datepicker.Activator>
            <Chip
              data-testing-id="datepicker-chip-toggle"
              onClick={() => props.onChange?.([true, datePickerDeliveryDate])}
              style={{ fontVariantNumeric: 'tabular-nums' }}
            >
              {datePickerDeliveryDate ?? 'Select a date'}
            </Chip>
          </Datepicker.Activator>
        </Datepicker>
      ),
    },
    { label: 'Who knows at this point?', date: 'Who knows?' },
  ]

  function onAwaitingDeliveryChange(event: boolean | null) {
    setAwaitingDelivery(event)

    if (event) props.onChange?.([event, null])
    else props.onChange?.([event])
  }

  function onExpectedDeliverySelectChange(event: number | null) {
    if (event === null) {
      props.onChange?.([true, null])
      return
    }

    const selectOptionAssociatedDate = expectedDeliveryOptions[event]?.date
    if (selectOptionAssociatedDate === undefined) {
      return
    }
    props.onChange?.([true, selectOptionAssociatedDate])
  }

  function onDatepickerChange(date: string) {
    setDatePickerDeliveryDate(date)
    props.onChange?.([true, date])
  }

  return (
    <Grid flow="row" gap="32px" templateRows="auto">
      <Question title="Are you awaiting EV delivery?">
        <BooleanInput
          initialValue={awaitingDelivery}
          onChange={onAwaitingDeliveryChange}
        />
      </Question>
      {awaitingDelivery ? (
        <Question
          data-testing-id="expected-delivery"
          title="When are you expecting it?"
        >
          <SelectInput
            onChange={onExpectedDeliverySelectChange}
            options={expectedDeliveryOptions.map(
              (option) => option.label ?? option,
            )}
            initialValue={expectedDeliveryOptions.findIndex(
              (option) =>
                option.date === awaitingEVDeliveryResponse?.deliveryDate ||
                option.value?.props.initialValue ===
                  awaitingEVDeliveryResponse?.deliveryDate,
            )}
            toggleable={false}
          />
        </Question>
      ) : (
        <></>
      )}
    </Grid>
  )
}

function OwnsSmartCharger(props: QuestionnairePageProps) {
  const { getResponseByKey } = useOnboardingSurveyResponses()
  const ownsSmartChargerResponse = getResponseByKey('ownsSmartCharger') as
    | boolean
    | undefined
  const [ownsSmartCharger, setOwnsASmartCharger] = useState<
    boolean | undefined
  >(ownsSmartChargerResponse)

  function onChange(event: boolean | null) {
    props.onChange?.(event)
    setOwnsASmartCharger(event ?? false)
  }

  return (
    <Question title="Do you own a smart charger?">
      <BooleanInput initialValue={ownsSmartCharger} onChange={onChange} />

      {ownsSmartCharger === undefined ? (
        <></>
      ) : (
        <>
          {!ownsSmartCharger ? (
            <div>
              <Text variant="body2">
                No problem! You can always add one later, from the Home or
                Profile tab.
              </Text>
              <Text variant="body2" style={{ paddingTop: 16 }}>
                Smart chargers are not required for program participation.
              </Text>
            </div>
          ) : (
            <div className="flex flex-col gap-4">
              <Text variant="body2">
                Great! We look forward to providing you with control and
                insights for your charger.
              </Text>
            </div>
          )}
        </>
      )}
    </Question>
  )
}

function OwnsSmartThermostat(props: QuestionnairePageProps) {
  const { getResponseByKey } = useOnboardingSurveyResponses()
  const ownsSmartThermostatResponse = getResponseByKey(
    'ownsSmartThermostat',
  ) as boolean | undefined
  const [ownsASmartThermostat, setOwnsASmartThermostat] = useState<
    boolean | undefined
  >(ownsSmartThermostatResponse)

  function onChange(event: boolean | null) {
    props.onChange?.(event)
    setOwnsASmartThermostat(event ?? undefined)
  }

  return (
    <Question title="Do you own a smart thermostat?">
      <BooleanInput initialValue={ownsASmartThermostat} onChange={onChange} />
      {ownsASmartThermostat === false ? (
        <div>
          <Text variant="body2">
            No problem! You can always add one later, from the Home or Profile
            tab.
          </Text>
          <Text variant="body2" style={{ paddingTop: 16 }}>
            Smart thermostats are not required for program participation.
          </Text>
        </div>
      ) : null}
    </Question>
  )
}

function SkipAddressModal({
  onSkipClick,
  onSkipClose,
}: {
  onSkipClick: () => void
  onSkipClose: () => void
}) {
  return (
    <Modal id="onboarding-skip-address-modal" open={true} onClose={onSkipClose}>
      <Modal.Title>
        <Text variant="h3">Are you sure you want to skip?</Text>
      </Modal.Title>
      <div className="p-2 flex flex-col gap-4">
        <Text variant="body2">
          Some utilities offer rebate programs of over $300 annually in your
          area, and require an address to determine eligibility.
        </Text>
        <div className="flex flex-row gap-4">
          <Button id="onboarding-skip-address-back" onClick={onSkipClose}>
            Back
          </Button>
          <Button
            id="onboarding-skip-address-skip"
            data-testing-id="onboarding-skip-address-button"
            variant="secondary"
            onClick={onSkipClick}
          >
            Skip
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export type OnboardingQuestionnairePageKey =
  | 'numberOfEVs'
  | 'awaitingEVDelivery'
  | 'ownsSmartCharger'
  | 'ownsSmartThermostat'
  | 'homeAddress'

export const pages = (props: Props) => {
  const allPages = [
    {
      buttons: ['skip', 'next'] as QuestionnaireButtonType[],
      component: HomeAddress,
      order: 0,
      skipModal: (onSkipClick: () => void, onSkipClose: () => void) => (
        <SkipAddressModal onSkipClick={onSkipClick} onSkipClose={onSkipClose} />
      ),
      key: 'homeAddress' as OnboardingQuestionnairePageKey,
    },
    {
      buttons: ['back', 'next'] as QuestionnaireButtonType[],
      component: NumberOfEVs,
      order: 1,
      key: 'numberOfEVs' as OnboardingQuestionnairePageKey,
    },
    {
      buttons: ['back', 'next'] as QuestionnaireButtonType[],
      component: AwaitingEVDelivery,
      order: 2,
      key: 'awaitingEVDelivery' as OnboardingQuestionnairePageKey,
    },
    {
      buttons: ['back', 'next'] as QuestionnaireButtonType[],
      component: OwnsSmartCharger,
      order: 3,
      shouldShow: (values: QuestionnairePageValue[]) => {
        if (!props.includeChargerQuestion) {
          return false
        }
        const numberOfEVs = values
          .filter((v) => v.key === 'numberOfEVs')
          .map((value) => value.value[0])?.[0]
        const awaitingEVDelivery = values
          .filter((v) => v.key === 'awaitingEVDelivery')
          .map((value) => value.value[0])?.[0]

        return Boolean(+numberOfEVs > 0 || awaitingEVDelivery)
      },
      key: 'ownsSmartCharger' as OnboardingQuestionnairePageKey,
    },
    {
      buttons: ['back', 'next'] as QuestionnaireButtonType[],
      component: OwnsSmartThermostat,
      order: 4,
      key: 'ownsSmartThermostat' as OnboardingQuestionnairePageKey,
    },
  ]

  return allPages.filter((page) => !props.questionsToSkip.includes(page.key))
}

/**
 * The onboarding questionnaire shown to users during onboarding.
 *
 * - Version 1.0.0: includes vehicle, delivery, and thermostat questions
 * - Version 1.1.0: adds charger question
 */
export default function OnboardingQuestionnaire(props: Props) {
  return (
    <Questionnaire
      className="onboarding-questionnaire"
      id="onboarding-questionnaire"
      pages={pages(props)}
      {...props}
    />
  )
}
