import { isNativeMobile } from '@/authenticated/hooks/useIsMobile'

const appCapabilitiesMap = {
  currentLocation: {
    description:
      'enables the use of the current location button to select the user address',
    requiredVersion: '1.6.9',
  },
  externalVehicleAuth: {
    description: 'enables use of external vehicle auth',
    requiredVersion: '1.7.4',
  },
  webviewRewards: {
    description: 'enables the rewards tab in the webview',
    requiredVersion: '1.8.1',
  },
} as const

type AppCapabilities = keyof typeof appCapabilitiesMap

const compareVersions = (version1: string, version2: string) => {
  const v1 = version1.split('.').map(Number)
  const v2 = version2.split('.').map(Number)

  const maxLength = Math.max(v1.length, v2.length)

  for (let i = 0; i < maxLength; i++) {
    const num1 = v1[i] || 0
    const num2 = v2[i] || 0

    if (num1 > num2) {
      return 1 // version1 is greater than version2
    }
    if (num1 < num2) {
      return -1 // version1 is less than version2
    }
  }

  return 0 // The versions are equal
}

const useMobileVersionFlag = (capability: AppCapabilities) => {
  if (!isNativeMobile() || !window.appVersion) return true

  const currentVersion = window.appVersion.replace('v', '')
  const requiredVersion = appCapabilitiesMap[capability].requiredVersion

  return compareVersions(currentVersion, requiredVersion) >= 0
}

export default useMobileVersionFlag
