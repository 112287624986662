import { createResourceCollection } from '@/request'
import { VehicleCharger } from '@/types'
import { createExternalDependencyMiddleware } from '@/request/middleware/createExternalDependencyMiddleware'
import { utilityProgramEnrollmentCollection } from '@/reducers/utilityProgramEnrollments'
import { RequestTypes } from '@/request/types'

export const vehicleChargersCollection =
  createResourceCollection<VehicleCharger>({
    apiConfig: {
      path: 'vehicle_chargers',
      middlewares: [
        // Dependencies because of auto-enrollment
        createExternalDependencyMiddleware([
          {
            requestTypes: [RequestTypes.Create, RequestTypes.Update],
            collections: [utilityProgramEnrollmentCollection],
          },
        ]),
      ],
    },
    name: 'vehicleChargers',
    selector: (state) => state.vehicleChargers,
  })

export function createVehicleChargerAction(
  charger: Parameters<typeof vehicleChargersCollection.actions.create>[0],
) {
  return vehicleChargersCollection.actions.create(charger, {
    pathSegments: ['charge_point_authentication'],
  })
}

export default vehicleChargersCollection.slice.reducer
