import { RSAA } from 'redux-api-middleware'
import config from '../config'
import { serializeQueryParams } from '../utils/urlUtilities'

export const CHARGER_SUCCESS = '@@charger/CHARGER_SUCCESS'
export const CHARGER_REQUEST = '@@charger/CHARGER_REQUEST'
export const CHARGER_FAILURE = '@@charger/CHARGER_FAILURE'

export const CHARGER_SAVINGS_SUCCESS = '@@charger/CHARGER_SAVINGS_SUCCESS'
export const CHARGER_SAVINGS_REQUEST = '@@charger/CHARGER_SAVINGS_REQUEST'
export const CHARGER_SAVINGS_FAILURE = '@@charger/CHARGER_SAVINGS_FAILURE'
export const CHARGER_SAVINGS_RESET = '@@charger/CHARGER_SAVINGS_RESET'

export const getChargers = () => ({
  [RSAA]: {
    endpoint: `${config.apiGateway.URL}/api/chargers/`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    types: [CHARGER_REQUEST, CHARGER_SUCCESS, CHARGER_FAILURE],
  },
})

export const getChargerSavings = (params) => ({
  [RSAA]: {
    endpoint: `${
      config.apiGateway.URL
    }/api/forms/charger_efficiency/?${serializeQueryParams(params)}`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    types: [
      CHARGER_SAVINGS_REQUEST,
      CHARGER_SAVINGS_SUCCESS,
      CHARGER_SAVINGS_FAILURE,
    ],
  },
})

export const resetChargerSavings = () => ({
  type: CHARGER_SAVINGS_RESET,
})
