import { Flex, Text } from '@/components'
import check from '@/app/img/check.svg'

export default function HomeEstimationConfirmation() {
  return (
    <Flex container direction="column" justifyContent="space-around" fullWidth>
      <img alt="success" src={check} />
      <Text variant="h3" align="center" className="pt-4">
        Great! Thank you for confirming your home information.
      </Text>
    </Flex>
  )
}
