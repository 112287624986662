import { NavigationPage } from '@/app/components'
import LinkDiagram from '@/app/features/logos/LinkDiagram'
import TeslaLogo from '@/authenticated/images/tesla.webp'
import OptiwattIconLogo from '@/app/img/logo-icon-colored-filled.webp'
import {
  BoxedSymbol,
  Button,
  Flex,
  IconDetailListItem,
  List,
  Sheet,
  Text,
} from '@/components'
import { useMockableViewModel } from '@/hooks'
import { teslaFleetAuthenticationCollection } from '@/reducers/teslaFleetAuthentication'
import { RequestStatus } from '@/request/types'
import PageLoader from '@/authenticated/components/layout/PageLoader'
import { useHistory, useParams } from 'react-router-dom'
import { IconNames } from '@/components/Icon/Icon'
import { useNavigateToFleetPublicKey } from '../utils/useNavigateToFleetPublicKey'

const listContent = [
  {
    icon: 'Heart',
    title: 'Keep the features you love',
    description:
      'Sharing your virtual key enables features like EV Scheduler, Smart Charging, and more.',
  },
  {
    icon: 'Key',
    title: 'Get more control',
    description: 'Your data remains secure, and we prioritize your privacy.',
  },
]

const useViewModel = useMockableViewModel({
  useViewModel() {
    const history = useHistory()
    const { data: fleetAuthentications, status } =
      teslaFleetAuthenticationCollection.useFetch()
    const { vehicleId } = useParams<{ vehicleId: string }>()
    const fleetPublicKeyUrl =
      fleetAuthentications[0]?.public_key_url ?? 'https://optiwatt.com/app'
    const navigateToFleetPublicKey = useNavigateToFleetPublicKey({
      fleetPublicKeyUrl,
    })

    return {
      onConfirmClick: () => navigateToFleetPublicKey(vehicleId),
      status,
    }
  },
  useMockViewModel() {
    return {
      onConfirmClick: () => {},
      status: RequestStatus.Succeeded,
    }
  },
})

export default function KeyConnectionPage() {
  const { onConfirmClick, status } = useViewModel()

  if (status !== RequestStatus.Succeeded) {
    return <PageLoader />
  }

  return (
    <NavigationPage id="key-confirmation-nav-page">
      <NavigationPage.SingleCardContent>
        <div>
          <LinkDiagram logos={[OptiwattIconLogo, TeslaLogo]} />
        </div>
        <div className="flex items-center justify-center w-full mt-4">
          <Sheet color="green-100">
            <div className="flex items-center">
              <img src={TeslaLogo} alt="Tesla Logo" className="w-4 h-4 mr-2" />
              <Text variant="body2" className="text-green-500">
                OFFICIAL FLEET PARTNER
              </Text>
            </div>
          </Sheet>
        </div>
        <Text variant="h2" className="!mt-14">
          Almost there! Pair your virtual key.
        </Text>
        <Text variant="body2" className="!mt-4 !mb-8">
          In a moment, we'll ask you to confirm your virtual key setup. This
          step is crucial for us to continue to provide you with the full range
          of our app's features
        </Text>
        <List gap="md">
          {listContent.map((item, index) => (
            <Flex key={index} item>
              <IconDetailListItem
                icon={() => (
                  <BoxedSymbol
                    variant="icon"
                    iconName={item.icon as IconNames}
                  />
                )}
                title={item.title}
                description={item.description}
              />
            </Flex>
          ))}
        </List>
        <Button
          id="tesla-fleet-authentication-key-confirmation-button"
          variant="primary"
          className="!mt-10 mb-8"
          onClick={onConfirmClick}
        >
          Confirm
        </Button>
      </NavigationPage.SingleCardContent>
    </NavigationPage>
  )
}
