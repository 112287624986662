import React from 'react'
import Oops from './Oops'
import DashboardCard from './DashboardCard'
import * as Sentry from '@sentry/react'

export enum LocationTag {
  Onboarding = 'Onboarding',
  HeardFromSurvey = 'HeardFromSurvey',
  Dashboard = 'Dashboard',
  Profile = 'Profile',
  Rewards = 'Rewards',
  SchedulePage = 'SchedulePage',
  ChargeSchedule = 'ChargeSchedule',
  ChargeCalendar = 'ChargeCalendar',
  TotalCost = 'TotalCost',
  UtilityUsage = 'UtilityUsage',
  ChargeForecast = 'ChargeForecast',
  Charges = 'Charges',
  TotalImpact = 'TotalImpact',
  UserResourcesManagementSelectionGallery = 'UserResourcesManagementSelectionGallery',
  UtilityProviderCard = 'UtilityProviderCard',
  UtilityPlanCard = 'UtilityPlanCard',
  UtilityProgramEnrollment = 'UtilityProgramEnrollment',
  MultiProgramEnrollment = 'MultiProgramEnrollment',
  UtilityProgramLevelThreeView = 'UtilityProgramLevelThreeView',
  UtilityProgramDeviceSelection = 'UtilityProgramDeviceSelection',
  UtilitySelection = 'UtilitySelection',
  ProgramEnrollment = 'ProgramEnrollment',
  ReviewTOS = 'ReviewTOS',
  EnrollmentState = 'EnrollmentState',
  HomeCard = 'HomeCard',
  PlanSelectCard = 'PlanSelectCard',
  Thermostat = 'Thermostat',
  YourLastAdjustmentCard = 'YourLastAdjustmentCard',
  VehicleChargerChargeForecastCard = 'VehicleChargerChargeForecastCard',
  VehicleChargerChargesCard = 'VehicleChargerChargesCard',
  VehicleChargerClarkEVSEAlert = 'VehicleChargerClarkEVSEAlert',
  TotalSpentCard = 'TotalSpentCard',
  HomeSchedulCard = 'HomeSchedulCard',
  UtilityOnboarding = 'UtilityOnboarding',
  NotificationSettings = 'NotificationSettings',
  PrecoolPreheatSettings = 'PrecoolPreheatSettings',
  ThermostatGridSupport = 'ThermostatGridSupport',
  ThermostatSchedule = 'ThermostatSchedule',
  HomeEstimations = 'HomeEstimations',
  HvacDetails = 'HvacDetails',
  VehicleChargerLinkedVehicles = 'VehicleChargerLinkedVehicles',
  HomeCurrentRate = 'HomeCurrentRate',
  HomeComparisonSurvey = 'HomeComparisonSurvey',
  VehicleLimits = 'VehicleLimits',
  VehicleGoals = 'VehicleGoals',
  VehicleSchedule = 'VehicleSchedule',
  VehicleTrips = 'VehicleTrips',
  VehicleSupportLocalGridDetails = 'VehicleSupportLocalGridDetails',
  DeviceConnectivity = 'DeviceConnectivity',
  ContactUs = 'ContactUs',
  AvaAddressSelect = 'AvaAddressSelect',
  OnboardingConnectAnother = 'OnboardingConnectAnother',
  BecomeUtilityCustomer = 'BecomeUtilityCustomer',
  AvaVerifyResidence = 'AvaVerifyResidence',
  UtilityProgramOffer = 'UtilityProgramOffer',
  UtilityProgramEnrollmentNew = 'UtilityProgramEnrollmentNew',
  UtilityProgramEnrollmentSuccess = 'UtilityProgramEnrollmentSuccess',
  PlanSelect = 'PlanSelect',
  PlanSelectOptionSelect = 'PlanSelectOptionSelect',
  PlanSelectManualEntry = 'PlanSelectManualEntry',
  PlanSelectUpload = 'PlanSelectUpload',
  UtilityProgramStart = 'UtilityProgramStart',
  TeslaKeyConnectionNotOwner = 'TeslaKeyConnectionNotOwner',
  ManageUtilityPlan = 'ManageUtilityPlan',
}

type Severity = 'critical' | 'high' | 'medium' | 'low'

export interface Props {
  children?: React.ReactNode
  location?: string
  fallbackOnCard?: boolean
  functionalityDescription?: string
  severity?: Severity
  fallback?: (opts: { eventId: string | null }) => JSX.Element
}

const defaultProps = {
  fallbackOnCard: false,
  severity: 'medium' as Severity,
}

export default function ErrorBoundary(inProps: Props) {
  const props = { ...defaultProps, ...inProps }
  const setTags = (scope: Sentry.Scope) => {
    const tags = { location: props.location, severity: props.severity }
    scope.setTags(tags)
  }

  return (
    <Sentry.ErrorBoundary
      fallback={({ eventId }) =>
        props.fallback ? (
          props.fallback({ eventId })
        ) : props.fallbackOnCard ? (
          <DashboardCard>
            {' '}
            <Oops
              functionalityDescription={props.functionalityDescription}
              eventId={eventId}
            />{' '}
          </DashboardCard>
        ) : (
          <Oops
            functionalityDescription={props.functionalityDescription}
            eventId={eventId}
          />
        )
      }
      beforeCapture={setTags}
    >
      {props.children}
    </Sentry.ErrorBoundary>
  )
}

export function pageWithErrorBoundary(
  Component: React.ComponentType,
  options: Props,
) {
  const allOptions = { ...defaultProps, ...options }

  return function ErrorBoundaryComponent({ ...props }) {
    return (
      <ErrorBoundary
        {...allOptions}
        fallback={({ eventId }) => (
          <div style={{ paddingTop: '100px' }}>
            <Oops
              functionalityDescription={allOptions.functionalityDescription}
              eventId={eventId}
            />
          </div>
        )}
      >
        <Component {...props} />
      </ErrorBoundary>
    )
  }
}
