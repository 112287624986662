import { useAppDispatch, useAppSelector } from '@/hooks'
import { surveyResponsesCollection } from '@/reducers/surveyResponses'
import { RootState } from '@/store'
import { SurveyResponse, SurveyType } from '@/types'
import { useEffect } from 'react'
import NPSSurveyDialog from './NPSSurveyDialog'
import OnboardingSurvey from './OnboardingSurvey'
import SMUDMEOSurvey from './SMUDMEOSurvey'
import { addActiveSurveyResponseID } from './reducer'
import TeslaFleetAuthSurvey from './TeslaFleetAuthSurvey'
import FeedbackSurvey from './FeedbackSurvey/FeedbackSurvey'
import ClarkEVSE from './ClarkEVSE/ClarkEVSE'
import HeardFromSurvey from './HeardFromSurvey'
import { GexaCompleteSwitchSurveyWrapped } from './GexaCompleteSwitchSurvey'
import TAFNPSSurveyDialog from '@/app/components/SurveyObserver/TAFNPS'
import UtilityProgramNPS from '@/app/components/SurveyObserver/UtilityProgramNPS'
import UtilityNPS from '@/app/components/SurveyObserver/UtilityNPS'

export const SurveyTypeMap = {
  NPS: NPSSurveyDialog,
  TAFNPS: TAFNPSSurveyDialog,
  UtilityProgramNPS: UtilityProgramNPS,
  UtilityNPS: UtilityNPS,
  Onboarding: OnboardingSurvey,
  SMUDMEO: SMUDMEOSurvey,
  TeslaFleetAuth: TeslaFleetAuthSurvey,
  Feedback: FeedbackSurvey,
  MissingBrand: null,
  AutogridExistingUser: null,
  ClarkEVSE: ClarkEVSE,
  ProfileDeletion: null,
  ProgramUnenrollment: null,
  LiveChat: null,
  HeardFrom: HeardFromSurvey,
  GexaCompleteSwitch: GexaCompleteSwitchSurveyWrapped,
  GexaNavigateToSwitch: null,
  InsightsDismissal: null,
  RateWatch: null,
  PlanSwitchApproval: null,
  BrandApology: null,
}

export interface Props {
  // A whitelist of surveys that the SurveyObserver will show
  // when it observes a survey response of that type.
  observedSurveys: SurveyType[]
  children?: JSX.Element
}

function getHighestPrioritySurveyResponse(
  surveyResponses: SurveyResponse[],
): SurveyResponse | undefined {
  return surveyResponses.sort(
    (a, b) => b.survey.priority - a.survey.priority,
  )[0]
}

function selectHightestPrioritySurveyResponse(
  state: RootState,
  observedSurveyTypes: SurveyType[],
) {
  const surveyResponses = surveyResponsesCollection.selectors.selectAll(state)
  const observedSurveyResponses = surveyResponses.filter((response) => {
    const responseNeedsAnswered =
      response.pending_response || response.manual_show_flag
    return (
      observedSurveyTypes.includes(response.survey.type) &&
      responseNeedsAnswered
    )
  })
  // select by id to avoid re-rendering
  const responseId = getHighestPrioritySurveyResponse(
    observedSurveyResponses,
  )?.id
  if (!responseId) {
    return undefined
  }
  return surveyResponsesCollection.selectors.selectById(state, responseId)
}

/**
 * Example usages:
 * ## Inject survey into page
 * <SurveyObserver observedSurveys={['NPS', 'Onboarding']} />
 * ## Use as a wrapper
 * <SurveyObserver observedSurveys={['MissingBrand']}>
 *  <BrandNotFoundPage />
 * </SurveyObserver>
 */
export default function SurveyObserver(props: Props) {
  const dispatch = useAppDispatch()
  const surveyResponse = useAppSelector((state) =>
    selectHightestPrioritySurveyResponse(state, props.observedSurveys),
  )

  useEffect(() => {
    if (!surveyResponse?.id) {
      return
    }
    dispatch(addActiveSurveyResponseID(surveyResponse.id))
  }, [surveyResponse?.id])

  if (!surveyResponse) {
    return null
  }

  const SurveyComponent = SurveyTypeMap[surveyResponse.survey.type]

  // Allows for the SurveyObserver to be used as a wrapper
  if (SurveyComponent === null) {
    return props.children ?? null
  }

  return <SurveyComponent />
}
