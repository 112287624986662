import './styles.scss'
import formatClasses from '../../../utils/classes/formatClasses'
import { useContext } from 'react'
import { BreakpointContext } from '@/context'
import { ColumnedCardLayoutProps } from '..'
import ColumnedCardLayout from '../ColumnedCardLayout'
import { Card, CardProps, Divider } from '@/components'
import React from 'react'
import twMerge from '@/utils/classes/twMerge'

export type Props = ColumnedCardLayoutProps

function useAdaptiveCardRowMode() {
  const breakpoint = useContext(BreakpointContext)
  return breakpoint.smAndDown ? 'rows-mode' : 'cards-mode'
}

function AdaptiveCardRowLayout({ children, ...props }: Props) {
  const mode = useAdaptiveCardRowMode()
  const className = formatClasses([
    'adaptive-card-rows-layout',
    mode,
    props.className,
  ])

  return (
    <ColumnedCardLayout {...props} gap="0px" className={className}>
      {children}
    </ColumnedCardLayout>
  )
}

function PlainRow(props: { children: React.ReactNode; className?: string }) {
  const mode = useAdaptiveCardRowMode()

  const baseClasses = mode === 'rows-mode' ? 'my-[16px]' : 'my-[12px]'
  const className = twMerge(baseClasses, props.className)

  return <div className={className}>{props.children}</div>
}
PlainRow.displayName = 'PlainRow'
AdaptiveCardRowLayout.PlainRow = PlainRow

type CardRowProps = CardProps & {
  bottomRule?: boolean
}
const defaultProps = {
  bottomRule: true,
}
function CardRow(props: CardRowProps) {
  const {
    className: inClassName,
    bottomRule,
    ...cardProps
  } = { ...defaultProps, ...props }
  const mode = useAdaptiveCardRowMode()
  const className = formatClasses(['card-row', inClassName])
  const marginClassName = `${mode === 'rows-mode' ? 'my-6' : 'my-3'}`
  const flat = mode === 'rows-mode'
  const showBottomRule = mode === 'rows-mode' && bottomRule

  return (
    <>
      <div className={marginClassName}>
        <Card {...cardProps} className={className} flat={flat} />
      </div>
      {showBottomRule && <Divider />}
    </>
  )
}
CardRow.displayName = 'CardRow'
AdaptiveCardRowLayout.CardRow = CardRow

export default AdaptiveCardRowLayout
