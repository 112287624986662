import './styles.scss'
import { Bar, Grid, GridItem, NumberEasing, Text } from '../../../../components'
import { clamp } from '../../../../utils/limit'
import getTemperatureGradient from '../../../../utils/color/getTemperatureGradient'
import formatClasses from '../../../../utils/classes/formatClasses'
import React from 'react'
import { TemperatureUnit } from '../../../../types/temperatureUnit'

export interface Props {
  low: number
  high: number
  max: number
  min: number
  unit: TemperatureUnit
}

export default function TemperatureBar(props: Props) {
  const classes = formatClasses(['temperature-bar'])

  const styles = () => {
    const scaleSize = props.max - props.min
    const position = ((props.low - props.min) / scaleSize) * 100
    const temperatureDifference = ((props.high - props.low) / scaleSize) * 100
    const width = clamp(0, temperatureDifference, 100 - position)
    const left = clamp(0, position, 100 - width)

    return {
      background: `linear-gradient(90deg, ${getTemperatureGradient(
        props.low,
        props.high,
        props.unit,
      )})`,
      left: `${left}%`,
      width: `${width}%`,
    }
  }

  return (
    <Grid
      className={classes}
      gap="8px"
      placeItems="center"
      templateColumns="auto auto 32px"
    >
      <Text variant="body2">
        <NumberEasing value={props.low || 0} speed={400} ease="quintOut" />°
      </Text>
      <Bar>
        <Bar style={styles()} />
      </Bar>
      <GridItem>
        <Text>
          <NumberEasing value={props.high || 0} speed={400} ease="quintOut" />°
        </Text>
      </GridItem>
    </Grid>
  )
}
