import { AlertMessage, NavigationPage, SelectInput } from '@/app/components'
import {
  CardActions,
  CardAction,
  Text,
  TextField,
  Button,
  Modal,
} from '@/components'
import { AnimatePresence } from 'framer-motion'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import {
  UPLOAD_UTILITY_BILL_SUCCESS,
  uploadUtilityBillForm,
} from '@/actions/plans'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { RSAAResultAction } from 'redux-api-middleware'
import { utilityAccountsCollection } from '@/reducers/utilityAccounts'
import * as yup from 'yup'
import { selectUtilityProgramForUserConsideringEnrollments } from '@/selectors'
import { useFormik } from 'formik'
import * as Sentry from '@sentry/browser'
import { logEvent } from '@/logging'
import { EnrollErrorMessage } from '../UtilityProgramEnrollmentTogglePrompt/EnrollErrorMessage'

type Props = {
  onAccountNumberEntryComplete?: () => void
  enrollError?: string
  closeModal: () => void
  onSkip?: () => void
  existingAccountNumber: string | null
}

function useViewModel(props: Props) {
  const dispatch = useAppDispatch()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [showManualEntry, setShowManualEntry] = useState(
    Boolean(props.existingAccountNumber),
  )
  // ensure existingAccountNumber does change during flow
  const [existingAccountNumber] = useState(props.existingAccountNumber)
  const [fileUploadStatus, setFileUploadStatus] = useState<
    'idle' | 'loading' | 'success' | 'error'
  >('idle')
  const [accountNumberUploadStatus, setAccountNumberUploadStatus] = useState<
    'idle' | 'loading' | 'success' | 'error'
  >('idle')
  const [fileUploadName, setFileUploadName] = useState<string | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [utilityBillExampleModalOpen, setUtilityBillExampleModalOpen] =
    useState(false)

  const utilitySiteLink = useAppSelector(
    (state) => state.utilities.selectedUtility?.website,
  )

  const utilityProgramViewConfig = useAppSelector(
    (state) =>
      selectUtilityProgramForUserConsideringEnrollments(state)
        ?.view_config_json,
  )

  const accountNumberLabel =
    utilityProgramViewConfig?.accountNumberEntry?.accountNumberLabel ??
    'account number'

  const utilityBillExampleSrc =
    utilityProgramViewConfig?.accountNumberEntry?.utilityBillExampleImage

  const manualEntryFormValues = useForm({
    length: utilityProgramViewConfig?.accountNumberEntry?.accountNumberLength,
    onSubmit: (values) => {
      setShowManualEntry(true)
      setAccountNumberUploadStatus('loading')
      dispatch(
        utilityAccountsCollection.actions.update('/utilities/utility_account', {
          account_number: values.accountNumber,
        }),
      ).then((res: RSAAResultAction) => {
        console.log('res', res)
        if (res.type === utilityAccountsCollection.actionTypes.update.Success) {
          setAccountNumberUploadStatus('success')
          props.onAccountNumberEntryComplete?.()
        } else {
          setAccountNumberUploadStatus('error')
        }
      })
    },
    initialValue: existingAccountNumber,
    existingAccountNumber: Boolean(existingAccountNumber),
  })

  const allEntrySelectOptionsAndActions = {
    upload: {
      option: {
        value: 'Upload utility bill',
        tag: existingAccountNumber ? undefined : 'RECOMMENDED',
      },
      action: () => {
        logEvent('accountnumber_utility_bill_upload_clicked')
        setShowManualEntry(false)
        fileInputRef.current?.click()
      },
    },
    manualEntry: {
      option: {
        value: `${
          existingAccountNumber ? 'Verify' : 'Manually enter'
        } ${accountNumberLabel}`,
        tag: existingAccountNumber ? 'RECOMMENDED' : undefined,
      },
      action: () => {
        logEvent('accountnumber_manual_entry_clicked')
        setShowManualEntry(true)
      },
    },
  }

  const entrySelectOptionsAndActions =
    fileUploadStatus === 'idle' || fileUploadStatus === 'loading'
      ? [
          allEntrySelectOptionsAndActions.upload,
          allEntrySelectOptionsAndActions.manualEntry,
        ]
      : fileUploadStatus === 'error'
      ? [allEntrySelectOptionsAndActions.upload]
      : []

  const handleSelectChange = (index: number | null) => {
    if (index === null) {
      return
    }
    entrySelectOptionsAndActions[index]?.action()
  }

  const initialSelectValue = existingAccountNumber ? 1 : undefined

  const handleFilesUploaded = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files

    if (!files) {
      return
    }

    setFileUploadStatus('loading')

    const data = new FormData()
    data.append('file', files[0])

    const uploadResponse: RSAAResultAction = await dispatch(
      uploadUtilityBillForm(data),
    )

    if (uploadResponse.type === UPLOAD_UTILITY_BILL_SUCCESS) {
      setFileUploadName(files[0].name)
      setFileUploadStatus('success')
    } else {
      setFileUploadStatus('error')
      setErrorMessage('Photo upload failed. Please try again.')
      logEvent('accountnumber_utility_bill_upload_failed')
    }
  }

  const onEnrollClicked = () => {
    if (fileUploadStatus === 'success') {
      logEvent('accountnumber_bill_uploaded_enroll')
      props.onAccountNumberEntryComplete?.()
      return
    }

    if (manualEntryFormValues.submitEnabled) {
      logEvent('accountnumber_manual_entry_enroll')
      manualEntryFormValues.submit()
      return
    }

    Sentry.captureMessage(
      'User attempted to enroll in utility program without providing account number or utility bill. The action should be disabled until the user provides the required information.',
    )
    setErrorMessage(
      'Please provide your account number or upload a utility bill',
    )
  }

  const loading =
    fileUploadStatus === 'loading' || accountNumberUploadStatus === 'loading'

  const enrollEnabled =
    fileUploadStatus === 'success' ||
    (manualEntryFormValues.submitEnabled && !loading) ||
    // if existing account number and the manual entry form isnt emtpy
    (existingAccountNumber &&
      manualEntryFormValues.inputValues.accountNumber !== '')

  return {
    showManualEntry,
    entrySelectOptions: entrySelectOptionsAndActions.map((o) => o.option),
    hanldleEntrySelectChange: handleSelectChange,
    fileInputRef,
    handleFilesUploaded,
    fileUploadStatus,
    fileUploadName,
    accountNumberUploadStatus,
    errorMessage,
    onEnrollClicked,
    manualEntryFormValues,
    enrollEnabled,
    accountNumberLabel,
    utilitySiteLink,
    utilityBillExampleModalOpen,
    setUtilityBillExampleModalOpen,
    utilityBillExampleSrc,
    existingAccountNumber: existingAccountNumber,
    initialSelectValue,
  }
}

function useForm(config: {
  length?: number
  onSubmit: (values: { accountNumber: string }) => void
  initialValue: string | null
  existingAccountNumber: boolean
}) {
  let accountNumberPropertyValidation = yup
    .string()
    .required('Account number is required')

  if (config.length && !config.existingAccountNumber) {
    accountNumberPropertyValidation = accountNumberPropertyValidation.min(
      config.length,
      `Account number must be at least ${config.length} characters`,
    )
  }

  const accountNumberValidateionSchema = yup.object({
    accountNumber: accountNumberPropertyValidation,
  })

  const formik = useFormik({
    initialValues: {
      accountNumber: config.initialValue ?? '',
    },
    validationSchema: accountNumberValidateionSchema,
    onSubmit: config.onSubmit,
  })

  return {
    inputValues: formik.values,
    inputErrors: {
      accountNumber: formik.dirty ? formik.errors.accountNumber : undefined,
    },
    onValueChange: formik.handleChange,
    submitEnabled:
      (formik.dirty && formik.isValid) || config.existingAccountNumber,
    submit: formik.submitForm,
  }
}

export default function UtilityProgramAccountNumberEntry(props: Props) {
  const viewModel = useViewModel(props)
  const {
    showManualEntry,
    entrySelectOptions,
    hanldleEntrySelectChange,
    fileInputRef,
    handleFilesUploaded,
    fileUploadName,
    fileUploadStatus,
    onEnrollClicked,
    manualEntryFormValues,
    enrollEnabled,
    accountNumberLabel,
    utilitySiteLink,
    utilityBillExampleModalOpen,
    setUtilityBillExampleModalOpen,
    utilityBillExampleSrc,
    existingAccountNumber,
    initialSelectValue,
  } = viewModel

  useEffect(() => {
    if (props.enrollError) {
      props.closeModal?.()
    }
  }, [props.enrollError])

  return (
    <NavigationPage
      id="account-number-entry-page"
      title={`${
        existingAccountNumber ? 'Verify' : 'Provide'
      } your ${accountNumberLabel} to secure your spot`}
      subtitle={
        <>
          Visit your utility’s{' '}
          {utilitySiteLink ? (
            <a
              href={utilitySiteLink ?? undefined}
              target="_blank"
              rel="noreferrer"
            >
              website
            </a>
          ) : (
            'website'
          )}{' '}
          to download your bill
        </>
      }
      className="p-0"
    >
      <div className="[&:not(:empty)]:pb-8">
        <EnrollErrorMessage error={props.enrollError} />
      </div>
      <div className="h-full flex flex-col shrink-0 grow-0">
        <AnimatePresence>
          {fileUploadStatus === 'success' && (
            <AnimatedAlertMessage
              key="file-upload-success"
              message="Photo successfully uplaoded"
              submessage={fileUploadName ?? undefined}
              variant="success"
            />
          )}
        </AnimatePresence>
        <SelectInput
          direction="column"
          initialValue={initialSelectValue}
          options={entrySelectOptions}
          onChange={hanldleEntrySelectChange}
          variant="select"
          disabled={fileUploadStatus === 'loading'}
        />
        <input
          onChange={handleFilesUploaded}
          style={{ display: 'none' }}
          multiple
          type="file"
          ref={fileInputRef}
        />

        <AnimatePresence>
          {showManualEntry && (
            <motion.div
              key="manual-account-num-text-field"
              className="w-full overflow-hidden"
              // slide down and opacity fade in
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -10, opacity: 0 }}
              transition={{ ease: 'easeInOut', duration: 0.15 }}
            >
              <Text variant="subheader" className="mt-8">
                {existingAccountNumber ? 'Verify' : 'Manually Enter'} your{' '}
                {accountNumberLabel}
              </Text>

              <TextField
                id="accountNumber"
                label={`Utility Provider ${accountNumberLabel}`}
                type="text"
                value={manualEntryFormValues.inputValues.accountNumber}
                error={Boolean(manualEntryFormValues.inputErrors.accountNumber)}
                helperText={manualEntryFormValues.inputErrors.accountNumber}
                onChange={manualEntryFormValues.onValueChange}
                className="max-w-md w-full"
              />
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {viewModel.errorMessage && (
            <AnimatedAlertMessage
              key="error-message"
              message={viewModel.errorMessage}
              variant="error"
            />
          )}
        </AnimatePresence>
        <CardActions className="flex-none mt-12">
          <CardAction type="primary">
            {utilityBillExampleSrc && (
              <div className="flex justify-center mb-8">
                <Text
                  variant="link"
                  onClick={() => setUtilityBillExampleModalOpen(true)}
                >
                  See utility bill example
                </Text>
                <ExampleUtilityBillModal
                  src={utilityBillExampleSrc}
                  open={utilityBillExampleModalOpen}
                  onClose={() => setUtilityBillExampleModalOpen(false)}
                />
              </div>
            )}
            {!props.enrollError ? (
              <Button
                id="account-number-entry-enroll-button"
                onClick={onEnrollClicked}
                disabled={!enrollEnabled}
              >
                Enroll
              </Button>
            ) : (
              <Button
                id="account-number-error-skip-button"
                onClick={props.onSkip}
              >
                Next
              </Button>
            )}
          </CardAction>
        </CardActions>
      </div>
    </NavigationPage>
  )
}

function ExampleUtilityBillModal(props: {
  src: string
  open: boolean
  onClose: () => void
}) {
  return (
    <Modal
      id="example-utility-bill-modal"
      title="Utility bill example"
      open={props.open}
      onClose={props.onClose}
    >
      <div className="mt-14 max-h-96">
        <img src={props.src} alt="Utility bill example" className="w-full" />
      </div>
    </Modal>
  )
}

function AnimatedAlertMessage(props: {
  message: string
  submessage?: string
  variant: 'success' | 'error'
}) {
  return (
    <motion.div
      key="alert-message"
      className="overflow-hidden"
      initial={{ height: 0, opacity: 0 }}
      animate={{ height: 'auto', opacity: 1 }}
      exit={{ height: 0, opacity: 0 }}
    >
      <AlertMessage variant={props.variant}>
        <Text className="text-initial">{props.message}</Text>
        {props.submessage && (
          <Text variant="label" className="mt-1 text-initial">
            {props.submessage}
          </Text>
        )}
      </AlertMessage>
    </motion.div>
  )
}
