import { useAppSelector, useAppDispatch } from '../../../hooks'
import useHideDashboardConfig from '../../hooks/useHideDashboardConfig'
import useTeslaGiveawayPromoActive from '../../hooks/useTeslaGiveawayPromoActive'
import { addConfig } from '../../../actions/dashboardConfigs'
import { DashboardConfigType } from '../../../types/dashboardConfig'
import {
  setDialogOpen as setDialogAction,
  setDialogConfirmationPending as setDialogConfirmationPendingAction,
} from './actions'
import useCanConnectUtility from '../../hooks/useCanConnectUtility'

function useViewModel() {
  const dispatch = useAppDispatch()
  const canConnectUtility = useCanConnectUtility()
  const promoActive = useTeslaGiveawayPromoActive()
  const profileId = useAppSelector((state) => state.user.user?.profile.id)
  const shouldHide = useHideDashboardConfig(DashboardConfigType.TeslaGiveaway)
  const dialogOpen = useAppSelector(
    (state) => state.components.TeslaGiveawayDashboardView.dialogOpen,
  )
  const dialogConfirmationPending = useAppSelector(
    (state) =>
      state.components.TeslaGiveawayDashboardView.dialogConfirmationPending,
  )

  const setGiveawayWasDismissed = () => {
    if (profileId) {
      dispatch(addConfig(DashboardConfigType.TeslaGiveaway, profileId))
    }
  }

  return {
    shouldShowCard: canConnectUtility && promoActive && !shouldHide,
    setGiveawayWasDismissed,
    dialogOpen,
    dialogConfirmationPending,
    setDialogOpen: (dialogOpen: boolean) =>
      dispatch(setDialogAction(dialogOpen)),
    setDialogConfirmationPending: (dialogConfirmationPending: boolean) =>
      dispatch(setDialogConfirmationPendingAction(dialogConfirmationPending)),
  }
}

export default useViewModel
