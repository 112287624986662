import { useEffect } from 'react'

import { getPlans } from '../../../actions/plans'
import { shouldLoadData } from '../useDataLoader'
import { useDispatch, useSelector } from 'react-redux'

const useSelectedPlanFetcher = () => {
  const dispatch = useDispatch()

  const { selectedPlan, plans, isLoading, errors } = useSelector(
    (state) => state.plans,
  )
  const planIsPending = useSelector(
    (state) => state.user?.user?.profile?.is_plan_pending,
  )

  useEffect(() => {
    if (!planIsPending && shouldLoadData(plans, isLoading, errors)) {
      dispatch(getPlans())
    }
  }, [plans, isLoading, errors, planIsPending, dispatch])

  return { selectedPlan, planIsPending, isLoading }
}

export default useSelectedPlanFetcher
