import React, { useState } from 'react'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import {
  DialogContent,
  Box as MuiBox,
  Grid as MuiGrid,
  Typography as MuiTypography,
} from '@material-ui/core'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  gasCarConversionCollection,
  selectTrimVariantsByGasCarConversion,
} from './reducer'
import { selectGasCarConversion } from '../../../actions/vehicles'
import { GasCarConversion, Vehicle } from '../../../types/vehicle/'
import GasConversionSearchbar from '../GasConversionSearchbar'
import TrimSearchbar from '../TrimSearchbar'
import LocalVehicleSelectionGallery from '../LocalVehicleSelectionGallery'
import ColorBoxedValueList from '../ColorBoxedValueList'
import SaveButton, { SaveState } from '../SaveButton'
import { RequestStatus } from '../../../request/types'
import EditableGasPriceTag from './EditableGasPriceTag'
import { gasPriceCollection } from '@/reducers/gasPrice'

const Box = styled(MuiBox)(spacing)
const Grid = styled(MuiGrid)(spacing)
const Typography = styled(MuiTypography)(spacing)

const useStyles = makeStyles(() =>
  createStyles({
    buttonContainer: {
      textAlign: 'center',
    },
    button: {
      width: '100%',
      maxWidth: '300px',
      height: '50px',
    },
    sectionTitle: {
      fontWeight: 'bold',
    },
    container: {
      marginBottom: 0,
    },
  }),
)

export interface Props {
  onSubmit?: () => void
  onClose?: () => void
  onSubmitPressed?: () => void
  initialVehicle: Vehicle
}

function useGasCarConversionSearchResults() {
  const searchResults = useAppSelector(
    gasCarConversionCollection.selectors.selectAll,
  )
  return {
    searchResults,
    fetchAction: (search: string) =>
      gasCarConversionCollection.actions.fetch({
        params: { search },
      }),
  }
}

function GasSavingsDialogContentSection({
  title,
  children,
}: {
  title: string
  children: React.ReactNode
}) {
  const classes = useStyles()
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography className={classes.sectionTitle} variant="subtitle1">
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  )
}

export default function GasSavingsDialogContent({
  onSubmit = () => {},
  onSubmitPressed = () => {},
  initialVehicle,
}: Props) {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const vehicles = useAppSelector((state) => state.vehicles.vehicles)
  // form data
  const [vehicle, setVehicle] = useState(initialVehicle)
  const [gasCarConversion, setGasCarConversion] = useState(
    initialVehicle.selected_gas_car_conversion,
  )
  const [gasPriceOverride, setGasPriceOverride] = useState<number | null>(null)
  const [showGasPriceAsCorrect, setShowGasPriceAsCorrect] = useState(false)
  const trimVariants = useAppSelector((state) =>
    selectTrimVariantsByGasCarConversion(state, gasCarConversion),
  )
  // @todo just call this fetch on mount and then dispatch actions manually using the collection instead
  // of the fetcher
  const trimVariantsFetcher = gasCarConversionCollection.useResourceFetcher(
    {
      params: {
        make: gasCarConversion.make,
        model: gasCarConversion.model,
        year: gasCarConversion.year,
      },
    },
    {
      refetchOnChange: false,
    },
  )
  const trimVariantsFetcherStatus = useAppSelector(
    trimVariantsFetcher.selectors.status,
  )
  const [userSelectedTrim, setUserSelectedTrim] = useState<GasCarConversion>()
  // @todo select trim by car id as well to make sure we update trim when vehicle changes
  const selectedTrim = useAppSelector((state) => {
    if (!userSelectedTrim) {
      return (
        gasCarConversionCollection.selectors.selectById(
          state,
          gasCarConversion.id,
        ) ?? gasCarConversion
      )
    }
    return gasCarConversionCollection.selectors.selectById(
      state,
      userSelectedTrim.id,
    )
  })

  const { data: gasPriceData } = gasPriceCollection.useFetch({
    params: {
      vehicle_id: vehicle.id,
    },
  })

  const gasCost = gasPriceData[gasPriceData.length - 1]

  const setVehicleAndUpdateConversions = (newVehicle: Vehicle) => {
    setVehicle(newVehicle)
    setGasCarConversion(newVehicle.selected_gas_car_conversion)
  }

  const [saveButtonState, setSaveButtonState] = useState(SaveState.Disabled)

  const handleGasPriceValueChange = (
    newValue: number | null,
    showCorrect: boolean,
  ) => {
    setShowGasPriceAsCorrect(showCorrect)
    if (gasCost?.average_gas_cost_cents_per_gallon != newValue && newValue) {
      setGasPriceOverride(newValue)
      setSaveButtonState(SaveState.Enabled)
    } else {
      setGasPriceOverride(null) // if values are the same -> reset gas price override
    }
  }

  const onSearchbarValueChange = (
    gasCarConversion: GasCarConversion | null,
  ) => {
    // fetch trim variant options every time a new conversion is selected
    if (!gasCarConversion) {
      return
    }
    setGasCarConversion(gasCarConversion)
    const getTrimVariants = () =>
      trimVariantsFetcher.fetch({
        params: {
          make: gasCarConversion.make,
          model: gasCarConversion.model,
          year: gasCarConversion.year,
        },
      })
    getTrimVariants().then((response) => {
      if (response?.type === gasCarConversionCollection.types.Success) {
        setSaveButtonState(SaveState.Enabled)
      }
    })
  }

  const submitGasCarConversionAndGasPriceOverride = (
    selectedConversion: GasCarConversion | undefined,
    gasPrice: number | null,
  ) => {
    setSaveButtonState(SaveState.Saving)
    onSubmitPressed()

    const requests: Promise<any>[] = []

    if (selectedConversion) {
      requests.push(
        dispatch(selectGasCarConversion(vehicle.id, selectedConversion.id)),
      )
    }

    if (gasPrice) {
      requests.push(
        dispatch(
          gasPriceCollection.actions.create({
            vehicle_id: vehicle.id,
            average_gas_cost_cents_per_gallon: gasPrice,
          }),
        ),
      )
    }

    Promise.all(requests).then((responses) => {
      const success = !responses.some((response) => {
        if (response.type.includes('gasPrice'))
          dispatch(gasPriceCollection.actions.invalidate()) // refetch gasPrice if updated

        return !response.type.includes('SUCCESS')
      })

      setSaveButtonState(SaveState.Saved)
      if (success && requests.length > 0) onSubmit()
    }) as Promise<void>
  }

  const onFormSubmit = () => {
    submitGasCarConversionAndGasPriceOverride(selectedTrim, gasPriceOverride)
  }

  const { gas_car_mpg: efficiency, gas_car_gas_type: gasType } =
    gasCarConversion

  const sortedVehicles = (vehicles ?? []).sort((a: Vehicle) => {
    return -(a.id === initialVehicle.id)
  })

  return (
    <DialogContent>
      <Grid container spacing={7} className={classes.container}>
        <Grid item xs={12}>
          <GasSavingsDialogContentSection title="Your Vehicle ⚡️">
            <LocalVehicleSelectionGallery
              vehicles={sortedVehicles}
              selectedVehicle={vehicle}
              setSelectedVehicle={setVehicleAndUpdateConversions}
              id="gas-savings-dialog-vehicle-selection-gallery"
            />
          </GasSavingsDialogContentSection>
        </Grid>
        <Grid item xs={12}>
          <GasSavingsDialogContentSection title="Gas Comparable 🔥">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <GasConversionSearchbar
                  useSearchResults={useGasCarConversionSearchResults}
                  gasCarConversion={gasCarConversion}
                  onChange={(event, value) => {
                    onSearchbarValueChange(value)
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                {selectedTrim && (
                  <TrimSearchbar
                    options={trimVariants ?? []}
                    trimValue={selectedTrim}
                    setTrimValue={setUserSelectedTrim}
                    disabled={
                      trimVariantsFetcherStatus === RequestStatus.Loading
                    }
                  />
                )}
              </Grid>
            </Grid>
          </GasSavingsDialogContentSection>
        </Grid>
        <Grid item xs={12}>
          <ColorBoxedValueList
            values={[
              {
                label: 'Efficiency',
                value: `${efficiency} mpg`,
                color: '#FB5842',
              },
              {
                label: 'Gas Type',
                value: gasType,
                color: '#FB5842',
              },
              {
                label: `Gas Cost in ${gasCost?.zipcode ?? '...'}`,
                value: (
                  <EditableGasPriceTag
                    initialGasPriceCents={
                      gasCost?.average_gas_cost_cents_per_gallon
                    }
                    onChange={handleGasPriceValueChange}
                  />
                ),
                color: showGasPriceAsCorrect ? '#00922B' : '#B4B4B4',
              },
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.buttonContainer}>
            <SaveButton onClick={onFormSubmit} saveState={saveButtonState} />
          </Box>
        </Grid>
      </Grid>
    </DialogContent>
  )
}
