import React, { Component } from 'react'

class TermsOfService extends Component {
  constructor(props) {
    super(props)
    this.state = {
      testimonial: {},
    }
  }

  render() {
    return (
      <React.Fragment>
        <section
          className={
            'testimonial-section ptb-100 ' +
            (this.props.bgColor && this.props.bgColor === 'gray'
              ? 'gray-light-bg'
              : '')
          }
        >
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-12">
                <div className="section-heading mb-5">
                  <h2>Sweepstakes Customer Terms & Conditions</h2>
                  <h3>
                    California and Texas Sweepstakes by Optiwatt and Leap
                    Official Rules
                  </h3>

                  <h3>1. No Purchase Necessary; Entry Instructions.</h3>
                  <p className="lead">
                    NO PURCHASE OR PAYMENT OF ANY MONEY IS NECESSARY TO ENTER. A
                    PURCHASE WILL NOT IMPROVE THE CHANCES OF WINNING. ODDS OF
                    WINNING WILL DEPEND ON THE TOTAL NUMBER OF ELIGIBLE ENTRIES
                    RECEIVED. VOID WHERE PROHIBITED OR WHERE REGISTRATION,
                    BONDING OR LOCALIZATION REQUIRED. SUBJECT TO ALL NATIONAL,
                    STATE AND LOCAL LAWS.
                  </p>
                  <p className="lead">
                    This sweepstakes (“Promotion”) is sponsored and administered
                    by Leap (“Sponsor”) and is governed by these official rules
                    (“Official Rules”). To participate in the Promotion and be
                    eligible for a potential prize, entrants must be existing
                    Sponsor customers and registered with Sponsor.
                  </p>
                  <p className="lead">
                    1. <u>Sponsor.</u> This Promotion is sponsored and
                    administered by Leapfrog Power, Inc. dba Leap (“Sponsor”) in
                    coordination with Optiwatt (“Optiwatt").
                  </p>
                  <p className="lead">
                    In the event of a dispute as to the identity of an entrant,
                    the authorized holder of the email account associated with
                    the account used to register with Sponsor will be deemed to
                    be the entrant. The “authorized holder” is the natural
                    person assigned an account or email address by an Internet
                    access provider, online service provider, or other
                    organization responsible for assigning accounts or email
                    addresses. Each potential winner may be required to show
                    proof of being the authorized holder. Sponsor will make
                    final determination of identity of participants and timing
                    of entries in its sole discretion.
                  </p>

                  <h3>2. Promotion Period.</h3>
                  <p className="lead">
                    The Promotion will run from 12:00 a.m. Pacific Time (“PT”)
                    on August 6, 2022 through 11:59 p.m. PT on December 31,
                    2022.
                  </p>

                  <h3>3. Eligibility.</h3>
                  <p className="lead">
                    In order to be eligible, entrants must be at least 18 years
                    of age or such higher age of majority in the resident’s
                    jurisdiction and a legal resident physically located in
                    California or Texas at the time of entry. By participating,
                    you agree to these Official Rules. Employees of Sponsor and
                    any of their parent and affiliate companies, the immediate
                    family (spouse, parents, siblings and children) and
                    household members of each such employee, and any person
                    involved in the administration and execution of this
                    Promotion, and any of their immediate family or household
                    members, are not eligible.
                  </p>

                  <h3>4. Entry Methods. </h3>
                  <p className="lead">
                    Optiwatt customers who have registered accounts with Sponsor
                    during the Promotion Period will be eligible receiving one
                    or more entries as described below. Optiwatt customers and
                    individuals who are not Optiwatt customers may also enter by
                    sending an email with “Enter Me in the Optiwatt Sweepstakes”
                    in the subject line and their name, email, address and phone
                    number in the body of the email to
                    tesla-sweepstakes@leap.ac. One entry per email.
                  </p>
                  <p className="lead">
                    One drawing entry will be awarded to a Optiwatt user for
                    every meter that is registered to their Optiwatt account
                    that meets the following requirements:
                    <ul
                      style={{
                        listStyleType: 'circle',
                        listStylePosition: 'inside',
                      }}
                    >
                      <li>
                        The meter must:
                        <ul
                          style={{
                            listStyleType: 'square',
                            listStylePosition: 'inside',
                            paddingLeft: '24px',
                          }}
                        >
                          <li>
                            Be tied to an account with an address in California
                            or Texas
                          </li>
                          <li>
                            Be successfully registered to Optiwatt as “eligible”
                            in the Leap partner portal, and with a participation
                            status of “participate,” during the Promotion Period
                          </li>
                          <li>
                            Remain successfully registered to Optiwatt as
                            eligible in the partner portal through the end of
                            the Promotion Period
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </p>
                  <p className="lead">
                    One drawing entry will be awarded to a Optiwatt user for
                    every VIN or thermostat device ID that they refer to be
                    registered to another account on Optiwatt that meets the
                    following requirements:
                    <ul
                      style={{
                        listStyleType: 'circle',
                        listStylePosition: 'inside',
                      }}
                    >
                      <li>
                        The meter of the referring account must:
                        <ul
                          style={{
                            listStyleType: 'square',
                            listStylePosition: 'inside',
                            paddingLeft: '24px',
                          }}
                        >
                          <li>
                            Be tied to an account with an address in California
                            or Texas
                          </li>
                          <li>
                            Be successfully registered to Optiwatt as eligible
                            in the partner portal at the end of the Promotion
                            Period
                          </li>
                        </ul>
                      </li>
                      <li>
                        The VIN or thermostat device ID of the referred account
                        must:
                        <ul
                          style={{
                            listStyleType: 'square',
                            listStylePosition: 'inside',
                            paddingLeft: '24px',
                          }}
                        >
                          <li>
                            Be tied to an account with an address in the United
                            States
                          </li>
                          <li>
                            Be successfully registered to Optiwatt as
                            “authenticated” between during the Promotion Period
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </p>
                  <p className="lead">
                    Regardless of method of entry, entries are capped at 200
                    entries per person. Any person who enters the Promotion
                    drawing or attempts to do so may be disqualified and
                    ineligible to receive the Prize.
                  </p>

                  <h3>5. Prizes.</h3>
                  <p className="lead">
                    There will be 1 prize awarded. Winner will have the
                    opportunity to claim the following prize (a “Prize”): Tesla
                    Model 3 (approximate retail value, or “ARV”: $47,000); . The
                    prizes will be subject to any terms of the manufacturer and
                    issuer. Sponsor has the right to determine model details in
                    its sole discretion. No substitution, assignment or transfer
                    of the prizes is permitted, except by Sponsor, who has the
                    right to substitute a prize with another of comparable or
                    greater value. TAXES AND ANY OTHER COSTS, IF ANY, RELATED TO
                    THE PRIZE ARE THE RESPONSIBILITY OF THE WINNER. Prior to
                    taking possession of the prize, winner will be required to
                    present a valid driver’s license and proof of insurance.
                    Prize will be purchased with all features included in
                    standard / base pricing, including color and interior
                    options. Any additional car features not included in the
                    standard / base pricing will not be included and may be
                    added at an additional cost by the winner.
                  </p>

                  <h3>6. Winner.</h3>
                  <p className="lead">
                    Winner of the Promotion will be randomly selected by Sponsor
                    or its designee. Winner will be selected following the
                    Promotion and will be contacted directly through their email
                    address, or other contact method available to Sponsor.
                    Sponsor is not responsible for any delay or failure to
                    receive notification for any reason, including inactive
                    account(s), technical difficulties associated therewith, or
                    Winner’s failure to adequately monitor any account. The
                    winner must then respond to Sponsor within fourteen (14)
                    days. Should the Winner fail to respond to Sponsor, Sponsor
                    reserves the right to disqualify that winner and select a
                    new one in a second-chance random drawing. Winner may be
                    required to sign an affidavit of eligibility, liability
                    release and a publicity release. By entering or accepting
                    any prize, winner allows Sponsor to use the winner’s name,
                    photograph, likeness, voice, quotes, prize information,
                    customer status, and biographical information for publicity
                    and promotional purposes without further compensation where
                    permitted by law. Except where prohibited, participation in
                    the Promotion constitutes participant’s consent to Sponsor’s
                    use of participant’s name, likeness, voice, quotes opinions,
                    customer status, biographical information, hometown and
                    state for promotional purposes in any media without further
                    payment or consideration, and Prize winners agree to be
                    briefly interviewed by Sponsor.
                  </p>

                  <h3>7. Conditions of Participation.</h3>
                  <p className="lead">
                    By submitting an entry for this Promotion, you agree to
                    abide by these Official Rules and any decision Sponsor makes
                    regarding this Promotion, which Sponsor shall make in its
                    sole discretion. Sponsor reserves the right to disqualify
                    and prosecute to the fullest extent permitted by law any
                    participant or winner who, in Sponsor’s reasonable
                    suspicion, tampers with Sponsor’s site, the entry process,
                    intentionally submits more than a single entry, violates
                    these Official Rules, or acts in an unsportsmanlike or
                    disruptive manner. Entrant acknowledges that Sponsor may
                    include entrant in future contests, sweepstakes and
                    promotions.
                  </p>

                  <h3>8. DISCLAIMER, RELEASE AND LIMIT OF LIABILITY.</h3>
                  <p className="lead">
                    SPONSOR MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND,
                    EXPRESS OR IMPLIED, REGARDING ANY PRIZE OR YOUR
                    PARTICIPATION IN THE PROMOTION. BY ENTERING THE PROMOTION OR
                    RECEIPT OF ANY PRIZE, EACH ENTRANT AGREES TO RELEASE AND
                    HOLD HARMLESS SPONSOR AND THEIR SUBSIDIARIES, AFFILIATES,
                    SUPPLIERS, DISTRIBUTORS, ADVERTISING/PROMOTION AGENCIES, AND
                    PRIZE SUPPLIERS, AND EACH OF THEIR RESPECTIVE PARENT
                    COMPANIES AND EACH SUCH COMPANY’S OFFICERS, DIRECTORS,
                    EMPLOYEES AND AGENTS (COLLECTIVELY, THE “RELEASED PARTIES”)
                    FROM AND AGAINST ANY CLAIM OR CAUSE OF ACTION, INCLUDING,
                    BUT NOT LIMITED TO, PERSONAL INJURY, DEATH, OR DAMAGE TO OR
                    LOSS OF PROPERTY, ARISING OUT OF PARTICIPATION IN THE
                    PROMOTION OR RECEIPT OR USE OR MISUSE OF ANY PRIZE. THE
                    RELEASED PARTIES ARE NOT RESPONSIBLE FOR: (1) ANY INCORRECT
                    OR INACCURATE INFORMATION, WHETHER CAUSED BY ENTRANTS,
                    PRINTING ERRORS OR BY ANY OF THE EQUIPMENT OR PROGRAMMING
                    ASSOCIATED WITH OR UTILIZED IN THE PROMOTION; (2) TECHNICAL
                    FAILURES OF ANY KIND, INCLUDING, BUT NOT LIMITED TO
                    MALFUNCTIONS, INTERRUPTIONS, OR DISCONNECTIONS IN PHONE
                    LINES OR NETWORK HARDWARE OR SOFTWARE; (3) UNAUTHORIZED
                    HUMAN INTERVENTION IN ANY PART OF THE ENTRY PROCESS OR THE
                    PROMOTION; (4) TECHNICAL OR HUMAN ERROR WHICH MAY OCCUR IN
                    THE ADMINISTRATION OF THE PROMOTION OR THE PROCESSING OF
                    ENTRIES; OR (5) ANY INJURY OR DAMAGE TO PERSONS OR PROPERTY
                    WHICH MAY BE CAUSED, DIRECTLY OR INDIRECTLY, IN WHOLE OR IN
                    PART, FROM ENTRANT’S PARTICIPATION IN THE PROMOTION OR
                    RECEIPT OR USE OR MISUSE OF ANY PRIZE. WINNER MUST BE AWARE
                    THAT USE OF THE PRIZE CAN BE HAZARDOUS AND CAN PRESENT
                    UNUSUAL RISKS OF DEATH, SERIOUS ILLNESS AND INJURY, AND
                    PROPERTY DAMAGE. WINNER PARTICIPATES IN USE OF THE PRIZE
                    WITH KNOWLEDGE OF THE DANGER INVOLVED AND AGREES TO ACCEPT
                    ANY AND ALL RISKS OF INJURY, ILLNESS, DEATH AND PROPERTY
                    DAMAGE. Sponsor reserves the right at its sole discretion to
                    cancel, terminate, modify or suspend the Promotion,
                    including if for any reason it is not capable of running as
                    planned, including infection by computer virus, bugs,
                    tampering, unauthorized intervention, fraud, technical
                    failures, or any other causes which corrupt or affect the
                    administration, security, fairness, integrity, or proper
                    conduct of the Promotion. If for any reason an entrant's
                    Entry is confirmed to have been erroneously deleted, lost,
                    or otherwise destroyed or corrupted, entrant’s sole remedy
                    is another entry in the Promotion, provided that if it is
                    not possible to award another entry due to discontinuance of
                    the promotion, or any part of it, for any reason, Sponsor,
                    at its discretion, may elect to hold a random drawing from
                    among all eligible entries received up to the date of
                    discontinuance for any or all of the prizes offered herein.
                    No more than the stated number of prizes will be awarded. In
                    event that production, technical, programming or any other
                    reasons cause more than the stated number of prizes as set
                    forth in these Official Rules to be available and/or claimed
                    Sponsor reserves the right to award only the stated number
                    of prizes by a random drawing among all legitimate,
                    unawarded, eligible prize claims.
                  </p>

                  <h3>9. Governing Law.</h3>
                  <p className="lead">
                    This Promotion is governed by, and will be construed in
                    accordance with, the laws of the state of California, and
                    the forum and venue for any dispute shall be in the United
                    States District Court for the Northern District of
                    California or the appropriate California state court. If the
                    controversy or claim is not otherwise resolved through
                    direct discussions or mediation, it shall then be resolved
                    by final and binding arbitration administered by the
                    American Arbitration Association in accordance with its
                    arbitration rules and procedures or subsequent versions
                    thereof (“AAA Rules”). The AAA Rules for selection of an
                    arbitrator shall be followed, except that the arbitrator
                    shall be experienced and licensed to practice law in
                    California. The remedy for any claim shall be limited to
                    actual damages, and in no event shall any party be entitled
                    to recover punitive, exemplary, consequential or incidental
                    damages or have damages multiplied or otherwise increased,
                    including attorneys’ fees or other such related costs of
                    bringing a claim, or to rescind this agreement or seek
                    injunctive or any other equitable relief. Entrants agree
                    that the rights and obligations of any entrant and/or
                    Released Parties and/or any other party shall be resolved
                    individually, without resort to any form of class action.
                    Any demand for arbitration must be filed within one (1) year
                    of the time the cause of action occurred, or the cause of
                    action shall be forever barred.
                  </p>

                  <h3>10. No Affiliation with Third Parties.</h3>
                  <p className="lead">
                    This Promotion is sponsored and administered exclusively by
                    Sponsor.
                  </p>

                  <h3>11. Winner(s) List.</h3>
                  <p className="lead">
                    Individuals may request the name of the Winner(s) by
                    submitting an email request to stephanie@leap.ac prior to
                    April 30, 2023.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

export default TermsOfService
