import * as actions from '../actions/utilities'
import * as homeActions from '../actions/homes'
import { PatchUserGridSupport } from '../actions/user'
import { getApiErrorMessage } from '../utils/errorUtilities'
import { Utility, UtilitySearchResult } from '@/types'
import { PayloadAction } from '@reduxjs/toolkit'

type State = {
  allUtilities: UtilitySearchResult[] | null
  utilities: Utility[] | null
  selectedUtility: Utility | null
  plans: Utility['plan_options'] | null
  gridSupportConfirmationPending: boolean
  isLoading: boolean
  hasFetched: boolean
  errors: Record<string, unknown>
}

const initialState: State = {
  allUtilities: null,
  utilities: null,
  selectedUtility: null,
  plans: null,
  gridSupportConfirmationPending: false,
  isLoading: false,
  errors: {},
  // true when a fetch has finished, even if it failed
  hasFetched: false,
}

export default (state = initialState, action: PayloadAction<any>): State => {
  switch (action.type) {
    case actions.ALL_LIST_SUCCESS:
      return {
        ...state,
        allUtilities: action.payload,
        isLoading: false,
      }
    case actions.LIST_SUCCESS:
      return {
        ...state,
        utilities: action.payload,
        selectedUtility: action.payload[0],
        plans: action.payload[0]?.plan_options,
        isLoading: false,
        errors: {},
        hasFetched: true,
      }
    case actions.CREATE_SUCCESS:
      return {
        ...state,
        utilities: [...(state.utilities ?? []), action.payload],
        selectedUtility: action.payload,
        isLoading: false,
        errors: {},
      }
    case actions.SELECT_UTILITY_SUCCESS:
      return {
        ...state,
        utilities: null,
        selectedUtility: action.payload?.profile?.utility,
        isLoading: false,
        errors: {},
      }
    case actions.ALL_LIST_REQUEST:
    case actions.LIST_REQUEST:
    case actions.CREATE_REQUEST:
    case actions.MAKE_GRID_SUPPORT_SELECTION_REQUEST:
    case actions.SELECT_UTILITY_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case actions.ALL_LIST_FAILURE:
    case actions.LIST_FAILURE:
    case actions.CREATE_FAILURE:
    case actions.MAKE_GRID_SUPPORT_SELECTION_FAILURE:
    case actions.SELECT_UTILITY_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: getApiErrorMessage(action.payload),
        hasFetched: true,
      }
    case actions.CLEAR_UTILITY_ERRORS:
      return {
        ...state,
        errors: {},
      }
    case homeActions.CREATE_SUCCESS:
      return {
        ...state,
      }
    case actions.MAKE_GRID_SUPPORT_SELECTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case actions.SET_GRID_SUPPORT_CONFIRMATION_PENDING:
      return {
        ...state,
        gridSupportConfirmationPending: action.payload,
      }
    case PatchUserGridSupport.Success: {
      const userProfile = action.payload.profile
      return {
        ...state,
        selectedUtility: userProfile.utility,
      }
    }
    default:
      return state
  }
}
