import React from 'react'
import { ViewModelComponentProps } from '../../../utils/typeUtilities'
import TeslaGiveawayMeterConnectionCardWithDialog, {
  Props as ComponentProps,
} from './TeslaGiveawayMeterConnectionCardWithDialog'
import useViewModel from './useViewModel'

type Props = ViewModelComponentProps<ComponentProps, typeof useViewModel>

export default ({ ...props }: Props) => {
  const viewModel = useViewModel(props)

  return (
    <TeslaGiveawayMeterConnectionCardWithDialog {...viewModel} {...props} />
  )
}
