function getSign(value: number) {
  const sign = Math.sign(value)

  if (sign === 1) return '+'
  else if (sign === -1) return '-'
  else return ''
}

interface DollarFormattingOptions {
  // Number of decimal places to display
  decimals?: number
  // The format the input value is in
  format: 'cents' | 'dollars'
  // Returns a number (with no dollar sign) if true
  numeric?: boolean
  // Adds a + or - to the beginning of the output
  signed?: boolean
}

export function toDollars(
  value?: string | number,
  options?: DollarFormattingOptions,
) {
  const numericValue = Number(value || 0)

  if (!options) return `$${numericValue}`
  else {
    const numericDollars =
      options.format === 'cents'
        ? Math.abs(numericValue) / 100
        : Math.abs(numericValue)

    const decimals = options.decimals ?? false
    const dollars =
      typeof decimals === 'number'
        ? `${numericDollars.toFixed(decimals)}`
        : `${numericDollars}`
    const sign = options.signed ? getSign(numericValue) : ''

    return !options.numeric ? `${sign}$${dollars}` : Number(`${sign}${dollars}`)
  }
}

export function toDollarsComputation(
  value: number,
  fromCents = false,
  fixedPrecision = 2,
) {
  const dollars = fromCents ? value / 100 : value
  return Number(dollars.toFixed(fixedPrecision))
}
