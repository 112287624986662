import { useState } from 'react'
import { useAppDispatch } from '@/hooks'
import UtilityProgramLearnMoreCard from '../UtilityProgramLearnMoreCard'
import { DashboardConfigType } from '@/types/dashboardConfig'
import { addConfig } from '@/actions/dashboardConfigs'
import useHideDashboardConfig from '@/authenticated/hooks/useHideDashboardConfig'
import { useNavigation } from '@/app/hooks'
import useAvailableUtilityPrograms from '@/app/hooks/useAvailableUtilityPrograms'
import useProfileId from '@/hooks/useProfileId'

export default function UtilityProgramLearnMoreDashboardView() {
  const navigation = useNavigation()
  const dispatch = useAppDispatch()

  const profileId = useProfileId()
  const shouldHideFromConfig = useHideDashboardConfig(
    DashboardConfigType.UtilityProgram,
  )
  const [closeClicked, setCloseClicked] = useState(false)

  const { firstAvailableProgram, programsAreAvailable } =
    useAvailableUtilityPrograms()

  const onLearnMoreClose = () => {
    setCloseClicked(true)
    if (profileId) {
      dispatch(addConfig(DashboardConfigType.UtilityProgram, profileId))
    }
  }

  const onLearnMoreClick = () => {
    if (!firstAvailableProgram) {
      return
    }

    const customNav =
      firstAvailableProgram.view_config_json.learnMore.navigateTo
    const url = !!customNav ? customNav : '/utility-program-enrollment?flow=upe'
    navigation.push(url)
  }

  if (!programsAreAvailable || shouldHideFromConfig || closeClicked) {
    return null
  }

  // No view config
  if (firstAvailableProgram && !firstAvailableProgram.view_config_json) {
    return null
  }

  return (
    <UtilityProgramLearnMoreCard
      utilityProgram={firstAvailableProgram}
      onLearnMoreClick={onLearnMoreClick}
      onCloseClick={onLearnMoreClose}
    />
  )
}
