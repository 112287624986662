import { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  Button as MuiButton,
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogTitle,
  FormControl as MuiFormControl,
  Grid as MuiGrid,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  TextField as MuiTextField,
  Typography as MuiTypography,
} from '@material-ui/core'
import { spacing } from '@material-ui/system'
import { DEFAULT_START_TIME } from '../ChargeSchedule/ScheduleDaysOfWeek'
import { useDistanceUnit } from '../../hooks/conversions'
import { useFeatureFlag } from '@/hooks/useFeatureFlag'

// const ColoredSwitch = withStyles({
//     switchBase: {
//         color: blue[800],
//         '&$checked': {
//             color: blue[500],
//         },
//         '&$checked + $track': {
//             backgroundColor: blue[500],
//         },
//     },
//     checked: {},
//     track: {},
// })(Switch);

const TextFieldSpacing = styled(MuiTextField)(spacing)

const TextField = styled(TextFieldSpacing)`
  width: 200px;
`

const FormControl = styled(MuiFormControl)(spacing)
const Dialog = styled(MuiDialog)(spacing)
const Grid = styled(MuiGrid)(spacing)
const Button = styled(MuiButton)(spacing)
const TypographySpacing = styled(MuiTypography)(spacing)
const DialogContent = styled(MuiDialogContent)(spacing)

const Typography = styled(TypographySpacing)``

const Select = styled(MuiSelect)`
  width: 80px;
`

const SmallTextField = styled(TextFieldSpacing)`
  width: 70px;
`

export const MILES = 'miles'
export const KILOMETERS = 'kilometers'
export const PERCENT = 'percent'

function TripsUnderConstructionContent(props) {
  return (
    <Dialog open={props.open} onClose={() => props.onClose()}>
      <DialogTitle>
        🚧 The Trips feature is currently under maintenance.
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" gutterBottom>
          It will be available again soon.
        </Typography>
      </DialogContent>
    </Dialog>
  )
}

function TripDialog(props) {
  let startDate = props.date
  let startTime = DEFAULT_START_TIME
  let initialBatteryUnit = PERCENT
  let batteryTarget = null
  let tripId = null
  if (props.trip) {
    tripId = props.trip.id
    startDate = props.trip.date_local_timezone
    startTime = props.trip.time_local_timezone
    initialBatteryUnit = props.trip.battery_target_unit
    batteryTarget = props.trip.battery_target_max
  }
  const [batteryUnit, setBatteryUnit] = useState(initialBatteryUnit)
  const [distanceUnit] = useDistanceUnit()
  const underConstruction = useFeatureFlag({
    flag: 'trips_under_construction',
  })

  useEffect(() => {
    setBatteryUnit(initialBatteryUnit)
  }, [initialBatteryUnit])

  function changeUnit(event) {
    event.preventDefault()
    setBatteryUnit(event.target.value)
  }

  function submit(event) {
    props.onSubmit(event, tripId)
  }

  function handleDelete(event) {
    event.preventDefault()
    props.handleDelete(tripId)
  }

  const isMiles = distanceUnit === MILES

  if (underConstruction) {
    return (
      <TripsUnderConstructionContent
        open={props.open}
        onClose={() => props.onClose()}
      />
    )
  }

  return (
    <Dialog maxWidth={'sm'} open={props.open} onClose={() => props.onClose()}>
      <DialogTitle>Enter upcoming trip preferences</DialogTitle>
      <DialogContent mt={3} mb={5}>
        <form onSubmit={submit}>
          <Typography variant="body2" gutterBottom>
            Ready By time
          </Typography>
          <TextField
            required
            id="date"
            label="Date"
            type="date"
            defaultValue={startDate}
            InputLabelProps={{
              shrink: true,
            }}
            m={2}
          />
          <TextField
            required
            id="time"
            label="Time"
            name="time"
            type="time"
            defaultValue={startTime}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
            m={2}
          />
          <Typography variant="body2" gutterBottom mt={5}>
            Target Battery
          </Typography>
          <FormControl m={2}>
            <InputLabel id="demo-simple-select-placeholder-label-label">
              Unit
            </InputLabel>
            <Select
              id="demo-simple-select-placeholder-label"
              labelId="demo-simple-select-placeholder-label-label"
              name="unit"
              value={batteryUnit}
              onChange={changeUnit}
            >
              <MenuItem value={PERCENT}>percent</MenuItem>
              {isMiles ? (
                <MenuItem value={MILES}>miles</MenuItem>
              ) : (
                <MenuItem value={KILOMETERS}>km</MenuItem>
              )}
            </Select>
          </FormControl>
          <SmallTextField
            mr={2}
            mt={2}
            required
            id="standard-basic"
            defaultValue={batteryTarget}
            InputProps={{
              inputProps: {
                max: batteryUnit === PERCENT ? 100 : 500,
                min: 50,
              },
            }}
            label="Target"
            name="max"
            type="number"
          />
          {/*<Typography variant="body2" gutterBottom mt={5}>*/}
          {/*    Preconditioning*/}
          {/*</Typography>*/}
          {/*<FormControl m={2}>*/}
          {/*    <Typography component="div">*/}
          {/*        <Grid component="label" container alignItems="center" spacing={1}>*/}
          {/*            <Grid item>Off</Grid>*/}
          {/*            <Grid item>*/}
          {/*                <ColoredSwitch checked={true}/>*/}
          {/*            </Grid>*/}
          {/*            <Grid item>On</Grid>*/}
          {/*        </Grid>*/}
          {/*    </Typography>*/}
          {/*    <FormControlLabel*/}
          {/*        value="top"*/}
          {/*        control={<Checkbox color="primary" size="small"/>}*/}
          {/*        label="Precondition HVAC"*/}
          {/*        labelPlacement="right"*/}
          {/*    />*/}
          {/*</FormControl>*/}
          <Grid container justifyContent="center" spacing={0} mt={3}>
            <Button type="submit" variant="contained" color="primary">
              {props.trip ? 'Update' : 'Submit'}
            </Button>
            {props.trip && (
              <Button
                ml={2}
                variant="contained"
                color="default"
                onClick={handleDelete}
              >
                Delete
              </Button>
            )}
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default TripDialog
