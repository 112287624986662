import { useAppSelector } from '../../../../hooks'
import useMockableViewModel from '../../../../hooks/useMockableViewModel'

function useViewModel() {
  const plans = useAppSelector((state) => state.utilities.plans)
  const isLoading = useAppSelector(
    (state) => state.plans.isLoading || state.utilities.isLoading,
  )

  return { isLoading, planOptions: plans ?? [] }
}

function useMockViewModel() {
  return {
    isLoading: false,
    planOptions: [], // there was a typescript error here
  }
}

export default useMockableViewModel({ useViewModel, useMockViewModel })
