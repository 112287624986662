import {
  CardActions,
  CardActionButton,
  CardNavigation,
  Icon,
} from '../../../../components'
import { FlowStep, Question } from '../../../components'
import UtilityPlanDropdown from '../../utilities/UtilityPlanDropdown'
import data from './resources/data.json'

export interface Props {
  selectedPlanId?: string | null
  onChange: (planId: number) => void
  onClose?: () => void
  onBack?: () => void
  onFinish: () => void
  disabled?: boolean
}

export default function SelectUtilityPlanFlowStep(props: Props) {
  return (
    <FlowStep id="select-utility-plan-flow">
      <FlowStep.Content>
        <CardNavigation
          id="select-utility-plan-card-navigation"
          onCloseClick={props.onClose}
        />
        <Question title={data.question} subtitle={data.subtitle}>
          <UtilityPlanDropdown
            label={data.dropdownLabel}
            onChange={props.onChange}
            selectedPlanId={props.selectedPlanId ?? undefined}
          />
        </Question>
      </FlowStep.Content>
      <FlowStep.Actions>
        <CardActions>
          {props.onBack && (
            <CardActionButton
              type="navigation"
              id="select-utility-plan-card-back-button"
              onClick={props.onBack}
            >
              <Icon name="ChevronLeft" color="grey-500" size={20} />
            </CardActionButton>
          )}
          <CardActionButton
            type="primary"
            id="select-utility-plan-card-select-button"
            disabled={props.disabled}
            onClick={props.onFinish}
          >
            Finish
          </CardActionButton>
        </CardActions>
      </FlowStep.Actions>
    </FlowStep>
  )
}
