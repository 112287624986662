import { Link } from 'app-components'
import MultilineSelect, {
  MultilineSelectOption,
} from '@/app/components/MultilineDropdown/MultilineSelect'
import GoogleMapsSearchWithMap from '@/authenticated/components/forms/GoogleMapsSearchWithMap'
import useViewModel, {
  GoogleLocationEvent,
} from '@/app/components/AddressSelector/useViewModel'

export type AddressSelectorProps = {
  addresses: MultilineSelectOption[]
  onAddressChange?: (event: GoogleLocationEvent) => void
  initialAddress?: string
}

export default function AddressSelector(props: AddressSelectorProps) {
  const {
    manualEntry,
    selectedAddress,
    addressSelectionChange,
    handleMapChange,
    manualEntryOption,
    resetToSelection,
    addresses,
    hasAddressesAvailable,
  } = useViewModel(props)

  return (
    <>
      {!manualEntry && hasAddressesAvailable && (
        <MultilineSelect
          className="mb-5"
          options={[...addresses, manualEntryOption]}
          selectedOption={selectedAddress}
          onChange={addressSelectionChange}
        ></MultilineSelect>
      )}
      <GoogleMapsSearchWithMap
        hideAutocomplete={!manualEntry}
        disableMapClick={!manualEntry}
        label={'Home Address'}
        initialLocation={{
          address: selectedAddress?.line1,
        }}
        handleChange={handleMapChange}
      />
      {manualEntry && hasAddressesAvailable && (
        <div className="mt-2">
          <Link onClick={resetToSelection}>Use existing address?</Link>
        </div>
      )}
    </>
  )
}
