import { selectUtilityProgramEnrollmentForUser } from '@/reducers/utilityProgramEnrollments'
import { selectUtilityProgramForUserUtility } from '@/reducers/utilityPrograms'
import { selectVehicleEnrollmentForUser } from '@/selectors'
import { RootState } from '@/store'
import { ID } from '@/types/model'
import { createSelector } from '@reduxjs/toolkit'

/**
 * Selects the view config from all valid sources in priority order:
 * 1. Vehicle enrollment (if vehicle id provided)
 * 2. Utility program enrollment
 * 3. Utility program
 */
const selectProgramViewConfigFromAllSources = createSelector(
  [
    (state: RootState) => selectUtilityProgramEnrollmentForUser(state),
    (state: RootState) => selectUtilityProgramForUserUtility(state),
    (state: RootState, vehicleId?: ID) =>
      vehicleId ? selectVehicleEnrollmentForUser(state, vehicleId) : undefined,
  ],
  (programEnrollment, program, vehicleEnrollment) => {
    return (
      vehicleEnrollment?.view_config_json ??
      programEnrollment?.utility_program?.view_config_json ??
      program?.view_config_json
    )
  },
)

export default selectProgramViewConfigFromAllSources
