import { createResourceCollection } from '../request'
import { Home } from '@/types'

export function useHomeCollection() {
  const { data, status } = homesCollection.useFetch()
  const home = data[0] as Home | undefined

  return home
}

export const homesCollection = createResourceCollection<Home>({
  name: 'homesCollection',
  apiConfig: {
    path: 'homes',
  },
  selector: (state) => state.homeCollection,
})

const homesReducer = homesCollection.slice.reducer

export default homesReducer
