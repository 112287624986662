import { SelectOption } from './SelectInput'

function isSelectOption(value: any): value is SelectOption {
  return typeof value === 'object' && 'value' in value
}

type Props = {
  options: (string | SelectOption)[]
}

export default function useViewModel({ options }: Props) {
  const selectOptions: SelectOption[] = options.map((option) => {
    if (isSelectOption(option)) {
      return option
    }
    return {
      value: option,
    }
  })

  return {
    selectOptions,
  }
}
