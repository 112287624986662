import { useEffect, useRef } from 'react'
import './styles.scss'

const BEE_VELOCITY = 5
const beePath = [
  {
    x: innerWidth / 20 + 5,
    y: innerHeight / 6,
  },
  {
    x: innerHeight / 7,
    y: innerHeight / 1.5,
  },
  {
    x: innerWidth - 64,
    y: innerHeight / 6,
  },
  {
    x: innerWidth / 2 + 32,
    y: innerHeight / 2 - 48,
  },
]
let beeStep = 0

export default function Bee() {
  const beeRef = useRef<HTMLDivElement>(null)

  //function to move the bee according to the beePath variable
  const moveBee = () => {
    if (!beeRef.current) return

    if (beeRef.current.style.left.length > 0) {
      const bx = parseFloat(beeRef.current.style.left.slice(0, -2))
      const by = parseFloat(beeRef.current.style.top.slice(0, -2))

      const xDist = beePath[beeStep].x - bx
      const yDist = beePath[beeStep].y - by

      const pathAng = Math.atan2(yDist, xDist)
      const xSpeed = BEE_VELOCITY * Math.cos(pathAng)
      const ySpeed = BEE_VELOCITY * Math.sin(pathAng)

      if (Math.abs(xDist) < 3 && Math.abs(yDist) < 3) {
        if (beeStep < beePath.length - 1) {
          beeStep++
          requestAnimationFrame(moveBee)
        }
      } else {
        xSpeed > 0
          ? beeRef.current.classList.add('flip')
          : beeRef.current.classList.remove('flip')
        beeRef.current.style.left = bx + xSpeed + 'px'
        beeRef.current.style.top = by + ySpeed + 'px'
        requestAnimationFrame(moveBee)
      }
    } else {
      beeRef.current.style.left = innerWidth / 2 - 7.05 + 'px'
      beeRef.current.style.top = innerHeight / 2 - 7.05 + 'px'
      requestAnimationFrame(moveBee)
    }
  }

  useEffect(() => {
    moveBee()
  }, [])

  return (
    <div ref={beeRef} className="bee">
      <div className="wings"></div>
      <div className="limbs"></div>
    </div>
  )
}
