import { ChargeForecast, ChargeSchedule } from '@/types'
import { Mode } from '.'
import { ChargeReasonType, Reasons } from '../types'
import { getChargeReasonTypes } from '../utils'
import { Divider, ExpansionPanel, Icon, Text } from '@/components'
import { IconNames } from '@/components/Icon/Icon'
import { logEvent } from '@/logging'
import { useNavigation } from '@/app/hooks'

type ChargeReasonsProps = {
  mode: Exclude<Mode, 'NoCharge' | 'CurrentlyCharging'>
  chargeSchedule: ChargeSchedule
  chargeForecast: ChargeForecast
  deviceType: 'vehicle' | 'vehicle_charger'
  deviceId: number
  onProgramEnrollmentClick: () => void
}
export default function ChargeReasons({
  mode,
  chargeSchedule,
  chargeForecast,
  deviceType,
  deviceId,
  onProgramEnrollmentClick,
}: ChargeReasonsProps) {
  const navigation = useNavigation()
  const ChargeReasonMap: { [key in ChargeReasonType]: Reasons } = {
    MoneySaving: {
      icon: 'Piggy',
      text: 'Save money enabled',
      linkTo: `/devices/${deviceType}/${deviceId}/goals`,
      linkText: 'Manage',
      id: 'moneysaving',
    },
    PlanetSaving: {
      icon: 'Heart',
      text: 'Save the planet enabled',
      linkTo: `/devices/${deviceType}/${deviceId}/goals`,
      linkText: 'Manage',
      id: 'planetsaving',
    },
    ProgramEnrollment: {
      icon: 'Zap',
      text: 'Grid event',
      linkTo: onProgramEnrollmentClick,
      linkText: 'Opt-out',
      id: 'programenrollment',
    },
  }

  const reasonTypes: ChargeReasonType[] =
    mode === 'DREventScheduled'
      ? // if the user has an upcoming DREvent and they aren't plugged in awaiting a smart charge,
        // we only want the DR enrollment reason to show so they can manage their enrollment
        // if needed.
        ['ProgramEnrollment']
      : getChargeReasonTypes(chargeSchedule, chargeForecast)

  const reasons = reasonTypes.map((type) => ChargeReasonMap[type])

  const reasonIcons = reasons.map((reason) => reason.icon)

  if (reasons.length === 0) {
    return null
  }

  const onLinkClick = (reason: Reasons) => {
    if (typeof reason.linkTo === 'string') {
      navigation.push(reason.linkTo)
    } else {
      reason.linkTo()
    }
    logEvent(`chargeforecast_reasons_link_${reason.id}`)
  }

  return (
    <>
      <Divider />
      <div>
        <ExpansionPanel
          compact
          className="mb-2 bg-transparent"
          label={<SmartChargeScheduleLabel reasonIcons={reasonIcons} />}
        >
          <div className="mt-6 flex flex-col gap-4">
            {reasons.map((reason) => (
              <div
                key={reason.icon}
                className="flex items-center justify-between"
              >
                <div className="flex items-center gap-2">
                  <Icon color="grey-500" name={reason.icon} className="w-5" />
                  <Text variant="body4">{reason.text}</Text>
                </div>

                <Text
                  variant="link"
                  onClick={(e) => {
                    onLinkClick(reason)
                    e?.stopPropagation()
                  }}
                >
                  {reason.linkText}
                </Text>
              </div>
            ))}
          </div>
        </ExpansionPanel>
      </div>
    </>
  )
}

export type SmartChargeScheduleLabelProps = {
  reasonIcons?: IconNames[]
}

function SmartChargeScheduleLabel({
  reasonIcons = [],
}: SmartChargeScheduleLabelProps) {
  return (
    <div className="flex items-center">
      <Text className="mr-3">Charge Reasons: </Text>
      {reasonIcons.map((name) => (
        <Icon color="grey-500" key={name} name={name} className="w-5 mr-2" />
      ))}
    </div>
  )
}
