import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import * as auth from '@/actions/auth'
import { useEffect } from 'react'
import { logEvent } from '@/logging'

export default function Google() {
  const location = useLocation()
  const dispatch = useDispatch()
  const history = useHistory()
  useEffect(() => {
    const params = new URLSearchParams(location.hash.replace('#', '?'))
    const access = params.get('access_token')
    const refresh = params.get('refresh_token')
    const isMobile = params.get('is_mobile')?.toLowerCase() === 'true'

    const signup = params.get('signup')
    if (signup) {
      logEvent(['sign_up', 'sign_up_google'])
    }

    if (isMobile) {
      window.location.href = `optiwatt://oauth-complete?access_token=${access}&refresh_token=${refresh}`
      setTimeout(() => {
        window.location.href = 'about:blank'
      }, 1000)
    } else {
      dispatch({
        type: auth.LOGIN_TOKEN_SUCCESS,
        payload: {
          access_token: access,
          refresh_token: refresh,
        },
      })
      history.push('/login')
    }
  }, [])
  return <></>
}
