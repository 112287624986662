import { surveyResponsesCollection } from '@/reducers/surveyResponses'

export function useOnboardingResponses() {
  const { data: surveyResponses, status: surveyResponsesStatus } =
    surveyResponsesCollection.useFetch({
      params: {
        pending_response: false,
      },
    })

  const onboardingSurveys = surveyResponses.find(
    (response) =>
      response.survey.type === 'Onboarding' &&
      response.pending_response === false,
  )

  const hasThermostat =
    (onboardingSurveys?.response?.ownsSmartThermostat as boolean) ?? false

  const hasVehicle =
    ((onboardingSurveys?.response?.numberOfEVs as number) ?? 0) > 0

  const hasCharger =
    (onboardingSurveys?.response?.ownsSmartCharger as boolean) ?? false

  return {
    hasThermostat,
    hasVehicle,
    hasCharger,
    isLoading:
      surveyResponsesStatus === 'loading' || surveyResponsesStatus === 'idle',
  }
}
