import { createResourceCollection } from '@/request'
import { ThermostatComfortSetting } from '@/types'

export const thermostatComfortSettingsCollection =
  createResourceCollection<ThermostatComfortSetting>({
    name: 'thermostatComfortSettings',
    selector: (state) => state.thermostatComfortSettings,
  })

export default thermostatComfortSettingsCollection.slice.reducer
