export default {
  question: 'How would you like to add your utility plan?',
  subtitle: (link) => (
    <>
      You can sign in to your utility’s website{' '}
      <a href={link} target="_blank" rel="noreferrer">
        here
      </a>
    </>
  ),
}
