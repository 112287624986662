import BrandedPage from '@/app/pages/ava/BrandedPage'
import { Button, LoadingAnimation } from '@/components'
import AddressSelector from '@/app/components/AddressSelector/AddressSelector'
import useViewModel from '@/app/pages/ava/onboarding/address-select/useViewModel'
import { AlertMessage } from 'app-components'

export default function AvaAddressSelectPage() {
  const {
    isLoading,
    addresses,
    subtitle,
    onAddressChange,
    address,
    isSubmitting,
    onNext,
    failureReason,
  } = useViewModel()

  const content = isLoading ? (
    <LoadingAnimation type={'falling'} centered />
  ) : (
    <>
      <AddressSelector
        addresses={addresses}
        onAddressChange={onAddressChange}
      />
      <BrandedPage.ButtonContainer>
        <Button
          id="next-button"
          variant="primary"
          disabled={!address || isSubmitting}
          onClick={onNext}
        >
          Next
        </Button>
      </BrandedPage.ButtonContainer>
    </>
  )

  return (
    <BrandedPage
      id="ava-address-select"
      title="Where do you consume energy?"
      navigationOptions={['nothing']}
      subtitle={subtitle}
    >
      {failureReason && (
        <AlertMessage variant="error" show className="mb-5">
          <span>{failureReason}</span>
        </AlertMessage>
      )}
      <BrandedPage.Content>{content}</BrandedPage.Content>
    </BrandedPage>
  )
}
