import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@material-ui/core/Button'
import { getChargeForecast } from '../../../actions/admin'
import { useChargeForecast } from '../../components/ChargeForecast/ChargeForecast'
import { ChargeForecastChartComponent } from '../../components/ChargeForecast/ChargeForecastChartComponent'
import Grid from '@material-ui/core/Grid'
import Loader from '../../components/layout/Loader'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 500,
  },
}))

export default function ChargeForecastAdmin({ userEmail = '' }) {
  let chargeForecast = useSelector((state) => state.admin.chargeForecast)
  const isLoading = useSelector((state) => state.admin.isLoading)
  const errors = useSelector((state) => state.admin.errors)
  if (process.env.NODE_ENV === 'development') {
    // For debugging
    // chargeForecast = chargeForecastData;
  }

  const [data, options] = useChargeForecast(chargeForecast)
  const [email, setEmail] = React.useState(userEmail)
  const [dateTime, setDateTime] = React.useState('')
  const classes = useStyles()

  const dispatch = useDispatch()

  function sendCommand() {
    dispatch(getChargeForecast({ email: email, datetime: dateTime }))
  }

  return (
    <React.Fragment>
      <form
        className={classes.container}
        noValidate
        style={{ padding: '10px' }}
      >
        <div>
          <TextField
            id="email"
            label="Email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <TextField
            id="datetime-local"
            label="Datetime (e.g. 2021-01-31T13:14)"
            type="datetime-local"
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setDateTime(e.target.value)}
          />
        </div>
      </form>
      <Button onClick={sendCommand} variant="contained" color="primary">
        Submit
      </Button>

      {data && (
        <Grid container>
          <Grid item xs={5}>
            <ChargeForecastChartComponent
              isEnabled={true}
              isOptimizedPlan={true}
              options={options}
              data={data}
            />
          </Grid>
        </Grid>
      )}

      {isLoading && <Loader />}
    </React.Fragment>
  )
}
