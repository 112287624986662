import useMockableViewModel from '../../../../hooks/useMockableViewModel'
import { useUsersPreferredTemperature } from '../../../../authenticated/hooks/useUsersPreferredTemperature'
import { TemperatureUnit } from '../../../../types/temperatureUnit'

function useViewModel() {
  return {
    unit: useUsersPreferredTemperature(0).unit as TemperatureUnit,
  }
}

function useMockViewModel() {
  return {
    unit: 'F' as TemperatureUnit,
  }
}

export default useMockableViewModel({ useViewModel, useMockViewModel })
