import { useAppSelector } from '.'
import { RequestStatus } from '@/request/types'

export function useSelectedUtility() {
  const utility = useAppSelector(
    (state) =>
      state.user.user?.profile?.utility ?? state.utilities.selectedUtility,
  )

  const requestStatus = useAppSelector((state) => {
    if (!state.utilities.hasFetched) {
      return RequestStatus.Idle
    } else if (state.utilities.isLoading || state.user.isFetchingUser) {
      return RequestStatus.Loading
    } else if (state.utilities.errors || state.user.errors) {
      return RequestStatus.Failed
    } else {
      return RequestStatus.Succeeded
    }
  })
  return {
    data: utility,
    status: requestStatus,
  }
}
