import TemperatureBarRoot, { Props as ComponentProps } from './TemperatureBar'
import useViewModel from './useViewModel'
import { ViewModelComponentProps } from '../../../../utils/typeUtilities'

export type Props = ViewModelComponentProps<ComponentProps, typeof useViewModel>

export default function TemperatureBar(props: Props) {
  const viewModel = useViewModel()

  return <TemperatureBarRoot {...viewModel} {...props} />
}
