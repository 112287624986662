import { ResourceModelQueryResult } from '@/request/types'
import {
  avaAddressesCollection,
  AvaAddressesResponse,
  AvaVerificationResponse,
} from '@/reducers/avaAddressesCollection'
import { MultilineSelectOption } from '@/app/components/MultilineDropdown/MultilineSelect'
import { useMemo, useState } from 'react'
import { useAppDispatch, useMockableViewModel } from '@/hooks'
import useProfileId from '@/hooks/useProfileId'
import { PayloadAction } from '@reduxjs/toolkit'
import { useNavigation } from '@/app/hooks'
import useProfileWhiteLabelService from '@/app/hooks/useProfileWhiteLabelService'
import { getUtilities } from '@/actions/utilities'
import { getPlans } from '@/actions/plans'
import { getCurrentUser } from '@/actions/user'
import { getHomes } from '@/actions/homes'
import { GoogleLocationEvent } from '@/app/components/AddressSelector/useViewModel'
import { surveyResponsesCollection } from '@/reducers/surveyResponses'
import { useSurveyResponder } from '@/app/components/SurveyObserver/reducer'
import { formatResponsesForSurvey } from '@/app/features/onboarding/OnboardingQuestionnaire/useViewModel'

function formatAvaAddresses(
  avaAddresses: ResourceModelQueryResult<
    AvaAddressesResponse | AvaVerificationResponse
  >[],
) {
  if (!avaAddresses || avaAddresses.length == 0) {
    return []
  }
  const response = (avaAddresses as AvaAddressesResponse[])?.[0]
  if (!response) {
    return []
  }

  let addresses = []
  if (typeof response.addresses === 'string') {
    addresses.push(response.addresses as string)
  } else {
    addresses = [...response.addresses]
  }

  const addressList = addresses.map((address, idx) => {
    return {
      label: idx === 0 ? 'Home Address' : `Home Address ${idx + 1}`,
      line1: address,
    } as MultilineSelectOption
  })

  return addressList ?? []
}

const useViewModel = () => {
  const dispatch = useAppDispatch()
  const profileId = useProfileId()
  const [address, setAddress] = useState('')
  const [failureReason, setFailureReason] = useState('')
  const { push: navigateTo } = useNavigation()
  const { submitResponse, activeSurveyResponse } =
    useSurveyResponder('Onboarding')
  const { removeProfileWhiteLabel } = useProfileWhiteLabelService()
  const { data: avaAddresses, isLoadingOrIdle } =
    avaAddressesCollection.useFetch(profileId ?? -1, {
      require: profileId !== null,
    })
  const [isSubmitting, setIsSubmitting] = useState(false)

  const addresses = useMemo(() => {
    return formatAvaAddresses(avaAddresses)
  }, [avaAddresses])

  const getAvaResponse = async () => {
    const avaResult: PayloadAction<AvaVerificationResponse> = await dispatch(
      avaAddressesCollection.actions.create(
        {
          address: address.trim(),
          profile_id: profileId,
        },
        { path: `/ava_addresses/${profileId}` },
      ),
    )

    if (avaResult.type === avaAddressesCollection.actionTypes.create.Failure) {
      setFailureReason(
        'There was an error authenticating your address. Please try again.',
      )
      return null
    }

    return avaResult
  }

  const onNext = async () => {
    setIsSubmitting(true)
    const avaResult = await getAvaResponse()
    setIsSubmitting(false)

    if (avaResult === null) {
      return
    }

    await submitResponse(
      formatResponsesForSurvey([
        {
          value: [address],
          key: 'homeAddress',
          order: 0,
        },
      ]),
      false,
    )

    // The user's utility may have been updated at this point, so we need to re-fetch
    await Promise.all([
      dispatch(getUtilities()),
      dispatch(getPlans()),
      dispatch(getCurrentUser()),
      dispatch(getHomes()),
      dispatch(surveyResponsesCollection.actions.invalidate()),
    ])

    if (!avaResult?.payload.is_covered) {
      await removeProfileWhiteLabel()
      navigateTo(
        '/onboarding?questionsToSkip=homeAddress&backTo=/ava/onboarding/address-select',
      )
      return
    }
    if (!avaResult?.payload.is_customer) {
      navigateTo('/ava/become-utility-customer?pageType=onboarding')
      return
    }
    if (!avaResult?.payload.is_verified) {
      navigateTo('/ava/onboarding/verify-residence')
      return
    }

    navigateTo(
      '/ava/onboarding?questionsToSkip=homeAddress&backTo=/ava/onboarding/address-select',
    )
  }

  const subtitle =
    addresses?.length > 0
      ? 'Select from one of your addresses on file with Ava or provide an alternative home address'
      : 'Enter your address or select your location on the map to confirm you are an Ava customer'

  return {
    addresses,
    subtitle,
    isLoading: isLoadingOrIdle,
    onAddressChange: (event: GoogleLocationEvent) =>
      setAddress(event.description),
    address,
    isSubmitting,
    onNext,
    failureReason,
  }
}

const useMockViewModel = () => {
  return {
    addresses: [],
    subtitle: 'subtitle',
    isLoading: false,
    onAddressChange: () => alert(`Address changed!`),
    address: '123 Reading Way',
    isSubmitting: false,
    onNext: async () => alert('Next!'),
    failureReason: '',
  }
}

export default useMockableViewModel({
  useViewModel,
  useMockViewModel,
})
