import * as form from '../actions/form'
import { getApiErrorMessage } from '../utils/errorUtilities'

const initialState = {
  hasContactFormSucceeded: false,
  contactFormLoading: false,
  errors: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case form.CONTACT_SUCCESS:
      return {
        ...state,
        hasContactFormSucceeded: true,
        contactFormLoading: false,
        errors: {},
      }
    case form.CONTACT_REQUEST:
      return {
        ...state,
        contactFormLoading: true,
      }
    case form.CONTACT_FAILURE:
      return {
        ...state,
        contactFormLoading: false,
        errors: getApiErrorMessage(action.payload),
      }
    case form.RESET_CONTACT_FORM:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export const hasContactFormSucceeded = (state) => state.hasContactFormSucceeded
export const contactFormLoading = (state) => state.contactFormLoading

export function errors(state) {
  return state.errors
}
