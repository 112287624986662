import { enrolledUtilityProgramsCollection } from '@/reducers/enrolledUtilityPrograms'

export default function useVehicleEnrollments(vehicleId: number) {
  const {
    data: enrolledPrograms,
    isLoadingOrIdle,
    error,
  } = enrolledUtilityProgramsCollection.useFetch()

  const enrollments = []
  for (const program of enrolledPrograms) {
    for (const enrollment of program.vehicle_enrollments) {
      if (enrollment.vehicle_id === vehicleId) {
        enrollments.push({
          enrollment,
          program: program.utility_program,
        })
      }
    }
  }

  return { enrollments, isLoading: isLoadingOrIdle, error }
}

/**
 * Returns the active enrollments for a vehicle,
 * where the enrollment is active if it has not been unenrolled and is eligible
 */
export function useVehicleActiveEnrollments(vehicleId: number) {
  const { enrollments, isLoading, error } = useVehicleEnrollments(vehicleId)
  const activeEnrollments = enrollments.filter((e) => {
    const hasntUnenrolled = e.enrollment.unenrolled_at === null
    const isEligible = e.enrollment.ineligible === false
    return hasntUnenrolled && isEligible
  })

  return { activeEnrollments, isLoading, error }
}

export function useVehicleHasActiveEnrollment(vehicleId: number) {
  const { activeEnrollments, isLoading, error } =
    useVehicleActiveEnrollments(vehicleId)

  return { hasActiveEnrollment: activeEnrollments.length > 0, isLoading, error }
}
