import React, { useEffect } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles<Theme, Props>((theme) => ({
  '@keyframes flash': {
    from: {
      backgroundColor: '#fff',
    },
    to: {
      backgroundColor: ({ color = theme.palette.blue?.background }) => color,
    },
  },
  flash: {
    animation: ({ speed = 200, flashes = 10, delay = 1000 }) =>
      `$flash linear ${speed}ms ${delay}ms ${flashes} alternate`,
    borderRadius: theme.spacing(4),
    margin: -theme.spacing(2),
    padding: theme.spacing(2),
  },
}))

export interface Props {
  flashes?: number
  delay?: number
  speed?: number
  active?: boolean
  color?: string
  scrollToElement?: boolean
  children: React.ReactNode
}

export default ({
  children,
  scrollToElement = false,
  active = true,
  delay = 1000,
  ...rest
}: Props) => {
  const classes = useStyles({
    ...rest,
    active,
    scrollToElement,
    children,
    delay,
  })
  const ref = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (scrollToElement && active && ref.current) {
      setTimeout(() => {
        ref.current?.scrollIntoView({ behavior: 'smooth' })
      }, delay)
    }
  }, [])

  if (!active) {
    return <>{children}</>
  }

  return (
    <div className={classes.flash} ref={ref}>
      {children}
    </div>
  )
}
