import { useState } from 'react'

export interface Props {
  showCard: boolean
  onCardDismiss: () => void
  dialogOpen: boolean
  setDialogOpen: (dialogOpen: boolean) => void
  dialogConfirmationPending: boolean
  setDialogConfirmationPending: (dialogConfirmationPending: boolean) => void
}

function useViewModel({
  showCard,
  onCardDismiss,
  dialogOpen,
  setDialogOpen,
  dialogConfirmationPending,
  setDialogConfirmationPending,
}: Props) {
  const [cardClosed, setCardClosed] = useState(false)

  const onMeterConnectDialogClose = () => {
    setDialogOpen(false)
    setDialogConfirmationPending(false)
  }

  const onConnectButtonClick = () => setDialogOpen(true)

  const onConnectCardDismiss = () => {
    onCardDismiss()
    setCardClosed(true)
  }

  return {
    meterConnectDialogOpen: dialogOpen || dialogConfirmationPending,
    meterConnectionDialogConfirmationPending: dialogConfirmationPending,
    setMeterConnectionDialogConfirmationPending: setDialogConfirmationPending,
    onMeterConnectDialogClose,
    onConnectCardDismiss,
    onConnectButtonClick,
    showCard: showCard && !cardClosed,
  }
}

export default useViewModel
