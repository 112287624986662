import {
  FetchArgs,
  FetchOptions,
  RequestDataSelector,
  RequestStatus,
  ResourceFetchActionCreator,
  ResourceModel,
} from '../types'
import {
  canRefreshResource,
  createResourceSelectors,
  shouldPopulateResource,
} from '../selectors'
import { useEffect } from 'react'
import {
  createQuerySpecFromFetchArgs,
  createQueryStateForRequestSpecSelector,
  getFetchOptions,
  useAppDispatch,
  useAppSelector,
} from '../utils'

type IdOrPath = string | number
type UseFetchArgs = {
  path?: string
  params?: { [key: string]: unknown }
} & FetchOptions
type FetchHookPathArgs = [...IdOrPath[]] | [...IdOrPath[], UseFetchArgs]

/**
 * @param pathArgs an array representing varargs that are (optionally) strings and numbers
 *   that represent the request path , and an optional object that has request
 *   options such as parameters and options around fetching behavior.
 * @returns a tuple of the fetch args and fetch options that are used internally
 *  by the fetch hook to determine what to fetch and how to fetch it.
 */
function pathArgsToFetchArgsAndOptions(
  pathArgs: FetchHookPathArgs,
): [FetchArgs, FetchOptions] {
  // if there is only one argument and it is a string, assume it is a path
  // enables useFetch('path')
  if (
    pathArgs.length === 1 &&
    (typeof pathArgs[0] === 'string' || typeof pathArgs[0] === 'number')
  ) {
    return [{ pathSegments: [pathArgs[0].toString()] }, {}]
  }

  const lastArg = pathArgs[pathArgs.length - 1]
  // turn the path arguments in to a pathSegments array
  const pathSegments = pathArgs.slice(0, -1).map((arg) => arg.toString())

  // if an object with arguments was attached to the end
  if (typeof lastArg === 'object' && !Array.isArray(lastArg)) {
    // combine path segments and params into fetch args
    const fetchArgs = {
      pathSegments,
      path: lastArg.path,
      ...(lastArg.params ? { params: lastArg.params } : {}),
    }
    const fetchOptions = getFetchOptions(lastArg)
    return [fetchArgs, fetchOptions]
  }

  return [{ pathSegments }, {}]
}

export function createFetchHook<Resource extends ResourceModel>(args: {
  rootSelector: RequestDataSelector<Resource>
  selectors: ReturnType<typeof createResourceSelectors<Resource>>
  fetch: ResourceFetchActionCreator
}) {
  const { rootSelector, selectors, fetch } = args
  /**
   * A hook that fetches a resource and returns the resulting data and status.
   *   This hook will automatically populate the resource if it has not been populated yet
   *   and avoid re-fetching the resource if it has already been fetched or is in the process of being fetched.
   *   By default, this hook will refetch when path arguments and params change.
   * @param pathArgs any number of strings and numbers that represent the request path, and an
   *   optional object at the end with request options.
   * @returns {
   *   data - the resulting data from the request
   *   status - the status of the request
   *   error - the error from the request
   *   refetch - a function that can be called to refetch the resource on demand
   * }
   */
  return (...pathArgs: FetchHookPathArgs) => {
    const [fetchArgs, options] = pathArgsToFetchArgsAndOptions(pathArgs)
    const dispatch = useAppDispatch()

    const querySpec = createQuerySpecFromFetchArgs(fetchArgs)

    const selectQueryState = createQueryStateForRequestSpecSelector(
      selectors,
      querySpec,
    )

    const queryState = useAppSelector(selectQueryState)

    const shouldPopulate = useAppSelector((state) => {
      const invalidated = rootSelector(state).status === 'invalidated'
      return shouldPopulateResource(queryState) || invalidated
    })
    // shouldRefetch returns true if the hook should refetch on a query state change
    const shouldRefetch = useAppSelector((state) => {
      if (!options?.refetchOnChange) {
        return false
      }
      const { status, error } = selectQueryState(state)
      const canRefresh = canRefreshResource({
        status,
        errorsPresent: Boolean(error),
      })

      // don't refetch if we've already successfully fetched this resource in the past
      const hasSuccessfullyFetched = status === 'succeeded'
      if (hasSuccessfullyFetched) {
        return false
      }

      return canRefresh && !shouldPopulate
    })

    // Populate the resource if it has not been populated yet
    useEffect(() => {
      if (shouldPopulate && (options.require ?? true)) {
        dispatch(fetch(fetchArgs))
      }
    }, [shouldPopulate, options?.require])
    // Refetch when the fetch args change if the refetchOnChange option is set.
    useEffect(() => {
      if (shouldRefetch && (options.require ?? true)) {
        dispatch(fetch(fetchArgs))
      }
    }, [JSON.stringify(fetchArgs)])

    const data = useAppSelector((state) =>
      selectors.data.selectExactlyMatchingQuerySpec(state, querySpec),
    )

    const isLoading = queryState.status === RequestStatus.Loading

    return {
      data,
      status: queryState.status,
      isLoading,
      isLoadingOrIdle: isLoading || queryState.status === RequestStatus.Idle,
      error: queryState.error,
      refetch: () => dispatch(fetch(fetchArgs)),
    }
  }
}
