import { createResourceCollection } from '@/request'
import { ThermostatSettings } from '@/types'
import { addThermostatIdFromPath } from './collectionMiddleware'

export const thermostatSettingsCollection =
  createResourceCollection<ThermostatSettings>({
    name: 'thermostatSettings',
    selector: (state) => state.thermostatSettings,
    apiConfig: {
      middlewares: [addThermostatIdFromPath<ThermostatSettings>()],
    },
  })

export default thermostatSettingsCollection.slice.reducer
