import { DeviceAuthAlertMessage } from '@/app/components'

export default function VehicleBrokenConnectionBanner() {
  return (
    <DeviceAuthAlertMessage
      mode="error"
      deviceName="vehicle"
      linkText="Reconnect"
      reconnectUrl="/connect-device/vehicle"
      show
    />
  )
}
