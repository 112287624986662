import { isNativeMobile } from '@/authenticated/hooks/useIsMobile'
import { useHistory, useLocation } from 'react-router-dom'

export function useNavigateToFleetPublicKey(options: {
  fleetPublicKeyUrl: string | undefined
  defaultUrl?: string
}) {
  const history = useHistory()
  const location = useLocation()
  const isAva = location.pathname.includes('/ava/')
  const isOnboarding = location.pathname.includes('/onboarding/')
  const url =
    options.fleetPublicKeyUrl ??
    options.defaultUrl ??
    'https://optiwatt.com/app'

  return (vehicleId: string) => {
    if (isNativeMobile()) {
      window.location.href = url
    } else {
      console.log(url)
      window.open(url, '_blank')
    }
    if (isAva && isOnboarding) {
      history.push(
        `/ava/onboarding/tesla-authentication/key-confirmation/${vehicleId}`,
      )
      return
    }
    history.push(`/tesla-authentication/key-confirmation/${vehicleId}`)
  }
}
