import { logEvent } from '../../logging'

const loggingMiddleware = (store) => (next) => (action) => {
  try {
    const logMessage = action.meta?.log
    if (action.meta?.log) {
      const isAuthenticated = action.meta.hasOwnProperty('authenticated')
        ? action.meta.authenticated
        : true
      logEvent(logMessage, { authenticated: isAuthenticated })
    }
  } catch (error) {
    return next(action)
  }

  return next(action)
}
export default loggingMiddleware
