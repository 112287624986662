import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import {
  Button as MuiButton,
  Menu,
  MenuItem,
  makeStyles,
} from '@material-ui/core'

const Button = styled(MuiButton)(spacing)

const useStyles = makeStyles((theme) => ({
  buttonRoot: {
    padding: '4px 10px',
    fontSize: '12px',
  },
  button: {
    backgroundColor: '#2196f3',
    color: '#fff',
  },
}))

export interface FilterOption<ValueType> {
  id: string | number
  label: string
  selectedLabel?: string
  // allow void return on value for side effects on selection
  value: ValueType | (() => ValueType | void)
}

export interface Props<ValueType> {
  options: FilterOption<ValueType>[]
  defaultId?: string | number
  onSelectedValue: (value: ValueType) => void
  disabled?: boolean
}

export default function SelectFilter<ValueType>({
  options,
  defaultId,
  onSelectedValue,
  disabled = false,
}: Props<ValueType>) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedLabel, setSelectedLabel] = useState('')
  const handleClose = () => setAnchorEl(null)

  const getValue = (valueOrFunc: ValueType | (() => ValueType | void)) =>
    valueOrFunc instanceof Function ? valueOrFunc() : valueOrFunc

  const valueUpdated = (filterOptionId: string | number) => {
    const filterOption = options.find((option) => option.id === filterOptionId)
    if (!filterOption) {
      return
    }
    const value = getValue(filterOption.value)
    if (!value) {
      return
    }
    onSelectedValue(value)
  }

  const handleChange = (filterOption: FilterOption<ValueType>) => () => {
    setSelectedLabel(filterOption.selectedLabel ?? filterOption.label)
    valueUpdated(filterOption.id)
    handleClose()
  }

  const defaultOptionOrFirst =
    options.find((option) => option.id === defaultId) ?? options[0]
  useEffect(() => {
    if (!defaultOptionOrFirst) {
      return
    }
    setSelectedLabel(
      defaultOptionOrFirst.selectedLabel ?? defaultOptionOrFirst.label,
    )
  }, [defaultOptionOrFirst?.id])

  return (
    <>
      <Button
        className={classes.button}
        classes={{ label: classes.buttonRoot }}
        variant="contained"
        size="small"
        color="secondary"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        disabled={disabled}
      >
        {selectedLabel}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option) => (
          <MenuItem key={option.id} onClick={handleChange(option)}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
