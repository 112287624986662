import { useState } from 'react'
import { remoteConfig } from '../firebase'

type validateReturnType = [string[], (newValue: string) => boolean]
type passCheckTypes = 'length'

const useValidatePassword = () => {
  const minPasswordLength =
    remoteConfig?.getNumber('password_validation_min_length') ?? 8
  const [liveErrors, setLiveErrors] = useState<string[]>([])
  const passChecks: Record<passCheckTypes, (password: string) => boolean> = {
    length: (password) => password.length >= minPasswordLength,
  }
  const errorMessages: Record<passCheckTypes, string> = {
    length: `Invalid password. Your password should be at least ${minPasswordLength} characters`,
  }

  const checkLiveErrors = (newValue: string) => {
    // don't validate if no password present
    if (newValue.length === 0) {
      setLiveErrors([])
      return false
    }

    // run through tests
    const tempErrors: string[] = []
    Object.keys(passChecks).forEach((checkKey) => {
      if (!passChecks[checkKey as passCheckTypes](newValue))
        tempErrors.push(errorMessages[checkKey as passCheckTypes])
    })
    setLiveErrors(tempErrors)
    return tempErrors.length === 0
  }

  return [liveErrors, checkLiveErrors] as validateReturnType
}

export default useValidatePassword
