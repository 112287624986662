import { useAppSelector } from '@/hooks'
import mockUtilityProgram from '@/types/utilityProgram/mock'
import useMockableViewModel from '@/hooks/useMockableViewModel'
import { selectUtilityProgramForUserUtility } from '@/reducers/utilityPrograms'

import { Utility, UtilitySearchResult } from '@/types/utility'
import mockUtilities from '@/types/utility/mock'

export type Props = {
  utility?: Utility | UtilitySearchResult
}

function useViewModel(props: Props) {
  const utilityProgram = useAppSelector(selectUtilityProgramForUserUtility)
  const storedUtility = useAppSelector(
    (state) => state.user.user?.profile?.utility,
  )
  const utility = props.utility ?? storedUtility

  const logoUrl = utilityProgram?.logo_url ?? utility?.logo_url ?? null

  const fullButtonConfig =
    utilityProgram?.view_config_json.onboarding.primaryButton ??
    // @ts-expect-error we deprecated enrollButton so it doesnt exist on the cms where types come from
    utilityProgram?.view_config_json.onboarding.enrollButton
  // remove the text for the button because it should always be next
  const { text, ...buttonConfig } = fullButtonConfig ?? {}

  return {
    program: utilityProgram,
    logoUrl,
    buttonConfig,
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function useMockViewModel(_: Props) {
  const mockUtility = mockUtilities[0] as UtilitySearchResult

  return {
    program: mockUtilityProgram,
    logoUrl: mockUtilityProgram.logo_url ?? mockUtility.logo_url,
    buttonConfig: mockUtilityProgram.view_config_json.onboarding.primaryButton,
  }
}

export default useMockableViewModel({ useViewModel, useMockViewModel })
