import { useWhiteLabelData } from '@/hooks/whitelabel/useWhiteLabelData'

export default function BrandedHeader(props: {
  containerClassName?: string
  imgClassName?: string
}) {
  const { whiteLabelData } = useWhiteLabelData()
  if (!whiteLabelData || !whiteLabelData.brandLogo) {
    return null
  }

  return (
    <div className={`flex justify-center ${props.containerClassName}`}>
      <img
        alt="brand-logo"
        className={`max-w-[70px] max-h-[70px] mt-4 ${props.imgClassName}`}
        src={whiteLabelData.brandLogo}
      />
    </div>
  )
}
