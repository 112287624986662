import * as actions from '../actions/homes'
import { getApiErrorMessage } from '../utils/errorUtilities'
import { getSelectedHome } from '../selectors/selectUserSelectedHome'
import { Home } from '@/types'
import { PayloadAction } from '@reduxjs/toolkit'

type State = {
  homes: Home[] | null
  selectedHome: Home | null
  utilities: Home['utilities'] | null
  isLoading: boolean
  hasFetched: boolean
  errors: Record<string, unknown>
}

const initialState: State = {
  homes: null,
  selectedHome: null,
  utilities: null,
  isLoading: false,
  errors: {},
  hasFetched: false,
}

export default (state = initialState, action: PayloadAction<any>): State => {
  switch (action.type) {
    case actions.LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        homes: action.payload,
        selectedHome: getSelectedHome(action.payload) ?? null,
        utilities: getSelectedHome(action.payload)?.utilities ?? null,
        errors: {},
        hasFetched: true,
      }
    case actions.CREATE_SUCCESS: {
      const homes = [action.payload, ...(state.homes || [])]
      const selectedHome = getSelectedHome(homes) ?? null
      return {
        ...state,
        isLoading: false,
        homes,
        selectedHome,
        utilities: selectedHome?.utilities ?? null,
        errors: {},
      }
    }
    case actions.LIST_REQUEST:
    case actions.CREATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case actions.LIST_FAILURE:
    case actions.CREATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: getApiErrorMessage(action.payload),
        hasFetched: true,
      }
    case actions.CLEAR_HOME_ERRORS:
      return {
        ...state,
        errors: {},
      }
    default:
      return state
  }
}
