import { createResourceCollection } from '@/request'
import { ChargeNowEvent } from '@/types'

export const chargeNowEventCollection =
  createResourceCollection<ChargeNowEvent>({
    name: 'chargeNowEvents',
    selector: (state) => state.chargeNowEvents,
  })

export default chargeNowEventCollection.slice.reducer
