import UtilityProgramEnrollmentTogglePromptRoot, {
  Props as ComponentProps,
} from './UtilityProgramEnrollmentTogglePrompt'
import useViewModel from './useViewModel'
import { ViewModelComponentProps } from '../../../../utils/typeUtilities'

export type Props = ViewModelComponentProps<ComponentProps, typeof useViewModel>

export default function UtilityProgramEnrollmentTogglePrompt(props: Props) {
  const viewModel = useViewModel(props)
  return <UtilityProgramEnrollmentTogglePromptRoot {...viewModel} {...props} />
}
