import { groupBy, orderBy, sumBy } from 'lodash'
import { homeUsagesCollection, Usages } from '@/reducers/homeUsages'
import { ResponseError } from '@/request/types'
import centeredRateIndexToLabel from '@/utils/centeredRateIndexToLabel'
import meanOrUndefined from '@/utils/meanOrUndefined'

type UsageSummary = {
  label: string
  rate_usd: number
  percent_of_total: number
  kwh: number
}

type UsageResponse = {
  usages: Usages
  error: ResponseError | null | undefined
  isLoading: boolean
}

export function useGetUsagesFrom(days: number): UsageResponse {
  const now = new Date()
  now.setHours(0, 0, 0, 0)
  const then = new Date(now)
  then.setDate(now.getDate() - days)

  const params = { start_datetime: then }
  const { data, error, status } = homeUsagesCollection.useFetch({ params })

  return {
    usages: data?.[0],
    error: error,
    isLoading: status === 'loading' || status === 'idle',
  }
}

export default function useGroupedUsageData(
  days: number,
): UsageSummary[] | undefined {
  const { usages, error } = useGetUsagesFrom(days)

  if (!usages) {
    return undefined
  }

  const filteredStats = usages.usage_stats.filter(
    (stat) => stat.stats[0].usage_kwh > 0,
  )

  if (error || filteredStats.length == 0) {
    return []
  }

  const totalUsages = filteredStats.length

  const groupedRates = groupBy(
    filteredStats,
    (entry) => entry.stats?.[0].rate_usd,
  )

  const usageGroups: UsageSummary[] = []
  for (const key in groupedRates) {
    const rates = groupedRates[key]
    usageGroups.push({
      rate_usd: +key,
      label: centeredRateIndexToLabel(
        meanOrUndefined(rates.map((rate) => rate.stats[0].centered_rate_index)),
      ),
      percent_of_total: Math.round((rates.length / totalUsages) * 100),
      kwh: Math.round(sumBy(rates, (rate) => rate.stats[0].usage_kwh)),
    })
  }

  return orderBy(usageGroups, (group) => group.rate_usd)
}
