import { remoteConfig } from '@/firebase'

function getWhiteLabelProgramIdFromReferralCode(referralCode: string) {
  const codeParts = referralCode.split('_')
  if (codeParts.length < 2) {
    return null
  }

  const prefix = codeParts[0].toLowerCase()
  if (!prefix) {
    return null
  }

  return remoteConfig?.getWhitelabelId(prefix)
}

export default { getWhiteLabelProgramIdFromReferralCode }
