import React from 'react'
import styled from 'styled-components'
import {
  Button,
  createStyles,
  Grid as MuiGrid,
  makeStyles,
  Typography as MuiTypography,
  useTheme,
} from '@material-ui/core'
import { spacing } from '@material-ui/system'
import DashboardCard, { DashboardDivider } from '../DashboardCard'
import { ChevronRight, Heart } from 'react-feather'
import Piggy from '../../images/simple_piggy.svg?react'
import Solar from '../../images/solar-icon.svg?react'
import ComparePlansDialog from './ComparePlansDialog'
import useShowBaselineSettings from '../../hooks/useShowBaselineSettings'
import FlashingComponent from '../FlashingComponent'
import { ChargeSchedule } from '@/types'
import IconToggleLabel from '@/authenticated/components/IconToggleLabel'
import { useFeatureFlag } from '@/hooks/useFeatureFlag'

const Grid = styled(MuiGrid)(spacing)
const StyledDashboardDivider = styled(DashboardDivider)(spacing)
const Typography = styled(MuiTypography)(spacing)

const useStyles = makeStyles((theme) =>
  createStyles({
    comparePlansBox: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.primary.main,
    },
    compareHelpText: {
      fontSize: '14px',
      fontWeight: 'bold',
      marginRight: '4px',
      padding: '16px 0px',
    },
    compareHelpLinkText: {
      fontSize: '14px',
      fontWeight: 'bold',
      marginRight: '2px',
      marginTop: '2px',
      color: theme.palette.blue?.main,
    },
    featureBoxPadding: {
      padding: '12px 0px !important',
    },
    chevron: {},
    buttonLabel: {
      padding: '0px 0px 0px 4px',
    },
    comparePlansWrapper: {
      padding: '8px 0px 8px 8px !important',
    },
  }),
)

const PlanDetailsCard = ({
  children,
  title,
  icon,
}: {
  children: React.ReactNode
  title?: React.ReactNode
  icon?: string
}) => {
  // Filter out null chilren
  const childrenArray = React.Children.toArray(children).filter(
    (child) => child !== null,
  )
  const childrenCount = React.Children.count(childrenArray)
  return (
    <DashboardCard pb={4} pt={6} mx={1} mb={0}>
      {title && title}
      <Grid container alignItems="center" justifyContent="center" spacing={6}>
        {
          // insert divider between children and dont show divider on last child
          React.Children.map(children, (child, index) => {
            return (
              <>
                {child}
                {childrenCount > 1 && index < childrenCount - 1 && (
                  <DashboardDivider />
                )}
              </>
            )
          })
        }
      </Grid>
    </DashboardCard>
  )
}

type PlanSelectFormProps<Schedule extends ChargeSchedule> = {
  onChange?: (chargeSchedule: Schedule) => void
  chargeSchedule?: Schedule
  children?: React.ReactNode
}

const PlanSelectForm = <Schedule extends ChargeSchedule>({
  chargeSchedule,
  onChange,
  children,
}: PlanSelectFormProps<Schedule>) => {
  const showBaselineSettings = useShowBaselineSettings()
  const classes = useStyles()
  const showSolarPlan = useFeatureFlag({
    flag: 'show_solar_plan',
    experimentalValue: true,
  })

  return (
    <>
      <Grid item xs={12}>
        <FlashingComponent
          active={
            showBaselineSettings && chargeSchedule?.save_the_planet_plan_enabled
          }
          scrollToElement={true}
        >
          <IconToggleLabel
            id="save-the-planet-plan"
            iconComponent={<Heart color="#FB42D2" />}
            title="Save the Planet"
            subtitle="Charge during lower CO₂ emissions"
            shouldDebounce={false}
            model={chargeSchedule}
            field="save_the_planet_plan_enabled"
            onChange={onChange}
            checked={false}
          />
        </FlashingComponent>
      </Grid>
      <DashboardDivider />
      <Grid item xs={12}>
        <IconToggleLabel
          id="save-money-plan"
          iconComponent={<Piggy />}
          title="Save Money"
          subtitle="Charge during cheaper rates"
          shouldDebounce={false}
          model={chargeSchedule}
          field="money_saving_plan_enabled"
          onChange={onChange}
          checked={false}
        />
      </Grid>
      {showSolarPlan && (
        <>
          <DashboardDivider />
          <Grid item xs={12}>
            <IconToggleLabel
              id="solar-surplus-plan"
              iconComponent={<Solar />}
              title="Solar Surplus"
              subtitle="Charge when your solar is likely overproducing"
              shouldDebounce={false}
              model={chargeSchedule}
              field="solar_surplus_plan_enabled"
              onChange={onChange}
              checked={false}
              newHighlight={true}
            />
          </Grid>
        </>
      )}
      <DashboardDivider />
      {children}
    </>
  )
}
PlanSelectForm.displayName = 'PlanSelectForm'
PlanDetailsCard.PlanSelectForm = PlanSelectForm

const ComparePlansPromptAndDialog = (props: {
  includeScheduleDeparture: boolean
}) => {
  const theme = useTheme()
  const classes = useStyles()
  const [dialogOpen, setDialogOpen] = React.useState(false)
  return (
    <>
      <Grid
        className={classes.comparePlansWrapper}
        item
        container
        xs={12}
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={0}
      >
        <Grid item>
          <Typography variant="body1" className={classes.compareHelpText}>
            Which plan is for you?
          </Typography>
        </Grid>
        <Grid item p={0}>
          <Button
            className={classes.comparePlansBox}
            classes={{ label: classes.buttonLabel }}
            onClick={() => setDialogOpen(true)}
          >
            <Typography variant="body1" className={classes.compareHelpLinkText}>
              Compare plans
            </Typography>
            <ChevronRight
              className={classes.chevron}
              size={14}
              color={theme.palette.blue?.main}
            />
          </Button>
          <ComparePlansDialog
            id="compare-plans"
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            includeScheduleDeparture={props.includeScheduleDeparture}
          />
        </Grid>
      </Grid>
    </>
  )
}
ComparePlansPromptAndDialog.displayName = 'ComparePlansPromptAndDialog'
PlanDetailsCard.ComparePlansPromptAndDialog = ComparePlansPromptAndDialog

export default PlanDetailsCard
