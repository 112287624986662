import {
  useAppDispatch,
  useMobileVersionFlag,
  useMockableViewModel,
  useUrlSearchParam,
} from '@/hooks'
import { isNativeMobile } from '@/authenticated/hooks/useIsMobile'
import { useEffect, useState } from 'react'
import { ConnectionStatusMode } from '@/authenticated/components/DeviceConnectionRedirect'
import { useNavigation } from '@/app/hooks'
import { postEventToMobileIfPresent } from '@/utils/messageUtils'
import useMobileMessage from '@/app/hooks/useMobileMessage'
import { Pkce, pkceCollection } from '@/reducers/pkce'
import {
  FordAuthentication,
  fordAuthentication,
} from '@/reducers/authenticateFord'
import { connectExternalVehicle } from '@/actions/vehicleAuth'
import { RSAAResultAction } from 'redux-api-middleware'
import { logEvent } from '@/logging'
import useBasePath from '@/hooks/useBasePath'

export type ExternalVehicleAuthPayload = {
  exchange_code: string
  code_verifier: string
}

export type ExternalVehicleAuthMessage = {
  payload?: ExternalVehicleAuthPayload
  close?: boolean
  error?: string
}

export type ExternalVehicleRedirectProps = {
  mobileMessageSent: 'showFordAuth'
  mobileMessageReceived: 'fordAuth'
  appIconSrc: string
  pageTitle: string
  id: string
  appPath: string
  logEventBrandName: string
}

const useViewModel = useMockableViewModel({
  useViewModel(props: ExternalVehicleRedirectProps) {
    const dispatch = useAppDispatch()
    const isMobile = isNativeMobile()
    const [connectionStatus, setConnectionStatus] = useState(
      ConnectionStatusMode.Connecting,
    )
    const [messageSent, setMessageSent] = useState(false)

    const basePath = useBasePath()
    const isOnboarding = useUrlSearchParam('onboarding')

    const navigate = useNavigation()

    const requiresUpdate =
      !useMobileVersionFlag('externalVehicleAuth') && isMobile
    const copyTitle = requiresUpdate
      ? 'Update Optiwatt to Continue'
      : 'Switch to Mobile to Continue'

    const copySubtext = requiresUpdate
      ? 'You must update Optiwatt in order to use this feature. You can navigate to your store using the buttons below.'
      : 'If you have the Optiwatt app installed, scan the QR code to continue where you left off. Otherwise, download the app below'

    const getPkceCodes = async () => {
      if (!isMobile || requiresUpdate) {
        return
      }

      logEvent(`fetching_pkce_${props.logEventBrandName}`)
      const res: RSAAResultAction<Pkce> = await dispatch(
        pkceCollection.actions.create({}),
      )

      if (res.type === pkceCollection.actionTypes.create.Failure) {
        setMessageSent(true)
        logEvent(`failed_pkce_generation_${props.logEventBrandName}`)
        setConnectionStatus(ConnectionStatusMode.Failed)
        return
      }

      const pkce = res.payload as Pkce
      logEvent(`start_${props.logEventBrandName}_connection`)
      postEventToMobileIfPresent(props.mobileMessageSent, pkce)

      // Just a delay so mobile animations can play out without jumping
      setTimeout(() => {
        setMessageSent(true)
      }, 1000)
    }

    useEffect(function onInit() {
      getPkceCodes()
    }, [])

    const createVehicleFromToken = async (
      payload: ExternalVehicleAuthPayload,
    ) => {
      setConnectionStatus(ConnectionStatusMode.Connecting)
      const res: RSAAResultAction<FordAuthentication> = await dispatch(
        fordAuthentication.actions.create(payload),
      )

      if (res.type === fordAuthentication.actionTypes.create.Failure) {
        logEvent(`start_${props.logEventBrandName}_login`, {
          metadata: { success: false },
        })
        setConnectionStatus(ConnectionStatusMode.Failed)
        return
      }

      logEvent(`start_${props.logEventBrandName}_login`, {
        metadata: { success: true },
      })
      dispatch(connectExternalVehicle(res.payload as FordAuthentication))
      setConnectionStatus(ConnectionStatusMode.Connected)
    }

    // Listen for messages from mobile -- we're ultimately looking for the external app's auth token
    useMobileMessage<ExternalVehicleAuthMessage>(
      async (data: ExternalVehicleAuthMessage) => {
        if (data.close) {
          navigate.goBack()
          return
        }

        if (data.error || !data.payload) {
          setConnectionStatus(ConnectionStatusMode.Failed)
          return
        }

        await createVehicleFromToken(data.payload)
      },
      [props.mobileMessageReceived],
    )

    const onSubmit = () => {
      // Successful submission, move on
      if (connectionStatus === ConnectionStatusMode.Connected) {
        if (basePath && basePath !== 'connect-vehicle' && isOnboarding) {
          navigate.push(`/${basePath}/onboarding/add-another`)
          return
        }
        navigate.push('/connect-vehicle/configure')
      } else {
        // "Retry" selected
        const newBasePath = basePath !== 'connect-vehicle' ? basePath + '/' : ''
        const onboardingParam = isOnboarding ? '?onboarding=true' : ''
        navigate.push(`/${newBasePath}connect-vehicle${onboardingParam}`)
      }
    }

    const onBack = () => {
      navigate.goBack()
    }

    return {
      isMobile,
      requiresUpdate,
      copySubtext,
      copyTitle,
      connectionStatus,
      onSubmit,
      onBack,
      appPath: props.appPath,
      messageSent,
    }
  },
  useMockViewModel(props: ExternalVehicleRedirectProps) {
    return {
      isMobile: false,
      requiresUpdate: false,
      copySubtext: '',
      copyTitle: '',
      connectionStatus: ConnectionStatusMode.Connecting,
      onSubmit: () => alert('Token submitted!'),
      onBack: () => alert('Back accepted!'),
      appPath: 'ford',
      messageSent: false,
    }
  },
})

export default useViewModel
