import React, { useState, useEffect, useMemo, ChangeEvent } from 'react'
import { GasCarConversion } from '../../types/vehicle/'
import { TextField } from '@material-ui/core'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import { useAppDispatch } from '../../hooks'
import { friendlyName as gasConversionFriendlyName } from '../../selectors/gasCarConversions'
import throttle from 'lodash/throttle'

const THROTTLE = 400

export interface Props {
  gasCarConversion: GasCarConversion
  onChange: (
    event: ChangeEvent<any>,
    value: GasCarConversion | null,
    reason: any,
    details?: any,
  ) => void
  useSearchResults: () => {
    searchResults: GasCarConversion[] | null
    fetchAction: (query: string) => { [key: string]: any }
  }
  label?: string
}

export default function GasConversionSearchbar({
  gasCarConversion,
  onChange,
  useSearchResults,
  label = 'Select Conversion',
}: Props) {
  const dispatch = useAppDispatch()
  const [value, setValue] = useState(gasCarConversion)
  const [inputValue, setInputValue] = useState<string>('')

  const { searchResults, fetchAction } = useSearchResults()
  const options = (() => {
    const _options = searchResults || []
    const optionIds = _options.map((option: GasCarConversion) =>
      gasConversionFriendlyName(option),
    )
    const selectedValueId = gasConversionFriendlyName(value)
    return optionIds.includes(selectedValueId)
      ? _options
      : [value].concat(_options)
  })()

  useEffect(() => {
    // initialize value and input
    if ((!value && !inputValue) || value.id !== gasCarConversion.id) {
      setValue(gasCarConversion)
      setInputValue(gasConversionFriendlyName(gasCarConversion) || 'Unnamed')
    }
  }, [gasCarConversion])

  const fetch = useMemo(
    () =>
      throttle((searchText) => {
        dispatch(fetchAction(searchText))
      }, THROTTLE),
    [],
  )

  useEffect(() => {
    fetch(inputValue)
  }, [inputValue])

  function updateInputValue(event: ChangeEvent<any>, newValue: string) {
    setInputValue(newValue)
  }

  function updateValueAndCallOnChange(
    event: ChangeEvent<any>,
    value: GasCarConversion | null,
    reason: any,
    details?: any,
  ) {
    if (value) {
      setValue(value)
    }
    onChange(event, value, reason, details)
  }

  return (
    <Autocomplete
      id="gasconversion-search"
      value={value as any}
      getOptionLabel={(option: GasCarConversion) => {
        return gasConversionFriendlyName(option) || 'Unnamed'
      }}
      getOptionSelected={(option: GasCarConversion, value: GasCarConversion) =>
        gasConversionFriendlyName(option) === gasConversionFriendlyName(value)
      }
      filterOptions={(options, params) =>
        createFilterOptions<GasCarConversion>({ limit: 10 })(options, params)
      }
      options={options}
      // autoComplete
      includeInputInList
      onInputChange={updateInputValue}
      onChange={updateValueAndCallOnChange}
      renderInput={(params) => (
        <TextField {...params} label={label} fullWidth required />
      )}
      renderOption={(option) => gasConversionFriendlyName(option) || 'Unnamed'}
    />
  )
}
