import React from 'react'
import ConnectDeviceInformationCard, {
  Props,
} from './ConnectDeviceInformationCard'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from '../../../v2/theme'

export default ({ ...rest }: Props) => (
  <ThemeProvider theme={theme}>
    <ConnectDeviceInformationCard {...rest} />
  </ThemeProvider>
)
