import { createContext, useEffect, useState } from 'react'
import { ReactNodeProps } from '../../types/reactNodeProps'
import { debounce } from '../../utils/limit'
import computedBreakpoints from './breakpoints'

export const BreakpointContext = createContext(computedBreakpoints())

export function BreakpointProvider(props: ReactNodeProps) {
  const [value, setValue] = useState(computedBreakpoints())

  function onResize() {
    setValue(computedBreakpoints())
  }

  useEffect(() => {
    // Since this is a user interfacing latency, we should shoot for < 100ms. Its a rule of thumb
    // that was established a while ago (30 years+, thats why its a minimum target):  https://stackoverflow.com/a/2547903
    window.addEventListener('resize', debounce(onResize, 100))

    return () => window.removeEventListener('resize', onResize)
  }, [])

  return (
    <BreakpointContext.Provider value={value}>
      {props.children}
    </BreakpointContext.Provider>
  )
}
