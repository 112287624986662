import { useEffect } from 'react'
import { Grid, LinearProgress } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import BatteryStats from './BatteryStats'
import ExampleStats from './ExampleStats'
import { getCharges } from '../../actions/metric'
import { useSelector, useDispatch } from 'react-redux'
import ErrorBoundary, { LocationTag } from './ErrorBoundary'
import theme from '../../v2/theme'
import { ThemeProvider } from '@material-ui/core/styles'

function Charges({ vehicle, vehicle_charger_id }) {
  const dispatch = useDispatch()
  const charges = useSelector((state) => state.metric.charges)
  const isFetchingCharges = useSelector(
    (state) => state.metric.isFetchingCharges,
  )
  const isExample =
    charges?.count === 0 &&
    (charges?.start_date === null || charges?.end_date === null)

  const chargesAreLoading = useSelector(
    (state) => state.metric.isFetchingCharges,
  )
  const chargesFailed = useSelector((state) => state.metric.chargesFailed)

  const reloadCharges = (page = 1) =>
    dispatch(
      getCharges(
        page,
        charges?.start_date,
        charges?.end_date,
        vehicle?.id,
        vehicle_charger_id,
      ),
    )
  const handleChange = (_event, value) => {
    reloadCharges(value)
  }

  useEffect(() => {
    if (
      // Prevent infinite fetching when a request failure happens
      (!charges && !chargesAreLoading && !chargesFailed) ||
      // if the current vehicle doesn't match the vehicle attached
      // to our stored charges, we need to update
      (vehicle?.id && vehicle?.id !== charges?.vehicle_id) ||
      // if the current vehicle charger doesn't match the charger attached
      // to our stored charges, we need to update
      (vehicle_charger_id && vehicle_charger_id !== charges?.vehicle_charger_id)
    ) {
      reloadCharges()
    }
  }, [])

  if (!charges || isFetchingCharges) {
    return <LinearProgress />
  }

  return (
    <div>
      {isExample ? (
        <Grid container spacing={6}>
          <Grid item xs={12} sm={6}>
            <ExampleStats />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ExampleStats />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ExampleStats />
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid container spacing={6}>
            {charges.results.map((charge, index) => (
              <Grid key={index} item xs={12} sm={6}>
                <BatteryStats
                  vehicle={vehicle}
                  charge={charge}
                  isExample={isExample}
                  reloadCharges={reloadCharges}
                />
              </Grid>
            ))}
          </Grid>
          {charges?.total_pages > 1 ? (
            <Grid container spacing={6}>
              <Grid item>
                <Pagination
                  variant="outlined"
                  color="primary"
                  count={charges.total_pages}
                  page={charges.page_number}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          ) : null}
        </>
      )}
    </div>
  )
}

export default (props) => (
  <ErrorBoundary
    location={LocationTag.Charges}
    functionalityDescription="Charges List"
  >
    <ThemeProvider theme={theme}>
      <Charges {...props} />
    </ThemeProvider>
  </ErrorBoundary>
)
