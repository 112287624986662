import React from 'react'
import Icon from '@/components/Icon/Icon'
import clsx from 'clsx'
import formatClasses from '@/utils/classes/formatClasses'
import twMerge from '@/utils/classes/twMerge'
import { AnimatePresence, motion } from 'framer-motion'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean
  errorStateClassName?: string
  errorMessage?: string | string[]
  showErrorMessage?: boolean
  inputClassName?: string
}

const FormInputWithError = ({
  error = false,
  errorStateClassName,
  errorMessage = 'Please complete this field',
  showErrorMessage = true,
  className,
  inputClassName,
  ...rest
}: Props) => {
  const defaultInputClasses = formatClasses([
    'rounded-lg',
    'bg-[#eff2fa]',
    'box-border',
    'w-full',
    'outline-none',
    'pr-2',
    'pl-4',
    'py-4',
    'text-[#424f76]',
    'text-[16px]',
    'font-medium',
    'border-none',
    'autofill:shadow-[inset_0_0_0px_1000px_rgb(239,242,250)]', // autofill is tricky with background colors
    'autofill:text-[#424f76]',
    'placeholder:text-[#424f76]',
    'placeholder:text-[16px]',
    'placeholder:font-medium',
    'font-[montserrat]',
  ])

  const defaultInvalidInputClasses = formatClasses([
    '!border-2',
    '!border-solid',
    '!border-[#FB5842]',
  ])

  return (
    <div className={className}>
      <input
        className={clsx(
          twMerge(defaultInputClasses, inputClassName),
          error && twMerge(defaultInvalidInputClasses, errorStateClassName),
        )}
        {...rest}
      />
      <AnimatePresence>
        {error && showErrorMessage && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
            className="overflow-hidden"
          >
            <div className="flex mt-2 pb-2">
              <div className="w-4 -mt-1 sm:-mt-[3px]">
                <Icon name="Info" color="#FB5842" size={16} />
              </div>
              <p className="text-body1 font-medium text-[#0A1C53] leading-4 mb-0 ml-1">
                {errorMessage}
              </p>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <motion.div
        initial={{ height: 24 }}
        animate={{ height: error && showErrorMessage ? 9 : 24 }}
      />
    </div>
  )
}

export default FormInputWithError
