import { Add as AddIcon, Remove as RemoveIcon } from '@material-ui/icons'
import styled from 'styled-components'
import { Fab as MuiFab } from '@material-ui/core'
import { spacing } from '@material-ui/system'

const Fab = styled(MuiFab)(spacing)

export default function AddRow({ id, disabled, fabDisabled }) {
  return (
    <Fab
      id={id}
      mb={3}
      name="action"
      type="submit"
      style={{ marginTop: '15px' }}
      color={disabled ? 'default' : 'primary'}
      value={disabled ? 'remove' : 'add'}
      size="small"
      aria-label="Add"
      disabled={fabDisabled}
    >
      {disabled ? <RemoveIcon /> : <AddIcon />}
    </Fab>
  )
}
