import { Flex, LoadingAnimation, Text } from '@/components'
import { capitalizeFirstLetter } from '@/utils/stringUtilities'
import { useHomeCollection } from '@/reducers/homeResource'
import { HomeEstimationVariant } from '@/app/components/HomeEstimations/Prompt/HomeEstimation'

function HomeEstimationRow(props: {
  label: string
  value: string
  variant: HomeEstimationVariant
}) {
  return (
    <Flex
      container
      direction="row"
      fullWidth
      justifyContent="space-between"
      className="mt-3"
    >
      <Text variant="body1">{props.label}</Text>
      <Text variant="body4">{props.value}</Text>
    </Flex>
  )
}

type Props = {
  variant: HomeEstimationVariant
}

export default function HomeEstimationData(props: Props) {
  const home = useHomeCollection()

  const Unknown = 'Unknown'
  const heatingBTUs = home?.heating_btu?.toLocaleString('en-us') ?? Unknown
  const coolingBTUs = home?.cooling_btu?.toLocaleString('en-us') ?? Unknown
  const sqFootage = home?.sq_footage?.toLocaleString('en-us') ?? Unknown
  const buildYear = home?.year_constructed.toString() ?? Unknown
  const heatSource =
    home?.heating_energy_source && home.heating_energy_source !== 'undefined'
      ? capitalizeFirstLetter(home.heating_energy_source)
      : Unknown

  return (
    <>
      <Flex container justifyContent="space-around" className="mt-2 mb-5">
        <img
          src={
            props.variant === 'alert'
              ? '/img/hero_image.svg'
              : '/img/hero_image_inverted.svg'
          }
        />
      </Flex>
      <Text variant="subheader" className="text-themed-base-900">
        {props.variant === 'alert'
          ? 'Your home default estimations'
          : 'Your home'}
      </Text>
      {!home && (
        <Flex container justifyContent="space-around" className="p-4">
          <LoadingAnimation type="falling" />
        </Flex>
      )}
      {home && (
        <div className="pb-2">
          <Flex container direction="column" fullWidth>
            <HomeEstimationRow
              variant={props.variant}
              label="Home constructed in"
              value={buildYear}
            />
            <HomeEstimationRow
              variant={props.variant}
              label="Home square footage"
              value={`${sqFootage} sq ft`}
            />
            <HomeEstimationRow
              variant={props.variant}
              label="AC BTUs"
              value={`${coolingBTUs}`}
            />
            <HomeEstimationRow
              variant={props.variant}
              label="Heating Type"
              value={heatSource}
            />
            <HomeEstimationRow
              variant={props.variant}
              label="Furnace BTUs"
              value={`${heatingBTUs}`}
            />
          </Flex>
        </div>
      )}
    </>
  )
}
