export default {
  miles: [
    { key: 5, value: '< 10 miles' },
    { key: 15, value: '10 - 20 miles' },
    { key: 25, value: '21 - 30 miles' },
    { key: 40, value: '31 - 50 miles' },
    { key: 65, value: '51 - 80 miles' },
    { key: 90, value: '81 - 100 miles' },
    { key: 125, value: '101 - 150 miles' },
    { key: 165, value: '151+ miles' },
  ],
}
