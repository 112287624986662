export interface Props {
  children: React.ReactNode
}

/**
 * This component is used to create a page that fills the entire screen.
 * This mimics the layout of our app layout.
 */
export default function ScreenFillingPage({ children }: Props) {
  return (
    <div style={{ minHeight: '100vh', display: 'flex' }}>
      <div style={{ flex: '1 1 0%', display: 'flex', flexDirection: 'column' }}>
        {children}
      </div>
    </div>
  )
}
