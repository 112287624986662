import advancedFormat from 'dayjs/plugin/advancedFormat'
import calendar from 'dayjs/plugin/calendar'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import timeDiff from './timeDiff/index.js'

dayjs.extend(advancedFormat)
dayjs.extend(calendar)
dayjs.extend(customParseFormat)
dayjs.extend(isBetween)
dayjs.extend(timeDiff)
