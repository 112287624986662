import { createResourceCollection } from '../request'
import { ResourceModel } from '@/request/types'

export type AvaSecurityQuestion = {
  name: string[]
  email: string[]
} & ResourceModel

export type AvaSecurityQuestionValidation = {
  name: boolean
  email: boolean
}

export const avaSecurityQuestionCollection =
  createResourceCollection<AvaSecurityQuestion>({
    name: 'avaSecurityQuestionCollection',
    apiConfig: {
      path: 'ava_security_questions',
    },
    selector: (state) => state.avaSecurityQuestions,
  })

const avaSecurityQuestionReducer = avaSecurityQuestionCollection.slice.reducer

export default avaSecurityQuestionReducer
