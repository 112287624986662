import { useEffect } from 'react'
import { RSAAResultAction } from 'redux-api-middleware'
import { useParams } from 'react-router-dom'
import {
  useAppSelector,
  useAppDispatch,
  useUrlSearchParams,
} from '../../../hooks'
import { ThermostatBrandName } from '../../../types/thermostatBrands'
import BroadcastChannelName from '../../../types/broadcastChannelName'
import { BroadcastChannel } from 'broadcast-channel'
import { thermostatAuthSelector } from '../../../reducers/thermostatAuth'
import thermostatAuth from '../../../actions/thermostatAuth'
import {
  postMessagetoMobileIfPresent,
  CONNECT_THERMOSTAT_CLOSE_TYPE,
} from '../../../utils/messageUtils'
import { ConnectionStatusMode } from '../DeviceConnectionRedirect'
import data from './resources/data'

export interface ThermostatRedirectBroadcasMessage {
  success: boolean
  brand: ThermostatBrandName
}

function sendAuthResultToApp(success: boolean, brand: ThermostatBrandName) {
  const channel = new BroadcastChannel(BroadcastChannelName.ThermostatRedirect)
  channel.postMessage({ success, brand })
}

export interface RouteParams {
  brand: ThermostatBrandName
}

enum RedirectUrlParams {
  code = 'code',
  state = 'state',
  externallyAuthenticating = 'externally_authenticating',
}

function connectionStatusMode(
  authIsLoading: boolean,
  connectionSuccess: boolean,
  externalAuthInProgress: boolean,
) {
  if (authIsLoading || externalAuthInProgress) {
    return ConnectionStatusMode.Connecting
  } else if (connectionSuccess) {
    return ConnectionStatusMode.Connected
  }
  return ConnectionStatusMode.Failed
}

function useViewModel() {
  const dispatch = useAppDispatch()
  const { brand } = useParams<RouteParams>()
  const {
    isLoading,
    errors,
    success: authSuccess,
  } = useAppSelector(thermostatAuthSelector(brand)) ?? {
    isLoading: false,
    errors: null,
    success: null,
  }
  const urlParams = useUrlSearchParams()
  const externalAuthInProgress = urlParams.has(
    RedirectUrlParams.externallyAuthenticating,
  )

  const isMobile = 'ReactNativeWebView' in window

  function submitThermostatAuthentication(code: string, redirectUri: string) {
    if (isLoading || Object.keys(errors ?? {}).length || authSuccess !== null) {
      return
    }
    dispatch(
      thermostatAuth(brand).actions.authenticate(code, redirectUri),
    ).then((responseData: RSAAResultAction) => {
      const { type } = responseData
      if (type === thermostatAuth(brand).types.Post.Success) {
        sendAuthResultToApp(true, brand)
        return
      }
      sendAuthResultToApp(false, brand)
    })
  }

  useEffect(() => {
    if (urlParams.has(RedirectUrlParams.code)) {
      submitThermostatAuthentication(
        urlParams.get(RedirectUrlParams.code) as string,
        // Note we use the state to store the redirect uri, which differs based on mobile platform
        urlParams.get(RedirectUrlParams.state) as string,
      )
    }
  }, [dispatch, isMobile, urlParams])

  const handleClose = () => {
    if (isMobile) {
      postMessagetoMobileIfPresent(CONNECT_THERMOSTAT_CLOSE_TYPE)
    } else {
      window.top?.close()
    }
  }

  const codePresent = urlParams.has(RedirectUrlParams.code)
  // if the code is present in the url, assume an auth request will be made
  // otherwise, the auth request could never be loading
  const authIsLoading = codePresent && (isLoading || authSuccess === null)

  const mode = connectionStatusMode(
    authIsLoading,
    authSuccess ?? false,
    externalAuthInProgress,
  )

  return {
    mode: mode,
    statusText: data.statusText(brand),
    buttonAction: handleClose,
  }
}

export default useViewModel
