import { useState } from 'react'

import { Typography as MuiTypography, useTheme } from '@material-ui/core'

import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import { makeStyles } from '@material-ui/styles'
import { ChevronDown } from 'react-feather'
import { DashboardSections, DashboardDivider } from '../../../DashboardCard'
import { UsageSectionHeaderTypography } from '../../UsageSectionTypography'
import ColorBoxedTypography from '../../../ColorBoxedTypography'
import DayOfWeekSelector from '../../../DayOfWeekSelector'
import DailyTOURateViz from './DailyTOURateViz'
import dayjs from 'dayjs'
import centeredRateIndexToLabel from '@/utils/centeredRateIndexToLabel'

const Typography = styled(MuiTypography)(spacing)

const useStyles = makeStyles((theme) => ({
  currentRateLabelText: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const useSeasonalRateSummaryForDay = (
  fullSeasonalRateSummary,
  day = dayjs().day(),
) => {
  const [selectedDayOfWeek, setSelectedDayOfWeek] = useState(day)
  const selectedDate = dayjs(selectedDayOfWeek).day()
  // @todo codi should isWeekendSelection be locale specific?
  const isWeekendSelection = [6, 7].includes(selectedDate)
  const currentMonth = dayjs().month()
  // list of rates in current month and weekday
  const currentSummary = fullSeasonalRateSummary.filter(
    (rate) =>
      rate.month === currentMonth + 1 &&
      isWeekendSelection === rate.weekend &&
      rate.tier === 0,
  )

  return [currentSummary, selectedDayOfWeek, setSelectedDayOfWeek]
}

function formatSeasonalRateSummaryForViz(seasonalRateSummary, colorSpectrum) {
  if (seasonalRateSummary.length === 0) {
    return []
  }

  let allStops = seasonalRateSummary.flatMap((rate, rateIdx) =>
    rate.hours.reduce((stops, hour, idx) => {
      const atEndOfHours = idx === rate.hours.length - 1
      const atEndOfRange = atEndOfHours || rate.hours[idx + 1] - hour > 1
      if (atEndOfRange) {
        return stops.concat({
          hour: hour + 1,
          colorIdx: rateIdx,
          label: centeredRateIndexToLabel(rate.centered_rate_index),
          value: `${(
            Number.parseFloat(rate.rate_currency_per_kwh) * 100
          ).toPrecision(2)}\u00A2 / kWh`,
          rate: rate,
        })
      }
      return stops
    }, []),
  )
  allStops = [{ hour: 0, colorIdx: 0 }].concat(allStops)
  allStops.sort((a, b) => a.hour - b.hour)

  const barColors = colorSpectrum.blueYellowRedBar(seasonalRateSummary.length)
  const labelColors = colorSpectrum.blueYellowRedLabel(
    seasonalRateSummary.length,
  )

  return allStops.map(({ hour, colorIdx, ...rest }, index) => ({
    stop: hour,
    start: allStops[index - 1]?.hour,
    fill: barColors[colorIdx],
    labelColor: labelColors[colorIdx],
    ...rest,
  }))
}

const useRateSummaryVizTransform = (
  seasonalRateSummaryForSelectedDate,
  defaultHour,
) => {
  const theme = useTheme()

  const colorSpectrum = theme.palette.spectrum.diverging

  const coloredStops = formatSeasonalRateSummaryForViz(
    seasonalRateSummaryForSelectedDate,
    colorSpectrum,
  )

  const [_selectedColorStop, setSelectedColorStop] = useState(null)
  const selectedColorStopDefault = ({ start, stop }) =>
    start <= defaultHour && stop > defaultHour
  const selectedColorStop =
    _selectedColorStop || coloredStops.find(selectedColorStopDefault) || null

  return { coloredStops, selectedColorStop, setSelectedColorStop }
}

const DailyTOURateOverview = ({ fullSeasonalRateSummary }) => {
  const theme = useTheme()
  const classes = useStyles()

  const todayDayOfWeek = dayjs().day()
  const currentHour = dayjs().hour()

  const [
    seasonalRateSummaryForSelectedDate,
    selectedDayOfWeek,
    setSelectedDayOfWeek,
  ] = useSeasonalRateSummaryForDay(fullSeasonalRateSummary, todayDayOfWeek)

  const { coloredStops, selectedColorStop, setSelectedColorStop } =
    useRateSummaryVizTransform(seasonalRateSummaryForSelectedDate, currentHour)

  const todayIsSelected = selectedDayOfWeek === todayDayOfWeek

  const displayRateEndTime = selectedColorStop?.stop
    ? dayjs(`${selectedColorStop.stop}`, 'HH').format('ha')
    : ''

  return (
    <DashboardSections>
      <Typography className={classes.headerText} variant="h6">
        <ColorBoxedTypography
          className={classes.currentRateLabelText}
          variant="h6"
          hexColor={selectedColorStop?.labelColor || '#000000'}
          spaceAfter
        >
          {selectedColorStop?.label}
        </ColorBoxedTypography>
        until {displayRateEndTime}
      </Typography>

      <DashboardDivider />

      <UsageSectionHeaderTypography my={1}>
        Electricity Rates for
        <DayOfWeekSelector
          dayOfWeek={selectedDayOfWeek}
          onSelect={setSelectedDayOfWeek}
        >
          <ColorBoxedTypography
            className={classes.currentRateLabelText}
            variant="subtitle1"
            hexColor={theme.palette.blue.main}
            py={0}
            asButton
            spaceBefore
          >
            {todayIsSelected
              ? 'Today'
              : dayjs().day(selectedDayOfWeek).format('dddd')}
            &nbsp;
            <ChevronDown width="1rem" className="align-middle" />
          </ColorBoxedTypography>
        </DayOfWeekSelector>
      </UsageSectionHeaderTypography>

      <DailyTOURateViz
        coloredStops={coloredStops}
        selectedColorStop={selectedColorStop}
        setSelectedColorStop={setSelectedColorStop}
        defaultHour={currentHour}
      />
    </DashboardSections>
  )
}

export default DailyTOURateOverview
