type Cost = {
  amount: number | null
  frequency: 'year' | 'month' | 'one-time' | 'free' | null
}

type Subscription = {
  region: string
  service: string
  package: string
  cost: Cost
  url: string
}

type VehicleSubscription = Record<string, Subscription[]>

export const vehicleSubscriptions: VehicleSubscription = {
  acura: [
    {
      region: 'US, Canada',
      service: 'ACURALINK',
      package: 'Remote',
      cost: { amount: 110, frequency: 'year' }, // After free trial
      url: 'https://mygarage.honda.com/s/acuralink-marketing',
    },
  ],
  'alfa romeo': [
    {
      region: 'US, Europe',
      service: 'My Alfa Connect',
      package: 'My Car and My Remote',
      cost: { amount: null, frequency: 'free' }, // Free for 3-5 years
      url: 'https://www.alfaromeoconnect.com/en-us.html',
    },
  ],
  audi: [
    {
      region: 'US',
      service: 'myAudi',
      package: 'CARE',
      cost: { amount: null, frequency: null }, // No info
      url: 'https://www.audiusa.com/myaudi',
    },
    {
      region: 'Europe, Canada',
      service: 'Audi Connect',
      package: 'Remote',
      cost: { amount: 120, frequency: 'year' }, // After free trial
      url: 'https://www.audiusa.com/us/web/en/inside-audi/innovation/audi-connect.html?',
    },
  ],
  bmw: [
    {
      region: 'All',
      service: 'BMW Connected',
      package: '-',
      cost: { amount: 120, frequency: 'year' }, // After trial
      url: 'https://www.bmw.com/en-au/shop/ls/cp/connected-drive',
    },
  ],
  buick: [
    {
      region: 'US, Canada',
      service: 'OnStar',
      package: 'Remote Access',
      cost: { amount: 14.99, frequency: 'month' }, // After trial
      url: 'https://www.buick.com/explore/connectivity/connected-services',
    },
  ],
  cadillac: [
    {
      region: 'US, Canada',
      service: 'OnStar',
      package: 'Remote Access',
      cost: { amount: 14.99, frequency: 'month' }, // After trial
      url: 'https://www.cadillac.com/ownercenter/onstar/shop/remote-access',
    },
  ],
  chevrolet: [
    {
      region: 'US, Canada',
      service: 'OnStar',
      package: 'Remote Access',
      cost: { amount: 14.99, frequency: 'month' }, // After trial
      url: 'https://www.onstar.c/connected-vehicle',
    },
  ],
  chrysler: [
    {
      region: 'US, Canada',
      service: 'Uconnect',
      package: 'Sirius XM Guardian (Remote Package)',
      cost: { amount: 12.99, frequency: 'month' }, // After trial
      url: 'https://www.chrysler.com/connectivity.html',
    },
  ],
  cupra: [
    {
      region: 'Europe',
      service: 'CUPRA CONNECT',
      package: 'Remote Access Services',
      cost: { amount: null, frequency: 'free' }, // Varies with region
      url: 'https://www.cupraofficial.com/owners/cupra-connect',
    },
  ],
  dodge: [
    {
      region: 'US, Canada',
      service: 'Uconnect',
      package: 'Sirius XM Guardian (Remote Package)',
      cost: { amount: 12.99, frequency: 'month' }, // After trial
      url: 'https://www.dodge.com/uconnect.html',
    },
  ],
  fiat: [
    {
      region: 'Europe',
      service: 'FIAT',
      package: 'My Car and My Remote',
      cost: { amount: null, frequency: 'free' }, // Free for 6 months
      url: 'https://connect.fiat.com/us/en/pricing-compare',
    },
    {
      region: 'US',
      service: 'FIAT Connect',
      package: 'Assistance Package',
      cost: { amount: 14.99, frequency: 'month' }, // After trial
      url: 'https://connect.fiat.com/us/en/pricing-compare',
    },
  ],
  ford: [
    {
      region: 'All',
      service: 'FordPass',
      package: '-',
      cost: { amount: null, frequency: null }, // No cost info
      url: 'https://www.ford.com/support/category/fordpass/',
    },
  ],
  gmc: [
    {
      region: 'US, Canada',
      service: 'OnStar',
      package: 'Remote Access',
      cost: { amount: 14.99, frequency: 'month' }, // After trial
      url: 'https://www.gmc.com/ownercenter/onstar/learn',
    },
  ],
  honda: [
    {
      region: 'US',
      service: 'HondaLink',
      package: 'EV Access for EV Data. Remote Access for Commands',
      cost: { amount: 14.99, frequency: 'month' }, // After trial
      url: 'https://mygarage.honda.com/s/hondalink-marketing',
    },
  ],
  hyundai: [
    {
      region: 'All',
      service: 'Hyundai BlueLink',
      package: '-',
      cost: { amount: 9.9, frequency: 'month' }, // After trial
      url: 'https://owners.hyundaiusa.com/us/en/index?origUri=/us/en/page/blue-link',
    },
    {
      region: 'US',
      service: 'Hyundai BlueLink (2023 IONIQ 6 & all 2024+)',
      package: 'BlueLink+',
      cost: { amount: null, frequency: null }, // No cost info
      url: 'https://owners.hyundaiusa.com/us/en/index?origUri=/us/en/page/blue-link',
    },
  ],
  infiniti: [
    {
      region: 'US',
      service: 'MyINFINITI',
      package: '-',
      cost: { amount: 12.99, frequency: 'month' }, // After trial
      url: 'https://www.infinitiusa.com/owners.html',
    },
  ],
  jaguar: [
    {
      region: 'All',
      service: 'Jaguar InControl',
      package: '-',
      cost: { amount: 100, frequency: 'year' }, // After trial
      url: 'https://incontrol.jaguar.com/jaguar-portal-owner-web/select-locale',
    },
  ],
  jeep: [
    {
      region: 'US, Canada',
      service: 'Uconnect',
      package: 'Sirius XM Guardian (Remote Package)',
      cost: { amount: 12.99, frequency: 'month' }, // After trial
      url: 'https://www.jeep.com/uconnect.html',
    },
    {
      region: 'US, Canada',
      service: 'Jeep Connect',
      package: 'Sirius XM Guardian (Remote Package)',
      cost: { amount: 12.99, frequency: 'month' }, // After trial
      url: 'https://www.jeep.com/connectivity.html',
    },
  ],
  kia: [
    {
      region: 'US, Canada',
      service: 'Kia Access',
      package: 'Plus',
      cost: { amount: 149, frequency: 'year' }, // After trial
      url: 'https://owners.kia.com/us/en/about-uvo-link.html',
    },
    {
      region: 'Europe',
      service: 'Kia Connect',
      package: '-',
      cost: { amount: null, frequency: 'free' }, // 7-year free trial
      url: 'https://owners.kia.com/us/en/about-uvo-link.html',
    },
  ],
  lexus: [
    {
      region: 'US, Canada',
      service: 'Lexus Enform',
      package: 'REMOTE',
      cost: { amount: 80, frequency: 'year' }, // After trial
      url: 'https://www.lexus.com/My-Lexus/resources/connected-technology',
    },
  ],
  mercedes: [
    {
      region: 'All',
      service: 'MeConnect',
      package: '-',
      cost: { amount: 15, frequency: 'year' }, // After trial
      url: 'https://www.mbusa.com/en/mercedes-me-connect',
    },
  ],
  mini: [
    {
      region: 'All',
      service: 'Mini Connected',
      package: '-',
      cost: { amount: 120, frequency: 'year' }, // After trial
      url: 'https://www.miniusa.com/why-mini/mini-connected-technology.html',
    },
  ],
  nissan: [
    {
      region: 'US (non-Leaf)',
      service: 'NissanConnect Services',
      package: 'Convenience',
      cost: { amount: 8, frequency: 'month' }, // After trial
      url: 'https://www.nissanusa.com/connect.html',
    },
    {
      region: 'US (Leaf)',
      service: 'NissanConnect EV Services',
      package: 'Convenience',
      cost: { amount: 8, frequency: 'month' }, // After trial
      url: 'https://www.nissanusa.com/connect.html',
    },
    {
      region: 'Europe',
      service: 'NissanConnect',
      package: '-',
      cost: { amount: 1.99, frequency: 'month' }, // After trial
      url: 'https://www.nissanusa.com/connect.html',
    },
  ],
  porsche: [
    {
      region: 'US, Europe',
      service: 'My Porsche',
      package: '-',
      cost: { amount: 299, frequency: 'year' }, // After trial
      url: 'https://identity.porsche.com/u/login/',
    },
  ],
  // rivian: [
  //   {
  //   region: 'all',
  //   service: 'Connect+',
  //   package: '-',
  //   cost: { amount: 14.99, frequency: 'month' }, // After trial
  //   url: 'https://stories.rivian.com/connect-plus',
  //   },
  // ],
  ram: [
    {
      region: 'US, Canada',
      service: 'Uconnect',
      package: 'Sirius XM Guardian (Remote Package)',
      cost: { amount: 12.99, frequency: 'month' }, // After trial
      url: 'https://www.ramtrucks.com/uconnect.html',
    },
    {
      region: 'US, Canada',
      service: 'Ram Connect',
      package: 'Sirius XM Guardian (Remote Package)',
      cost: { amount: 12.99, frequency: 'month' }, // After trial
      url: 'https://connect.ramtrucks.com/us/en/login',
    },
  ],
  subaru: [
    {
      region: 'US',
      service: 'MySubaru',
      package: 'STARLINK Security Plus',
      cost: { amount: 4.95, frequency: 'month' }, // After trial
      url: 'https://www.subaru.com/owners/starlink.html',
    },
  ],
  tesla: [
    {
      region: 'All',
      service: 'Tesla',
      package: '-',
      cost: { amount: null, frequency: null }, // No cost info
      url: 'https://auth.tesla.com/en_us/oauth2/v1/authorize?redirect_uri=https%3A%2F%2Fwww.tesla.com%2Fteslaaccount%2Fowner-xp%2Fauth%2Fcallback&response_type=code&client_id=ownership&scope=offline_access%20openid%20ou_code%20email%20phone&audience=https%3A%2F%2Fownership.tesla.com%2F&locale=en-US',
    },
  ],
  toyota: [
    {
      region: 'US, Canada',
      service: 'Toyota Connected Services',
      package: 'Remote Connect',
      cost: { amount: 80, frequency: 'year' }, // After trial
      url: 'https://www.toyota.com/connected-services/',
    },
  ],
  volvo: [
    {
      region: 'All',
      service: 'Volvo Cars',
      package: '-',
      cost: { amount: 200, frequency: 'year' }, // After trial
      url: 'https://volvoid.eu.volvocars.com/VolvoLogin/login?resumePath=JibO4owBXQ&client_id=Rf0n3Pz_10&relayFlags={%22hideInfoLink%22%3Atrue}&language=en&market=LB&tid=tid:EhMJ3iNvxPrpaKlMBWSfYJ6XixI',
    },
  ],
  volkswagen: [
    {
      region: 'US',
      service: 'Car-Net',
      package: 'Remote Access',
      cost: { amount: null, frequency: null }, // No cost info
      url: 'https://connect.volkswagen.com/connectivity.html',
    },
    {
      region: 'Europe (non-ID) 2018 - 2022',
      service: 'Car-Net',
      package: '-',
      cost: { amount: null, frequency: null }, // No cost info
      url: 'https://connect.volkswagen.com/connectivity.html',
    },
    {
      region: 'Europe (non-ID) ≤ 2023',
      service: 'We Connect',
      package: '-',
      cost: { amount: null, frequency: null }, // No cost info
      url: 'https://connect.volkswagen.com/connectivity.html',
    },
    {
      region: 'Europe (non-ID) 2024+',
      service: 'VW Connect',
      package: '-',
      cost: { amount: null, frequency: null }, // No cost info
      url: 'https://connect.volkswagen.com/connectivity.html',
    },
    {
      region: 'Europe (ID) Firmware <3',
      service: 'We Connect Start',
      package: '-',
      cost: { amount: null, frequency: null }, // No cost info
      url: 'https://connect.volkswagen.com/connectivity.html',
    },
    {
      region: 'Europe (ID) Firmware v3+',
      service: 'We Connect ID',
      package: '-',
      cost: { amount: null, frequency: null }, // No cost info
      url: 'https://connect.volkswagen.com/connectivity.html',
    },
  ],
}
