import { createTheme } from '@material-ui/core/styles'
import { blue } from '@material-ui/core/colors'

import typography from './typography'
import overrides from './overrides'
import breakpoints from './breakpoints'
import props from './props'
import shadows from './shadows'
import spectrum from './colorSpectrums'

const theme = {
  spacing: 4,
  breakpoints: breakpoints,
  overrides: overrides,
  props: props,
  typography: typography,
  shadows: shadows,
  body: {
    background: '#F2F2F2',
  },
  header: {
    color: '#343434',
    background: '#FFF',
    search: {
      color: '#EBEBEB',
    },
    indicator: {
      background: '#5D5D5D',
    },
  },
  palette: {
    primary: {
      main: '#AF4BFB',
      contrastText: '#FFF',
    },
    action: {
      disabled: '#F7EDFF',
      disabledBackground: '#AF4BFBAD',
    },
    secondary: {
      main: '#F7EDFF',
      contrastText: '#343434',
    },
    blue: {
      main: '#426FFB',
      contrastText: '#FFF',
      background: '#ECF1FF',
    },
    purple: {
      main: '#AF4BFB',
      dark: '#8d05f4',
      contrastText: '#FFF',
    },
    green: {
      main: '#00D73F',
      contrastText: '#FFF',
    },
    red: {
      main: '#fb4242',
      secondary: '#ffeded',
    },
    body: {
      main: '#5D5D5D',
    },
    spectrum,
  },
  sidebar: {
    color: '#FFF',
    background: blue[700],
    header: {
      color: '#FFF',
      background: blue[800],
      brand: {
        color: '#FFFFFF',
      },
    },
    footer: {
      color: '#FFF',
      background: blue[800],
      online: {
        background: '#FFF',
      },
    },
    badge: {
      color: '#000',
      background: '#FFF',
    },
  },
} as const

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    blue?: { [key: string]: string }
  }

  interface PaletteOptions {
    blue?: { [key: string]: string }
  }
}

export default createTheme(theme)
