import './styles.scss'
import formatClasses from '../../utils/classes/formatClasses'
import twMerge from '@/utils/classes/twMerge'
import { HTMLAttributes } from 'react'

export interface Props extends HTMLAttributes<HTMLElement> {
  align?: 'left' | 'center' | 'right'
  fontSize?: string
  onClick?: (event?: React.MouseEvent<HTMLElement>) => void
  variant?:
    | 'title'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h3-subheader'
    | 'subheader'
    | 'body1'
    | 'body2'
    | 'body4'
    | 'body3'
    | 'body5'
    | 'link'
    | 'label'
    | 'dimmed'
  styled?: boolean
}

const defaultProps: Partial<Props> = {
  variant: 'body1',
  styled: true,
}

export default function Text({ ...props }: Props) {
  props = { ...defaultProps, ...props }

  const {
    variant,
    align,
    className,
    color,
    style,
    onClick,
    children,
    styled,
    ...restProps
  } = props

  let Tag
  let variantClassNames = 'font-bold '

  switch (variant) {
    case 'title':
      Tag = 'p'
      variantClassNames += 'text-title text-themed-grey-900'
      break
    case 'link':
      Tag = 'a'
      variantClassNames +=
        'text-link !underline !text-themed-blue-500 cursor-pointer'
      break
    case 'h1':
      variantClassNames += 'text-h1 text-themed-grey-900'
      Tag = 'h1'
      break
    case 'h2':
      variantClassNames += 'text-h2 text-themed-grey-900'
      Tag = 'h2'
      break
    case 'h3':
      variantClassNames += 'text-h3 text-themed-grey-900'
      Tag = 'h3'
      break
    case 'h3-subheader':
      variantClassNames += 'text-h3-subheader text-themed-grey-500'
      Tag = 'h3'
      break
    case 'subheader':
      variantClassNames += 'text-subheader text-themed-grey-900'
      Tag = 'p'
      break
    case 'body1':
      variantClassNames += '!text-body1 text-themed-grey-900 leading-body1'
      Tag = 'p'
      break
    case 'body2':
      variantClassNames +=
        '!text-body2 !font-medium text-themed-grey-500 leading-body2'
      Tag = 'p'
      break
    case 'body3':
      variantClassNames += '!text-body3 text-themed-grey-500 leading-body3'
      Tag = 'p'
      break
    // TODO switch body3 for body4 because body3 is smaller
    case 'body4':
      variantClassNames +=
        '!text-body4 text-themed-grey-500 leading-body4 font-medium'
      Tag = 'p'
      break
    case 'label':
      variantClassNames += 'text-label text-themed-blue-500 uppercase'
      Tag = 'p'
      break
    case 'dimmed':
      variantClassNames += '!text-sm font-medium text-optiwatt-grey-500'
      Tag = 'p'
      break
    default:
      variantClassNames += 'text-body1 text-themed-grey-900'
      Tag = 'p'
      break
  }

  const Component = Tag as 'p' | 'h1' | 'h2' | 'h3' | 'a'

  const classes = styled
    ? formatClasses(['text', twMerge(variantClassNames, className)])
    : formatClasses(['text', className])

  const styles = () => {
    return {
      fontSize: props?.fontSize,
      textAlign: align,
      ...style,
    }
  }

  return (
    <Component
      id="text"
      className={classes}
      onClick={(e) => variant === 'link' && onClick?.(e)}
      style={styles()}
      {...restProps}
    >
      {children}
    </Component>
  )
}
