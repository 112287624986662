import { createResourceCollection } from '@/request'
import { TimedUsageStats } from '@/types'
import { ResourceModel } from '@/request/types'

export type Usages = {
  source: 'meter' | 'vehicle'
  total_cost: number
  total_usage_kwh: number
  usage_stats: TimedUsageStats[]
} & ResourceModel

export const homeUsagesCollection = createResourceCollection<Usages>({
  name: 'homeUsages',
  apiConfig: {
    path: 'usage/home',
  },
  selector: (state) => state.homeUsages,
})

export default homeUsagesCollection.slice.reducer
