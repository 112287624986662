import { useState } from 'react'
import { motion } from 'framer-motion'
import { Range, getTrackBackground, Direction } from 'react-range'
import clsx from 'clsx'
import './styles.scss'
import { IRenderThumbParams, IRenderTrackParams } from 'react-range/lib/types'
import twMerge from '@/utils/classes/twMerge'
import Grid from '../Grid'
import Text from '../Text'

export const DEFAULT_MIN_TEMP_SETTING = 50
export const DEFAULT_MAX_TEMP_SETTING = 90
export const DEFAULT_OFFSET = 3

type DualThumbRangeSliderProps = {
  startingValues: [number, number]
  valueBounds?: [number, number]
  minimumOffset?: number
  defaultOffsets?: [number?, number?]
  className?: string
  onChange?: (value: number, side: 'left' | 'right') => void
}

type ThumbProps = {
  isDragged: boolean
  disabled: boolean
  slider: { values: number[] }
}

// Animation variants for the Thumb component
const thumbVariants = {
  initial: { outlineOffset: '1px' },
  dragged: { outlineOffset: '3px' },
}

const activeHoverVariants = {
  initial: { opacity: 0, y: 0 },
  dragged: { opacity: 1, y: -45 },
}

const thumbInputVariants = {
  initial: { scale: 1 },
  dragged: {
    scale: 1.1,
    shadow:
      '0px 0px 0px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 4px 8px rgba(0, 0, 0, 0.3)',
  },
}

// Thumb component
function Thumb({ isDragged, slider, disabled, ...props }: ThumbProps) {
  const currentAnimationState = isDragged ? 'dragged' : 'initial'
  const roundedValue = Math.round(slider.values?.[0])

  return (
    <motion.div
      {...props}
      variants={thumbVariants}
      transition={{ duration: 0.15 }}
      animate={currentAnimationState}
      className={clsx('thumb-container', {
        'thumb-container-disabled': disabled,
      })}
    >
      <motion.div
        className="active-hover"
        variants={activeHoverVariants}
        animate={currentAnimationState}
      >
        {roundedValue}°
      </motion.div>
      <motion.div
        variants={thumbInputVariants}
        animate={currentAnimationState}
        className="thumb-input"
      >
        <motion.span className="text">{roundedValue}</motion.span>
      </motion.div>
    </motion.div>
  )
}

// function for rendering track
function renderTrack(
  trackProps: IRenderTrackParams | null,
  values: number[],
  min: number,
  max: number,
  colors: string[],
  style?: React.CSSProperties,
) {
  const { props, children } = trackProps || {}
  const trackStyle = {
    ...props?.style,
    height: '4px',
    background: getTrackBackground({
      values,
      direction: Direction.Right,
      colors,
      min,
      max,
    }),
    ...style,
  }

  return (
    <div {...props} className="track" style={trackStyle}>
      {children}
    </div>
  )
}

function DualThumbRangeSlider({
  className,
  startingValues: [leftStartingValue, rightStartingValue],
  valueBounds = [DEFAULT_MIN_TEMP_SETTING, DEFAULT_MAX_TEMP_SETTING],
  minimumOffset = 1,
  defaultOffsets,
  onChange,
}: DualThumbRangeSliderProps) {
  const offsets = [
    defaultOffsets?.[0] ?? minimumOffset,
    defaultOffsets?.[1] ?? minimumOffset,
  ]
  const [leftSlider, setLeftSlider] = useState({
    values: [leftStartingValue - offsets[0]],
  })
  const [rightSlider, setRightSlider] = useState({
    values: [rightStartingValue + offsets[1]],
  })
  const diff = rightStartingValue - leftStartingValue

  function handleSliderChange(side: 'left' | 'right') {
    return (values: number[]) => {
      if (side === 'left') {
        setLeftSlider({ values })
      }
      if (side === 'right') {
        setRightSlider({ values })
      }
    }
  }

  function handleFinalChange(side: 'left' | 'right') {
    return (values: number[]) => {
      if (onChange) {
        const value = Math.round(values[0])
        onChange(value, side)
      }
    }
  }

  // function for rendering thumb
  function renderThumb(
    { props, isDragged }: IRenderThumbParams,
    slider: { values: number[] },
    disabled = false,
  ) {
    return (
      <Thumb
        {...props}
        isDragged={isDragged}
        slider={slider}
        disabled={disabled}
      />
    )
  }

  function OffsetSpacer({ side }: { side: 'left' | 'right' }) {
    const color = side === 'right' ? '#426BFB' : '#FB5842BF'
    return (
      <div
        className={clsx({
          'w-6 xs:w-10 sm:w-12': minimumOffset <= 1,
          'w-8 xs:w-14 sm:w-16': minimumOffset === 2,
          'w-10 xs:w-16 sm:w-24': minimumOffset >= 3,
        })}
        style={{ backgroundColor: color }}
      />
    )
  }

  const keepTempInRange = (range: number[], values: number[]) => {
    const value = values[0]

    if (value < range[0]) return [range[0]]
    else if (value > range[1]) return [range[1]]
    else return [value]
  }

  const keepStartingValuesInRange = (
    side: 'left' | 'right',
    rangeValue: number,
    value: number,
  ) => {
    if (side === 'left') {
      if (value <= rangeValue) return rangeValue + DEFAULT_OFFSET
      else return value
    } else {
      if (value >= rangeValue) return rangeValue - DEFAULT_OFFSET
      else return value
    }
  }

  return (
    <div className={`${twMerge('optiwatt-dualthumbrangeslider', className)}`}>
      <Range
        min={valueBounds[0]}
        max={keepStartingValuesInRange(
          'left',
          valueBounds[0],
          leftStartingValue - minimumOffset,
        )}
        step={0.1}
        values={keepTempInRange(
          [valueBounds[0], leftStartingValue - minimumOffset],
          leftSlider.values,
        )}
        direction={Direction.Right}
        disabled={
          leftStartingValue - minimumOffset < valueBounds[0] + DEFAULT_OFFSET
        }
        onFinalChange={handleFinalChange('left')}
        onChange={handleSliderChange('left')}
        renderTrack={(props) =>
          renderTrack(
            props,
            keepTempInRange(
              [valueBounds[0], leftStartingValue - minimumOffset],
              leftSlider.values,
            ),
            valueBounds[0],
            leftStartingValue - minimumOffset,
            ['#E5E5E5', '#FB5842BF'],
            { borderRadius: '10px 0px 0px 10px' },
          )
        }
        renderThumb={(props) =>
          renderThumb(
            props,
            leftSlider,
            leftStartingValue - minimumOffset < valueBounds[0] + DEFAULT_OFFSET,
          )
        }
      />
      <OffsetSpacer side="left" />
      <div
        className={clsx('current-schedule', {
          'w-0': diff === 0,
          'w-[60px]': diff > 0 && diff <= 2,
          'w-[80px]': diff > 2,
        })}
      >
        <div className="current-schedule-text">
          <div>{leftStartingValue}°</div>
          {diff > 0 && <div>{rightStartingValue}°</div>}
        </div>
      </div>
      <OffsetSpacer side="right" />
      <Range
        min={keepStartingValuesInRange(
          'right',
          valueBounds[1],
          rightStartingValue + minimumOffset,
        )}
        max={valueBounds[1]}
        step={0.1}
        values={keepTempInRange(
          [rightStartingValue + minimumOffset, valueBounds[1]],
          rightSlider.values,
        )}
        direction={Direction.Right}
        disabled={
          rightStartingValue + minimumOffset > valueBounds[1] - DEFAULT_OFFSET
        }
        onFinalChange={handleFinalChange('right')}
        onChange={handleSliderChange('right')}
        renderTrack={(props) =>
          renderTrack(
            props,
            keepTempInRange(
              [rightStartingValue + minimumOffset, valueBounds[1]],
              rightSlider.values,
            ),
            rightStartingValue + minimumOffset,
            valueBounds[1],
            ['#426BFB', '#E5E5E5', '#E5E5E5'],
            { borderRadius: '0px 10px 10px 0px' },
          )
        }
        renderThumb={(props) =>
          renderThumb(
            props,
            rightSlider,
            rightStartingValue + minimumOffset >
              valueBounds[1] - DEFAULT_OFFSET,
          )
        }
      />
    </div>
  )
}

type LegendProps = {
  startingRangeLabel: string
  offsetsLabel: string
  className?: string
}

function Legend({ startingRangeLabel, offsetsLabel, className }: LegendProps) {
  const renderLegendTrack = (colors: [string, string]) =>
    renderTrack(null, [50], 0, 100, colors, {
      width: '20px',
      borderRadius: '10px',
    })

  return (
    <Grid
      flow="row"
      placeItems="center start"
      templateColumns="20px 1fr"
      gap="4px 10px"
      className={className}
    >
      {renderLegendTrack(['#5D5D5D', '#5D5D5D'])}
      <Text variant="body3">{startingRangeLabel}</Text>
      {renderLegendTrack(['#FB5842BF', '#426BFB'])}
      <Text variant="body3">{offsetsLabel}</Text>
    </Grid>
  )
}
Legend.displayName = 'Legend'
DualThumbRangeSlider.Legend = Legend

export default DualThumbRangeSlider
