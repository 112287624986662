import { useAppSelector } from '@/hooks'
import {
  selectProgramViewConfigFromAllSources,
  selectUtilityProgramEnrollmentActive,
} from '@/selectors'
import { ID } from '@/types/model'
import { ReactNodeProps } from '@/types/reactNodeProps'
import { selectNestedProperty } from '@/utils/object'
import { createContext, useContext } from 'react'

export type FunctionalityConfig = {
  hide: boolean
}
function isFunctionalityConfig(x: any): x is FunctionalityConfig {
  // note this is currently sound but not complete. We will never allow an
  // object that isnt a FunctionalityConfig to be passed in, but we may
  // miss an empty FunctionalityConfig which is fine because theres nothing to
  // do with it
  return x.hide !== undefined
}

export type FunctionalityConfigSpec = {
  [key: string]: FunctionalityConfig | FunctionalityConfigSpec
}

function useMergeGlobalConfigs(props: Props) {
  // @todo as we get more configs, we can merge them here
  const configsFromEnrolledProgram = useAppSelector((state) => {
    const programActive = selectUtilityProgramEnrollmentActive(
      state,
      props.vehicleId,
    )
    if (!programActive) {
      return undefined
    }
    const viewConfig = selectProgramViewConfigFromAllSources(
      state,
      props.vehicleId,
    )
    return viewConfig?.enrolledFunctionality
  })
  return configsFromEnrolledProgram as FunctionalityConfigSpec | undefined
}

export const ConfigurableFunctionalityContext = createContext({
  // The features object is a map of feature names to functionality configs, that can be used
  // by the consumer to determine whether or not to show a feature
  // Example:
  // {
  //   'feature1': { hide: true },
  //   'feature2': { hide: false },
  // }
  features: {} as { [key: string]: FunctionalityConfig },
  // The current scope key path is an array of strings that represent the current scope
  // of the ConfigurableFunctionalityProvider tree. This is used to index the global config.
  // When you use pushScope, the scope you provide is pushed on to the current scope key path.
  _currentScopeKeyPath: [] as string[],
})

export type Props = ReactNodeProps & { vehicleId?: ID } & (
    | { pushScope: string }
    | { scope: string }
  )
function propsHavePushScope(
  props: Props,
): props is { pushScope: string } & ReactNodeProps & { vehicleId?: ID } {
  return (props as { pushScope: string }).pushScope !== undefined
}

export function ConfigurableFunctionalityProvider(props: Props) {
  const globalConfig = useMergeGlobalConfigs(props)
  const inheritedValue = useContext(ConfigurableFunctionalityContext)
  const useFreshScope = !propsHavePushScope(props)
  // if just scope was provided, start from the top level config by indexing the global config
  // with the scope
  // else if pushScope was provided, start from the current scope by indexing the global config
  // with the accumulated key path + pushScope
  const currentScopeKeyPath = useFreshScope
    ? [props.scope]
    : [...inheritedValue._currentScopeKeyPath, props.pushScope]
  const currentScope = selectNestedProperty(
    globalConfig ?? {},
    ...currentScopeKeyPath,
  )

  // Note: if isFunctionalityConfig(currentScope) is true, then currentScope is a leaf node
  // because we want to provide a set of features to the consumer, not a single feature
  if (!currentScope || isFunctionalityConfig(currentScope)) {
    return (
      <ConfigurableFunctionalityContext.Provider value={inheritedValue}>
        {props.children}
      </ConfigurableFunctionalityContext.Provider>
    )
  }

  const featuresInCurrentConfig = Object.keys(currentScope).reduce(
    (acc, key) => {
      const value = currentScope[key]
      if (isFunctionalityConfig(value)) {
        acc[key] = value
      }
      return acc
    },
    {} as { [key: string]: FunctionalityConfig },
  )

  // if were starting a fresh scope, dont use any features inherited
  const features = useFreshScope
    ? featuresInCurrentConfig
    : { ...inheritedValue.features, ...featuresInCurrentConfig }

  return (
    <ConfigurableFunctionalityContext.Provider
      value={{ features, _currentScopeKeyPath: currentScopeKeyPath }}
    >
      {props.children}
    </ConfigurableFunctionalityContext.Provider>
  )
}
