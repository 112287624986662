import React, { useEffect } from 'react'
import { BatteryCharging, Gift, Home, User } from 'react-feather'
import { getPathFromRoutes } from '../../utils/routeUtilities'
import {
  LocationTag,
  pageWithErrorBoundary,
} from '../../authenticated/components/ErrorBoundary'
import AppLayout from '../../authenticated/layouts/Dashboard'
import { useIsExperimentalUser } from '../../authenticated/hooks/useIsExperimentalUser'
import { Route, RouteProps, Switch, useHistory } from 'react-router-dom'
import Dashboard from '../pages/app/index'

// Pages components
import { LazyPage } from '../components'
import NotificationSettingsPage from '@/app/pages/devices/thermostat/notification-settings'
import PrecoolPreheatSettingsPage from '@/app/pages/devices/thermostat/precool-preheat-settings'
import KeyConfirmationPage from '../pages/tesla-authentication/key-confirmation'
import KeyConnectionPage from '../pages/tesla-authentication/key-connection'
import AutogridConfirmation from '../pages/autogrid/confirmation'
import AutogridVerifiedExistingUserPage from '../pages/autogrid/confirmation/verified-existing-user'
import AutogridSuccess from '../pages/autogrid/success'
import AutogridVehicleEnrollment from '../pages/autogrid/enroll'
import EditPermissionsPage from '../pages/tesla-authentication/edit-permissions'
import ConfigureVehicles from '../pages/connect-vehicle/configure'
import StagingToLocalTeslaRedirect from '../pages/tesla-authentication/local'
import ConnectTeslaStartPage from '../pages/connect-vehicle/tesla'
import ThermostatUtilityProgramEnrollment from '@/app/pages/devices/thermostat/utility-program'
import HomeEstimationsPage from '@/app/pages/home-estimations/HomeEstimationsPage'
import HomeHvacModelNoForm from '@/app/pages/home-estimations/model-number/HomeHvacModelNoForm'
import HomeEstimationWizard from '@/app/pages/home-estimations/wizard/HomeEstimationWizard'
import HomeEstimationEditDetails from '@/app/pages/home-estimations/edit/HomeEstimationEditDetails'
import HomeEstimationSuccess from '@/app/pages/home-estimations/success/HomeEstimationSuccess'
import {
  HOME_ESTIMATION_ROOT,
  HomeEstimationPages,
} from '@/app/pages/home-estimations/useHomeEstimationNavigation'
import UtilityEnrollment from '../pages/utility-enrollment'
import HomeCurrentRatePage from '@/app/pages/home-insights/current-rate'
import LinkedVehicles from '@/authenticated/components/LinkedVehicles'
import HomeComparisonSurveyPage from '@/app/pages/home-insights/survey'
import EvApology from '../pages/ev-apology/ev-apology'
import ConnectFordMobileRedirect from '@/app/pages/connect-vehicle/ford/ConnectFordMobileRedirect'
import VehicleLimitsPage from '@/app/pages/vehicle-limits/VehicleLimitsPage'
import VehicleGoalsPage from '@/app/pages/vehicle-goals/VehicleGoalsPage'

import ScheduleCharging from '../pages/schedule/vehicle/scheduling/schedule-charging'
import ContactUsPage from '@/app/pages/contact-us/ContactUsPage'
import DeviceConnectivity from '../pages/schedule/vehicle/connectivity'
import DeviceCapability from '../pages/schedule/vehicle/capability'
import OnboardingConnectAnotherPage from '@/app/pages/onboarding/connect-another/OnboardingConnectAnotherPage'
import BecomeUtilityCustomerPage from '@/app/pages/become-utility-customer/BecomeUtilityCustomerPage'
import AvaVerifyResidencePage from '@/app/pages/ava/onboarding/verify-residence/AvaVerifyResidencePage'
import ProgramEnrollmentOfferPage from '@/app/pages/program-enrollment/offer/ProgramEnrollmentOfferPage'
import ProgramEnrollmentEnrollPage from '@/app/pages/program-enrollment/enroll/ProgramEnrollmentEnrollPage'
import UtilityProgramEnrollmentSuccess from '@/app/pages/program-enrollment/success/UtilityProgramEnrollmentSuccessPage'
import PlanSelectPage from '@/app/pages/[utility]/plan-select'
import PlanSelectOptionSelectPage from '@/app/pages/[utility]/plan-select/select'
import PlanSelectManualEntryPage from '@/app/pages/[utility]/plan-select/manual-entry'
import PlanSelectUploadPage from '@/app/pages/[utility]/plan-select/upload'
import ProgramStartGateway from '@/app/pages/[utility]/start'
import NotOwnerPage from '../pages/tesla-authentication/key-connection/not-owner'
import NowOwnerNoKeycardPage from '../pages/tesla-authentication/key-connection/not-owner/no-keycard'
import EmailOwnerPage from '../pages/tesla-authentication/key-connection/not-owner/no-keycard/email'
import AvaAddressSelectPage from '../pages/ava/onboarding/address-select/AvaAddressSelectPage'
import ManageUtilityPlan from '@/authenticated/pages/Profile/Plan'

const Profile = React.lazy(() => import('../../authenticated/pages/Profile'))
const Rewards = React.lazy(() => import('../pages/rewards'))
const CalendarPage = React.lazy(() => import('@/app/pages/devices/calendar'))
const VehicleSupportLocalGridDetails = React.lazy(
  () => import('../pages/schedule/vehicle/local-grid-support-details'),
)
const DevicesPage = React.lazy(() => import('../pages/devices'))
const ConnectThermostat = React.lazy(
  () => import('../../authenticated/pages/ConnectThermostat'),
)
const ConnectThermostatNotFound = React.lazy(
  () =>
    import(
      '../../authenticated/pages/ConnectThermostat/not-found/ThermostatNotFound'
    ),
)
const ConnectVehicle = React.lazy(() => import('../pages/connect-vehicle'))
const ConnectVehicleNotFound = React.lazy(
  () => import('../pages/connect-vehicle/not-found'),
)
const ConnectUtility = React.lazy(() => import('../pages/connect-utility'))
const UtilityProgramEnrollment = React.lazy(
  () => import('../pages/utility-program-enrollment'),
)
const MultiProgramEnrollment = React.lazy(
  () => import('../pages/multi-program-enrollment'),
)
const LevelThree = React.lazy(
  () => import('../pages/utility-program/level-three/level-three'),
)
const Onboarding = React.lazy(() => import('../pages/onboarding'))
const AvaOnboarding = React.lazy(() => import('../pages/ava/onboarding'))
const HeardFromSurvey = React.lazy(() => import('../pages/heard-from-survey'))
const ThermostatGridSupport = React.lazy(
  () => import('@/app/pages/devices/thermostat/grid-support'),
)
const ConnectDevice = React.lazy(() => import('../pages/connect-device'))
const ConnectCharger = React.lazy(
  () => import('../pages/connect-device/charger'),
)
const PartnerLogin = React.lazy(() => import('../pages/autogrid/login'))

interface RouteSpec {
  id?: string
  name?: string
  path?: string | string[]
  icon?: React.ComponentType
  component?: React.ComponentType<RouteProps>
  children?: RouteSpec[] | null
  hidden?: boolean
  experimental?: boolean
}

const dashboardsRoutes: RouteSpec = {
  id: 'Home',
  path: ['/app', '/app/:deviceType?/:deviceId?'],
  icon: Home,
  component: pageWithErrorBoundary(Dashboard, {
    location: LocationTag.Dashboard,
    functionalityDescription: 'Dashboard',
    severity: 'critical',
  }),
  children: null,
}

const hiddenDashboardRoutes: RouteSpec = {
  id: 'HiddenDashboard',
  hidden: true,
  children: [
    {
      path: '/connect-device',
      name: 'Connect Device',
      component: pageWithErrorBoundary(LazyPage(ConnectDevice), {
        location: LocationTag.Dashboard,
        functionalityDescription: 'Device Connection',
        severity: 'critical',
      }),
    },
    {
      path: '/connect-device/vehicle',
      name: 'Connect Vehicle',
      component: pageWithErrorBoundary(LazyPage(ConnectVehicle), {
        location: LocationTag.Dashboard,
        functionalityDescription: 'Vehicle Connection',
        severity: 'critical',
      }),
    },
    {
      path: [
        '/connect-device/vehicle/tesla',
        '/connect-vehicle/tesla',
        '/ava/connect-device/vehicle/tesla',
        '/ava/connect-vehicle/tesla',
      ],
      name: 'Connect Tesla',
      component: pageWithErrorBoundary(ConnectTeslaStartPage, {
        location: LocationTag.Dashboard,
        functionalityDescription: 'Tesla Connection Prompt',
        severity: 'critical',
      }),
    },
    {
      path: [
        '/:utility?/connect-device/vehicle/ford',
        '/:utility?/connect-vehicle/ford',
      ],
      name: 'Connect Ford',
      component: pageWithErrorBoundary(ConnectFordMobileRedirect, {
        location: LocationTag.Dashboard,
        functionalityDescription: 'Ford Connection Prompt',
        severity: 'critical',
      }),
    },
    {
      path: [
        '/:utility/onboarding/connect-device/charger*',
        '/:utility?/connect-device/charger*',
      ],
      name: 'Connect Charger',
      component: pageWithErrorBoundary(LazyPage(ConnectCharger), {
        location: LocationTag.Dashboard,
        functionalityDescription: 'Charger Connection',
        severity: 'critical',
      }),
    },
    {
      path: '/connect-device/thermostat',
      name: 'Connect Thermostat',
      component: pageWithErrorBoundary(LazyPage(ConnectThermostat), {
        location: LocationTag.Dashboard,
        functionalityDescription: 'Thermostat Connection',
        severity: 'critical',
      }),
    },
    {
      path: '/beta/connect-thermostat',
      name: 'Connect Thermostat',
      component: pageWithErrorBoundary(LazyPage(ConnectThermostat), {
        location: LocationTag.Dashboard,
        functionalityDescription: 'Thermostat Connection',
        severity: 'critical',
      }),
    },
    {
      path: '/connect-thermostat',
      name: 'Connect Thermostat',
      component: pageWithErrorBoundary(LazyPage(ConnectThermostat), {
        location: LocationTag.Dashboard,
        functionalityDescription: 'Thermostat Connection',
        severity: 'critical',
      }),
    },
    {
      path: '/connect-thermostat/not-found',
      name: 'Connect Thermostat',
      component: pageWithErrorBoundary(LazyPage(ConnectThermostatNotFound), {
        location: LocationTag.Dashboard,
        functionalityDescription: 'Thermostat Connection',
        severity: 'critical',
      }),
    },
    {
      path: ['/:utility?/connect-vehicle'],
      name: 'Connect Vehicle',
      component: pageWithErrorBoundary(LazyPage(ConnectVehicle), {
        location: LocationTag.Dashboard,
        functionalityDescription: 'Vehicle Connection',
        severity: 'critical',
      }),
    },
    {
      path: ['/connect-vehicle/configure', '/ava/connect-vehicle/configure'],
      name: 'Configure Vehicles',
      component: pageWithErrorBoundary(LazyPage(ConfigureVehicles), {
        location: LocationTag.Dashboard,
        functionalityDescription: 'Vehicle Configuration',
        severity: 'critical',
      }),
    },
    {
      path: ['/connect-vehicle/not-found', '/ava/connect-vehicle/not-found'],
      name: 'Configure Vehicles',
      component: pageWithErrorBoundary(LazyPage(ConnectVehicleNotFound), {
        location: LocationTag.Dashboard,
        functionalityDescription: 'Vehicle Configuration',
        severity: 'critical',
      }),
    },
    {
      path: [
        '/connect-device/vehicle/ev-apology/:make',
        '/connect-vehicle/ev-apology/:make',
        'ava/connect-vehicle/ev-apology/:make',
        'ava/connect-vehicle/ev-apology/:make',
      ],
      name: 'EV Apology',
      component: pageWithErrorBoundary(LazyPage(EvApology), {
        location: LocationTag.Dashboard,
        functionalityDescription: 'EV Apology Page',
        severity: 'critical',
      }),
    },
    {
      path: '/onboarding',
      name: 'Onboarding',
      component: pageWithErrorBoundary(LazyPage(Onboarding), {
        location: LocationTag.Dashboard,
        functionalityDescription: 'Onboarding',
        severity: 'critical',
      }),
    },
    {
      path: '/ava/onboarding',
      name: 'Onboarding',
      component: pageWithErrorBoundary(LazyPage(AvaOnboarding), {
        location: LocationTag.Dashboard,
        functionalityDescription: 'AvaOnboarding',
        severity: 'critical',
      }),
    },
    {
      path: '/heard-from-survey',
      name: 'Heard From Survey',
      component: pageWithErrorBoundary(LazyPage(HeardFromSurvey), {
        location: LocationTag.HeardFromSurvey,
        functionalityDescription: 'Heard From Survey',
      }),
    },
    {
      path: '/connect-utility*',
      name: 'Connect Utility',
      component: pageWithErrorBoundary(LazyPage(ConnectUtility), {
        location: LocationTag.Dashboard,
        functionalityDescription: 'Utility Connection',
        severity: 'critical',
      }),
    },
    {
      path: '/utility-program-enrollment',
      name: 'Utility Program Enrollment',
      component: pageWithErrorBoundary(LazyPage(UtilityProgramEnrollment), {
        location: LocationTag.UtilityProgramEnrollment,
        functionalityDescription: 'Enrollment of Utility Program',
        severity: 'critical',
      }),
    },
    {
      path: HOME_ESTIMATION_ROOT + HomeEstimationPages.Edit,
      name: 'HVAC Details',
      component: pageWithErrorBoundary(LazyPage(HomeEstimationEditDetails), {
        location: LocationTag.HvacDetails,
        functionalityDescription: 'HVAC Details',
        severity: 'critical',
      }),
    },
    {
      path: HOME_ESTIMATION_ROOT + HomeEstimationPages.ModelNum,
      name: 'HVAC Model Number',
      component: pageWithErrorBoundary(LazyPage(HomeHvacModelNoForm), {
        location: LocationTag.HvacDetails,
        functionalityDescription: 'HVAC Model Number Form',
        severity: 'critical',
      }),
    },
    {
      path: HOME_ESTIMATION_ROOT + HomeEstimationPages.Wizard,
      name: 'HVAC Details Wizard',
      component: pageWithErrorBoundary(LazyPage(HomeEstimationWizard), {
        location: LocationTag.HvacDetails,
        functionalityDescription: 'HVAC Details Wizard',
        severity: 'critical',
      }),
    },
    {
      path: HOME_ESTIMATION_ROOT + HomeEstimationPages.Success + '/:reason?',
      name: 'HVAC Edit Success',
      component: pageWithErrorBoundary(LazyPage(HomeEstimationSuccess), {
        location: LocationTag.HvacDetails,
        functionalityDescription: 'HVAC Edit Success',
        severity: 'critical',
      }),
    },
    {
      path: '/devices/vehicle/:deviceId?/trips',
      name: 'Trips',
      component: pageWithErrorBoundary(LazyPage(CalendarPage), {
        location: LocationTag.ChargeCalendar,
        functionalityDescription: 'Trip Planner',
      }),
    },
    {
      path: '/devices/thermostat/:deviceId?/notification-settings',
      name: 'Notification Settings',
      component: pageWithErrorBoundary(LazyPage(NotificationSettingsPage), {
        location: LocationTag.NotificationSettings,
        functionalityDescription: 'Thermostat Notification Settings',
      }),
    },
    {
      path: '/devices/thermostat/:deviceId?/precool-preheat-settings',
      name: 'Notification Settings',
      component: pageWithErrorBoundary(LazyPage(PrecoolPreheatSettingsPage), {
        location: LocationTag.PrecoolPreheatSettings,
        functionalityDescription: 'Thermostat Precool Preheat Settings',
      }),
    },
    {
      path: '/devices/thermostat/:deviceId?/grid-support',
      name: 'Grid Support',
      component: pageWithErrorBoundary(LazyPage(ThermostatGridSupport), {
        location: LocationTag.ThermostatGridSupport,
        functionalityDescription: 'Thermostat Grid Support Settings',
      }),
    },
    {
      path: '/devices/thermostat/:deviceId?/utility-enrollment',
      name: 'Grid Support',
      component: pageWithErrorBoundary(
        LazyPage(ThermostatUtilityProgramEnrollment),
        {
          location: LocationTag.ThermostatGridSupport,
          functionalityDescription: 'Thermostat Utility Program Enrollment',
        },
      ),
    },
    {
      path: '/devices/vehicle/:deviceId/scheduling',
      name: 'Schedule Charging',
      component: pageWithErrorBoundary(LazyPage(ScheduleCharging), {
        location: LocationTag.ChargeSchedule,
        functionalityDescription: 'Schedule Charge',
      }),
    },
    {
      path: '/devices/vehicle/:deviceId?/local-grid-details/:localGridId',
      name: 'Local Grid Details',
      component: pageWithErrorBoundary(
        LazyPage(VehicleSupportLocalGridDetails),
        {
          location: LocationTag.VehicleSupportLocalGridDetails,
          functionalityDescription: 'Local Grid Details',
        },
      ),
    },
    {
      path: '/devices/vehicle/:deviceId?/connectivity',
      name: 'device connectivity',
      component: pageWithErrorBoundary(LazyPage(DeviceConnectivity), {
        location: LocationTag.DeviceConnectivity,
        functionalityDescription: 'device connectivity',
      }),
    },
    {
      path: '/devices/vehicle/:deviceId?/capability',
      name: 'device capability',
      component: pageWithErrorBoundary(LazyPage(DeviceCapability), {
        location: LocationTag.DeviceConnectivity,
        functionalityDescription: 'device capability',
      }),
    },
    {
      path: '/schedule/thermostat/:deviceId/notification-settings', // TODO Remove after next mobile update
      name: 'Notification Settings',
      component: pageWithErrorBoundary(LazyPage(NotificationSettingsPage), {
        location: LocationTag.NotificationSettings,
        functionalityDescription: 'Thermostat Notification Settings',
      }),
    },
    {
      path: '/schedule/thermostat/:deviceId?/precool-preheat-settings', // TODO Remove after next mobile update
      name: 'Notification Settings',
      component: pageWithErrorBoundary(LazyPage(PrecoolPreheatSettingsPage), {
        location: LocationTag.PrecoolPreheatSettings,
        functionalityDescription: 'Thermostat Precool Preheat Settings',
      }),
    },
    {
      path: '/schedule/thermostat/:deviceId?/grid-support', // TODO Remove after next mobile update
      name: 'Grid Support',
      component: pageWithErrorBoundary(LazyPage(ThermostatGridSupport), {
        location: LocationTag.ThermostatGridSupport,
        functionalityDescription: 'Thermostat Grid Support Settings',
      }),
    },
    {
      path: '/schedule/thermostat/:deviceId?/utility-enrollment', // TODO Remove after next mobile update
      name: 'Grid Support',
      component: pageWithErrorBoundary(
        LazyPage(ThermostatUtilityProgramEnrollment),
        {
          location: LocationTag.ThermostatGridSupport,
          functionalityDescription: 'Thermostat Utility Program Enrollment',
        },
      ),
    },
    {
      path: '/app/vehicle-charger/:deviceId?/linked-vehicles',
      name: 'Linked Vehicles',
      component: pageWithErrorBoundary(LazyPage(LinkedVehicles), {
        location: LocationTag.VehicleChargerLinkedVehicles,
        functionalityDescription: 'Vehicle Charger Linked Vehicles',
      }),
    },
    {
      path: [
        '/tesla-authentication/key-connection/:vehicleId',
        '/ava/tesla-authentication/key-connection/:vehicleId',
        '/ava/onboarding/tesla-authentication/key-connection/:vehicleId',
      ],
      name: 'Tesla Key Confirmation',
      component: pageWithErrorBoundary(LazyPage(KeyConnectionPage), {
        location: LocationTag.Dashboard,
        functionalityDescription: 'Tesla Key Connection',
        severity: 'critical',
      }),
    },
    {
      path: [
        '/:utility?/tesla-authentication/key-connection/:vehicleId/not-owner',
        '/ava/onboarding/tesla-authentication/key-connection/:vehicleId/not-owner',
      ],
      name: 'Tesla Key Connection Not Owner',
      component: pageWithErrorBoundary(NotOwnerPage, {
        location: 'TeslaKeyConnectionNotOwner',
        functionalityDescription: 'Tesla Key Connection - Not Owner',
        severity: 'critical',
      }),
    },
    {
      path: [
        '/tesla-authentication/key-connection/:vehicleId/not-owner/no-keycard',
        '/ava/tesla-authentication/key-connection/:vehicleId/not-owner/no-keycard',
        '/ava/onboarding/tesla-authentication/key-connection/:vehicleId/not-owner/no-keycard',
      ],
      name: 'Tesla Key Connection Not Owner No Keycard',
      component: pageWithErrorBoundary(NowOwnerNoKeycardPage, {
        location: 'TeslaKeyConnectionNotOwnerNoKeycard',
        functionalityDescription: 'Tesla Key Connection - Not Owner',
        severity: 'critical',
      }),
    },
    {
      path: [
        '/tesla-authentication/key-connection/:vehicleId/not-owner/no-keycard/email',
        '/ava/tesla-authentication/key-connection/:vehicleId/not-owner/no-keycard/email',
        '/ava/onboarding/tesla-authentication/key-connection/:vehicleId/not-owner/no-keycard/email',
      ],
      name: 'Tesla Key Connection Email Owner',
      component: pageWithErrorBoundary(EmailOwnerPage, {
        location: 'TeslaKeyConnectionEmailOwner',
        functionalityDescription: 'Tesla key connection - Email owner form',
        severity: 'critical',
      }),
    },
    {
      path: [
        '/tesla-authentication/key-confirmation',
        '/ava/tesla-authentication/key-confirmation',
        '/ava/onboarding/tesla-authentication/key-confirmation',
      ],
      name: 'Tesla Key Confirmation For Onboarding',
      component: pageWithErrorBoundary(LazyPage(KeyConfirmationPage), {
        location: LocationTag.Dashboard,
        functionalityDescription: 'Tesla Key Confirmation For Onboarding',
        severity: 'critical',
      }),
    },
    {
      path: [
        '/tesla-authentication-local',
        '/ava/tesla-authentication-local',
        '/ava/onboarding/tesla-authentication-local',
      ],
      name: 'Staging -> Local redirect',
      component: pageWithErrorBoundary(LazyPage(StagingToLocalTeslaRedirect), {
        location: LocationTag.Dashboard,
        functionalityDescription: 'Tesla Key Confirmation For Onboarding',
        severity: 'critical',
      }),
    },
    {
      path: [
        '/tesla-authentication/key-confirmation/:vehicleId',
        '/ava/tesla-authentication/key-confirmation/:vehicleId',
        '/ava/onboarding/tesla-authentication/key-confirmation/:vehicleId',
      ],
      name: 'Tesla Key Confirmation For Onboarding',
      component: pageWithErrorBoundary(LazyPage(KeyConfirmationPage), {
        location: LocationTag.Dashboard,
        functionalityDescription: 'Tesla Key Confirmation For Onboarding',
        severity: 'critical',
      }),
    },
    {
      path: [
        '/tesla-authentication/edit-permissions',
        '/ava/tesla-authentication/edit-permissions',
        '/ava/onboarding/tesla-authentication/edit-permissions',
      ],
      name: 'Tesla Edit Permissions',
      component: pageWithErrorBoundary(LazyPage(EditPermissionsPage), {
        location: LocationTag.Dashboard,
        functionalityDescription: 'Tesla Edit Permissions',
        severity: 'critical',
      }),
    },
    {
      path: '/autogrid/login',
      name: 'Autogrid Login',
      component: pageWithErrorBoundary(LazyPage(PartnerLogin), {
        location: LocationTag.Dashboard,
        functionalityDescription: 'Partner Login',
        severity: 'critical',
      }),
    },
    {
      path: '/autogrid/confirmation',
      name: 'AutoGrid Confirmation',
      component: pageWithErrorBoundary(LazyPage(AutogridConfirmation), {
        location: LocationTag.Dashboard,
        functionalityDescription: 'AutoGrid Confirmation',
        severity: 'critical',
      }),
    },
    {
      path: '/autogrid/confirmation/verified-existing-user',
      name: 'AutoGrid Verified Existing User',
      component: pageWithErrorBoundary(
        LazyPage(AutogridVerifiedExistingUserPage),
        {
          location: LocationTag.Dashboard,
          functionalityDescription: 'AutoGrid Verified Existing User',
          severity: 'critical',
        },
      ),
    },
    {
      path: '/autogrid/success',
      name: 'AutoGrid Success',
      component: pageWithErrorBoundary(LazyPage(AutogridSuccess), {
        location: LocationTag.Dashboard,
        functionalityDescription: 'AutoGrid Success',
        severity: 'critical',
      }),
    },
    {
      path: '/autogrid/enroll',
      name: 'AutoGrid Enrollment',
      component: pageWithErrorBoundary(LazyPage(AutogridVehicleEnrollment), {
        location: LocationTag.Dashboard,
        functionalityDescription: 'AutoGrid Enrollment',
        severity: 'critical',
      }),
    },
    {
      path: HOME_ESTIMATION_ROOT,
      name: 'Home Estimations',
      component: pageWithErrorBoundary(LazyPage(HomeEstimationsPage), {
        location: LocationTag.HomeEstimations,
        functionalityDescription: 'Home Estimations',
        severity: 'critical',
      }),
    },
    {
      path: '/utility-enrollment',
      name: 'Utility Enrollment',
      component: pageWithErrorBoundary(LazyPage(UtilityEnrollment), {
        location: LocationTag.Dashboard,
        functionalityDescription: 'Utility Enrollment',
        severity: 'critical',
      }),
    },
    {
      path: '/home-insights/current-rate',
      name: 'Current Rate',
      component: pageWithErrorBoundary(LazyPage(HomeCurrentRatePage), {
        location: LocationTag.HomeCurrentRate,
        functionalityDescription: 'Home Current Rate',
        severity: 'critical',
      }),
    },
    {
      path: '/home-insights/survey/:response',
      name: 'Survey',
      component: pageWithErrorBoundary(LazyPage(HomeComparisonSurveyPage), {
        location: LocationTag.HomeComparisonSurvey,
        functionalityDescription: 'Home Comparison Survey',
        severity: 'critical',
      }),
    },
    {
      path: '/devices/vehicle/:deviceId?/limits',
      name: 'Vehicle Limits',
      component: pageWithErrorBoundary(LazyPage(VehicleLimitsPage), {
        location: LocationTag.VehicleLimits,
        functionalityDescription: 'Vehicle Limits',
        severity: 'critical',
      }),
    },
    {
      path: '/devices/vehicle/:deviceId?/goals',
      name: 'Vehicle Goals',
      component: pageWithErrorBoundary(LazyPage(VehicleGoalsPage), {
        location: LocationTag.VehicleGoals,
        functionalityDescription: 'Vehicle Goals',
        severity: 'critical',
      }),
    },
    {
      path: '/contact-us',
      name: 'Contact Us',
      component: pageWithErrorBoundary(LazyPage(ContactUsPage), {
        location: LocationTag.HomeComparisonSurvey,
        functionalityDescription: 'Contact Us',
        severity: 'critical',
      }),
    },
    {
      path: '/ava/onboarding/address-select',
      name: 'Address Select',
      component: pageWithErrorBoundary(LazyPage(AvaAddressSelectPage), {
        location: LocationTag.AvaAddressSelect,
        functionalityDescription: 'Address Selection',
        severity: 'critical',
      }),
    },
    {
      path: '/:utility?/onboarding/add-another',
      name: 'Add Another Device',
      component: pageWithErrorBoundary(LazyPage(OnboardingConnectAnotherPage), {
        location: LocationTag.OnboardingConnectAnother,
        functionalityDescription: 'Add Another Device',
        severity: 'critical',
      }),
    },
    {
      path: '/:utility/become-utility-customer',
      name: 'Become Utility Customer',
      component: pageWithErrorBoundary(LazyPage(BecomeUtilityCustomerPage), {
        location: LocationTag.BecomeUtilityCustomer,
        functionalityDescription: 'Become Utility Customer',
        severity: 'critical',
      }),
    },
    {
      path: '/ava/onboarding/verify-residence',
      name: 'Verify Residence',
      component: pageWithErrorBoundary(LazyPage(AvaVerifyResidencePage), {
        location: LocationTag.AvaVerifyResidence,
        functionalityDescription: 'Verify Your Residence',
        severity: 'critical',
      }),
    },
    {
      path: '/:utility?/program-enrollment/:programId/offer',
      name: 'Utility Program Offer',
      component: pageWithErrorBoundary(LazyPage(ProgramEnrollmentOfferPage), {
        location: LocationTag.UtilityProgramOffer,
        functionalityDescription: 'Utility Program Offer',
        severity: 'critical',
      }),
    },
    {
      path: '/:utility?/program-enrollment/:programId/enroll',
      name: 'Utility Program Enrollment',
      component: pageWithErrorBoundary(LazyPage(ProgramEnrollmentEnrollPage), {
        location: LocationTag.UtilityProgramEnrollmentNew,
        functionalityDescription: 'Utility Program Enrollment',
        severity: 'critical',
      }),
    },
    {
      path: '/:utility?/plan-select',
      name: 'Plan Select',
      component: pageWithErrorBoundary(PlanSelectPage, {
        location: LocationTag.PlanSelect,
        functionalityDescription: 'Plan Select',
        severity: 'critical',
      }),
    },
    {
      path: '/:utility?/plan-select/select',
      name: 'Plan Select - Option Select',
      component: pageWithErrorBoundary(PlanSelectOptionSelectPage, {
        location: LocationTag.PlanSelectOptionSelect,
        functionalityDescription: 'Plan Select - Option Select',
        severity: 'critical',
      }),
    },
    {
      path: '/:utility?/plan-select/manual-entry',
      name: 'Plan Select - Manual Entry',
      component: pageWithErrorBoundary(PlanSelectManualEntryPage, {
        location: LocationTag.PlanSelectManualEntry,
        functionalityDescription: 'Plan Select - Manual Entry',
        severity: 'critical',
      }),
    },
    {
      path: '/:utility?/plan-select/upload',
      name: 'Plan Select - Upload',
      component: pageWithErrorBoundary(PlanSelectUploadPage, {
        location: LocationTag.PlanSelectUpload,
        functionalityDescription: 'Plan Select - Upload',
        severity: 'critical',
      }),
    },
    {
      path: '/:utility?/program-enrollment/:programId?/success',
      name: 'Utility Program Enrollment Success',
      component: pageWithErrorBoundary(
        LazyPage(UtilityProgramEnrollmentSuccess),
        {
          location: LocationTag.UtilityProgramEnrollmentSuccess,
          functionalityDescription: 'Utility Program Enrollment Success',
          severity: 'critical',
        },
      ),
    },
    {
      path: '/:utility/start',
      name: 'Utility Program Onboarding Start',
      component: pageWithErrorBoundary(LazyPage(ProgramStartGateway), {
        location: LocationTag.UtilityProgramStart,
        functionalityDescription: 'Utility Program Start',
        severity: 'critical',
      }),
    },
    {
      path: '/multi-program-enrollment/:id/*',
      name: 'Utility Program Enrollment',
      component: pageWithErrorBoundary(LazyPage(MultiProgramEnrollment), {
        location: LocationTag.MultiProgramEnrollment,
        functionalityDescription: 'Multi program enrollment pages root',
        severity: 'critical',
      }),
    },
    {
      path: '/settings/plan*',
      name: 'Manage Utility Plan',
      component: pageWithErrorBoundary(LazyPage(ManageUtilityPlan), {
        location: LocationTag.ManageUtilityPlan,
        functionalityDescription: 'Manage Utility Plan',
        severity: 'critical',
      }),
    },
    {
      path: '/utility-program/:id/level-three',
      name: 'Utility Program Information',
      component: pageWithErrorBoundary(LazyPage(LevelThree), {
        location: LocationTag.UtilityProgramLevelThreeView,
        functionalityDescription: 'Utility Program Level Three View',
        severity: 'critical',
      }),
    },
  ],
}

const profileRoutes: RouteSpec = {
  id: 'Profile',
  path: '/settings',
  icon: User,
  component: pageWithErrorBoundary(LazyPage(Profile), {
    location: LocationTag.Profile,
    functionalityDescription: 'Profile Settings',
    severity: 'critical',
  }),
  children: null,
}

const rewardsRoutes: RouteSpec = {
  id: 'Rewards',
  path: '/rewards',
  icon: Gift,
  component: pageWithErrorBoundary(LazyPage(Rewards), {
    location: LocationTag.Rewards,
    functionalityDescription: 'Rewards',
    severity: 'critical',
  }),
  children: null,
}

const deviceRoutes: RouteSpec = {
  id: 'Devices',
  path: [
    '/charging/devices',
    '/charging/calendar',
    '/devices/:deviceType?/:deviceId?',
  ],
  icon: BatteryCharging,
  component: pageWithErrorBoundary(LazyPage(DevicesPage), {
    location: LocationTag.ChargeSchedule,
    functionalityDescription: 'Charge Schedule Setttings',
    severity: 'critical',
  }),
}

export const tabbedRouteSpecs: RouteSpec[] = [
  dashboardsRoutes,
  deviceRoutes,
  profileRoutes,
  rewardsRoutes,
  hiddenDashboardRoutes,
]

export const tabbedRoutePaths = getPathFromRoutes(tabbedRouteSpecs)

interface ExperimentalRouteProps extends RouteProps {
  experimental?: boolean
}

function ExperimentalRoute(props: ExperimentalRouteProps) {
  const isExperimentalUser = useIsExperimentalUser()
  return isExperimentalUser ? <Route {...props} /> : <></>
}

export const TabbedRoutes: React.FC = () => {
  const history = useHistory()

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (
        event.data.event?.type === 'navigation' &&
        event.data.event?.payload?.location
      ) {
        history.push(event.data.event?.payload?.location)
      }
    }

    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [history])

  const createRoutes = (routes: RouteSpec[]): JSX.Element[] =>
    routes
      .map(({ children, path, component: Component, experimental }, index) => {
        const RouteComponent = experimental ? ExperimentalRoute : Route
        return (
          !Component || !path
            ? []
            : [
                <RouteComponent
                  key={index}
                  path={path}
                  exact
                  render={(props: any) => (
                    <AppLayout>
                      <Component {...props} />
                    </AppLayout>
                  )}
                />,
              ]
        ).concat(children ? createRoutes(children) : [])
      })
      .flat()

  const routes = createRoutes(tabbedRouteSpecs)

  return <Switch>{routes}</Switch>
}
