import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Grid, GridItem, Text, Toggle } from '@/components'
import { NavigationPage } from '@/app/components'
import connectUtilityAnimation from '@/app/img/connect-utility-lottie.json'
import { AdaptiveCardRowLayout } from '@/app/layouts'
import Lottie from 'lottie-react'
import { Thermostat, ThermostatSettings } from '@/types'
import { debounce } from '@/utils/limit'
import { useAppDispatch } from '@/hooks'
import { thermostatSettingsCollection } from '@/reducers/thermostatSettings'
import PageLoader from '@/authenticated/components/layout/PageLoader'
import { useParams } from 'react-router-dom'
import { useBrandedThermostatName } from '@/hooks/useBrandedThermostatName'
import {
  NOTIFICATION_PERMISSIONS_TYPE,
  postMessagetoMobileIfPresent,
  SHOW_NOTIFICATION_PERMISSIONS_MODAL_ACTION,
} from '@/utils/messageUtils'
import { isNativeMobile } from '@/authenticated/hooks/useIsMobile'

type ViewModelProps = {
  settings: ThermostatSettings | undefined
  thermostatId: Thermostat['id']
}

function useViewModel(props: ViewModelProps) {
  const dispatch = useAppDispatch()
  const [localNotificationsEnabled, setLocalNotificationsEnabled] = useState(
    props.settings?.notifications_enabled,
  )
  const localNotificationsEnabledRef = useRef(localNotificationsEnabled)

  const debouncedUpdateSettings = useCallback(
    debounce((updatedSettings: Partial<ThermostatSettings>) => {
      dispatch(
        thermostatSettingsCollection.actions.update(
          `/thermostats/${props.thermostatId}/settings/`,
          updatedSettings,
          { id: props.settings?.id },
        ),
      )
    }, 500),
    [props.thermostatId, props.settings?.id],
  )

  const brandedThermostatName = useBrandedThermostatName(props.thermostatId)

  const onNotificationsToggle = (checked: boolean) => {
    // Keep a background state of what the user is trying to do
    // while we interrupt the flow so we know what their intention is
    localNotificationsEnabledRef.current = checked

    // If we are native mobile and the user's intention was to turn on notifications
    // then we need to show the native permissions modal and then update the settings
    // in our handleNotificationPermissions callback
    if (isNativeMobile() && checked) {
      return postMessagetoMobileIfPresent(
        SHOW_NOTIFICATION_PERMISSIONS_MODAL_ACTION,
      )
    }

    setLocalNotificationsEnabled(checked)
    debouncedUpdateSettings({ notifications_enabled: checked })
  }

  const handleNotificationPermissions = (event: MessageEvent) => {
    if (
      event.data?.event?.status === 'enabled' &&
      event.data?.event?.type === NOTIFICATION_PERMISSIONS_TYPE &&
      localNotificationsEnabledRef.current
    ) {
      setLocalNotificationsEnabled(true)
      debouncedUpdateSettings({ notifications_enabled: true })
    } else {
      setLocalNotificationsEnabled(false)
      debouncedUpdateSettings({ notifications_enabled: false })
    }
  }

  useEffect(() => {
    window.addEventListener('message', handleNotificationPermissions)
  }, [])

  return {
    brandedThermostatName,
    onNotificationsToggle,
    localNotificationsEnabled,
  }
}

function NotificationSettingsPageComponent(
  props: ReturnType<typeof useViewModel>,
) {
  return (
    <NavigationPage
      id="thermostat-notification-settings-page"
      title="Notifications"
      subtitle={props.brandedThermostatName ?? 'Thermostat'}
      navigationOptions={['back']}
    >
      <AdaptiveCardRowLayout.PlainRow className="mt-0">
        <GridItem placeItems="center">
          <Lottie
            className="w-1/2"
            animationData={connectUtilityAnimation}
            loop
          />
        </GridItem>
      </AdaptiveCardRowLayout.PlainRow>
      <AdaptiveCardRowLayout.CardRow bottomRule={false}>
        <Grid flow="column">
          <GridItem>
            <Text variant="subheader">Push Notifications</Text>
            <Text variant="body2">
              Allow Optiwatt to send push notifications for demand response (DR)
              events.
            </Text>
          </GridItem>
          <GridItem className="pt-2 pl-16">
            <Toggle
              id="thermostat-notifications-toggle"
              checked={props.localNotificationsEnabled}
              onChange={props.onNotificationsToggle}
            />
          </GridItem>
        </Grid>
      </AdaptiveCardRowLayout.CardRow>
    </NavigationPage>
  )
}

function NotificationSettingsPage(props: ViewModelProps) {
  const vm = useViewModel(props)
  return <NotificationSettingsPageComponent {...vm} />
}

function NotificationsSettingsPageWithFallback(props: {
  thermostatId: Thermostat['id']
}) {
  const { data: settingsArray, status: settingsStatus } =
    thermostatSettingsCollection.useFetch(
      `/thermostats/${props.thermostatId}/settings/`,
    )
  const settings = settingsArray?.[0]
  const settingsLoading = settingsStatus !== 'succeeded' && !settings

  if (settingsLoading) {
    return <PageLoader />
  }

  return (
    <NotificationSettingsPage
      thermostatId={props.thermostatId}
      settings={settings}
    />
  )
}

type PathParams = { deviceId: string | undefined }

export default function NotificationSettingsPageWithParams() {
  const { deviceId } = useParams<PathParams>()
  if (!deviceId) return <PageLoader />
  const thermostatId = parseInt(deviceId)
  if (isNaN(thermostatId)) return <PageLoader />
  return <NotificationsSettingsPageWithFallback thermostatId={thermostatId} />
}
