import { RootState } from '@/reducers'
import { selectNextVehicleDREvent } from './selectNextVehicleDREvent'
import { ID } from '@/types/model'
import { eventIsCurrentlyActive } from '@/types/deviceDemandResponseEvent'

export function selectVehicleIsParticipatingInActiveEvent(
  state: RootState,
  vehicleId: ID,
) {
  const nextEvent = selectNextVehicleDREvent(state, vehicleId)
  if (!nextEvent?.enrolled) {
    return false
  }
  return eventIsCurrentlyActive(nextEvent)
}
