import React from 'react'
import ResetPasswordComponent from '../components/HeroSection/HeroSectionResetPassword'

const ResetPassword = () => {
  return (
    <React.Fragment>
      <ResetPasswordComponent />
    </React.Fragment>
  )
}

export default ResetPassword
