import './styles.scss'
import formatClasses from '../../utils/classes/formatClasses'
import { ReactNodeProps } from '../../types/reactNodeProps'

export interface Props extends ReactNodeProps {
  size?: 'sm' | 'md' | 'lg'
}

const defaultProps: Partial<Props> = {
  size: 'md',
}

export default function Bar(props: Props) {
  props = { ...defaultProps, ...props }

  const classes = formatClasses([
    props.className,
    'bar',
    props.size,
    props.children ? 'has-children' : undefined,
  ])

  return (
    <div className={classes} style={props.style}>
      {props.children}
    </div>
  )
}
