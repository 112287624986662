import { createResourceCollection } from '@/request'
import UtilityProgramProfileEligibility from '@/types/utilityProgramProfileEligibility'

export const utilityProgramProfileEligibilityCollection =
  createResourceCollection<UtilityProgramProfileEligibility>({
    name: 'utilityProgramProfileEligibilityCollection',
    apiConfig: { path: 'programs/eligibility' },
    selector: (state) => state.utilityProgramProfileEligibility,
  })
const reducer = utilityProgramProfileEligibilityCollection.slice.reducer

export default reducer
