import { NumberEasing, Sheet, Text } from '../../../components'
import { toDollars } from '../../../utils/string'
import { ReactNodeProps } from '../../../types/reactNodeProps'

interface Props extends ReactNodeProps {
  cost?: number
  size: 'sm' | 'md'
  invertColor?: boolean
}

export default function CostSheet(props: Props) {
  const dollarValue = toDollars(props.cost, {
    format: 'cents',
    signed: true,
    numeric: true,
  }) as number
  const sign = dollarValue > 0 ? '+' : dollarValue < 0 ? '-' : ''
  const { positiveColor, negativeColor } = props.invertColor
    ? { positiveColor: 'green', negativeColor: 'red' }
    : { positiveColor: 'red', negativeColor: 'green' }
  const color = dollarValue > 0 ? positiveColor : negativeColor
  const dollarValueIsWholeNumber = dollarValue % 1 === 0

  return (
    <Sheet
      className={props.className}
      color={`${color}-100`}
      rounded
      size={props.size}
    >
      <Text
        className={
          color === 'green' ? 'text-themed-green-900' : 'text-themed-red-500'
        }
        variant={props.size === 'sm' ? 'body1' : 'h2'}
      >
        {`${sign}$`}
        <NumberEasing
          decimals={dollarValue === 0 || dollarValueIsWholeNumber ? 0 : 2}
          value={Number(
            toDollars(props.cost, {
              format: 'cents',
              signed: false,
              numeric: true,
            }),
          )}
          speed={400}
          ease="quintOut"
        />
      </Text>
    </Sheet>
  )
}
