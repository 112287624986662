import { NavigationPage } from '@/app/components'
import Text from '@/components/Text/Text'
import { useAppSelector } from '@/hooks'
import theme from '@/v2/theme'
import { ThemeProvider } from '@material-ui/styles'
import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'
import DepartureAndPlanCard from '@/app/pages/devices/vehicle/DepartureAndPlanCard'
import VehicleProgramCard from '@/app/pages/devices/vehicle/VehicleProgramCard'
import Button from '@/components/Button/Button'
import scrollToTop from '@/hooks/useScrollToTop'
import { useNavigation } from '@/app/hooks'
import { useDeviceSelectionRedirectUrl } from '@/hooks/useDeviceSelectionRedirectUrl'
import { Vehicle } from '@/types'
import useVehicleChargeSchedulesFetcher from '@/authenticated/hooks/useVehicleChargeSchedulesFetcher'
import { getVehicleManufacturedName } from '@/utils/CarUtils'
import { Sheet } from '@/components'
import TeslaLogo from '@/authenticated/images/tesla.webp'
import { Check } from 'react-feather'

type VehicleConfigureState = {
  idx: number
  // array of vehicle ids that have been reviewed
  reviewed: number[]
}

const getVehicleSettingsVariants = (
  direction: 'next' | 'previous',
  amount = 100,
) => ({
  initial: { opacity: 0, x: direction === 'next' ? amount : -amount },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: direction === 'next' ? -amount : amount },
})

export default function ConfigureVehicles() {
  useVehicleChargeSchedulesFetcher()
  const navigation = useNavigation()
  const user = useAppSelector((state) => state.user.user)
  const isFromPartner = useAppSelector((state) => state.auth.fromToken)
  const vehicles = useAppSelector(
    (state) => state.vehicleAuth.mfaData ?? [],
  ) as Vehicle[] | undefined
  const [activeVehicle, setActiveVehicleState] =
    useState<VehicleConfigureState>({
      idx: 0,
      reviewed: [],
    })
  const [navigationDirection, setNavigationDirection] = useState<
    'next' | 'previous'
  >('next')
  const vehicle = vehicles?.[activeVehicle.idx]
  const vehicleImage = vehicle?.car?.image_url
    ? '/dashboard/img/car_models' + vehicle?.car?.image_url
    : '/dashboard/img/car_models/covered-car.webp'
  const progressPercentage =
    (vehicles?.length ?? 0) === 0
      ? 0
      : Math.round(((activeVehicle.idx + 1) / (vehicles?.length ?? 0)) * 100)
  const url = useDeviceSelectionRedirectUrl()

  if (!vehicle) {
    return null
  }

  const handleNextVehicle = async () => {
    const nextIdx = activeVehicle.idx + 1
    const endOfVehicles = nextIdx === vehicles.length

    if (endOfVehicles) {
      return navigation.push(url)
    }

    await scrollToTop()
    setNavigationDirection('next') // Set direction to next

    if (nextIdx < vehicles.length) {
      setActiveVehicleState((prevState) => ({
        ...prevState,
        idx: nextIdx,
        reviewed: [...prevState.reviewed, vehicle.id],
      }))
    }
  }

  const handlePreviousVehicle = async () => {
    await scrollToTop()
    setNavigationDirection('previous') // Set direction to previous

    const previousIdx = activeVehicle.idx - 1
    if (previousIdx >= 0) {
      setActiveVehicleState((prevState) => ({
        ...prevState,
        idx: previousIdx,
      }))
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <NavigationPage
        id="connect-vehicle-configuration"
        className="!bg-base-base-100"
        navigationOptions={['nothing']}
      >
        <NavigationPage.SingleCardContent className="bg-base-base-100 filter-none">
          <div className={`rounded-xl `}>
            <Text variant="h2" className="pt-2">
              Welcome, {user?.first_name}
            </Text>
            <Text variant="body4" className="pt-2 pb-6">
              Let's set up your charging preferences for your vehicles
            </Text>
            <div className="flex flex-col p-5 bg-white rounded-[20px] border-themed-base-300">
              <div className="flex justify-between w-full">
                <div className="flex flex-col">
                  <Text variant="subheader">
                    {vehicle?.car?.display_name !== ''
                      ? vehicle?.car?.display_name
                      : vehicle?.car?.car_model?.friendly_name}
                  </Text>
                  <Text variant="body4">
                    {getVehicleManufacturedName(vehicle)}
                  </Text>
                  <Sheet color="green-100" className="my-2">
                    <div className="flex items-center gap-1">
                      <Check color="#00922B" size={16} />
                      <Text
                        variant="body1"
                        className="text-green-500 !text-[12px]"
                      >
                        VERIFIED
                      </Text>
                    </div>
                  </Sheet>
                </div>
                <div>
                  <AnimatePresence mode="wait">
                    <motion.img
                      transition={{
                        duration: 0.15,
                        ease: 'easeInOut',
                      }}
                      variants={getVehicleSettingsVariants(
                        navigationDirection,
                        10,
                      )}
                      initial="initial"
                      animate="animate"
                      exit="exit"
                      src={vehicleImage}
                      alt={vehicle?.car?.display_name}
                      key={vehicle.id}
                      className="h-14"
                    />
                  </AnimatePresence>
                </div>
              </div>
              {vehicles?.length > 0 && (
                <div className="flex items-center pt-1">
                  <Text
                    variant="body4"
                    className="flex-grow-0 pr-4 text-themed-accent"
                  >
                    {activeVehicle.idx + 1} of {vehicles?.length} reviewed
                  </Text>
                  <div className="flex-grow h-2 rounded bg-slate-300">
                    <div
                      style={{
                        width: `${progressPercentage}%`,
                      }}
                      className="h-2 transition-all duration-300 rounded bg-themed-blue-500"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <AnimatePresence mode="wait">
            <motion.div
              transition={{
                duration: 0.15,
                ease: 'easeInOut',
              }}
              initial="initial"
              animate="animate"
              exit="exit"
              variants={getVehicleSettingsVariants(navigationDirection)}
              key={vehicle.id}
              className="grid gap-6 mt-6"
            >
              <DepartureAndPlanCard
                hidePlans={isFromPartner}
                vehicle={vehicle}
                animateOnMount={false}
              />
              {!isFromPartner && (
                <VehicleProgramCard
                  showUtilityUpsell={false}
                  vehicle={vehicle}
                  buttonVariant="primary-subtle"
                />
              )}
            </motion.div>
          </AnimatePresence>
          <div className="flex mt-8">
            {vehicles.length > 1 && vehicle.id !== vehicles?.[0].id && (
              <Button
                variant="secondary"
                className="flex-1 flex-grow-0 mr-4"
                id="connect-vehicle-configure-back"
                onClick={handlePreviousVehicle}
              >
                Back
              </Button>
            )}
            <Button
              className="flex-grow"
              id="connect-vehicle-configure-next-vehicle"
              onClick={handleNextVehicle}
            >
              {activeVehicle?.idx === (vehicles?.length ?? 0) - 1
                ? 'Continue'
                : 'Next Vehicle'}
            </Button>
          </div>
        </NavigationPage.SingleCardContent>
      </NavigationPage>
    </ThemeProvider>
  )
}
