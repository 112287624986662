import { LoadingAnimation } from '@/components'
import waiting from '@/app/img/waiting.svg'
import check from '@/app/img/check.svg'
import frown from '@/app/img/frown.svg'

export type Status = 'loading' | 'succeeded' | 'failed'

const StatusImgSrc = {
  loading: waiting,
  succeeded: check,
  failed: frown,
}

export default function (props: { status: Status }) {
  if (props.status === 'loading') {
    return <LoadingAnimation type="falling" />
  } else {
    return <img alt={props.status} src={StatusImgSrc[props.status]} />
  }
}
