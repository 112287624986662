import {
  ChargeForecast,
  ChargeForecastPeriod,
  ChargeForecastPeriodMetric,
  ChargeSchedule,
} from '@/types'
import {
  allMetricsForPeriod,
  getChargeEndPeriod,
} from '@/types/chargeForecast/utils'
import dayjs from 'dayjs'
import { ChargeReasonType } from './types'
import {
  convertKilometersToMiles,
  convertMilesToKilometers,
} from '@/utils/conversionUtilities'

/**
 * This function will find the first period by looking at the metric (standard, smart, and cheapest)
 * where should_charge is true.
 * @param chargeForecast
 */
export function getStartChargeDatetime(
  chargeForecast: ChargeForecast | undefined,
) {
  if (!chargeForecast) {
    return null
  }

  const { charge_forecast_periods, charging_start_time } = chargeForecast

  const metricShouldCharge = (metric?: ChargeForecastPeriodMetric) =>
    metric?.should_charge === true

  const periodHasShouldChargeMetric = (period: ChargeForecastPeriod) =>
    allMetricsForPeriod(period, 'start').some(metricShouldCharge)

  const firstPeriodWithShouldCharge = charge_forecast_periods?.find(
    periodHasShouldChargeMetric,
  )

  // If we are actively charging, this will not be null and will
  // let us know when we actually started charging.
  if (charging_start_time) {
    return dayjs(charging_start_time)
  }

  // Otherwise we see if we have a scheduled period with a charge
  // and return the first one
  if (firstPeriodWithShouldCharge) {
    return dayjs(firstPeriodWithShouldCharge.start_datetime)
  }

  return null
}

/**
 * This function returns the time that your scheduled charge should end.
 * It uses the time that your battery target is reached or the scheduled
 * Ready By time if it is set.
 * @param chargeForecast
 */
export function getEndChargeDatetime(
  chargeForecast: ChargeForecast | undefined,
) {
  if (!chargeForecast) {
    return null
  }
  const chargeEndPeriod = getChargeEndPeriod(chargeForecast)

  if (!chargeEndPeriod) {
    return null
  }

  return dayjs(chargeEndPeriod.end_datetime)
}

/**
 * Returns remaining time in 'h mm' format.
 * @param chargeForecast
 * @returns
 */
export function getChargeRemainingTime(
  chargeForecast: ChargeForecast | undefined,
) {
  if (!chargeForecast) {
    return null
  }

  const endTime = getEndChargeDatetime(chargeForecast)

  if (!endTime) {
    return null
  }

  const now = dayjs()
  const remainingTime = endTime.diff(now, 'minute')
  const hours = Math.floor(remainingTime / 60)
  const minutes = remainingTime % 60

  return `${hours}h ${minutes}m`
}

/**
 * @param chargeForecast
 * @returns
 */
export function getIsPluggedIn(chargeForecast: ChargeForecast | undefined) {
  if (!chargeForecast) {
    return false
  }

  return chargeForecast.plugged_in
}

/**
 * @param chargeForecast
 * @returns
 */
export function getIsHome(chargeForecast: ChargeForecast | undefined) {
  if (!chargeForecast) {
    return false
  }

  return chargeForecast.is_home
}

/**
 * If we are plugged in and the first period has should_charge as true,
 * we are charging.
 * @param chargeForecast
 */
export function getIsCharging(chargeForecast: ChargeForecast | undefined) {
  if (!chargeForecast) {
    return false
  }

  return chargeForecast.is_charging
}

/**
 * This function will return the next enrolled demand response event
 * @param chargeForecast
 */
export function getUpcomingDREvent(chargeForecast: ChargeForecast | undefined) {
  if (!chargeForecast) {
    return null
  }
  return chargeForecast.next_enrolled_dr_event
}

/**
 * This function will return the timeline, start and end datetimes,
 * for the next enrolled demand response event
 * @param chargeForecast
 */
export function getUpcomingDREventTimeline(
  chargeForecast: ChargeForecast | undefined,
) {
  if (!chargeForecast) {
    return null
  }
  const nextDREvent = getUpcomingDREvent(chargeForecast)

  if (!nextDREvent) {
    return null
  }

  return {
    start: dayjs(nextDREvent.start_datetime),
    end: dayjs(nextDREvent.end_datetime),
  }
}

/**
 * This function will return the charge reason types based on the charge schedule and forecast.
 * @param chargeSchedule
 * @param chargeForecast
 */
export function getChargeReasonTypes(
  chargeSchedule: ChargeSchedule,
  chargeForecast: ChargeForecast | undefined,
): ChargeReasonType[] {
  const chargeReasonTypes: ChargeReasonType[] = []
  if (chargeSchedule?.save_the_planet_plan_enabled) {
    chargeReasonTypes.push('PlanetSaving')
  }

  if (chargeSchedule?.money_saving_plan_enabled) {
    chargeReasonTypes.push('MoneySaving')
  }

  if (getUpcomingDREvent(chargeForecast)) {
    chargeReasonTypes.push('ProgramEnrollment')
  }

  return chargeReasonTypes
}

/**
 * return the distance in miles or kilometers based on the charge limit.
 * @param args
 */
export function getChargeLimitWithUnit(args: {
  chargeLimit: number | null
  chargeLimitUnit: 'percent' | 'miles' | 'kilometers'
  milesPerBatteryPercent: number | null
  outputUnit: 'miles' | 'kilometers' | 'percent'
}) {
  const { chargeLimit, chargeLimitUnit, milesPerBatteryPercent, outputUnit } =
    args

  if (chargeLimit === null) {
    return null
  }

  // If the charge limit unit is the same as the output unit, we don't need to convert.
  if (chargeLimitUnit === outputUnit) {
    return chargeLimit
  }

  // Convert the charge limit to miles.
  let chargeLimitMiles = 0
  if (chargeLimitUnit === 'miles') {
    chargeLimitMiles = chargeLimit
  } else if (chargeLimitUnit === 'percent') {
    // If we don't have miles per battery percent, we can't convert.
    if (!milesPerBatteryPercent) {
      return null
    }
    chargeLimitMiles = Math.round(chargeLimit * milesPerBatteryPercent)
  } else if (chargeLimitUnit === 'kilometers') {
    chargeLimitMiles = convertKilometersToMiles(chargeLimit)
  }

  // Convert the miles to the output unit.
  if (outputUnit === 'miles') {
    return chargeLimitMiles
  } else if (outputUnit === 'kilometers') {
    return convertMilesToKilometers(chargeLimitMiles)
  } else {
    if (!milesPerBatteryPercent) {
      return null
    }
    return chargeLimitMiles / milesPerBatteryPercent
  }
}
