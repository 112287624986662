import { Button, Modal, Text } from '@/components'

type Props = {
  open: boolean
  handleClose: () => void
}

export default function ChargingDurationDialog(props: Props) {
  return (
    <Modal
      id="charging-duration-dialog"
      open={props.open}
      onClose={props.handleClose}
      title="What does this time represent?"
    >
      <Text variant="body4">This is the total duration for this charge.</Text>
      <Modal.Actions>
        <Button id="close-charging-duration-dialog" onClick={props.handleClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
