import { makeStyles } from '@material-ui/styles'
import { DialogTitle, IconButton } from '@material-ui/core'
import { X } from 'react-feather'

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.header.color,
  },
  title: {
    '& h2': {
      paddingRight: theme.spacing(6),
    },
  },
}))

const DialogTitleCloseable = ({ children, onClose, ...restProps }) => {
  const classes = useStyles()

  return (
    <DialogTitle className={classes.title} id="alert-dialog-title">
      {children}
      <IconButton
        className={classes.closeButton}
        aria-label="close"
        onClick={onClose}
      >
        <X />
      </IconButton>
    </DialogTitle>
  )
}

export default DialogTitleCloseable
