import { useFocus } from '@/app/hooks/useFocus'
import Button from '@/components/Button/Button'
import Icon from '@/components/Icon/Icon'
import TextField from '@/components/TextField/TextField'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'

type AA = 'AM' | 'PM'
export default function DesktopTimePicker({
  defaultValue,
  onChange,
}: {
  defaultValue?: Dayjs
  onChange: (date: Dayjs) => Dayjs
}) {
  const [aa, setAA] = useState(
    (defaultValue?.format('A') as AA) ?? ('AM' as AA),
  )
  const [time, setTime] = useState(defaultValue?.format('h:mm') ?? '')
  const [suggestions, setSuggestions] = useState(defaultSuggestions())
  const [is24h, setIs24h] = useState(false)
  // regex for time with optional minutes i.e. 8:00, 8
  const validTime = /^([0-1]?[0-9]|2[0-3]):?([0-5][0-9]|[0-9])?$/
  const [inputRef, setInputFocus] = useFocus()
  const updateSuggestions = (hour: number, aa?: AA) => {
    const startDate = hourToDayjs(hour, aa).minute(0)
    const suggestions = [...Array(4).keys()].map((_, i) =>
      startDate.add(30 * i, 'minute'),
    )
    setSuggestions(suggestions)
  }
  useEffect(() => {
    setIs24h(false)
    if (time.length === 0 || !validTime.test(time)) {
      setSuggestions(defaultSuggestions())
      return
    }
    const times = time.split(':')
    const hour = parseInt(times[0])
    if (hour > 12) {
      setIs24h(true)
      setAA('PM')
    }
    updateSuggestions(hour, aa)
  }, [time, aa])
  useEffect(() => {
    // focus input on mount
    setInputFocus()
  }, [])
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row gap-4 items-end px-2">
        <TextField
          value={time}
          label="Time"
          placeholder="8:00"
          error={!validTime.test(time) && time.length > 0}
          className="w-20"
          autoFocus
          inputRef={inputRef}
          onChange={(e) => setTime(e.currentTarget.value)}
        />
        <div>
          <Button
            id="desktop-time-picker-am-button"
            className="rounded-r-none p-4 px-6 w-4 h-6"
            variant={aa === 'AM' ? 'accent' : 'accent-subtle'}
            disabled={is24h}
            onClick={() => setAA('AM')}
          >
            AM
          </Button>
          <Button
            id="desktop-time-picker-pm-button"
            className="rounded-l-none p-4 px-6 w-4 h-6"
            variant={aa === 'PM' ? 'accent' : 'accent-subtle'}
            onClick={() => setAA('PM')}
          >
            PM
          </Button>
        </div>
      </div>
      <div className="flex flex-col">
        {suggestions.map((s, i) => (
          <div
            key={i}
            className="flex flex-row justify-between items-center hover:bg-gray-100 rounded-lg p-2 hover:cursor-pointer group"
            onClick={() => onChange(handleSubmit(s.format('h:mm'), aa))}
          >
            <div>{s.format('h:mm A')}</div>
            <div className="hidden group-hover:block">
              <Icon name="Check" size={14} className="translate-y-[2px]" />
            </div>
          </div>
        ))}
      </div>
      <Button
        id="desktop-datepicker-ok-button"
        disabled={!validTime.test(time)}
        className="mt-2 p-6 w-full h-6"
        onClick={() => onChange(handleSubmit(time, aa))}
      >
        Apply
      </Button>
    </div>
  )
}

function hourToDayjs(hour: number, aa?: AA) {
  return dayjs()
    .hour(hour !== 12 ? hour + (aa === 'PM' ? 12 : 0) : aa === 'PM' ? 12 : 0)
    .add(hour > 12 ? 12 : 0, 'hour')
}

function handleSubmit(time: string, aa: AA) {
  const times = time.split(':')
  const hour = parseInt(times[0])
  const minute = time.length > 1 ? parseInt(times?.[1] ?? '0') : 0
  return hourToDayjs(hour, aa).minute(minute)
}

function defaultSuggestions() {
  return [
    dayjs().hour(8).minute(0),
    dayjs().hour(9).minute(0),
    dayjs().hour(17).minute(0),
    dayjs().hour(18).minute(0),
  ]
}
