import { useState } from 'react'
import { Box as MuiBox, Container, Grid } from '@material-ui/core'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import frown from '@/app/img/frown.svg'
import { Button } from '@/components'
import clsx from 'clsx'
import FeedbackDialogContactForm from '@/authenticated/components/feedback/FeedbackDialogContactForm'

const Box = styled(MuiBox)(spacing)

const useStyles = makeStyles((theme) =>
  createStyles({
    fullScreen: {
      maxHeight: '100vh',
      position: 'relative',
      width: '100%',
      zIndex: 1,
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    mainSection: {
      padding: '0px 0px',
    },
    container: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    textContainer: {
      textAlign: 'center',
      textColor: 'white',
    },
    helpButton: {
      width: '100%',
      maxWidth: '300px',
      height: '50px',
      borderRadius: '20px',
    },
  }),
)

export default function Oops({ functionalityDescription, eventId }) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [issueReported, setIssueReported] = useState(false)

  const onIssueReported = () => {
    setIssueReported(true)
    setOpen(false)
  }

  return (
    <Box>
      <section className={clsx(classes.mainSection, classes.fullScreen)}>
        <Container className={classes.container} maxWidth="lg">
          <Grid container justifyContent="center" alignItems="flex-start">
            <Grid item md={12}>
              <Box className={classes.textContainer}>
                <img src={frown} alt="Red frown face" />
                <Box>
                  <h1
                    style={{
                      fontSize: '2.5rem',
                    }}
                  >
                    Oops...
                  </h1>
                </Box>
                <h3
                  style={{
                    fontSize: '1rem',
                    padding: '0px 32px',
                  }}
                >
                  Something went wrong
                  {functionalityDescription &&
                    ` with the ${functionalityDescription}`}
                  . We're on it.
                </h3>
                <Button
                  className={classes.helpButton}
                  variant="primary"
                  onClick={() => setOpen(true)}
                  disabled={issueReported}
                >
                  {issueReported ? 'Thanks!' : 'Report Issue'}
                </Button>
              </Box>
            </Grid>
          </Grid>
          <FeedbackDialogContactForm
            id="oops-feedback"
            title="Please explain what you were trying to do and happened when the issue occurred."
            isOpen={open}
            onClose={onIssueReported}
            supportTitle={`Feedback: Crash on ${functionalityDescription}`}
            feedbackExtra={`This feedback was initiated from a crash. Sentry Event ID: ${eventId}`}
          />
        </Container>
      </section>
    </Box>
  )
}
