import { ID } from '@/request/types'
import { createSlice } from '@reduxjs/toolkit'

type LoggingState = {
  userId: ID | null
}

const initialState: LoggingState = {
  userId: null,
}

const loggingSlice = createSlice({
  name: 'Logging',
  initialState,
  reducers: {
    setUserId: (state, action) => {
      state.userId = action.payload
    },
  },
})

export const { setUserId: setLoggingUserId } = loggingSlice.actions

export default loggingSlice.reducer
