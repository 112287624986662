import { Route, Redirect } from 'react-router'
import { RouteProps, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { refreshTokenIsValid, RootState } from '../../reducers'

type AuthenticatedRouteProps = RouteProps & {
  component: React.ComponentType<any>
  isAuthenticated?: boolean
  exact: boolean
}

const AuthenticatedRoute = ({
  component: Component,
  isAuthenticated,
  exact,
  ...rest
}: AuthenticatedRouteProps) => {
  const path = useLocation()
  const shouldShow = isAuthenticated || path.pathname === '/autogrid/login'

  return (
    <Route
      exact={exact}
      {...rest}
      render={(props) =>
        shouldShow ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: refreshTokenIsValid(state),
})

export default connect(mapStateToProps, null)(AuthenticatedRoute)
