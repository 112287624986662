import { useEffect, useState } from 'react'
import { useAppSelector, useUrlSearchParam } from '../../../../hooks'
import useMockableViewModel from '../../../../hooks/useMockableViewModel'
import {
  capitalizeFirstLetter,
  camelToKebabCase,
} from '../../../../utils/stringUtilities'
import { selectDevicesHaveLoaded } from '@/selectors'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import {
  DeviceType,
  DeviceSelection,
} from '@/app/components/DeviceSelectionGallery/DeviceSelectionGallery.types'
import { useNavigation } from '@/app/hooks'
import { thermostatsCollection } from '@/reducers/thermostats'
import { vehicleChargersCollection } from '@/reducers/vehicleChargers'

type PathParams = {
  deviceType: DeviceType | undefined
  deviceId: string | undefined
}

function useViewModel() {
  const history = useHistory()
  const navigation = useNavigation()
  const [showAlert, setShowAlert] = useState(false)
  const surveryComplete = useUrlSearchParam('survey_complete')
  const { path } = useRouteMatch()
  const { deviceType, deviceId } = useParams<PathParams>()
  thermostatsCollection.useFetch()
  vehicleChargersCollection.useFetch()
  const selectedDeviceState = useState<DeviceSelection | null>(null)
  const firstName = useAppSelector((state) =>
    capitalizeFirstLetter(state.user.user?.first_name ?? ''),
  )
  const devicesLoaded = useAppSelector(selectDevicesHaveLoaded)
  const userLoaded = useAppSelector((state) => Boolean(state.user.user))
  // Convert from kebab case to camel case
  const transformedDeviceType = deviceType?.replace(/-([a-z])/g, (g) =>
    g[1].toUpperCase(),
  ) as DeviceType | undefined

  const selectedDevice: DeviceSelection | null =
    transformedDeviceType && deviceId
      ? {
          id: parseInt(deviceId),
          type: transformedDeviceType,
        }
      : null

  const setSelectedDevice = (device: DeviceSelection) => {
    history.push({
      pathname: `/app/${camelToKebabCase(device.type)}/${device.id}`,
      search: history.location.search,
    })
  }

  // set alert message to be visible after half a second then
  // go away after 5 seconds
  useEffect(() => {
    if (surveryComplete) {
      setTimeout(() => {
        setShowAlert(true)
        setTimeout(() => {
          navigation.pushRemoveParam('survey_complete')
          setShowAlert(false)
        }, 5000)
      }, 500)
    }
  }, [surveryComplete])

  return {
    loading: !devicesLoaded || !userLoaded,
    path,
    selectedDevice,
    setSelectedDevice,
    selectedDeviceState,
    firstName,
    showAlert,
  }
}

function useMockViewModel() {
  const selectedDeviceState = useState<DeviceSelection | null>(null)
  return {
    loading: false,
    path: '',
    selectedDevice: null,
    setSelectedDevice: (device: DeviceSelection) => {},
    selectedDeviceState,
    firstName: 'John',
    showAlert: false,
  }
}

export default useMockableViewModel({ useViewModel, useMockViewModel })
