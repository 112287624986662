import { searchableUtilityProgramsCollection } from '@/reducers/utilityPrograms'
import { UtilityProgram } from '@/types/utilityProgram'
import OptiwattLogo from '@/app/pages/autogrid/optiwatt-logo.webp'

export function useProgramSpecificConfig(
  externalUtilityProgramId: string | null,
  utilityProgramOverride?: UtilityProgram,
) {
  const { data: utilityPrograms, status } =
    searchableUtilityProgramsCollection.useFetch('search_config', {
      params: { external_id: externalUtilityProgramId },
      require: Boolean(externalUtilityProgramId),
    })

  const defaultConfig = {
    programName: 'Your utility',
    programLogoSrc: OptiwattLogo,
    partnerEnrollmentDescription: 'Enroll and receive rewards.',
  }

  // if no program id param is present, return the default config
  if (!externalUtilityProgramId && !utilityProgramOverride) {
    return {
      config: defaultConfig,
      isLoading: false,
    }
  }

  const utilityProgram =
    utilityProgramOverride ??
    ((utilityPrograms?.[0] ?? null) as UtilityProgram | null)

  // todo add cms field for this
  const name =
    utilityProgram?.view_config_json.externalPartnerIntegration?.programName
  const partnerEnrollmentDescription =
    utilityProgram?.view_config_json.externalPartnerIntegration
      ?.deviceEnrollmentDescription

  const programLogoSrc = utilityProgram?.view_config_json.logoUrl

  return {
    config: {
      programName: name ?? defaultConfig.programName,
      programLogoSrc: programLogoSrc ?? defaultConfig.programLogoSrc,
      partnerEnrollmentDescription:
        partnerEnrollmentDescription ??
        defaultConfig.partnerEnrollmentDescription,
    },
    isLoading:
      !utilityProgramOverride && (status === 'loading' || status === 'idle'),
  }
}
