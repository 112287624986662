import { createResourceCollection } from '@/request'
import { ResourceModel } from '@/request/types'
import { ID } from '@/types/model'

export type DeletedThermostat = {
  thermostat: ID
  profile: ID
  reason: string
} & ResourceModel

export const deletedThermostatCollection =
  createResourceCollection<DeletedThermostat>({
    name: 'deletedThermostat',
    apiConfig: {
      path: 'deleted_thermostats',
    },
    selector: (state) => state.deletedThermostat,
  })

export default deletedThermostatCollection.slice.reducer
