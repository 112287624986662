import AddUtilityPlanOptionFlowStep from '../AddUtilityPlanOptionFlowStep'
import SelectUtilityPlanFlowStep from '../SelectUtilityPlanFlowStep'
import SelectManualUtilityPlanOptionsFlowStep from '../SelectManualUtilityPlanOptionsFlowStep'
import DefineManualPlanFlowStep from '../DefineManualPlanFlowStep'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { useNavigation } from '@/app/hooks'

export interface Props {
  onComplete?: () => void
}

function SelectAddUtilityOptionStep(props: Props) {
  const navigation = useNavigation()

  const onSelectFromList = () => {
    navigation.push('plan/plan-from-list')
  }

  const onSelectManually = () => {
    navigation.push('plan/manual-select')
  }

  return (
    <AddUtilityPlanOptionFlowStep
      onComplete={props.onComplete}
      onSelectFromList={onSelectFromList}
      onSelectManually={onSelectManually}
    />
  )
}

function SelectPlanFromListStep(props: Props) {
  return <SelectUtilityPlanFlowStep onComplete={props.onComplete} />
}

function SelectManualOptionStep() {
  const navigation = useNavigation()
  const onOptionSelect = () => {
    navigation.push('manual-plan')
  }

  return (
    <SelectManualUtilityPlanOptionsFlowStep onOptionSelect={onOptionSelect} />
  )
}

function DefineManualPlanStep(props: Props) {
  return <DefineManualPlanFlowStep onComplete={props.onComplete} />
}

export default function AddUtilityPlanFlow(props: Props) {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route
        exact
        path={`${path}/plan-from-list`}
        component={() => <SelectPlanFromListStep {...props} />}
      />
      <Route
        exact
        path={`${path}/manual-select`}
        component={() => <SelectManualOptionStep />}
      />
      <Route
        exact
        path={`${path}/manual-plan`}
        component={() => <DefineManualPlanStep {...props} />}
      />
      <Route
        exact
        path={path}
        component={() => <SelectAddUtilityOptionStep {...props} />}
      />
    </Switch>
  )
}
