import { Link, NavigationPage } from '@/app/components'
import FullScreenSingleCard from '@/authenticated/components/FullScreenSingleCard'
import Text from '@/components/Text/Text'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import SurveyObserver, {
  useSurveyResponder,
} from '@/app/components/SurveyObserver'
import { makes } from '@/types/vehicleBrands'
import { useNavigation } from '@/app/hooks'
import { CheckCircleIcon, CircleIcon } from 'lucide-react'
import { surveyResponsesCollection } from '@/reducers/surveyResponses'
import { useAppDispatch } from '@/hooks'

const surveyName = 'BrandApology'
export default function EvApology() {
  const params = useParams<{ make: string }>()
  const navigation = useNavigation()
  const evMakes = [...Object.keys(makes)]
  const validMake = evMakes.includes(params.make)
  if (params?.make && !validMake) {
    navigation.push('/connect-device/vehicle')
  }
  const dispatch = useAppDispatch()
  const { addSurveyInteraction, activeSurveyResponse } =
    useSurveyResponder(surveyName)
  const lastResponse = activeSurveyResponse?.survey_interactions
    .filter((interaction) => interaction.action_data.make === params.make)
    .sort((a, b) =>
      new Date(a.created as string) < new Date(b.created as string) ? 1 : -1,
    )[0]
  const lastNotifyAnswer = lastResponse?.action_data
    ? lastResponse?.action_data.notify === true
      ? 'yes'
      : 'no'
    : ''
  const [notificationPreference, setNotificationPreference] = useState<
    'yes' | 'no' | ''
  >(lastNotifyAnswer)
  useEffect(() => {
    setNotificationPreference(lastNotifyAnswer)
  }, [lastNotifyAnswer])
  const handleRadioChange = (value: 'yes' | 'no') => {
    addSurveyInteraction({
      action: 'click',
      action_data: {
        notify: value === 'yes',
        make: params.make,
      },
    })

    dispatch(surveyResponsesCollection.actions.fetch())
    setNotificationPreference(value)
  }

  const radioCheck = (label: 'Yes' | 'No', selected: boolean) => {
    return (
      <button
        className={`flex items-center h-[40.5px] gap-2 cursor-pointer bg-transparent border-none outline-none`}
        onClick={() => handleRadioChange(label.toLowerCase() as 'yes' | 'no')}
      >
        {selected ? (
          <CheckCircleIcon
            className={`text-themed-success flex-none`}
            size={22}
          />
        ) : (
          <CircleIcon className={`text-themed-gray-500 flex-none`} size={22} />
        )}
        <span>{label}</span>
      </button>
    )
  }

  return (
    <SurveyObserver observedSurveys={[surveyName]}>
      <FullScreenSingleCard className="min-h-[380px] p-4 sm:h-full">
        <NavigationPage
          id="ev-apology-page"
          title={params.make}
          subtitle={`Integrations with ${params.make} vehicles are currently in development.`}
          navigationOptions={['back']}
        >
          <NavigationPage.SingleCardContent>
            <div className="flex flex-col gap-4">
              <Text variant="body1">
                Would you like to be notified when {params.make} support becomes
                available?
              </Text>
              <div>
                {radioCheck('Yes', notificationPreference === 'yes')}
                {radioCheck('No', notificationPreference === 'no')}
              </div>
              <div className="self-center">
                <Link
                  to={'/connect-device/charger/select'}
                  data-testid="connect-charger-link"
                >
                  Connect my charger instead
                </Link>
              </div>
            </div>
          </NavigationPage.SingleCardContent>
        </NavigationPage>
      </FullScreenSingleCard>
    </SurveyObserver>
  )
}
