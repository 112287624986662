import CardRoot, { Props, Ref } from './Card'
import { forwardRef } from 'react'

export type { Props }

function Card(props: Props, ref?: Ref) {
  return <CardRoot {...props} ref={ref} />
}

export default forwardRef(Card)
