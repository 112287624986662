import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import {
  Typography as MuiTypography,
  Grid as MuiGrid,
  Box as MuiBox,
  CardMedia,
  styled,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Button, CardAction, CardActions, Icon } from '@/components'
import { ChevronDown } from 'react-feather'
import { spacing } from '@material-ui/system'
import { Props as BasicInformationProps } from './ListableInformationCard'
import ListableInformationCard, {
  Classes as ListableInformationCardClasses,
} from './ListableInformationCard'
import { Identifiable } from '@/types'

const Typography = styled(MuiTypography)(spacing)
const Grid = styled(MuiGrid)(spacing)
const Box = styled(MuiBox)(spacing)

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      position: 'relative',
    },
    shadowed: {
      boxShadow: theme.shadows[6],
    },
    sticky: {
      bottom: theme.spacing(5),
      position: 'sticky',
    },
    scrollTextArea: {
      marginBottom: theme.spacing(10),
      zIndex: 2,
    },
    scrollText: {
      marginBottom: theme.spacing(1),
    },
    scrollChevron: {
      marginTop: -theme.spacing(1),
    },
    image: {
      height: 'auto',
      width: '60%',
      // The following styles make the media go edge to edge in a mobile full screen environment
      [theme.breakpoints.down('xs')]: {
        width: 'unset',
        '& > div': {
          [theme.breakpoints.down('xs')]: {
            width: '90vw',
          },
        },
      },
      zIndex: 1,
    },
    flex: {
      flex: 1,
    },
  }),
)

interface Classes extends ListableInformationCardClasses {
  header?: string
  iconContainer?: string
}

export interface Props extends BasicInformationProps, Identifiable {
  media?: string | JSX.Element
  button?: React.ReactNode
  buttonText?: string
  onboarding?: boolean
  backButtonClassNames?: string
  headerlessOnDesktop?: boolean
  onClick?: () => void
  informationCards: BasicInformationProps[]
  style?: React.CSSProperties
  imageStyle?: React.CSSProperties
  className?: string
  imageClassName?: string
  underTitleComponent?: React.ReactNode
  footer?: React.ReactNode
  classes?: Classes
  sticky?: boolean
  padContent?: boolean
  onSkipClick?: () => void
  onBackClick?: () => void
}

const ConnectDeviceInformationCard = ({
  media,
  tag,
  button,
  buttonText,
  onboarding = false,
  backButtonClassNames,
  headerlessOnDesktop = onboarding,
  title,
  subtitle,
  onClick,
  informationCards,
  imageStyle,
  className,
  classes: providedClasses,
  imageClassName,
  underTitleComponent,
  footer,
  sticky = true,
  padContent = false,
  onSkipClick,
  onBackClick,
  id,
  ...rest
}: Props) => {
  const classes = useStyles()
  const theme = useTheme()

  const buttonMessage = buttonText ?? (onboarding ? 'Get Started' : 'Connect')
  const isXSmallOrLess = useMediaQuery(theme.breakpoints.down('xs'))
  const canShowComponents = headerlessOnDesktop ? isXSmallOrLess : true

  return (
    <>
      {onBackClick && media && canShowComponents && (
        <Button
          id={`${id}-navigation-page-back`}
          variant="icon"
          className={`absolute top-0 left-0.5 z-10 ${
            backButtonClassNames ?? ''
          }`}
          onClick={onBackClick}
        >
          <Icon name="ArrowLeft" size={24} color="grey-900" />
        </Button>
      )}
      <Grid
        container
        className={`${classes.wrapper} ${className}`}
        justifyContent="center"
        {...rest}
      >
        {media &&
          canShowComponents &&
          (typeof media === 'string' ? (
            <CardMedia
              component="img"
              image={media}
              className={`${classes.image} ${imageClassName}`}
              style={imageStyle}
            />
          ) : (
            <CardMedia className={`${classes.image} ${imageClassName}`}>
              {media}
            </CardMedia>
          ))}
        <Box
          p={padContent ? 8 : 0}
          pt={{
            xs: 0,
            sm: 8,
          }}
        >
          {onboarding && media && canShowComponents && (
            <Grid
              container
              alignItems="center"
              direction="column"
              className={classes.scrollTextArea}
            >
              {isXSmallOrLess && (
                <>
                  <Typography
                    variant="subtitle2"
                    className={classes.scrollText}
                  >
                    Scroll for more
                  </Typography>
                  {[...Array(3).keys()].map((x) => (
                    <ChevronDown
                      key={x}
                      size={16}
                      color="#5D5D5D"
                      className={classes.scrollChevron}
                    />
                  ))}
                </>
              )}
            </Grid>
          )}
          <ListableInformationCard
            pageName={id}
            backButtonClassNames={backButtonClassNames}
            mainContent
            title={title}
            subtitle={subtitle}
            tag={tag}
            className={providedClasses?.header}
            classes={providedClasses}
            onBackClick={
              onBackClick && (!media || !canShowComponents)
                ? onBackClick
                : undefined
            }
          />
          {underTitleComponent}
          {informationCards.map((infoCard, index) => (
            <ListableInformationCard
              key={index}
              classes={{ iconContainer: providedClasses?.iconContainer }}
              {...infoCard}
            />
          ))}
          {footer}
          <CardActions className={sticky ? classes.sticky : undefined}>
            {Boolean(onSkipClick) && (
              <CardAction type="secondary">
                <Button
                  className={sticky ? classes.shadowed : undefined}
                  variant="secondary"
                  onClick={onSkipClick}
                  id={`${id}-skip-button`}
                >
                  Skip
                </Button>
              </CardAction>
            )}
            {button ?? (
              <CardAction type="primary">
                <Button
                  className={sticky ? classes.shadowed : undefined}
                  variant="primary"
                  onClick={onClick}
                  id={`${id}-primary-button`}
                >
                  {buttonMessage}
                </Button>
              </CardAction>
            )}
          </CardActions>
        </Box>
      </Grid>
    </>
  )
}

export default ConnectDeviceInformationCard
