import { RSAA } from 'redux-api-middleware'
import { withAuth } from '../reducers'
import config from '../config'

export const ALL_LIST_REQUEST = '@@utilities/ALL_LIST_REQUEST'
export const ALL_LIST_SUCCESS = '@@utilities/ALL_LIST_SUCCESS'
export const ALL_LIST_FAILURE = '@@utilities/ALL_LIST_FAILURE'

export const LIST_REQUEST = '@@utilities/LIST_REQUEST'
export const LIST_SUCCESS = '@@utilities/LIST_SUCCESS'
export const LIST_FAILURE = '@@utilities/LIST_FAILURE'

export const CREATE_REQUEST = '@@utilities/CREATE_REQUEST'
export const CREATE_SUCCESS = '@@utilities/CREATE_SUCCESS'
export const CREATE_FAILURE = '@@utilities/CREATE_FAILURE'

export const SELECT_UTILITY_REQUEST = '@@utilities/SELECT_UTILITY_REQUEST'
export const SELECT_UTILITY_SUCCESS = '@@utilities/SELECT_UTILITY_SUCCESS'
export const SELECT_UTILITY_FAILURE = '@@utilities/SELECT_UTILITY_FAILURE'

export const MAKE_GRID_SUPPORT_SELECTION_REQUEST =
  '@@utilities/MAKE_GRID_SUPPORT_SELECTION_REQUEST'
export const MAKE_GRID_SUPPORT_SELECTION_SUCCESS =
  '@@utilities/MAKE_GRID_SUPPORT_SELECTION_SUCCESS'
export const MAKE_GRID_SUPPORT_SELECTION_FAILURE =
  '@@utilities/MAKE_GRID_SUPPORT_SELECTION_FAILURE'

export const UPDATE_LEAP_AUTHORIZATION_REQUEST =
  '@@utilities/UPDATE_LEAP_AUTHORIZATION_REQUEST'
export const UPDATE_LEAP_AUTHORIZATION_SUCCESS =
  '@@utilities/UPDATE_LEAP_AUTHORIZATION_SUCCESS'
export const UPDATE_LEAP_AUTHORIZATION_FAILURE =
  '@@utilities/UPDATE_LEAP_AUTHORIZATION_FAILURE'

export const SET_LOCAL_GRID_SUPPORT_SELECTION =
  '@@utilities/SET_LOCAL_GRID_SUPPORT_SELECTION'

export const SET_GRID_SUPPORT_CONFIRMATION_PENDING =
  '@@utilities/SET_GRID_SUPPORT_CONFIRMATION_PENDING'

export const CLEAR_UTILITY_ERRORS = '@@utilities/CLEAR_UTILITY_ERRORS'

export const getAllUtilities = (searchValue) => ({
  [RSAA]: {
    endpoint: `${config.apiGateway.URL}/api/search-utilities?search=${searchValue}`,
    method: 'GET',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [ALL_LIST_REQUEST, ALL_LIST_SUCCESS, ALL_LIST_FAILURE],
  },
})

export const getUtilities = () => ({
  [RSAA]: {
    endpoint: `${config.apiGateway.URL}/api/utilities/`,
    method: 'GET',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [LIST_REQUEST, LIST_SUCCESS, LIST_FAILURE],
  },
})

export const createUtility = (utilityData) => ({
  [RSAA]: {
    endpoint: `${config.apiGateway.URL}/api/utilities/`,
    method: 'POST',
    body: JSON.stringify(utilityData),
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
  },
})

export const selectUtility = (utilityData) => ({
  [RSAA]: {
    endpoint: `${config.apiGateway.URL}/api/current_user/`,
    method: 'PATCH',
    body: JSON.stringify(utilityData),
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      SELECT_UTILITY_REQUEST,
      SELECT_UTILITY_SUCCESS,
      SELECT_UTILITY_FAILURE,
    ],
  },
})

export const patchGridSupportSelection = (data) => ({
  [RSAA]: {
    endpoint: `${config.apiGateway.URL}/api/current_user/`,
    method: 'PATCH',
    body: JSON.stringify(data),
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      MAKE_GRID_SUPPORT_SELECTION_REQUEST,
      {
        type: MAKE_GRID_SUPPORT_SELECTION_SUCCESS,
        meta: { log: 'enableGridSupport' },
      },
      MAKE_GRID_SUPPORT_SELECTION_FAILURE,
    ],
  },
})

export const makeGridSupportSelection = (data, dispatch) => {
  if (!('has_made_grid_support_selection' in data)) {
    data.has_made_grid_support_selection = true
  }
  return patchGridSupportSelection(data)
}

export const updateLeapAuthorization = (data) => ({
  [RSAA]: {
    endpoint: `${config.apiGateway.URL}/api/current_user/`,
    method: 'PATCH',
    body: JSON.stringify(data),
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      UPDATE_LEAP_AUTHORIZATION_REQUEST,
      UPDATE_LEAP_AUTHORIZATION_SUCCESS,
      UPDATE_LEAP_AUTHORIZATION_FAILURE,
    ],
  },
})

export const setGridSupportConfirmationPending = (data) => ({
  type: SET_GRID_SUPPORT_CONFIRMATION_PENDING,
  payload: data,
})

export const clearUtilityErrors = () => ({
  type: CLEAR_UTILITY_ERRORS,
})

export const clearAllUtilities = () => ({
  type: ALL_LIST_SUCCESS,
  payload: [],
})
