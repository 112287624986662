import React, { Component } from 'react'

import Header from '../components/Header/header'
import FooterSection from '../components/Footer'
import TermsOfServiceHeader from '../components/TermsOfService/TermsOfServiceHeader'
import TermsOfService from '../components/TermsOfService'

// This came from Termly
class Terms extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <div className="main _use-deprecated-bootstrap _use-deprecated-style">
          <TermsOfServiceHeader lastUpdated="March 20, 2025" />
          <TermsOfService />
        </div>
        <FooterSection />
      </React.Fragment>
    )
  }
}

export default Terms
