import { useMockableViewModel } from '@/hooks'
import { SurveyType } from '@/types'
import { useCallback, useState } from 'react'
import { useSurveyResponder } from '@/app/components/SurveyObserver'
import surveyBuilder from '@/utils/survey-builder/surveyBuilder'

export interface FeedbackMessageSurveyProps {
  open: boolean
  onClose: () => void
  onNext?: () => void
  onSubmit: () => void
  surveyType: SurveyType
}

function useViewModel(props: FeedbackMessageSurveyProps) {
  const [surveyIdx, setSurveyIdx] = useState(0)
  const [reason, setReason] = useState('')
  const [message, setMessage] = useState('')
  const { submitResponse } = useSurveyResponder(props.surveyType)

  const surveys = surveyBuilder(props.surveyType)
  const onLastSurvey = surveyIdx === surveys.length - 1
  const currentSurvey = surveys?.[surveyIdx]

  const progressSurvey = async () => {
    if (onLastSurvey) {
      await submitResponse({ reason, message })
      props.onSubmit()
      setSurveyIdx(0)

      return
    }

    if (props.onNext) {
      props.onNext()
    }
    setSurveyIdx(surveyIdx + 1)
  }

  const selectReason = useCallback(async (reason: string) => {
    setReason(reason)
    await progressSurvey()
  }, [])

  const closeSurvey = useCallback(() => {
    setSurveyIdx(0)
    props.onClose()
  }, [props.onClose])

  return {
    ...props,
    onLastSurvey,
    currentSurvey,
    closeSurvey,
    selectReason,
    progressSurvey,
    setMessage,
    surveyIdx,
  }
}

function useMockViewModel() {
  const testSurvey = surveyBuilder('ProfileDeletion')?.[0]

  return {
    open: false,
    onClose: () => alert('Closed!'),
    onNext: () => alert('Next!'),
    onSubmit: () => alert('Submitted!'),
    surveyType: 'ProfileDeletion' as SurveyType,
    onLastSurvey: false,
    currentSurvey: testSurvey,
    closeSurvey: () => alert('Survey closed!'),
    selectReason: async () => alert('Reason selected!'),
    progressSurvey: async () => alert('Survey progressed!'),
    setMessage: () => alert('Message selected!'),
    surveyIdx: 0,
  }
}

export default useMockableViewModel({ useViewModel, useMockViewModel })
