import { useRef } from 'react'

export const useFocus = (): [
  React.MutableRefObject<HTMLInputElement>,
  () => void,
] => {
  const htmlElRef = useRef(
    null,
  ) as unknown as React.MutableRefObject<HTMLInputElement>
  const setFocus = (): void => {
    htmlElRef.current?.focus()
    return
  }

  return [htmlElRef, setFocus]
}
