import { useSelector } from 'react-redux'
import { getGraphData } from '../../Graph'
import { getHoursRange } from '../../../../utils/rangeUtilities'
import { getMissingPeriods } from './Plan'

export const OVERLAP_HOURS_MESSAGE = 'Hours cannot overlap'
export const MISSING_HOURS_MESSAGE =
  'Please fill in all hours. Make sure you hit the + button after adding a rate'

export function getOptions(currencyName) {
  return {
    lineDashType: 'dash',
    maintainAspectRatio: false,
    legend: { display: false },
    scales: {
      yAxes: [
        {
          gridLines: { display: false },
          stacked: false,
          ticks: {
            stepSize: 0.05,
            beginAtZero: true,
            callback: function (value, index, values) {
              return value.toLocaleString('en-US', {
                style: 'currency',
                currency: currencyName,
              })
            },
          },
        },
      ],
      xAxes: [
        {
          barPercentage: 0.95,
        },
      ],
    },
  }
}

export function getCurrency(rates) {
  let currency = { name: 'USD', symbol: '$' }
  const found = rates.find((rate) => {
    if (rate.currency) {
      return
    }
  })
  return found ? found.currency : currency
}

export function useRatesGraph(rates) {
  const { errorHours: errorPeriods } = useSelector((state) => state.manualPlans)
  const currency = getCurrency(rates)
  const labels = [
    '12am',
    '1am',
    '2am',
    '3am',
    '4am',
    '5am',
    '6am',
    '7am',
    '8am',
    '9am',
    '10am',
    '11am',
    '12pm',
    '1pm',
    '2pm',
    '3pm',
    '4pm',
    '5pm',
    '6pm',
    '7pm',
    '8pm',
    '9pm',
    '10pm',
    '11pm',
  ]
  const label = `${currency.symbol}/kWh`
  const missingHours = getMissingPeriods(labels.length, rates, getHoursRange)
  const hoursData = getGraphData(
    getHoursGraphData(),
    labels,
    label,
    rates,
    'rate',
    errorPeriods,
    getHoursRange,
  )

  function getHoursGraphData() {
    let graphRates = Array(labels.length).fill(0.5)
    for (let i = 0; i < rates.length; i++) {
      let rate = rates[i]
      let hours = getHoursRange(rate.start, rate.end)
      for (let j = 0; j < hours.length; j++) {
        graphRates[hours[j]] = rate.rate
      }
    }
    return graphRates
  }

  return [hoursData, getOptions(currency.name), missingHours]
}
