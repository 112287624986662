import './styles.scss'
import {
  BarGraph,
  Grid,
  LegendItemProps,
  NumberEasing,
  Sheet,
  Text,
} from '../../../../components'
import formatClasses from '../../../../utils/classes/formatClasses'
import getColor from '../../../../utils/color/getColor'
import React, { useEffect, useState } from 'react'
import { clamp, positiveClamp } from '../../../../utils/limit'
import { isDecimal } from '../../../../utils/number'

export interface Props {
  items: DataPoint[]
  period: DataPeriod
  legend: LegendItemProps[]
  loading: boolean
  totalSpent: number
  setPeriod: (period: DataPeriod) => void
  xAxis: (string | number)[]
  yAxis: (string | number)[]
  selectedInfo: HoveredDataPointInfo
  setSelectedInfo: (info: HoveredDataPointInfo) => void
  selectedPeriod: string
  onPointerDown: (event: React.PointerEvent<HTMLElement>) => void
  onPointerEnter: (info: HoveredDataPointInfo) => void
  onPointerLeave: (event?: React.PointerEvent<HTMLElement>) => void
}

export type DataPeriod = 'day' | 'week' | 'month'
export type DataPointType = 'cool' | 'heat' | 'adjustments'

export interface DataPoint {
  cost: number
  data: { value: number; type: DataPointType }[]
  label: string
  maxValue: number
  type: DataPeriod
  value: number // # of kWh
}

interface DataPointProps extends DataPoint {
  onPointerDown: (event: any) => void
  onPointerEnter: (info: HoveredDataPointInfo) => void
  selected: boolean
  setSelected: (selected: boolean) => void
}

export interface HoveredDataPointInfo {
  energyUsed: number
  totalSpent: number
  period: string
}

function DataPoint(props: DataPointProps) {
  const classes = {
    bar: formatClasses(['total-spent-bar-graph-bar']),
    dataPoint: formatClasses([
      'total-spent-data-point',
      props.selected ? 'selected' : undefined,
    ]),
  }

  const BAR_GRAPH_HEIGHT = 105 // Height in pixels

  // Sum of all data values for this data point (cooling + heating + adjustments)
  const totalValue = props.data
    .map((item) => Number(item.value.toFixed(1)))
    .reduce((sum, operand) => sum + operand, 0)

  // Computed height of this bar
  const height = `${Math.round(
    (totalValue / props.maxValue) * BAR_GRAPH_HEIGHT,
  )}px`

  function getBackground() {
    const colors = {
      cool: 'blue-500',
      heat: 'red-500',
      adjustments: 'orange-300',
      off: 'grey-200',
    }

    if (totalValue <= 0) {
      return getColor(colors.off)
    }

    const filteredData = props.data.filter((item) => item.value)

    // If there's only one piece of data, use a solid color, otherwise generate a gradient
    if (filteredData.length === 1) {
      return getColor(colors[filteredData[0].type])
    } else {
      const sortOrder = {
        cool: 3,
        heat: 2,
        adjustments: 1,
        off: 3,
      }

      // Sort in the order of Cool, Heat, Adjustment, starting from the bottom of the bar
      const sortedData = filteredData.sort((a, b) => {
        return sortOrder[a.type] - sortOrder[b.type]
      })

      // Create the linear gradient for the bar's background color
      const gradient = sortedData.map((item, index) => {
        if (!item.value) return

        const color = getColor(colors[item.type])
        // Percentage to begin the color at
        const start =
          index - 1 >= 0
            ? `${clamp(
                0,
                Math.round(
                  (sortedData[index - 1].value / totalValue) * BAR_GRAPH_HEIGHT,
                ),
                100,
              )}%`
            : ''
        // Percentage to end the color at
        const end = `${clamp(
          0,
          Math.round((item.value / totalValue) * BAR_GRAPH_HEIGHT),
          100,
        )}%`

        return [color, start ? start : '', end].join(' ')
      })

      return `linear-gradient(to bottom, ${gradient})`.trim()
    }
  }

  function onPointerEnter() {
    props.setSelected(true)

    const label = props.label
      ? props.type !== 'day'
        ? props.label
        : `Today between ${props.label}`
      : ''

    // Update the summary to show the data for the selected bar
    props.onPointerEnter({
      energyUsed: totalValue,
      period: label,
      totalSpent: props.cost,
    })
  }

  return (
    <div
      className={classes.dataPoint}
      data-key={props.label ? props.label : undefined}
      data-type={props.type}
      data-value={totalValue}
      onPointerDown={props.onPointerDown}
      onPointerEnter={onPointerEnter}
      onPointerLeave={() => props.setSelected(false)}
    >
      <div
        className={classes.bar}
        style={{
          background:
            totalValue !== 0 ? getBackground() : 'var(--color-grey-200)',
          height: height,
        }}
      />
    </div>
  )
}

function TotalSpentSummary(props: HoveredDataPointInfo) {
  return (
    <Sheet size="lg" color="grey-100">
      <Grid flow="row" gap="16px">
        <Text>{props.period}</Text>
        <Grid gap="64px" templateColumns="88px 1fr">
          <Grid flow="row">
            <Text variant="body2">Energy Used</Text>
            <Grid gap="2px" placeItems="baseline" templateColumns="auto 1fr">
              <Text variant="h1">
                <NumberEasing
                  decimals={
                    positiveClamp(props.energyUsed) === 0 ||
                    props.energyUsed > 100
                      ? 0
                      : 1
                  }
                  value={positiveClamp(props.energyUsed)}
                  speed={400}
                  ease="quintOut"
                />
              </Text>
              <Text>kWh</Text>
            </Grid>
          </Grid>
          <Grid flow="row">
            <Text variant="body2">Total Spent</Text>
            <Text variant="h1">
              $
              <NumberEasing
                decimals={isDecimal(positiveClamp(props.totalSpent)) ? 2 : 0}
                value={positiveClamp(props.totalSpent)}
                speed={400}
                ease="quintOut"
              />
            </Text>
          </Grid>
        </Grid>
      </Grid>
    </Sheet>
  )
}

export default function TotalSpentGraph(props: Props) {
  const [selectedBar, setSelectedBar] = useState<number | null>(null)

  // Lift period prop from TotalSpentCard to useViewModel
  useEffect(() => {
    props.setPeriod(props.period)
    setSelectedBar(null)
  }, [props.period])

  function onPointerLeave() {
    props.onPointerLeave()

    // Deselects the bar when the pointer leaves the graph on mobile (iOS only)
    setSelectedBar(null)
  }

  function DataPoints() {
    return (
      <>
        {props.items.map((item, index) => {
          return (
            <DataPoint
              data={item.data}
              cost={item.cost}
              key={index}
              label={item.label}
              maxValue={item.maxValue}
              type={props.period}
              value={6}
              onPointerDown={props.onPointerDown}
              onPointerEnter={props.onPointerEnter}
              selected={index === selectedBar}
              setSelected={(selected: boolean) => {
                if (!selected) {
                  // Returns the graph back to its default state when the pointer leaves via the bottom of the graph
                  props.setSelectedInfo({
                    period: props.selectedPeriod,
                    energyUsed:
                      props.items
                        .filter((item) => item.type === props.period)
                        .reduce((sum, dataPoint) => sum + dataPoint.value, 0) ||
                      0,
                    totalSpent: props.totalSpent,
                  })

                  // Deselects the bar when the pointer leaves the graph on desktop
                  setSelectedBar(null)
                } else setSelectedBar(index)
              }}
            />
          )
        })}
      </>
    )
  }

  return (
    <>
      <Grid flow="row" gap="24px">
        <TotalSpentSummary {...props.selectedInfo} />
        <BarGraph
          className={props.loading ? 'loading' : undefined}
          onPointerLeave={onPointerLeave}
          legend={props.legend}
          xAxis={props.xAxis}
          yAxis={props.yAxis}
        >
          <DataPoints />
        </BarGraph>
      </Grid>
    </>
  )
}
