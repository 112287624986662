import { RootState } from '@/reducers'
import { ChargeForecast, ChargeSchedule } from '@/types'
import {
  isChargeForecastWithCharger,
  isChargeForecastWithVehicle,
} from '@/types/chargeForecast'
import { isVehicleChargeSchedule } from '@/types/vehicle/chargeSchedule'
import { isVehicleChargerChargeSchedule } from '@/types/vehicleCharger/chargeSchedule'
import * as Sentry from '@sentry/browser'

export function selectScheduleForChargeForecast(
  state: RootState,
  chargeForecast: ChargeForecast,
): ChargeSchedule | undefined {
  if (isChargeForecastWithVehicle(chargeForecast)) {
    const schedule = state.schedule.chargeSchedules?.find(
      (schedule) => schedule.vehicle_id === chargeForecast.vehicle_id,
    )
    if (schedule && isVehicleChargeSchedule(schedule)) {
      return schedule
    } else if (schedule && !isVehicleChargeSchedule(schedule)) {
      Sentry.captureException(
        new Error(`Expected schedule to be a VehicleChargeSchedule`),
      )
    }
  }

  if (isChargeForecastWithCharger(chargeForecast)) {
    const schedule = state.schedule.chargeSchedules?.find(
      (schedule) => schedule.charger_id === chargeForecast.charger_id,
    )
    if (schedule && isVehicleChargerChargeSchedule(schedule)) {
      return schedule
    } else if (schedule && !isVehicleChargerChargeSchedule(schedule)) {
      Sentry.captureException(
        new Error(`Expected schedule to be a VehicleChargerChargeSchedule`),
      )
    }
  }

  return undefined
}
