import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import { makeStyles } from '@material-ui/styles'
import {
  Button,
  createTheme,
  Dialog,
  DialogContent,
  Grid as MuiGrid,
} from '@material-ui/core'
import DialogTitleCloseable from '../DialogTitleCloseable'
import v2Theme from '@/v2/theme'
import profileTheme from '@/authenticated/pages/Profile/theme'
import { ThemeProvider } from '@material-ui/core/styles'

const Grid = styled(MuiGrid)(spacing)

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    textAlign: 'center',
  },
  button: {
    width: '100%',
    height: '50px',
    backgroundColor: theme.palette.gray.main,
    color: theme.palette.gray.contrastText,
  },
}))

const FeedbackSelectDialogUnthemed = ({
  feedbackInquiry = 'Tell us why',
  reasons,
  open,
  onClose,
  onSubmit,
  ...restProps
}) => {
  const classes = useStyles()

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      open={open}
    >
      <DialogTitleCloseable onClose={onClose}>
        {feedbackInquiry}
      </DialogTitleCloseable>
      <DialogContent>
        <Grid container direction="column" spacing={2} justifyContent="center">
          {reasons.map(({ display, value }) => (
            <Grid
              key={display}
              className={classes.buttonContainer}
              item
              xs={12}
            >
              <Button
                className={classes.button}
                variant="contained"
                onClick={() => onSubmit(value)}
              >
                {display}
              </Button>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default function FeedbackSelectDialog({
  feedbackInquiry = 'Tell us why',
  reasons,
  open,
  onClose,
  onSubmit,
  ...restProps
}) {
  return (
    <ThemeProvider
      theme={(theme) =>
        createTheme({
          ...v2Theme,
          palette: {
            ...v2Theme.palette,
            ...profileTheme.palette,
          },
          overrides: {
            ...v2Theme.overrides,
            ...profileTheme.overrides,
          },
        })
      }
    >
      <FeedbackSelectDialogUnthemed
        feedbackInquiry={feedbackInquiry}
        reasons={reasons}
        open={open}
        onClose={onClose}
        onSubmit={onSubmit}
        {...restProps}
      />
    </ThemeProvider>
  )
}
