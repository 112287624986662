import { createResourceCollection } from '@/request'
import { SurveyResponse } from '@/types'

export const surveyResponsesCollection =
  createResourceCollection<SurveyResponse>({
    name: 'surveyResponses',
    apiConfig: {
      path: 'survey_responses',
    },
    selector: (state) => state.surveyResponses,
  })

export default surveyResponsesCollection.slice.reducer
