import { Button, Icon, LoadingAnimation, Modal, Text } from '@/components'
import CarouselSelector from '@/components/CarouselSelector/CarouselSelector'
import TextFormField, {
  TextFormFieldIcon,
} from '@/components/forms/text-form-field'
import { AlertMessage, Link } from 'app-components'
import useViewModel, {
  AvaQuestionStatus,
} from '@/app/pages/ava/onboarding/verify-residence/useViewModel'
import { Redirect } from 'react-router-dom'
import BrandedPage, { ButtonContainer } from '@/app/pages/ava/BrandedPage'
import { useForm } from 'react-hook-form'
import { useNavigation } from '@/app/hooks'

function Question(props: { label: string; value?: string }) {
  return (
    <div className="form-field-root">
      <div className="text-xs text-[#5D5D5D] font-bold">{props.label}</div>
      <div className="text-sm">{props.value ?? 'Unknown'}</div>
    </div>
  )
}

function QuestionStatus(props: { status: AvaQuestionStatus }) {
  switch (props.status) {
    case AvaQuestionStatus.INVALID:
      return <Icon name="FormInvalid" size={20} />
    case AvaQuestionStatus.VALID:
      return <Icon name="FormValid" size={20} />
    case AvaQuestionStatus.LOADING:
      return (
        <LoadingAnimation
          type={'falling'}
          className="h-5 w-5"
          style={{ gridGap: 1 }}
        />
      )
    default:
      return <></>
  }
}

function HelpModal(props: {
  isOpen: boolean
  closeModal: () => void
  nextPage: string
}) {
  const { register } = useForm<{ name: string; email: string }>()
  const navigation = useNavigation()

  return (
    <Modal
      open={props.isOpen}
      id={'verify-residence-help-modal'}
      title={'Help with verification'}
      onClose={props.closeModal}
    >
      <Text variant="body4" className="mb-5">
        If your primary account holder is a person, enter their name as&nbsp;
        <u>Last,First</u> with no spaces (including the comma)
      </Text>
      <TextFormField
        register={register}
        disabledColor="base-800"
        name="name"
        label="Last name,First name"
        disabled={true}
        defaultValue={'Smith,John'}
      >
        <TextFormFieldIcon>
          <QuestionStatus status={AvaQuestionStatus.VALID} />
        </TextFormFieldIcon>
      </TextFormField>
      <Text variant="body4" className="mt-5 mb-5">
        If your primary account holder is a business, enter their name normally,
        including spaces
      </Text>
      <TextFormField
        register={register}
        disabledColor="base-800"
        name="email"
        label="Full Name"
        disabled={true}
        defaultValue={'Smith Real Estate LLC'}
      >
        <TextFormFieldIcon>
          <QuestionStatus status={AvaQuestionStatus.VALID} />
        </TextFormFieldIcon>
      </TextFormField>
      <Text variant="body4" className="mt-5 mb-5">
        Email is often easier to guess than name. You may also skip
        verification, but must verify prior to reward redemption unless device
        location data confirms your address.
      </Text>
      <div className="mb-5 justify-center text-center">
        <Link onClick={() => navigation.push(props.nextPage)}>
          Skip verification
        </Link>
      </div>
      <ButtonContainer>
        <Button id="retry-button" onClick={props.closeModal}>
          Retry
        </Button>
      </ButtonContainer>
    </Modal>
  )
}

export default function AvaVerifyResidencePage() {
  const {
    currentQuestion,
    labelledQuestions,
    formRegister,
    isLoading,
    onCarouselChange,
    onNext,
    onNameChange,
    onEmailChange,
    nameStatus,
    emailStatus,
    error,
    showSkipMessage,
    success,
    openModal,
    closeModal,
    helpModalOpen,
    nextPage,
  } = useViewModel()

  const mutlipleUsers = labelledQuestions?.length > 1

  const loadedContent =
    labelledQuestions.length > 0 ? (
      <BrandedPage.Content>
        {error && (
          <AlertMessage variant="error" className="mb-2">
            {error}
          </AlertMessage>
        )}
        <div
          className={`${
            mutlipleUsers ? 'absolute' : ''
          } inline-flex md:w-[95%] w-[100%] justify-end text-right`}
        >
          <Link onClick={openModal}>Help?</Link>
        </div>
        <CarouselSelector
          className="justify-center"
          items={labelledQuestions}
          labelProperty="label"
          onChange={onCarouselChange}
          options={{
            hideHeader: !mutlipleUsers,
            disableNavWrapping: true,
          }}
        >
          <div className="flex flex-col mt-2.5 gap-2.5">
            <Question
              label={
                currentQuestion?.name.includes(',')
                  ? 'Last name,First name'
                  : 'Full Name'
              }
              value={currentQuestion?.name}
            />
            <TextFormField
              name="name"
              label={
                !currentQuestion?.name
                  ? 'No name found'
                  : 'What is the full name listed above?'
              }
              register={formRegister}
              onChange={onNameChange}
              disabled={
                currentQuestion?.name?.toLowerCase() === 'unknown' || success
              }
            >
              <TextFormFieldIcon>
                <QuestionStatus status={nameStatus} />
              </TextFormFieldIcon>
            </TextFormField>
            <Text variant="body1" className="flex-fill text-center my-1">
              OR
            </Text>
            <Question label="Full Email" value={currentQuestion?.email} />
            <TextFormField
              name="email"
              label={
                !currentQuestion?.email
                  ? 'No email found'
                  : 'What is the full email listed above?'
              }
              register={formRegister}
              onChange={onEmailChange}
              disabled={!currentQuestion?.email || success}
            >
              <TextFormFieldIcon>
                <QuestionStatus status={emailStatus} />
              </TextFormFieldIcon>
            </TextFormField>
          </div>
        </CarouselSelector>
        {showSkipMessage && (
          <AlertMessage variant="info" className="mt-4 transition-opacity">
            You may skip verification, but must verify prior to reward
            redemption unless vehicle location data confirms your address
          </AlertMessage>
        )}
        {success && (
          <AlertMessage variant="success" className="mt-4 transition-opacity">
            Thank you! Your residence has been successfully verified. Please
            proceed.
          </AlertMessage>
        )}
        <BrandedPage.ButtonContainer>
          <Button id={'next-button'} onClick={onNext}>
            {!success ? 'Skip' : 'Next'}
          </Button>
        </BrandedPage.ButtonContainer>
      </BrandedPage.Content>
    ) : (
      <Redirect to={nextPage} />
    )

  return (
    <BrandedPage
      id={'verify-residence'}
      title="Verify your residence"
      subtitle="We were unable to match your information to a primary account holder at this address. Please confirm a primary account holder's information to verify your residence."
    >
      {isLoading && <LoadingAnimation type="falling" centered />}
      {!isLoading && loadedContent}
      {helpModalOpen && (
        <HelpModal
          closeModal={closeModal}
          isOpen={helpModalOpen}
          nextPage={nextPage}
        />
      )}
    </BrandedPage>
  )
}
