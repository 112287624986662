import DeviceConnectionRedirect, {
  ConnectionStatusMode,
  Props as DeviceConnectionRedirectProps,
} from '../DeviceConnectionRedirect'
import ConnectVehicleConfirmation from '../Vehicle/ConnectVehicleConfirmation'
import { Grid as MuiGrid } from '@material-ui/core'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import useIsMobile from '@/authenticated/hooks/useIsMobile'
import NavigationPage from '@/app/components/NavigationPage'
import { useAppDispatch, useUrlSearchParam } from '@/hooks'
import React, { useEffect, useMemo } from 'react'
import { useNavigation } from '@/app/hooks'
import { useWhiteLabelData } from '@/hooks/whitelabel/useWhiteLabelData'
import useUserWhitelabel from '@/hooks/whitelabel/useUserWhitelabel'
import { remoteConfig } from '@/firebase'
import useWhiteLabelProgramId from '@/hooks/whitelabel/useWhiteLabelProgramId'
import { useProfile } from '@/hooks/useProfileId'
import { Link } from 'app-components'
import { ButtonContainer } from '@/app/pages/ava/BrandedPage'
import { getCurrentUser } from '@/actions/user'
import useRemoteConfig from '@/hooks/useRemoteConfig'
import { LoadingAnimation } from '@/components'

const Grid = styled(MuiGrid)(spacing)
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      maxHeight: '1000px',
      backgroundColor: '#FFF',
      padding: theme.spacing(2),
      minHeight: '280px',
      flex: '1',
    },
  }),
)

export type SmartcarConnectError =
  | 'access_denied'
  | 'invalid_subscription'
  | 'configuration_error'
  | 'no_vehicles'
  | 'server_error'
  | 'vehicle_incompatible'
  | 'oem_cancelled_login'
  | 'user_manually_returned_to_application'

// These are errors that mean that, according to smartcar, your vehicle _cannot_
// be used with Optiwatt. Reasons outside this list indicate a server issue
// within smartcar
const SMARTCAR_FAILURE_REASONS: SmartcarConnectError[] = [
  'access_denied',
  'invalid_subscription',
  'no_vehicles',
  'vehicle_incompatible',
]

export interface Props extends DeviceConnectionRedirectProps {}

function GetReadableSmartcarError(error: SmartcarConnectError | null): string {
  if (error === null || !SMARTCAR_FAILURE_REASONS.includes(error)) {
    return 'Oh no! Something went wrong. Please go back and try again.'
  }

  return 'Oh no! You can not connect this vehicle. Please connect another way.'
}

function SmartCarRedirectContent(props: Props) {
  const isMobile = useIsMobile()
  const error = useUrlSearchParam('error')
  const navigation = useNavigation()
  const userWhitelabelId = useWhiteLabelProgramId()
  const { appRemoteConfig, isLoading } = useRemoteConfig()
  const avaId = appRemoteConfig?.getWhitelabelId('ava')
  const dispatch = useAppDispatch()

  useEffect(() => {
    // Fetch whitelabel information in the event Smartcar opened a new Optiwatt window
    dispatch(getCurrentUser())
  }, [])

  const isAva = userWhitelabelId === avaId

  if (!isMobile && props.mode !== ConnectionStatusMode.Failed) {
    return null
  }

  if (props.mode === ConnectionStatusMode.Connected) {
    return (
      <ConnectVehicleConfirmation
        onConfirmed={props.buttonAction ?? (() => {})}
      />
    )
  }

  const statusText = { ...props.statusText }
  const buttonText = { ...props.buttonText }
  const cantConnect = SMARTCAR_FAILURE_REASONS.includes(
    error as SmartcarConnectError,
  )
  statusText.failed = GetReadableSmartcarError(error as SmartcarConnectError)
  buttonText.failed = cantConnect ? 'Connect Another Vehicle' : 'Retry'
  const buttonAction = () => {
    if (cantConnect) {
      if (isAva) {
        navigation.push('/ava/connect-vehicle')
      } else {
        navigation.push('/connect-vehicle')
      }
    } else if (props.buttonAction) {
      props.buttonAction()
    }
  }

  if (isLoading) {
    return <LoadingAnimation type="falling" centered />
  }

  return (
    <DeviceConnectionRedirect
      {...props}
      statusText={statusText}
      buttonText={buttonText}
      buttonAction={buttonAction}
    >
      {isAva && (
        <>
          <ButtonContainer>
            <div className="flex justify-center w-full my-4">
              <Link to={'/ava/connect-device/charger/select'}>
                Connect my charger instead
              </Link>
            </div>
            <div className="flex justify-center w-full my-4">
              <Link to={`/ava/program-enrollment/${avaId}/offer`}>
                Skip and enroll my address
              </Link>
            </div>
          </ButtonContainer>
        </>
      )}
    </DeviceConnectionRedirect>
  )
}

export default function SmartCarRedirect(props: Props) {
  return (
    <NavigationPage id="smartcar-redirect-page" navigationOptions={['nothing']}>
      <SmartCarRedirectContent {...props} />
    </NavigationPage>
  )
}
