import { CarModel } from '@/types'
import * as actions from '../actions/carModels'
import { getApiErrorMessage } from '../utils/errorUtilities'
import { PayloadAction } from '@reduxjs/toolkit'

type State = {
  carModels: CarModel[] | null
  isLoading: boolean
  errors: Record<string, unknown>
}

const initialState = {
  carModels: null,
  isLoading: false,
  errors: {},
}

export default (state = initialState, action: PayloadAction<any>): State => {
  switch (action.type) {
    case actions.CAR_MODEL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        carModels: action.payload,
        errors: {},
      }
    case actions.CAR_MODEL_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case actions.CAR_MODEL_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: getApiErrorMessage(action.payload),
      }
    default:
      return state
  }
}
