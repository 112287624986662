import {
  Card,
  Chip,
  ChipGroup,
  Flex,
  List,
  DataListItem,
  Sheet,
  Skeleton,
  Text,
  Grid,
  NumberEasing,
} from '../../../../components'
import { TemperatureBar, TotalSpentGraph } from '../index'
import { DataPeriod } from '../TotalSpentGraph/TotalSpentGraph'
import { ThermostatCostSummary } from '../../../../types/thermostatCostHistorySummary'
import { ID } from '../../../../types/model'
import { isDecimal } from '../../../../utils/number'
import { positiveClamp } from '../../../../utils/limit'
import { centsToDollars } from '../../../../utils/currency'
import { CostSheet } from '../../../components'
import { ThermostatProvider } from '@/types/thermostat'
import { remoteConfig } from '@/firebase'

export interface Props {
  thermostatId: ID
  thermostatName: ThermostatProvider
  items?: ThermostatCostSummary
  onChipGroupClick?: (index: number | null) => void
  period: DataPeriod
  setPeriod: (period: DataPeriod) => void
  loading: boolean
}

const CostSheetSkeleton = () => (
  <Skeleton animation="wave" variant="text" width={34} height={26} />
)

const TemperatureSkeleton = () => (
  <Skeleton animation="wave" variant="text" width={25} height={18} />
)

const TemperatureBarSkeleton = () => (
  <Skeleton animation="wave" variant="text" width={137} height={18} />
)

export default function TotalSpentCard(props: Props) {
  const hide_nest_schedule =
    (remoteConfig?.getBoolean('hide_nest_schedule_components') ?? true) &&
    props.thermostatName === 'Nest'

  return (
    <Card>
      <Grid flow="row" gap="24px">
        <Text variant="h3">Total Spent</Text>
        <ChipGroup
          disabled={props.loading}
          onClick={props.onChipGroupClick}
          required
          scrollable
        >
          <Chip>
            <Text>Today</Text>
          </Chip>
          <Chip>
            <Text>Last 7 Days</Text>
          </Chip>
          <Chip>
            <Text>Last 30 Days</Text>
          </Chip>
        </ChipGroup>
        <TotalSpentGraph
          thermostatId={props.thermostatId}
          period={props.period}
        />
        <List bordered>
          {!hide_nest_schedule && (
            <DataListItem label="Cost of Schedule">
              {props.loading ? (
                <CostSheetSkeleton />
              ) : (
                <Sheet color="green-100">
                  <Text className="text-themed-green-900">
                    $
                    <NumberEasing
                      decimals={
                        isDecimal(
                          centsToDollars(
                            positiveClamp(
                              props.items?.cost_of_schedule_cents || 0,
                            ),
                          ),
                        )
                          ? 2
                          : 0
                      }
                      value={centsToDollars(
                        positiveClamp(props.items?.cost_of_schedule_cents || 0),
                      )}
                      speed={400}
                      ease="quintOut"
                    />
                  </Text>
                </Sheet>
              )}
            </DataListItem>
          )}
          <DataListItem label="Cost of Adjustments">
            {props.loading ? (
              <CostSheetSkeleton />
            ) : (
              <CostSheet
                cost={props.items?.cost_of_adjustments_cents || 0}
                size="sm"
              />
            )}
          </DataListItem>
          <DataListItem label="Avg. Set Point">
            {props.loading ? (
              <TemperatureSkeleton />
            ) : (
              <Text>
                <NumberEasing
                  value={positiveClamp(props.items?.average_set_point || 0)}
                  speed={400}
                  ease="quintOut"
                />
                °
              </Text>
            )}
          </DataListItem>
          <DataListItem label="Avg. Outside Temp">
            <Flex item>
              {props.loading ? (
                <TemperatureBarSkeleton />
              ) : (
                <TemperatureBar
                  high={props.items?.avg_high_temp_f ?? 0}
                  low={props.items?.avg_low_temp_f ?? 0}
                  max={props.items?.max_temp_f ?? 0}
                  min={props.items?.min_temp_f ?? 0}
                />
              )}
            </Flex>
          </DataListItem>
        </List>
      </Grid>
    </Card>
  )
}
