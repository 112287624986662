import './styles.scss'
import { Button, Card, CardProps, Icon } from '../../../components'
import { useEffect, useState } from 'react'
import formatClasses from '../../../utils/classes/formatClasses'
import { Identifiable } from '@/types'

export interface Props extends Identifiable<CardProps> {
  delay?: number
  dismiss?: boolean
  onDismiss?: () => void
}

export default function DismissibleCard(props: Props) {
  const [dismissed, setDismissed] = useState(false)
  const classes = {
    card: formatClasses(['dismissible-card']),
    dismissButton: formatClasses(['dismiss-button']),
  }

  // Automatically dismiss the card after a given amount of time when something happens
  useEffect(() => {
    if (!props.dismiss) return

    const timeout = setTimeout(() => {
      if (props.dismiss) setDismissed(true)
    }, props.delay)

    return () => clearTimeout(timeout)
  }, [props.dismiss])

  const onClick = () => {
    setDismissed(true)
    props.onDismiss?.()
  }

  return !dismissed ? (
    <Card className={classes.card}>
      <Button
        className={classes.dismissButton}
        onClick={onClick}
        role="button"
        variant="icon"
        id={`${props.id}-dismiss`}
      >
        <Icon color="grey-900" name="X" size={20} />
      </Button>
      {props.children}
    </Card>
  ) : (
    <></>
  )
}
