// Forces a value to fall between a given lower and upper bound
export default function clamp(min: number, value: number, max: number) {
  if (value < min) return min
  else if (value > max) return max
  else return value
}

export function positiveClamp(value: number, max = Infinity) {
  return clamp(0, value, max)
}
