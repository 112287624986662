import { Text } from '@/components'
import { ChargeForecast, ChargeSchedule } from '@/types'
import {
  getIsCharging,
  getIsPluggedIn,
  getUpcomingDREvent,
  getIsHome,
} from '../utils'
import * as Sentry from '@sentry/browser'
import { selectScheduleForChargeForecast } from '../selectors'
import { useAppSelector } from '@/hooks'
import ChargeReasons from './ChargeReasons'
import ChargeScheduleTime from './ChargeScheduleTime'
import { useState } from 'react'
import DROptOutModal from './DROptOutModal'
import { logEvent } from '@/logging'

type Props = {
  deviceType: 'vehicle' | 'vehicle_charger'
  deviceId: number
  chargeForecast: ChargeForecast
  chargeSchedule: ChargeSchedule
}

export type Mode =
  | 'NoCharge'
  | 'SmartChargeScheduled'
  | 'DREventScheduled'
  | 'CurrentlySmartCharging'
  | 'CurrentlyCharging'

const TitleMap: { [key in Mode]: string | null } = {
  NoCharge: null,
  SmartChargeScheduled: 'Smart charge scheduled',
  DREventScheduled: 'Grid event scheduled',
  CurrentlySmartCharging: 'Currently smart charging',
  CurrentlyCharging: 'Currently Charging',
}

export default function SmartChargeSchedule(props: Props) {
  const {
    mode,
    chargeForecast,
    chargeSchedule,
    deviceType,
    deviceId,
    onDrOptOutClick,
    drOptOutModalOpen,
    onDrOptOutModalClose,
    upcomingDREvent,
  } = useViewModel(props)

  if (mode === 'NoCharge') {
    return null
  }

  return (
    <div className="flex flex-col gap-4 p-5 rounded-xl bg-themed-base-100">
      <div>
        <Text variant="subheader" className="inline mr-2">
          {TitleMap[mode]}
        </Text>
        {mode === 'DREventScheduled' && (
          <Text variant="link" onClick={onDrOptOutClick}>
            Opt out
          </Text>
        )}
      </div>
      <div className="flex justify-between w-full">
        <ChargeScheduleTime mode={mode} chargeForecast={chargeForecast} />
      </div>
      {mode !== 'CurrentlyCharging' && (
        <ChargeReasons
          mode={mode}
          chargeForecast={chargeForecast}
          chargeSchedule={chargeSchedule}
          deviceType={deviceType}
          deviceId={deviceId}
          onProgramEnrollmentClick={onDrOptOutClick}
        />
      )}
      {upcomingDREvent && (
        <DROptOutModal
          open={drOptOutModalOpen}
          onClose={onDrOptOutModalClose}
          drEvent={upcomingDREvent}
        />
      )}
    </div>
  )
}

function useViewModel(props: Props) {
  const vehicles = useAppSelector((state) => state.vehicles)
  const pluggedIn = getIsPluggedIn(props.chargeForecast)
  const isCharging = getIsCharging(props.chargeForecast)
  const isHome = getIsHome(props.chargeForecast)
  const upcomingDREvent = getUpcomingDREvent(props.chargeForecast)
  const allowsOptiwattManagedCharging = useAppSelector((state) =>
    Boolean(
      selectScheduleForChargeForecast(state, props.chargeForecast)
        ?.allow_charging_control,
    ),
  )

  let hasReachedLimit = false
  // If we are a vehicle, we need to send the mode function some additional data
  if (props.deviceType === 'vehicle') {
    const vehicle = vehicles.vehicles?.find(
      (vehicle) => vehicle.id === props.deviceId,
    )
    if (
      vehicle?.battery_level_percent !== undefined &&
      vehicle?.battery_level_percent !== undefined
    ) {
      hasReachedLimit =
        vehicle?.battery_level_percent >=
        props.chargeSchedule.battery_target_max
    }
  }

  // @todo `mode` should be a record with a mode id and the data
  // needed to render the mode so that the we don't have to validate
  // the mode in the view before using data that is always available
  // for a given mode.
  // For example, we check if `upcomingDREvent` is truthy before
  // rendering the DrOptOutModal, when in reality we should be able
  // to just check if were in the DREventScheduled mode.
  const mode = getCurrentMode({
    pluggedIn,
    isCharging,
    isHome,
    upcomingDREvent: Boolean(upcomingDREvent),
    allowsOptiwattManagedCharging,
    hasReachedLimit,
  })

  const [drOptOutModalOpen, setDrOptOutModalOpen] = useState(false)

  const onDrOptOutClick = () => {
    logEvent('chargeforecast_smartschedule_droptout_click')
    setDrOptOutModalOpen(true)
  }
  const onDrOptOutModalClose = () => setDrOptOutModalOpen(false)

  return {
    mode,
    drOptOutModalOpen,
    onDrOptOutClick,
    onDrOptOutModalClose,
    upcomingDREvent,
    ...props,
  }
}

type GetCurrentModeArgs = {
  pluggedIn: boolean
  isCharging: boolean
  isHome: boolean
  upcomingDREvent: boolean
  allowsOptiwattManagedCharging: boolean
  hasReachedLimit: boolean
}

function getCurrentMode({
  pluggedIn,
  isCharging,
  isHome,
  upcomingDREvent,
  allowsOptiwattManagedCharging,
  hasReachedLimit,
}: GetCurrentModeArgs): Mode {
  if (!pluggedIn && isCharging) {
    Sentry.captureException(
      new Error(
        'Unexpected state: isCharging is true but the vehicle is not plugged in',
      ),
    )
    return 'NoCharge'
  }

  if (!pluggedIn) {
    return upcomingDREvent ? 'DREventScheduled' : 'NoCharge'
  }

  if (hasReachedLimit) {
    return 'NoCharge'
  }

  if (isCharging) {
    return allowsOptiwattManagedCharging && isHome
      ? 'CurrentlySmartCharging'
      : 'CurrentlyCharging'
  }

  return allowsOptiwattManagedCharging ? 'SmartChargeScheduled' : 'NoCharge'
}
