import * as admin from '../actions/admin'
import { getApiErrorMessage } from '../utils/errorUtilities'

const initialState = {
  user: null,
  timeLines: null,
  chargeForecast: null,
  isLoading: false,
  errors: {},
  utilityEligibility: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case admin.ELIGIBILITY_ADMIN_SUCCESS:
      return {
        ...state,
        utilityEligibility: action.payload,
        isLoading: false,
        errors: {},
      }
    case admin.PROGRAM_REQUIREMENTS_ADMIN_SUCCESS:
      return {
        ...state,
        programRequirements: action.payload,
        isLoading: false,
        errors: {},
      }
    case admin.CHARGE_FORECAST_ADMIN_SUCCESS:
      return {
        ...state,
        chargeForecast: action.payload,
        isLoading: false,
        errors: {},
      }
    case admin.VEHICLE_TIMELINE_SUCCESS:
      return {
        ...state,
        timeLines: action.payload,
        isLoading: false,
        errors: {},
      }
    case admin.USER_PLAN_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errors: {},
      }
    case admin.ADMIN_USER_INFORMATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload,
        errors: {},
      }
    case admin.ADMIN_USER_CHARGER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        chargers: action.payload,
        errors: {},
      }
    case admin.ADMIN_USER_THERMOSTAT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        thermostats: action.payload,
        errors: {},
      }
    case admin.ADMIN_USER_VEHICLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        vehicles: action.payload,
        errors: {},
      }
    case admin.ELIGIBILITY_ADMIN_REQUEST:
    case admin.PROGRAM_REQUIREMENTS_ADMIN_REQUEST:
    case admin.CHARGE_FORECAST_ADMIN_REQUEST:
    case admin.USER_PLAN_UPDATE_REQUEST:
    case admin.VEHICLE_TIMELINE_REQUEST:
    case admin.ADMIN_USER_VEHICLE_REQUEST:
    case admin.ADMIN_USER_CHARGER_REQUEST:
    case admin.ADMIN_USER_THERMOSTAT_REQUEST:
    case admin.ADMIN_USER_INFORMATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case admin.ELIGIBILITY_ADMIN_FAILURE:
    case admin.PROGRAM_REQUIREMENTS_ADMIN_FAILURE:
    case admin.CHARGE_FORECAST_ADMIN_FAILURE:
    case admin.USER_PLAN_UPDATE_FAILURE:
    case admin.VEHICLE_TIMELINE_FAILURE:
    case admin.ADMIN_USER_VEHICLE_FAILURE:
    case admin.ADMIN_USER_CHARGER_FAILURE:
    case admin.ADMIN_USER_THERMOSTAT_FAILURE:
    case admin.ADMIN_USER_INFORMATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: getApiErrorMessage(action.payload),
      }
    default:
      return state
  }
}

export function errors(state) {
  return state.errors
}
