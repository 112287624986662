import { createSelector } from '@reduxjs/toolkit'

const selectUserCanAndHasntIntegratedUtility = createSelector(
  [
    (state) => !state.user.user?.profile?.latest_utility_integration,
    (state) => state.user.user?.profile?.utility,
  ],
  (hasntIntegrated, utility) =>
    hasntIntegrated &&
    utility !== null &&
    utility?.api_provider_url !== null &&
    utility?.api_provider_active === true,
)

export default selectUserCanAndHasntIntegratedUtility
