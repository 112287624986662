import { ID, Model } from '../model'
import { DateRepresentation } from '../dates'
import { UtilityProgram, UtilityProgramViewConfig } from '../utilityProgram'

export type Group = {
  alias: string
  // When null, the group does not define a forced charge control
  // setting. When defined, the user cannot override the setting
  // because their program requires it.
  allow_charging_control: boolean | null
}

export type ProgramEnrollment = {
  utility_program_id?: ID
  unenrolled_at: DateRepresentation | null
}

export type DeviceEnrollment = Model &
  ProgramEnrollment & {
    id: ID
    ineligible: boolean
    utility_program_enrollment_id: ID
    group?: Group
    // view config is populated from the cms
    view_config_json?: UtilityProgramViewConfig
    error?: boolean
    unenrolled_reasons: unknown
    unenrolled_source: unknown
    active?: boolean
  }

export type VehicleEnrollment = DeviceEnrollment & {
  vehicle_id: ID
}

export type ThermostatEnrollment = DeviceEnrollment & {
  thermostat_id: ID
}

export type ChargerEnrollment = DeviceEnrollment & {
  charger_id: ID
}

export type BehavioralEnrollment = DeviceEnrollment & {
  profile_id: ID
}

export type UtilityProgramEnrollment = Model &
  ProgramEnrollment & {
    utility_program: UtilityProgram
    vehicle_ids?: ID[]
    thermostat_ids?: ID[]
    charger_ids?: ID[]
    vehicle_charger_ids?: ID[]
    vehicle_enrollments: VehicleEnrollment[]
    thermostat_enrollments: ThermostatEnrollment[]
    charger_enrollments: ChargerEnrollment[]
    behavioral_enrollments: BehavioralEnrollment[]
    external_redirect_url?: string
    enroll_behaviorally?: boolean
  }

export function enrollmentIsUnenrolled(enrollment: ProgramEnrollment) {
  return Boolean(enrollment.unenrolled_at)
}
