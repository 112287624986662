import { RootState } from '@/reducers'
import { ChargeForecast } from '@/types'

type NoPlanResponse = {
  type: 'no plan'
}

function isNoPlanResponse(
  response: ChargeForecast | NoPlanResponse,
): response is NoPlanResponse {
  return (response as NoPlanResponse).type === 'no plan'
}

export function selectChargeForecast(state: RootState) {
  if (
    !state.metric.chargeForecast ||
    isNoPlanResponse(state.metric.chargeForecast)
  ) {
    return null
  }

  return state.metric.chargeForecast as ChargeForecast | null
}
