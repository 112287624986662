import { vehicleIneligibilityReasonContent } from '@/types/programs-new/vehicle-requirements'
import { chargerIneligibilityReasonContent } from '@/types/programs-new/charger-requirements'
import { thermostatIneligibilityReasonContent } from '@/types/programs-new/thermostat-requirements'
import { fetchViewConfigFromCMS } from '@/graphql/utilties/fetchViewConfigFromCMS'
import { createResourceCollection } from '@/request'
import {
  ID,
  RequestTypes,
  ResourceMiddlewareFunction,
  ResourceModelQueryResult,
} from '@/request/types'
import { Model } from '@/types/model'
import { ProgramsNew } from '@/types/programs-new/programs-new'
import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@/store'
import { behavioralIneligibilityReasonContent } from '@/types/programs-new/behavioral-requirements'
import { ProfileEligibilityRequirements } from '@/types/programs-new/profile-requirements'
import {
  UtilityProgram,
  UtilityProgramViewConfig,
} from '@/types/utilityProgram'

export type ProgramsNewModel = Model &
  ProgramsNew & {
    id: ID
    view_config_json?: UtilityProgramViewConfig
  }

async function populateViewConfigFromCMSMiddleware<T extends ProgramsNewModel>(
  requestData: { requestType: RequestTypes },
  data: T | T[],
): ReturnType<ResourceMiddlewareFunction<T>> {
  const type = requestData.requestType
  const middlewareInput: T[] = Array.isArray(data) ? data : [data]

  if (
    middlewareInput &&
    (type === RequestTypes.Fetch ||
      type === RequestTypes.Create ||
      type === RequestTypes.Update)
  ) {
    const programsWithViewConfigsPopulated = await Promise.all(
      middlewareInput.map((program) => populateViewConfigFromCMS(program)),
    )

    return programsWithViewConfigsPopulated
  }

  return data
}

async function populateViewConfigFromCMS<T extends ProgramsNewModel>(
  program: T,
) {
  const cmsViewConfig = await fetchViewConfigFromCMS({
    utilityProgramId: program.utility_program.id,
  })

  return {
    ...program,
    view_config_json: cmsViewConfig,
  }
}

export const programsNewCollection = createResourceCollection<ProgramsNewModel>(
  {
    name: 'programsNewCollection',
    apiConfig: {
      path: 'programs/new',
      middlewares: [populateViewConfigFromCMSMiddleware],
    },
    selector: (state) => state.programsNew,
  },
)

const reducer = programsNewCollection.slice.reducer

export default reducer

export const selectAvailablePrograms = () =>
  createSelector(
    (state: RootState) => programsNewCollection.selectors.selectAll(state),
    (programs) => {
      if (programs.length === 0) {
        return [] as UtilityProgram[]
      }
      return programs
        .filter(
          (p) =>
            !p.enrollment_overview &&
            p.device_eligibility_overview.profile.requirements_result.find(
              (r) => r.key === ProfileEligibilityRequirements.RequireLocation,
            )?.is_eligible,
        )
        .map((p) => mapProgramToUtilityProgram(p)) as UtilityProgram[]
    },
  )
export const selectEnrolledPrograms = () =>
  createSelector(
    (state: RootState) => programsNewCollection.selectors.selectAll(state),
    (programs) => {
      if (programs.length === 0) {
        return [] as UtilityProgram[]
      }
      return programs
        .filter((p) => p.enrollment_overview)
        .map((p) => mapProgramToUtilityProgram(p)) as UtilityProgram[]
    },
  )
const mapProgramToUtilityProgram = (
  p: ResourceModelQueryResult<ProgramsNewModel>,
) =>
  ({
    ...p.utility_program,
    total_incentives_usd: 0,
    referral_points: 0,
    verification_status:
      p.enrollment_overview?.program_enrollment?.verification_status,
    view_config_json: p.view_config_json as UtilityProgramViewConfig,
    device_eligibility: {
      eligible_charger_ids: [],
      eligible_vehicle_ids: [],
      eligible_thermostat_ids: [],
      ineligible_charger_ids: [],
      ineligible_thermostat_ids: [],
      ineligible_vehicle_ids: [],
    },
  } satisfies UtilityProgram)

export const selectDeviceEligibilityMessages = (programId: number) =>
  createSelector(
    (state: RootState) => programsNewCollection.selectors.selectAll(state),
    (programs) => {
      if (programs.length === 0) {
        return null
      }
      const devices = programs.find((p) => p.utility_program.id === programId)
        ?.device_eligibility_overview?.devices
      if (!devices) return null
      return {
        vehicles: (devices.vehicles ?? [])
          .filter((d) => d.is_eligible)
          .map((device) => ({
            id: device.id,
            vehicle: device.vehicle,
          })),
        chargers: (devices.chargers ?? [])
          .filter((d) => d.is_eligible)
          .map((device) => ({
            id: device.id,
            charger: device.charger,
          })),
        thermostats: (devices.thermostats ?? [])
          .filter((d) => d.is_eligible)
          .map((device) => ({
            id: device.id,
            thermostat: device.thermostat,
          })),
        behavioral: (devices.behavioral ?? [])
          .filter((b) => b.is_eligible)
          .map((behavior) => ({
            id: behavior.id,
          })),
      }
    },
  )

export type DeviceEligibility = ReturnType<
  ReturnType<typeof selectDeviceEligibilityMessages>
>

export const selectDeviceIneligibilityMessages = (programId: number) =>
  createSelector(
    (state: RootState) => programsNewCollection.selectors.selectAll(state),
    (programs) => {
      if (programs.length === 0) {
        return null
      }
      const devices = programs.find((p) => p.utility_program.id === programId)
        ?.device_eligibility_overview?.devices
      if (!devices) return null
      return {
        vehicles: (devices.vehicles ?? [])
          .filter((d) => !d.is_eligible)
          .map((device) => ({
            id: device.id,
            vehicle: device.vehicle,
            requirements: device.requirements_result
              .filter((r) => !r.is_eligible)
              .map((req) => ({
                key: req.key,
                content: vehicleIneligibilityReasonContent(req.key),
                isEligible: req.is_eligible,
              })),
          })),
        chargers: (devices.chargers ?? [])
          .filter((d) => !d.is_eligible)
          .map((device) => ({
            id: device.id,
            charger: device.charger,
            requirements: device.requirements_result
              .filter((r) => !r.is_eligible)
              .map((req) => ({
                key: req.key,
                content: chargerIneligibilityReasonContent(req.key),
                isEligible: req.is_eligible,
              })),
          })),
        thermostats: (devices.thermostats ?? [])
          .filter((d) => !d.is_eligible)
          .map((device) => ({
            id: device.id,
            thermostat: device.thermostat,
            requirements: device.requirements_result
              .filter((r) => !r.is_eligible)
              .map((req) => ({
                key: req.key,
                content: thermostatIneligibilityReasonContent(req.key),
                isEligible: req.is_eligible,
              })),
          })),
        behavioral: (devices.behavioral ?? [])
          .filter((b) => !b.is_eligible)
          .map((behavior) => ({
            id: behavior.id,
            requirements: behavior.requirements_result.map((req) => ({
              key: req.key,
              content: behavioralIneligibilityReasonContent(req.key),
              isEligible: req.is_eligible,
            })),
          })),
      }
    },
  )

export type DeviceIneligibility = ReturnType<
  ReturnType<typeof selectDeviceIneligibilityMessages>
>
