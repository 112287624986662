export function getQueryParams(qs) {
  qs = qs.split('+').join(' ')

  var params = {},
    tokens,
    re = /[?&]?([^=]+)=([^&]*)/g

  while ((tokens = re.exec(qs))) {
    params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2])
  }

  return params
}

export const serializeQueryParams = (params) =>
  Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&')
