import React from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from '../../../../v2/theme'
import { ViewModelComponentProps } from '../../../../utils/typeUtilities'
import { Props as ComponentProps } from './TeslaFleetRedirect'
import useViewModel from './useViewModel'
import TeslaFleetRedirect from './TeslaFleetRedirect'

type Props = ViewModelComponentProps<ComponentProps, typeof useViewModel>

export default ({ ...props }: Props) => {
  const viewModel = useViewModel()

  return (
    <ThemeProvider theme={theme}>
      <TeslaFleetRedirect {...viewModel} {...props} />
    </ThemeProvider>
  )
}
