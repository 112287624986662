import { UtilityProgram } from '../../../../types/utilityProgram'

export type Props = {
  utilityProgram: UtilityProgram
  logoUrl: string | null
}

export default function useViewModel({ utilityProgram, logoUrl }: Props) {
  const viewConfig = utilityProgram.view_config_json.onboarding

  return {
    title: viewConfig.title,
    subtitle: viewConfig.subtitle,
    informationCards: viewConfig.informationList,
    programLogoUrl: logoUrl,
    programName: viewConfig.programName,
    tosUrl: utilityProgram.tos_url,
    footerContent: viewConfig.footer,
    excludeHeaderAnimation: viewConfig.excludeHeaderAnimation,
    onConnectClick: () => {},
  }
}
