import { useRef } from 'react'
import ResourceSelectionGallery, {
  GalleryRefHandle,
} from '../../../authenticated/components/ResourceSelectionGallery'
import { Home, Thermostat, Vehicle, VehicleCharger } from '@/types'
import { DeviceSelection } from './DeviceSelectionGallery.types'
import { useViewModel } from './useViewModel'

export type Props = {
  vehicles: Vehicle[]
  homes: Home[]
  thermostats: Thermostat[]
  vehicleChargers: VehicleCharger[]
  selectedDevice: DeviceSelection | null
  setSelectedDevice: (device: DeviceSelection) => void
}

export default function DeviceSelectionGallery(props: Props) {
  const viewModel = useViewModel(props)
  const galleryControlsRef = useRef<GalleryRefHandle>(null)

  const resources = [
    ...viewModel.galleryActions,
    ...viewModel.sortedDevices.map((d) => d.resource),
  ]
  const selectedDevice = viewModel.sortedDevices.find(
    (d) => d.id === props.selectedDevice?.id,
  )

  return (
    <>
      <ResourceSelectionGallery
        resources={resources}
        ref={galleryControlsRef}
      />

      {selectedDevice && selectedDevice.alertState.mode !== 'none' && (
        <div className="mt-8">{selectedDevice.alertState.renderBanner()}</div>
      )}
    </>
  )
}
