import { createResourceCollection } from '@/request'
import { ThermostatDemandResponseEvent } from '@/types'
import { ID } from '@/types/model'

export const thermostatDemandResponseEventsCollection =
  createResourceCollection<ThermostatDemandResponseEvent>({
    name: 'thermostatDemandResponseEvents',
    apiConfig: {
      path: 'thermostat_demand_response_events',
    },
    selector: (state) => state.thermostatDemandResponseEvents,
  })

export function takeActionOnThermostatDemandResponseEvent(
  thermostatDemandResponseEventId: ID,
  action: 'opt-in' | 'opt-out',
  { optimistic = false }: { optimistic?: boolean } = {},
) {
  return thermostatDemandResponseEventsCollection.actions.update(
    thermostatDemandResponseEventId,
    {
      enrolled: action === 'opt-in',
      user_opt_choice: action,
    },
    { optimistic },
  )
}

export default thermostatDemandResponseEventsCollection.slice.reducer
