import { RSAA } from 'redux-api-middleware'
import { withAuth } from '../reducers'
import config from '../config'
import { DeletedVehicle } from '../types/deletedVehicle'

export enum Patch {
  Request = '@@deletedVehicles/DELETED_VEHICLES_PATCH_REQUEST',
  Success = '@@deletedVehicles/DELETED_VEHICLES_PATCH_SUCCESS',
  Failure = '@@deletedVehicles/DELETED_VEHICLES_PATCH_FAILURE',
}

function patch(deletedVehicle: Partial<DeletedVehicle>) {
  if (!deletedVehicle.id) {
    throw new Error('deletedVehicle must have an id')
  }
  return {
    [RSAA]: {
      endpoint: `${config.apiGateway.URL}/api/deleted_vehicles/${deletedVehicle.id}/`,
      method: 'PATCH',
      body: JSON.stringify(deletedVehicle),
      headers: withAuth({ 'Content-Type': 'application/json' }),
      types: [Patch.Request, Patch.Success, Patch.Failure],
    },
  }
}

export function justifyDeletion(deletedVehicleId: number, reason: string) {
  return patch({
    id: deletedVehicleId,
    reason: reason,
  })
}
