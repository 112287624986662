import { Token } from './token'

export function tokenIsExpired(token: Token): boolean {
  if (!token.exp) {
    return true
  }

  // If were within 5 seconds of the token expiring, consider it expired
  return 1000 * token.exp - new Date().getTime() < 5000
}
