import { isString } from '../../stringUtilities'

/**
 *
 * @param {T[]} array
 * @param {string | (arrayItem: T) => string} key
 * @returns { { [key: string]: T[] } }
 */
export function groupBy<T>(
  array: T[],
  key: string | ((arrayItem: T) => string),
): { [key: string]: T[] } {
  const keyFunc = isString(key)
    ? (x: T) => String(x[key as keyof T])
    : (key as (arrayItem: T) => string)

  return array.reduce((result: { [key: string]: T[] }, currentValue: T) => {
    const keyValue = keyFunc(currentValue)

    if (result[keyValue] === undefined) {
      result[keyValue] = []
    }

    result[keyValue].push(currentValue)
    return result
  }, {})
}
