import { defaultConfig } from '@/firebase'
import { useFeatureFlag } from '@/hooks/useFeatureFlag'

type Props = {
  flag: keyof typeof defaultConfig
  /**
   * If true, this feature will always be shown for experimental users.
   * If false, the feature should not be treated differently for experimental users.
   */
  experimental?: boolean
  children: React.ReactNode
}

export default function BehindFlag(props: Props) {
  const flag = useFeatureFlag({
    flag: props.flag,
    // if experimental true is passed, we only show the feature if the user is an experimental user
    // if false is passed then the feature should not be treated differently for experimental users.
    // In this case the experimentalValue should not be set, because if we pass false then it will never
    // show up for experimental users
    experimentalValue: props.experimental ? true : undefined,
  })
  if (!flag) {
    return null
  }
  return <>{props.children}</>
}
