import Sdge from '../../images/sdge.svg'
import Sce from '../../images/sce.svg'
import Pge from '../../images/pge.svg'
import ConEd from '../../images/coned.svg'
import FPL from '../../images/flordia-power.svg'
import LADWP from '../../images/los-angeles-power.svg'
import ComEd from '../../images/comed.svg'
import PSEG from '../../images/pseg.svg'
import JCPL from '../../images/jersey-central-power-light.svg'
import APS from '../../images/arizona-public-services.svg'
import PECO from '../../images/peco-energy.svg'
import SMUD from '../../images/smud.svg'
import BGE from '../../images/baltimore-gas-electric.svg'
import PORTGE from '../../images/portland-general-electric.svg'
import DOMINION from '../../images/dominion-energy.svg'
import RMP from '../../images/rocky-mountain-power.svg'
import AUSTIN from '../../images/austin-energy.svg'
import ACE from '../../images/atlantic-city-energy.svg'
import XCEL from '../../images/xcel-energy.svg'
import NVE from '../../images/nv-energy-square.svg'
import PEPCO from '../../images/potomac.svg'
import TEP from '../../images/tucson-electric-power.svg'
import AMEREN from '../../images/ameren.svg'

const UtilityLogos: { [key: string]: string } = {
  'Pacific Gas & Electric Co': Pge,
  'Southern California Edison Co': Sce,
  'San Diego Gas & Electric Co': Sdge,
  ConEdison: ConEd,
  'Los Angeles Department of Water & Power': LADWP,
  'Florida Power & Light Co.': FPL,
  'Public Service Elec & Gas Co': PSEG,
  'Jersey Central Power & Lt Co (New Jersey)': JCPL,
  'Arizona Public Service Co': APS,
  'Consolidated Edison Co-NY Inc': ConEd,
  'PECO Energy Co': PECO,
  PECO: PECO,
  'Sacramento Municipal Utility District': SMUD,
  SMUD: SMUD,
  'Baltimore Gas and Electric': BGE,
  'Baltimore Gas & Electric Co': BGE,
  'Portland General Electric Co': PORTGE,
  'Dominion Energy (Virginia)': DOMINION,
  PSEG: PSEG,
  'Rocky Mountain Power (Utah)': RMP,
  'Rocky Mountain Power (Wyoming)': RMP,
  'Rocky Mountain Power (Idaho)': RMP,
  'Austin Energy': AUSTIN,
  'Atlantic City Electric Co': ACE,
  'Xcel Energy of Colorado': XCEL,
  'Xcel Energy of Minnesota': XCEL,
  'Xcel Energy of North Dakota': XCEL,
  'Xcel Energy of South Dakota': XCEL,
  'Xcel Energy Michigan': XCEL,
  'Xcel Energy New Mexico': XCEL,
  'Xcel Energy North Dakota': XCEL,
  'Xcel Energy South Dakota': XCEL,
  'Xcel Energy Texas': XCEL,
  'Xcel Energy Wisconsin': XCEL,
  'Xcel Energy': XCEL,
  'NV Energy': NVE,
  'Potomac Electric Power Co': PEPCO,
  'Potomac Electric Power Company': PEPCO,
  'The Potomac Edison Co (West Virginia)': PEPCO,
  'Potomac Electric Power Co (Maryland)': PEPCO,
  'The Potomac Edison Co (Maryland)': PEPCO,
  'Commonwealth Edison Co': ComEd,
  ComED: ComEd,
  'Tucson Electric Power Co': TEP,
  'Ameren Illinois Company': AMEREN,
}

import Leap from '../../images/leap.webp'
import UtilityAPI from '../../images/utilityapi.webp'
import { ApiProvider } from '../../../types/utility/'

const ApiProviderLogos: { [key: string]: string } = {
  [ApiProvider.Leap]: Leap,
  [ApiProvider.UtilityApi]: UtilityAPI,
}

export interface Props {
  utilityName: string
  utilityLogoUrl: string
  apiProvider: ApiProvider
}

function useViewModel({ utilityName, utilityLogoUrl, apiProvider }: Props) {
  const utilityLogo = utilityLogoUrl ?? UtilityLogos[utilityName]
  const apiProviderLogo = ApiProviderLogos[apiProvider]
  return { utilityLogo, apiProviderLogo }
}

export default useViewModel
