import { useAppSelector } from '@/hooks'

import { DeviceType } from '@/app/components/DeviceSelectionGallery/DeviceSelectionGallery.types'
import { useParams } from 'react-router-dom'
import { vehicleConnectivityCollection } from '@/reducers/vehicleConnectivity'
import { Vehicle } from '@/types/vehicle'
import { VehicleConnectivity } from '@/types/vehicle/vehicleConnectivity'
import { capitalize } from '@/utils/stringUtilities'
import { vehicleSubscriptions } from './vehicleSubscriptionInformation'
import { getConnectivityConfigStatus } from './utils'

type LoadedData = {
  selectedVehicle: Vehicle
  connectivity: VehicleConnectivity
}

function useComputedViewModelFromLoadedData(data: LoadedData) {
  const make = capitalize(data.selectedVehicle.car.car_model?.make || '')
  const subscription = vehicleSubscriptions[make.toLowerCase()]?.[0]

  const configStatus = getConnectivityConfigStatus(data.connectivity)
  const manufacturerConnectionStatus = data.connectivity.manufacturer_connection
  return {
    status: 'loaded' as const,
    subscription,
    configStatus,
    make,
    vehicle: data.selectedVehicle,
    manufacturerConnectionStatus,
  }
}

type ViewModel =
  | {
      status: 'loading'
    }
  | ({
      status: 'loaded'
    } & ReturnType<typeof useComputedViewModelFromLoadedData>)

export function useViewModel(): ViewModel {
  type PathParams = {
    localGridId: string
    deviceType: DeviceType | undefined
    deviceId: string | undefined
  }
  const { deviceId } = useParams<PathParams>()
  const selectedVehicle = useAppSelector((state) => {
    if (!deviceId) {
      return null
    }
    return state.vehicles.vehicles?.find(
      (vehicle) => vehicle.id === parseInt(deviceId),
    )
  })
  const { data, status } = vehicleConnectivityCollection.useFetch(
    `vehicle/${selectedVehicle?.id}/connectivity`,
  )

  if (!selectedVehicle || status === 'failed' || !data.length) {
    return {
      status: 'loading' as const,
    }
  }

  const viewModel = useComputedViewModelFromLoadedData({
    selectedVehicle,
    connectivity: data[0],
  })

  return viewModel
}
