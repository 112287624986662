import './styles.scss'
import React, { ReactNode, useState } from 'react'
import { ReactNodeProps } from '../../types/reactNodeProps'
import formatClasses from '../../utils/classes/formatClasses'

export interface Props extends ReactNodeProps {
  active?: number
  centered?: boolean
}

export default function TabGroup(props: Props) {
  const classes = {
    tab: (index: number) =>
      formatClasses(['tab', activeTab === index ? 'active' : undefined]),
    tabGroup: formatClasses(['tab-group']),
    tabList: formatClasses([
      'tab-list',
      props.centered ? 'centered' : undefined,
    ]),
    tabPanel: formatClasses(['tab-panel']),
  }

  const [activeTab, setActiveTab] = useState(0)

  return (
    <div className={classes.tabGroup}>
      <ul className={classes.tabList}>
        {React.Children.map<ReactNode, ReactNode>(
          props.children,
          (child, index) => {
            if (React.isValidElement(child)) {
              const title = child.props.title
              if (!title)
                throw new Error(
                  `Expected a value for prop "title", but received ${
                    typeof title === 'string' ? `"${title}"` : title
                  }`,
                )

              return (
                <li
                  className={classes.tab(index)}
                  onClick={() => setActiveTab(index)}
                >
                  {title}
                </li>
              )
            }
          },
        )}
      </ul>
      <div className={classes.tabPanel}>
        {React.Children.map(props.children, (child, index) => {
          if (index === activeTab) {
            return child
          }
        })}
      </div>
    </div>
  )
}
