import { thermostatsCollection } from '../reducers/thermostats'
import { RootState } from '../store'

export default function selectCostHistorySummaryForThermostat(
  state: RootState,
  thermostatId: number,
) {
  return thermostatsCollection.selectors.selectById(state, thermostatId)?.[
    'cost_history/summary'
  ]
}
