import { useFeatureFlag } from '@/hooks/useFeatureFlag'
import useHomeEstimationCompletion from './useHomeEstimationCompletion'

function useShouldShowHomeEstimations() {
  const homeEstimationsFeatureFlag = useFeatureFlag({
    flag: 'show_home_estimations',
    experimentalValue: true, // TODO AVild - probably off?
  })

  const showPrompt = useHomeEstimationCompletion()

  return homeEstimationsFeatureFlag && showPrompt
}

export default useShouldShowHomeEstimations
