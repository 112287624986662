import React from 'react'
import { ReactNodeProps } from '@/types/reactNodeProps'

type Props = {
  size: number
} & Omit<ReactNodeProps, 'children'>

export default function Dot(props: Props) {
  return (
    <span
      className={`${props.className} inline-block rounded-full align-middle`}
      style={{ width: `${props.size}px`, height: `${props.size}px` }}
    />
  )
}
