import {
  RequestTypes,
  ResourceMiddlewareFunction,
  ResourceModel,
} from '@/request/types'
import { ResourceCollection } from '@/request'
import { store } from '@/store'

/**
 * requestTypes: A list of RequestTypes that we listen for to fire off collection invalidations
 * collections: A list of resource collections to invalidate on the given request type
 */
export type CollectionDependencies<T extends ResourceModel> = {
  requestTypes: RequestTypes[]
  collections: ResourceCollection<T>[]
}

/**
 * Middleware used for firing invalidation events for external
 * resource collections when a certain RequestType is fired by
 * the relevant collection.
 *
 * @param dependencies - A list of type CollectionDependencies, dictating
 * the request types and their mapped collections to invalidate.
 */
export function createExternalDependencyMiddleware<
  Resource extends ResourceModel,
>(
  // TODO AVild - Really shouldn't be <any>, but wasted enough time on this (Codi approves)s
  dependencies: CollectionDependencies<any>[],
): ResourceMiddlewareFunction<Resource> {
  return async (requestData, data) => {
    const relevantCollections = dependencies
      .filter((collection) => {
        return collection.requestTypes.includes(requestData.requestType)
      })
      .flatMap((dependency) => dependency.collections)

    for (const collection of relevantCollections) {
      store.dispatch(collection.actions.invalidate())
    }

    return data
  }
}
