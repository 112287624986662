import { AlertMessageLink } from 'app-components'
import { logEvent } from '@/logging'
import useShouldShowHomeEstimations from '../useShouldShowHomeEstimations'

type Props = {
  from: string
}

export default function HomeEstimationUpdateNotification(props: Props) {
  const shouldShowHomeEstimations = useShouldShowHomeEstimations()

  if (!shouldShowHomeEstimations) {
    return null
  }

  return (
    <AlertMessageLink
      variant="info"
      onLinkClick={() => logEvent('Want_to_Improve_Accuracy_Clicked')}
      linkTo={`/home-estimations/${props.from}`}
      linkText="Learn More"
    >
      Want to improve accuracy?
    </AlertMessageLink>
  )
}
