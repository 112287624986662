export const colors = [
  'blue-100',
  'blue-300',
  'blue-400',
  'blue-500',
  'green-100',
  'green-500',
  'green-900',
  'grey-100',
  'grey-200',
  'grey-400',
  'grey-500',
  'grey-900',
  'orange-100',
  'orange-300',
  'orange-400',
  'orange-500',
  'pink-500',
  'purple-100',
  'purple-300',
  'purple-500',
  'red-100',
  'red-500',
  'red-600',
  'yellow-500',
  'base-blue-100',
  'base-blue-300',
  'base-blue-400',
  'base-blue-500',
  'base-green-100',
  'base-green-500',
  'base-green-900',
  'base-grey-100',
  'base-grey-200',
  'base-grey-400',
  'base-grey-500',
  'base-grey-900',
  'base-orange-100',
  'base-orange-300',
  'base-orange-400',
  'base-orange-500',
  'base-pink-500',
  'base-purple-100',
  'base-purple-300',
  'base-purple-500',
  'base-red-100',
  'base-red-500',
  'base-red-600',
  'base-yellow-500',
] as const
export type Color = (typeof colors)[number]

// Converts a color string to the corresponding CSS variable
export default function getColor(color?: Color | string) {
  if (!color) return

  return colors.includes(color as (typeof colors)[number])
    ? `var(--color-${color})`
    : color
}
