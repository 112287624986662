import { Fragment } from 'react'
import { Flex } from '../../../../components'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import twMerge from '@/utils/classes/twMerge'

const useStyles = makeStyles((theme) =>
  createStyles({
    innerContainer: {
      textAlign: 'center',
    },
    logoBackground: {
      background: '#ffffff',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      borderRadius: '8px',
      '&.wide': {
        width: '120px',
        minWidth: '60px',
        height: '60px',
      },
      '&.tall': {
        width: '66px',
        height: '66px',
      },
    },
    logoContainer: {
      width: '100%',
      height: '100%',
      paddingLeft: '16px',
      paddingRight: '16px',
    },
    logoImg: {
      maxHeight: '40px',
      maxWidth: '100%',
      verticalAlign: 'middle',
    },
    connector: {
      width: '26px',
      borderTop: '2px dashed',
      borderBottom: 'none',
    },
  }),
)

export interface Props {
  logos: (string | JSX.Element)[]
  borderClassNames?: string
  logoClassName?: string
  widthOverride?: 'tall' | 'wide'
}

export default function LinkDiagram({
  logos,
  borderClassNames,
  logoClassName,
  widthOverride,
}: Props) {
  const classes = useStyles()

  return (
    <Flex container alignItems="center" justifyContent="center" wrap="nowrap">
      {logos.map((logo, index) => (
        <Fragment key={index}>
          <Flex
            item
            className={clsx(
              classes.logoBackground,
              widthOverride
                ? widthOverride
                : index % 2 !== 0 && index > 0 && index < logos.length - 1
                ? 'tall'
                : 'wide',
            )}
          >
            <Flex
              container
              justifyContent="center"
              alignItems="center"
              className={classes.logoContainer}
            >
              <Flex item className={classes.innerContainer}>
                {typeof logo === 'string' ? (
                  <img
                    className={`${classes.logoImg} ${logoClassName}`}
                    src={logo}
                    alt="optiwatt logo"
                  />
                ) : (
                  logo
                )}
              </Flex>
            </Flex>
          </Flex>
          {index < logos.length - 1 && (
            <Flex item>
              <hr
                className={`${classes.connector} ${twMerge(
                  'border-themed-base-500',
                  borderClassNames,
                )}`}
              />
            </Flex>
          )}
        </Fragment>
      ))}
    </Flex>
  )
}
