import useMockableViewModel from '../../../../hooks/useMockableViewModel'
import { useNavigation } from '@/app/hooks'
import { useOnboardingResponses } from '@/app/hooks/useOnboardingResponses'
import useWhiteLabelProgramId from '@/hooks/whitelabel/useWhiteLabelProgramId'
import useBasePath from '@/hooks/useBasePath'

function useViewModel() {
  const navigation = useNavigation()
  const avaId = useWhiteLabelProgramId()
  const basePath = useBasePath()

  const { hasThermostat, hasCharger, hasVehicle, isLoading } =
    useOnboardingResponses()

  const onAddAnother = () => {
    const prefix = basePath !== 'onboarding' ? `/${basePath}` : ''
    if (hasCharger) {
      navigation.push(`${prefix}/onboarding/connect-device/charger/value-prop`)
    }
    if (hasThermostat) {
      navigation.push(`${prefix}/connect-thermostat?onboarding=true`)
    }
    if (hasVehicle) {
      navigation.push(
        `${prefix}/connect-vehicle?value_prop=false&pre_auth=true&onboarding=true`,
      )
    }
  }

  const onContinue = () => {
    navigation.push(`/ava/program-enrollment/${avaId}/offer`)
  }

  return {
    onAddAnother,
    onContinue,
    isLoading,
    noDevices: !hasThermostat && !hasCharger && !hasVehicle,
    avaId,
  }
}

function useMockViewModel() {
  return {
    onAddAnother: () => alert('Add another device clicked!'),
    onContinue: () => alert('Continue clicked!'),
    isLoading: false,
    noDevices: false,
    avaId: 57,
  }
}

export default useMockableViewModel({ useViewModel, useMockViewModel })
