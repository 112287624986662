import { useLocation } from 'react-router-dom'
import { useProfile } from '@/hooks/useProfileId'
import { useAppDispatch } from '@/hooks'
import { PayloadAction } from '@reduxjs/toolkit'
import {
  avaAddressesCollection,
  AvaVerificationResponse,
} from '@/reducers/avaAddressesCollection'
import { useCallback } from 'react'
import useBasePath from '@/hooks/useBasePath'

export default function useAvaVerification() {
  const profile = useProfile()
  const dispatch = useAppDispatch()

  const isVerified = useCallback(async () => {
    if (!profile || !profile.home) {
      return false
    }

    const avaResponse: PayloadAction<AvaVerificationResponse> = await dispatch(
      avaAddressesCollection.actions.create(
        {
          address: profile.home.formatted_address.trim(),
          profile_id: profile.id,
        },
        { path: `/ava_addresses/${profile.id}` },
      ),
    )

    if (
      avaResponse.type === avaAddressesCollection.actionTypes.create.Failure
    ) {
      return false
    }

    return avaResponse.payload.is_verified
  }, [profile, dispatch])

  return { isVerified }
}
export function useIsAvaBasePath(): boolean {
  const basePath = useBasePath()
  return basePath?.toLowerCase() === 'ava'
}
