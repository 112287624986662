import { NavigationPage } from 'app-components'
import { useParams } from 'react-router-dom'
import { Button } from '@/components'
import TextFormField from '@/components/forms/text-form-field'
import { useForm } from 'react-hook-form'
import useIsMobile from '@/authenticated/hooks/useIsMobile'
import Multiselect, { MultiselectOption } from '@/app/components/Multiselect'
import { useCallback, useMemo, useState } from 'react'
import { useNavigation } from '@/app/hooks'
import SurveyObserver, {
  useSurveyResponder,
} from '@/app/components/SurveyObserver'
import { logEvent } from '@/logging'

const APPROVED_RESPONSES = [
  { text: 'Easy process', value: 'easy_process' },
  { text: 'Perfect fit', value: 'perfect_fit' },
  { text: 'Saved money', value: 'saved_money' },
  { text: 'Clear information', value: 'clear_information' },
  { text: 'Other', value: 'other_approve' },
]

const DISAPPROVED_RESPONSES = [
  { text: 'Increased costs', value: 'increased_costs' },
  { text: 'Poor fit', value: 'perfect_fit' },
  { text: 'Confusing information', value: 'confusing_information' },
  { text: 'Difficult process', value: 'difficult_process' },
  { text: 'Other', value: 'other_disapprove' },
]

export default function HomeComparisonSurveyPage() {
  const [surveyAnswers, setSurveyAnswers] = useState<string[]>([])
  const [otherEnabled, setOtherEnabled] = useState(false)
  const [other, setOther] = useState('')

  const navigate = useNavigation()
  const { register } = useForm()
  const isMobile = useIsMobile()
  const { response } = useParams() as { response: 'approve' | 'disapprove' }
  const { submitResponse } = useSurveyResponder('PlanSwitchApproval')

  const userApproved = response === 'approve'
  const title = userApproved ? 'Awesome' : 'How can we improve?'
  const subtitle = userApproved
    ? "We're glad to here you're satisfied with the GEXA plan. Could you share what you liked most about our recommendation?"
    : "It seems our recommendation didn't fully meet your expectations. Can you help us understand what didn't work?"

  const questions: MultiselectOption[] = useMemo(
    () => (userApproved ? APPROVED_RESPONSES : DISAPPROVED_RESPONSES),
    [userApproved],
  )

  const onChange = useCallback((vals: MultiselectOption[]) => {
    const otherSelected = vals.some(
      (val) => val.value.includes('other') && val.selected,
    )
    setOtherEnabled(otherSelected)
    setSurveyAnswers(vals.filter((val) => val.selected).map((val) => val.value))
  }, [])

  const onOtherChange = (val: { currentTarget: { value: string } }) => {
    setOther(val.currentTarget.value)
  }

  const onSubmit = useCallback(() => {
    const otherText = otherEnabled ? other : undefined
    logEvent('feedbackReasonSubmitted')

    submitResponse({
      response,
      reasons: surveyAnswers,
      other_text: otherText,
    })

    navigate.goBack()
  }, [surveyAnswers, other, otherEnabled, response, submitResponse, navigate])

  return (
    <SurveyObserver observedSurveys={['PlanSwitchApproval']}>
      <NavigationPage
        id="comparison-survey-page"
        title={title}
        subtitle={subtitle}
      >
        <div className="flex flex-col h-full">
          <div className="flex flex-wrap gap-2.5">
            <Multiselect options={questions} onChange={onChange} />
          </div>
          {otherEnabled && (
            <div className="mt-8">
              <TextFormField
                name={'form?'}
                label="Other"
                register={register}
                disabled={!otherEnabled}
                onChange={onOtherChange}
              ></TextFormField>
            </div>
          )}
          <Button
            id="submit-btn"
            variant="primary"
            className={isMobile ? 'mt-auto' : 'mt-12'}
            onClick={onSubmit}
          >
            Submit Feedback
          </Button>
        </div>
      </NavigationPage>
    </SurveyObserver>
  )
}
