import { Vehicle } from '.'

export function vehicleIsNew(vehicle: Vehicle, newWindowMinutes = 30): boolean {
  if (!vehicle.created) {
    return false
  }

  return (
    new Date(vehicle.created) >
    new Date(Date.now() - newWindowMinutes * 60 * 1000)
  )
}
