import { AlertMessage, NavigationPage } from '@/app/components'
import CircleCheckbox from '@/authenticated/components/CircleCheckbox'
import {
  Button,
  CardActionButton,
  CardActions,
  Modal,
  Text,
} from '@/components'
import { Props } from '../UtilityProgramEligibleDeviceEnrollmentFlowStep'
import { Dispatch, SetStateAction, useState } from 'react'
import { AlertTriangle } from 'react-feather'
import { homesCollection } from '@/reducers/homeResource'
import { useAppDispatch } from '@/hooks'

function useViewModel(
  props: Props & { setIsSkipModalOpen: Dispatch<SetStateAction<boolean>> },
) {
  const { isHvacAttested, onHVACAttestationComplete, setIsSkipModalOpen } =
    props

  const home = homesCollection.useFetch()
  const dispatch = useAppDispatch()

  async function onSkip() {
    // Set user_has_electric_hvac to false on the home
    await dispatch(
      homesCollection.actions.update(home?.data?.[0].id, {
        user_has_electric_hvac: false,
      }),
    )
    await dispatch(homesCollection.actions.invalidate())
    onHVACAttestationComplete?.()
  }

  async function onContinue() {
    if (!isHvacAttested) {
      setIsSkipModalOpen(true)
      return
    }

    await dispatch(
      homesCollection.actions.update(home?.data?.[0].id, {
        user_has_electric_hvac: isHvacAttested,
      }),
    )
    // Invalidate and refetch to update the home state
    await dispatch(homesCollection.actions.invalidate())
    onHVACAttestationComplete?.()
  }

  return { onSkip, onContinue }
}

function SkipModal(
  props: Props & {
    open: boolean
    onSkip: () => Promise<void>
    setIsSkipModalOpen: Dispatch<SetStateAction<boolean>>
  },
) {
  const { onSkip, open, setIsSkipModalOpen } = props

  return (
    <Modal
      open={open}
      onClose={() => setIsSkipModalOpen(false)}
      id="hvac-attestation-skip-modal"
    >
      <Modal.Title className="flex items-center gap-2">
        <AlertTriangle className="w-6 h-6 text-themed-yellow-500" />
        Are you sure you want to skip?
      </Modal.Title>
      <Text variant="body4">
        By skipping this step, your thermostat{' '}
        <span className="font-bold">will not</span> be enrolled in the program.
        Only thermostats that control cooling or electric heating qualify.
      </Text>
      <Text variant="body4" className="mt-4">
        You must check the box to confirm your thermostat meets the
        requirements. If you skip or don’t check the box, your thermostat won’t
        be part of the program.
      </Text>

      <div className="flex justify-end gap-2 mt-4">
        <Button
          id="hvac-attestation-skip-modal-go-back-button"
          variant="secondary"
          onClick={() => setIsSkipModalOpen(false)}
        >
          Go Back
        </Button>
        <Button
          id="hvac-attestation-skip-modal-continue-button"
          variant="primary"
          onClick={onSkip}
        >
          Skip Anyways
        </Button>
      </div>
    </Modal>
  )
}

export default function UtilityProgramHVACAttestationNavPage(props: Props) {
  return (
    <NavigationPage
      id="hvac-attestation"
      title="Does your thermostat meet the program requirements?"
    >
      <NavigationPage.SingleCardContent>
        <UtilityProgramHVACAttestation {...props} />
      </NavigationPage.SingleCardContent>
    </NavigationPage>
  )
}

export function UtilityProgramHVACAttestation(props: Props) {
  const { isHvacAttested, setIsHvacAttested } = props
  const [isSkipModalOpen, setIsSkipModalOpen] = useState(false)
  const { onSkip, onContinue } = useViewModel({
    ...props,
    setIsSkipModalOpen: setIsSkipModalOpen,
  })

  return (
    <>
      <SkipModal
        open={isSkipModalOpen}
        onSkip={onSkip}
        setIsSkipModalOpen={setIsSkipModalOpen}
      />
      <AlertMessage variant="warning">
        <Text variant="body1" className="text-base-grey-900">
          Review requirements before continuing
        </Text>
      </AlertMessage>

      <div className="mt-6 mb-4 space-y-6">
        <div className="flex items-start">
          <div className="flex items-center justify-center flex-shrink-0 w-8 h-8 mr-3 text-sm rounded-md text-themed-blue-500 bg-themed-blue-100">
            1
          </div>
          <div className="flex flex-col space-y-2">
            <Text variant="body1" className="text-base-grey-900">
              Your thermostat controls cooling or electric heating.
            </Text>
            <Text variant="body4">
              You may also have a gas furnace, as long as your system includes
              AC.
            </Text>
          </div>
        </div>
        <div className="flex items-start">
          <div className="flex items-center justify-center flex-shrink-0 w-8 h-8 mr-3 text-sm rounded-md bg-themed-blue-100 text-themed-blue-500">
            2
          </div>
          <div className="flex flex-col space-y-2">
            <Text variant="body1" className="text-base-grey-900">
              You don't have a gas furnace only
            </Text>
            <Text variant="body4">
              If your system only has a gas furnace, it cannot be enrolled in
              the program.
            </Text>
          </div>
        </div>
      </div>

      <CardActions>
        <div className="w-full">
          <div className="flex items-start gap-4 p-4 mb-8 border border-solid rounded-md border-base-grey-100">
            <CircleCheckbox
              checked={isHvacAttested}
              onChange={() => setIsHvacAttested?.(!isHvacAttested)}
              activeColor="#00D73F"
              style={{ padding: '0px' }}
            />
            <Text variant="body4">
              By checking this box, you confirm you meet the requirements listed
              above
            </Text>
          </div>
          <div className="flex w-full gap-2">
            <CardActionButton
              id="hvac-attestation-skip-button"
              type="secondary"
              onClick={() => setIsSkipModalOpen(true)}
            >
              Skip
            </CardActionButton>
            <CardActionButton
              id="hvac-attestation-continue-button"
              type="primary"
              onClick={onContinue}
            >
              Continue
            </CardActionButton>
          </div>
        </div>
      </CardActions>
    </>
  )
}
