import { ChangeEvent, useState } from 'react'
import { LoadingAnimation, Flex } from '../../../../components'
import { Select, InputLabel, FormControl } from '@material-ui/core'
import { Plan } from '../../../../types/plan'

export interface Props {
  planOptions: Plan[]
  isLoading: boolean
  label?: string
  // pass the props below to use as a controlled component
  selectedPlanId?: string
  onChange?: (planId: number) => void
}

function UtilityPlanDropdown({
  planOptions,
  isLoading,
  label = 'Select a plan',
  selectedPlanId,
  onChange,
}: Props) {
  const [internalSelectedPlanId, setInternalSelectedPlanId] = useState(
    selectedPlanId || '',
  )

  function handleChange(event: ChangeEvent<any>) {
    const planId = event.target.value
    setInternalSelectedPlanId(planId)
    onChange?.(planId)
  }

  function getName(plan: Plan) {
    if (plan.is_default) {
      return '[Most Common] ' + plan.name
    } else {
      return plan.name
    }
  }

  if (isLoading) {
    return (
      <Flex container justifyContent="center">
        <Flex item>
          <LoadingAnimation
            type="flashing"
            color="purple-500"
            size="medium"
            noBackground
          />
        </Flex>
      </Flex>
    )
  }

  return (
    <FormControl required fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        native
        required
        label={label}
        value={selectedPlanId ?? internalSelectedPlanId}
        onChange={handleChange}
      >
        <option value="" />
        {planOptions.map((plan: Plan) => (
          <option key={plan.id} value={plan.id}>
            {getName(plan)}
          </option>
        ))}
        ;
      </Select>
    </FormControl>
  )
}

export default UtilityPlanDropdown
