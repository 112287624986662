import dayjs from 'dayjs'

export function getTimeFromTimestamp(timestamp) {
  let d = new Date(timestamp), // Convert the passed timestamp to milliseconds
    // yyyy = d.getFullYear(),
    // mm = ('0' + (d.getMonth() + 1)).slice(-2),	// Months are zero based. Add leading 0.
    // dd = ('0' + d.getDate()).slice(-2),			// Add leading 0.
    hh = d.getHours(),
    h = hh,
    min = ('0' + d.getMinutes()).slice(-2), // Add leading 0.
    ampm = 'AM',
    time

  if (hh > 12) {
    h = hh - 12
    ampm = 'PM'
  } else if (hh === 12) {
    h = 12
    ampm = 'PM'
  } else if (hh === 0) {
    h = 12
  }

  // Full format
  // time = yyyy + '-' + mm + '-' + dd + ', ' + h + ':' + min + ' ' + ampm;
  time = h + ':' + min + ' ' + ampm
  return time
}

export function getHoursBetweenDates(d1, d2) {
  // 36e5 is the scientific notation for 60*60*1000
  return Math.abs(d1 - d2) / 36e5
}

export function getDateNow() {
  return dayjs().format('YYYY-MM-DD')
}

export function getDateFromTimestamp(timestamp, format = 'MMM D, YYYY') {
  return dayjs(timestamp).format(format)
  // let dateFormat = require('dateformat');
  // let d = new Date(timestamp);
  // return dateFormat(d, "mmm d, yyyy");
}

export function addSeconds(date, seconds) {
  date.setTime(date.getTime() + seconds * 1000)
  return date
}

export function addMinutes(date, minutes) {
  return addSeconds(date, minutes * 60)
}

export function addHours(date, hours) {
  return addMinutes(date, hours * 60)
}

export function addDays(date, days) {
  return addHours(date, days * 24)
}

export function subtractSeconds(date, seconds) {
  let copiedDate = new Date(date.getTime())
  copiedDate.setTime(date.getTime() - seconds * 1000)
  return copiedDate
}

export function subtractMinutes(date, minutes) {
  return subtractSeconds(date, minutes * 60)
}

export function subtractHours(date, hours) {
  return subtractMinutes(date, hours * 60)
}

export function subtractDays(date, days) {
  return subtractHours(date, days * 24)
}

export function getDifferenceMinutes(dt2, dt1) {
  let diff = (dt2.getTime() - dt1.getTime()) / 1000
  diff /= 60
  return Math.abs(Math.round(diff))
}

export function getCurrentYear() {
  let d = new Date()
  return d.getFullYear()
}

export function convertTime24to12(time24) {
  let tmpArr = time24.split(':'),
    time12
  if (+tmpArr[0] === 12) {
    time12 = tmpArr[0] + ':' + tmpArr[1] + ' pm'
  } else {
    if (+tmpArr[0] === 0) {
      time12 = '12:' + tmpArr[1] + ' am'
    } else {
      if (+tmpArr[0] > 12) {
        time12 = +tmpArr[0] - 12 + ':' + tmpArr[1] + ' pm'
      } else {
        time12 = +tmpArr[0] + ':' + tmpArr[1] + ' am'
      }
    }
  }
  return time12
}

export function getEvenHourFromTime(inputTime) {
  const timeArray = inputTime.split(':')
  if (timeArray.length === 2) {
    return `${timeArray[0]}:00`
  }
  return inputTime
}
