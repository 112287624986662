import { ConfigurableButton } from '@/app/components'
import { useEffect } from 'react'
import createStore from '@/hooks/useStore'
import twMerge from '@/utils/classes/twMerge'

type TabContentProps = {
  /** unique key for the tab - pairs the TabBar with the TabContent */
  tabKey: string
  /** content for the tabs */
  children: React.ReactElement | React.ReactElement[]
}

type TabBarProps = {
  /** unique key for the tab - pairs the TabBar with the TabContent */
  tabKey: string
  /** labels for each tab */
  labels?: string[]
  /** index of the tab to show first */
  initialTabIndex?: number
  /** additional attributes for the tab bar */
  tabBarAttributes?: React.HTMLAttributes<HTMLDivElement>
  /** custom tab component */
  Tab?: (props?: TabProps) => React.JSX.Element
  /** callback function when tab changes */
  onTabChange?: (index: number) => void
  /** indexes of tabs that should be disabled */
  disabledTabIndexes?: number[]
}

type TabProps = {
  /** whether the tab is active */
  isActive: boolean
  /** index of the tab */
  index: number
  /** label of the tab */
  label?: string
}

const tabStoreBase = {
  activeTabIndex: -1,
  tabContentCount: 0,
}

const tabGlobalStore = createStore('tabGlobalStore')

const setActiveTabIndex = (key: string, index: number) => {
  tabGlobalStore.setStore<typeof tabStoreBase>(key, (data) => {
    if (!data) {
      return { activeTabIndex: 0, tabContentCount: 0 }
    }
    data.activeTabIndex = index
    return { ...data }
  })
}

const TabBarHighlighter = (props: {
  tabCount: number
  activeTabIndex: number
}) => {
  const animationCSS = 'transition-all duration-250 ease-out'
  const barLeft = `${animationCSS} flex h-full`
  const barRight = `${animationCSS} flex h-full min-w-0`
  const highlight = `${animationCSS} flex h-full min-w-0 grow bg-themed-primary rounded-full opacity-10`
  return (
    <div className="flex z-[-1] p-1 absolute w-full h-full top-0 left-0 rounded-full overflow-clip">
      <div className={barLeft} style={{ flexGrow: props.activeTabIndex }} />
      <div id="highlighter" className={highlight} />
      <div
        className={barRight}
        style={{
          flexGrow: props.tabCount - (props.activeTabIndex + 1),
        }}
      />
    </div>
  )
}

const DefaultTab = ({ isActive, index, label }: TabProps) => {
  return (
    <div>
      <div
        className={`text-link font-bold ${
          isActive
            ? 'text-themed-primary'
            : 'text-themed-base-900 hover:text-themed-primary'
        }`}
      >
        {label || `Tab ${index + 1}`}
      </div>
    </div>
  )
}

const defaultProps = {
  tabBarAttributes: {} as React.HTMLAttributes<HTMLDivElement>,
}

const TabBar = (props: TabBarProps) => {
  const defaults = { ...defaultProps, ...props }
  const {
    tabBarAttributes,
    tabKey,
    initialTabIndex,
    disabledTabIndexes = [],
  } = defaults
  const { className, ...rest } = tabBarAttributes
  const tabStore = tabGlobalStore.useStore(tabKey, tabStoreBase)
  const { activeTabIndex, tabContentCount } = tabStore.data

  const handleTabClick = (index: number) => {
    if (disabledTabIndexes.includes(index)) return
    setActiveTabIndex(tabKey, index)

    if (index !== activeTabIndex) {
      props.onTabChange?.(index)
    }
  }

  useEffect(() => {
    if (initialTabIndex !== undefined) {
      setActiveTabIndex(
        tabKey,
        Math.min(Math.max(initialTabIndex, 0), tabContentCount - 1),
      )
    }
  }, [initialTabIndex, tabContentCount])

  const childrenArray = Array(tabContentCount ?? 0).fill(null)
  if (tabContentCount === undefined || activeTabIndex === -1) {
    return null
  }

  return (
    <div
      className={twMerge(
        'relative z-0 flex flex-row w-full h-12 max-w-md rounded-full bg-themed-white shadow-optiwatt py-2 px-4 justify-around',
        className,
      )}
      {...rest}
    >
      <TabBarHighlighter
        tabCount={tabContentCount ?? 0}
        activeTabIndex={activeTabIndex}
      />
      {childrenArray.map((_, index) => {
        const isDisabled = disabledTabIndexes.includes(index)
        return props?.Tab ? (
          <ConfigurableButton
            variant="click-wrapper"
            id={`tab-${index}`}
            key={`tab-${index}`}
            className={`duration-300 w-full h-full justify-center items-center bg-transparent border-none cursor-pointer focus:outline-none hover:text-themed-primary text-themed-base-900 ${
              isDisabled ? 'cursor-not-allowed opacity-50' : ''
            }`}
            onClick={() => handleTabClick(index)}
            type="button"
            disabled={isDisabled}
          >
            <props.Tab
              index={index}
              key={index}
              isActive={activeTabIndex === index}
              label={props.labels?.[index]}
            />
          </ConfigurableButton>
        ) : (
          <ConfigurableButton
            variant="click-wrapper"
            id={`tab-${index}`}
            key={`tab-${index}`}
            className={`duration-1000 w-full h-full justify-center items-center bg-transparent border-none cursor-pointer focus:outline-none hover:text-themed-primary text-themed-base-900 ${
              isDisabled ? 'cursor-not-allowed opacity-50' : ''
            }`}
            onClick={() => handleTabClick(index)}
            type="button"
            disabled={isDisabled}
          >
            <DefaultTab
              index={index}
              key={index}
              isActive={activeTabIndex === index}
              label={props.labels?.[index]}
            />
          </ConfigurableButton>
        )
      })}
    </div>
  )
}

const TabContent = ({ children, tabKey }: TabContentProps) => {
  const tabStore = tabGlobalStore.useStore(tabKey, tabStoreBase)
  const { activeTabIndex } = tabStore.data
  children = Array.isArray(children) ? children : [children]
  useEffect(() => {
    tabStore.set((data) => {
      if (!data) {
        return { activeTabIndex: 0, tabContentCount: children?.length ?? 0 }
      }

      data.tabContentCount = children.length
      return { ...data }
    })
  }, [children.length])
  return children[activeTabIndex ?? 0]
}

export { TabBar, TabContent, setActiveTabIndex }
export type { TabBarProps, TabContentProps }
