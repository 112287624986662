import { submitContactForm } from '@/actions/form'
import { useAppDispatch } from '@/hooks'
import { FormEvent, useState } from 'react'

function convertFormToObject(formData: FormData) {
  const object: Record<string, unknown> = {}
  formData.forEach((value, key) => (object[key] = value))
  return object
}

export default function ContactForm() {
  const dispatch = useAppDispatch()

  const [contactSubmitted, setContactSubmitted] = useState(false)
  const onContactSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const formData = new FormData(event.target as HTMLFormElement)
    const formObject = convertFormToObject(formData)
    dispatch(submitContactForm(formObject))
    setContactSubmitted(true)
  }

  return (
    <form
      id="contact-form"
      className="needs-validation"
      onSubmit={onContactSubmit}
    >
      <input
        type="text"
        id="message"
        name="message"
        placeholder="Type your question..."
      />
      <input
        type="text"
        id="email"
        name="email"
        placeholder="Enter your email"
        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
        required
      />
      <div className="invalid-feedback">Please provide a valid email.</div>
      <div className="faq-form-btn-wrap">
        <p>We usually will reply in 48 hours</p>
        <button
          type="submit"
          className="faq-submit-btn prim-btn"
          disabled={contactSubmitted}
        >
          {contactSubmitted ? 'Thanks' : 'Submit'}
        </button>
      </div>
    </form>
  )
}
