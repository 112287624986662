import { Text } from '@/components'
import React from 'react'

export function OrderedListContent(props: { title: string; subtitle: string }) {
  return (
    <div className="flex flex-col">
      {props.title && <Text variant="h3">{props.title}</Text>}
      {props.subtitle && <Text variant="body4">{props.subtitle}</Text>}
    </div>
  )
}

export default function OrderedListItem(props: {
  index?: number
  title?: string
  subtitle?: string
  children: React.ReactNode
}) {
  return (
    <li className="flex flex-row gap-4 items-start">
      <div className="bg-themed-purple-200 align-items-center justify-around rounded-[10px] py-1.5 px-3">
        <Text variant="h3" className="text-themed-primary">
          {props.index}
        </Text>
      </div>
      {props.children}
    </li>
  )
}

export function OrderedList(props: { children: React.ReactNode }) {
  return (
    <ol className="flex flex-col gap-7">
      {React.Children.map(props.children, (child, index) =>
        React.cloneElement(child as React.ReactElement, { index: index + 1 }),
      )}
    </ol>
  )
}
