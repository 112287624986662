import { AlertMessageLink } from '@/app/components'
import HomeEstimationUpdateNotification from '@/app/components/HomeEstimations/Notification/HomeEstimationUpdateNotificiation'
import useShouldShowHomeEstimations from '@/app/components/HomeEstimations/useShouldShowHomeEstimations'
import { ID } from '@/types/model'

function DefaultHomeSectionNotification() {
  return (
    <AlertMessageLink
      variant="info"
      linkTo={`/connect-device`}
      linkText="Connect Devices"
    >
      Want to see more home insights?
    </AlertMessageLink>
  )
}

export default function HomeSectionNotification(props: { homeId: ID }) {
  const shouldShowHomeEstimations = useShouldShowHomeEstimations()

  if (shouldShowHomeEstimations) {
    return (
      <HomeEstimationUpdateNotification from={`app/home/${props.homeId}`} />
    )
  }

  return <DefaultHomeSectionNotification />
}
