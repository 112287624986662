import React, { useState } from 'react'
import { DateRepresentation } from '../../../types/dates'
import DateRangeModal from '../DateRangeModal'
import SelectFilter from './SelectFilter'
import dayjs from 'dayjs'

export type DateRange = {
  start: DateRepresentation | null
  end: DateRepresentation | null
}

export interface Props {
  disabled?: boolean
  handleSelectedDateRange: (dateRange: DateRange) => void
}

export default function DateSelectFilter({
  disabled,
  handleSelectedDateRange,
}: Props) {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const closeDateModal = (
    start: DateRepresentation | null,
    end: DateRepresentation | null,
  ) => {
    handleSelectedDateRange({ start, end })
    setModalIsOpen(false)
  }

  return (
    <>
      <SelectFilter
        defaultId="All Time"
        options={[
          {
            id: 'Today',
            label: 'Today',
            value: { start: dayjs().startOf('day').format(), end: null },
          },
          {
            id: 'This Month',
            label: 'This Month',
            value: { start: dayjs().startOf('month').format(), end: null },
          },
          {
            id: 'Last 7 Days',
            label: 'Last 7 Days',
            value: { start: dayjs().subtract(7, 'days').format(), end: null },
          },
          {
            id: 'Last 30 Days',
            label: 'Last 30 Days',
            value: { start: dayjs().subtract(30, 'days').format(), end: null },
          },
          {
            id: 'All Time',
            label: 'All Time',
            value: { start: null, end: null },
          },
          {
            id: 'Custom',
            label: 'Custom',
            value: () => {
              setModalIsOpen(true)
            },
          },
        ]}
        onSelectedValue={handleSelectedDateRange}
        disabled={disabled}
      />
      <DateRangeModal open={modalIsOpen} closeModal={closeDateModal} />
    </>
  )
}
