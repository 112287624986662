import React, { ReactNode } from 'react'
import Divider, { DividerProps } from '.'

type DivideProps = DividerProps & {
  children: ReactNode
  when?: boolean
  excludeFirst?: boolean
  excludeLast?: boolean
}

export default function Divide({
  children,
  when,
  excludeFirst,
  excludeLast,
  ...dividerProps
}: DivideProps) {
  const shouldShowDividers = when === undefined ? true : when

  if (!shouldShowDividers) {
    return <>{children}</>
  }

  const childrenArray = React.Children.toArray(children)

  if (childrenArray.length === 0) {
    return null
  }

  const childrenWithHR = childrenArray.reduce<ReactNode[]>(
    (accumulator, child, index) => {
      accumulator.push(child)
      if (
        index < childrenArray.length - 1 &&
        // dont add first hr if excludeFirst is true
        !(index === 0 && excludeFirst) &&
        // dont add last hr if excludeLast is true
        !(index === childrenArray.length - 1 && excludeLast)
      ) {
        accumulator.push(
          <Divider
            orientation="horizontal"
            key={`hr-${index}`}
            {...dividerProps}
          />,
        )
      }
      return accumulator
    },
    [],
  )

  return <>{childrenWithHR}</>
}
