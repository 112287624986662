import React, { useState } from 'react'
import {
  passwordResetComplete,
  passwordResetErrors,
  passwordResetLoading,
} from '../../../../reducers'
import { resetPassword } from '../../../../actions/auth'
import { useAppDispatch, useAppSelector } from '@/hooks'
import Header from '../../Header/HeaderV2'
import { AnimatePresence, motion } from 'framer-motion'
import { Button, FormInputWithError } from '@/components'
import { Link } from '@mui/material'
import './styles.scss'

const ResetPassword = () => {
  const dispatch = useAppDispatch()
  const [user, setUser] = useState({ username: '' })
  const [triedSubmit, setTriedSubmit] = useState(false)

  const errors = useAppSelector(passwordResetErrors)
  const loading = useAppSelector(passwordResetLoading)
  const resetComplete = useAppSelector(passwordResetComplete)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setTriedSubmit(true)

    const { username } = user
    if (username && username.includes('@')) {
      dispatch(resetPassword(username))
    }
  }

  const LoginLink = (
    <p className="text-[#0A1C53] text-[16px] md:text-[20px] mb-0 font-medium">
      Remember your password?{' '}
      <Link style={{ color: '#426FFB', fontWeight: 700 }} href="/login">
        Login
      </Link>
    </p>
  )

  const { username } = user
  const validErrors: { detail?: string[] } = errors ?? {}
  const validLoading = typeof loading !== 'undefined' && loading

  const usernameInvalid = triedSubmit && (!username || !username.includes('@'))
  let resetInvalid = false
  if (
    validErrors.detail?.includes(
      "We couldn't find an account associated with that email. Please try a different e-mail address.",
    )
  ) {
    delete validErrors.detail
    resetInvalid = true
  }

  return (
    <div className="reset-root flex flex-col items-center bg-[#EEF2FB] min-h-[100vh]">
      <div className="flex flex-col justify-center w-[90%] max-w-5xl min-h-[100vh]">
        <div
          className="flex flex-wrap md:flex-nowrap justify-between pt-8 pb-6 px-5 md:px-12 md:pt-12 md:pb-8 my-3
          bg-white rounded-[20px] shadow-[0_20px_52px_0_rgba(1,50,107,0.16)]"
        >
          <div className="flex flex-col justify-between basis-full md:basis-[45%] mb-8 md:mb-0">
            <div>
              <Header className="mb-4" />
              <h1 className="text-themed-primary font-bold md:font-extrabold leading-10 md:leading-[3.625rem] mb-2 md:mb-0">
                Password Reset
              </h1>
              <p className="text-[#0a1c53] font-medium text-[16px] md:text-[20px] leading-5 md:leading-7 m-1">
                Enter your email to get a password reset link.
              </p>
            </div>
            <img
              src="img/signup-image.webp"
              alt="Charging Vehicle"
              width={311}
              className="self-center hidden md:block my-auto mt-6"
            />
          </div>
          <div className="flex flex-col justify-between basis-full md:basis-[47%] md:ml-2">
            <AnimatePresence>
              {validErrors?.detail && (
                <motion.div
                  initial={{ height: 0 }}
                  animate={{ height: 'auto' }}
                  exit={{ height: 0 }}
                  className="overflow-hidden text-themed-error bg-themed-error/20 p-3 pl-4 mb-6 font-semibold rounded-md max-sm:text-sm"
                >
                  {validErrors.detail}
                </motion.div>
              )}
            </AnimatePresence>
            <form action="post" noValidate onSubmit={handleSubmit}>
              <FormInputWithError
                type="email"
                name="username"
                placeholder="Email"
                data-testing-id="password-reset-email"
                onChange={handleChange}
                value={username}
                error={usernameInvalid || resetInvalid}
                errorMessage={
                  resetInvalid
                    ? 'This email is not associated with an existing Optiwatt account. Please try again.'
                    : 'Please enter a valid email address.'
                }
              />
              <Button
                className="!bg-themed-primary text-[18px] font-semibold py-3 px-5 rounded-lg w-[100%] font-[montserrat] mt-3"
                id="optiwatt-password-reset-button"
                variant="primary"
                type="submit"
                disabled={validLoading || resetComplete}
              >
                {resetComplete
                  ? 'Email Sent'
                  : validLoading
                  ? 'Sending Email'
                  : 'Reset Password'}
              </Button>
            </form>
            <div>
              <div className="hidden mt-6 md:block">{LoginLink}</div>
            </div>
          </div>
        </div>
        <div className="mb-4 self-start ml-[6%] md:hidden">{LoginLink}</div>
      </div>
    </div>
  )
}

export default ResetPassword
