import { useLayoutEffect } from 'react'

export function useUnsetBodyOverflow(originalOverflow: string) {
  useLayoutEffect(() => {
    document.body.style.overflow = ''
    return () => {
      document.body.style.overflow = originalOverflow
    }
  }, [])
}
