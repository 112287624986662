import {
  AlertMessage,
  ConfigurableText,
  NavigationPage,
} from '@/app/components'
import Page404 from '@/authenticated/pages/Page404'
import { Button, CardAction, CardActions } from '@/components'
import TextFormField from '@/components/forms/text-form-field'
import { logEvent } from '@/logging'
import { logException } from '@/logging/sentry'
import { fetchAuthenticated } from '@/utils/fetch'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'

type EmailOwnerPageProps = {
  vehicleId: string
}

type FormValues = {
  email: string
}

const getEmailOwnerRequest = (vehicleId: string) => ({
  path: `/request_owner_pair_virtual_key/${vehicleId}`,
  method: 'POST',
})

const formSchema = yup.object({
  email: yup.string().email().required().label('Recipient email'),
})

async function sendEmailOwnerRequest(
  email: string,
  vehicleId: string
): Promise<boolean> {
  const body = {
    to_email: email,
  }
  try {
    const emailRequest = getEmailOwnerRequest(vehicleId)
    const res = await fetchAuthenticated(emailRequest.path, {
      method: emailRequest.method,
      body: JSON.stringify(body),
    })

    if (res.status !== 200) {
      return false
    }
  } catch (e) {
    logException(e)
    return false
  }

  logEvent('non_owner_requested_pair_email')

  return true
}

function useViewModel(props: EmailOwnerPageProps) {
  const { handleSubmit, register, watch, formState } = useForm<FormValues>({
    resolver: yupResolver(formSchema),
  })
  const inputtedEmail = watch('email')
  const formError = formState.errors.email
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [submitError, setSubmitError] = useState<string | undefined>()
  const [submitSuccess, setSubmitSuccess] = useState(false)

  const onSubmit = handleSubmit(async ({ email }: FormValues) => {
    setIsSubmitLoading(true)
    const ok = await sendEmailOwnerRequest(email, props.vehicleId)
    setIsSubmitLoading(false)

    if (!ok) {
      setSubmitError(
        'There was an error sending the email. Try again and contact support if your problem persists.',
      )
      setSubmitSuccess(false)
    } else {
      setSubmitError(undefined)
      setSubmitSuccess(true)
    }
  })

  return {
    register,
    formError,
    submitError,
    submitSuccess,
    inputtedEmail,
    onSubmit,
    isSubmitLoading,
  }
}

function EmailOwnerPage(props: EmailOwnerPageProps) {
  const {
    register,
    formError,
    submitError,
    submitSuccess,
    inputtedEmail,
    onSubmit,
    isSubmitLoading,
  } = useViewModel(props)

  return (
    <NavigationPage id="email-owner-page" title="Email Owner">
      <NavigationPage.SingleCardContent>
        <ConfigurableText variant="body4" className="mb-8">
          We'll send an email to the owner with instructions on how to share
          permissions to activate Optiwatt.
        </ConfigurableText>

        <TextFormField
          label="Recipient's email"
          register={register}
          name="email"
        />
        <div className="mt-3">
          <AlertMessage variant="error" show={Boolean(formError)}>
            {formError?.message}
          </AlertMessage>
          <AlertMessage
            variant={submitError ? 'error' : 'success'}
            show={!formError && (Boolean(submitError) || submitSuccess)}
          >
            {submitSuccess ? `Email sent to ${inputtedEmail}` : submitError}
          </AlertMessage>
        </div>
        <CardActions>
          <CardAction type="primary">
            <Button
              id="send-email-to-owner-button"
              variant="primary"
              onClick={onSubmit}
              disabled={isSubmitLoading || submitSuccess}
            >
              {submitSuccess ? 'Email sent' : 'Send email'}
            </Button>
          </CardAction>
        </CardActions>
      </NavigationPage.SingleCardContent>
    </NavigationPage>
  )
}

export default function EmailOwnerPageGuard() {
  const { vehicleId } = useParams<{ vehicleId: string | undefined }>()
  if (!vehicleId) {
    return <Page404 />
  }
  return <EmailOwnerPage vehicleId={vehicleId} />
}
