import { AlertMessage } from '@/app/components'
import { DualThumbRangeSlider, Grid, GridItem, Text } from '@/components'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { thermostatComfortSettingsCollection } from '@/reducers/thermostatComfortSettings'
import { Thermostat, ThermostatComfortSetting } from '@/types'
import { ID } from '@/types/model'
import { debounce } from '@/utils/limit'
import { useCallback, useState } from 'react'

type Props = {
  comfortSettings: ThermostatComfortSetting[] // Now expecting a single comfortSetting object
  thermostatId: Thermostat['id']
  onChange?: (comfortSettings: ThermostatComfortSetting[]) => void // Adjusted to expect a single comfortSetting object
}

export default function SettingDeltaSlideEditor(props: Props) {
  const dispatch = useAppDispatch()
  const comfortSettingsUpdateError = useAppSelector((state) =>
    thermostatComfortSettingsCollection.selectors.queryState
      .select(state, {
        method: 'PATCH',
      })
      .some((queryState) => Boolean(queryState.error)),
  )
  const [resetKey, setResetKey] = useState(false)

  const debouncedUpdateComfortSettings = useCallback(
    debounce(
      async (
        settingIds: ID[],
        updatedSettting: Partial<ThermostatComfortSetting>,
      ) => {
        const promise = await dispatch(
          thermostatComfortSettingsCollection.actions.updateMany(
            `/thermostats/${props.thermostatId}/comfort_settings/bulk_update/`,
            settingIds,
            updatedSettting,
          ),
        )
        if (promise.type.includes('UPDATE_FAILURE')) {
          setResetKey((prev) => !prev)
        }

        props.onChange?.(props.comfortSettings) // Adjusted to work with a single comfortSetting object
      },
      200,
    ),
    [props.thermostatId, props.comfortSettings], // Added props.comfortSetting as a dependency
  )

  const onComfortSettingSliderChangeHandler = useCallback(
    (value: number, side: 'left' | 'right') => {
      const absValue = Math.abs(value)
      const updatedSettting =
        side === 'left'
          ? {
              event_heat_setpoint_delta: absValue,
            }
          : {
              event_cool_setpoint_delta: absValue,
            }
      debouncedUpdateComfortSettings(
        props.comfortSettings.map((s) => s.id),
        updatedSettting,
      )
    },
    [props.comfortSettings, debouncedUpdateComfortSettings], // Adjusted to directly use props.comfortSetting
  )

  if (!props.comfortSettings.length) {
    return (
      <Grid flow="row" gap="32px">
        <AlertMessage variant="error" show>
          There was an issue fetching your comfort settings. Please try again
          later.
        </AlertMessage>
      </Grid>
    )
  }

  return (
    <>
      <Grid flow="row" gap="32px">
        <AlertMessage
          variant="error"
          show={Boolean(comfortSettingsUpdateError)}
        >
          There was an issue updating your comfort setting. Please try again
          later.
        </AlertMessage>
        <Text variant="body2">
          Drag the slider to adjust your acceptable temperature range during
          future events
        </Text>
        <GridItem>
          <DualThumbRangeSlider
            key={Number(resetKey)}
            startingValues={[0, 0]}
            valueBounds={[-15, 15]}
            defaultOffsets={[
              props.comfortSettings[0].event_heat_setpoint_delta ?? 1,
              props.comfortSettings[0].event_cool_setpoint_delta ?? 1,
            ]}
            onChange={onComfortSettingSliderChangeHandler}
            minimumOffset={1}
          />
        </GridItem>
      </Grid>
      <DualThumbRangeSlider.Legend
        className="mt-[24px]"
        startingRangeLabel="Your Current Schedule"
        offsetsLabel="Your Event Settings"
      />
    </>
  )
}
