import styled from 'styled-components'

import { CardMedia } from '@material-ui/core'
import dayjs from 'dayjs'

const CraterOne = styled.div`
  position: absolute;
  list-style: none;
  background: #737277;
  border-radius: 50%;
  left: 10px;
  top: 25px;
  width: 18px;
  height: 18px;
  box-shadow: inset 2px -0.5px 0 0px #414043;
`

const CraterTwo = styled.div`
  position: absolute;
  list-style: none;
  background: #737277;
  border-radius: 50%;
  left: 54px;
  top: 20px;
  width: 12px;
  height: 12px;
  box-shadow: inset 1px -0.25px 0 0px #414043;
`

const CraterThree = styled.div`
  position: absolute;
  list-style: none;
  background: #737277;
  border-radius: 50%;
  left: 45px;
  top: 55px;
  width: 10px;
  height: 10px;
  box-shadow: inset 1px -0.25px 0 0px #414043;
`

const CraterFour = styled.div`
  position: absolute;
  list-style: none;
  background: #737277;
  border-radius: 50%;
  left: 19px;
  top: 56px;
  width: 6px;
  height: 6px;
  box-shadow: inset 1.2px -0.4px 0 0px #414043;
`

const CraterFive = styled.div`
  position: absolute;
  list-style: none;
  background: #737277;
  border-radius: 50%;
  left: 28px;
  top: 8px;
  width: 6px;
  height: 6px;
  box-shadow: inset 1.2px -0.4px 0 0px #414043;
`

const CraterSix = styled.div`
  position: absolute;
  list-style: none;
  background: #737277;
  border-radius: 50%;
  left: 43px;
  top: 35px;
  width: 6px;
  height: 6px;
  box-shadow: inset 1.2px -0.4px 0 0px #414043;
`

const Moon = styled.div`
  position: absolute;
  width: 75px;
  height: 75px;
  background: #c7cbd0;
  border-radius: 50%;
  box-shadow: inset -9px 0px 0 0px #9098a1;
`

const PumpkinHat = styled.div`
  position: absolute;
  background: green;
  height: 75px;
  left: 33px;
  margin-bottom: 30px;
  width: 15px;
`
const Pumpkin = styled.div`
  border-radius: 40% / 50%;
  border: solid 2px #cc7000;
  background: darkorange;
  height: 65px;
  width: 80px;
  position: absolute;

  &:before {
    border-radius: 40% / 50%;
    border: solid 2px #cc7000;
    background: darkorange;
    content: '';
    position: absolute;
    display: block;
    height: 62px;
    width: 60px;
    left: 8.8px;
  }

  &:after {
    border-radius: 40% / 50%;
    border: solid 2px #cc7000;
    background: darkorange;
    content: '';
    position: absolute;
    display: block;
    height: 62px;
    width: 30px;
    left: 24px;
  }
`

const Image = styled(CardMedia)`
  position: absolute;
  width: 150px;
  height: 150px;

  animation: infinite-spinning 2s infinite;
  animation-timing-function: linear;

  @keyframes infinite-spinning {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`

const Root = styled.div`
  justify-content: center;
  align-items: center;
  align: center;
  display: flex;
  min-height: 100%;
`

function PageLoader() {
  const today = dayjs().format()
  const halloween = dayjs().set('month', 9).set('date', 31).format()

  // Day of week of September 1st (1 = Sunday, 7 = Saturday)
  const septemberFirst = dayjs().set('month', 8).set('date', 1).day() + 1
  // Nov 29 - day of September 1st = Thanksgiving day
  const thanksgiving = dayjs()
    .set('month', 10)
    .set('date', 29)
    .subtract(septemberFirst, 'days')
    .format()

  if (today === halloween) {
    return (
      <Root>
        <Image>
          <PumpkinHat />
          <Pumpkin />
        </Image>
      </Root>
    )
  } else if (today === thanksgiving) {
    return (
      <Root>
        <Image image="/dashboard/img/turkey_spinner.webp" />
      </Root>
    )
  }

  return (
    <Root>
      <Image image="/dashboard/img/roadster_orbit.webp" />
      <Moon>
        <ul>
          <CraterOne></CraterOne>
          <CraterTwo></CraterTwo>
          <CraterThree></CraterThree>
          <CraterFour></CraterFour>
          <CraterFive></CraterFive>
          <CraterSix></CraterSix>
        </ul>
      </Moon>
    </Root>
  )
}

export default PageLoader
