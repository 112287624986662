import { ResourceRequestState, RequestState } from '../reducers/utils'

export function shouldPopulateResource<ResourceType>({
  resource,
  isLoading,
  errors,
}: ResourceRequestState<ResourceType>) {
  return !resource && !isLoading && !errors
}

export function shouldRefreshResource({ isLoading, errors }: RequestState) {
  return !isLoading && !errors
}
