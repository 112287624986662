import React from 'react'
import theme from '../../../v2/theme'
import { ThemeProvider } from '@material-ui/core/styles'
import GasSavingsDialog, { Props } from './GasSavingsDialog'

export default function ThemedGasSavingsDialog(props: Props) {
  return (
    <ThemeProvider theme={theme}>
      <GasSavingsDialog {...props} />
    </ThemeProvider>
  )
}
