import config from '../config'
export const UNSUBSCRIBE_REQUEST = '@@units/UNSUBSCRIBE_REQUEST'
export const UNSUBSCRIBE_SUCCESS = '@@units/UNSUBSCRIBE_SUCCESS'
export const UNSUBSCRIBE_FAILURE = '@@units/UNSUBSCRIBE_FAILURE'

export const unsubscribe = (token) => {
  return (dispatch) => {
    return fetch(`${config.apiGateway.URL}/api/unsubscribe/${token}/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((_res) => dispatch({ type: UNSUBSCRIBE_SUCCESS }))
      .catch((_err) =>
        dispatch({
          type: UNSUBSCRIBE_FAILURE,
          meta: { log: 'unsubscribe_error', authenticated: false },
        }),
      )
  }
}
