// To see list of smart car makes: https://smartcar.com/docs/api/?version=v1.0&language=Javascript#makes

import { remoteConfig } from '@/firebase'
import { useAppSelector } from '@/hooks'

export function useGetMakes() {
  const fromPartnerOnly = remoteConfig?.getBoolean(
    'back_soon_from_partner_only',
  )
  const backSoonBrands = remoteConfig?.getArray('back_soon_brands') ?? []
  const isFromPartner = useAppSelector((state) => state.auth.fromToken)
  Object.keys(makes).forEach((key) => {
    const isInBackSoonMakes = backSoonBrands.includes(makes[key].smartcarMake)
    makes[key].backSoon = fromPartnerOnly
      ? isInBackSoonMakes && isFromPartner
      : isInBackSoonMakes
  })
  return makes
}

export const makes = {
  Tesla: {
    name: 'Tesla',
    logo: '/dashboard/img/brands/tesla.webp',
    smartcarMake: 'TESLA',
  },
  Acura: {
    name: 'Acura',
    logo: '/dashboard/img/brands/acura.svg',
    smartcarMake: 'ACURA',
    unsupported: false,
  },
  'Alfa Romeo': {
    name: 'Alfa Romeo',
    logo: '/dashboard/img/brands/alfa_romeo.webp',
    smartcarMake: 'ALFA_ROMEO',
    unsupported: false,
  },
  Audi: {
    name: 'Audi',
    logo: '/dashboard/img/brands/audi.svg',
    smartcarMake: 'AUDI',
  },
  Bentley: {
    name: 'Bentley',
    logo: '/dashboard/img/brands/bentley.svg',
    unsupported: true,
  },
  BMW: {
    name: 'BMW',
    logo: '/dashboard/img/brands/bmw.svg',
    smartcarMake: 'BMW',
  },
  Cadillac: {
    name: 'Cadillac',
    logo: '/dashboard/img/brands/cadillac.svg',
    smartcarMake: 'CADILLAC',
  },
  Chevrolet: {
    name: 'Chevrolet',
    logo: '/dashboard/img/brands/chevrolet.svg',
    smartcarMake: 'CHEVROLET',
  },
  Chrysler: {
    name: 'Chrysler',
    logo: '/dashboard/img/brands/chrysler.svg',
    smartcarMake: 'CHRYSLER',
  },
  Dodge: {
    name: 'Dodge',
    logo: '/dashboard/img/brands/dodge.svg',
    smartcarMake: 'DODGE',
    unsupported: false,
  },
  Ferrari: {
    name: 'Ferrari',
    logo: '/dashboard/img/brands/ferrari.svg',
    unsupported: true,
  },
  Fiat: {
    name: 'Fiat',
    logo: '/dashboard/img/brands/fiat.svg',
    smartcarMake: 'FIAT',
    unsupported: false,
  },
  Ford: {
    name: 'Ford',
    logo: '/dashboard/img/brands/ford.svg',
    smartcarMake: 'FORD',
  },
  Genesis: {
    name: 'Genesis',
    logo: '/dashboard/img/brands/genesis.svg',
    unsupported: true,
  },
  GMC: {
    name: 'GMC',
    logo: '/dashboard/img/brands/gmc.svg',
    smartcarMake: 'GMC',
    unsupported: false,
  },
  Honda: {
    name: 'Honda',
    logo: '/dashboard/img/brands/honda.svg',
    smartcarMake: 'HONDA',
    unsupported: false,
  },
  Hyundai: {
    name: 'Hyundai',
    logo: '/dashboard/img/brands/hyundai.svg',
    smartcarMake: 'HYUNDAI',
  },
  Jaguar: {
    name: 'Jaguar',
    logo: '/dashboard/img/brands/jaguar.svg',
    smartcarMake: 'JAGUAR',
  },
  Jeep: {
    name: 'Jeep',
    logo: '/dashboard/img/brands/jeep.svg',
    smartcarMake: 'JEEP',
  },
  Kandi: {
    name: 'Kandi',
    logo: '/dashboard/img/brands/kandi.jpg',
    unsupported: true,
  },
  Kia: {
    name: 'Kia',
    logo: '/dashboard/img/brands/kia.svg',
    smartcarMake: 'KIA',
  },
  'Land Rover': {
    name: 'Land Rover',
    logo: '/dashboard/img/brands/land_rover.svg',
    smartcarMake: 'LAND_ROVER',
  },
  Lexus: {
    name: 'Lexus',
    logo: '/dashboard/img/brands/lexus.svg',
    smartcarMake: 'LEXUS',
  },
  Lincoln: {
    name: 'Lincoln',
    logo: '/dashboard/img/brands/lincoln.svg',
    smartcarMake: 'LINCOLN',
  },
  Lucid: {
    name: 'Lucid',
    logo: '/dashboard/img/brands/lucid.svg',
    unsupported: true,
  },
  Maserati: {
    name: 'Maserati',
    logo: '/dashboard/img/brands/maserati.svg',
    unsupported: true,
  },
  Mazda: {
    name: 'Mazda',
    logo: '/dashboard/img/brands/mazda.svg',
    smartcarMake: 'MAZDA',
    unsupported: false,
  },
  'McLaren Automotive': {
    name: 'McLaren Automotive',
    logo: '/dashboard/img/brands/mclaren_automotive.svg',
    unsupported: true,
  },
  'Mercedes-Benz': {
    name: 'Mercedes-Benz',
    logo: '/dashboard/img/brands/mercedes_benz.svg',
    smartcarMake: 'MERCEDES-BENZ',
    unsupported: false,
  },
  Mini: {
    name: 'Mini',
    logo: '/dashboard/img/brands/mini.svg',
    smartcarMake: 'MINI',
  },
  Mitsubishi: {
    name: 'Mitsubishi',
    logo: '/dashboard/img/brands/mitsubishi.svg',
    unsupported: true,
  },
  Nissan: {
    name: 'Nissan',
    logo: '/dashboard/img/brands/nissan.svg',
    smartcarMake: 'NISSAN',
  },
  Polestar: {
    name: 'Polestar',
    logo: '/dashboard/img/brands/polestar.svg',
    unsupported: true,
  },
  Porsche: {
    name: 'Porsche',
    logo: '/dashboard/img/brands/porsche.svg',
    smartcarMake: 'PORSCHE',
  },
  Ram: {
    name: 'Ram',
    logo: '/dashboard/img/brands/ram.png',
    smartcarMake: 'RAM',
  },
  Rivian: {
    name: 'Rivian',
    logo: '/dashboard/img/brands/rivian.svg',
    smartcarMake: 'RIVIAN',
  },
  'Rolls-Royce': {
    name: 'Rolls-Royce',
    logo: '/dashboard/img/brands/rolls_royce.svg',
    unsupported: true,
  },
  smart: {
    name: 'smart',
    logo: '/dashboard/img/brands/smart.svg',
    unsupported: true,
  },
  Subaru: {
    name: 'Subaru',
    logo: '/dashboard/img/brands/subaru.webp',
    smartcarMake: 'SUBARU',
    unsupported: false,
  },
  Toyota: {
    name: 'Toyota',
    logo: '/dashboard/img/brands/toyota.svg',
    smartcarMake: 'TOYOTA',
  },
  Vinfast: {
    name: 'Vinfast',
    logo: '/dashboard/img/brands/vinfast.svg',
    unsupported: true,
  },
  Volkswagen: {
    name: 'Volkswagen',
    logo: '/dashboard/img/brands/volkswagen.svg',
    smartcarMake: 'VOLKSWAGEN',
  },
  Volvo: {
    name: 'Volvo',
    logo: '/dashboard/img/brands/volvo.svg',
    smartcarMake: 'VOLVO',
  },
}
