import Icon from '../Icon'
import { IconNames } from '../Icon/Icon'
import Text from '../Text'
import twMerge from '@/utils/classes/twMerge'

type BaseProps = {
  /**
   * Must be a valid color with from the tailwind config with scale variants. For example, 'purple' or 'blue'
   */
  color?: string
  style?: React.CSSProperties
  className?: string
}

type BoxedIconProps = BaseProps & {
  variant: 'icon'
  iconName: IconNames
}

type BoxedCharacterProps = BaseProps & {
  variant: 'character'
  character: string
}

type Props = BoxedIconProps | BoxedCharacterProps

const defaultProps = {
  color: 'purple',
}

export default function BoxedSymbol(inProps: Props) {
  const props = { ...defaultProps, ...inProps }

  const tailwindBackgroundColor = `bg-optiwatt-${props.color}-100`
  const color = `${props.color}-500`
  const tailwindColor = `text-optiwatt-${color}`

  return (
    <div
      style={props.style}
      className={twMerge(
        `${tailwindBackgroundColor} rounded-[10px] grid items-center justify-center w-8 h-8`,
        props.className,
      )}
    >
      {props.variant === 'icon' ? (
        <Icon name={props.iconName} color={color} size={20} />
      ) : (
        <Text variant="body2" className={`${tailwindColor}`}>
          {props.character}
        </Text>
      )}
    </div>
  )
}
