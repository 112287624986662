import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

export default function OdometerDialog(props) {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="odometer-dialog"
    >
      <DialogTitle id="odometer-dialog">
        What is the odometer value?
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ marginBottom: '15px' }}>
          {`This is the currently reported EV odometer in your preferred units (${
            props.distanceUnit ?? 'from your home location'
          }).`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
