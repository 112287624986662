import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAppSelector, useThermostatEnrollment } from '@/hooks'
import { selectUtilityProgramEnrollmentForUser } from '@/reducers/utilityProgramEnrollments'
import { useBrandedThermostatName } from '@/hooks/useBrandedThermostatName'
import { thermostatComfortSettingsCollection } from '@/reducers/thermostatComfortSettings'
import { thermostatsCollection } from '@/reducers/thermostats'
import { RequestStatus } from '@/request/types'
import { homesCollection } from '@/reducers/homeResource'
import { useShouldShowHVACAttestation } from '@/app/features/utility-programs/UtilityProgramEligibleDeviceEnrollmentFlowStep/useViewModel'

export default function useViewModel() {
  const [showHVACAttestation, setShowHVACAttestation] = useState(false)
  const [isHvacAttested, setIsHvacAttested] = useState(false)
  const { deviceId } = useParams<{ deviceId: string | undefined }>()
  const { data: home, status: homeStatus } = homesCollection.useFetch()
  const utilityEnrollment = useAppSelector(
    selectUtilityProgramEnrollmentForUser,
  )

  const thermostatId = parseInt(deviceId ?? '')
  const { data: thermostats, status: thermostatStatus } =
    thermostatsCollection.useFetch(thermostatId, {
      require: !isNaN(thermostatId),
    })

  const thermostat = thermostats?.find(
    (thermostat) => thermostat.id === thermostatId,
  )
  const isAttested = home?.[0]?.user_has_electric_hvac

  const { shouldShow: shouldShowHVACAttestation } =
    useShouldShowHVACAttestation(
      [
        {
          id: thermostat?.id ?? 0,
          name: thermostat?.name ?? 'Thermostat',
          description: thermostat?.portal ?? '',
        },
      ],
      [],
    )

  const brandedThermostatName = useBrandedThermostatName(thermostatId)
  const thermostatEnrollment = useThermostatEnrollment(thermostatId)
  const [isEnrolledLocal, setIsEnrolledLocal] = useState(
    !thermostatEnrollment || thermostatEnrollment?.unenrolled_at ? false : true,
  )

  const {
    data: comfortSettings,
    status: comfortSettingsStatus,
    error: comfortSettingsError,
  } = thermostatComfortSettingsCollection.useFetch(
    `/thermostats/${thermostatId}/comfort_settings/`,
    {
      require: !isNaN(thermostatId),
    },
  )

  const dataLoading = [thermostatStatus, comfortSettingsStatus].some(
    (status) =>
      status === RequestStatus.Loading || status === RequestStatus.Idle,
  )

  const handleEnrollToggle = (checked: boolean, localHasAttested = false) => {
    // We need local since the isAttested state is not updated immediately
    // since onHVACAttestationComplete is run syncronously so the home collection
    // has not been refetched yet.
    if (!isAttested && !localHasAttested && shouldShowHVACAttestation) {
      setShowHVACAttestation(checked)
    } else {
      setIsEnrolledLocal(checked)
    }
  }

  const updateHVACAttestation = async () => {
    setShowHVACAttestation(false)
    setIsEnrolledLocal(true)
  }

  return {
    showHVACAttestation,
    setShowHVACAttestation,
    deviceId,
    thermostatId,
    isAttested,
    isHvacAttested,
    setIsHvacAttested,
    brandedThermostatName,
    isEnrolledLocal,
    comfortSettings,
    comfortSettingsError,
    dataLoading,
    handleEnrollToggle,
    updateHVACAttestation,
    pageTitle:
      utilityEnrollment?.utility_program?.view_config_json?.scheduler?.title ??
      'Utility Program Enrollment',
  }
}
