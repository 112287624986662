import React from 'react'

function _RoundedRect(
  {
    x,
    y,
    width,
    height,
    borderRadiusRight = 0,
    borderRadiusLeft = 0,
    ...restProps
  },
  ref,
) {
  if ([width, height].includes(0)) {
    return (
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        ref={ref}
        {...restProps}
      />
    )
  }
  const pathString = `
    M${x + borderRadiusLeft},${y}
    h${width - (borderRadiusRight + borderRadiusLeft)}
    a${borderRadiusRight},${borderRadiusRight} 0 0 1 ${borderRadiusRight},${borderRadiusRight}
    v${height - borderRadiusRight * 2}
    a${borderRadiusRight},${borderRadiusRight} 0 0 1 ${-borderRadiusRight},${borderRadiusRight}
    h${-width + (borderRadiusRight + borderRadiusLeft)}
    a${borderRadiusLeft},${borderRadiusLeft} 0 0 1 ${-borderRadiusLeft},${-borderRadiusLeft}
    v${-height + borderRadiusLeft * 2}
    a${borderRadiusLeft},${borderRadiusLeft} 0 0 1 ${borderRadiusLeft},${-borderRadiusLeft}`
  return <path d={pathString} ref={ref} {...restProps} />
}

const RoundedRect = React.forwardRef(_RoundedRect)

export { RoundedRect }
