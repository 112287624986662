import React from 'react'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { useAppSelector } from '../../../hooks'
import { Dialog, Typography as MuiTypography } from '@material-ui/core'
import DialogTitleCloseable from '../DialogTitleCloseable'
import GasSavingsDialogContent from './GasSavingsDialogContent'
import { Vehicle } from '../../../types/vehicle/'
import Loader from '../layout/Loader'

const Typography = styled(MuiTypography)(spacing)

const useStyles = makeStyles((theme) =>
  createStyles({
    dialog: {
      margin: theme.spacing(4),
    },
  }),
)

export interface Props {
  open: boolean
  onSubmit?: () => void
  onClose?: () => void
  onSubmitPressed?: () => void
  initialVehicleId: number
}

export default function GasSavingsDialog({
  open,
  onSubmit,
  onSubmitPressed,
  onClose = () => {},
  initialVehicleId,
}: Props) {
  const classes = useStyles()
  const initialVehicle = useAppSelector((state) =>
    state.vehicles.vehicles?.find((v: Vehicle) => v.id === initialVehicleId),
  )

  if (!initialVehicle) {
    return <Loader />
  }

  return (
    <Dialog
      classes={{ root: classes.dialog }}
      onClose={onClose}
      maxWidth={'xs'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      open={open}
    >
      <DialogTitleCloseable onClose={onClose}>
        <Typography variant="h4">Gas Savings</Typography>
      </DialogTitleCloseable>
      {!initialVehicle ? (
        <Loader />
      ) : (
        <GasSavingsDialogContent
          onSubmit={onSubmit}
          onSubmitPressed={onSubmitPressed}
          onClose={onClose}
          initialVehicle={initialVehicle}
        />
      )}
    </Dialog>
  )
}
