import { ID } from '@/types/model'
import ThermostatDREventAlertMessage from './ThermostatDREventAlertMessage'
import ThermostatDREventDetailsModal from './ThermostatDREventDetailsModal'
import {
  useAppSelector,
  useMockableViewModel,
  useUrlSearchParamFlagControls,
} from '@/hooks'
import { selectNextWithThermostatId } from '@/selectors/thermostatDemandResponseEvents'
import { thermostatDemandResponseEventsCollection } from '@/reducers/thermostatDemandResponseEvents'
import { AlertMessage } from '@/app/components'

type Props = {
  thermostatId: ID
}

const OPEN_OPT_OUT_MODAL_QUERY_PARAM =
  'thermostat-opt-out-dr-event-details-modal-open'
const OPEN_OPT_IN_STATUS_MODAL_QUERY_PARAM =
  'thermostat-opt-in-status-dr-event-details-modal-open'

function useDREventModalControls() {
  const optOutModalFlagControls = useUrlSearchParamFlagControls(
    OPEN_OPT_OUT_MODAL_QUERY_PARAM,
  )
  const closeOptOutModal = () => optOutModalFlagControls.setFlag(false)
  const optInStatusModalFlagControls = useUrlSearchParamFlagControls(
    OPEN_OPT_IN_STATUS_MODAL_QUERY_PARAM,
  )
  const closeOptInStatusModal = () =>
    optInStatusModalFlagControls.setFlag(false)

  return {
    optOutModalFlagControls,
    closeOptOutModal,
    optInStatusModalFlagControls,
    closeOptInStatusModal,
  }
}

function useLiveViewModel(props: Props) {
  const {
    optOutModalFlagControls,
    closeOptOutModal,
    optInStatusModalFlagControls,
    closeOptInStatusModal,
  } = useDREventModalControls()

  const eventPresent = useAppSelector((state) =>
    Boolean(selectNextWithThermostatId(state, props.thermostatId)),
  )
  const errorTakingEventAction = useAppSelector((state) =>
    thermostatDemandResponseEventsCollection.selectors.queryState
      .select(state, {
        method: 'PATCH',
      })
      .some((queryState) => Boolean(queryState.error)),
  )
  return {
    optOutModalFlagControls,
    closeOptOutModal,
    optInStatusModalFlagControls,
    closeOptInStatusModal,
    eventPresent,
    errorTakingEventAction,
    thermostatId: props.thermostatId,
  }
}

function ThermostatDREventAlertAndDetailsView({
  optOutModalFlagControls,
  closeOptOutModal,
  optInStatusModalFlagControls,
  closeOptInStatusModal,
  eventPresent,
  errorTakingEventAction,
  thermostatId,
}: ReturnType<typeof useViewModel>) {
  if (errorTakingEventAction) {
    return (
      <AlertMessage variant="error">
        There was an error updating your event participation. Please refresh the
        app and try again or contact support if the issue persists.
      </AlertMessage>
    )
  }
  if (!eventPresent) {
    return null
  }
  return (
    <div>
      <ThermostatDREventAlertMessage
        thermostatId={thermostatId}
        linkToOptOut={optOutModalFlagControls.onLocation}
        linkToOptIn={optInStatusModalFlagControls.onLocation}
      />
      <ThermostatDREventDetailsModal
        variant="opt-out"
        thermostatId={thermostatId}
        open={optOutModalFlagControls.isOn}
        close={closeOptOutModal}
      />
      <ThermostatDREventDetailsModal
        variant="opt-in-status"
        thermostatId={thermostatId}
        open={optInStatusModalFlagControls.isOn}
        close={closeOptInStatusModal}
      />
    </div>
  )
}

function useMockViewModel(props: Props) {
  const {
    optOutModalFlagControls,
    closeOptOutModal,
    optInStatusModalFlagControls,
    closeOptInStatusModal,
  } = useDREventModalControls()
  return {
    optOutModalFlagControls,
    closeOptOutModal,
    optInStatusModalFlagControls,
    closeOptInStatusModal,
    eventPresent: true,
    errorTakingEventAction: false,
    thermostatId: props.thermostatId,
  }
}

const useViewModel = useMockableViewModel({
  useViewModel: useLiveViewModel,
  useMockViewModel,
})

export default function ThermostatDREventAlertAndDetails(props: Props) {
  return <ThermostatDREventAlertAndDetailsView {...useViewModel(props)} />
}
