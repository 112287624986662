import BrandedPage from '@/app/pages/ava/BrandedPage'
import { Button, Text } from '@/components'
import { useViewModel } from './useViewModel'
import Check from '@/app/img/check.svg?react'

export default function UtilityProgramEnrollmentSuccess() {
  const {
    onFinish,
    askForMobileNotificationPermission,
    shouldAskForNotificationPermission,
  } = useViewModel()
  return (
    <BrandedPage id={'enrollment-success'} navigationOptions={['nothing']}>
      <BrandedPage.Content>
        <div className="flex flex-col items-center justify-content-center">
          <Check />
          <Text variant="h3" className="mt-6 text-center max-w-[250px]">
            Great! You've successfully enrolled
          </Text>
        </div>
        <BrandedPage.ButtonContainer>
          {shouldAskForNotificationPermission && (
            <Button
              id="ask-for-notification-permission"
              onClick={askForMobileNotificationPermission}
            >
              Enable Notifications
            </Button>
          )}
          {!shouldAskForNotificationPermission && (
            <Button id="finish-button" onClick={onFinish}>
              Finish
            </Button>
          )}
        </BrandedPage.ButtonContainer>
      </BrandedPage.Content>
    </BrandedPage>
  )
}
