import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setGridSupportConfirmationPending } from '../../../actions/utilities'
import styled from 'styled-components'
import {
  Grid as MuiGrid,
  makeStyles,
  Typography as MuiTypography,
  Button,
} from '@material-ui/core'
import GridSupportSelectDialog from '../GridSupport/GridSupportSelectDialog'
import { spacing } from '@material-ui/system'
import clsx from 'clsx'

const Grid = styled(MuiGrid)(spacing)
const Typography = styled(MuiTypography)(spacing)

const useStyles = makeStyles((theme) => ({
  text: {
    textAlign: 'left',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 14,
  },
  subtitle: {
    fontSize: 14,
    marginTop: '2px',
  },
  buttonContainer: {
    textAlign: 'center',
  },
  connectButton: {
    height: '58px',
    width: '100%',
    borderRadius: '20px',
  },
  textContainer: {
    padding: '0px 6px',
  },
}))

const ConnectUtilitySection = ({ title, subtitle, buttonText, onSubmit }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const gridSupportConfirmationPending = useSelector(
    (state) => state.utilities.gridSupportConfirmationPending,
  )

  const [open, setOpen] = useState(false)

  const onGridSupportClose = () => {
    setOpen(false)
    dispatch(setGridSupportConfirmationPending(false))
  }
  const openGridSupport = () => {
    setOpen(true)
  }
  const onGridSupportSubmit = ({ grid_support_enabled }) => {
    onSubmit(grid_support_enabled)
  }

  return (
    <Grid container alignItems="center" className={classes.textContainer}>
      <Grid item pt={1}>
        <Typography variant="h6" className={clsx(classes.text, classes.title)}>
          {title}
        </Typography>
      </Grid>
      <Grid item pt={1}>
        <Typography
          variant="body1"
          className={clsx(classes.text, classes.subtitle)}
        >
          {subtitle}
        </Typography>
      </Grid>
      <Grid className={classes.buttonContainer} item pt={5} xs={12}>
        <Button
          className={classes.connectButton}
          variant="contained"
          color="primary"
          onClick={openGridSupport}
        >
          {buttonText}
        </Button>
      </Grid>

      <GridSupportSelectDialog
        open={open}
        onClose={onGridSupportClose}
        allowSkip={false}
        confirmationPending={gridSupportConfirmationPending}
        onSubmit={onGridSupportSubmit}
      />
    </Grid>
  )
}

export default ConnectUtilitySection
