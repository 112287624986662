import { Checkbox, useTheme } from '@material-ui/core'
import { Circle, CheckCircle } from 'react-feather'

const CircleCheckbox = (props) => {
  const DEFAULT_SIZE = 24
  const theme = useTheme()

  return (
    <Checkbox
      {...props}
      checkedIcon={
        <CheckCircle
          color={props.activeColor || theme.palette.green?.main}
          size={props.size || DEFAULT_SIZE}
        />
      }
      icon={
        <Circle
          color={props.color || theme.palette.body?.main}
          size={props.size || DEFAULT_SIZE}
        />
      }
    />
  )
}

export default CircleCheckbox
