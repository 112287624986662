import React, { useEffect, useRef, useState } from 'react'
import Icon from '@/components/Icon/Icon'

type Props = {
  initialGasPriceCents?: number
  onChange?: (newValue: number | null, showCorrect: boolean) => void
}

const EditableGasPriceTag = ({
  initialGasPriceCents,
  onChange = () => {},
}: Props) => {
  const [value, setValue] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    setValueFromInitialGasPrice()
  }, [initialGasPriceCents])

  const setValueFromInitialGasPrice = () => {
    setValue(
      initialGasPriceCents ? (initialGasPriceCents / 100).toFixed(2) : '...',
    )
    onChange(initialGasPriceCents ?? null, true)
  }

  const handleValueChange = (event: React.FormEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.value
    const splitValue = newValue.split('.')

    if (
      (newValue.length > 2 && !newValue.includes('.')) ||
      (splitValue.length === 2 &&
        (splitValue[0].length > 2 || splitValue[1].length > 2)) ||
      isNaN(+newValue)
    )
      return // invalid input -> don't change value

    const valueInCents = +newValue * 100
    setValue(newValue)
    onChange(newValue ? valueInCents : null, Boolean(newValue))
  }

  const onBlur = () => {
    if (!value) setValueFromInitialGasPrice()
  }

  const onFocus = () => {
    setValue('')
    onChange(null, false)
  }

  return (
    <div className="flex items-center">
      {value !== '...' && <div>$</div>}
      <input
        type="text"
        ref={inputRef}
        inputMode="decimal"
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
        onChange={handleValueChange}
        placeholder="0.00"
        className="w-9 border-none focus:outline-none bg-[#ffffff00] font-semibold 
        text-[12px] text-[#00922B] text-center disabled:cursor-text placeholder:text-[#B4B4B4]"
      />
      {value !== '...' && <div>/gal</div>}
      <button
        onClick={() => inputRef.current?.focus()}
        className="flex items-center border-none p-0 hover:bg-inherit hover:cursor-pointer ml-1"
      >
        <Icon
          name="Edit"
          color={value ? '#00922B' : '#B4B4B4'}
          size={14}
          strokeWidth={2.5}
        />
      </button>
    </div>
  )
}

export default EditableGasPriceTag
