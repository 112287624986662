import { Charge } from '@/types/charges'
import {
  getChargeLimitWithUnit,
  getChargeRemainingTime,
  getIsCharging,
} from './utils'
import { ChargeForecast, VehicleChargeSchedule } from '@/types'
import { Text } from '@/components'
import { selectedVehicle } from '@/selectors/vehicles'
import { useAppSelector } from '@/hooks'

type Props = {
  latestCharge: Charge | null
  chargeLimit?: number
  chargeForecast: ChargeForecast | undefined
  chargeSchedule: VehicleChargeSchedule | undefined
  milesPerBatteryPercent: number | null
  userPreferredDistanceUnit: 'miles' | 'kilometers'
}

export default function ChargeForecastSubtitle({
  chargeForecast,
  chargeLimit,
  chargeSchedule,
  latestCharge,
  milesPerBatteryPercent,
  userPreferredDistanceUnit,
}: Props) {
  const mode = getIsCharging(chargeForecast) ? 'Charging' : 'NotCharging'

  const kW = Math.round(latestCharge?.kw ?? 0)
  const amp =
    latestCharge?.average_amperage ??
    Math.round(
      (kW * 1000) / (latestCharge?.charger_type === '240v' ? 240 : 120),
    )
  const chargeRemainingTime = getChargeRemainingTime(chargeForecast)

  const vehicle = useAppSelector((state) =>
    selectedVehicle(state.vehicles.vehicles),
  )

  const powerDescriptor = kW && amp ? `${amp} A • ${kW} kW` : null
  const remainingTimeDescriptor = chargeRemainingTime
    ? `${chargeRemainingTime} remain`
    : null

  const chargingSubtitle =
    !powerDescriptor && !remainingTimeDescriptor
      ? undefined
      : `${[powerDescriptor, remainingTimeDescriptor]
          .filter(Boolean)
          .join(' • ')}`

  const chargeLimitDistance = getChargeLimitWithUnit({
    chargeLimit: chargeLimit ?? null,
    chargeLimitUnit: chargeSchedule?.battery_target_unit ?? 'percent',
    milesPerBatteryPercent: milesPerBatteryPercent ?? null,
    outputUnit: userPreferredDistanceUnit,
  })
  const chargeLimitPercentage = getChargeLimitWithUnit({
    chargeLimit: chargeLimit ?? null,
    chargeLimitUnit: chargeSchedule?.battery_target_unit ?? 'percent',
    milesPerBatteryPercent: milesPerBatteryPercent ?? null,
    outputUnit: 'percent',
  })

  const chargeLimitPercentageLabel = chargeLimitPercentage
    ? `${chargeLimitPercentage}%`
    : null
  const distanceUnitLabel = userPreferredDistanceUnit === 'miles' ? 'mi' : 'km'
  const chargeLimitDistanceLabel = chargeLimitDistance
    ? `${chargeLimitDistance} ${distanceUnitLabel}`
    : null
  const chargeLimitSubtitle =
    chargeLimitPercentageLabel || chargeLimitDistanceLabel
      ? `Charge limit: ${[chargeLimitPercentageLabel, chargeLimitDistanceLabel]
          .filter(Boolean)
          .join(' • ')}`
      : undefined

  return (
    <>
      {mode === 'Charging' && chargingSubtitle && (
        <Text className="font-medium" variant="body2">
          {chargingSubtitle}
        </Text>
      )}
      {chargeLimitSubtitle && (
        <Text className="font-medium" variant="body2">
          {chargeLimitSubtitle}
        </Text>
      )}
      {vehicle?.last_ping && (
        <Text className="font-medium" variant="body2">
          {`Last updated: ${timeAgo(vehicle?.last_ping?.toString())}`}
        </Text>
      )}
    </>
  )
}

function timeAgo(dateString: string): string {
  const now = new Date()
  const date = new Date(dateString)
  const seconds = Math.floor((now.getTime() - date.getTime()) / 1000)

  const intervals: { [key: string]: number } = {
    year: 31536000,
    month: 2592000,
    week: 604800,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1,
  }

  for (const [unit, value] of Object.entries(intervals)) {
    const interval = Math.floor(seconds / value)
    if (interval >= 1) {
      return interval === 1 ? `1 ${unit} ago` : `${interval} ${unit}s ago`
    }
  }

  return 'just now'
}
