import { QuestionnairePageValue } from '@/app/components'
import { OnboardingQuestionnairePageKey } from './OnboardingQuestionnaire'
import { useAppSelector } from '@/hooks'
import { surveyResponsesCollection } from '@/reducers/surveyResponses'

type RedirectFunc = (response: QuestionnairePageValue) => string | null

export const responseKeyToRedirectMap: {
  [key in OnboardingQuestionnairePageKey]?: string
} = {
  numberOfEVs: '/connect-vehicle?onboarding=true',
  ownsSmartThermostat: '/connect-thermostat?onboarding=true',
  ownsSmartCharger: '/connect-device/charger?onboarding=true',
}

export const responseToRedirectMap: {
  [key in OnboardingQuestionnairePageKey]?: RedirectFunc
} = {
  numberOfEVs(response) {
    const shouldRedirect = Number(response.value[0]) > 0
    return shouldRedirect
      ? responseKeyToRedirectMap['numberOfEVs'] ?? null
      : null
  },
  ownsSmartThermostat(response) {
    const shouldRedirect = Boolean(response.value[0])
    return shouldRedirect
      ? responseKeyToRedirectMap['ownsSmartThermostat'] ?? null
      : null
  },
  ownsSmartCharger(response) {
    const shouldRedirect = Boolean(response.value[0])
    return shouldRedirect
      ? responseKeyToRedirectMap['ownsSmartCharger'] ?? null
      : null
  },
}

export function computeRedirectFromResponses(
  responses: QuestionnairePageValue[],
  avaId: number | null,
  base_path?: string,
) {
  const DEFAULT_REDIRECT = '/app'
  let cleanedPath = base_path
  if (cleanedPath?.startsWith('/')) {
    cleanedPath = cleanedPath.slice(1)
  }
  if (cleanedPath?.endsWith('/')) {
    cleanedPath = cleanedPath.slice(0, -1)
  }
  const redirectMap = responses
    .map((response) =>
      responseToRedirectMap[response.key as OnboardingQuestionnairePageKey]?.(
        response,
      ),
    )
    .filter((redirect) => redirect !== null && redirect !== undefined)

  const redirectPath = redirectMap[0]

  if (!redirectPath) {
    // In ava-flow, we want to always offer a behavioral enrollment at
    // this stage
    if (base_path === 'ava') {
      return `/ava/program-enrollment/${avaId}/offer`
    }
    return DEFAULT_REDIRECT
  }
  return cleanedPath ? `/${cleanedPath}${redirectPath}` : `${redirectPath}`
}

export function useOnboardingSurveyResponses() {
  const surveys = useAppSelector(surveyResponsesCollection.selectors.selectAll)
  const onboardingSurvey = surveys.find(
    (survey) => survey.survey.type === 'Onboarding',
  )

  const getResponseByKey = (key: OnboardingQuestionnairePageKey) => {
    return onboardingSurvey?.response?.[key]
  }

  return { onboardingSurvey, getResponseByKey }
}
