export const findDuplicates = (arr) => {
  let sorted_arr = arr.slice().sort() // You can define the comparing function here.
  // JS by default uses a crappy string compare.
  // (we use slice to clone the array so the
  // original array won't be modified)
  let results = []
  for (let i = 0; i < sorted_arr.length - 1; i++) {
    if (sorted_arr[i + 1] === sorted_arr[i]) {
      results.push(sorted_arr[i])
    }
  }
  return results
}

export const getKey = (obj, val) =>
  Object.keys(obj).find((key) => obj[key] === val)

export const findIndexByPropertyValue = (
  array,
  propertyName,
  propertyValue,
) => {
  for (let i = 0; i < array.length; i += 1) {
    if (array[i][propertyName] === propertyValue) {
      return i
    }
  }
  return -1
}

// Note: hash should turn elements of arr into a string
export const unique = (arr, hash = (x) => JSON.stringify(x)) => {
  return arr.reduce(
    ({ seen, arr }, x) => {
      if (!seen.has(hash(x))) {
        seen.add(hash(x))
        arr.push(x)
      }
      return { seen, arr }
    },
    { seen: new Set(), arr: [] },
  ).arr
}
