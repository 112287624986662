import { ReactNode } from 'react'
import { Grid, Text } from '@/components'
import AlertMessage from '../AlertMessage'
import { useNavigation } from '@/app/hooks'

export interface Props {
  className?: string
  mode: 'info' | 'error' | 'warning'
  children?: ReactNode
  linkText?: string
  reconnectUrl?: string
  reconnectAction?: () => void
  deviceName: string
  newTab?: boolean
  show?: boolean
}

export default function DeviceAuthAlertMessage(props: Props) {
  const navigation = useNavigation()
  const reconnectUrl = props.reconnectUrl ?? '/connect-device'
  // If we don't start with http, we assume it's an internal link
  const isExternalLink = reconnectUrl.startsWith('http')

  const navAction =
    props.reconnectAction ?? (() => navigation.push(reconnectUrl))

  return (
    <AlertMessage
      className={props.className}
      variant={props.mode}
      show={props.show}
    >
      <Grid flow="row" gap="12px">
        <Text className="text-themed-red-500">
          {props.children
            ? props.children
            : `Oops! We're having trouble communicating with your ${props.deviceName}.`}
        </Text>
        {isExternalLink ? (
          <a
            className="text link-text"
            href={props.reconnectUrl}
            target={props.newTab ? '_blank' : undefined}
            rel="noreferrer"
          >
            {props.linkText ? props.linkText : 'Reconnect'}
          </a>
        ) : (
          <Text variant="link" onClick={navAction}>
            {props.linkText ? props.linkText : 'Reconnect'}
          </Text>
        )}
      </Grid>
    </AlertMessage>
  )
}
