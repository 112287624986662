import { client } from '@/apolloClient'
import { UtilitiesDocument, UtilitiesQuery } from '@/generated/graphql'
import * as Sentry from '@sentry/browser'

export async function fetchViewConfigFromCMS(
  variables: Parameters<typeof client.query<UtilitiesQuery>>[0]['variables'],
) {
  const result = await client.query<UtilitiesQuery>({
    query: UtilitiesDocument,
    variables,
  })

  // If there are any errors in the result, capture them using Sentry
  if (result.error || result.errors) {
    Sentry.captureException(result.error ?? result.errors)
  }

  return result.data.resolvedGroupViewConfig
}
