import { RSAA } from 'redux-api-middleware'
import config from '../config'
import { withAuth } from '../reducers'

export const LIST_REQUEST = '@@referrals_deprecated/LIST_REQUEST'
export const LIST_SUCCESS = '@@referrals_deprecated/LIST_SUCCESS'
export const LIST_FAILURE = '@@referrals_deprecated/LIST_FAILURE'

export const CREATE_REQUEST = '@@referrals_deprecated/CREATE_REQUEST'
export const CREATE_SUCCESS = '@@referrals_deprecated/CREATE_SUCCESS'
export const CREATE_FAILURE = '@@referrals_deprecated/CREATE_FAILURE'

export const getReferralCode = () => ({
  [RSAA]: {
    endpoint: `${config.apiGateway.URL}/api/referrals/`,
    method: 'GET',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [LIST_REQUEST, LIST_SUCCESS, LIST_FAILURE],
  },
})

export const createReferral = (referral_code) => ({
  [RSAA]: {
    endpoint: `${config.apiGateway.URL}/api/referrals/`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ code: referral_code }),
    types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
  },
})
