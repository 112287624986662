import { logMessage } from '@/logging/sentry'
import { RequestStatus, ResourceApiConfig, ResourceModel } from '../types'

type RootState = {
  auth: {
    access: {
      token: string
    }
  }
}

export function shouldPopulateResource({ status }: { status: RequestStatus }) {
  return status === RequestStatus.Idle || status === RequestStatus.Invalidated
}

export function canRefreshResource({
  status,
  errorsPresent,
}: {
  status: RequestStatus
  errorsPresent: boolean
}) {
  return status !== RequestStatus.Loading && !errorsPresent
}

export function selectHeadersWithAuth(headers: { [key: string]: string }) {
  return (state: RootState) => {
    const token = state.auth.access?.token

    if (!token) {
      logMessage('Attempted to create authed headers without access token', {
        logToConsole: true,
      })
    }

    return {
      ...headers,
      Authorization: `Bearer ${token}`,
    }
  }
}

export function selectHeadersWithConfig<Resource extends ResourceModel>(
  headers: { [key: string]: string },
  config: ResourceApiConfig<Resource>,
) {
  return (state: RootState) => {
    if (config.public) {
      return headers
    }
    return selectHeadersWithAuth(headers)(state)
  }
}

export { createResourceSelectors } from './createResourceSelectors'
