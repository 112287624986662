import useMockableViewModel from '../../../../../../hooks/useMockableViewModel'
import { thermostatsCollection } from '../../../../../../reducers/thermostats'
import { ID } from '../../../../../../types/model'
import { useAppSelector } from '../../../../../../hooks'
import { ThermostatProvider } from '../../../../../../types/thermostat'

interface Props {
  thermostatId: ID
}

function useViewModel(props: Props) {
  const thermostatName = useAppSelector(
    (state) =>
      thermostatsCollection.selectors.selectById(state, props.thermostatId)
        ?.portal,
  )
  return {
    ...props,
    thermostatName: thermostatName || ('thermostat' as ThermostatProvider),
  }
}

function useMockViewModel(props: Props) {
  return {
    ...props,
    thermostatName: 'Nest' as ThermostatProvider,
  }
}

export default useMockableViewModel({ useViewModel, useMockViewModel })
