import { Variants } from 'framer-motion'

export function slideInVariant(isMobile: boolean): Variants {
  const commonTransition = {
    transition: { ease: [0], duration: 0.1 },
  }
  const xValue = isMobile ? '100vw' : 80

  return {
    initial: { opacity: isMobile ? undefined : 0, x: xValue, y: 0 },
    animate: {
      opacity: isMobile ? undefined : 1,
      x: 0,
      y: 0,
      transition: { ...commonTransition, delay: 0.2 },
    },
    exit: {
      opacity: isMobile ? undefined : 0,
      x: isMobile ? '-100vw' : -xValue,
      y: 0,
      ...commonTransition,
    },
  }
}
