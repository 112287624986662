import { Flex, TextField } from '@/components'
import { FormikConfig, useFormik } from 'formik'
import { ChangeEvent } from 'react'
import * as yup from 'yup'

export type UsernamePasswordInputsProps = {
  className?: string
  inputValues: {
    username: string
    password: string
  }
  inputErrors: {
    username?: string
    password?: string
  }
  onValueChange: (e: ChangeEvent<any>) => void
  usernameIsEmail?: boolean
}

export default function UsernamePasswordInputs(
  props: UsernamePasswordInputsProps,
) {
  return (
    <Flex container direction="column" gap="36px" className={props.className}>
      <Flex item>
        <TextField
          id="username"
          fullWidth
          type={props.usernameIsEmail ? 'email' : 'text'}
          autoComplete="do-not-autocomplete"
          label={props.usernameIsEmail ? 'Email' : 'Username'}
          value={props.inputValues.username}
          error={Boolean(props.inputErrors.username)}
          helperText={props.inputErrors.username}
          onChange={props.onValueChange}
        />
      </Flex>
      <Flex item>
        <TextField
          id="password"
          fullWidth
          autoComplete="new-password"
          label="Password"
          type="password"
          value={props.inputValues.password}
          error={Boolean(props.inputErrors.password)}
          helperText={props.inputErrors.password}
          onChange={props.onValueChange}
        />
      </Flex>
    </Flex>
  )
}

const emailValidationSchema = yup.object({
  username: yup.string().email().required().label('Email'),
  password: yup.string().required().label('Password'),
})

const usernameValidationSchema = yup.object({
  username: yup.string().required().label('Username'),
  password: yup.string().required().label('Password'),
})
/**
 * Provides all the values needed to render the UsernamePasswordInputs component.
 */
export function useUsernamePasswordInputs({
  onSubmit,
  usernameIsEmail,
  initialEmail,
}: {
  onSubmit: FormikConfig<{ username: string; password: string }>['onSubmit']
  usernameIsEmail?: boolean
  initialEmail?: string
}) {
  const formik = useFormik({
    initialValues: {
      username: initialEmail ? initialEmail : '',
      password: '',
    },
    validationSchema: usernameIsEmail
      ? emailValidationSchema
      : usernameValidationSchema,
    onSubmit: onSubmit,
  })

  return {
    inputValues: formik.values,
    inputErrors: {
      username: formik.touched.username ? formik.errors.username : undefined,
      password: formik.touched.password ? formik.errors.password : undefined,
    },
    onSubmit: formik.handleSubmit,
    onValueChange: formik.handleChange,
    usenameLabel: usernameIsEmail ? 'Email' : 'Username',
  }
}
