import { XIcon } from 'lucide-react'

type Props = {
  onClick: () => void
}
export default function XButton(props: Props) {
  return (
    <button
      onClick={props.onClick}
      className="h-[34px] w-[34px] p-2 border-none hover:bg-themed-grey-400 active:scale-95 hover:cursor-pointer bg-themed-grey-200 flex items-center justify-center rounded-full"
    >
      <XIcon size={24} className="flex-none" />
    </button>
  )
}
