import { NavigationPage } from '@/app/components'
import UtilityProgramEligibleDeviceEnrollmentFlowStep from '@/app/features/utility-programs/UtilityProgramEligibleDeviceEnrollmentFlowStep'
import { useNavigation } from '@/app/hooks'
import { useProgramSpecificConfigFromUrlParam } from '@/app/hooks/useProgramSpecificConfigFromUrlParam'
import { useAppSelector } from '@/hooks'
import { logEvent } from '@/logging'
import { utilityProgramEnrollmentCollection } from '@/reducers/utilityProgramEnrollments'
import { selectUtilityProgramForUserConsideringEnrollments } from '@/selectors'

export default function AutogridVehicleEnrollment() {
  const navigation = useNavigation()
  // The user will have an enrollment already made for them that happened when they were onboarded
  // via flex saver. Pull the enrollment to make sure we have it and then select it to get the config
  utilityProgramEnrollmentCollection.useFetch()
  const utilityProgram = useAppSelector(
    selectUtilityProgramForUserConsideringEnrollments,
  )
  const { config } = useProgramSpecificConfigFromUrlParam(utilityProgram)

  const onRedirectUrl = (redirectUrl: string) => {
    logEvent('enableGridSupport')
    // Navigate back to flexsaver
    if (redirectUrl) {
      window.location.href = redirectUrl
      return
    }
    navigation.push('/app')
  }

  return (
    <NavigationPage id="autogrid-vehicle-enroll-nav-page">
      <NavigationPage.SingleCardContent>
        <UtilityProgramEligibleDeviceEnrollmentFlowStep
          onRedirectUrl={onRedirectUrl}
          titleText={`Review Vehicle Enrollment for the ${config.programName} Program`}
          descriptionText={config.partnerEnrollmentDescription}
        />
      </NavigationPage.SingleCardContent>
    </NavigationPage>
  )
}
