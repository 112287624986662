import React from 'react'
import DeviceConnectionRedirect, {
  Props as DeviceConnectionRedirectProps,
} from '../DeviceConnectionRedirect'
import { FullPageCard, ScreenFillingPage } from '@/app/components'

export interface Props extends DeviceConnectionRedirectProps {}

export default function UtilityConnectRedirect(props: Props) {
  return (
    <ScreenFillingPage>
      <FullPageCard>
        <DeviceConnectionRedirect {...props} />
      </FullPageCard>
    </ScreenFillingPage>
  )
}
