import { CostSheet, LockedFeature } from '@/app/components'
import { Grid, GridItem, Sheet, Skeleton, Text } from '@/components'

type Props = {
  savingsCents: number
  loading?: boolean
  error?: boolean
}

export default function EstimatedAnnualSavingsRow({
  savingsCents,
  loading,
  error,
}: Props) {
  return (
    <LockedFeature
      title="Est Annual Savings"
      connectionRequirements={['utility', 'unpendingPlan']}
      flat
      className="p-0"
    >
      <Grid flow="column" className="flex items-center">
        <GridItem>
          <Text variant="body1">Est Annual Savings</Text>
        </GridItem>
        <GridItem className="flex justify-end items-center h-7">
          {error ? (
            <Sheet color="red-100">
              <Text className="text-themed-error" variant="body1">
                Error
              </Text>
            </Sheet>
          ) : loading ? (
            <Skeleton
              animation="wave"
              variant="rect"
              width={42}
              height={26}
              className="rounded-[12px]"
            />
          ) : (
            <CostSheet size="sm" cost={savingsCents} invertColor />
          )}
        </GridItem>
      </Grid>
    </LockedFeature>
  )
}
