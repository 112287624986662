import { RootState } from '@/reducers'
import { selectChargeForecast } from '../chargeForecast/selectChargeForecast'

/**
 * startable: the user can start a charge now event: the car is plugged in but
 *   not scheduled to charge until later
 * stoppable: the user can stop a charge now event: the car is plugged in and
 *   charging now based on a charge now event
 * boostable: the user can boost a charge now event - scheduled to charge,
 *  but the charge will be interrupted. The user can boost the charge with
 *  a charge now event to charge non-stop until the battery target is reached.
 * unavailable: the user cannot perform any charge now actions
 *
 */
export function selectChargeNowStatus(state: RootState) {
  const chargeForecast = selectChargeForecast(state)

  if (!chargeForecast) {
    return null
  }

  return chargeForecast.charge_now_status
}
