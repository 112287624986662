import { TimedUsageStats } from '@/types'
import * as actions from '../../actions/usages'
import { getApiErrorMessage } from '../../utils/errorUtilities'
import { PayloadAction } from '@reduxjs/toolkit'

type State = {
  usages: { usage_stats: TimedUsageStats[] } | null
  isLoading: boolean
  errors: Record<string, unknown>
}

const initialState: State = {
  usages: null,
  isLoading: false,
  errors: {},
}

export default (state = initialState, action: PayloadAction<any>): State => {
  switch (action.type) {
    case actions.LIST_SUCCESS:
      return {
        ...state,
        usages: action.payload,
        isLoading: false,
        errors: {},
      }
    case actions.LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case actions.LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: getApiErrorMessage(action.payload),
      }
    default:
      return state
  }
}
