import { combineReducers } from 'redux'
import { GasCarConversion } from '../../../types/vehicle/'
import { createResourceCollection } from '../../../request'
import { createSelector, Reducer } from '@reduxjs/toolkit'

export const gasCarConversionCollection =
  createResourceCollection<GasCarConversion>({
    name: 'gasCarConversions',
    apiConfig: {
      path: 'gas_car_conversions',
    },
    selector: (state) => state.components.GasSavingsDialog.gasCarConversions,
  })

export const selectTrimVariantsByGasCarConversion = createSelector(
  [
    gasCarConversionCollection.selectors.selectAll,
    (state, gasCarConversion: GasCarConversion) => gasCarConversion,
  ],
  (trimVariants, { make, model, year }) =>
    trimVariants.filter(
      (trimVariant) =>
        trimVariant.make === make &&
        trimVariant.model === model &&
        trimVariant.year === year,
    ),
)

const trimVariantsReducer: Reducer = gasCarConversionCollection.slice.reducer

export default combineReducers({
  gasCarConversions: trimVariantsReducer,
})
