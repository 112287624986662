export enum DashboardConfigType {
  HireCard = 'HIRE_CARD',
  WildfireGridSupport = 'WILDFIRE_GRID_SUPPORT',
  ActionableWinterWeatherAlert = 'ACTIONABLE_WINTER_WEATHER_ALERT',
  InfoWinterWeatherAlert = 'INFO_WINTER_WEATHER_ALERT',
  TeslaGiveaway = 'TESLA_GIVEAWAY',
  UtilityProgram = 'UTILITY_PROGRAM',
  OnboardingProgress = 'ONBOARDING_PROGRESS',
}

export interface DashboardConfig {
  id: number
  shouldHide: boolean
}
