import { logEvent as logGTMEvent } from './gtm'
import { logServerEvent } from '../actions/event'
import { postEventToMobileIfPresent } from '../utils/messageUtils'
import * as Sentry from '@sentry/browser'
import { v4 as uuidv4 } from 'uuid'
import { track as trackMixpanel, trackPosthog } from './mixpanel'
import { isProduction } from '@/utils/appUtilities'

export type LoggingOptions = {
  authenticated: boolean
  sendToMobileWhenPresent: 'exclusively' | 'inclusively' | 'never'
  metadata: {
    [key: string]: string | number | boolean
  } | null
  count?: number
}

const defaultLoggingOptions = {
  authenticated: true,
  sendToMobileWhenPresent: 'exclusively',
  metadata: null,
  count: 1,
} as const

function logSingleEvent(event: string, options?: Partial<LoggingOptions>) {
  if (!isProduction) {
    return console.log(`Logging event: ${event}`)
  }

  const logOptions = { ...defaultLoggingOptions, ...options }

  const eventId: string = uuidv4()
  logServerEvent(event, logOptions.authenticated, eventId)

  switch (logOptions.sendToMobileWhenPresent) {
    case 'exclusively': {
      const sentToMobile = postEventToMobileIfPresent(
        event,
        logOptions.metadata,
      )
      // Track posthog event always
      trackPosthog(event, logOptions.metadata)
      logGTMEvent(event, eventId, logOptions.metadata, !sentToMobile)
      // If the event was sent to mobile, we don't want to send it to mixpanel
      // because it will be sent from the mobile app.
      if (!sentToMobile) {
        trackMixpanel(event, logOptions.metadata)
      }
      break
    }
    case 'inclusively': {
      postEventToMobileIfPresent(event, logOptions.metadata)
      logGTMEvent(event, eventId, logOptions.metadata, true)
      trackMixpanel(event, logOptions.metadata)
      break
    }
    case 'never': {
      logGTMEvent(event, eventId, logOptions.metadata, true)
      trackMixpanel(event, logOptions.metadata)
      break
    }
  }
}

function logEventUnsafe(
  maybeMultipleEvents: string | string[],
  options?: Partial<LoggingOptions>,
) {
  const events = Array.isArray(maybeMultipleEvents)
    ? maybeMultipleEvents
    : [maybeMultipleEvents]

  // Ensure the count is a non-negative integer
  const count = Math.max(0, Math.floor(options?.count ?? 1))

  for (let i = 0; i < count; i++) {
    events.forEach((event) => logSingleEvent(event, options))
  }
}

export function logEvent(
  maybeMultipleEvents: string | string[],
  options?: Partial<LoggingOptions>,
) {
  try {
    logEventUnsafe(maybeMultipleEvents, options)
  } catch (err) {
    Sentry.captureException(err)
  }
}

export function attachLoggingToGlobal() {
  window.logEvent = logEvent
}
