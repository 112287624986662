import { RSAA } from 'redux-api-middleware'
import { withAuth } from '../reducers'
import config from '../config'

export const VEHICLE_DELAY_EVENT_REQUEST =
  '@@vehicle_events/VEHICLE_DELAY_EVENT_REQUEST'
export const VEHICLE_DELAY_EVENT_SUCCESS =
  '@@vehicle_events/VEHICLE_DELAY_EVENT_SUCCESS'
export const VEHICLE_DELAY_EVENT_FAILURE =
  '@@vehicle_events/VEHICLE_DELAY_EVENT_FAILURE'

export const CHANGE_VEHICLE_DELAY_EVENT_REQUEST =
  '@@vehicle_events/CHANGE_VEHICLE_DELAY_EVENT_REQUEST'
export const CHANGE_VEHICLE_DELAY_EVENT_SUCCESS =
  '@@vehicle_events/CHANGE_VEHICLE_DELAY_EVENT_SUCCESS'
export const CHANGE_VEHICLE_DELAY_EVENT_FAILURE =
  '@@vehicle_events/CHANGE_VEHICLE_DELAY_EVENT_FAILURE'

export const getVehicleChargingDelayEvents = (vehicle_id) => ({
  [RSAA]: {
    endpoint: `${config.apiGateway.URL}/api/vehicle_charging_delay_events/`,
    method: 'GET',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    params: vehicle_id ? { vehicle_id } : {},
    types: [
      VEHICLE_DELAY_EVENT_REQUEST,
      VEHICLE_DELAY_EVENT_SUCCESS,
      VEHICLE_DELAY_EVENT_FAILURE,
    ],
  },
})

export const changeVehicleChargingDelayEvent = (delayEventId, body) => ({
  [RSAA]: {
    endpoint: `${config.apiGateway.URL}/api/vehicle_charging_delay_events/${delayEventId}/`,
    method: 'PATCH',
    body: JSON.stringify(body),
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      CHANGE_VEHICLE_DELAY_EVENT_REQUEST,
      CHANGE_VEHICLE_DELAY_EVENT_SUCCESS,
      CHANGE_VEHICLE_DELAY_EVENT_FAILURE,
    ],
  },
})
