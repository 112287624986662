import { useAppSelector } from '.'
import { thermostatsCollection } from '@/reducers/thermostats'
import { ID } from '@/types/model'

export function useBrandedThermostatName(thermostatId: ID) {
  return useAppSelector((state) => {
    const thermostat = thermostatsCollection.selectors.selectById(
      state,
      thermostatId,
    )
    if (thermostat === undefined) {
      return undefined
    }
    const brand = thermostat.portal
    const name = thermostat.name
    return `${brand}${name ? ` • ${name}` : ''}`
  })
}
