import './styles.scss'
import formatClasses from '../../utils/classes/formatClasses'
import getColor from '../../utils/color/getColor'
import { ReactNodeProps } from '../../types/reactNodeProps'

export interface Props extends Omit<ReactNodeProps, 'children'> {
  type: 'falling' | 'flashing'
  size?: 'small' | 'medium' | 'large'
  color?: string
  noBackground?: boolean
  centered?: boolean
}

const defaultProps: Partial<Props> = {
  type: 'falling',
  size: 'small',
  color: 'white',
}

export default function LoadingAnimation(passedProps: Props) {
  const props = { ...defaultProps, ...passedProps }

  const classes = formatClasses([
    'loading-animation',
    props.type,
    props.size,
    props.noBackground ? 'no-background' : undefined,
    props.className,
  ])

  const styles = () => {
    const color = getColor(props.color)
    return {
      background: color,
    }
  }

  const Dot = () => <div style={styles()} />

  return (
    <span
      className={`${
        props.centered ? 'flex flex-row justify-center items-center' : ''
      }`}
    >
      <div style={props.style} className={classes}>
        <Dot />
        <Dot />
        <Dot />
      </div>
    </span>
  )
}
