import './styles.scss'
import { HTMLAttributes } from 'react'
import { Grid, Text } from 'component-library'
import formatClasses from '@/utils/classes/formatClasses'
import { TestingProps } from '@/types/testingProps'

export interface Props
  extends Omit<HTMLAttributes<HTMLDivElement>, 'title'>,
    TestingProps {
  title?: string | JSX.Element
  subtitle?: string | JSX.Element
  disabled?: boolean
}

function Title(props: { title?: string | JSX.Element }) {
  if (!props.title) return <></>

  return typeof props.title === 'string' ? (
    <Text variant="h2">{props.title}</Text>
  ) : (
    props.title
  )
}

function Subtitle(props: { subtitle?: string | JSX.Element }) {
  if (!props.subtitle) return <></>

  return <Text variant="body2">{props.subtitle}</Text>
}

export default function Question(props: Props) {
  const classes = {
    question: formatClasses([
      'optiwatt-question',
      props.disabled ? 'disabled' : undefined,
    ]),
  }

  return (
    <Grid
      className={classes.question}
      flow="row"
      gap="20px"
      data-testing-id={props['data-testing-id']}
    >
      {props.disabled ? (
        <></>
      ) : (
        <>
          {props.title && props.subtitle ? (
            <Grid flow="row" gap="16px">
              <Title title={props.title} />
              <Subtitle subtitle={props.subtitle} />
            </Grid>
          ) : (
            <>
              <Title title={props.title} />
              <Subtitle subtitle={props.subtitle} />
            </>
          )}
          {props.children}
        </>
      )}
    </Grid>
  )
}
