import './css/bootstrap.min.scss'
import './css/meanmenu.scss'
import './css/owl.carousel.min.scss'
import './css/slick.scss'
import './css/main.scss'
import useAddScripts from '@/utils/dom/useAddScripts'
import { Helmet } from 'react-helmet'
import HubSpotForm from './HubSpotForm'
import { optiwattConsts } from '@/optiwattConsts'

const Header = () => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      <title>Optiwatt Business</title>
      <meta name="description" content="" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link
        rel="stylesheet"
        href="landing-assets/business/css/fontawesome-all.min.css"
      />
    </Helmet>
  )
}

const PressReleasePage = () => {
  useAddScripts([
    'landing-assets/business/js/jquery.min.js',
    'landing-assets/business/js/bootstrap.bundle.min.js',
    'landing-assets/business/js/jquery.meanmenu.min.js',
    'landing-assets/business/js/main.js',
  ])

  return (
    <div
      id="business-landing-scope"
      className="_use-deprecated-style _use-deprecated-bootstrap"
    >
      <Header />
      <div className="sidebar-info side-info">
        <div className="sidebar-logo-wrapper mb-25">
          <div className="row align-items-center">
            <div className="col-xl-6 col-8">
              <div className="sidebar-logo">
                <a href="/home">
                  <img src="/img/logo/logo-colored-white.webp" alt="logo-img" />
                </a>
              </div>
            </div>
            <div className="col-xl-6 col-4">
              <div className="sidebar-close-wrapper text-end">
                <button className="sidebar-close side-info-close">
                  <i className="fal fa-times"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="sidebar-menu-wrapper fix">
          <div className="mobile-menu"></div>
        </div>

        <div className="sidebar-contact-wrapper mt-40"></div>
      </div>
      <div className="offcanvas-overlay"></div>
      <div className="submit-popup">
        {/* <img src="landing-assets/business/img/popup-icon.png" alt="" /> */}
        <h1 className="popup-heading">Question Submitted</h1>
        <p>
          Thank you for sending your question. Our team will be in touch with
          you soon!
        </p>
        <span className="popup-close">Close</span>
      </div>

      <header>
        <div className="header-area">
          <div className="container">
            <div className="menu-wrap">
              <div className="header-logo">
                <a href="business">
                  <img
                    src="landing-assets/business/img/logo/logo.png"
                    alt="Brand logo"
                  />
                </a>
              </div>
              <nav
                className="main-menu mobile-menu d-sm-none d-md-block"
                id="mobile-menu"
              >
                <ul>
                  <li>
                    <a href="https://optiwatt.applytojob.com/">Career</a>
                  </li>
                  <li>
                    <a href="business/#contact">Contact</a>
                  </li>
                  <li>
                    <a href="faq">FAQ</a>
                  </li>
                </ul>
              </nav>
              <div className="menu-btn d-flex align-items-center">
                <div className="header-btns d-none d-md-flex">
                  <a href="login" className="header-login-btn">
                    login
                  </a>
                  <a href="signup" className="header-signup-btn">
                    Sign Up
                  </a>
                </div>
                <div className="menu-bar d-md-none">
                  <div className="navbar-sign side-toggle ml-20">
                    <span className="menu-line-1"></span>
                    <span className="menu-line-2"></span>
                    <span className="menu-line-3"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <main>
        <section className="banner-area">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-md-10 col-lg-10 col-xl-10">
                <h1 className="banner-heading">
                  Optiwatt Raises Series A Funding to Expand its New Solution
                  for Utility DR Programs
                </h1>
                <h5 className="gradient-title">
                  Optiwatt Closes Seven Million Dollar Series A Funding Round to
                  Accelerate Grid-connected EV and Thermostat Optimization.
                </h5>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-md-10 col-lg-10 col-xl-10">
                <p>
                  Optiwatt, the leading provider of electric vehicle (EV)
                  managed charging solutions, is thrilled to announce that it
                  received a total of 7 million dollars through its Series A
                  financing round. With new capital secured, Optiwatt aims to
                  accelerate the development and deployment of optimized EV
                  charging and smart thermostats that empower customers to
                  efficiently manage their energy use and respond to dynamic
                  grid signals.
                </p>
              </div>
            </div>
            <br />
            <div className="row d-flex justify-content-center">
              <div className="col-md-10 col-lg-10 col-xl-10">
                <p>
                  Their free, user-friendly platform improves grid reliability,
                  saves customers money, and reduces greenhouse gas emissions,
                  all while embracing a consumer-first approach that prioritizes
                  user preferences, frictionless automation, and detailed
                  visibility of energy use.
                </p>
              </div>
            </div>
            <br />
            <div className="row d-flex justify-content-center">
              <div className="col-md-10 col-lg-10 col-xl-10">
                <p>
                  With over 70 thousand connected EVs, Optiwatt’s
                  hyper-scalable, telematics-based EV-managed charging solution
                  is already the largest EV managed charging provider in North
                  America, achieving over $21 million in energy bill savings and
                  avoiding 329,000 tons of carbon from being emitted. In the
                  short term, the platform is poised to deploy hundreds of
                  thousands of grid-connected and rate-optimized EVs and smart
                  thermostats. In the long term, Optiwatt has a vision to be the
                  trusted home energy platform for consumers and a key provider
                  of DR program solutions for utilities and grid operators
                  around the world.
                </p>
              </div>
            </div>
            <br />
            <div className="row d-flex justify-content-center">
              <div className="col-md-10 col-lg-10 col-xl-10">
                <p>
                  Optiwatt’s unique leadership team boasts decades of experience
                  building popular and engaging consumer-facing applications,
                  and they have deep utility industry experience. Crucially, the
                  new funding will enable Optiwatt to expand their direct
                  utility and energy ecosystem partnerships.
                </p>
              </div>
            </div>
            <br />
            <div className="row d-flex justify-content-center">
              <div className="col-md-10 col-lg-10 col-xl-10">
                <p>
                  “Today, the energy sector is faced with numerous challenges
                  including load growth from electrification and EV adoption,
                  the need to manage more grid complexity with the growth in
                  renewable energy, aging infrastructure, and the imperative to
                  cut power sector carbon emissions,” said Optiwatt CEO Casey
                  Donahue. “Optiwatt is focused on solving these challenges by
                  scaling grid-connected smart devices in a way that makes
                  energy management easy for the consumer and makes load
                  management of these devices easy for utilities and the energy
                  ecosystem.”
                </p>
              </div>
            </div>
            <br />
            <div className="row d-flex justify-content-center">
              <div className="col-md-10 col-lg-10 col-xl-10">
                <p>
                  "We were immediately impressed with Optiwatt's approach and
                  consumer-focused DNA as a company," said Louis Schotsky,
                  managing partner at Navitas Capital. "Optiwatt's unique value
                  proposition delivers direct user value while solving
                  long-standing challenges for utilities, making it a win-win
                  proposition for consumers, utilities, and the environment."
                </p>
              </div>
            </div>
            <br />
            <div className="row d-flex justify-content-center">
              <div className="col-md-10 col-lg-10 col-xl-10">
                <p>
                  The Series A funding round was led by Navitas Capital and
                  brought in new investor Skyview Ventures alongside existing
                  investors Google Ventures (GV), Active Impact Investments,
                  Urban Innovation Fund, and Thin Line Capital.
                </p>
              </div>
            </div>
            <br />
          </div>
        </section>
        <section className="faq section-padding">
          <a id="contact" style={{ display: 'block' }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-3"></div>
                <div className="col-lg-4 col-md-6">
                  <div className="faq-form">
                    <h3 className="faq-form-heading">
                      Get in touch to learn more.
                    </h3>
                    <HubSpotForm />
                  </div>
                </div>
              </div>
            </div>
          </a>
        </section>
      </main>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 order-last order-lg-first">
              <div className="footer-widget footer-logo-widget">
                <div className="footer-logo">
                  <img src="/img/logo/logo-colored-white.webp" alt="logo" />
                </div>
                <p className="copyright">
                  Copyrights © {new Date().getFullYear()}. <br />
                  All rights reserved by Optiwatt
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-2">
              <div className="footer-widget">
                <h4 className="footer-widget-title">About</h4>
                <ul>
                  <li>
                    <a href="https://optiwatt.applytojob.com" rel="noreferrer">
                      Careers
                    </a>
                  </li>
                  <li>
                    <a href="privacy">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="tos">Terms of Service</a>
                  </li>
                  <li>
                    <a href="faq">FAQ</a>
                  </li>
                  <li>
                    <a href="partners">For Partners</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="footer-widget footer-address-widget">
                <h4 className="footer-widget-title">Contact</h4>
                <ul>
                  <li>
                    <img
                      src="landing-assets/thermostat/img/footer/location.png"
                      alt=""
                    />
                    <p>{optiwattConsts.contact.location}</p>
                  </li>
                  <li>
                    <a href={`mailto:${optiwattConsts.contact.email}`}>
                      <img
                        src="landing-assets/thermostat/img/footer/support.png"
                        alt=""
                      />
                      <p>{optiwattConsts.contact.email}</p>
                    </a>
                  </li>
                  <li>
                    <a href={optiwattConsts.contact.website}>
                      <img
                        src="landing-assets/thermostat/img/footer/wifi.png"
                        alt=""
                      />
                      <p>{optiwattConsts.contact.website}</p>
                    </a>
                  </li>
                  <li>
                    <a href={`tel:${optiwattConsts.contact.phoneNumber}`}>
                      <div
                        style={{
                          width: '24px',
                          height: '24px',
                          padding: '2px',
                        }}
                      >
                        <img
                          src="img/feathericons/primary/phone.webp"
                          alt="phone"
                          style={{ width: '100%' }}
                        />
                      </div>
                      <p>{optiwattConsts.contact.phoneNumber}</p>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-lg-2">
              <div className="footer-widget footer-icon-widget">
                <h4 className="footer-widget-title">Contact</h4>
                <ul>
                  <li>
                    <a href={optiwattConsts.social.facebook}>
                      <i className="fab fa-facebook"></i>
                      <p className="social-media-name">Facebook</p>
                    </a>
                  </li>
                  <li>
                    <a href={optiwattConsts.social.twitter}>
                      <i className="fab fa-twitter"></i>
                      <p className="social-media-name">Twitter</p>
                    </a>
                  </li>
                  <li>
                    <a href={optiwattConsts.social.youtube}>
                      <i className="fab fa-youtube"></i>
                      <p className="social-media-name">YouTube</p>
                    </a>
                  </li>
                  <li>
                    <a href={optiwattConsts.social.instagram}>
                      <img
                        src="landing-assets/thermostat/img/footer/ins.png"
                        alt=""
                      />
                      <p className="social-media-name">Instagram</p>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default PressReleasePage
