import { AlertMessage } from '@/app/components'
import { DualThumbRangeSlider, Grid, GridItem, Text } from '@/components'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { thermostatComfortSettingsCollection } from '@/reducers/thermostatComfortSettings'
import { Thermostat, ThermostatComfortSetting } from '@/types'
import { ID } from '@/types/model'
import { debounce } from '@/utils/limit'
import { useCallback, useState } from 'react'

type Props = {
  comfortSettings: ThermostatComfortSetting[]
  thermostatId: Thermostat['id']
  onChange?: (comfortSettings: ThermostatComfortSetting[]) => void
}

export default function ComfortSettingSlideEditor(props: Props) {
  const dispatch = useAppDispatch()
  const comfortSettingsUpdateError = useAppSelector((state) =>
    thermostatComfortSettingsCollection.selectors.queryState
      .select(state, {
        method: 'PATCH',
      })
      .some((queryState) => Boolean(queryState.error)),
  )
  // used to remount DualThumbRangeSlider if updating failed -> resets slider on failure
  const [resetKey, setResetKey] = useState(false)

  // debounce settings update
  const debouncedUpdateComfortSetting = useCallback(
    debounce(
      async (
        settingId: ID,
        updatedSettting: Partial<ThermostatComfortSetting>,
      ) => {
        const promise = await dispatch(
          thermostatComfortSettingsCollection.actions.update(
            `/thermostats/${props.thermostatId}/comfort_settings/${settingId}/`,
            updatedSettting,
          ),
        )
        if (promise.type.includes('UPDATE_FAILURE')) {
          setResetKey((prev) => !prev)
        }

        props.onChange?.(props.comfortSettings)
      },
      200,
    ),
    [props.thermostatId],
  )

  const onComfortSettingSliderChangeHandler =
    (comfortSetting: ThermostatComfortSetting) =>
    (value: number, side: 'left' | 'right') => {
      const updatedSettting =
        side === 'left'
          ? { event_heat_setpoint_delta: comfortSetting.heat_setpoint - value }
          : { event_cool_setpoint_delta: value - comfortSetting.cool_setpoint }
      debouncedUpdateComfortSetting(comfortSetting.id, updatedSettting)
    }

  return (
    <>
      <Grid flow="row" gap="32px">
        <AlertMessage
          variant="error"
          show={Boolean(comfortSettingsUpdateError)}
        >
          There was an issue updating your comfort settings. Please try again
          later.
        </AlertMessage>
        <Text variant="body2">
          Drag the slider to adjust your acceptable temperature range during
          future events
        </Text>
        {props.comfortSettings.map((comfortSetting) => (
          <GridItem key={comfortSetting.id}>
            <Text variant="body1" className="pb-2">
              {comfortSetting.name}
            </Text>
            <DualThumbRangeSlider
              key={Number(resetKey)}
              startingValues={[
                comfortSetting.heat_setpoint,
                comfortSetting.cool_setpoint,
              ]}
              defaultOffsets={[
                comfortSetting.event_heat_setpoint_with_defaults
                  ? comfortSetting.heat_setpoint -
                    comfortSetting.event_heat_setpoint_with_defaults
                  : undefined,
                comfortSetting.event_cool_setpoint_with_defaults
                  ? comfortSetting.event_cool_setpoint_with_defaults -
                    comfortSetting.cool_setpoint
                  : undefined,
              ]}
              onChange={onComfortSettingSliderChangeHandler(comfortSetting)}
              minimumOffset={1}
            />
          </GridItem>
        ))}
      </Grid>
      <DualThumbRangeSlider.Legend
        className="mt-[24px]"
        startingRangeLabel="Your Current Schedule"
        offsetsLabel="Your Event Settings"
      />
    </>
  )
}
