import React, { useCallback, useState } from 'react'
import { Grid, GridItem, Text, Toggle } from '@/components'
import { NavigationPage } from '@/app/components'
import connectUtilityAnimation from '@/app/img/connect-utility-lottie.json'
import { AdaptiveCardRowLayout } from '@/app/layouts'
import Lottie from 'lottie-react'
import { Thermostat, ThermostatSettings } from '@/types'
import { debounce } from '@/utils/limit'
import { useAppDispatch } from '@/hooks'
import { thermostatSettingsCollection } from '@/reducers/thermostatSettings'
import PageLoader from '@/authenticated/components/layout/PageLoader'
import { useParams } from 'react-router-dom'
import { useBrandedThermostatName } from '@/hooks/useBrandedThermostatName'
import EstimatedAnnualSavingsRow from 'features/devices/thermostat/EstimatedAnnualSavingsRow'
import { thermostatPrecoolSavingsSummariesCollection } from '@/reducers/thermostatSavingsSummaries'

type ViewModelProps = {
  settings: ThermostatSettings | undefined
  thermostatId: Thermostat['id']
}

function useViewModel(props: ViewModelProps) {
  const dispatch = useAppDispatch()

  const {
    data: savingsSummary,
    status: savingsSummaryStatus,
    error: savingsError,
    refetch: refetchSavingsSummary,
  } = thermostatPrecoolSavingsSummariesCollection.useFetch(
    `/thermostats/${props.thermostatId}/savings_summary/pre_cool/`,
  )

  const [localPrecoolEnabled, setLocalPrecoolEnabled] = useState(
    props.settings?.pre_cool_enabled,
  )

  const debouncedUpdateSettings = useCallback(
    debounce((updatedSettings: Partial<ThermostatSettings>) => {
      dispatch(
        thermostatSettingsCollection.actions.update(
          `/thermostats/${props.thermostatId}/settings/`,
          updatedSettings,
          { id: props.settings?.id },
        ),
      )
    }, 500),
    [props.thermostatId, props.settings?.id],
  )

  const brandedThermostatName = useBrandedThermostatName(props.thermostatId)

  const onPrecoolToggle = (checked: boolean) => {
    setLocalPrecoolEnabled(checked)
    debouncedUpdateSettings({ pre_cool_enabled: checked })
  }

  return {
    brandedThermostatName,
    onPrecoolToggle,
    localPrecoolEnabled,
    savingsCents: savingsSummary?.[0]?.pre_cool_savings_cents,
    savingsSummary,
    savingsSummaryStatus,
    savingsError,
    refetchSavingsSummary,
  }
}

function PrecoolPreheatSettingsPageComponent(
  props: ReturnType<typeof useViewModel>,
) {
  return (
    <NavigationPage
      id="thermostat-precool-preheat-settings-page"
      title="Precool"
      subtitle={props.brandedThermostatName ?? 'Thermostat'}
      navigationOptions={['back']}
    >
      <AdaptiveCardRowLayout.PlainRow>
        <GridItem placeItems="center">
          <Lottie
            className="w-1/2"
            animationData={connectUtilityAnimation}
            loop
          />
        </GridItem>
      </AdaptiveCardRowLayout.PlainRow>
      <AdaptiveCardRowLayout.CardRow>
        <Grid flow="column">
          <GridItem>
            <Text variant="subheader">Precool</Text>
            <Text variant="body2">
              Optiwatt will cool your home down to your desired temperature when
              electricity rates are cheapest
            </Text>
          </GridItem>
          <GridItem className="pt-2 pl-16">
            <Toggle
              id="thermostat-precool-toggle"
              checked={props.localPrecoolEnabled}
              onChange={props.onPrecoolToggle}
            />
          </GridItem>
        </Grid>
      </AdaptiveCardRowLayout.CardRow>
      <AdaptiveCardRowLayout.CardRow bottomRule={false}>
        <EstimatedAnnualSavingsRow
          error={Boolean(props.savingsError)}
          loading={props.savingsSummaryStatus === 'loading'}
          savingsCents={props.savingsCents}
        />
      </AdaptiveCardRowLayout.CardRow>
    </NavigationPage>
  )
}

function PrecoolPreheatSettingsPage(props: ViewModelProps) {
  const vm = useViewModel(props)
  return <PrecoolPreheatSettingsPageComponent {...vm} />
}

function PrecoolPreheatSettingsPageWithFallback(props: {
  thermostatId: Thermostat['id']
}) {
  const { data: settingsArray, status: settingsStatus } =
    thermostatSettingsCollection.useFetch(
      `/thermostats/${props.thermostatId}/settings/`,
    )
  const settings = settingsArray?.[0]
  const settingsLoading = settingsStatus !== 'succeeded' && !settings

  if (settingsLoading) {
    return <PageLoader />
  }

  return (
    <PrecoolPreheatSettingsPage
      thermostatId={props.thermostatId}
      settings={settings}
    />
  )
}

type PathParams = { deviceId: string | undefined }

export default function PrecoolPreheatSettingsPageWithParams() {
  const { deviceId } = useParams<PathParams>()
  if (!deviceId) return <PageLoader />
  const thermostatId = parseInt(deviceId)
  if (isNaN(thermostatId)) return <PageLoader />
  return <PrecoolPreheatSettingsPageWithFallback thermostatId={thermostatId} />
}
