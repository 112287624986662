import React from 'react'
import { Button as MuiButton } from '@material-ui/core'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import { makeStyles, createStyles } from '@material-ui/core/styles'

const Button = styled(MuiButton)(spacing)

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      width: '100%',
      maxWidth: '300px',
      height: '50px',
    },
  }),
)

export enum SaveState {
  Disabled,
  Enabled,
  Saving,
  Saved,
}

function saveButtonText(state: SaveState) {
  return {
    [SaveState.Disabled]: 'Save',
    [SaveState.Enabled]: 'Save',
    [SaveState.Saving]: 'Saving...',
    [SaveState.Saved]: 'Saved',
  }[state]
}

export interface Props {
  onClick: () => void
  saveState?: SaveState
}

export default function SaveButton({ onClick, saveState }: Props) {
  const classes = useStyles()
  const state = saveState ?? SaveState.Enabled
  return (
    <Button
      className={classes.button}
      onClick={onClick}
      variant="contained"
      color="primary"
      disabled={state !== SaveState.Enabled}
    >
      {saveButtonText(state)}
    </Button>
  )
}
