import { NavigationPage } from 'app-components'
import SmartCarRedirect from '@/authenticated/components/SmartCarRedirect/SmartCarRedirect'
import LinkDiagram from 'features/logos/LinkDiagram'
import OptiwattIconLogo from '@/app/img/optiwatt-inverted.svg'
import { Text } from '@/components'
import QRCode from 'react-qr-code'
import AppStoreButton from '@/app/components/AppStoreButton/AppStoreButton'
import useViewModel, {
  ExternalVehicleRedirectProps,
} from '@/app/pages/connect-vehicle/external-vehicle-redirect/useViewModel'
import buildDeeplinkUri from '@/utils/deeplinkBuilder'

export default function ExternalVehicleRedirect(
  props: ExternalVehicleRedirectProps,
) {
  const viewModel = useViewModel(props)

  let pageContent

  if (viewModel.isMobile && !viewModel.requiresUpdate) {
    if (!viewModel.messageSent) {
      return null
    }

    pageContent = (
      <SmartCarRedirect
        mode={viewModel.connectionStatus}
        statusText={{
          connecting: 'Hold tight...we’re finishing up your connection',
          connected: 'Great! You successfully connected your vehicle',
          failed: 'Oh no! Something went wrong. Please go back and try again',
        }}
        buttonAction={viewModel.onSubmit}
        backButtonAction={viewModel.onBack}
      ></SmartCarRedirect>
    )
  } else {
    pageContent = (
      <div className="bg-white rounded-[10px] px-5 py-[30px] flex flex-col gap-4 align-content-center items-center">
        <LinkDiagram logos={[OptiwattIconLogo, props.appIconSrc]} />
        <div className="bg-themed-green-100 rounded-2xl px-4 py-1.5 mt-6 mb-2">
          <Text variant="h3" className="text-[#22C55E] text-center">
            {viewModel.copyTitle}
          </Text>
        </div>
        <Text
          variant="body1"
          className="sm:px-[60px] text-center leading-6 mb-4"
        >
          {viewModel.copySubtext}
        </Text>
        {!viewModel.requiresUpdate && (
          <QRCode
            value={buildDeeplinkUri(
              `connect-device/vehicle/${viewModel.appPath}`,
            )}
            size={120}
          />
        )}
        <div className="flex flex-col sm:flex-row gap-4 mt-5">
          <AppStoreButton className="max-w-[200px]" store="apple" />
          <AppStoreButton className="max-w-[200px]" store="google" />
        </div>
      </div>
    )
  }

  return (
    <NavigationPage id={props.id} title={props.pageTitle}>
      {pageContent}
    </NavigationPage>
  )
}
