export default function debounce<
  F extends (...args: Parameters<F>) => ReturnType<F>,
>(callback: F, delay = 100): (...args: Parameters<F>) => void {
  let timeout: NodeJS.Timeout

  return (...args: Parameters<F>): void => {
    clearTimeout(timeout)
    timeout = setTimeout(() => callback(...args), delay)
  }
}
