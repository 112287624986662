import { ConfigurableText, NavigationPage } from '@/app/components'
import Collapsible from '@/components/collapsibleList'
import { CollapsibleListItemHeader } from '../components/CollapsibleListItemHeader'
import { useNavigation } from '@/app/hooks'
import { useViewModel } from './useViewModel'
import { ConnectivityConfigStatus, ConnectivityConfigType } from './utils'
import {
  ManufacturerConnection,
  ManufacturerConnectionReason,
} from '@/types/vehicle/vehicleConnectivity'
import useNavigateToTeslaKeyPairing from '@/app/hooks/useNavigateToTeslaKeyPairing'
import { Vehicle } from '@/types'
import { logMessage } from '@/logging/sentry'

function ReenterVehicleCredentials(props: {
  vehicleMake: string
  actionText: string
}) {
  const navigate = useNavigation()

  const vehicleMake = props.vehicleMake.toLowerCase()

  const navigationMake =
    vehicleMake === 'tesla'
      ? 'tesla'
      : vehicleMake === 'ford'
      ? 'ford'
      : 'smartcar'

  return (
    <ConfigurableText variant="body4" className="pl-8">
      <ConfigurableText
        variant="link"
        onClick={() =>
          navigate.push(
            `/connect-device/vehicle${
              navigationMake !== 'smartcar' ? `/${navigationMake}` : ''
            }`,
          )
        }
      >
        Re-enter
      </ConfigurableText>{' '}
      your credentials to {props.actionText}.
    </ConfigurableText>
  )
}

function TeslaManufacturerConnectionAction(props: {
  vehicleMake: 'tesla'
  vehicle: Vehicle
  manufacturerConnectionStatus: ManufacturerConnection
}) {
  const {
    isLoading,
    error,
    navigate: navigateToTeslaKeyPairing,
  } = useNavigateToTeslaKeyPairing()

  const navigate = useNavigation()

  const navigateToAction =
    props.manufacturerConnectionStatus.reason ===
    ManufacturerConnectionReason.PublicKeyNotAttached
      ? () => navigateToTeslaKeyPairing(props.vehicle)
      : () => navigate.push('/connect-device/vehicle/tesla')

  const actionText =
    props.manufacturerConnectionStatus.reason ===
    ManufacturerConnectionReason.PublicKeyNotAttached
      ? 'Pair your virtual key'
      : 'Re-enter your credentials'

  return (
    <ConfigurableText variant="body4" className="pl-8">
      <ConfigurableText variant="link" onClick={navigateToAction}>
        {actionText}
      </ConfigurableText>{' '}
      to restore access.
    </ConfigurableText>
  )
}

function ConnectivityListItemContent(props: {
  message: { [key in ConnectivityConfigStatus]: string }
  status: ConnectivityConfigStatus
  children?: React.ReactNode
}) {
  const message = props.message[props.status]

  return (
    <>
      <ConfigurableText variant="body4" className="pl-8">
        {message}
      </ConfigurableText>
      {props.children && <div className="mt-4">{props.children}</div>}
    </>
  )
}

interface SubscriptionStatusDescriptionProps {
  make: string
  status: ConnectivityConfigStatus
  subscription?: {
    cost?: {
      amount: number | null
      frequency: string | null
    }
    url?: string
  }
  onSubscriptionClick: (url: string) => void
}

export function SubscriptionStatusDescription({
  make,
  status,
  subscription,
  onSubscriptionClick,
}: SubscriptionStatusDescriptionProps) {
  const onSubscriptionTextClick = () => {
    if (!subscription || !subscription?.url) {
      logMessage(
        'SubscriptionStatusDescription: Unexpectedly tried to navigate to subscription url that doesnt exist',
      )
    }

    const url: string = subscription?.url ?? ''

    onSubscriptionClick(url)
  }

  return (
    <div className="flex flex-col bg-themed-base-100 rounded-lg p-4 gap-2">
      <ConfigurableText variant="body1">{`${make} Connectivity`}</ConfigurableText>
      <div className="flex flex-row items-center gap-1">
        <ConfigurableText
          variant="body2"
          className={
            status === 'success'
              ? 'text-themed-green-900'
              : 'text-themed-red-600'
          }
        >
          {status === 'success'
            ? 'Active'
            : status === 'failure'
            ? 'Expired'
            : 'Unknown'}
        </ConfigurableText>
        {subscription?.cost?.amount && subscription?.cost?.frequency && (
          <>
            <div className="aspect-square h-1 bg-themed-base-400 rounded-full" />
            <ConfigurableText variant="body2">
              ${subscription.cost.amount}/{subscription.cost.frequency}
            </ConfigurableText>
          </>
        )}
      </div>
      {subscription?.url && (
        <ConfigurableText variant="link" onClick={onSubscriptionTextClick}>
          Go to Subscription Page
        </ConfigurableText>
      )}
    </div>
  )
}

export default function Connectivity() {
  const viewModel = useViewModel()

  if (viewModel.status === 'loading') {
    return null
  }

  const {
    subscription,
    configStatus,
    make,
    manufacturerConnectionStatus,
    vehicle,
  } = viewModel

  const openLinkInNewTab = (url: string) => {
    window.open(url, '_blank')
  }

  const connectivityConfig: {
    visible: boolean
    title: ConnectivityConfigType
    content: React.ReactNode
  }[] = [
    {
      visible: true,
      title: ConnectivityConfigType.SoftwareUpdated,
      content: (
        <ConnectivityListItemContent
          message={{
            success:
              'Your software is up to date. Enjoy access to the latest features and improvements.',
            failure: `You are currently using an older method of connecting your ${make} to Optiwatt.`,
            unknown: `You are currently using an older method of connecting your ${make} to Optiwatt.`,
          }}
          status={configStatus[ConnectivityConfigType.SoftwareUpdated]}
        >
          {configStatus[ConnectivityConfigType.SoftwareUpdated] !==
            'success' && (
            <ReenterVehicleCredentials
              vehicleMake={make}
              actionText="restore access"
            />
          )}
        </ConnectivityListItemContent>
      ),
    },
    {
      visible: true,
      title: ConnectivityConfigType.ManufacturerConnection,
      content: (
        <ConnectivityListItemContent
          message={{
            success: `Connected to ${make} Servers. Your vehicle’s data is syncing properly.`,
            failure: `Unable to connect to ${make} Servers.`,
            unknown: `Unable to connect to ${make} Servers.`,
          }}
          status={configStatus[ConnectivityConfigType.ManufacturerConnection]}
        >
          {configStatus[ConnectivityConfigType.ManufacturerConnection] !==
            'success' &&
            (make.toLowerCase() === 'tesla' ? (
              <TeslaManufacturerConnectionAction
                vehicleMake="tesla"
                vehicle={vehicle}
                manufacturerConnectionStatus={manufacturerConnectionStatus}
              />
            ) : (
              <ReenterVehicleCredentials
                vehicleMake={make}
                actionText="restore access"
              />
            ))}
        </ConnectivityListItemContent>
      ),
    },
    {
      visible: true,
      title: ConnectivityConfigType.VehicleSignal,
      content: (
        <ConnectivityListItemContent
          message={{
            success: 'Your vehicle is connected and ready to receive signals.',
            failure: `Unable to connect to ${make} servers. Optiwatt will retry the connection shortly. No action is required from you.`,
            unknown: `Connectivity with ${make} is unknown. A valid subscription and manufacturer connection are required.`,
          }}
          status={configStatus[ConnectivityConfigType.VehicleSignal]}
        />
      ),
    },
    {
      visible: true,
      title: ConnectivityConfigType.Subscription,
      content: (
        <ConnectivityListItemContent
          message={{
            success: `Extend your subscription with ${make}`,
            failure: `Renew your subscription with ${make}`,
            unknown: `Your subscription with ${make} is unknown. An active manufacturer connection is required.`,
          }}
          status={configStatus[ConnectivityConfigType.Subscription]}
        >
          <SubscriptionStatusDescription
            make={make}
            status={configStatus[ConnectivityConfigType.Subscription]}
            subscription={subscription}
            onSubscriptionClick={openLinkInNewTab}
          />
        </ConnectivityListItemContent>
      ),
    },
  ]
  const connectivityConfigToShow = connectivityConfig.filter(
    (config) => config.visible,
  )
  return (
    <NavigationPage
      id="connectivity_page"
      title="Connectivity"
      subtitle={`Manage your connectivity with ${make}`}
    >
      <Collapsible.List>
        {connectivityConfigToShow.map((config, index) => (
          <Collapsible.ListItem
            key={index}
            isOpen={configStatus[config.title] === 'failure'}
            header={
              <CollapsibleListItemHeader
                status={configStatus[config.title]}
                title={config.title}
              />
            }
          >
            {config.content}
          </Collapsible.ListItem>
        ))}
      </Collapsible.List>
    </NavigationPage>
  )
}
