import './styles.scss'
import { HTMLAttributes } from 'react'
import formatClasses from '../../utils/classes/formatClasses'
import {
  Button,
  Grid,
  GridItem,
  Icon,
  IconProps,
  Text,
  TextProps,
} from '../index'
import { getChildrenOnDisplayName } from '../../utils/components'
import getColor from '../../utils/color/getColor'
import React from 'react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  color?: string
  show?: boolean
}

const defaultProps: Partial<Props> = {
  show: true,
}

function Alert(passedProps: Props) {
  const props = { ...defaultProps, ...passedProps }

  const classes = {
    alert: formatClasses(['optiwatt-alert', props.className]),
  }

  const icon = getChildrenOnDisplayName(props.children, 'Icon')
  const text = getChildrenOnDisplayName(props.children, 'Text')
  const close = getChildrenOnDisplayName(props.children, 'Close')

  const styles = {
    background: getColor(props.color || 'grey-100'),
    ...props.style,
  }

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        classNames="alert"
        in={props.show}
        key={props.show ? 'show' : 'hidden'}
        timeout={200}
      >
        {props.show ? (
          <div className={classes.alert} style={styles} id={props?.id}>
            <Grid flow="column" gap="12px" templateColumns="auto 1fr">
              {icon}
              <GridItem placeSelf="center start">
                {text?.length
                  ? text
                  : React.Children.toArray(props.children).filter(
                      (child, index) => index !== 0,
                    )}
              </GridItem>
              <GridItem placeSelf="start">{close}</GridItem>
            </Grid>
          </div>
        ) : (
          <></>
        )}
      </CSSTransition>
    </SwitchTransition>
  )
}

const AlertIcon = (props: IconProps) => {
  return <Icon {...props} size={18} />
}
AlertIcon.displayName = 'Icon'
Alert.Icon = AlertIcon

const AlertText = (props: TextProps) => {
  return <Text variant="body4" {...props} />
}
AlertText.displayName = 'Text'
Alert.Text = AlertText

const AlertClose = (props: { onClose: () => void; color: string }) => {
  return (
    <Button
      className="!p-0"
      variant="icon"
      id="close-button"
      onClick={props.onClose}
    >
      <Icon color={props.color} name="X" size={18} />
    </Button>
  )
}
AlertClose.displayName = 'Close'
Alert.Close = AlertClose

export default Alert
