import { DeviceAuthAlertMessage } from '@/app/components'
import { teslaFleetAuthenticationCollection } from '@/reducers/teslaFleetAuthentication'

export default function FleetMigrationBanner() {
  const { data: fleetAuthentications } =
    teslaFleetAuthenticationCollection.useFetch()
  const fleetAuthorizeUrl = fleetAuthentications[0]?.authorize_url

  return (
    <DeviceAuthAlertMessage
      mode="error"
      deviceName="vehicle"
      linkText="Reconnect Now"
      reconnectUrl={fleetAuthorizeUrl ?? '/connect-vehicle'}
      show
    >
      You're using an old method of connecting to your Tesla Account. To
      maintain access to all control features, please update now.
    </DeviceAuthAlertMessage>
  )
}
