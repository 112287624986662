import dayjs from 'dayjs'

export function timeDiff(timestamp1, timestamp2) {
  const timestamps = [timestamp1, timestamp2].map((timestamp) =>
    timestamp.split(':'),
  )
  const times = timestamps.map((time, index) =>
    dayjs()
      .hour(index === 0 ? time[0] : time[0] === '00' ? '24' : time[0])
      .minute(time[1])
      .second(time[2])
      .format(),
  )

  const minutesDiff = dayjs(times[1]).diff(dayjs(times[0]), 'minute')

  return minutesDiff / 60
}

export default function (_, dayjsClass) {
  dayjsClass.prototype.timeDiff = timeDiff
}
