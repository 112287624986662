import configureStore, { history } from './configure'
export { configureStore, history }

const store = configureStore({})

if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
  window.store = store
}

export { store }

// Infer the `RootState` and `AppDispatch` types from the store itself
export type { RootState } from '../reducers'
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
