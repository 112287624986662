import { useSelector } from 'react-redux'

export const useGetDashboardConfig = (type) => {
  const dashboardConfigs = useSelector(
    (state) => state.user.user?.profile?.dashboard_configs,
  )
  if (!dashboardConfigs) {
    return null
  }
  return dashboardConfigs.find((config) => config.type === type)
}

export default (type, hideIfNotDefined = false) => {
  const dashboardConfigs = useSelector(
    (state) => state.user.user?.profile?.dashboard_configs,
  )
  if (!dashboardConfigs) {
    return true
  }
  // If object does not exist on dashboardConfigs, then hide it if hideIfNotDefined is true
  if (hideIfNotDefined && !dashboardConfigs.map((c) => c.type).includes(type)) {
    return true
  }
  return dashboardConfigs
    .filter((c) => c.should_hide)
    .map((c) => c.type)
    .includes(type)
}
