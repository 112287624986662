import React from 'react'
import { HeroSection } from '../components/HeroSection/HeroSectionSignUp'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useUrlSearchParam } from '@/hooks'

const useRedirectToWhiteLabelIfNeeded = () => {
  const { utility } = useParams()
  if (utility !== undefined) return
  const listing = useUrlSearchParam('listing')
  if (!listing) return
  const location = useLocation()
  const history = useHistory()
  history.replace(`/${listing}${location.pathname}`)
}

export default function SignUp() {
  useRedirectToWhiteLabelIfNeeded()
  return (
    <React.Fragment>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_CAPTCHA_SITE_KEY ?? ''}
      >
        <HeroSection />
      </GoogleReCaptchaProvider>
    </React.Fragment>
  )
}
