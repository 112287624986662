import { useParams } from 'react-router-dom'
import {
  AlertMessage,
  ConfigurableText,
  NavigationPage,
} from '@/app/components'
import { useAppSelector } from '@/hooks'
import { DeviceType } from '@/app/components/DeviceSelectionGallery/DeviceSelectionGallery.types'
import Collapsible from '@/components/collapsibleList'
import { capitalize, capitalizeFirstLetter } from '@/utils/stringUtilities'
import { CollapsibleListItemHeader } from '../components/CollapsibleListItemHeader'
import { Children } from 'react'
import { VehicleLabel } from '@/app/components/TeslaVehiclePermissions/TeslaVehiclePermissions'
import { canCharge } from '@/utils/vehicles/permissions'
import { useNavigation } from '@/app/hooks'
import { Vehicle } from '@/types'
import PageLoader from '@/authenticated/components/layout/PageLoader'
import useNavigateToTeslaKeyPairing from '@/app/hooks/useNavigateToTeslaKeyPairing'
import useRefreshPermissionsIfNeeded from './useRefreshPermissionsIfNeeded'
import { logEvent } from '@/logging'

function getPermissionsEditUrl() {
  const url = new URL(window.location.href)
  url.searchParams.append('refresh_permissions', 'true')
  const backUrl = url.toString()

  const DEFAULT_BASE =
    'https://auth.tesla.com/en_us/user/revoke/consent?revoke_client_id=9da1edefb96f-4837-a344-a028d8fa7fcb'
  const baseUrl =
    process.env.REACT_APP_TESLA_FLEET_ACCOUNT_BASE_URL ?? DEFAULT_BASE
  const encodedBackUrl = encodeURIComponent(backUrl)
  return `${baseUrl}&back_url=${encodedBackUrl}`
}

const List = (props: { title: string; children: React.ReactNode }) => {
  return (
    <div className="flex flex-col gap-2 only:mb-0 mb-2">
      <ConfigurableText variant="body4">{props.title}</ConfigurableText>
      <ul className="list-disc list-outside pl-6 w-full">
        {Children.map(props.children, (child) => (
          <li className="w-full">{child}</li>
        ))}
      </ul>
    </div>
  )
}

function CapabilityLoading() {
  return <PageLoader />
}

function CapabilityLoaded(props: {
  vehicle: Vehicle
  errorRefreshingPermissions: boolean
}) {
  const navigate = useNavigation()
  const permissionsEditUrl = getPermissionsEditUrl()
  const navigateToEditUrl = () => {
    logEvent('capabilities_opened_permissions')
    window.open(permissionsEditUrl, '_blank', 'noopener,noreferrer')
  }
  const {
    isLoading,
    error,
    navigate: navigateToTeslaKeyPairing,
  } = useNavigateToTeslaKeyPairing()

  if (isLoading) {
    return <PageLoader />
  }

  const isTesla = props.vehicle.car.car_model?.make.toLowerCase() === 'tesla'
  const canControlCharge = canCharge(props.vehicle)
  const missingScopes = props.vehicle.car.fleet_scopes?.missing ?? []

  const configStatus = {
    /**=================================**/
    // Tesla capabilities
    'Profile Information': !missingScopes.includes('user_data'),
    'Vehicle Information': !missingScopes.includes('vehicle_device_data'),
    'Vehicle Location': !missingScopes.includes('vehicle_location'),
    'Vehicle Commands': !missingScopes.includes('vehicle_cmds'),
    'Vehicle Charging Management': !missingScopes.includes(
      'vehicle_charging_cmds',
    ),
    'Energy Product Information': !missingScopes.includes('energy_device_data'),
    'Energy Product Settings': !missingScopes.includes('energy_cmds'),
    'Virtual Key': !props.vehicle.car.public_key_not_attached_at,
    /**=================================**/
    // Non-Tesla Capabilities
    'Charge Control': canControlCharge,
    'Vehicle Data': true,
    'Charge Data': true,
  }

  const capabilityConfig: {
    visible: boolean
    title: keyof typeof configStatus
    inError: boolean
    isCorePermission: boolean
    content: React.ReactNode
    extraContent?: React.ReactNode
  }[] = [
    {
      visible: isTesla,
      title: 'Virtual Key',
      inError: !configStatus['Virtual Key'],
      isCorePermission: false,
      content: configStatus['Virtual Key'] ? (
        <List title="Your access supports future features including:">
          <ConfigurableText variant="body4">
            Home Energy Insights
          </ConfigurableText>
          <ConfigurableText variant="body4">
            Home Energy Management
          </ConfigurableText>
          <ConfigurableText variant="body4">
            Import Costs or Export Revenue
          </ConfigurableText>
          <ConfigurableText variant="body4">
            Toggle Operation Mode
          </ConfigurableText>
        </List>
      ) : (
        <>
          <List title="Required to support future features including:">
            <ConfigurableText variant="body4">
              Home Energy Insights
            </ConfigurableText>
            <ConfigurableText variant="body4">
              Home Energy Management
            </ConfigurableText>
            <ConfigurableText variant="body4">
              Import Costs or Export Revenue
            </ConfigurableText>
            <ConfigurableText variant="body4">
              Toggle Operation Mode
            </ConfigurableText>
          </List>
          <ConfigurableText
            id="PairVirtualKey"
            variant="link"
            onClick={() => navigateToTeslaKeyPairing(props.vehicle)}
          >
            Click to pair your virtual key
          </ConfigurableText>
        </>
      ),
    },
    {
      visible: isTesla,
      title: 'Profile Information',
      inError: !configStatus['Profile Information'],
      isCorePermission: false,
      content: configStatus['Profile Information'] ? (
        <List title="You have access to features including:">
          <ConfigurableText variant="body4">
            Personalized communication
          </ConfigurableText>
          <ConfigurableText variant="body4">Offers</ConfigurableText>
        </List>
      ) : (
        <List title="Required to access features including:">
          <ConfigurableText variant="body4">
            Personalized communication
          </ConfigurableText>
          <ConfigurableText variant="body4">Offers</ConfigurableText>
        </List>
      ),
    },
    {
      visible: isTesla,
      title: 'Vehicle Information',
      inError: !configStatus['Vehicle Information'],
      isCorePermission: true,
      content: configStatus['Vehicle Information'] ? (
        <List title="You have access to features including:">
          <ConfigurableText variant="body4">
            Save Money or Save The Planet
          </ConfigurableText>
          <ConfigurableText variant="body4">
            Scheduled Departure
          </ConfigurableText>
          <ConfigurableText variant="body4">Trips</ConfigurableText>
        </List>
      ) : (
        <>
          <ConfigurableText variant="body4">
            The vehicle information permission is necessary to continue.
          </ConfigurableText>
          <ConfigurableText
            id="UpdateVehiclePermissions"
            variant="link"
            onClick={navigateToEditUrl}
          >
            Click to upgrade permissions
          </ConfigurableText>
        </>
      ),
    },
    {
      visible: isTesla,
      title: 'Vehicle Location',
      inError: !configStatus['Vehicle Location'],
      isCorePermission: true,
      content: configStatus['Vehicle Location'] ? (
        <List title="You have access to features including:">
          <ConfigurableText variant="body4">Manage Charging</ConfigurableText>
          <ConfigurableText variant="body4">
            Save Money or Save The Planet
          </ConfigurableText>
          <ConfigurableText variant="body4">
            Scheduled Departure
          </ConfigurableText>
          <ConfigurableText variant="body4">Trips</ConfigurableText>
        </List>
      ) : (
        <>
          <ConfigurableText variant="body4">
            The vehicle location permission is necessary to continue.
          </ConfigurableText>
          <ConfigurableText
            id="UpdateVehiclePermissions"
            variant="link"
            onClick={navigateToEditUrl}
          >
            Click to upgrade permissions
          </ConfigurableText>
        </>
      ),
    },
    {
      visible: isTesla,
      title: 'Vehicle Charging Management',
      inError: !configStatus['Vehicle Charging Management'],
      isCorePermission: false,
      content: configStatus['Vehicle Charging Management'] ? (
        <List title="You have access to features including:">
          <ConfigurableText variant="body4">Manage Charging</ConfigurableText>
          <ConfigurableText variant="body4">
            Save Money or Save The Planet
          </ConfigurableText>
          <ConfigurableText variant="body4">
            Scheduled Departure
          </ConfigurableText>
          <ConfigurableText variant="body4">Trips</ConfigurableText>
        </List>
      ) : (
        <>
          <List title="Required to access features including:">
            <ConfigurableText variant="body4">Manage Charging</ConfigurableText>
            <ConfigurableText variant="body4">
              Save Money or Save The Planet
            </ConfigurableText>
            <ConfigurableText variant="body4">
              Scheduled Departure
            </ConfigurableText>
            <ConfigurableText variant="body4">Trips</ConfigurableText>
          </List>
          <ConfigurableText
            id="UpgradeChargingManagement"
            variant="link"
            onClick={navigateToEditUrl}
          >
            Click to upgrade permissions
          </ConfigurableText>
        </>
      ),
    },
    {
      visible: isTesla,
      title: 'Vehicle Commands',
      inError: !configStatus['Vehicle Commands'],
      isCorePermission: false,
      content: configStatus['Vehicle Commands'] ? (
        <List title="You have access to features including:">
          <ConfigurableText variant="body4">
            Climate Preconditioning
          </ConfigurableText>
        </List>
      ) : (
        <>
          <ConfigurableText variant="body4">
            The vehicle commands permission enables the Climate Preconditioning
            feature.
          </ConfigurableText>
          <ConfigurableText
            id="UpdateVehiclePermissions"
            variant="link"
            onClick={navigateToEditUrl}
          >
            Click to upgrade permissions
          </ConfigurableText>
        </>
      ),
    },
    {
      visible: isTesla,
      title: 'Energy Product Information',
      inError: !configStatus['Energy Product Information'],
      isCorePermission: false,
      content: configStatus['Energy Product Information'] ? (
        <List title="You have access to features including:">
          <ConfigurableText variant="body4">
            Home Energy Insights
          </ConfigurableText>
          <ConfigurableText variant="body4">
            Home Energy Management
          </ConfigurableText>
          <ConfigurableText variant="body4">
            Import Costs or Export Revenue
          </ConfigurableText>
          <ConfigurableText variant="body4">
            Toggle Operation Mode
          </ConfigurableText>
        </List>
      ) : (
        <>
          <List title="Required to support future features including:">
            <ConfigurableText variant="body4">
              Home Energy Insights
            </ConfigurableText>
            <ConfigurableText variant="body4">
              Home Energy Management
            </ConfigurableText>
            <ConfigurableText variant="body4">
              Import Costs or Export Revenue
            </ConfigurableText>
            <ConfigurableText variant="body4">
              Toggle Operation Mode
            </ConfigurableText>
          </List>
          <ConfigurableText
            id="UpgradeEnergyProductInformation"
            variant="link"
            onClick={navigateToEditUrl}
          >
            Click to upgrade permissions
          </ConfigurableText>
        </>
      ),
    },
    {
      visible: isTesla,
      title: 'Energy Product Settings',
      inError: !configStatus['Energy Product Settings'],
      isCorePermission: false,
      content: configStatus['Energy Product Settings'] ? (
        <List title="Your access supports future features including:">
          <ConfigurableText variant="body4">
            Home Energy Insights
          </ConfigurableText>
          <ConfigurableText variant="body4">
            Home Energy Management
          </ConfigurableText>
          <ConfigurableText variant="body4">
            Import Costs or Export Revenue
          </ConfigurableText>
          <ConfigurableText variant="body4">
            Toggle Operation Mode
          </ConfigurableText>
        </List>
      ) : (
        <>
          <List title="Required to support future features including:">
            <ConfigurableText variant="body4">
              Home Energy Insights
            </ConfigurableText>
            <ConfigurableText variant="body4">
              Home Energy Management
            </ConfigurableText>
            <ConfigurableText variant="body4">
              Import Costs or Export Revenue
            </ConfigurableText>
            <ConfigurableText variant="body4">
              Toggle Operation Mode
            </ConfigurableText>
          </List>
          <ConfigurableText
            id="UpgradeEnergyProductSettings"
            variant="link"
            onClick={navigateToEditUrl}
          >
            Click to upgrade permissions
          </ConfigurableText>
        </>
      ),
    },
    {
      visible: !isTesla,
      title: 'Charge Control',
      inError: !configStatus['Charge Control'],
      isCorePermission: false,
      content: configStatus['Charge Control'] ? (
        <List title="This vehicle supports:">
          <ConfigurableText variant="body4">
            Save Money or Save The Planet
          </ConfigurableText>
          <ConfigurableText variant="body4">
            Scheduled Departure
          </ConfigurableText>
          <ConfigurableText variant="body4">Trips</ConfigurableText>
        </List>
      ) : (
        <List title="This vehicle does not support:">
          <ConfigurableText variant="body4">
            Save Money or Save The Planet
          </ConfigurableText>
          <ConfigurableText variant="body4">
            Scheduled Departure
          </ConfigurableText>
          <ConfigurableText variant="body4">Trips</ConfigurableText>
        </List>
      ),
    },
    {
      visible: !isTesla,
      title: 'Vehicle Data',
      inError: !configStatus['Vehicle Data'],
      isCorePermission: false,
      content: configStatus['Vehicle Data'] ? (
        <List title="This vehicle supports:">
          <ConfigurableText variant="body4">
            Location information
          </ConfigurableText>
          <ConfigurableText variant="body4">Gasoline savings</ConfigurableText>
        </List>
      ) : (
        <List title="This vehicle does not support:">
          <ConfigurableText variant="body4">
            Location information
          </ConfigurableText>
          <ConfigurableText variant="body4">Gasoline savings</ConfigurableText>
        </List>
      ),
    },
    {
      visible: !isTesla,
      title: 'Charge Data',
      inError: configStatus['Vehicle Data'],
      isCorePermission: false,
      content: configStatus['Charge Data'] ? (
        <List title="This vehicle supports:">
          <ConfigurableText variant="body4">Program rewards</ConfigurableText>
          <ConfigurableText variant="body4">Charging insights</ConfigurableText>
        </List>
      ) : (
        <List title="This vehicle does not support:">
          <ConfigurableText variant="body4">Program rewards</ConfigurableText>
          <ConfigurableText variant="body4">Charging insights</ConfigurableText>
        </List>
      ),
    },
  ]

  const make = capitalize(props.vehicle.car.car_model?.make || '')

  const capabilityToDisplay = capabilityConfig.filter(
    (config) => config.visible,
  )
  const sortedCapabilityToDisplay = [...capabilityToDisplay].sort((a, b) => {
    // First sort by inError
    if (a.inError !== b.inError) {
      return a.inError ? -1 : 1
    }
    // If both have same error status, sort by isCorePermission
    if (a.isCorePermission !== b.isCorePermission) {
      return a.isCorePermission ? -1 : 1
    }
    // If both have same error and core permission status, maintain original order
    return 0
  })

  const showAlert = missingScopes.length > 0
  const iconType = () => {
    return capabilityToDisplay.every((config) => configStatus[config.title])
      ? 'connected'
      : 'missingPermissions'
  }

  return (
    <NavigationPage
      id="capabilities_page"
      title="Capabilities"
      subtitle={`Manage your permissions with ${make}`}
    >
      <AlertMessage
        variant="error"
        show={props.errorRefreshingPermissions}
        className="mb-2"
      >
        Your permissions in Optiwatt might be out of sync with what you have
        enabled. We'll automatically sync them later.
      </AlertMessage>
      <Collapsible.List className="!p-0">
        <Collapsible.ListItem
          className="!pb-0 [&>:first-child]:px-4"
          isOpen={true}
          header={
            <ConfigurableText variant="body4">
              <VehicleLabel
                name={
                  props.vehicle.car.display_name !== ''
                    ? props.vehicle.car.display_name
                    : capitalizeFirstLetter(
                        props.vehicle.car.car_model?.make ?? '',
                      )
                }
                model={props.vehicle.car.car_model?.friendly_name ?? ''}
                iconType={iconType()}
              />
            </ConfigurableText>
          }
        >
          <Collapsible.List className="border-0 border-t-[1px] rounded-t-none">
            {sortedCapabilityToDisplay.map((config, index) => (
              <Collapsible.ListItem
                key={index}
                isOpen={!configStatus[config.title]}
                header={
                  <CollapsibleListItemHeader
                    status={configStatus[config.title] ? 'success' : 'failure'}
                    title={config.title}
                  />
                }
              >
                {typeof config.content === 'string' ? (
                  <ConfigurableText variant="body4" className="pl-8">
                    {config.content}
                  </ConfigurableText>
                ) : (
                  config.content && <div className="pl-8">{config.content}</div>
                )}
                {config.extraContent && (
                  <div className="mt-4">{config.extraContent}</div>
                )}
              </Collapsible.ListItem>
            ))}
          </Collapsible.List>
        </Collapsible.ListItem>
      </Collapsible.List>
      <AlertMessage show={showAlert} variant="info" className="mt-4">
        {`Don't want to enable vehicle permissions? Connect your charger instead. `}
        <ConfigurableText
          id="ConnectChargerButton"
          variant="link"
          onClick={() => navigate.push('/connect-device/charger')}
        >
          {`Connect Smart Charger`}
        </ConfigurableText>
      </AlertMessage>
    </NavigationPage>
  )
}

export default function CapabilityPage() {
  type PathParams = {
    localGridId: string
    deviceType: DeviceType | undefined
    deviceId: string | undefined
  }
  const { deviceId } = useParams<PathParams>()
  const vehiclesLoading = useAppSelector((state) => state.vehicles.isLoading)
  const selectedVehicle = useAppSelector((state) => {
    if (!deviceId) return null
    return state.vehicles.vehicles?.find(
      (vehicle) => vehicle.id === parseInt(deviceId),
    )
  })
  const {
    isLoading: isLoadingPermissionRefresh,
    error: permissionRefreshError,
  } = useRefreshPermissionsIfNeeded(selectedVehicle?.id)

  if (!selectedVehicle || vehiclesLoading || isLoadingPermissionRefresh) {
    return <CapabilityLoading />
  }

  return (
    <CapabilityLoaded
      vehicle={selectedVehicle}
      errorRefreshingPermissions={permissionRefreshError}
    />
  )
}
