import { useState } from 'react'
import {
  Button as MuiButton,
  Menu,
  MenuItem,
  Grid as MuiGrid,
  Box,
} from '@material-ui/core'
import { ChevronDown } from 'react-feather'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/styles'
import { spacing } from '@material-ui/system'
import clsx from 'clsx'

const Button = styled(MuiButton)(spacing)
const Grid = styled(MuiGrid)(spacing)

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.blue.main,
    color: theme.palette.blue.contrastText,
    '&:hover': {
      backgroundColor: '#3c66e8',
    },
  },
  menu: {
    '& .MuiMenu-paper': {
      borderRadius: '10px',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
      marginTop: theme.spacing(2),
    },
  },
  gridContainer: {
    width: '225px',
  },
  dayMenuItem: {
    justifyContent: 'center',
    fontSize: '0.75rem',
  },
}))

export default function DaySelector({
  day,
  onSelect,
  textRender,
  buttonClassName,
  fontSize,
  ...restProps
}) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const pickedDay = day

  const possibleDays = [...Array(28).keys()].map((i) => i + 1)

  const handleClickText = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuItemClick = (event) => {
    onSelect(Number(event.target.getAttribute('value')))
    setAnchorEl(null)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const NumberGrid = () => (
    <Grid container className={classes.gridContainer}>
      {possibleDays.flatMap((day) => [
        (day - 1) % 5 === 0 ? (
          <Grid item xs={1} key={`${day}-start-spacer`}></Grid>
        ) : null,
        <Grid item xs={2} key={`${day}`}>
          <MenuItem
            className={classes.dayMenuItem}
            value={day}
            onClick={handleMenuItemClick}
            selected={day === pickedDay}
            disableGutters
          >
            {day}
          </MenuItem>
        </Grid>,
        day % 5 === 0 ? (
          <Grid item xs={1} key={`${day}-end-spacer`}></Grid>
        ) : null,
      ])}
    </Grid>
  )

  return (
    <Box {...restProps}>
      <Button
        aria-label="Select a day"
        className={clsx(buttonClassName, classes.button)}
        onClick={handleClickText}
        style={{ fontSize: fontSize }}
        variant="contained"
      >
        {textRender(pickedDay)}
        &nbsp;
        <ChevronDown width={fontSize} />
      </Button>
      <Menu
        className={classes.menu}
        id="day-menu-selector"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <NumberGrid />
      </Menu>
    </Box>
  )
}
