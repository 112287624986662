import {
  Button,
  CardAction,
  CardActions,
  Grid,
  Modal,
  ModalProps,
} from '@/components'
import { EnrollConfirmationModal } from '@/types/utilityProgram'
import formatClasses from '@/utils/classes/formatClasses'
import { DocumentRenderer } from '@keystone-6/document-renderer'
import { useAppSelector } from '@/hooks'
import { selectUtilityProgramForUserUtility } from '@/reducers/utilityPrograms'
import { ViewModelComponentProps } from '@/utils/typeUtilities'
import './styles.scss'
import { ConfigurableText } from '@/app/components'
import { logEvent } from '@/logging'

export interface ComponentProps extends Omit<ModalProps, 'title'> {
  onConfirmationClick: () => void
  viewConfig?: EnrollConfirmationModal
}

function UtilityProgramEnrollmentConfirmationModalRoot(props: ComponentProps) {
  const { viewConfig, onConfirmationClick, ...modalProps } = props
  const classes = {
    modalContent: formatClasses(['enrollment-modal-content']),
    modalTosWindow: formatClasses(['modal-tos-window']),
  }

  if (!props.viewConfig) {
    return null
  }

  const enroll = () => {
    logEvent(['request_program_enrollment'])
    onConfirmationClick()
  }

  return (
    <Modal title={props.viewConfig.title} {...modalProps}>
      <Grid className={classes.modalContent} gap="20px" flow="row">
        <ConfigurableText
          variant="body2"
          config={props.viewConfig.description}
        />
        {props.viewConfig.tosDocument && (
          <div className={classes.modalTosWindow}>
            <div>
              <DocumentRenderer document={props.viewConfig.tosDocument} />
            </div>
          </div>
        )}
        <CardActions>
          <CardAction type="primary">
            <Button
              variant="secondary"
              onClick={props.onClose}
              id="utility-program-enrollment-prompt-enroll-back-button"
            >
              Back
            </Button>
          </CardAction>
          <CardAction type="primary">
            <Button
              variant="primary"
              onClick={enroll}
              style={
                props.viewConfig.primaryButton
                  ? {
                      backgroundColor:
                        props.viewConfig.primaryButton.backgroundColor,
                      color: props.viewConfig.primaryButton.color,
                    }
                  : {}
              }
              id="utility-program-enrollment-prompt-enroll-confirmation-accept-button"
            >
              Accept
            </Button>
          </CardAction>
        </CardActions>
      </Grid>
    </Modal>
  )
}

function useViewModel() {
  const viewConfig = useAppSelector(
    (state) =>
      selectUtilityProgramForUserUtility(state)?.view_config_json
        .enrollmentConfirmationModal,
  )

  return {
    viewConfig,
  }
}

export type Props = ViewModelComponentProps<ComponentProps, typeof useViewModel>

export default function UtilityProgramEnrollmentConfirmationModal(
  props: Props,
) {
  const viewModel = useViewModel()
  return (
    <UtilityProgramEnrollmentConfirmationModalRoot {...viewModel} {...props} />
  )
}
