import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { thermostatsCollection } from '../../../../reducers/thermostats'
import dayjs from 'dayjs'
import mockAdjustment from '../../../../types/thermostatAdjustment/mock'
import mockThermostats from '../../../../types/thermostat/mock'
import {
  ThermostatMode,
  ThermostatState,
} from '../../../../types/thermostatMeasurement'
import { useCallback, useMemo, useState } from 'react'
import { debounce } from '../../../../utils/limit'
import { getAutoAdjustedHvacMode } from '../../../../utils/thermostats'
import useMockableViewModel from '../../../../hooks/useMockableViewModel'
import {
  selectLatestAdjustmentForThermostat,
  selectLatestMeasurementForThermostat,
} from '../../../../selectors'
import { useUsersPreferredTemperature } from '../../../../authenticated/hooks/useUsersPreferredTemperature'
import { selectUserHasMadePlanSelection } from '@/selectors'
import selectUserHasPendingPlan from '@/selectors/selectUserHasPendingPlan'

interface Props {
  id: number
}

const maximumTemperature = 90
const minimumTemperature = 50

/**
 * Scales a value using a lower and upper bound.
 *
 * e.g.,
 *  Given an input of 0 and a range of 25 to 75, returns 25
 *  Given an input of 100 and a range of 25 to 75, returns 75
 *
 * @param value - The value you want to scale
 * @param range - Minimum and maximum output values
 */
export function percentToDegrees(value: number, range: number[]) {
  const [min, max] = range
  const percent = value / 100

  return Math.round(percent * (max - min) + min)
}

/**
 * Converts a number of degrees back to a percentage value (between 0 and 100)
 *
 * @param value - The value you want to scale
 * @param range - Minimum and maximum output values
 */
function degreesToPercent(value: number, range: number[]) {
  const [min, max] = range

  return Math.round(((value - min) / (max - min)) * 100)
}

const startDate = dayjs().format()
const endDate = dayjs().format()

const loadingPayload = {
  loading: true,
  adjustment: {},
  measurement: {},
  disabled: true,
  value: 0,
  setValue: (_: number) => {},
  temperature: 0,
  indoorTemperature: 0 as number | undefined,
  indoorTemperaturePercent: 0,
  thermostatMode: 'cool' as ThermostatMode,
  hvacMode: 'cool' as 'heat' | 'cool' | 'off',
  setHvacMode: (_: 'heat' | 'cool' | 'off') => {},
  hvacState: 'off' as ThermostatState,
}

function useViewModel(props: Props) {
  const dispatch = useAppDispatch()

  const adjustmentsFetcher = thermostatsCollection.useFetchAnnotation(
    props.id,
    'adjustments',
    {
      start_date: startDate,
      end_date: endDate,
      page_size: 1,
    },
  )

  const thermostatAdjustment = useAppSelector((state) =>
    selectLatestAdjustmentForThermostat(state, props.id),
  )
  const thermostatMeasurement = useAppSelector((state) => {
    const adjustmentsLoaded = !['idle', 'loading'].includes(
      adjustmentsFetcher.selectors.status(state),
    )
    if (!adjustmentsLoaded) {
      return undefined
    }
    return selectLatestMeasurementForThermostat(state, props.id)
  })

  const disabled =
    useAppSelector((state) => {
      return (
        !selectUserHasMadePlanSelection(state) ||
        selectUserHasPendingPlan(state)
      )
    }) ?? true

  // Used to position current indoor temperature on the thermostat slider
  const indoorTemperature = useUsersPreferredTemperature(
    thermostatMeasurement?.indoor_temperature ?? 0,
  ).value
  // The controllable values of the thermostat. We group slider value and mode together because if we update the mode,
  // we need to update the slider value to match the new mode. Grouping them together makes it easier to keep them in sync
  // without multiple states and useEffects
  const [internalThermostatValues, setThermostatValues] = useState<{
    value: number | null
    hvacMode: 'cool' | 'heat' | 'off' | null
    thermostatId: number
  }>({
    // The value of the `<input type="range">` that controls the slider behind the scenes
    value: null,
    // This is the hvacMode that defaults to heat or cool when not in auto mode, and uses the user selected
    hvacMode: null,
    // We need the thermostat id to make sure we dont use the internal values for a different thermostat
    thermostatId: props.id,
  })
  const getActiveSetpoint = useCallback((hvacMode: 'cool' | 'heat' | 'off') => {
    return (hvacMode !== 'off' ? `${hvacMode}_setpoint` : undefined) as
      | 'cool_setpoint'
      | 'heat_setpoint'
      | undefined
  }, [])
  // thermostat values takes in to consideration the user inputted values, as well as the thermostat measurement
  // to determine what values to display. We use the user inputted values if they exist, otherwise we use the thermostat.
  const thermostatValues = useMemo(() => {
    const userInputtedValues =
      internalThermostatValues.thermostatId === props.id
        ? internalThermostatValues
        : undefined

    const mode =
      userInputtedValues?.hvacMode ??
      getAutoAdjustedHvacMode(
        thermostatMeasurement?.hvac_mode ?? 'off',
        String(thermostatMeasurement?.api_event_timestamp),
      )
    const activeSetpoint = getActiveSetpoint(mode)
    if (!activeSetpoint) {
      return {
        value: userInputtedValues?.value ?? 0,
        hvacMode: mode,
      }
    }
    const value =
      userInputtedValues?.value ??
      degreesToPercent(thermostatMeasurement?.[activeSetpoint] ?? 0, [
        minimumTemperature,
        maximumTemperature,
      ])
    return {
      hvacMode: mode,
      value,
    }
  }, [internalThermostatValues, props.id, thermostatMeasurement])

  // Input value scaled to be between 50 degrees and 90 degrees
  const temperature = useUsersPreferredTemperature(
    percentToDegrees(thermostatValues.value ?? 0, [
      minimumTemperature,
      maximumTemperature,
    ]),
  ).value

  // Sets the hvacMode and updates the thermostat values
  const changeHvacMode = (newHvacMode: 'heat' | 'cool' | 'off') => {
    setThermostatValues({
      ...internalThermostatValues,
      hvacMode: newHvacMode,
      thermostatId: props.id,
    })
  }

  /**
   * Sends an update request to the API to update the thermostat adjustment
   */
  const updateThermostatTemperature = useCallback(
    debounce(async (temperature: number) => {
      const activeSetpoint = getActiveSetpoint(thermostatValues.hvacMode)
      if (!activeSetpoint) return

      if (
        thermostatAdjustment?.[activeSetpoint] !== temperature &&
        thermostatMeasurement?.[activeSetpoint] !== temperature
      ) {
        const response = await dispatch(
          thermostatsCollection.actions.createAnnotation(
            props.id,
            'adjustments',
            {
              [activeSetpoint]: temperature,
              previous_setpoint_to_use: 'schedule',
            },
          ),
        )
        if (response.type.includes('CREATE_ANNOTATION_FAILURE')) {
          setThermostatValues({
            value: degreesToPercent(
              thermostatMeasurement?.[activeSetpoint] ?? 0,
              [minimumTemperature, maximumTemperature],
            ),
            hvacMode: getAutoAdjustedHvacMode(
              thermostatMeasurement?.hvac_mode ?? 'off',
              String(thermostatMeasurement?.api_event_timestamp),
            ),
            thermostatId: props.id,
          })
        }
      }
    }, 500),
    [props.id, thermostatAdjustment?.id, thermostatValues.hvacMode],
  )

  function onPointerActionEnd() {
    updateThermostatTemperature(
      percentToDegrees(thermostatValues.value, [
        minimumTemperature,
        maximumTemperature,
      ]),
    )
  }

  // if the adjustment is populated or null, then adjustments were previously loaded
  if (thermostatAdjustment === undefined) {
    return { ...loadingPayload, onPointerActionEnd }
  }
  return {
    ...props,
    loading: false,
    disabled,
    adjustment: thermostatAdjustment ?? {},
    measurement: thermostatMeasurement ?? {},
    value: thermostatValues.value,
    setValue: (value: number) =>
      setThermostatValues({
        ...thermostatValues,
        value,
        thermostatId: props.id,
      }),
    temperature,
    indoorTemperature,
    indoorTemperaturePercent: degreesToPercent(indoorTemperature ?? 0, [
      minimumTemperature,
      maximumTemperature,
    ]),
    // This is the mode the thermostat is in, including auto mode
    thermostatMode: thermostatMeasurement?.hvac_mode ?? 'off',
    // This ist the mode the hvac is in, not including auto mode, which is a function of thermostat mode
    hvacMode: thermostatValues.hvacMode,
    setHvacMode: changeHvacMode,
    hvacState: thermostatMeasurement?.hvac_state ?? 'off',
    onPointerActionEnd,
  }
}

function useMockViewModel(props: Props) {
  const thermostatAdjustment = mockAdjustment
  const thermostatMeasurement = mockThermostats[0].last_measurement
  // The value of the `<input type="range">` that controls the slider behind the scenes
  const [value, setValue] = useState(0)
  // Used to position current indoor temperature on the thermostat slider
  const [indoorTemperature] = useState<number | undefined>(undefined)
  // Input value scaled to be between 50 degrees and 90 degrees
  const temperature = percentToDegrees(value, [
    minimumTemperature,
    maximumTemperature,
  ])
  const [hvacMode, setHvacMode] = useState(
    getAutoAdjustedHvacMode(
      thermostatMeasurement?.hvac_mode ?? 'off',
      String(thermostatMeasurement?.api_event_timestamp),
    ),
  )

  return {
    ...props,
    disabled: false,
    loading: false,
    adjustment: thermostatAdjustment,
    measurement: thermostatMeasurement,
    value,
    setValue,
    temperature,
    indoorTemperature,
    indoorTemperaturePercent: degreesToPercent(indoorTemperature ?? 0, [
      minimumTemperature,
      maximumTemperature,
    ]),
    thermostatMode: thermostatMeasurement?.hvac_mode ?? 'off',
    hvacMode: hvacMode,
    setHvacMode,
    hvacState: thermostatMeasurement?.hvac_state ?? 'off',
    onPointerActionEnd: () => {},
  } as const
}

export default useMockableViewModel({ useViewModel, useMockViewModel })
