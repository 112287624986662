import { Vehicle } from '@/types'
import { capitalize } from '@/utils/stringUtilities'

function findVehicleName(vehicle: Vehicle | undefined) {
  if (!vehicle) {
    return 'Vehicle Not Found'
  }

  const car = vehicle.car
  if (!car) {
    return 'Your Vehicle'
  }

  if (car.display_name) {
    return vehicle.car.display_name
  }

  return getVehicleManufacturedName(vehicle)
}

export function getVehicleManufacturedName(vehicle: Vehicle | undefined) {
  if (!vehicle) {
    return 'Vehicle Not Found'
  }

  const car = vehicle.car
  if (!car) {
    return 'Your Vehicle'
  }

  if (car.car_model?.friendly_name) {
    return car.car_model.friendly_name
  }

  if (car.car_model) {
    const displayModel = car.car_model.friendly_model ?? car.car_model.model
    return `${car.car_model.year} ${capitalize(
      car.car_model.make,
    )} ${capitalize(displayModel)}`
  }

  return 'Your Vehicle'
}

export function getVehicleDisplayName(
  vehicle: Vehicle | undefined,
  includeYear?: boolean,
) {
  const name = capitalize(findVehicleName(vehicle))
  return includeYear
    ? `${vehicle?.car?.car_model?.year ?? ''} ${name}`.trim()
    : name
}

type VehicleCarResource = {
  car: {
    display_name: string
    car_model: {
      make: string
      model: string
      friendly_model: string | null
    } | null
  }
}
export function getVehicleDisplayModel<V extends VehicleCarResource>(
  vehicle: V,
) {
  const car = vehicle.car
  if (!car) {
    return ''
  }

  const carModel = car.car_model
  if (!carModel) {
    return ''
  }

  const displayModel = carModel.friendly_model ?? carModel.model
  return displayModel
}

export default { getVehicleDisplayName }
