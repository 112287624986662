import { useEffect, useState } from 'react'

type Props = {
  onChange: (value: number) => void
  initialValue?: number
  value?: number
}

export default function useViewModel(props: Props) {
  const [count, setCount] = useState(props.initialValue ?? 0)
  const handleCountChange = (value: number) => {
    setCount((previousValue) => previousValue + value)
  }

  useEffect(() => {
    props.onChange(count)
  }, [count])

  return {
    count: props.value ?? count,
    handleCountChange,
  }
}
