import { useAppSelector } from '../../hooks'
import { ApiProvider } from '../../types/utility/'

export default function useTeslaGiveawayPromoActive(apiProvider?: ApiProvider) {
  const date = new Date()
  const promoExpired = date.getFullYear() >= 2023
  const apiProviderIsLeap = useAppSelector(
    (state) =>
      (apiProvider ?? state.utilities.selectedUtility?.api_provider) ===
      ApiProvider.Leap,
  )
  return apiProviderIsLeap && !promoExpired
}
