import React, { useCallback, useState } from 'react'
import MultiselectButton from '@/app/components/Multiselect/MultiselectButton'
import { useForm } from 'react-hook-form'
import TextFormField from '@/components/forms/text-form-field'

type Props = {
  options: MultiselectOption[]
  onChange?: (newOptions: MultiselectOption[]) => void
  includeOther?: boolean
  onOtherChange?: (other: string | undefined) => void
}

export type MultiselectOption = {
  text: string
  value: string
  selected?: boolean
}

export default function Multiselect(props: Props) {
  const [values, setValues] = useState(props.options)
  const [otherEnabled, setOtherEnabled] = useState(false)
  const [other, setOther] = useState<string>()
  const { register, reset: formReset } = useForm()

  const onClick = useCallback(
    (index: number, selected: boolean) => {
      if (index < 0 || index >= values.length) {
        return
      }

      const newValues = values.map((option, idx) =>
        idx === index ? { ...option, selected } : option,
      )

      setValues(newValues)
      props.onChange?.(newValues)
    },
    [values, props.onChange],
  )

  const onOtherChange = (val: { currentTarget: { value: string } }) => {
    setOther(val.currentTarget.value)
    props.onOtherChange?.(val.currentTarget.value)
  }

  const toggleOther = () => {
    setOtherEnabled((prev) => {
      if (prev) {
        formReset()
        setOther(undefined)
      }

      return !prev
    })
  }

  return (
    <>
      <div className="flex flex-wrap gap-2.5 mt-2.5">
        {props.options.map((option, idx) => {
          return (
            <MultiselectButton
              key={option.value}
              option={option}
              index={idx}
              onClick={onClick}
            ></MultiselectButton>
          )
        })}
        {props.includeOther && (
          <MultiselectButton
            option={{ text: 'Other', value: 'other' }}
            index={props.options.length}
            onClick={toggleOther}
          />
        )}
      </div>
      <div className="w-full">
        {otherEnabled && (
          <div className="mt-4">
            <TextFormField
              name={'other_text'}
              label="Other"
              register={register}
              disabled={!otherEnabled}
              onChange={onOtherChange}
            ></TextFormField>
          </div>
        )}
      </div>
    </>
  )
}
