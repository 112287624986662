import UtilityProgramEnrollmentPromptRoot, {
  Props as ComponentProps,
} from './UtilityProgramEnrollmentPrompt'
import useViewModel from './useViewModel'
import { ViewModelComponentProps } from '../../../../utils/typeUtilities'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from '../../../../v2/theme'

export type Props = ViewModelComponentProps<ComponentProps, typeof useViewModel>

export default function UtilityProgramEnrollmentPrompt(props: Props) {
  const viewModel = useViewModel(props)
  return (
    <ThemeProvider theme={theme}>
      <UtilityProgramEnrollmentPromptRoot {...viewModel} {...props} />
    </ThemeProvider>
  )
}
