export const optiwattConsts = {
  contact: {
    phoneNumber: '+1 (925) 492-7585',
    email: 'support@optiwatt.com',
    website: 'https://optiwatt.com',
    location: 'San Francisco, CA',
  },
  social: {
    facebook: 'https://www.facebook.com/optiwatt/',
    twitter: 'https://twitter.com/optiwatt',
    youtube: 'https://www.youtube.com/channel/UC3hhn3AJdZCxFJ_30wyQCdQ',
    instagram: 'https://www.instagram.com/optiwatt/',
  },
}
