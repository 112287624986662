import styled from 'styled-components'
import { X as Close } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { patchConfig } from '../../../actions/dashboardConfigs'

import '../../vendor/roundedBarCharts'

import {
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography as MuiTypography,
  IconButton,
} from '@material-ui/core'

import { spacing } from '@material-ui/system'
import { makeStyles } from '@material-ui/styles'
import { useGetDashboardConfig } from '../../hooks/useHideDashboardConfig'

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.secondary.main,
  },
  cardContent: {
    position: 'relative',
    backgroundColor: theme.palette.secondary.main,
    '&:last-child': {
      paddingBottom: `${theme.spacing(4)}px`,
    },
  },
  header: {
    fontWeight: 900,
    color: theme.palette.common.white,
    fontSize: 16,
  },
  body: {
    color: theme.palette.common.white,
    lineHeight: 1.35,
    fontSize: 14,
  },
  iconWrapper: {
    position: 'absolute',
    zIndex: 100,
    right: 4,
    top: 4,
  },
  icon: {
    color: theme.palette.common.white,
    height: 16,
    width: 16,
  },
}))

const Card = styled(MuiCard)(spacing)
const Typography = styled(MuiTypography)(spacing)

const ActionableWinterWeatherAlertCard = (_props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const profile = useSelector((state) => state.user?.user?.profile)
  const home = useSelector((state) => state.homes.selectedHome)

  const infoWinterWeatherAlertConfig = useGetDashboardConfig(
    'INFO_WINTER_WEATHER_ALERT',
  )

  // Hide this alert from this profile
  const onDismiss = () => {
    dispatch(
      patchConfig(
        infoWinterWeatherAlertConfig.id,
        'INFO_WINTER_WEATHER_ALERT',
        profile.id,
      ),
    )
  }

  return (
    <Box position="relative">
      <Card mb={6} pt={2} className={classes.card} px={4}>
        <IconButton onClick={onDismiss} className={classes.iconWrapper}>
          <Close className={classes.icon} />
        </IconButton>
        <MuiCardContent className={classes.cardContent}>
          <Typography variant="h6" mb={2} className={classes.header}>
            Prepare for {home?.state?.name} Winter Storm{' '}
            <span role="img" aria-label="warning">
              ⚠️
            </span>
            <span role="img" aria-label="snow">
              ❄️
            </span>
          </Typography>
          <Typography variant="body1" mb={3} className={classes.body}>
            <Box fontWeight="fontWeightRegular">
              Heavy mixed precipitation is expected across the state, with
              greatest snow and/or ice predicted in Northeast Texas.
            </Box>
            <Box fontWeight="fontWeightRegular" mt={4}>
              Power outages are likely, and travel could be dangerous. Prepare
              accordingly!
            </Box>
          </Typography>
        </MuiCardContent>
      </Card>
    </Box>
  )
}

export default ActionableWinterWeatherAlertCard
