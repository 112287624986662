import { Button, Icon, LoadingAnimation, Text } from '@/components'
import BrandedPage from '@/app/pages/ava/BrandedPage'
import { useNavigation } from '@/app/hooks'
import { ChangeEvent, useRef, useState } from 'react'
import {
  UPLOAD_UTILITY_BILL_SUCCESS,
  uploadUtilityBillForm,
} from '@/actions/plans'
import { RSAAResultAction } from 'redux-api-middleware'
import { useAppDispatch } from '@/hooks'
import { logEvent } from '@/logging'
import { AlertMessage } from '@/app/components'
import { AnimatePresence, motion } from 'framer-motion'
import useEnrolledDeviceSelections from '@/app/hooks/useEnrolledDeviceSelections'

const MAX_FILE_SIZE_MB = 20

const useViewModel = () => {
  const dispatch = useAppDispatch()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const { getEnrolledDeviceRoute } = useEnrolledDeviceSelections()
  const navigation = useNavigation()

  const [fileUploadName, setFileUploadName] = useState<string | null>(null)
  const [fileUploadStatus, setFileUploadStatus] = useState<
    'idle' | 'loading' | 'success' | 'error'
  >('idle')
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const handleFilesUploaded = async (event: ChangeEvent<HTMLInputElement>) => {
    console.log('handleFilesUploaded')
    setErrorMessage(null)
    setFileUploadName(null)

    const files = event.target.files

    if (!files) {
      setFileUploadStatus('idle')
      return
    }

    setFileUploadStatus('loading')

    const data = new FormData()
    data.append('file', files[0])

    const uploadResponse: RSAAResultAction = await dispatch(
      uploadUtilityBillForm(data),
    )

    if (uploadResponse.type === UPLOAD_UTILITY_BILL_SUCCESS) {
      setFileUploadName(files[0].name)
      setFileUploadStatus('success')
      logEvent('utility_bill_upload')
    } else {
      setFileUploadStatus('error')
      const fileSizeInMB = files[0]?.size / 1024 / 1024
      if (fileSizeInMB >= MAX_FILE_SIZE_MB) {
        setErrorMessage(
          `Photo upload failed. Please try again with a smaller file. (Max size: ${MAX_FILE_SIZE_MB}MB)`,
        )
      } else {
        setErrorMessage('Photo upload failed. Please try again.')
      }
      logEvent('utility_bill_upload_failed')
    }
  }

  const handleUpload = () => {
    fileInputRef.current?.click()
  }

  const onSubmit = () => {
    navigation.push(getEnrolledDeviceRoute())
  }

  return {
    fileInputRef,
    fileUploadStatus,
    fileUploadName,
    errorMessage,
    handleFilesUploaded,
    handleUpload,
    onSubmit,
  }
}

function AnimatedAlertMessage(props: {
  message: string
  submessage?: string
  variant: 'success' | 'error'
  className?: string
}) {
  return (
    <motion.div
      key="alert-message"
      className={`overflow-hidden ${props.className}`}
      initial={{ height: 0, opacity: 0 }}
      animate={{ height: 'auto', opacity: 1 }}
      exit={{ height: 0, opacity: 0 }}
    >
      <AlertMessage variant={props.variant}>
        <Text className="text-initial">{props.message}</Text>
        {props.submessage && (
          <Text variant="label" className="mt-1 text-initial">
            {props.submessage}
          </Text>
        )}
      </AlertMessage>
    </motion.div>
  )
}

export default function PlanSelectOptionSelectPage() {
  const {
    fileUploadStatus,
    fileUploadName,
    errorMessage,
    handleUpload,
    handleFilesUploaded,
    fileInputRef,
    onSubmit,
  } = useViewModel()
  return (
    <BrandedPage title="Upload your utility bill" id="plan-select-upload-page">
      <BrandedPage.Content>
        <Text variant="body2" className="font-medium mb-5">
          Please upload your most recent full utility bill that shows the exact
          rates you pay for your electricity. This will integrate your rates
          with your Optiwatt account.
        </Text>
        <AnimatePresence>
          {['success', 'error'].includes(fileUploadStatus) && (
            <AnimatedAlertMessage
              className="mb-4"
              message={
                fileUploadStatus === 'success'
                  ? 'Photo successfully uplaoded'
                  : errorMessage ?? 'Photo upload failed. Please try again.'
              }
              submessage={fileUploadName ?? undefined}
              variant={fileUploadStatus === 'success' ? 'success' : 'error'}
            />
          )}
        </AnimatePresence>
        <Button
          variant="click-wrapper"
          id="upload-button"
          onClick={handleUpload}
          disabled={['loading', 'success'].includes(fileUploadStatus)}
        >
          <Icon name="Upload" size={24} color="grey-900" />
          <Text variant="link" className="text-themed-blue-500">
            Upload
          </Text>
          <input
            type="file"
            id="upload-input"
            className="absolute w-0 h-0 opacity-0 overflow-hidden"
            ref={fileInputRef}
            onChange={handleFilesUploaded}
          />
        </Button>
        {fileUploadStatus === 'loading' && (
          <div className="w-full flex justify-center">
            <LoadingAnimation type="falling" size="small" />
          </div>
        )}
        <BrandedPage.ButtonContainer>
          <Button
            id="next-button"
            variant="primary"
            disabled={fileUploadStatus !== 'success'}
            onClick={onSubmit}
          >
            Next
          </Button>
        </BrandedPage.ButtonContainer>
      </BrandedPage.Content>
    </BrandedPage>
  )
}
