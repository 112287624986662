import SelectInputRoot, {
  Props as ComponentProps,
  SelectOption,
} from './SelectInput'
import useViewModel from './useViewModel'
import { ViewModelComponentProps } from '../../../utils/typeUtilities'

type Props = ViewModelComponentProps<ComponentProps, typeof useViewModel>

export default function SelectInput(props: Props) {
  const viewModel = useViewModel(props)
  return <SelectInputRoot {...viewModel} {...props} />
}

export type { Props, SelectOption }
