import React from 'react'

import { Helmet } from 'react-helmet'
import Header from '../components/Header/header'
import HeroSection from '../components/HeroSection/HeroSectionFaq'
import FaqSection from '../components/Faq/CepciFaq'
import FooterSection from '../components/Footer'

const CepciFaq = (props) => {
  return (
    <React.Fragment>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Header />
      <div className="main _use-deprecated-bootstrap _use-deprecated-style">
        <HeroSection />
        <FaqSection {...props} />
      </div>
      <FooterSection noSubscription={true} />
    </React.Fragment>
  )
}

export const AikenFaq = (props) => {
  return <CepciFaq utility="aiken" />
}

export const BerkeleyFaq = (props) => {
  return <CepciFaq utility="berkeley" />
}

export const BroadRiverFaq = (props) => {
  return <CepciFaq utility="broad_river" />
}

export const HorryFaq = (props) => {
  return <CepciFaq utility="horry" />
}

export const LaurensFaq = (props) => {
  return <CepciFaq utility="laurens" />
}

export const MidCarolinaFaq = (props) => {
  return <CepciFaq utility="mid_carolina" />
}

export const NewberryFaq = (props) => {
  return <CepciFaq utility="newberry" />
}

export const PalmettoFaq = (props) => {
  return <CepciFaq utility="palmetto" />
}

export const SanteeFaq = (props) => {
  return <CepciFaq utility="santee" />
}

export const YorkFaq = (props) => {
  return <CepciFaq utility="york" />
}
