import {
  Box as MuiBox,
  Grid as MuiGrid,
  Typography as MuiTypography,
  Button,
  IconButton,
} from '@material-ui/core'
import clsx from 'clsx'
import { X } from 'react-feather'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import { makeStyles, createStyles } from '@material-ui/core/styles'

const Box = styled(MuiBox)(spacing)
const Grid = styled(MuiGrid)(spacing)
const Typography = styled(MuiTypography)(spacing)

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      position: 'relative',
      width: '100%',
      borderRadius: '10px',
      // @ts-expect-error imgSource is not a valid CSS property
      backgroundImage: ({ imgSource }) => `url("${imgSource}")`,
      backgroundSize: 'cover',
      padding: '24px !important',
    },
    textContainer: {
      width: '60%',
    },
    headerText: {
      color: 'white',
      fontSize: '1.2rem',
    },
    bodyText: {
      color: 'white',
      fontSize: '0.7rem',
    },
    button: {
      backgroundColor: 'rgb(248,241,110)',
      borderRadius: '10px',
      borderWidth: 0,
      height: '40px',
      padding: '0px',
      width: '100%',
    },
    buttonText: {
      fontSize: '0.7rem',
      fontWeight: 'bold',
    },
    buttonLabel: {
      padding: theme.spacing(1),
    },
    iconButton: {
      position: 'absolute',
      top: theme.spacing(3),
      right: theme.spacing(3),
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      padding: '4px',
    },
  }),
)

type Props = {
  onClose: () => void
  title: string
  subtitle?: string
  subtitleComponent?: React.ReactNode
  buttonText: string
  buttonClass?: string
  buttonTextClass?: string
  imgSource: string
}

function ReferralCard({
  onClose,
  title,
  subtitle,
  subtitleComponent,
  buttonText,
  buttonClass,
  buttonTextClass,
  imgSource,
}: Props) {
  const classes = useStyles({ imgSource })

  return (
    <Box className={classes.container} p={6}>
      <Grid container className={classes.textContainer} spacing={3}>
        <Grid item xs={12}>
          <Typography className={classes.headerText} variant="h2">
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {subtitleComponent || (
            <Typography className={classes.bodyText} variant="body1">
              {subtitle}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Button
            className={clsx(classes.button, buttonClass)}
            classes={{ label: classes.buttonLabel }}
            variant="outlined"
            href="signup"
          >
            <Typography
              className={clsx(classes.buttonText, buttonTextClass)}
              variant="body1"
            >
              {buttonText}
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <IconButton className={classes.iconButton} onClick={onClose}>
        <X size={16} color="white" />
      </IconButton>
    </Box>
  )
}

export default ReferralCard
