import YourLastAdjustmentCardRoot, {
  Props as ComponentProps,
} from './YourLastAdjustmentCard'
import useViewModel from './useViewModel'
import { ViewModelComponentProps } from '../../../../utils/typeUtilities'

export type Props = ViewModelComponentProps<ComponentProps, typeof useViewModel>

export default function YourLastAdjustmentCard(props: Props) {
  const viewModel = useViewModel(props)

  return <YourLastAdjustmentCardRoot {...viewModel} />
}
