import useViewModel from './useViewModel'
import BrandedPage from '@/app/pages/ava/BrandedPage'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import LoadingAnimation from '@/components/LoadingAnimation'

export default function StartGateway() {
  const history = useHistory()
  const { destination } = useViewModel()

  useEffect(() => {
    if (destination) {
      // use history instead of navigation to avoid animation
      history.replace(destination)
    }
  }, [destination])

  return (
    <BrandedPage
      id="utility-program-onboarding-start"
      navigationOptions={['nothing']}
    >
      <BrandedPage.Content>
        <div className="w-full flex justify-center mt-14">
          <LoadingAnimation type="falling" size="small" />
        </div>
      </BrandedPage.Content>
    </BrandedPage>
  )
}
