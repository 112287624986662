import { createTheme } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import utilityUsageTheme from './theme'
import UtilityUsage from './UtilityUsage'
import ErrorBoundary, { LocationTag } from '../ErrorBoundary'

const ThemedUtilityUsage = (props) => {
  return (
    <ErrorBoundary
      location={LocationTag.UtilityUsage}
      functionalityDescription="Home Usage Overview"
      fallbackOnCard
    >
      <ThemeProvider
        theme={(theme) =>
          createTheme({
            ...theme,
            palette: {
              ...theme.palette,
              ...utilityUsageTheme.palette,
            },
            border: {
              ...(theme.border || {}),
              ...utilityUsageTheme.border,
            },
          })
        }
      >
        <UtilityUsage {...props} />
      </ThemeProvider>
    </ErrorBoundary>
  )
}

export default ThemedUtilityUsage
