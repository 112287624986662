import { NavigationPage } from '@/app/components'
import TeslaEditPermissions from '@/app/components/TeslaEditPermissions/TeslaEditPermissions'
import TeslaVehiclePermissions from '@/app/components/TeslaVehiclePermissions/TeslaVehiclePermissions'
import { useAppSelector } from '@/hooks'
import { TeslaFleetAuthenticationScopeName } from '@/types/teslaFleetAuthentication'

import { isTesla } from '@/utils/vehicles/car'

export default function EditPermissionsPage() {
  const vehicles = useAppSelector((state) =>
    state.vehicles.vehicles?.filter((v) => isTesla(v)),
  )
  const missingScopes = Array.from(
    // deduplicate missing scopes
    new Set(
      // flatten all missing scopes names
      vehicles?.flatMap(
        (v) =>
          // map scopes to names enum
          v.car.fleet_scopes?.missing?.map(
            (s) => TeslaFleetAuthenticationScopeName[s],
          ) ?? [],
      ),
    ),
  )

  return (
    <NavigationPage
      id="edit-permissions"
      title="Edit Permissions"
      subtitle="For the best experience with Optiwatt, enable all permissions. See the instructions below to update, or simply click 'Continue' to proceed."
    >
      <NavigationPage.SingleCardContent>
        {vehicles && (
          <div className="mb-[18px]">
            <TeslaVehiclePermissions
              vehicles={vehicles}
              missingScopes={missingScopes}
            />
          </div>
        )}
        <TeslaEditPermissions excludeHeader />
      </NavigationPage.SingleCardContent>
    </NavigationPage>
  )
}
