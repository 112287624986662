const overrides = {
  MuiCardHeader: {
    action: {
      marginTop: '-4px',
      marginRight: '-4px',
    },
  },
  MuiPickersDay: {
    day: {
      fontWeight: '300',
    },
  },
  MuiPickersYear: {
    root: {
      height: '64px',
    },
  },
  MuiPickersCalendar: {
    transitionContainer: {
      marginTop: '6px',
    },
  },
  MuiPickersCalendarHeader: {
    iconButton: {
      backgroundColor: 'transparent',
      '& > *': {
        backgroundColor: 'transparent',
      },
    },
    switchHeader: {
      marginTop: '2px',
      marginBottom: '4px',
    },
  },
  MuiPickersClock: {
    container: {
      margin: `32px 0 4px`,
    },
  },
  MuiPickersClockNumber: {
    clockNumber: {
      left: `calc(50% - 16px)`,
      width: '32px',
      height: '32px',
    },
  },
  MuiPickerDTHeader: {
    dateHeader: {
      '& h4': {
        fontSize: '2.125rem',
        fontWeight: 400,
      },
    },
    timeHeader: {
      '& h3': {
        fontSize: '3rem',
        fontWeight: 400,
      },
    },
  },
  MuiPickersTimePicker: {
    hourMinuteLabel: {
      '& h2': {
        fontSize: '3.75rem',
        fontWeight: 300,
      },
    },
  },
  MuiPickersToolbar: {
    toolbar: {
      '& h4': {
        fontSize: '2.125rem',
        fontWeight: 400,
      },
    },
  },
  MuiTooltip: {
    tooltip: {
      fontSize: '14px',
    },
  },
  MuiInput: {
    root: {
      fontSize: 16,
      '-webkitAppearance': 'none',
    },
  },
  MuiChip: {
    colorSecondary: {
      backgroundColor: '#FC4B4F1A',
      color: '#FC4B4F',
    },
  },
  MuiButton: {
    label: {
      padding: '12px 32px',
      fontSize: '0.925rem',
    },
    root: {
      borderRadius: 20,
    },
    containedSecondary: {
      backgroundColor: '#F2F2F2',
      color: '#343434',
    },
  },
  MuiDivider: {
    root: {
      color: '#BCBCBC',
    },
  },
  MuiOutlinedInput: {
    root: {
      '& $notchedOutline': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
        borderColor: '#426FFB',
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          borderColor: 'rgba(0, 0, 0, 0.23)',
        },
      },
      '&$focused $notchedOutline': {
        borderColor: '#426FFB',
        borderWidth: 1,
      },
    },
  },
  MuiPaper: {
    rounded: {
      borderRadius: 20,
    },
  },
  MuiSlider: {
    thumb: {
      // Default size is 12, use margin to center the thumb
      width: '18px',
      height: '18px',
      marginLeft: '-7px',
      marginTop: '-8px',
      boxShadow: '0px 0px 10px #5D5D5D7D',
      color: '#5D5D5D',
      '&:focus, &:hover, &.Mui-active': {
        boxShadow: `0px 0px 0px 10px #00D73F28 !important`,
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          boxShadow: '0px 0px 10px #5D5D5D7D',
        },
      },
    },
    rail: {
      height: '4px',
      borderRadius: '2px',
      backgroundColor: '#E5E5E5',
    },
    track: {
      height: '4px',
      borderRadius: '2px',
      backgroundColor: '#00D73F',
    },
    mark: {
      height: '9px',
      borderRadius: '3px',
      marginTop: '-2.5px',
      backgroundColor: 'rgb(0, 0, 0, 0.5)',
    },
    markActive: {
      backgroundColor: 'rgb(0, 0, 0, 0.5)',
      color: '#E5E5E5',
    },
    markLabel: {
      color: '#5D5D5D',
      fontSize: '10px',
      marginLeft: '4px',
    },
    markLabelActive: {
      color: '#5D5D5D',
      fontSize: '10px',
      marginLeft: '4px',
    },
  },
  MuiSwitch: {
    switchBase: {
      // Controls default (unchecked) color for the thumb
      color: '#B8B8B8',
    },
    colorSecondary: {
      '&$checked': {
        // Controls checked color for the thumb
        color: '#EDEDED',
      },
    },
    track: {
      // Controls default (unchecked) color for the track
      opacity: 0.2,
      backgroundColor: '#5D5D5D',
      '$checked$checked + &': {
        // Controls checked color for the track
        opacity: 0.8,
        backgroundColor: '#426FFB',
      },
    },
  },
}

export default overrides
