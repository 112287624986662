import './styles.scss'
import formatClasses from '../../utils/classes/formatClasses'
import {
  Button,
  Card,
  CardAction,
  CardActions,
  Flex,
  Icon,
  Text,
  TextProps,
} from '../index'
import React, { HTMLAttributes, useLayoutEffect, useState } from 'react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import ReactDOM from 'react-dom'
import { getChildrenOnDisplayName } from '../../utils/components'
import { Identifiable } from '@/types'
import twMerge from '@/utils/classes/twMerge'

export interface Props extends Identifiable<HTMLAttributes<HTMLDivElement>> {
  fullscreen?: boolean
  hideCloseButton?: boolean
  maxWidth?: string
  onClose?: () => void
  onOpen?: () => void
  open?: boolean
  title?: string
}

const rootElement = !process.env.STORYBOOK_USE_MOCK_DATA
  ? document.getElementById('modal-root')
  : document.getElementById('root')

function isModalSubcomponent(
  child?:
    | Exclude<React.ReactNode, boolean | null>
    | ReturnType<typeof ModalActivator>
    | ReturnType<typeof ModalTitle>,
) {
  if (!child || typeof child !== 'object' || !('type' in child)) return false

  return ['Activator', 'Title'].includes(child.type.displayName)
}

function Modal(props: Props) {
  const [internalOpen, setInternalOpen] = useState(props.open)
  const open = props.open ?? internalOpen

  const classes = {
    closeButton: formatClasses(['optiwatt-modal-close-button']),
    dialog: formatClasses(['optiwatt-modal-dialog', props.className]),
    modal: formatClasses([
      'optiwatt-modal',
      props.fullscreen ? 'fullscreen' : undefined,
    ]),
    modalActivator: formatClasses(['optiwatt-modal-activator']),
    overlay: formatClasses(['optiwatt-modal-overlay']),
    titleContainer: formatClasses(['optiwatt-modal-title-container']),
    content: formatClasses(['optiwatt-modal-content']),
  }

  const styles = {
    maxWidth: props.maxWidth || undefined,
  }

  function onClose() {
    props.onClose?.()
    setInternalOpen(false)
  }

  function onOpen() {
    setInternalOpen(true)
    props.onOpen?.()
  }

  const activator = getChildrenOnDisplayName(props.children, 'Activator')
  const title = getChildrenOnDisplayName(props.children, 'Title')

  // Stop scrolling on the body when the modal is open
  useModalScrollLock(Boolean(open))

  return (
    <>
      <div className={classes.modalActivator} onClick={onOpen}>
        {activator}
      </div>
      {ReactDOM.createPortal(
        <>
          <SwitchTransition mode="out-in">
            <CSSTransition
              appear
              classNames="modal"
              key={open ? 'open' : 'closed'}
              timeout={200}
              unmountOnExit
              mountOnEnter
            >
              {open ? (
                <div className={classes.modal}>
                  <div className={classes.overlay} />
                  <Card className={classes.dialog} style={styles}>
                    {!props.hideCloseButton && (
                      <Button
                        className={classes.closeButton}
                        onClick={onClose}
                        variant="icon"
                        id={`${props.id}-close-button`}
                      >
                        <Icon color="grey-900" name="X" size={18} />
                      </Button>
                    )}
                    <Flex
                      container
                      direction="column"
                      wrap="nowrap"
                      className={classes.content}
                    >
                      <Flex item>
                        <div
                          className={twMerge(classes.titleContainer, 'mb-3')}
                        >
                          {props.title ? (
                            <Text variant="h3">{props.title}</Text>
                          ) : (
                            title
                          )}
                        </div>
                      </Flex>
                      <Flex
                        item
                        className={props.fullscreen ? 'h-full' : undefined}
                      >
                        {React.Children.toArray(props.children).filter(
                          (child) => !isModalSubcomponent(child),
                        )}
                      </Flex>
                    </Flex>
                  </Card>
                </div>
              ) : (
                <></>
              )}
            </CSSTransition>
          </SwitchTransition>
        </>,
        rootElement as Element,
      )}
    </>
  )
}

function useModalScrollLock(open: boolean) {
  useLayoutEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [open])
}

function ModalActivator(props: HTMLAttributes<HTMLElement>) {
  return <>{props.children}</>
}
ModalActivator.displayName = 'Activator'
Modal.Activator = ModalActivator

function ModalTitle(props: TextProps) {
  return <Text {...props} />
}
ModalTitle.displayName = 'Title'
Modal.Title = ModalTitle

function ModalActions(props: { children: React.ReactNode }) {
  return (
    <CardActions className="mt-5">
      <CardAction type="primary">
        <div className="flex flex-row gap-4">{props.children}</div>
      </CardAction>
    </CardActions>
  )
}
ModalActions.displayName = 'ModalActions'
Modal.Actions = ModalActions

export default Modal
