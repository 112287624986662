import useVehicleChargeSchedulesFetcher from '@/authenticated/hooks/useVehicleChargeSchedulesFetcher'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { utilityProgramEnrollmentCollection } from '@/reducers/utilityProgramEnrollments'
import {
  selectUserCanAndHasntIntegratedUtility,
  selectUserVehicleIsEnrolledProgramEligible,
} from '@/selectors'

import {
  selectUtilityProgramForUserUtility,
  utilityProgramCollection,
} from '@/reducers/utilityPrograms'
import { Vehicle, VehicleChargeSchedule } from '@/types'
import EnvironmentalChargingOptionsCard from '@/authenticated/components/ChargeSchedule/EnvironmentalChargingOptionsCard'
import { useCallback, useEffect, useState } from 'react'
import debounce from 'lodash.debounce'
import { updateChargeSchedule } from '@/actions/schedule'
import { ButtonVariant, Divider } from '@/components'
import { localGridDetails } from '../../schedule/vehicle/local-grid-support-details/localGridDetails'
import * as Sentry from '@sentry/browser'
import { vehicleEnrollmentCollection } from '@/reducers/deviceProgramEnrollments'
import { canCharge } from '@/utils/vehicles/permissions'

type Props = {
  vehicle: Vehicle
  buttonVariant?: ButtonVariant
  showUtilityUpsell?: boolean
}

function useCanControlVehicleEnrollment(vehicle: Vehicle) {
  const vehicleIsEligibleForProfileEnrolledProgram = useAppSelector((state) =>
    selectUserVehicleIsEnrolledProgramEligible(state, vehicle.id),
  )
  const vehicleHasEnrolledInProgram = useAppSelector((state) =>
    Boolean(
      vehicleEnrollmentCollection.selectors
        .selectAll(state)
        .find((enrollment) => enrollment.vehicle_id === vehicle.id),
    ),
  )

  return (
    vehicleIsEligibleForProfileEnrolledProgram || vehicleHasEnrolledInProgram
  )
}

export function useViewModel({
  vehicle,
  buttonVariant,
  showUtilityUpsell = true,
}: Props) {
  const dispatch = useAppDispatch()
  const chargeSchedules = useVehicleChargeSchedulesFetcher()
  const { isLoadingOrIdle: vehicleEnrollmentIsLoadingOrIdle } =
    vehicleEnrollmentCollection.useFetch()
  const { isLoadingOrIdle: utilityProgramEnrollmentIsLoadingOrIdle } =
    utilityProgramEnrollmentCollection.useFetch()
  const { isLoadingOrIdle: utilityProgramIsLoadingOrIdle } =
    utilityProgramCollection.useFetch()

  // Fetch utility program enrollments to make sure we have the latest data
  // if we invalidated the cache elsewhere
  utilityProgramEnrollmentCollection.useFetch()

  useEffect(() => {
    Sentry.addBreadcrumb({ data: { chargeSchedules } })
  }, [chargeSchedules])
  const chargeSchedule = (chargeSchedules ?? []).find(
    (schedule) => schedule.vehicle_id === vehicle.id,
  ) as VehicleChargeSchedule | undefined

  const canVehicleCharge = canCharge(vehicle)
  const [localChargeSchedule, setLocalChargeSchedule] = useState(chargeSchedule)
  const AllEligibleProgramsAreLocalGridPrograms = useAppSelector((state) => {
    const localGridNames = localGridDetails.map((program) => program.name)
    return utilityProgramEnrollmentCollection.selectors
      .selectAll(state)
      .every((enrollment) =>
        localGridNames.includes(enrollment.utility_program.name),
      )
  })

  const canControlVehicleEnrollment = useCanControlVehicleEnrollment(vehicle)
  const userHasUnenrolledProgram = useAppSelector((state) =>
    selectUtilityProgramForUserUtility(state),
  )
  const userCanAndHasntConnectedUtility = useAppSelector(
    selectUserCanAndHasntIntegratedUtility,
  )

  const debouncedSave = useCallback(
    debounce((model) => {
      dispatch(updateChargeSchedule(model))
    }, 500),
    [],
  )

  const onChargeScheduleChange = (chargeSchedule: VehicleChargeSchedule) => {
    setLocalChargeSchedule(chargeSchedule)
    debouncedSave(chargeSchedule)
  }

  // Ensure the local charge schedule is in sync with what we pull from the server.
  // This fixed a bug where local charge schedule was not being initialized properly
  // when the component was first mounted.
  useEffect(() => {
    if (localChargeSchedule?.id !== chargeSchedule?.id && chargeSchedule) {
      setLocalChargeSchedule(chargeSchedule)
    }
  }, [chargeSchedules, localChargeSchedule])

  // if vehicle is enrolled in a program, show toggle header
  // if vehicle is not enrolled in a program, but is eligible for a profile enrolled program, show toggle header
  // if vehicle is not enrolled in a program, and is not eligible for a profile enrolled program, don't show toggle header
  const shouldShowProgramHeader =
    !AllEligibleProgramsAreLocalGridPrograms && canControlVehicleEnrollment

  return {
    vehicle,
    buttonVariant,
    showUtilityUpsell,
    shouldShowProgramHeader,
    canVehicleCharge,
    localChargeSchedule,
    onChargeScheduleChange,
    userHasUnenrolledProgram,
    userCanAndHasntConnectedUtility,
    isLoading:
      vehicleEnrollmentIsLoadingOrIdle ||
      utilityProgramEnrollmentIsLoadingOrIdle ||
      utilityProgramIsLoadingOrIdle,
  }
}

export default function VehicleProgramCard(props: Props) {
  const {
    vehicle,
    buttonVariant,
    showUtilityUpsell,
    shouldShowProgramHeader,
    canVehicleCharge,
    localChargeSchedule,
    onChargeScheduleChange,
    userHasUnenrolledProgram,
    userCanAndHasntConnectedUtility,
  } = useViewModel(props)

  return (
    <>
      <EnvironmentalChargingOptionsCard>
        {shouldShowProgramHeader ? (
          <>
            <EnvironmentalChargingOptionsCard.EnrollmentToggleHeader
              vehicleId={vehicle.id}
            />
            {
              // If there is going to be any content below the toggle header, add a divider
              canVehicleCharge ? <Divider /> : null
            }
          </>
        ) : (
          <EnvironmentalChargingOptionsCard.PlainHeader />
        )}
        {canVehicleCharge && (
          <>
            <EnvironmentalChargingOptionsCard.AirQualityToggle
              chargeSchedule={localChargeSchedule}
              onChange={onChargeScheduleChange}
            />
            {
              // If there is going to be any content below the toggle header, add a divider
              !shouldShowProgramHeader ? <Divider /> : null
            }
          </>
        )}
        {/*
        If the vehicle is eligible for an enrolled program, we dont want
        to sell them any other programs/utility integrations.
      */}
        {!shouldShowProgramHeader && (
          <>
            {userHasUnenrolledProgram ? (
              <EnvironmentalChargingOptionsCard.ProgramEnrollmentUpsell
                buttonVariant={buttonVariant}
                vehicleId={vehicle.id}
              />
            ) : userCanAndHasntConnectedUtility && showUtilityUpsell ? (
              <EnvironmentalChargingOptionsCard.UtilityIntegrationUpsell
                chargeSchedule={localChargeSchedule}
                onChange={onChargeScheduleChange}
              />
            ) : (
              <EnvironmentalChargingOptionsCard.GridSuportToggle
                chargeSchedule={localChargeSchedule}
                onChange={onChargeScheduleChange}
              />
            )}
          </>
        )}
      </EnvironmentalChargingOptionsCard>
    </>
  )
}
