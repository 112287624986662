import { useAppSelector, useUrlSearchParam } from '@/hooks'
import useMockableViewModel from '@/hooks/useMockableViewModel'
import { selectSearchableProgramForReferralCode } from '@/reducers/utilityPrograms'
import mockUtilityProgram from '@/types/utilityProgram/mock'

function useViewModel() {
  const referralCode = useUrlSearchParam('referral_code')
  const viewConfig = useAppSelector(
    (state) =>
      selectSearchableProgramForReferralCode(state, referralCode)
        ?.view_config_json.referral,
  )

  return { viewConfig }
}

function useMockViewModel() {
  return {
    viewConfig: mockUtilityProgram.view_config_json.referral,
  }
}

export default useMockableViewModel({ useViewModel, useMockViewModel })
