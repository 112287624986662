import { useState } from 'react'
import {
  CardActionButton,
  CardActions,
  Grid,
  Modal,
  Text,
  TextField,
} from '@/components'
import { useAppSelector } from '@/hooks'
import AlertMessage from '../../../app/components/AlertMessage'

interface SurveyErrors {
  detail: string
}

function useContactFormData() {
  const success = useAppSelector((state) => state.form.hasContactFormSucceeded)
  const isLoading = useAppSelector((state) => state.form.contactFormLoading)
  const errors: SurveyErrors = useAppSelector(
    (state) => state.form.errors,
  ) as SurveyErrors
  return { success, isLoading, errors }
}

export interface FeedbackDialogProps {
  id: string
  isOpen: boolean
  title?: string
  prompt?: string
  submitText?: string
  onClose?: () => void
  onOpen?: () => void
  onSubmit?: (message: string) => void
  onChanges?: (message: string) => void
}

const FeedbackDialog = (
  props: FeedbackDialogProps,
  { useData = useContactFormData },
) => {
  const { success, isLoading, errors } = useData()
  const [feedback, setFeedback] = useState('')

  const submit = () => {
    if (props.onSubmit) {
      props?.onSubmit(feedback)
    }
  }

  const close = () => {
    if (props.onClose) {
      props?.onClose()
    }
  }

  const buttonMessage = success
    ? 'Success'
    : isLoading
    ? 'Sending...'
    : props.submitText || 'Submit'

  const onTextFieldChange = (message: string) => {
    if (props.onChanges) {
      props.onChanges(message)
    }

    setFeedback(message)
  }

  return (
    <Modal
      id={props.id}
      open={props.isOpen}
      onClose={close}
      onOpen={props.onOpen}
    >
      <Modal.Title>{props.title ?? 'How can we improve?'}</Modal.Title>
      <Grid gap="8px" flow="row">
        {props.prompt && <Text variant="body2">{props.prompt}</Text>}
        <TextField
          id="feedback-multiline"
          label="Feedback"
          multiline
          required={true}
          value={feedback}
          fullWidth={true}
          onChange={(e) => onTextFieldChange(e.target.value)}
        />
        <CardActions>
          <CardActionButton
            id="feedback-dialog-cancel-button"
            onClick={close}
            type="secondary"
            buttonVariantOverride="primary"
          >
            Cancel
          </CardActionButton>
          <CardActionButton
            id="feedback-dialog-submit-button"
            onClick={submit}
            disabled={success || !feedback || isLoading}
            type="primary"
            buttonVariantOverride="secondary"
          >
            {buttonMessage}
          </CardActionButton>
        </CardActions>
      </Grid>
      <AlertMessage variant="error" show={!!errors.detail}>
        {errors.detail}
      </AlertMessage>
    </Modal>
  )
}

export default FeedbackDialog
