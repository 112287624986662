import { RSAA } from 'redux-api-middleware'
import { withAuth } from '../reducers'
import config from '../config'
import { SET_USER } from './user'

export const CONFIG_POST_SUCCESS = '@@dashboardConfig/CONFIG_POST_SUCCESS'
export const CONFIG_POST_REQUEST = '@@dashboardConfig/CONFIG_POST_REQUEST'
export const CONFIG_POST_FAILURE = '@@dashboardConfig/CONFIG_POST_FAILURE'

export const addConfig = (type, profileId, hide = true) => {
  return (dispatch) => {
    dispatch({
      [RSAA]: {
        endpoint: `${config.apiGateway.URL}/api/dashboard_configs/`,
        method: 'POST',
        body: JSON.stringify({
          type: type,
          should_hide: hide,
          profile: profileId,
        }),
        headers: withAuth({ 'Content-Type': 'application/json' }),
        types: [CONFIG_POST_REQUEST, CONFIG_POST_SUCCESS, CONFIG_POST_FAILURE],
      },
    })
    dispatch({
      type: SET_USER,
      payload: {
        profile: { dashboard_configs: [{ type, should_hide: hide }] },
      },
    })
  }
}

export const patchConfig = (id, type, profileId, hide = true) => {
  return (dispatch) => {
    dispatch({
      [RSAA]: {
        endpoint: `${config.apiGateway.URL}/api/dashboard_configs/${id}/`,
        method: 'PATCH',
        body: JSON.stringify({
          type: type,
          should_hide: hide,
          profile: profileId,
        }),
        headers: withAuth({ 'Content-Type': 'application/json' }),
        types: [CONFIG_POST_REQUEST, CONFIG_POST_SUCCESS, CONFIG_POST_FAILURE],
      },
    })
    dispatch({
      type: SET_USER,
      payload: {
        profile: { dashboard_configs: [{ type, should_hide: hide }] },
      },
    })
  }
}
