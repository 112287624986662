import formatClasses from '../../utils/classes/formatClasses'
import getColor from '../../utils/color/getColor'
import './styles.scss'
import { ReactNodeProps } from '../../types/reactNodeProps'

export interface Props extends ReactNodeProps {
  color?: string
  size?: 'sm' | 'md' | 'lg' | 'xs'
  rounded?: boolean
}

const defaultProps: Partial<Props> = {
  size: 'sm',
  rounded: true,
}

export default function Sheet(props: Props) {
  props = { ...defaultProps, ...props }

  const color = getColor(props.color)
  const classes = formatClasses([
    'sheet',
    props.size ? props.size : undefined,
    props.rounded ? 'rounded' : undefined,
    props.className,
  ])

  const styles = () => {
    return {
      background: color,
      ...props.style,
    }
  }

  return (
    <div className={classes} style={styles()}>
      {props.children}
    </div>
  )
}
