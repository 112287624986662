import { useFeatureFlag } from '@/hooks/useFeatureFlag'
import { searchableUtilityProgramsCollection } from '@/reducers/utilityPrograms'
import { getWhilelabeledExperienceData } from '@/types/utilityProgram/utils'
import useWhiteLabelProgramId from './useWhiteLabelProgramId'
import DefaultBrandLogo from '@/app/img/ava-optiwatt-logo.png'
import DefaultLargeBrandLogo from '@/app/img/ava-logo.png'
import Env from '@/utils/env'
import { useEffect, useState } from 'react'
import { UtilityProgram } from '@/types/utilityProgram'
import ImageUtils from '@/utils/images/ImageUtils'

function formatWhitelabelData(utilityProgram: UtilityProgram | undefined) {
  if (!utilityProgram) {
    return null
  }

  const whiteLabelData = {
    ...getWhilelabeledExperienceData(utilityProgram.view_config_json),
  }

  // We do this because uploading images in the staging/local CMS is broken and/or
  // difficult to test -- simply change the defaults if you wish to test specific
  // logos.
  if (!Env.isProd) {
    whiteLabelData.brandLogo = DefaultBrandLogo
    whiteLabelData.largeBrandLogo = DefaultLargeBrandLogo
  }

  return whiteLabelData
}

export const useWhiteLabelData = () => {
  const whiteLabelingEnabled = useFeatureFlag({
    flag: 'whitelabeling_enabled',
    experimentalValue: true,
  })
  const whiteLabelProgramId = useWhiteLabelProgramId()
  const [imagesLoaded, setImagesLoaded] = useState(false)

  const { data: utilityPrograms, isLoadingOrIdle: fetchingProgram } =
    searchableUtilityProgramsCollection.useFetch(whiteLabelProgramId ?? -1, {
      require: Boolean(whiteLabelProgramId) && Boolean(whiteLabelingEnabled),
    })

  useEffect(
    function refreshImages() {
      if (!whiteLabelProgramId) {
        return
      }

      setImagesLoaded(false)
    },
    [whiteLabelProgramId],
  )

  useEffect(
    function preloadImages() {
      if (!utilityPrograms || utilityPrograms.length === 0) {
        return
      }

      const formattedData = formatWhitelabelData(utilityPrograms?.[0])
      const validImgs = [
        formattedData?.brandLogo ?? '',
        formattedData?.largeBrandLogo ?? '',
      ].filter((img) => img !== '')
      ImageUtils.preloadImages(validImgs).finally(() => {
        setImagesLoaded(true)
      })
    },
    [utilityPrograms],
  )

  const utilityProgram = utilityPrograms?.[0]

  const whiteLabelData = formatWhitelabelData(utilityProgram)

  if (!whiteLabelProgramId || !whiteLabelingEnabled) {
    return { whiteLabelData: null, isLoading: false }
  }

  const hasImages =
    Boolean(whiteLabelData?.brandLogo) ||
    Boolean(whiteLabelData?.largeBrandLogo)

  return {
    whiteLabelData,
    viewConfig: utilityProgram?.view_config_json,
    isLoading: fetchingProgram || (hasImages && !imagesLoaded),
  }
}
