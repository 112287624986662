import { DeviceAuthAlertMessage } from '@/app/components'

export default function TeslaChargeControlPermissionsBanner(props: {
  vehicleId: number
}) {
  return (
    <DeviceAuthAlertMessage
      mode="warning"
      deviceName="vehicle"
      linkText="Enable control permissions"
      reconnectUrl={`/devices/vehicle/${props.vehicleId}/capability`}
      show
    >
      Some features are unavailable due to missing permissions for vehicle
      control and charge management.
    </DeviceAuthAlertMessage>
  )
}
