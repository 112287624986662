const MILES_TO_KILOMETERS = 1.60934
const GALLONS_TO_LITERS = 3.78541
const MPG_TO_LPK = 235.214 // 1 mile/US gallon to Liter/100 kilometers

export const convertMilesToKilometers = (miles, shouldRound = true) => {
  const value = miles * MILES_TO_KILOMETERS
  return shouldRound ? Math.round(value) : value
}

export const convertKilometersToMiles = (kilometers, shouldRound = true) => {
  const value = kilometers / MILES_TO_KILOMETERS
  return shouldRound ? Math.round(value) : value
}

export const convertGallonsToLiters = (gallons, shouldRound = true) => {
  const value = gallons * GALLONS_TO_LITERS
  return shouldRound ? Math.round(value) : value
}

export const convertLitersToGallons = (liters, shouldRound = true) => {
  const value = liters / GALLONS_TO_LITERS
  return shouldRound ? Math.round(value) : value
}

export const convertMPGToLPK = (mpg, shouldRound = true) => {
  const value = MPG_TO_LPK / mpg
  return shouldRound ? Math.round(value) : value
}

export const convertLPKToMPG = (lpk, shouldRound = true) => {
  const value = MPG_TO_LPK / lpk
  return shouldRound ? Math.round(value) : value
}

export const convertPoundsToKilograms = (pounds, shouldRound = true) => {
  const value = pounds * 0.453592
  return shouldRound ? Math.round(value) : value
}

export const convertKilogramsToPounds = (kilograms, shouldRound = true) => {
  const value = kilograms / 0.453592
  return shouldRound ? Math.round(value) : value
}

export const convertFahrenheitToCelsius = (degrees, shouldRound = true) => {
  const value = (degrees - 32) * 0.5556
  return shouldRound ? Math.round(value) : value
}

export const convertCelsiusToFahrenheit = (degrees, shouldRound = true) => {
  const value = degrees / 0.5556 + 32
  return shouldRound ? Math.round(value) : value
}
