import useRemoteConfig from '@/hooks/useRemoteConfig'
import useWhiteLabelProgramId from '@/hooks/whitelabel/useWhiteLabelProgramId'
import Script from '../Script'
import { useMemo, useEffect, useCallback, useRef } from 'react'
import { usePostHogBooleanFeatureFlag } from '@/hooks/usePostHogFeatureFlag'
import { useIsExperimentalUser } from '@/authenticated/hooks/useIsExperimentalUser'
import { useAppSelector } from '@/hooks'
import { zendeskAuthCollection } from '@/reducers/zendeskAuth'
import { ErrorBoundary } from '@sentry/react'
// @ts-expect-error we need to upgrade this package to get its types
import jwtDecode from 'jwt-decode'

const src =
  'https://static.zdassets.com/ekr/snippet.js?key=16a9ecc5-409c-4970-bc2a-29b027375d02'
const id = 'ze-snippet'

const checkAnyTrueConditions = (conditions: (boolean | (() => boolean))[]) => {
  return conditions.some((condition) =>
    typeof condition === 'function' ? condition() : condition,
  )
}

// Error fallback component, for chat we just want to show nothing
function ErrorFallback() {
  return <div />
}

interface JWTPayload {
  exp: number
  [key: string]: any
}

const isJWTExpired = (jwt: string): boolean => {
  try {
    const payload = jwtDecode<JWTPayload>(jwt)
    const expirationTime = payload.exp * 1000 // Convert to milliseconds
    return Date.now() >= expirationTime
  } catch (error) {
    console.error('Error decoding JWT:', error)
    return true // If we can't decode the JWT, consider it expired
  }
}

/**
 * ZendeskLiveChat view model hook
 */
function useViewModel() {
  const userWhitelabelId = useWhiteLabelProgramId()
  const { appRemoteConfig, isLoading } = useRemoteConfig()
  const avaId = appRemoteConfig?.getWhitelabelId('ava')
  const authStartedRef = useRef(false)
  const zeReadyRef = useRef(false)

  const isAva = userWhitelabelId === avaId

  const avaLiveChatEnabled =
    usePostHogBooleanFeatureFlag('ava-live-chat-enabled') && isAva

  const liveChatEnabled = usePostHogBooleanFeatureFlag('live-chat-enabled')

  const isExperimentalUser = useIsExperimentalUser()

  const conditions = [avaLiveChatEnabled, liveChatEnabled, isExperimentalUser]

  const shouldShowChat = useMemo(
    () => checkAnyTrueConditions(conditions),
    [conditions],
  )

  const user = useAppSelector((state) => state.user.user)
  const { data: zendeskAuthData, refetch } = zendeskAuthCollection.useFetch()
  const jwt = zendeskAuthData?.[0]?.jwt

  // Handle JWT callback for Zendesk authentication
  const handleJwtCallback = useCallback(
    async (callback: (jwt: string) => void) => {
      try {
        // If we already have a valid JWT, use it
        if (jwt && !isJWTExpired(jwt)) {
          callback(jwt)
        } else {
          // Only refetch if we don't have a valid JWT
          const result = await refetch()
          // If the fetch was successful and we have data
          if (result?.[0]?.jwt) {
            callback(result[0].jwt)
          } else {
            console.error('Failed to fetch Zendesk JWT token')
          }
        }
      } catch (error) {
        console.error('Error in Zendesk JWT callback:', error)
      }
    },
    [jwt, refetch],
  )

  // Initialize Zendesk with user data
  const initializeZendesk = useCallback(() => {
    if (!window.zE || !user?.username || authStartedRef.current || !jwt) {
      return false
    }

    // Mark authentication as started
    authStartedRef.current = true

    try {
      // Use the loginUser method according to Zendesk documentation
      window.zE(
        'messenger',
        'loginUser',
        // JWT callback - this dynamically provides the JWT when needed
        handleJwtCallback,
        // Login callback - handle login result
        (error: { message: string; reason: string; type: string } | null) => {
          if (error) {
            console.error(
              'Zendesk login failed:',
              error.message,
              'Reason:',
              error.reason,
            )
          }
        },
      )

      // Set additional user information
      window.zE('messenger:set', 'conversationFields', {
        email: user.username,
      })

      return true
    } catch (error) {
      console.error('Error initializing Zendesk:', error)
      return false
    }
  }, [user?.username, jwt, handleJwtCallback])

  // Cleanup function for Zendesk
  const cleanupZendesk = useCallback(() => {
    try {
      if (window.zE) {
        window.zE('messenger', 'close')
      }
    } catch (error) {
      console.error('Error cleaning up Zendesk:', error)
    }

    // Reset auth flag on cleanup
    authStartedRef.current = false
    zeReadyRef.current = false
  }, [])

  // Check if Zendesk is ready
  useEffect(() => {
    if (!shouldShowChat) return

    // Set up a function to check if zE is available
    const checkZendeskReady = () => {
      if (window.zE && !zeReadyRef.current) {
        zeReadyRef.current = true

        // Try to initialize once zE is available
        if (user?.username && jwt) {
          initializeZendesk()
        }
      }
    }

    // Check immediately
    checkZendeskReady()

    // Also set up an interval to check periodically
    const intervalId = setInterval(checkZendeskReady, 500)

    return () => {
      clearInterval(intervalId)
    }
  }, [shouldShowChat, user?.username, jwt, initializeZendesk])

  // Fetch the Zendesk JWT when component mounts and should show chat
  useEffect(() => {
    if (shouldShowChat && user?.username && (!jwt || isJWTExpired(jwt))) {
      refetch()
    }
  }, [shouldShowChat, user?.username, jwt, refetch])

  // Try to initialize Zendesk when all conditions are met
  useEffect(() => {
    if (!shouldShowChat || !user?.username || !jwt || !zeReadyRef.current) {
      return
    }

    initializeZendesk()

    return cleanupZendesk
  }, [shouldShowChat, user?.username, jwt, initializeZendesk, cleanupZendesk])

  return {
    shouldShowChat,
    isLoading,
  }
}

/**
 * #### ZenDesk Live Chat component
 * Renders the ZenDesk live chat script if the user is eligible to see it.
 */
function ZendeskLiveChatContent() {
  const { shouldShowChat, isLoading } = useViewModel()

  if (!shouldShowChat || isLoading) {
    return null
  }

  return <Script src={src} id={id} />
}

/**
 * #### ZenDesk Live Chat component with error boundary
 * Wraps the ZendeskLiveChatContent in an error boundary to prevent app crashes
 */
export default function ZendeskLiveChat() {
  return (
    <ErrorBoundary fallback={ErrorFallback}>
      <ZendeskLiveChatContent />
    </ErrorBoundary>
  )
}
