import { useEffect, useState } from 'react'
import { UtilityProgram } from '../../../../types/utilityProgram'
import mockUtilityProgram from '../../../../types/utilityProgram/mock'
import useMockableViewModel from '../../../../hooks/useMockableViewModel'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { selectUtilityProgramEnrollmentForUser } from '../../../../reducers/utilityProgramEnrollments'
import {
  selectUtilityProgramForUserUtility,
  selectUtilityProgramLogoUrl,
} from '../../../../reducers/utilityPrograms'
import { debounce } from 'lodash'
import {
  createDeviceEnrollmentsForUserSelector,
  selectProgramViewConfigFromAllSources,
} from '@/selectors'
import {
  behavioralEnrollmentCollection,
  chargerEnrollmentCollection,
  thermostatEnrollmentCollection,
  vehicleEnrollmentCollection,
} from '@/reducers/deviceProgramEnrollments'
import { ID } from '@/types/model'
import { useHistory } from 'react-router-dom'
import {
  BehavioralEnrollment,
  ChargerEnrollment,
  ThermostatEnrollment,
  VehicleEnrollment,
} from '@/types/utilityProgramEnrollment'
import { logEvent } from '@/logging'
import useProfileId from '@/hooks/useProfileId'

type Props = {
  vehicleId?: ID
  thermostatId?: ID
  chargerId?: ID
  deviceType: 'vehicle' | 'thermostat' | 'vehicle_charger' | 'behavioral'
  isEnrolledValue?: boolean
  onEnrollToggleValueChange?: (checked: boolean) => void
}

function isChargerEnrollment(
  enrollment:
    | VehicleEnrollment
    | ThermostatEnrollment
    | ChargerEnrollment
    | BehavioralEnrollment,
): enrollment is ChargerEnrollment {
  return 'charger_id' in enrollment
}

function useViewModel(props: Props) {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const profileId = useProfileId()

  // Fetch all our device type enrollments to make sure we have the latest data
  // if we invalidated the cache elsewhere
  vehicleEnrollmentCollection.useFetch()
  thermostatEnrollmentCollection.useFetch()
  chargerEnrollmentCollection.useFetch()
  behavioralEnrollmentCollection.useFetch()

  const utilityProgramEnrollment = useAppSelector(
    selectUtilityProgramEnrollmentForUser,
  )
  const maybeUtilityProgram: UtilityProgram | undefined = useAppSelector(
    selectUtilityProgramForUserUtility,
  )
  const utilityProgram =
    utilityProgramEnrollment?.utility_program ?? maybeUtilityProgram

  const deviceEnrollment = useAppSelector((state) => {
    const id = createDeviceEnrollmentsForUserSelector(props.deviceType)(
      state,
    ).find((enrollment) => {
      if (props.deviceType === 'vehicle') {
        return (enrollment as VehicleEnrollment).vehicle_id === props.vehicleId
      }
      if (props.deviceType === 'thermostat') {
        return (
          (enrollment as ThermostatEnrollment).thermostat_id ===
          props.thermostatId
        )
      }
      if (props.deviceType === 'behavioral') {
        return (enrollment as BehavioralEnrollment).profile_id === profileId
      }
      return (
        isChargerEnrollment(enrollment) &&
        enrollment.charger_id === props.chargerId
      )
    })?.id
    if (!id) {
      return undefined
    }

    if (props.deviceType === 'thermostat') {
      return thermostatEnrollmentCollection.selectors.selectById(state, id)
    }

    if (props.deviceType === 'vehicle_charger') {
      return chargerEnrollmentCollection.selectors.selectById(state, id)
    }

    if (props.deviceType === 'behavioral') {
      return behavioralEnrollmentCollection.selectors.selectById(state, id)
    }

    return vehicleEnrollmentCollection.selectors.selectById(state, id)
  })

  const logoUrl =
    useAppSelector((state) => {
      const utilityProgramUrl = selectUtilityProgramLogoUrl(state)
      const deviceEnrollmentLogoUrl =
        deviceEnrollment?.view_config_json?.logoUrl
      return deviceEnrollmentLogoUrl ?? utilityProgramUrl
    }) ?? utilityProgram?.logo_url

  const viewConfig = useAppSelector(
    (state) =>
      selectProgramViewConfigFromAllSources(state, props.vehicleId)?.scheduler,
  )

  const [disenrollConfirmationOpen, setDisenrollConfirmationOpen] =
    useState(false)
  const closeDisenrollConfirmation = () => setDisenrollConfirmationOpen(false)

  const [isEnrolledInternal, setIsEnrolledInternal] = useState(
    !!deviceEnrollment && !deviceEnrollment.unenrolled_at,
  )
  const isEnrolled = props.isEnrolledValue ?? isEnrolledInternal
  const setIsEnrolled = props.onEnrollToggleValueChange ?? setIsEnrolledInternal

  const [enrollError, setEnrollError] = useState<string | undefined>(undefined)

  const [unenrollSurveyOpen, setUnenrollSurveyOpen] = useState(false)
  const closeSurvey = () => {
    closeDisenrollConfirmation()
    setUnenrollSurveyOpen(false)
  }

  useEffect(() => {
    setIsEnrolled(!!deviceEnrollment && !deviceEnrollment.unenrolled_at)
  }, [deviceEnrollment])

  useEffect(() => {
    const updateEnrollment = async () => {
      let promise
      if (!isEnrolled && !deviceEnrollment) {
        return
      }
      if (!deviceEnrollment) {
        if (props.deviceType === 'thermostat') {
          promise = dispatch(
            thermostatEnrollmentCollection.actions.create({
              utility_program_id: utilityProgram?.id,
              thermostat_id: props.thermostatId,
            }),
          )
        } else if (props.deviceType === 'vehicle_charger') {
          promise = dispatch(
            chargerEnrollmentCollection.actions.create({
              utility_program_id: utilityProgram?.id,
              charger_id: props.chargerId,
            }),
          )
        } else if (props.deviceType === 'behavioral' && profileId !== null) {
          promise = dispatch(
            behavioralEnrollmentCollection.actions.create({
              utility_program_id: utilityProgram?.id,
              profile_id: profileId,
            }),
          )
        } else if (props.deviceType === 'vehicle') {
          promise = dispatch(
            vehicleEnrollmentCollection.actions.create({
              utility_program_id: utilityProgram?.id,
              vehicle_id: props.vehicleId,
            }),
          )
        }
      } else {
        const unenrolledAt = isEnrolled ? null : new Date().toISOString()
        if (props.deviceType === 'thermostat') {
          promise = dispatch(
            thermostatEnrollmentCollection.actions.update(deviceEnrollment.id, {
              unenrolled_at: unenrolledAt,
            }),
          )
        } else if (props.deviceType === 'vehicle_charger') {
          promise = dispatch(
            chargerEnrollmentCollection.actions.update(deviceEnrollment.id, {
              unenrolled_at: unenrolledAt,
            }),
          )
        } else if (props.deviceType === 'behavioral') {
          promise = dispatch(
            behavioralEnrollmentCollection.actions.update(deviceEnrollment.id, {
              unenrolled_at: unenrolledAt,
            }),
          )
        } else {
          promise = dispatch(
            vehicleEnrollmentCollection.actions.update(deviceEnrollment.id, {
              unenrolled_at: unenrolledAt,
            }),
          )
        }
      }

      const res = await promise
      if (res.type.includes('FAILURE')) {
        setEnrollError(res.payload?.response?.[0])
      } else {
        setEnrollError(undefined)
        if (isEnrolled) {
          logEvent('utilityenrollment', {
            metadata: { program: utilityProgram?.name ?? 'undefined' },
          })
        }
      }
    }

    const debouncedUpdate = debounce(updateEnrollment, 1000, {
      leading: true,
      trailing: true,
    })

    debouncedUpdate()

    return () => {
      debouncedUpdate.cancel()
    }
  }, [isEnrolled])

  const setEnrollment = (enrolled: boolean) => {
    setIsEnrolled(enrolled)
  }

  const onEnrollToggleChange = (checked: boolean) => {
    if (viewConfig?.disenrollConfirmation && !checked) {
      setDisenrollConfirmationOpen(true)
      return
    }
    setEnrollment(checked)

    if (!checked) {
      setUnenrollSurveyOpen(true)
    }
  }

  const onButtonClick = () => {
    history.push('/utility-program-enrollment')
  }

  const confirmDisenroll = () => {
    setEnrollment(false)
    setDisenrollConfirmationOpen(false)
    setUnenrollSurveyOpen(true)
  }

  return {
    title: viewConfig?.title ?? '',
    description: viewConfig?.description ?? '',
    programLogoUrl: logoUrl,
    isEnrolled,
    onEnrollToggleChange,
    buttonConfig: viewConfig?.button,
    onButtonClick,
    enrolledAlert: isEnrolled ? viewConfig?.enrolledAlert : undefined,
    disenrollConfirmationConfig: viewConfig?.disenrollConfirmation,
    disenrollConfirmationOpen,
    closeDisenrollConfirmation,
    confirmDisenroll,
    unenrollSurveyOpen,
    closeSurvey,
    enrollError,
  }
}

function useMockViewModel() {
  const viewConfig = mockUtilityProgram.view_config_json.scheduler

  const [isEnrolled, setIsEnrolled] = useState(true)
  const [modalOpen, setModalOpen] = useState(false)
  const onEnrollToggleChange = (checked: boolean) => {
    setIsEnrolled(checked)
    setModalOpen(!checked)
  }
  const [enrollError] = useState<string | undefined>(undefined)

  return {
    title: viewConfig.title,
    description: viewConfig.description,
    programLogoUrl: mockUtilityProgram.logo_url,
    isEnrolled,
    onEnrollToggleChange,
    onButtonClick: () => {},
    buttonConfig: undefined,
    enrolledAlert: isEnrolled ? viewConfig?.enrolledAlert : undefined,
    disenrollConfirmationConfig: viewConfig?.disenrollConfirmation,
    disenrollConfirmationOpen: modalOpen,
    closeDisenrollConfirmation: () => setModalOpen(false),
    confirmDisenroll: () => {},
    enrollError,
    unenrollSurveyOpen: false,
    closeSurvey: () => alert('Survey closed!'),
  }
}

export default useMockableViewModel({ useViewModel, useMockViewModel })
