import {
  TabBar,
  TabContent,
  setActiveTabIndex,
  type TabBarProps,
  type TabContentProps,
} from './Tabs'

const Tabs = {
  Tabs: TabBar,
  Content: TabContent,
  setActiveTabIndex,
}

export default Tabs

export { TabBarProps, TabContentProps }
