import './styles.scss'
import { Grid, List, Text } from '../../../../components'
import { OnboardingProgressBar } from '../index'
import { TaskListItem } from '../../../components'
import DismissibleCard from '../../../components/DismissibleCard'

export interface Props {
  shouldHide: boolean
  hasConnectedVehicle: boolean
  hasConnectedThermostat: boolean
  hasConnectedUtility: boolean
  hasConnectedCharger: boolean
  hasVehicle: boolean
  hasThermostat: boolean
  hasCharger: boolean
  navigateToStep: (route: string) => void
  onDismiss: () => void
}

export default function OnboardingProgressCard(props: Props) {
  const onboardingSteps: {
    completed: boolean
    label: string
    onClick: () => void
    id: string
  }[] = []

  if (props.hasVehicle) {
    onboardingSteps.push({
      completed: props.hasConnectedVehicle,
      label: 'Connect your electric vehicle',
      onClick: () => props.navigateToStep('/connect-vehicle'),
      id: 'connect-vehicle',
    })
  }

  if (props.hasThermostat) {
    onboardingSteps.push({
      completed: props.hasConnectedThermostat,
      label: 'Connect smart thermostat',
      onClick: () => props.navigateToStep('/connect-thermostat'),
      id: 'connect-thermostat',
    })
  }

  if (props.hasCharger) {
    onboardingSteps.push({
      completed: props.hasConnectedCharger,
      label: 'Connect smart charger',
      onClick: () => props.navigateToStep('/connect-device/charger'),
      id: 'connect-charger',
    })
  }

  onboardingSteps.push({
    completed: props.hasConnectedUtility,
    label: 'Select your utility',
    onClick: () => props.navigateToStep('/connect-utility'),
    id: 'connect-utility',
  })

  const sortedSteps = onboardingSteps.sort(
    (a, b) => Number(a.completed) - Number(b.completed),
  )

  function calculateCurrentStep() {
    return onboardingSteps.filter((step) => step.completed).length
  }

  if (props.shouldHide) {
    return null
  }

  return (
    <DismissibleCard id="onboarding-progress-card" onDismiss={props.onDismiss}>
      <Grid flow="row" gap="8px">
        <Grid flow="row" gap="16px">
          <Text variant="h3">Welcome to Optiwatt</Text>
          <Text variant="body2">
            Let’s get you set up. Follow the steps below to get up and running
            to get the most out of Optiwatt.
          </Text>
          <OnboardingProgressBar
            numberOfSteps={onboardingSteps.length}
            step={calculateCurrentStep()}
          />
        </Grid>
        <List bordered>
          {sortedSteps.map((step, index) => {
            return (
              <TaskListItem
                completed={step.completed}
                key={index}
                label={step.label}
                number={index + 1}
                onClick={step.onClick}
                id={`onboarding-progress-card-task-list-item-${step.id}`}
              />
            )
          })}
        </List>
      </Grid>
    </DismissibleCard>
  )
}
