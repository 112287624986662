import Loader from '@/authenticated/components/layout/Loader'
import React, { Suspense } from 'react'

// typescript version of the above component
export default function LazyPage<T extends object>(
  Component: React.ComponentType<T>,
) {
  return (props: T) => (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  )
}
