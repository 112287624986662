import { DeviceAuthAlertMessage } from '@/app/components'

export default function ChargerBrokenConnectionBanner() {
  return (
    <DeviceAuthAlertMessage
      mode="error"
      deviceName="charger"
      linkText="Reconnect"
      reconnectUrl="/connect-device/charger"
      show
    />
  )
}
