function parseTemplate(
  templatedString: string,
  templateVariables: { [key: string]: string },
  templateMatcher = /\${\s*([^{}\s]*)\s*}/g,
) {
  const template = templatedString.replace(
    templateMatcher,
    (match: string, key: string) => {
      const value = templateVariables[key]
      return value
    },
  )
  return template
}

export default parseTemplate
