import InsightsCard from '@/app/components/Insights/InsightsCard'
import { ID } from '../../../../../../types/model'
import UtilityUsageDashboardView from '../../../../../features/home-integrations/UtilityUsageDashboardView'
import HomeSectionNotification from './HomeSectionNotification'
import UtilityProgramLearnMoreDashboardView from 'features/utility-programs/UtilityProgramLearnMoreDashboardView'

export interface Props {
  homeId: ID
}

export default function HomeSection(props: Props) {
  return (
    <>
      <HomeSectionNotification homeId={props.homeId} />
      <UtilityProgramLearnMoreDashboardView />
      <InsightsCard />
      <UtilityUsageDashboardView />
    </>
  )
}
