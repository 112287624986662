import { useHistory } from 'react-router-dom'
import { useAppSelector, useUrlSearchParam } from '.'
import { eligibleUtilityProgramsCollection } from '@/reducers/eligibleUtilityPrograms'
import { useOnboardingResponses } from '@/app/hooks/useOnboardingResponses'

export const useDeviceSelectionRedirectUrl = (goToSchedule?: boolean) => {
  const isPartner = useAppSelector((state) => state.auth.fromToken)
  const onboardingResponses = useOnboardingResponses()
  const onboarding = useUrlSearchParam('onboarding')
  const { data: eligiblePrograms } =
    eligibleUtilityProgramsCollection.useFetch()
  const selectedUtility = useAppSelector(
    (state) => state.utilities.selectedUtility,
  )

  if (isPartner) {
    return '/autogrid/success'
  }

  if (onboarding) {
    if (eligiblePrograms.length > 0 && selectedUtility) {
      return '/utility-program-enrollment'
    }

    if (onboardingResponses.hasThermostat) {
      return '/connect-device/thermostat'
    }

    if (!selectedUtility) {
      return '/connect-utility/information'
    }
  }

  if (onboarding && goToSchedule) {
    return '/devices?show-utility-enrollment=true'
  }

  return '/app'
}

export const usePostAuthRedirectUrl = () => {
  const onboarding = useUrlSearchParam('onboarding')
  const currentPath = useHistory().location.pathname
  if (currentPath.includes('/ava/')) {
    if (onboarding) {
      return '/ava/onboarding/add-another'
    }
    return '/app'
  }
  return `/connect-vehicle/configure${onboarding ? '?onboarding=true' : ''}`
}
