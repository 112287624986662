import React, { useCallback, useEffect, useState } from 'react'
import { convertMilesToKilometers } from '@/utils/conversionUtilities'
import { useDistanceUnit } from '@/authenticated/hooks/conversions'
import { canCharge } from '@/utils/vehicles/permissions'
import { Text } from '@/components'
import Dot from '@/components/Dot/Dot'
import './slider.css'
import { Vehicle, VehicleChargeSchedule } from '@/types'
import useVehicleSettingsManager from '@/hooks/useVehicleSettingsManager'
import { ThemeProvider } from '@material-ui/styles'
import { makeStyles, Slider as MuiSlider } from '@material-ui/core'
import theme from '@/v2/theme'
import { cloneDeep } from 'lodash'

type WrapperProps = {
  onChange: (chargeSchedule: VehicleChargeSchedule) => void
}

const MARKS = [
  {
    value: 10,
  },
  {
    value: 20,
  },
  {
    value: 50,
  },
  {
    value: 60,
  },
  {
    value: 70,
  },
  {
    value: 80,
  },
  {
    value: 90,
  },
]

const markClassSelector = `&[data-index="${MARKS.length - 1}"]`

const useStyles = makeStyles((theme: any) => ({
  percentText: {
    fontWeight: 'bold',
    fontSize: 14,
  },
  milesText: {
    fontWeight: 'bold',
    color: theme.typography.body1.color,
    fontSize: 14,
  },
  slider: (props: any) => ({
    '& [class*="MuiSlider-rail"]': {
      background: `linear-gradient(to right, #00D73F ${props.batteryLevel}%, #E5E5E5 ${props.batteryLevel}%)`,
      transition: 'width 0.5s ease-in-out',
      opacity: 1,
    },
    '& [class*="MuiSlider-track"]': {
      visibility: 'hidden',
    },
    '& [class*="MuiSlider-thumb"]': {
      color: '#426FFb',
    },
    '& [class*="MuiSlider-thumb"] ~ [class*="MuiSlider-thumb"]': {
      color: '#5D5D5D',
    },
  }),
  disabledThumb: {
    color: '#00000000',
    boxShadow: 'none',
  },
  thumb: {
    '.MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover': {
      boxShadow: 'none',
      color: 'blue',
    },
  },
  marks: {
    [markClassSelector]: {
      width: '3px',
    },
  },
}))

const ChargeLimitSliderWrapper = (props: WrapperProps) => {
  const { isLoading, vehicle, chargeSchedule } = useVehicleSettingsManager()

  const [localChargeSchedule, setLocalChargeSchedule] = useState(
    cloneDeep(chargeSchedule),
  )

  if (!isLoading && vehicle && localChargeSchedule) {
    return (
      <ThemeProvider theme={theme}>
        <ChargeLimitSlider
          batteryLevel={vehicle.battery_level_percent}
          vehicle={vehicle}
          chargeSchedule={localChargeSchedule}
          onChange={props.onChange}
        />
      </ThemeProvider>
    )
  } else {
    return (
      <div className="flex flex-col space-y-4 px-1 py-2 mb-2">
        <div className="w-1/2">
          <div className="bg-gray-200 animate-pulse h-4" />
        </div>
        <div className="w-full">
          <div className="bg-gray-200 animate-pulse h-3" />
        </div>
      </div>
    )
  }
}

type ChargeLimitSliderProps = {
  chargeSchedule: VehicleChargeSchedule
  onChange: (chargeSchedule: VehicleChargeSchedule) => void
  vehicle: Vehicle
  batteryLevel: number
}

const ChargeLimitSlider: React.FC<ChargeLimitSliderProps> = ({
  chargeSchedule,
  onChange,
  vehicle,
  batteryLevel,
}) => {
  const DEFAULT_MAX_CHARGE_LIMIT = 80
  const DEFAULT_MIN_CHARGE_LIMIT = 20

  const [maxChargeLimit, setMaxChargeLimit] = useState<number>(
    chargeSchedule?.battery_target_max ?? DEFAULT_MAX_CHARGE_LIMIT,
  )
  const [minChargeLimit, setMinChargeLimit] = useState<number>(
    chargeSchedule?.battery_target_min ?? DEFAULT_MIN_CHARGE_LIMIT,
  )
  const [marks, setMarks] = React.useState<Array<{ value: number }>>([])

  const [longDistanceUnit] = useDistanceUnit()
  const useDistanceInMiles = longDistanceUnit === 'miles'
  const distanceUnit = useDistanceInMiles ? 'mi' : 'km'
  const milesPerPercent = vehicle.miles_per_battery_percent ?? 2

  const maxChargeLimitInMiles = Math.round(maxChargeLimit * milesPerPercent)
  const minChargeLimitInMiles = Math.round(minChargeLimit * milesPerPercent)
  const batteryInMiles = Math.round(batteryLevel * milesPerPercent)

  const batteryInDistanceValue = useDistanceInMiles
    ? batteryInMiles
    : convertMilesToKilometers(batteryInMiles)
  const maxChargeLimitInDistanceValue = useDistanceInMiles
    ? maxChargeLimitInMiles
    : convertMilesToKilometers(maxChargeLimitInMiles)
  const minChargeLimitInDistanceValue = useDistanceInMiles
    ? minChargeLimitInMiles
    : convertMilesToKilometers(minChargeLimitInMiles)

  const updateSlider = (
    _event: React.ChangeEvent<{}>,
    newValue: number | number[],
  ) => {
    setMarks(MARKS)
    if (typeof newValue === 'number') {
      return
    }

    // For now (DR concerns among other things), we don't want charge minimums
    // to be set above 20% or charge maximums above 50%
    if (newValue?.[0] > 20 || newValue?.[1] < 50) {
      return
    }

    chargeSchedule.battery_target_max = newValue?.[1]
    chargeSchedule.battery_target_min = newValue?.[0]

    if (chargeSchedule.battery_target_unit === 'percent') {
      setMaxChargeLimit(chargeSchedule.battery_target_max)
      setMinChargeLimit(chargeSchedule.battery_target_min)
    } else {
      const maxMiles = Math.round(
        chargeSchedule.battery_target_max / milesPerPercent,
      )
      const minMiles = Math.round(
        chargeSchedule.battery_target_min / milesPerPercent,
      )

      if (chargeSchedule.battery_target_unit === 'miles') {
        setMaxChargeLimit(maxMiles)
        setMinChargeLimit(minMiles)
      } else if (chargeSchedule.battery_target_unit === 'kilometers') {
        setMaxChargeLimit(convertMilesToKilometers(maxMiles))
        setMinChargeLimit(convertMilesToKilometers(minMiles))
      }
    }
  }

  const onDragEnd = () => {
    setMarks([])
    onChange(chargeSchedule)
  }

  const disableScroll = useCallback((e: TouchEvent) => {
    const target = e.target as HTMLElement
    if (target.classList.contains('slider')) {
      e.stopPropagation() // Allow slider movement
    } else {
      e.preventDefault() // Prevent page scrolling
    }
  }, [])

  useEffect(() => {
    const slider = document.querySelector('.slider') as HTMLInputElement

    if (slider) {
      // Add touchmove event listener only
      slider.addEventListener('touchmove', disableScroll, { passive: false })

      // Cleanup event listener on unmount
      return () => {
        slider.removeEventListener('touchmove', disableScroll)
      }
    }
  }, [disableScroll])

  const controlDisabled = !canCharge(vehicle)

  const classes = useStyles({ batteryLevel: vehicle.battery_level_percent })

  return (
    <div className="flex flex-col space-y-2 px-1 py-2">
      <div className="flex items-center space-x-1.5">
        <div className="flex flex-col gap-1.5">
          <div className="flex items-center gap-1.5">
            <Dot size={14} className="bg-blue-500" />
            <Text className="font-bold text-sm">
              {controlDisabled
                ? `Battery Level: ${batteryLevel}%`
                : `Charge Min: ${minChargeLimit}%`}
            </Text>
            {vehicle?.miles_per_battery_percent && (
              <>
                <span className="font-bold text-sm text-gray-700">•</span>
                <Text className="font-bold text-sm text-gray-700">
                  {controlDisabled
                    ? `${batteryInDistanceValue} ${distanceUnit}`
                    : `${minChargeLimitInDistanceValue} ${distanceUnit}`}
                </Text>
              </>
            )}
          </div>
          <div className="flex items-center gap-1.5">
            <Dot size={14} className="bg-gray-500" />
            <Text className="font-bold text-sm">
              {controlDisabled
                ? `Battery Level: ${batteryLevel}%`
                : `Charge Limit: ${maxChargeLimit}%`}
            </Text>
            {vehicle?.miles_per_battery_percent && (
              <>
                <span className="font-bold text-sm text-gray-700">•</span>
                <Text className="font-bold text-sm text-gray-700">
                  {controlDisabled
                    ? `${batteryInDistanceValue} ${distanceUnit}`
                    : `${maxChargeLimitInDistanceValue} ${distanceUnit}`}
                </Text>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="mt-4">
        <MuiSlider
          className={classes.slider}
          classes={{
            thumb: controlDisabled ? classes.disabledThumb : classes.thumb,
            mark: classes.marks,
          }}
          id="charge-limit-slider"
          aria-label="Charge Limit"
          defaultValue={[DEFAULT_MIN_CHARGE_LIMIT, DEFAULT_MAX_CHARGE_LIMIT]}
          min={0}
          max={100}
          onChange={updateSlider}
          onChangeCommitted={onDragEnd}
          value={[minChargeLimit, maxChargeLimit]}
          disabled={controlDisabled}
          marks={marks}
          name="charge-limit-slider"
        />
      </div>
    </div>
  )
}

export default ChargeLimitSliderWrapper
