import { ThemeProvider } from '@material-ui/core/styles'
import theme from '../../../v2/theme'
import BooleanInputRoot, { Props } from './BooleanInput'

export default function BooleanInput(props: Props) {
  return (
    <ThemeProvider theme={theme}>
      <BooleanInputRoot {...props} />
    </ThemeProvider>
  )
}
