import React from 'react'

import Header from '../components/Header/header'
import FooterSection from '../components/Footer'
import TermsOfServiceHeader from '../components/TermsOfService/TermsOfServiceHeader'
import TermsOfService from '../components/TermsOfService/TeslaGiveaway'

// This came from Termly
function Terms() {
  return (
    <React.Fragment>
      <Header />
      <div className="main">
        <TermsOfServiceHeader lastUpdated="August 5th, 2022" />
        <TermsOfService />
      </div>
      <FooterSection />
    </React.Fragment>
  )
}

export default Terms
