import { tabbedRoutePaths, TabbedRoutes } from './tabs'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import AuthLayout from '../../authenticated/layouts/Auth'
import Page404 from '../../authenticated/pages/Page404'
import Admin from '../../authenticated/pages/Admin'
import { AnimatedNavigationSwitch } from '../components'
import { NavigationContextProvider } from '@/context'
import { BottomSheetProvider } from '@/context/bottom-sheet-context'

const adminPaths = ['/admin/:profileID', '/admin']
export const appPath = tabbedRoutePaths.concat(adminPaths)

const AppRoutes = () => {
  return (
    <Router>
      <NavigationContextProvider>
        <BottomSheetProvider>
          <AnimatedNavigationSwitch>
            <Route path={tabbedRoutePaths} exact>
              <TabbedRoutes />
            </Route>
            <Route path={adminPaths} component={Admin} />
            <Route
              render={() => (
                <AuthLayout>
                  <Page404 />
                </AuthLayout>
              )}
            />
          </AnimatedNavigationSwitch>
        </BottomSheetProvider>
      </NavigationContextProvider>
    </Router>
  )
}

export default AppRoutes
