import { Plan } from '@/types'
import * as actions from '../actions/plans'
import * as utilityActions from '../actions/utilities'
import { getApiErrorMessage } from '../utils/errorUtilities'
import { PayloadAction } from '@reduxjs/toolkit'

type State = {
  plans: Plan[] | null
  selectedPlan: Plan | null
  isLoading: boolean
  hasFetched: boolean
  errors: Record<string, unknown>
}

const initialState: State = {
  plans: null,
  selectedPlan: null,
  isLoading: false,
  // true when a fetch has finished, even if it failed
  hasFetched: false,
  errors: {},
}

export default (state = initialState, action: PayloadAction<any>): State => {
  switch (action.type) {
    case actions.LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        plans: action.payload,
        selectedPlan: action.payload[0],
        errors: {},
        hasFetched: true,
      }
    case actions.SELECT_PLAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedPlan: action.payload.profile.plan,
        errors: {},
      }
    case actions.LIST_REQUEST:
    case actions.SELECT_PLAN_REQUEST:
    case actions.UPLOAD_PLAN_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case actions.LIST_FAILURE:
    case actions.SELECT_PLAN_FAILURE:
    case actions.UPLOAD_PLAN_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: getApiErrorMessage(action.payload),
        hasFetched: true,
      }
    case actions.UPLOAD_PLAN_SUCCESS:
      return {
        ...state,
        plans: [...(state.plans ?? []), action.payload],
        selectedPlan: action.payload,
        isLoading: false,
        errors: {},
      }
    case actions.UPLOAD_UTILITY_BILL_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case actions.UPLOAD_UTILITY_BILL_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: getApiErrorMessage(action.payload),
      }
    case actions.UPLOAD_UTILITY_BILL_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case actions.CLEAR_PLAN_ERRORS:
      return {
        ...state,
        errors: {},
      }
    case utilityActions.CREATE_SUCCESS:
    case utilityActions.SELECT_UTILITY_SUCCESS:
      return {
        ...state,
        plans: null,
        selectedPlan: null,
      }
    default:
      return state
  }
}
