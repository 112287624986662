import { useCallback, useEffect, useState } from 'react'
import { slideInVariant } from '@/components/Wizard/WizardAnimations'
import isMobile from 'is-mobile'
import WizardStep from '@/components/Wizard/WizardStep/WizardStep'
import { AnimatePresence, motion } from 'framer-motion'
import { Identifiable } from '@/types'
import { useMockableViewModel } from '@/hooks'

export interface Props extends Identifiable {
  questionnaire: WizardPage[]
  onComplete?: () => void
  onIndexChange?: (idx: number) => void
  prevStepSignal?: boolean
}

export interface WizardChoice {
  label: string
  value: string
}

export type Question = {
  prompt: string
  skipEnabled?: boolean
  skipOverride?: string
  helpText?: string
  helpTitle?: string
  helpSubtitle?: string
  helpSubtext?: string
}

export type InputQuestion = Question & {
  label: string
  type: 'number' | 'text'
  maxInput?: number
  minInput?: number
}

export type ChoicesQuestion = Question & {
  choices: WizardChoice[]
}

export type WizardQuestion = InputQuestion | ChoicesQuestion

export interface WizardPage extends Identifiable {
  question: WizardQuestion
  callback: (answer: string | null) => void
  onNext?: () => void
}

const useViewModel = useMockableViewModel({
  useViewModel(props: Props) {
    const [surveyIdx, setSurveyIdx] = useState(0)

    useEffect(() => {
      if (surveyIdx != props.questionnaire.length) {
        return
      }

      props.onComplete?.()
    }, [surveyIdx])

    const indexChange = useCallback(
      (newIdx: number) => {
        setSurveyIdx(newIdx)
        props.onIndexChange?.(newIdx)
      },
      [props.onIndexChange],
    )

    const progressSurvey = useCallback(() => {
      indexChange(surveyIdx + 1)
    }, [surveyIdx])

    const prevSurvey = useCallback(() => {
      indexChange(surveyIdx - 1)
    }, [surveyIdx])

    return {
      surveyIdx,
      progressSurvey,
      prevSurvey,
      questionnaire: props.questionnaire,
      prevStepSignal: props.prevStepSignal,
    }
  },
  useMockViewModel() {
    return {
      surveyIdx: 0,
      progressSurvey: () => alert('Progress survey!'),
      prevSurvey: () => alert('Previous page!'),
      questionnaire: [],
      prevStepSignal: false,
    }
  },
})

export default function Wizard(props: Props) {
  const {
    surveyIdx,
    progressSurvey,
    prevSurvey,
    questionnaire,
    prevStepSignal,
  } = useViewModel(props)
  const variants = slideInVariant(isMobile())

  useEffect(() => {
    if (prevStepSignal) {
      prevSurvey()
    }
  }, [prevStepSignal])

  return (
    <AnimatePresence>
      {
        <motion.div
          key={`question-${surveyIdx}`}
          initial={'initial'}
          animate="animate"
          exit={surveyIdx == questionnaire.length - 1 ? '' : 'exit'}
          style={{ height: '100%' }}
          variants={variants}
        >
          {questionnaire?.[surveyIdx] && (
            <WizardStep
              id={`${props.id}-${surveyIdx}`}
              callback={questionnaire[surveyIdx].callback}
              question={questionnaire[surveyIdx].question}
              onNext={progressSurvey}
            />
          )}
        </motion.div>
      }
    </AnimatePresence>
  )
}
