import { thermostatsCollection } from '../../../../reducers/thermostats'
import { ID } from '../../../../types/model'
import { useState } from 'react'
import { useAppSelector } from '../../../../hooks'
import { selectCostHistorySummaryForThermostat } from '../../../../selectors'
import { DataPeriod } from '../TotalSpentGraph/TotalSpentGraph'
import useMockableViewModel from '../../../../hooks/useMockableViewModel'
import mockData from '../../../../types/thermostatCostHistorySummary/mock.json'
import {
  ThermostatCostHistorySummary,
  ThermostatUsageInterval,
} from '../../../../types/thermostatCostHistorySummary'
import { useUsersPreferredTemperature } from '../../../../authenticated/hooks/useUsersPreferredTemperature'
import { ThermostatProvider } from '@/types/thermostat'

interface Props {
  thermostatId: ID
  thermostatName: ThermostatProvider
}

const costHistoryPeriods = ['day', 'week', 'month'] as const

function useViewModel(props: Props) {
  thermostatsCollection.useResourceAnnotationFetcher(
    props.thermostatId,
    'cost_history/summary',
  )

  const costHistory = useAppSelector((state) =>
    selectCostHistorySummaryForThermostat(state, props.thermostatId),
  )
  const costHistoryStatus = useAppSelector((state) =>
    thermostatsCollection.selectors.annotationStatus(
      state,
      'cost_history/summary',
    ),
  )
  const [period, setPeriod] = useState<DataPeriod>('day')
  const items = costHistory?.[period]

  function onChipGroupClick(index: number | null) {
    if (index === null) {
      return
    }
    setPeriod(costHistoryPeriods[index || 0])
  }

  return {
    ...props,
    loading: costHistoryStatus === 'loading' || costHistoryStatus === 'idle',
    items: {
      ...items,
      average_set_point: useUsersPreferredTemperature(
        items?.average_set_point || 0,
      ).value,
      total_cost_cents: items?.total_cost_cents || 0,
      cost_of_schedule_cents: items?.cost_of_schedule_cents || 0,
      cost_of_adjustments_cents: items?.cost_of_adjustments_cents || 0,
      total_kwh_used: items?.total_kwh_used || 0,
      usage_intervals:
        items?.usage_intervals || ([] as ThermostatUsageInterval[]),
      avg_high_temp_f: useUsersPreferredTemperature(items?.avg_high_temp_f || 0)
        .value,
      avg_low_temp_f: useUsersPreferredTemperature(items?.avg_low_temp_f || 0)
        .value,
      max_temp_f: useUsersPreferredTemperature(items?.max_temp_f || 0).value,
      min_temp_f: useUsersPreferredTemperature(items?.min_temp_f || 0).value,
    },
    onChipGroupClick,
    period,
    setPeriod,
  }
}

function useMockViewModel(props: Props) {
  const costHistory = mockData as ThermostatCostHistorySummary
  const [period, setPeriod] = useState<DataPeriod>('day')
  const items = costHistory?.[period]

  function onChipGroupClick(index: number | null) {
    if (index === null) {
      return
    }
    setPeriod(costHistoryPeriods[index || 0])
  }

  return {
    ...props,
    loading: false,
    items,
    onChipGroupClick,
    period,
    setPeriod,
  }
}

export default useMockableViewModel({ useViewModel, useMockViewModel })
