import { RSAA } from 'redux-api-middleware'
import { withAuth } from '../reducers'
import config from '../config'
import { RequestTypeEnum } from '../types/requests'

export enum Get {
  Request = '@@gasCarConversion/GAS_CAR_CONVERSION_GET_REQUEST',
  Success = '@@gasCarConversion/GAS_CAR_CONVERSION_GET_SUCCESS',
  Failure = '@@gasCarConversion/GAS_CAR_CONVERSION_GET_FAILURE',
}

export function get(
  params: { [key: string]: any },
  TypeEnum: RequestTypeEnum = Get,
) {
  return {
    [RSAA]: {
      endpoint: `${config.apiGateway.URL}/api/gas_car_conversions/`,
      method: 'GET',
      params,
      headers: withAuth({ 'Content-Type': 'application/json' }),
      types: [TypeEnum.Request, TypeEnum.Success, TypeEnum.Failure],
    },
  }
}

export enum GetCost {
  Request = '@@gasCarConversion/GAS_CAR_CONVERSION_GET_COST_REQUEST',
  Success = '@@gasCarConversion/GAS_CAR_CONVERSION_GET_COST_SUCCESS',
  Failure = '@@gasCarConversion/GAS_CAR_CONVERSION_GET_COST_FAILURE',
}

export function getCost(
  gasCarConversionId: number,
  TypeEnum: RequestTypeEnum = GetCost,
) {
  return {
    [RSAA]: {
      endpoint: `${config.apiGateway.URL}/api/gas_car_conversions/${gasCarConversionId}/cost`,
      method: 'GET',
      headers: withAuth({ 'Content-Type': 'application/json' }),
      types: [TypeEnum.Request, TypeEnum.Success, TypeEnum.Failure],
    },
  }
}

export function search(query: string, TypeEnum: RequestTypeEnum = Get) {
  return get({ search: query }, TypeEnum)
}
