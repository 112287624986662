import { BoxedSymbol, Flex, IconDetailListItem, List } from '@/components'
import { IconNames } from '@/components/Icon/Icon'

type Props = {
  make: string
}

export default function VehicleConnectionPitch({ make }: Props) {
  const listItems = [
    {
      icon: 'Shield' as IconNames,
      title: 'Share your data securely',
      description: `Optiwatt lets you securely connect your ${make} to Optiwatt in seconds`,
    },
    {
      icon: 'Key' as IconNames,
      title: 'Your data belongs to you',
      description:
        'Review permissions before you enable, and revoke access at any time',
    },
    {
      icon: 'Heart' as IconNames,
      title: 'Join our growing community',
      description:
        'Over 80,000 EV drivers have trusted us to unlock more savings',
    },
  ]
  return (
    <List gap="md">
      {listItems.map((item, index) => (
        <Flex key={index} item>
          <IconDetailListItem
            icon={() => <BoxedSymbol variant="icon" iconName={item.icon} />}
            title={item.title}
            description={item.description}
          />
        </Flex>
      ))}
    </List>
  )
}
