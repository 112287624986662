import React, { useState } from 'react'
import { getVehicleTimeline } from '../../../actions/admin'
import { useDispatch, useSelector } from 'react-redux'
import Timeline from '../../components/GoogleChart/Timeline'
import Loader from '../../components/layout/Loader'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}))

class CheckboxField extends React.PureComponent {
  handleCheck = (event, isInputChecked) => {
    this.props.onChange(event, isInputChecked, this.props.category)
  }

  render() {
    return (
      <Checkbox
        label={this.props.category}
        iconStyle={{ fill: '#000' }}
        value={this.props.category}
        onCheck={this.handleCheck}
      />
    )
  }
}

function VehicleTimeline({ userEmail = '' }) {
  const [email, setEmail] = useState(userEmail)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [options, setOptions] = React.useState({
    location_timeline: false,
    vehicle_state_timeline: false,
    charge_state_timeline: false,
    charge_timeline: false,
    drive_timeline: false,
    sleep_timeline: false,
    sleep_request_timeline: false,
    charge_schedule_timeline: false,
    charge_schedule_command_timeline: false,
    charge_schedule_decision_timeline: false,
    rate_timeline: false,
    tesla_vehicle_exception: false,
    tesla_profile_exception: false,
  })
  const { timeLines, isLoading, errors } = useSelector((state) => state.admin)
  const dispatch = useDispatch()
  const classes = useStyles()

  const handleCheckboxChange = (event) => {
    setOptions({ ...options, [event.target.name]: event.target.checked })
  }

  function sendCommand() {
    dispatch(
      getVehicleTimeline({
        email: email,
        startDate: startDate,
        endDate: endDate,
        options: options,
      }),
    )
  }

  return (
    <React.Fragment>
      <form className={classes.container} style={{ padding: '10px' }}>
        <div>
          <TextField
            id="email"
            label="Email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            id="startDate"
            label="Start Date"
            type="date"
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <TextField
            id="endDate"
            label="End Date"
            type="date"
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
      </form>
      <form>
        <Grid>
          {Object.keys(options).map((key, index) => (
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={options.key}
                    onChange={handleCheckboxChange}
                    name={key}
                  />
                }
                label={key}
              />
            </Grid>
          ))}
        </Grid>
        <Button onClick={sendCommand} variant="contained" color="primary">
          Submit
        </Button>
      </form>
      {timeLines && <Timeline timeLines={timeLines} />}
      {errors.detail ? (
        <div className="alert alert-danger" role="alert">
          {errors.detail}
        </div>
      ) : (
        ''
      )}
      {isLoading && <Loader />}
    </React.Fragment>
  )
}

export default VehicleTimeline
