import { Card, Grid, GridItem } from '@/components'
import { BreakpointContext } from '@/context'
import formatClasses from '@/utils/classes/formatClasses'
import { useContext } from 'react'
import './styles.scss'

export interface Props {
  children: React.ReactNode
}

export default function FullPageCard(props: Props) {
  const breakpoint = useContext(BreakpointContext)

  const classes = {
    mobileCard: formatClasses(['full-page-card']),
    desktopCard: formatClasses(['full-page-card', 'desktop']),
  }

  // Mobile
  if (breakpoint.smAndDown) {
    return (
      <Card className={classes.mobileCard} flat fullHeight>
        {props.children}
      </Card>
    )
  }

  // Desktop
  return (
    <Grid templateColumns="1fr">
      <GridItem placeItems="center">
        <Card
          className={classes.desktopCard}
          flat={breakpoint.smAndDown}
          fullHeight={breakpoint.smAndDown}
        >
          {props.children}
        </Card>
      </GridItem>
    </Grid>
  )
}
