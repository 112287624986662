import { NavigationPage } from '@/app/components'
import ControlledVehiclesList from './ControlledVehiclesList'
import useViewModel from './useViewModel'

const LinkedVehicles = () => {
  const props = useViewModel()

  return (
    <NavigationPage
      id="vehicle-charger-linked-vehicles"
      title="EVs controlled by this charger"
      subtitle="Below are your vehicles managed by this
    charger’s settings:"
    >
      <NavigationPage.SingleCardContent
        overrideFullscreen
        className="px-5 py-1"
      >
        <ControlledVehiclesList
          vehicleList={props.vehicles}
          onToggleChange={props.handleToggleChange}
          hideLastDivider
        />
      </NavigationPage.SingleCardContent>
    </NavigationPage>
  )
}

export default LinkedVehicles
