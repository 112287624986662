import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Paper as MuiPaper, TextField as MuiTextField } from '@material-ui/core'

import { spacing } from '@material-ui/system'
import InputAdornment from '@material-ui/core/InputAdornment'
import Grid from '@material-ui/core/Grid'
import AddRow from './AddRow'
import { OVERLAP_HOURS_MESSAGE } from './RatesGraph'
import { useDispatch } from 'react-redux'
import {
  addRate,
  deleteRate,
  setErrorHours,
} from '../../../../actions/manualPlans'
import { getHoursRange } from '../../../../utils/rangeUtilities'
import { getEvenHourFromTime } from '../../../../utils/timeUtilities'
import { getOverlapping } from './Plan'
import { useSelector } from 'react-redux'

const Paper = styled(MuiPaper)(spacing)

const TextFieldSpacing = styled(MuiTextField)(spacing)

const TextField = styled(TextFieldSpacing)`
  width: 200px;
`

const StyledPaper = styled(Paper)`
  margin-left: 0px;
  padding: 0px 10px;
`

function Rate(props) {
  const rates = props.rates
  const [nameState, setNameState] = useState(props)
  const [index, setIndex] = useState(props.index)
  const [rate, setRate] = useState(props.rate)
  const [start, setStart] = useState(props.start ?? '00:00')
  const [end, setEnd] = useState(props.end ?? '00:00')
  const [errors, setErrors] = useState(props.errors)
  const currentUser = useSelector((state) => state.user.user)
  const currency = currentUser?.profile?.currency
    ? currentUser.profile.currency
    : { name: 'USD', symbol: '$' }

  const dispatch = useDispatch()

  useEffect(() => {
    setNameState(props)
    setStart(start ? start : props.start ?? '00:00')
    setEnd(end ? end : props.end ?? '00:00')
    setRate(rate ? rate : props.rate ?? '00:00')
  }, [props])

  function resetState() {
    setIndex(null)
    setRate('')
    setStart('')
    setEnd('')
    setErrors({ rate: null, start: null, end: null })
  }

  function onSubmit(event) {
    event.preventDefault()
    console.log('Submitting')
    if (event.target.action.value === 'add') {
      let isValid = validateForm(
        index,
        event.target.start.value,
        event.target.end.value,
      )
      if (isValid) {
        let rate = {
          index: index,
          rate: event.target.rate.value,
          start: event.target.start.value,
          end: event.target.end.value,
          errors: { rate: null, start: null, end: null },
          editRates: false,
        }
        dispatch(addRate(props.planId, rate, props.type))
        resetState()
      }
    } else {
      dispatch(deleteRate(props.planId, index, props.type))
    }
  }

  function validateForm(index, start, end) {
    return validateOverlapping(index, start, end)
  }

  function validateOverlapping(index, start, end) {
    let overlappingHours = getOverlapping(
      index,
      rates,
      'index',
      getHoursRange,
      start,
      end,
    )
    dispatch(setErrorHours(overlappingHours))
    if (overlappingHours.length > 0) {
      setErrors({
        rate: null,
        start: OVERLAP_HOURS_MESSAGE,
        end: OVERLAP_HOURS_MESSAGE,
      })
    }
    return overlappingHours.length === 0
  }

  return (
    <StyledPaper mt={5}>
      <form autoComplete="off" onSubmit={onSubmit}>
        <Grid container>
          <Grid item xs={11} md={11}>
            <TextField
              required
              id="standard-required"
              value={rate}
              disabled={props.disabled}
              name="rate"
              label={`${currency?.symbol}/kWh`}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {currency?.symbol}
                  </InputAdornment>
                ),
              }}
              inputProps={{
                step: 0.00001,
              }}
              m={2}
              style={{ width: '21%' }}
              onChange={(e) => setRate(e.target.value)}
            />

            <TextField
              id="standard-uncontrolled"
              value={start}
              name="start"
              required
              disabled={props.disabled}
              type="time"
              label="Start Time"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 3600, // 1 hour
              }}
              m={2}
              error={errors.start !== null}
              helperText={errors.start}
              style={{ width: '30%' }}
              onChange={(e) => setStart(getEvenHourFromTime(e.target.value))}
            />

            <TextField
              id="standard-uncontrolled"
              value={end}
              required
              disabled={props.disabled}
              type="time"
              name="end"
              label="End Time"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 3600, // 1 hour
              }}
              m={2}
              error={errors.end !== null}
              helperText={errors.end}
              style={{ width: '30%' }}
              onChange={(e) => setEnd(getEvenHourFromTime(e.target.value))}
            />
          </Grid>
          <Grid item xs={1} mb={3}>
            <AddRow
              id={props.addButtonId}
              disabled={props.disabled}
              fabDisabled={props.fabDisabled}
            />
          </Grid>
        </Grid>
      </form>
    </StyledPaper>
  )
}

export default Rate
