import React from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from '../../../v2/theme'
import { ViewModelComponentProps } from '../../../utils/typeUtilities'
import ConnectUtility, { Props as ComponentProps } from './ConnectUtility'
import useViewModel from './useViewModel'

type Props = ViewModelComponentProps<ComponentProps, typeof useViewModel>

export default ({ ...props }: Props) => {
  const viewModel = useViewModel(props)

  return (
    <ThemeProvider theme={theme}>
      <ConnectUtility {...viewModel} {...props} />
    </ThemeProvider>
  )
}
