import useBasePath from '@/hooks/useBasePath'
import { useLocation } from 'react-router-dom'
import { useNavigation } from '@/app/hooks'
import useExternalNavigation from '@/app/hooks/useExternalNavigation'
import useIsMobile from '@/authenticated/hooks/useIsMobile'
import useRemoteConfig from '@/hooks/useRemoteConfig'
import { searchableUtilityProgramsCollection } from '@/reducers/utilityPrograms'
import useStoreLinks from '@/app/hooks/useStoreLinks/useStoreLinks'
import { useEffect } from 'react'
import { isIOS } from 'react-device-detect'
import { useMockableViewModel } from '@/hooks'

const useViewModel = () => {
  const appBasePath = useBasePath()
  const basePath = appBasePath === 'download' ? '' : `${appBasePath}/`
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const urlParams = searchParams.toString()
  const navigate = useNavigation()
  const { externallyNavigate } = useExternalNavigation()

  const isMobile = useIsMobile()
  const { appRemoteConfig, isLoading: remoteConfigLoading } = useRemoteConfig()
  const whiteLabelId =
    appRemoteConfig?.getWhitelabelId(appBasePath ?? '') ?? null
  const { data: utilityPrograms, isLoadingOrIdle: fetchingViewConfig } =
    searchableUtilityProgramsCollection.useFetch(whiteLabelId ?? -1, {
      require: Boolean(whiteLabelId) && whiteLabelId !== null,
    })
  const viewConfig = utilityPrograms?.[0]?.view_config_json
  const {
    androidUrl,
    appleUrl,
    isLoading: loadingStoreLinks,
  } = useStoreLinks(viewConfig)
  const isLoading =
    remoteConfigLoading ||
    (fetchingViewConfig && whiteLabelId) ||
    loadingStoreLinks

  const signupUrl = `${basePath}signup${urlParams ? `?${urlParams}` : ''}`

  useEffect(() => {
    if (isLoading) {
      return
    }

    if (isMobile) {
      externallyNavigate(isIOS ? appleUrl : androidUrl)
    }

    navigate.push('/' + signupUrl)
  }, [appBasePath, isLoading])

  return { isLoading }
}

const useMockViewModel = () => {
  return { isLoading: false }
}

export default useMockableViewModel({ useViewModel, useMockViewModel })
