import styled from 'styled-components'

import { Typography as MuiTypography } from '@material-ui/core'

import { spacing } from '@material-ui/system'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'

const Typography = styled(MuiTypography)(spacing)

const useStyles = makeStyles((theme) => ({
  legendText: {
    fontSize: '0.75rem',
    fontWeight: theme.typography.fontWeightBold,
  },
  legendValueText: {
    color: '#5D5D5D',
  },
}))

const LegendLabelTypography = ({ className = '', children, ...restProps }) => {
  const classes = useStyles()

  return (
    <Typography
      className={clsx(classes.legendText, className)}
      variant="body1"
      {...restProps}
    >
      {children}
    </Typography>
  )
}

const LegendValueTypography = ({ className = '', children, ...restProps }) => {
  const classes = useStyles()

  return (
    <Typography
      className={clsx(classes.legendText, classes.legendValueText, className)}
      variant="body1"
      {...restProps}
    >
      {children}
    </Typography>
  )
}

export { LegendLabelTypography, LegendValueTypography }
