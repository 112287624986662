import './styles.scss'
import UtilityProgramConnectStatusCard from '@/app/features/utility-program-connect/UtilityProgramConnectStatusCard'
import formatClasses from '@/utils/classes/formatClasses'
import { Grid, GridItem } from '@/components'
import { useUrlSearchParams } from '@/hooks'
import { searchableUtilityProgramsCollection } from '@/reducers/utilityPrograms'

export default function UtilityProgramConnect() {
  const classes = {
    container: formatClasses(['utility-program-connect-container']),
    card: formatClasses(['status-card']),
  }

  const urlParams = useUrlSearchParams()
  const utilityProgramId = urlParams.get('program_id') ?? undefined
  const utilityProgramName = urlParams.get('utility') ?? undefined
  searchableUtilityProgramsCollection.useResourceFetcher(
    { id: utilityProgramId },
    { require: Boolean(utilityProgramId) },
  )

  const errors =
    urlParams.get('errors') || !utilityProgramId || !utilityProgramName
  const statusCardProps = errors
    ? ({
        status: 'error',
      } as const)
    : ({
        status: 'success',
        utilityProgramId,
        utilityProgramName,
      } as const)

  return (
    <Grid
      className={classes.container}
      placeContent="center"
      templateColumns="1fr"
    >
      <GridItem placeItems="center">
        <UtilityProgramConnectStatusCard
          className={classes.card}
          {...statusCardProps}
        />
      </GridItem>
    </Grid>
  )
}
