import { Alert, AlertProps } from '@/components'
import './styles.scss'
import formatClasses from '@/utils/classes/formatClasses'
import { sanitizeHtmlContent } from '@/utils/components'
import { IconNames } from '@/components/Icon/Icon'

export interface Props extends AlertProps {
  variant: 'info' | 'warning' | 'error' | 'success'
  onClose?: () => void
  rawHtmlText?: string
  iconOverride?: IconNames
}

const colorVariantMap = {
  info: {
    background: 'text-base-blue-100',
    content: 'text-base-blue-500',
  },
  warning: {
    background: 'text-base-orange-100',
    content: 'text-base-orange-500',
  },
  error: {
    background: 'text-base-red-100',
    content: 'text-base-red-500',
  },
  success: {
    background: 'text-base-green-100',
    content: 'text-base-green-900',
  },
}

export default function AlertMessage({
  children,
  variant,
  className,
  onClose,
  ...props
}: Props) {
  const classNameInternal = formatClasses(['alert-message', className])
  const colors = colorVariantMap[variant]

  const backgroundColor = colors.background.replace('text-', '')
  const iconColor = colors.content.replace('text-', '')
  const iconName = props.iconOverride
    ? props.iconOverride
    : (
        {
          info: 'Info',
          warning: 'AlertTriangle',
          error: 'AlertCircle',
          success: 'Check',
        } as const
      )[variant]

  const rawText = props.rawHtmlText
    ? sanitizeHtmlContent(props.rawHtmlText)
    : undefined

  return (
    <Alert color={backgroundColor} className={classNameInternal} {...props}>
      <Alert.Icon name={iconName} color={iconColor} />
      {rawText ? (
        <Alert.Text
          className={`${colors.content} overflow-auto`}
          dangerouslySetInnerHTML={{ __html: rawText }}
        />
      ) : (
        <Alert.Text className={`${colors.content} overflow-auto`}>
          {children}
        </Alert.Text>
      )}
      {onClose && <Alert.Close color={iconColor} onClose={onClose} />}
    </Alert>
  )
}
