import { useState } from 'react'
import {
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Typography as MuiTypography,
  Grid as MuiGrid,
  Box as MuiBox,
  Button,
  useTheme,
} from '@material-ui/core'
import { Heart, X } from 'react-feather'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { alpha } from '@mui/material'
import theme from '../../../../v2/theme'
import { ThemeProvider } from '@material-ui/core/styles'
import { useSurveyResponder } from '@/app/components/SurveyObserver'
import { RequestStatus } from '@/request/types'
import PageLoader from '@/authenticated/components/layout/PageLoader'
import { useAppSelector } from '@/hooks'

const Typography = styled(MuiTypography)(spacing)
const Grid = styled(MuiGrid)(spacing)
const Dialog = styled(MuiDialog)(spacing)
const DialogTitle = styled(MuiDialogTitle)(spacing)
const Box = styled(MuiBox)(spacing)
const DialogContent = styled(MuiDialogContent)(spacing)

const useStyles = makeStyles((theme) =>
  createStyles({
    iconButton: {
      position: 'absolute',
      top: theme.spacing(4),
      right: theme.spacing(4),
      padding: '4px',
    },
    scoreBox: {
      borderRadius: '8px',
      cursor: 'pointer',
      width: '42px',
      height: '42px',
      '&:hover': {
        backgroundColor: alpha(theme.palette.blue?.main ?? '', 0.5),
      },
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      userSelect: 'none',
    },
    scaleText: {
      fontSize: '12px',
      fontWeight: 'bold',
    },
    submitButton: {
      height: '50px',
      maxWidth: '400px',
      width: '100%',
      borderRadius: '20px',
    },
    buttonContainer: {
      textAlign: 'center',
    },
    dialog: {
      margin: theme.spacing(3),
      padding: `0 ${theme.spacing(2)}px`,
      maxWidth: '650px',
    },
    mainContent: {
      minHeight: '80px',
      [theme.breakpoints.down('xs')]: {
        minHeight: '125px',
      },
    },
  }),
)

type Props = {
  onScoreSelect: (score: number) => () => void
  selected: number | undefined
}

function ScorePicker({ onScoreSelect, selected }: Props) {
  const classes = useStyles()
  const theme = useTheme()
  const scores = [...Array(11).keys()]

  return (
    <>
      <Grid container justifyContent="space-between" direction="row" mb={2}>
        <Grid item>
          <Typography className={classes.scaleText} variant="subtitle1">
            0 - Not likely at all
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.scaleText} variant="subtitle1">
            10 - Extremely likely
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3} justifyContent="center" direction="row">
        {scores.map((score) => (
          <Grid item key={score}>
            <Box
              className={classes.scoreBox}
              onClick={onScoreSelect(score)}
              style={{
                backgroundColor:
                  selected === score
                    ? theme.palette.blue?.main ?? ''
                    : theme.palette.blue?.background ?? '',
              }}
            >
              <Typography
                variant="h6"
                style={{
                  color:
                    selected === score
                      ? theme.palette.blue?.contrastText ?? ''
                      : theme.palette.blue?.main ?? '',
                }}
              >
                {score}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  )
}

function Thanks() {
  return (
    <Grid container justifyContent="center">
      <Grid item>
        <Heart size={48} color={'#FB42D2'} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4" pt={5} px={12} align="center">
          You rock! Thanks for sharing your feedback.
        </Typography>
      </Grid>
    </Grid>
  )
}

function UtilityProgramSurveyDialog() {
  const classes = useStyles()
  const { dismissSurvey, submitResponse } = useSurveyResponder('UtilityNPS')
  const [score, setScore] = useState<number>()
  const [open, setOpen] = useState(true)
  const [confirmationPending, setConfirmationPending] = useState(false)
  const utilityId = useAppSelector(
    (state) => state.utilities.selectedUtility?.id,
  )

  const onScoreSelect = (score: number) => () => {
    setScore(score)
  }

  const onConfirm = () => {
    submitResponse({
      score,
      utility_id: utilityId,
    })
    setOpen(false)
  }

  const onScoreSubmit = () => {
    setConfirmationPending(true)
  }

  const onDismiss = () => {
    if (score) {
      submitResponse({
        score,
        utilityId: utilityId,
      })
    } else {
      dismissSurvey()
    }
    setOpen(false)
  }

  return (
    <Dialog open={open ?? true} classes={{ paper: classes.dialog }}>
      <DialogTitle
        style={{ opacity: confirmationPending ? 0 : 1 }}
        p={4}
        pt={6}
        pb={8}
        pr={12}
      >
        How likely are you to recommend us to a friend or colleague?
      </DialogTitle>
      <IconButton
        className={classes.iconButton}
        onClick={confirmationPending ? onConfirm : onDismiss}
      >
        <X size={16} color="black" />
      </IconButton>
      <DialogContent p={4} pt={0}>
        <Box className={classes.mainContent}>
          {confirmationPending ? (
            <Thanks />
          ) : (
            <ScorePicker onScoreSelect={onScoreSelect} selected={score} />
          )}
        </Box>
        <Box className={classes.buttonContainer} pt={7}>
          <Button
            className={classes.submitButton}
            variant="contained"
            color="primary"
            disabled={!score}
            onClick={confirmationPending ? onConfirm : onScoreSubmit}
          >
            {confirmationPending ? 'Done' : 'Submit'}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default function ThemedUtilityProgramNPSSurveyDialog() {
  return (
    <ThemeProvider theme={theme}>
      <UtilityProgramSurveyDialog />
    </ThemeProvider>
  )
}
