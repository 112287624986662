import { TransactionRequest } from '../types/transaction'
import { createResourceCollection } from '../request'

export const transactionsCollection =
  createResourceCollection<TransactionRequest>({
    name: 'transactions',
    apiConfig: {
      path: 'transactions',
      invalidateOnRequest: true,
    },
    paginationKey: 'UNSET',
    selector: (state) => state.transactions,
  })

const transactionsReducer = transactionsCollection.slice.reducer

export default transactionsReducer
