import React, { useCallback, useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { isIOS } from 'react-device-detect'
import useIsMobile from '@/authenticated/hooks/useIsMobile'
import { logEvent } from '@/logging'
import useBasePath from '@/hooks/useBasePath'
import { searchableUtilityProgramsCollection } from '@/reducers/utilityPrograms'
import useRemoteConfig from '@/hooks/useRemoteConfig'
import OptiwattLogo from '@/app/pages/autogrid/optiwatt-logo.webp'
import { LoadingAnimation, Text } from '@/components'
import { Link } from 'app-components'
import { motion } from 'framer-motion'
import useImagesLoaded from '@/hooks/useImageLoader'
import { debounce } from 'lodash'
import useStoreLinks from '@/app/hooks/useStoreLinks/useStoreLinks'
import useExternalNavigation from '@/app/hooks/useExternalNavigation'

const MOBILE_SCHEME = 'optiwatt'
const DEFAULT_PATH = 'app'
const REDIRECT_DEPLAY_SECONDS = 5

type URLParams = {
  appPath: string | undefined
}

function DeviceTrafficControl() {
  const history = useHistory()
  const { appPath: maybeAppPath } = useParams<URLParams>()
  const location = useLocation()
  const appPath = maybeAppPath ?? DEFAULT_PATH
  const mobileAppUrl = `${MOBILE_SCHEME}://${appPath}${location?.search}`
  const isMobile = useIsMobile()
  const appBasePath = useBasePath()
  const { appRemoteConfig, isLoading: remoteConfigLoading } = useRemoteConfig()
  const { isFinished: imagesLoaded } = useImagesLoaded([OptiwattLogo])
  const { externallyNavigate } = useExternalNavigation()

  // We can customize the mobile app download link location via CMS config
  // Create a whitelabel slug in our remote config that maps to a utility program's
  // viewConfig, and edit the view config's google/apple store URLs. Then we'll
  // send incoming deeplinkable users to those custom product pages if they don't
  // yet have the app installed
  const whiteLabelId =
    appRemoteConfig?.getWhitelabelId(appBasePath ?? '') ?? null
  const { data: utilityPrograms, isLoadingOrIdle } =
    searchableUtilityProgramsCollection.useFetch(whiteLabelId ?? -1, {
      require: Boolean(whiteLabelId) && whiteLabelId !== null,
    })
  const viewConfig = utilityPrograms?.[0]?.view_config_json
  const { androidUrl, appleUrl } = useStoreLinks(viewConfig)

  const tryToOpenInMobile = () => {
    window.location.replace(mobileAppUrl)
  }

  const navigateToWebApp = () => {
    // 'navPath' refers to our deeplinking catch-all 'navigate' -- if we
    // are trying to use the /navigate route, which supports arbitrary deeplinking
    // in the native mobile app, we want to remove the navigate portion of the
    // route when navigating on web
    if (appPath.toLowerCase() === 'navigate') {
      const params = new URLSearchParams(location?.search)
      const route = params.get('url')

      fireDeeplinkEvent(route)
      history.replace(`/${route}`)
    } else {
      fireDeeplinkEvent(appPath)
      history.replace(`/${appPath}`)
    }
  }

  const fireDeeplinkEvent = (route: string | null) => {
    if (!route) {
      return
    }

    if (route.includes('connect-device/vehicle/ford')) {
      logEvent('ford_connection_qr_scan')
    }
  }

  const navigateToDeviceAppStore = useCallback(() => {
    if (isIOS) {
      externallyNavigate(appleUrl)
    } else {
      externallyNavigate(androidUrl)
    }
  }, [remoteConfigLoading])

  const isLoading =
    (whiteLabelId && isLoadingOrIdle) || remoteConfigLoading || !imagesLoaded

  const debouncedNavigation = useCallback(
    debounce(() => {
      if (isMobile && !remoteConfigLoading) {
        navigateToDeviceAppStore()
      } else {
        navigateToWebApp()
      }
    }, 1000 * REDIRECT_DEPLAY_SECONDS),
    [isMobile, remoteConfigLoading],
  )

  useEffect(() => {
    if (isLoading) {
      return
    }

    if (!isMobile) {
      navigateToWebApp()
      return
    }

    tryToOpenInMobile()

    debouncedNavigation()
  }, [isLoading, whiteLabelId, isMobile])

  if (isLoading) {
    return null
  }

  return (
    <motion.div
      className="flex gap-4 max-w-[400px] flex-col justify-center items-center h-screen px-8 mx-auto"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div id="image-container" className="w-full h-[98px]">
        <img alt="optiwatt-logo" src={OptiwattLogo} className="w-full" />
      </div>
      <Text variant="h2" className="text-center">
        Redirecting you to the Optiwatt app.
      </Text>
      <LoadingAnimation type="falling" centered />
      <Text variant="body4" className="text-center">
        If you haven't been redirected within {REDIRECT_DEPLAY_SECONDS} seconds,{' '}
        <Link onClick={navigateToWebApp}>click here</Link>.
      </Text>
    </motion.div>
  )
}

export default DeviceTrafficControl
