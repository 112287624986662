import { useState } from 'react'
import styled from 'styled-components'
import { green, red } from '@material-ui/core/colors'
import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  IconButton,
  Typography as MuiTypography,
} from '@material-ui/core'
import { Chip } from './Chip'
import { spacing } from '@material-ui/system'
import { Battery } from '../../app/features/electric-vehicles/charging-history'
import { Activity, Clock, HelpCircle } from 'react-feather'
import GasSavingsDialog from './GasSavingsDialog'
import { getDateFromTimestamp } from '../../utils/timeUtilities'
import ChargingCostDialog from './dialogs/ChargingCostDialog'
import ChargingDurationDialog from './dialogs/ChargingDurationDialog'
import ChargingEfficiencyDialog from './dialogs/ChargingEfficiencyDialog'
import ChargingElectricityRateDialog from './dialogs/ChargingElectricityRateDialog'
import { useAppSelector } from '@/hooks'
import { Charge } from '@/types/charges'
import dayjs from 'dayjs'

const Card = styled(MuiCard)(spacing)

const StatsCard = styled(Card)`
  border-radius: 20px;
`

const Typography = styled(MuiTypography)(spacing)

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`

const SmallTypography = styled(MuiTypography)`
  color: ${(props) => props.theme.palette.secondary.main};

  span {
    color: ${(props: any) => props.percentagecolor};
    padding-right: 10px;
    font-weight: ${(props) => props.theme.typography.fontWeightBold};
  }
`

const SmallTypographyBlue = styled(SmallTypography)`
  color: ${(props) => props.theme.palette.secondary.main};
`

const SmallTypographyGreen = styled(SmallTypography)`
  color: ${green[500]};
`

const ClockIcon = styled(Clock)`
  margin-right: ${(props) => props.theme.spacing(2)}px;
  margin-bottom: 2px;
  width: 16px;
  height: 16px;
`

const ActivityIcon = styled(Activity)`
  margin-right: ${(props) => props.theme.spacing(2)}px;
  margin-bottom: 1px;
  width: 20px;
  height: 20px;
  color: ${(props) => props.theme.palette.secondary.main};
`

const BlueTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.secondary.main};
`

const SpanStyled = styled.span`
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
`

const Centered = styled.div`
  text-align: center;
`

const ExampleChip = styled(Chip)`
  background-color: ${() => red[700]};
  right: 0px;
  border-radius: 0px !important;
`

const SmallHelp = styled(HelpCircle)`
  width: 14px;
  height: 14px;
  color: ${(props) => props.theme.palette.primary.main};
`

const SmallHelpGreen = styled(HelpCircle)`
    width: 14px;
    height: 14px;
    color: ${green[500]}
    margin-bottom: 2px;
`

const SmallHelpBlue = styled(HelpCircle)`
  width: 14px;
  height: 14px;
  color: ${(props) => props.theme.palette.secondary.main};
  margin-bottom: 2px;
`

interface BatteryStatsProps {
  charge: Charge
  isExample: boolean
  vehicle: {
    id: string
    car: {
      car_model: {
        gas_car_conversion: boolean
      }
    }
  } | null
  reloadCharges: () => void
}

export default function BatteryStats(props: BatteryStatsProps) {
  const [durationOpen, setDurationOpen] = useState(false)
  const [chargingCostOpen, setChargingCostOpen] = useState(false)
  const [gasSavingsOpen, setGasSavingsOpen] = useState(false)
  const [electricityRateOpen, setElectricityRateOpen] = useState(false)
  const [efficiencyOpen, setEfficiencyOpen] = useState(false)
  const { charge, isExample } = props
  const selectedVehicle = props.vehicle
  const numberOfVehicles = useAppSelector(
    (state) => state.vehicles.vehicles?.length ?? 0,
  )
  const currency = charge.currency || 'USD'

  const totalChargeCost = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  }).format(charge?.total_amount_in_charge_currency)

  const gasSavingsCost = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  }).format(charge?.total_gas_savings_in_charge_currency)

  const handleGasSavingsClose = () => {
    setGasSavingsOpen(false)
    props.reloadCharges()
  }

  const handleGasSavingsSubmitPressed = () => {
    if (numberOfVehicles === 1) {
      handleGasSavingsClose()
    }
  }

  return (
    <StatsCard mb={3}>
      <CardContent>
        <Typography variant="h6" mb={4}>
          <span className="flex items-center">
            <ClockIcon />
            {charge.total_time_formatted}
            <IconButton
              onClick={() => setDurationOpen(true)}
              style={{ marginBottom: '0 !important' }}
            >
              <SmallHelp />
            </IconButton>
            <ChargingDurationDialog
              open={durationOpen}
              handleClose={() => setDurationOpen(false)}
            />
          </span>
        </Typography>
        {charge?.total_amount_in_charge_currency && (
          <Typography variant="h3" mb={3}>
            <SpanStyled>{totalChargeCost}</SpanStyled>
            <IconButton
              onClick={() => setChargingCostOpen(true)}
              style={{ padding: '6px' }}
            >
              <SmallHelp />
            </IconButton>
            <ChargingCostDialog
              open={chargingCostOpen}
              handleClose={() => setChargingCostOpen(false)}
            />
          </Typography>
        )}
        {selectedVehicle?.car?.car_model?.gas_car_conversion &&
          charge?.total_gas_savings_in_charge_currency &&
          currency === 'USD' && (
            <SmallTypographyGreen variant="subtitle1">
              {`${gasSavingsCost} in gas savings `}
              <IconButton
                onClick={() => setGasSavingsOpen(true)}
                style={{ padding: '6px' }}
              >
                <SmallHelpGreen />
              </IconButton>
              <GasSavingsDialog
                initialVehicleId={Number(selectedVehicle.id)}
                open={gasSavingsOpen}
                onClose={handleGasSavingsClose}
                onSubmitPressed={handleGasSavingsSubmitPressed}
              />
            </SmallTypographyGreen>
          )}
        <SmallTypographyBlue variant="subtitle1">
          <span>
            {`${new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: currency,
            }).format(charge?.average_cost_in_charge_currency_per_kwh)}/kWh`}
            <IconButton
              onClick={() => setElectricityRateOpen(true)}
              style={{ padding: '6px' }}
            >
              <SmallHelpBlue />
            </IconButton>
            <ChargingElectricityRateDialog
              open={electricityRateOpen}
              handleClose={() => setElectricityRateOpen(false)}
            />
          </span>
        </SmallTypographyBlue>
        {charge.start_battery_percent !== null &&
        charge.end_battery_percent !== null ? (
          <>
            <Centered>
              <Battery charge={charge} />
            </Centered>
            <BlueTypography variant="subtitle1" mb={4} align="center">
              <span className="flex items-center">
                <ActivityIcon />
                {charge?.efficiency_percent.toFixed(0)}%
                <IconButton
                  onClick={() => setEfficiencyOpen(true)}
                  style={{ marginBottom: '0 !important', marginTop: 2 }}
                >
                  <SmallHelpBlue />
                </IconButton>
                <ChargingEfficiencyDialog
                  open={efficiencyOpen}
                  handleClose={() => setEfficiencyOpen(false)}
                />
              </span>
            </BlueTypography>
          </>
        ) : (
          <Typography variant="body1" mb={4}>
            <SpanStyled>
              {`${dayjs(charge?.start_time).format('h:mm A')}`} -{' '}
              {`${dayjs(charge?.end_time).format('h:mm A')}`}
            </SpanStyled>
          </Typography>
        )}
        {isExample ? (
          <ExampleChip label="Example" />
        ) : (
          <Chip label={getDateFromTimestamp(charge?.start_time, 'MMM D')} />
        )}
      </CardContent>
    </StatsCard>
  )
}
