import { useAppSelector } from '@/hooks/index'

export default function useVehicle(vehicleId: number | undefined) {
  if (!vehicleId) {
    return undefined
  }
  return useAppSelector((state) =>
    state.vehicles?.vehicles?.find((vehicle) => vehicle.id === vehicleId),
  )
}
