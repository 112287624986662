import { useEffect } from 'react'

/**
 * Adds scripts to dom synchronously.
 * @param scriptSources An array of script sources that are added in the order
 * they are provided, synchronously.
 */
export default function useAddScripts(scriptSources: string[]) {
  useEffect(() => {
    const scripts = scriptSources.map((src) => {
      const script = document.createElement('script')
      script.src = src
      script.async = false
      document.body.appendChild(script)
      return script
    })

    return () => {
      scripts.forEach((script) => document.body.removeChild(script))
    }
  }, [])
}
