import { ConfigurableText } from '@/app/components'
import { Icon, Text } from '@/components'
import { capitalize } from '@/utils/stringUtilities'

export const CollapsibleListItemHeader = ({
  status,
  title,
}: {
  status: 'success' | 'failure' | 'unknown'
  title: string
}) => {
  return (
    <div className="flex flex-row items-center justify-start gap-4">
      <StatusIcon status={status} />
      <ConfigurableText className="text-themed-base-900">
        {title}
      </ConfigurableText>
    </div>
  )
}

const StatusIcon = ({
  status,
}: {
  status: 'success' | 'failure' | 'unknown'
}) => {
  const backgroundColor = {
    success: 'bg-themed-success',
    failure: 'bg-themed-error',
    unknown: 'bg-themed-unknown',
  }[status]
  return (
    <div
      className={`rounded-full flex-none w-[16px] h-[16px] flex items-center justify-center ${backgroundColor}`}
    >
      {(status === 'success' || status === 'failure') && (
        <Icon
          name={status === 'success' ? 'Check' : 'X'}
          size={12}
          className="text-themed-base-100"
          aria-label={capitalize(status)}
        />
      )}
      {status === 'unknown' && (
        <Text variant="body3" className="text-themed-base-100 font-light">
          ?
        </Text>
      )}
    </div>
  )
}
