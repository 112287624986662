import React from 'react'
import HeroSection from '../components/HeroSection/HeroSectionLogin1'

const Login = (props) => {
  return (
    <React.Fragment>
      <HeroSection {...props} />
    </React.Fragment>
  )
}

export default Login
