import { DeviceAuthAlertMessage } from '@/app/components'

export default function TeslaMissingAnyPermissionBanner(props: {
  vehicleId: number
}) {
  return (
    <DeviceAuthAlertMessage
      mode="warning"
      deviceName="vehicle"
      linkText="Enable full permissions"
      reconnectUrl={`/devices/vehicle/${props.vehicleId}/capability`}
      show
    >
      Enable full permissions to gain entry to our early access program.You’ll
      be able to explore our new features before they’re available to everyone
      else and to let us know what you think
    </DeviceAuthAlertMessage>
  )
}
