import { EntityAdapter, EntityState } from '@reduxjs/toolkit'
import {
  AnnotationRequestState,
  ID,
  QuerySpec,
  QueryState,
  RequestState,
  RequestStatus,
  ResourceModel,
  ResourceModelQueryResult,
} from '../types'
import {
  MatchQueryStateToQuerySpecOptions,
  QuerySpecWithPredicateProps,
  querySpecToIDString,
  queryStateMatchesQuerySpec,
} from '../utils'
import { createSelector, Const } from './createSelector'

type RootState = any

export function createResourceSelectors<Resource extends ResourceModel>(
  adapter: EntityAdapter<ResourceModelQueryResult<Resource>>,
  queryStateAdapter: EntityAdapter<QueryState>,
  selector: (
    state: RootState,
  ) => EntityState<ResourceModelQueryResult<Resource>> &
    RequestState &
    AnnotationRequestState,
  stateSelector: (state: RootState) => EntityState<QueryState>,
) {
  const dataSelectors = adapter.getSelectors(selector)
  const queryStateSelectors = queryStateAdapter.getSelectors(stateSelector)

  const status = createSelector(
    [(state) => selector(state)],
    (resourceRequestState) => resourceRequestState.status,
  )
  const errors = createSelector(
    [(state) => selector(state)],
    (resourceRequestState) => resourceRequestState.errors,
  )

  const annotationStatus = createSelector(
    [
      (state) => selector(state).annotationStatus,
      (state, annotation: string) => annotation,
    ],
    (annotationStatus, annotation) =>
      annotationStatus[annotation] ?? RequestStatus.Idle,
  )
  const annotationErrors = createSelector(
    [
      (state) => selector(state).annotationErrors,
      (state, annotation: string) => annotation,
    ],
    (annotationErrors, annotation) => annotationErrors[annotation] ?? null,
  )
  const allAnnotationErrors = createSelector(
    [(state) => selector(state).annotationErrors],
    (annotationErrors) => annotationErrors,
  )

  const selectQueryStatesByResourceId = createSelector(
    [(state) => queryStateSelectors.selectAll(state), (state, id: ID) => id],
    (queryStates, id) =>
      queryStates.filter((queryState) => queryState.id === id),
  )

  const selectQueryStateByQuerySpec = createSelector(
    [
      // @todo this is a bad selector that will cause a lot of unnecessary recomputations (on all query state updates)
      // i wonder if we can has a query spec to an id for O(1) lookup?
      (state) => queryStateSelectors.selectAll(state),
      (state, querySpec?: Partial<QuerySpecWithPredicateProps>) =>
        Const(querySpec),
      (
        state,
        querySpec?: Partial<QuerySpecWithPredicateProps>,
        options?: MatchQueryStateToQuerySpecOptions,
      ) => Const(options),
    ],
    (queryStates, querySpec, options) => {
      const defaultOptions = { includePartialMatches: true }
      return queryStates.filter((queryState) =>
        queryStateMatchesQuerySpec(queryState, querySpec.wrapped ?? {}, {
          ...defaultOptions,
          ...options.wrapped,
        }),
      )
    },
  )

  const selectSomeLoading = createSelector(
    [(state) => queryStateSelectors.selectAll(state)],
    (queryStates) =>
      queryStates.some(
        (queryState) => queryState.status === RequestStatus.Loading,
      ),
  )

  const selectDataExactlyMatchingQuerySpec = createSelector(
    [
      (state) => dataSelectors.selectAll(state),
      (state, querySpec: Partial<QuerySpec>) => Const(querySpec),
    ],
    (resources, querySpec) =>
      resources.filter((resource) => {
        const querySpecHash = querySpecToIDString(querySpec.wrapped)
        const resourceQuerySpecHashes = resource.querySpecHashes
        return resourceQuerySpecHashes.includes(querySpecHash)
      }),
  )

  return {
    /**
     * @deprecated use a selector in `selectors.queryState` instead
     */
    status,
    /**
     * @deprecated use a selector in `selectors.queryState` instead
     */
    errors,
    /**
     * @deprecated use a selector in `selectors.queryState` instead
     */
    annotationStatus,
    /**
     * @deprecated use a selector in `selectors.queryState` instead
     */
    annotationErrors,
    /**
     * @deprecated use a selector in `selectors.queryState` instead
     */
    allAnnotationErrors,
    queryState: {
      select: selectQueryStateByQuerySpec,
      selectByResourceId: selectQueryStatesByResourceId,
      someLoading: selectSomeLoading,
    },
    data: {
      selectExactlyMatchingQuerySpec: selectDataExactlyMatchingQuerySpec,
    },
    ...dataSelectors,
  }
}
