import { createForeignKeysFromPathMiddleware } from '@/request/middleware'
import { ResourceModel } from '@/request/types'

type ThermostatRelatedResource = ResourceModel & { thermostat_id: number }

export function addThermostatIdFromPath<T extends ThermostatRelatedResource>() {
  const middleware = createForeignKeysFromPathMiddleware<T>(
    (pathToIdMap) =>
      ({
        thermostat_id: pathToIdMap['thermostats'],
      } as Partial<T>),
  )
  return middleware
}
