import { setUserMetadata } from '@/actions/user'
import { AdaptiveModal } from '@/app/components'
import SurveyObserver, {
  useSurveyResponder,
} from '@/app/components/SurveyObserver'
import UsernamePasswordInputs, {
  useUsernamePasswordInputs,
} from '@/app/features/connect-device/UsernamePasswordInputs'
import { useNavigation } from '@/app/hooks'
import { Button, Card, CardAction, CardActions, Text } from '@/components'
import config from '@/config'
import { useAppDispatch, useUrlSearchParam } from '@/hooks'
import { ComponentProps, useState } from 'react'
import * as Sentry from '@sentry/react'

type Props = ComponentProps<typeof AdaptiveModal> & {
  afterLogin?: (res: Response) => Promise<void>
}

function useViewModel(props: Props) {
  const navigation = useNavigation()
  const dispatch = useAppDispatch()
  const email = useUrlSearchParam('email')
  const { submitResponse } = useSurveyResponder('AutogridExistingUser')
  const [submitIsLoading, setSubmitIsLoading] = useState(false)

  const loginAndLogResponse: (
    callback?: (res: Response) => Promise<void>,
  ) => Parameters<typeof useUsernamePasswordInputs>[0]['onSubmit'] =
    (callback) => async (value, formActions) => {
      setSubmitIsLoading(true)
      // send request with fetch
      const res = await fetch(`${config.apiGateway.URL}/api/token/`, {
        method: 'POST',
        body: JSON.stringify(value),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      // if response is ok, submit response
      if (res.ok) {
        if (!callback) {
          const data = (await res.json()) as { access: string; refresh: string }
          submitResponse({ email: value.username })
          if (!data.access) {
            Sentry.captureMessage('No access token in response', {
              user: { username: value.username },
              level: 'error',
            })
            formActions.setErrors({
              password: 'There was an error, please try again later',
            })
            return
          }
          try {
            // We need to do a profile creation call here for the user who just logged in
            const confirmProfileResponse = await fetch(
              `${config.apiGateway.URL}/api/autogrid/confirm_profile_creation`,
              {
                method: 'POST',
                headers: {
                  Authorization: `Bearer ${data.access}`,
                  'Content-Type': 'application/json',
                },
              },
            )
            if (!confirmProfileResponse.ok) {
              Sentry.captureMessage(
                'Failed to confirm profile creation for autogrid',
                {
                  user: { username: value.username },
                  level: 'error',
                },
              )
              formActions.setErrors({
                password: 'There was an error, please try again later',
              })
            } else {
              navigation.push('/autogrid/confirmation/verified-existing-user')
            }
          } catch {
            Sentry.captureMessage(
              'Error when trying to create profile for autogrid',
              {
                user: { username: value.username },
                level: 'error',
              },
            )
            formActions.setErrors({
              password: 'There was an error, please try again later',
            })
          }
        } else {
          dispatch(setUserMetadata({ existingAutogridUser: true }))
          // If we have a callback, lets await it and pass the response
          await callback?.(res)
        }
      } else if (res.status === 401) {
        formActions.setErrors({
          password: 'The email or password you entered is incorrect.',
        })
      }
      setSubmitIsLoading(false)
    }

  return {
    ...useUsernamePasswordInputs({
      onSubmit: loginAndLogResponse(props?.afterLogin),
      usernameIsEmail: true,
      initialEmail: email ?? undefined,
    }),
    submitIsLoading,
    modalProps: props,
  }
}

export default function ExistingUserModalSurvey(props: Props) {
  return (
    <SurveyObserver observedSurveys={['AutogridExistingUser']}>
      <ExistingUserModal {...props} />
    </SurveyObserver>
  )
}

export function ExistingUserModal(props: Props) {
  const {
    inputValues,
    inputErrors,
    onValueChange,
    onSubmit,
    submitIsLoading,
    modalProps,
  } = useViewModel(props)

  return (
    <AdaptiveModal {...modalProps} id="autogrid-existing-user">
      <form
        noValidate
        autoComplete="off"
        style={{ height: '100%' }}
        onSubmit={onSubmit}
      >
        <Card className="!p-0" fullHeight flat>
          <Text variant="h2" className="mb-4">
            Existing User
          </Text>
          <Text variant="body4" className="mb-10">
            Enter your existing username and password and we'll work to connect
            your account to Flexsaver.
          </Text>
          <UsernamePasswordInputs
            inputValues={inputValues}
            inputErrors={inputErrors}
            onValueChange={onValueChange}
            usernameIsEmail
          />
          <CardActions>
            <CardAction type="primary">
              <Button
                type="submit"
                id="autogrid-existing-user-sign-in-button"
                className="!mt-12"
                variant="primary"
                disabled={submitIsLoading}
              >
                Sign In
              </Button>
            </CardAction>
          </CardActions>
        </Card>
      </form>
    </AdaptiveModal>
  )
}
