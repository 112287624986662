import { useAppSelector } from '@/hooks'
import { ID } from '@/types/model'
import { selectVehicleById, selectVehicleEnrollmentForUser } from '@/selectors'
import { RootState } from '@/reducers'
import useRemoteConfig from '@/hooks/useRemoteConfig'
import useWhiteLabelProgramId from '@/hooks/whitelabel/useWhiteLabelProgramId'
import { PausedDataCollectionAlert } from '@/types/pausedDataCollection'

const normalizeString = (str: string | undefined | null): string => {
  return (str ?? '').toLowerCase().replace(/[^a-z0-9]/g, '')
}

export function useVehiclePausedDataAlert(vehicleId: ID): string | null {
  const { appRemoteConfig } = useRemoteConfig()
  const pausedDataCollectionAlert: PausedDataCollectionAlert = JSON.parse(
    appRemoteConfig?.getString('paused_data_collection_alert') ?? '[]',
  )

  const alertMessage = useAppSelector((state: RootState) => {
    const whitelabelProgramId = useWhiteLabelProgramId()
    const vehicle = selectVehicleById(state, vehicleId)
    const vehicleEnrollment = selectVehicleEnrollmentForUser(state, vehicleId)

    if (!vehicle) {
      return null
    }

    // Match the program id from the whitelabel or the vehicle enrollment
    // This allows us to show the message even if the vehicle is not enrolled in a whitelabelled program
    const matchingProgram = pausedDataCollectionAlert.find((collection) => {
      const isEnrolled =
        !!vehicleEnrollment &&
        vehicleEnrollment.utility_program_id === collection.programId &&
        vehicleEnrollment.unenrolled_at === null
      const isWhitelabelled = collection.programId === whitelabelProgramId
      return isEnrolled || isWhitelabelled
    })

    const hasMatchingMakeModel = matchingProgram?.makeModels.find(
      (makeModel) => {
        const userVehicleMake = normalizeString(vehicle.car.car_model?.make)
        const matchMake = normalizeString(makeModel.make)

        if (userVehicleMake !== matchMake) {
          return false
        }

        const userVehicleModel = normalizeString(vehicle.car.car_model?.model)

        // If no models are specified, match any model
        if (!makeModel.models) {
          return true
        }

        // Otherwise check for exact model match
        return makeModel.models.some(
          (model) => normalizeString(model) === userVehicleModel,
        )
      },
    )

    return matchingProgram && hasMatchingMakeModel
      ? matchingProgram.message
      : null
  })

  return alertMessage
}
