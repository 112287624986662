import { Utility, UtilitySearchResult } from '@/types/utility'
import { CardProps } from '@/components'
import { utilityProgramEnrollmentCollection } from '@/reducers/utilityProgramEnrollments'
import {
  chargerEnrollmentCollection,
  thermostatEnrollmentCollection,
  vehicleEnrollmentCollection,
} from '@/reducers/deviceProgramEnrollments'
import { UtilityProgram } from '@/types/utilityProgram'

export interface Props extends Omit<CardProps, 'children'> {
  utility?: Utility | UtilitySearchResult
  utilityProgram?: UtilityProgram
  onSkip?: () => void
  onClose?: () => void
  onBack?: () => void
  onRedirect?: () => void
  onConnect?: () => void
}

export default function useViewModel(props: Props) {
  utilityProgramEnrollmentCollection.useFetch()
  vehicleEnrollmentCollection.useFetch()
  thermostatEnrollmentCollection.useFetch()
  chargerEnrollmentCollection.useFetch()

  return {
    ...props,
  }
}
