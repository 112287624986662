import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

class GreenImpactDialog extends React.Component {
  state = {
    fullWidth: true,
    maxWidth: 'sm',
  }

  handleClose = () => {
    this.props.handleClose()
  }

  render() {
    return (
      <Dialog
        fullWidth={this.state.fullWidth}
        maxWidth={this.state.maxWidth}
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          How do we calculate your environmental impact?
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ marginBottom: '15px' }}>
            We take your all-time amount of gasoline described in the section
            above and convert it using the EPA's conversion{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={this.props.source}
            >
              here
            </a>
            .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default GreenImpactDialog
