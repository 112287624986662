import { thermostatsCollection } from '../reducers/thermostats'
import { RootState } from '../store'
import { ID } from '../types/model'

export default function selectThermostatIsInactive(
  state: RootState,
  thermostatId: ID,
) {
  const authenticationInactive = thermostatsCollection.selectors.selectById(
    state,
    thermostatId,
  )?.authentication_inactive
  const authErrorPresent = thermostatsCollection.selectors.queryState
    .selectByResourceId(state, thermostatId)
    .map((queryState) => queryState.error)
    .some((error) => error?.status === 401)

  return authenticationInactive || authErrorPresent
}
