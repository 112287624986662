import { useState } from 'react'
import styled from 'styled-components'

import {
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardMedia as MuiCardMedia,
  IconButton,
  Typography as MuiTypography,
} from '@material-ui/core'

import { useSelector, useDispatch } from 'react-redux'
import { spacing } from '@material-ui/system'
import { green, red } from '@material-ui/core/colors'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import { HelpCircle } from 'react-feather'
import GasSavingsDialog from '../GasSavingsDialog'
import GreenImpactDialog from '../dialogs/GreenImpactDialog'
import {
  useGasVolumeWithUnits,
  useOdometerWithUnits,
} from '../../hooks/conversions'
import OdometerDialog from './OdometerDialog'
import { getCharges } from '../../../actions/metric'
import theme from '../../../v2/theme'
import { ThemeProvider } from '@material-ui/core/styles'
import { toCommaSeparatedString } from '../../../utils/string'

const Card = styled(MuiCard)(spacing)

const TotalImpactCard = styled(Card)`
  border-radius: 20px;
`

const Typography = styled(MuiTypography)(spacing)

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`

const TypographyPercentage = styled(MuiTypography)`
  color: ${(props) => props.theme.palette.grey[600]};
`

const SpanStyled = styled.span`
  color: ${red[500]};
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
`

const CardMedia = styled(MuiCardMedia)`
  height: 90px;
  width: 90px;
  margin: 10px;
`

const SmallHelp = styled(HelpCircle)`
    width: 14px;
    height: 14px;
    color: ${(props) => props.theme.palette.primary.main}
    margin-bottom: 2px;
`

const TypographyLarge = styled(MuiTypography)`
  color: ${(props) => props.theme.palette.grey[500]};
  font-size: 20px;
`

const FixedSizeStatsGridItem = styled(Grid)`
  max-width: 56%;
`

const EqualSymbolBoxContainer = styled(Box)`
  margin: 16px 0px;
`

export default function TotalImpact({ vehicle: selectedVehicle }) {
  const dispatch = useDispatch()
  const [odometerOpen, setOdometerOpen] = useState(false)
  const [gallonsOpen, setGallonsOpen] = useState(false)
  const [greenImpactOpen, setGreenImpactOpen] = useState(false)

  const [distanceValue, distanceUnit] = useOdometerWithUnits(selectedVehicle.id)
  const [gasVolumeValue, gasVolumeUnit] = useGasVolumeWithUnits(
    selectedVehicle.id,
  )

  const numberOfVehicles = useSelector(
    (state) => state.vehicles.vehicles?.length ?? 0,
  )

  const charges = useSelector((state) => state.metric.charges)

  const reloadCharges = () => {
    if (charges) {
      dispatch(
        getCharges(1, charges.start_date, charges.end_date, charges.vehicle_id),
      )
    } else {
      dispatch(getCharges())
    }
  }

  const handleGallonsClickOpen = (event) => {
    event.preventDefault()
    setGallonsOpen(true)
  }

  const handleGallonsClose = () => {
    setGallonsOpen(false)
  }

  const handleGallonsSubmitPressed = () => {
    if (numberOfVehicles === 1) {
      handleGallonsClose()
    }
  }

  const handleGallonsSubmit = () => {
    reloadCharges()
  }

  const handleGreenImpactClickOpen = (event) => {
    event.preventDefault()
    setGreenImpactOpen(true)
  }

  const handleGreenImpactClose = () => {
    setGreenImpactOpen(false)
  }

  if (
    !(
      selectedVehicle?.selected_gas_car_conversion &&
      selectedVehicle.environmental_impact
    )
  ) {
    return null
  }

  return (
    <ThemeProvider theme={theme}>
      <Box position="relative">
        <TotalImpactCard>
          <CardContent>
            <Typography variant="h6" mb={4}>
              All-Time Environmental Impact
            </Typography>
            <TypographyPercentage variant="subtitle1">
              <SpanStyled>{toCommaSeparatedString(distanceValue)}</SpanStyled>

              <span>{` odometer ${distanceUnit}`}</span>
              <IconButton
                onClick={() => setOdometerOpen(true)}
                style={{ padding: '6px' }}
              >
                <SmallHelp />
              </IconButton>
              <OdometerDialog
                open={odometerOpen}
                handleClose={() => setOdometerOpen(false)}
                distanceUnit={distanceUnit}
              />
            </TypographyPercentage>
            <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
            <Grid
              container
              spacing={6}
              style={{ marginBottom: '-20px' }}
              justifyContent="flex-start"
            >
              <FixedSizeStatsGridItem item>
                <Typography variant="h3" color="textPrimary">
                  <Box
                    display="inline"
                    fontWeight="fontWeightRegular"
                    color={green[500]}
                  >
                    {toCommaSeparatedString(gasVolumeValue)}
                  </Box>
                </Typography>
                <TypographyPercentage
                  display="inline"
                  variant="subtitle1"
                  align="left"
                >
                  <p>
                    {gasVolumeUnit} of gasoline saved
                    <IconButton
                      onClick={handleGallonsClickOpen}
                      style={{ padding: '6px' }}
                    >
                      <SmallHelp />
                    </IconButton>
                  </p>
                  <GasSavingsDialog
                    open={gallonsOpen}
                    onClose={handleGallonsClose}
                    onSubmit={handleGallonsSubmit}
                    onSubmitPressed={handleGallonsSubmitPressed}
                    initialVehicleId={selectedVehicle.id}
                  />
                </TypographyPercentage>
              </FixedSizeStatsGridItem>
              <Grid item>
                <CardMedia
                  image="/dashboard/img/green-impact/gasoline.webp"
                  title="Gallons saved"
                />
              </Grid>
            </Grid>
            <TypographyLarge
              style={{ fontSize: '31px' }}
              variant="h3"
              color="textPrimary"
              align="center"
            >
              <EqualSymbolBoxContainer
                align="center"
                display="inline"
                fontWeight="fontWeightRegular"
              >
                ≈
              </EqualSymbolBoxContainer>
            </TypographyLarge>
            <Grid
              container
              spacing={6}
              style={{ marginTop: '-12px', marginBottom: '-20px' }}
              justifyContent="flex-end"
            >
              <Grid item>
                <CardMedia
                  image={
                    '/dashboard/img/green-impact/' +
                    selectedVehicle.environmental_impact['image_file']
                  }
                  title={selectedVehicle.environmental_impact['image_file']}
                />
              </Grid>
              <FixedSizeStatsGridItem item>
                <Typography variant="h3" color="textPrimary" align="right">
                  <Box
                    mr={3}
                    display="inline"
                    fontWeight="fontWeightRegular"
                    color={green[500]}
                  >
                    {toCommaSeparatedString(
                      selectedVehicle.environmental_impact[
                        'total_metric_impact'
                      ],
                    )}
                  </Box>
                </Typography>
                <TypographyPercentage
                  display="inline"
                  variant="subtitle1"
                  align="right"
                >
                  <p>
                    {selectedVehicle.environmental_impact['description']}{' '}
                    <IconButton
                      onClick={handleGreenImpactClickOpen}
                      style={{ padding: '6px' }}
                    >
                      <SmallHelp />
                    </IconButton>
                  </p>
                  <GreenImpactDialog
                    source={selectedVehicle.environmental_impact['source']}
                    open={greenImpactOpen}
                    handleClose={handleGreenImpactClose}
                  />
                </TypographyPercentage>
              </FixedSizeStatsGridItem>
            </Grid>
          </CardContent>
        </TotalImpactCard>
      </Box>
    </ThemeProvider>
  )
}
