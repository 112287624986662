import AlertMessage from '@/app/components/AlertMessage'
import DashboardCard from '../DashboardCard'
import IconToggleLabel from '../IconToggleLabel'
import { sanitizeHtmlContent } from '@/utils/components'
import { ChargeSchedule } from '@/types'

type Props<Schedule extends ChargeSchedule> = {
  onChange: (chargeSchedule: Schedule) => void
  chargeSchedule: Schedule | undefined
  canCharge: boolean
  utilityAlertSubtitle?: string
  overrideValue?: boolean | null
}

const AllowChargeControlCard = <Schedule extends ChargeSchedule>({
  onChange,
  chargeSchedule,
  canCharge,
  utilityAlertSubtitle,
  // when provided, the value should be used instead of the value from the chargeSchedule
  // and the toggle should be disabled
  overrideValue,
}: Props<Schedule>) => {
  let alertMessage = (
    <AlertMessage show={!canCharge} variant="info" style={{ marginTop: 10 }}>
      This vehicle does not currently support third-party charge management.
    </AlertMessage>
  )

  if (utilityAlertSubtitle && canCharge) {
    alertMessage = (
      <AlertMessage show variant="info" style={{ marginTop: 10 }}>
        <span
          dangerouslySetInnerHTML={{
            __html: sanitizeHtmlContent(utilityAlertSubtitle),
          }}
        />
      </AlertMessage>
    )
  }

  const overrideValueWasProvided =
    overrideValue !== undefined && overrideValue !== null
  const overriddenCheckedValue = !canCharge ? false : overrideValue

  return (
    <DashboardCard pb={4} pt={4} mb={0}>
      <IconToggleLabel
        id="allow-charge-control-toggle"
        title="Allow Optiwatt to Schedule Charging"
        icon="/img/logo/logo-icon-colored.webp"
        shouldDebounce={false}
        model={chargeSchedule}
        disabled={!canCharge || overrideValueWasProvided}
        field="allow_charging_control"
        onChange={onChange}
        checked={overriddenCheckedValue}
      />
      {alertMessage}
    </DashboardCard>
  )
}

export default AllowChargeControlCard
