import React from 'react'
import { connect } from 'react-redux'
import { isNativeMobile } from '@/authenticated/hooks/useIsMobile'

class Header extends React.Component {
  render() {
    return (
      <React.Fragment>
        <header className="header _use-deprecated-bootstrap _use-deprecated-style">
          <nav
            className={
              'navbar navbar-expand-lg fixed-top ' +
              (this.props.bgColor && this.props.bgColor === 'white'
                ? 'custom-nav white-bg'
                : 'bg-transparent')
            }
          >
            <div className="container">
              <a
                className="navbar-brand"
                href={!isNativeMobile() ? '/' : undefined}
              >
                <img
                  src={
                    this.props.bgColor && this.props.bgColor === 'white'
                      ? 'img/logo-color-1x.webp'
                      : 'img/logo-small.webp'
                  }
                  width="130"
                  alt="logo"
                  className="img-fluid"
                />
              </a>
              {!isNativeMobile() && (
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="ti-menu"></span>
                </button>
              )}
              <div
                className="collapse navbar-collapse main-menu-2"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav mr-auto">
                  <li className="nav-item">
                    <a className="nav-link" href="news">
                      News
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="https://optiwatt.applytojob.com"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Careers
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="faq">
                      FAQ
                    </a>
                  </li>
                </ul>
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <a className="nav-link" href="login">
                      Login
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="signup">
                      Sign Up
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
      </React.Fragment>
    )
  }
}

export default connect((state) => ({}))(Header)
