import useTeslaGiveawayPromoActive from '../../hooks/useTeslaGiveawayPromoActive'
import { Props as ListableInformationCardProps } from '../ConnectDeviceInformationCard/ListableInformationCard'
import { ApiProvider } from '../../../types/utility/'
import data from './resources/data.json'

export interface Props {
  apiProvider: ApiProvider
}

const FormattedApiProviderNames: { [key: string]: string } = {
  [ApiProvider.Leap]: 'Leap',
  [ApiProvider.UtilityApi]: 'Utility API',
}

function useViewModel({ apiProvider }: Props) {
  const promoActive = useTeslaGiveawayPromoActive(apiProvider)
  const informationCards: ListableInformationCardProps[] = data.informationCards
    .filter((card) => !card.promoOnly || promoActive)
    .map((card) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { promoOnly, ...rest } = card
      return rest
    })

  return {
    apiProviderName: FormattedApiProviderNames[apiProvider],
    showGiveawayTerms: promoActive,
    informationCards,
  }
}

export default useViewModel
