import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useRef,
} from 'react'
import {
  BottomSheet,
  BottomSheetProps,
  BottomSheetRef,
} from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { match } from 'ts-pattern'

export type BottomSheetConfig = BottomSheetProps & { preventDismiss?: boolean }
type BottomSheetContextType = {
  open: boolean
  config: BottomSheetProps | null
  setBottomSheetContent: (
    config: BottomSheetConfig,
    suppressOpen?: boolean,
  ) => void
  openBottomSheet: () => void
  closeBottomSheet: () => void
  sheetRef: React.RefObject<BottomSheetRef>
  snapToHeader: () => void
  sheetHeight: number | null
}

const BottomSheetContext = createContext<BottomSheetContextType | undefined>(
  undefined,
)

export type DefaultSnap = 'headerHeight' | 'minHeight'

export const BottomSheetProvider = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState(false)
  const [config, setConfig] = useState<BottomSheetConfig | null>(null)
  const [sheetHeight, setSheetHeight] = useState<number | null>(null)
  const [defaultSnap, setDefaultSnap] = useState<DefaultSnap>('minHeight')
  const sheetRef = useRef<BottomSheetRef | null>(null)

  const openBottomSheet = () => {
    setDefaultSnap('minHeight')
    setOpen(true)
  }

  const setBottomSheetContent = (
    config: BottomSheetConfig,
    suppressOpen?: boolean,
  ) => {
    setConfig(config)
    if (suppressOpen) {
      return
    }
    setOpen(true)
  }

  const updateSheetHeight = () => {
    const height = sheetRef.current?.height ?? 0
    setSheetHeight(height)
  }

  const snapToHeader = () => {
    match(open)
      .with(true, () =>
        setTimeout(() => {
          // workaround for navigation causing sheet to not snap because animation gets cancelled
          sheetRef.current?.snapTo((snaps) => snaps.headerHeight)
        }, 500),
      )
      .otherwise(() => {
        setDefaultSnap('headerHeight')
        setOpen(true)
      })
  }
  const closeBottomSheet = () => {
    setOpen(false)
    setDefaultSnap('minHeight')
  }
  const componentConfig = { ...config }
  // delete so react doesn't complain about unknown props
  delete componentConfig?.preventDismiss
  return (
    <BottomSheetContext.Provider
      value={{
        open,
        config,
        openBottomSheet,
        setBottomSheetContent,
        closeBottomSheet,
        sheetRef,
        snapToHeader,
        sheetHeight,
      }}
    >
      {children}
      {config && (
        <div className={open ? 'h-12' : ''}>
          <BottomSheet
            ref={sheetRef}
            scrollLocking={!config.preventDismiss}
            onSpringEnd={updateSheetHeight}
            defaultSnap={({ headerHeight, minHeight }) =>
              match(defaultSnap)
                .with('headerHeight', () => headerHeight)
                .with('minHeight', () => minHeight)
                .exhaustive()
            }
            onDismiss={
              config.preventDismiss
                ? undefined
                : config.onDismiss ?? closeBottomSheet
            }
            {...componentConfig}
            open={open}
          >
            {config.children}
          </BottomSheet>
        </div>
      )}
    </BottomSheetContext.Provider>
  )
}

export const useBottomSheet = (): BottomSheetContextType => {
  const context = useContext(BottomSheetContext)
  if (context === undefined) {
    throw new Error('useBottomSheet must be used within a BottomSheetProvider')
  }
  return context
}
