import UtilityProgramConnectStatusCardRoot, {
  Props as ComponentProps,
} from './UtilityProgramConnectStatusCard'
import useViewModel from './useViewModel'
import { ViewModelComponentProps } from '@/utils/typeUtilities'

export type Props = ViewModelComponentProps<ComponentProps, typeof useViewModel>

export default function UtilityProgramConnectStatusCard(props: Props) {
  const viewModel = useViewModel(props)
  return <UtilityProgramConnectStatusCardRoot {...viewModel} {...props} />
}
