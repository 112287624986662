import { useNavigation } from '@/app/hooks'

type LinkProps = {
  to?: string
  className?: string
} & React.HTMLProps<HTMLAnchorElement>

export function Link({ to, className, ...rest }: LinkProps) {
  const navigate = useNavigation()

  const handleNavigate = () => {
    if (to) {
      navigate.push(to)
    }
  }

  return (
    <a
      className={`!underline cursor-pointer text-themed-blue-500 hover:text-themed-info font-bold text-sm ${className}`}
      onClick={handleNavigate}
      {...rest}
    >
      {rest.children}
    </a>
  )
}
