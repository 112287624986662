import { useEffect, useState } from 'react'
import styled from 'styled-components'

import {
  Button as MuiButton,
  FormControl as MuiFormControl,
  Grid as MuiGrid,
  InputLabel,
  MenuItem,
  Paper as MuiPaper,
  Select as MuiSelect,
} from '@material-ui/core'
import { spacing } from '@material-ui/system'
import FormHelperText from '@material-ui/core/FormHelperText'
import { useDispatch, useSelector } from 'react-redux'
import AddRow from './AddRow'
import {
  blankPlanErrors,
  getPlan,
  OVERLAP_MONTHS_MESSAGE,
  usePlansGraph,
} from './PlansGraph'
import {
  addPlan,
  modifyPlan,
  deletePlan,
  setErrorMonths,
  showEditPlan,
  showNewPlan,
} from '../../../../actions/manualPlans'
import { getMonthsRange } from '../../../../utils/rangeUtilities'
import { findDuplicates } from '../../../../utils/arrayUtilities'

const Grid = styled(MuiGrid)(spacing)
const Select = styled(MuiSelect)(spacing)

const Paper = styled(MuiPaper)(spacing)
const Button = styled(MuiButton)(spacing)

const FormControlSpacing = styled(MuiFormControl)(spacing)

const FormControl = styled(FormControlSpacing)`
  width: 200px;
`

const StyledPaper = styled(Paper)`
  margin-left: 0px;
`

export function getValidPlans(plans, changingWeekdays) {
  let validPlans = [...plans]
  if (!changingWeekdays) {
    for (let i = 0; i < validPlans.length; i++) {
      validPlans[i].rates.weekend = validPlans[i].rates.weekday
    }
  }

  if (isOverlapping(validPlans, getMonthsRange)) {
    return false
  }

  if (hasMissingPeriods(12, validPlans, getMonthsRange)) {
    return false
  }

  return validPlans
}

export function isOverlapping(items, getRange) {
  let periods = []
  for (let i = 0; i < items.length; i++) {
    let item = items[i]
    periods = periods.concat(getRange(item.start, item.end))
  }

  let duplicates = findDuplicates(periods)
  return duplicates.length !== 0
}

export function getOverlapping(id, items, idProperty, getRange, start, end) {
  let periods = getRange(start, end)
  for (let i = 0; i < items.length; i++) {
    let item = items[i]
    if (item[idProperty] === null || item[idProperty] !== id) {
      periods = periods.concat(getRange(item.start, item.end))
    }
  }

  return findDuplicates(periods)
}

export function hasMissingPeriods(length, items, getRange) {
  return getMissingPeriods(length, items, getRange).length !== 0
}

export function getMissingPeriods(length, items, getRange) {
  const allPeriods = new Array(length).fill(false)
  for (let i = 0; i < items.length; i++) {
    let months = getRange(items[i].start, items[i].end)
    for (let j = 0; j < months.length; j++) {
      allPeriods[months[j]] = true
    }
  }

  let missingPeriods = []
  for (let i = 0; i < allPeriods.length; i++) {
    if (!allPeriods[i]) {
      missingPeriods.push(i)
    }
  }

  return missingPeriods
}

function Plan(props) {
  const [plans] = usePlansGraph()
  const [planId, setPlanId] = useState(props.planId)
  const [start, setStart] = useState(props.start)
  const [end, setEnd] = useState(props.end)
  const [errors, setErrors] = useState(props.errors)

  const editingRates = useSelector((state) => state.manualPlans.editingRates)
  const hasSetRates = editingRates.weekday.length > 0 || props.planId

  const dispatch = useDispatch()

  useEffect(() => {
    setStart(props.start)
    setEnd(props.end)
    setErrors(props.errors)
    setPlanId(props.planId)
  }, [props])

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  function onSubmit(event) {
    event.preventDefault()
    if (event.target.action.value === 'add') {
      let isValid = validateForm(
        event.target.start.value,
        event.target.end.value,
      )
      if (isValid) {
        const plan = getPlan(planId, start, end, editingRates)
        dispatch(addPlan(plan))
        dispatch(showEditPlan(plan.planId))
      }
    } else {
      dispatch(deletePlan(planId))
    }
  }

  function onStartChange(newStart) {
    setStart(newStart)
    if (!props.isLastRow) {
      tryModifyPlan(newStart, end)
    }
  }

  function onEndChange(newEnd) {
    setEnd(newEnd)
    if (!props.isLastRow) {
      tryModifyPlan(start, newEnd)
    }
  }

  function tryModifyPlan(start, end) {
    let isValid = validateForm(start, end)
    if (isValid) {
      dispatch(modifyPlan(planId, start, end))
      setErrors(blankPlanErrors)
      return true
    } else {
      return false
    }
  }

  function validateForm(start, end) {
    let isCompleted = validateCompletion(start, end)
    if (!isCompleted) {
      return false
    }

    return validateOverlapping(planId, start, end)
  }

  function validateOverlapping(planId, start, end) {
    let overlappingMonths = getOverlapping(
      planId,
      plans,
      'planId',
      getMonthsRange,
      start,
      end,
    )
    dispatch(setErrorMonths(overlappingMonths))
    if (overlappingMonths.length > 0) {
      setErrors({ start: OVERLAP_MONTHS_MESSAGE, end: OVERLAP_MONTHS_MESSAGE })
    }
    return overlappingMonths.length === 0
  }

  function validateCompletion(start, end) {
    if (start === null || start === '') {
      setErrors({ planId: null, start: 'Please set a start month', end: null })
      return false
    } else if (end === null || end === '') {
      setErrors({ planId: null, start: null, end: 'Please set an end month' })
      return false
    }
    return true
  }

  function handleClick() {
    if (planId) {
      dispatch(showEditPlan(planId))
    } else {
      dispatch(showNewPlan())
    }
  }

  return (
    <div>
      <form id="form" noValidate autoComplete="off" onSubmit={onSubmit}>
        <Grid container justifyContent="left">
          <Grid item xs={11} md={11}>
            <FormControl
              m={2}
              align="left"
              required
              error={errors.start !== null}
              style={{ width: '27%' }}
            >
              <InputLabel shrink htmlFor="age-simple">
                Start Month
              </InputLabel>
              <Select
                name="start"
                defaultValue={start}
                value={start}
                onChange={(e) => onStartChange(e.target.value)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {months.map((row, index) => (
                  <MenuItem value={row}>{row}</MenuItem>
                ))}
              </Select>
              {errors.start && <FormHelperText>{errors.start}</FormHelperText>}
            </FormControl>

            <FormControl
              m={2}
              align="left"
              required
              error={errors.end !== null}
              style={{ width: '27%' }}
            >
              <InputLabel shrink htmlFor="age-simple">
                End Month
              </InputLabel>
              <Select
                name="end"
                defaultValue={end}
                value={end}
                onChange={(e) => onEndChange(e.target.value)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {months.map((row, index) => (
                  <MenuItem value={row}>{row}</MenuItem>
                ))}
              </Select>
              {errors.end && <FormHelperText>{errors.end}</FormHelperText>}
            </FormControl>

            {hasSetRates && (
              <FormControl
                m={2}
                align="left"
                required
                error={errors.planId !== null}
                style={{ width: '27%' }}
              >
                <Button
                  mt={4}
                  name="planId"
                  value={planId}
                  variant={hasSetRates ? 'outlined' : 'contained'}
                  color="primary"
                  size="small"
                  onChange={(e) => setPlanId(e.target.value)}
                  onClick={handleClick}
                >
                  {hasSetRates ? 'Edit Plan' : 'Add Plan'}
                </Button>
                {errors.planId && (
                  <FormHelperText>{errors.planId}</FormHelperText>
                )}
              </FormControl>
            )}
          </Grid>
          <Grid item xs={1}>
            <AddRow disabled={!props.isLastRow} fabDisabled={false} />
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

export default Plan
