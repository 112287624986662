import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

export default (props) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="demand-response-dialog"
      style={{ padding: '32px' }}
    >
      <DialogTitle id="demand-response-dialog" style={{ paddingTop: '32px' }}>
        What is Grid Support?
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ marginBottom: '15px' }}>
          When Optiwatt grid support is enabled your vehicle will temporarily
          postpone charging during brief periods of high electricity demand. You
          can change this setting at any time on your profile page.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
