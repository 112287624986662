import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import './styles.scss'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid as MuiGrid,
  Paper,
  styled,
  Typography as MuiTypography,
} from '@material-ui/core'
import { spacing } from '@material-ui/system'
import { useAppSelector } from '@/hooks'
import { Button, LoadingAnimation } from '@/components'
import Text from '@/components/Text/Text'
import { capitalizeFirstLetter } from '../../../../utils/stringUtilities'
import { AlertMessage } from '@/app/components'
import { ConnectVehicleConfirmationProps } from './ConnectVehicleConfirmation.types'
import { Vehicle } from '@/types'
import {
  resetVehicleAuthErrors,
  setVehicleConnectionConfirmationPending,
} from '@/actions/vehicleAuth'

import frown from '@/app/img/frown.svg'
import TeslaVehiclePermissions from '@/app/components/TeslaVehiclePermissions/TeslaVehiclePermissions'
import TeslaEditPermissions from '@/app/components/TeslaEditPermissions/TeslaEditPermissions'
import { ConfirmationContent } from '@/authenticated/components/Vehicle/ConnectVehicleConfirmation/ConnectVehicleConfirmationContent'
import TelsaFleetNotOwnerModal from '@/app/pages/tesla-authentication/not-owner/not-owner'
import Check from '@/app/img/check.svg'
import useUserWhitelabel from '@/hooks/whitelabel/useUserWhitelabel'
import { remoteConfig } from '@/firebase'
import { useIsAvaBasePath } from '@/app/hooks/ava/useAvaVerification'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    paddingTop: theme.spacing(4),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(10),
      paddingTop: theme.spacing(4),
    },
  },
  connectButton: {
    backgroundColor: theme.palette.primary.main,
    marginTop: 20,
  },
}))
const Typography = styled(MuiTypography)(spacing)
const Grid = styled(MuiGrid)(spacing)

const ConnectVehicleConfirmation: React.FC<ConnectVehicleConfirmationProps> = ({
  onConfirmed,
  onReset,
  isTesla = false,
  disableButton = false,
  missingScopes,
}) => {
  const isAva = useIsAvaBasePath()
  const dispatch = useDispatch()
  const classes = useStyles()
  const { isLoading, errors } = useAppSelector((state) => state.vehicleAuth)
  const connectedVehicles = useAppSelector(
    (state) => state.vehicleAuth.mfaData ?? [],
  ) as Vehicle[] | undefined
  const { make } = useAppSelector(
    (state) => state.vehicleAuth.vehicleConnectionConfirmationPending,
  )
  const displayMake = isTesla ? 'Tesla' : capitalizeFirstLetter(make)
  const [showNotOwnerModal, setShowNotOwnerModal] = useState(false)

  // On component unmount, dispatch an action to clear the vehicle connection confirmation pending state
  useEffect(() => {
    return () => {
      dispatch(
        setVehicleConnectionConfirmationPending({
          pending: false,
          make: '',
        }),
      )
    }
  }, [])

  function continueClick() {
    const isDriver =
      (connectedVehicles ?? []).some((v) => v.car?.access_type === 'DRIVER') ??
      false
    if (isDriver) {
      setShowNotOwnerModal(true)
      return
    }
    onConfirmed()
  }

  if (Object.keys(errors ?? {}).length > 0) {
    return (
      <Paper className={classes.wrapper} elevation={0}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{ flexGrow: 1 }}
        >
          <div>
            <Grid
              container
              item
              xs={12}
              pb={6}
              alignContent="center"
              justifyContent="center"
            >
              <img alt="error" src={frown} />
            </Grid>
            <Text variant="h3">
              {' '}
              Oh no! Something went wrong. Please go back and try again
            </Text>
            <Grid container item py={{ xs: 6 }} mt={4}>
              <AlertMessage variant="error" style={{ width: '100%' }}>
                {(errors as { detail?: string })?.detail ??
                  'Something went wrong'}
              </AlertMessage>
            </Grid>
          </div>
        </Grid>
        <Grid>
          <Button
            id="close-vehicle-connection-button"
            className={classes.connectButton}
            onClick={() => {
              onReset?.()
              dispatch(resetVehicleAuthErrors())
              dispatch(
                setVehicleConnectionConfirmationPending({
                  pending: false,
                  make: '',
                }),
              )
            }}
            variant="secondary"
            disabled={disableButton}
          >
            <Text>Retry</Text>
          </Button>
        </Grid>
      </Paper>
    )
  }

  if (isLoading) {
    return (
      <Paper className={classes.wrapper} elevation={0}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{ flexGrow: 1 }}
        >
          <div>
            <Grid
              container
              item
              xs={12}
              pb={6}
              alignContent="center"
              justifyContent="center"
            >
              <LoadingAnimation type="falling" />
            </Grid>
            <Text variant="h3">
              Hold tight... we’re finishing up your connection
            </Text>
            <Grid container item py={{ xs: 6 }} mt={4}>
              <AlertMessage variant="info" style={{ width: '100%' }}>
                This may take up to a minute
              </AlertMessage>
            </Grid>
          </div>
        </Grid>
        <Grid>
          <Button
            id="close-vehicle-connection-button"
            className={classes.connectButton}
            onClick={onConfirmed}
            variant="secondary"
            disabled={disableButton}
          >
            <Text>Close</Text>
          </Button>
        </Grid>
      </Paper>
    )
  }

  return (
    <>
      <div className="flex flex-col min-h-[100%] gap-4">
        <div className="flex gap-4">
          <img src={Check} className="max-w-[24px]" />
          <Text variant="h3">
            Great, we connected {connectedVehicles?.length} {displayMake}{' '}
            {connectedVehicles?.length === 1 ? 'vehicle!' : 'vehicles!'}
          </Text>
        </div>
        <Text variant="body4">
          We're thrilled you've taken the first step towards smarter, more
          efficient electric vehicle energy management. The capabilities of each
          car are listed below:
        </Text>
        <div className="mt-4">
          {isTesla ? (
            <>
              <TeslaVehiclePermissions
                vehicles={connectedVehicles ?? []}
                missingScopes={missingScopes}
              />
              {(missingScopes ?? []).length > 0 && <TeslaEditPermissions />}
            </>
          ) : (
            <>
              {connectedVehicles?.map?.((vehicle: Vehicle) => (
                <ConfirmationContent vehicle={vehicle} isTesla={isTesla} />
              ))}
            </>
          )}
        </div>
        <div className="mt-auto">
          <Button
            id="confirm-vehicle-connection-button"
            className={classes.connectButton}
            onClick={continueClick}
            variant="primary"
            disabled={disableButton}
          >
            {isAva ? 'Next' : 'Continue'}
          </Button>
        </div>
      </div>
      <TelsaFleetNotOwnerModal
        open={showNotOwnerModal}
        setIsModalOpen={setShowNotOwnerModal}
      />
    </>
  )
}

export default ConnectVehicleConfirmation
