import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import {
  Typography as MuiTypography,
  Grid as MuiGrid,
  CardMedia,
  styled,
} from '@material-ui/core'
import clsx from 'clsx'
import ColorBoxedTypography from '../ColorBoxedTypography'
import { spacing } from '@material-ui/system'
import { Icon as IconType } from '../../../types/reactFeather'
import { List, CheckedListItem } from '../../../components'
import * as _Icon from 'react-feather'
import { ConfigurableText } from '@/app/components'
import { Button, Icon as LocalIcon } from '@/components'
import { sanitizeHtmlContent } from '@/utils/components'
const Icon: { [key: string]: IconType } = _Icon

const Typography = styled(MuiTypography)(spacing)
const Grid = styled(MuiGrid)(spacing)

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      marginBottom: theme.spacing(13),
    },
    titleStyle: {
      marginBottom: theme.spacing(2),
      fontSize: 17,
      fontWeight: 700,
      textAlign: 'left',
    },
    mainTitleStyle: {
      marginBottom: theme.spacing(4),
      fontSize: 22,
      fontWeight: 700,
      textAlign: 'left',
    },
    subtitleStyle: {
      fontSize: 14,
      textAlign: 'left',
    },
    iconBackground: {
      height: 33,
      width: 33,
      backgroundColor: theme.palette.blue?.background,
      borderRadius: 10,
      marginRight: theme.spacing(3),
    },
    iconOptionaltext: {
      textAlign: 'center',
      fontSize: 16,
      color: theme.palette.blue?.main,
    },
    iconOptionalImage: {
      height: 18,
      width: 'auto',
    },
    betaTag: {
      fontSize: 12,
      fontWeight: 700,
    },
    tagContainer: {
      borderRadius: '8px',
      verticalAlign: 'text-bottom',
    },
  }),
)

export interface Classes {
  iconContainer?: string
  headerText?: string
}

export interface Props {
  pageName?: string
  backButtonClassNames?: string
  stringIcon?: string
  imageIcon?: string
  featherIcon?: string
  iconBackgroundColor?: string
  iconColor?: string
  title: string
  subtitle: string | string[]
  mainContent?: boolean
  tag?: string
  tagBackgroundColor?: string
  tagColor?: string
  className?: string
  classes?: Classes
  onBackClick?: () => void
}

const ListableInformationCard = ({
  pageName,
  backButtonClassNames,
  stringIcon,
  imageIcon,
  featherIcon,
  iconBackgroundColor,
  iconColor,
  title,
  subtitle,
  mainContent = false,
  tag,
  tagBackgroundColor,
  tagColor,
  className,
  classes: providedClasses,
  onBackClick,
}: Props) => {
  const classes = useStyles()
  const useIcon = stringIcon || imageIcon || featherIcon
  const FeatherIcon = featherIcon ? Icon[featherIcon] : null

  return (
    <Grid
      className={clsx(classes.wrapper, className)}
      container
      direction="row"
    >
      {useIcon && (
        <Grid
          container
          className={clsx(
            classes.iconBackground,
            providedClasses?.iconContainer,
          )}
          style={
            iconBackgroundColor ? { backgroundColor: iconBackgroundColor } : {}
          }
          justifyContent="center"
          alignItems="center"
        >
          {stringIcon ? (
            <Typography variant="h3" className={classes.iconOptionaltext}>
              {stringIcon}
            </Typography>
          ) : imageIcon ? (
            <CardMedia
              component="img"
              image={imageIcon}
              className={classes.iconOptionalImage}
            />
          ) : (
            FeatherIcon && (
              <FeatherIcon size={18} color={iconColor ?? '#AF4BFB'} />
            )
          )}
        </Grid>
      )}
      <Grid container style={{ width: useIcon ? '84%' : '100%' }}>
        <Grid container item xs={12} direction="row">
          {onBackClick && (
            <Grid item>
              <Button
                id={`${pageName}-navigation-page-back`}
                className={`!p-0 mt-0.5 mr-5 ${backButtonClassNames ?? ''}`}
                variant="icon"
                onClick={onBackClick}
              >
                <LocalIcon name="ArrowLeft" size={24} color="grey-900" />
              </Button>
            </Grid>
          )}
          <Grid item>
            <Typography
              variant="h3"
              className={clsx(
                mainContent ? classes.mainTitleStyle : classes.titleStyle,
                providedClasses?.headerText,
              )}
            >
              <ConfigurableText
                className="inline-block"
                config={title}
                styled={false}
              />
              {tag && (
                <ColorBoxedTypography
                  variant="h3"
                  className={classes.betaTag}
                  hexColor={tagColor ?? '#426FFB'}
                  backgroundColor={tagBackgroundColor ?? '#ECF1FF'}
                  ml={2}
                  providedClasses={{ container: classes.tagContainer }}
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtmlContent(tag),
                  }}
                />
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {typeof subtitle === 'string' ? (
            <Typography variant="subtitle2" className={classes.subtitleStyle}>
              <ConfigurableText config={subtitle} styled={false} />
            </Typography>
          ) : (
            <List>
              {subtitle.map((item, index) => (
                <CheckedListItem key={index}>
                  <ConfigurableText config={item} styled={false} />
                </CheckedListItem>
              ))}
            </List>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ListableInformationCard
