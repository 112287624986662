import { Vehicle } from '@/types'
import { Car, TeslaCar, FordCar } from '@/types/vehicle'

export function isTesla(vehicle: Vehicle): boolean {
  return isTeslaCar(vehicle.car)
}

export function isTeslaCar(car: Car): car is TeslaCar {
  return car.car_model?.make.toLowerCase() === 'tesla'
}

export function isFordCar(car: Car): car is FordCar {
  return car.car_model?.make.toLowerCase() === 'ford'
}
