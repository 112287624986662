import { selectUtilityProgramEnrollmentForUser } from '@/reducers/utilityProgramEnrollments'
import { evaluatedProgramViewConfigTemplate } from '@/reducers/utilityPrograms'
import {
  behavioralEnrollmentCollection,
  chargerEnrollmentCollection,
  thermostatEnrollmentCollection,
  vehicleEnrollmentCollection,
} from '@/reducers/deviceProgramEnrollments'
import { RootState } from '@/store'
import { Utility, VehicleEnrollment } from '@/types'
import { ID } from '@/types/model'
import { createSelector } from '@reduxjs/toolkit'
import {
  BehavioralEnrollment,
  ChargerEnrollment,
  DeviceEnrollment,
  ThermostatEnrollment,
} from '@/types/utilityProgramEnrollment'

export const createDeviceEnrollmentsForUserSelector = <
  T extends 'vehicle' | 'thermostat' | 'vehicle_charger' | 'behavioral',
>(
  deviceType: T = 'vehicle' as T,
) =>
  createSelector(
    [
      (state: RootState) =>
        vehicleEnrollmentCollection.selectors.selectAll(state),
      (state: RootState) =>
        thermostatEnrollmentCollection.selectors.selectAll(state),
      (state: RootState) =>
        chargerEnrollmentCollection.selectors.selectAll(state),
      (state: RootState) =>
        behavioralEnrollmentCollection.selectors.selectAll(state),
      (state: RootState) => selectUtilityProgramEnrollmentForUser(state),
      (state: RootState) =>
        (state.user.user?.profile?.utility as Utility | undefined)?.name,
    ],
    (
      vehicleEnrollments,
      thermostatEnrollments,
      chargerEnrollments,
      behavioralEnrollments,
      utilityProgramEnrollment,
      utilityName,
    ): T extends 'thermostat'
      ? ThermostatEnrollment[]
      : T extends 'vehicle'
      ? VehicleEnrollment[]
      : T extends 'vehicle_charger'
      ? ChargerEnrollment[]
      : T extends 'behavioral'
      ? BehavioralEnrollment[]
      : never => {
      // Our internal vehicleEnrollmentsCollection may not have the
      // latest vehicle enrollments in the case that we just enrolled in a program.
      // In that case, we need to merge the vehicle enrollments from the utility program enrollment
      // with the vehicle enrollments from the vehicleEnrollmentsCollection.
      const enrollmentsNotInVehicleEnrollments =
        utilityProgramEnrollment?.vehicle_enrollments?.filter(
          (vehicleEnrollment) =>
            !vehicleEnrollments.find(
              (enrollment) => enrollment.id === vehicleEnrollment.id,
            ),
        ) ?? []

      const enrollmentsNotInThermostatEnrollments =
        utilityProgramEnrollment?.thermostat_enrollments?.filter(
          (thermostatEnrollment) =>
            !thermostatEnrollments.find(
              (enrollment) => enrollment.id === thermostatEnrollment.id,
            ),
        ) ?? []

      const enrollmentsNotInChargerEnrollments =
        utilityProgramEnrollment?.charger_enrollments?.filter(
          (chargerEnrollment) =>
            !chargerEnrollments.find(
              (enrollment) => enrollment.id === chargerEnrollment.id,
            ),
        ) ?? []

      const enrollmentsNotInBehavioralEnrollments =
        utilityProgramEnrollment?.behavioral_enrollments?.filter(
          (behavioralEnrollment) =>
            !behavioralEnrollments.find(
              (enrollment) => enrollment.id === behavioralEnrollment.id,
            ),
        ) ?? []

      if (deviceType === 'thermostat') {
        const allThermostatEnrollments = [
          ...thermostatEnrollments,
          ...enrollmentsNotInThermostatEnrollments,
        ] as ThermostatEnrollment[]

        // return thermostat enrollments with view configs evaluated
        return allThermostatEnrollments.map((thermostatEnrollment) => {
          const viewConfig = thermostatEnrollment.view_config_json
          if (!viewConfig) {
            return thermostatEnrollment
          }

          return {
            ...thermostatEnrollment,
            view_config_json: evaluatedProgramViewConfigTemplate(viewConfig, {
              utility_name: utilityName ?? '',
            }),
          }
        }) as T extends 'thermostat'
          ? ThermostatEnrollment[]
          : T extends 'vehicle'
          ? never
          : T extends 'vehicle_charger'
          ? ChargerEnrollment[]
          : T extends 'behavioral'
          ? BehavioralEnrollment[]
          : never
      } else if (deviceType === 'vehicle') {
        const allVehicleEnrollments = [
          ...vehicleEnrollments,
          ...enrollmentsNotInVehicleEnrollments,
        ] as VehicleEnrollment[]

        // return vehicle enrollments with view configs evaluated
        return allVehicleEnrollments.map((vehicleEnrollment) => {
          const viewConfig = vehicleEnrollment.view_config_json
          if (!viewConfig) {
            return vehicleEnrollment
          }

          return {
            ...vehicleEnrollment,
            view_config_json: evaluatedProgramViewConfigTemplate(viewConfig, {
              utility_name: utilityName ?? '',
            }),
          }
        }) as T extends 'thermostat'
          ? never
          : T extends 'vehicle'
          ? VehicleEnrollment[]
          : T extends 'vehicle_charger'
          ? ChargerEnrollment[]
          : T extends 'behavioral'
          ? BehavioralEnrollment[]
          : never
      } else if (deviceType === 'behavioral') {
        const allBehavioralEnrollments = [
          ...behavioralEnrollments,
          ...enrollmentsNotInBehavioralEnrollments,
        ] as BehavioralEnrollment[]

        // return vehicle enrollments with view configs evaluated
        return allBehavioralEnrollments.map((behavioralEnrollment) => {
          const viewConfig = behavioralEnrollment.view_config_json
          if (!viewConfig) {
            return behavioralEnrollment
          }

          return {
            ...behavioralEnrollment,
            view_config_json: evaluatedProgramViewConfigTemplate(viewConfig, {
              utility_name: utilityName ?? '',
            }),
          }
        }) as T extends 'thermostat'
          ? never
          : T extends 'vehicle'
          ? VehicleEnrollment[]
          : T extends 'vehicle_charger'
          ? ChargerEnrollment[]
          : T extends 'behavioral'
          ? BehavioralEnrollment[]
          : never
      } else {
        const allChargerEnrollments = [
          ...chargerEnrollments,
          ...enrollmentsNotInChargerEnrollments,
        ] as ChargerEnrollment[]

        return allChargerEnrollments.map((chargerEnrollment) => {
          const viewConfig = chargerEnrollment.view_config_json
          if (!viewConfig) {
            return chargerEnrollment
          }

          return {
            ...chargerEnrollment,
            view_config_json: evaluatedProgramViewConfigTemplate(viewConfig, {
              utility_name: utilityName ?? '',
            }),
          }
        }) as T extends 'thermostat'
          ? never
          : T extends 'vehicle'
          ? VehicleEnrollment[]
          : T extends 'vehicle_charger'
          ? ChargerEnrollment[]
          : T extends 'behavioral'
          ? BehavioralEnrollment[]
          : never
      }
    },
  )

export const selectVehicleEnrollmentForUser = createSelector(
  [
    (state: RootState) =>
      createDeviceEnrollmentsForUserSelector('vehicle')(state),
    (state: RootState, vehicleId: ID) => vehicleId,
  ],
  (vehicleEnrollments, vehicleId) =>
    vehicleEnrollments.find(
      (vehicleEnrollment) => vehicleEnrollment.vehicle_id === vehicleId,
    ),
)

export const selectThermostatEnrollmentsForUser = createSelector(
  [
    (state: RootState) =>
      createDeviceEnrollmentsForUserSelector('thermostat')(state),
    (state: RootState, thermostatId) => thermostatId,
  ],
  (thermostatEnrollments, thermostatId) =>
    thermostatEnrollments.filter(
      (thermostatEnrollment) =>
        thermostatEnrollment.thermostat_id === thermostatId,
    ),
)
