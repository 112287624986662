import { Vehicle } from '@/types/vehicle'
import {
  carCanControlClimate,
  carMissingCoreAvailablePermissions,
} from './car/permissions'

// @todo pull these from the backend
export enum CarPermissions {
  W_CHARGE = 'W_CHARGE',
  R_CHARGE = 'R_CHARGE',
  R_BAT = 'R_BAT',
  R_GEO = 'R_GEO',
  R_ODO = 'R_ODO',
  R_INFO = 'R_INFO',
  R_VIN = 'R_VIN',
}

export const CHARGE_PERMISSIONS = [
  CarPermissions.W_CHARGE, // required to start charging
  CarPermissions.R_CHARGE, // required to check if the car is charging
  CarPermissions.R_BAT, // required to check battery level
  CarPermissions.R_GEO, // required to check location
]

export function canCharge(vehicle?: Vehicle) {
  if (!vehicle) return false

  const permissions = vehicle?.car?.permissions ?? []
  const hasChargePermissions = CHARGE_PERMISSIONS.every((p) =>
    permissions.includes(p),
  )
  const publicKeyMissing = Boolean(vehicle.car.public_key_not_attached_at)
  return hasChargePermissions && !publicKeyMissing
}

// function that returns true when the vehicle canCharge but doesn't have all permissions in CarPermissions
export function missingSomePermissions(vehicle: Vehicle) {
  const allPermissions = Object.values(CarPermissions)
  return allPermissions.some((p) => !vehicle.car.permissions?.includes(p))
}

export function missingCoreAvailablePermissions(vehicle: Vehicle) {
  return carMissingCoreAvailablePermissions(vehicle.car)
}

export function canControlClimate(vehicle: Vehicle) {
  return carCanControlClimate(vehicle.car)
}
