import { VehicleConnectivity } from '@/types/vehicle/vehicleConnectivity'
import moment from 'moment'

export type ConnectivityConfigStatus = 'success' | 'failure' | 'unknown'

export enum ConnectivityConfigType {
  SoftwareUpdated = 'Software Updated',
  ManufacturerConnection = 'Manufacturer Connection',
  VehicleSignal = 'Vehicle Signal',
  Subscription = 'Subscription',
}

export function getConnectivityConfigStatus(connectivity: VehicleConnectivity) {
  const dependencyGraph = {
    [ConnectivityConfigType.SoftwareUpdated]: [],
    [ConnectivityConfigType.ManufacturerConnection]: [],
    [ConnectivityConfigType.Subscription]: [
      ConnectivityConfigType.ManufacturerConnection,
    ],
    [ConnectivityConfigType.VehicleSignal]: [
      ConnectivityConfigType.ManufacturerConnection,
      ConnectivityConfigType.Subscription,
    ],
  }

  const statuses: {
    [key in ConnectivityConfigType]: ConnectivityConfigStatus
  } = {
    [ConnectivityConfigType.SoftwareUpdated]: !connectivity?.connectivity_method
      .should_update
      ? 'success'
      : 'failure',
    [ConnectivityConfigType.ManufacturerConnection]: !connectivity
      .manufacturer_connection.is_auth_expired
      ? 'success'
      : 'failure',
    [ConnectivityConfigType.VehicleSignal]: moment(
      connectivity.vehicle_signal.last_ping_time,
    ).isAfter(moment().subtract(8, 'hour'))
      ? 'success'
      : 'failure',
    [ConnectivityConfigType.Subscription]: !connectivity
      .subscription_information.is_missing_subscription
      ? 'success'
      : 'failure',
  }

  for (const [configType, dependencies] of Object.entries(dependencyGraph)) {
    const configTypeEnum = configType as ConnectivityConfigType
    for (const dependency of dependencies) {
      if (['failure', 'unknown'].includes(statuses[dependency])) {
        statuses[configTypeEnum] = 'unknown'
      }
    }
  }

  return statuses
}
