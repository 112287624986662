import { AlertState, Device } from '../DeviceSelectionGallery.types'
import { Thermostat, Vehicle, VehicleCharger } from '@/types'
import {
  selectThermostatIsInactive,
  selectVehicleChargerIsInactive,
} from '@/selectors'
import { useAppSelector } from '@/hooks'
import {
  canCharge,
  missingCoreAvailablePermissions,
  missingSomePermissions,
} from '@/utils/vehicles/permissions'
import { isTesla } from '@/utils/vehicles/car/index'
import FleetMigrationBanner from '../banners/vehicle/FleetMigrationBanner'
import TeslaCorePermissionsBanner from '../banners/vehicle/TeslaCorePermissionsBanner'
import TeslaVirtualKeyBanner from '../banners/vehicle/TeslaVirtualKeyBanner'
import TeslaChargeControlPermissionsBanner from '../banners/vehicle/TeslaChargeControlPermissionsBanner'
import TeslaMissingAnyPermissionBanner from '../banners/vehicle/TeslaMissingAnyPermissionBanner'
import VehicleBrokenConnectionBanner from '../banners/vehicle/VehicleBrokenConnectionBanner'
import ThermostatBrokenConnectionBanner from '../banners/thermostat/ThermostatBrokenConnectionBanner'
import ChargerBrokenConnectionBanner from '../banners/vehicle-charger/ChargerBrokenConnectionBanner'

type Props = {
  devices: Device[]
}

function vehicleAlertState(vehicle: Vehicle): AlertState & { id: number } {
  // If we lost connection to the vehicle
  if (vehicle.authentication_inactive) {
    return {
      id: vehicle.id,
      mode: 'error',
      renderBanner: () => <VehicleBrokenConnectionBanner />,
    }
  }

  // If the vehicle is using the old Tesla API
  if (vehicle.requires_tesla_fleet_migration) {
    return {
      id: vehicle.id,
      mode: 'error',
      renderBanner: () => <FleetMigrationBanner />,
    }
  }

  if (
    isTesla(vehicle) &&
    !vehicle.requires_tesla_fleet_migration &&
    missingCoreAvailablePermissions(vehicle)
  ) {
    return {
      id: vehicle.id,
      mode: 'warning',
      renderBanner: () => <TeslaCorePermissionsBanner vehicleId={vehicle.id} />,
    }
  }

  // If the vehicle has not paired it's virtual key
  if (isTesla(vehicle) && vehicle.car.public_key_not_attached_at) {
    return {
      id: vehicle.id,
      mode: 'warning',
      renderBanner: () => <TeslaVirtualKeyBanner vehicle={vehicle} />,
    }
  }

  // if the vehicle is a tesla and hasn't enabled charge control related permissions,
  // ask them to enable them
  if (isTesla(vehicle) && !canCharge(vehicle)) {
    return {
      id: vehicle.id,
      mode: 'warning',
      renderBanner: () => (
        <TeslaChargeControlPermissionsBanner vehicleId={vehicle.id} />
      ),
    }
  }

  // if the vehicle is a tesla and has enabled charge control related permissions,
  // but hasn't enabled full permissions, ask them to enable them
  if (
    isTesla(vehicle) &&
    canCharge(vehicle) &&
    missingSomePermissions(vehicle)
  ) {
    return {
      id: vehicle.id,
      mode: 'info',
      renderBanner: () => (
        <TeslaMissingAnyPermissionBanner vehicleId={vehicle.id} />
      ),
    }
  }

  return { id: vehicle.id, mode: 'none' }
}

function useVehicleAlertStates(vehicles: Vehicle[]) {
  return vehicles.map((vehicle) => {
    return vehicleAlertState(vehicle)
  })
}

function thermostatAlertState(
  thermostat: Thermostat,
): AlertState & { id: number } {
  if (thermostat.authentication_inactive) {
    return {
      id: thermostat.id,
      mode: 'error',
      renderBanner: () => <ThermostatBrokenConnectionBanner />,
    }
  }

  return { id: thermostat.id, mode: 'none' }
}

function useThermostatAlertStates(thermostats: Thermostat[]) {
  const thermostatsWithAuth = useAppSelector((state) =>
    thermostats.map((thermostat) => {
      return {
        ...thermostat,
        authentication_inactive: selectThermostatIsInactive(
          state,
          thermostat.id,
        ),
      }
    }),
  )
  return thermostatsWithAuth.map((tstat) => {
    return thermostatAlertState(tstat)
  })
}

const vehicleChargerAlertState = (
  vehicleCharger: VehicleCharger,
): AlertState & { id: number } => {
  if (vehicleCharger.authentication_inactive) {
    return {
      id: vehicleCharger.id,
      mode: 'error',
      renderBanner: () => <ChargerBrokenConnectionBanner />,
    }
  }

  return { id: vehicleCharger.id, mode: 'none' }
}

const useVehicleChargerAlertStates = (vehicleChargers: VehicleCharger[]) => {
  const vehicleChargersWithAuth = useAppSelector((state) =>
    vehicleChargers.map((vehicleCharger) => {
      return {
        ...vehicleCharger,
        authentication_inactive: selectVehicleChargerIsInactive(
          state,
          vehicleCharger.id,
        ),
      }
    }),
  )
  return vehicleChargersWithAuth.map((charger) => {
    return vehicleChargerAlertState(charger)
  })
}

export function useDeviceAlertStates({
  devices,
}: Props): (Device & { alertState: AlertState })[] {
  const vehicleAlertStates = useVehicleAlertStates(
    devices
      .filter((device) => device.type === 'vehicle')
      .map((device) => device.model as Vehicle),
  )
  const thermostatAlertStates = useThermostatAlertStates(
    devices
      .filter((device) => device.type === 'thermostat')
      .map((device) => device.model as Thermostat),
  )
  const vehicleChargerAlertStates = useVehicleChargerAlertStates(
    devices
      .filter((device) => device.type === 'vehicleCharger')
      .map((device) => device.model as VehicleCharger),
  )

  const devicesWithAlertState = devices.map((device) => {
    let alertState: AlertState | undefined
    if (device.type === 'vehicle') {
      alertState = vehicleAlertStates.find((v) => v.id === device.model.id)
    } else if (device.type === 'thermostat') {
      alertState = thermostatAlertStates.find((t) => t.id === device.model.id)
    } else if (device.type === 'vehicleCharger') {
      alertState = vehicleChargerAlertStates.find(
        (c) => c.id === device.model.id,
      )
    }

    return {
      ...device,
      alertState: alertState ?? { mode: 'none' },
    }
  })

  return devicesWithAlertState
}
