import './styles.scss'
import { ReactNodeProps } from '../../types/reactNodeProps'
import getColor from '../../utils/color/getColor'
import { Icon as IconType, Props as IconProps } from '../../types/reactFeather'
import * as _Icon from 'react-feather'
import formatClasses from '../../utils/classes/formatClasses'
import Plug from './CustomIcons/Plug.svg?react'
import StrikedPlug from './CustomIcons/StrikedPlug.svg?react'
import Piggy from './CustomIcons/Piggy.svg?react'
import Solar from './CustomIcons/Solar.svg?react'
import Tree from './CustomIcons/Tree.svg?react'
import Optiwatt from './CustomIcons/Optiwatt.png'
import Google from './CustomIcons/google.svg?react'
import Apple from './CustomIcons/apple.svg?react'
import HomeCharge from './CustomIcons/HomeCharge.svg?react'
import HomePlug from './CustomIcons/HomePlug.svg?react'
import Target2 from './CustomIcons/Target2.svg?react'
import Cupcake from './CustomIcons/Cupcake.svg?react'
import FormValid from './CustomIcons/form-valid.svg?react'
import FormInvalid from './CustomIcons/form-invalid.svg?react'
import SteeringWheel from './CustomIcons/steering-wheel.svg?react'

const FeatherIcons: { [key: string]: IconType } = _Icon

export type IconNames = keyof typeof _Icon | keyof typeof CustomIcons

export interface Props extends ReactNodeProps, Omit<IconProps, 'children'> {
  name: IconNames
  color?: string
  rotation?: number
  svgHeight?: number
  svgWidth?: number
}

const CustomIcons = {
  StrikedPlug,
  Plug,
  Piggy,
  Solar,
  Tree,
  Google,
  Apple,
  Optiwatt: () => <img src={Optiwatt} alt="Optiwatt" />,
  HomeCharge,
  HomePlug,
  Target2,
  Cupcake,
  FormValid,
  FormInvalid,
  SteeringWheel,
}

export default function Icon({
  name,
  color,
  rotation,
  className,
  ...iconProps
}: Props) {
  const FeatherIcon = FeatherIcons[name]
  const CustomIcon = CustomIcons[name as keyof typeof CustomIcons]

  if (!FeatherIcon && !CustomIcon) {
    throw new Error(`Icon ${name} not found. Please select a valid icon.`)
  }

  const classes = formatClasses(['--icon', className])

  const styles = () => {
    return {
      transform: `rotate(${rotation}deg)`,
      width: `${iconProps.size}px`,
      height: `${iconProps.size}px`,
    }
  }

  if (CustomIcon) {
    return (
      <CustomIcon
        style={{ ...styles(), color: getColor(color) }}
        className={classes}
      />
    )
  } else if (!FeatherIcon) {
    return null
  }

  return (
    <FeatherIcon
      className={classes}
      color={getColor(color)}
      style={styles()}
      {...iconProps}
    />
  )
}
