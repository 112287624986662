import { Box as MuiBox } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import { makeStyles, useTheme } from '@material-ui/styles'
import { Circle } from 'react-feather'
import SegmentedCapsule from '../../../visualization/SegmentedCapsule'
import IconLegend from '../../../visualization/IconLegend'
import {
  LegendLabelTypography,
  LegendValueTypography,
} from '../../UsageSectionTypography/LegendTypography'

const Box = styled(MuiBox)(spacing)

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
  },
}))

function formatPrettyUsage(usage, cost, currencySymbol) {
  const usagePretty = Math.round(usage)
  const costPretty = Math.round(cost)
  return `${currencySymbol}${costPretty} (${usagePretty} kWh)`
}

function formatUsagesForViz(
  homeUsage,
  carUsage,
  maxCost,
  homeFill,
  carFill,
  currencySymbol,
) {
  const { usage: homeUsagekWh, cost: homeCost } = homeUsage
  const { usage: carUsagekWh, cost: carCost } = carUsage
  const stopsBeforeMax = maxCost > homeCost + carCost

  return [
    { stop: 0, fill: carFill },
    {
      stop: carCost,
      fill: carFill,
      label: 'Car',
      value: formatPrettyUsage(carUsagekWh, carCost, currencySymbol),
    },
    {
      stop: homeCost + carCost,
      fill: homeFill,
      label: 'Home',
      value: formatPrettyUsage(homeUsagekWh, homeCost, currencySymbol),
    },
    ...(stopsBeforeMax ? [{ stop: maxCost, fill: null }] : []),
  ]
}

const VizTitle = ({ title, subtitle, isLoading }) => (
  <Box>
    <LegendLabelTypography>
      {isLoading ? (
        <Skeleton animation="wave" variant="text" width="4rem" />
      ) : (
        title
      )}
    </LegendLabelTypography>
    <LegendValueTypography>
      {isLoading ? (
        <Skeleton animation="wave" variant="text" width="4rem" />
      ) : (
        subtitle
      )}
    </LegendValueTypography>
  </Box>
)

// @todo pass currency
const HomeComparisonViz = ({
  homeUsage = { usage: 0, cost: 0 },
  carUsage = { usage: 0, cost: 0 },
  maxCost = 0,
  title,
  isLoading,
}) => {
  const classes = useStyles()
  const theme = useTheme()

  const homeFill = theme.palette.purple.main
  const carFill = theme.palette.blue.main
  const currencySymbol = '$'
  const coloredStops = isLoading
    ? []
    : formatUsagesForViz(
        homeUsage,
        carUsage,
        maxCost,
        homeFill,
        carFill,
        currencySymbol,
      )

  const heightPercentage = 5
  const borderRadius = heightPercentage / 2 / 1.75

  const totalUsage = homeUsage.usage + carUsage.usage
  const totalCost = homeUsage.cost + carUsage.cost

  return (
    <Box className={classes.container}>
      <VizTitle
        title={title}
        subtitle={formatPrettyUsage(totalUsage, totalCost, currencySymbol)}
        isLoading={isLoading}
      />
      <SegmentedCapsule
        segmentStops={coloredStops}
        heightPercentage={heightPercentage}
        segmentPaddingPercentage={0.5}
        backgroundFill="#ECF1FF"
        borderRadius={borderRadius}
        py={2}
      />
      <IconLegend
        data={coloredStops.map(({ fill, label, value }) => ({
          color: fill,
          label,
          value,
        }))}
        iconRender={(color) => <Circle size={10} fill={color} color={color} />}
        labelRender={(label) =>
          isLoading ? (
            <Skeleton
              animation="wave"
              variant="text"
              height="1rem"
              width="2rem"
            />
          ) : (
            <LegendLabelTypography>{label}</LegendLabelTypography>
          )
        }
        valueRender={(value) =>
          isLoading ? (
            <Skeleton
              animation="wave"
              variant="text"
              height="1rem"
              width="4rem"
            />
          ) : (
            <LegendValueTypography>{value}</LegendValueTypography>
          )
        }
        spacing={6}
      />
    </Box>
  )
}

export default HomeComparisonViz
