import { useEffect, useState } from 'react'
import { withTheme } from 'styled-components'
import { useChargeForecast } from './ChargeForecast'
import { getChargeForecast } from '../../../actions/metric'
import { useDispatch, useSelector } from 'react-redux'
import { useDistanceUnit } from '../../hooks/conversions'
import {
  ChargeForecastChartComponent,
  SkeletonChart,
} from './ChargeForecastChartComponent'
import ErrorBoundary, { LocationTag } from '../ErrorBoundary'
import ChargeNowStatusModal from '@/app/features/app/vehicle/ChargeNowStatusModal'

export function ExampleChargeForecastCharge() {
  return (
    <SkeletonChart
      heading={'Charge Forecast'}
      description={
        'Your charge forecast and rates will appear here once your utility plan has been added'
      }
    />
  )
}

function ChargeForecastChart({
  vehicleId,
  setShowChargeNowConfirmation,
  showChargeNowConfirmation,
}) {
  const dispatch = useDispatch()
  const chargeSchedules = useSelector((state) => state.schedule.chargeSchedules)
  const [distanceUnit] = useDistanceUnit()
  const chargeForecast = useSelector((state) => state.metric.chargeForecast)

  const isFetchingChargeForecast = useSelector(
    (state) => state.metric.isFetchingChargeForecast,
  )
  const chargeForecastFailed = useSelector(
    (state) => state.metric.chargeForecastFailed,
  )
  const [data, options, plugins, isMissingPlan] = useChargeForecast(
    chargeForecast,
    distanceUnit,
  )

  useEffect(() => {
    const userHasNoPlan = chargeForecast?.type === 'no plan'
    const vehicleFilterMatchesCurrentVehicle =
      chargeForecast && chargeForecast.vehicle_id === vehicleId
    if (vehicleFilterMatchesCurrentVehicle || userHasNoPlan) {
      return
    }
    if (!isFetchingChargeForecast && !chargeForecastFailed) {
      dispatch(getChargeForecast(vehicleId))
    }
  }, [
    chargeForecast,
    isFetchingChargeForecast,
    chargeForecastFailed,
    vehicleId,
    dispatch,
  ])

  if (isFetchingChargeForecast) {
    return (
      <SkeletonChart heading={'Charge Forecast'} description={'Loading...'} />
    )
  } else if (!chargeForecast) {
    return (
      <SkeletonChart
        heading={'Charge Forecast'}
        description={'Error loading chart'}
      />
    )
  }

  if (data.length === 0) {
    if (isMissingPlan) {
      return <ExampleChargeForecastCharge />
    } else {
      return <div />
    }
  }

  const isEnabled = chargeSchedules?.[0]?.allow_charging_control
  const isOptimizedPlan = !chargeSchedules?.[0]?.money_saving_plan_enabled

  return (
    <ChargeForecastChartComponent
      isEnabled={isEnabled}
      isOptimizedPlan={isOptimizedPlan}
      options={options}
      data={data}
      plugins={plugins}
      vehicleId={vehicleId}
      setShowChargeNowConfirmation={setShowChargeNowConfirmation}
      showChargeNowConfirmation={showChargeNowConfirmation}
    />
  )
}

const ThemedChargeForecastChart = withTheme(ChargeForecastChart)

export default function (props) {
  const [showChargeNowConfirmation, setShowChargeNowConfirmation] =
    useState(false)
  return (
    <ErrorBoundary
      location={LocationTag.ChargeForecast}
      functionalityDescription="Charge Forecast"
      fallbackOnCard
    >
      <ThemedChargeForecastChart
        {...props}
        showChargeNowConfirmation={showChargeNowConfirmation}
        setShowChargeNowConfirmation={setShowChargeNowConfirmation}
      />
      <ChargeNowStatusModal
        vehicleId={props.vehicleId}
        open={showChargeNowConfirmation}
        onClose={() => setShowChargeNowConfirmation(false)}
      />
    </ErrorBoundary>
  )
}
