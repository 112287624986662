import UtilityPlanDropdownRoot, {
  Props as ComponentProps,
} from './UtilityPlanDropdown'
import useViewModel from './useViewModel'
import { ViewModelComponentProps } from '../../../../utils/typeUtilities'

export type Props = ViewModelComponentProps<ComponentProps, typeof useViewModel>

export default function UtilityPlanDropdown(props: Props) {
  const viewModel = useViewModel()
  return <UtilityPlanDropdownRoot {...viewModel} {...props} />
}
