import React from 'react'
import ContactUs from '@/app/components/ContactUs/ContactUs'

const UTILITIES = {
  aiken: {
    name: 'Aiken Electric Cooperative',
    representative: 'Kenny Brown',
    phone: '803-645-7006',
  },
  berkeley: {
    name: 'Berkeley Electric Cooperative',
    representative: 'the Member call center',
    phone: '843-761-8200',
  },
  broad_river: {
    name: 'Broad River Electric Cooperative',
    representative: 'John Fowler',
    phone: '866-687-2667',
  },
  horry: {
    name: 'Horry Electric Cooperative',
    representative: 'Gordon Lay',
    phone: '843-369-6418',
  },
  laurens: {
    name: 'Laurens Electric Cooperative',
    representative: 'Kacie Owings',
    phone: '864-683-1690',
  },
  mid_carolina: {
    name: 'Mid Carolina Electric Cooperative',
    representative: 'Matt Porth',
    phone: '803-749-6501',
  },
  newberry: {
    name: 'Newberry Electric Cooperative',
    representative: 'Kevin Shull',
    phone: '803-924-7137',
  },
  palmetto: {
    name: 'Palmetto Electric Cooperative',
    representative: 'the Member call center',
    phone: '843-681-0071',
  },
  santee: {
    name: 'Santee Electric Coop',
    representative: 'Member call center',
    phone: '843-355-0613',
  },
  york: {
    name: 'York Electric Cooperative',
    representative: 'Brent Clinton',
    phone: '803-628-5554',
  },
}

export default ({ utility = 'aiken' }) => {
  return (
    <React.Fragment>
      <section className="promo-section ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-9">
              <div className="section-heading mb-5">
                <h2>
                  {UTILITIES[utility]['name']}
                  <br />
                  Managed Charging Program
                </h2>
                Don't see the answer you're looking for? Please <ContactUs />{' '}
                and we'll get back to you
                <p className="lead">right away.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div id="accordion-1" className="accordion accordion-faq">
                <div className="card">
                  <div
                    className="card-header py-4"
                    id="heading-1-1"
                    data-toggle="collapse"
                    role="button"
                    data-target="#collapse-1-1"
                    aria-expanded="false"
                    aria-controls="collapse-1-1"
                  >
                    <h6 className="mb-0" style={{ 'padding-right': '10px' }}>
                      How will this affect my charging schedule?
                    </h6>
                  </div>
                  <div
                    id="collapse-1-1"
                    className="collapse"
                    aria-labelledby="heading-1-1"
                    data-parent="#accordion-1"
                  >
                    <div className="card-body">
                      <p>
                        Optiwatt will occasionally postpone charging during
                        times of high grid stress while ensuring your battery
                        still reaches your desired target limit. At any time,
                        you can override this to boost charging or opt-out of
                        the program entirely with one click. Optiwatt will never
                        postpone your away from home charging, (e.g. at
                        superchargers, your place of work, etc.).
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div
                    className="card-header py-4"
                    id="heading-1-2"
                    data-toggle="collapse"
                    role="button"
                    data-target="#collapse-1-2"
                    aria-expanded="false"
                    aria-controls="collapse-1-2"
                  >
                    <h6 className="mb-0" style={{ 'padding-right': '10px' }}>
                      Will this prevent me from charging my EV?
                    </h6>
                  </div>
                  <div
                    id="collapse-1-2"
                    className="collapse"
                    aria-labelledby="heading-1-2"
                    data-parent="#accordion-1"
                  >
                    <div className="card-body">
                      <p>
                        Not at all! Optiwatt will prioritize your departure time
                        and charge limit preference to ensure you always have
                        the battery you need.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div
                    className="card-header py-4"
                    id="heading-1-3"
                    data-toggle="collapse"
                    role="button"
                    data-target="#collapse-1-3"
                    aria-expanded="false"
                    aria-controls="collapse-1-3"
                  >
                    <h6 className="mb-0" style={{ 'padding-right': '10px' }}>
                      How do I participate in the program?
                    </h6>
                  </div>
                  <div
                    id="collapse-1-3"
                    className="collapse"
                    aria-labelledby="heading-1-3"
                    data-parent="#accordion-1"
                  >
                    <div className="card-body">
                      <p>
                        To participate, simply connect your EV to the Optiwatt
                        mobile or desktop app and enable the program enrollment
                        toggle in the “Charging” section of the app. No
                        additional steps are required, Optiwatt will
                        automatically handle the rest.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div
                    className="card-header py-4"
                    id="heading-1-4"
                    data-toggle="collapse"
                    role="button"
                    data-target="#collapse-1-4"
                    aria-expanded="false"
                    aria-controls="collapse-1-4"
                  >
                    <h6 className="mb-0" style={{ 'padding-right': '10px' }}>
                      How do I stop participating in the program?
                    </h6>
                  </div>
                  <div
                    id="collapse-1-4"
                    className="collapse"
                    aria-labelledby="heading-1-4"
                    data-parent="#accordion-1"
                  >
                    <div className="card-body">
                      <p>
                        You will be able to disenroll from the program easily
                        with one-click in the “Charging” section of the mobile
                        or desktop app. Alternatively, you may email
                        support@getoptiwatt.com to disenroll at any time.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div id="accordion-2" className="accordion accordion-faq">
                <div className="card">
                  <div
                    className="card-header py-4"
                    id="heading-1-5"
                    data-toggle="collapse"
                    role="button"
                    data-target="#collapse-1-5"
                    aria-expanded="false"
                    aria-controls="collapse-1-5"
                  >
                    <h6 className="mb-0" style={{ 'padding-right': '10px' }}>
                      If I agree to participate in the Optiwatt pilot with my
                      Electric Cooperative, what incentive will I receive?
                    </h6>
                  </div>
                  <div
                    id="collapse-1-5"
                    className="collapse"
                    aria-labelledby="heading-1-5"
                    data-parent="#accordion-2"
                  >
                    <div className="card-body">
                      <p>
                        Participants in the Optiwatt pilot will receive a $50
                        bill credit at sign-up and a $100 bill credit if you are
                        enrolled through the duration of the pilot and complete
                        the survey at the end. This is a total incentive of $150
                        for each participating vehicle.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div
                    className="card-header py-4"
                    id="heading-2-5"
                    data-toggle="collapse"
                    role="button"
                    data-target="#collapse-2-5"
                    aria-expanded="false"
                    aria-controls="collapse-2-5"
                  >
                    <h6 className="mb-0" style={{ 'padding-right': '10px' }}>
                      When will I receive my incentive?
                    </h6>
                  </div>
                  <div
                    id="collapse-2-5"
                    className="collapse"
                    aria-labelledby="heading-2-5"
                    data-parent="#accordion-2"
                  >
                    <div className="card-body">
                      <p>
                        The incentive will be issued as a bill credit and appear
                        on your next monthly utility bill statement following
                        enrollment.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div
                    className="card-header py-4"
                    id="heading-2-1"
                    data-toggle="collapse"
                    role="button"
                    data-target="#collapse-2-1"
                    aria-expanded="false"
                    aria-controls="collapse-2-1"
                  >
                    <h6 className="mb-0" style={{ 'padding-right': '10px' }}>
                      How do I qualify to receive the second incentive of $100?
                    </h6>
                  </div>
                  <div
                    id="collapse-2-1"
                    className="collapse"
                    aria-labelledby="heading-2-1"
                    data-parent="#accordion-2"
                  >
                    <div className="card-body">
                      <p>
                        To receive the second bill credits of $100, we kindly
                        ask that participants remain in the pilot project until
                        March of 2023 and complete a final participation survey.
                        The final survey will take place in March of 2023, which
                        will be a short survey to help your local utility
                        provider better understand and accommodate EV charging
                        preference.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div
                    className="card-header py-4"
                    id="heading-2-2"
                    data-toggle="collapse"
                    role="button"
                    data-target="#collapse-2-2"
                    aria-expanded="false"
                    aria-controls="collapse-2-2"
                  >
                    <h6 className="mb-0" style={{ 'padding-right': '10px' }}>
                      If I have more than one Electric Vehicle, am I eligible
                      for two incentives?
                    </h6>
                  </div>
                  <div
                    id="collapse-2-2"
                    className="collapse"
                    aria-labelledby="heading-2-2"
                    data-parent="#accordion-2"
                  >
                    <div className="card-body">
                      <p>
                        Yes, you can receive the incentive for up to two
                        vehicles per household. To register additional vehicles,
                        simply connect the second vehicle to Optiwatt in the
                        “Profile” section of the mobile app or desktop and you
                        will be automatically qualified.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div
                    className="card-header py-4"
                    id="heading-2-3"
                    data-toggle="collapse"
                    role="button"
                    data-target="#collapse-2-3"
                    aria-expanded="false"
                    aria-controls="collapse-2-3"
                  >
                    <h6 className="mb-0" style={{ 'padding-right': '10px' }}>
                      If I have further questions, who should I contact?
                    </h6>
                  </div>
                  <div
                    id="collapse-2-3"
                    className="collapse"
                    aria-labelledby="heading-2-3"
                    data-parent="#accordion-2"
                  >
                    <div className="card-body">
                      <p>
                        Further questions should be directed to{' '}
                        <b>{UTILITIES[utility]['representative']}</b> from{' '}
                        {UTILITIES[utility]['name']} at{' '}
                        <b>{UTILITIES[utility]['phone']}</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}
