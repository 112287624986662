const dev = {
  apiGateway: {
    URL: process.env.REACT_APP_LOCAL_API_GATEWAY || 'http://localhost:8000',
  },
}

const staging = {
  apiGateway: {
    URL: 'https://api.staging.getoptiwatt.com',
  },
}

const prod = {
  apiGateway: {
    URL: 'https://api.getoptiwatt.com',
  },
}

let config
if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  config = prod
} else if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
  config = staging
} else {
  config = dev
}

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
}
