import { hexToRGB } from '../../utils/colorUtilities'

export function getColors(rates, opacity = 1, grey = '#eceff1') {
  // Example: rates = [{'rate': 1, 'error': false}, {'rate': 2, 'error': true}]
  let colors = new Array(rates.length).fill(grey)
  let uniqueIds = [...new Set(rates.map((rate) => rate['rate']))].sort(
    function (a, b) {
      return a - b
    },
  )

  let colorOptions = [
    '#bbdcfb',
    '#b9d5fb',
    '#b7cefa',
    '#b5c7fa',
    '#b3bffa',
    '#b1b8fa',
    '#b0b1fa',
    '#aeaaf9',
    '#aca3f9',
    '#aa9bf9',
    '#a894f8',
    '#a68df8',
    '#a486f8',
    '#a27ff8',
    '#a078f8',
    '#9e71f7',
    '#9c69f7',
    '#9a62f7',
    '#985bf7',
    '#9654f7',
    '#944df7',
    '#9245f7',
    '#903ef6',
    '#8e37f6',
    '#8c30f6',
  ]
  let colorMap = {}
  for (let i = 0; i < uniqueIds.length; i++) {
    colorMap[uniqueIds[i]] = colorOptions[i]
  }

  let red = '#f44336'
  for (let i = 0; i < rates.length; i++) {
    let rate = rates[i]
    if (rate['error']) {
      colors[i] = hexToRGB(red, opacity)
    } else if (rate['rate'] !== null) {
      colors[i] = hexToRGB(colorMap[rate['rate']], opacity)
    } else {
      colors[i] = hexToRGB(grey, opacity)
    }
  }

  return colors
}

export function getGraphData(
  data,
  labels,
  label,
  items,
  sortProperty,
  errorPeriods,
  getRange,
) {
  function getRates() {
    let rates = []
    // Create a blank rate for all labels
    for (let i = 0; i < labels.length; i++) {
      rates.push({ rate: null, error: false })
    }

    for (let i = 0; i < items.length; i++) {
      let item = items[i]
      let months = getRange(items[i].start, items[i].end)
      for (let j = 0; j < months.length; j++) {
        rates[months[j]] = { rate: item[sortProperty], error: false }
      }
    }

    for (let i = 0; i < errorPeriods.length; i++) {
      rates[errorPeriods[i]]['error'] = true
    }

    return rates
  }

  return {
    labels: labels,
    datasets: [
      {
        label: label,
        data: data,
        backgroundColor: getColors(getRates(), 0.7),
        hoverBackgroundColor: getColors(getRates(), 1, '#ddeefe'),
        barPercentage: 1,
        categoryPercentage: 1,
      },
    ],
  }
}
