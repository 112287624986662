import FormGroup from '@material-ui/core/FormGroup'
import { useState } from 'react'
import styled from 'styled-components'
import {
  Checkbox as MuiCheckbox,
  FormControl as MuiFormControl,
  FormControlLabel as MuiFormControlLabel,
  TextField as MuiTextField,
  Typography as MuiTypography,
} from '@material-ui/core'
import { spacing } from '@material-ui/system'
import { findIndexByPropertyValue } from '../../../utils/arrayUtilities'

const TextFieldSpacing = styled(MuiTextField)(spacing)
const FormControlLabel = styled(MuiFormControlLabel)(spacing)

const Checkbox = styled(MuiCheckbox)``

const TextField = styled(TextFieldSpacing)``

const CompactFormControlLabel = styled(FormControlLabel)`
  margin-left: -5.5px;
  margin-right: -5.5px;
`

const FormControl = styled(MuiFormControl)(spacing)

const Typography = styled(MuiTypography)(spacing)

function TimePicker(props) {
  return (
    <TextField
      id="time"
      label="Time"
      type="time"
      value={props.time} // return only hour:min
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        step: 300, // 5 min
      }}
      mt={2}
      mr={1}
      style={{ width: '108px' }}
      onChange={props.onChange}
    />
  )
}

const dayMap = [
  { full: 'monday', short: 'Mon' },
  { full: 'tuesday', short: 'Tu' },
  { full: 'wednesday', short: 'Wed' },
  { full: 'thursday', short: 'Th' },
  { full: 'friday', short: 'Fri' },
  { full: 'saturday', short: 'Sat' },
  { full: 'sunday', short: 'Sun' },
]

function convertDaysOfWeekToDepartureTimes(daysOfWeek) {
  // Create a dictionary to keep Ready By times unique
  let departureDict = {}
  for (let i = 0; i < daysOfWeek.length; i++) {
    let dayOfWeek = daysOfWeek[i]
    let departureTime = dayOfWeek['departure_time']
    if (!departureDict.hasOwnProperty(departureTime)) {
      departureDict[departureTime] = {}
      departureDict[departureTime]['days_filled_in'] = [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ]
    }

    let dayIndex = findIndexByPropertyValue(
      dayMap,
      'full',
      dayOfWeek['day_of_week'],
    )
    departureDict[departureTime]['days_filled_in'][dayIndex] = true
  }

  let departureTimes = []
  for (let key in departureDict) {
    departureTimes.push({
      time: key,
      days_filled_in: departureDict[key]['days_filled_in'],
    })
  }
  return departureTimes
}

export function convertDepartureTimesToDaysOfWeek(departureTimes) {
  let days = []
  let daysAdded = []
  for (let i = 0; i < departureTimes.length; i++) {
    let departureTime = departureTimes[i]
    // If there is a valid time and at least one day is filled in
    if (departureTime.time !== '') {
      for (let j = 0; j < departureTime.days_filled_in.length; j++) {
        let isSelected = departureTime.days_filled_in[j]
        // We only want days where True is checked, otherwise there is no Ready By time
        if (isSelected) {
          let dayOfWeek = dayMap[j].full
          // Only add a new row if we haven't already added that day
          if (!daysAdded.includes(dayOfWeek)) {
            days.push({
              departure_time: departureTime.time,
              day_of_week: dayOfWeek,
            })
            daysAdded.push(dayOfWeek)
          }
        }
      }
    }
  }

  return days
}

export const DEFAULT_START_TIME = '08:30:00'

export default function ScheduleDaysOfWeek(props) {
  let useFriendlyDepartureTimes = getUserFriendlyDepartureTimes(
    convertDaysOfWeekToDepartureTimes(props.daysOfWeek),
  )
  const [departureTimes, setDepartureTimes] = useState(
    useFriendlyDepartureTimes,
  )

  function setUserFriendlyDepartureTimes(departureTimes) {
    setDepartureTimes(getUserFriendlyDepartureTimes(departureTimes))
    props.onChange(convertDepartureTimesToDaysOfWeek(departureTimes))
  }

  const updateDay = (event, departureTimeIndex, dayIndex) => {
    let newDepartureTimes = [...departureTimes]
    newDepartureTimes[departureTimeIndex].days_filled_in[dayIndex] =
      event.target.checked
    // Remove checked boxes from any other row
    for (let i = 0; i < newDepartureTimes.length; i++) {
      let departureTime = newDepartureTimes[i]
      if (i !== departureTimeIndex) {
        if (event.target.checked) {
          departureTime.days_filled_in[dayIndex] = false
        }
      }
    }

    setUserFriendlyDepartureTimes(newDepartureTimes)
  }

  function updateTime(index, time) {
    let newDepartureTimes = [...departureTimes]
    newDepartureTimes[index].time = time
    setUserFriendlyDepartureTimes(newDepartureTimes)
  }

  function getUserFriendlyDepartureTimes(departureTimes) {
    let newDepartureTimes = []
    let filledDays = [false, false, false, false, false, false, false]
    let hasBlank = false

    for (let i = 0; i < departureTimes.length; i++) {
      let departureTime = departureTimes[i]

      // Update filledDays where a day has been set to true
      for (let j = 0; j < departureTime.days_filled_in.length; j++) {
        if (departureTime.days_filled_in[j]) {
          filledDays[j] = true
        }
      }

      let numDaysSelected = departureTime.days_filled_in.filter(Boolean).length
      // If there is a time filled in, add a new row. If there are days selected, and we don't yet have a blank
      // row, add a new blank row.
      if (departureTime.time !== '') {
        newDepartureTimes.push(departureTime)
      } else if (numDaysSelected && !hasBlank) {
        newDepartureTimes.push(departureTime)
        // If we add a new row, mark that the newDepartureTimes now have a blank row, and we won't need another.
        if (departureTime.time === '') {
          hasBlank = true
        }
      }
    }

    // If not all days are filled in, add a new blank row.
    if (!filledDays.every(Boolean) && !hasBlank) {
      newDepartureTimes.push({
        time: '',
        days_filled_in: [false, false, false, false, false, false, false],
      })
    }

    return newDepartureTimes
  }

  return (
    <>
      <Typography variant="body2" gutterBottom mt={5}>
        Ready By time
      </Typography>
      {departureTimes.map((departureTime, departureTimeIndex) => (
        <>
          <FormControl row>
            <FormGroup aria-label="position" row>
              <TimePicker
                onChange={(event) =>
                  updateTime(departureTimeIndex, event.target.value)
                }
                time={departureTime.time}
              />
              {departureTime.days_filled_in.map((isDayFilledIn, dayIndex) => (
                <CompactFormControlLabel
                  value="top"
                  control={
                    <Checkbox
                      color="primary"
                      name={dayMap[dayIndex].full}
                      size="small"
                      checked={isDayFilledIn}
                      onChange={(event) =>
                        updateDay(event, departureTimeIndex, dayIndex)
                      }
                    />
                  }
                  label={dayMap[dayIndex].short}
                  labelPlacement="top"
                />
              ))}
            </FormGroup>
          </FormControl>
          <br />
        </>
      ))}
    </>
  )
}
