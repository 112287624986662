import React from 'react'

import './css/bootstrap.min.scss'
import './css/meanmenu.scss'
import './css/slick.scss'
import './css/owl.carousel.min.scss'
import './css/main.scss'
import { Helmet } from 'react-helmet'
import useAddScripts from '@/utils/dom/useAddScripts'
import { optiwattConsts } from '@/optiwattConsts'

function Header() {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      <title>Optiwatt</title>
      <meta name="description" content="" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link
        rel="stylesheet"
        href="landing-assets/thermostat/css/fontawesome-all.min.css"
      />
    </Helmet>
  )
}

type Props = {
  children: React.ReactNode
}

export default function ThermostatLandingLayout({ children }: Props) {
  useAddScripts([
    'landing-assets/thermostat/js/jquery.min.js',
    'landing-assets/thermostat/js/bootstrap.bundle.min.js',
    'landing-assets/thermostat/js/jquery.meanmenu.min.js',
    'landing-assets/thermostat/js/slick.min.js',
    'landing-assets/thermostat/js/owl.carousel.min.js',
    'https://cdnjs.cloudflare.com/ajax/libs/waypoints/4.0.1/noframework.waypoints.min.js',
    'landing-assets/thermostat/js/main.js',
  ])

  return (
    <div id="thermostat-landing-scope">
      <Header />
      {/* <!-- sidebar-information-area-start --> */}
      <div className="sidebar-info side-info">
        <div className="sidebar-logo-wrapper mb-25">
          <div className="row align-items-center">
            <div className="col-xl-6 col-8">
              <div className="sidebar-logo">
                <a href="/home">
                  <img src="/img/logo/logo-colored-white.webp" alt="logo-img" />
                </a>
              </div>
            </div>
            <div className="col-xl-6 col-4">
              <div className="sidebar-close-wrapper text-end">
                <button className="sidebar-close side-info-close">
                  <i className="fal fa-times"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="sidebar-menu-wrapper fix">
          <div className="mobile-menu"></div>
        </div>

        <div className="sidebar-contact-wrapper mt-40"></div>
      </div>
      <div className="offcanvas-overlay"></div>
      {/* <!-- sidebar-information-area-end -->
    <!-- modal popup start --> */}
      <div className="submit-popup">
        {/* <img src="landing-assets/thermostat/img/popup-icon.png" alt=""> */}
        <h1 className="popup-heading">Question Submitted</h1>
        <p>
          Thank you for sending your question. Our team will be in touch with
          you soon!
        </p>
        <span className="popup-close">Close</span>
      </div>
      {/* <!-- modal popup end -->

    <!-- header area start --> */}
      <header>
        <div className="header-area">
          <div className="container">
            <div className="menu-wrap">
              <div className="header-logo">
                <a href="/home">
                  <img src="/img/logo/logo-colored.webp" alt="Brand logo" />
                </a>
              </div>
              <nav
                className="main-menu mobile-menu d-sm-none d-md-block"
                id="mobile-menu"
              >
                <ul>
                  <li>
                    <a href="signup" target="_blank">
                      Sign Up
                    </a>
                  </li>
                  <li>
                    <a href="news" target="_blank">
                      News
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://optiwatt.applytojob.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Careers
                    </a>
                  </li>
                  <li>
                    <a href="faq" target="_blank">
                      FAQ
                    </a>
                  </li>
                </ul>
              </nav>
              <div className="menu-btn d-flex align-items-center">
                <div className="header-btns d-none d-md-flex">
                  <a href="login" className="header-login-btn">
                    login
                  </a>
                  <a href="signup" className="header-signup-btn">
                    Sign Up
                  </a>
                </div>
                <div className="menu-bar d-md-none">
                  <div className="navbar-sign side-toggle ml-20">
                    <span className="menu-line-1"></span>
                    <span className="menu-line-2"></span>
                    <span className="menu-line-3"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- header area end -->
    <!-- =========================== --> */}
      <main>{children}</main>
      {/* <!-- =========================== --> */}
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 order-last order-lg-first">
              <div className="footer-widget footer-logo-widget">
                <div className="footer-logo">
                  <img src="/img/logo/logo-colored-white.webp" alt="logo" />
                </div>
                <p className="copyright">
                  Copyrights © {new Date().getFullYear()}. <br />
                  All rights reserved by Optiwatt
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-2">
              <div className="footer-widget">
                <h4 className="footer-widget-title">About</h4>
                <ul>
                  <li>
                    <a href="https://optiwatt.applytojob.com" rel="noreferrer">
                      Careers
                    </a>
                  </li>
                  <li>
                    <a href="privacy">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="tos">Terms of Service</a>
                  </li>
                  <li>
                    <a href="faq">FAQ</a>
                  </li>
                  <li>
                    <a href="business">Business</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="footer-widget footer-address-widget">
                <h4 className="footer-widget-title">Contact</h4>
                <ul>
                  <li>
                    <img
                      src="landing-assets/thermostat/img/footer/location.png"
                      alt=""
                    />
                    <p>{optiwattConsts.contact.location}</p>
                  </li>
                  <li>
                    <a href={`mailto:${optiwattConsts.contact.email}`}>
                      <img
                        src="landing-assets/thermostat/img/footer/support.png"
                        alt=""
                      />
                      <p>{optiwattConsts.contact.email}</p>
                    </a>
                  </li>
                  <li>
                    <a href={optiwattConsts.contact.website}>
                      <img
                        src="landing-assets/thermostat/img/footer/wifi.png"
                        alt=""
                      />
                      <p>{optiwattConsts.contact.website}</p>
                    </a>
                  </li>
                  <li>
                    <a href={`tel:${optiwattConsts.contact.phoneNumber}`}>
                      <div
                        style={{
                          width: '24px',
                          height: '24px',
                          padding: '2px',
                        }}
                      >
                        <img
                          src="img/feathericons/primary/phone.webp"
                          alt="phone"
                          style={{ width: '100%' }}
                        />
                      </div>
                      <p>{optiwattConsts.contact.phoneNumber}</p>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-lg-2">
              <div className="footer-widget footer-icon-widget">
                <h4 className="footer-widget-title">Contact</h4>
                <ul>
                  <li>
                    <a href={optiwattConsts.social.facebook}>
                      <i className="fab fa-facebook"></i>
                      <p className="social-media-name">Facebook</p>
                    </a>
                  </li>
                  <li>
                    <a href={optiwattConsts.social.twitter}>
                      <i className="fab fa-twitter"></i>
                      <p className="social-media-name">Twitter</p>
                    </a>
                  </li>
                  <li>
                    <a href={optiwattConsts.social.youtube}>
                      <i className="fab fa-youtube"></i>
                      <p className="social-media-name">YouTube</p>
                    </a>
                  </li>
                  <li>
                    <a href={optiwattConsts.social.instagram}>
                      <img
                        src="landing-assets/thermostat/img/footer/ins.png"
                        alt=""
                      />
                      <p className="social-media-name">Instagram</p>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}
