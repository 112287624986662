import BrandedPage from '../../ava/BrandedPage'
import UtilityProgramPage from '../UtilityProgramPage'
import {
  BulletListItem,
  Button,
  CardAction,
  CardActions,
  LoadingAnimation,
  Modal,
  Text,
  List,
} from '@/components'
import CircleCheckbox from '@/authenticated/components/CircleCheckbox'
import InnerCard from '@/components/InnerCard/InnerCard'
import { ID } from '@/types/model'
import {
  getVehicleDisplayName,
  getVehicleManufacturedName,
} from '@/utils/CarUtils'
import { DocumentRenderer } from '@keystone-6/document-renderer'
import { AlertMessage, Link } from 'app-components'
import useViewModel from '@/app/pages/program-enrollment/enroll/useViewModel'
import { EnrollConfirmationModal } from '@/generated/graphql'
import { DeviceDescription } from '@/app/features/utility-programs/UtilityProgramEligibleDeviceEnrollmentFlowStep/UtilityProgramEligibleDeviceEnrollmentFlowStep'
import { match } from 'ts-pattern'

function ConfirmationModal(props: {
  tosConfig: EnrollConfirmationModal
  show: boolean
  onClose: () => void
  onAccept: () => void
}) {
  return (
    <Modal
      id={'confirmation-modal'}
      title={props.tosConfig?.title}
      open={props.show}
      onClose={() => props.onClose()}
    >
      {props.tosConfig.tosDocument && (
        <div className="h-[140px] overflow-y-scroll bg-base-grey-200 p-3 text-[12px]">
          <DocumentRenderer document={props.tosConfig.tosDocument} />
        </div>
      )}
      <CardActions className="mt-6">
        <CardAction type="primary">
          <Button
            variant="secondary"
            onClick={() => props.onClose()}
            id="utility-program-enrollment-prompt-enroll-back-button"
          >
            Back
          </Button>
        </CardAction>
        <CardAction type="primary">
          <Button
            variant="primary"
            onClick={() => props.onAccept()}
            id="utility-program-enrollment-prompt-enroll-confirmation-accept-button"
          >
            Accept
          </Button>
        </CardAction>
      </CardActions>
    </Modal>
  )
}

function EnrollmentSelectionGroup(props: {
  key?: ID
  checked: boolean
  onClick: (id?: ID) => void
  title: string
  subtitle: string
}) {
  return (
    <InnerCard>
      <BulletListItem
        key={props.key}
        icon={() => (
          <CircleCheckbox
            activeColor="#00D73F"
            checked={props.checked}
            style={{ padding: '0px' }}
            onClick={() => props.onClick(props.key)}
          />
        )}
      >
        <div className="max-w-[225px] sm:max-w-full">
          <Text variant="body1" className="truncate overflow-hidden w-full">
            {props.title}
          </Text>
          <Text variant="body4">{props.subtitle}</Text>
        </div>
      </BulletListItem>
    </InnerCard>
  )
}

export default function ProgramEnrollmentEnrollPage() {
  const {
    error,
    isLoading,
    isEnrolling,
    showConfirmationModal,
    selectedVehicles,
    controlledVehicles,
    passiveVehicles,
    ineligibleVehicles,
    eligibleChargers,
    selectedChargers,
    tosConfig,
    viewConfig,
    toggleVehicleEnroll,
    toggleChargerEnroll,
    closeModal,
    onEnroll,
    acceptAndEnroll,
    behavioralEnrollment,
    address,
    pageSubtitle,
    pageTitle,
    toggleBehavioralEnrollment,
    selectedBehavioralEnrollment,
    ineligibleForEnrollment,
    isSkippingEnrollment,
  } = useViewModel()

  const inEligibleVehiclesSection = (
    ineligibleVehicles: DeviceDescription[],
  ) => (
    <>
      <Text variant="body1" className="mb-4">
        Ineligible - Vehicle Limitations
      </Text>
      <List>
        {ineligibleVehicles.map((vehicle) => (
          <BulletListItem
            key={vehicle.id}
            icon={() => <Text variant="body2">•</Text>}
          >
            {vehicle.description}
            <br />
            {vehicle.name}
          </BulletListItem>
        ))}
      </List>
    </>
  )

  const ineligibleSection = (
    <>
      <Text variant="body1" className="mb-4">
        No eligible devices were found at this time.
      </Text>
      <Text variant="body4" className="mb-4">
        You can add devices at another time and still enroll in the program
        later.
      </Text>
      {ineligibleVehicles.length > 0 &&
        inEligibleVehiclesSection(ineligibleVehicles)}
    </>
  )

  const behavioralEnrollmentSelection = (
    <>
      <Text variant="body1" className="mb-4">
        Address only - must enable notifications
      </Text>
      <div className="flex flex-col gap-4 mb-5">
        <EnrollmentSelectionGroup
          title={address}
          subtitle={'All EVs and PHEVs at address'}
          onClick={toggleBehavioralEnrollment}
          checked={selectedBehavioralEnrollment}
        />
      </div>
    </>
  )

  const vehicleEnrollmentSelection = (
    <>
      {controlledVehicles?.length > 0 && (
        <>
          <Text variant="body1" className="mb-4">
            Controlled vehicles - no action needed
          </Text>
          <div className="flex flex-col gap-4 mb-5">
            {controlledVehicles.map((vehicle) => (
              <EnrollmentSelectionGroup
                key={vehicle.id}
                checked={selectedVehicles.includes(vehicle.id)}
                onClick={() => toggleVehicleEnroll(vehicle.id)}
                title={getVehicleDisplayName(vehicle)}
                subtitle={`${
                  vehicle.car?.car_model?.year
                } ${getVehicleManufacturedName(vehicle)}`}
              />
            ))}
          </div>
        </>
      )}

      {passiveVehicles?.length > 0 && (
        <>
          <Text variant="body1" className="mb-4">
            Passive vehicles - must enable notifications
          </Text>
          <div className="flex flex-col gap-4 mb-5">
            {passiveVehicles.map((vehicle) => (
              <EnrollmentSelectionGroup
                key={vehicle.id}
                checked={selectedVehicles.includes(vehicle.id)}
                onClick={() => toggleVehicleEnroll(vehicle.id)}
                title={getVehicleDisplayName(vehicle)}
                subtitle={`${
                  vehicle.car?.car_model?.year
                } ${getVehicleManufacturedName(vehicle)}`}
              />
            ))}
          </div>
        </>
      )}
    </>
  )
  const chargerEnrollmentSelection = eligibleChargers?.length > 0 && (
    <>
      <Text variant="body1" className="mb-4">
        Chargers - no action needed
      </Text>
      <div className="flex flex-col gap-4 mb-5">
        {eligibleChargers.map((charger) => (
          <EnrollmentSelectionGroup
            key={charger.id}
            checked={selectedChargers.includes(charger.id)}
            onClick={() => toggleChargerEnroll(charger.id)}
            title={charger.name ?? 'Chargepoint'}
            subtitle={'Charger'}
          />
        ))}
      </div>
    </>
  )

  const enrollmentSections = match(behavioralEnrollment)
    .with(true, () => (
      <>
        {behavioralEnrollmentSelection}
        {ineligibleVehicles.length > 0 &&
          inEligibleVehiclesSection(ineligibleVehicles)}
      </>
    ))
    .with(false, () => (
      <>
        {vehicleEnrollmentSelection}
        {chargerEnrollmentSelection}
        {ineligibleVehicles.length > 0 &&
          inEligibleVehiclesSection(ineligibleVehicles)}
      </>
    ))
    .exhaustive()

  const loadedContent = (
    <BrandedPage.Content>
      {error && (
        <AlertMessage variant="error" className="mb-5">
          <div>{error}</div>
          <div>
            You can also <Link href={'/app'}>skip enrollment for now.</Link>
          </div>
        </AlertMessage>
      )}

      {ineligibleForEnrollment && ineligibleSection}
      {!ineligibleForEnrollment && enrollmentSections}

      <BrandedPage.ButtonContainer>
        <Button id={'next-button'} onClick={onEnroll}>
          {isSkippingEnrollment
            ? 'Skip'
            : viewConfig?.enrollButton?.text ?? 'Next'}
        </Button>
      </BrandedPage.ButtonContainer>
    </BrandedPage.Content>
  )

  const enrollingContent = (
    <>
      <Text variant="h3" className="mb-5 flex flex-col items-center flex-fill">
        Enrolling devices, please wait...
      </Text>
      <LoadingAnimation type="falling" centered />
    </>
  )

  return (
    <BrandedPage
      id="utility-plan-enrollment"
      title={pageTitle}
      subtitle={pageSubtitle}
    >
      <UtilityProgramPage>
        {!isEnrolling && loadedContent}
        {isEnrolling && enrollingContent}
      </UtilityProgramPage>

      {tosConfig && (
        <ConfirmationModal
          tosConfig={tosConfig}
          onClose={closeModal}
          onAccept={() => acceptAndEnroll()}
          show={showConfirmationModal}
        />
      )}
    </BrandedPage>
  )
}
