import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import useMockableViewModel from '../../../../hooks/useMockableViewModel'
import { thermostatsCollection } from '../../../../reducers/thermostats'
import {
  selectMeetsUtilityRequirements,
  selectUserShouldTakeUtilityAction,
  selectUserVehicles,
} from '../../../../selectors'
import useHideDashboardConfig from '../../../../authenticated/hooks/useHideDashboardConfig'
import { DashboardConfigType } from '../../../../types/dashboardConfig'
import { addConfig } from '../../../../actions/dashboardConfigs'
import { surveyResponsesCollection } from '@/reducers/surveyResponses'
import { vehicleChargersCollection } from '@/reducers/vehicleChargers'
import { useOnboardingResponses } from '@/app/hooks/useOnboardingResponses'

function useViewModel() {
  const dispatch = useAppDispatch()

  const hasConnectedVehicle = useAppSelector((state) =>
    Boolean(selectUserVehicles(state).length),
  )
  const hasConnectedThermostat = useAppSelector(
    (state) => thermostatsCollection.selectors.selectTotal(state) > 0,
  )
  const hasConnectedCharger = useAppSelector(
    (state) => vehicleChargersCollection.selectors.selectTotal(state) > 0,
  )

  const {
    hasThermostat,
    hasVehicle,
    hasCharger,
    isLoading: isLoadingSurveyResponses,
  } = useOnboardingResponses()

  const { loading: loadingUtilities } = useAppSelector((state) =>
    selectUserShouldTakeUtilityAction(state),
  )
  const hasMetUtilityRequirements = useAppSelector(
    selectMeetsUtilityRequirements,
  )

  const history = useHistory()
  const navigateToStep = (route: string) => {
    history.push(route)
  }

  const wasDismissed = useHideDashboardConfig(
    DashboardConfigType.OnboardingProgress,
  )
  const loading = useAppSelector((state) => {
    const loadingVehicles = state.vehicles.isLoading
    const fetchThermostatsQueryStates =
      thermostatsCollection.selectors.queryState.select(state, {
        method: 'GET',
        pathSegments: (s?: string[]) => s?.length === 0,
      })
    const loadingThermostats = fetchThermostatsQueryStates.every(
      (queryState) => queryState.status === 'loading',
    )
    return (
      loadingVehicles ||
      loadingThermostats ||
      loadingUtilities ||
      isLoadingSurveyResponses
    )
  })

  const hasCompletedProgress =
    (hasConnectedVehicle || !hasVehicle) &&
    (hasConnectedThermostat || !hasThermostat) &&
    (hasConnectedCharger || !hasCharger) &&
    hasMetUtilityRequirements

  const profileId = useAppSelector((state) => state.user.user?.profile.id)
  const onDismiss = () => {
    if (profileId) {
      dispatch(addConfig(DashboardConfigType.OnboardingProgress, profileId))
    }
  }

  return {
    shouldHide: wasDismissed || loading || hasCompletedProgress,
    hasConnectedVehicle,
    hasConnectedThermostat,
    hasConnectedCharger,
    hasConnectedUtility: hasMetUtilityRequirements,
    hasVehicle,
    hasThermostat,
    hasCharger,
    navigateToStep,
    onDismiss,
  }
}

function useMockViewModel() {
  const [hasConnected, setHasConnected] = useState({
    '/connect-vehicle': false,
    '/connect-thermostat': false,
    '/connect-utility': false,
    '/connect/charger': false,
  })
  const navigateToStep = (route: string) => {
    setHasConnected({
      ...hasConnected,
      [route]: true,
    })
  }
  return {
    shouldHide: false,
    hasConnectedVehicle: hasConnected['/connect-vehicle'],
    hasConnectedThermostat: hasConnected['/connect-thermostat'],
    hasConnectedUtility: hasConnected['/connect-utility'],
    hasConnectedCharger: hasConnected['/connect/charger'],
    hasVehicle: true,
    hasThermostat: true,
    hasCharger: true,
    navigateToStep,
    onDismiss: () => alert('Dismissed'),
  }
}

export default useMockableViewModel({ useViewModel, useMockViewModel })
