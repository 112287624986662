import { ID } from './model'
import { Vehicle } from './vehicle'

export type TeslaFleetAuthenticationScope =
  | 'energy_device_data'
  | 'energy_cmds'
  | 'user_data'
  | 'openid'
  | 'offline_access'
  | 'vehicle_device_data'
  | 'vehicle_location'
  | 'vehicle_cmds'
  | 'vehicle_charging_cmds'

export type TeslaFleetAuthentication = {
  id: ID
  // Returned from GET
  authorize_url?: string
  public_key_url?: string
  // Passed to POST
  code?: string
  // Returned from POST
  vehicles?: Vehicle[]
  scopes?: {
    selected: TeslaFleetAuthenticationScope[]
    missing: TeslaFleetAuthenticationScope[]
  }
}

export enum TeslaFleetAuthenticationScopeName {
  energy_device_data = 'Energy Product Information',
  energy_cmds = 'Energy Product Settings',
  user_data = 'Profile Information',
  openid = 'Open ID',
  offline_access = 'Offline Access',
  vehicle_device_data = 'Vehicle Information',
  vehicle_location = 'Vehicle Location',
  vehicle_cmds = 'Vehicle Commands',
  vehicle_charging_cmds = 'Vehicle Charging Management',
}

export const TeslaFleetAuthenticationScopeNames = Object.keys(
  TeslaFleetAuthenticationScopeName,
) as TeslaFleetAuthenticationScope[]
