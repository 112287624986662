import {
  FetchArgs,
  RequestArgs,
  ResourceApiConfig,
  ResourceModel,
} from './types'
import { buildRequestUrl, getBaseUrl } from './utils'

/**
 * This is the resource collection config that is exposed through the instantiated resource
 * collection object
 * @returns {
 *   - baseUrl {string} The base URL used by the resouce collection
 *   - getRequestUrl {Function}  A function for building request urls like they are built internally by the collection.
 *    useful for testing.
 *    example `myCollection.config.getRequestUrl({ path: 'path/to/my/resource/1/' })`
 *   - ...apiConfig: {ResourceApiConfig} the rest of the api config passed to the resource collection, or
 *   populated by default
 * }
 */
export default function createExternalConfig<Resource extends ResourceModel>(
  apiConfig: ResourceApiConfig<Resource>,
) {
  const getRequestUrl = (args: FetchArgs | RequestArgs = {}) =>
    buildRequestUrl(apiConfig, args)

  return {
    baseUrl: getBaseUrl(apiConfig),
    getRequestUrl,
    ...apiConfig,
  }
}
