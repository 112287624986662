import { PayloadAction } from '@reduxjs/toolkit'
import * as actions from '../actions/dashboardConfigs'

type State = {
  success: boolean | null
  isLoading: boolean
}

const initialState: State = {
  success: null,
  isLoading: false,
}

export default (state = initialState, action: PayloadAction<any>): State => {
  switch (action.type) {
    case actions.CONFIG_POST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
      }
    case actions.CONFIG_POST_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case actions.CONFIG_POST_FAILURE:
      return {
        ...state,
        isLoading: false,
        success: false,
      }
    default:
      return state
  }
}
