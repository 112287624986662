import React from 'react'
import { useSelector } from 'react-redux'
import { Button, Grid, IconButton, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { RefreshCw } from 'react-feather'
import config from '../../../config'

function CommandButton(props) {
  const [loading, setLoading] = React.useState(false)

  return (
    <Button onClick={props.sendCommand} variant="outlined">
      {props.command}
    </Button>
  )
}

export default function AuthenticationToken({ profileID = '' }) {
  const [commandResponse, setCommandResponse] = React.useState('')
  const [commandResponseOpen, setCommandResponseOpen] = React.useState(false)
  const [dataLoading, setDataLoading] = React.useState(false)
  const [resetAuthenticationData, setResetAuthenticationData] = React.useState(
    {},
  )
  const [refreshAuthenticationData, setRefreshAuthenticationData] =
    React.useState({})
  const [authenticationData, setAuthenticationData] = React.useState({})
  const accessToken = useSelector((state) => state.auth.access)

  if (!accessToken) {
    return null
  }

  function tryAuthentication() {
    setDataLoading(true)
    fetch(`${config.apiGateway.URL}/api/try-authentication/${profileID}/`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken.token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => setAuthenticationData(res))
      .catch((err) => alert(err))
      .finally(() => setDataLoading(false))
  }

  function tryResetAuthentication() {
    setDataLoading(true)
    fetch(
      `${config.apiGateway.URL}/api/try-reset-authentication/${profileID}/`,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken.token}`,
        },
      },
    )
      .then((res) => res.json())
      .then((res) => setResetAuthenticationData(res))
      .catch((err) => alert(err))
      .finally(() => setDataLoading(false))
  }

  function tryRefreshAuthentication() {
    setDataLoading(true)
    fetch(
      `${config.apiGateway.URL}/api/try-refresh-authentication/${profileID}/`,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken.token}`,
        },
      },
    )
      .then((res) => res.json())
      .then((res) => setRefreshAuthenticationData(res))
      .catch((err) => alert(err))
      .finally(() => setDataLoading(false))
  }

  return (
    <>
      <Grid
        container
        spacing={1}
        justifyContent="space-evenly"
        alignItems="stretch"
      >
        <Grid item xl={4} lg={4}>
          <h1>
            Test Authentication
            <IconButton onClick={tryAuthentication}>
              <RefreshCw />
            </IconButton>
          </h1>
          {dataLoading ? (
            'Loading...'
          ) : (
            <div>
              <pre>{JSON.stringify(authenticationData, null, 2)}</pre>
            </div>
          )}
        </Grid>
        <Grid item xl={4} lg={4}>
          <h1>
            Test Reset Authentication
            <IconButton onClick={tryResetAuthentication}>
              <RefreshCw />
            </IconButton>
          </h1>
          {dataLoading ? (
            'Loading...'
          ) : (
            <div>
              <pre>{JSON.stringify(resetAuthenticationData, null, 2)}</pre>
            </div>
          )}
        </Grid>
        <Grid item xl={4} lg={4}>
          <h1>
            Test Refresh Authentication
            <IconButton onClick={tryRefreshAuthentication}>
              <RefreshCw />
            </IconButton>
          </h1>
          {dataLoading ? (
            'Loading...'
          ) : (
            <div>
              <pre>{JSON.stringify(refreshAuthenticationData, null, 2)}</pre>
            </div>
          )}
        </Grid>
      </Grid>
      <Snackbar
        open={commandResponseOpen}
        autoHideDuration={6000}
        onClose={() => setCommandResponseOpen(false)}
      >
        <Alert onClose={() => setCommandResponseOpen(false)} severity="success">
          {commandResponse}
        </Alert>
      </Snackbar>
    </>
  )
}
