import { useParams } from 'react-router-dom'
import { useAppSelector } from '@/hooks'
import { selectUserUtility } from '@/selectors'
import { utilityProgramCollection } from '@/reducers/utilityPrograms'
import { RequestStatus } from '@/request/types'
import { utilityProgramProfileEligibilityCollection } from '@/reducers/utilityProgramProfileEligibility'

export default function useProgramFromParams() {
  const { programId } = useParams<{ programId: string }>()
  const utility = useAppSelector(selectUserUtility)
  const { data: eligibilityResponse, status: eligibilityResponseStatus } =
    utilityProgramProfileEligibilityCollection.useFetch(programId, {
      require: !!programId,
    })
  const { data: utilityProgramResponse, status: programResponseStatus } =
    utilityProgramCollection.useFetch(programId, {
      require: eligibilityResponse?.[0]?.profile_result?.is_eligible,
    })

  const isLoading = [eligibilityResponseStatus, programResponseStatus].some(
    (status) => [RequestStatus.Idle, RequestStatus.Loading].includes(status),
  )

  return {
    isLoading,
    utility,
    isEligible: eligibilityResponse?.[0]?.profile_result?.is_eligible ?? null,
    utilityProgram: utilityProgramResponse?.[0] ?? null,
    eligibility: eligibilityResponse?.[0],
    programId,
  }
}
