import { useEffect, useState } from 'react'
import useMockableViewModel from '@/hooks/useMockableViewModel'
import { useHistory } from 'react-router-dom'
import { QuestionnairePage, QuestionnairePageValue } from 'app-components'
import { Identifiable, User } from '@/types'
import { useDispatch } from 'react-redux'
import { addHome } from '@/actions/homes'
import { getUtilities } from '@/actions/utilities'
import { getPlans } from '@/actions/plans'
import { getCurrentUser, USER_SUCCESS } from '@/actions/user'
import { dismissInitialMobileUrl } from '@/utils/messageUtils'
import {
  pushToQuestionnaireResponse,
  shouldNavigateUserToProgramOnboarding,
} from './utils'
import { PayloadAction } from '@reduxjs/toolkit'
import { useNavigation } from '@/app/hooks'

export interface Props extends Identifiable {
  // No additional props provided
  pages: QuestionnairePage[]
  pageNumber?: number
  onNextClick?: (responses: QuestionnairePageValue[]) => void
  onSubmit: (responses: QuestionnairePageValue[]) => void
  showSkipModalId: string | null
  setShowSkipModalId: (value: string | null) => void
  showBackButton?: boolean
  isLoading?: boolean
  isSubmitting?: boolean
}

export function useViewModel(props: Props) {
  const dispatch = useDispatch()
  const [pageNumber, setPageNumber] = useState(props.pageNumber ?? 0)
  useEffect(() => {
    if (props.isLoading || props.isSubmitting) {
      return
    }
    setPageNumber(props.pageNumber ?? 0)
  }, [props.pageNumber])
  const [values, setValues] = useState([] as QuestionnairePageValue[])
  const [showSkipModalId, setShowSkipModalId] = useState<string | null>(null)
  const history = useHistory()
  const navigation = useNavigation()
  const pages = props.pages
    .filter((page) => {
      const shouldShowPredicate = page.shouldShow ?? (() => true)
      return shouldShowPredicate(values)
    })
    .map((page) => {
      const showButtonsPredicate = page.showButtons ?? (() => page.buttons)
      return {
        ...page,
        buttons: showButtonsPredicate(values),
      }
    })
  const addressPageNumber = props.pages.findIndex(
    (page) => page.key === 'homeAddress',
  )

  function insertQuestionnaireResponse(value: QuestionnairePageValue['value']) {
    setValues((values) =>
      pushToQuestionnaireResponse(value, values, pages, pageNumber),
    )
  }

  function onBackClick() {
    if (pageNumber - 1 < 0) {
      const backTo = new URLSearchParams(window.location.search).get('backTo')
      if (backTo) {
        navigation.push(backTo)
      } else {
        history.goBack()
      }
      return
    }
    setPageNumber((value) => {
      return value - 1
    })
  }

  async function onNextClick(setDisabled: (value: boolean) => void) {
    setShowSkipModalId(null)
    const onLastPage = pageNumber + 1 > pages.length - 1
    if (onLastPage) {
      // filter out any values that are associated with pages that were skipped over
      // because of the shouldShow predicate
      const valuesWithSkippedPagesFilteredOut = values.filter((value) =>
        pages.some((page) => page.key === value.key),
      )
      props.onSubmit(valuesWithSkippedPagesFilteredOut)
    } else {
      if (addressPageNumber === pageNumber) {
        const googlePlaceId = values[addressPageNumber]?.value?.[0]
        const location = values[addressPageNumber]?.value?.[1] as unknown as {
          lat: number
          lng: number
        }
        if (googlePlaceId) {
          const form = { google_place_id: googlePlaceId } as {
            google_place_id: string
            location?: { lat: number; lng: number }
          }
          if (location) {
            form.location = location
          }
          setDisabled(true)
          await dispatch(addHome(form))
          const [, , _userRes] = await Promise.all([
            dispatch(getUtilities()),
            dispatch(getPlans()),
            dispatch(getCurrentUser()),
          ])

          // @ts-expect-error not sure whats going on here but ts thinks the type is a dispatch function
          const userRes = _userRes as PayloadAction<User>

          if (userRes.type === USER_SUCCESS) {
            const { shouldNavigate, destination } =
              shouldNavigateUserToProgramOnboarding(userRes.payload)

            if (shouldNavigate) {
              navigation.push(destination)
            }
          }

          setDisabled(false)
        }
      }
      const thisPagesValues = values[pageNumber]
      insertQuestionnaireResponse(thisPagesValues.value)
      props?.onNextClick?.([thisPagesValues])
      setPageNumber((value) => value + 1)
      // navigation.pushAddParam('pageNumber', (pageNumber + 1).toString())
    }

    // hack for mobile location issues
    if (props.id === 'onboarding-questionnaire' && pageNumber === 0) {
      dismissInitialMobileUrl()
    }
  }

  function onSkipClick() {
    setShowSkipModalId(null)
    setPageNumber((value) => value + 1)

    // hack for mobile location issues
    if (props.id === 'onboarding-questionnaire' && pageNumber === 0) {
      dismissInitialMobileUrl()
    }
  }

  // Check if we should show a back button (when on first page with backTo param)
  const hasBackToParam = !!new URLSearchParams(window.location.search).get(
    'backTo',
  )
  const showBackButton = pageNumber > 0 || hasBackToParam

  return {
    ...props,
    pages,
    pageNumber,
    setPageNumber,
    onNextClick,
    onBackClick,
    onSkipClick,
    setValues,
    showSkipModalId,
    setShowSkipModalId,
    values,
    insertQuestionnaireResponse,
    showBackButton,
  }
}

function useMockViewModel(props: Props) {
  const [pageNumber, setPageNumber] = useState(0)
  const [values, setValues] = useState([] as QuestionnairePageValue[])

  function onBackClick(page?: number) {
    if (page === 0) return console.log('Previous route')
    setPageNumber((value) => value - 1)
  }

  async function onNextClick() {
    setPageNumber((value) => value + 1)
  }

  function onSkipClick() {
    setPageNumber((value) => value + 1)
  }

  // Include showBackButton in mock data
  const hasBackToParam = !!new URLSearchParams(window.location.search).get(
    'backTo',
  )
  const showBackButton = pageNumber > 0 || hasBackToParam

  return {
    ...props,
    pageNumber,
    setPageNumber,
    onNextClick,
    onBackClick,
    onSkipClick,
    setValues,
    showSkipModalId: null,
    setShowSkipModalId: () => {},
    values,
    insertQuestionnaireResponse: (value: QuestionnairePageValue['value']) => {},
    showBackButton,
  }
}

export default useMockableViewModel({ useViewModel, useMockViewModel })
