import { CardActions, CardAction, CardNavigation } from '@/components'
import {
  AlertMessage,
  ConfigurableButton,
  ConfigurableText,
  FlowStep,
} from '@/app/components'
import type { Props } from '../UtilityProgramEnrollmentFlow/UtilityProgramEnrollment'
import { useAppSelector } from '@/hooks'
import { utilityProgramEnrollmentCollection } from '@/reducers/utilityProgramEnrollments'
import { useProgramSpecificConfigFromUrlParam } from '@/app/hooks/useProgramSpecificConfigFromUrlParam'
import { UtilityProgramEnrollment } from '@/types'
import { useState } from 'react'
import useExternalNavigation from '@/app/hooks/useExternalNavigation'

export default function SigninStep(props: Omit<Props, 'onEnrollClick'>) {
  const [logInError, setLogInError] = useState<string | null>(null)
  const utilityProgramEnrollments = useAppSelector(
    utilityProgramEnrollmentCollection.selectors.selectAll,
  )
  const utilityProgramEnrollment = utilityProgramEnrollments?.[0] as
    | UtilityProgramEnrollment
    | undefined

  const { externallyNavigate } = useExternalNavigation()

  const { config } = useProgramSpecificConfigFromUrlParam(
    utilityProgramEnrollment?.utility_program,
  )
  const onClick = () => {
    if (
      !utilityProgramEnrollment ||
      !utilityProgramEnrollment.external_redirect_url
    ) {
      setLogInError(
        "There was an issue directing you to the utility's website. Please try again later or contact support if the issue persists",
      )
      return
    }
    externallyNavigate(utilityProgramEnrollment.external_redirect_url)
    props.onConnect?.()
  }

  const onBackClick = () => {
    props.onBack?.()
  }

  const AwaitLogo = () => (
    <div className="flex bg-themed-base-400 aspect-square items-center justify-center gap-1 rounded-full w-16">
      <div className="bg-themed-white rounded-full aspect-square h-1.5" />
      <div className="bg-themed-white rounded-full aspect-square h-1.5" />
      <div className="bg-themed-white rounded-full aspect-square h-1.5" />
    </div>
  )

  return (
    <FlowStep
      id="signin-flow-step"
      className="h-full"
      gridClassName="!grid-rows-none"
    >
      <FlowStep.Content>
        <CardNavigation
          onBackClick={onBackClick}
          id="utility-program-enrollment-prompt-navigation"
        />
        <div className="flex flex-col justify-center items-center h-full gap-8">
          <AwaitLogo />
          <ConfigurableText variant="h2" className="text-center w-3/4">
            To complete your enrollment, please log into your{' '}
            {config.programName} account.
          </ConfigurableText>
          <AlertMessage variant="error" show={Boolean(logInError)}>
            {logInError ?? 'There was an issue.'}
          </AlertMessage>
        </div>
      </FlowStep.Content>
      <FlowStep.Actions>
        <CardActions>
          <CardAction type="primary">
            <ConfigurableButton
              id="utility-program-enrollment-prompt-primary-button"
              onClick={onClick}
            >
              <span>Log in to {config.programName}</span>
            </ConfigurableButton>
          </CardAction>
        </CardActions>
      </FlowStep.Actions>
    </FlowStep>
  )
}
