import styled from 'styled-components'
import {
  makeStyles,
  Typography as MuiTypography,
  Box as MuiBox,
  useTheme,
  IconButton,
} from '@material-ui/core'
import { spacing } from '@material-ui/system'
import { Plus } from 'react-feather'
import { alpha } from '@mui/material'

const Typography = styled(MuiTypography)(spacing)
const Box = styled(MuiBox)(spacing)

const PADDING = 20

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  buttonBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: (props) => `${props.size + PADDING}px`,
    height: (props) => `${props.size + PADDING}px`,
    borderRadius: '8px',
    backgroundColor: '#F2F2F2',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: alpha(theme.palette.blue.main, 0.15),
    },
  },
  text: {
    cursor: 'pointer',
    fontSize: '12px',
    paddingLeft: '16px',
    fontWeight: '700',
  },
}))

const IconLabelButton = ({ onClick, iconComponent, text, size = 16 }) => {
  const classes = useStyles({ size })
  const theme = useTheme()

  return (
    <Box className={classes.wrapper}>
      <IconButton className={classes.buttonBox} onClick={onClick}>
        {iconComponent || <Plus size={size} color={theme.palette.blue.main} />}
      </IconButton>
      <Typography onClick={onClick} className={classes.text} variant="body1">
        {text || 'Add another'}
      </Typography>
    </Box>
  )
}

export default IconLabelButton
