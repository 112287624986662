import { combineReducers } from 'redux'
import GasSavingsDialog from '../authenticated/components/GasSavingsDialog/reducer'
import SurveyObserver from '@/app/components/SurveyObserver/reducer'
import TeslaGiveawayDashboardView from '../authenticated/components/TeslaGiveawayDashboardView/reducer'

export default combineReducers({
  GasSavingsDialog,
  SurveyObserver,
  TeslaGiveawayDashboardView,
})
