import { RootState } from '@/reducers'
import { Vehicle, VehicleEnrollment } from '@/types'
import { selectVehicleEnrollmentForUser } from '@/selectors'
import { enrollmentIsUnenrolled } from '@/types/utilityProgramEnrollment'
import { useAppSelector } from '@/hooks/index'

const getEnrolledVehicleEnrollment = (
  state: RootState,
  vehicleId: number,
): VehicleEnrollment | undefined => {
  const enrollment = selectVehicleEnrollmentForUser(state, vehicleId)
  if (!enrollment || enrollmentIsUnenrolled(enrollment)) {
    return undefined
  }
  return enrollment
}

const getUtilityAlertSubtitle = (
  isFromPartner: boolean,
  enrolledVehicleEnrollment: VehicleEnrollment | undefined,
): string | undefined => {
  if (isFromPartner) {
    return 'You must allow Optiwatt to manage charging in order to participate in this program.'
  }
  return enrolledVehicleEnrollment?.view_config_json?.scheduleCharging?.subtitle
}

const getEnrollmentChargeControl = (
  isFromPartner: boolean,
  enrolledVehicleEnrollment: VehicleEnrollment | undefined,
): boolean | null | undefined => {
  if (isFromPartner) {
    return true
  }
  return enrolledVehicleEnrollment?.group?.allow_charging_control
}

export type UtilityAlert = {
  subtitle: string
  forceChargeControl: boolean
}

export default function useUtilityAlerts(
  vehicle: Vehicle | undefined,
): UtilityAlert {
  const enrolledVehicleEnrollment = useAppSelector((state: RootState) =>
    getEnrolledVehicleEnrollment(state, vehicle?.id ?? -1),
  )
  const isFromPartner =
    useAppSelector((state: RootState) => state.auth.fromToken) ?? false

  const forceChargeControl = getEnrollmentChargeControl(
    isFromPartner,
    enrolledVehicleEnrollment,
  )

  return {
    subtitle:
      getUtilityAlertSubtitle(isFromPartner, enrolledVehicleEnrollment) ?? '',
    forceChargeControl: forceChargeControl ?? false,
  }
}
