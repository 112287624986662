import { selectUtilityProgramEnrollmentForUser } from '@/reducers/utilityProgramEnrollments'
import { selectUtilityProgramForUserUtility } from '@/reducers/utilityPrograms'
import { RootState } from '@/store'
import { ID } from '@/types/model'
import { createSelector } from '@reduxjs/toolkit'

const selectUserVehicleChargerIsProgramEligible = createSelector(
  [
    (state: RootState) =>
      selectUtilityProgramForUserUtility(state)?.device_eligibility
        ?.eligible_charger_ids,
    (state: RootState) =>
      selectUtilityProgramEnrollmentForUser(state)?.utility_program
        ?.device_eligibility?.eligible_charger_ids,
    (state: RootState, chargerId?: ID) => chargerId,
  ],
  (
    eligibleChargerIdsFromProgram,
    eligibleChargerIdsFromEnrollmentProgram,
    chargerId,
  ) => {
    if (!chargerId) {
      return false
    }

    return [
      ...(eligibleChargerIdsFromProgram ?? []),
      ...(eligibleChargerIdsFromEnrollmentProgram ?? []),
    ].includes(chargerId)
  },
)

export default selectUserVehicleChargerIsProgramEligible
