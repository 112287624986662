import { Text, TextProps } from '@/components'
import { ViewConfigText } from '@/types/utilityProgram'
import { sanitizeHtmlContent } from '@/utils/components'

export interface Props extends TextProps {
  config?: ViewConfigText
}

export default function ConfigurableText(props: Props) {
  const { children, config, ...textProps } = props

  // If config is a string, just use the string as the text content
  if (typeof config === 'string') {
    return (
      <Text
        {...textProps}
        dangerouslySetInnerHTML={{ __html: sanitizeHtmlContent(config) }}
      />
    )
  }

  const { text: configuredText, ...styles } = config ?? {}

  // Apply configurable props to the text component
  const configuredProps = {
    ...textProps,
    style: {
      ...styles,
      ...textProps.style,
    },
  }

  // If the text was provided in the config, use it
  if (configuredText) {
    return (
      <Text
        {...configuredProps}
        dangerouslySetInnerHTML={{
          __html: sanitizeHtmlContent(configuredText),
        }}
      />
    )
  }

  // Otherwise, no text was provided, so just render the children as-is
  return <Text {...configuredProps}>{children}</Text>
}
