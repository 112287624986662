import { useEffect, useState } from 'react'
import { remoteConfig, RemoteConfig } from '@/firebase'

export enum RemoteConfigLoadingStatus {
  Idle,
  Loading,
  Success,
  Failed,
}

/**
 * Custom hook to manage and observe the state of Firebase Remote Config in a React application.
 * It listens for `ready` and `error` events emitted by the `remoteConfig` instance
 * and updates the hook's state to reflect the current loading status.
 *
 * @returns {Object} An object containing:
 * - `isLoading` {boolean}: Indicates if the remote config is still loading.
 * - `isFailed` {boolean}: Indicates if the remote config failed to load.
 * - `error` {string}: The error message if the remote config failed to load.
 * - `status` {RemoteConfigLoadingStatus}: The current loading status of the remote config.
 * - `appRemoteConfig` {RemoteConfig | null}: The instance of the remote config if available, or null.
 */
export default function useRemoteConfig() {
  const [status, setStatus] = useState(
    remoteConfig?.ready
      ? RemoteConfigLoadingStatus.Success
      : RemoteConfigLoadingStatus.Idle,
  )
  const [error, setError] = useState<string | null>(null)
  const [appRemoteConfig, setAppRemoteConfig] = useState<RemoteConfig | null>(
    remoteConfig ?? null,
  )

  const handleReady = () => {
    setError(null)
    setStatus(RemoteConfigLoadingStatus.Success)
    setAppRemoteConfig(remoteConfig)
  }

  const handleError = (err: string) => {
    setError(err)
    setStatus(RemoteConfigLoadingStatus.Failed)
    setAppRemoteConfig(null)
  }

  useEffect(() => {
    if (remoteConfig) {
      remoteConfig.eventEmitter.on('ready', handleReady)
      remoteConfig.eventEmitter.on('error', handleError)
    }

    return () => {
      remoteConfig?.eventEmitter.off('ready', handleReady)
      remoteConfig?.eventEmitter.off('error', handleError)
    }
  }, [remoteConfig])

  return {
    isLoading:
      status === RemoteConfigLoadingStatus.Loading ||
      status === RemoteConfigLoadingStatus.Idle,
    isFailed: status === RemoteConfigLoadingStatus.Failed,
    error,
    status,
    appRemoteConfig,
  }
}
