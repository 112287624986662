import { useCallback, useEffect, useState } from 'react'
import { authErrors, authLoading, refreshTokenIsValid } from '@/reducers'
import { Redirect } from 'react-router'
import { signUp } from '@/actions/auth'
import {
  useAppDispatch,
  useAppSelector,
  useUrlSearchParam,
  useValidatePassword,
} from '@/hooks'
import { Button, FormInputWithError, Icon } from '@/components'
import { Link } from '@mui/material'
import { AnimatePresence, motion } from 'framer-motion'
import Header from '../../Header/HeaderV2'
import './styles.scss'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { remoteConfig } from '@/firebase'
import {
  redirectToAppleOAuthURL,
  redirectToGoogleOAuthURL,
} from '@/utils/oauth/oauthUtils'
import { isNativeMobile } from '@/authenticated/hooks/useIsMobile'
import { logEvent } from '@/logging'
import { logException, logMessage } from '@/logging/sentry'
import { useWhiteLabelData } from '@/hooks/whitelabel/useWhiteLabelData'
import useBasePath from '@/hooks/useBasePath'
import PageLoader from '@/authenticated/components/layout/PageLoader'
import useRemoteConfig from '@/hooks/useRemoteConfig'
import referralUtils from '@/utils/referralUtils/referralUtils'

export const HeroSection = () => {
  const basePath = useBasePath() ?? ''
  const paramReferralCode = useUrlSearchParam('referral_code') as string
  const {
    whiteLabelData,
    isLoading: loadingTheme,
    viewConfig,
  } = useWhiteLabelData()
  const {
    appRemoteConfig,
    isLoading: remoteConfigLoading,
    error: remoteConfigError,
  } = useRemoteConfig()
  const programId = appRemoteConfig?.getWhitelabelId(basePath) ?? null
  const useCaptcha = !appRemoteConfig?.getBoolean('hide_signup_captcha')

  const dispatch = useAppDispatch()
  const [liveErrors, checkLiveErrors] = useValidatePassword()
  const [triedSubmit, setTriedSubmit] = useState(false)
  const [user, setUser] = useState({
    name: '',
    username: '',
    password: '',
    referral_code: paramReferralCode ?? '',
    utility_program_id: programId,
    captcha: '',
  })

  const { executeRecaptcha } = useGoogleReCaptcha()
  const [captchaLoading, setCaptchaLoading] = useState(false)

  const getCaptchaToken = useCallback(async () => {
    if (!executeRecaptcha) {
      // recaptcha not yet ready
      return null
    }

    return await executeRecaptcha('signup')
  }, [executeRecaptcha])

  useEffect(() => {
    logEvent('start_sign_up')
  }, [])

  useEffect(() => {
    if (remoteConfigError) {
      logException(
        `Failed to load remote config on signup page! Error: ${remoteConfigError}`,
      )
    }
  }, [remoteConfigError])

  const errors = useAppSelector((state) => authErrors(state))
  const authenticated = useAppSelector((state) => refreshTokenIsValid(state))
  const loading = useAppSelector((state) => authLoading(state))

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    if (name === 'password') {
      checkLiveErrors(value)
      // reset pass confirm if no pass
      if (!value) {
        setUser((prevUser) => ({
          ...prevUser,
          passwordConfirm: '',
        }))
      }
    }

    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }))
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    let captchaToken = ''
    if (useCaptcha) {
      setCaptchaLoading(true)
      captchaToken = (await getCaptchaToken()) ?? ''
      setCaptchaLoading(false)
    }

    setTriedSubmit(true)
    if (
      user.name &&
      user.username &&
      user.username.includes('@') &&
      user.password &&
      checkLiveErrors(user.password)
    ) {
      if (!user.utility_program_id) {
        user.utility_program_id =
          appRemoteConfig?.getWhitelabelId(basePath) ?? null
        if (user.utility_program_id) {
          logMessage(
            `Program id was found to be null then successfully loaded on second try upon submit: ${user.utility_program_id}`,
          )
        }
      }
      if (!user.utility_program_id && user.referral_code !== '') {
        const programId = referralUtils.getWhiteLabelProgramIdFromReferralCode(
          user.referral_code,
        )
        if (programId) {
          user.utility_program_id = programId
        }
      }

      dispatch(signUp({ ...user, captcha: captchaToken }))
    }
  }

  const continueWithGoogle = async () => {
    logEvent('signUp')
    await redirectToGoogleOAuthURL()
  }

  const continueWithApple = async () => {
    logEvent('signUp')
    await redirectToAppleOAuthURL()
  }
  if (authenticated) {
    logEvent('signUp')
    return <Redirect to="/app" />
  }

  const { name, username, password, referral_code } = user
  const validErrors = errors ?? {}
  const validLoading = typeof loading !== 'undefined' && loading

  const nameInvalid = triedSubmit && !name
  const usernameInvalid = triedSubmit && (!username || !username.includes('@'))
  const passwordInvalid = liveErrors.length > 0 || (triedSubmit && !password)

  const LoginLink = (
    <p className="text-[#0A1C53] text-[16px] md:text-[20px] mb-0 font-medium">
      Already have an account?{' '}
      <Link style={{ color: '#426FFB', fontWeight: 700 }} href="/login">
        Login
      </Link>
    </p>
  )

  const showSsoLinks = basePath === 'signup'

  if (loadingTheme || remoteConfigLoading) {
    return <PageLoader />
  }

  return (
    <div className="signup-root flex flex-col items-center bg-[#EEF2FB] min-h-[100vh]">
      <div className="flex flex-col justify-center w-[90%] max-w-5xl min-h-[100vh]">
        {whiteLabelData?.brandLogo && (
          <div className="flex justify-center">
            <img
              className="max-w-[70px] max-h-[70px] mt-4 mb-1"
              src={whiteLabelData.brandLogo}
            />
          </div>
        )}
        <div
          className="flex flex-wrap md:flex-nowrap justify-between pt-8 pb-6 px-5 md:px-12 md:pt-12 md:pb-8 my-3
          bg-white rounded-[20px] shadow-[0_20px_52px_0_rgba(1,50,107,0.16)]"
        >
          <div className="flex flex-col justify-between basis-full md:basis-[45%] mb-8 md:mb-0">
            <div>
              <Header className="mb-4" />
              <h1 className="text-themed-primary font-bold md:font-extrabold leading-10 md:leading-[3.625rem] mb-0">
                Create Your Account.
              </h1>
              <h5 className="md:hidden text-themed-primary text-[16px] md:text-[18px] m-0 font-bold mt-4 mx-1">
                Free forever. No gimmicks.
              </h5>
              <p className="text-[#0a1c53] font-medium text-[16px] md:text-[20px] leading-5 md:leading-7 m-1">
                {viewConfig?.signupPage?.signupText ??
                  'You’re 3 minutes away from joining over 60,000 users saving an average $388 / year!'}
              </p>
            </div>
            <img
              src="img/signup-image.webp"
              alt="Charging Vehicle"
              width={311}
              className="self-center hidden md:block"
            />
          </div>
          <div className="flex flex-col justify-between basis-full md:basis-[47%] md:ml-2">
            <AnimatePresence>
              {(validErrors?.detail as string[]) && (
                <motion.div
                  initial={{ height: 0 }}
                  animate={{ height: 'auto' }}
                  exit={{ height: 0 }}
                  className="overflow-hidden text-themed-error bg-themed-error/20 p-3 pl-4 mb-6 font-semibold rounded-md max-sm:text-sm"
                >
                  {validErrors.detail as string[]}
                </motion.div>
              )}
            </AnimatePresence>
            <form action="post" noValidate onSubmit={handleSubmit}>
              <FormInputWithError
                type="text"
                name="name"
                placeholder="Full Name"
                data-testing-id="signup-name"
                onChange={handleChange}
                value={name}
                error={nameInvalid}
              />
              <FormInputWithError
                type="email"
                name="username"
                placeholder="Email"
                data-testing-id="signup-email"
                onChange={handleChange}
                value={username}
                error={usernameInvalid}
                errorMessage={
                  triedSubmit && username && !username.includes('@')
                    ? 'Invalid email'
                    : undefined
                }
              />
              <FormInputWithError
                type="password"
                name="password"
                placeholder="Password"
                data-testing-id="signup-password"
                onChange={handleChange}
                errorMessage={liveErrors.length > 0 ? liveErrors : undefined}
                value={password}
                error={passwordInvalid}
              />
              <FormInputWithError
                type="text"
                name="referral_code"
                placeholder="Referral Code (Optional)"
                data-testing-id="referral-code"
                onChange={handleChange}
                defaultValue={paramReferralCode}
              />
              <div className="flex flex-wrap mt-3 md:flex-nowrap">
                <div className="items-center order-2 hidden mt-4 ml-4 md:flex basis-full md:basis-9/12 md:order-1 md:m-0">
                  <h5 className="text-themed-primary text-[16px] md:text-[18px] m-0 font-bold">
                    Free forever. No gimmicks.
                  </h5>
                </div>
                <div className="order-1 basis-full md:basis-3/12 md:order-2">
                  <Button
                    className="!bg-themed-primary text-[18px] font-semibold py-3 px-5 rounded-lg w-[100%] font-[montserrat]"
                    id="optiwatt-signup-button"
                    variant="primary"
                    type="submit"
                    disabled={
                      validLoading || !executeRecaptcha || captchaLoading
                    }
                  >
                    Continue
                  </Button>
                </div>
              </div>
              {showSsoLinks && (
                <div className="flex flex-col gap-4 mt-4">
                  <div className="grid grid-cols-[1fr_50px_1fr]">
                    <div
                      className="border-solid border-b-themed-base-400 h-[13px] -mt-[5px]"
                      style={{ borderBottomWidth: '1px' }}
                    />
                    <div className="flex items-center justify-center font-bold text-xs text-[#424F76]">
                      Or
                    </div>
                    <div
                      className="border-solid border-b-themed-base-400 h-[13px] -mt-[5px]"
                      style={{ borderBottomWidth: '1px' }}
                    />
                  </div>
                  <ContinueWithButton
                    onClick={continueWithGoogle}
                    icon={<Icon name="Google" size={22} />}
                  />
                  <ContinueWithButton
                    onClick={continueWithApple}
                    icon={<Icon name="Apple" size={22} />}
                  />
                </div>
              )}
            </form>
            <div>
              {!isNativeMobile() && (
                <div className="mt-6 md:mt-9 pl-4 border-l border-solid border-[#c9d1e7]">
                  <p className="text-[12px] md:text-[16px] font-medium text-[#0a1c53] m-0">
                    <Link
                      className="!text-[#426ffb] !font-bold"
                      href="/privacy"
                    >
                      Privacy Policy
                    </Link>{' '}
                    and{' '}
                    <Link className="!text-[#426ffb] !font-bold" href="/tos">
                      Terms of Service
                    </Link>
                  </p>
                </div>
              )}
              <div className="hidden mt-6 md:block">{LoginLink}</div>
            </div>
          </div>
        </div>
        <div className="mb-4 self-start ml-[6%] md:hidden">{LoginLink}</div>
      </div>
    </div>
  )
}

function ContinueWithButton({
  onClick,
  icon,
}: {
  onClick: () => Promise<void>
  icon: React.ReactNode
}) {
  return (
    <div
      onClick={onClick}
      className="flex flex-row items-center justify-center gap-2 p-3 text-lg font-semibold rounded-lg cursor-pointer hover:bg-themed-grey-200 text-themed-grey-900 bg-themed-grey-100"
    >
      Continue with
      {icon}
    </div>
  )
}
