import './styles.scss'
import formatClasses from '../../utils/classes/formatClasses'
import { HTMLAttributes } from 'react'

type Variant = 'primary' | 'secondary' | 'navigation'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  type: 'primary' | 'secondary' | 'navigation'
}

export default function CardActionButton({
  children,
  type,
  className,
  ...props
}: Props) {
  const classes = (actionVariant: Variant) =>
    formatClasses(['card-action', actionVariant, className])

  return (
    <div className={classes(type)} {...props}>
      {children}
    </div>
  )
}
