import React from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from '../../../v2/theme'
import { ViewModelComponentProps } from '../../../utils/typeUtilities'
import UtilityConnectRedirect, {
  Props as ComponentProps,
} from './UtilityConnectRedirect'
import useViewModel from './useViewModel'

type Props = ViewModelComponentProps<ComponentProps, typeof useViewModel>

export default ({ ...props }: Props) => {
  const viewModel = useViewModel()

  return (
    <ThemeProvider theme={theme}>
      <UtilityConnectRedirect {...viewModel} {...props} />
    </ThemeProvider>
  )
}
