import { fetchViewConfigFromCMS } from '@/graphql/utilties/fetchViewConfigFromCMS'
import { createResourceCollection } from '@/request'
import { ID, RequestTypes, ResourceMiddlewareFunction } from '@/request/types'
import { Model } from '@/types/model'
import {
  UtilityEnrollmentWithProgram,
  UtilityProgramViewConfig,
} from '@/types/utilityProgram'

export type UtilityProgramModel = Model &
  UtilityEnrollmentWithProgram & {
    id: ID
    // view config is populated from the cms
    view_config_json?: UtilityProgramViewConfig
  }

async function populateViewConfigFromCMSMiddleware<
  UtilityEnrollmentWithProgram extends UtilityProgramModel,
>(
  requestData: { requestType: RequestTypes },
  data: UtilityEnrollmentWithProgram | UtilityEnrollmentWithProgram[],
): ReturnType<ResourceMiddlewareFunction<UtilityEnrollmentWithProgram>> {
  const type = requestData.requestType
  const middlewareInput: UtilityEnrollmentWithProgram[] = Array.isArray(data)
    ? data
    : [data]

  if (
    middlewareInput &&
    (type === RequestTypes.Fetch ||
      type === RequestTypes.Create ||
      type === RequestTypes.Update)
  ) {
    // @ts-expect-error ignoring this type error
    return await Promise.all(
      middlewareInput.map((utilityProgram) =>
        populateViewConfigFromCMS(utilityProgram),
      ),
    )
  }

  return data
}

async function populateViewConfigFromCMS(
  enrollment: UtilityEnrollmentWithProgram,
) {
  const cmsViewConfig = await fetchViewConfigFromCMS({
    utilityProgramId: enrollment.utility_program.id,
  })

  return {
    ...enrollment,
    utility_program: {
      ...enrollment.utility_program,
      verification_status: enrollment.verification_status,
      view_config_json: cmsViewConfig,
    },
  }
}

export function useEnrolledUtilityViewConfig() {
  const { data: enrolledProgramResponse, isLoadingOrIdle } =
    enrolledUtilityProgramsCollection.useFetch()
  return {
    viewConfig: enrolledProgramResponse?.[0]?.utility_program?.view_config_json,
    isLoading: isLoadingOrIdle,
  }
}

type EnrolledUtilityPrograms = UtilityProgramModel & {
  utilityProgramEnrollments: UtilityEnrollmentWithProgram[]
}
export const enrolledUtilityProgramsCollection =
  createResourceCollection<EnrolledUtilityPrograms>({
    name: 'enrolledUtilityProgramsCollection',
    apiConfig: {
      path: 'programs/enrolled',
      middlewares: [populateViewConfigFromCMSMiddleware],
    },
    selector: (state) => state.enrolledUtilityPrograms,
  })
const reducer = enrolledUtilityProgramsCollection.slice.reducer

export default reducer
