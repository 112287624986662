import { thermostatsCollection } from '@/reducers/thermostats'
import { RootState } from '@/store'
import selectUserHasMadePlanSelection from './selectUserHasMadePlanSelection'
import selectUserSelectedHome from './selectUserSelectedHome'
import selectUserUtility from './selectUserUtility'
import selectUserVehicles from './selectUserVehicles'
import { ConnectionRequirement } from '@/app/components/types'
import selectUserCanAndHasntIntegratedUtility from './selectUserCanAndHasntIntegratedUtility'
import selectUserHasPendingPlan from './selectUserHasPendingPlan'
import selectUserHasHome from './selectUserHasHome'

export function selectMeetsUtilityRequirements(state: RootState) {
  return [
    selectUserHasMadePlanSelection(state),
    selectUserSelectedHome(state),
    selectUserUtility(state),
  ].every(Boolean)
}

export function selectMeetsVehicleRequirements(state: RootState) {
  return [selectUserVehicles(state).length].every(Boolean)
}

export function selectMeetsThermostatRequirements(state: RootState) {
  return [thermostatsCollection.selectors.selectTotal(state) > 0].every(Boolean)
}

export function selectMeetsConnectionRequirement(
  state: RootState,
  connectionRequirement: ConnectionRequirement,
) {
  return {
    utility: selectMeetsUtilityRequirements(state),
    vehicle: selectMeetsVehicleRequirements(state),
    thermostat: selectMeetsThermostatRequirements(state),
    meter: !selectUserCanAndHasntIntegratedUtility(state),
    plan: selectUserHasMadePlanSelection(state),
    unpendingPlan: !selectUserHasPendingPlan(state),
    home: selectUserHasHome(state),
  }[connectionRequirement]
}

export function selectFirstUnmetConnectionRequirement(
  state: RootState,
  connectionRequirements: ConnectionRequirement[],
) {
  return connectionRequirements.find(
    (connectionRequirement) =>
      !selectMeetsConnectionRequirement(state, connectionRequirement),
  )
}
