import React, { useRef } from 'react'
import ResourceSelectionGallery, {
  GalleryRefHandle,
} from './ResourceSelectionGallery'
import { Resource } from './ResourceSelectionGallery/types'
import Loader from './layout/Loader'
import { Vehicle } from '../../types/vehicle/'
import { Identifiable } from '@/types'
import { getVehicleDisplayModel } from '@/utils/CarUtils'

type LocalVehicleSelectionGalleryProps = Identifiable & {
  vehicles: Vehicle[]
  selectedVehicle: Vehicle
  setSelectedVehicle: (vehicle: Vehicle) => void
}

function LocalVehicleSelectionGallery({
  vehicles,
  selectedVehicle,
  setSelectedVehicle,
  id,
}: LocalVehicleSelectionGalleryProps) {
  const galleryControlsRef: React.Ref<GalleryRefHandle> | null = useRef(null)

  const selectVehicle = (vehicle: Vehicle) => {
    setSelectedVehicle(vehicle)
  }

  const displayModel = getVehicleDisplayModel(selectedVehicle)

  const vehicleToResource = (vehicle: Vehicle) => ({
    id: `${id}-vehicle-gallery-vehicle-selection-${vehicle.id}`,
    name:
      vehicle?.car?.display_name !== ''
        ? vehicle?.car?.display_name
        : vehicle?.car?.car_model?.make ?? 'Unset Make',
    details: `${vehicle?.car?.car_model?.year ?? ''} ${
      vehicle?.car?.display_name !== ''
        ? vehicle?.car?.car_model?.friendly_name ?? ''
        : displayModel ?? ''
    }`,
    image: vehicle.car?.image_url
      ? '/dashboard/img/car_models' + vehicle?.car?.image_url
      : '/dashboard/img/car_models/covered-car.webp',
    action: () => selectVehicle(vehicle),
    selected: vehicle.id === selectedVehicle?.id,
  })

  const resources: Resource[] = vehicles.map(vehicleToResource)

  return (
    <ResourceSelectionGallery resources={resources} ref={galleryControlsRef} />
  )
}

type MaybeLocalVehicleSelectionGalleryProps = Identifiable & {
  vehicles?: Vehicle[]
  selectedVehicle: Vehicle
  setSelectedVehicle: (vehicle: Vehicle) => void
}

function MaybeResourceSelectionGallery({
  vehicles,
  selectedVehicle,
  setSelectedVehicle,
  id,
}: MaybeLocalVehicleSelectionGalleryProps) {
  return !vehicles ? (
    <Loader color="primary" />
  ) : (
    <LocalVehicleSelectionGallery
      vehicles={vehicles}
      selectedVehicle={selectedVehicle}
      setSelectedVehicle={setSelectedVehicle}
      id={id}
    />
  )
}

export default MaybeResourceSelectionGallery
