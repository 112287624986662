import './styles.scss'
import {
  Card,
  CardProps,
  CardNavigation,
  Flex,
  CardActions,
  CardActionButton,
} from '@/components'
import formatClasses from '@/utils/classes/formatClasses'
import ConfigurableText from '../ConfigurableText'
import { UtilityProgram } from '@/types/utilityProgram'

export interface Props extends Omit<CardProps, 'children'> {
  title: string
  subtitle: string
  buttonTitle: string
  programLogoUrl: string | null
  onLearnMoreClick: () => void
  onCloseClick: () => void
  utilityProgram?: UtilityProgram
}

export default function LearnMoreCard({
  title,
  subtitle,
  buttonTitle,
  programLogoUrl,
  onLearnMoreClick,
  onCloseClick,
  ...cardProps
}: Props) {
  const classes = {
    content: formatClasses(['learn-more-prompt-content']),
    logo: formatClasses(['learn-more-prompt-logo']),
  }

  return (
    <Card {...cardProps}>
      <CardNavigation
        onCloseClick={onCloseClick}
        id="utility-program-learn-more-navigation"
      >
        {programLogoUrl && (
          <img
            src={programLogoUrl}
            alt="Utility Program Logo"
            className={classes.logo}
          />
        )}
      </CardNavigation>
      <Flex
        container
        direction="column"
        rowSpacing={1}
        className={classes.content}
      >
        <Flex item>
          <ConfigurableText config={title} variant="subheader" />
        </Flex>
        <Flex item>
          <ConfigurableText config={subtitle} variant="body2" />
        </Flex>
      </Flex>
      <CardActions>
        <CardActionButton
          type="primary"
          onClick={onLearnMoreClick}
          id="utility-program-learn-more-button"
        >
          {buttonTitle}
        </CardActionButton>
      </CardActions>
    </Card>
  )
}
