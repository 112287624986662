import React from 'react'
import {
  Divider as MuiDivider,
  Grid as MuiGrid,
  Typography as MuiTypography,
  GridSpacing,
} from '@material-ui/core'
import ColorBoxedTypography from './ColorBoxedTypography'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import { makeStyles, createStyles } from '@material-ui/core/styles'

const Divider = styled(MuiDivider)(spacing)
const Grid = styled(MuiGrid)(spacing)
const Typography = styled(MuiTypography)(spacing)

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
    },
    divider: {
      width: '100%',
    },
    text: {
      color: '#343434',
      fontSize: '0.8rem',
    },
  }),
)

interface LabeledColoredValue {
  label: string
  value: string | React.ReactNode
  color: string
}

export interface Props {
  values: LabeledColoredValue[]
  spacing?: GridSpacing
}

export default function ColorBoxedValueList({ values, spacing = 2 }: Props) {
  const classes = useStyles()

  return (
    <Grid container spacing={spacing} className={classes.container}>
      {values.map((value) => (
        <Grid item key={value.label} className={classes.container}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="subtitle1" className={classes.text}>
                {value.label}
              </Typography>
            </Grid>
            <Grid item>
              <ColorBoxedTypography
                hexColor={value.color}
                variant="subtitle1"
                className={classes.text}
              >
                {value.value}
              </ColorBoxedTypography>
            </Grid>
          </Grid>
          <Divider className={classes.divider} my={2} />
        </Grid>
      ))}
    </Grid>
  )
}
