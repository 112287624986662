import { AlertMessage, NavigationPage } from 'app-components'
import { useCallback, useEffect, useState } from 'react'
import useHomeEstimationNavigation, {
  HomeEditReasons,
  HomeEstimationPages,
} from '@/app/pages/home-estimations/useHomeEstimationNavigation'
import Wizard, { WizardChoice, WizardPage } from '@/components/Wizard/Wizard'
import { useAppDispatch, useMockableViewModel } from '@/hooks'
import { homesCollection, useHomeCollection } from '@/reducers/homeResource'
import { Flex, LoadingAnimation } from '@/components'
import { logEvent } from '@/logging'

const heatingTypeQuestion: WizardChoice[] = [
  { label: 'Gas', value: 'gas' },
  { label: 'Electric', value: 'electric' },
]

const useViewModel = useMockableViewModel({
  useViewModel() {
    const [squareFootage, setSquareFootage] = useState(null)
    const [homeAge, setHomeAge] = useState(null)
    const [heatBTUs, setHeatBTUs] = useState(null)
    const [coolingBTUs, setCoolingBTUs] = useState(null)
    const [heatType, setHeatType] = useState(null)
    const navigate = useHomeEstimationNavigation()
    const [isLoading, setIsLoading] = useState(false)
    const [questionnaireStart, setQuestionnaireStart] = useState(0)
    const selectedHome = useHomeCollection()
    const [error, setError] = useState(false)

    const dispatch = useAppDispatch()
    const currentYear = new Date().getFullYear()

    const questionnaire: WizardPage[] = [
      {
        id: 'home-sqft-questionnaire',
        callback: setSquareFootage as (val: string | null) => void,
        question: {
          prompt: 'What is the total square footage of your home?',
          label: 'Home square footage',
          type: 'number',
          skipEnabled: true,
          helpText:
            "Enter your home's square footage. This helps us calculate how much space your HVAC system needs to heat or cool, affecting energy usage.",
          helpTitle: 'Square Footage',
          minInput: 1,
        },
      },
      {
        id: 'home-age-questionnaire',
        callback: setHomeAge as (val: string | null) => void,
        question: {
          prompt: 'When was your home constructed?',
          label: 'Year of construction (ex. 1994)',
          type: 'number',
          skipEnabled: true,
          minInput: 1,
          maxInput: currentYear,
          helpTitle: 'Age of Home',
          helpText:
            'The age of your home often affects its insulation properties. Knowing this helps us adjust the energy consumption estimates.',
        },
      },
      {
        id: 'heat-BTUs-questionnaire',
        callback: setHeatBTUs as (val: string | null) => void,
        question: {
          prompt: 'What is the BTU number of your heating system?',
          label: 'Number of BTUs',
          type: 'number',
          skipEnabled: true,
          minInput: 1,
          maxInput: 10000000,
          helpTitle: 'Heating BTUs',
          helpText:
            'Enter the BTU rating of your heating system. This number helps us create accurate energy consumption and cost estimates',
          helpSubtitle: 'Where to find it?',
          helpSubtext:
            "Look for a metal nameplate on the heater—outside or inside the service panel. Alternatively, check the 'Specifications' section of your manual.",
        },
      },
      {
        id: 'heat-type-questionnaire',
        callback: setHeatType as (val: string | null) => void,
        question: {
          prompt: 'What is your heating type?',
          choices: heatingTypeQuestion,
          skipEnabled: true,
          skipOverride: 'undefined',
          helpTitle: 'Heating Energy Source',
          helpText:
            "Identify whether your heating is powered by gas or electric. This information helps us calculate your system's overall energy efficiency.",
        },
      },
      {
        id: 'cooling-BTUs-questionnaire',
        callback: setCoolingBTUs as (val: string | null) => void,
        question: {
          prompt: 'How many BTUs does your cooling system have?',
          label: 'Number of BTUs',
          type: 'number',
          skipEnabled: true,
          minInput: 1,
          maxInput: 10000000,
          helpTitle: 'Cooling BTUs',
          helpText:
            "Enter your air conditioner's BTU number to refine our energy calculations. This helps us project heating costs accurately.",
          helpSubtitle: 'Where to find it?',
          helpSubtext:
            "Check the label on the AC unit—side, back, or inside the access panel. Or look in the 'Technical Specs' section of your manual.",
        },
      },
    ]

    const onComplete = useCallback(async () => {
      if (!selectedHome) {
        return
      }

      setIsLoading(true)
      setError(false)

      const response: {
        error: boolean
        payload: { response: string[]; external_redirect_url: string }
      } = await dispatch(
        homesCollection.actions.update(selectedHome.id, {
          user_defined_sq_footage: squareFootage ?? undefined,
          user_defined_heating_btu: heatBTUs ?? undefined,
          user_defined_cooling_btu: coolingBTUs ?? undefined,
          user_defined_year_constructed: homeAge ?? undefined,
          user_defined_heating_energy_source: heatType ?? undefined,
        }),
      )

      if (response.error) {
        setError(true)
        setIsLoading(false)
      } else {
        navigate.push(
          HomeEstimationPages.Success,
          HomeEditReasons.WizardSubmission,
        )
      }
    }, [selectedHome, squareFootage, homeAge, heatType, heatBTUs, coolingBTUs])

    return {
      onComplete,
      isLoading,
      questionnaire,
      questionnaireStart,
      error,
    }
  },
  useMockViewModel() {
    return {
      onComplete: async () => alert('Survey complete'),
      isLoading: true,
      questionnaire: [],
      questionnaireStart: 0,
      error: false,
    }
  },
})

export default function HomeEstimationWizard() {
  const [onBackClicked, setOnBackClicked] = useState<() => void | undefined>()
  const { onComplete, isLoading, questionnaire, questionnaireStart, error } =
    useViewModel()
  const [prevStepSignal, setPrevStepSignal] = useState(false)

  useEffect(() => {
    logEvent('HVAC_Questionnaire_Started')
  }, [])

  const backButtonClicked = useCallback(() => {
    setPrevStepSignal(true)
  }, [])

  const onWizardIndexChange = useCallback((idx: number) => {
    setPrevStepSignal(false)
    if (idx == 0) {
      setOnBackClicked(undefined)
    } else {
      setOnBackClicked(() => backButtonClicked)
    }
  }, [])

  return (
    <NavigationPage
      id="hvac-wizard-page"
      title="Let's answer some questions"
      backOverride={onBackClicked}
      subtitle="Please answer a few questions about your home to better estimate your energy consumption."
    >
      {error && (
        <AlertMessage variant={'error'} className="mb-4">
          An error occurred. Please try again.
        </AlertMessage>
      )}
      {isLoading && (
        <Flex container alignContent={'center'} direction={'column'}>
          <LoadingAnimation type={'falling'} />
        </Flex>
      )}

      {!isLoading && (
        <Wizard
          questionnaire={
            questionnaire.slice(
              questionnaireStart,
              questionnaire.length,
            ) as WizardPage[]
          }
          id="hvac-wizard"
          onComplete={onComplete}
          onIndexChange={onWizardIndexChange}
          prevStepSignal={prevStepSignal}
        />
      )}
    </NavigationPage>
  )
}
