import styled from 'styled-components'

import { Box as MuiBox } from '@material-ui/core'

import { spacing } from '@material-ui/system'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'

const Box = styled(MuiBox)(spacing)

const useStyles = makeStyles((theme) => ({
  circleStyles: {
    borderRadius: '50%',
  },
}))

const CircledContent = ({
  children,
  className,
  width,
  height,
  ...restProps
}) => {
  const classes = useStyles()

  return (
    <Box
      component="span"
      className={clsx(className, classes.circleStyles)}
      style={{ width, height }}
    >
      {children}
    </Box>
  )
}

export default CircledContent
