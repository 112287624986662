import {
  Grid as MuiGrid,
  Paper,
  Typography as MuiTypography,
  Box,
  Button,
  Hidden,
} from '@material-ui/core'
import { spacing } from '@material-ui/system'
import { styled, makeStyles } from '@material-ui/styles'
import { Zap, DollarSign as Dollar, Clock, Link } from 'react-feather'
import CopyToClipboard from 'react-copy-to-clipboard'
import theme from '../../../../../v2/theme'

const Grid = styled(MuiGrid)(spacing)
const Typography = styled(MuiTypography)(spacing)

const useStyles = makeStyles((theme) => ({
  answerContainer: {
    backgroundColor: theme.palette.secondary.main,
    width: '100%',
    borderRadius: '20px',
    padding: '24px 32px',
  },
  answerBody: {
    fontSize: '1.25rem',
    color: '#343434',
    fontWeight: 600,
  },
  answerGreenBody: {
    fontSize: '1.65rem',
    color: '#00D73F',
    fontWeight: 800,
    padding: '0 2px',
  },
  answerRedBody: {
    fontSize: '1.65rem',
    color: '#FC4B4F',
    fontWeight: 800,
    padding: '0 2px',
  },
  answerPurpleBody: {
    fontSize: '1.65rem',
    color: theme.palette.primary.main,
    fontWeight: 800,
  },
  answerPurpleHeader: {
    fontSize: '1.65rem',
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  signupButton: {
    '&:hover': {
      color: 'white !important',
    },
  },
}))

const verbiageForTitle = (cs) => {
  if (!cs.years_to_pay_off_new_charger && cs.increased_efficiency_pct <= 0) {
    return 'Probably Not'
  } else if (
    !cs.years_to_pay_off_new_charger &&
    cs.increased_efficiency_pct > 0
  ) {
    return 'Maybe'
  } else if (cs.years_to_pay_off_new_charger < 0) {
    return 'Definitely Not'
  } else {
    return 'Yes'
  }
}

const Answer = ({ chargerSavings, resetForm, copied, setCopied }) => {
  const styles = useStyles()
  return (
    <Grid item xs={12} mt={4} justifyContent="center" alignItems="center">
      <Paper className={styles.answerContainer}>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Typography variant="h3">
            The Short Answer.{' '}
            <Box display="inline" className={styles.answerPurpleHeader}>
              {verbiageForTitle(chargerSavings)}
            </Box>
          </Typography>
          <Typography variant="subtitle2" mt={1}>
            Here's why...
          </Typography>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          mt={4}
          spacing={3}
        >
          <Hidden only={['xs']}>
            <Grid item>
              <Zap color="#343434" size={24} />
            </Grid>
          </Hidden>
          <Grid item alignItems="center">
            <Typography variant="body1" className={styles.answerBody}>
              Your car will charge{' '}
              <Box
                display="inline"
                className={
                  chargerSavings.charging_speed_change_pct >= 0
                    ? styles.answerGreenBody
                    : styles.answerRedBody
                }
              >
                {Math.abs(chargerSavings.charging_speed_change_pct)}%
              </Box>{' '}
              {chargerSavings.charging_speed_change_pct >= 0
                ? 'faster'
                : 'slower'}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={3}
        >
          <Hidden only={['xs']}>
            <Grid item mt={1}>
              <Dollar color="#343434" size={24} />
            </Grid>
          </Hidden>
          <Grid item>
            <Grid item>
              <Typography variant="body1" className={styles.answerBody}>
                You will {chargerSavings.yearly_savings >= 0 ? 'save' : 'lose'}{' '}
                <Box
                  display="inline"
                  className={
                    chargerSavings.yearly_savings >= 0
                      ? styles.answerGreenBody
                      : styles.answerRedBody
                  }
                >
                  ${Math.abs(chargerSavings.yearly_savings)}
                </Box>{' '}
                over a year
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                Current spend per year ~ $
                {chargerSavings.cost_per_year_old_charger}
              </Typography>
              <Typography variant="body1">
                {chargerSavings.increased_efficiency_pct >= 0
                  ? 'Increased'
                  : 'Decreased'}{' '}
                effciency ~ {Math.abs(chargerSavings.increased_efficiency_pct)}%
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={3}
        >
          <Hidden only={['xs']}>
            <Grid item mt={1}>
              <Clock color="#343434" size={24} />
            </Grid>
          </Hidden>
          <Grid item>
            <Grid item>
              {chargerSavings.years_to_pay_off_new_charger &&
              chargerSavings.years_to_pay_off_new_charger > 0 ? (
                <Typography variant="body1" className={styles.answerBody}>
                  You will pay off your charger in{' '}
                  <Box display="inline" className={styles.answerPurpleBody}>
                    {chargerSavings.years_to_pay_off_new_charger} years
                  </Box>
                </Typography>
              ) : (
                <Typography variant="body1" className={styles.answerBody}>
                  Your new charger will not pay for itself
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          padding={4}
          mt={5}
          justifyContent="center"
          align="center"
        >
          <Grid item xs={12}>
            <Typography variant="h6">These are estimates</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              To track exact spend and efficiency, connect your EV to Optiwatt
            </Typography>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Button
              variant="contained"
              color="primary"
              href="https://optiwatt.com/?referral_code=blgchr"
              className={styles.signupButton}
              target="_blank"
              rel="noopener noreferrer"
            >
              Get Started - It's Free
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Grid item xs={12} mt={4} alignItems="center" justifyContent="center">
        <Button onClick={resetForm} variant="text" color="primary">
          I want to crunch more numbers
        </Button>
      </Grid>
      <Grid item mt={2} xs={12}>
        <Hidden only={['sm', 'md', 'lg', 'xl']}>
          <CopyToClipboard text="https://optiwatt.com/news/is-the-tesla-gen-3-wall-connector-worth-it#interactive">
            <Button
              variant="text"
              color="primary"
              className={styles.sharexs}
              startIcon={<Link color={theme.palette.primary.main} size={16} />}
              onClick={() => setCopied(true)}
            >
              {!copied ? 'Copy Link' : 'Copied!'}
            </Button>
          </CopyToClipboard>
        </Hidden>
      </Grid>
    </Grid>
  )
}

export default Answer
