import { useMockableViewModel } from '@/hooks'
import { useSelectedPlanFetcher } from '@/authenticated/hooks/plans'
import { useGetUsagesFrom } from '@/app/pages/home-insights/current-rate/plan-details/plan-usage-graph/useGroupedUsageData'
import {
  RateComparison,
  useComparisonsFrom,
} from '@/reducers/homeRateComparisons'
import { useCallback } from 'react'
import { Plan } from '@/types'
import shouldRecommendPlan from '@/utils/shouldRecommendPlan'
import { logEvent } from '@/logging'
import { useSurveyResponder } from '@/app/components/SurveyObserver'
import { surveyResponsesCollection } from '@/reducers/surveyResponses'
import { remoteConfig } from '@/firebase'
import useExternalNavigation from '@/app/hooks/useExternalNavigation'

type ViewModel = {
  selectedPlan?: Plan
  comparison?: RateComparison
  planIsPending: boolean
  fetchingPlans: boolean
  isLoading: boolean
  currentCosts: number
  learnMore: () => void
  planName: string
  savings: number
  newCosts: number
  percentDiff: number
  displayRecommendation: boolean
  displayApprovalSurvey: boolean
}

export type RecommendedPlanProps = {
  onLearnMore: () => void
}

function useViewModel({ onLearnMore }: RecommendedPlanProps): ViewModel {
  const {
    selectedPlan,
    planIsPending,
    isLoading: fetchingPlans,
  } = useSelectedPlanFetcher()

  const { usages, isLoading: fetchingUsages } = useGetUsagesFrom(30)

  const { comparison, isLoading: fetchingComparisons } = useComparisonsFrom(30)

  const { externallyNavigate } = useExternalNavigation()
  const { completeSurvey } = useSurveyResponder('GexaNavigateToSwitch')
  const surveyData = surveyResponsesCollection.useFetch()?.data
  const feedbackSurvey = surveyData?.find(
    (data) => data.survey.type === 'PlanSwitchApproval',
  )

  const gexaLinkUrl =
    remoteConfig?.getString('gexa_link_url') ?? 'https://www.gexaenergy.com/'

  const learnMore = useCallback(async () => {
    logEvent('rateOfferLearnMoreClicked')
    await completeSurvey()
    externallyNavigate(gexaLinkUrl)
    onLearnMore()
  }, [completeSurvey])

  const currentCosts = +usages?.total_cost?.toFixed(2)
  const isLoading = fetchingComparisons || fetchingPlans || fetchingUsages
  const planName = comparison?.comparison_plan?.name ?? 'Unknown'
  const savings = Math.round(comparison?.current_plan_difference ?? 0)
  const newCosts = Math.round(currentCosts - savings)
  const percentDiff = Math.round((newCosts / currentCosts) * 100)
  const displayRecommendation = shouldRecommendPlan(selectedPlan, comparison)
  const displaySurvey =
    (feedbackSurvey &&
      !feedbackSurvey?.dismissed &&
      !feedbackSurvey?.completed) ??
    false

  return {
    comparison,
    planIsPending,
    fetchingPlans,
    isLoading,
    currentCosts,
    learnMore,
    planName,
    savings,
    newCosts,
    percentDiff,
    displayRecommendation,
    displayApprovalSurvey: displaySurvey,
  }
}

function useMockViewModel(): ViewModel {
  return {
    comparison: undefined,
    planIsPending: false,
    fetchingPlans: false,
    isLoading: false,
    currentCosts: 100,
    learnMore: () => alert('Learn more clicked!'),
    planName: 'mock plan',
    savings: 10,
    newCosts: 90,
    percentDiff: 10,
    displayRecommendation: true,
    displayApprovalSurvey: false,
  }
}

export default useMockableViewModel({ useViewModel, useMockViewModel })
