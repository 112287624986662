import { useIsExperimentalUser } from '@/authenticated/hooks/useIsExperimentalUser'
import { defaultConfig, remoteConfig } from '@/firebase'

type Options = {
  flag: keyof typeof defaultConfig
  // When provided, the flag will have the value of experimentalValue if the user is an experimental user
  experimentalValue?: boolean
}

export function useFeatureFlag(options: Options) {
  const flag = remoteConfig?.getBoolean(options.flag) ?? false
  const isExperimentalUser = useIsExperimentalUser()

  if (options.experimentalValue !== undefined) {
    return isExperimentalUser ? options.experimentalValue : flag
  }

  return flag
}
