import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
  changePasswordComplete as changePasswordCompleteImport,
  changePasswordErrors as changePasswordErrorsImport,
  changePasswordLoading as changePasswordLoadingImport,
  validateResetErrors as validateResetErrorsImport,
} from '../../../reducers'
import { validatePasswordToken, changePassword } from '../../../actions/auth'
import queryString from 'query-string'
import { isObjectEmpty } from '../../../utils/objectUtilities'
import { Redirect } from 'react-router'
import { useAppDispatch, useAppSelector, useValidatePassword } from '@/hooks'

const UpdatePassword = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState<Record<string, unknown>>({ detail: '' })
  const [token, setToken] = useState<string | null>(null)
  const [liveErrors, checkLiveErrors] = useValidatePassword()

  const changePasswordErrors = useAppSelector((state) =>
    changePasswordErrorsImport(state),
  )
  const changePasswordLoading = useAppSelector((state) =>
    changePasswordLoadingImport(state),
  )
  const changePasswordComplete = useAppSelector((state) =>
    changePasswordCompleteImport(state),
  )
  const validateResetErrors = useAppSelector((state) =>
    validateResetErrorsImport(state),
  )

  useEffect(() => {
    const params = queryString.parse(location.search)
    if (typeof params.token !== 'undefined') {
      dispatch(validatePasswordToken(params['token']))
      setToken(params['token'] as string)
    } else {
      setErrors({
        detail: 'No token found, please resend reset password email.',
      })
    }
  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    checkLiveErrors(newValue)
    setPassword(newValue)
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (password && checkLiveErrors(password)) {
      dispatch(changePassword(password, token))
    }
  }

  const getErrors = () => {
    if (validateResetErrors) {
      return validateResetErrors
    } else if (changePasswordErrors) {
      return changePasswordErrors
    } else if (errors.detail) {
      return errors
    } else {
      return {}
    }
  }

  const validLoading =
    typeof changePasswordLoading !== 'undefined' && changePasswordLoading
  const validErrors = getErrors()

  if (!isObjectEmpty(validErrors)) {
    return (
      <Redirect
        to={{
          pathname: '/reset-password',
          state: { errors: validErrors },
        }}
      />
    )
  }

  return (
    <>
      <section
        className="hero-section ptb-100 background-img full-screen"
        style={{
          backgroundImage: "url('img/hero-bg-1.webp')",
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-between pt-5 pt-sm-5 pt-md-5 pt-lg-0">
            <div className="col-md-7 col-lg-6">
              <div className="hero-content-left text-white"></div>
            </div>
            <div className="col-md-5 col-lg-5">
              <div className="card login-signup-card shadow-lg mb-0">
                <div className="card-body px-md-5 py-5">
                  <div className="mb-5">
                    <h5 className="h3">Reset Password</h5>
                    <p className="text-muted mb-0">
                      Please enter your new password.
                    </p>
                  </div>
                  {validErrors.detail ? (
                    <div className="alert alert-danger" role="alert">
                      {validErrors.detail as string[]}
                    </div>
                  ) : (
                    ''
                  )}
                  <form
                    className="login-signup-form"
                    method="post"
                    onSubmit={handleSubmit}
                  >
                    <div className="form-group mb-2">
                      <label className="pb-1">Password</label>
                      <div className="input-group input-group-merge">
                        <div className="input-icon">
                          <span className="ti-lock color-primary"></span>
                        </div>
                        <input
                          value={password}
                          onChange={handleChange}
                          type="password"
                          name="password"
                          className="form-control"
                          placeholder="Enter your new password"
                          required
                        />
                      </div>
                    </div>
                    <div className="flex h-7">
                      {liveErrors.length > 0 && (
                        <div className="text-red-600 text-xs">{`*${liveErrors}`}</div>
                      )}
                    </div>
                    <button
                      disabled={validLoading || changePasswordComplete}
                      className="btn btn-lg btn-block solid-btn border-radius mt-3 mb-3"
                      id="logIn"
                    >
                      {validLoading
                        ? 'Sending...'
                        : changePasswordComplete
                        ? 'Success'
                        : 'Reset Password'}
                    </button>
                  </form>
                </div>
                <div className="card-footer bg-transparent border-top px-md-5">
                  <small>Remember your password?</small>
                  <a href="login" className="small">
                    {' '}
                    Login
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-img-absolute">
          <img
            src="img/hero-bg-shape-1.svg"
            className="img-fluid"
            alt="fluid"
          />
        </div>
      </section>
    </>
  )
}

export default UpdatePassword
