import { Sheet, Text } from '@/components'
import TeslaLogo from '@/authenticated/images/tesla.webp'

export default function TeslaFleetPartnerSheet(props: { className?: string }) {
  return (
    <Sheet color="green-100" className={props.className}>
      <div className="flex items-center">
        <img src={TeslaLogo} alt="Tesla Logo" className="w-4 h-4 mr-2" />
        <Text variant="body2" className="text-green-500">
          OFFICIAL FLEET PARTNER
        </Text>
      </div>
    </Sheet>
  )
}
