import { TOKEN_FAILURE, TOKEN_SUCCESS } from '@/actions/auth'
import { logEvent } from '@/logging'
import { tokenIsExpired } from '@/types/auth/utils'
import { Middleware } from 'redux'
import * as Sentry from '@sentry/browser'

// Use these flags so we don't log the expiry events more than once per session
let accessTokenExpiryLogged = false
let refreshTokenExpiryLogged = false

export const tokenLoggingMiddleware: Middleware =
  ({ getState }) =>
  (next) =>
  (action) => {
    try {
      const state = getState()
      const accessToken = state.auth.access
      const refreshToken = state.auth.refresh

      if (
        accessToken &&
        tokenIsExpired(accessToken) &&
        !accessTokenExpiryLogged
      ) {
        logEvent('access_token_expired')
        accessTokenExpiryLogged = true
      }

      if (
        refreshToken &&
        tokenIsExpired(refreshToken) &&
        !refreshTokenExpiryLogged
      ) {
        logEvent('refresh_token_expired')
        refreshTokenExpiryLogged = true
      }

      if (action.type === TOKEN_FAILURE) {
        logEvent('token_refresh_failed')
      } else if (action.type === TOKEN_SUCCESS) {
        logEvent('token_refresh_success')
      }
    } catch (e) {
      Sentry.captureException(e)
      return next(action)
    }
    return next(action)
  }
