import { useEffect } from 'react'

/**
 * A hook that listens for messages from the native mobile app and
 * executes a callback when a message is received
 * @param onReceiveMessage - The callback to execute on message receive
 * @param messageTypes - A list of message types that we're listening for. These
 * are defined within the mobile application
 */
export default function useMobileMessage<T>(
  onReceiveMessage: (data: T) => void,
  messageTypes?: string[],
) {
  const onMessageReceived = (event: MessageEvent) => {
    const messageType = event.data.event?.type
    if (messageTypes && (!messageType || !messageTypes.includes(messageType))) {
      return
    }

    onReceiveMessage(event.data.event as T)
  }

  useEffect(function handleMobileEvents() {
    window.addEventListener('message', onMessageReceived)

    return () => {
      window.removeEventListener('message', onMessageReceived)
    }
  }, [])
}
