import { createAction } from '@reduxjs/toolkit'
import { RSAA } from 'redux-api-middleware'
import config from '../config'
import { withAuth } from '../reducers'

export const CHARGE_SCHEDULES_REQUEST = 'CHARGE_SCHEDULES_REQUEST'
export const CHARGE_SCHEDULES_SUCCESS = 'CHARGE_SCHEDULES_SUCCESS'
export const CHARGE_SCHEDULES_FAILURE = 'CHARGE_SCHEDULES_FAILURE'

export const CHARGE_SCHEDULES_UPDATE_REQUEST = 'CHARGE_SCHEDULES_UPDATE_REQUEST'
export const CHARGE_SCHEDULES_UPDATE_SUCCESS = 'CHARGE_SCHEDULES_UPDATE_SUCCESS'
export const CHARGE_SCHEDULES_UPDATE_FAILURE = 'CHARGE_SCHEDULES_UPDATE_FAILURE'

export const MARK_CHARGE_SCHEDULE_REQUEST = 'MARK_CHARGE_SCHEDULE_REQUEST'
export const MARK_CHARGE_SCHEDULE_SUCCESS = 'MARK_CHARGE_SCHEDULE_SUCCESS'
export const MARK_CHARGE_SCHEDULE_FAILURE = 'MARK_CHARGE_SCHEDULE_FAILURE'

export const SET_CHARGE_SCHEDULE_ERROR = 'SET_CHARGE_SCHEDULE_ERROR'

export const getChargeSchedules = () => ({
  [RSAA]: {
    endpoint: config.apiGateway.URL + '/api/charge_schedules/',
    method: 'GET',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      CHARGE_SCHEDULES_REQUEST,
      CHARGE_SCHEDULES_SUCCESS,
      CHARGE_SCHEDULES_FAILURE,
    ],
  },
})

export const createChargeSchedule = (chargeScheduleData) => ({
  [RSAA]: {
    endpoint: config.apiGateway.URL + '/api/charge_schedules/',
    method: 'POST',
    body: JSON.stringify(chargeScheduleData),
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      CHARGE_SCHEDULE_REQUEST,
      CHARGE_SCHEDULE_SUCCESS,
      CHARGE_SCHEDULE_FAILURE,
    ],
  },
})

export const updateChargeSchedule = (chargeScheduleData) => {
  const { vehicle_id, charger_id, ...deviceRemovedData } = chargeScheduleData
  return {
    [RSAA]: {
      endpoint:
        config.apiGateway.URL +
        `/api/charge_schedules/${chargeScheduleData.id}/`,
      method: 'PUT',
      body: JSON.stringify(deviceRemovedData),
      headers: withAuth({ 'Content-Type': 'application/json' }),
      types: [
        CHARGE_SCHEDULES_UPDATE_REQUEST,
        CHARGE_SCHEDULES_UPDATE_SUCCESS,
        CHARGE_SCHEDULES_UPDATE_FAILURE,
      ],
    },
  }
}

export const markChargeScheduleSelection = () => ({
  [RSAA]: {
    endpoint: config.apiGateway.URL + '/api/current_user/',
    method: 'PATCH',
    body: JSON.stringify({ has_made_charge_schedule_selection: true }),
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      MARK_CHARGE_SCHEDULE_REQUEST,
      MARK_CHARGE_SCHEDULE_SUCCESS,
      MARK_CHARGE_SCHEDULE_FAILURE,
    ],
  },
})

export const setChargeScheduleError = createAction(SET_CHARGE_SCHEDULE_ERROR)
