import './styles.scss'
import { Grid, Icon } from 'component-library'
import formatClasses from '@/utils/classes/formatClasses'
import { HTMLAttributes } from 'react'

export interface Props
  extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  range: number[]
  count: number
  handleCountChange: (count: number) => void
}

export function NumberInput(props: Props) {
  const classes = {
    decrement: formatClasses(['decrement']),
    increment: formatClasses(['increment']),
    numberInput: formatClasses(['optiwatt-number-input']),
    value: formatClasses(['input-value']),
  }

  return (
    <Grid
      className={classes.numberInput}
      data-value={props.count}
      gap="20px"
      placeContent="center"
    >
      <button
        className={classes.decrement}
        data-testing-id="decrement-button"
        onClick={() => props.handleCountChange(-1)}
        style={{
          visibility: props.count <= props.range[0] ? 'hidden' : undefined,
        }}
      >
        <Icon color="blue-500" name="Minus" size={16} />
      </button>
      <div className={classes.value} data-testing-id="number-input-value">
        {props.count}
      </div>
      <button
        className={classes.increment}
        data-testing-id="increment-button"
        onClick={() => props.handleCountChange(1)}
        style={{
          visibility: props.count >= props.range[1] ? 'hidden' : undefined,
        }}
      >
        <Icon color="blue-500" name="Plus" size={16} />
      </button>
    </Grid>
  )
}

export default NumberInput
