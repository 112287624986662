import { NavigationPage } from '@/app/components'
import { Card, Divider, Text } from '@/components'

function ContactUsLink(props: { href?: string; children: React.ReactNode }) {
  return (
    <a
      className="cursor-pointer text-themed-blue-500 hover:text-themed-info font-bold text-[14px]"
      href={props.href}
    >
      {props.children}
    </a>
  )
}

export default function ContactUsPage() {
  return (
    <NavigationPage
      id="contact-us"
      title="Customer Support"
      subtitle="Find answers, get in touch, or connect with us for support and updates."
    >
      <Text variant="h3" className="mb-3">
        About
      </Text>
      <Card className="mb-6">
        <ContactUsLink href="https://optiwatt.applytojob.com/">
          Careers
        </ContactUsLink>
        <Divider className="my-4" />
        <ContactUsLink href="https://optiwatt.com/privacy">
          Privacy Policy
        </ContactUsLink>
        <Divider className="my-4" />
        <ContactUsLink href="https://optiwatt.com/tos">
          Terms of Service
        </ContactUsLink>
        <Divider className="my-4" />
        <ContactUsLink href="https://optiwatt.com/faq">FAQ</ContactUsLink>
        <Divider className="my-4" />
        <ContactUsLink href="https://optiwatt.com/partners">
          For Partners
        </ContactUsLink>
      </Card>

      <Text variant="h3" className="mb-3">
        Contact
      </Text>
      <Card className="mb-6">
        <ContactUsLink href="mailto:support@getoptiwatt.com">
          support@optiwatt.com
        </ContactUsLink>
        <Divider className="my-4" />
        <ContactUsLink href="https://optiwatt.com">
          www.optiwatt.com
        </ContactUsLink>
        <Divider className="my-4" />
        <ContactUsLink href="tel:+19254927585">+1 (925) 492-7585</ContactUsLink>
      </Card>

      <Text variant="h3" className="mb-3">
        Social
      </Text>
      <Card className="mb-6">
        <ContactUsLink href="https://www.facebook.com/optiwatt/">
          Facebook
        </ContactUsLink>
        <Divider className="my-4" />
        <ContactUsLink href="https://x.com/optiwatt">Twitter</ContactUsLink>
        <Divider className="my-4" />
        <ContactUsLink href="https://www.youtube.com/channel/UC3hhn3AJdZCxFJ_30wyQCdQ">
          YouTube
        </ContactUsLink>
        <Divider className="my-4" />
        <ContactUsLink href="https://www.instagram.com/optiwatt/">
          Instagram
        </ContactUsLink>
      </Card>
    </NavigationPage>
  )
}
