import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { getChargeSchedules, setChargeScheduleError } from '@/actions/schedule'
import { shouldLoadData } from './useDataLoader'
import { selectUserVehicles } from '@/selectors'
import { PayloadAction } from '@reduxjs/toolkit'

type Options = {
  keepInSyncWithVehicles?: boolean
}

export default (options?: Options) => {
  const dispatch = useAppDispatch()
  const { chargeSchedules, isLoading, errors } = useAppSelector(
    (state) => state.schedule,
  )
  const vehicleCount = useAppSelector(
    (state) => selectUserVehicles(state).length,
  )

  // Load charge schedules when we have not loaded them yet
  useEffect(() => {
    if (shouldLoadData(chargeSchedules, isLoading, errors)) {
      dispatch(getChargeSchedules())
    }
  }, [chargeSchedules, isLoading, errors, dispatch])

  // Update charge schedules when a new vehicle is added, because schedules
  // are added on the backend when a vehicle is added
  useEffect(() => {
    if (!options?.keepInSyncWithVehicles) {
      return
    }
    const vehiclesNotInSyncWithSchedules =
      vehicleCount > 0 && vehicleCount !== chargeSchedules?.length
    const noErrors = !Object.keys(errors).length
    if (vehiclesNotInSyncWithSchedules && !isLoading && noErrors) {
      dispatch(getChargeSchedules()).then(
        (response: PayloadAction<unknown[]>) => {
          if (response?.payload?.length !== vehicleCount) {
            // As a fallback, we set an error if the vehicle count does not match so we can avoid
            // infinite fetches
            // @ts-expect-error createAction used in js file
            dispatch(setChargeScheduleError('Vehicle count mismatch'))
          }
        },
      )
    }
  }, [vehicleCount, chargeSchedules?.length])

  return chargeSchedules
}
