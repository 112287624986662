import { createResourceCollection } from '@/request'
import { User } from '@/types'

export const customUserResourceCollection = createResourceCollection<User>({
  name: 'customUsers',
  apiConfig: {
    path: 'users',
  },
  selector: (state) => state.customUsers,
})

const customUserReducer = customUserResourceCollection.slice.reducer
export default customUserReducer
