import React from 'react'
import './styles.scss'
import { AlertCircle, Check, X } from 'react-feather'
import Text from '@/components/Text/Text'
import { ExpansionPanel } from '@/components'

export type ConnectDeviceConfirmationProps = {
  onCancelOrRetry: () => void
  onConfirmed: () => void
  disableButton?: boolean
  status: 'connecting' | 'connected' | 'failed'
  deviceConnections: DeviceConfirmationPanelProps[]
  title: string
}

export type ConfirmationExpansionContentItemProps = {
  title: string
  type?: 'success' | 'error'
  subtitle?: string
  children?: React.ReactNode
}

export type ConnectionPermission = {
  name: string
  description?: string
  allowed: boolean
  associatedFeatures?: string[]
}

export type DeviceConfirmationPanelProps = {
  device: { name: string; description: string }
  permissions: ConnectionPermission[]
}

export type ConfirmationContentLabelProps = {
  type: 'success' | 'error'
  name: string
  description: string
}

export const ConfirmationContentLabel: React.FC<
  ConfirmationContentLabelProps
> = ({ type, name, description }) => (
  <div className="confirmation-label">
    <div>
      {type === 'error' ? (
        <AlertCircle className="icon alert" />
      ) : (
        <Check className="icon check" />
      )}
    </div>
    <div>
      <Text variant="h3">{name}</Text>
      <Text variant="body4">{description}</Text>
    </div>
  </div>
)

export const ConfirmationExpansionContentItem: React.FC<
  ConfirmationExpansionContentItemProps
> = ({ title, type, subtitle, children }) => (
  <div className="item-container">
    <div className="check-container">
      {type === 'error' ? (
        <X className="icon x" />
      ) : (
        <Check className="icon check" />
      )}
    </div>
    <div>
      <Text variant="body1">{title}</Text>
      {subtitle && (
        <div className="subtitle">
          <Text variant="body4">{subtitle}</Text>
        </div>
      )}
      {children}
    </div>
  </div>
)

export const DeviceConfirmationPanel: React.FC<
  DeviceConfirmationPanelProps
> = ({ device, permissions }) => {
  const missingPermissionsPresent = permissions?.some(
    (permission) => !permission.allowed,
  )

  return (
    <div className="confirmation-content">
      <ExpansionPanel
        initiallyExpanded={missingPermissionsPresent}
        label={
          <ConfirmationContentLabel
            type={!missingPermissionsPresent ? 'success' : 'error'}
            name={device.name}
            description={device.description}
          />
        }
      >
        {permissions.map((permission) => (
          <ConfirmationExpansionContentItem
            key={`${device.name}-${permission.name}`}
            title={permission.name}
            subtitle={permission.description}
            type={permission.allowed ? 'success' : 'error'}
          >
            {!permission.allowed && permission.associatedFeatures && (
              <ul className="unordered-list">
                {permission.associatedFeatures.map((feature) => (
                  <li key={`${permission.name}-${feature}`}>{feature}</li>
                ))}
              </ul>
            )}
          </ConfirmationExpansionContentItem>
        ))}
      </ExpansionPanel>
    </div>
  )
}
