import { thermostatsCollection } from '../reducers/thermostats'
import { RootState } from '../store'
import { ID } from '../types/model'

export default function selectSchedulesForThermostat(
  state: RootState,
  thermostatId: ID,
) {
  return thermostatsCollection.selectors.selectById(state, thermostatId)
    ?.schedules
}
