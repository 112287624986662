import { NavigationPage } from 'app-components'
import { FullwidthButton } from '@/components/ContainerButton/FullwidthButton'
import { Flex } from '@/components'
import useHomeEstimationNavigation, {
  HomeEstimationPages,
} from '@/app/pages/home-estimations/useHomeEstimationNavigation'
import { useCallback } from 'react'
import { logEvent } from '@/logging'

export default function HomeEstimationEditDetails(props: any) {
  const navigate = useHomeEstimationNavigation()

  const onModelNumberClick = useCallback(() => {
    logEvent('HVAC_Upload_Method', {
      metadata: { method: 'HVAC Model Number' },
    })
    navigate.push(HomeEstimationPages.ModelNum)
  }, [])

  const onWizardClick = useCallback(() => {
    logEvent('HVAC_Upload_Method', {
      metadata: { method: 'Answer Questions' },
    })
    navigate.push(HomeEstimationPages.Wizard)
  }, [])

  return (
    <NavigationPage
      id="hvac-edit-details-page"
      title="Let's fix that"
      subtitle="To get a better estimate regarding your energy consumption, we need some information about your HVAC."
    >
      <Flex container gap="24px" direction="column">
        {/* TODO - AVild implement in DEV-1873
         <FullwidthButton
          id="hvac-upload-btn"
          title="Upload your HVAC tag"
          subtitle="Snap a picture of the tag on your HVAC"
          sheetText="RECOMMENDED"
        ></FullwidthButton>*/}
        {/* TODO - AVIld implement in DEV-1947
        <FullwidthButton
          id="hvac-model-number-btn"
          title="HVAC model number"
          subtitle="Provide us the model number of your HVAC"
          onClick={onModelNumberClick}
        ></FullwidthButton>*/}
        <FullwidthButton
          id="hvac-survey-btn"
          title="Answer some questions"
          subtitle="about your home"
          onClick={onWizardClick}
        ></FullwidthButton>
      </Flex>
    </NavigationPage>
  )
}
