import { useState, useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { updateLeapAuthorization } from '../../../actions/utilities'
import { ApiProvider } from '../../../types/utility/'
import { BroadcastChannel } from 'broadcast-channel'
import BroadcastChannelName from '../../../types/broadcastChannelName'
import { ConnectionStatusMode } from '../DeviceConnectionRedirect'
import { postMessagetoMobileIfPresent } from '../../../utils/messageUtils'
import data from './resources/data.json'
import { isNativeMobile } from '@/authenticated/hooks/useIsMobile'

type LeapAuthorizationData = {
  has_made_grid_support_selection: boolean
  utility_authorization_id?: string
  utility_api_provider?: string | null
}

const apiProviderFromUrlParams = (params: URLSearchParams) => {
  if (params.get('referral')) {
    return ApiProvider.UtilityApi
  } else if (params.get('authorizationId')) {
    return ApiProvider.Leap
  }
  return null
}

const authorizationIdFromUrlParams = (params: URLSearchParams) => {
  const provider = apiProviderFromUrlParams(params)
  if (provider === ApiProvider.Leap) {
    return params.get('authorizationId')
  } else if (provider === ApiProvider.UtilityApi) {
    return params.get('referral')
  }
  return null
}

function connectionStatusMode(
  authIsLoading: boolean,
  connectionSuccess: boolean,
) {
  if (authIsLoading) {
    return ConnectionStatusMode.Connecting
  } else if (connectionSuccess) {
    return ConnectionStatusMode.Connected
  }
  return ConnectionStatusMode.Failed
}

function useViewModel() {
  const dispatch = useAppDispatch()
  const isLoading = useAppSelector((state) => state.utilities.isLoading)
  const [success, setSuccess] = useState(false)

  const sendResult = (result: boolean, message = {}) => {
    const channel = new BroadcastChannel(BroadcastChannelName.GridSupport)
    channel.postMessage({
      result: result,
      message: message,
    })
  }

  const updateLeapAuthorizationRequest = useCallback(
    (urlParams: URLSearchParams) => {
      const data: LeapAuthorizationData = {
        has_made_grid_support_selection: true,
      }
      const authorizationId = authorizationIdFromUrlParams(urlParams)
      if (authorizationId) {
        data['utility_authorization_id'] = authorizationId
        data['utility_api_provider'] = apiProviderFromUrlParams(urlParams)
      }
      sendResult(true, {})
      dispatch(updateLeapAuthorization(data))
    },
    [],
  )

  useEffect(() => {
    // @todo, check if leap has updated "error_dercription" mispelling
    const urlParams = new URLSearchParams(window.location.search)
    if (
      urlParams.has('error') ||
      urlParams.has('error_code') ||
      urlParams.has('error_message')
    ) {
      setSuccess(false)
      sendResult(false, {
        error: urlParams.get('error'),
        error_code: urlParams.get('error_code'),
        error_message: urlParams.get('error_message'),
        error_description: urlParams.get('error_dercription'),
      })
    } else {
      setSuccess(true)
      updateLeapAuthorizationRequest(urlParams)
    }
  }, [updateLeapAuthorizationRequest])

  const handleClose = () => {
    if (isNativeMobile()) {
      postMessagetoMobileIfPresent('closeGridSupport')
    } else {
      window.close()
    }
  }

  return {
    mode: connectionStatusMode(isLoading, success),
    statusText: data.statusText,
    buttonAction: handleClose,
  }
}

export default useViewModel
