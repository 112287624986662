import { useEffect, useMemo, useState } from 'react'
import ImageUtils from '@/utils/images/ImageUtils'

export enum ImageLoadingState {
  Null,
  Loading,
  Complete,
  Failed,
}

export default function useImagesLoaded(imgSrcs: (string | undefined)[]) {
  const [loadingState, setLoadingState] = useState(ImageLoadingState.Null)
  const validImgs = useMemo(() => {
    return imgSrcs.filter((img): img is string => typeof img === 'string')
  }, [JSON.stringify(imgSrcs)])

  useEffect(() => {
    setLoadingState(ImageLoadingState.Loading)
    if (validImgs.length === 0) {
      setLoadingState(ImageLoadingState.Complete)
      return
    }
    ImageUtils.preloadImages(validImgs)
      .then(() => {
        setLoadingState(ImageLoadingState.Complete)
      })
      .catch(() => {
        setLoadingState(ImageLoadingState.Failed)
      })
  }, [validImgs])

  return {
    loadingState,
    isFinished: [ImageLoadingState.Complete, ImageLoadingState.Failed].includes(
      loadingState,
    ),
  }
}
