import { thermostatEnrollmentCollection } from '@/reducers/deviceProgramEnrollments'
import { useAppSelector } from '.'
import { createDeviceEnrollmentsForUserSelector } from '@/selectors'
import { ThermostatEnrollment } from '@/types/utilityProgramEnrollment'

export function useThermostatEnrollment(thermostatId: number) {
  thermostatEnrollmentCollection.useFetch()

  const thermostatEnrollment = useAppSelector((state) => {
    if (isNaN(thermostatId)) {
      return undefined
    }
    const id = createDeviceEnrollmentsForUserSelector('thermostat')(state).find(
      (enrollment) => {
        return (
          (enrollment as ThermostatEnrollment).thermostat_id === thermostatId
        )
      },
    )?.id
    if (!id) {
      return undefined
    }
    return thermostatEnrollmentCollection.selectors.selectById(state, id)
  })

  return thermostatEnrollment
}
