import { SeasonalRateSummary } from '@/types'
import * as actions from '../actions/seasonalRateSummary'
import { getApiErrorMessage } from '../utils/errorUtilities'
import { PayloadAction } from '@reduxjs/toolkit'

type State = {
  seasonalRateSummary: SeasonalRateSummary | null
  isLoading: boolean
  errors: Record<string, unknown>
}

const initialState: State = {
  seasonalRateSummary: null,
  isLoading: false,
  errors: {},
}

export default (state = initialState, action: PayloadAction<any>): State => {
  switch (action.type) {
    case actions.LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        seasonalRateSummary: action.payload,
        errors: {},
      }
    case actions.LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case actions.LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: getApiErrorMessage(action.payload),
      }
    default:
      return state
  }
}
