import { isRSAA, apiMiddleware } from 'redux-api-middleware'
import thunk from 'redux-thunk'

import {
  TOKEN_SUCCESS,
  refreshAccessToken,
  loginWithToken,
  LOGIN_TOKEN_SUCCESS,
} from '../../actions/auth'
import {
  refreshToken,
  isAccessTokenExpired,
  refreshTokenIsValid as refeshTokenIsValid,
} from '../../reducers'

export function createApiMiddleware() {
  let resolvingRefresh = null

  return ({ dispatch, getState }) => {
    const rsaaMiddleware = apiMiddleware({ dispatch, getState })

    return (next) => (action) => {
      if (isRSAA(action)) {
        // Get token from URL params if it is there
        const state = getState(),
          token = refreshToken(state),
          partnerToken = new URLSearchParams(window.location.search).get(
            'partnerToken',
          )

        const handleAuthMiddleware = () =>
          new Promise((resolve) => {
            // Receives RSAA actions that were dispatched while access token was expired.
            // If the action recieved is a refresh token success, then all postponed
            // RSAA actions are dispatched.
            const nextCheckPostoned = (nextAction) => {
              // Run postponed actions after token refresh
              if (
                nextAction.type === TOKEN_SUCCESS ||
                nextAction.type === LOGIN_TOKEN_SUCCESS
              ) {
                next(nextAction)
                resolve()
              } else {
                next(nextAction)
              }
            }

            if (partnerToken) {
              rsaaMiddleware(nextCheckPostoned)(loginWithToken(partnerToken))
            } else {
              rsaaMiddleware(nextCheckPostoned)(refreshAccessToken(token))
            }
          })

        // If there is a partner token, or if there is a token and it is expired
        if (
          partnerToken ||
          (token && isAccessTokenExpired(state) && refeshTokenIsValid(state))
        ) {
          // // return a promise so that callsites that treat dispatched actions as promises
          // // (i.e. they use `.then()`) will not break
          const thunkMiddleware = thunk({ dispatch, getState })
          return thunkMiddleware(next)(async () => {
            if (resolvingRefresh === null) {
              // if this is the first action that requires a refresh, then trigger the
              // refresh action by creating a promise that will resolve when the refresh
              // action is complete
              resolvingRefresh = handleAuthMiddleware()
            }
            await resolvingRefresh
            resolvingRefresh = null
            return rsaaMiddleware(next)(action)
          })
        }

        return rsaaMiddleware(next)(action)
      }

      return next(action)
    }
  }
}

export default createApiMiddleware()
