import { useSelector } from 'react-redux'

const useHasIntegratedUtility = () => {
  const latestUtilityIntegration = useSelector(
    (state) => state.user.user?.profile?.latest_utility_integration,
  )
  return !!latestUtilityIntegration
}

export default useHasIntegratedUtility
