import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Grid as MuiGrid, makeStyles } from '@material-ui/core'
import { spacing } from '@material-ui/system'
import WeeklyTimePicker from './WeeklyTimePicker'
import IconLabelButton from './IconLabelButton'

const Grid = styled(MuiGrid)(spacing)

const useStyles = makeStyles(() => ({
  pickerContainer: {
    padding: '12px 0px 12px 0px !important',
  },
  addIconContainer: {
    alignSelf: 'flex-start',
    marginLeft: '8px',
  },
}))

// Map array of day+time to array of days+time
// e.g. [{day: 'monday', hour: 13, minute: 0}, {day: 'tuesday', hour: 13, minute: 0}]
//       => [{days: ['monday', 'tuesday'], hour: 13, minute: 0}]
const dayTimesToDisplays = (dayTimes: DayTime[]) => {
  const dayTimesDisplays = [] as Display[]
  dayTimes.forEach((dayTime) => {
    const dayTimesDisplayItem = dayTimesDisplays.find(
      (dayTimesDisplay) =>
        dayTimesDisplay?.hour === parseInt(dayTime.hour) &&
        dayTimesDisplay?.minute === parseInt(dayTime.minute),
    )
    if (dayTimesDisplayItem) {
      dayTimesDisplayItem.days.push(dayTime.day)
    } else {
      dayTimesDisplays.push({
        days: [dayTime.day],
        hour: parseInt(dayTime.hour),
        minute: parseInt(dayTime.minute),
      })
    }
  })
  if (dayTimesDisplays.length === 0) {
    // Add a default display
    dayTimesDisplays.push({ days: [], hour: 8, minute: 30 })
  }

  // Sort by hour and minute
  dayTimesDisplays.sort((a, b) => {
    if (a.hour === b.hour) {
      return a.minute - b.minute
    } else {
      return a.hour - b.hour
    }
  })
  return dayTimesDisplays
}

// Map array of day+time to array of days+time
// e.g. [{days: ['monday', 'tuesday'], hour: 13, minute: 0}]
//       => [{day: 'monday', hour: 13, minute: 0}, {day: 'tuesday', hour: 13, minute: 0}]
const displaysToDayTimes = (displays: Display[]) => {
  const dayTimes = [] as DayTime[]
  displays.forEach((display) => {
    display.days.forEach((day) => {
      dayTimes.push({
        day,
        hour: display.hour.toString(),
        minute: display.minute.toString(),
      })
    })
  })
  return dayTimes
}

const removeDayFromDisplays = (
  displays: Display[],
  day: string | null,
): Display[] => {
  return displays.map((display) => {
    const newDays = display.days.filter((d) => d !== day)
    return { ...display, days: newDays }
  })
}

const findNewDayInDisplays = (
  displays: Display[],
  newDays: string[],
  idx: number,
) => {
  const days = displays[idx].days
  const delta = newDays.filter((day: string) => !days.includes(day))
  if (delta.length > 0) {
    return delta[0]
  }
  return null
}
export type DayTime = { day: string; hour: string; minute: string }
type Display = { days: string[]; hour: number; minute: number }
const WeeklyTimePickerList = ({
  dayTimes,
  onChange,
  onLastDelete,
  mode,
}: {
  dayTimes: DayTime[]
  onChange: (dayTimes: DayTime[]) => void
  onLastDelete: () => void
  mode: 'departure' | 'charging'
}) => {
  const [displays, setDisplays] = useState(
    dayTimesToDisplays(dayTimes || []) as Display[],
  )

  useEffect(() => {
    // Update displays when dayTimes changes (when charge schedule changes externally, e.g. when vehicle changes)
    setDisplays(dayTimesToDisplays(dayTimes || []) as Display[])
  }, [dayTimes])

  const onDisplayChange = ({
    idx,
    hour,
    minute,
    days,
  }: {
    idx: number
    hour: number
    minute: number
    days: string[]
  }) => {
    const newDisplayValue = { hour, minute, days }

    // Returns the new day that was added if any
    const newDay = findNewDayInDisplays(displays, days, idx)

    // Creates a copy of displays with the new day removed from all other displays
    const newDisplays = removeDayFromDisplays(displays, newDay)

    // Inserts the new display at the correct index
    newDisplays[idx] = newDisplayValue

    setDisplays(newDisplays)
    // do not call onChange if no days are selected on the weekly time picker
    // prevents cards with no days selected to be prematurely removed
    if (onChange && days.length > 0) {
      onChange(displaysToDayTimes(newDisplays))
    }
  }

  const onDeleteDisplay = (idx: number) => {
    const newDisplays = displays.filter((_, i) => i !== idx)
    setDisplays(newDisplays)
    if (onChange) {
      onChange(displaysToDayTimes(newDisplays))
      if (newDisplays.length === 0 && onLastDelete) {
        onLastDelete()
      }
    }
  }

  const onAddDisplay = () => {
    const time =
      mode === 'departure'
        ? { days: [], hour: 8 + displays.length, minute: 30 }
        : { days: [], hour: 7 + displays.length, minute: 0 }
    const newDisplays = [...displays, time]
    setDisplays(newDisplays)
  }

  const hasDayInEveryDisplay = () => {
    return displays.every((display) => display.days.length > 0)
  }

  const classes = useStyles()

  return (
    <Grid
      container
      spacing={6}
      justifyContent="center"
      alignItems="center"
      direction="column"
    >
      {displays.map((display, idx) => (
        <Grid key={idx} item className={classes.pickerContainer}>
          <WeeklyTimePicker
            onChange={({
              hour,
              minute,
              days,
            }: {
              hour: number
              minute: number
              days: string[]
            }) => onDisplayChange({ idx, hour, minute, days })}
            onDelete={() => onDeleteDisplay(idx)}
            days={display.days}
            hour={display.hour}
            minute={display.minute}
            mode={mode}
          />
        </Grid>
      ))}
      {hasDayInEveryDisplay() && (
        <Grid item className={classes.addIconContainer}>
          <IconLabelButton
            onClick={onAddDisplay}
            text={'Add another time'}
            iconComponent={undefined}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default WeeklyTimePickerList
