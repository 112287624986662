import NumberInputRoot, { Props as ComponentProps } from './NumberInput'
import useViewModel from './useViewModel'
import { ViewModelComponentProps } from '@/utils/typeUtilities'

type Props = ViewModelComponentProps<ComponentProps, typeof useViewModel>

export default function NumberInput(props: Props) {
  const viewModel = useViewModel(props)

  return <NumberInputRoot {...viewModel} {...props} />
}
