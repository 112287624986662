import { createResourceCollection } from '@/request'
import {
  BehavioralEnrollment,
  ChargerEnrollment,
  DeviceEnrollment,
  ThermostatEnrollment,
  VehicleEnrollment,
} from '@/types/utilityProgramEnrollment'
import { RequestTypes, ResourceMiddlewareFunction } from '@/request/types'
import { selectUtilityProgramForUserConsideringEnrollments } from '@/selectors'
import { UtilityProgramViewConfig } from '@/types/utilityProgram'
import { fetchViewConfigFromCMS } from '@/graphql/utilties/fetchViewConfigFromCMS'

async function populateViewConfigFromCMS<Enrollment extends DeviceEnrollment>(
  deviceEnrollment: Enrollment,
  parentViewConfig: UtilityProgramViewConfig | undefined,
) {
  // make call to cms to get the group level view config
  const cmsViewConfig = await fetchViewConfigFromCMS({
    utilityProgramId: deviceEnrollment.utility_program_id,
    groupName: deviceEnrollment.group?.alias,
  })
  // return the group level view config if it is available, otherwise return the program level view config
  if (!cmsViewConfig) {
    return {
      ...deviceEnrollment,
      view_config_json: parentViewConfig,
    }
  }

  return {
    ...deviceEnrollment,
    view_config_json: cmsViewConfig,
  }
}

async function populateViewConfigFromCMSMiddleware<
  Enrollment extends DeviceEnrollment,
>(
  requestData: { requestType: RequestTypes },
  data: Enrollment | Enrollment[],
  getState: () => unknown,
): ReturnType<ResourceMiddlewareFunction<Enrollment>> {
  const type = requestData.requestType
  const middlewareInput: Enrollment[] = Array.isArray(data) ? data : [data]

  if (
    middlewareInput &&
    (type === RequestTypes.Fetch ||
      type === RequestTypes.Create ||
      type === RequestTypes.Update)
  ) {
    // get the view config from a parent utility program enrollment or program
    const state = getState()
    const parentViewConfig =
      selectUtilityProgramForUserConsideringEnrollments(state)?.view_config_json

    const vehicleEnrollmentsWithViewConfigsPopulated = await Promise.all(
      middlewareInput.map((deviceEnrollment) =>
        populateViewConfigFromCMS(deviceEnrollment, parentViewConfig),
      ),
    )

    return Array.isArray(data)
      ? vehicleEnrollmentsWithViewConfigsPopulated
      : vehicleEnrollmentsWithViewConfigsPopulated[0]
  }

  return data
}

export const vehicleEnrollmentCollection =
  createResourceCollection<VehicleEnrollment>({
    name: 'vehicleEnrollments',
    apiConfig: {
      path: 'vehicle_enrollments',
      middlewares: [populateViewConfigFromCMSMiddleware],
    },
    selector: (state) => state.vehicleEnrollments,
  })

export const vehicleEnrollmentCollectionReducer =
  vehicleEnrollmentCollection.slice.reducer

export const thermostatEnrollmentCollection =
  createResourceCollection<ThermostatEnrollment>({
    name: 'thermostatEnrollments',
    apiConfig: {
      path: 'thermostat_enrollments',
      middlewares: [populateViewConfigFromCMSMiddleware],
    },
    selector: (state) => state.thermostatEnrollments,
  })

export const thermostatEnrollmentCollectionReducer =
  thermostatEnrollmentCollection.slice.reducer

export const chargerEnrollmentCollection =
  createResourceCollection<ChargerEnrollment>({
    name: 'chargerEnrollments',
    apiConfig: {
      path: 'charger_enrollments',
      middlewares: [populateViewConfigFromCMSMiddleware],
    },
    selector: (state) => state.chargerEnrollments,
  })

export const chargerEnrollmentCollectionReducer =
  chargerEnrollmentCollection.slice.reducer

export const behavioralEnrollmentCollection =
  createResourceCollection<BehavioralEnrollment>({
    name: 'behavioralEnrollments',
    apiConfig: {
      path: 'behavioral_enrollments',
      middlewares: [populateViewConfigFromCMSMiddleware],
    },
    selector: (state) => state.behavioralEnrollments,
  })

export const behavioralEnrollmentCollectionReducer =
  behavioralEnrollmentCollection.slice.reducer
