import React, { useEffect } from 'react'
import Autocomplete, {
  AutocompleteChangeReason,
} from '@material-ui/lab/Autocomplete'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import parse from 'autosuggest-highlight/parse'
import debounce from 'lodash.debounce'
import { Icon, TextField } from '@/components'
import GoogleMapReact from '../HomeSettingsManagementCard/GoogleMapReact'
import GoogleMapReactLib from 'google-map-react'
import { Box as MuiBox } from '@material-ui/core'
import { spacing } from '@material-ui/system'
import styled from 'styled-components'
import { logEvent } from '@/logging'
import { isNativeMobile } from '@/authenticated/hooks/useIsMobile'
import { requestLocationOnMobile } from '@/utils/messageUtils'
import GoogleMapsUtils from '@/utils/google-maps/GoogleMapsUtils'

const Box = styled(MuiBox)(spacing)

const GoogleMapReactWrapper = styled.div`
  height: 300px;
  width: 100%;
`
// This came from: https://material-ui.com/components/autocomplete/
const autocompleteService: {
  current?: google.maps.places.AutocompleteService
} = { current: undefined }
const googleGeocoder = GoogleMapsUtils.googleGeocoder

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}))

type GoogleMapsSearchProps = {
  handleChange: (
    event?: React.ChangeEvent<unknown> | undefined,
    newValue?: google.maps.places.AutocompletePrediction & {
      location?: GoogleMapsSearchProps['initialLocation']
    },
    method?: AutocompleteChangeReason,
  ) => void
  initialLocation?: {
    lat?: number
    lng?: number
    address?: string
    precise?: boolean
  }
  label?: string
  mapProps?: GoogleMapReactLib.Props
  hideAutocomplete?: boolean
  disableMapClick?: boolean
}

export default function GoogleMapsSearchWithMap(props: GoogleMapsSearchProps) {
  const DEBOUNCE = 400
  const classes = useStyles()
  const [inputValue, setInputValue] = React.useState('')
  const [value, setValue] = React.useState<
    | (google.maps.places.AutocompletePrediction & {
        location?: GoogleMapsSearchProps['initialLocation']
      })
    | null
  >(null)
  const [options, setOptions] = React.useState<
    google.maps.places.AutocompletePrediction[]
  >([])
  const [location, setLocation] = React.useState<
    GoogleMapsSearchProps['initialLocation']
  >(props.initialLocation)
  const loaded = React.useRef(false)
  loaded.current = true

  const autocompleteFetcher = React.useMemo(
    () =>
      debounce(
        (
          input: google.maps.places.AutocompletionRequest,
          callback: (
            results: google.maps.places.AutocompletePrediction[],
          ) => void,
        ) => {
          if (!window.google) return
          if (!autocompleteService.current) {
            autocompleteService.current =
              new window.google.maps.places.AutocompleteService()
          }
          autocompleteService.current.getPlacePredictions(
            input,
            (results, status) => {
              if (status === 'OVER_QUERY_LIMIT') {
                logEvent('GoogleMapsSearchRateLimitExceeded')
                return
              }
              if (!results) {
                // no results
                return
              }
              callback(results)
            },
          )
        },
        DEBOUNCE,
      ),
    [],
  )

  const updateLocation = async (placeId: string) => {
    if (!GoogleMapsUtils.geocode) return
    GoogleMapsUtils.geocode({ placeId: placeId }, (results) => {
      const location = {
        ...results[0].geometry.location.toJSON(),
        precise: true,
      }
      setLocation(location)
    })
  }

  const handleChange = (
    event: React.ChangeEvent<unknown> | undefined,
    newValue:
      | (google.maps.places.AutocompletePrediction & {
          location?: GoogleMapsSearchProps['initialLocation']
        })
      | null,
    method?: AutocompleteChangeReason,
  ) => {
    if (!newValue) {
      setOptions([])
      setValue(null)
      if (method === 'clear') {
        props.handleChange(event)
      }
      return
    }
    const location = newValue?.location
    if (location?.precise === false) {
      props.handleChange(event, newValue)
      delete newValue.location
      setOptions([newValue])
      setValue({ ...newValue, description: `Near ${newValue.description}` })
    } else {
      props.handleChange(event, newValue)
      setOptions(newValue ? [newValue, ...options] : [])
      updateLocation(newValue.place_id)
      setValue(newValue)
    }
  }

  const mapClick = ({
    event,
    lat,
    lng,
  }: {
    event?: React.ChangeEvent<unknown>
    lat: number
    lng: number
  }) => {
    if (!GoogleMapsUtils.geocode) return
    GoogleMapsUtils.geocode({ location: { lat, lng } }, (results) => {
      autocompleteFetcher(
        { input: results?.[0].formatted_address, types: ['address'] },
        (results) => {
          const location =
            results[0] as google.maps.places.AutocompletePrediction & {
              location?: GoogleMapsSearchProps['initialLocation']
            }
          //add the location to the object -> this is used to determine if the user wants precise address or not
          location.location = { lat, lng, precise: false }
          setLocation(location.location)
          handleChange(event, location)
        },
      )
      autocompleteFetcher.flush()
    })
  }

  // Set the location if the user has already selected a location
  useEffect(() => {
    if (props?.initialLocation && !inputValue) {
      const { lat, lng, address, precise } = props.initialLocation
      if (precise === false && lat && lng) {
        mapClick({ lat, lng })
      } else if (address) {
        autocompleteFetcher(
          { input: address, types: ['address'] },
          (results) => {
            const location = results[0]
            handleChange(undefined, location)
          },
        )
        autocompleteFetcher.flush()
      }
    }
  }, [props?.initialLocation])

  useEffect(() => {
    if (props.hideAutocomplete) {
      setInputValue('')
    }
  }, [props?.hideAutocomplete])

  // Get the coords from mobile and use them to set address
  useEffect(() => {
    const handleGetCoords = (event: MessageEvent) => {
      if (event.data.event?.type === 'location') {
        const lat: number = event.data.event.payload?.latitude ?? 0
        const lng: number = event.data.event.payload?.longitude ?? 0
        mapClick({ lat, lng })
      }
    }

    window.addEventListener('message', handleGetCoords)
    return () => {
      window.removeEventListener('message', handleGetCoords)
    }
  }, [])

  const handleCurrentLocationClick = () => {
    if (isNativeMobile()) {
      const pathname = window.location.pathname
      // url to start the app at since the webview gets reloaded
      // with permission changes sometimes
      const path =
        pathname === '/onboarding'
          ? '/app'
          : pathname === '/settings'
          ? '/settings?location-open=true'
          : pathname

      requestLocationOnMobile(path)
      return
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude: lat, longitude: lng } = position.coords
        mapClick({ lat, lng })
      })
    }
  }

  return (
    <>
      {!props.hideAutocomplete && (
        <Autocomplete
          id="google-map-demo"
          data-testing-id="address-input"
          getOptionLabel={(option) =>
            typeof option === 'string' ? option : option.description
          }
          filterOptions={(x) => x}
          options={options}
          autoComplete
          closeIcon={<Icon name="X" size={22} />}
          popupIcon={<Icon name={'ChevronDown'} />}
          onInputChange={(event, newInputValue, reason) => {
            setInputValue(newInputValue)
            if (reason === 'reset') return
            autocompleteFetcher(
              { input: newInputValue, types: ['address'] },
              (results) => {
                setOptions(results || [])
              },
            )
          }}
          inputValue={inputValue}
          includeInputInList
          onChange={handleChange}
          value={value}
          renderInput={(params) => {
            const label = props.label ?? 'Add a location'
            return (
              <div className="mb-5">
                <TextField
                  variant={undefined} // Applies border
                  {...params}
                  label={label}
                  fullWidth
                  required
                />
              </div>
            )
          }}
          renderOption={(option: google.maps.places.AutocompletePrediction) => {
            // Sentry was reporting:
            // React ErrorBoundary Error: undefined is not an object (evaluating 'option.structured_formatting.main_text_matched_substrings')
            // This is ambiguous as to whether it's the `option` or `option.structured_formatting` that is undefined
            // So I did not change the type of the `option` parameter. Instead, just doing some optional chaining
            const matches =
              option?.structured_formatting?.main_text_matched_substrings
            const mainText = option?.structured_formatting?.main_text
            const secondaryText = option?.structured_formatting?.secondary_text
            if (!matches || !mainText || !secondaryText) {
              return <span className="text-themed-base-500">No options</span>
            }
            const parts = parse(
              mainText,
              matches.map((match) => [
                match.offset,
                match.offset + match.length,
              ]),
            )
            return (
              <Grid container alignItems="center">
                <Grid item>
                  <LocationOnIcon className={classes.icon} />
                </Grid>
                <Grid item xs>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      data-place-id={option?.place_id}
                      style={{ fontWeight: part.highlight ? 700 : 400 }}
                    >
                      {part.text}
                    </span>
                  ))}

                  <Typography variant="body2" color="textSecondary">
                    {secondaryText}
                  </Typography>
                </Grid>
              </Grid>
            )
          }}
        />
      )}
      <Box>
        <GoogleMapReactWrapper>
          <GoogleMapReact
            text={'Home'}
            showRadius
            {...props.mapProps}
            lat={location?.lat}
            lng={location?.lng}
            defaultZoom={location?.lat && location?.lng ? 17 : 2}
            onClick={(...args) => {
              if (props.disableMapClick) {
                return
              }
              mapClick(...args)
              if (props?.mapProps?.onClick) {
                props.mapProps.onClick(...args)
              }
            }}
            onCurrentLocationClick={handleCurrentLocationClick}
          />
        </GoogleMapReactWrapper>
      </Box>
    </>
  )
}
