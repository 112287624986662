import './styles.scss'
import formatClasses from '../../utils/classes/formatClasses'
import { ForwardedRef, forwardRef, HTMLAttributes } from 'react'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  // No props provided
}

export type Ref = ForwardedRef<HTMLDivElement>

function Chip(props: Props, ref?: ForwardedRef<HTMLDivElement>) {
  const classes = {
    chip: formatClasses(['chip', props.className]),
  }

  return (
    <div className={classes.chip} ref={ref} {...props}>
      {props.children}
    </div>
  )
}

export default forwardRef(Chip)
