export function useDataLoader(objectState) {
  const shouldLoadData =
    !objectState.data &&
    !objectState.isLoading &&
    !Object.keys(objectState.errors).length
  const error = objectState.errors?.detail

  return [objectState.data, objectState.isLoading, error, shouldLoadData]
}

export const shouldLoadData = (data, isLoading, errors) => {
  return !data && !isLoading && !Object.keys(errors).length
}
