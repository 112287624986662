import { Flex, Sheet, Text } from '@/components'
import { Identifiable } from '@/types'
import React, { ButtonHTMLAttributes, useCallback } from 'react'

export interface Props
  extends Identifiable<ButtonHTMLAttributes<HTMLButtonElement>> {
  title: string
  onClick?: () => void
  subtitle?: string
  sheetText?: string
  disabled?: boolean
}

export function FullwidthButton(props: Props) {
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault()

      if (props.onClick) {
        props.onClick()
      }
    },
    [props.onClick],
  )

  return (
    <button
      className="bg-themed-white
      hover:bg-themed-base-100
      active:bg-themed-base-200
      disabled:bg-themed-base-100
      py-3
      px-4
      rounded-[8px]
      border-3
      border-themed-base-200
      outline-none
      min-w-full
      border-solid
      cursor-pointer
      "
      disabled={props.disabled}
      id={props.id}
      onClick={handleClick}
    >
      <Flex container gap="4px" direction="column" alignItems="flex-start">
        <Flex
          container
          gap="20px"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex item>
            <Text variant="body1">{props.title}</Text>
          </Flex>
          {props.sheetText && !props.disabled && (
            <Flex item className="font-bold">
              <Sheet color="blue-100">
                <Text variant="label" className="text-themed-blue-500">
                  {props.sheetText}
                </Text>
              </Sheet>
            </Flex>
          )}
        </Flex>
        <Flex item>
          <Text variant="body4">{props.subtitle}</Text>
        </Flex>
      </Flex>
    </button>
  )
}
