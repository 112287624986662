import { match } from 'ts-pattern'

export enum VehicleEligibilityRequirement {
  ACTIVE = 'ACTIVE',
  VEHICLE_MAKE = 'VEHICLE_MAKE',
  BEV = 'BEV',
  CONTROLLABLE = 'CONTROLLABLE',
  AUTH_METHOD = 'AUTH_METHOD',
  METER = 'METER',
  GRID_SUPPORT = 'GRID_SUPPORT',
  ALLOWED = 'ALLOWED',
  NOT_ALLOWED = 'NOT_ALLOWED',
}

export function vehicleIneligibilityReasonContent(
  key: VehicleEligibilityRequirement,
) {
  return match(key)
    .returnType<string>()
    .with(
      VehicleEligibilityRequirement.CONTROLLABLE,
      () => 'Vehicle must be controllable to enroll for this program.',
    )
    .with(
      VehicleEligibilityRequirement.VEHICLE_MAKE,
      () => `We don't support this vehicle currently for this program.`,
    )
    .with(
      VehicleEligibilityRequirement.ACTIVE,
      () => 'Vehicle must be active to enroll in this program.',
    )
    .with(
      VehicleEligibilityRequirement.BEV,
      () => 'Only battery electric vehicles are eligible for this program.',
    )
    .with(
      VehicleEligibilityRequirement.AUTH_METHOD,
      () => 'Vehicle authentication method is not supported.',
    )
    .with(
      VehicleEligibilityRequirement.METER,
      () => 'Vehicle meter configuration is not compatible.',
    )
    .with(
      VehicleEligibilityRequirement.GRID_SUPPORT,
      () => 'Vehicle does not support required grid functions.',
    )
    .with(
      VehicleEligibilityRequirement.ALLOWED,
      () => 'Vehicle is not on the allowed list for this program.',
    )
    .with(
      VehicleEligibilityRequirement.NOT_ALLOWED,
      () => 'Vehicle is specifically excluded from this program.',
    )
    .exhaustive()
}
