import SurveyObserver from '@/app/components/SurveyObserver'
import ErrorBoundary, {
  LocationTag,
} from '../../../../authenticated/components/ErrorBoundary'
import PageLoader from '../../../../authenticated/components/layout/PageLoader'
import OnboardingProgressCard from '../../../features/onboarding/OnboardingProgressCard'
import { DashboardLayout } from '../../../layouts'
import HomeSection from './sections/HomeSection'
import ThermostatSection, {
  ThermostatSectionHeader,
} from './sections/ThermostatSection'
import VehicleChargerSection from './sections/VehicleChargerSection'
import VehicleSection from './sections/VehicleSection'
import useViewModel from './useViewModel'
import { DeviceSelection } from '@/app/components/DeviceSelectionGallery/DeviceSelectionGallery.types'
import { AnimatePresence, motion } from 'framer-motion'
import { AlertMessage } from '@/app/components'
import VehicleSectionHeader from './sections/VehicleSection/VehicleSectionHeader'
import BrandedHeader from '@/app/components/NavigationPage/WhitelabeledNavHeader/BrandedHeader'
import { useProgramDisabledDevices } from '@/hooks/useProgramDisabledDevices'
import { LoadingAnimation } from '@/components'

export interface Props {
  loading: boolean
  firstName: string
  selectedDeviceState: [
    DeviceSelection | null,
    (device: DeviceSelection) => void,
  ]
}

function getDeviceSectionViews(device: DeviceSelection | null) {
  if (!device) {
    return null
  }

  const {
    deviceIsDisabled: deviceDisabledByProgram,
    disabledMessage,
    isLoading,
  } = useProgramDisabledDevices()

  if (isLoading) {
    return {
      DeviceSection: <LoadingAnimation type="falling" centered />,
      DeviceHeader: null,
    }
  }

  if (deviceDisabledByProgram(device.type)) {
    return {
      DeviceSection: (
        <AlertMessage variant="info">{disabledMessage}</AlertMessage>
      ),
      DeviceHeader: null,
    }
  }

  return {
    vehicle: {
      DeviceSection: <VehicleSection vehicleId={device.id} />,
      DeviceHeader: <VehicleSectionHeader vehicleId={device.id} />,
    },
    home: {
      DeviceSection: <HomeSection homeId={device.id} />,
      DeviceHeader: null,
    },
    thermostat: {
      DeviceSection: <ThermostatSection thermostatId={device.id} />,
      DeviceHeader: <ThermostatSectionHeader thermostatId={device.id} />,
    },
    vehicleCharger: {
      DeviceSection: <VehicleChargerSection vehicleChargerId={device.id} />,
      DeviceHeader: null,
    },
  }[device.type]
}

function DashboardRoot(props: ReturnType<typeof useViewModel>) {
  if (props.loading) {
    return <PageLoader />
  }

  const deviceViews = getDeviceSectionViews(props.selectedDevice)
  const DeviceSection = deviceViews?.DeviceSection ?? null
  const DeviceHeader = deviceViews?.DeviceHeader ?? null

  return (
    <>
      <SurveyObserver
        observedSurveys={['TAFNPS', 'UtilityProgramNPS', 'UtilityNPS']}
      />
      <BrandedHeader />
      <DashboardLayout
        title={`Welcome back, ${props.firstName}`}
        selectedDevice={props.selectedDevice}
        setSelectedDevice={props.setSelectedDevice}
      >
        <AnimatePresence>
          {props.showAlert && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="absolute w-full -top-7"
            >
              <AlertMessage variant="info">
                Thanks for your feedback!
              </AlertMessage>
            </motion.div>
          )}
        </AnimatePresence>
        {DeviceHeader}
        <ErrorBoundary
          location={LocationTag.Dashboard}
          functionalityDescription={'Onboarding Progress Card'}
          severity="critical"
          fallbackOnCard
        >
          <OnboardingProgressCard />
          <SurveyObserver observedSurveys={['SMUDMEO', 'GexaCompleteSwitch']} />
        </ErrorBoundary>
        {DeviceSection}
      </DashboardLayout>
    </>
  )
}

export default function Dashboard() {
  const viewModel = useViewModel()

  return <DashboardRoot {...viewModel} />
}
