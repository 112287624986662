import React, { Component } from 'react'

import HeroSection from '../components/HeroSection/HeroSection404'

class NotFound extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="main _use-deprecated-bootstrap _use-deprecated-style">
          <HeroSection />
        </div>
      </React.Fragment>
    )
  }
}

export default NotFound
