export interface VehicleConnectivity {
  connectivity_method: ConnectivityMethod
  manufacturer_connection: ManufacturerConnection
  vehicle_signal: VehicleSignal
  subscription_information: SubscriptionInformation
  id: string
}

export interface SubscriptionInformation {
  is_missing_subscription: boolean
  subscription_name: string | null
  url: null
  subscription_details: null
}

export interface ConnectivityMethod {
  portal: string
  method: string
  should_update: boolean
}

export enum ManufacturerConnectionReason {
  VehicleNotFound = 'vehicle_not_found',
  PublicKeyNotAttached = 'public_key_not_attached',
  AuthenticationInactive = 'authentication_inactive',
}
export interface ManufacturerConnection {
  is_auth_expired: boolean
  reason: ManufacturerConnectionReason | ''
}

export interface VehicleSignal {
  last_ping_time: string
}
