import { VehicleChargeSchedule, VehicleChargerChargeSchedule } from '@/types'
import * as schedule from '../actions/schedule'
import * as vehicleActions from '../actions/vehicles'
import { getApiErrorMessage } from '../utils/errorUtilities'
import { PayloadAction } from '@reduxjs/toolkit'

type State = {
  chargeSchedules:
    | (VehicleChargeSchedule & VehicleChargerChargeSchedule)[]
    | null
  hasTriedFetching: boolean
  isLoading: boolean
  isUpdateProfileLoading: boolean
  errors: Record<string, unknown>
}

const initialState: State = {
  chargeSchedules: null,
  hasTriedFetching: false,
  isLoading: false,
  isUpdateProfileLoading: false,
  errors: {},
}

export default (state = initialState, action: PayloadAction<any>): State => {
  switch (action.type) {
    case schedule.CHARGE_SCHEDULES_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasTriedFetching: true,
      }
    case schedule.CHARGE_SCHEDULES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        chargeSchedules: action.payload,
      }
    case schedule.CHARGE_SCHEDULES_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case schedule.CHARGE_SCHEDULES_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        chargeSchedules:
          state.chargeSchedules?.map((chargeSchedule) =>
            chargeSchedule.id === action.payload.id
              ? action.payload
              : chargeSchedule,
          ) ?? null,
      }
    case vehicleActions.CHANGE_VEHICLE_SUCCESS:
    case vehicleActions.DISABLE_VEHICLE_ACTIONS_SUCCESS:
      return {
        chargeSchedules: null,
        hasTriedFetching: false,
        isLoading: false,
        isUpdateProfileLoading: false,
        errors: {},
      }
    case schedule.CHARGE_SCHEDULES_FAILURE:
    case schedule.CHARGE_SCHEDULES_UPDATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: getApiErrorMessage(action.payload),
      }
    case schedule.MARK_CHARGE_SCHEDULE_REQUEST:
      return {
        ...state,
        isUpdateProfileLoading: true,
      }
    case schedule.MARK_CHARGE_SCHEDULE_SUCCESS:
      return {
        ...state,
        isUpdateProfileLoading: false,
      }
    case schedule.MARK_CHARGE_SCHEDULE_FAILURE:
      return {
        ...state,
        isUpdateProfileLoading: false,
        errors: getApiErrorMessage(action.payload),
      }
    case schedule.SET_CHARGE_SCHEDULE_ERROR:
      return {
        ...state,
        errors: { detail: action.payload },
      }
    default:
      return state
  }
}
