import ComfortSettingSlideEditor from '@/app/features/devices/thermostat/ComfortSettingSlideEditor'
import { AdaptiveCardRowLayout } from '@/app/layouts'
import SettingDeltaSlideEditor from './SettingDeltaSlideEditor'
import { AnimatePresence, motion } from 'framer-motion'
import Oops from '@/authenticated/components/Oops'
import { ThermostatComfortSetting } from '@/types/thermostatComfortSetting'
import { useAppSelector } from '@/hooks'
import { thermostatsCollection } from '@/reducers/thermostats'

type Props = {
  editingEnabled: boolean
  comfortSettingsError: boolean
  thermostatId: number
  comfortSettings: ThermostatComfortSetting[]
  onComfortSettingsChange?: () => void
}

export default function EventSettingsRow(props: Props) {
  const isNest = useAppSelector((state) => {
    const thermostat = thermostatsCollection.selectors.selectById(
      state,
      props.thermostatId,
    )
    return thermostat?.portal === 'Nest'
  })

  return (
    <AnimatePresence>
      {props.editingEnabled && (
        <motion.div
          style={{ overflow: 'hidden' }}
          initial={{ height: 0 }}
          animate={{ height: 'auto' }}
          exit={{ height: 0 }}
        >
          <AdaptiveCardRowLayout.CardRow>
            {props.comfortSettingsError ? (
              <Oops
                functionalityDescription={'Grid support comfort settings'}
                eventId={undefined}
              />
            ) : isNest ? (
              <SettingDeltaSlideEditor
                thermostatId={props.thermostatId}
                comfortSettings={props.comfortSettings}
                onChange={props.onComfortSettingsChange}
              />
            ) : (
              <ComfortSettingSlideEditor
                thermostatId={props.thermostatId}
                comfortSettings={props.comfortSettings}
                onChange={props.onComfortSettingsChange}
              />
            )}
          </AdaptiveCardRowLayout.CardRow>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
