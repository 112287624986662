import { ViewConfigButton } from '@/types/utilityProgram'
import StatusButton, { Props as StatusButtonProps } from '../StatusButton'
import { RequestStatus } from '@/request/types'

export interface Props extends Omit<StatusButtonProps, 'status'> {
  config?: ViewConfigButton
  status?: RequestStatus
}

export default function ConfigurableButton(props: Props) {
  const { children, config, status, ...buttonProps } = props
  return (
    <StatusButton
      {...buttonProps}
      status={status ?? RequestStatus.Idle}
      style={{
        ...(config?.backgroundColor
          ? { backgroundColor: config.backgroundColor }
          : {}),
        ...(config?.color ? { color: config.color } : {}),
      }}
      id={buttonProps.id}
    >
      {config?.text ?? children}
    </StatusButton>
  )
}
