import { useEffect, useState } from 'react'
import { useNavigation } from '@/app/hooks'
import useBasePath from '@/hooks/useBasePath'
import { remoteConfig } from '@/firebase'
import { useProfile } from '@/hooks/useProfileId'
import { isNativeMobile } from '@/authenticated/hooks/useIsMobile'
import {
  NOTIFICATION_PERMISSIONS_TYPE,
  postEventToMobileIfPresent,
  REQUEST_NOTIFICATION_PERMISSION_STATUS_ACTION,
  SHOW_NOTIFICATION_PERMISSIONS_MODAL_ACTION,
} from '@/utils/messageUtils'
import { enrolledUtilityProgramsCollection } from '@/reducers/enrolledUtilityPrograms'
import { useDispatch } from 'react-redux'
import useProgramFromParams from '@/app/hooks/useProgramFromParams'
import { useAppSelector } from '@/hooks'
import { Vehicle } from '@/types'
import useEnrolledDeviceSelections from '@/app/hooks/useEnrolledDeviceSelections'

export function useViewModel() {
  const dispatch = useDispatch()
  const navigate = useNavigation()
  const basePath = useBasePath() ?? ''
  const { utilityProgram } = useProgramFromParams()
  const isUtilityPath = remoteConfig?.getWhitelabelId(basePath) !== null
  const profile = useProfile()
  const vehicles: Vehicle[] = useAppSelector(
    (state) => state.vehicles.vehicles ?? [],
  )
  const { getEnrolledDeviceRoute } = useEnrolledDeviceSelections()
  const { data: enrolledPrograms } =
    enrolledUtilityProgramsCollection.useFetch()

  const enrolledProgram = enrolledPrograms.find(
    (p) => p.utility_program.id === utilityProgram?.id,
  )
  const behavioralDeviceEnrolled =
    (enrolledProgram?.behavioral_enrollments ?? []).length > 0

  const nonControllableVehicles = vehicles.filter(
    (v: Vehicle) => v.controllable === false,
  )
  const hasNonControllableVehiclesInProgram = (
    enrolledProgram?.vehicle_enrollments ?? []
  ).some((ve) => nonControllableVehicles.some((v) => v.id === ve.vehicle_id))

  const [askedForNotificationPermission, setAskedForNotificationPermission] =
    useState(false)
  const [notificationPermissionsEnabled, setNotificationPermissionsEnabled] =
    useState(true)

  const handleNotificationPermissions = (event: MessageEvent) => {
    if (event.data?.event?.type !== NOTIFICATION_PERMISSIONS_TYPE) {
      return
    }
    setNotificationPermissionsEnabled(event.data?.event?.status === 'enabled')
  }

  useEffect(() => {
    dispatch(enrolledUtilityProgramsCollection.actions.invalidate())
    if (isNativeMobile()) {
      // Request the notification permission status from the mobile app
      // The mobile app will send back a message handled in handleNotificationPermissions
      postEventToMobileIfPresent(REQUEST_NOTIFICATION_PERMISSION_STATUS_ACTION)
      window.addEventListener('message', handleNotificationPermissions)
    }
  }, [])

  // Check if we are on the mobile app and a user has a behavioral device or non-controllable vehicle
  // If so, we should ask for notification permissions if they are not already enabled for the mobile app
  const shouldAskForNotificationPermission =
    isNativeMobile() &&
    !notificationPermissionsEnabled &&
    !askedForNotificationPermission &&
    (behavioralDeviceEnrolled || hasNonControllableVehiclesInProgram)

  const askForMobileNotificationPermission = () => {
    postEventToMobileIfPresent(SHOW_NOTIFICATION_PERMISSIONS_MODAL_ACTION)
    setAskedForNotificationPermission(true)
  }

  const onFinish = async () => {
    if (!profile?.plan) {
      if (isUtilityPath) {
        navigate.pushRetainingParams(`/${basePath}/plan-select`)
        return
      } else {
        navigate.pushRetainingParams(`/plan-select`)
        return
      }
    }

    navigate.push(getEnrolledDeviceRoute())
  }

  return {
    onFinish,
    askForMobileNotificationPermission,
    shouldAskForNotificationPermission,
  }
}
