import { getApiErrorMessage } from '../utils/errorUtilities'
import { RequestTypeEnum } from '../types/requests'

export interface Action {
  type: string
  payload?: any
  meta?: any
}

export type Errors = { [key: string]: any } | null

export interface RequestState {
  isLoading: boolean
  errors: Errors
}

export enum RequestStatus {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

export type RequestStatusPayload = {
  status: RequestStatus
  errors: Errors
}

export interface ResourceRequestState<ResourceType> extends RequestState {
  resource: ResourceType
}

export function combine<State extends RequestState>(
  initState: State,
  action: Action,
  ...reducerFunctions: Array<(state: State, action: Action) => State>
) {
  return reducerFunctions.reduce(
    (state, reducerFunction) => reducerFunction(state, action),
    initState,
  )
}

export function awaitResponse<State extends RequestState>(state: State) {
  return {
    ...state,
    isLoading: true,
  }
}

export function succeed<State extends RequestState>(state: State) {
  return {
    ...state,
    isLoading: false,
    errors: {},
  }
}

// @todo move everything over to this new format which treats errors as a null field unless
// a failure occurs (as opposed to an empty object)
export function succeedNew<State extends RequestState>(state: State) {
  return {
    ...state,
    isLoading: false,
    errors: null,
  }
}

export function fail<State extends RequestState>(state: State, action: Action) {
  return {
    ...state,
    isLoading: false,
    errors: getApiErrorMessage(action.payload),
  }
}

export function save<State extends RequestState>(
  stateKey: string,
  payloadKey?: string,
) {
  return (state: State, action: Action) => {
    return {
      ...state,
      [stateKey]: payloadKey ? action.payload[payloadKey] : action.payload,
    }
  }
}

export const savePage = <State extends RequestState>(stateKey: string) =>
  save<State>(stateKey, 'results')

/**
 * Reducer used for paginated GET query responses
 */
export function queryReducer<ResourceType>(TypeEnum: RequestTypeEnum) {
  /**
   * Note: Assumes a paginated response with
   */
  interface State extends RequestState {
    resource: ResourceType[] | null
  }
  const initialState: State = {
    resource: null,
    isLoading: false,
    errors: null,
  }
  return (state = initialState, action: Action) => {
    switch (action.type) {
      case TypeEnum.Success:
        return combine(state, action, succeedNew, savePage('resource'))
      case TypeEnum.Request:
        return combine(state, action, awaitResponse)
      case TypeEnum.Failure:
        return combine(state, action, fail)
      default:
        return state
    }
  }
}

/**
 * Reducer used for GET responses where a single value is returned
 */
export function valueReducer<ResourceType>(TypeEnum: RequestTypeEnum) {
  interface State extends RequestState {
    resource: ResourceType | null
  }
  const initialState: State = {
    resource: null,
    isLoading: false,
    errors: null,
  }
  return (state = initialState, action: Action) => {
    switch (action.type) {
      case TypeEnum.Success:
        return combine(state, action, succeedNew, save('resource'))
      case TypeEnum.Request:
        return combine(state, action, awaitResponse)
      case TypeEnum.Failure:
        return combine(state, action, fail)
      default:
        return state
    }
  }
}
