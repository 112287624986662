import { Box as MuiBox } from '@material-ui/core'

import { green } from '@material-ui/core/colors'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { UsageSectionHeaderTypography } from '../UsageSectionTypography'
import BillingStartDaySelectionPrompt from '../UtilitySetup/BillingStartDaySelectionPrompt'
import { DollarSign } from 'react-feather'

const Box = styled(MuiBox)(spacing)

const useStyles = makeStyles((theme) => ({
  billingStartPrompt: {
    display: 'inline-block',
  },
  textPrefixIcon: {
    display: 'inline-block',
    marginRight: theme.spacing(2),
  },
  dollarIcon: {
    color: green[500],
  },
}))

const BillingCycleSection = ({
  billingStartDay,
  setBillingStartDay,
  isLoading,
}) => {
  const classes = useStyles()

  return (
    <Box>
      <UsageSectionHeaderTypography>Billing Cycle</UsageSectionHeaderTypography>
      <DollarSign
        className={clsx(classes.textPrefixIcon, classes.dollarIcon)}
        width="1.05rem"
        height="1.05rem"
      />
      <BillingStartDaySelectionPrompt
        billingStartDay={billingStartDay}
        setBillingStartDay={setBillingStartDay}
        isLoading={isLoading}
        className={clsx(classes.billingStartPrompt)}
      />
    </Box>
  )
}

export default BillingCycleSection
