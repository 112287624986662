import BrandedPage from '@/app/pages/ava/BrandedPage'
import { programWithEvaluatedConfigTemplateFromUtility } from '@/reducers/utilityPrograms'
import React from 'react'
import UtilityProgramPage from '@/app/pages/program-enrollment/UtilityProgramPage'
import useProgramFromParams from '@/app/hooks/useProgramFromParams'
import LinkDiagram from 'features/logos/LinkDiagram'
import OptiwattLogo from '@/app/pages/autogrid/optiwatt-logo.webp'
import { Button } from '@/components'
import { IconNames } from '@/components/Icon/Icon'
import IconList, { IconListItem } from '@/components/IconList/IconList'
import { useNavigation } from '@/app/hooks'
import AvaLogo from '@/app/img/ava-logo.png'
import { utilityProgramEnrollmentCollection } from '@/reducers/utilityProgramEnrollments'

type InformationList = {
  featherIcon: IconNames
  title: string
  subtitle: string
  tag: string
  tagTextColor: string
  tagBackgroundColor: string
}

export default function ProgramEnrollmentOfferPage() {
  const { utility, utilityProgram } = useProgramFromParams()
  const navigate = useNavigation()

  const viewConfig = utilityProgram
    ? programWithEvaluatedConfigTemplateFromUtility(utilityProgram, {
        utility_name: utility?.name ?? '',
      }).view_config_json.onboarding
    : null

  const onNext = () => {
    if (!utilityProgram) {
      return
    }
    navigate.push('enroll')
  }

  const logo =
    process.env.REACT_APP_ENVIRONMENT !== 'production'
      ? AvaLogo
      : utilityProgram?.view_config_json?.logoUrl ?? ''

  return (
    <BrandedPage
      id="utility-plan-offer"
      title={viewConfig?.title ?? 'Fetching Utility Programs...'}
      subtitle={viewConfig?.subtitle ?? ''}
    >
      <UtilityProgramPage>
        <BrandedPage.Content>
          <div className="mb-8">
            <LinkDiagram logos={[logo, OptiwattLogo]} />
          </div>
          <IconList>
            {viewConfig?.informationList.map(
              (infoList: InformationList, index: number) => {
                return (
                  <IconListItem
                    title={infoList.title}
                    subtitle={infoList.subtitle}
                    featherIcon={infoList.featherIcon}
                    sheetProps={{
                      text: infoList.tag,
                      color: infoList.tagBackgroundColor,
                      textColor: infoList.tagTextColor,
                    }}
                  />
                )
              },
            )}
          </IconList>
          <BrandedPage.ButtonContainer>
            <Button id={'next-button'} onClick={onNext}>
              Next
            </Button>
          </BrandedPage.ButtonContainer>
        </BrandedPage.Content>
      </UtilityProgramPage>
    </BrandedPage>
  )
}
