import { Identifiable } from '@/types'
import { useState } from 'react'
import { useMousePress } from '@/authenticated/hooks/useMousePress'

type Props = Identifiable<{
  checked?: boolean
  onChange?: (checked: boolean) => void
  defaultChecked?: boolean
  disabled?: boolean
}>

export default function Toggle(props: Props) {
  const isControlled =
    props.checked !== undefined && props.onChange !== undefined
  const [localChecked, setLocalChecked] = useState(
    props.defaultChecked || false,
  )

  const isMousePress = useMousePress()

  const currentChecked = isControlled ? props.checked : localChecked

  const toggle = () => {
    if (props.disabled) return
    if (!isControlled) {
      setLocalChecked(!localChecked)
    }
    props.onChange?.(!currentChecked)
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    // toggle on enter or space
    if (e.key === 'Enter' || e.key === ' ') {
      toggle()
    }
  }

  return (
    <div
      id={props.id}
      onMouseDown={isMousePress ? toggle : undefined}
      onClick={!isMousePress ? toggle : undefined}
      onKeyDown={onKeyDown}
      role="button"
      tabIndex={0}
      aria-pressed={props.checked ? 'true' : 'false'}
      className={`cursor-pointer w-9 h-3.5 flex items-center rounded-full duration-150 ease-in-out ${
        currentChecked ? 'bg-themed-blue-400' : 'bg-themed-grey-200'
      } ${props.disabled ? 'opacity-50' : ''}`}
    >
      <div
        aria-hidden="true"
        className={`transform duration-150 w-5 h-5 ${
          currentChecked ? 'translate-x-[16px]' : 'translate-x-0'
        } ${
          currentChecked ? 'bg-themed-grey-200' : 'bg-themed-grey-400'
        } rounded-full shadow`}
      ></div>
    </div>
  )
}
