import { selectUtilityProgramEnrollmentForUser } from '@/reducers/utilityProgramEnrollments'
import { createDeviceEnrollmentsForUserSelector } from '@/selectors'
import { ID } from '@/types/model'
import { createSelector } from '@reduxjs/toolkit'

const selectUtilityProgramEnrollmentActive = createSelector(
  [
    (state) => createDeviceEnrollmentsForUserSelector('vehicle')(state),
    (state) => selectUtilityProgramEnrollmentForUser(state),
    (state, vehicleId?: ID) => vehicleId,
  ],

  (vehicleEnrollments, utilityProgramEnrollment, vehicleId) => {
    // if the user does not have a utility program enrollment, or they unenrolled
    // wholly from the utility program, then the utility program is not active
    if (!utilityProgramEnrollment || utilityProgramEnrollment.unenrolled_at) {
      return false
    }
    // if a vehicle id is not provided, and the utility program enrollment is active,
    // then the utility program is active
    if (!vehicleId) {
      return true
    }
    // if a vehicle id is provided, return whether the vehicle is enrolled or not
    const enrollment = vehicleEnrollments.find(
      (enrollment) => enrollment.vehicle_id === vehicleId,
    )
    // if the vehicle enrollment exists and hasn't been unenrolled, then the utility program is active
    if (!enrollment || enrollment?.unenrolled_at) {
      return false
    }
    return true
  },
)

export default selectUtilityProgramEnrollmentActive
