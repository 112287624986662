import styled from 'styled-components'
import { Typography as MuiTypography, Box as MuiBox } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { spacing } from '@material-ui/system'
import { makeStyles } from '@material-ui/styles'
import DaySelector from '../../DaySelector'
import dayjs from 'dayjs'

const Typography = styled(MuiTypography)(spacing)
const Box = styled(MuiBox)(spacing)
const useStyles = makeStyles((theme) => ({
  selectText: {
    display: 'inline-block',
    fontSize: '0.75rem',
  },
  inlineSelector: {
    display: 'inline-block',
    fontSize: '0.75rem',
    margin: '0 2px',
  },
  daySelectorButton: {
    minWidth: 0,
    padding: '0 0.4rem',
    borderRadius: '10px',
  },
  skelly: {
    verticalAlign: 'middle',
    display: 'inline-block',
  },
}))

const BillingStartDaySelectionPrompt = ({
  billingStartDay,
  setBillingStartDay,
  isLoading = false,
  ...restProps
}) => {
  const classes = useStyles()

  return (
    <Box {...restProps}>
      <Typography className={classes.selectText} variant="body1">
        Starts on the&nbsp;
      </Typography>
      {isLoading ? (
        <Skeleton className={classes.skelly} animation="wave" width="4rem" />
      ) : (
        <DaySelector
          day={billingStartDay}
          onSelect={setBillingStartDay}
          buttonClassName={classes.daySelectorButton}
          fontSize="0.75rem"
          className={classes.inlineSelector}
          textRender={(day) =>
            day ? dayjs().set('date', day).format('Do') : 'Select Day'
          }
        />
      )}
      <Typography className={classes.selectText} variant="body1">
        &nbsp;of every month
      </Typography>
    </Box>
  )
}

export default BillingStartDaySelectionPrompt
