import { SelectInput, SelectInputProps } from '../index'

function indexToBooleanValue(index: number | null) {
  if (index === null) {
    return null
  }
  return index === 0 ? true : false
}

function booleanValueToIndex(value?: boolean | null) {
  if (value === null || value === undefined) {
    return null
  }
  return value ? 0 : 1
}

export interface Props
  extends Omit<SelectInputProps, 'initialValue' | 'onChange' | 'options'> {
  initialValue?: boolean | null
  onChange: (value: boolean | null) => void
}

export default function BooleanInput({
  initialValue,
  onChange,
  ...props
}: Props) {
  const options = ['Yes', 'No']
  const booleanInitialValue = booleanValueToIndex(initialValue)
  const handleOnChange = (value: number | null) => {
    onChange(indexToBooleanValue(value))
  }

  return (
    <SelectInput
      {...props}
      options={options}
      initialValue={booleanInitialValue}
      onChange={handleOnChange}
    />
  )
}
