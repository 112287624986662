import config from '../config'
import { store } from '../store'

export function logServerEvent(
  event: string,
  authenticated = true,
  eventId: string | null = null,
) {
  const { auth } = store.getState()
  const token = auth?.access?.token

  fetch(`${config.apiGateway.URL}/api/log_event/`, {
    method: 'POST',
    body: JSON.stringify({
      event: event,
      cookies: document.cookie,
      event_id: eventId,
    }),
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      ...(authenticated && { Authorization: `Bearer ${token}` }),
    },
  }).catch(() => {
    console.log('Could not successfully log event.')
  })
}
