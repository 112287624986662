import { getChargeSchedules, updateChargeSchedule } from '@/actions/schedule'
import { ConfigurableFunctionality } from '@/app/components'
import AllowChargeControlCard from '@/authenticated/components/ChargeSchedule/AllowChargeControlCard'
import PlanDetailsCard from '@/authenticated/components/ChargeSchedule/PlanDetailsCard'
import ErrorBoundary, {
  LocationTag,
} from '@/authenticated/components/ErrorBoundary'
import useVehicleChargeSchedulesFetcher from '@/authenticated/hooks/useVehicleChargeSchedulesFetcher'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { selectVehicleEnrollmentForUser } from '@/selectors/vehicleEnrollments'
import { Vehicle, VehicleChargeSchedule, VehicleEnrollment } from '@/types'
import { canCharge } from '@/utils/vehicles/permissions'
import { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { enrollmentIsUnenrolled } from '@/types/utilityProgramEnrollment'
import { AnimatePresence, motion } from 'framer-motion'
import { RootState } from '@/store'
import { Grid, GridItem } from '@/components'

export default function DepartureAndPlanCard({
  vehicle,
  animateOnMount = true,
  hidePlans = false,
}: {
  vehicle: Vehicle
  animateOnMount?: boolean
  hidePlans?: boolean
}) {
  const viewModel = useViewModel(vehicle)

  return (
    <ConfigurableFunctionality feature="chargingControl">
      <AllowChargeControlCard
        chargeSchedule={viewModel.localChargeSchedule}
        onChange={viewModel.onChargeScheduleChange}
        canCharge={viewModel.canVehicleCharge}
        utilityAlertSubtitle={viewModel.utilityAlertSubtitle}
        overrideValue={viewModel.overrideValue}
      />
      <div>
        <AnimatePresence initial={animateOnMount}>
          {viewModel.userCanControlCharging && (
            <motion.div
              layout
              className="overflow-hidden rounded-3xl"
              initial={{ height: 1 }}
              animate={{ height: '100%' }}
              exit={{ height: 1 }}
            >
              <ErrorBoundary
                location={LocationTag.PlanSelectCard}
                functionalityDescription="Charge Planning Overview"
                fallbackOnCard
              >
                <Grid flow="row" gap="24px">
                  {hidePlans ? null : (
                    <GridItem>
                      <PlanDetailsCard>
                        <PlanDetailsCard.PlanSelectForm
                          chargeSchedule={viewModel.localChargeSchedule}
                          onChange={viewModel.onChargeScheduleChange}
                        >
                          <PlanDetailsCard.ComparePlansPromptAndDialog
                            includeScheduleDeparture
                          />
                        </PlanDetailsCard.PlanSelectForm>
                      </PlanDetailsCard>
                    </GridItem>
                  )}
                </Grid>
              </ErrorBoundary>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </ConfigurableFunctionality>
  )
}

export const useViewModel = (vehicle: Vehicle) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getChargeSchedules())
  }, [])

  const debouncedSave = useCallback(
    debounce(async (model: VehicleChargeSchedule) => {
      await dispatch(updateChargeSchedule(model))
    }, 500),
    [],
  )

  const onChargeScheduleChange = (chargeSchedule: VehicleChargeSchedule) => {
    // reflect change locally
    setLocalChargeSchedule(chargeSchedule)
    // sync to server
    debouncedSave(chargeSchedule)
  }

  const chargeSchedules = useVehicleChargeSchedulesFetcher()
  const chargeSchedule = (chargeSchedules ?? []).find(
    (schedule) => schedule.vehicle_id === vehicle?.id,
  ) as VehicleChargeSchedule | undefined
  const [localChargeSchedule, setLocalChargeSchedule] = useState<
    VehicleChargeSchedule | undefined
  >(chargeSchedule)
  const canVehicleCharge = canCharge(vehicle)
  const enrolledVehicleEnrollment = useAppSelector((state: RootState) =>
    getEnrolledVehicleEnrollment(state, vehicle.id),
  )
  const isFromPartner =
    useAppSelector((state: RootState) => state.auth.fromToken) ?? false

  const utilityAlertSubtitle = getUtilityAlertSubtitle(
    isFromPartner,
    enrolledVehicleEnrollment,
  )
  const overrideValue = getOverrideValue(
    isFromPartner,
    enrolledVehicleEnrollment,
  )
  const userCanControlCharging = getUserCanControlCharging(
    canVehicleCharge,
    enrolledVehicleEnrollment,
    localChargeSchedule,
  )

  // Ensure the local charge schedule is in sync with what we pull from the server.
  // This fixed a bug where local charge schedule was not being initialized properly
  // when the component was first mounted.
  useEffect(() => {
    if (localChargeSchedule?.id !== chargeSchedule?.id && chargeSchedule) {
      setLocalChargeSchedule(chargeSchedule)
    }
  }, [chargeSchedules, localChargeSchedule])

  return {
    chargeSchedule,
    canVehicleCharge,
    enrolledVehicleEnrollment,
    onChargeScheduleChange,
    localChargeSchedule,
    utilityAlertSubtitle,
    overrideValue,
    userCanControlCharging,
  }
}

const getEnrolledVehicleEnrollment = (
  state: RootState,
  vehicleId: number,
): VehicleEnrollment | undefined => {
  const enrollment = selectVehicleEnrollmentForUser(state, vehicleId)
  if (!enrollment || enrollmentIsUnenrolled(enrollment)) {
    return undefined
  }
  return enrollment
}

const getUtilityAlertSubtitle = (
  isFromPartner: boolean,
  enrolledVehicleEnrollment: VehicleEnrollment | undefined,
): string | undefined => {
  if (isFromPartner) {
    return 'You must allow Optiwatt to manage charging in order to participate in this program.'
  }
  return enrolledVehicleEnrollment?.view_config_json?.scheduleCharging?.subtitle
}

const getOverrideValue = (
  isFromPartner: boolean,
  enrolledVehicleEnrollment: VehicleEnrollment | undefined,
): boolean | null | undefined => {
  if (isFromPartner) {
    return true
  }
  return enrolledVehicleEnrollment?.group?.allow_charging_control
}

const getUserCanControlCharging = (
  canVehicleCharge: boolean,
  enrolledVehicleEnrollment: VehicleEnrollment | undefined,
  chargeSchedule: VehicleChargeSchedule | undefined,
): boolean => {
  if (!canVehicleCharge) {
    return false
  }

  const allowChargingControl =
    enrolledVehicleEnrollment?.group?.allow_charging_control ??
    chargeSchedule?.allow_charging_control ??
    true

  return allowChargingControl
}
