import { LearnMoreCard } from '@/app/components'
import { useSurveyResponder } from './reducer'
import { useEffect } from 'react'

const EXTERNAL_PROGRAM_LINK =
  'https://www.myenergyoptimizer.org/?utm_source=Optiwatt&utm_medium=ApBanner&utm_campaign=Pilot_June2023'

function useViewModel() {
  const {
    dismissSurvey,
    addSurveyInteraction,
    completeSurvey,
    markSurveySeen,
    activeSurveyResponse,
    activeSurveyResponseErrors,
  } = useSurveyResponder('SMUDMEO')
  const onCloseClick = dismissSurvey
  const onLearnMoreClick = () => {
    addSurveyInteraction({ action: 'click' })
    completeSurvey()
    window.location.href = EXTERNAL_PROGRAM_LINK
  }

  useEffect(() => {
    if (
      !activeSurveyResponse?.last_seen &&
      !activeSurveyResponseErrors?.length
    ) {
      markSurveySeen()
    }
  }, [activeSurveyResponse?.last_seen, activeSurveyResponseErrors?.length])

  return {
    onCloseClick,
    onLearnMoreClick,
  }
}

type Props = {
  onCloseClick: () => void
  onLearnMoreClick: () => void
}

function SMUDMEOSurveyComponent(props: Props) {
  return (
    <LearnMoreCard
      title="Try My Energy Optimizer"
      subtitle="Have central air conditioning? You can get $50 for being a little more flexible with your thermostat schedule. Join My Energy Optimizer today."
      buttonTitle="Learn More"
      programLogoUrl="https://optiwatt-static-assets.s3.amazonaws.com/utility-programs/logos/smud.svg"
      onLearnMoreClick={props.onLearnMoreClick}
      onCloseClick={props.onCloseClick}
    />
  )
}

export default function SMUDMEOSurvey() {
  const viewModel = useViewModel()
  return <SMUDMEOSurveyComponent {...viewModel} />
}
