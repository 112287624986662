import { useEffect, useState } from 'react'
import {
  useAppDispatch,
  useAppSelector,
  useUrlSearchParam,
} from '../../../hooks'
import * as actions from '../../../actions/vehicleAuth'
import { ConnectionStatusMode } from '../DeviceConnectionRedirect'
import data from './resources/data.json'
import { getVehicles } from '@/actions/vehicles'
import { RSAAResultAction } from 'redux-api-middleware'
import { useNavigation } from '@/app/hooks'
import { isNativeMobile } from '@/authenticated/hooks/useIsMobile'
import { logException, logMessage } from '@/logging/sentry'

function connectionStatusMode(
  authIsLoading: boolean,
  connectionSuccess: boolean,
  errorPresent: boolean,
) {
  if (authIsLoading) {
    return ConnectionStatusMode.Connecting
  } else if (errorPresent) {
    return ConnectionStatusMode.Failed
  } else if (connectionSuccess) {
    return ConnectionStatusMode.Connected
  }
  return ConnectionStatusMode.Failed
}

function useViewModel() {
  const dispatch = useAppDispatch()
  const navigation = useNavigation()
  const [success, setSuccess] = useState(false)
  const [isVehicleAuthLoading, setVehicleAuthLoading] = useState(true)
  const isVehiclesLoading = useAppSelector((state) => state.vehicles.isLoading)
  const isPartner = useAppSelector((state) => state.auth.fromToken)
  const [smartCarState, setSmartCarState] = useState<string[]>([])
  const errorParam = useUrlSearchParam('error')
  const errorPresent = errorParam !== null
  const isMobile = isNativeMobile()

  function navigateAway(path: string) {
    if (isMobile) {
      navigation.push(path)
      return
    }

    // Get back to the main window
    if (window.opener !== null) {
      window.opener.location.replace(path)
      window.close()
    }
  }

  function handleConnectionSuccess(code: string, state: string | null) {
    if (isMobile) {
      dispatch(actions.submitConnectSmartcar(code)).then(
        (result: RSAAResultAction) => {
          if (result?.type === actions.CONNECT_VEHICLE_SUCCESS) {
            setSuccess(true)
          }
          if (result?.type === actions.CONNECT_VEHICLE_FAILURE) {
            setSuccess(false)
          }
          setVehicleAuthLoading(false)
        },
      )
    } else {
      // let the tab with the app in it handle the success
      const message = {
        name: 'SmartcarAuthMessage',
        isSmartcarHosted: false,
        code: code,
        state: state,
      }
      window.opener.postMessage(message, window.location.origin)
      window.close()
    }
  }

  useEffect(() => {
    // Because this is the entry point component for the Smartcar flow on native mobile,
    // we need to ensure that we have fetched the vehicles before we attempt to connect
    // new vehicles. This is to populate our existing vehicles in the redux store. On
    // vehicle connect success, we concat our new vehicle to the existing vehicles.
    dispatch(getVehicles())
    const urlParams = new URLSearchParams(window.location.search)
    const state = urlParams.get('state')
    if (state) {
      try {
        const { originalState } = JSON.parse(window.atob(state)) as {
          instanceId: string
          originalState: string
        }
        setSmartCarState(originalState.split(','))
      } catch (e) {
        logException(e)
      }
    }
    if (urlParams.has('code')) {
      const code = urlParams.get('code') as string
      handleConnectionSuccess(code, state)
    } else if (errorPresent) {
      setVehicleAuthLoading(false)
      setSuccess(false)
      navigateAway(`${window.location.pathname + window.location.search}`)
    } else {
      logMessage(`Unhandled smartcar redirect: ${window.location.search}`)
      navigateAway('/app')
      return
    }
  }, [dispatch, isMobile, location])

  const handleError = () => {
    if (smartCarState.includes('ava')) {
      return navigateAway(
        `/ava/connect-vehicle${
          smartCarState.includes('onboarding') ? '?onboarding=true' : ''
        }`,
      )
    }
    if (isPartner && !success) {
      return navigateAway(
        '/connect-device/vehicle?value_prop=false&pre_auth=true',
      )
    }

    return navigateAway('/connect-vehicle')
  }

  const handleSuccess = () => {
    if (smartCarState.includes('onboarding') && smartCarState.includes('ava')) {
      return navigateAway('/ava/onboarding/add-another')
    }
    if (isPartner && success) {
      return navigateAway('/autogrid/success')
    }

    if (smartCarState.includes('ava')) {
      return navigateAway('/ava/connect-vehicle/configure')
    }
    return navigateAway('/connect-vehicle/configure')
  }

  const handleClose = () => {
    if (errorPresent) {
      return handleError()
    }
    return handleSuccess()
  }

  return {
    mode: connectionStatusMode(
      isVehicleAuthLoading || isVehiclesLoading,
      success,
      errorPresent,
    ),
    statusText: data.statusText,
    buttonAction: handleClose,
  }
}

export default useViewModel
