import {
  Chip,
  ChipGroup,
  ChipGroupProps,
  Grid,
  GridItem,
  Sheet,
  Text,
} from 'component-library'
import React from 'react'

export type SelectOption = {
  value: any
  tag?: string
}

export interface Props extends ChipGroupProps {
  selectOptions: SelectOption[]
  onChange: (value: number | null) => void
  initialValue?: number | null
  direction?: 'row' | 'column'
}

function Component(props: { option: SelectOption }) {
  return typeof props.option.value === 'string' ? (
    <Chip>{props.option.value}</Chip>
  ) : (
    props.option.value
  )
}

const SelectInput = ({
  direction,
  selectOptions,
  initialValue,
  onChange,
  ...props
}: Props) => {
  return (
    <ChipGroup
      {...props}
      direction={direction}
      initialValue={initialValue}
      required={false}
      toggleable={props.toggleable}
      onClick={onChange}
    >
      {selectOptions.map((option, index) => {
        return option.tag ? (
          <Grid key={index} templateColumns="repeat(2, min-content)">
            <GridItem placeItems="start">
              <Component option={option} />
            </GridItem>
            <GridItem placeItems="center">
              <Sheet color="blue-100">
                <Text variant="label" className="text-themed-blue-500">
                  {option.tag}
                </Text>
              </Sheet>
            </GridItem>
          </Grid>
        ) : (
          <Component option={option} key={index} />
        )
      })}
    </ChipGroup>
  )
}

export default SelectInput
