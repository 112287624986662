import { Button, LoadingAnimation, Text } from '@/components'
import useViewModel from '@/app/pages/onboarding/connect-another/useViewModel'
import OrderedListItem, {
  OrderedList,
  OrderedListContent,
} from '@/components/OrderedList/OrderedListItem'
import BrandedPage from '@/app/pages/ava/BrandedPage'
import React from 'react'
import { Redirect } from 'react-router-dom'
import useWhiteLabelProgramId from '@/hooks/whitelabel/useWhiteLabelProgramId'

export default function OnboardingConnectAnotherPage() {
  const { onAddAnother, onContinue, isLoading, noDevices, avaId } =
    useViewModel()

  const loadedContent = (
    <>
      {noDevices && avaId !== null && (
        <Redirect to={`/ava/program-enrollment/${avaId}/offer`} />
      )}
      <BrandedPage.Content>
        <OrderedList>
          <OrderedListItem>
            <OrderedListContent
              title="Earn and save more"
              subtitle="Each car and charger earns an additional incentive when charging
          independently"
            />
          </OrderedListItem>
          <OrderedListItem>
            <OrderedListContent
              title="Have a backup"
              subtitle="If we can't reach your vehicle, we can use your smart charger instead"
            />
          </OrderedListItem>
          <OrderedListItem>
            <OrderedListContent
              title="Get more features"
              subtitle="Some devices are not as capable as others. Unlock as much as possible!"
            />
          </OrderedListItem>
        </OrderedList>
        <BrandedPage.ButtonContainer>
          <Button variant="primary" id="continue" onClick={onContinue}>
            Continue
          </Button>
          <Button variant="secondary" id="continue" onClick={onAddAnother}>
            Add Another Device
          </Button>
        </BrandedPage.ButtonContainer>
      </BrandedPage.Content>
    </>
  )

  return (
    <BrandedPage
      id={'connect-another'}
      title="Would you like to add additional vehicles or smart chargers?"
      subtitle="For the best experience with Optiwatt, add all your available devices."
    >
      {isLoading && <LoadingAnimation type="falling" centered />}
      {!isLoading && loadedContent}
    </BrandedPage>
  )
}
