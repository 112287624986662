import React from 'react'
import styled from 'styled-components'

import { Grid as MuiGrid, Divider } from '@material-ui/core'

import { spacing } from '@material-ui/system'
import { makeStyles } from '@material-ui/styles'

const Grid = styled(MuiGrid)(spacing)

const useStyles = makeStyles((theme) => ({}))

const DashboardRow = ({ children, ...rest }) => (
  <Grid item xs={12} {...rest}>
    {children}
  </Grid>
)

const DashboardDivider = ({ ...restProps }) => (
  <DashboardRow {...restProps}>
    <Divider />
  </DashboardRow>
)

const DashboardSections = ({ children, insertDividers = false, ...rest }) => {
  const classes = useStyles()

  return (
    <Grid container spacing={4} {...rest}>
      {React.Children.toArray(children)
        .filter(React.isValidElement)
        .map((child, idx) => (
          <React.Fragment key={idx}>
            <DashboardRow>{child}</DashboardRow>
            {idx !== React.Children.count(children) - 1 && insertDividers && (
              <DashboardDivider />
            )}
          </React.Fragment>
        ))}
    </Grid>
  )
}

export { DashboardRow, DashboardDivider }
export default DashboardSections
