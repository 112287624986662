import React from 'react'
import styled from 'styled-components'

import {
  Box as MuiBox,
  Typography,
  Button as MuiButton,
  TypographyProps,
} from '@material-ui/core'

import { spacing } from '@material-ui/system'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { hexToRGB } from '../../utils/colorUtilities'
import clsx from 'clsx'

const Box = styled(MuiBox)(spacing)
const Button = styled(MuiButton)(spacing)

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'inline-block',
      borderRadius: '10px',
    },
  }),
)

interface ConditionalButtonProps {
  wrapWithButton: boolean
  children: React.ReactNode
}

function ConditionalButton({
  wrapWithButton,
  children,
}: ConditionalButtonProps) {
  const classes = useStyles()
  return wrapWithButton ? (
    <Button className={classes.container} p={0}>
      {children}
    </Button>
  ) : (
    <>{children}</>
  )
}

interface Classes {
  container?: string
}

export interface Props extends TypographyProps {
  children?: React.ReactNode
  hexColor: string
  backgroundColor?: string
  backgroundOpacity?: number
  px?: number
  py?: number
  ml?: number
  mr?: number
  asButton?: boolean
  space?: boolean
  spaceBefore?: boolean
  spaceAfter?: boolean
  providedClasses?: Classes
}

const ColorBoxedTypography = ({
  children,
  hexColor,
  backgroundColor,
  backgroundOpacity = 0.1,
  px = 2,
  py = 1,
  ml = 0,
  mr = 0,
  asButton = false,
  space = false,
  spaceBefore = false,
  spaceAfter = false,
  providedClasses,
  ...rest
}: Props) => {
  const classes = useStyles()

  const boxColor = backgroundColor || hexToRGB(hexColor, backgroundOpacity)

  return (
    <Box
      className={clsx(providedClasses?.container, classes.container)}
      component="span"
    >
      {(spaceBefore || space) && (
        <Box className={classes.container} component="span">
          <Typography {...rest}> &nbsp; </Typography>
        </Box>
      )}
      <ConditionalButton wrapWithButton={asButton}>
        <Box
          className={clsx(providedClasses?.container, classes.container)}
          component="span"
          style={{ backgroundColor: boxColor }}
          px={px}
          py={py}
          ml={ml}
          mr={mr}
        >
          <Typography style={{ color: hexColor }} {...rest}>
            {children}
          </Typography>
        </Box>
      </ConditionalButton>
      {(spaceAfter || space) && (
        <Box
          className={clsx(providedClasses?.container, classes.container)}
          component="span"
        >
          <Typography {...rest}> &nbsp; </Typography>
        </Box>
      )}
    </Box>
  )
}

export default ColorBoxedTypography
