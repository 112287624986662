import styled from 'styled-components'

import {
  Typography as MuiTypography,
  Grid as MuiGrid,
  Paper as MuiPaper,
} from '@material-ui/core'

import { spacing } from '@material-ui/system'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'

const Typography = styled(MuiTypography)(spacing)
const Grid = styled(MuiGrid)(spacing)
const Paper = styled(MuiPaper)(spacing)

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.blue.background,
    borderRadius: '10px',
    width: '100%',
  },
  headerText: {
    color: theme.palette.blue.main,
    fontWeight: theme.typography.fontWeightBold,
  },
  infoText: {
    color: theme.palette.blue.main,
    fontSize: '0.75rem',
    lineHeight: '1rem',
  },
  icon: {
    color: theme.palette.blue.main,
    verticalAlign: 'top',
    width: '1.05rem',
    height: '1.05rem',
  },
}))

const ColorBoxedInfoBox = ({
  header = false,
  info,
  Icon,
  classes: passedClasses = {},
}) => {
  const classes = useStyles()

  return (
    <Paper className={classes.paper} elevation={0}>
      <Grid container p={{ xs: 4 }}>
        <Grid item xs={1}>
          <Icon className={clsx(classes.icon, passedClasses.icon)} />
        </Grid>
        <Grid item xs={11}>
          {header && (
            <Typography
              variant="h6"
              className={clsx(classes.headerText, passedClasses.header)}
            >
              {header}
            </Typography>
          )}
          <Typography
            variant="body1"
            className={clsx(classes.infoText, passedClasses.info)}
          >
            {info}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ColorBoxedInfoBox
