import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { useRef, ChangeEvent } from 'react'
import mockUtilities from '../../../../types/utility/mock'
import { uploadUtilityBillForm } from '../../../../actions/plans'
import useMockableViewModel from '../../../../hooks/useMockableViewModel'
import { selectUserUtility } from '@/selectors'
import { Utility } from '@/types'

interface Props {
  onComplete?: () => void
  onSelectFromList?: () => void
  onSelectManually?: () => void
}

function useViewModel(props: Props) {
  const dispatch = useAppDispatch()
  const utility = useAppSelector(selectUserUtility)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const canSelectFromList = useAppSelector(
    (state) => (state.utilities.plans?.length ?? 0) > 0,
  )

  const handleFilesUploaded = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files

    if (!files) {
      return
    }

    const data = new FormData()
    data.append('file', files[0])
    dispatch(uploadUtilityBillForm(data)).then(() => {
      props.onComplete?.()
    })
  }

  const optionsAndActions = [
    {
      option: { value: 'Upload utility bill', tag: 'RECOMMENDED' },
      action: () => {
        fileInputRef.current?.click()
      },
    },
    ...(canSelectFromList
      ? [
          {
            option: { value: 'Select plan from list' },
            action: () => props.onSelectFromList?.(),
          },
        ]
      : []),
    {
      option: { value: 'Set rates manually' },
      action: () => props.onSelectManually?.(),
    },
  ]

  const handleSelectChange = (index: number | null) => {
    if (index === null) {
      return
    }
    optionsAndActions[index]?.action()
  }

  return {
    utility: utility ?? undefined,
    fileInputRef,
    handleFilesUploaded,
    options: optionsAndActions.map((o) => o.option),
    handleSelectChange,
  }
}

function useMockViewModel(props: Props) {
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleFilesUploaded = (event: ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.files)
  }

  const optionsAndActions = [
    {
      option: { value: 'Upload utility bill', tag: 'RECOMMENDED' },
      action: () => {
        fileInputRef.current?.click()
      },
    },
    {
      option: { value: 'Select plan from list' },
      action: () => props.onSelectFromList?.(),
    },
    {
      option: { value: 'Set rates manually' },
      action: () => props.onSelectManually?.(),
    },
  ]

  const handleSelectChange = (index: number | null) => {
    if (index === null) {
      return
    }
    optionsAndActions[index]?.action()
  }

  return {
    utility: mockUtilities[0] as Utility,
    fileInputRef,
    handleFilesUploaded,
    options: optionsAndActions.map((o) => o.option),
    handleSelectChange,
  }
}

export default useMockableViewModel({ useViewModel, useMockViewModel })
