import FeedbackDialog, {
  FeedbackDialogProps,
} from '@/authenticated/components/feedback/FeedbackDialog'
import { useAppDispatch } from '@/hooks'
import { resetContactForm, submitContactFormWithAuth } from '@/actions/form'

interface Props extends FeedbackDialogProps {
  feedbackExtra?: string
  supportTitle?: string
}

/*
 AVild:
 Wrapper around the FeedbackDialog created after refactoring that component to be more generic.

 The existing implementations of that component hit the /contact endpoint, instead of the newer
 /survey endpoint. While awaiting refactors to those existing flows, this sits as a helper
 to simply swap those existing implementations over to the refactored FeedbackDialog and retain
 existing deprecated functionality
 */
export default function FeedbackDialogContactForm(props: Props) {
  const dispatch = useAppDispatch()

  const passedProps = {
    ...props,
    onSubmit: (message: string) => {
      dispatch(
        submitContactFormWithAuth({
          ...(props.supportTitle ? { title: props.supportTitle } : {}),
          message: props.feedbackExtra
            ? `${message}. ${props.feedbackExtra}`
            : message,
        }),
      ).then(() => {
        dispatch(resetContactForm())
      })

      if (props.onClose) {
        props.onClose()
      }
    },
  }

  return (
    <FeedbackDialog
      isOpen={passedProps.isOpen}
      onClose={passedProps.onClose}
      onSubmit={passedProps.onSubmit}
      id={passedProps.id}
      onOpen={passedProps.onOpen}
      title={passedProps.title}
      submitText={passedProps.submitText}
      prompt={passedProps.prompt}
    ></FeedbackDialog>
  )
}
