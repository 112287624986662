import { Helmet } from 'react-helmet'

type ScriptProps = React.ScriptHTMLAttributes<HTMLScriptElement>

/**
 * A component that renders a script tag in the head of the document using react-helmet.
 */
export default function Script(props: ScriptProps) {
  return (
    <Helmet>
      <script {...props} />
    </Helmet>
  )
}
