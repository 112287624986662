import { ReactNode } from 'react'
import formatClasses from '../../utils/classes/formatClasses'
import './styles.scss'
import { Flex, FlexProps, Text } from '../index'

export interface Props extends FlexProps {
  icon: () => ReactNode
}

export default function BulletListItem({
  icon,
  children,
  ...flexProps
}: Props) {
  const classes = {
    root: formatClasses(['list-item', 'bullet-list-item']),
    text: formatClasses(['item-text']),
    icon: formatClasses(['item-icon']),
  }

  return (
    <Flex
      className={classes.root}
      container
      alignItems="flex-start"
      wrap="nowrap"
      {...flexProps}
    >
      <Flex item className={classes.icon}>
        {icon()}
      </Flex>
      <Flex item alignItems="flex-start" className={classes.text}>
        <Text variant="body2">{children}</Text>
      </Flex>
    </Flex>
  )
}
