import { useEffect, useState } from 'react'
import styled, { withTheme } from 'styled-components'
import { Paper, Typography as MuiTypography } from '@material-ui/core'
import { spacing } from '@material-ui/system'
import { Bar } from 'react-chartjs-2'
import Plan from './Plan'
import { MISSING_MONTHS_MESSAGE, usePlansGraph } from './PlansGraph'
import { useDispatch } from 'react-redux'
import { setErrorMonths, clearForm } from '../../../../actions/manualPlans'
import { AlertMessage } from '@/app/components'
import { motion } from 'framer-motion'
import { Button, Icon } from '@/components'

const Typography = styled(MuiTypography)(spacing)
const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`

const ChartWrapper = styled.div`
  height: 200px;
  width: 100%;
`

function MonthRates(props) {
  const [plans, data, options, missingMonths] = usePlansGraph()
  const [showMissingError, setShowMissingError] = useState(false)

  const dispatch = useDispatch()

  function handleSubmit(event) {
    event.preventDefault()
    if (missingMonths.length > 0) {
      setShowMissingError(true)
      dispatch(setErrorMonths(missingMonths))
    } else {
      props.handleSuccess(plans)
      props.onComplete?.()
    }
  }

  // Mapping of month names to their numeric values
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  // Get the last plan end safely. Add 1 month to it to get the start of the next plan
  const lastPlanEnd =
    plans.length > 0 ? plans[plans.length - 1].end : 'December' // Default to December so it rolls over to January if no plans
  const lastPlanEndIndex = monthNames.indexOf(lastPlanEnd)

  // Calculate the index of the next month, wrapping around to January after December
  const nextPlanStartIndex = (lastPlanEndIndex + 1) % 12
  const nextPlanStart = monthNames[nextPlanStartIndex]

  return (
    <div>
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        Add Plans
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        Enter your monthly rates
      </Typography>
      <ChartWrapper style={{ 'margin-top': '20px' }}>
        <Bar data={data} options={options} />
      </ChartWrapper>
      <Typography variant="h6" gutterBottom style={{ 'margin-top': '20px' }}>
        Enter Months
      </Typography>
      <Typography variant="body2" gutterBottom>
        Please add a new plan for each month of the year
      </Typography>
      {showMissingError && (
        <motion.div layout="position">
          <AlertMessage variant="error" className="mt-2">
            <Typography
              variant="body2"
              gutterBottom
              style={{ color: '#f44336' }}
            >
              {MISSING_MONTHS_MESSAGE}
            </Typography>
          </AlertMessage>
        </motion.div>
      )}
      <motion.div layout="position">
        {plans.map((plan, index) => (
          <Plan
            key={index}
            planId={plan.planId}
            isLastRow={false}
            start={plan.start}
            end={plan.end}
            errors={plan.errors}
          />
        ))}
      </motion.div>
      <motion.div layout="position">
        {missingMonths.length > 0 && (
          <Plan
            planId={null}
            isLastRow={true}
            start={nextPlanStart}
            end={''}
            errors={{
              planId: null,
              start: null,
              end: null,
            }}
          />
        )}
      </motion.div>
      <div className="flex justify-center flex-grow w-full gap-4 mt-20">
        <Button
          data-ga-event-label="select-plan"
          className="flex-[4]"
          type="submit"
          variant="primary"
          color="primary"
          onClick={handleSubmit}
          m={1}
        >
          Submit
        </Button>
        {props.errorMessage && (
          <Typography variant="body2" gutterBottom style={{ color: '#f44336' }}>
            {props.errorMessage}
          </Typography>
        )}
      </div>
    </div>
  )
}

export default withTheme(MonthRates)
