import { ConfigurableFunctionalityContext } from '@/app/context/ConfigurableFunctionalityContext'
import { ReactNodeProps } from '@/types/reactNodeProps'
import { useContext } from 'react'

type Props = ReactNodeProps & {
  feature: string
}

export default function ConfigurableFunctionality(props: Props) {
  const { features } = useContext(ConfigurableFunctionalityContext)

  if (features[props.feature]?.hide || features['ALL']?.hide) {
    return null
  }

  return <>{props.children}</>
}
