import { createResourceCollection } from '../request'
import { ID } from '@/types/model'
import { useAppSelector } from '@/hooks'

export type VehicleTrip = {
  allow_charging_control: boolean
  battery_target_max: number
  battery_target_min: number
  battery_target_unit: string
  charging_start_time_utc: string | null
  created: string
  date_local_timezone: string
  departure_datetime_utc: string
  environment_support_enabled: boolean
  grid_support_enabled: boolean
  id: number
  modified: string
  money_saving_plan_enabled: boolean
  precondition_climate_target: string | null
  save_the_planet_plan_enabled: boolean
  schedule_departure_enabled: boolean
  schedule_start_charge_enabled: boolean
  should_only_use_stop_charge: boolean
  should_precondition: boolean
  solar_surplus_plan_enabled: boolean
  time_local_timezone: string
  vehicle: number
}

export function useTripsForVehicle(vehicleId: ID) {
  const trips = useAppSelector((state) =>
    tripsCollection.selectors.selectAll(state),
  )

  const { status } = tripsCollection.useFetch()

  const vehicleTrips = trips.filter((trip) => trip.vehicle == vehicleId)

  const sortedTrips = vehicleTrips.sort((a, b) => {
    const now = new Date()

    const departureA = a.departure_datetime_utc
      ? new Date(a.departure_datetime_utc).getTime()
      : null
    const departureB = b.departure_datetime_utc
      ? new Date(b.departure_datetime_utc).getTime()
      : null

    if (departureA === null && departureB === null) {
      return 0
    }

    if (departureA === null) {
      return 1
    }

    if (departureB === null) {
      return -1
    }

    return departureA - now.getTime() - (departureB - now.getTime())
  })

  return { trips: sortedTrips, status }
}

export const tripsCollection = createResourceCollection<VehicleTrip>({
  name: 'trips',
  apiConfig: {
    path: 'trips',
  },
  selector: (state) => state.trips,
})

const tripsReducer = tripsCollection.slice.reducer

export default tripsReducer
