const breakpoints = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
}

export const xsOnly = () => window.innerWidth < breakpoints.sm
export const smOnly = () =>
  window.innerWidth >= breakpoints.sm && window.innerWidth <= breakpoints.md
export const smAndDown = () => window.innerWidth <= breakpoints.md
export const smAndUp = () => window.innerWidth >= breakpoints.sm
export const mdOnly = () =>
  window.innerWidth > breakpoints.md && window.innerWidth <= breakpoints.lg
export const mdAndDown = () => window.innerWidth <= breakpoints.lg
export const mdAndUp = () => window.innerWidth > breakpoints.md
export const lgOnly = () =>
  window.innerWidth > breakpoints.lg && window.innerWidth <= breakpoints.xl
export const lgAndDown = () => window.innerWidth <= breakpoints.xl
export const lgAndUp = () => window.innerWidth > breakpoints.lg
export const xlOnly = () => window.innerWidth > breakpoints.xl
export const width = () => window.innerWidth
export const height = () => window.innerHeight
export const current = () => {
  return (
    [
      { name: 'xs', value: xsOnly() },
      { name: 'sm', value: smOnly() },
      { name: 'md', value: mdOnly() },
      { name: 'lg', value: lgOnly() },
      { name: 'xl', value: xlOnly() },
    ].find((breakpoint) => breakpoint.value)?.name || 'none'
  )
}

export default function computedBreakpoints() {
  return {
    xsOnly: xsOnly(),
    smOnly: smOnly(),
    smAndDown: smAndDown(),
    smAndUp: smAndUp(),
    mdOnly: mdOnly(),
    mdAndDown: mdAndDown(),
    mdAndUp: mdAndUp(),
    lgOnly: lgOnly(),
    lgAndDown: lgAndDown(),
    lgAndUp: lgAndUp(),
    xlOnly: xlOnly(),
    current: current(),
    width: width(),
    height: height(),
  }
}
