import { RSAA, createAction } from 'redux-api-middleware'
import config from '../config'
import { logEvent } from '@/logging'
import { setLoggingUserId } from '@/reducers/logging'
import {
  identify as identifyWithMixpanel,
  resetIdentity,
} from '@/logging/mixpanel'
import { resetTheme } from '@/hooks/whitelabel/useWhitelabelTheme'
import { isNativeMobile } from '@/authenticated/hooks/useIsMobile'

export const LOGIN_REQUEST = '@@jwt/LOGIN_REQUEST'
export const LOGIN_SUCCESS = '@@jwt/LOGIN_SUCCESS'
export const LOGIN_TOKEN_SUCCESS = '@@jwt/LOGIN_TOKEN_SUCCESS'
export const LOGIN_FAILURE = '@@jwt/LOGIN_FAILURE'
export const LOGIN_TOKEN_FAILURE = '@@jwt/LOGIN_TOKEN_FAILURE'

export const TOKEN_REQUEST = '@@jwt/TOKEN_REQUEST'
export const TOKEN_SUCCESS = '@@jwt/TOKEN_SUCCESS'
export const TOKEN_FAILURE = '@@jwt/TOKEN_FAILURE'

export const SIGNUP_REQUEST = '@@jwt/SIGNUP_REQUEST'
export const SIGNUP_SUCCESS = '@@jwt/SIGNUP_SUCCESS'
export const SIGNUP_FAILURE = '@@jwt/SIGNUP_FAILURE'

export const PASSWORD_RESET_REQUEST = '@@jwt/PASSWORD_RESET_REQUEST'
export const PASSWORD_RESET_SUCCESS = '@@jwt/PASSWORD_RESET_SUCCESS'
export const PASSWORD_RESET_FAILURE = '@@jwt/PASSWORD_RESET_FAILURE'

export const RESET_VALIDATE_REQUEST = '@@jwt/RESET_VALIDATE_REQUEST'
export const RESET_VALIDATE_SUCCESS = '@@jwt/RESET_VALIDATE_SUCCESS'
export const RESET_VALIDATE_FAILURE = '@@jwt/RESET_VALIDATE_FAILURE'

export const CHANGE_PASSWORD_REQUEST = '@@jwt/CHANGE_PASSWORD_REQUEST'
export const CHANGE_PASSWORD_SUCCESS = '@@jwt/CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILURE = '@@jwt/CHANGE_PASSWORD_FAILURE'

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'

export const login = (username, password) => ({
  [RSAA]: {
    endpoint: config.apiGateway.URL + '/api/token/',
    method: 'POST',
    body: JSON.stringify({ username, password }),
    headers: { 'Content-Type': 'application/json' },
    types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE],
  },
})

export const loginWithToken = (token) => ({
  [RSAA]: {
    endpoint: config.apiGateway.URL + `/api/short_token/?token=${token}`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    types: [LOGIN_REQUEST, LOGIN_TOKEN_SUCCESS, LOGIN_FAILURE],
  },
})

export const loginWithGoogle = (searchParams) => ({
  [RSAA]: {
    endpoint: config.apiGateway.URL + `/api/oauth/google${searchParams}`,
    method: 'POST',
    credentials: 'include',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE],
  },
})

export const resetPassword = (email) => ({
  [RSAA]: {
    endpoint: config.apiGateway.URL + '/reset-password/password_reset/',
    method: 'POST',
    body: JSON.stringify({ email }),
    headers: { 'Content-Type': 'application/json' },
    types: [
      PASSWORD_RESET_REQUEST,
      PASSWORD_RESET_SUCCESS,
      PASSWORD_RESET_FAILURE,
    ],
  },
})

export const validatePasswordToken = (token) => ({
  [RSAA]: {
    endpoint: config.apiGateway.URL + '/reset-password/validate_token/',
    method: 'POST',
    body: JSON.stringify({ token: token }),
    headers: { 'Content-Type': 'application/json' },
    types: [
      RESET_VALIDATE_REQUEST,
      RESET_VALIDATE_SUCCESS,
      RESET_VALIDATE_FAILURE,
    ],
  },
})

export const changePassword = (password, token) => ({
  [RSAA]: {
    endpoint: config.apiGateway.URL + '/reset-password/password_reset/confirm/',
    method: 'POST',
    body: JSON.stringify({ password: password, token: token }),
    headers: { 'Content-Type': 'application/json' },
    types: [
      CHANGE_PASSWORD_REQUEST,
      CHANGE_PASSWORD_SUCCESS,
      CHANGE_PASSWORD_FAILURE,
    ],
  },
})

export const refreshAccessToken = (token) => ({
  [RSAA]: {
    endpoint: config.apiGateway.URL + '/api/token/refresh/',
    method: 'POST',
    body: JSON.stringify({ refresh: token }),
    headers: { 'Content-Type': 'application/json' },
    types: [TOKEN_REQUEST, TOKEN_SUCCESS, TOKEN_FAILURE],
  },
})

export function signUp(user) {
  return async (dispatch, getState) => {
    const actionResponse = await dispatch(
      createAction({
        endpoint: config.apiGateway.URL + '/api/users/',
        method: 'POST',
        body: JSON.stringify(user),
        headers: { 'Content-Type': 'application/json' },
        types: [SIGNUP_REQUEST, SIGNUP_SUCCESS, SIGNUP_FAILURE],
      }),
    )
    if (actionResponse.type === SIGNUP_SUCCESS) {
      logEvent(['userSignup', 'sign_up'])
      const auth = getState().auth
      dispatch(setLoggingUserId(auth.access?.user_id))
      if (auth.access?.user_id) {
        identifyWithMixpanel(auth.access?.user_id, user.username, user.name)
      }
    }
    return actionResponse
  }
}

export const logout = () => {
  return { type: LOGOUT_REQUEST }
}

export const doLogout = (dispatch) => {
  if (isNativeMobile()) {
    window.ReactNativeWebView?.postMessage('logout')
  }

  logEvent('logout')

  resetIdentity()

  try {
    resetTheme()
  } catch (e) {
    console.log('Failed to reset theme')
    console.log(e.getMessage())
  }

  return dispatch(logout())
}
