import ErrorBoundary, {
  LocationTag,
} from '@/authenticated/components/ErrorBoundary'
import { UseViewModelProps } from '../SurveyObserver/ClarkEVSE/ClarkEVSE'
import DismissibleCard from '../DismissibleCard'
import Text from '@/components/Text'
import List from '@/components/List/List'
import Button from '@/components/Button/Button'
import { useState } from 'react'
import { useNavigation } from '@/app/hooks'

export default function ClarkVehicleChargerAlert({
  submitResponse,
  dismissSurvey,
  logoUrl,
}: UseViewModelProps) {
  const [isDismissed, setIsDismissed] = useState(false)
  const navigate = useNavigation()
  return (
    <ErrorBoundary
      location={LocationTag.VehicleChargerClarkEVSEAlert}
      functionalityDescription="Vehicle charger charge forecast card"
      fallbackOnCard
    >
      <DismissibleCard
        id="clark-vehicle-charger-alert"
        onDismiss={dismissSurvey}
        dismiss={isDismissed}
      >
        <div className="flex flex-col gap-4">
          {logoUrl && (
            <img src={logoUrl} alt="Utility Program Logo" className="w-16" />
          )}
          <div className="flex flex-col gap-2">
            <Text variant="h3">You’re Almost There! Get $50</Text>
            <Text variant="body2">
              Clark PUC is offering an EV Managed Charging Program in your area,
              but the following devices are not yet eligible:
            </Text>
            <Text variant="body2">
              <List>
                <li>Smart chargers</li>
                <li>EVs without charge control</li>
              </List>
            </Text>
          </div>
          <Button
            id="add-clark-eligible-vehicle-button"
            onClick={() => {
              submitResponse({ addVehicle: true })
              setIsDismissed(true)
              navigate.push('/connect-device/vehicle')
            }}
          >
            Add Eligible Vehicle
          </Button>
        </div>
      </DismissibleCard>
    </ErrorBoundary>
  )
}
