import { useSeasonalRateSummaryFetcher } from '@/authenticated/components/UtilityUsage/UsageOverview'
import TimeOfUseOverview from '@/authenticated/components/UtilityUsage/UsageOverview/TimeOfUseOverview'
import { useSelectedPlanFetcher } from '@/authenticated/hooks/plans'
import { Card } from '@/components'
import utilityUsageTheme from '@/authenticated/components/UtilityUsage/theme'
import { ThemeProvider, createTheme } from '@material-ui/core/styles'
import { useAppDispatch } from '@/request/utils'

function UnthemedPlanOverview() {
  const dispatch = useAppDispatch()
  const { selectedPlan } = useSelectedPlanFetcher()
  const { seasonalRateSummary } = useSeasonalRateSummaryFetcher(dispatch)
  const isTou = selectedPlan?.types?.includes('Time-of-use')

  if (!selectedPlan || !seasonalRateSummary) {
    return null
  }

  if (!isTou) {
    return null
  }

  return (
    <Card>
      <TimeOfUseOverview seasonalRateSummary={seasonalRateSummary} />
    </Card>
  )
}

export default function PlanOverview() {
  return (
    <ThemeProvider
      theme={(theme) =>
        createTheme({
          ...theme,
          palette: {
            // @ts-expect-error some MUI bull shiiii
            ...theme.palette,
            ...utilityUsageTheme.palette,
          },
          // @ts-expect-error some MUI bull shiiii
          border: {
            // @ts-expect-error some MUI bull shiiii
            ...(theme.border || {}),
            ...utilityUsageTheme.border,
          },
        })
      }
    >
      <UnthemedPlanOverview />
    </ThemeProvider>
  )
}
