import TeslaGiveawayDashboardView from '@/authenticated/components/TeslaGiveawayDashboardView'
import TotalCost from '@/authenticated/components/TotalCost'
import ActionableWinterWeatherAlertCard from '@/authenticated/components/WinterWeatherAlertCard/ActionableWinterWeatherAlertCard'
import InfoWinterWeatherAlertCard from '@/authenticated/components/WinterWeatherAlertCard/InfoWinterWeatherAlertCard'
import UtilityProgramLearnMoreDashboardView from '@/app/features/utility-programs/UtilityProgramLearnMoreDashboardView'
import { Vehicle } from '@/types/vehicle'
import TotalImpact from '@/authenticated/components/TotalImpact'
import Charges from '@/authenticated/components/Charges'
import { BehindFlag, LockedFeature } from '@/app/components'
import ChargeForecastChart from '@/authenticated/components/ChargeForecast/ChargeForecastChart'
import ErrorBoundary, {
  LocationTag,
} from '@/authenticated/components/ErrorBoundary'
import ChargeForecastDashboardView from '@/authenticated/components/ChargeForecastDashboardView'
import InsightsCard from '@/app/components/Insights/InsightsCard'

export interface Props {
  vehicle?: Vehicle
  shouldHideActionableWinterWeatherAlert: boolean
  shouldHideInfoWinterWeatherAlert: boolean
}

export default function VehicleSection(props: Props) {
  if (!props.vehicle) {
    return null
  }

  return (
    <>
      <UtilityProgramLearnMoreDashboardView />
      <TeslaGiveawayDashboardView />

      <InsightsCard />

      {!props.shouldHideActionableWinterWeatherAlert && (
        <ActionableWinterWeatherAlertCard />
      )}
      {!props.shouldHideInfoWinterWeatherAlert && (
        <InfoWinterWeatherAlertCard />
      )}

      <BehindFlag flag="vehicle_chargeforecast" experimental>
        <ErrorBoundary
          location={LocationTag.VehicleChargerChargeForecastCard}
          functionalityDescription="Vehicle charger charge forecast card"
          fallbackOnCard
        >
          <ChargeForecastDashboardView vehicleId={props.vehicle.id} />
        </ErrorBoundary>
      </BehindFlag>

      <TotalCost vehicleId={props.vehicle.id} />
      <LockedFeature
        title="Charge Forecast"
        connectionRequirements={['utility', 'unpendingPlan']}
      >
        <ChargeForecastChart vehicleId={props.vehicle.id} />
      </LockedFeature>

      <TotalImpact vehicle={props.vehicle} />
      <Charges vehicle={props.vehicle} />
    </>
  )
}
