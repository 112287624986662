import { RSAA } from 'redux-api-middleware'
import config from '../config'
import { withAuth } from '../reducers'

export const LIST_REQUEST = '@@seasonalRateSummary/LIST_REQUEST'
export const LIST_SUCCESS = '@@seasonalRateSummary/LIST_SUCCESS'
export const LIST_FAILURE = '@@seasonalRateSummary/LIST_FAILURE'

export const getSeasonalRateSummary = () => ({
  [RSAA]: {
    endpoint: `${config.apiGateway.URL}/api/seasonal_rate_summary/`,
    method: 'GET',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [LIST_REQUEST, LIST_SUCCESS, LIST_FAILURE],
  },
})
