import { ChargeSchedule } from '../chargeSchedule'
import { ID } from '../model'

export type VehicleChargeSchedule = ChargeSchedule & {
  vehicle_id: ID
  battery_target_unit: 'percent' | 'miles' | 'kilometers'
  should_precondition: boolean | null
  precondition_climate_target: number | null
}

export function isVehicleChargeSchedule(
  schedule: ChargeSchedule & { vehicle_id?: ID | null },
): schedule is VehicleChargeSchedule {
  return 'vehicle_id' in schedule && schedule.vehicle_id !== null
}
