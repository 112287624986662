export const START_EDIT_PLAN = '@@manualPlans/START_EDIT_PLAN'
export const END_EDIT_PLAN = '@@manualPlans/END_EDIT_PLAN'

export const ADD_PLAN = '@@manualPlans/ADD_PLAN'
export const DELETE_PLAN = '@@manualPlans/DELETE_PLAN'
export const MODIFY_PLAN = '@@manualPlans/MODIFY_PLAN'

export const ADD_RATE = '@@manualPlans/ADD_RATE'
export const DELETE_RATE = '@@manualPlans/DELETE_RATE'
export const SUBMIT_RATES = '@@manualPlans/SUBMIT_RATES'

export const SET_ERROR_MONTHS = '@@manualPlans/SET_ERROR_MONTHS'
export const SET_ERROR_HOURS = '@@manualPlans/SET_ERROR_HOURS'

export const SHOW_NEW_PLAN = '@@manualPlans/SHOW_NEW_PLAN'
export const HIDE_NEW_PLAN = '@@manualPlans/HIDE_NEW_PLAN'

export const SHOW_EDIT_NEW_WEEK_RATES = '@@manualPlans/SHOW_EDIT_NEW_WEEK_RATES'
export const HIDE_EDIT_NEW_WEEK_RATES = '@@manualPlans/HIDE_EDIT_NEW_WEEK_RATES'

export const SUBMIT_QUESTIONS = '@@manualPlans/SUBMIT_QUESTIONS'
export const CLEAR_FORM = '@@manualPlans/CLEAR_FORM'

export const startEditPlan = () => {
  return {
    type: START_EDIT_PLAN,
  }
}

export const endEditPlan = () => {
  return {
    type: END_EDIT_PLAN,
  }
}

export const showNewPlan = () => {
  return {
    type: SHOW_NEW_PLAN,
  }
}

export const showEditPlan = (planId) => {
  return {
    type: SHOW_EDIT_NEW_WEEK_RATES,
    payload: {
      planId: planId,
    },
  }
}

export const hideEditPlans = () => {
  return {
    type: HIDE_EDIT_NEW_WEEK_RATES,
  }
}

export const addPlan = (plan) => {
  return {
    type: ADD_PLAN,
    payload: {
      plan: plan,
    },
  }
}

export const deletePlan = (planId) => {
  return {
    type: DELETE_PLAN,
    payload: {
      planId: planId,
    },
  }
}

export const addRate = (planId, rate, type) => {
  return {
    type: ADD_RATE,
    payload: {
      planId: planId,
      rate: rate,
      type: type,
    },
  }
}

export const deleteRate = (planId, index, type) => {
  return {
    type: DELETE_RATE,
    payload: {
      planId: planId,
      index: index,
      type: type,
    },
  }
}

export const modifyPlan = (planId, start, end) => {
  return {
    type: MODIFY_PLAN,
    payload: {
      planId: planId,
      start: start,
      end: end,
    },
  }
}

export const submitRates = (planId, rates) => {
  return {
    type: SUBMIT_RATES,
    payload: {
      planId: planId,
      rates: rates,
    },
  }
}

export const setErrorMonths = (errorMonths) => {
  return {
    type: SET_ERROR_MONTHS,
    payload: {
      errorMonths: errorMonths,
    },
  }
}

export const setErrorHours = (errorHours) => {
  return {
    type: SET_ERROR_HOURS,
    payload: {
      errorHours: errorHours,
    },
  }
}

export const submitQuestions = (weekdays, months) => {
  return {
    type: SUBMIT_QUESTIONS,
    payload: {
      weekdays: weekdays,
      months: months,
    },
  }
}

export const clearForm = () => {
  return {
    type: CLEAR_FORM,
  }
}
