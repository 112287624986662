import { vehicleChargersCollection } from '@/reducers/vehicleChargers'
import { RootState } from '../store'
import { ID } from '@/types/model'

const selectVehicleChargerIsInactive = (
  state: RootState,
  vehicleChargerId: ID,
) => {
  const authenticationInactive = vehicleChargersCollection.selectors.selectById(
    state,
    vehicleChargerId,
  )?.authentication_inactive
  const authErrorPresent = vehicleChargersCollection.selectors.queryState
    .selectByResourceId(state, vehicleChargerId)
    .map((queryState) => queryState.error)
    .some((error) => error?.status === 401)

  return authenticationInactive || authErrorPresent
}

export default selectVehicleChargerIsInactive
