import { Home, Thermostat, Vehicle, VehicleCharger } from '@/types'
import { Device, DeviceSelection } from '../DeviceSelectionGallery.types'
import { useDeviceAlertStates } from './useDeviceAlertStates'
import { useSelectionGalleryResources } from './useSelectionGalleryResources'
import { sortDevices } from './sortDevices'
import { useEffect } from 'react'
import { getPiped } from '@/utils/function'
import { useGalleryActions } from './useGalleryActions'

type Props = {
  vehicles: Vehicle[]
  homes: Home[]
  thermostats: Thermostat[]
  vehicleChargers: VehicleCharger[]
  selectedDevice: DeviceSelection | null
  setSelectedDevice: (device: DeviceSelection) => void
}

// function to turn all the devices in props to the Device type
function mapDevicesToType({
  vehicles,
  homes,
  thermostats,
  vehicleChargers,
}: Props): Device[] {
  return [
    ...vehicles.map((vehicle) => ({
      type: 'vehicle' as const,
      id: vehicle.id,
      model: vehicle,
    })),
    ...homes.map((home) => ({
      type: 'home' as const,
      id: home.id,
      model: home,
    })),
    ...thermostats.map((thermostat) => ({
      type: 'thermostat' as const,
      id: thermostat.id,
      model: thermostat,
    })),
    ...vehicleChargers.map((vehicleCharger) => ({
      type: 'vehicleCharger' as const,
      id: vehicleCharger.id,
      model: vehicleCharger,
    })),
  ]
}

export function useViewModel(props: Props) {
  const useTransformAndSortDevices = getPiped(
    mapDevicesToType,
    (devices) => useDeviceAlertStates({ devices }),
    (devicesWithAlertState) =>
      useSelectionGalleryResources({
        devices: devicesWithAlertState,
        selectedDevice: props.selectedDevice,
        setSelectedDevice: props.setSelectedDevice,
      }),
    sortDevices,
  )

  const sortedDevices = useTransformAndSortDevices(props)

  const galleryActions = useGalleryActions()

  // set the selected device to the first device in the sorted list.
  // listen to the sortedDevices in case this component is rendered before
  // the devices are loaded.
  useEffect(() => {
    if (props.selectedDevice || sortedDevices.length === 0) {
      return
    }

    props.setSelectedDevice(sortedDevices[0])
  }, [sortedDevices.length])

  return {
    sortedDevices: sortedDevices,
    galleryActions,
  }
}
