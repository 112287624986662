import { useContext, useState } from 'react'
import styled, { createGlobalStyle } from 'styled-components'

import Sidebar from '../components/layout/Sidebar'
import Header from '../components/layout/Header'
import Footer from '../components/layout/Footer'

import { spacing } from '@material-ui/system'
import {
  CssBaseline,
  Hidden,
  Paper as MuiPaper,
  withWidth,
} from '@material-ui/core'
import { BreakpointContext } from '@/context'
import { isNativeMobile } from '../hooks/useIsMobile'

const drawerWidth = 260

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.body.background};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`

const AppContent = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: calc(100% - ${drawerWidth}px);
  }
  ${(props) => props.theme.breakpoints.down('sm')} {
    max-width: 100%;
  }
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Paper = styled(MuiPaper)(spacing)

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.body.background};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`

function Dashboard(props) {
  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const { children, routes, width } = props

  const breakpoint = useContext(BreakpointContext)

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {!isNativeMobile() && (
        <Drawer>
          <Hidden mdUp implementation="js">
            <Sidebar
              routes={routes}
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
            />
          </Hidden>
          <Hidden smDown implementation="js">
            <Sidebar
              routes={routes}
              PaperProps={{ style: { width: drawerWidth } }}
            />
          </Hidden>
        </Drawer>
      )}
      <AppContent>
        <Header onDrawerToggle={handleDrawerToggle} />
        <MainContent
          style={{
            paddingLeft: breakpoint.mdAndUp
              ? `calc((100vw - 100%) - ${drawerWidth}px)`
              : `calc(100vw - 100%)`,
          }}
        >
          {children}
        </MainContent>
        <Footer />
      </AppContent>
    </Root>
  )
}

export default withWidth()(Dashboard)
