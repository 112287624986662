import TotalImpactRoot from './TotalImpact'
import ErrorBoundary, { LocationTag } from '../ErrorBoundary'

export default function TotalImpact(props) {
  return (
    <ErrorBoundary
      location={LocationTag.TotalImpact}
      functionalityDescription="Total Impact Overiew"
      fallbackOnCard
    >
      <TotalImpactRoot {...props} />
    </ErrorBoundary>
  )
}
