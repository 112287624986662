import { withTheme } from 'styled-components'
import MonthRates from './MonthRates'

function MonthPlan(props) {
  return (
    <MonthRates
      onBack={props.onBack}
      handleSuccess={props.handleSuccess}
      onComplete={props.onComplete}
    />
  )
}

export default withTheme(MonthPlan)
