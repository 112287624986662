import { useEffect } from 'react'
import { useFeatureFlag } from '@/hooks/useFeatureFlag'
import { getWhilelabeledExperienceData } from '@/types/utilityProgram/utils'
import { useWhiteLabelData } from './useWhiteLabelData'

type CMSColors = {
  primary: string
  primarySubtle: string
  secondary: string
  secondarySubtle: string
  accent: string
  accentSubtle: string
}

const cmsToColorVarMap: CMSColors = {
  primary: '--color-purple-500',
  primarySubtle: '--color-purple-200',
  secondary: '--color-grey-100',
  secondarySubtle: '--color-grey-100',
  accent: '--color-blue-500',
  accentSubtle: '--color-blue-100',
}

const defaultTheme: CMSColors = {
  primary: '#af4bfb',
  primarySubtle: '#e2cbfb',
  secondary: '#f2f2f2',
  secondarySubtle: '#f2f2f2',
  accent: '#426ffb',
  accentSubtle: '#ecf1ff',
}

const replaceAppColors = (colors: CMSColors) => {
  for (const key in colors) {
    const mappedColor = cmsToColorVarMap[key as keyof CMSColors]
    if (!mappedColor) {
      continue
    }

    document.documentElement.style.setProperty(
      mappedColor,
      colors[key as keyof CMSColors],
    )
  }
}

export const resetTheme = () => {
  replaceAppColors(defaultTheme)
}

function cmsThemeToAppTheme(
  cmsTheme: ReturnType<typeof getWhilelabeledExperienceData>['colorScheme'],
): CMSColors | null {
  if (!cmsTheme) {
    return null
  }

  return {
    ...defaultTheme,
    primary: cmsTheme.primaryColor ?? defaultTheme.primary,
    primarySubtle: cmsTheme.primarySubtleColor ?? defaultTheme.primarySubtle,
    secondary: cmsTheme.secondaryColor ?? defaultTheme.secondary,
    secondarySubtle:
      cmsTheme.secondarySubtleColor ?? defaultTheme.secondarySubtle,
    accent: cmsTheme.accentColor ?? defaultTheme.accent,
    accentSubtle: cmsTheme.accentSubtleColor ?? defaultTheme.accentSubtle,
  }
}

export const useWhitelabelTheme = () => {
  const whiteLabelingEnabled = useFeatureFlag({
    flag: 'whitelabeling_enabled',
    experimentalValue: true,
  })
  const { whiteLabelData } = useWhiteLabelData()

  const theme = whiteLabelData
    ? cmsThemeToAppTheme(whiteLabelData.colorScheme)
    : null

  useEffect(() => {
    if (whiteLabelingEnabled && theme) {
      replaceAppColors(theme)
    } else {
      resetTheme()
    }
  }, [whiteLabelingEnabled, theme])

  return {
    setTheme: replaceAppColors,
    isLoading: whiteLabelData === null,
  }
}
