import { ID } from '@/types/model'
import { AnimatePresence, motion } from 'framer-motion'
import { CircularSlider } from '@/app/components/CirclularSlider'
import clsx from 'clsx'
import useViewModel from './useViewModel'

export type ChargeForecastSliderProps = {
  vehicleId: ID
  loading: boolean
  onChange?: (newValue: number) => void
}

export default (props: ChargeForecastSliderProps) => {
  const viewModel = useViewModel(props)
  return (
    <div className="flex flex-col items-center justify-center w-full -mt-3">
      <CircularSlider
        loading={viewModel.loading}
        size={225}
        strokeWidth={18}
        min={0}
        max={100}
        limit={viewModel.chargeLimit}
        thumbText={`${viewModel.chargeLimit.toFixed(0)}%`}
        currentCharge={viewModel.currentCharge ?? 0}
        chargeLimitColor={viewModel.chargeLimitColor}
        currentChargeColor={viewModel.currentChargeColor}
        onStartDragging={viewModel.onStartDragging}
        onChange={viewModel.onChange}
        onStoppedDragging={viewModel.onStoppedDragging}
        step={viewModel.step}
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="flex flex-col items-center justify-center"
        >
          <AnimatePresence initial={false} mode="popLayout">
            {!viewModel.interacting && (
              <motion.div
                key="currentCharge"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 30, scale: 0.9 }}
                transition={{ duration: 0.2 }}
                className={clsx('text-4xl font-bold', {
                  'text-green-700': viewModel.currentCharge > 60,
                })}
              >
                {viewModel.currentCharge}%
              </motion.div>
            )}
            {viewModel.interacting && (
              <motion.div
                key="chargeLimit"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 30, scale: 0.9 }}
                transition={{ duration: 0.2 }}
                className={clsx('text-4xl font-bold', {
                  'text-green-700': viewModel.currentCharge > 60,
                })}
              >
                {viewModel.chargeLimit.toFixed(0)}%
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence initial={false} mode="popLayout">
            {!viewModel.interacting && (
              <motion.div
                key="batteryInMiles"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                className={clsx({
                  'text-green-700': viewModel.currentCharge > 60,
                })}
              >
                {viewModel.batteryDistanceWithUnitsLabel}
              </motion.div>
            )}
            {viewModel.interacting && (
              <motion.div
                key="chargeLimit"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                className={clsx({
                  'text-green-700': viewModel.currentCharge > 60,
                })}
              >
                Charge Limit
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      </CircularSlider>
    </div>
  )
}
