export function capitalize(string: string | undefined) {
  return (string?.charAt?.(0).toUpperCase() ?? '') + (string?.slice(1) ?? '')
}

export function capitalizeFirstLetter(string: string | undefined) {
  return (string?.charAt?.(0).toUpperCase() ?? '') + (string?.slice(1) ?? '')
}

export function isString(value: unknown) {
  return typeof value === 'string' || value instanceof String
}

export function camelToKebabCase(string: string) {
  return string.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase()
}

export function kebabToCamelCase(string: string) {
  return string.replace(/-([a-z])/g, function (g) {
    return g[1].toUpperCase()
  })
}

export const spaceOccupyingBlank = '\u00A0'
