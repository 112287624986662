import { RootState } from '../store'
import { Home } from '../types/home'

export function getSelectedHome(homes: Home[]): Home | undefined {
  return homes[0]
}

export default function selectUserSelectedHome(state: RootState) {
  return getSelectedHome(state.homes.homes ?? [])
}
