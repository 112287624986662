import { useEffect, useState } from 'react'
import { ChevronRight, ChevronLeft } from 'react-feather'
import styled from 'styled-components'
import {
  Grid as MuiGrid,
  Box as MuiBox,
  makeStyles,
  Typography as MuiTypography,
  Collapse,
  IconButton,
} from '@material-ui/core'
import { spacing } from '@material-ui/system'
import IconToggleLabel from '../IconToggleLabel'
import {
  convertCelsiusToFahrenheit,
  convertFahrenheitToCelsius,
} from '../../../utils/conversionUtilities'
import { useUsersPreferredTemperature } from '../../hooks/useUsersPreferredTemperature'

const Grid = styled(MuiGrid)(spacing)
const Box = styled(MuiBox)(spacing)
const Typography = styled(MuiTypography)(spacing)

const CLIMATE_HEAT_THRESHOLD = 71

const useStyles = makeStyles((theme) => ({
  climateWrapper: {
    backgroundColor: '#F2F2F2',
    padding: `${theme.spacing(4)}px ${theme.spacing(6)}px`,
    borderRadius: '20px',
    marginBottom: theme.spacing(4),
  },
  climateControlBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  climateLabelBox: {
    backgroundColor: (props) =>
      props.localClimateTarget >= CLIMATE_HEAT_THRESHOLD
        ? theme.palette.red.secondary
        : '#ECF1FF',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0px 8px',
    borderRadius: '10px',
  },
  climateText: {
    color: (props) =>
      props.localClimateTarget >= CLIMATE_HEAT_THRESHOLD
        ? theme.palette.red.main
        : theme.palette.blue.main,
    fontWeight: '700',
    textAlign: 'center',
    flex: 1,
    padding: '8px',
  },
  text: {
    fontWeight: '700',
    fontSize: '13px',
  },
  colorText: {
    color: (props) =>
      props.localClimateTarget >= CLIMATE_HEAT_THRESHOLD
        ? theme.palette.red.main
        : theme.palette.blue.main,
  },
}))

const ClimateControl = ({ onChange, chargeSchedule, canControlClimate }) => {
  const DEFAULT_CLIMATE_TARGET = 70
  const MAX_CLIMATE = 85
  const MIN_CLIMATE = 55

  const [localClimateTarget, setLocalClimateTarget] = useState(
    chargeSchedule?.precondition_climate_target,
  )
  const { value: userTempValue, unit: userTempUnit } =
    useUsersPreferredTemperature(localClimateTarget)

  useEffect(() => {
    if (chargeSchedule) {
      setLocalClimateTarget(
        chargeSchedule.precondition_climate_target || DEFAULT_CLIMATE_TARGET,
      )
    }
  }, [chargeSchedule?.id, localClimateTarget])

  const onClimateChanged = (model) => {
    // Model "precondition_climate_target" is set in the "transform" function in the rendering
    if (model.precondition_climate_target) {
      setLocalClimateTarget(localClimateTarget)
    }
    if (onChange) {
      chargeSchedule.precondition_climate_target =
        model.precondition_climate_target
      onChange(chargeSchedule)
    }
  }

  const changeClimateByValue = (value) => {
    setLocalClimateTarget((prevClimateTarget) => {
      let newValue
      if (userTempUnit === 'C') {
        //convert prevClimateTarget to celsius, add the value, then convert back to fahrenheit
        newValue = convertCelsiusToFahrenheit(
          convertFahrenheitToCelsius(prevClimateTarget) + value,
        )
      } else {
        newValue = prevClimateTarget + value
      }

      if (newValue >= MIN_CLIMATE && newValue <= MAX_CLIMATE) {
        if (chargeSchedule) {
          chargeSchedule.precondition_climate_target = newValue
          if (onChange) {
            onChange(chargeSchedule)
          }
        }
        return newValue
      }
      return prevClimateTarget
    })
  }

  const classes = useStyles({ localClimateTarget })

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      spacing={5}
      px={1}
    >
      <Grid item xs={12}>
        <IconToggleLabel
          title="Precondition car"
          subtitle="Automatically warm the battery before departure to reduce the effects of cold weather"
          shouldDebounce={false}
          model={chargeSchedule}
          field="should_precondition"
          onChange={onChange}
        />
      </Grid>
      {canControlClimate && (
        <>
          <Grid item xs={12}>
            <IconToggleLabel
              title="Climate"
              subtitle="Automatically set your cabin's climate to your desired temperature"
              shouldDebounce={false}
              model={chargeSchedule}
              field="precondition_climate_target"
              transform={(_model, _field, checked) =>
                checked ? localClimateTarget : null
              }
              onChange={onClimateChanged}
            />
          </Grid>
          <Grid item xs={12}>
            <Collapse in={chargeSchedule && localClimateTarget !== null}>
              <Box className={classes.climateWrapper}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={6}>
                    <Typography variant="body1" className={classes.text}>
                      Set climate to:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item xs={12}>
                        <Box className={classes.climateControlBox}>
                          <IconButton
                            className={classes.colorText}
                            onClick={() => changeClimateByValue(-1)}
                          >
                            <ChevronLeft
                              className={classes.colorText}
                              size={16}
                            />
                          </IconButton>
                          <Box className={classes.climateLabelBox}>
                            <Typography
                              variant="body1"
                              className={classes.climateText}
                            >
                              {`${userTempValue}°${userTempUnit}`}
                            </Typography>
                          </Box>
                          <IconButton
                            className={classes.colorText}
                            onClick={() => changeClimateByValue(1)}
                          >
                            <ChevronRight
                              className={classes.colorText}
                              size={16}
                            />
                          </IconButton>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Collapse>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default ClimateControl
