import { NavigationPage } from '@/app/components'
import { Text, Icon, Button } from '@/components'

export default function VerifiedExistingUserPage() {
  const handleReturnToFlexSaverClick = async () => {
    window.location.href = 'https://pse.autogridflexsaver.com/pages/home'
  }

  return (
    <NavigationPage id="connect-device-page" navigationOptions={['nothing']}>
      <NavigationPage.SingleCardContent>
        <div className="flex justify-center w-full mb-6">
          <div className="rounded-full bg-green-500 flex p-[13px]">
            <Icon name="Check" size={24} color="white" strokeWidth={3} />
          </div>
        </div>
        <div className="flex flex-col items-center w-full">
          <Text variant="h2" className="mb-4" align="center">
            Welcome Back!
          </Text>
          <Text variant="body4" className="mb-10" align="center">
            Thanks for logging into your account! In the next few days, we will
            be in touch to help link your PSE and Optiwatt accounts and complete
            your enrollment to the PSE Flex EV program. Thanks for your
            patience.
          </Text>
        </div>
        <div>
          <Button
            id="return-to-flex-saver"
            variant="primary"
            className="w-full mt-6"
            onClick={handleReturnToFlexSaverClick}
          >
            Return to Your PSE Flex EV Program
          </Button>
        </div>
      </NavigationPage.SingleCardContent>
    </NavigationPage>
  )
}
