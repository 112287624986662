import { DeviceAuthAlertMessage } from '@/app/components'

export default function ThermostatBrokenConnectionBanner() {
  return (
    <DeviceAuthAlertMessage
      mode="error"
      deviceName="thermostat"
      linkText="Reconnect"
      reconnectUrl="/connect-device/thermostat"
      show
    />
  )
}
