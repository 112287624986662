enum EnvType {
  Local,
  Staging,
  Prod,
}

function getEnvType() {
  if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    return EnvType.Prod
  } else if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
    return EnvType.Staging
  } else {
    return EnvType.Local
  }
}

const Env = {
  isLocal: getEnvType() === EnvType.Local,
  isProd: getEnvType() === EnvType.Prod,
  isStaging: getEnvType() === EnvType.Staging,
}

export default Env
