import { clamp } from '../../limit'
import { TemperatureUnit } from '../../../types/temperatureUnit'
import { convertCelsiusToFahrenheit } from '../../conversionUtilities'

const colors: { [key: string]: string } = {
  '20': 'var(--color-tier-100)',
  '30': 'var(--color-tier-200)',
  '40': 'var(--color-tier-300)',
  '50': 'var(--color-tier-400)',
  '60': 'var(--color-tier-500)',
  '70': 'var(--color-tier-600)',
  '80': 'var(--color-tier-700)',
  '90': 'var(--color-tier-800)',
  '100': 'var(--color-tier-900)',
}

export default function getTemperatureGradient(
  min: number,
  max: number,
  unit?: TemperatureUnit,
) {
  // Convert the temperatures that are in celsius back to fahrenheit so the calculation below works
  if (unit === 'C') {
    min = convertCelsiusToFahrenheit(min)
    max = convertCelsiusToFahrenheit(max)
  }

  // Ensure min and max are not too big or too small
  min = clamp(20, min, 100)
  max = clamp(20, max, 100)

  // Get the closest minimum value by temperature
  const closestMin: number = [20, 30, 40, 50, 60, 70, 80, 90, 100].reduce(
    (previousValue, currentValue) => {
      return Math.abs(currentValue - min) <= Math.abs(previousValue - min)
        ? currentValue
        : previousValue
    },
  )

  // Get the closest maximum value by temperature
  const closestMax = [20, 30, 40, 50, 60, 70, 80, 90, 100].reduce(
    (previousValue, currentValue) => {
      return Math.abs(currentValue - max) <= Math.abs(previousValue - max)
        ? currentValue
        : previousValue
    },
  )

  // Filter out colors based on their corresponding value
  const keys = Object.keys(colors)
    .filter((value) => Number(value) >= closestMin)
    .filter((value) => Number(value) <= closestMax)

  // Return a comma-delimited string of colors
  return keys.length === 1
    ? // Uses the same color for gradient start and stop since there is only a single color
      keys.map((index) => [colors[index], colors[index]].join(', '))[0]
    : // Uses different colors for the gradient stops
      keys.map((index) => colors[index]).join(', ')
}
