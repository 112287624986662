import React from 'react'
import TeslaGiveawayMeterConnectionCard from '../TeslaGiveawayMeterConnectionCard'
import GridSupportSelectDialog from '../GridSupport/GridSupportSelectDialog'

// @todo make this component general to the content being displayed alongside the grid support dialog
// export interface DialogPresentingComponentProps {

// }
// type DialogPresentingComponent = React.FC<DialogPresentingComponentProps>;

export interface Props {
  meterConnectDialogOpen: boolean
  onMeterConnectDialogClose: () => void
  meterConnectionDialogConfirmationPending: boolean
  setMeterConnectionDialogConfirmationPending: (
    meterConnectionDialogConfirmationPending: boolean,
  ) => void
  onConnectCardDismiss: () => void
  onConnectButtonClick: () => void
  showCard: boolean
}

export default function MeterConnectionCardWithDialog({
  meterConnectDialogOpen,
  onMeterConnectDialogClose,
  meterConnectionDialogConfirmationPending,
  setMeterConnectionDialogConfirmationPending,
  onConnectCardDismiss,
  onConnectButtonClick,
  showCard,
}: Props) {
  return (
    <>
      {showCard && (
        <TeslaGiveawayMeterConnectionCard
          onButtonClick={onConnectButtonClick}
          onClose={onConnectCardDismiss}
        />
      )}
      <GridSupportSelectDialog
        open={meterConnectDialogOpen}
        onClose={onMeterConnectDialogClose}
        allowSkip={false}
        confirmationPending={meterConnectionDialogConfirmationPending}
        setConfirmationPending={setMeterConnectionDialogConfirmationPending}
      />
    </>
  )
}
