import * as auth from '@/actions/auth'
import { getVehicles } from '@/actions/vehicles'
import { AlertMessage, NavigationPage } from '@/app/components'
import LinkDiagram from '@/app/features/logos/LinkDiagram'
import { useNavigation } from '@/app/hooks'
import { useInitialDataLoader } from '@/authenticated/App'
import {
  Button,
  Flex,
  Icon,
  IconDetailListItem,
  List,
  Text,
} from '@/components'
import { IconNames } from '@/components/Icon/Icon'
import { useAppDispatch, useAppSelector, useUrlSearchParam } from '@/hooks'
import { Vehicle } from '@/types'
import clsx from 'clsx'
import { motion } from 'framer-motion'
import { ChevronDown } from 'react-feather'
import Car from '../car.png'
import OptiwattLogo from '../optiwatt-logo.webp'
import ExistingUserModalSurvey, { ExistingUserModal } from './existing-user'
import { fetchAuthenticated } from '@/utils/fetch'
import * as Sentry from '@sentry/react'
import { useProgramSpecificConfigFromUrlParam } from '@/app/hooks/useProgramSpecificConfigFromUrlParam'
import PageLoader from '@/authenticated/components/layout/PageLoader'

const listContent = (programName: string) => [
  {
    icon: 'Optiwatt',
    title: 'What is Optiwatt?',
    description:
      "Optiwatt's mission is to reduce global warming and increase EV adoption by creating the largest connected EV network. 70,000 cars and climbing!",
  },
  {
    icon: 'Share2',
    title: `Why is Optiwatt working with ${programName}?`,
    description: `Optiwatt manages the technical connection to your vehicle on behalf of ${programName}, adhering to the highest security standards.`,
  },
  {
    icon: 'Zap',
    title: 'Get more out of Optiwatt',
    description:
      'Create or link an Optiwatt account below. Log in anytime to access a world of savings, convenience, and insights.',
  },
]

function ProgramLogoComp(props: {
  programLogoSrc: string
  programName: string
}) {
  return (
    <div className="w-32 p-3">
      <img
        className="w-24"
        src={props.programLogoSrc}
        alt={props.programName}
      />
    </div>
  )
}

type AutogridConfirmationProps = {
  existingUser?: boolean
}

function Actions({
  existingUser,
  handleProfileCreation,
}: {
  existingUser?: boolean
  handleProfileCreation: () => void
}) {
  const navigation = useNavigation()
  const user = useAppSelector((state) => state.user?.user)
  const name = user?.username ?? useUrlSearchParam('firstName')
  const buttonContiunueText = name ? `Continue as ${name}` : 'Continue'

  if (existingUser) {
    return (
      <Button
        id="partner-confirmation-button"
        variant="primary"
        className="!mt-12 mb-6"
        onClick={() => {
          navigation.pushAddParam('show_login_modal', 'true')
        }}
      >
        {buttonContiunueText}
      </Button>
    )
  }

  return (
    <div className="flex flex-col items-center w-full mt-12">
      <Button
        id="partner-confirmation-button"
        variant="primary"
        className="!mt-12 mb-6"
        onClick={() => {
          handleProfileCreation()
          navigation.push('/connect-vehicle?value_prop=false&pre_auth=true')
        }}
      >
        {buttonContiunueText}
      </Button>
      <div className="flex items-center justify-center w-full">
        <a
          className="!underline"
          onClick={() => {
            navigation.pushAddParam('show_login_modal', 'true')
          }}
          target="_blank"
          rel="noreferrer"
        >
          Use a different Optiwatt account? Log In
        </a>
      </div>
    </div>
  )
}

export default function AutogridConfirmation({
  existingUser,
}: AutogridConfirmationProps) {
  const navigation = useNavigation()
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.user?.user ?? undefined)
  const existingUserPageOpen = useUrlSearchParam('show_login_modal') === 'true'
  const ExistingUserModalComponent = existingUser
    ? ExistingUserModal
    : ExistingUserModalSurvey
  const { config: programConfig, isLoading } =
    useProgramSpecificConfigFromUrlParam()
  const appDataDispatches = useInitialDataLoader(dispatch, false)

  const handleAfterLogin = async (res: Response) => {
    const body = await res.json()
    await dispatch({
      type: auth.LOGIN_TOKEN_SUCCESS,
      payload: {
        access_token: body.access,
        refresh_token: body.refresh,
      },
    })
    await appDataDispatches()
    // After we login, we should create a profile for the user
    await handleProfileCreation()

    const vehicles: { payload: Vehicle[] } = await dispatch(getVehicles())

    if (vehicles?.payload?.length > 0) {
      navigation.push('/autogrid/success?existing_user=true')
    } else {
      navigation.push(
        '/connect-vehicle?value_prop=false&pre_auth=true&existing_user=true',
      )
    }
  }

  const handleProfileCreation = async () => {
    await fetchAuthenticated('autogrid/confirm_profile_creation', {
      method: 'POST',
    }).then((response) => {
      if (!response.ok) {
        Sentry.captureMessage('Failed to create profile for autogrid', {
          user,
          level: 'error',
        })
      }
    })
  }

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <NavigationPage
      id="autogrid-confirmation-nav-page"
      navigationOptions={['nothing']}
    >
      <NavigationPage.SingleCardContent>
        <div className="flex flex-col items-center w-full">
          <img src={Car} alt="Car" className="max-w-[min(24em,100%)]" />
          <div className="h-16 border-l-2 border-dashed border-themed-blue-500 -mb-7" />
          <LinkDiagram
            borderClassNames="border-themed-blue-500"
            logos={[
              OptiwattLogo,
              <ProgramLogoComp
                programLogoSrc={programConfig.programLogoSrc}
                programName={programConfig.programName}
              />,
            ]}
          />
        </div>
        <div>
          <div className="flex flex-col items-center justify-center w-full mt-10 sm:hidden">
            <Text variant="body2">Scroll for more</Text>
            <motion.div
              className="flex flex-col mt-2"
              animate={{ y: ['0%', '10%', '0%'] }}
              transition={{ duration: 0.8, repeat: Infinity }}
            >
              {[...Array(3).keys()].map((x) => (
                <ChevronDown
                  key={x}
                  size={16}
                  color="#5D5D5D"
                  className="-mt-1"
                />
              ))}
            </motion.div>
          </div>
        </div>
        {existingUser && (
          <AlertMessage className="mt-7" variant="info">
            It seems you already have an Optiwatt account with this email
            address.
          </AlertMessage>
        )}
        <Text
          variant="h2"
          className={clsx({ '!mt-4': existingUser, '!mt-16': !existingUser })}
        >
          Connect Electric Vehicle
        </Text>
        <Text variant="body2" className="!mt-4 !mb-12">
          {programConfig.programName} partners with Optiwatt to connect to your
          vehicle.
        </Text>
        <List gap="md">
          {listContent(programConfig.programName).map((item, index) => (
            <Flex key={index} item>
              <IconDetailListItem
                icon={() => (
                  <div className="flex items-center justify-center w-8 h-8 font-bold rounded-lg bg-themed-blue-100 text-themed-blue-500">
                    <Icon
                      color="blue"
                      size={16}
                      name={item.icon as IconNames}
                    />
                  </div>
                )}
                title={item.title}
                description={item.description}
              />
            </Flex>
          ))}
        </List>
        <Actions
          existingUser={existingUser}
          handleProfileCreation={handleProfileCreation}
        />
      </NavigationPage.SingleCardContent>
      <ExistingUserModalComponent
        id="autogrid-existing-user-modal"
        open={existingUserPageOpen}
        afterLogin={existingUser ? handleAfterLogin : undefined}
        onClose={() => {
          navigation.pushRemoveParam('show_login_modal')
        }}
      />
    </NavigationPage>
  )
}
