import { CircularProgress, Grid } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { makeStyles, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minHeight: '100%',
    },
    alert: {
      paddingRight: theme.spacing(5),
      width: 'fit-content',
    },
  }),
)

const Loader = ({ color = 'secondary', message = undefined }) => {
  const classes = useStyles()
  return (
    <Grid
      container
      className={classes.root}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>
        <CircularProgress m={2} color={color} />
      </Grid>
      {message && (
        <Grid item xs={12}>
          <Alert className={classes.alert} severity="info">
            {message}
          </Alert>
        </Grid>
      )}
    </Grid>
  )
}
export default Loader
