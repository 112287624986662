import React from 'react'
import data from './resources/data.json'
import check from '@/app/img/check.svg'
import frown from '@/app/img/frown.svg'
import waiting from '@/app/img/waiting.svg'
import { Button, Icon, LoadingAnimation, Text } from '../../../components'
import { AlertMessage, Link } from '@/app/components'
import { TeslaFleetAuthenticationScopeName } from '@/types/teslaFleetAuthentication'
import { useUrlSearchParam } from '@/hooks'
import { ButtonContainer } from '@/app/pages/ava/BrandedPage'

export enum ConnectionStatusMode {
  Connecting = 'connecting',
  Connected = 'connected',
  Failed = 'failed',
}

const ConnectionStatusImageSrc = {
  [ConnectionStatusMode.Connecting]: waiting,
  [ConnectionStatusMode.Connected]: check,
  [ConnectionStatusMode.Failed]: frown,
}

const ConnectionStatus = (props: { mode: ConnectionStatusMode }) => {
  if (props.mode === ConnectionStatusMode.Connecting) {
    return <LoadingAnimation type="falling" />
  } else {
    return <img alt={props.mode} src={ConnectionStatusImageSrc[props.mode]} />
  }
}

export interface Props {
  className?: string
  mode: ConnectionStatusMode
  buttonAction?: () => void
  backButtonAction?: () => void
  statusText: { [key in ConnectionStatusMode]: string }
  statusSubText?: { [key in ConnectionStatusMode]?: string }
  alertText?: { [key in ConnectionStatusMode]?: string }
  buttonText?: { [key in ConnectionStatusMode]?: string }
  disableButton?: boolean
  missingScopes?: TeslaFleetAuthenticationScopeName[]
  children?: React.ReactNode
}

export default function DeviceConnectionRedirect({
  mode,
  statusText,
  statusSubText,
  alertText,
  buttonText,
  buttonAction,
  backButtonAction,
  disableButton,
  className,
  children,
}: Props) {
  const cancellable = mode === ConnectionStatusMode.Connecting

  return (
    <>
      <div
        className={`flex flex-col align-content-center justify-center items-center min-h-[100%] gap-6 ${className}`}
      >
        <ConnectionStatus mode={mode} />
        <Text className="max-w-[275px]" variant="h3" align="center">
          {statusText[mode]}
        </Text>
        {statusSubText?.[mode] && (
          <Text className="max-w-[275px]" variant="body2" align="center">
            {statusSubText[mode]}
          </Text>
        )}
        {alertText?.[mode] && (
          <AlertMessage
            variant={mode === ConnectionStatusMode.Failed ? 'error' : 'info'}
            rawHtmlText={alertText[mode]}
          />
        )}
        {children}
        <div className="flex min-w-[100%] gap-4 mt-auto">
          {backButtonAction && (
            <Button
              variant="navigation"
              id="device-connection-redirect-back-button"
              onClick={backButtonAction}
            >
              <Icon name="ChevronLeft" color="grey-500" size={20} />
            </Button>
          )}
          {buttonAction && (
            <Button
              variant={cancellable ? 'secondary' : 'primary'}
              id="select-utility-plan-card-select-button"
              onClick={buttonAction}
              disabled={disableButton}
            >
              {buttonText ? buttonText[mode] : data.buttonText[mode]}
            </Button>
          )}
        </div>
      </div>
    </>
  )
}
