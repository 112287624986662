import './styles.scss'
import React, { useState } from 'react'
import Divide from '../Divider/Divide'
import Text from '../Text'
import { ChevronDown, ChevronRight, ChevronUp } from 'react-feather'
import { AnimatePresence, motion } from 'framer-motion'
import Divider from '../Divider'
import BoxedSymbol from '../BoxedSymbol'
import { useMousePress } from '@/authenticated/hooks/useMousePress'

export type Props = {
  children: React.ReactNode
  maxItems?: number
}

function NavigationList(props: Props) {
  const maxItems = props.maxItems ?? 4

  const [showAll, setShowAll] = useState(false)

  const childrenArray = React.Children.toArray(props.children)
  const alwaysVisibleItems = childrenArray.slice(0, maxItems)
  const hiddenItems = childrenArray.slice(maxItems)

  const handleShowMoreClick = () => {
    setShowAll(!showAll)
  }

  const hiddenItemsContainerVariants = {
    hidden: { height: 0 },
    visible: { height: 'auto' },
  }

  return (
    <>
      <Divide>
        {/* Render the items that are always visible */}
        {alwaysVisibleItems.map((item, index) => (
          <div key={index}>{item}</div>
        ))}
      </Divide>
      {childrenArray.length > maxItems && <Divider />}
      <AnimatePresence>
        {showAll && (
          <motion.div
            key="hidden-items"
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={hiddenItemsContainerVariants}
            transition={{
              duration: 0.2,
              ease: 'easeOut',
            }}
            className="overflow-hidden"
          >
            <Divide>
              {/* Render the hidden items within the animated container */}
              {hiddenItems.map((item, index) => (
                <div key={index + maxItems}>{item}</div>
              ))}
            </Divide>
            <Divider />
          </motion.div>
        )}
      </AnimatePresence>
      {childrenArray.length > maxItems && (
        <ShowMoreItem onClick={handleShowMoreClick} showAll={showAll} />
      )}
    </>
  )
}

type ShowMoreItemProps = {
  onClick: () => void
  showAll: boolean
}

function ShowMoreItem(props: ShowMoreItemProps) {
  return (
    <button
      onClick={props.onClick}
      className="nav-item-button flex justify-center items-center w-full py-4 bg-transparent border-0 hover:cursor-pointer"
    >
      <div className="flex items-center space-x-2 h-10">
        <Text
          variant="body2"
          className="nav-item-label text-themed-grey-900 underline"
        >
          {props.showAll ? 'Show less' : 'Show more'}
        </Text>
        {props.showAll ? (
          <ChevronUp
            className="nav-item-chevron text-md text-themed-grey-900"
            size={16}
          />
        ) : (
          <ChevronDown
            className="nav-item-chevron text-md text-themed-grey-900"
            size={16}
          />
        )}
      </div>
    </button>
  )
}

type BasicListItemProps =
  | {
      id: string
      label: string
      onClick: () => void
      variant: 'image'
      imgSrc: string
      labelClasses?: string
    }
  | {
      id: string
      label: string
      onClick: () => void
      variant: 'character'
      character: string
      characterClasses?: string
      labelClasses?: string
    }

function BasicItem(props: BasicListItemProps) {
  const isMousePress = useMousePress()

  return (
    // todo make sure click events on children other than button don't happen
    <button
      id={props.id}
      className="nav-item-button grid grid-cols-[17%_1fr_1fr] items-center gap-x-4 w-full py-4 bg-transparent border-0 hover:cursor-pointer !px-0"
      onMouseDown={isMousePress ? props.onClick : undefined}
      onClick={!isMousePress ? props.onClick : undefined}
    >
      <div className="flex items-center justify-center space-x-3 h-10">
        {'character' in props && (
          <BoxedSymbol
            variant="character"
            character={props.character}
            color="blue"
            className={props.characterClasses}
          />
        )}
        {'imgSrc' in props && (
          <img
            src={props.imgSrc}
            alt={props.label}
            className="w-16 h-10 object-contain"
          />
        )}
      </div>

      <Text
        variant="subheader"
        className={`nav-item-label text-themed-grey-900 whitespace-nowrap ${props.labelClasses} justify-self-start`}
      >
        {props.label}
      </Text>
      <ChevronRight
        size={24}
        className="nav-item-chevron text-themed-grey-400 text-xl font-extrabold justify-self-end"
      />
    </button>
  )
}
BasicItem.displayName = 'NavigationListBasicItem'
NavigationList.BasicItem = BasicItem

export default NavigationList
