import { store } from '../store'
import getEventContext from './getEventContext'

/**
 * Sends an event to GTM with the given name and parameters.
 */
export function sendEvent(
  event: string,
  params: { [key: string]: string | number | boolean | object | null },
) {
  gtag('event', event, params)
}

/**
 *
 * @param {string} event
 * @param {string} eventId identifer for deduping events
 * @param {boolean} sendToPartners whether to send the event to partners attached to gtm.
 *  For example, if this is true, the logged event will be sent from gtm to facebook, google analytics, etc.
 *
 * Sends a log to GTM, where it is routed to various destinations.
 *
 * Note the event is sent as a `'tracking_event'`, which in our GTM setup means that the event will
 * be automatically routed to various partners (e.g. Google Analytics, Facebook, etc.).
 * See GTM for routing details: https://tagmanager.google.com/#/container/accounts/6000830922/containers/30957865/workspaces/19/tags
 *
 * If you want to send an event to GTM and avoid this automatic routing, use `sendEvent()` instead.
 *
 */
export function logEvent(
  event: string,
  eventId: string,
  eventMetadata: { [key: string]: string | number | boolean } | null = null,
  sendToPartners = true,
) {
  const state = store.getState()
  // Use the profile id from the server if it is available, otherwise fallback on the
  // id that is persisted. This is necessary for new logins/signups that change the user id.
  // Otherwise, the user id would be persisted from the previous login/signup.
  const recentlyFetchedProfileId = state.auth.access?.user_id
  const persistedUserId = state.logging.userId
  const userId = recentlyFetchedProfileId ?? persistedUserId
  const context = getEventContext(state)

  sendEvent('tracking_event', {
    send_to_partners: sendToPartners,
    user_id: userId,
    event_name: event,
    event_id: eventId,
    user_context: context,
    params: eventMetadata,
  })
}
