import useMockableViewModel from '@/hooks/useMockableViewModel'
import useVehicleSettingsManager from '@/hooks/useVehicleSettingsManager'
import { canCharge } from '@/utils/vehicles/permissions'
import { Vehicle, VehicleChargeSchedule } from '@/types'

export function vehicleIsChargeable(
  vehicle: Vehicle | undefined,
  chargeSchedule: VehicleChargeSchedule | undefined,
  utilityChargeControlOverride: boolean,
) {
  return (
    canCharge(vehicle) &&
    (utilityChargeControlOverride || chargeSchedule?.allow_charging_control)
  )
}

export function useViewModel() {
  const {
    vehicle,
    vehicleId,
    chargeSchedule,
    isLoading,
    utilityChargeControlOverride,
  } = useVehicleSettingsManager()

  return {
    chargeable: vehicleIsChargeable(
      vehicle,
      chargeSchedule,
      utilityChargeControlOverride,
    ),
    isLoading,
    vehicleId,
  }
}

function useMockViewModel() {
  return {
    chargeable: true,
    isLoading: false,
    vehicleId: 0,
  }
}

export default useMockableViewModel({ useViewModel, useMockViewModel })
