import React, { Component } from 'react'

import Header from '../components/Header/header'
import UpdatePasswordComponent from '../components/HeroSection/HeroSectionUpdatePassword'
import FooterSection from '../components/Footer'

class UpdatePassword extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <div className="main _use-deprecated-bootstrap _use-deprecated-style">
          <UpdatePasswordComponent />
        </div>
        <FooterSection />
      </React.Fragment>
    )
  }
}

export default UpdatePassword
