import { Charger, ChargerEfficiency } from '@/types'
import * as actions from '../actions/chargers'
import { getApiErrorMessage } from '../utils/errorUtilities'
import { PayloadAction } from '@reduxjs/toolkit'

type State = {
  chargers: Charger[] | null
  savings: ChargerEfficiency | null
  isLoading: boolean
  errors: Record<string, unknown>
}

const initialState: State = {
  chargers: null,
  savings: null,
  isLoading: false,
  errors: {},
}

export default (state = initialState, action: PayloadAction<any>): State => {
  switch (action.type) {
    case actions.CHARGER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        chargers: action.payload,
        errors: {},
      }
    case actions.CHARGER_SAVINGS_SUCCESS:
      return {
        ...state,
        isLoading: true,
        savings: action.payload,
        errors: {},
      }
    case actions.CHARGER_SAVINGS_REQUEST:
    case actions.CHARGER_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case actions.CHARGER_SAVINGS_FAILURE:
    case actions.CHARGER_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: getApiErrorMessage(action.payload),
      }
    case actions.CHARGER_SAVINGS_RESET:
      return {
        ...state,
        savings: null,
      }
    default:
      return state
  }
}
