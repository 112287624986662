import { useAppDispatch, useAppSelector } from '@/hooks'
import { surveyInteractionCollection } from '@/reducers/surveyInteractions'
import { surveyResponsesCollection } from '@/reducers/surveyResponses'
import { SurveyInteraction, SurveyResponse, SurveyType } from '@/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { useEffect } from 'react'

type SurveyObserverState = {
  activeSurveyResponseIDs: SurveyResponse['id'][] | null
}

const initialState: SurveyObserverState = {
  activeSurveyResponseIDs: null,
}

const surveyObserverSlice = createSlice({
  name: 'SurveyObserver',
  initialState,
  reducers: {
    addActiveSurveyResponseID: (
      state,
      action: PayloadAction<SurveyResponse['id']>,
    ) => {
      if (!state.activeSurveyResponseIDs) {
        state.activeSurveyResponseIDs = []
      }
      if (state.activeSurveyResponseIDs.includes(action.payload)) {
        return
      }
      state.activeSurveyResponseIDs.push(action.payload)
    },
  },
})

export const { addActiveSurveyResponseID } = surveyObserverSlice.actions
export default surveyObserverSlice.reducer

class SurveyResponseBuilder {
  private response: Partial<SurveyResponse> = {}

  see() {
    this.response.last_seen = dayjs().toISOString()
    return this
  }

  complete(done = true) {
    if (done) {
      this.response.completed = done ? dayjs().toISOString() : null
    }
    return this
  }

  dismiss() {
    this.response.dismissed = dayjs().toISOString()
    return this
  }

  respond(response: SurveyResponse['response']) {
    this.response.response = response
    return this
  }

  build() {
    return this.response
  }
}

export function useSurveyResponder(surveyType: SurveyType) {
  const dispatch = useAppDispatch()

  const activeSurveyResponse = useAppSelector((state) => {
    const activeResponse =
      state.components.SurveyObserver.activeSurveyResponseIDs
        // turn the array of IDs into an array of SurveyResponse objects
        ?.map((responseID) =>
          surveyResponsesCollection.selectors.selectById(state, responseID),
        )
        // find the one that matches the provided type
        .find((response) => response?.survey.type === surveyType)
    return activeResponse
  })

  const activeSurveyResponseErrors = useAppSelector((state) => {
    if (!activeSurveyResponse?.id) {
      return null
    }
    const queryState =
      surveyResponsesCollection.selectors.queryState.selectByResourceId(
        state,
        activeSurveyResponse.id,
      )
    return queryState.map((s) => s.error)
  })

  if (!activeSurveyResponse) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return {
      activeSurveyResponse,
      activeSurveyResponseErrors,
      markSurveySeen() {},
      completeSurvey() {},
      submitResponse(
        responses: Omit<SurveyResponse['response'], 'version'>,
        completed = true,
      ) {},
      appendResponse(responses: Omit<SurveyResponse['response'], 'version'>) {},
      dismissSurvey() {},
      addSurveyInteraction(
        interaction: Partial<Pick<SurveyInteraction, 'action' | 'action_data'>>,
      ) {},
    }
  }

  const updateSurveyResponse = async (
    surveyResponse: Partial<SurveyResponse>,
  ) => {
    const response = await dispatch(
      surveyResponsesCollection.actions.update(
        activeSurveyResponse.id,
        surveyResponse,
        { optimistic: true, params: { survey_type: surveyType } },
      ),
    )
    return response
  }

  const appendSurveyResponse = async (
    surveyResponse: Partial<SurveyResponse>,
  ) => {
    const response = await dispatch(
      surveyResponsesCollection.actions.update(
        `${activeSurveyResponse.id}/append`,
        surveyResponse,
        { optimistic: true },
      ),
    )
    return response
  }

  return {
    activeSurveyResponse,
    activeSurveyResponseErrors,
    markSurveySeen() {
      const surveyResponse = new SurveyResponseBuilder().see().build()
      updateSurveyResponse(surveyResponse)
    },
    completeSurvey() {
      const surveyResponse = new SurveyResponseBuilder()
        .see()
        .complete()
        .build()
      return updateSurveyResponse(surveyResponse)
    },
    submitResponse(
      response: Omit<SurveyResponse['response'], 'version'>,
      completed = true,
    ) {
      const surveyResponse = new SurveyResponseBuilder()
        .see()
        .complete(completed)
        .respond({ ...response, version: activeSurveyResponse.survey.version })
        .build()
      return updateSurveyResponse(surveyResponse)
    },
    appendResponse(response: Omit<SurveyResponse['response'], 'version'>) {
      const surveyResponse = new SurveyResponseBuilder()
        .respond({
          ...response,
          version: activeSurveyResponse.survey.version,
          responded_at: dayjs().toISOString(),
        })
        .build()
      return appendSurveyResponse(surveyResponse)
    },
    dismissSurvey() {
      const surveyResponse = new SurveyResponseBuilder().see().dismiss().build()
      updateSurveyResponse(surveyResponse)
    },
    addSurveyInteraction(
      interaction: Partial<Pick<SurveyInteraction, 'action' | 'action_data'>>,
    ) {
      const surveyInteraction = {
        survey_response: activeSurveyResponse.id,
        ...interaction,
      }
      dispatch(surveyInteractionCollection.actions.create(surveyInteraction))
    },
  }
}

export function useMarkSurveySeen({
  withInTimeMs,
  surveyType,
}: {
  withInTimeMs: number
  surveyType: SurveyType
}) {
  const { activeSurveyResponse, activeSurveyResponseErrors, markSurveySeen } =
    useSurveyResponder(surveyType)

  useEffect(() => {
    const lastSeen = activeSurveyResponse?.last_seen
    if (!lastSeen) {
      markSurveySeen()
    }

    if (activeSurveyResponseErrors?.length) {
      return
    }

    if (
      lastSeen &&
      // if last seen not within the last hour, mark as seen
      new Date().getTime() - new Date(lastSeen).getTime() > withInTimeMs
    ) {
      markSurveySeen()
    }
  }, [activeSurveyResponse?.last_seen, activeSurveyResponseErrors?.length])
}
