import { Card, Icon, Skeleton, Text } from '@/components'
import { IconNames } from '@/components/Icon/Icon'
import {
  chargeForecastCollection,
  chargesCollection,
  getLatestCharge,
} from '@/reducers/chargeRelatedCollections'
import { ChargeForecastDashboardViewProps, DisplayMode } from './types'
import { getIsCharging, getIsPluggedIn } from './utils'
import clsx from 'clsx'
import useVehicleChargeSchedulesFetcher from '@/authenticated/hooks/useVehicleChargeSchedulesFetcher'
import { chargeNowEventCollection } from '@/reducers/chargeNowEvents'
import { RequestStatus } from '@/request/types'
import ChargeForecastSlider from './ChargeForecastSlider'
import { ChargeNowButton } from './ChargeNowButton'
import SmartChargeSchedule from './SmartChargeSchedule'
import { ChargeForecast } from '@/types'
import { useDistanceUnit } from '@/authenticated/hooks/conversions'
import { useAppSelector } from '@/hooks'
import ChargeForecastSubtitle from './ChargeForecastSubtitle'
import { AnimatePresence, motion } from 'framer-motion'
import { logEvent } from '@/logging'
import { useEffect, useState } from 'react'
import { LockedFeature } from '@/app/components'

function useViewModel(props: ChargeForecastDashboardViewProps) {
  const [chargeLimit, setChargeLimit] = useState<number | undefined>(undefined)
  const deviceType: 'vehicle' | 'vehicle_charger' = props.vehicleChargerId
    ? 'vehicle_charger'
    : 'vehicle'
  const deviceId =
    deviceType === 'vehicle' ? props.vehicleId : props.vehicleChargerId

  if (!deviceId) {
    console.error('ChargeForecastDashboardView: Missing vehicle or charger ID')
  }

  const { data: chargeForecasts, status: chargeForecastStatus } =
    chargeForecastCollection.useFetch({
      params: {
        charger_id: props.vehicleChargerId,
        vehicle_id: props.vehicleId,
      },
    })
  const { data: charges, status: chargesStatus } = chargesCollection.useFetch({
    params: {
      charger_id: props.vehicleChargerId,
      vehicle_id: props.vehicleId,
    },
  })
  const { status: chargeNowRequestStatus } = chargeNowEventCollection.useFetch(
    `${deviceType}/${deviceId}/charge_now`,
  )

  const hasHome = chargeForecasts?.[0]?.type !== 'no home'
  const chargeForecast: ChargeForecast | undefined =
    hasHome && chargeForecasts?.length ? chargeForecasts?.[0] : undefined

  const chargeSchedule = useVehicleChargeSchedulesFetcher()?.find(
    (schedule) =>
      (deviceType === 'vehicle' && schedule.vehicle_id === props.vehicleId) ||
      (deviceType === 'vehicle_charger' &&
        schedule.charger_id === props.vehicleChargerId),
  )
  const [userPreferredDistanceUnit] = useDistanceUnit()
  const milesPerBatteryPercent = useAppSelector((state) =>
    deviceType === 'vehicle' && props.vehicleId
      ? state.vehicles.vehicles?.find(
          (vehicle) => vehicle.id === props.vehicleId,
        )?.miles_per_battery_percent
      : null,
  )

  const isPluggedIn = getIsPluggedIn(chargeForecast)
  const latestCharge = getLatestCharge(charges[0]?.results ?? [])

  let mode: DisplayMode = 'NotPluggedIn'

  if (isPluggedIn) {
    mode = 'PluggedIn'
  }

  if (getIsCharging(chargeForecast)) {
    mode = 'Charging'
  }

  if (!chargeForecast) {
    mode = 'Unknown'
  }

  const isHome = chargeForecast?.is_home ?? false

  const content = {
    title: 'Not plugged in' as string,
    subtitle: undefined as string | undefined,
    icon: 'StrikedPlug' as IconNames,
  }

  switch (mode) {
    case 'Charging': {
      content.title = 'Charging'
      content.icon = isHome ? 'HomeCharge' : 'Zap'
      break
    }
    case 'PluggedIn': {
      content.title = 'Plugged in, not charging'
      content.icon = isHome ? 'HomePlug' : 'Plug'
      break
    }
    case 'NotPluggedIn': {
      content.title = 'Not plugged in'
      content.icon = isHome ? 'Home' : 'StrikedPlug'
      break
    }
    case 'Unknown': {
      content.title = 'Charge Limit Controller'
      content.icon = 'Zap'
      break
    }
  }

  // make sure to overrule local value when actual value comes in.
  useEffect(() => {
    setChargeLimit(chargeSchedule?.battery_target_max)
  }, [chargeSchedule?.battery_target_max])

  const smartScheduleProps =
    chargeForecast && chargeSchedule && deviceId
      ? {
          deviceType,
          deviceId,
          chargeForecast,
          chargeSchedule,
        }
      : null

  // log that the component was seen
  useEffect(() => {
    logEvent('chargeforecast_seen')
  }, [])

  return {
    // @todo actually use this loading state
    isLoading:
      chargeForecastStatus === RequestStatus.Loading ||
      chargesStatus === RequestStatus.Loading ||
      chargeNowRequestStatus === RequestStatus.Loading,
    deviceType,
    deviceId,
    mode,
    content,
    chargeForecast,
    chargeSchedule,
    smartScheduleProps,
    latestCharge,
    milesPerBatteryPercent: milesPerBatteryPercent ?? null,
    userPreferredDistanceUnit,
    chargeLimit,
    setChargeLimit,
    hasHome,
    isHome,
  }
}

export default function ChargeForecastDashboardView(
  props: ChargeForecastDashboardViewProps,
) {
  const viewModel = useViewModel(props)

  return (
    <Card>
      <div className="flex flex-col gap-6">
        <div className="flex">
          {viewModel.isLoading ? (
            <div className="w-8" />
          ) : (
            viewModel.content.icon && (
              <Icon
                color={viewModel.mode === 'Charging' ? 'green-500' : 'grey-900'}
                name={viewModel.content.icon}
                className="mt-1 mr-2"
              />
            )
          )}
          <div className="flex flex-col mt-1">
            <AnimatePresence mode="popLayout">
              {viewModel.isLoading ? (
                <motion.div
                  key="skeleton"
                  animate={{ opacity: 1 }}
                  initial={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="-mt"
                >
                  <Skeleton width={200} height={25} />
                  <Skeleton width={150} height={18} />
                </motion.div>
              ) : (
                <motion.div
                  key="content"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <Text
                    variant="h3"
                    className={clsx({
                      'text-themed-green-900': viewModel.mode === 'Charging',
                    })}
                  >
                    {viewModel.content.title}
                  </Text>
                  <ChargeForecastSubtitle
                    chargeForecast={viewModel.chargeForecast}
                    chargeLimit={viewModel.chargeLimit}
                    chargeSchedule={viewModel.chargeSchedule}
                    latestCharge={viewModel.latestCharge}
                    milesPerBatteryPercent={viewModel.milesPerBatteryPercent}
                    userPreferredDistanceUnit={
                      viewModel.userPreferredDistanceUnit
                    }
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
        {props.vehicleId && (
          <ChargeForecastSlider
            loading={viewModel.isLoading}
            vehicleId={props.vehicleId}
            onChange={viewModel.setChargeLimit}
          />
        )}
        {!viewModel.isLoading && (
          <LockedFeature
            title="Smart Charge Schedule"
            connectionRequirements={['home']}
            className="flex flex-col gap-4 p-5 rounded-xl bg-themed-base-100 !drop-shadow-none"
          >
            {viewModel.smartScheduleProps && (
              <SmartChargeSchedule {...viewModel.smartScheduleProps} />
            )}
          </LockedFeature>
        )}
        <ChargeNowButton
          deviceType={viewModel.deviceType}
          vehicleChargerId={props.vehicleChargerId}
          vehicleId={props.vehicleId}
        />
      </div>
    </Card>
  )
}
