import { ResourceMiddlewareFunction, ResourceModel } from '../types'

export function createForeignKeysFromPathMiddleware<
  Resource extends ResourceModel,
>(
  createForeignKeysFromPath: (pathToIdMap: {
    [key: string]: number
  }) => Partial<Resource>,
): ResourceMiddlewareFunction<Resource> {
  return async (requestData, data) => {
    const path = requestData.endpoint

    if (typeof path !== 'string') {
      return data
    }

    // create a map from path portions to ids (ints).
    // example: /programs/1/utility_programs/2/vehicle_enrollments/3
    // would become { programs: 1, utility_programs: 2, vehicle_enrollments: 3 }
    const pathToIdMap = path
      .split('/')
      .filter((pathPart) => !isNaN(Number(pathPart)))
      .reduce((acc, pathPart, index, pathParts) => {
        // Check if the current part is a number and the previous part exists
        if (!isNaN(Number(pathPart)) && index > 0) {
          const key = pathParts[index - 1] // Take the preceding part as the key
          acc[key] = Number(pathPart) // Assign the value to the accumulator
        }
        return acc
      }, {} as { [key: string]: number })

    const foreignKeys = createForeignKeysFromPath(pathToIdMap)

    if (Array.isArray(data)) {
      return data.map((datum) => ({ ...datum, ...foreignKeys }))
    }

    return { ...data, ...foreignKeys }
  }
}
