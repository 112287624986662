import * as user from '../actions/user'
import * as calendar from '../actions/calendar'
import * as auth from '../actions/auth'
import * as plans from '../actions/plans'
import * as schedule from '../actions/schedule'
import * as units from '../actions/units'
import * as utilities from '../actions/utilities'
import { getApiErrorMessage } from '../utils/errorUtilities'
import _ from 'lodash'
import { PayloadAction } from '@reduxjs/toolkit'
import { User } from '@/types'

type Metadata = {
  existingAutogridUser: boolean
}

type State = {
  user: User | null
  isFetchingUser: boolean
  errors: Record<string, unknown>
  metadata?: Metadata
}

const initialState: State = {
  user: null,
  isFetchingUser: false,
  errors: {},
}

// TODO how to listen just to billing start changes?

export default (state = initialState, action: PayloadAction<any>): State => {
  switch (action.type) {
    case user.USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isFetchingUser: false,
      }
    case user.SET_USER:
      return {
        ...state,
        user: { ..._.merge(state.user, action.payload) },
      }
    case user.SET_USER_METADATA:
      return {
        ...state,
        metadata: { ..._.merge(state.metadata, action.payload) },
      }
    case calendar.ENABLE_CALENDAR_REQUEST:
      return {
        ...state,
        isFetchingUser: true,
      }
    case user.USER_REQUEST:
      return {
        ...state,
        isFetchingUser: true,
      }
    case user.USER_FAILURE:
    case user.USER_PATCH_BILLING_START_FAILURE:
      return {
        ...state,
        isFetchingUser: false,
        errors: getApiErrorMessage(action.payload),
      }
    case user.USER_PATCH_BILLING_START_SUCCESS:
      return {
        ...state,
        user: action.payload,
      }
    case auth.SIGNUP_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
      }
    case utilities.MAKE_GRID_SUPPORT_SELECTION_SUCCESS:
      return {
        ...state,
        user: action.payload,
      }
    case calendar.ENABLE_CALENDAR_SUCCESS:
    case schedule.MARK_CHARGE_SCHEDULE_SUCCESS:
    case plans.UPLOAD_UTILITY_BILL_SUCCESS:
    case units.DISTANCE_UNITS_SUCCESS:
    case units.TEMPERATURE_UNITS_SUCCESS:
    case utilities.SELECT_UTILITY_SUCCESS:
    case plans.SELECT_PLAN_SUCCESS:
      return {
        ...state,
        user: action.payload,
      }
    default:
      return state
  }
}

export const currentUser = (state: State) => state.user
export const isFetchingUser = (state: State) => state.isFetchingUser
