import * as feedback from '../actions/feedback'
import * as vehicleActions from '../actions/vehicles'
import * as vehicleAuthActions from '../actions/vehicleAuth'
import { getApiErrorMessage } from '../utils/errorUtilities'

const initialState = {
  feedbackReason: null,
  hasContactFormSucceeded: false,
  contactFormLoading: false,
  errors: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case feedback.FEEDBACK_SUCCESS:
      return {
        ...state,
        hasContactFormSucceeded: true,
        contactFormLoading: false,
        errors: {},
      }
    case feedback.FEEDBACK_REQUEST:
      return {
        ...state,
        contactFormLoading: true,
      }
    case feedback.FEEDBACK_FAILURE:
      return {
        ...state,
        contactFormLoading: false,
        errors: getApiErrorMessage(action.payload),
      }
    case feedback.RESET_FEEDBACK_FORM:
      return {
        ...initialState,
      }
    case vehicleActions.DISABLE_VEHICLE_ACTIONS_SUCCESS:
      return {
        ...state,
        feedbackReason: 'disabling',
      }
    case vehicleAuthActions.DELETE_VEHICLE_SUCCESS:
      return {
        ...state,
        feedbackReason: 'deleting',
      }
    default:
      return state
  }
}
