import { RootState } from '@/reducers'
import { thermostatDemandResponseEventsCollection } from '@/reducers/thermostatDemandResponseEvents'
import { ID } from '@/request/types'
import { ThermostatDemandResponseEvent } from '@/types'
import { createSelector } from 'reselect'

export const selectWithThermostatId = createSelector(
  thermostatDemandResponseEventsCollection.selectors.selectAll,
  (_: RootState, thermostatId: ID) => thermostatId,
  (events, thermostatId) =>
    events.filter((event) => event.thermostat_id === thermostatId),
)

export const selectNextWithThermostatId = createSelector(
  selectWithThermostatId,
  (events): ThermostatDemandResponseEvent | undefined =>
    events
      // filter events where endtime has passed
      .filter((event) => new Date(event.end_datetime).getTime() >= Date.now())
      // sort by start time
      .sort(
        (a, b) =>
          new Date(a.start_datetime).getTime() -
          new Date(b.start_datetime).getTime(),
      )[0],
)
