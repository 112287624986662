import './styles.scss'
import formatClasses from '../../utils/classes/formatClasses'
import { HTMLAttributes } from 'react'

export interface Props extends HTMLAttributes<HTMLDivElement> {}

export default function CardActions({ children, className, ...props }: Props) {
  const classes = {
    container: formatClasses(['card-actions', className]),
    content: formatClasses(['card-actions-content']),
  }

  return (
    <div className={classes.container} {...props}>
      <div className={classes.content}>{children}</div>
    </div>
  )
}
