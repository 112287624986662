import { useNavigation } from '@/app/hooks'
import { useIsAvaBasePath } from '@/app/hooks/ava/useAvaVerification'
import { BoxedSymbol } from '@/components'
import Text from '@/components/Text/Text'

type Props = {
  excludeHeader?: boolean
}

export default function TeslaEditPermissions({ excludeHeader }: Props) {
  const PERMISSIONS_URL = process.env
    .REACT_APP_TESLA_FLEET_ACCOUNT_URL as string
  const navigation = useNavigation()
  const isAva = useIsAvaBasePath()
  const reconnectUrl = isAva ? '/ava/connect-vehicle' : '/connect-vehicle'

  return (
    <div
      className="border-solid border-gray-200 rounded-lg p-4 flex flex-col gap-4"
      style={{ borderWidth: '1px' }}
    >
      {!excludeHeader && (
        <div>
          <Text variant="subheader">Edit Permissions</Text>
          <Text variant="body4">
            For the best experience with Optiwatt, enable all permissions. See
            the instructions below to update, or simply click 'Continue' to
            proceed.
          </Text>
        </div>
      )}
      <div className="flex flex-row gap-4">
        <div className="block">
          <BoxedSymbol character="1" variant="character" color="blue" />
        </div>
        <div className="flex flex-col gap-2">
          <Text variant="h3">Revoke Optiwatt’s access</Text>
          <Text variant="body4">
            This is a necessary step to refresh and update your permissions. It
            disconnects Optiwatt from your vehicle, clearing the way for new
            settings.
          </Text>
          <a
            href={PERMISSIONS_URL}
            className="!underline font-bold"
            target="_blank"
            rel="noreferrer"
          >
            Revoke access
          </a>
        </div>
      </div>
      <div className="flex flex-row gap-4">
        <div className="block">
          <BoxedSymbol character="2" variant="character" color="blue" />
        </div>
        <div className="flex flex-col gap-2">
          <Text variant="h3">Reconnect your vehicle</Text>
          <Text variant="body4">
            Click on ‘Reconnect your vehicle’ below to start the process of
            updating your permissions. When reconnecting, you’ll pick the
            permissions you want and features that work for you.
          </Text>
          <div
            onClick={() => navigation.push(reconnectUrl)}
            className="!underline font-bold hover:cursor-pointer"
            style={{ color: '#007AFF' }}
          >
            Reconnect your vehicle
          </div>
        </div>
      </div>
    </div>
  )
}
