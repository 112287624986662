import { ViewModelComponentProps } from '@/utils/typeUtilities'
import UtilityProgramEligibleDeviceEnrollmentFlowStepRoot, {
  Props as ComponentProps,
} from './UtilityProgramEligibleDeviceEnrollmentFlowStep'
import useViewModel from './useViewModel'

export type Props = ViewModelComponentProps<
  ComponentProps,
  typeof useViewModel
> & {
  hideTitle?: boolean
}

export default function UtilityProgramEligibleDeviceEnrollmentFlowStep(
  props: Props,
) {
  const viewModel = useViewModel(props)
  return (
    <UtilityProgramEligibleDeviceEnrollmentFlowStepRoot
      {...viewModel}
      {...props}
    />
  )
}
