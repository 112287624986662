import { createSelector } from '@reduxjs/toolkit'
import {
  selectUtilityProgramEnrollmentForUser,
  utilityProgramEnrollmentCollection,
} from '../reducers/utilityProgramEnrollments'
import {
  selectUtilityProgramForUserUtility,
  utilityProgramCollection,
} from '../reducers/utilityPrograms'
import selectUserCanAndHasntIntegratedUtility from './selectUserCanAndHasntIntegratedUtility'

const selectUserShouldTakeUtilityAction = createSelector(
  [
    (state) => Boolean(state.user.user?.profile?.utility),
    (state) => Boolean(selectUtilityProgramForUserUtility(state)),
    (state) => selectUserCanAndHasntIntegratedUtility(state),
    (state) => Boolean(selectUtilityProgramEnrollmentForUser(state)),
    (state) => {
      const loadingUtilityPrograms =
        utilityProgramCollection.selectors.queryState.someLoading(state)
      const loadingUtilityProgramEnrollments =
        utilityProgramEnrollmentCollection.selectors.queryState.someLoading(
          state,
        )
      const loadingUtilities = state.utilities.isLoading
      const loadingUser = state.user.isFetchingUser
      return [
        loadingUtilityPrograms,
        loadingUtilityProgramEnrollments,
        loadingUtilities,
        loadingUser,
      ].some(Boolean)
    },
  ],
  (
    hasUtility,
    hasProgram,
    canAndHasntIntegratedUtility,
    hasEnrollment,
    loading,
  ) => {
    return {
      value:
        !hasEnrollment &&
        (hasProgram || canAndHasntIntegratedUtility || !hasUtility),
      loading,
    }
  },
)

export default selectUserShouldTakeUtilityAction
