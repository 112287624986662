import { RSAA } from 'redux-api-middleware'
import config from '../config'
import { withAuth } from '../reducers'

export const LIST_REQUEST = '@@calendar/LIST_REQUEST'
export const LIST_SUCCESS = '@@calendar/LIST_SUCCESS'
export const LIST_FAILURE = '@@calendar/LIST_FAILURE'

export const CREATE_REQUEST = '@@calendar/CREATE_REQUEST'
export const CREATE_SUCCESS = '@@calendar/CREATE_SUCCESS'
export const CREATE_FAILURE = '@@calendar/CREATE_FAILURE'

export const UPDATE_REQUEST = '@@calendar/UPDATE_REQUEST'
export const UPDATE_SUCCESS = '@@calendar/UPDATE_SUCCESS'
export const UPDATE_FAILURE = '@@calendar/UPDATE_FAILURE'

export const DELETE_REQUEST = '@@calendar/DELETE_REQUEST'
export const DELETE_SUCCESS = '@@calendar/DELETE_SUCCESS'
export const DELETE_FAILURE = '@@calendar/DELETE_FAILURE'

export const DELETE_ALL_REQUEST = '@@calendar/DELETE_ALL_REQUEST'
export const DELETE_ALL_SUCCESS = '@@calendar/DELETE_ALL_SUCCESS'
export const DELETE_ALL_FAILURE = '@@calendar/DELETE_ALL_FAILURE'

export const ENABLE_CALENDAR_REQUEST = '@@calendar/ENABLE_CALENDAR_REQUEST'
export const ENABLE_CALENDAR_SUCCESS = '@@calendar/ENABLE_CALENDAR_SUCCESS'
export const ENABLE_CALENDAR_FAILURE = '@@calendar/ENABLE_CALENDAR_FAILURE'

export const getTrips = (params) => ({
  [RSAA]: {
    endpoint: config.apiGateway.URL + '/api/trips/',
    method: 'GET',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [LIST_REQUEST, LIST_SUCCESS, LIST_FAILURE],
    ...(params ? { params } : {}),
  },
})

export const createTrip = (trip) => ({
  [RSAA]: {
    endpoint: config.apiGateway.URL + `/api/trips/`,
    method: 'POST',
    body: JSON.stringify(trip),
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
  },
})

export const updateTrip = (trip) => ({
  [RSAA]: {
    endpoint: config.apiGateway.URL + `/api/trips/${trip['id']}/`,
    method: 'PUT',
    body: JSON.stringify(trip),
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
  },
})

export const deleteTrip = (tripId) => ({
  [RSAA]: {
    endpoint: `${config.apiGateway.URL}/api/trips/${tripId}/`,
    method: 'DELETE',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      DELETE_REQUEST,
      {
        type: DELETE_SUCCESS,
        payload: { id: tripId },
      },
      DELETE_FAILURE,
    ],
  },
})

export const deleteTrips = (body) => ({
  [RSAA]: {
    endpoint: config.apiGateway.URL + `/api/trips/delete_all/`,
    method: 'POST',
    ...(body ? { body: JSON.stringify(body) } : {}),
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [DELETE_ALL_REQUEST, DELETE_ALL_SUCCESS, DELETE_ALL_FAILURE],
  },
})

/*

enableCalendar will use the current_user API endpoint to set currentUser.profile.has_enabled_calendar to true
*/
export const enableCalendar = () => ({
  [RSAA]: {
    endpoint: `${config.apiGateway.URL}/api/current_user/`,
    method: 'PATCH',
    body: JSON.stringify({ has_enabled_calendar: true }),
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [
      ENABLE_CALENDAR_REQUEST,
      ENABLE_CALENDAR_SUCCESS,
      ENABLE_CALENDAR_FAILURE,
    ],
  },
})
