import { TransactionRedemption } from '../types/transactionRedemption'
import { createResourceCollection } from '../request'

export const transactionsRedemptionCollection =
  createResourceCollection<TransactionRedemption>({
    name: 'transactionsRedemption',
    apiConfig: {
      path: 'transactions/redeem',
    },
    selector: (state) => state.transactionsRedemption,
  })

const transactionsRedemptionReducer =
  transactionsRedemptionCollection.slice.reducer

export default transactionsRedemptionReducer
