import { Modal, Text, Button } from '@/components'
import { plansCollection } from '@/reducers/plansCollection'
import { useEffect, useState } from 'react'
import { useSurveyResponder } from '../reducer'
import GreenCheck from '@/app/img/check.svg'
import WhichPlanBody from './WhichPlan'
import { useNavigation } from '@/app/hooks'
import { logEvent } from '@/logging'

type SurveyDisplayMode = 'did-you-switch' | 'which-plan' | 'complete'

type Props = {
  onSubmit: (response: { plan_id: number | null; switched: boolean }) => void
  onDismiss: () => void
  surveyVersion: string
  utilityId: number
  surveyOpen: boolean
  onClose: () => void
}

/**
 This extension exists because there's a case where we want to handle the GEXA completion
 survey outside the scope of the Survey Observer. In other cases, though, this wrapper exists
 and can be used in the typical SurveyObserver way -- AVild
 **/
export function GexaCompleteSwitchSurveyWrapped() {
  const { dismissSurvey, activeSurveyResponse, submitResponse } =
    useSurveyResponder('GexaCompleteSwitch')

  const [surveyOpen, setSurveyOpen] = useState(true)

  const utilityId = activeSurveyResponse?.survey?.metadata?.utility_id as string

  if (!activeSurveyResponse || !utilityId) {
    return
  }

  return (
    <GexaCompleteSwitchSurvey
      onSubmit={submitResponse}
      onDismiss={dismissSurvey}
      surveyVersion={activeSurveyResponse.survey.version}
      utilityId={+utilityId}
      surveyOpen={surveyOpen}
      onClose={() => setSurveyOpen(false)}
    />
  )
}

export default function GexaCompleteSwitchSurvey(props: Props) {
  const {
    surveyOpen,
    surveyMode,
    utilityId,
    plans,
    handleUserAnsweredSwitched,
    handleUserAnsweredSwitchedNo,
    handlePlanSubmit,
    handleFinishSurvey,
    closeAndSubmitSurveyIfAnswered,
  } = useViewModel(props)

  const renderBody = () => {
    switch (surveyMode) {
      case 'did-you-switch':
        return (
          <DidYouSwitchBody
            onYes={handleUserAnsweredSwitched}
            onNo={handleUserAnsweredSwitchedNo}
          />
        )
      case 'which-plan': {
        if (!utilityId || !plans) {
          return null
        }
        return (
          <WhichPlanBody
            plans={plans}
            utilityId={utilityId}
            onPlanSubmit={handlePlanSubmit}
          />
        )
      }
      case 'complete':
        return <CompleteBody onGotItClicked={handleFinishSurvey} />
      default:
        return null
    }
  }

  const title = {
    'did-you-switch': 'Did You Complete the Switch to GEXA?',
    'which-plan': 'Which plan did you switch to?',
    complete: undefined,
  }[surveyMode]

  return (
    <Modal
      id="gexa-complete-switch-survey"
      open={surveyOpen}
      title={title}
      onClose={closeAndSubmitSurveyIfAnswered}
    >
      <div className="w-full h-[224px]">{renderBody()}</div>
    </Modal>
  )
}

function useViewModel(props: Props) {
  const navigation = useNavigation()
  const [surveyMode, setSurveyMode] =
    useState<SurveyDisplayMode>('did-you-switch')
  const [userSwitched, setUserSwitched] = useState<boolean | null>(null)
  const [selectedPlanId, setSelectedPlanId] = useState<number | null>(null)

  const { data: plans } = plansCollection.useFetch('plans', {
    params: { utility_id: props.utilityId },
    require: Boolean(props.utilityId),
  })

  const submitSurveyIfAnswered = () => {
    if (userSwitched === null) {
      return
    }
    props.onSubmit({
      switched: userSwitched,
      plan_id: selectedPlanId ?? null,
    })
  }

  const handleUserAnsweredSwitched = () => {
    setUserSwitched(true)
    logEvent('switchConfirmationYesClicked')
    setSurveyMode('which-plan')
  }
  const handleUserAnsweredSwitchedNo = () => {
    props.onSubmit({
      switched: false,
      plan_id: null,
    })
    logEvent('switchConfirmationNoClicked')
    setSurveyMode('did-you-switch')
    props.onClose()
  }
  const handlePlanSubmit = (planId: number | null) => {
    setSelectedPlanId(planId)
    setSurveyMode('complete')
  }
  const handleFinishSurvey = () => {
    submitSurveyIfAnswered()
    navigation.push('/app')
  }
  const closeAndSubmitSurveyIfAnswered = () => {
    if (userSwitched === null) {
      props.onDismiss()
    } else {
      submitSurveyIfAnswered()
    }
    props.onClose()
  }

  return {
    surveyOpen: props.surveyOpen,
    surveyMode,
    userSwitched,
    utilityId: props.utilityId,
    plans,
    handleUserAnsweredSwitched,
    handleUserAnsweredSwitchedNo,
    handlePlanSubmit,
    handleFinishSurvey,
    closeAndSubmitSurveyIfAnswered,
  }
}

function DidYouSwitchBody(props: { onYes: () => void; onNo: () => void }) {
  return (
    <div className="flex flex-col justify-between h-full">
      <div>
        <Text variant="body2">
          Let us know if you've successfully switched to the GEXA EV Plan and
          update your settings in Optiwatt.
        </Text>
      </div>
      <div>
        <Button
          id="gexa-complete-switch-yes-button"
          variant="primary-subtle"
          className="w-full mb-3 mt-6"
          onClick={props.onYes}
        >
          Yes, I switched
        </Button>
        <Button
          id="gexa-complete-switch-no-button"
          variant="primary-subtle"
          className="w-full"
          onClick={props.onNo}
        >
          No, I didn't
        </Button>
      </div>
    </div>
  )
}

function CompleteBody(props: { onGotItClicked: () => void }) {
  useEffect(() => {
    logEvent('switchConfirmationViewed')
  }, [])

  return (
    <div className="flex flex-col justify-between h-full">
      <div className="w-full flex flex-col items-center">
        <img src={GreenCheck} alt="Green checkmark" className="w-[50px]" />
        <Text variant="h3" className="text-center mt-6">
          Thanks! We’ve updated your plan in Optiwatt
        </Text>
      </div>
      <div>
        <Button
          id="gexa-complete-switch-done-button"
          variant="secondary"
          className="w-full mt-12"
          onClick={props.onGotItClicked}
        >
          Got it
        </Button>
      </div>
    </div>
  )
}
