import { RSAA } from 'redux-api-middleware'
import { withAuth } from '../reducers'
import config from '../config'

export const LIST_REQUEST = '@@homes/LIST_REQUEST'
export const LIST_SUCCESS = '@@homes/LIST_SUCCESS'
export const LIST_FAILURE = '@@homes/LIST_FAILURE'

export const CREATE_REQUEST = '@@homes/CREATE_REQUEST'
export const CREATE_SUCCESS = '@@homes/CREATE_SUCCESS'
export const CREATE_FAILURE = '@@homes/CREATE_FAILURE'

export const CLEAR_HOME_ERRORS = '@@homes/CLEAR_HOME_ERRORS'

export const getHomes = () => ({
  [RSAA]: {
    endpoint: `${config.apiGateway.URL}/api/homes/`,
    method: 'GET',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [LIST_REQUEST, LIST_SUCCESS, LIST_FAILURE],
  },
})

// TODO: This should eventually be the way to ADD homes, not update
// @todo multi-location support fix
export const addHome = (homeData) => ({
  [RSAA]: {
    endpoint: `${config.apiGateway.URL}/api/homes/`,
    method: 'POST',
    body: JSON.stringify(homeData),
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
  },
})

export const clearHomeErrors = () => ({
  type: CLEAR_HOME_ERRORS,
})
