import DateFnsUtils from '@date-io/date-fns'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { StylesProvider } from '@material-ui/styles'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import maTheme from '../../../../v2/theme'
import NewCharger from './NewCharger'

export default function BlogTools() {
  return (
    <StylesProvider injectFirst>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <MuiThemeProvider theme={maTheme}>
          <ThemeProvider theme={maTheme}>
            <Router>
              <Switch>
                <Route
                  path={['/blog-tools/new-charger']}
                  component={NewCharger}
                />
              </Switch>
            </Router>
          </ThemeProvider>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    </StylesProvider>
  )
}
