import Faq from '../Faq'

export default function ODECFaq() {
  const questions = [
    {
      question: 'What is Beat the Peak?',
      answer:
        'Beat the Peak is a voluntary program developed by Delaware Electric Cooperative to empower members to help keep energy rates as affordable as possible. Members can Beat the Peak by reducing their energy consumption during times when the cost to produce or purchase energy is the highest. Members can now Beat the Peak with their EV through the use of Optiwatt!',
    },
    {
      question: 'How will this affect my charging schedule?',
      answer:
        'After you sign up for the program, charging at your home will be interrupted each weekday between 4:00 p.m. and 7:00 p.m. from June through September. These are the times when energy is most expensive. At any time, you can override the program settings to start charging, or you can simply opt-out of the program entirely with one click. Charging at any other time will not be limited and charging at a location other than your home will not be impacted either.',
    },
    {
      question: 'What happens if I opt-out of a managed charging event?',
      answer:
        'You may elect to charge your vehicle at any time if you need to do so. Charging during a managed charging period may disqualify you from the monthly billing credit that you receive from participating in the program. Opting out of 3 managed charging periods may disqualify you from all billing credits associated with the program.',
    },
    {
      question: 'How do I participate in the program?',
      answer:
        'To participate, simply connect your EV to the Optiwatt mobile or desktop app and enable the program enrollment toggle in the “Charging” section of the app. No additional steps are required – your membership with DEC will be verified.',
    },
    {
      question: 'How do I stop participating in the program?',
      answer:
        'You can unenroll from the program easily with one-click in the “Charging” section of the Optiwatt mobile or desktop app. Alternatively, you may email <a href="mailto:support@getoptiwatt.com" target="_blank" rel="noreferrer">support@optiwatt.com</a> to unenroll at any time.',
      html: true,
    },
    {
      question:
        'If I agree to participate in the Optiwatt pilot program with Delaware Electric Cooperative, what incentive will I receive?',
      answer:
        'Participants in the Optiwatt program will receive a $25 billing credit at sign-up and a $5 monthly billing credit from June through September. This is a total incentive of up to $45 for each participant in the first year.',
    },
    {
      question: 'When will I receive my incentives?',
      answer:
        'The sign-up incentive will be issued as a billing credit and will appear on your next monthly utility bill statement following enrollment. Monthly incentives will be issued for each billing period covering June through September.',
    },
    {
      question:
        'If I have more than one Electric Vehicle, am I eligible for multiple incentives?',
      answer:
        'Incentives are limited to one per member, regardless of the number of EVs or chargers in the home.',
    },
    {
      question: 'If I have further questions, who should I contact?',
      answer:
        'Contact Delaware Electric Cooperative at 855-332-9090 or Optiwatt at <a href="mailto:support@getoptiwatt.com" target="_blank" rel="noreferrer">support@optiwatt.com</a>',
      html: true,
    },
  ]
  return <Faq name="DEC" questions={questions} />
}
