import React from 'react'
import { ViewModelComponentProps } from '../../../utils/typeUtilities'
import ThermostatRedirect, {
  Props as ComponentProps,
} from './ThermostatRedirect'
import useViewModel, { ThermostatRedirectBroadcasMessage } from './useViewModel'
import { FullPageCard, ScreenFillingPage } from '@/app/components'

type Props = ViewModelComponentProps<ComponentProps, typeof useViewModel>

export default ({ ...props }: Props) => {
  const viewModel = useViewModel()

  return (
    <ScreenFillingPage>
      <FullPageCard>
        <ThermostatRedirect {...viewModel} {...props} />
      </FullPageCard>
    </ScreenFillingPage>
  )
}

export type { ThermostatRedirectBroadcasMessage }
