import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { HelpCircle } from 'react-feather'
import { changeVehicleChargingDelayEvent } from '../../../../actions/vehicleChargingDelayEvents'
import { Grid, IconButton, Link } from '@material-ui/core'
import dayjs from 'dayjs'
import { AlertMessage } from '@/app/components'

import DemandResponseHelpDialog from './DemandResponseHelpDialog'
import DemandResponseActionDialog from './DemandResponseActionDialog'
import { chargeNowEventCollection } from '@/reducers/chargeNowEvents'
import { getChargeForecast } from '@/actions/metric'
import {
  selectNextVehicleDREvent,
  selectVehicleIsParticipatingInActiveEvent,
} from '@/selectors/vehicleDemandResponseEvent'

const SmallHelp = styled(HelpCircle)`
    width: 14px;
    height: 14px;
    color: ${(props) => props.theme.palette.primary.main}
    margin-bottom: 2px;
`

export default (props) => {
  const nextDelayEvent = useSelector((state) =>
    selectNextVehicleDREvent(state, props.vehicleId),
  )
  const participatingInActiveEvent = useSelector((state) =>
    selectVehicleIsParticipatingInActiveEvent(state, props.vehicleId),
  )
  const [demandResponseHelpOpen, setDemandResponseHelpOpen] = useState(false)
  const [demandResponseActionOpen, setDemandResponseActionOpen] =
    useState(false)
  const [optIn, setOptIn] = useState(false)
  const dispatch = useDispatch()

  const refreshChargeNowRelatedData = () => {
    dispatch(chargeNowEventCollection.actions.invalidate())
    dispatch(getChargeForecast(props.vehicleId))
  }

  const onClickOptIn = () => {
    dispatch(
      changeVehicleChargingDelayEvent(nextDelayEvent.id, {
        enrolled: true,
        reason: 'opt-in',
      }),
    ).then(refreshChargeNowRelatedData)
  }

  const onClickOptOut = () => {
    dispatch(
      changeVehicleChargingDelayEvent(nextDelayEvent.id, {
        enrolled: false,
        reason: 'opt-out',
      }),
    ).then(refreshChargeNowRelatedData)
  }

  const startTime = dayjs(nextDelayEvent.start_datetime)
    .calendar()
    .toLowerCase()

  const endTime = dayjs(nextDelayEvent.end_datetime).calendar().toLowerCase()

  return (
    <Grid container justifyContent="flex-start">
      <Grid item xs={12} direction="row" justifyContent="flex-start">
        <AlertMessage variant="info">
          {nextDelayEvent.enrolled
            ? `You are relieving stress from your local grid from ${startTime} until ${endTime}.`
            : 'There is an ongoing demand response event for your local grid.'}
          <IconButton
            style={{ padding: '6px' }}
            onClick={() => setDemandResponseHelpOpen(true)}
          >
            <SmallHelp />
          </IconButton>
          {nextDelayEvent.enrolled ? (
            <Link
              component="button"
              onClick={() => {
                setOptIn(false)
                setDemandResponseActionOpen(true)
              }}
            >
              {participatingInActiveEvent
                ? 'Opt out to charge'
                : 'Click here to opt out'}
            </Link>
          ) : (
            <Link
              component="button"
              onClick={() => {
                setOptIn(true)
                setDemandResponseActionOpen(true)
              }}
            >
              Click here to opt in
            </Link>
          )}
        </AlertMessage>
      </Grid>
      <DemandResponseHelpDialog
        open={demandResponseHelpOpen}
        handleClose={() => setDemandResponseHelpOpen(false)}
      />
      <DemandResponseActionDialog
        optIn={optIn}
        startTime={startTime}
        endTime={endTime}
        open={demandResponseActionOpen}
        handleClose={() => setDemandResponseActionOpen(false)}
        handleAction={(optIn) => {
          setDemandResponseActionOpen(false)
          optIn ? onClickOptIn() : onClickOptOut()
          props.onLoad()
        }}
      />
    </Grid>
  )
}
