import React from 'react'
import { Button, Card, LoadingAnimation, Text } from '@/components'
import useProgramFromParams from '@/app/hooks/useProgramFromParams'
import { NavigationPage } from '@/app/components'
import BrandedPage from '@/app/pages/ava/BrandedPage'
import { useNavigation } from '@/app/hooks'

export default function UtilityProgramPage(props: {
  children: React.ReactNode
}) {
  const { utility, isEligible, utilityProgram, isLoading } =
    useProgramFromParams()
  const navigate = useNavigation()

  if (isLoading) {
    return <LoadingAnimation type="falling" centered />
  }

  if (!utility) {
    return (
      <NavigationPage.SingleCardContent>
        <Text variant="body4">
          You must add your utility to view utility programs. Please{' '}
          <a href={'/connect-utility'}>connect your utility.</a>
        </Text>
        <BrandedPage.ButtonContainer>
          <Button id={'next-button'} onClick={() => navigate.push('/app')}>
            Next
          </Button>
        </BrandedPage.ButtonContainer>
      </NavigationPage.SingleCardContent>
    )
  }

  if (isEligible === false || utilityProgram === null) {
    return (
      <NavigationPage.SingleCardContent>
        <Text variant="body4">
          You are not eligible to view this utility program, or this plan does
          not exist.
        </Text>
        <BrandedPage.ButtonContainer>
          <Button id={'next-button'} onClick={() => navigate.push('/app')}>
            Next
          </Button>
        </BrandedPage.ButtonContainer>
      </NavigationPage.SingleCardContent>
    )
  }

  return props.children
}
