import * as actions from '../actions/vehicleAuth'
import { getApiErrorMessage } from '../utils/errorUtilities'
import { teslaFleetAuthenticationCollection } from './teslaFleetAuthentication'

const initialState = {
  isLoading: false,
  errors: {},
  shouldShowMultiFactorAuthentication: false,
  mfaData: {},
  hasTriedFetchingCaptcha: false,
  captchaData: {},
  vehicleConnectionConfirmationPending: { pending: false, make: '' },
}

export default (state = initialState, action) => {
  let showMfa

  switch (action.type) {
    case actions.CONNECT_VEHICLE_SUCCESS:
      showMfa =
        action.payload.hasOwnProperty('should_show_mfa') &&
        action.payload.should_show_mfa === true
      return {
        ...state,
        isLoading: false,
        shouldShowMultiFactorAuthentication: showMfa,
        mfaData: action.payload,
        errors: {},
      }
    case teslaFleetAuthenticationCollection.actionTypes.create
      .SuccessResolved: {
      // payload type should be TeslaFleetAuthentication
      const newlyConnectedVehicles = action.payload.vehicles ?? []
      return {
        ...state,
        mfaData: newlyConnectedVehicles,
      }
    }
    case actions.MULTIFACTOR_VEHICLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errors: {},
      }
    case actions.DELETE_VEHICLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errors: {},
      }
    case actions.CONNECT_VEHICLE_REQUEST:
    case actions.MULTIFACTOR_VEHICLE_REQUEST:
    case actions.DELETE_VEHICLE_REQUEST:
      return {
        ...state,
        shouldShowMultiFactorAuthentication: false,
        isLoading: true,
      }
    case actions.CONNECT_VEHICLE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: getApiErrorMessage(action.payload),
        hasTriedFetchingCaptcha: false,
      }
    case actions.MULTIFACTOR_VEHICLE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: getApiErrorMessage(action.payload),
        hasTriedFetchingCaptcha: false,
      }
    case actions.DELETE_VEHICLE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: getApiErrorMessage(action.payload),
        hasTriedFetchingCaptcha: false,
      }
    case actions.DELETE_VEHICLE_CLEAR_ERRORS:
      return {
        ...state,
        errors: {},
      }
    case actions.CAPTCHA_DATA_SUCCESS:
      return {
        ...state,
        captchaData: action.payload,
        hasTriedFetchingCaptcha: true,
        isLoading: false,
      }
    case actions.CAPTCHA_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasTriedFetchingCaptcha: true,
      }
    case actions.SET_CONNECT_CONFIRMATION_PENDING:
      return {
        ...state,
        vehicleConnectionConfirmationPending: action.payload,
      }
    case actions.RESET_VEHICLE_AUTH_ERRORS:
      return {
        ...state,
        errors: {},
      }
    default:
      return state
  }
}
