import { store } from '../../store'
import config from '../../config'
import { refreshAccessToken } from '@/actions/auth'
import { isAccessTokenExpired } from '@/reducers'

type Options = Parameters<typeof fetch>[1] & {
  attemptRefreshWhenExpired?: boolean
}

const defaultOptions = {
  method: 'GET',
  mode: 'cors' as RequestMode,
  attemptRefreshWhenExpired: true,
}

/**
 *
 * @param apiEndpoint - endpoint prepended to the api gateway url, with optional leading and trailing slashes
 * @param options - options identical to the `fetch` API options
 * @returns - `fetch` API response Promise
 */
export function fetchAuthenticated(
  apiEndpoint: string,
  options?: Options,
  searchParams?: URLSearchParams,
): Promise<Response> {
  const fetchOptions = { ...defaultOptions, ...options }
  const authToken = store.getState()?.auth?.access?.token
  const refreshToken = store.getState()?.auth?.refresh?.token

  const tokenIsExpired = isAccessTokenExpired(store.getState())
  if (tokenIsExpired && fetchOptions.attemptRefreshWhenExpired) {
    return store.dispatch(refreshAccessToken(refreshToken)).then(() => {
      // set attemptRefreshWhenExpired to false to prevent infinite recursion
      return fetchAuthenticated(apiEndpoint, {
        ...options,
        attemptRefreshWhenExpired: false,
      })
    })
  }

  const endpoint = `${apiEndpoint.startsWith('/') ? '' : '/'}${apiEndpoint}${
    apiEndpoint.endsWith('/') ? '' : '/'
  }`

  return fetch(
    `${config.apiGateway.URL}/api${endpoint}${
      searchParams ? `?${searchParams.toString()}` : ''
    }`,
    {
      ...fetchOptions,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
        ...fetchOptions.headers,
      },
    },
  )
}
