import { DeviceAuthAlertMessage } from '@/app/components'

export default function TeslaCorePermissionsBanner(props: {
  vehicleId: number
}) {
  return (
    <DeviceAuthAlertMessage
      mode="warning"
      deviceName="vehicle"
      linkText="Update your connection"
      reconnectUrl={`/devices/vehicle/${props.vehicleId}/capability`}
      show
    >
      Connection issue detected. Grant access to Vehicle Info, Location, and
      Charging Management to keep using Optiwatt.
    </DeviceAuthAlertMessage>
  )
}
