import { GridItem } from '../../../../../../components'
import { ID } from '../../../../../../types/model'
import {
  HomeScheduleCard,
  Thermostat,
  TotalSpentCard,
  YourLastAdjustmentCard,
} from '../../../../../features/smart-thermostats'
import { ThermostatProvider } from '../../../../../../types/thermostat'
import ErrorBoundary, {
  LocationTag,
} from '../../../../../../authenticated/components/ErrorBoundary'
import { LockedFeature } from '@/app/components'
import { remoteConfig } from '@/firebase'
import HomeEstimationUpdateNotification from '@/app/components/HomeEstimations/Notification/HomeEstimationUpdateNotificiation'

export interface Props {
  thermostatId: ID
  thermostatName: ThermostatProvider
}

export default function ThermostatSection(props: Props) {
  const hide_nest_schedule =
    (remoteConfig?.getBoolean('hide_nest_schedule_components') ?? true) &&
    props.thermostatName === 'Nest'

  return (
    <>
      <HomeEstimationUpdateNotification
        from={'app/thermostat/' + props.thermostatId}
      />
      <GridItem placeSelf="center" style={{ width: '100%' }}>
        <ErrorBoundary
          location={LocationTag.Thermostat}
          functionalityDescription="Thermostat"
        >
          <Thermostat id={props.thermostatId} />
        </ErrorBoundary>
      </GridItem>
      <ErrorBoundary
        location={LocationTag.YourLastAdjustmentCard}
        functionalityDescription="Last Adjustment Summary"
        fallbackOnCard
      >
        <YourLastAdjustmentCard thermostatId={props.thermostatId} />
      </ErrorBoundary>
      <ErrorBoundary
        location={LocationTag.TotalSpentCard}
        functionalityDescription="Total Spent Summary"
        fallbackOnCard
      >
        <LockedFeature
          title="Total Spent"
          connectionRequirements={['utility', 'unpendingPlan']}
        >
          <TotalSpentCard
            thermostatId={props.thermostatId}
            thermostatName={props.thermostatName}
          />
        </LockedFeature>
      </ErrorBoundary>
      <ErrorBoundary
        location={LocationTag.HomeSchedulCard}
        functionalityDescription="Home Schedule Summary"
        fallbackOnCard
      >
        {!hide_nest_schedule && (
          <LockedFeature
            title="Home Schedule"
            connectionRequirements={['utility', 'unpendingPlan']}
          >
            <HomeScheduleCard thermostatId={props.thermostatId} />
          </LockedFeature>
        )}
      </ErrorBoundary>
    </>
  )
}
