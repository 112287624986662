import { User } from '@/types'
import { QuestionnairePage, QuestionnairePageValue } from './Questionnaire'
import { remoteConfig } from '@/firebase'
import { logMessage } from '@/logging/sentry'

export function pushToQuestionnaireResponse(
  value: QuestionnairePageValue['value'],
  values: QuestionnairePageValue[],
  pages: QuestionnairePage[],
  pageNumber: number,
) {
  const valueForCurrentPage = values[pageNumber]
  let newValues = [
    {
      order: pages[pageNumber].order,
      key: pages[pageNumber].key,
      value: Array.isArray(value) ? value : [value],
    },
  ]

  if (!valueForCurrentPage) newValues = [...values, ...newValues]
  else
    newValues = [
      // Filter out the old version of the current page
      ...values.filter((page) => page !== valueForCurrentPage),
      ...newValues,
    ]

  // Sort the pages so that the order does not change (maintains the order in which pages are displayed)
  return newValues.sort((a, b) => a.order - b.order)
}

export function shouldNavigateUserToProgramOnboarding(user: {
  profile: { utility_white_label: User['profile']['utility_white_label'] }
}):
  | { shouldNavigate: false; destination: null }
  | { shouldNavigate: true; destination: string } {
  const whiteLabelId = user.profile.utility_white_label
  if (!whiteLabelId) {
    return { shouldNavigate: false, destination: null }
  }

  const destinationSlug =
    remoteConfig?.getProgramSlugFromWhiteLabelId(whiteLabelId)

  if (!destinationSlug) {
    logMessage(
      `Questionnaire: No destination found for white label id ${whiteLabelId}`,
    )
    return { shouldNavigate: false, destination: null }
  }

  const destination = `/${destinationSlug}/start`

  return { shouldNavigate: true, destination }
}
