import { ContainerAnimation, Icon, TextField } from '@/components'
import Button from '@/components/Button/Button'
import { ChangeEvent, useState } from 'react'
import { useSurveyResponder } from '../reducer'
import Lottie from 'lottie-react'
import connectUtilityAnimation from '@/app/img/connect-utility-lottie.json'
import { logEvent } from '@/logging'
import { isNativeMobile } from '@/authenticated/hooks/useIsMobile'
import {
  postMessagetoMobileIfPresent,
  RATE_APP_TYPE,
} from '@/utils/messageUtils'
import Modal from '@/components/Modal'
import { isIOS } from '@/utils/dom/reactNativeDevice'
import useExternalNavigation from '@/app/hooks/useExternalNavigation'
import { remoteConfig } from '@/firebase'

enum FeedbackSurveyState {
  INITIAL = 'INITIAL',
  NEGATIVE_RESPONSE = 'NEGATIVE_RESPONSE',
  POSITIVE_RESPONSE_SUBMITTED = 'POSITIVE_RESPONSE_SUBMITTED',
  NEGATIVE_RESPONSE_SUBMITTED = 'NEGATIVE_RESPONSE_SUBMITTED',
}
type FeedbackSurveyResponse = {
  is_positive: boolean
  feedback: string | null
}
export default function FeedbackSurvey() {
  const [state, setState] = useState(FeedbackSurveyState.INITIAL)
  const [open, setOpen] = useState(true)
  const [response, setResponse] = useState<FeedbackSurveyResponse | null>(null)
  const { dismissSurvey, submitResponse } = useSurveyResponder('Feedback')
  function submitSurveyResponse(response: FeedbackSurveyResponse | null) {
    // track the response and submit it once the dialog actually closes
    // this is to avoid a bug where submitting a survey response when the dialog is open
    // causes the dialog to close prematurely (presumed a redux side effect)
    if (!response) {
      return
    }
    if (!response.is_positive && !response.feedback) {
      // do not submit negative survey with no feedback
      return
    }
    submitResponse(response)
  }
  const renderSurveyState = (state: FeedbackSurveyState) => {
    switch (state) {
      case FeedbackSurveyState.INITIAL:
        return <Initial setState={setState} setResponse={setResponse} />
      case FeedbackSurveyState.POSITIVE_RESPONSE_SUBMITTED:
        return (
          <PositiveResponseSubmitted
            setOpen={setOpen}
            submitSurveyResponse={submitSurveyResponse}
          />
        )
      case FeedbackSurveyState.NEGATIVE_RESPONSE:
        return (
          <NegativeResponse setState={setState} setResponse={setResponse} />
        )
      case FeedbackSurveyState.NEGATIVE_RESPONSE_SUBMITTED:
        return (
          <NegativeResponseSubmitted
            setOpen={setOpen}
            submitSurveyResponse={submitSurveyResponse}
            response={response}
          />
        )
    }
  }
  return (
    <Modal
      id={'feedback-survey'}
      maxWidth={'16rem'}
      open={open}
      onClose={() => {
        setOpen(false)
        dismissSurvey()
        submitSurveyResponse(response)
      }}
    >
      {renderSurveyState(state)}
    </Modal>
  )
}

export function Initial({
  setState,
  setResponse,
}: {
  setState: React.Dispatch<React.SetStateAction<FeedbackSurveyState>>
  setResponse: React.Dispatch<
    React.SetStateAction<FeedbackSurveyResponse | null>
  >
}) {
  return (
    <div className="flex flex-col gap-8">
      <section className="flex flex-col items-start gap-1">
        <div className="flex items-center justify-center w-full">
          <Lottie
            style={{
              height: '10rem',
              width: 'full',
            }}
            animationData={connectUtilityAnimation}
            loop
          />
        </div>
        <div className="font-bold text-base text-themed-base-900">
          Are you enjoying Optiwatt?
        </div>
        <div className="text-sm text-themed-base-500">
          Your feedback helps us improve the app
        </div>
      </section>
      <section className="flex flex-col gap-4">
        <Button
          id="feedback-survey-positive-response-button"
          variant="accent-subtle"
          onClick={() => {
            setState(FeedbackSurveyState.POSITIVE_RESPONSE_SUBMITTED)
            setResponse({ is_positive: true, feedback: null })
            logEvent('rate_thumbs_up')
          }}
        >
          <Icon name="ThumbsUp" />
        </Button>
        <Button
          id="feedback-survey-negative-response-button"
          variant="accent-subtle"
          onClick={() => {
            setState(FeedbackSurveyState.NEGATIVE_RESPONSE)
            logEvent('rate_thumbs_down')
          }}
        >
          <Icon name="ThumbsDown" />
        </Button>
      </section>
    </div>
  )
}

export function PositiveResponseSubmitted({
  setOpen,
  submitSurveyResponse,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  submitSurveyResponse: (response: FeedbackSurveyResponse) => void
}) {
  const { externallyNavigate } = useExternalNavigation()
  const appStoreLink = remoteConfig?.getString('app_store_link') ?? ''
  const playStoreLink = remoteConfig?.getString('play_store_link') ?? ''
  return (
    <div className="flex flex-col gap-8">
      <ContainerAnimation
        className="absolute top-0 left-0 z-100 text-themed-primary"
        variant="snow"
        width="100%"
        height="180px"
      />
      <section className="flex flex-col gap-4 items-center">
        <div className="text-4xl text-themed-primary font-bold">Awesome!</div>
        <Icon name="Heart" className="text-themed-primary" size={64} />
      </section>
      {isNativeMobile() ? (
        <>
          <section>
            <div className="text-sm text-themed-base-500">
              We're glad to hear you're enjoying Optiwatt. Would you like to
              share your experience by leaving us a review on the{' '}
              {isIOS() ? 'App Store' : 'Play Store'}?
            </div>
          </section>
          <section className="flex flex-col gap-4">
            <Button
              id="feedback-survey-optiwatt-themed-button"
              variant="primary"
              onClick={() => {
                externallyNavigate(isIOS() ? appStoreLink : playStoreLink)
                postMessagetoMobileIfPresent(RATE_APP_TYPE)
                submitSurveyResponse({ is_positive: true, feedback: null })
                setOpen(false)
              }}
            >
              {isIOS() ? 'Give Optiwatt 5 stars' : 'Rate Optiwatt'}
            </Button>
            <Button
              id="feedback-survey-no-thanks-button"
              variant="primary-subtle"
              onClick={() => {
                submitSurveyResponse({ is_positive: true, feedback: null })
                setOpen(false)
              }}
            >
              No thanks
            </Button>
          </section>
        </>
      ) : (
        <>
          <Button
            id="feedback-survey-done-button"
            variant="primary"
            onClick={() => {
              submitSurveyResponse({ is_positive: true, feedback: null })
              setOpen(false)
            }}
          >
            Done
          </Button>
        </>
      )}
    </div>
  )
}

export function NegativeResponse({
  setState,
  setResponse,
}: {
  setState: React.Dispatch<React.SetStateAction<FeedbackSurveyState>>
  setResponse: React.Dispatch<
    React.SetStateAction<FeedbackSurveyResponse | null>
  >
}) {
  const [feedback, setFeedback] = useState('')

  function updateFeedback(
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void {
    setFeedback(event.currentTarget.value)
  }

  return (
    <div className="flex flex-col gap-8">
      <section className="flex flex-col items-start gap-1">
        <div className="font-bold text-base text-themed-base-900">
          How can we improve?
        </div>
        <div className="text-sm text-themed-base-500">
          Your responses will be directly shared with our product development
          team
        </div>
      </section>
      <section className="flex flex-col gap-4">
        <TextField
          id="feedback"
          label="Your response"
          onChange={updateFeedback}
        />
        <Button
          id="feedback-survey-submit-feedback-button"
          data-testid="submit-button"
          variant="primary"
          disabled={feedback.length === 0}
          onClick={() => {
            setResponse({ is_positive: false, feedback })
            setState(FeedbackSurveyState.NEGATIVE_RESPONSE_SUBMITTED)
          }}
        >
          Submit
        </Button>
      </section>
    </div>
  )
}

export function NegativeResponseSubmitted({
  setOpen,
  submitSurveyResponse,
  response,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  submitSurveyResponse: (response: FeedbackSurveyResponse | null) => void
  response: FeedbackSurveyResponse | null
}) {
  return (
    <div className="flex flex-col gap-8">
      <section className="flex flex-col items-center justify-center gap-2">
        <Icon name="Heart" className="text-themed-secondary" size={40} />
        <div className="font-bold text-themed-base-900 w-52 text-center">
          You rock! Thanks for sharing your feedback.
        </div>
      </section>
      <Button
        id="feedback-survey-done-negative-button"
        onClick={() => {
          submitSurveyResponse(response)
          setOpen(false)
        }}
      >
        Done
      </Button>
    </div>
  )
}
