import { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  Box,
  Button as MuiButton,
  Typography as MuiTypography,
} from '@material-ui/core'
import { spacing } from '@material-ui/system'
import { useDispatch, useSelector } from 'react-redux'
import { BroadcastChannel } from 'broadcast-channel'
import Grid from '@material-ui/core/Grid'
import { markStartedGridSupportAuthorize } from '../../../actions/user'
import { setGridSupportConfirmationPending } from '../../../actions/utilities'
import { makeStyles } from '@material-ui/styles'
import useCanConnectUtility from '../../hooks/useCanConnectUtility'
import {
  LEAP_AUTHORIZE_TYPE,
  postMessagetoMobileIfPresent,
} from '../../../utils/messageUtils'
import ConnectUtility from '../ConnectUtility'
import BroadcastChannelName from '../../../types/broadcastChannelName'
import { isNativeMobile } from '@/authenticated/hooks/useIsMobile'
import { logMessage } from '@/logging/sentry'

const Button = styled(MuiButton)(spacing)

const Typography = styled(MuiTypography)(spacing)

// For completion page
const StyledAuthorized = styled.div`
  text-align: center;
  margin-top: 32px;
`

const StyledTitleTypography = styled(Typography)`
  font-size: 14px;
  line-height: 19px;
  color: #343434;
  font-weight: bold;
  margin-bottom: 8px;
  text-align: center;
`

const StyledSummaryTypography = styled(Typography)`
  font-size: 12px;
  line-height: 16px;
  color: #343434;
  margin-bottom: 10px;
`

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    position: 'relative',
  },
  mainContainer: {
    padding: theme.spacing(6),
  },
}))

function SelectButtons(props) {
  return (
    <Box p={2} display="flex" justifyContent={props.justify || 'left'}>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className="brand-submit-button"
        onClick={props.handleSubmit}
      >
        {props.buttonText}
      </Button>
    </Box>
  )
}

const APIProviderIntegration = (props) => {
  const classes = useStyles()

  return !props.isAuthorized ? (
    <Grid container justifyContent="flex-end">
      <ConnectUtility
        utilityName={props.utilityName}
        utilityLogoUrl={props.utilityLogoUrl}
        apiProvider={props.apiProvider}
        onConnectClick={props.authorize}
        onSkipClick={props.allowSkip ? props.handleSkip : undefined}
        onBackClick={props.handleBack}
        classes={props.connectUtilityClasses}
      />
    </Grid>
  ) : (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      mb={10}
      className={classes.root}
    >
      <Grid
        item
        xs={12}
        className={props.contentStyle ?? classes.mainContainer}
      >
        <StyledAuthorized>
          <StyledTitleTypography>
            Thanks for Supporting the Local Grid!
          </StyledTitleTypography>
          <StyledSummaryTypography>
            Successfully linked Optiwatt with {props.utilityName}.
          </StyledSummaryTypography>
        </StyledAuthorized>
        <SelectButtons
          handleSubmit={props.handleSubmit}
          buttonText="Continue"
          justifyContent="center"
        />
      </Grid>
    </Grid>
  )
}

const GridSupport = (props) => {
  const { utilitySelection, handleSubmit, confirmationPending } = props
  const setConfirmationPending =
    props.setConfirmationPending ??
    ((pending) => dispatch(setGridSupportConfirmationPending(pending)))
  const dispatch = useDispatch()
  const utilityRefId = useSelector(
    (state) => state.user.user?.profile.utility_ref_id,
  )
  const userName = useSelector((state) => state.user.user?.username)

  // Grid Support
  const isCalifornia =
    useSelector((state) => state.homes.selectedHome?.state?.state_code) === 'CA'
  const canConnectUtility = useCanConnectUtility(utilitySelection, true)

  const shouldShowAPIProviderAuthorization =
    canConnectUtility || confirmationPending

  const [apiProviderSuccess, setApiProviderSuccess] = useState(false)

  const bc = new BroadcastChannel(BroadcastChannelName.GridSupport)

  useEffect(() => {
    bc.onmessage = (eventMessage) => {
      if (eventMessage?.message && utilitySelection !== null) {
        console.log('GridSupport: Received message: ', eventMessage.message)
        setConfirmationPending(true)
        handleSubmit(true)
        setApiProviderSuccess(true)
      }
    }
  }, [utilitySelection, handleSubmit, dispatch])

  const authorizeAPIProvider = () => {
    const providerUrlWithParams = () => {
      if (utilitySelection.api_provider === 'LEAP') {
        return `${utilitySelection.api_provider_url}?refId=${utilityRefId}`
      } else if (utilitySelection.api_provider === 'UTIL') {
        return `${utilitySelection.api_provider_url}&email=${userName}&redirect_uri=${process.env.REACT_APP_UTILITY_API_REDIRECT}`
      }
    }
    const providerUrl = providerUrlWithParams()
    // const providerUrl = `http://localhost:3000/grid-support-complete?authorizationId=bc3fc5ad-627e-4fe7-8b28-c58a2192757c&refId=12`;
    if (isNativeMobile()) {
      postMessagetoMobileIfPresent(providerUrl, LEAP_AUTHORIZE_TYPE)
    } else {
      window.open(
        providerUrl,
        'Grid Support',
        'resizeable,scrollbar,status,width=600,height=700',
      )
    }
    dispatch(markStartedGridSupportAuthorize(utilitySelection.id))
  }

  const handleFinishGridSupport = () => {
    if (props.handleClose) {
      props.handleClose()
    }
  }

  const handleSkipOrFinish = () => {
    if (props.handleSkip) {
      props.handleSkip()
    } else {
      handleFinishGridSupport()
    }
  }

  const handleSkip = () => {
    if (!isCalifornia) {
      handleSubmit(false)
    } else {
      handleSubmit(null)
    }
    handleSkipOrFinish()
  }

  useEffect(() => {
    // call skip or finish to close if this issue arises
    if (!shouldShowAPIProviderAuthorization) {
      logMessage('Grid Support - shouldShowAPIProviderAuthorization is false', {
        logToConsole: true,
      })
      handleSkipOrFinish()
    }
  }, [shouldShowAPIProviderAuthorization])

  if (!shouldShowAPIProviderAuthorization) {
    return null
  }

  return (
    <APIProviderIntegration
      data={props.data}
      isAuthorized={apiProviderSuccess || confirmationPending}
      handleSubmit={handleFinishGridSupport}
      handleSkip={props.handleSkip ? handleSkip : undefined}
      handleBack={props.handleBack}
      allowSkip={props.allowSkip}
      skipText={props.skipText}
      authorize={authorizeAPIProvider}
      isCalifornia={isCalifornia}
      contentStyle={props.contentStyle}
      utilityName={utilitySelection.name}
      utilityLogoUrl={utilitySelection.logo_url}
      apiProvider={utilitySelection.api_provider}
      connectUtilityClasses={props.connectUtilityClasses}
    />
  )
}

export default GridSupport
