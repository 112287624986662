import { optiwattConsts } from '@/optiwattConsts'
import Faq from '../Faq'

export default function FortisAlbertaFaq() {
  const questions = [
    {
      question: 'What is FortisAlberta’s EV Smart Charging Pilot?',
      answer:
        'FortisAlberta is looking to understand the impacts of residential EV charging on the electricity distribution grid through a new EV Smart Charging Pilot.',
    },
    {
      question: 'Why is FortisAlberta running the pilot?',
      answer:
        'FortisAlberta is interested in learning how and when EV owners charge their EVs and what possibilities exist to manage the increased demands on the electrical grid. Throughout the pilot, opportunities may arise for EV owners to shift their charging schedules and earn rewards. More information will be shared with participants upon enrolment into the pilot through the Optiwatt app.',
    },
    {
      question: 'How long is the pilot?',
      answer:
        'The pilot will launch in January 2023 and run for approximately 1 year, ending on December 31, 2023.',
    },
    {
      question: 'What are the criteria to enrol in the pilot?',
      answer: `To enrol in the pilot you must:
      - Drive a battery electric vehicle (BEV)
      - Live in the FortisAlberta service area and be a FortisAlberta customer*
      - Download the Optiwatt App on your smartphone or other device from the Apple App Store or Google Play Store
      - Have an indoor or outdoor charging station installed at your home, i.e. Level 1 (120V) or Level 2 (240V)
      *If you are unsure if you are a FortisAlberta customer, please reach out to your electricity retailer (the company that provides you your bill) to confirm your electricity distributor. 
      `,
    },
    {
      question: 'How many vehicles can participate in the pilot?',
      answer:
        'FortisAlberta is looking for up to 600 EVs across its service territory to participate in the pilot.',
    },
    {
      question: 'Which EV models are eligible to enrol in the pilot?',
      answer: `Audi,  e-tron and Q4 e-tron
      BMW,  i3
      Chevrolet, Bolt EUV, Bolt, and Spark
      Ford, F150 Lightning, Focus Electric, and Mach-e
      Hyundai, IONIQ 5, IONIQ Electric, Kona Electric
      Jaguar, I-Pace
      Kia, EV6, and Soul EV
      Mercedes-Benz, EQS
      Mini, SE Hardtop
      Nissan, Leaf
      Rivian, R1S, and R1T
      Tesla, 3, S, X, and Y
      Volkswagen, e-Golf, and ID.4 (2020 models and newer)
      `,
    },
    {
      question: 'How will managed charging affect my charging schedule?',
      answer:
        'The first phase of the pilot is simply to share how and when you charge your EV with FortisAlberta. FortisAlberta is interested in learning how managed charging can be used to reduce the demands on the electrical grid.  Optiwatt balances your desired EV charging target with high loading of the grid. As a result, Optiwatt may occasionally postpone your charging at times of high grid stress while still trying to meet your desired target limit. At any time, you can override postponed charging to boost charging. Optiwatt will never postpone your away from home charging (i.e., at superchargers, your place of work, etc.). ',
    },
    {
      question: 'Will managed charging prevent me from charging my EV?',
      answer:
        'Not at all! Optiwatt will prioritize your departure time and charge limit preference to ensure you always have the battery you need.',
    },
    {
      question: 'How do I participate in the pilot?',
      answer:
        'To participate, simply connect your EV to the Optiwatt mobile or desktop app and enable the FortisAlberta program enrolment toggle in the “Charging” section of the app. No additional steps are required. Optiwatt will automatically handle the rest.',
    },
    {
      question:
        'If I agree to participate in the FortisAlberta pilot with Optiwatt, what incentive will I receive?',
      answer: `Each participating vehicle will receive $150 in rewards for enroling and participating in the pilot ($50 at sign-up and $100 if you are enrolled through the duration of the pilot and complete the survey at the end). Additional incentives may be available throughout the course of the pilot through the Optiwatt app. 
      
      Rewards are provided through the Optiwatt app and can be redeemed through Paypal, Zelle, Stripe, and digital gift cards.
      `,
    },
    {
      question: 'How do I stop participating in the program?',
      answer: `You will be able to withdraw from the program easily with one click in the “Charging” section of the mobile or desktop app. Alternatively, you may email ${optiwattConsts.contact.email} to end your participation at any time. If you exit the program prior to the pilot end date, you will no longer be eligible to earn rewards tied to managed charging and completion of the pilot. Any rewards earned up to the time of exiting the program will be available for redemption.`,
    },
    {
      question: 'Can I register more than one EV vehicle?',
      answer:
        'Yes, you can register more than one EV per household. To register additional vehicles, simply connect the vehicle to Optiwatt in the “Profile” section of the mobile app or desktop.',
    },
    {
      question: 'Do I connect my EV charger to the Optiwatt App?',
      answer:
        'No. The Optiwatt app connects directly to your vehicle so there is no need to connect your charger.',
    },
    {
      question:
        'I am having issues downloading the Optiwatt App, who do I contact?',
      answer: `If you are having issues with the Optiwatt app, please contact Optiwatt at ${optiwattConsts.contact.email}, or toll-free at ${optiwattConsts.contact.phoneNumber}.`,
    },
    {
      question:
        'If I have further questions about the pilot, who do I contact?',
      answer:
        'Further questions about the EV Smart Charging Pilot can be sent to EVPilotProject@fortisalberta.com. A team member from FortisAlberta will respond to you within 2 business days, Monday to Friday.',
    },
  ]
  return <Faq name="FortisAlberta" questions={questions} />
}
