import * as metric from '../actions/metric'
import * as form from '../actions/form'
import * as planActions from '../actions/plans'
import * as scheduleActions from '../actions/schedule'
import * as vehicleActions from '../actions/vehicles'
import * as tripActions from '../actions/calendar'

const initialState = {
  charges: null,
  isFetchingCharges: false,
  rates: null,
  isFetchingRates: false,
  ratesFailed: false,
  chargeForecast: null,
  isFetchingChargeForecast: false,
  chargeForecastFailed: false,
  chargesFailed: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case metric.CHARGES_SUCCESS:
      return {
        ...state,
        charges: action.payload,
        isFetchingCharges: false,
      }
    case metric.CHARGES_REQUEST:
      return {
        ...state,
        isFetchingCharges: true,
      }
    case metric.CHARGES_FAILURE:
      return {
        ...state,
        isFetchingCharges: false,
        chargesFailed: true,
      }
    case metric.RATES_SUCCESS:
      return {
        ...state,
        rates: action.payload,
        isFetchingRates: false,
        ratesFailed: false,
      }
    case metric.RATES_REQUEST:
      return {
        ...state,
        isFetchingRates: true,
        ratesFailed: false,
      }
    case metric.RATES_FAILURE:
      return {
        ...state,
        isFetchingRates: false,
        ratesFailed: true,
      }
    case metric.CHARGE_FORECAST_SUCCESS:
      return {
        ...state,
        chargeForecast: action.payload,
        isFetchingChargeForecast: false,
        chargeForecastFailed: false,
      }
    case metric.CHARGE_FORECAST_REQUEST:
      return {
        ...state,
        isFetchingChargeForecast: true,
        chargeForecastFailed: false,
      }
    case metric.CHARGE_FORECAST_FAILURE:
      return {
        ...state,
        isFetchingChargeForecast: false,
        chargeForecastFailed: true,
      }
    case form.SELECT_PLAN_SUCCESS:
      return {
        ...state,
        rates: null,
      }
    case planActions.UPLOAD_PLAN_SUCCESS:
      return {
        ...state,
        rates: null,
      }
    case vehicleActions.CHANGE_VEHICLE_SUCCESS:
      return {
        ...state,
        charges: null,
        isFetchingCharges: false,
      }
    case scheduleActions.CHARGE_SCHEDULES_UPDATE_SUCCESS:
    case tripActions.CREATE_SUCCESS:
    case tripActions.UPDATE_SUCCESS:
    case tripActions.DELETE_SUCCESS:
    case tripActions.DELETE_ALL_SUCCESS:
      return {
        ...state,
        chargeForecast: null,
        isFetchingChargeForecast: false,
        chargeForecastFailed: false,
      }
    default:
      return state
  }
}

export const rates = (state) => state.rates
