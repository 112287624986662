import useVehicleChargeSchedulesFetcher from '@/authenticated/hooks/useVehicleChargeSchedulesFetcher'
import { Vehicle, VehicleChargeSchedule } from '@/types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { getChargeSchedules, updateChargeSchedule } from '@/actions/schedule'
import { debounce } from 'lodash'
import { chargeForecastCollection } from '@/reducers/chargeRelatedCollections'
import dayjs from 'dayjs'
import { ChargeScheduleDaysOfWeek } from '@/types/chargeSchedule'
import { canControlClimate as vehicleCanControlClimate } from '@/utils/vehicles/permissions'

export type ChargeScheduleMessage = {
  maxChargeTimeMinutes: number
  minutesDiff: number
  startChargeScheduleDay: ChargeScheduleDaysOfWeek
  endChargeScheduleDay: ChargeScheduleDaysOfWeek
  startTime: dayjs.Dayjs
  endTime: dayjs.Dayjs
}
export type ChargeScheduleMessaging = ChargeScheduleMessage[]

export function useViewModel() {
  const params = useParams<{ deviceId: string }>()
  const vehicles = useAppSelector((state) => state.vehicles.vehicles ?? []) as
    | Vehicle[]
    | undefined
  const vehicle = (vehicles ?? []).find((v) => v.id === +params.deviceId)
  const chargeSchedules = useVehicleChargeSchedulesFetcher()
  const chargeSchedule = (chargeSchedules ?? []).find(
    (schedule) => schedule.vehicle_id === +params.deviceId,
  ) as VehicleChargeSchedule | undefined
  const [localChargeSchedule, setLocalChargeSchedule] = useState<
    VehicleChargeSchedule | undefined
  >(chargeSchedule)
  const { data: chargeForecastResponse, refetch: refetchChargeForecast } =
    chargeForecastCollection.useFetch({
      params: {
        vehicle_id: vehicle?.id,
      },
    })
  const canControlClimate = vehicle ? vehicleCanControlClimate(vehicle) : false
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getChargeSchedules())
  }, [])

  // Ensure the local charge schedule is in sync with what we pull from the server.
  // This fixed a bug where local charge schedule was not being initialized properly
  // when the component was first mounted.
  useEffect(() => {
    if (localChargeSchedule?.id !== chargeSchedule?.id && chargeSchedule) {
      setLocalChargeSchedule(chargeSchedule)
    }
  }, [chargeSchedules, localChargeSchedule])

  useEffect(() => {
    refetchChargeForecast()
  }, [localChargeSchedule])
  const chargeScheduleMessaging = useMemo(() => {
    if (!chargeForecastResponse?.[0] || !localChargeSchedule) {
      return []
    }
    const chargeForecast = chargeForecastResponse[0]
    const maxChargeTimeMinutes = chargeForecast.max_charge_time_minutes
    const messages = localChargeSchedule.charge_schedule_days_of_week
      .map((day, _index, array) => {
        if (!day.charging_start_time || !day.day_of_week) return
        const dayStartTime = dayjs(`1970-01-01T${day.charging_start_time}`)
        const dayDepartureTime = dayjs(`1970-01-01T${day.departure_time}`)
        // if the start and departure time are on the same day
        if (day.departure_time && dayDepartureTime > dayStartTime) {
          const minutesDiff = Math.abs(
            dayDepartureTime.diff(dayStartTime, 'minutes'),
          )
          return {
            maxChargeTimeMinutes,
            minutesDiff,
            startChargeScheduleDay: day,
            endChargeScheduleDay: day,
            startTime: dayStartTime,
            endTime: dayDepartureTime,
          }
        }
        const daysOfWeek = [
          'monday',
          'tuesday',
          'wednesday',
          'thursday',
          'friday',
          'saturday',
          'sunday',
        ]
        const dayIndex = daysOfWeek.indexOf(day.day_of_week)
        const nextDay = array.find(
          (d) => d.day_of_week === daysOfWeek[dayIndex + 1],
        )
        if (!nextDay || !nextDay.departure_time) return
        const nextDayDepartureTime = dayjs(
          `1970-01-02T${nextDay.departure_time}`,
        )
        const minutesDiff = Math.abs(
          nextDayDepartureTime.diff(dayStartTime, 'minutes'),
        )
        return {
          maxChargeTimeMinutes,
          minutesDiff,
          startChargeScheduleDay: day,
          endChargeScheduleDay: nextDay,
          startTime: dayStartTime,
          endTime: nextDayDepartureTime,
        }
      })
      .filter((d) => !!d) as ChargeScheduleMessaging
    // keep messages that where the charge time is less than the estimated max charge time
    return messages.filter((d) => d.minutesDiff < d.maxChargeTimeMinutes)
  }, [chargeForecastResponse?.[0], localChargeSchedule])

  const debouncedSave = useCallback(
    debounce(async (model: VehicleChargeSchedule) => {
      await dispatch(updateChargeSchedule(model))
    }, 500),
    [],
  )

  const onChargeScheduleChange = (chargeSchedule: VehicleChargeSchedule) => {
    // reflect change locally
    setLocalChargeSchedule(chargeSchedule)
    // sync to server
    debouncedSave(chargeSchedule)
  }
  return {
    onChargeScheduleChange,
    localChargeSchedule,
    canControlClimate,
    chargeScheduleMessaging,
  }
}

export type ScheduleChargingProps = ReturnType<typeof useViewModel>
