import { BulletListItem, Icon } from '../index'
import { ReactNodeProps } from '../../types/reactNodeProps'

export interface Props extends ReactNodeProps {
  // No further props included
}

export default function PermissionsList({ children, ...props }: Props) {
  return (
    <BulletListItem
      icon={() => <Icon name="Check" color="blue-500" size={24} />}
      {...props}
    >
      {children}
    </BulletListItem>
  )
}
