import { useContext, useMemo } from 'react'
import {
  NavigationContext,
  NavigationContextValue,
} from '../../context/NavigationContext'
import { useHistory, useLocation } from 'react-router-dom'

/**
 * This hook utilizes the NavigationContext where relevant, allowing
 * navigation components to animate transitions between pages.
 * For example, if you use this component to navigate to a `NavigationPage`,
 * the page will slide in from the right. If you then use this hook to `goBack`,
 * the page will slide out to the right.
 *
 * Use this hook in place of useHistory() for navigation purposes.
 * @returns {object} An object with `goBack` and `push` methods.
 * @example
 * const { goBack, push } = useNavigation()
 * const onClick = () => push('/some-page')
 * const onBackClick = () => goBack()
 */
export function useNavigation() {
  const history = useHistory()
  const navigationContext = useContext(NavigationContext)

  // useMemo to prevent rerenders if goBack or push
  // are passed as dependencies
  const navigation = useMemo(() => {
    const pushAddParam = (key: string, value: string) => {
      pushAddParamToHistory(history, key, value)
    }
    const pushRemoveParam = (key: string) => {
      pushRemoveParamFromHistory(history, key)
    }
    const pushRetainingParams = (
      path: string,
      additionalParams: Record<string, string> = {},
    ) => {
      pushWithRetainedParams(history, path, additionalParams)
    }
    const pushToPath = (path: string) => {
      pushOnToPath(history, navigationContext, path)
    }

    if (navigationContext) {
      return {
        goBack: navigationContext.goBack,
        go: navigationContext.go,
        push: navigationContext.push,
        pushAddParam,
        pushRemoveParam,
        pushRetainingParams,
        pushToPath,
      }
    }

    return {
      goBack: history.goBack,
      go: history.go,
      push: history.push,
      pushAddParam,
      pushRemoveParam,
      pushRetainingParams,
      pushToPath,
    }
  }, [history, navigationContext])

  return navigation
}

function pushAddParamToHistory(
  history: ReturnType<typeof useHistory>,
  key: string,
  value: string,
) {
  const searchParams = new URLSearchParams(history.location.search)
  searchParams.set(key, value)
  history.push({ search: searchParams.toString() })
}

function pushRemoveParamFromHistory(
  history: ReturnType<typeof useHistory>,
  key: string,
) {
  const searchParams = new URLSearchParams(history.location.search)
  searchParams.delete(key)
  history.push({ search: searchParams.toString() })
}

function pushWithRetainedParams(
  history: ReturnType<typeof useHistory>,
  path: string,
  additionalParams: Record<string, string> = {},
) {
  const searchParams = new URLSearchParams(history.location.search)
  Object.entries(additionalParams).forEach(([key, value]) => {
    searchParams.set(key, value)
  })
  history.push({ pathname: path, search: searchParams.toString() })
}

function pushOnToPath(
  history: ReturnType<typeof useHistory>,
  navigationContext: NavigationContextValue | undefined,
  path: string,
) {
  const push = navigationContext?.push ?? history.push
  const existingPath = history.location.pathname
  const trailingSlash = existingPath.endsWith('/') ? '' : '/'
  const pathWithoutLeadingSlash = path.startsWith('/') ? path.slice(1) : path
  push(existingPath + trailingSlash + pathWithoutLeadingSlash)
}
