import { Typography } from '@material-ui/core'
import 'chartjs-plugin-annotation'
import { Link } from 'react-router-dom'
import { Bar } from 'react-chartjs-2'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import { isMobileDevice } from '../../../utils/sizeUtilities'
import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'
import { useDispatch } from 'react-redux'
import { DelayEvent } from '../GridSupport/DelayEvent'
import BaselineShiftingEvent from '../BaselineShiftingEvent'
import { getChargeForecast } from '../../../actions/metric'
import { useFetchingResourceSelector } from '../../../hooks/requests'
import { get as getBaselineShiftingEvents } from '../../../actions/baselineShiftingEvents'
import theme from '../../../v2/theme'
import { ThemeProvider } from '@material-ui/core/styles'
import { useAppDispatch, useAppSelector } from '@/hooks'
import {
  selectProgramViewConfigFromAllSources,
  selectUtilityProgramEnrollmentActive,
} from '@/selectors'
import { AlertMessage } from '@/app/components'
import { Button, Card } from '@/components'
import NonStopChargeAvailable from '@/app/features/app/vehicle/NonStopChargeAvailableSubtitle'
import {
  selectChargeNowEvent,
  selectChargeNowStatus,
} from '@/selectors/chargeNowEvent'
import { chargeNowEventCollection } from '@/reducers/chargeNowEvents'
import {
  selectBatteryTargetETA,
  selectChargeForecast,
  selectChargeStartTime,
} from '@/selectors/chargeForecast'
import dayjs from 'dayjs'
import { sanitizeHtmlContent } from '@/utils/components'
import { getVehicleChargingDelayEvents } from '@/actions/vehicleChargingDelayEvents'
import { selectVehicleIsParticipatingInActiveEvent } from '@/selectors/vehicleDemandResponseEvent'
import { ChargeForecast } from '@/types'
import { ID } from '@/types/model'
import { useFeatureFlag } from '@/hooks/useFeatureFlag'

const Spacer = styled.div(spacing)

const height = isMobileDevice() ? '250px' : '100%'

const ChartWrapper = styled.div`
  height: ${height}
  width: 100%;
`

export function SkeletonChart(props: { heading: string; description: string }) {
  return (
    <ThemeProvider theme={theme}>
      <Card>
        <Typography variant="h6" gutterBottom>
          {props.heading}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {props.description}
        </Typography>

        <Spacer mb={6} />

        <ChartWrapper>
          <Grid container spacing={6}>
            <Grid item>
              <Skeleton
                animation="wave"
                variant="text"
                height={30}
                width={50}
                style={{ marginBottom: '15px' }}
              />
            </Grid>
            <Grid item>
              <Skeleton
                animation="wave"
                variant="text"
                height={30}
                width={50}
                style={{ marginBottom: '15px' }}
              />
            </Grid>
          </Grid>
          <Skeleton animation="wave" variant="rect" height={200} />
          <Skeleton
            animation="wave"
            variant="text"
            height={30}
            style={{ marginTop: '15px' }}
          />
        </ChartWrapper>
      </Card>
    </ThemeProvider>
  )
}

const ChartSubtitleDisplayMode = {
  DelayEvent: 'DelayEvent',
  BaselineShiftingEvent: 'BaselineShiftingEvent',
  OptimizedPlan: 'OptimizedPlan',
  ScheduledSmartCharge: 'ScheduledSmartCharge',
  NonStopChargeAvailable: 'NonStopChargeAvailable',
  CurrentlyCharging: 'CurrentlyCharging',
  Default: 'Default',
}

function ChargeForecastChartSubtitle(props: {
  subtitleDisplayMode: keyof typeof ChartSubtitleDisplayMode
  vehicleId: ID
  requestChargeNowEvent: () => void
}) {
  const dispatch = useDispatch()
  const maybeUtilityProgramSubtitle = useAppSelector((state) => {
    const programActive = selectUtilityProgramEnrollmentActive(
      state,
      props.vehicleId,
    )
    if (!programActive) {
      return
    }
    const viewConfig = selectProgramViewConfigFromAllSources(
      state,
      props.vehicleId,
    )
    return viewConfig?.chargeForecast?.subtitle
  })
  const chargeStartTime = useAppSelector(selectChargeStartTime)
  const finishChargingTime = useAppSelector(selectBatteryTargetETA)
  const batteryTarget = useAppSelector(
    (state) => selectChargeForecast(state)?.max_battery_target,
  )

  // If there is a dispatch or baseline event, prioritize showing that information
  if (
    [
      ChartSubtitleDisplayMode.DelayEvent,
      ChartSubtitleDisplayMode.BaselineShiftingEvent,
    ].includes(props.subtitleDisplayMode)
  ) {
    return (
      {
        [ChartSubtitleDisplayMode.DelayEvent]: (
          <DelayEvent
            onLoad={() => dispatch(getChargeForecast(props.vehicleId))}
            vehicleId={props.vehicleId}
          />
        ),
        [ChartSubtitleDisplayMode.BaselineShiftingEvent]: (
          <BaselineShiftingEvent />
        ),
      }[props.subtitleDisplayMode] ?? <></>
    )
  }

  if (
    props.subtitleDisplayMode ===
    ChartSubtitleDisplayMode.NonStopChargeAvailable
  ) {
    return (
      <NonStopChargeAvailable
        requestChargeNowEvent={props.requestChargeNowEvent}
      />
    )
  }

  // If there is a utility program subtitle, show that instead of general information about your forecast
  if (maybeUtilityProgramSubtitle) {
    return (
      <AlertMessage variant="info">
        <span
          dangerouslySetInnerHTML={{
            __html: sanitizeHtmlContent(maybeUtilityProgramSubtitle),
          }}
        />
      </AlertMessage>
    )
  }

  // Otherwise, show the default subtitles
  return (
    {
      [ChartSubtitleDisplayMode.ScheduledSmartCharge]: (
        <AlertMessage variant="info">
          Your vehicle is scheduled to charge{' '}
          {chargeStartTime instanceof Date &&
            `at ${dayjs(chargeStartTime).format('h:mm a')}.`}
        </AlertMessage>
      ),
      [ChartSubtitleDisplayMode.CurrentlyCharging]: (
        <AlertMessage variant="info">
          Your vehicle is currently charging.
          {batteryTarget &&
            finishChargingTime &&
            ` Your battery will reach ${Math.round(batteryTarget)}% by ${dayjs(
              finishChargingTime,
            ).format('h:mm a')}.`}
        </AlertMessage>
      ),
      [ChartSubtitleDisplayMode.OptimizedPlan]: (
        <AlertMessage variant="info">
          Here is the upcoming charge schedule that's been optimized for you
        </AlertMessage>
      ),
      [ChartSubtitleDisplayMode.Default]: (
        <AlertMessage variant="info">
          Want more convenience and savings? &nbsp;
          <Link to="/devices">Click here to set up Optimized Charge</Link>
        </AlertMessage>
      ),
    }[props.subtitleDisplayMode] ?? <></>
  )
}

function ChargeForecastChargeNowButton(props: {
  chargeNowStatus: ChargeForecast['charge_now_status'] | null
  requestChargeNowEvent: () => void
  vehicleId: ID
  refreshChargeForecastAndDelayEvents: () => void
}) {
  const dispatch = useAppDispatch()
  const chargeNowEventId = useAppSelector(selectChargeNowEvent)?.id
  const participatingInActiveDrEvent = useAppSelector((state) =>
    selectVehicleIsParticipatingInActiveEvent(state, props.vehicleId),
  )
  const isLoading = useAppSelector((state) => {
    const chargeNowLoading =
      chargeNowEventCollection.selectors.queryState.someLoading(state)
    const chargeForecastLoading = state.metric.isFetchingChargeForecast
    return chargeNowLoading || chargeForecastLoading
  })

  if (
    isLoading ||
    !props.chargeNowStatus ||
    !['startable', 'stoppable'].includes(props.chargeNowStatus)
  ) {
    return null
  }

  // if the user is participating in a DR event, dont show the button because
  // the dr alert will contain an opt out option that will also create a charge now
  // event
  if (participatingInActiveDrEvent) {
    return null
  }

  if (props.chargeNowStatus === 'startable') {
    return (
      <Button
        id="charge-now-action-button"
        variant="primary-subtle"
        style={{
          marginTop: '24px',
        }}
        onClick={props.requestChargeNowEvent}
      >
        Start Charging
      </Button>
    )
  }

  // if we get here, the status is stoppable but we dont have an event id
  // that we can stop
  if (!chargeNowEventId) {
    return null
  }

  // else return the stop charge now button
  const stopChargeNowEvent = () => {
    dispatch(
      chargeNowEventCollection.actions.update(
        `vehicle/${props.vehicleId}/charge_now/${chargeNowEventId}/`,
        {
          complete: true,
        },
      ),
    ).then(props.refreshChargeForecastAndDelayEvents)
  }
  return (
    <Button
      id="charge-now-action-button"
      variant="secondary"
      style={{
        marginTop: '24px',
      }}
      onClick={stopChargeNowEvent}
    >
      Resume Smart Charging
    </Button>
  )
}

type Props = {
  vehicleId: ID
  isEnabled: boolean
  isOptimizedPlan: boolean
  setShowChargeNowConfirmation: (show: boolean) => void
  data: unknown
  options: unknown
  plugins: object[] | undefined
}

export function ChargeForecastChartComponent(props: Props) {
  const dispatch = useAppDispatch()
  chargeNowEventCollection.useFetch(`vehicle/${props.vehicleId}/charge_now`)
  // If the user is in the group that has access to the new charge forecast feature
  // then we dont want to show the charge now button
  const shouldShowNewChargeForecast = useFeatureFlag({
    flag: 'vehicle_chargeforecast',
  })
  const upcomingDelayEvent = useAppSelector(
    (state) =>
      !!state.delayEvents.delayEvents?.find(
        (delayEvent) => delayEvent.vehicle_id === props.vehicleId,
      ),
  )
  const chargeNowStatus = useAppSelector(selectChargeNowStatus)
  const chargeStartTime = useAppSelector(selectChargeStartTime)

  const baselineShiftingEvents = useFetchingResourceSelector({
    selector: (state) => state.baselineShiftingEvents,
    fetchAction: () =>
      getBaselineShiftingEvents({ vehicle_id: props.vehicleId }),
  })

  const hasBaselineEvents = (baselineShiftingEvents?.resource?.length ?? 0) > 0

  const subtitleDisplayMode = (() => {
    if (upcomingDelayEvent) {
      return ChartSubtitleDisplayMode.DelayEvent
    } else if (hasBaselineEvents) {
      return ChartSubtitleDisplayMode.BaselineShiftingEvent
    } else if (chargeNowStatus !== null && chargeNowStatus !== 'unavailable') {
      return {
        startable: ChartSubtitleDisplayMode.ScheduledSmartCharge,
        boostable: ChartSubtitleDisplayMode.NonStopChargeAvailable,
        stoppable: ChartSubtitleDisplayMode.CurrentlyCharging,
      }[chargeNowStatus]
      // if, then show the scheduled charge time
    } else if (props.isEnabled && chargeStartTime instanceof Date) {
      return ChartSubtitleDisplayMode.ScheduledSmartCharge
      // if were already changing, then show the time we'll finish
    } else if (props.isEnabled && chargeStartTime === 'now') {
      return ChartSubtitleDisplayMode.CurrentlyCharging
    } else if (props.isEnabled && props.isOptimizedPlan) {
      return ChartSubtitleDisplayMode.OptimizedPlan
    }
    return ChartSubtitleDisplayMode.Default
  })() as keyof typeof ChartSubtitleDisplayMode

  const refreshChargeForecastAndDelayEvents = () => {
    dispatch(getChargeForecast(props.vehicleId))
    dispatch(getVehicleChargingDelayEvents(props.vehicleId))
  }

  const requestChargeNowEvent = () => {
    dispatch(
      chargeNowEventCollection.actions.create(
        {},
        { pathSegments: [`vehicle/${props.vehicleId}/charge_now`] },
      ),
    ).then(refreshChargeForecastAndDelayEvents)
    props.setShowChargeNowConfirmation(true)
  }

  return (
    <ThemeProvider theme={theme}>
      <Card>
        <Typography variant="h6" gutterBottom style={{ marginBottom: '24px' }}>
          Charge Forecast
        </Typography>
        <ChargeForecastChartSubtitle
          subtitleDisplayMode={subtitleDisplayMode}
          requestChargeNowEvent={requestChargeNowEvent}
          vehicleId={props.vehicleId}
        />
        <Spacer mb={6} />
        <ChartWrapper>
          <Bar
            data={props.data}
            options={props.options}
            plugins={props.plugins}
          />
        </ChartWrapper>
        {!shouldShowNewChargeForecast && (
          <ChargeForecastChargeNowButton
            chargeNowStatus={chargeNowStatus}
            requestChargeNowEvent={requestChargeNowEvent}
            vehicleId={props.vehicleId}
            refreshChargeForecastAndDelayEvents={
              refreshChargeForecastAndDelayEvents
            }
          />
        )}
      </Card>
    </ThemeProvider>
  )
}
