import BrandedPage from '@/app/pages/ava/BrandedPage'
import { useNavigation } from '@/app/hooks'
import { useWhiteLabelData } from '@/hooks/whitelabel/useWhiteLabelData'
import { Button, Icon, LoadingAnimation, Text } from '@/components'
import InnerCard from '@/components/InnerCard/InnerCard'
import { Link } from 'app-components'
import { useUrlSearchParam } from '@/hooks'
import { resetTheme } from '@/hooks/whitelabel/useWhitelabelTheme'
import toExternalLink from '@/utils/to-external-link'

function UtilityInfoRow(props: { children: React.ReactNode }) {
  return (
    <div className="flex flex-row items-center gap-2.5">{props.children}</div>
  )
}

function UtilityInfoSection(props: {
  title?: string
  site?: string
  phone?: string
  email?: string
}) {
  return (
    <InnerCard>
      <div className="flex flex-col gap-4">
        {props.title && <Text variant="body1">{props.title}</Text>}
        {props.site && (
          <UtilityInfoRow>
            <Icon name="Globe" size={16} />
            <Link href={toExternalLink(props.site)}>{props.site}</Link>
          </UtilityInfoRow>
        )}
        {props.phone && (
          <UtilityInfoRow>
            <Icon name="Phone" size={16} fill="black" />
            <Link href={`tel:${props.phone}`}>{props.phone}</Link>
          </UtilityInfoRow>
        )}
        {props.email && (
          <UtilityInfoRow>
            <Icon name="Mail" size={16} />
            <Link href={`mailto:${props.email}`}>{props.email}</Link>
          </UtilityInfoRow>
        )}
      </div>
    </InnerCard>
  )
}

export default function BecomeUtilityCustomerPage() {
  const { whiteLabelData } = useWhiteLabelData()
  const isLoading = !whiteLabelData
  const pageType = useUrlSearchParam('pageType')
  const { push: navigateTo } = useNavigation()

  function onOnboardingButtonClick() {
    resetTheme()
    navigateTo('/onboarding?questionsToSkip=homeAddress')
  }

  const loadedContent = (
    <BrandedPage.Content>
      <div className="flex flex-col flex-fill gap-5">
        {whiteLabelData?.externalContact?.sections?.map((section) => {
          return (
            <UtilityInfoSection
              title={section?.title}
              email={section?.email}
              phone={section?.phone}
              site={section?.site}
            />
          )
        })}
      </div>
      {pageType === 'onboarding' && (
        <BrandedPage.ButtonContainer>
          <Button id="onboarding-button" onClick={onOnboardingButtonClick}>
            Next
          </Button>
        </BrandedPage.ButtonContainer>
      )}
    </BrandedPage.Content>
  )

  return (
    <BrandedPage
      id="become-utility-customer"
      title={whiteLabelData?.externalContact?.title}
      subtitle={whiteLabelData?.externalContact?.description}
    >
      {isLoading && <LoadingAnimation type="falling" centered />}
      {!isLoading && loadedContent}
    </BrandedPage>
  )
}
