import { selectUtilityProgramEnrollmentForUser } from '@/reducers/utilityProgramEnrollments'
import { selectUtilityProgramForUserUtility } from '@/reducers/utilityPrograms'
import { createSelector } from '@reduxjs/toolkit'

type Options = {
  onlyEnrolledPrograms?: boolean
}

const defaultOptions = { onlyEnrolledPrograms: false }

/**
 * Selects the utility program from the current user, from the utility program enrollment if it exists,
 * otherwise from the utility program for the user's utility.
 */
const selectUtilityProgramForUserConsideringEnrollments = createSelector(
  [
    (state) => selectUtilityProgramEnrollmentForUser(state),
    (state) => selectUtilityProgramForUserUtility(state),
    (state, options?: Options) => options ?? defaultOptions,
  ],
  (enrollment, program, options) => {
    if (options.onlyEnrolledPrograms) {
      const isEnrolled = enrollment && !enrollment?.unenrolled_at
      return isEnrolled ? enrollment?.utility_program : undefined
    }
    return enrollment?.utility_program ?? program
  },
)

export default selectUtilityProgramForUserConsideringEnrollments
