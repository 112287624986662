import { createResourceCollection } from '@/request'
import { ChargeForecast } from '@/types'
import { Charges, Charge } from '@/types/charges'
import { Model } from '@/types/model'

export const chargeForecastCollection = createResourceCollection<
  ChargeForecast & Model
>({
  name: 'chargeForecast',
  apiConfig: {
    path: 'charge_forecast',
  },
  selector: (state) => state.chargeForecast,
  computedId: (forecast) =>
    `chargeForecast:vehicleId:${forecast.vehicle_id}:chargerId:${forecast.charger_id}`,
})

export const chargeForecast = chargeForecastCollection.slice.reducer

export const chargesCollection = createResourceCollection<Charges & Model>({
  name: 'charges',
  apiConfig: {
    path: 'charges',
  },
  paginationKey: 'UNSET',
  selector: (state) => state.charges,
})

export const charges = chargesCollection.slice.reducer

export function getLatestCharge(charges: Charge[]) {
  return charges.reduce((latest, charge) => {
    if (!latest) {
      return charge
    }
    const currentEndTime = new Date(charge.end_time).getTime()
    const latestEndTime = new Date(latest.end_time).getTime()

    if (currentEndTime > latestEndTime) {
      return charge
    }
    return latest
  }, null as Charge | null)
}
