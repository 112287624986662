import { useState, useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { VehicleChargeSchedule } from '@/types'
import { convertMilesToKilometers } from '@/utils/conversionUtilities'
import { debounce } from 'lodash'
import { updateChargeSchedule } from '@/actions/schedule'
import { logEvent } from '@/logging'
import useVehicleChargeSchedulesFetcher from '@/authenticated/hooks/useVehicleChargeSchedulesFetcher'
import { ChargeForecastSliderProps } from '.'
import { useDistanceUnit } from '@/authenticated/hooks/conversions'

const DEFAULT_CHARGE_LIMIT = 80
const CHARGE_LIMIT_MIN = 20
const DEBOUNCE = 1000

const sliderStepsByMake: { [key: string]: number } = {
  ford: 5,
}

const useViewModel = ({
  vehicleId,
  loading,
  onChange: onSliderChange,
}: ChargeForecastSliderProps) => {
  const dispatch = useAppDispatch()
  const vehicle = useAppSelector((state) =>
    state.vehicles.vehicles?.find((vehicle) => vehicle.id === vehicleId),
  )
  const chargeSchedules = useVehicleChargeSchedulesFetcher()

  const batteryInMiles = Math.round(
    (vehicle?.battery_level_percent ?? 0) *
      (vehicle?.miles_per_battery_percent ?? 0),
  )

  const [distanceUnits] = useDistanceUnit()
  const batteryDistance =
    distanceUnits === 'miles'
      ? batteryInMiles
      : convertMilesToKilometers(batteryInMiles)

  const batteryDistanceWithUnitsLabel = `${batteryDistance} ${
    distanceUnits === 'miles' ? 'mi' : 'km'
  }`

  const chargeSchedule = chargeSchedules?.find(
    (schedule) => schedule.vehicle_id === vehicleId,
  ) as VehicleChargeSchedule | undefined

  const [interacting, setInteracting] = useState(false)
  const [chargeLimit, setChargeLimit] = useState<number>(DEFAULT_CHARGE_LIMIT)
  const currentCharge = vehicle?.battery_level_percent ?? 0

  const onChange = (newValue: number) => {
    if (newValue < CHARGE_LIMIT_MIN) return

    setChargeLimit(newValue)
    onSliderChange?.(newValue)
  }

  const debouncedChargeLimitSave = useCallback(
    debounce((chargeSchedule) => {
      dispatch(updateChargeSchedule(chargeSchedule))
    }, DEBOUNCE),
    [],
  )

  const onStartDragging = () => {
    setInteracting(true)
    logEvent('chargeforecast_slider_dragged')
  }

  const onStoppedDragging = async () => {
    setInteracting(false)
    if (!chargeSchedule) {
      return
    }

    if (chargeSchedule.battery_target_unit === 'percent') {
      const newChargeSchedule = {
        ...chargeSchedule,
        battery_target_max: Math.round(chargeLimit),
      }
      debouncedChargeLimitSave(newChargeSchedule)
    }

    if (
      vehicle &&
      vehicle.miles_per_battery_percent &&
      chargeSchedule.battery_target_unit === 'miles'
    ) {
      const newChargeSchedule = {
        ...chargeSchedule,
        battery_target_max: chargeLimit * vehicle.miles_per_battery_percent,
      }
      debouncedChargeLimitSave(newChargeSchedule)
    }
  }

  useEffect(() => {
    if (chargeSchedule?.battery_target_unit === 'percent') {
      setChargeLimit(chargeSchedule.battery_target_max)
    } else if (vehicle && chargeSchedule) {
      const miles = vehicle.miles_per_battery_percent
        ? Math.round(
            chargeSchedule?.battery_target_max /
              vehicle.miles_per_battery_percent,
          )
        : 0

      if (chargeSchedule.battery_target_unit === 'miles') {
        setChargeLimit(miles)
      } else if (chargeSchedule.battery_target_unit === 'kilometers') {
        setChargeLimit(convertMilesToKilometers(miles))
      }
    }
  }, [chargeSchedule, vehicle])

  const getChargeColors = (currentCharge: number) => {
    let chargeLimitColor = '#80EA9E'
    let currentChargeColor = '#00D740'

    if (currentCharge <= 20) {
      chargeLimitColor = '#FDABA0'
      currentChargeColor = '#FA5941'
    } else if (currentCharge <= 10) {
      chargeLimitColor = '#FDE9A0'
      currentChargeColor = '#FAD341'
    }

    return { chargeLimitColor, currentChargeColor }
  }

  const { chargeLimitColor, currentChargeColor } =
    getChargeColors(currentCharge)

  const make: string = vehicle?.car?.car_model?.make ?? ''
  const step = sliderStepsByMake?.[make] ?? 1

  return {
    loading,
    interacting,
    chargeLimit,
    onChange,
    batteryInMiles,
    batteryDistanceWithUnitsLabel,
    chargeLimitColor,
    currentChargeColor,
    currentCharge,
    onStartDragging,
    onStoppedDragging,
    step,
  }
}

export default useViewModel
