export function getApiErrorMessage(payload) {
  if (payload.response) {
    // Handle Django's rest-framework custom API errors
    if (payload.response !== 'undefined') {
      let keys = Object.keys(payload.response)
      if (keys.length > 0) {
        // Get the first property key, and return that as the error details.
        return { detail: payload.response[keys[0]] }
      }
    }

    // This comes from Django's ApiException()
    if (typeof payload.response.detail !== 'undefined') {
      return { detail: payload.response.detail }
    }
  }

  return { detail: payload.statusText }
}
