import { isNativeMobile } from '@/authenticated/hooks/useIsMobile'

export const LOG_EVENT_TYPE = 'logEvent'
export const LEAP_AUTHORIZE_TYPE = 'authorizeWithLeap'
export const CONNECT_THERMOSTAT_TYPE = 'connectThermostat'
export const CONNECT_THERMOSTAT_CLOSE_TYPE = 'connectThermostatClose'
export const RATE_APP_TYPE = 'rateApp'
export const SEND_TOKENS_TYPE = 'sendTokens'
export const UPDATE_URL_TYPE = 'updateUrl'
export const OVERRIDE_URLS_TYPE = 'overrideUrls'
export const REQUEST_LOCATION_TYPE = 'requestLocation'
export const DISMISS_URL_TYPE = 'dismissUrl'
export const CLIPBOARD_COPY_TYPE = 'clipboardCopy'
export const NOTIFICATION_PERMISSIONS_TYPE = 'notification_permissions'

export const SHOW_NOTIFICATION_PERMISSIONS_MODAL_ACTION = 'showPermissionsModal'
export const REQUEST_NOTIFICATION_PERMISSION_STATUS_ACTION =
  'requestNotificationPermissionStatus'

/**
 * Posts a event log to the mobile app if it is available through the window
 * @param eventName
 * @param params data to be sent with the event
 * @returns boolean indicating whether the event was sent to the mobile app
 */
export const postEventToMobileIfPresent = (
  eventName: string,
  params: null | any = null,
) => {
  const event = JSON.stringify({ event: eventName, params })
  return postMessagetoMobileIfPresent(event, LOG_EVENT_TYPE)
}

/**
 * Posts a message to the mobile app if it is available through the window
 * @param message
 * @param type
 * @returns boolean indicating if the message was posted
 */
export const postMessagetoMobileIfPresent = (
  message: string,
  type?: string,
) => {
  if (isNativeMobile()) {
    window.ReactNativeWebView?.postMessage(
      type ? `${type}|${message}` : message,
    )
    return true
  }

  return false
}

/**
 * Sends tokens to mobile
 * @param tokens
 * @returns boolean indicating whether the tokens were sent to the mobile app
 */
export const sendTokensToMobile = (tokens: {
  access: string
  refresh: string
}) => {
  const event = JSON.stringify(tokens)
  return postMessagetoMobileIfPresent(event, SEND_TOKENS_TYPE)
}

/**
 * Tell Native to update current route
 * @param url
 * @returns boolean indicating whether the route was sent to the mobile app
 */
export const updateRouteOnMobile = (url: string) => {
  return postMessagetoMobileIfPresent(url, UPDATE_URL_TYPE)
}

/**
 * Overrides the mobile app to use staging and not prod
 * @returns boolean indicating whether the message was sent to the mobile app
 */
export const overrideMobileUrls = () => {
  return postMessagetoMobileIfPresent('true', OVERRIDE_URLS_TYPE)
}

/**
 * Requests the current location from react native to prevent a double confirmation
 * @param url optional url to redirect to if the app reloads on permissions change
 * @returns boolean indicating whether the request was sent to the mobile app
 */
export const requestLocationOnMobile = (url?: string) => {
  return postMessagetoMobileIfPresent(url ?? '', REQUEST_LOCATION_TYPE)
}

/**
 * Tell the mobile app to dismiss a requested initial url route
 * @returns boolean indicating whether the request was sent to the mobile app
 */
export const dismissInitialMobileUrl = () => {
  return postMessagetoMobileIfPresent('true', DISMISS_URL_TYPE)
}
