import React from 'react'
import { Box as MuiBox, Grid as MuiGrid } from '@material-ui/core'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const Box = styled(MuiBox)(spacing)
const Grid = styled(MuiGrid)(spacing)

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: 'rgba(0,0,0,0)',
    },
    fullScreen: {
      width: '100%',
      height: '100%',
    },
    fullWidth: {
      width: '100%',
    },
    cardContainer: {
      [theme.breakpoints.down('sm')]: {
        backgroundColor: '#FFF',
      },
    },
    card: {
      borderRadius: '20px',
      backgroundColor: '#FFF',
      height: 'fit-content',

      '&.full-height-on-mobile': {
        [theme.breakpoints.down('sm')]: {
          minHeight: '100%',
          height: 'initial',
        },
      },
    },
  }),
)

export type Props = {
  children: React.ReactNode
  className?: string
  fullHeightOnMobile?: boolean
}

export default function FullScreenSingleCard({
  children,
  className,
  fullHeightOnMobile,
}: Props) {
  const classes = useStyles()

  return (
    <Grid
      container
      className={clsx(classes.root, classes.fullScreen)}
      justifyContent="center"
      alignItems="flex-start"
    >
      <Grid
        container
        item
        justifyContent="center"
        className={clsx(classes.fullScreen, classes.cardContainer)}
        xs={12}
        sm={12}
        md={8}
        lg={10}
        xl={4}
        p={{ md: 10 }}
      >
        <Box
          className={clsx(
            classes.card,
            classes.fullWidth,
            fullHeightOnMobile && 'full-height-on-mobile',
            className,
          )}
        >
          {children}
        </Box>
      </Grid>
    </Grid>
  )
}
