import { DateRepresentation } from '@/types/dates'

export function dateIsBetween({
  date,
  start,
  end,
}: {
  date: DateRepresentation
  start: DateRepresentation
  end: DateRepresentation
}) {
  return new Date(date) >= new Date(start) && new Date(date) <= new Date(end)
}
