import { Button, Icon, Text } from '@/components'
import React, { useCallback, useState } from 'react'
import { useNavigation } from '@/app/hooks'
import SurveyObserver, {
  useSurveyResponder,
} from '@/app/components/SurveyObserver'
import { logEvent } from '@/logging'

export default function PlanSurveyNotification() {
  const { dismissSurvey } = useSurveyResponder('PlanSwitchApproval')
  const [hide, setHide] = useState(false)
  const navigate = useNavigation()

  const onApprove = useCallback(() => {
    logEvent('rateToolFeedbackViewed')
    navigate.push('/home-insights/survey/approve')
  }, [navigate])

  const onDisapprove = useCallback(() => {
    logEvent('rateToolFeedbackViewed')
    navigate.push('/home-insights/survey/disapprove')
  }, [navigate])

  const dismiss = () => {
    dismissSurvey?.()
    setHide(true)
  }

  if (hide) {
    return null
  }

  return (
    <SurveyObserver observedSurveys={['PlanSwitchApproval']}>
      <div className="mt-7 bg-themed-white border rounded-[20px] shadow-spread pt-3 pb-4 px-5">
        <div className="flex flex-row justify-between items-end">
          <img
            alt="Plan logo"
            src="/img/gexa_energy.jpeg"
            className="h-[30px]"
          />
          <Button variant="icon" id="close-btn">
            <Icon color="#5d5d5d" name="X" size={24} onClick={dismiss} />
          </Button>
        </div>
        <Text variant="h3" className="mt-3">
          We want to hear from you!
        </Text>
        <Text variant="body4" className="mt-2">
          Your feedback helps GEXA improve their service
        </Text>
        <div className="flex flex-col">
          <Button
            id="thumbs-up-btn"
            variant="primary-subtle"
            className="mt-6"
            onClick={onApprove}
          >
            <Icon name="ThumbsUp" />
          </Button>
          <Button
            id="thumbs-up-btn"
            variant="primary-subtle"
            className="mt-4 mb-2"
            onClick={onDisapprove}
          >
            <Icon name="ThumbsDown" />
          </Button>
        </div>
      </div>
    </SurveyObserver>
  )
}
