interface Pipe {
  <A>(): A
  <A, B>(fn1: (input: A) => B): (input: A) => B
  <A, B, C>(fn1: (input: A) => B, fn2: (input: B) => C): (input: A) => C
  <A, B, C, D>(
    fn1: (input: A) => B,
    fn2: (input: B) => C,
    fn3: (input: C) => D,
  ): (input: A) => D
  <A, B, C, D, E>(
    fn1: (input: A) => B,
    fn2: (input: B) => C,
    fn3: (input: C) => D,
    fn4: (input: D) => E,
  ): (input: A) => E
  <A, B, C, D, E, F>(
    fn1: (input: A) => B,
    fn2: (input: B) => C,
    fn3: (input: C) => D,
    fn4: (input: D) => E,
    fn5: (input: E) => F,
  ): (input: A) => F
  <A, B, C, D, E, F, G>(
    fn1: (input: A) => B,
    fn2: (input: B) => C,
    fn3: (input: C) => D,
    fn4: (input: D) => E,
    fn5: (input: E) => F,
    fn6: (input: F) => G,
  ): (input: A) => G
  // add more overloads if needed
}

/**
 * Returns a piped function that takes the input of the first function and returns the result of the last function in the list
 * @param fns A list of functions in which the output of the previous function is the input of the next function
 * @returns A function that takes the input of the first function and returns the result of the last function in the list
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const getPiped: Pipe = (...fns: Function[]) => {
  return (value: unknown) => fns.reduce((acc, fn) => fn(acc), value)
}
