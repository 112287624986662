import { ThermostatCostHistorySummary } from '../../../../types/thermostatCostHistorySummary'
import {
  DataPeriod,
  DataPoint,
  DataPointType,
  HoveredDataPointInfo,
} from './TotalSpentGraph'
import { DateRepresentation } from '../../../../types/dates'
import dayjs from 'dayjs'
import { ID } from '../../../../types/model'
import React, { useEffect, useState } from 'react'
import { centsToDollars } from '../../../../utils/currency'
import { useAppSelector } from '../../../../hooks'
import { selectCostHistorySummaryForThermostat } from '../../../../selectors'
import mockData from '../../../../types/thermostatCostHistorySummary/mock.json'
import useMockableViewModel from '../../../../hooks/useMockableViewModel'
import { thermostatsCollection } from '../../../../reducers/thermostats'

export interface Props {
  thermostatId: ID
  period: DataPeriod
}

const legend = [
  {
    label: 'cool',
    color: 'blue-500',
  },
  {
    label: 'heat',
    color: 'red-500',
  },
  {
    label: 'adjustments',
    color: 'orange-300',
  },
]

const labels = {
  day: dayjs().format('ddd, MMM D'),
  week: `${dayjs().subtract(6, 'days').format('MMM D')}–${dayjs().format(
    'MMM D',
  )}`,
  month: `${dayjs()
    .subtract(1, 'month')
    .format(
      dayjs().subtract(1, 'month').month() !== 11 ? 'MMM' : 'MMM YYYY',
    )}–${dayjs().format('MMM YYYY')}`,
}

function onPointerDown(event: React.PointerEvent<HTMLElement>) {
  // @ts-expect-error hasPointerCapture does not exist on event.target
  if (event.target.hasPointerCapture(event.pointerId)) {
    // @ts-expect-error releasePointerCapture does not exist on event.target
    event.target.releasePointerCapture(event.pointerId)
  }
}

function formatUsageData(costHistorySummary?: ThermostatCostHistorySummary) {
  const dataPoints = {
    day: [] as DataPoint[],
    week: [] as DataPoint[],
    month: [] as DataPoint[],
  }

  // Returns the text that is displayed when hovering over a data point
  function generateLabel(date: DateRepresentation, period: DataPeriod) {
    if (period !== 'day') return dayjs(<string>date).format('ddd, MMM D')
    else {
      const nextHour = dayjs(<string>date)
        .add(1, 'hour')
        .format()
      return `${dayjs(<string>date).format('h')}–${dayjs(nextHour).format(
        'ha',
      )}`
    }
  }

  if (costHistorySummary) {
    Object.keys(costHistorySummary).forEach((key) => {
      const period = key as DataPeriod
      const maxValue = {
        day: 0,
        week: 0,
        month: 0,
      }

      // Create data point
      costHistorySummary[period]?.usage_intervals?.forEach((interval) => {
        const dataPoint = {} as DataPoint

        // @ts-expect-error date does not exist on type DataPoint
        dataPoint.date = interval.date
        dataPoint.label = generateLabel(interval.date, period)
        dataPoint.type = period
        dataPoint.value = Number(
          (
            interval.usage.adjustments.kwh_used +
            interval.usage.cool.kwh_used +
            interval.usage.heat.kwh_used
          ).toFixed(1),
        )
        dataPoint.data = Object.keys(interval.usage).map((key) => {
          const usageType = key as DataPointType

          return {
            value: Number(interval.usage[usageType].kwh_used.toFixed(1)),
            type: usageType,
          }
        })

        dataPoint.cost = Number(
          centsToDollars(
            interval.usage.adjustments.cost_cents +
              interval.usage.cool.cost_cents +
              interval.usage.heat.cost_cents,
          ).toFixed(2),
        )

        // Update max value
        if (dataPoint.value > maxValue[period]) {
          maxValue[period] = dataPoint.value
        }

        dataPoints[period].push(dataPoint)
      })

      // Update all data points with the highest value in the graph
      dataPoints[period]?.forEach((dataPoint) => {
        dataPoint.maxValue = maxValue[period]
      })
    })
  }

  // If the day does not have 24 hours, fill in the remaining hours with blank data
  if (dataPoints.day.length < 24) {
    const hours = [...dataPoints.day, ...Array(24 - dataPoints.day.length)]

    dataPoints.day = hours.map((dataPoint, index) => {
      if (dataPoint) return dataPoint
      else {
        let label

        // If the data is not undefined and there is a previous element in the array...
        if (hours[0] && index !== 0) {
          // Generate a label using a date that continues from the last provided date
          const date = dayjs(hours[dataPoints.day.length - 1].date)
            .add(index - dataPoints.day.length + 1, 'hours')
            .format()

          label = generateLabel(date, 'day')
        } else {
          // Generate a label starting from the beginning of the day
          const date = dayjs().startOf('day').add(index, 'hours').format()

          label = generateLabel(date, 'day')
        }

        return {
          cost: 0,
          data: [
            {
              value: 0,
              type: 'cool',
            },
          ],
          label: hours[index]?.label || label,
          type: 'day',
          value: 0,
        }
      }
    })
  }

  if (dataPoints.month && dataPoints.month.length > 30) {
    for (let i = 30; i <= dataPoints.month.length; i++) {
      dataPoints.month.shift()
    }
  }

  return dataPoints
}

function useViewModel(props: Props) {
  const costHistory = useAppSelector((state) =>
    selectCostHistorySummaryForThermostat(state, props.thermostatId),
  )
  const costHistoryStatus = useAppSelector((state) =>
    thermostatsCollection.selectors.annotationStatus(
      state,
      'cost_history/summary',
    ),
  )

  const formattedItems = formatUsageData(costHistory)

  const [_period, setPeriod] = useState<DataPeriod>('day')
  const [totalSpent, setTotalSpent] = useState(
    centsToDollars(costHistory?.day.total_cost_cents || 0),
  )
  const [items, setItems] = useState(formattedItems.day || [])
  const maxValues = {
    day:
      formattedItems.day.find((item: DataPoint) => item.type === 'day')
        ?.maxValue || 0,
    week:
      formattedItems.week.find((item: DataPoint) => item.type === 'week')
        ?.maxValue || 0,
    month:
      formattedItems.month.find((item: DataPoint) => item.type === 'month')
        ?.maxValue || 0,
  }
  const [selectedInfo, setSelectedInfo] = useState({
    period: labels.day,
    energyUsed: getEnergyUsed(_period) || 0,
    totalSpent: centsToDollars(costHistory?.[_period].total_cost_cents || 0),
  })
  const [xAxis, setXAxis] = useState<(string | number)[]>([
    '12a',
    'noon',
    '12a',
  ])
  const [yAxis, setYAxis] = useState<(string | number)[]>([
    maxValues[_period] || 0,
    (maxValues[_period] || 0) / 2,
    'kWh',
  ])

  function getEnergyUsed(period: DataPeriod) {
    return (
      formattedItems[period]
        .filter((item: DataPoint) => item.type === period)
        .reduce(
          (sum: number, dataPoint: DataPoint) => sum + dataPoint.value,
          0,
        ) || 0
    )
  }

  function onPointerEnter(info: HoveredDataPointInfo) {
    setSelectedInfo(info)
  }

  function onPointerLeave() {
    setSelectedInfo({
      period: labels[_period],
      energyUsed: getEnergyUsed(_period),
      totalSpent: centsToDollars(costHistory?.[_period].total_cost_cents || 0),
    })
  }

  useEffect(() => {
    setSelectedInfo({
      period: labels[_period],
      energyUsed: getEnergyUsed(_period),
      totalSpent: centsToDollars(costHistory?.[_period].total_cost_cents || 0),
    })
    setItems(formattedItems?.[_period] || [])
    setTotalSpent(centsToDollars(costHistory?.[_period].total_cost_cents || 0))
    setYAxis([maxValues[_period], maxValues[_period] / 2, 'kWh'])

    switch (_period) {
      case 'day':
        setXAxis(['12a', 'noon', '12a'])
        break
      case 'week':
        setXAxis([
          dayjs().subtract(6, 'days').format('ddd'),
          dayjs().subtract(3, 'days').format('ddd'),
          dayjs().format('ddd'),
        ])
        break
      case 'month':
        setXAxis([
          dayjs().subtract(1, 'month').format('Do'),
          dayjs().subtract(15, 'days').format('Do'),
          dayjs().format('Do'),
        ])
        break
    }
  }, [_period, costHistory])

  return {
    ...props,
    items,
    legend: legend,
    xAxis,
    yAxis,
    setPeriod,
    selectedInfo,
    setSelectedInfo,
    totalSpent,
    onPointerDown,
    onPointerEnter,
    onPointerLeave,
    selectedPeriod: labels[_period] || labels.day,
    // If a separate request is loading, but this data has already been loaded, then loading is false
    loading: costHistory?.[_period]
      ? false
      : costHistoryStatus === 'loading' || costHistoryStatus === 'idle',
  }
}

function useMockViewModel(props: Props) {
  const costHistory = mockData as ThermostatCostHistorySummary

  const formattedItems = formatUsageData(costHistory)

  const [_period, setPeriod] = useState<DataPeriod>('day')
  const [totalSpent, setTotalSpent] = useState(
    centsToDollars(costHistory.day.total_cost_cents || 0),
  )
  const [items, setItems] = useState(formattedItems.day || [])

  const maxValues = {
    day:
      formattedItems.day.find((item: DataPoint) => item.type === 'day')
        ?.maxValue || 0,
    week:
      formattedItems.week.find((item: DataPoint) => item.type === 'week')
        ?.maxValue || 0,
    month:
      formattedItems.month.find((item: DataPoint) => item.type === 'month')
        ?.maxValue || 0,
  }

  const [selectedInfo, setSelectedInfo] = useState({
    period: labels.day,
    energyUsed: getEnergyUsed(_period) || 0,
    totalSpent: centsToDollars(costHistory[_period].total_cost_cents || 0),
  })

  const [xAxis, setXAxis] = useState<(string | number)[]>([
    '12a',
    'noon',
    '12a',
  ])
  const [yAxis, setYAxis] = useState<(string | number)[]>([
    maxValues[_period] || 0,
    (maxValues[_period] || 0) / 2,
    'kWh',
  ])

  function getEnergyUsed(period: DataPeriod) {
    return (
      formattedItems[period]
        .filter((item: DataPoint) => item.type === period)
        .reduce(
          (sum: number, dataPoint: DataPoint) => sum + dataPoint.value,
          0,
        ) || 0
    )
  }

  function onPointerEnter(info: HoveredDataPointInfo) {
    setSelectedInfo(info)
  }

  function onPointerLeave() {
    setSelectedInfo({
      period: labels[_period],
      energyUsed: getEnergyUsed(_period),
      totalSpent: centsToDollars(costHistory[_period].total_cost_cents || 0),
    })
  }

  useEffect(() => {
    setSelectedInfo({
      period: labels[_period],
      energyUsed: getEnergyUsed(_period),
      totalSpent: centsToDollars(costHistory?.[_period].total_cost_cents || 0),
    })
    setItems(formattedItems[_period] || [])
    setTotalSpent(centsToDollars(costHistory[_period].total_cost_cents || 0))
    setYAxis([maxValues[_period], maxValues[_period] / 2, 'kWh'])

    switch (_period) {
      case 'day':
        setXAxis(['12a', 'noon', '12a'])
        break
      case 'week':
        setXAxis([
          dayjs().subtract(6, 'days').format('ddd'),
          dayjs().subtract(3, 'days').format('ddd'),
          dayjs().format('ddd'),
        ])
        break
      case 'month':
        setXAxis([
          dayjs().subtract(1, 'month').format('Do'),
          dayjs().subtract(15, 'days').format('Do'),
          dayjs().format('Do'),
        ])
        break
    }
  }, [_period])

  return {
    ...props,
    items,
    legend: legend,
    xAxis,
    yAxis,
    setPeriod,
    selectedInfo,
    setSelectedInfo,
    totalSpent,
    onPointerDown,
    onPointerEnter,
    onPointerLeave,
    selectedPeriod: labels[_period] || labels.day,
    loading: false,
  }
}

export default useMockableViewModel({ useViewModel, useMockViewModel })
