import { DateRepresentation } from '../dates'
import { DeviceDemandResponseEvent } from '../deviceDemandResponseEvent'
import { Plan } from '../plan'

export type ChargeForecastPeriodMetric = {
  type: string
  miles: number
  kilometers: number
  miles_range: number
  kilometers_range: number
  battery_percent: number
  charge_cost_cents: number
  carbon_lbs: number
  should_charge: boolean
  should_precondition: boolean
  should_precondition_climate: boolean
  emissions_moer: number
}

export type ChargeForecastPeriod = {
  start_datetime: DateRepresentation
  end_datetime: DateRepresentation
  electricity_price_per_kwh: number
  low_emissions: boolean
  emissions_moer: number
  percent_time_at_home: number
  start_standard_metric?: ChargeForecastPeriodMetric
  end_standard_metric?: ChargeForecastPeriodMetric
  start_cheapest_rate_metric?: ChargeForecastPeriodMetric
  end_cheapest_rate_metric?: ChargeForecastPeriodMetric
  start_smart_charge_metric?: ChargeForecastPeriodMetric
  end_smart_charge_metric?: ChargeForecastPeriodMetric
}

export type ChargeForecast = {
  plan: Plan
  charge_forecast_periods: ChargeForecastPeriod[]
  max_battery_target: number
  min_battery_target: number
  battery_unit: 'percent' | 'miles' | 'kilometers'
  selected_plan: string
  comparison_plan: string
  schedule_start_time_utc: DateRepresentation
  departure_time_utc: DateRepresentation
  timezone: string
  is_rates_only: boolean
  is_trip: boolean
  can_charge_now: boolean
  charge_now_status: 'startable' | 'stoppable' | 'boostable' | 'unavailable'
  vehicle_id?: number | null
  charger_id?: number | null
  // The time that the current ongoing charge started
  charging_start_time?: DateRepresentation
  next_enrolled_dr_event: DeviceDemandResponseEvent | null
  plugged_in: boolean
  is_charging: boolean
  is_home: boolean
  type?: 'no home'
  max_charge_time_minutes: number
}

export type ChargeForecastWithVehicle = ChargeForecast & {
  vehicle_id: number
}

export type ChargeForecastWithCharger = ChargeForecast & {
  charger_id: number
}

export function isChargeForecastWithVehicle(
  chargeForecast: ChargeForecast,
): chargeForecast is ChargeForecastWithVehicle {
  return 'vehicle_id' in chargeForecast && chargeForecast.vehicle_id !== null
}

export function isChargeForecastWithCharger(
  chargeForecast: ChargeForecast,
): chargeForecast is ChargeForecastWithCharger {
  return 'charger_id' in chargeForecast && chargeForecast.charger_id !== null
}
