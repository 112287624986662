import useUserWhitelabel from './useUserWhitelabel'
import { remoteConfig } from '@/firebase'
import useBasePath from '@/hooks/useBasePath'
import useRemoteConfig from '../useRemoteConfig'
import { useParams } from 'react-router-dom'

/**
 * Returns the program id to use for whitelabeling the app.
 * Defaults to the program id obtained by mapping the app base path
 *  to a program id.
 * If none for basepath, use the id attached to the user.
 */
export default function useWhiteLabelProgramId(): number | null {
  const userAttachedWhiteLabelId = useUserWhitelabel()
  const { appRemoteConfig } = useRemoteConfig()
  const { utility } = useParams<{ utility: string }>()
  const appBasePath = useBasePath()

  if (utility) {
    const whiteLabelId = appRemoteConfig?.getWhitelabelId(utility) ?? null
    if (whiteLabelId) {
      return whiteLabelId
    }
  }
  if (appBasePath) {
    const whiteLabelId = appRemoteConfig?.getWhitelabelId(appBasePath) ?? null
    if (whiteLabelId) {
      return whiteLabelId
    }
  }

  if (userAttachedWhiteLabelId) {
    return userAttachedWhiteLabelId
  }

  return null
}
