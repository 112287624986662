import { useAppDispatch } from '@/hooks/index'
import { useParams } from 'react-router-dom'
import useVehicle from '@/hooks/useVehicle'
import { useVehicleChargeSchedule } from '@/hooks/useVehicleChargeSchedule'
import { VehicleChargeSchedule } from '@/types'
import { updateChargeSchedule } from '@/actions/schedule'
import useUtilityAlerts from '@/hooks/useUtilityAlerts'
import { useCallback, useState } from 'react'
import debounce from 'lodash.debounce'

type Params = {
  defaultVehicleId?: string
}

export default function useVehicleSettingsManager(params?: Params) {
  const dispatch = useAppDispatch()
  const { deviceId } = useParams<{ deviceId: string | undefined }>()
  const vehicleId = Number.parseInt(
    params?.defaultVehicleId ?? deviceId ?? '',
    10,
  )
  const vehicle = useVehicle(vehicleId)
  const chargeSchedule = useVehicleChargeSchedule(vehicleId)
  const isLoading = !vehicle || !chargeSchedule

  const debouncedSave = useCallback(
    debounce((model) => {
      dispatch(updateChargeSchedule(model))
    }, 500),
    [],
  )

  if (!deviceId) {
    throw new Error(
      ':deviceId was not found in your current route. Ensure tabs.tsx is setup correctly for this page.',
    )
  }

  const saveChargeSchedule = (model: VehicleChargeSchedule) => {
    dispatch(updateChargeSchedule(model))
  }

  const {
    subtitle: utilityAlert,
    forceChargeControl: utilityChargeControlOverride,
  } = useUtilityAlerts(vehicle)

  return {
    vehicleId,
    vehicle,
    chargeSchedule,
    isLoading,
    saveChargeSchedule,
    utilityChargeControlOverride,
    utilityAlert,
    debouncedSave,
  }
}
