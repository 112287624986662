import { AlertMessage } from '@/app/components'
import { ID } from '@/types/model'
import { useVehiclePausedDataAlert } from '@/app/hooks/useVehiclePausedDataAlert'

type Props = {
  vehicleId: ID
}

export default function VehicleSectionHeader(props: Props) {
  const alertMessage = useVehiclePausedDataAlert(props.vehicleId)

  if (!alertMessage) {
    return null
  }

  return <AlertMessage variant="info" rawHtmlText={alertMessage} />
}
