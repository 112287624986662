import { extendTailwindMerge } from 'tailwind-merge'

/**
 * This file is used to allow custom tailwind config generated classes
 * to not be pruned out in using tailwind-merge
 */
export default extendTailwindMerge({
  classGroups: {
    // screens don't need to be configured in tailwind-merge
    // custom font families are supported out of the box and don't need to be configured
    // custom colors are supported out of the box and don't need to be configured
    // custom line heights with numerical values work out of the box
    text: [
      'text-link',
      'text-title',
      'text-label',
      'text-h1',
      'text-h2',
      'text-h3',
      'text-h3-subheader',
      'text-subheader',
      'text-body1',
      'text-body2',
      'text-body3',
    ],
  },
})
