import { ConfigurableButton } from '@/app/components'
import { useMobileVersionFlag } from '@/hooks'
import googleMapReact from 'google-map-react'
import GoogleMapReactLib from 'google-map-react'
import { useEffect, useState } from 'react'
import { Navigation, Minus, Plus } from 'react-feather'

const calculateRadiusInPixels = (zoomLevel: number, radiusInMeters = 100) => {
  const earthCircumferenceInMeters = 40075017
  const worldWidthInPixels = 256 * Math.pow(2, zoomLevel)
  const metersPerPixelAtCurrentZoomLevel =
    earthCircumferenceInMeters / worldWidthInPixels

  const radiusInPixels = radiusInMeters / metersPerPixelAtCurrentZoomLevel

  return radiusInPixels
}
const Marker = (props: {
  lat?: number
  lng?: number
  text: string
  zoomLevel?: number
}) => {
  const zoomLevel = props.zoomLevel ?? 15
  const markerRadius =
    Math.max(8, 4 * Math.log2(zoomLevel)) +
    (zoomLevel < 5 ? Math.log2(zoomLevel + 2) : 0)
  if (!props.lat || !props.lng) return <></>
  return (
    <div
      className="absolute bg-themed-accent border-2 border-solid border-white rounded-full select-none -translate-x-1/2 -translate-y-1/2"
      style={{
        width: markerRadius,
        height: markerRadius,
      }}
      {...props}
    ></div>
  )
}
const RadiusMarker = (props: {
  lat?: number
  lng?: number
  text: string
  zoomLevel: number
}) => {
  const radiusInPixels = calculateRadiusInPixels(props.zoomLevel) * 2
  if (!props.lat || !props.lng) return <></>
  return (
    <div
      className={`absolute bg-themed-accent/10 border-2 border-solid border-themed-accent/50 rounded-full select-none -translate-x-1/2 -translate-y-1/2 transition-all ease-in `}
      style={{
        width: radiusInPixels,
        height: radiusInPixels,
      }}
      {...props}
    ></div>
  )
}
type GoogleMapReactDom = googleMapReact & { googleMapDom_: HTMLElement }
const CustomZoomControl = ({
  mapDomRef,
  map,
}: {
  mapDomRef: GoogleMapReactDom | null
  map?: { setZoom: (arg: number) => void; zoom: number }
}) => {
  if (!mapDomRef || !map) return <></>
  return (
    <div
      className={`absolute w-12 md:w-14 md:right-2 right-1 md:top-2 top-1 z-10 flex flex-col items-center bg-white border border-gray-200 rounded-2xl shadow-lg`}
    >
      <ConfigurableButton
        id="zoom-in-button"
        variant="navigation"
        className="flex !p-0 !w-full !aspect-square !rounded-b-none items-center justify-center md:px-4 px-2 !rounded-2xl cursor-pointer active:bg-themed-neutral/10"
        onClick={() => map.setZoom(map.zoom + 1)}
      >
        <Plus />
      </ConfigurableButton>
      <div className="h-[1px] w-[80%] bg-themed-neutral/10 md:mx-2 mx-1" />
      <ConfigurableButton
        id="zoom-out-button"
        variant="navigation"
        className="flex !p-0 !w-full !aspect-square !rounded-t-none !rounded-2xl items-center justify-center md:px-4 px-2 cursor-pointer active:bg-themed-neutral/5"
        onClick={() => map.setZoom(map.zoom - 1)}
      >
        <Minus />
      </ConfigurableButton>
    </div>
  )
}

const UseCurrentLocationButton = (props: { onClick: () => void }) => {
  return (
    <div className="absolute w-12 md:w-14 md:right-2 right-1 md:bottom-2 bottom-1 z-10 flex flex-col gap-2 md:gap-4 items-center">
      <ConfigurableButton
        id="use-current-location-button"
        variant="navigation"
        className="!p-2 !aspect-square !w-full !rounded-2xl !shadow-lg"
        onClick={() => props.onClick()}
      >
        <Navigation className="text-themed-accent" />
      </ConfigurableButton>
    </div>
  )
}

export default function GoogleMapReact({
  lat,
  lng,
  text,
  showRadius = false,
  defaultZoom = 15,
  mapOptions,
  onCurrentLocationClick,
  ...rest
}: {
  lat?: number
  lng?: number
  text: string
  showRadius?: boolean
  defaultZoom?: number
  mapOptions?: GoogleMapReactLib.MapOptions
  onCurrentLocationClick?: () => void
} & GoogleMapReactLib.Props) {
  const getMapOptions = (
    maps: GoogleMapReactLib.Maps,
  ): GoogleMapReactLib.MapOptions => ({
    fullscreenControl: false,
    mapTypeControl: false,
    mapTypeId: maps.MapTypeId.ROADMAP,
    scaleControl: false,
    scrollwheel: false,
    streetViewControl: false,
    zoomControl: false,
  })

  const [zoom, setZoom] = useState(defaultZoom)
  const [mapDomRef, setMapDomRef] = useState<googleMapReact | null>(null)
  const [map, setMap] = useState()
  useEffect(() => {
    setZoom(defaultZoom)
  }, [defaultZoom])
  function zoomAnimationEnd(zoomLevel: number) {
    setZoom(zoomLevel)
  }

  const canUseCurrentLocation =
    !!navigator.geolocation &&
    !!onCurrentLocationClick &&
    useMobileVersionFlag('currentLocation')

  return (
    <div className="w-full block h-[300px] relative">
      <GoogleMapReactLib
        options={mapOptions ?? getMapOptions}
        bootstrapURLKeys={{
          key: 'AIzaSyBepLq2XUtkg6kX9gx4FMREr_8sRmZPE44',
        }}
        center={lat && lng ? { lat, lng } : { lat: 38.4522, lng: -99.1332 }}
        defaultZoom={defaultZoom || 15}
        zoom={zoom}
        onZoomAnimationEnd={zoomAnimationEnd}
        onGoogleApiLoaded={({ map }) => {
          setMap(map)
        }}
        ref={(map) => {
          setMapDomRef(map)
        }}
        yesIWantToUseGoogleMapApiInternals
        {...rest}
      >
        <Marker lat={lat} lng={lng} text={text} zoomLevel={zoom} />
        {showRadius && (
          <RadiusMarker lat={lat} lng={lng} text={text} zoomLevel={zoom} />
        )}
      </GoogleMapReactLib>
      <CustomZoomControl mapDomRef={mapDomRef as GoogleMapReactDom} map={map} />
      {canUseCurrentLocation && (
        <UseCurrentLocationButton onClick={onCurrentLocationClick} />
      )}
    </div>
  )
}
