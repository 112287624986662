import { useEffect, useState } from 'react'

export default function useHasRendered() {
  const [hasRenderedOnce, setHasRenderedOnce] = useState(false)

  useEffect(() => {
    setHasRenderedOnce(true)
  }, [hasRenderedOnce])

  return hasRenderedOnce
}
