import ReactDOM from 'react-dom'
import { ApolloProvider } from '@apollo/client'
import { client } from '@/apolloClient'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import { ConnectedRouter } from 'connected-react-router'
import { history, store } from './store/'
import { RootRoutes } from './app/routes'
import { BreakpointProvider } from './context'
import './modules/plugins/dayjs'
import { attachLoggingToGlobal } from './logging'
import './index.css'
import { isNativeMobile } from './authenticated/hooks/useIsMobile'
import { PostHogProvider } from 'posthog-js/react'

// Disable zoom for react-native-webview. This disables zoom in and zoom out, which can be dangerous.
if (isNativeMobile() ?? false) {
  document.body.style.touchAction = 'pan-x pan-y'
}

// Attach logging functionality to the global scope.
// This is used by Google Tag Manager tags to log events.
attachLoggingToGlobal()

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <PostHogProvider
        apiKey={process.env.REACT_APP_POSTHOG_API_KEY}
        options={{ api_host: process.env.REACT_APP_POSTHOG_API_HOST }}
      >
        <PersistGate persistor={persistStore(store)}>
          <BreakpointProvider>
            <ConnectedRouter history={history}>
              <RootRoutes />
            </ConnectedRouter>
          </BreakpointProvider>
        </PersistGate>
      </PostHogProvider>
    </Provider>
  </ApolloProvider>,
  document.getElementById('root'),
)
