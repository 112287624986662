import React from 'react'
import { getCurrentYear } from '../../../utils/timeUtilities'

class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <footer className="footer-section _use-deprecated-bootstrap _use-deprecated-style">
          <div
            className={'footer-top background-img-2 py-5'}
            style={{
              backgroundImage: "url('img/footer-bg.webp')",
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center top',
              backgroundSize: 'cover',
            }}
          >
            <div className="container px-4">
              <div className="row justify-content-between">
                <div className="col-lg-3 mb-3 mb-lg-0">
                  <div className="footer-nav-wrap text-white">
                    <img
                      src="img/logo/logo-small-white.webp"
                      alt="footer logo"
                      width="150"
                      className="img-fluid mb-3"
                    />
                    <div className="social-list-wrap">
                      <ul className="social-list list-inline list-unstyled">
                        <li className="list-inline-item">
                          <a
                            href="https://www.facebook.com/optiwatt/"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Facebook"
                          >
                            <span className="ti-facebook"></span>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            href="https://twitter.com/optiwatt"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Twitter"
                          >
                            <span className="ti-twitter"></span>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            href="https://www.youtube.com/channel/UC3hhn3AJdZCxFJ_30wyQCdQ"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Youtube"
                          >
                            <span className="ti-youtube"></span>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            href="https://www.instagram.com/optiwatt/"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Instagram"
                          >
                            <span className="ti-instagram"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 ml-auto mb-4 mb-lg-0">
                  <div className="footer-nav-wrap text-white">
                    <h5 className="mb-3 text-white">About</h5>
                    <ul className="list-unstyled">
                      <li className="mb-2">
                        <a
                          href="https://optiwatt.applytojob.com"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Careers
                        </a>
                      </li>
                      <li className="mb-2">
                        <a href="/privacy">Privacy Policy</a>
                      </li>
                      <li className="mb-2">
                        <a href="/tos">Terms of Service</a>
                      </li>
                      <li className="mb-2">
                        <a href="/faq">FAQ</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 ml-auto mb-4 mb-lg-0">
                  <div className="footer-nav-wrap text-white">
                    <h5 className="mb-3 text-white">Support</h5>
                    <ul className="list-unstyled support-list">
                      <li className="mb-2 d-flex align-items-center">
                        <span className="ti-location-pin mr-2"></span>
                        San Francisco, California
                      </li>
                      <li className="mb-2 d-flex align-items-center">
                        <span className="ti-email mr-2"></span>
                        <a href="mailto:support@getoptiwatt.com">
                          {' '}
                          support@getoptiwatt.com
                        </a>
                      </li>
                      <li className="mb-2 d-flex align-items-center">
                        <span className="ti-world mr-2"></span>
                        <a href="/#"> www.optiwatt.com</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="footer-nav-wrap text-white">
                    <h5 className="mb-3 text-white">Location</h5>
                    <img
                      src="img/map.webp"
                      alt="location map"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div className="footer-bottom pt-4">
                <div className="container">
                  <div className="row text-center justify-content-center">
                    <div className="col-md-6 col-lg-5">
                      <p className="copyright-text pb-0 mb-0">
                        Copyrights © {getCurrentYear()}. All rights reserved by
                        <a href="/#"> Optiwatt</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    )
  }
}

export default Footer
