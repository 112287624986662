import './styles.scss'
import formatClasses from '../../utils/classes/formatClasses'

export interface Props {
  orientation?: 'horizontal' | 'vertical'
  className?: string
}

const defaultProps = {
  orientation: 'horizontal',
}

export default function Divider(inProps: Props) {
  const props = { ...defaultProps, ...inProps }
  const classes = formatClasses(['divider', props.orientation, props.className])

  return <div className={classes} />
}
