import { RSAA } from 'redux-api-middleware'
import { withAuth } from '../reducers'
import config from '../config'

export const FEEDBACK_REQUEST = '@@feedback/FEEDBACK_REQUEST'
export const FEEDBACK_SUCCESS = '@@feedback/FEEDBACK_SUCCESS'
export const FEEDBACK_FAILURE = '@@feedback/FEEDBACK_FAILURE'

export const RESET_FEEDBACK_FORM = '@@feedback/RESET_FEEDBACK_FORM'

export const submitFeedbackForm = (contactData) => ({
  [RSAA]: {
    endpoint: `${config.apiGateway.URL}/api/forms/contact`,
    method: 'POST',
    body: JSON.stringify(contactData),
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: [FEEDBACK_REQUEST, FEEDBACK_SUCCESS, FEEDBACK_FAILURE],
  },
})

export const resetFeedbackForm = () => {
  return { type: RESET_FEEDBACK_FORM }
}
