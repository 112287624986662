import { Dispatch, SetStateAction, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Button, Icon, Text, TextField } from '@/components'
import DescriptiveListItem from '@/app/components/DescriptiveListItem/DescriptiveListItem'
import QRCode from 'react-qr-code'
import { AdaptiveModal, AlertMessage } from 'app-components'
import { useFormik } from 'formik'
import * as yup from 'yup'
import useSkipTeslaVirtualKeyUrl from '@/app/hooks/useSkipTeslaVirtualKeyUrl'
import { NotOwnerCardContent } from '../key-connection/not-owner/no-keycard'

type ContentState = 'notOwner' | 'emailOwner'
export default function TelsaFleetNotOwnerModal({
  open,
  setIsModalOpen,
}: {
  open: boolean
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
}) {
  const [contentState, setContentState] = useState<ContentState>('notOwner')
  return (
    <AdaptiveModal
      id={'not-owner-modal'}
      open={open}
      onClose={() => setIsModalOpen(false)}
      className={'p-1'}
    >
      <div className={'p-8'}>
        {contentState === 'notOwner' ? (
          <NotOwnerCardContent includeSkip={true} includeEmail={false} />
        ) : (
          <EmailOwner setContentState={setContentState} />
        )}
      </div>
    </AdaptiveModal>
  )
}

function NotOwner({
  setContentState,
}: {
  setContentState: (state: ContentState) => void
}) {
  const history = useHistory()
  const { path } = useRouteMatch()
  const skipToUrl = useSkipTeslaVirtualKeyUrl()
  const backToConnectionTest = () => {
    history.push(`${path}/key-confirmation`)
  }
  const onSkipClick = () => {
    history.push(skipToUrl)
  }
  return (
    <div>
      <Text variant="h2" className={'flex flex-row items-start gap-4'}>
        <span className={'text-base'}>
          <Icon
            name={'Info'}
            size={'20px'}
            className={'text-themed-blue-500 pt-[1px]'}
          />
        </span>
        <span>Vehicle owner required to pair virtual key</span>
      </Text>
      <div className={'text-sm font-medium ml-9 mt-4 my-8'}>
        We noticed you're listed as a driver, and the vehicle owner must share
        their virtual key with Optiwatt to complete the connection. Here's what
        you can do next:
      </div>
      <div className={'flex flex-col gap-10'}>
        <div className={'flex flex-col gap-4'}>
          <DescriptiveListItem
            title={'Ask the Owner to Scan This Code'}
            subtitle={
              "Share this QR code with the vehicle's owner. It'll guide them through sharing their virtual key with Optiwatt."
            }
            index={1}
          />
          <div className={'flex items-center justify-center'}>
            <QRCode
              value={'https://www.tesla.com/_ak/www.optiwatt.com'}
              size={120}
            />
          </div>
          {/*impl this later*/}
          {/*<div className={'ml-12'}>*/}
          {/*  <DescriptiveListItem*/}
          {/*    title={'Or Send Instructions to Owner'}*/}
          {/*    subtitle={*/}
          {/*      "If preferable, click 'Email The Owner' to send them the necessary instructions and QR code via email."*/}
          {/*    }*/}
          {/*  />*/}
          {/*  <div*/}
          {/*    className={*/}
          {/*      'mt-2 decoration-blue-500 underline-offset-2 underline text-blue-500 cursor-pointer'*/}
          {/*    }*/}
          {/*    onClick={() => setContentState('emailOwner')}*/}
          {/*  >*/}
          {/*    Email The Owner*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
        <div>
          <DescriptiveListItem
            title={'Start Connection Test'}
            subtitle={
              'After confirming the vehicle owner has completed the virtual key share, tap ‘Start Connection Test’ to continue.'
            }
            index={2}
          />
          <div
            className={
              'ml-12 mt-2 decoration-blue-500 underline-offset-2 underline text-blue-500 cursor-pointer'
            }
            onClick={backToConnectionTest}
          >
            Start Connection Test
          </div>
          <div
            className={
              'ml-12 mt-2 decoration-blue-500 underline-offset-2 underline text-blue-500 cursor-pointer'
            }
            onClick={onSkipClick}
          >
            Skip pairing and continue
          </div>
        </div>
      </div>
    </div>
  )
}

function EmailOwner({
  setContentState,
}: {
  setContentState: (state: ContentState) => void
}) {
  const [emailSent, setEmailSent] = useState(false)
  const history = useHistory()
  const { path } = useRouteMatch()
  const skipToUrl = useSkipTeslaVirtualKeyUrl()
  const onSkipClick = () => {
    history.push(skipToUrl)
  }

  const backToConnectionTest = () => {
    history.push(`${path}/key-confirmation`)
  }
  function handleBack() {
    setContentState('notOwner')
  }
  async function sendEmail(email: string) {
    // TODO: Implement email sending
    setEmailSent(true)
  }
  const emailSchema = yup.object().shape({
    email: yup
      .string()
      .email('Invalid email address')
      .required('Email is required'),
  })
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: emailSchema,
    validateOnBlur: true,
    onSubmit: async (values) => {
      await sendEmail(values.email)
    },
  })
  return (
    <section>
      <div className={'flex flex-row gap-4 items-center'}>
        <Button
          variant={'icon'}
          id={'email-owner-back-button'}
          onClick={handleBack}
        >
          <Icon name={'ArrowLeft'} className={'text-themed-base-900'} />
        </Button>
        <Text variant="h2" className={'my-4'}>
          Email Owner
        </Text>
      </div>
      <div className={'text-sm font-medium mb-14 ml-14'}>
        We'll send an email to the owner with instructions on how to share
        permissions to activate Optiwatt.
      </div>
      <div className={'mt-16 md:ml-14'}>
        <TextField
          label={"Recipient's Email"}
          name={'email'}
          id={'recipient-email-form-field'}
          variant={'standard'}
          className={'max-w-lg w-full'}
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && formik.errors.email !== undefined}
          onBlur={formik.handleBlur}
          disabled={emailSent}
        />
        <div className={'text-themed-error font-medium text-sm h-4'}>
          {formik.touched.email && <>{formik.errors.email ?? ''}</>}
        </div>
      </div>
      {emailSent && (
        <AlertMessage variant={'info'} className={'mt-4'}>
          Email sent to {formik.values.email}
        </AlertMessage>
      )}
      {!emailSent ? (
        <Button
          id={'send-email-button'}
          variant={'primary'}
          onClick={() => formik.handleSubmit()}
          className={'mt-16'}
          disabled={!formik.isValid}
        >
          Send Email
        </Button>
      ) : (
        <>
          <div
            onClick={backToConnectionTest}
            className={
              'flex items-center mt-8 justify-center decoration-blue-500 underline-offset-2 underline text-blue-500 cursor-pointer'
            }
          >
            Start Connection Test
          </div>
          <div
            onClick={onSkipClick}
            className={
              'flex items-center mt-8 justify-center decoration-blue-500 underline-offset-2 underline text-blue-500 cursor-pointer'
            }
          >
            Skip pairing and continue
          </div>
        </>
      )}
    </section>
  )
}
