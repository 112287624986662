import './styles.scss'
import formatClasses from '../../utils/classes/formatClasses'
import { ReactNodeProps } from '../../types/reactNodeProps'
import { Grid, Icon, Text } from '../index'
import { ReactElement, useEffect, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'

export interface Props extends ReactNodeProps {
  compact?: boolean
  last?: boolean
  nested?: boolean
  initiallyExpanded?: boolean
  label: string | ReactElement
  disabled?: boolean
  onExpand?: (expanded: boolean) => void
  expandedCount?: number
}

export default function ExpansionPanel(props: Props) {
  const [_expanded, setExpanded] = useState(props.initiallyExpanded ?? false)
  const expanded = props.disabled ? false : _expanded
  const ref = useRef<HTMLDivElement>(null)
  const [maxHeight, setMaxHeight] = useState('0')

  useEffect(() => {
    setMaxHeight(
      ref.current && expanded
        ? `${ref.current.scrollHeight + ref.current.clientHeight}px`
        : '0',
    )
  }, [expanded, props.expandedCount])

  const classes = {
    expansionPanel: formatClasses([
      'expansion-panel',
      expanded ? 'expanded' : undefined,
      props.disabled ? 'disabled' : undefined,
      props.compact ? 'compact' : undefined,
      props.nested ? 'nested' : undefined,
      props.last ? 'last' : undefined,
      props.className,
    ]),
    expansionPanelContent: formatClasses([
      'expansion-panel-content',
      'content-enter-done',
      props.compact ? 'compact' : undefined,
    ]),
    icon: formatClasses(['expansion-panel-icon']),
  }

  const ExpansionPanelLabel = () => {
    if (typeof props.label === 'string') {
      return <Text>{props.label}</Text>
    }

    return props.label || <Text>Label</Text>
  }

  return (
    <div
      className={classes.expansionPanel}
      onClick={(event) => {
        setExpanded(!expanded)
        props.onExpand?.(!expanded)
        event.stopPropagation()
      }}
      style={props.style}
    >
      <Grid flow="row" fluid>
        <Grid
          gap="12px"
          placeContent="space-between"
          placeItems="center start"
          templateColumns="1fr auto"
        >
          <ExpansionPanelLabel />
          <Icon
            name="ChevronDown"
            color="grey-400"
            rotation={expanded ? -180 : 0}
            className={classes.icon}
          />
        </Grid>
        <CSSTransition in={expanded} timeout={200} classNames="content">
          <div
            className={classes.expansionPanelContent}
            ref={ref}
            style={{ maxHeight }}
          >
            {props.children}
          </div>
        </CSSTransition>
      </Grid>
    </div>
  )
}
