import { useAppSelector } from '@/hooks'
import useMockableViewModel from '@/hooks/useMockableViewModel'
import {
  evaluatedProgramViewConfigTemplate,
  searchableUtilityProgramsCollection,
} from '@/reducers/utilityPrograms'
import { RootState } from '@/store'

export type SuccessProps = {
  status: 'success'
  utilityProgramId: string
  utilityProgramName: string
}

export type ErrorProps = {
  status: 'error'
}

export type Props = SuccessProps | ErrorProps

const defaults = {
  success: {
    title: 'Congrats!',
    description:
      "Great news - you successfully joined our pilot program! We've teamed up with ${utility_name} to offer bill credits just for using Optiwatt.",
  },
  error: {
    title: 'Oh no...',
    description:
      'Sorry, this promotion is no longer valid. Please reach out to support if you have any questions.',
  },
}

function selectProgramIfneeded(state: RootState, props: Props) {
  if (props.status === 'error') {
    return
  }
  return searchableUtilityProgramsCollection.selectors.selectById(
    state,
    props.utilityProgramId,
  )
}

function useViewModel(props: Props) {
  const viewConfigFromProgram = useAppSelector(
    (state) => selectProgramIfneeded(state, props)?.view_config_json,
  )
  const tosUrl = useAppSelector(
    (state) => selectProgramIfneeded(state, props)?.tos_url,
  )
  const faqUrl = useAppSelector(
    (state) => selectProgramIfneeded(state, props)?.faq_url,
  )

  if (props.status === 'error') {
    return { variant: props.status, ...defaults.error }
  }

  const unevaluatedViewConfig = {
    ...viewConfigFromProgram,
    utilityProgramConnect: {
      success: {
        title:
          viewConfigFromProgram?.utilityProgramConnect?.success.title ??
          defaults.success.title,
        description:
          viewConfigFromProgram?.utilityProgramConnect?.success?.description ??
          defaults.success.description,
      },
    },
  }
  const viewConfigTemplateVariables = { utility_name: props.utilityProgramName }
  const viewConfig = evaluatedProgramViewConfigTemplate(
    unevaluatedViewConfig,
    viewConfigTemplateVariables,
  )

  return {
    variant: props.status,
    tosUrl,
    faqUrl,
    ...viewConfig?.utilityProgramConnect?.success,
  }
}

function useMockViewModel(props: Props) {
  const viewConfig =
    props.status === 'error' ? defaults.error : defaults.success
  return {
    variant: props.status,
    ...viewConfig,
  }
}

export default useMockableViewModel({ useViewModel, useMockViewModel })
