import { AsteriskIcon, CheckIcon } from 'lucide-react'
import React, { ReactNode, useEffect, useState } from 'react'
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form'
import './styles.css'
import clsx from 'clsx'
import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Transition,
} from '@headlessui/react'
import CircleCheckbox from '@/authenticated/components/CircleCheckbox'

type Option = {
  id: number
  name: string
}

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  label: string
  multiple?: boolean
  name: string
  options: Option[]
  value?: Option | undefined
  initialValue?: Option | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getValues: UseFormGetValues<any>
  disabled?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register?: any
  onQueryChange?: (value: string) => void
  icon?: ReactNode
}

export default function ComboBox({
  name,
  label,
  options,
  multiple = false,
  setValue,
  getValues,
  disabled = false,
  initialValue,
  register,
  onQueryChange,
  autoFocus,
  icon,
  ...inputHtmlProps
}: Props) {
  const [query, setQuery] = useState(initialValue?.name ?? '')
  const [selected, setSelected] = useState<Option | undefined>(initialValue)

  const filteredOptions =
    query === ''
      ? options
      : options.filter((option) => {
          return option.name.toLowerCase().includes(query.toLowerCase())
        })

  return (
    <div className="h-fit">
      <Combobox
        immediate
        multiple={multiple}
        value={selected ?? null}
        onChange={(value) => {
          setSelected(value as Option)
          setValue(name, (value as Option) ?? undefined, {
            shouldDirty: true,
          })
        }}
        disabled={disabled}
      >
        <div
          className={clsx(
            'form-field-root flex flex-row gap-1',
            multiple ? '!rounded-b-none !border-b-0 !pb-0' : '',
            disabled && 'opacity-50 cursor-not-allowed',
          )}
        >
          <div className="flex flex-col gap-1 w-full">
            <input
              type="text"
              className="peer"
              value={selected ? selected.name : query}
              readOnly
              hidden
              {...(register ? register(name) : {})}
              {...inputHtmlProps}
            />
            <label
              htmlFor={name}
              className="text-themed-grey-500 font-bold bg-transparent text-xs"
            >
              <div className="flex flex-row">
                {label}
                {inputHtmlProps.required && (
                  <AsteriskIcon className="text-themed-error" size={12} />
                )}
              </div>
            </label>
            <ComboboxInput
              name={name}
              className={clsx(
                'w-full outline-none border-none text-sm text-themed-grey-500 font-medium',
                disabled && 'bg-optiwatt-grey-100',
              )}
              displayValue={(o: Option | undefined) => {
                return o?.name ?? query
              }}
              onChange={(event) => {
                setQuery(event.target.value)
                onQueryChange?.(event.target.value)
              }}
              autoFocus={autoFocus}
              disabled={disabled}
            />
          </div>
          <div className="flex items-center justify-center">{icon}</div>
        </div>
        <Transition
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery('')}
        >
          <ComboboxOptions
            anchor="bottom"
            className="empty:hidden w-[calc(var(--input-width)+1rem)] border border-solid border-themed-grey-200 rounded-bl-lg rounded-br-lg bg-white p-1 [--anchor-gap:1rem] translate-y-1"
          >
            {filteredOptions.map((option) => (
              <ComboboxOption
                key={option.id}
                value={option}
                className="group flex bg-white cursor-default items-center justify-center gap-2 py-2 px-3 rounded-lg select-none data-[focus]:bg-themed-grey-100"
              >
                <div className="w-4 flex items-center justify-center">
                  <CheckIcon
                    size={16}
                    className="invisible size-4 fill-white group-data-[selected]:visible text-themed-green-500"
                  />
                </div>
                <div className="text-sm/6 text-themed-grey-500 grow text">
                  {option.name}
                </div>
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        </Transition>
      </Combobox>
    </div>
  )
}
