import React from 'react'
import TeslaGiveawayMeterConnectionCardWithDialog from '../TeslaGiveawayMeterConnectionCardWithDialog'

export interface Props {
  shouldShowCard: boolean
  dialogOpen: boolean
  setDialogOpen: (dialogOpen: boolean) => void
  dialogConfirmationPending: boolean
  setDialogConfirmationPending: (dialogConfirmationPending: boolean) => void
  setGiveawayWasDismissed: () => void
}

export default function TeslaGiveawayDashboardView({
  shouldShowCard,
  setGiveawayWasDismissed,
  dialogOpen,
  setDialogOpen,
  dialogConfirmationPending,
  setDialogConfirmationPending,
}: Props) {
  return (
    <TeslaGiveawayMeterConnectionCardWithDialog
      showCard={shouldShowCard}
      onCardDismiss={setGiveawayWasDismissed}
      dialogOpen={dialogOpen}
      setDialogOpen={setDialogOpen}
      dialogConfirmationPending={dialogConfirmationPending}
      setDialogConfirmationPending={setDialogConfirmationPending}
    />
  )
}
