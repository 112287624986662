import { match } from 'ts-pattern'

export enum ProfileEligibilityRequirements {
  Available = 'AVAILABLE',
  RequireUtility = 'REQUIRE_UTILITY_PROVIDER',
  RequireLocation = 'REQUIRE_LOCATION',
  Device = 'DEVICE',
  AvaVerified = 'AVA_VERIFIED',
}

export function profileIneligibilityReasonContent(
  key: ProfileEligibilityRequirements,
) {
  return match(key)
    .returnType<string>()
    .with(
      ProfileEligibilityRequirements.Available,
      () => 'This program is currently full or unavailable.',
    )
    .with(
      ProfileEligibilityRequirements.RequireUtility,
      () => 'You must be a customer of an eligible utility provider.',
    )
    .with(
      ProfileEligibilityRequirements.RequireLocation,
      () => 'Your location is not eligible for this program.',
    )
    .with(
      ProfileEligibilityRequirements.Device,
      () => 'You must have an eligible device to enroll in this program.',
    )
    .with(
      ProfileEligibilityRequirements.AvaVerified,
      () =>
        'You must complete verification with Ava to enroll in this program.',
    )
    .exhaustive()
}
