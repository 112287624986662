import { createResourceCollection } from '@/request'
import { SurveyInteraction } from '@/types'

export const surveyInteractionCollection =
  createResourceCollection<SurveyInteraction>({
    name: 'surveyInteractions',
    apiConfig: {
      path: 'survey_interactions',
    },
    selector: (state) => state.surveyInteractions,
  })

export default surveyInteractionCollection.slice.reducer
