import formatClasses from '../../utils/classes/formatClasses'
import './styles.scss'
import { Flex, Text } from '../index'
import { ReactNodeProps } from '../../types/reactNodeProps'

export interface Props extends ReactNodeProps {
  label: string
}

export default function DataListItem(props: Props) {
  const classes = formatClasses(['list-item', 'data-list-item'])

  return (
    <Flex
      alignItems="center"
      className={classes}
      container
      justifyContent="space-between"
    >
      <Text variant="body2">{props.label}</Text>
      {props.children}
    </Flex>
  )
}
