import { useNavigation } from '@/app/hooks'
import { Resource } from '@/authenticated/components/ResourceSelectionGallery/types'
/**
 * The gallery actions are the buttons in the gallery that allow the user to
 * perform actions other than device selection, e.g. add a new device.
 *
 * @returns An array of resources that define the gallery actions.
 */
export function useGalleryActions(): Resource[] {
  const navigation = useNavigation()

  const connectDeviceActionResource = {
    name: 'Connect Device',
    id: `resource-gallery-connect-device`,
    details: 'Connect Device',
    action: () => navigation.push('/connect-device'),
    selected: false,
    variant: 'action' as const,
  }

  return [connectDeviceActionResource]
}
