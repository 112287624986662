import SelectManualUtilityPlanOptionsFlowStepRoot, {
  Props as ComponentProps,
} from './SelectManualUtilityPlanOptionsFlowStep'
import useViewModel from './useViewModel'
import { ViewModelComponentProps } from '../../../../utils/typeUtilities'

export type Props = ViewModelComponentProps<ComponentProps, typeof useViewModel>

export default function SelectManualUtilityPlanOptionsFlowStep(props: Props) {
  const viewModel = useViewModel(props)
  return (
    <SelectManualUtilityPlanOptionsFlowStepRoot {...viewModel} {...props} />
  )
}
