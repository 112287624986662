import { ConfigurableText, NavigationPage } from '@/app/components'
import { useNavigation } from '@/app/hooks'
import useSkipTeslaVirtualKeyUrl from '@/app/hooks/useSkipTeslaVirtualKeyUrl'
import QRCode from 'react-qr-code'
import { useUrlWithUtilityBasePath } from '@/app/hooks/useUtilityBasePath'
import { logMessage } from '@/logging/sentry'
import { useUrlSearchParam } from '@/hooks'
import check from '@/app/img/check.svg'
import { Redirect, useParams } from 'react-router-dom'

const PROMPT_WITH_EMAIL =
  "If preferable, click 'Email The Owner' to send them the necessary instructions via email. Alternatively, they can scan the QR code below."
const PROMPT_WITHOUT_EMAIL =
  'You can send the QR code below to the owner for them to scan.'

export function NotOwnerCardContent(props: {
  includeSkip: boolean
  includeEmail: boolean
  vehicleId?: number
}) {
  const { includeSkip, vehicleId, includeEmail } = props
  const navigation = useNavigation()
  const skipToUrl = useSkipTeslaVirtualKeyUrl()
  const { url: connectionTestUrl, isLoading } = useUrlWithUtilityBasePath(
    `/tesla-authentication/key-confirmation${vehicleId ? `/${vehicleId}` : ''}`,
  )
  const backToConnectionTest = () => {
    if (connectionTestUrl) {
      navigation.push(connectionTestUrl)
    } else {
      logMessage(
        'NotOwnerPage: attempt to navigate to connection test failed, no url found',
      )
    }
  }
  const onEmailTheOwnerClick = () => {
    navigation.pushToPath('/email')
  }
  const onSkipClick = () => {
    navigation.push(skipToUrl)
  }

  if (isLoading) {
    return null
  }

  return (
    <div>
      <ConfigurableText variant="body4">
        {includeEmail ? PROMPT_WITH_EMAIL : PROMPT_WITHOUT_EMAIL}
      </ConfigurableText>
      {includeEmail && (
        <ConfigurableText variant="link" onClick={onEmailTheOwnerClick}>
          Email The Owner
        </ConfigurableText>
      )}
      <div className="flex items-center justify-center mt-5 mb-5">
        <QRCode
          value={'https://www.tesla.com/_ak/www.optiwatt.com'}
          size={120}
        />
      </div>
      <ConfigurableText variant="h3" className="mb-2">
        <span className="flex items-center gap-2">
          Start connection test <img src={check} width="20" />
        </span>
      </ConfigurableText>
      <ConfigurableText variant="body4">
        After confirming the virtual key was shared, tap 'Start Connection Test'
        to go back and start the connection test.
      </ConfigurableText>
      <ConfigurableText
        variant="link"
        className="mt-2"
        onClick={backToConnectionTest}
      >
        Start Connection Test
      </ConfigurableText>
      <br />
      {includeSkip && (
        <ConfigurableText variant="link" className="mt-2" onClick={onSkipClick}>
          Skip pairing and continue
        </ConfigurableText>
      )}
    </div>
  )
}

export default function NowOwnerNoKeycardPage() {
  const { vehicleId: vehicleIdParam } = useParams<{ vehicleId: string }>()
  const includeSkip = useUrlSearchParam('includeSkip') === 'true'
  const includeEmail = !(useUrlSearchParam('excludeEmail') === 'true')
  const vehicleId = parseInt(vehicleIdParam)

  if (isNaN(vehicleId)) {
    return <Redirect to="/404" />
  }

  return (
    <NavigationPage
      id="now-owner-no-keycard-page"
      title="I don't have keycard to pair"
    >
      <NavigationPage.SingleCardContent>
        <NotOwnerCardContent
          includeSkip={includeSkip}
          vehicleId={vehicleId}
          includeEmail={includeEmail}
        />
      </NavigationPage.SingleCardContent>
    </NavigationPage>
  )
}
