import { Heart } from 'react-feather'
import Piggy from '../../images/simple_piggy.svg?react'
import Solar from '../../images/solar-icon.svg?react'
import { useFeatureFlag } from '@/hooks/useFeatureFlag'
import { AdaptiveModal } from 'app-components'
import React from 'react'
import IconItem from '@/app/components/IconItem/IconItem'
import { AdaptiveModalProps } from '@/app/components/AdaptiveModal'
import { Divider } from '@/components'
import CheckItem from '@/app/components/IconItem/Prefabs/CheckItem'

type ComparePlansDialogProps = AdaptiveModalProps & {
  includeScheduleDeparture: boolean
}

const ComparePlansDialog = (props: ComparePlansDialogProps) => {
  const includeScheduleDeparture = props.includeScheduleDeparture ?? true

  const showSolarPlan = useFeatureFlag({
    flag: 'show_solar_plan',
    experimentalValue: true,
  })

  return (
    <AdaptiveModal title="Compare Plans" {...props}>
      <IconItem
        iconComponent={<Heart style={{ width: 24 }} color="#FB42D2" />}
        title="Save the Planet"
        subtitle="Charge during times of the lowest CO₂ emissions"
      />
      <CheckItem className="mt-2">
        Prioritizes charging when CO₂ emissions are low and postpones charging
        when CO₂ emissions are high
      </CheckItem>
      {includeScheduleDeparture && (
        <CheckItem className="mt-2">
          If Scheduled Departure is turned on, Optiwatt will ensure your charge
          level is reached, but it will optimize charging to keep CO₂ emissions
          at a minimum
        </CheckItem>
      )}
      <Divider className="my-4" />
      <IconItem
        iconComponent={<Piggy height={24} width={24} />}
        title="Save Money"
        subtitle="Charge during cheaper rates"
      />
      <CheckItem className="mt-2">
        Prioritizes charging when electricity rates are low and postpones
        charging when electricity are high
      </CheckItem>
      {includeScheduleDeparture && (
        <CheckItem className="mt-2">
          If Scheduled Departure is turned on, Optiwatt will ensure your charge
          level is reached, but it will optimize charging to keep charging costs
          at a minimum
        </CheckItem>
      )}
      <Divider className="my-4" />
      <IconItem
        iconComponent={
          <>
            <Heart
              height={24}
              width={24}
              style={{ marginRight: 4 }}
              color="#FB42D2"
            />
            <Piggy height={24} width={24} />
          </>
        }
        title="If You Love Your Planet and Money"
        subtitle="Get the best of both plans"
      />
      <CheckItem className="mt-2">
        Strikes a balance between charging during low CO₂ emissions and low
        electricity rates
      </CheckItem>
      <CheckItem className="mt-2">
        Optiwatt will charge when CO₂ emissions are extremely low regardless of
        electricity rates
      </CheckItem>
      {showSolarPlan && (
        <>
          <Divider className="my-4" />
          <IconItem
            iconComponent={<Solar height={24} width={24} />}
            title="Solar Surplus"
            subtitle="Charge when your solar is likely overproducing"
            displayNew
          />
          <CheckItem className="mt-2">
            When Optiwatt detects your solar panels are producing more energy
            than what you're sending back to the grid, we'll charge your EV
          </CheckItem>
        </>
      )}
    </AdaptiveModal>
  )
}

export default ComparePlansDialog
