import { AlertMessage } from '@/app/components'

export const EnrollErrorMessage = ({
  error,
}: {
  error?: string | { detail: string }
}) => {
  if (!error) return null
  const message = typeof error === 'string' ? error : error?.detail || ''
  switch (message) {
    case 'utility_program_id must be set':
      return (
        <AlertMessage variant="error" show>
          <span>
            There was an error enrolling one or more of your vehicles. Please
            try again. If this continues, contact our{' '}
            <a href="mailto:support@optiwatt.com">support team</a> for
            assistance.
          </span>
        </AlertMessage>
      )
    case 'vehicle_id must be set':
      return (
        <AlertMessage variant="error" show>
          <span>
            A vehicle must be selected for enrollment. Please connect a vehicle
            and try again to proceed with the enrollment process.
          </span>
        </AlertMessage>
      )
    case 'Vehicle is already enrolled in this program':
      return (
        <AlertMessage variant="warning" show>
          <span>
            Good news! Your vehicle is already enrolled in this program, so
            there's no further action needed on your part.
          </span>
        </AlertMessage>
      )
    case 'You do not have permission to enroll this vehicle in any programs':
      return (
        <AlertMessage variant="error" show>
          <span>
            Oops! It looks like there's a permission issue enrolling one or more
            of your vehicles. For assistance, please{' '}
            <a href="mailto:support@optiwatt.com">contact support.</a>
          </span>
        </AlertMessage>
      )
    default:
      if (
        message.match(
          /Profile .+ and vehicle .+ is no longer eligible for utility program .+/,
        )
      ) {
        return (
          <AlertMessage variant="error" show>
            <span>
              Oops! It looks like your account or vehicle doesn’t meet the
              enrollment requirements for the utility program.
            </span>
          </AlertMessage>
        )
      } else {
        return (
          <AlertMessage variant="error" show>
            <span>{'An error occurred. Please try again.'}</span>
          </AlertMessage>
        )
      }
  }
}
