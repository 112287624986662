export function getCookie(name: string) {
  const cookieName = `${name}=`
  const decodedCookie = decodeURIComponent(document.cookie)
  const cookieArray = decodedCookie.split(';')
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i]
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1)
    }
    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length)
    }
  }
  return ''
}

export function setCookie(name: string, value: string, days: number) {
  const date = new Date()
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
  const expires = `expires=${date.toUTCString()}`
  document.cookie = `${name}=${value};${expires};path=/`
}

export function setUTMCookies(urlSearchParams: string) {
  const urlParams = new URLSearchParams(urlSearchParams)
  const utmSource = urlParams.get('utm_source')
  const utmMedium = urlParams.get('utm_medium')
  const utmCampaign = urlParams.get('utm_campaign')
  const utmTerm = urlParams.get('utm_term')
  const utmContent = urlParams.get('utm_content')

  if (utmSource) {
    setCookie('optiwatt_utm_source', utmSource, 30)
  }
  if (utmMedium) {
    setCookie('optiwatt_utm_medium', utmMedium, 30)
  }
  if (utmCampaign) {
    setCookie('optiwatt_utm_campaign', utmCampaign, 30)
  }
  if (utmTerm) {
    setCookie('optiwatt_utm_term', utmTerm, 30)
  }
  if (utmContent) {
    setCookie('optiwatt_utm_content', utmContent, 30)
  }
}
