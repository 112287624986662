import './styles.scss'
import MuiGrid from '@mui/material/Grid'
import { GridProps } from '@material-ui/core'
import formatClasses from '../../utils/classes/formatClasses'
import { ForwardedRef, forwardRef } from 'react'

export interface Props extends GridProps, OverrideProps {
  gap?: string
  fullWidth?: boolean
}

// Prevents TypeScript errors for props that technically exist within MUI but aren't detected by TypeScript
interface OverrideProps {
  columns?: number
  columnSpacing?: number | string
  rowSpacing?: number | string
}

export type Ref = ForwardedRef<HTMLDivElement>

function Flex(flexProps: Props, ref?: ForwardedRef<HTMLDivElement>) {
  const { fullWidth, ...props } = flexProps
  const classes = formatClasses([fullWidth ? 'full-width' : undefined])

  return (
    <MuiGrid
      className={classes}
      ref={ref}
      {...props}
      style={{
        ...(props.gap ? { gap: props.gap } : undefined),
        ...props.style,
      }}
    >
      {props.children}
    </MuiGrid>
  )
}

export default forwardRef(Flex)
