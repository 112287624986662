import { FlowStep, Link, NavigationPage } from 'app-components'
import {
  Button,
  CardAction,
  CardActions,
  Flex,
  Modal,
  Text,
  TextField,
} from '@/components'
import React, { useCallback, useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useMockableViewModel } from '@/hooks'
import useHomeEstimationNavigation, {
  HomeEditReasons,
  HomeEstimationPages,
} from '@/app/pages/home-estimations/useHomeEstimationNavigation'
import ImageCarousel from '@/app/components/ImageCarousel/ImageCarousel'

const useViewModel = useMockableViewModel({
  useViewModel() {
    const [exampleModal, setExampleModal] = useState(false)
    const navigate = useHomeEstimationNavigation()

    const onSubmit = async (values: { hvacModelNumber: string }) => {
      navigate.push(HomeEstimationPages.Success, HomeEditReasons.TagSubmission)
    }

    const formik = useFormik({
      initialValues: {
        hvacModelNumber: '',
      },
      validationSchema: yup.object({
        hvacModelNumber: yup.string().required().label('Required'),
      }),
      onSubmit,
    })

    const onClick = useCallback(() => {
      setExampleModal(true)
    }, [])

    const closeModal = useCallback(() => {
      setExampleModal(false)
    }, [])

    const unknownClicked = useCallback(() => {
      navigate.push(HomeEstimationPages.Wizard)
    }, [])

    return {
      exampleModal,
      handleSubmit: formik.handleSubmit,
      isValid: formik.isValid && formik.dirty,
      formValues: formik.values,
      onValueChange: formik.handleChange,
      onClick,
      closeModal,
      unknownClicked,
    }
  },
  useMockViewModel() {
    return {
      exampleModal: false,
      handleSubmit: () => alert('Form submitted!'),
      isValid: false,
      formValues: { hvacModelNumber: '' },
      onClick: () => alert('Clicked!'),
      onValueChange: () => alert('Form field changed'),
      closeModal: () => alert('Modal closed!'),
      unknownClicked: () => alert("'I don't know' clicked!"),
    }
  },
})

export default function HomeHvacModelNoForm(props: any) {
  const {
    exampleModal,
    handleSubmit,
    formValues,
    isValid,
    onClick,
    onValueChange,
    closeModal,
    unknownClicked,
  } = useViewModel()

  return (
    <NavigationPage id="hvac-model-number-form-page" title="HVAC Model number">
      <form style={{ height: '100%' }} onSubmit={handleSubmit}>
        <FlowStep id="hvac-model-number-flow">
          <FlowStep.Content>
            <Text className="font-bold">What is your HVAC model number?</Text>
            <Link to="" onClick={onClick} id="hvac-model-number-example-link">
              See example
            </Link>
            <TextField
              className="!mt-4"
              label="HVAC Model Number"
              id="hvacModelNumber"
              autoFocus
              value={formValues.hvacModelNumber}
              onChange={onValueChange}
            ></TextField>
            <Flex container>
              <Flex item>
                <Button
                  className="mt-4"
                  id="hvac-model-num-idk-btn"
                  variant="chip"
                  onClick={unknownClicked}
                >
                  I don't know
                </Button>
              </Flex>
            </Flex>
          </FlowStep.Content>
          <FlowStep.Actions>
            <CardActions>
              <CardAction type="primary">
                <Button
                  type="submit"
                  disabled={!isValid}
                  id="hvac-success-finish"
                >
                  Submit
                </Button>
              </CardAction>
            </CardActions>
          </FlowStep.Actions>
        </FlowStep>
      </form>
      <Modal
        title="HVAC Information Examples"
        id="hvac-model-number-example-modal"
        open={exampleModal}
        onClose={closeModal}
      >
        Your HVAC's model number might be prefixed with "M/N", "MOD. NO", or
        something similar.
        <div className="mt-4">
          <ImageCarousel
            maxHeight={500}
            srcs={['/img/hvac_example_1.png', '/img/hvac_example_2.png']}
          />
        </div>
      </Modal>
    </NavigationPage>
  )
}
