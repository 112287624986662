import React from 'react'
import { TextField, InputProps } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { GasCarConversion } from '../../types/vehicle/'
import {
  trimName,
  friendlyName as gasConversionFriendlyName,
} from '../../selectors/gasCarConversions'

export interface Props extends InputProps {
  options: GasCarConversion[]
  trimValue: GasCarConversion
  setTrimValue: (conversion: GasCarConversion) => void
  label?: string
  disabled?: boolean
}

export default function TrimSearchbar({
  options,
  trimValue,
  setTrimValue,
  label = 'Select Trim',
  disabled = false,
}: Props) {
  const searchOptions = (() => {
    const optionIds = options.map((option: GasCarConversion) =>
      gasConversionFriendlyName(option),
    )
    const selectedValueId = gasConversionFriendlyName(trimValue)
    return optionIds.includes(selectedValueId)
      ? options
      : [trimValue].concat(options)
  })()

  return (
    <Autocomplete
      disabled={disabled}
      value={trimValue}
      onChange={(event, newValue) => {
        if (newValue) {
          setTrimValue(newValue)
        }
      }}
      id="trim-search"
      options={searchOptions}
      getOptionLabel={(option: GasCarConversion) => {
        return trimName(option) || 'Unnamed'
      }}
      getOptionSelected={(option: GasCarConversion, value: GasCarConversion) =>
        gasConversionFriendlyName(option) === gasConversionFriendlyName(value)
      }
      renderInput={(params) => (
        <TextField {...params} label={label} fullWidth required />
      )}
    />
  )
}
