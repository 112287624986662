import useMockableViewModel from '../../../../hooks/useMockableViewModel'
import { useAppSelector } from '../../../../hooks'
import mockUtilityProgram from '../../../../types/utilityProgram/mock'
import {
  LearnMoreViewConfig,
  UtilityProgram,
} from '../../../../types/utilityProgram'
import {
  selectFirstUtilityProgram,
  selectUtilityProgramLogoUrl,
} from '../../../../reducers/utilityPrograms'

function propsFromViewConfig(viewConfig?: LearnMoreViewConfig) {
  return {
    title: viewConfig?.title ?? '',
    subtitle: viewConfig?.subtitle ?? '',
    buttonTitle: viewConfig?.buttonTitle ?? '',
  }
}

function useViewModel(props: { forcedUtilityProgram?: UtilityProgram }) {
  const appUtilityProgram = useAppSelector(selectFirstUtilityProgram)
  const appUtilityProgramLogo = useAppSelector(selectUtilityProgramLogoUrl)
  // This was done to provide backwards compatibility while rushing through Ava
  // work. In the future it likely makes more sense for this component just to take
  // a utility program
  const utilityProgram = props.forcedUtilityProgram
    ? props.forcedUtilityProgram
    : appUtilityProgram
  const logoUrl = props.forcedUtilityProgram?.logo_url ?? appUtilityProgramLogo

  const viewConfig = utilityProgram?.view_config_json?.learnMore
  return {
    programLogoUrl: logoUrl,
    onLearnMoreClick: () => {},
    utilityProgram: props.forcedUtilityProgram,
    ...propsFromViewConfig(viewConfig),
  }
}

function useMockViewModel(props: { forcedUtilityProgram?: UtilityProgram }) {
  const viewConfig = mockUtilityProgram.view_config_json.learnMore
  return {
    programLogoUrl: mockUtilityProgram.logo_url,
    onLearnMoreClick: () => {},
    utilityProgram: props.forcedUtilityProgram,
    ...propsFromViewConfig(viewConfig),
  }
}

export default useMockableViewModel({ useViewModel, useMockViewModel })
