import { useAppSelector } from '../utils'
import { createResourceSelectors } from '../selectors'
import {
  RequestStatus,
  ResourceCreateActionCreator,
  ResourceModel,
} from '../types'
import {
  createQuerySpecFromCreateArgs,
  createQueryStateForRequestSpecSelector,
} from '../utils'
import { useState } from 'react'

type CreateCreationHookArgs = {
  path?: string
  pathSegments?: string[]
}

/**
 * @todo this hook requires that the resource include the data that
 * is sent in the create request. This is not ideal, for example if we
 * want to create a resource by sending credentials that are then used
 * to pull from another api and fetch the resource, we would need to
 * include those credentials in the resource model.
 *
 * to fix this we could add another generic to the createCreationHook
 * that is the type of the request that is sent to create the resource.
 */
export function createCreationHook<Resource extends ResourceModel>(args: {
  selectors: ReturnType<typeof createResourceSelectors<Resource>>
  create: ResourceCreateActionCreator<Resource>
}) {
  const { selectors, create } = args
  return (args?: CreateCreationHookArgs) => {
    const [dispatchedResource, setDispatchedResource] =
      useState<Partial<Resource>>()

    const querySpec = !dispatchedResource
      ? null
      : createQuerySpecFromCreateArgs(dispatchedResource, args)

    const data = useAppSelector((state) => {
      if (!querySpec) {
        return []
      }
      return selectors.data.selectExactlyMatchingQuerySpec(state, querySpec)
    })
    const queryState = useAppSelector((state) => {
      if (!querySpec) {
        return {
          status: 'idle' as RequestStatus,
          error: null,
        }
      }
      return createQueryStateForRequestSpecSelector(selectors, querySpec)(state)
    })

    const createCreationAction = (resource: Partial<Resource>) => {
      setDispatchedResource(resource)
      return create(resource, args)
    }

    return {
      data,
      status: queryState.status,
      error: queryState.error,
      createAction: createCreationAction,
    }
  }
}
