import { useProfile } from '@/hooks/useProfileId'
import { fetchAuthenticated } from '@/utils/fetch'
import { useState } from 'react'
import { resetTheme } from '@/hooks/whitelabel/useWhitelabelTheme'
import { useAppDispatch } from '@/hooks'
import { getCurrentUser } from '@/actions/user'

export type WhiteLabelUpdateResponse = {
  action: WhiteLabelAction
}

export type WhiteLabelUpdateRequest = {
  action: WhiteLabelAction
  utility_program_id?: number
}

export type WhiteLabelAction = 'add' | 'remove'

export default function useProfileWhiteLabelService() {
  const profile = useProfile()
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useAppDispatch()

  const removeProfileWhiteLabel = async () => {
    if (!profile || profile.utility_white_label === null) {
      return
    }

    setError(null)
    setIsLoading(true)

    const request = {
      action: 'remove',
      utility_program_id: profile.utility_white_label,
    } as WhiteLabelUpdateRequest

    const response = await fetchAuthenticated(
      `white_label_user/${profile.id}`,
      {
        method: 'POST',
        body: JSON.stringify(request),
      },
    )
    setIsLoading(false)

    if (response.status !== 200) {
      setError(response.statusText)
      return
    }

    // Get the user before resetting the theme so the profile's whitelabel_id is reset
    await dispatch(getCurrentUser())

    // Reset the user's theme
    resetTheme()
  }

  return { removeProfileWhiteLabel, error, isLoading }
}
