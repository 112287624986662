import { NavigationPage } from '@/app/components'
import { useNavigation } from '@/app/hooks'
import { Button, Flex, IconDetailListItem, List, Text } from '@/components'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { useEffect, useState } from 'react'
import SuccessImage from './success_car.webp'

export default function AutogridSuccess() {
  const dispatch = useAppDispatch()
  const navigation = useNavigation()
  const isExistingUser = useAppSelector(
    (state) => state.user.metadata?.existingAutogridUser,
  )
  const [listContent, setListContent] = useState([
    {
      title: 'Connect additional devices',
      description:
        'For the best experience with Optiwatt, add all your available devices. If you have no more devices to add, you can go ahead and click continue.',
    },
  ])

  useEffect(() => {
    if (!isExistingUser) {
      setListContent([
        ...listContent,
        {
          title: 'Set your password',
          description:
            'We sent you an email to set your password to your email. This ensures your account security and a smooth start.',
        },
      ])
    }
  }, [isExistingUser])

  const handleContinueClick = async () => {
    navigation.push('/autogrid/enroll')
  }

  return (
    <NavigationPage
      id="autogrid-success-nav-page"
      navigationOptions={['nothing']}
    >
      <NavigationPage.SingleCardContent>
        <div className="flex items-center justify-center w-full md:mt-4">
          <img src={SuccessImage} alt="Success" className="h-36 md:h-48" />
        </div>
        <Text variant="h2" className="!mt-16">
          You've successfully connected your vehicle to Optiwatt
        </Text>
        <Text variant="body2" className="!mt-4 !mb-12">
          You've successfully connected to Optiwatt.
        </Text>
        <List gap="md">
          {listContent.map((item, index) => (
            <Flex key={index} item>
              <IconDetailListItem
                icon={() => (
                  <div className="flex items-center justify-center w-8 h-8 font-bold rounded-lg bg-themed-blue-100 text-themed-blue-500">
                    {index + 1}
                  </div>
                )}
                title={item.title}
                description={item.description}
              />
            </Flex>
          ))}
        </List>
        <Button
          id="partner-confirmation-button"
          variant="primary-subtle"
          className="!mt-12"
          onClick={() => {
            navigation.push('/connect-vehicle?value_prop=false&pre_auth=true')
          }}
        >
          Add another device
        </Button>
        <Button
          id="partner-confirmation-button"
          variant="primary"
          className="!mt-4 mb-6"
          onClick={handleContinueClick}
        >
          Continue
        </Button>
      </NavigationPage.SingleCardContent>
    </NavigationPage>
  )
}
