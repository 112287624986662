import { Vehicle } from '../types/vehicle/'

export function selectedVehicle(vehicles: Vehicle[] | null) {
  const vehiclesArray = vehicles ?? []
  const selected = vehiclesArray?.find((vehicle) => vehicle.is_selected_vehicle)
  return selected ?? null
}

export function isSmartcar(vehicle: Vehicle | null) {
  return !!vehicle?.car?.smartcar_id
}
