type Options = {
  length: number
  filler: string
  direction: 'fromFront' | 'fromBack'
}

const defaultOptions: Options = {
  length: 4,
  filler: '...',
  direction: 'fromFront',
}

export default function abbreviatedString(
  s: string,
  options?: Partial<Options>,
) {
  const { length, filler, direction } = {
    ...defaultOptions,
    ...options,
  }

  if (s.length <= length + filler.length) {
    return s
  }
  if (direction === 'fromBack') {
    return `${filler}${s.slice(s.length - length)}`
  }
  return `${s.slice(0, length)}${filler}`
}
