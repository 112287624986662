import './styles.scss'
import React, { useState } from 'react'
import { Divider, IconButton, Menu, MenuItem } from '@material-ui/core'
import { AlertCircle, MoreHorizontal } from 'react-feather'
import {
  Resource,
  MenuAction,
} from '../../../authenticated/components/ResourceSelectionGallery/types'
import { Sheet, Card, Grid, GridItem, Text, Icon } from '../../../components'
import formatClasses from '../../../utils/classes/formatClasses'
import { ReactNodeProps } from '../../../types/reactNodeProps'
import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'

interface CardActionProps {
  menuActions?: MenuAction[]
}

function CardAction({ menuActions }: CardActionProps): JSX.Element | null {
  if (!menuActions || !menuActions.length) {
    return null
  }

  const classes = {
    button: formatClasses(['selection-gallery-action-button']),
    menu: formatClasses(['selection-gallery-menu']),
    menuItem: formatClasses(['selection-gallery-menu-item']),
    divider: formatClasses(['selection-gallery-menu-divider']),
    text: formatClasses(['header-text']),
  }
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose =
    (action = () => {}) =>
    () => {
      action()
      setAnchorEl(null)
    }

  return (
    <>
      <IconButton
        className={classes.button}
        aria-label="resource options"
        aria-controls={open ? 'menu of resource actions' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreHorizontal />
      </IconButton>
      <Menu
        classes={{
          paper: classes.menu,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose()}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {menuActions.map(({ name, action, disabled }, idx) => (
          <div key={idx}>
            <MenuItem
              className={classes.menuItem}
              onClick={handleClose(action)}
              disabled={disabled}
            >
              <Text className={classes.text} variant="body2">
                {name}
              </Text>
            </MenuItem>
            {idx !== menuActions.length - 1 && (
              <Divider className={classes.divider} />
            )}
          </div>
        ))}
      </Menu>
    </>
  )
}

interface Props extends Resource, ReactNodeProps {}

function SelectionCardVariantContent(props: Props) {
  const image =
    typeof props.image === 'object'
      ? props.image
      : { src: props.image, style: {} }
  const variants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  }

  return (
    <div className="max-h-full">
      <AnimatePresence mode="wait">
        {(props.mode === 'error' || props.mode === 'warning') && (
          <motion.div
            key={props.mode}
            variants={variants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 0.4 }}
          >
            <Icon
              color={'red-500'}
              className={formatClasses(['device-error-icon', props.mode])}
              name={'AlertCircle'}
              size={18}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <CardAction menuActions={props.menuActions} />
      <div
        className={`flex w-full ${!props.menuActions ? 'justify-between' : ''}`}
      >
        <Text
          key={props.mode}
          className={clsx(
            [
              'selection-gallery-text',
              'header',
              props.menuActions ? 'next-to-menu' : '',
            ],
            {
              'text-red-500': props.mode === 'error',
              'text-orange-500': props.mode === 'warning',
            },
          )}
          variant="subheader"
        >
          {props.name}
        </Text>
        {props.mode === 'disabled' && (
          <motion.div className="mb-[-5px]">
            <AlertCircle color="white" fill="#7B7B7B" />
          </motion.div>
        )}
      </div>
      <Text
        className={clsx(['selection-gallery-text', 'subheader'], {
          'text-red-500': props.mode === 'error',
          'text-orange-500': props.mode === 'warning',
        })}
        variant="body2"
      >
        {props.details}
      </Text>
      <img
        className={formatClasses(['selection-gallery-image'])}
        src={image?.src?.replace('.png', '.webp')}
        style={image.style}
        alt={props.name}
      />
    </div>
  )
}

function ActionCardVariantContent(props: Props) {
  const image =
    typeof props.image === 'object'
      ? props.image
      : { src: props.image, style: {} }
  return (
    <div className="flex items-center justify-center h-full">
      <Grid
        flow="row"
        templateRows="minmax(24px, min-content) auto auto"
        placeItems="center"
      >
        <span>
          <Sheet
            color="blue-100"
            style={{
              marginBottom: '8px',
            }}
          >
            <Icon
              name="Plus"
              color="blue-500"
              size={18}
              style={{
                verticalAlign: 'sub',
              }}
            />
          </Sheet>
        </span>
        <Text
          className={formatClasses(['selection-gallery-action-label'])}
          variant="body1"
        >
          {props.details}
        </Text>
        {props.image !== undefined && (
          <GridItem placeSelf="center">
            <img
              className={formatClasses(['selection-gallery-image', 'featured'])}
              src={image?.src?.replace('.png', '.webp')}
              style={image.style}
              alt={props.name}
            />
          </GridItem>
        )}
      </Grid>
    </div>
  )
}

export default function ResourceSelectionGalleryCard(props: Props) {
  const Content =
    props.variant === 'action'
      ? ActionCardVariantContent
      : SelectionCardVariantContent

  return (
    <Card
      className={formatClasses([
        'selection-gallery-card',
        props.selected ? 'selected' : '',
        props.action ? 'clickable' : '',
        props.mode === 'disabled' ? 'disabled' : '',
        props.className,
      ])}
      style={props.style}
      onMouseDown={props.action}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          props.action?.()
        }
      }}
      id={props.id}
    >
      <Content {...props} />
    </Card>
  )
}
