import { OrderDirection, useFaqQuery } from '@/generated/graphql'
import { useHistory, useParams } from 'react-router-dom'
import * as Sentry from '@sentry/browser'
import Faq from '../Faq'

export default function () {
  const { slug } = useParams<{ slug: string }>()
  const history = useHistory()
  const { data, loading, error } = useFaqQuery({
    variables: {
      where: {
        slug: slug,
      },
      orderBy: {
        order: OrderDirection.Asc,
      },
    },
  })

  const questions =
    data?.fAQ?.items?.map((item) => ({
      question: item?.question ?? '',
      answer: item?.answer ?? '',
    })) ?? []

  if (!loading && data?.fAQ === null) {
    // navigate to /404
    history.push('/404')
    return null
  }

  if (!loading && error) {
    // navigate to /404 and log an error to sentry
    Sentry.captureException(error)
    history.push('/404')
    return null
  }

  return (
    <Faq
      loading={loading}
      subtitle={data?.fAQ?.subtitle}
      name={data?.fAQ?.slug ?? ''}
      questions={questions}
      title={data?.fAQ?.title}
    />
  )
}
