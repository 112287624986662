import './styles.scss'
import formatClasses from '../../../../../utils/classes/formatClasses'
import { Grid, Text } from '../../../../../components'
import dayjs from 'dayjs'
import { Charge } from '@/types/charges'

export interface Props {
  charge: Charge
}

export default function Battery(props: Props) {
  const classes = {
    battery: formatClasses(['battery']),
    batteryCharge: formatClasses(['battery-charge']),
    batteryPercentage: formatClasses(['battery-percentage']),
    batteryTerminal: formatClasses(['battery-terminal']),
    chargeDiagram: formatClasses(['charge-diagram']),
    chargeTimes: formatClasses(['charge-times']),
    chargeSnapshot: formatClasses(['charge-snapshot']),
    existingChargeLevel: formatClasses(['existing-charge-level']),
    recentCharge: formatClasses(['recent-charge']),
  }

  const startBatteryPercent = props.charge.start_battery_percent ?? 0
  const endBatteryPercent = props.charge.end_battery_percent ?? 0
  // Width of the battery (pixels)
  const batteryWidth = 225
  // Width of the existing battery level (pixels)
  const existingChargeLevel = batteryWidth * (startBatteryPercent / 100)
  // Amount of charge that was added to the battery (percent)
  const chargePercent = (endBatteryPercent - startBatteryPercent) / 100
  // Width of the charge that was added (pixels)
  const recentCharge = batteryWidth * chargePercent
  // Width of the start/end percent text with a lower bound (matches clamp in CSS)
  const chargePercentDetailsWidth = Math.max(batteryWidth * 0.3, recentCharge)
  // Left offset for the start/end percent
  const chargingDetailsPosition = Math.round(
    existingChargeLevel +
      recentCharge -
      0.5 * recentCharge -
      0.5 * chargePercentDetailsWidth,
  )
  // Width of the start/end time with a lower bound (matches clamp in CSS)
  const chargeTimeWidth = Math.max(batteryWidth * 0.45, recentCharge)
  // Left offset for the start/end time
  const chargeTimePosition = Math.round(
    existingChargeLevel +
      recentCharge -
      0.5 * recentCharge -
      0.5 * chargeTimeWidth,
  )

  return (
    <Grid className={classes.chargeSnapshot} placeContent="center">
      <Grid className={classes.chargeDiagram} flow="row" gap="4px">
        <Grid
          className={classes.batteryPercentage}
          style={{
            left: `clamp(0%, calc(${chargingDetailsPosition}px), 73%)`,
            width: `max(30%, calc(${chargePercentDetailsWidth}px))`,
          }}
          templateRows="repeat(2, auto)"
        >
          <Grid
            gap="5px"
            placeContent="space-between"
            templateColumns="35px 35px"
          >
            <Text className="charge-start-label text-themed-grey-500">
              Start
            </Text>
            <Text className="charge-end-label text-themed-grey-500">End</Text>
          </Grid>
          <Grid
            gap="5px"
            placeContent="space-between"
            templateColumns="35px 35px"
          >
            <Text className="charge-start-percent text-themed-grey-500">
              {`${props.charge.start_battery_percent}%`}
            </Text>
            <Text className="charge-end-percent text-themed-grey-500">
              {`${props.charge.end_battery_percent}%`}
            </Text>
          </Grid>
        </Grid>
        <Grid className={classes.battery} placeItems="center">
          <div className={classes.batteryCharge}>
            <div
              className={classes.existingChargeLevel}
              style={{
                width: `calc(var(--battery-width) * ${
                  startBatteryPercent / 100
                })`,
              }}
            />
            <div
              className={classes.recentCharge}
              style={{
                width: `calc(var(--battery-width) * ${
                  endBatteryPercent / 100 - startBatteryPercent / 100
                })`,
              }}
            />
          </div>
          <div className={classes.batteryTerminal} />
        </Grid>
        <Grid
          className={classes.chargeTimes}
          style={{
            left: `clamp(0%, calc(${chargeTimePosition}px), 60%)`,
            width: `max(45%, calc(${chargeTimeWidth}px))`,
          }}
          templateRows="repeat(2, auto)"
        >
          <Grid gap="5px" placeContent="space-between">
            <Text className="charge-start-time text-themed-grey-500">
              {dayjs(props.charge.start_time).format('h:mm A')}
            </Text>
            <Text className="charge-end-time text-themed-grey-500">
              {dayjs(props.charge.end_time).format('h:mm A')}
            </Text>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
