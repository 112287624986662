import { useAppSelector } from '@/hooks/index'
import { Profile } from '@/types/user'

export default function useProfileId() {
  return useAppSelector((state) => state.user.user?.profile?.id ?? null)
}

export function useProfile(): Profile | null {
  return useAppSelector((state) => state.user.user?.profile ?? null)
}
