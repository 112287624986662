import {
  Card,
  Chip,
  ChipGroup,
  DataListItem,
  ExpansionPanel,
  Divider,
  List,
  Sheet,
  Skeleton,
  Tab,
  TabGroup,
  Text,
  Grid,
  GridItem,
  Button,
  Icon,
  Modal,
} from '../../../../components'
import { HomeScheduleBar, TemperatureBar } from '../index'
import {
  ThermostatDailySchedule,
  ThermostatSchedule,
} from '@/types/thermostatSchedule'
import { ThermostatComfortSetting } from '@/types/thermostatComfortSetting'
import { toDegrees, toDollars } from '../../../../utils/string'
import './styles.scss'

export type ThermostatDailySchedulesWithDisplayValues = {
  dailySchedules: ThermostatDailySchedule[]
  comfortSettingCosts: {
    comfortSetting: ThermostatComfortSetting
    cost: number
  }[]
  totalCost: number
}

export interface Props extends ThermostatSchedule {
  // No additional props provided
  schedules: ThermostatDailySchedulesWithDisplayValues[]
  onChipGroupClick?: (index: number | null) => void
  loading: boolean
}

function formatDollars(dollars: number, decimals = 2) {
  return toDollars(dollars, { format: 'dollars', decimals: decimals })
}

const SheetSkeleton = () => (
  <Skeleton animation="wave" variant="text" width={34} height={26} />
)

const TemperatureSkeleton = () => (
  <Skeleton animation="wave" variant="text" width={25} height={18} />
)

const TemperatureBarSkeleton = () => (
  <Skeleton animation="wave" variant="text" width={137} height={18} />
)

function ComfortSettingPanelLabel(props: {
  label: string
  heat: number
  cool: number
  cost: number
  loading: boolean
}) {
  return (
    <Grid fluid placeItems="center" placeContent="space-between">
      <Text>{props.label}</Text>
      <Grid
        placeItems="center start"
        templateColumns="1fr auto"
        style={{
          fontVariantNumeric: 'tabular-nums',
          width: '175px',
        }}
      >
        <Grid gap="10px" templateColumns="26px 2px 26px">
          <Text className="text-themed-red-500">
            {props.loading ? <TemperatureSkeleton /> : toDegrees(props.heat)}
          </Text>
          <Divider orientation="vertical" />
          <Text className="text-themed-blue-500">
            {props.loading ? <TemperatureSkeleton /> : toDegrees(props.cool)}
          </Text>
        </Grid>
        {props.loading ? (
          <SheetSkeleton />
        ) : (
          <GridItem>
            <Sheet color="green-100">
              <Text className="text-themed-green-900">
                {formatDollars(props.cost)}
              </Text>
            </Sheet>
          </GridItem>
        )}
      </Grid>
    </Grid>
  )
}

function DayOfWeekPanelLabel(props: { cost: number; loading: boolean }) {
  return (
    <Grid placeItems="center" gap="8px">
      Daily Cost
      {props.loading ? (
        <SheetSkeleton />
      ) : (
        <Sheet color="green-100">
          <Text className="text-themed-green-900">
            {formatDollars(props.cost)}
          </Text>
        </Sheet>
      )}
    </Grid>
  )
}

function SettingUsageListItem(props: {
  label: string
  duration: string
  cost: number
}) {
  return (
    <DataListItem label={props.label}>
      <Grid
        // templateColumns="75px 50px"
        templateColumns="50px"
      >
        {/* <Grid
          gap="8px"
          placeItems="center start"
          templateColumns="auto 1fr"
        >
          <Icon
            name="Clock"
            color="grey-400"
            size={16}
          />
          <Text>
            {props.duration}
          </Text>
        </Grid> */}
        <GridItem placeSelf="end">
          <Text>{formatDollars(props.cost)}</Text>
        </GridItem>
      </Grid>
    </DataListItem>
  )
}

function SettingCostListItem(props: {
  label: string
  heat: number
  cool: number
  cost: number
}) {
  return (
    <DataListItem label={props.label}>
      <Grid
        templateColumns="1fr auto"
        style={{
          fontVariantNumeric: 'tabular-nums',
          width: '150px',
        }}
      >
        <Grid gap="10px" templateColumns="26px 2px 26px">
          <Text className="text-themed-red-500">{toDegrees(props.heat)}</Text>
          <Divider orientation="vertical" />
          <Text className="text-themed-blue-500">{toDegrees(props.cool)}</Text>
        </Grid>
        <GridItem>{formatDollars(props.cost)}</GridItem>
      </Grid>
    </DataListItem>
  )
}

function EstimateCostFactorsModal(props: { open?: boolean }) {
  return (
    <Modal
      open={props.open}
      title="Estimated Cost Factors"
      id="thermostat-schedule-estimate-cost-factors-modal"
    >
      <Modal.Activator>
        <Button
          variant="icon"
          id="thermostat-schedule-estimate-cost-factors-info-button"
        >
          <Icon color="grey-400" name="Info" size={16} />
        </Button>
      </Modal.Activator>
      <ul>
        <li>Home sq. footage, installation, and location</li>
        <li>Inside and outside temperature and humidity</li>
        <li>Thermostat set point and power</li>
        <li>Electricity prices</li>
      </ul>
    </Modal>
  )
}

function getNext12Months() {
  const calendarMonths = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const date = new Date()
  const months = []

  // "Current" month is set to 2 months from now to account for `This Month` and `Next Month` as options
  date.setMonth(date.getMonth() + 2)
  let month = date.getMonth()

  for (let i = 0; i < 10; i++) {
    months.push(date.getMonth())
    month++
    date.setMonth(month)
  }

  return months.map((index) => calendarMonths[index])
}

export default function HomeScheduleCard(props: Props) {
  return (
    <Card>
      <Grid gap="24px" flow="row">
        <Text variant="h3">Home Schedule</Text>
        <Grid gap="32px" flow="row">
          <ChipGroup
            disabled={props.loading}
            centered
            required
            scrollable
            onClick={props.onChipGroupClick}
          >
            <Chip>
              <Text>This Month</Text>
            </Chip>
            <Chip>
              <Text>Next Month</Text>
            </Chip>
            {getNext12Months().map((month, index) => {
              return (
                <Chip key={index}>
                  <Text>{month}</Text>
                </Chip>
              )
            })}
          </ChipGroup>
          <TabGroup centered>
            <Tab title="Comfort Setting">
              <Grid flow="row" gap="12px">
                <List>
                  <DataListItem label="Avg. Outside Temp">
                    <Grid>
                      {props.loading ? (
                        <TemperatureBarSkeleton />
                      ) : (
                        <TemperatureBar
                          high={props.avg_high_temp_f}
                          low={props.avg_low_temp_f}
                          max={props.max_temp_f}
                          min={props.min_temp_f}
                        />
                      )}
                    </Grid>
                  </DataListItem>
                </List>
                <Grid flow="row" gap="24px">
                  <Grid flow="row" gap="20px">
                    {(props.comfort_settings || []).map((setting, index) => {
                      return (
                        <ExpansionPanel
                          disabled={props.loading}
                          key={index}
                          label={
                            <ComfortSettingPanelLabel
                              loading={props.loading}
                              label={setting.name}
                              heat={setting.heat_setpoint}
                              cool={setting.cool_setpoint}
                              cost={setting.total_cost || 0}
                            />
                          }
                        >
                          <List bordered>
                            {(setting.schedules || []).map(
                              (schedule, index) => {
                                return (
                                  <SettingUsageListItem
                                    cost={schedule.cost}
                                    duration={`${schedule.duration}h`}
                                    key={index}
                                    label={schedule.label}
                                  />
                                )
                              },
                            )}
                            <DataListItem label="Monthly Cost Est.">
                              <Sheet color="green-100">
                                <Text className="text-themed-green-900">
                                  {formatDollars(setting.total_cost || 0)}
                                </Text>
                              </Sheet>
                            </DataListItem>
                          </List>
                        </ExpansionPanel>
                      )
                    })}
                  </Grid>
                  <Divider orientation="horizontal" />
                </Grid>
                <List>
                  <DataListItem label="This Month Total">
                    <Grid gap="8px" placeItems="center">
                      {props.loading ? (
                        <SheetSkeleton />
                      ) : (
                        <Sheet color="green-100">
                          <Text className="text-themed-green-900">
                            {formatDollars(props.total_cost)}
                          </Text>
                        </Sheet>
                      )}
                      <EstimateCostFactorsModal />
                    </Grid>
                  </DataListItem>
                </List>
              </Grid>
            </Tab>
            <Tab title="Day of Week">
              <Grid gap="24px" flow="row">
                <List>
                  <DataListItem label="Avg. Outside Temp">
                    <Grid>
                      {props.loading ? (
                        <TemperatureBarSkeleton />
                      ) : (
                        <TemperatureBar
                          high={props.avg_high_temp_f}
                          low={props.avg_low_temp_f}
                          max={props.max_temp_f}
                          min={props.min_temp_f}
                        />
                      )}
                    </Grid>
                  </DataListItem>
                </List>
                <Grid flow="row" gap="48px">
                  {props.schedules.map((schedule, index) => {
                    return (
                      <Grid flow="row" gap="24px" key={index}>
                        <HomeScheduleBar
                          schedule={schedule.dailySchedules}
                          settings={props.comfort_settings}
                        />
                        <ExpansionPanel
                          disabled={props.loading}
                          label={
                            <DayOfWeekPanelLabel
                              cost={schedule.totalCost}
                              loading={props.loading}
                            />
                          }
                        >
                          <List bordered>
                            {schedule.comfortSettingCosts.map(
                              ({ comfortSetting, cost }, index) => (
                                <SettingCostListItem
                                  key={index}
                                  cost={cost}
                                  cool={comfortSetting.cool_setpoint}
                                  label={comfortSetting.name}
                                  heat={comfortSetting.heat_setpoint}
                                />
                              ),
                            )}
                            <DataListItem label="Daily Cost">
                              {props.loading ? (
                                <SheetSkeleton />
                              ) : (
                                <Sheet color="green-100">
                                  <Text className="text-themed-green-900">
                                    {formatDollars(schedule.totalCost)}
                                  </Text>
                                </Sheet>
                              )}
                            </DataListItem>
                          </List>
                        </ExpansionPanel>
                      </Grid>
                    )
                  })}
                </Grid>
                <Grid gap="12px" flow="row">
                  <Divider orientation="horizontal" />
                  <List>
                    <DataListItem label="This Month Total">
                      <Grid gap="8px" placeItems="center">
                        {props.loading ? (
                          <SheetSkeleton />
                        ) : (
                          <Sheet color="green-100">
                            <Text className="text-themed-green-900">
                              {formatDollars(props.total_cost)}
                            </Text>
                          </Sheet>
                        )}
                        <EstimateCostFactorsModal />
                      </Grid>
                    </DataListItem>
                  </List>
                </Grid>
              </Grid>
            </Tab>
          </TabGroup>
        </Grid>
      </Grid>
    </Card>
  )
}
