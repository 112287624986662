import React, { useEffect } from 'react'
import { Grid as MuiGrid, GridProps } from '@material-ui/core'
import { useAppSelector, useAppDispatch } from '../../hooks'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import { Vehicle } from '../../types/vehicle'
import { DateRepresentation } from '../../types/dates'
import { DateSelectFilter, VehicleSelectFilter } from './SelectFilter'
import { DateRange } from './SelectFilter/DateSelectFilter'
import { ChargesParams } from '../../types/charges'
import { getCharges } from '../../actions/metric'
import { ID } from '../../types/model'
import dayjs from 'dayjs'

const Grid = styled(MuiGrid)(spacing)

export interface DashboardFilterParams {
  vehicle?: Vehicle
  start?: DateRepresentation | null
  end?: DateRepresentation | null
}

export interface Props extends GridProps {
  onFilterChange?: (params: DashboardFilterParams) => void
  filters?: ('vehicle' | 'date')[]
  vehicleId?: ID
}

export default function DashboardChargesFilterGroup({
  onFilterChange,
  filters,
  vehicleId,
  ...rest
}: Props) {
  const dispatch = useAppDispatch()
  const loading = useAppSelector((state) => state.metric.isFetchingCharges)
  const charges = useAppSelector((state) => state.metric.charges)
  const chargesAreLoading = useAppSelector(
    (state) => state.metric.isFetchingCharges,
  )
  const chargesFailed = useAppSelector((state) => state.metric.chargesFailed)

  const updateCharges = (params: ChargesParams = {}) => {
    const dateFormat = 'YYYY-MM-DDTHH:mm:ssZ'
    const chargesParams = {
      page: params.page ?? 1,
      start_time_after:
        'start_time_after' in params
          ? params.start_time_after
          : charges?.start_date,
      start_time_before:
        'start_time_before' in params
          ? params.start_time_before
          : charges?.end_date,
      vehicle_id:
        vehicleId ??
        ('vehicle_id' in params ? params.vehicle_id : charges?.vehicle_id),
    }

    // we dont want to update the charges if the vehicle is not present, so we don't risk
    // sending a request to get charges that are inconsistent with the vehicle filter value
    if (!chargesParams.vehicle_id) {
      return
    }

    dispatch(
      getCharges(
        chargesParams.page,
        chargesParams.start_time_after
          ? dayjs(chargesParams.start_time_after)
              .startOf('day')
              .format(dateFormat)
          : null,
        chargesParams.start_time_before
          ? dayjs(chargesParams.start_time_before)
              .endOf('day')
              .format(dateFormat)
          : null,
        chargesParams.vehicle_id,
      ),
    )
  }

  useEffect(() => {
    // if we have no charges and we arent fetching them,
    // or if we have a vehicleId and it doesnt match the charges vehicle_id
    // then we need to update the charges
    if (
      (!charges && !chargesAreLoading && !chargesFailed) ||
      (vehicleId && vehicleId !== charges?.vehicle_id)
    ) {
      updateCharges()
    }
  }, [vehicleId])

  const handleSelectedDateRange = (dateRange: DateRange) => {
    updateCharges({
      start_time_after: dateRange.start,
      start_time_before: dateRange.end,
    })
    onFilterChange?.({ ...dateRange })
  }
  const handleSelectedVehicle = (vehicle: Vehicle) => {
    updateCharges({ vehicle_id: vehicle.id })
    onFilterChange?.({ vehicle })
  }

  if (!filters?.length) {
    return null
  }

  return (
    <Grid container {...rest}>
      {filters?.includes('date') && (
        <Grid item>
          <DateSelectFilter
            handleSelectedDateRange={handleSelectedDateRange}
            disabled={loading}
          />
        </Grid>
      )}
      {filters?.includes('vehicle') && (
        <Grid item>
          <VehicleSelectFilter
            handleSelectedVehicle={handleSelectedVehicle}
            disabled={loading}
          />
        </Grid>
      )}
    </Grid>
  )
}
