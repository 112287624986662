import './styles.scss'
import { ReactNodeProps } from '../../../types/reactNodeProps'
import formatClasses from '../../../utils/classes/formatClasses'
import { Flex, Grid, Text } from '../../../components'
import { useWhiteLabelData } from '@/hooks/whitelabel/useWhiteLabelData'

export interface Props extends ReactNodeProps {
  title: string | JSX.Element
  gap?: string
}

const defaultProps: Partial<Props> = {
  gap: '24px',
}

export default function ColumnedCardLayout({
  children,
  className,
  ...props
}: Props) {
  const classes = {
    root: formatClasses(['columned-card-layout', className]),
    content: formatClasses(['content']),
  }

  return (
    <Flex
      container
      justifyContent="center"
      alignItems="flex-start"
      className={classes.root}
    >
      <Grid
        gap={props.gap ?? defaultProps.gap}
        flow="row"
        templateRows="auto 1fr"
        templateColumns="minmax(0px, 600px)"
        className={classes.content}
      >
        <Text variant="h2">{props.title}</Text>
        <Flex
          container
          direction="column"
          gap={props.gap ?? defaultProps.gap}
          style={{ maxWidth: '600px', width: '100%' }}
        >
          {children}
        </Flex>
      </Grid>
    </Flex>
  )
}
