import styled from 'styled-components'

import { Box as MuiBox } from '@material-ui/core'

import { spacing } from '@material-ui/system'
import { makeStyles } from '@material-ui/styles'
import UtilitySetupCard from './UtilitySetupCard'
import { Calendar } from 'react-feather'
import BillingStartDaySelectionPrompt from './BillingStartDaySelectionPrompt'
import { UsageSectionSubtitleTypography } from '../UsageSectionTypography'

const Box = styled(MuiBox)(spacing)

const useStyles = makeStyles((theme) => ({
  selectText: {
    display: 'inline-block',
    fontSize: '0.75rem',
  },
  inlineSelector: {
    display: 'inline-block',
    fontSize: '0.75rem',
    margin: '0 4px',
  },
  daySelectorButton: {
    padding: '0 0.5rem',
    borderRadius: '10px',
  },
}))

const BillingStartSetup = ({
  billingStartDay,
  setBillingStartDay,
  notificationNumber,
  selectedVehicle,
}) => {
  const classes = useStyles()

  const friendlyCarName =
    selectedVehicle?.car?.car_model?.friendly_name || 'car'

  return (
    <UtilitySetupCard
      header="Select Your Billing Cycle"
      subHeader={`Get an energy usage breakdown between your ${friendlyCarName} and home.`}
      Icon={Calendar}
      notificationNumber={notificationNumber}
    >
      <Box px={{ xs: 4 }}>
        <UsageSectionSubtitleTypography>
          What day does your billing cycle start?
        </UsageSectionSubtitleTypography>
        <BillingStartDaySelectionPrompt
          billingStartDay={billingStartDay}
          setBillingStartDay={setBillingStartDay}
        />
      </Box>
    </UtilitySetupCard>
  )
}

export default BillingStartSetup
