import './styles.scss'
import { HTMLAttributes } from 'react'
import { Bar, Grid, Text } from '../../../../components'
import formatClasses from '../../../../utils/classes/formatClasses'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  step: number
  numberOfSteps: number
}

export default function OnboardingProgressBar(props: Props) {
  const classes = {
    progressBar: formatClasses(['onboarding-progress-bar']),
    progress: formatClasses(['onboarding-progress']),
  }

  return (
    <Grid gap="24px" placeItems="center start" alignItems="center">
      <Text
        color="blue-500"
        style={{
          fontVariantNumeric: 'tabular-nums',
        }}
      >
        {`${props.step} of ${props.numberOfSteps} complete`}
      </Text>
      <Bar className={classes.progressBar}>
        <Bar
          className={classes.progress}
          style={{
            background: 'var(--color-blue-500)',
            width: `clamp(2%, calc(100% * ${props.step} / ${props.numberOfSteps}), 100%)`,
          }}
        />
      </Bar>
    </Grid>
  )
}
