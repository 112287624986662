import formatClasses from '../../utils/classes/formatClasses'
import './styles.scss'
import { Flex } from '../index'
import { ReactNodeProps } from '../../types/reactNodeProps'

export interface Props extends ReactNodeProps {
  bordered?: boolean
  gap?: 'md'
}

const gapMap = {
  md: '48px',
}

export default function List(props: Props) {
  const classes = formatClasses([
    props.bordered ? 'bordered-list list-none' : undefined,
    props.className,
  ])

  const gap = props.gap ? gapMap[props.gap] : undefined

  return (
    <Flex className={classes} container direction="column" gap={gap}>
      {props.children}
    </Flex>
  )
}
