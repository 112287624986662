import { QuestionnairePageValue } from '@/app/components'
import { useSurveyResponder } from '@/app/components/SurveyObserver'
import useMockableViewModel from '@/hooks/useMockableViewModel'
import { useHistory } from 'react-router-dom'
import {
  OnboardingQuestionnairePageKey,
  pages,
} from './OnboardingQuestionnaire'
import { Survey } from '@/types'
import { satisfies as versionSatisfies } from 'semver'
import { computeRedirectFromResponses } from './utils'
import { useUrlSearchParam } from '@/hooks'
import { remoteConfig } from '@/firebase'
import { useState } from 'react'
import { surveyResponsesCollection } from '@/reducers/surveyResponses'

interface Props {}

type FormatFunc = (response: QuestionnairePageValue) => unknown
const responseToSurveyFormattedValueMap: {
  [key in OnboardingQuestionnairePageKey]: FormatFunc
} = {
  numberOfEVs(response) {
    return response.value[0]
  },
  awaitingEVDelivery(response) {
    return {
      awaitingDelivery: response.value[0],
      deliveryDate: response.value[1] ?? null,
    }
  },
  ownsSmartCharger(response) {
    return response.value[0]
  },
  ownsSmartThermostat(response) {
    return response.value[0]
  },
  homeAddress: function (response: QuestionnairePageValue): unknown {
    return response.value[0]
  },
}

export function formatResponsesForSurvey(responses: QuestionnairePageValue[]) {
  return responses.reduce((acc, response) => {
    return {
      ...acc,
      [response.key]:
        responseToSurveyFormattedValueMap[
          response.key as OnboardingQuestionnairePageKey
        ](response),
    }
  }, {} as Record<string, unknown>)
}

function shouldIncludeChargerQuestion(survey: Survey | undefined) {
  if (!survey) {
    return false
  }
  return versionSatisfies(survey.version, '>=1.1.0 <=2.0.0')
}

function useQuestionsToSkip() {
  const pagesToSkipParam = useUrlSearchParam('questionsToSkip')
  if (!pagesToSkipParam) {
    return []
  }
  return pagesToSkipParam.split(',') as OnboardingQuestionnairePageKey[]
}

function useViewModel(props: Props) {
  const history = useHistory()
  const { submitResponse, activeSurveyResponse } =
    useSurveyResponder('Onboarding')
  const { data, isLoading } = surveyResponsesCollection.useFetch()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const questionsToSkip = useQuestionsToSkip()
  const avaId = remoteConfig?.getWhitelabelId('ava') ?? null

  async function onNextClick(responses: QuestionnairePageValue[]) {
    const formattedResponses = formatResponsesForSurvey(responses)
    await submitResponse(
      { ...activeSurveyResponse?.response, ...formattedResponses },
      false,
    )
  }

  async function onSubmit(responses: QuestionnairePageValue[]) {
    const formattedResponses = formatResponsesForSurvey(responses)
    setIsSubmitting(true)
    await submitResponse(formattedResponses)
    setIsSubmitting(false)
    const base_path = history.location.pathname.split('/')[1]
    if (base_path === 'onboarding') {
      const redirect = computeRedirectFromResponses(responses, null)
      history.push(redirect)
      return
    }
    const redirect = computeRedirectFromResponses(responses, avaId, base_path)
    history.push(redirect)
  }

  const includeChargerQuestion = shouldIncludeChargerQuestion(
    activeSurveyResponse?.survey,
  )

  const allProps = {
    ...props,
    includeChargerQuestion: true,
    onSubmit,
    onNextClick,
    setShowSkipModalId: () => {},
    showSkipModalId: null,
    questionsToSkip: questionsToSkip,
    isLoading,
    isSubmitting,
  }

  const pagesToShow = pages(allProps).filter((page) => {
    const pageValue = activeSurveyResponse?.response?.[
      page.key
    ] as QuestionnairePageValue['value']
    const shouldShowArgs = [{ ...page, value: pageValue }]
    return page.shouldShow?.(shouldShowArgs) !== false
  })
  const pageNumber = pagesToShow.findIndex(
    (page) => activeSurveyResponse?.response?.[page.key] === undefined,
  )
  return { ...allProps, pageNumber: pageNumber < 0 ? 0 : pageNumber }
}

function useMockViewModel(props: Props) {
  async function onSubmit(responses: QuestionnairePageValue[]) {
    console.log('Next route')
  }

  async function onNextClick(responses: QuestionnairePageValue[]) {
    console.log('Next route')
  }

  // todo: remove these static values to appease the type checker
  return {
    ...props,
    includeChargerQuestion: true,
    onSubmit,
    onNextClick,
    setShowSkipModalId: () => {},
    showSkipModalId: null,
    questionsToSkip: [],
    pageNumber: 0,
    isLoading: false,
    isSubmitting: false,
  }
}

export default useMockableViewModel({ useViewModel, useMockViewModel })
