import { Action } from '../../../reducers/utils'
import { ActionType } from './actions'

interface State {
  dialogOpen: boolean
  dialogConfirmationPending: boolean
}

const initialState: State = {
  dialogOpen: false,
  dialogConfirmationPending: false,
}

const setDialogOpen = (state: State, action: Action) => ({
  ...state,
  dialogOpen: action.payload,
})
const setDialogConfirmationPending = (state: State, action: Action) => ({
  ...state,
  dialogConfirmationPending: action.payload,
})

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.SetDialogOpen:
      return setDialogOpen(state, action)
    case ActionType.SetDialogConfirmationPending:
      return setDialogConfirmationPending(state, action)
    default:
      return state
  }
}
