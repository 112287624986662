import React from 'react'
import { useAppSelector } from '../../../hooks'
import { selectedVehicle as getSelectedVehicle } from '../../../selectors/vehicles'
import { Vehicle } from '../../../types/vehicle/'
import SelectFilter from './SelectFilter'

const UnnamedCarName = 'Unnamed Car'

function labelForVehicleFitler(vehicle: Vehicle): string {
  const friendlyName = vehicle.car.car_model?.friendly_name ?? UnnamedCarName
  return vehicle.car.display_name
    ? `${vehicle.car.display_name} • ${friendlyName}`
    : friendlyName
}

export interface Props {
  disabled?: boolean
  handleSelectedVehicle: (vehicle: Vehicle) => void
}

export default function VehicleSelectFilter({
  disabled,
  handleSelectedVehicle,
}: Props) {
  const vehicles: Vehicle[] = useAppSelector(
    (state) => state.vehicles.vehicles ?? [],
  )
  const platformSelectedVehicle = useAppSelector((state) =>
    getSelectedVehicle(state.vehicles.vehicles),
  )

  const handleSelectedValue = (selectedValue: number) => {
    const vehicle = vehicles.find((vehicle) => vehicle.id === selectedValue)
    if (vehicle) {
      handleSelectedVehicle(vehicle)
    }
  }

  return (
    <SelectFilter
      defaultId={platformSelectedVehicle?.id}
      options={vehicles.map((vehicle) => ({
        id: vehicle.id,
        label: labelForVehicleFitler(vehicle),
        selectedLabel:
          vehicle.car.display_name ||
          (vehicle.car.car_model?.friendly_name ?? UnnamedCarName),
        value: vehicle.id,
      }))}
      onSelectedValue={handleSelectedValue}
      disabled={disabled}
    />
  )
}
