import { UsageComparison } from '@/types'
import * as actions from '../../actions/usages'
import { getApiErrorMessage } from '../../utils/errorUtilities'
import { ID } from '@/types/model'
import { PayloadAction } from '@reduxjs/toolkit'

type State = {
  usageComparison: UsageComparison | null
  chargesComparison: UsageComparison<{ vehicle_id?: ID }> | null
  isLoadingUsageComparison: boolean
  isLoadingChargesComparison: boolean
  usageErrors: unknown
  chargesErrors: unknown
}

const initialState: State = {
  usageComparison: null,
  chargesComparison: null,
  isLoadingUsageComparison: false,
  isLoadingChargesComparison: false,
  usageErrors: {},
  chargesErrors: {},
}

export default (state = initialState, action: PayloadAction<any>): State => {
  switch (action.type) {
    case actions.USAGE_COMPARISON_SUCCESS:
      return {
        ...state,
        usageComparison: action.payload,
        isLoadingUsageComparison: false,
        usageErrors: {},
      }
    case actions.USAGE_COMPARISON_REQUEST:
      return {
        ...state,
        isLoadingUsageComparison: true,
      }
    case actions.USAGE_COMPARISON_FAILURE:
      return {
        ...state,
        isLoadingUsageComparison: false,
        usageErrors: getApiErrorMessage(action.payload),
      }
    case actions.CHARGES_COMPARISON_SUCCESS:
      return {
        ...state,
        chargesComparison: action.payload,
        isLoadingChargesComparison: false,
      }
    case actions.CHARGES_COMPARISON_REQUEST:
      return {
        ...state,
        isLoadingChargesComparison: true,
      }
    case actions.CHARGES_COMPARISON_FAILURE:
      return {
        ...state,
        isLoadingChargesComparison: false,
        chargesErrors: getApiErrorMessage(action.payload),
      }
    default:
      return state
  }
}
