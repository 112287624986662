import { getColors } from '../Graph'
import { hexToRGB } from '../../../utils/colorUtilities'
import { isMobileDevice } from '../../../utils/sizeUtilities'
import {
  addDays,
  getDifferenceMinutes,
  getHoursBetweenDates,
  subtractMinutes,
} from '../../../utils/timeUtilities'
import { logException } from '../../../utils/exceptionUtilities'
import { KILOMETERS, MILES } from '../dialogs/TripDialog'
import { useDispatch, useSelector } from 'react-redux'
import { convertPoundsToKilograms } from '../../../utils/conversionUtilities'
import useIsMobile from '../../hooks/useIsMobile'

const blue = '#2296F3'
const green = '#8CBA00'
const grey = '#78909c'
const red = '#F44336'

const ELECTRICITY_RATE_LABEL = 'Electricity Rate'
const MOER_LABEL = 'Carbon Emissions'
const STANDARD_TYPE = 'start_standard_metric'
const CHEAPEST_RATE_TYPE = 'start_cheapest_rate_metric'
const SMART_CHARGE_TYPE = 'start_smart_charge_metric'
const SCHEDULE_START_TIME_LABEL = 'schedule_start_time_utc'
const DEPARTURE_TIME_LABEL = 'departure_time_utc'

export const STANDARD_LABELS = {
  charge: 'Normal Charge',
  shading: 'Normal Shading',
  miles: 'Normal Miles',
  kilometers: 'Normal Kilometers',
  cost: 'Normal Cost',
  type: STANDARD_TYPE,
  periodType: 'standard',
  color: green,
}

export const CHEAPEST_RATES_LABELS = {
  charge: 'Cheapest Charge',
  shading: 'Cheapest Shading',
  miles: 'Cheapest Miles',
  kilometers: 'Cheapest Kilometers',
  cost: 'Cheapest Cost',
  type: CHEAPEST_RATE_TYPE,
  periodType: 'cheapest_rate',
  color: green,
}

export const SMART_CHARGE_LABELS = {
  charge: 'Optimized Charge',
  shading: 'Optimized Shading',
  miles: 'Optimized Miles',
  kilometers: 'Optimized Kilometers',
  cost: 'Optimized Cost',
  type: SMART_CHARGE_TYPE,
  periodType: 'smart_charge',
  color: green,
}

export const GREEN_CHARGE_LABELS = {
  charge: 'Battery Level',
  shading: 'Optimized Shading',
  cost: 'Green Cost',
  carbon: 'CO₂ Produced',
  type: CHEAPEST_RATE_TYPE,
  periodType: 'cheapest_rate',
  color: green,
}

export const GREEN_MONEY_SAVINGS_CHARGE_LABELS = {
  charge: 'Battery Level',
  shading: 'Optimized Shading',
  cost: 'Green Cost',
  carbon: 'CO₂ Produced',
  type: SMART_CHARGE_TYPE,
  periodType: 'cheapest_rate',
  color: green,
}

const EMISSIONS_DISPLAY_TYPE = {
  key: 'emissions_moer',
  label: 'Emissions (CO₂lbs / MWh)',
  legend: MOER_LABEL,
  format: (value) => value.toFixed(1).toLocaleString(),
}
const ELECTRICITY_PRICE_DISPLAY_TYPE = {
  key: 'electricity_price_per_kwh',
  label: '$/kWh',
  legend: ELECTRICITY_RATE_LABEL,
  format: (value) => {
    return value.toFixed(4).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    })
  },
}

const READONLY_PLAN = 'normal'
const CHEAPEST_PLAN = 'cheapest'
const GREEN_PLAN = 'green'
const GREEN_MONEY_SAVINGS_PLAN = 'green_money_savings'
const DEPARTURE_PLAN = 'departure'
const BASELINE_PLAN = 'baseline_readonly'

const labelsToPlanMapping = {
  normal: STANDARD_LABELS,
  cheapest: CHEAPEST_RATES_LABELS,
  optimized: SMART_CHARGE_LABELS,
  green: GREEN_CHARGE_LABELS,
  green_money_savings: GREEN_MONEY_SAVINGS_CHARGE_LABELS,
  departure: SMART_CHARGE_LABELS,
  [BASELINE_PLAN]: GREEN_CHARGE_LABELS,
}

function isOnHour(periodDate) {
  // Will return if the date is on an exact hour. Examples:
  // 10:00:00 -> true
  // 10:10:00 -> false
  if (
    periodDate instanceof Date &&
    periodDate.getMinutes() === 0 &&
    periodDate.getSeconds() === 0 &&
    periodDate.getMilliseconds() === 0
  ) {
    return true
  }
  return false
}

class ChargeForecastBase {
  constructor(
    chargeForecastData,
    distanceUnit,
    displayType = EMISSIONS_DISPLAY_TYPE,
  ) {
    this._display_type = displayType
    this._chargeForecastData = chargeForecastData
    this._setPeriodsAndDates()
    this._setData(distanceUnit)
    this._setLowEmissions()
    this._setOptions()
    this._setPlugins()
  }

  _setPeriodsAndDates() {
    // Filter charge forecast periods to only include entries that occur exactly on the hour
    // (e.g. 1:00, 2:00, etc. but not 1:30, 2:15, etc.)
    this._periods = this._chargeForecastData['charge_forecast_periods'].filter(
      (period) => isOnHour(new Date(period.start_datetime)),
    )

    // Create array of Date objects from the filtered periods' start times
    // Filter out any invalid dates (null, undefined, or invalid Date objects)
    this._dates = this._periods
      .map((period) => new Date(period.start_datetime))
      .filter((date) => date instanceof Date && !isNaN(date))
  }

  data() {
    return this._data
  }

  options() {
    return this._options
  }

  plguins() {
    return this._plugins
  }

  _setPlugins() {
    const isMobile = useIsMobile()

    if (!isMobile) {
      const LEAF_SIZE = 20
      const LEAF_X_OFFSET = -10
      const LEAF_Y_OFFSET = 8
      this._plugins = [
        {
          // Creates a plugin that adds a leaf to the bar when emissions are low
          id: 'leaf-icon',
          beforeDraw: (chart, _args, _options) => {
            const { ctx, scales } = chart
            for (const element of this._data.datasets) {
              if (element.type === 'bar') {
                for (const idx of element.data.keys()) {
                  if (
                    this._lowEmissionsData[idx] === true &&
                    idx + 1 < element.data.length
                  ) {
                    const leafImage = new Image(LEAF_SIZE, LEAF_SIZE)
                    leafImage.src = `${process.env.REACT_APP_HOST}/img/leaf.svg`
                    const x = scales['x-axis-1'].getPixelForValue(
                      this._data.labels[idx],
                    )
                    const y = scales['y-A'].getPixelForValue(element.data[idx])
                    ctx.drawImage(
                      leafImage,
                      x + LEAF_X_OFFSET,
                      y + LEAF_Y_OFFSET,
                      LEAF_SIZE,
                      LEAF_SIZE,
                    )
                  }
                }
              }
            }
          },
        },
      ]
    } else {
      this._plugins = []
    }
  }

  _setOptions() {
    this._options = {
      maintainAspectRatio: !isMobileDevice(),
      title: {
        display: false,
      },
      legend: this._getLegend(),
      tooltips: this._getToolTips(),
      scales: this._getScales(),
      annotation: this._getAnnotation(),
    }
  }

  _setLowEmissions() {
    this._lowEmissionsData = this._chargeForecastData[
      'charge_forecast_periods'
    ].flatMap((x) => x['low_emissions'])
  }

  _getLastDate() {
    return new Date(this._periods.slice(-1)[0].start_datetime)
  }

  _getAnnotation() {
    return {
      drawTime: 'afterDraw',
      events: ['click', 'mouseenter', 'mouseleave'],
      annotations: this._getAnnotations(),
    }
  }

  _getAnnotations() {
    throw Error(
      "Can't call getAnnotations directly, this is an abstract method.",
    )
  }

  _getLegend() {
    return {
      labels: {},
    }
  }

  _getToolTips() {
    return {
      mode: 'nearest',
      callbacks: this._getCallbacks(),
    }
  }

  _getCallbacks() {
    throw Error("Can't call getCallbacks directly, this is an abstract method.")
  }

  _getScales() {
    try {
      // If no dates available, this will throw an error
      if (!this._dates || !this._dates[1] || !this._dates[0]) {
        throw new Error('Invalid dates array for scale calculation')
      }

      const minDifference = getDifferenceMinutes(this._dates[1], this._dates[0])
      return {
        yAxes: [
          {
            id: 'y-A',
            scaleLabel: {
              display: true,
              labelString: this._display_type.label,
            },
            type: 'linear',
            position: 'left',
            gridLines: {
              display: false,
            },
            stacked: false,
            ticks: {
              display: !isMobileDevice(),
              beginAtZero: true,
              callback: function (value, index, values) {
                if (this._display_type) {
                  return this._display_type.format(value)
                }
                return value.toFixed(2)
              },
            },
          },
        ],
        xAxes: [
          {
            id: 'x-axis-1',
            type: 'time',
            display: false,
            time: {
              unit: 'hour',
              unitStepSize: 1,
              tooltipFormat: 'MMM D h:mm a',
            },
            ticks: {
              min: subtractMinutes(this._dates[0], minDifference / 2),
              max: subtractMinutes(this._dates.slice(-1)[0], minDifference / 2),
            },
          },
          {
            id: 'x-axis-2',
            type: 'time',
            time: {
              unit: 'hour',
              unitStepSize: isMobileDevice() ? 3 : 1,
              tooltipFormat: 'MMM D h:mm a',
            },
            ticks: {
              major: {
                enabled: true, // <-- This is the key line
                fontStyle: 'bold', //You can also style these values differently
                fontSize: 14, //You can also style these values differently
              },
            },
            gridLines: {
              display: false,
            },
          },
        ],
      }
    } catch (error) {
      // Log the error and dates to Sentry
      logException(
        `ChargeForecast _dates when error occurred: ${JSON.stringify(
          this._dates,
        )}`,
      )
      logException(error)
    }
  }

  _setData(distanceUnit) {
    this._data = {
      labels: this._dates,
      datasets: this._getDatasets(),
      distanceUnit: distanceUnit,
      lowEmissionsData: this._lowEmissionsData,
    }
  }

  _getDatasets() {
    let datasets = []
    datasets.push({
      label: this._display_type.legend,
      type: 'bar',
      barPercentage: 1.2,
      xAxisID: 'x-axis-1',
      yAxisID: 'y-A',
      hidden: false,
      backgroundColor: this._getRateColors(0.6),
      hoverBackgroundColor: this._getRateColors(1),
      data: this._periods.map((x) => x[this._display_type.key]),
    })
    return datasets
  }

  _getRateColors(opacity) {
    let rates = []
    for (let i = 0; i < this._periods.length; i++) {
      let period = this._periods[i]
      let rateValue = this._getRateValueForColor(period)
      rates.push({ rate: rateValue, error: false })
    }

    return getColors(rates, opacity, '#ddeefe')
  }

  _getRateValueForColor(period) {
    return period[this._display_type.key]
  }
}

class ChargeForecastRatesOnly extends ChargeForecastBase {
  constructor(chargeForecastData, _distanceUnit, displayType) {
    if (!chargeForecastData.is_rates_only) {
      logException(
        'Trying to load ChargeForecastRatesOnly with is_rates_only = False',
      )
    }
    super(chargeForecastData, null, displayType)
  }

  _getDatasets() {
    let datasets = []
    datasets.push({
      label: 'Charger Unplugged',
      type: 'line',
      borderColor: red,
      data: [],
    })

    return datasets.concat(super._getDatasets())
  }

  _getAnnotations() {
    return []
  }

  _getCallbacks() {
    return {
      label: function (tooltipItem) {
        return `${this._display_type.label}: ${Number(
          tooltipItem.yLabel.toFixed(2),
        )}`
      }.bind(this),
    }
  }
}

class ChargeForecast extends ChargeForecastBase {
  // Percent difference between standard and smart charging tolerance
  UPSELL_COST_TOLERANCE_PERCENT = 0.05

  constructor(chargeForecastData, distanceUnit, displayType) {
    if (chargeForecastData.is_rates_only) {
      logException('Trying to load ChargeForecast with is_rates_only')
    }
    super(chargeForecastData, distanceUnit, displayType)
  }

  _getBatteryUnit() {
    let battery_unity = this._chargeForecastData['battery_unit']
    if (battery_unity === 'percent') {
      return 'battery_percent'
    } else if (battery_unity === 'miles_range') {
      return 'miles_range'
    } else {
      return 'kilometers_range'
    }
  }

  _getBatteryUnitSuffix() {
    let battery_unity = this._chargeForecastData['battery_unit']
    if (battery_unity === 'percent') {
      return '%'
    } else if (battery_unity === 'miles_range') {
      return ' miles'
    } else {
      return ' km'
    }
  }

  _getBatteryAxisLabel() {
    let battery_unity = this._chargeForecastData['battery_unit']
    if (battery_unity === 'percent') {
      return 'Percent (%)'
    } else if (battery_unity === 'miles_range') {
      return 'Range (miles)'
    } else {
      return 'Range (km)'
    }
  }

  _getYAxisBatteryLimit() {
    return this._chargeForecastData['battery_unit'] === 'percent' ? 100 : 400
  }

  _setLabelsToUse() {
    // 1) Add selected_plan to labelsToUse
    // 2) If comparison plan, also add that to labelsToUse (if within tolerance)
    //    decide colors
    let labelsToUse = []
    let labelsSet = labelsToPlanMapping[this._chargeForecastData.selected_plan]
    labelsToUse.push(labelsSet)
    if (this._chargeForecastData.comparison_plan) {
      let comparisonSet =
        labelsToPlanMapping[this._chargeForecastData.comparison_plan]
      labelsToUse.push({ ...comparisonSet, color: blue })
    }

    this._labelsToUse = labelsToUse
  }

  _getLabelsToUse() {
    if (typeof this._labelsToUse === 'undefined') {
      this._setLabelsToUse()
    }

    return this._labelsToUse
  }

  _getShadingData(data) {
    // Just remember, the points are being filled in between 2 points,
    // so it's impossible to have just one empty slot.
    let shading = []
    for (let i = 0; i < data.length; i++) {
      let shade = null

      // Look ahead
      if (typeof data[i + 1] !== 'undefined') {
        if (data[i] < data[i + 1]) {
          shade = data[i]
        }
      }

      // Look behind
      if (typeof data[i - 1] !== 'undefined') {
        if (data[i] > data[i - 1]) {
          shade = data[i]
        }
      }

      shading.push(shade)
    }

    return shading
  }

  _getMetricsByType(type) {
    let metrics = []
    this._periods.forEach((period) => {
      if (type in period) {
        metrics.push(period[type])
      }
    })

    return metrics
  }

  _getMetricsSet(labelSet, isPrimaryDataSet) {
    let metricsSet = []
    let metrics = this._getMetricsByType(labelSet.type)
    let batteryData = metrics.map((x) => x[this._getBatteryUnit()])
    metricsSet.push({
      label: labelSet.charge,
      type: 'line',
      xAxisID: 'x-axis-2',
      yAxisID: 'y-B',
      borderColor: labelSet.color,
      backgroundColor: hexToRGB(labelSet.color, 0.1),
      pointBackgroundColor: labelSet.color,
      fill: isPrimaryDataSet,
      hidden: false,
      data: batteryData,
      costData: metrics.map((x) => x['charge_cost_cents'] / 100),
      carbonData: metrics.map((x) => x['carbon_lbs'] || 0),
      percentData: metrics.map((x) => x['battery_percent']),
      milesData: metrics.map((x) => x['miles_range']),
      kilometersData: metrics.map((x) => x['kilometers_range']),
    })

    if (isPrimaryDataSet) {
      metricsSet.push({
        label: labelSet.shading,
        type: 'line',
        xAxisID: 'x-axis-2',
        yAxisID: 'y-B',
        borderColor: 'rgba(0, 0, 0, 0)',
        backgroundColor: hexToRGB(labelSet.color, 0.15),
        pointBackgroundColor: 'rgba(0, 0, 0, 0)',
        hidden: false,
        fill: true,
        data: this._getShadingData(batteryData),
      })
    }

    return metricsSet
  }

  _getDatasets() {
    let datasets = []
    let labels = this._getLabelsToUse()
    labels.forEach((labelSet, labelIndex) => {
      let additionalDataset = this._getMetricsSet(labelSet, labelIndex === 0)
      datasets = datasets.concat(additionalDataset)
    })

    datasets = datasets.concat(super._getDatasets())
    return datasets
  }

  _getVerticalLineDate(label) {
    let time = this._chargeForecastData[label]
    let scheduledTime = new Date(time)
    const startDate = new Date(Math.min.apply(null, this._dates))
    if (scheduledTime < startDate) {
      scheduledTime = addDays(scheduledTime, 1)
    }
    return scheduledTime
  }

  _getAnnotationAdjustment(scheduledStartDate) {
    const maxDate = new Date(Math.max.apply(null, this._dates))
    const hoursBeforeMax = getHoursBetweenDates(maxDate, scheduledStartDate)
    let adjustment = 0
    if (hoursBeforeMax <= 5) {
      adjustment = (5 - hoursBeforeMax) * (5 - hoursBeforeMax) * 2.8 * -1
    }

    const minDate = new Date(Math.min.apply(null, this._dates))
    const hoursAfterMin = getHoursBetweenDates(scheduledStartDate, minDate)
    if (hoursAfterMin <= 5) {
      adjustment = (5 - hoursAfterMin) * (5 - hoursAfterMin) * 2.8
    }

    if (isMobileDevice()) {
      adjustment = adjustment * 1.2
    }

    return adjustment
  }

  _getVerticalAnnotation(scaleID, content, value, adjustment, colorRGB) {
    return {
      id: 'vline',
      type: 'line',
      mode: 'vertical',
      scaleID: scaleID,
      value: value,
      borderWidth: 1,
      borderColor: `rgba(${colorRGB},0.3)`,
      label: {
        enabled: false,
        content: content,
        position: 'center',
        xAdjust: adjustment,
      },
      onMouseenter: function (e) {
        this.options.borderColor = `rgba(${colorRGB},0.8)`
        this.options.borderWidth = 2
        this.options.label.enabled = true
        this.chartInstance.update()
      },
      onMouseleave: function (e) {
        this.options.borderColor = `rgba(${colorRGB},0.3)`
        this.options.borderWidth = 1
        this.options.label.enabled = false
        this.chartInstance.update()
      },
    }
  }

  _getHorizontalAnnotation(scaleID, content, value, adjustment, colorRGB) {
    return {
      id: 'hline',
      type: 'line',
      mode: 'horizontal',
      scaleID: scaleID,
      value: value,
      borderWidth: 1,
      borderColor: `rgba(${colorRGB},0.3)`,
      label: {
        enabled: false,
        content: content,
        position: 'center',
        yAdjust: adjustment,
      },
      onMouseenter: function (e) {
        this.options.borderColor = `rgba(${colorRGB},0.8)`
        this.options.borderWidth = 2
        this.options.label.enabled = true
        this.chartInstance.update()
      },
      onMouseleave: function (e) {
        this.options.borderColor = `rgba(${colorRGB},0.3)`
        this.options.borderWidth = 1
        this.options.label.enabled = false
        this.chartInstance.update()
      },
    }
  }

  _getAnnotations() {
    let annotations = []
    if (this._chargeForecastData['max_battery_target']) {
      annotations.push(
        this._getHorizontalAnnotation(
          'y-B',
          `Target Battery: ${
            this._chargeForecastData['max_battery_target']
          }${this._getBatteryUnitSuffix()}`,
          this._chargeForecastData['max_battery_target'],
          this._chargeForecastData['max_battery_target'] > 80 ? 10 : 0,
          '0,0,255',
        ),
      )
    }

    if (
      this._chargeForecastData[SCHEDULE_START_TIME_LABEL] ||
      this._chargeForecastData[DEPARTURE_TIME_LABEL]
    ) {
      let verticalLineDate
      let content
      if (this._chargeForecastData[SCHEDULE_START_TIME_LABEL]) {
        verticalLineDate = this._getVerticalLineDate(SCHEDULE_START_TIME_LABEL)
        content = 'Scheduled Charge'
      } else {
        verticalLineDate = this._getVerticalLineDate(DEPARTURE_TIME_LABEL)
        content = 'Ready By time'
      }

      // We only want to display a departure date that is within our alloted time
      if (verticalLineDate <= this._getLastDate()) {
        annotations.push(
          this._getVerticalAnnotation(
            'x-axis-2',
            content,
            verticalLineDate,
            this._getAnnotationAdjustment(verticalLineDate),
            '0,0,255',
          ),
        )
      }
    }

    return annotations
  }

  _getAllPlottedValuesByUnit(unit) {
    let values = []
    let labels = this._getLabelsToUse()
    labels.forEach((labelSet) => {
      let metrics = this._getMetricsByType(labelSet.type)
      metrics.forEach((metric) => {
        values.push(metric[unit])
      })
    })

    return values
  }

  _getYAxisMax() {
    let allPossibleValues = this._getAllPlottedValuesByUnit(
      this._getBatteryUnit(),
    )
    let maxValue = Math.max(...allPossibleValues)
    let batteryLimit = this._getYAxisBatteryLimit()
    // Increase by 3%, but limit to 100
    let displayValue = Math.min(maxValue * 1.03, batteryLimit)
    return 10 * Math.ceil(displayValue / 10)
  }

  _getYAxisMin() {
    let allPossibleValues = this._getAllPlottedValuesByUnit(
      this._getBatteryUnit(),
    )
    let maxValue = Math.min(...allPossibleValues)
    // Decrease by 3%, but limit to 0
    let displayValue = Math.max(maxValue * 0.97, 0)
    return 10 * Math.floor(displayValue / 10)
  }

  _getScales() {
    let scales = super._getScales()
    scales['yAxes'].push({
      id: 'y-B',
      scaleLabel: {
        display: !isMobileDevice(),
        labelString: `Battery ${this._getBatteryAxisLabel()}`,
      },
      type: 'linear',
      position: 'right',
      gridLines: {
        display: false,
      },
      stacked: false,
      ticks: {
        display: !isMobileDevice(),
        // stepSize: 10,
        max: this._getYAxisMax(),
        min: this._getYAxisMin(),
        // callback: function (value) {
        //     return (value).toFixed(0);
        // },
        callback: (value) => value.toFixed(0),
      },
    })

    return scales
  }

  _getCallbacks() {
    let labelsToUse = this._getLabelsToUse()
    const displayType = this._display_type
    return {
      label: function (tooltipItem, data) {
        const dataset = data.datasets[tooltipItem.datasetIndex]
        const label = dataset.label
        if (label.toLowerCase().includes('shading')) {
          return ''
        } else if ([ELECTRICITY_RATE_LABEL, MOER_LABEL].includes(label)) {
          return `${displayType.label}: ${displayType.format(
            Number(tooltipItem.yLabel),
          )}`
        } else {
          let labelItems = []
          const foundLabelSet = labelsToUse.find((l) => l.charge === label)

          if (data['distanceUnit'] === MILES && foundLabelSet.carbon) {
            let carbon = dataset.carbonData[tooltipItem.index]
            labelItems.push(
              `${foundLabelSet.carbon}: ${carbon
                .toFixed(1)
                .toLocaleString('en-US')} lbs`,
            )
          } else if (
            data['distanceUnit'] === KILOMETERS &&
            foundLabelSet.carbon
          ) {
            let carbon = convertPoundsToKilograms(
              dataset.carbonData[tooltipItem.index],
              false,
            )
            labelItems.push(
              `${foundLabelSet.carbon}: ${carbon
                .toFixed(1)
                .toLocaleString('en-US')} kg`,
            )
          }

          if (foundLabelSet.cost) {
            let currency = dataset.costData[tooltipItem.index]
            labelItems.push(
              `${foundLabelSet.cost}: ${Number(currency).toLocaleString(
                'en-US',
                {
                  style: 'currency',
                  currency: 'USD',
                },
              )}`,
            )
          }

          if (foundLabelSet.charge) {
            let batteryPercent = dataset.percentData[tooltipItem.index]
            labelItems.push(
              `${foundLabelSet.charge}: ${Number(batteryPercent).toFixed(0)}%`,
            )
          }

          if (data['distanceUnit'] === MILES && foundLabelSet.miles) {
            let miles = dataset.milesData[tooltipItem.index]
            labelItems.push(
              `${foundLabelSet.miles}: ${Number(miles).toFixed(0)}`,
            )
          } else if (
            data['distanceUnit'] === KILOMETERS &&
            foundLabelSet.kilometers
          ) {
            let kilometers = dataset.kilometersData[tooltipItem.index]
            labelItems.push(
              `${foundLabelSet.kilometers}: ${Number(kilometers).toFixed(0)}`,
            )
          }

          return labelItems
        }
      },
    }
  }

  _getLegend() {
    let labelsToUse = this._getLabelsToUse()
    return {
      labels: {
        filter: function (item) {
          return !item.text.toLowerCase().includes('shading')
        },
      },
      onClick: function (e, legendItem) {
        let ci = this.chart
        let clickedDataset = ci.data.datasets[legendItem.datasetIndex]
        let shouldHide = !clickedDataset.hidden
        clickedDataset.hidden = shouldHide

        const foundLabelSet = labelsToUse.find(
          (labelSet) => labelSet.charge === legendItem.text,
        )
        if (typeof foundLabelSet !== 'undefined') {
          let shadingDataset = ci.data.datasets.find(
            (dataset) => dataset.label === foundLabelSet.shading,
          )
          if (typeof shadingDataset !== 'undefined') {
            shadingDataset.hidden = shouldHide
          }
        }

        ci.update()
      },
    }
  }

  /**
   * Return null value for rate so color will be grey
   */
  _getRateValueForColor(period) {
    return null
  }
}

class ChargeTripForecast extends ChargeForecast {
  _getAnnotations() {
    let annotations = []
    if (this._chargeForecastData['max_battery_target']) {
      annotations.push(
        this._getHorizontalAnnotation(
          'y-B',
          `Target Battery: ${
            this._chargeForecastData['max_battery_target']
          }${this._getBatteryUnitSuffix()}`,
          this._chargeForecastData['max_battery_target'],
          this._chargeForecastData['max_battery_target'] > 80 ? 10 : 0,
          '0,0,255',
        ),
      )
    }

    if (
      this._chargeForecastData[SCHEDULE_START_TIME_LABEL] ||
      this._chargeForecastData[DEPARTURE_TIME_LABEL]
    ) {
      let verticalLineDate
      let content
      if (this._chargeForecastData[SCHEDULE_START_TIME_LABEL]) {
        verticalLineDate = this._getVerticalLineDate(SCHEDULE_START_TIME_LABEL)
        content = 'Scheduled Charge for Trip'
      } else {
        verticalLineDate = this._getVerticalLineDate(DEPARTURE_TIME_LABEL)
        content = 'Trip Ready By time'
      }

      // We only want to display a departure date that is within our alloted time
      if (verticalLineDate <= this._getLastDate()) {
        annotations.push(
          this._getVerticalAnnotation(
            'x-axis-2',
            content,
            verticalLineDate,
            this._getAnnotationAdjustment(verticalLineDate),
            '255,0,0',
          ),
        )
      }
    }

    return annotations
  }
}

export function useChargeForecast(chargeForecastData, distanceUnit) {
  const shouldShowEmissionsForecast =
    chargeForecastData?.selected_plan === GREEN_PLAN ||
    chargeForecastData?.selected_plan === BASELINE_PLAN

  if (chargeForecastData === null) {
    return [[], [], false]
  } else if (
    typeof chargeForecastData['type'] !== 'undefined' &&
    chargeForecastData['type'] === 'no plan'
  ) {
    return [[], [], true]
  } else if (chargeForecastData['charge_forecast_periods'].length === 0) {
    return [[], [], false]
  }

  const displayType = shouldShowEmissionsForecast
    ? EMISSIONS_DISPLAY_TYPE
    : ELECTRICITY_PRICE_DISPLAY_TYPE

  let Forecast
  if (chargeForecastData.is_rates_only) {
    Forecast = new ChargeForecastRatesOnly(
      chargeForecastData,
      distanceUnit,
      displayType,
    )
  } else if (chargeForecastData.is_trip) {
    Forecast = new ChargeTripForecast(
      chargeForecastData,
      distanceUnit,
      displayType,
    )
  } else {
    Forecast = new ChargeForecast(chargeForecastData, distanceUnit, displayType)
  }

  return [Forecast.data(), Forecast.options(), Forecast.plguins(), false]
}
