import DeviceConnectionRedirect, {
  ConnectionStatusMode,
  Props as DeviceConnectionRedirectProps,
} from '@/authenticated/components/DeviceConnectionRedirect'
import ConnectVehicleConfirmation from '@/authenticated/components/Vehicle/ConnectVehicleConfirmation'
import { Grid as MuiGrid } from '@material-ui/core'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { FullPageCard, ScreenFillingPage } from '@/app/components'
import TeslaMissingRequiredPermission from '@/app/components/TeslaMissingRequiredPermission/TeslaMissingRequiredPermission'
import { TeslaFleetAuthenticationScopeName } from '@/types/teslaFleetAuthentication'
import { useHistory } from 'react-router-dom'

const Grid = styled(MuiGrid)(spacing)
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      maxHeight: '1000px',
      backgroundColor: '#FFF',
      padding: theme.spacing(2),
      minHeight: '280px',
      flex: '1',
    },
  }),
)

export interface Props extends DeviceConnectionRedirectProps {
  actionDisabled: boolean
}

function TeslaFleetRedirectContent(props: Props) {
  const classes = useStyles()
  const history = useHistory()

  const { actionDisabled, missingScopes, ...deviceConnectionRedirectProps } =
    props

  const requiredScopes = [
    TeslaFleetAuthenticationScopeName.vehicle_device_data,
    TeslaFleetAuthenticationScopeName.vehicle_location,
  ]

  if (missingScopes?.some((scope) => requiredScopes.includes(scope))) {
    return <TeslaMissingRequiredPermission />
  }

  if (props.mode === ConnectionStatusMode.Connected) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="flex-end"
        className={classes.root}
      >
        <ConnectVehicleConfirmation
          onConfirmed={props.buttonAction ?? (() => {})}
          isTesla
          disableButton={props.actionDisabled}
          missingScopes={missingScopes ?? []}
        />
      </Grid>
    )
  }

  const handleBackClick = () => {
    history.go(-2) // skip past the tesla auth page
  }

  return (
    <DeviceConnectionRedirect
      {...deviceConnectionRedirectProps}
      disableButton={props.actionDisabled}
      backButtonAction={handleBackClick}
    />
  )
}

export default function TeslaFleetRedirect(props: Props) {
  return (
    <ScreenFillingPage>
      <FullPageCard>
        <TeslaFleetRedirectContent {...props} />
      </FullPageCard>
    </ScreenFillingPage>
  )
}
