import React from 'react'
import styled from 'styled-components'

import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography as MuiTypography,
  Grid,
} from '@material-ui/core'

import { spacing } from '@material-ui/system'
import Skeleton from '@material-ui/lab/Skeleton'

const Card = styled(MuiCard)(spacing)

const StatsCard = styled(Card)`
  border-radius: 20px;
`

const Typography = styled(MuiTypography)(spacing)

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`

class BatteryStats extends React.Component {
  render() {
    return (
      <StatsCard mb={3}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Example Charge
          </Typography>
          <Typography variant="body2" gutterBottom>
            Your charge will appear here after your next charge at home
            completes.
          </Typography>
          <Skeleton
            animation="wave"
            variant="text"
            style={{ marginTop: '20px' }}
          />
          <Skeleton animation="wave" variant="text" />
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Skeleton
              animation="wave"
              variant="rect"
              style={{ marginTop: '45px', marginBottom: '45px' }}
              width={250}
              height={50}
            />
          </Grid>
          <Skeleton variant="text" style={{ marginBottom: '13px' }} />
        </CardContent>
      </StatsCard>
    )
  }
}

export default BatteryStats
