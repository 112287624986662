import React from 'react'
import {
  Box as MuiBox,
  Typography as MuiTypography,
  Link,
} from '@material-ui/core'
import ConnectDeviceInformationCard from '../../../../authenticated/components/ConnectDeviceInformationCard'
import LinkDiagram from '../../logos/LinkDiagram'
import optiwattLogoSrc from './resources/images/optiwatt-logo.webp'
import { Props as ListableInformationCardProps } from '../../../../authenticated/components/ConnectDeviceInformationCard/ListableInformationCard'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Lottie from 'lottie-react'
import connectUtilityAnimation from './resources/images/connect-utility-lottie.json'
import sanitizeHtml from 'sanitize-html'
import useIsMobile from '@/authenticated/hooks/useIsMobile'

const Box = styled(MuiBox)(spacing)
const Typography = styled(MuiTypography)(spacing)

const useStyles = makeStyles((theme) =>
  createStyles({
    informationCard: {
      borderRadius: '0px',
      maxHeight: 'none',
      overflow: 'visible',
    },
    informationCardHeader: {
      '&': {
        marginBottom: 0,
      },
    },
    footText: {
      fontSize: '12px',
    },
    link: {
      color: theme.palette.blue?.main,
    },
    listIconContainer: {
      background: theme.palette.secondary.main,
    },
  }),
)

const ConnectUtilityAnimation = (
  <Lottie animationData={connectUtilityAnimation} loop />
)

function Footer(props: {
  content?: string
  tosUrl?: string | null
  programName: string
}) {
  const classes = useStyles()
  if (props.content) {
    const sanitizedContent = sanitizeHtml(props.content, {
      allowedTags: ['a'],
      allowedAttributes: {
        a: ['href', 'target'],
      },
    })
    return (
      <Typography
        className={classes.footText}
        variant="subtitle2"
        dangerouslySetInnerHTML={{ __html: sanitizedContent }}
      />
    )
  }

  if (props.tosUrl) {
    return (
      <Typography className={classes.footText} variant="subtitle2">
        By clicking Enroll, you agree to the{' '}
        <Link className={classes.link} href={props.tosUrl} target="_blank">
          terms
        </Link>{' '}
        of the {props.programName} managed charging program.
      </Typography>
    )
  }

  return null
}

export interface Props {
  title: string
  subtitle: string
  informationCards: ListableInformationCardProps[]
  programLogoUrl: string | null
  programName: string
  onConnectClick: () => void
  tosUrl?: string | null
  footerContent?: string
  excludeHeaderAnimation?: boolean
}

export default function UtilityProgramEnrollmentPrompt({
  title,
  subtitle,
  informationCards,
  programLogoUrl,
  programName,
  onConnectClick,
  tosUrl,
  footerContent,
  excludeHeaderAnimation,
}: Props) {
  const classes = useStyles()
  const isApp = useIsMobile()

  return (
    <ConnectDeviceInformationCard
      id="utility-program-enrollment-prompt-information-card"
      padContent={!isApp}
      headerlessOnDesktop={false}
      className={clsx(classes.informationCard)}
      media={!excludeHeaderAnimation ? ConnectUtilityAnimation : undefined}
      title={title}
      subtitle={subtitle}
      informationCards={informationCards}
      onClick={onConnectClick}
      classes={{
        header: classes.informationCardHeader,
        iconContainer: classes.listIconContainer,
      }}
      sticky={false}
      underTitleComponent={
        <Box py={8}>
          <LinkDiagram logos={[optiwattLogoSrc, programLogoUrl ?? '']} />
        </Box>
      }
      // blank because button is defined in the card actions of the card
      // that contains this component
      button={<></>}
      footer={
        <Footer
          tosUrl={tosUrl}
          programName={programName}
          content={footerContent}
        />
      }
    />
  )
}
