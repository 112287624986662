import { DeviceDemandResponseEvent } from '@/types/deviceDemandResponseEvent'
import { DemandResponseActionDialog } from '../../GridSupport/DelayEvent'
import dayjs from 'dayjs'
import { changeVehicleChargingDelayEvent } from '@/actions/vehicleChargingDelayEvents'
import { useAppDispatch } from '@/hooks'
import { chargeNowEventCollection } from '@/reducers/chargeNowEvents'
import { chargeForecastCollection } from '@/reducers/chargeRelatedCollections'

type Props = {
  open: boolean
  drEvent: DeviceDemandResponseEvent
  onClose: () => void
}

export default function DROptOutModal({ drEvent, open, onClose }: Props) {
  const dispatch = useAppDispatch()
  const startTime = dayjs(drEvent.start_datetime).calendar().toLowerCase()
  const endTime = dayjs(drEvent.end_datetime).calendar().toLowerCase()

  const invalidateRelatedData = () => {
    dispatch(chargeNowEventCollection.actions.invalidate())
    dispatch(chargeForecastCollection.actions.invalidate())
  }

  const onClickOptOut = () => {
    dispatch(
      changeVehicleChargingDelayEvent(drEvent.id, {
        enrolled: false,
        reason: 'opt-out',
      }),
    ).then(invalidateRelatedData)
  }

  return (
    <DemandResponseActionDialog
      optIn={false}
      startTime={startTime}
      endTime={endTime}
      open={open}
      handleClose={onClose}
      handleAction={onClickOptOut}
    />
  )
}
