import useViewModel from './useViewModel'
import UtilityUsage, {
  Props as UtilityUsageProps,
} from '../../../../authenticated/components/UtilityUsage'
import { ViewModelComponentProps } from '../../../../utils/typeUtilities'
import PlanOverview from '../../app/home/PlanOverview'

interface InnerProps extends UtilityUsageProps {
  shouldShow: boolean
}
function UtilityUsageDashboardView({ shouldShow, ...props }: InnerProps) {
  if (!shouldShow) {
    return <PlanOverview />
  }
  return <UtilityUsage {...props} />
}

type Props = ViewModelComponentProps<InnerProps, typeof useViewModel>
// Note: we use a view model here to gain access to mock view models for storybook
export default function (props: Props) {
  const viewModel = useViewModel()
  return <UtilityUsageDashboardView {...props} {...viewModel} />
}
