import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  Typography as MuiTypography,
  Box as MuiBox,
  Grid as MuiGrid,
  Divider,
  Button,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { green } from '@material-ui/core/colors'
import { spacing } from '@material-ui/system'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { Zap, Calendar } from 'react-feather'
import UtilitySetupCard from './UtilitySetupCard'
import BillingCycleSection from '../UsageOverview/BillingCycleSection'
import dayjs from 'dayjs'

const Typography = styled(MuiTypography)(spacing)
const Box = styled(MuiBox)(spacing)
const Grid = styled(MuiGrid)(spacing)
const useStyles = makeStyles((theme) => ({
  connectButton: {
    width: '60%',
    height: '50px',
    borderRadius: '20px',
    backgroundColor: theme.palette.purple.main,
    '&:hover': {
      backgroundColor: theme.palette.purple.dark,
    },
  },
  billingCycleProgressText: {
    display: 'inline-block',
    fontSize: '0.75rem',
  },
  skelly: {
    verticalAlign: 'middle',
  },
  billingCycleProgressDaysText: {
    color: theme.palette.blue.main,
  },
  inlineSelector: {
    display: 'inline-block',
    fontSize: '0.75rem',
    margin: '0 4px',
  },
  daySelectorButton: {
    padding: '0 0.5rem',
    borderRadius: '10px',
  },
  textPrefixIcon: {
    display: 'inline-block',
    marginRight: theme.spacing(2),
  },
  dollarIcon: {
    color: green[500],
  },
  calendarIcon: {
    color: theme.palette.blue.main,
  },
  billingStartPrompt: {
    display: 'inline-block',
  },
}))

const ConnectUtilityPropmt = ({ setIsGridSupportOpen }) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const currentUser = useSelector((state) => state.user.user)
  const { selectedUtility } = useSelector((state) => state.utilities)

  const startUtilityConnection = () => {
    setIsGridSupportOpen(true)
  }

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Button
        className={classes.connectButton}
        variant="contained"
        color="primary"
        onClick={startUtilityConnection}
      >
        Connect Utility
      </Button>
    </Grid>
  )
}

const ConnectUtilitySetup = ({
  billingStartDay,
  setBillingStartDay,
  isLoading,
  selectedVehicle,
  setIsGridSupportOpen,
}) => {
  const classes = useStyles()

  const lastBillingStart =
    billingStartDay > dayjs().date()
      ? dayjs().subtract(1, 'months').set('date', billingStartDay)
      : dayjs().set('date', billingStartDay)

  const billingCycleProgress = billingStartDay
    ? dayjs().diff(lastBillingStart, 'days')
    : 'an unknown amount of'

  const friendlyCarName =
    selectedVehicle?.car?.car_model?.friendly_name || 'car'

  return (
    <UtilitySetupCard
      header="Connect Your Utility"
      subHeader={`Get more insightful energy usage metrics for your ${friendlyCarName} and home.`}
      Icon={Zap}
      notificationNumber={1}
    >
      <Box>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <ConnectUtilityPropmt setIsGridSupportOpen={setIsGridSupportOpen} />
          </Grid>

          <Grid item xs={12} my={{ xs: 0 }}>
            <Divider className={classes.divider} />
          </Grid>

          <Grid item xs={12} mb={-4}>
            <BillingCycleSection
              billingStartDay={billingStartDay}
              setBillingStartDay={setBillingStartDay}
              isLoading={isLoading}
            />
          </Grid>

          <Grid item xs={12}>
            <Calendar
              className={clsx(classes.textPrefixIcon, classes.calendarIcon)}
              width="1.05rem"
              height="1.05rem"
            />
            <Typography
              className={classes.billingCycleProgressText}
              variant="body1"
            >
              You are&nbsp;
            </Typography>
            {isLoading ? (
              <Skeleton
                className={clsx(
                  classes.billingCycleProgressText,
                  classes.skelly,
                )}
                animation="wave"
                variant="text"
                width="3rem"
              />
            ) : (
              <Typography
                className={clsx(
                  classes.billingCycleProgressText,
                  classes.billingCycleProgressDaysText,
                )}
                variant="body1"
              >
                {billingCycleProgress} days
              </Typography>
            )}
            <Typography
              className={classes.billingCycleProgressText}
              variant="body1"
            >
              &nbsp;into your billing cycle
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </UtilitySetupCard>
  )
}

export default ConnectUtilitySetup
