import { LoadingAnimation, Text } from '@/components'
import Icon from '../../../components/Icon'
import React, { useCallback } from 'react'
import { useNavigation } from '@/app/hooks'
import Dot from '@/components/Dot/Dot'
import { useComparisonsFrom } from '@/reducers/homeRateComparisons'
import { AlertMessage, BehindFlag } from 'app-components'
import useSelectedPlanFetcher from '../../../authenticated/hooks/plans/useSelectedPlanFetcher'
import { Plan } from '@/types'
import shouldRecommendPlan from '@/utils/shouldRecommendPlan'
import { logEvent } from '@/logging'
import SurveyObserver, {
  useSurveyResponder,
} from '@/app/components/SurveyObserver'

export default function InsightsCard() {
  const {
    selectedPlan,
    planIsPending,
    isLoading: loadingPlans,
  } = useSelectedPlanFetcher()
  const { isLoading: loadingComparisons, error } = useComparisonsFrom(30)
  const isLoading = loadingComparisons || loadingPlans

  if (!selectedPlan || planIsPending || isLoading || error) {
    return null
  }

  return (
    <BehindFlag flag="show_home_insights" experimental>
      <div className="bg-themed-white shadow-spread rounded-[20px] py-6 px-5">
        <Text variant="h3">Your Insights</Text>
        <div className="mt-5">
          <RateComparisonInfo currentPlan={selectedPlan} />
        </div>
      </div>
    </BehindFlag>
  )
}

type Props = {
  currentPlan: Plan
}

export function RateComparisonInfo(props: Props) {
  const navigate = useNavigation()

  const { comparison, isLoading, error } = useComparisonsFrom(30)
  const { dismissSurvey } = useSurveyResponder('InsightsDismissal')

  const onClick = useCallback(() => {
    logEvent('yourInsightsModuleClicked')
    navigate.push('/home-insights/current-rate')
  }, [])

  const onClose = useCallback(() => {
    dismissSurvey()
  }, [dismissSurvey])

  if (error) {
    return null
  }

  if (isLoading) {
    return <LoadingAnimation type="falling" centered />
  }

  const recommendedPlan = comparison?.comparison_plan?.name ?? 'Unknown'
  const recommendPlan = shouldRecommendPlan(props.currentPlan, comparison)
  const savings = Math.round(comparison?.current_plan_difference ?? 0)
  const showHistoricalSavings =
    props.currentPlan.name.toLowerCase().includes('gexa') && !recommendPlan

  return (
    <>
      <div className="flex gap-2.5 cursor-pointer" onMouseDown={onClick}>
        {recommendPlan && <Dot size={6} className="bg-themed-red-600 mt-1.5" />}
        {!recommendPlan && (
          <Dot size={6} className="bg-themed-green-900 mt-1.5" />
        )}
        <div className="flex-1">
          <Text variant="body1">Current Rate</Text>
          {recommendPlan && (
            <Text variant="body4" className="pt-1">
              You could potentially save <strong>${savings}/month</strong> with
              the {recommendedPlan} plan
            </Text>
          )}
          {!recommendPlan && (
            <>
              <Text variant="body4" className="pt-1">
                You're currently on the cheapest plan based on your usage
              </Text>
            </>
          )}
        </div>
        <div className="flex-4 mt-[9px]">
          <Icon color="#5d5d5d" name="ChevronRight" />
        </div>
      </div>
      {showHistoricalSavings && comparison?.current_plan_difference > 0 && (
        <SurveyObserver observedSurveys={['InsightsDismissal']}>
          <AlertMessage
            className="mt-4"
            variant="success"
            iconOverride="ThumbsUp"
            onClose={onClose}
          >
            You saved up to ${comparison.current_plan_difference.toFixed(2)}
            /month by switching to the {props.currentPlan.name} plan
          </AlertMessage>
        </SurveyObserver>
      )}
    </>
  )
}
