import { sendEvent as sendGTMEvent } from '@/logging/gtm'

// Taken from here: https://medium.com/@benshope/how-to-add-google-analytics-to-redux-ef5b6d8aa70e
const requestLoggingMiddleware = (store) => (next) => (action) => {
  try {
    sendGTMEvent('data_action', {
      action_type: action.type,
      action_params: action?.meta?.querySpec?.params,
    })
  } catch (error) {
    return next(action)
  }

  return next(action)
}
export default requestLoggingMiddleware
