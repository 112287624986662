import { createResourceCollection } from '@/request'
import { Plan } from '@/types'
import { ResourceModel, ResponseError } from '@/request/types'

export type RateComparison = {
  start_time: string
  end_time: string
  comparison_plan: Plan
  current_plan_difference: number
  source: 'meter' | 'vehicle'
} & ResourceModel

export function useComparisonsFrom(days: number): {
  comparison: RateComparison
  error: ResponseError | null | undefined
  isLoading: boolean
} {
  const now = new Date()
  now.setHours(0, 0, 0, 0)
  const then = new Date(now)
  then.setDate(now.getDate() - days)

  const { data, error, status } = homeRateComparisonsCollection.useFetch({
    params: {
      start_datetime: then,
    },
  })

  return {
    comparison: data?.[0],
    error,
    isLoading: status === 'loading' || status === 'idle',
  }
}

export const homeRateComparisonsCollection =
  createResourceCollection<RateComparison>({
    name: 'homeRateComparisons',
    apiConfig: {
      path: 'usage/home/compare',
    },
    selector: (state) => state.homeRateComparisons,
  })

export default homeRateComparisonsCollection.slice.reducer
