export default {
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 20,
      },
    },
    MuiDialog: {
      paper: {
        padding: '16px 8px',
      },
    },
  },
  palette: {
    gray: {
      main: '#F2F2F2',
      contrastText: '#5D5D5D',
    },
  },
}

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    gray?: { [key: string]: string }
  }

  interface PaletteOptions {
    gray?: { [key: string]: string }
  }
}
