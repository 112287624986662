import TotalSpentCardRoot, { Props as ComponentProps } from './TotalSpentCard'
import useViewModel from './useViewModel'
import { ViewModelComponentProps } from '../../../../utils/typeUtilities'

export type Props = ViewModelComponentProps<ComponentProps, typeof useViewModel>

export default function TotalSpentCard(props: Props) {
  const viewModel = useViewModel(props)

  return <TotalSpentCardRoot {...viewModel} />
}
