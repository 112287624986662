import { useAppSelector } from '@/hooks'
import { selectFirstUnmetConnectionRequirement } from '@/selectors'
import LockedFeatureCard from '../LockedFeatureCard'
import { ConnectionRequirement } from '../types'
import { CardProps } from '@/components'

export interface Props extends CardProps {
  title: string
  connectionRequirements: ConnectionRequirement[]
  children?: React.ReactNode
}

export default function LockedFeature(props: Props) {
  const { title, connectionRequirements, children, ...cardProps } = props
  const connectRequirement = useAppSelector((state) =>
    selectFirstUnmetConnectionRequirement(state, connectionRequirements),
  )

  return !connectRequirement ? (
    <>{children}</>
  ) : (
    <LockedFeatureCard
      title={title}
      connectionRequirement={connectRequirement}
      {...cardProps}
    />
  )
}
